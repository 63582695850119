export const SOC_ECO_TH_5_5 = [
  {
    id: 'transportObject',
    label: 'Транспортный объект',
  },
  {
    id: 'transportArea',
    label: 'Транспортный район',
  },
  // {
  //   id: 'year',
  //   label: 'Год',
  // },
  // {
  //   id: 'simultaneousMaximumPassTraffic',
  //   label: 'Единовременный максимальный пассажиропоток, чел.',
  // },
  {
    id: 'numberNavigationElements',
    label: 'Количество элементов навигации, шт.',
  },
];
