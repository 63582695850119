import { IBusType, IPlaneType, ITrainType } from '@api/gql/types';
import { useMemo } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import {
  AirportIcon,
  BusStationIcon,
  PathWithBorderIcon,
} from '@components/icons';
import { FreeCircleIcon } from '@components/icons/FreeCircleIcon/FreeCircleIcon';
import { RailwayStationIcon } from '@components/icons/RailwayStation';

import { palette } from '@system/theme/palette';

type PathCreatorProps = {
  stations:
    | ITrainType['stations']
    | IBusType['stations']
    | IPlaneType['stations']
    | undefined;
  layout: string;
};

export const PathCreator = (props: PathCreatorProps) => {
  const { stations, layout } = props;

  const subColor = useMemo(() => {
    switch (layout) {
      case 'nightTrain':
        return palette.common.white;
      case 'dayTrain':
        return palette.common.white;
      case 'suburbanTrain':
        return palette.common.white;
      case 'speedSuburbanTrain':
        return palette.common.white;
      case 'highSpeedTrain':
        return palette.common.white;
      case 'bus':
        return palette.common.white;
      case 'avia':
        return palette.common.white;

      default:
        return palette.common.white;
    }
  }, [layout]);

  const mainColor = useMemo(() => {
    switch (layout) {
      case 'nightTrain':
        return palette.customs.trainNight;
      case 'dayTrain':
        return palette.customs.trainDay;
      case 'suburbanTrain':
        return palette.customs.trainLocal;
      case 'speedSuburbanTrain':
        return palette.customs.trainPlannedHighSpeed;
      case 'highSpeedTrain':
        return palette.customs.trainHighSpeed;
      case 'bus':
        return palette.customs.auto;
      case 'avia':
        return palette.customs.avia;

      default:
        return palette.customs.train;
    }
  }, [layout]);

  return (
    <Grid>
      {stations?.map((station, i) => {
        const layoutIcon =
          layout === 'bus' ? (
            <BusStationIcon />
          ) : layout === 'avia' ? (
            <AirportIcon />
          ) : (
            <RailwayStationIcon
              mainColor={mainColor}
              background={subColor}
            />
          );

        const icon =
          i === 0 || i === stations.length - 1 ? (
            layoutIcon
          ) : (
            <FreeCircleIcon
              color={subColor}
              border={mainColor}
            />
          );

        return (
          <Box key={station?.id + i}>
            {i !== 0 && (
              <Grid
                display={'flex'}
                alignItems={'center'}
              >
                <PathWithBorderIcon
                  path={subColor}
                  border={mainColor}
                />
              </Grid>
            )}

            <Grid
              display={'flex'}
              alignItems={'center'}
              pl={i === 0 || i === stations.length - 1 ? 0 : 0.75}
            >
              {icon}

              <Typography
                sx={{
                  fontSize: theme => theme.typography.pxToRem(12),
                  lineHeight: 1,
                  ml: i === 0 || i === stations.length - 1 ? 0.5 : 1,
                }}
              >
                {station?.station?.name || ''}
              </Typography>
            </Grid>
          </Box>
        );
      })}
    </Grid>
  );
};
