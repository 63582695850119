import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import {
  IconButton,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  iconButtonClasses,
} from '@mui/material';

import { BasketIcon, CopyIcon, EditIcon, ShareIcon } from '@components/icons';

import { EAgProjectStatusType } from '@features/ag-projects-registry/constants/agProjectStatuses';
import {
  CopyProject,
  CurrentProjectApi,
} from '@features/ag-projects-registry/store/currentProject';
import { GetProjectFilesFx } from '@features/ag-projects-registry/store/files/store';
import { ProjectType } from '@features/ag-projects-registry/store/lists/projects';
import { SettingsApi } from '@features/ag-projects-registry/store/settings/store';
import { UIAgApi } from '@features/ag-projects-registry/store/ui';

type TableProjectCellActionsProps = {
  row: ProjectType;
};

export const TableProjectCellActions = (
  props: TableProjectCellActionsProps,
) => {
  const { row } = props;
  const { setVisibleProjectCreator, setModalView } = useUnit(UIAgApi);
  const { selectProject } = useUnit(CurrentProjectApi);
  const copyProject = useUnit(CopyProject);
  const { setStateReadonly } = useUnit(SettingsApi);

  const disabledCopy = useMemo(
    () => row.status !== EAgProjectStatusType.Success,
    [row.status],
  );

  const disabledEdit = useMemo(
    () =>
      row.status === EAgProjectStatusType.Queue ||
      row.status === EAgProjectStatusType.Calculation,
    [row.status],
  );

  const disabledShare = useMemo(
    () => row.status !== EAgProjectStatusType.Success,
    [row.status],
  );

  const disabledRemove = useMemo(
    () =>
      row.status === EAgProjectStatusType.Queue ||
      row.status === EAgProjectStatusType.Calculation,
    [row.status],
  );

  // Копировать проект
  const onCopyProject = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, disabled: boolean) => {
      e.stopPropagation();

      if (disabled) return;

      copyProject();
    },
    [copyProject],
  );

  // Передать в редактирование этот проект
  const handleEditProject = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, disabled: boolean) => {
      e.stopPropagation();

      if (disabled) return;

      setVisibleProjectCreator(true);
      selectProject({
        id: String(row.id),
        name: row.name,
        selectedAgglomeration: row.agglomeration,
      });
      GetProjectFilesFx({ id: String(row.id) });
      setStateReadonly(false);
    },
    [
      row.agglomeration,
      row.id,
      row.name,
      selectProject,
      setStateReadonly,
      setVisibleProjectCreator,
    ],
  );

  // Отобразить модалку - поделиться проектом
  const handleShareProject = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, disabled: boolean) => {
      e.stopPropagation();

      if (disabled) return;

      setModalView({ shareProject: true });
    },
    [setModalView],
  );

  // Отобразить модалку - удалить проект
  const handleRemoveProject = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, disabled: boolean) => {
      e.stopPropagation();

      if (disabled) return;

      setModalView({ deleteProject: true });
    },
    [setModalView],
  );

  return (
    <Stack direction="row">
      <Tooltip
        title={'Копировать'}
        placement="top"
      >
        <IconButton
          sx={IconButtonSX}
          size={'small'}
          className={disabledCopy ? iconButtonClasses.disabled : ''}
          onClick={e => onCopyProject(e, disabledCopy)}
        >
          <CopyIcon />
        </IconButton>
      </Tooltip>

      <Tooltip
        title={'Редактировать'}
        placement="top"
      >
        <IconButton
          size={'small'}
          sx={IconButtonSX}
          className={disabledEdit ? iconButtonClasses.disabled : ''}
          onClick={e => handleEditProject(e, disabledEdit)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Tooltip
        title={'Поделиться'}
        placement="top"
      >
        <IconButton
          size={'small'}
          sx={IconButtonSX}
          className={disabledShare ? iconButtonClasses.disabled : ''}
          onClick={e => handleShareProject(e, disabledShare)}
        >
          <ShareIcon />
        </IconButton>
      </Tooltip>

      <Tooltip
        title={'Удалить'}
        placement="top"
      >
        <IconButton
          size={'small'}
          sx={IconButtonSX}
          className={disabledRemove ? iconButtonClasses.disabled : ''}
          onClick={e => handleRemoveProject(e, disabledRemove)}
        >
          <BasketIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

const IconButtonSX: SxProps<Theme> = {
  p: 0,
  ml: 1,
};
