export const SOC_ECO_TH_5_2 = [
  {
    id: 'transportObject',
    label: 'Транспортный объект',
  },
  {
    id: 'transportArea',
    label: 'Транспортный район',
  },
  {
    id: 'simultaneousMaximumPassTraffic',
    label: 'Единовременный максимальный пассажиропоток, чел.',
  },
  {
    id: 'numberPublicTransportRoutes',
    label: 'Число видов транспортов, на которых возможна пересадка',
  },
];
