import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import {
  BankIcon,
  BankomatIcon,
  BuffetIcon,
  CartIcon,
  InformationTableIcon,
  KioskIcon,
  RestaurantIcon,
  StoreIcon,
  TicketPrintingMachineIcon,
  TicketsIcon,
  ToiletIcon,
  VendingIcon,
} from '@components/icons';

import { ITableRow } from '@features/tpu-calculation-scenarios-viewing/stores/chartsCommercialObjects/chartsCommercialObjects.store';
import { EditableObjectType } from '@features/tpu-simulation-model/types';

import { dividingNumber } from '@utils/dividingNumber';

const enum EBarColor {
  Quantity = '#79A2F2',
  Revenue = '#B9E4AB',
  RevenuePerSqMeter = '#FEC583',
}

interface CommercialAreasTableProps {
  fullVariant?: boolean;
  rows: ITableRow[];
  onClick?: () => void;
}

interface BarProps {
  value: number;
  color: EBarColor;
}

const IconList: Record<string, JSX.Element> = {
  [EditableObjectType.Restaurant]: <RestaurantIcon />,
  [EditableObjectType.InformationTable]: <InformationTableIcon />,
  [EditableObjectType.TicketOffice]: <TicketsIcon />,
  [EditableObjectType.TicketPrintingMachine]: <TicketPrintingMachineIcon />,
  [EditableObjectType.Toilet]: <ToiletIcon />,
  [EditableObjectType.Buffet]: <BuffetIcon />,
  [EditableObjectType.VendingMachine]: <VendingIcon />,
  [EditableObjectType.Store]: <StoreIcon />,
  [EditableObjectType.Kiosk]: <KioskIcon />,
  [EditableObjectType.Bank]: <BankIcon />,
  [EditableObjectType.Bankomat]: <BankomatIcon />,
  [EditableObjectType.Other]: <CartIcon />,
};

const IconEl = ({ name }: { name: string }): JSX.Element => {
  return IconList[name];
};

const StyledText = ({ children }: React.PropsWithChildren): JSX.Element => {
  return <span style={{ color: '#606060' }}>{children}</span>;
};

const Bar = ({ value, color }: BarProps) => {
  return (
    <Box
      style={{
        width: `${value}%`,
        height: '6px',
        marginTop: '8px',
        backgroundColor: `${color}`,
        cursor: 'pointer',
        borderRadius: '4px',
      }}
    ></Box>
  );
};

export const CommercialAreasTable = ({
  fullVariant,
  rows,
  onClick,
}: CommercialAreasTableProps) => {
  return (
    <TableContainer
      sx={{
        borderRadius: '8px',
        border: '1px solid #EBECEF',
        '&::-webkit-scrollbar': { width: '4px' },
        '&::-webkit-scrollbar-track': {
          background: '#F6F6F6',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#BBC0CB',
          border: 'none',
          borderRadius: '50px',
        },
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Table
        size="medium"
        aria-label="Commercial Areas Table"
      >
        <TableHead sx={{ backgroundColor: '#F6F6F6' }}>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                borderRight: theme =>
                  `1px solid ${theme.palette.border.divider}`,
                fontSize: '12px',
              }}
            >
              Название
            </TableCell>

            {fullVariant && (
              <>
                <TableCell
                  align="left"
                  sx={{
                    borderRight: theme =>
                      `1px solid ${theme.palette.border.divider}`,
                    fontSize: '12px',
                  }}
                >
                  Площадь,<StyledText> кв. м</StyledText>
                </TableCell>

                <TableCell
                  align="left"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '1.2',
                    borderRight: theme =>
                      `1px solid ${theme.palette.border.divider}`,
                    fontSize: '12px',
                  }}
                >
                  <Box sx={{ mb: '3px' }}>
                    Выручка на кв. м,
                    <StyledText> руб.</StyledText>
                  </Box>

                  <StyledText>(результаты на период симуляции)</StyledText>
                </TableCell>
              </>
            )}

            <TableCell
              align="left"
              sx={{
                borderRight: theme =>
                  `1px solid ${theme.palette.border.divider}`,
                fontSize: '12px',
              }}
            >
              Кол-во,<StyledText> чел.</StyledText>
            </TableCell>

            <TableCell
              align="left"
              sx={{
                fontSize: '12px',
              }}
            >
              Выручка,<StyledText> руб.</StyledText>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map(row => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': {
                  borderTop: 0,
                  borderBottom: 0,
                },
              }}
            >
              <TableCell
                sx={{
                  minWidth: '150px',
                  borderRight: theme =>
                    `1px solid ${theme.palette.border.divider}`,
                  fontSize: '12px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {fullVariant && <IconEl name={row.icon} />}

                  <Typography
                    sx={{
                      ml: '8px',
                      display: 'inline-block',
                      fontSize: '12px',
                    }}
                  >
                    {row.name}
                  </Typography>
                </Box>
              </TableCell>

              {fullVariant && (
                <>
                  <TableCell
                    sx={{
                      minWidth: '100px',
                      borderRight: theme =>
                        `1px solid ${theme.palette.border.divider}`,
                      fontSize: '12px',
                    }}
                  >
                    {row.square}
                  </TableCell>

                  <TableCell
                    align="left"
                    sx={{
                      minWidth: '140px',
                      borderRight: theme =>
                        `1px solid ${theme.palette.border.divider}`,
                      color: '#606060',
                      fontSize: '12px',
                      lineHeight: '12px',
                      fontWeight: '400',
                      paddingY: '10px',
                      paddingLeft: '14px',
                    }}
                  >
                    {row.revenuePerSqMeter !== null
                      ? dividingNumber(row.revenuePerSqMeter)
                      : ' - '}

                    {row.revenuePerSqMeterPercent !== null && (
                      <Bar
                        value={row.revenuePerSqMeterPercent}
                        color={EBarColor.RevenuePerSqMeter}
                      />
                    )}
                  </TableCell>
                </>
              )}

              <TableCell
                align="left"
                sx={{
                  minWidth: '130px',
                  borderRight: theme =>
                    `1px solid ${theme.palette.border.divider}`,
                  color: '#606060',
                  fontSize: '12px',
                  lineHeight: '12px',
                  fontWeight: '400',
                  paddingY: '10px',
                  paddingLeft: '14px',
                }}
              >
                {dividingNumber(row.agentsQuantity)}

                <Bar
                  value={row.agentsQuantityPercent}
                  color={EBarColor.Quantity}
                />
              </TableCell>

              <TableCell
                align="left"
                sx={{
                  minWidth: '140px',
                  color: '#606060',
                  fontSize: '12px',
                  lineHeight: '12px',
                  fontWeight: '400',
                  paddingY: '10px',
                  paddingLeft: '14px',
                }}
              >
                {row.revenue !== null ? dividingNumber(row.revenue) : ' - '}

                {row.revenuePercent !== null && (
                  <Bar
                    value={row.revenuePercent}
                    color={EBarColor.Revenue}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
