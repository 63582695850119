import { FetchResult, Operation } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Observable } from '@apollo/client/utilities/observables/Observable';

type ShowErrorHandler = (message: string) => void;
type CreateErrorLinkArgs = {
  showErrorHandler?: ShowErrorHandler;
  unauthorizedCallback?: () => void;
  refreshRetryCallback?: (
    forward: (operation: Operation) => Observable<FetchResult>,
    operation: Operation,
  ) => Observable<FetchResult>;
};
export const createErrorLink = ({
  showErrorHandler,
  unauthorizedCallback,
  refreshRetryCallback,
}: CreateErrorLinkArgs) =>
  onError(({ graphQLErrors, networkError, forward, operation }) => {
    const isDev = import.meta.env.NODE_ENV === 'development';
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (isDev) {
          if (showErrorHandler) {
            showErrorHandler(
              `[GraphQL error]: Message: ${message},\n\r
               Location: ${JSON.stringify(locations)},\n\r
               Path: ${JSON.stringify(path)}`,
            );
          }
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        }
        /**
         * ЕСЛИ НУЖНО БУДЕТ ОТОБРАЖАТЬ ОПРЕДЕЛЁННЫЕ ОШИБКИ В ПРОДАКШЕНЕ ИСПОЛЬЗУЕМ showErrorHandler
         * */
      });
    }
    if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === 401
    ) {
      if (isDev) console.log(`[Network error]: ${networkError}`);
      if (unauthorizedCallback) unauthorizedCallback();
      if (refreshRetryCallback) return refreshRetryCallback(forward, operation);
    }
  });
