import { Effect, attach, createEvent, createStore, sample } from 'effector';

export const debounceEffect = <T, R>(
  target: Effect<T, R>,
  ms: number = 500,
) => {
  const launchTimeoutFn = createEvent<T>();
  const setCancellerFn = createEvent<[NodeJS.Timeout, () => void]>();

  const $canceller = createStore<[NodeJS.Timeout, () => void] | []>([]);
  const $targetArgs = createStore<T[]>([]).on(launchTimeoutFn, (_, payload) => [
    payload,
  ]);

  const triggerTargetFx = attach({
    source: $canceller,
    effect: async ([timeoutId, rejectPromise]) => {
      if (timeoutId) clearTimeout(timeoutId);
      if (rejectPromise) rejectPromise();
      return new Promise((res, rej) => {
        setCancellerFn([setTimeout(res, ms), rej]);
      });
    },
  });

  $canceller
    .reset(triggerTargetFx.done)
    .on(setCancellerFn, (_, payload) => payload);

  sample({
    clock: launchTimeoutFn,
    target: triggerTargetFx,
  });

  sample({
    clock: triggerTargetFx.done,
    source: $targetArgs,
    fn: ([args]) => args,
    target,
  });

  return launchTimeoutFn;
};
