import { Icon, Style } from 'ol/style';

import PointASVG from '@components/icons/PointA.svg';

export const getPointAStyle = () =>
  new Style({
    image: new Icon({
      src: PointASVG,
    }),
  });
