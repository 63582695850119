import { List, ListItem, ListItemText } from '@mui/material';

import { IPlayerError } from '@features/tpu-calculation-scenarios-viewing/stores/scenarioErrors/scenarioErrors.store';

interface ErrorsListProps {
  errors: IPlayerError[] | null;
}

export const ErrorsList = ({ errors }: ErrorsListProps) => {
  return (
    <List
      sx={{
        padding: '12px 0 0 0',
        overflow: 'auto',
      }}
    >
      {errors &&
        errors.map((error, index) => (
          <ListItem
            key={index}
            sx={{
              borderTop: theme => `1px solid ${theme.palette.border.divider}`,
              padding: '12px 0',
            }}
          >
            <ListItemText
              primary={error.description}
              primaryTypographyProps={{
                sx: {
                  fontSize: theme => theme.typography.pxToRem(16),
                  lineHeight: theme => theme.typography.pxToRem(20),
                  wordBreak: 'break-word',
                },
              }}
            />
          </ListItem>
        ))}
    </List>
  );
};
