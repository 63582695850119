import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const InformationTableIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <circle
        cx="12"
        cy="12"
        r="12"
        fill="#fff"
      />
      <path
        d="m15.718 16.817-.507 1.014c-.845 1.69-2.112 2.958-4.056 2.873C8.113 20.62 7.859 18 8.366 15.972l1.775-6.93h4.14c0 .17-1.859 7.944-1.859 7.944-.168.676-.591 1.944.339 1.944.591 0 1.098-.846 1.436-1.606l.423-.93c.169-.507.676-.507 1.014-.422.338.169.169.591.084.845ZM13.183 3.21c1.268.085 1.944 1.014 1.944 2.197.084 1.353-1.099 2.536-2.45 2.536-1.184 0-2.198-1.014-2.198-2.198 0-1.52 1.268-2.62 2.704-2.535ZM12 0C5.408 0 0 5.324 0 12s5.324 12 12 12c6.592 0 12-5.324 12-12S18.676 0 12 0Z"
        fill="#83CA6B"
      />
    </SvgIcon>
  );
};
