import { IQuery, IQueryGetComputedPassTrafficArgs } from '@api/gql/types';
import { createApi, createEffect, createStore } from 'effector';

import { EDailyUnevennessTime } from '@features/pt-forecast-new/constants/eDailyUnevennessTime';
import { EVehicle } from '@features/pt-forecast-new/constants/eVehicle';

const initState: ComputedStore = {
  sampleResults: [],
  isLoading: false,
};

export const $Computed = createStore(initState);

export const ComputedApi = createApi($Computed, {
  setComputedResults: (store, payload: ComputedResulItem[]) => ({
    ...store,
    sampleResults: payload,
  }),
  setIsLoading: (store, payload: boolean) => ({
    ...store,
    isLoading: payload,
  }),
  reset: () => initState,
});

export const getComputedResultsFx = createEffect<
  IQueryGetComputedPassTrafficArgs,
  IQuery['getComputedPassTraffic']
>();

export type ComputedResulItem = {
  id: string;
  year: number;
  passengerTraffic: Record<EVehicle, number> & {
    totalFlow: number;
  };
  price: Record<EVehicle, number>;
  frequency: Record<EVehicle, number>;
  duration: Record<EVehicle, number>;
  unevenness: Record<EVehicle, number[]>;
  dailyUnevenness: Record<
    EVehicle,
    Record<EDailyUnevennessTime, number | null>
  > | null;
  passengerTrafficByIncomeGroups: {
    value: number;
    category: string;
  }[];
  passengerTrafficByAge: {
    workingAge: number;
    underWorkingAge: number;
    overWorkingAge: number;
  };
  passengerTrafficBySex: {
    male: number;
    female: number;
  };
};

type ComputedStore = {
  sampleResults: ComputedResulItem[];
  isLoading: boolean;
};
