import { apiClient } from '@api/client';
import { IQuery, IQueryFindRegionArgs } from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import './store';

import { PT_CLIENT_NAME } from '@constants/api';

import { AgGeometryApi } from '@features/ag-project/stores/map';
import {
  FeatureSettingsApi,
  FindRegionFx,
} from '@features/ag-project/stores/settings';

// При получении координатов агломерации, запросить UUID региона
sample({
  clock: AgGeometryApi.setAgGeometry,
  fn: payload => {
    if (!payload.center?.length) throw Error('Center is not defined');
    return {
      lat: payload.center[0],
      lon: payload.center[1],
    } as IQueryFindRegionArgs;
  },
  target: FindRegionFx,
});

sample({
  clock: FindRegionFx.done,
  fn: request => {
    return request.result?.transportRegion?.id;
  },
  target: FeatureSettingsApi.setRegionUUID,
});

FindRegionFx.use(async params => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query QueryFindRegion($lon: Float!, $lat: Float!) {
        findRegion(lon: $lon, lat: $lat) {
          transportRegion {
            id
          }
        }
      }
    `,
    variables: {
      lon: params.lon,
      lat: params.lat,
    },
    context: {
      clientName: PT_CLIENT_NAME,
    },
  });
  return response.data.findRegion;
});
