import { getWallLines } from './get-wall-lines';

import { IPlatform, IWall } from '@features/tpu-simulation-model/types';
import { getPolygonLines } from '@features/tpu-simulation-model/utils/prepare-plan-before-upload/get-polygon-lines';

import { getCorners } from '@utils/simulationModel/getCorners';
import { isPointInsideOfPolygon } from '@utils/simulationModel/isPointInsideOfPolygon';
import { lineIntersection } from '@utils/simulationModel/line-intersection';

export const checkIntersectionWallPlatform = (
  wall: IWall,
  platform: IPlatform,
): boolean => {
  const platformCorners = getCorners(platform);
  const platformLines = getPolygonLines(platformCorners);
  const wallLines = getWallLines(wall);

  const isIntersect = platformLines.some(roomLine => {
    const isRoomLineIntersectWall = wallLines.some(wallLine =>
      lineIntersection(wallLine, roomLine),
    );
    return isRoomLineIntersectWall;
  });

  const isWallInsideRoom = wall.points.some(point =>
    isPointInsideOfPolygon([point.x, point.y], platformCorners),
  );

  return isIntersect || isWallInsideRoom;
};
