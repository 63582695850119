import { useUnit } from 'effector-react';
import { PropsWithChildren, useCallback, useEffect } from 'react';

import {
  Box,
  Card,
  CardContent,
  CardProps,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { CostDynamics } from '@features/ag-forecast/containers/DashboardsContainer/components/FirstPage/CostDynamics';
import { PassTrafficDynamics } from '@features/ag-forecast/containers/DashboardsContainer/components/FirstPage/PassTrafficDynamics';
import { TotalTransportPassengerTraffic } from '@features/ag-forecast/containers/DashboardsContainer/components/FirstPage/TotalTransportPassengerTraffic';
import { FilterContainer } from '@features/ag-forecast/containers/DashboardsContainer/containers/FirstPageContainer/FilterContainer';
import { YearLineContainer } from '@features/ag-forecast/containers/DashboardsContainer/containers/YearLineContainer/YearLineContainer';
import { queryDataDashboardP1Fx } from '@features/ag-forecast/stores/dashboard/firstPage/store';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings/store';

export const FirstPageDashboardContainer = () => {
  const $settings = useUnit($FeatureSettings);

  useEffect(() => {
    queryDataDashboardP1Fx({ scenarioId: $settings.scenarioUUID || '' });
  }, [$settings.scenarioUUID]);

  const CardHOC = useCallback(
    ({
      children,
      title,
      ...rest
    }: PropsWithChildren<{ title: string }> & CardProps) => (
      <Card
        sx={{ width: '100%' }}
        {...rest}
      >
        <CardContent sx={{ p: 3, height: '100%' }}>
          <Typography
            sx={{ mb: '24px' }}
            variant={'subtitle2'}
          >
            {title}
          </Typography>

          {children}
        </CardContent>
      </Card>
    ),
    [],
  );

  return (
    <Box sx={ContainerMuiStyle}>
      <FilterContainer />

      <AutoFullHeight
        style={{
          padding: '24px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '24px',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              columnGap: '24px',
              width: '100%',
              height: 'calc(50% - 55px)',
            }}
          >
            <Box sx={{ width: '50%', height: '100%' }}>
              <CardHOC
                title="Динамика совокупных транспортных затрат по времени суток"
                sx={{
                  minHeight: '180px',
                  height: '100%',
                }}
              >
                <CostDynamics />
              </CardHOC>
            </Box>

            <Box sx={{ width: '50%', height: '100%' }}>
              <CardHOC
                title="Динамика пассажиропотока по времени суток"
                sx={{
                  minHeight: '180px',
                  height: '100%',
                }}
              >
                <PassTrafficDynamics />
              </CardHOC>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              columnGap: '24px',
              width: '100%',
              height: 'calc(50% - 55px)',
            }}
          >
            <Box sx={{ width: '100%', height: '100%' }}>
              <CardHOC
                title="Структура суммарного пассажиропотока по видам транспорта в агломерации"
                sx={{
                  minHeight: '180px',
                  height: '100%',
                }}
              >
                <TotalTransportPassengerTraffic />
              </CardHOC>
            </Box>

            {/* <Box sx={{ width: '50%', height: '100%' }}>
              <CardHOC
                title="Структура суммарного пассажиропотока по видам транспорта Улица Мичурина — Хлебозавод"
                sx={{
                  minHeight: '180px',
                  height: '100%',
                }}
              >
                <TotalTransportPassengerTrafficStreet />
              </CardHOC>
            </Box> */}
          </Box>

          <YearLineContainer />
        </Box>
      </AutoFullHeight>
    </Box>
  );
};

const ContainerMuiStyle: SxProps<Theme> = {
  borderTop: theme => `1px solid ${theme.palette.border.divider}`,
  display: 'flex',
};
