import { ForwardedRef, forwardRef } from 'react';

import { Button, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

import { StyledTooltip } from '@components/StyledTooltip';
import { CloseIcon, InfoOutlinedIcon, RulerIcon } from '@components/icons';

export interface RulerButtonComponentProps {
  isScaleExist: boolean;
  isOpenRulerInputWindow: boolean;
  onClick: () => void;
  onReset: () => void;
}

const RulerButtonComponent = (
  props: RulerButtonComponentProps,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  const { isScaleExist, isOpenRulerInputWindow, onClick, onReset } = props;
  const tooltipTitle = isScaleExist ? (
    <>
      Масштаб определен, нажмите на
      <CloseIcon sx={{ height: '16px', width: '16px' }} />
      чтобы сбросить.
    </>
  ) : (
    'Проведите линию и укажите расстояние в метрах, чтобы определить точный масштаб карты ТПУ.'
  );

  return (
    <StyledTooltip
      title={tooltipTitle}
      sx={{
        '& .MuiTooltip-tooltip': {
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          width: '386px',
          maxWidth: 'unset',
          padding: '12px',
          boxShadow: '0px 2px 4px 0px #0000001A',
          backgroundColor: (theme: Theme) => theme.palette.background.paper,
          color: (theme: Theme) => theme.palette.text.secondary,
          fontSize: (theme: Theme) => theme.typography.pxToRem(12),
          lineHeight: (theme: Theme) => theme.typography.pxToRem(16),
        },
      }}
    >
      <Button
        ref={ref}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          height: '48px',
          padding: '12px 24px',
          width: '176px',
          backgroundColor: theme =>
            isOpenRulerInputWindow
              ? theme.palette.secondary.dark
              : theme.palette.background.paper,
          color: theme =>
            isOpenRulerInputWindow ? '#fff' : theme.palette.text.primary,
          borderRadius: '4px',
          boxShadow: '0px 0px 8px 0px #00000026',
          pointerEvents: 'auto',

          '&:hover': {
            backgroundColor: theme => theme.palette.customs.bgHover,
          },
        }}
        onClick={isScaleExist ? onReset : onClick}
      >
        <RulerIcon />
        <Typography
          sx={{
            fontSize: theme => theme.typography.pxToRem(14),
            lineHeight: theme => theme.typography.pxToRem(16),
          }}
        >
          Линейка
        </Typography>
        {isScaleExist ? (
          <CloseIcon sx={{ height: '16px', width: '16px' }} />
        ) : (
          <InfoOutlinedIcon />
        )}
      </Button>
    </StyledTooltip>
  );
};

export const RulerButton = forwardRef<
  HTMLButtonElement,
  RulerButtonComponentProps
>(RulerButtonComponent);
