import { apiClient } from '@api/client';
import {
  IQuery,
  IQueryGetTransportsRegionStarTableArgs,
} from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import { $FilterMap } from '@features/ag-forecast/stores/filters';
import {
  PassFlowTableContentApi,
  PassFlowTableContentRow,
  fetchPassFlowTableContent,
  fetchPassFlowTableContentFx,
} from '@features/ag-forecast/stores/lists/passFlowTableContent/store';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';
import { $YearLineStore } from '@features/ag-forecast/stores/yearLine';

// Запросить данные по таблице
sample({
  clock: fetchPassFlowTableContent,
  source: {
    FeatureSettings: $FeatureSettings,
    FilterMap: $FilterMap,
    YearLineStore: $YearLineStore,
  },
  fn: ({ FeatureSettings, FilterMap, YearLineStore }) => {
    return {
      scenarioId: FeatureSettings.scenarioUUID,
      timeSlot: FilterMap.selectedTimeSlot,
      year: YearLineStore.selectedYear,
      transportDistrictsFrom: FilterMap.fromSelectedRegions.map(
        ({ value }) => value,
      ),
      transportDistrictsTo: FilterMap.toSelectedRegions.map(
        ({ value }) => value,
      ),
    } as IQueryGetTransportsRegionStarTableArgs;
  },
  target: fetchPassFlowTableContentFx,
});

fetchPassFlowTableContentFx.use(async params => {
  const response = await apiClient.query<
    Pick<IQuery, 'getTransportsRegionStarTable'>,
    IQueryGetTransportsRegionStarTableArgs
  >({
    query: gql`
      query QueryGetTransportsRegionStarTable(
        $scenarioId: UUID!
        $timeSlot: UUID!
        $transportDistrictsFrom: [UUID]!
        $transportDistrictsTo: [UUID]!
        $year: Int!
      ) {
        getTransportsRegionStarTable(
          scenarioId: $scenarioId
          timeSlot: $timeSlot
          transportDistrictsFrom: $transportDistrictsFrom
          transportDistrictsTo: $transportDistrictsTo
          year: $year
        ) {
          regionFrom {
            center {
              name
            }
          }
          regionTo {
            center {
              name
            }
          }
          private {
            flow
            stz
          }
          public {
            flow
            stz
          }
        }
      }
    `,
    variables: {
      scenarioId: params.scenarioId,
      timeSlot: params.timeSlot,
      transportDistrictsFrom: params.transportDistrictsFrom,
      transportDistrictsTo: params.transportDistrictsTo,
      year: params.year,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data.getTransportsRegionStarTable || null;
});

sample({
  clock: fetchPassFlowTableContentFx.done,
  fn: request => {
    return request.result?.map(row => ({
      fromName: row?.regionFrom?.center?.name || '',
      toName: row?.regionTo?.center?.name || '',
      stzPrivate: row?.private?.stz || 0,
      stzPublic: row?.public?.stz || 0,
      flowPrivate: row?.private?.flow || 0,
      flowPublic: row?.public?.flow || 0,
      timePublic: 0,
      timePrivate: 0,
      percent: 0,
    })) as PassFlowTableContentRow[];
  },
  target: PassFlowTableContentApi.setRows,
});
