import { IQuery, IQueryGetProjectByIdArgs } from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

const initState: YearLineState = {
  selectedYear: 2023,
  maxYear: 2027,
  minYear: 2023,
};

export const $YearLineStore = createStore<YearLineState>(initState);

export const YearLineApi = createApi($YearLineStore, {
  setSelectedYear: (store, year) => ({ ...store, selectedYear: year }),
  setMaxYear: (store, year) => ({ ...store, maxYear: year || store.maxYear }),
  setMinYear: (store, year) => ({ ...store, nimYear: year || store.minYear }),
  reset: () => initState,
});

export const getTimelineYearsFx = createEffect<
  IQueryGetProjectByIdArgs,
  IQuery['getProjectById']
>();

type YearLineState = {
  selectedYear: number;
  maxYear: number;
  minYear: number;
};
