import { Line } from 'react-konva';

import {
  IWall,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

interface WallProps extends IWall {
  opacity: number;
  onSelect: (arg: ObjectOfTheSimulationModel) => void;
}

export const Wall = ({ onSelect, opacity, ...props }: WallProps) => {
  const { points, isClosed } = props;

  return (
    <Line
      onClick={e => {
        e.cancelBubble = true;
        onSelect(props);
      }}
      opacity={opacity}
      strokeWidth={4}
      stroke="#E21A1A"
      closed={isClosed}
      points={points.flatMap(({ x, y }) => [x, y])}
    />
  );
};
