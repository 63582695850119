import { PropsWithChildren } from 'react';

import { InputLabel, Stack } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';

type FormLabelProps = PropsWithChildren<{
  title: string;
  id: string;
  sx?: SxProps<Theme>;
}>;

export const FormLabel = (props: FormLabelProps) => {
  const { title, id, children, sx } = props;
  return (
    <Stack
      flexDirection={'column'}
      sx={sx}
    >
      <InputLabel
        htmlFor={id}
        sx={{ mb: 1 }}
      >
        {title}
      </InputLabel>
      {children}
    </Stack>
  );
};
