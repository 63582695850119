import { SVGProps } from 'react';
import { Rectangle } from 'recharts';

import { CustomCursorStyle } from '../ScenarioBarChart';

export const CustomCursor = ({
  x,
  y,
  width,
  height,
  color,
  opacity,
}: Partial<SVGProps<SVGPathElement>> & CustomCursorStyle) => {
  return (
    <Rectangle
      fill={color}
      stroke={color}
      opacity={opacity}
      x={Number(x)}
      y={Number(y)}
      width={Number(width)}
      height={Number(height)}
    />
  );
};
