import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { ModalDeleteCalculationAction } from '@features/pt-constructor/components/ModalDeleteCalculation';
import { ModalEditCancellationData } from '@features/pt-constructor/components/ModalEditCancellation';
import { ModalReCalculationAction } from '@features/pt-constructor/components/ModalReCalculation/ModalEditCancellation';
import { ModalRewriteCalculatedAction } from '@features/pt-constructor/components/ModalRewriteCalculated';
import { ECalculationScenarioStatusType } from '@features/pt-constructor/constants/calculationScenarioStatusType';
import {
  $CalculationStatuses,
  $Calculations,
  EModalRewriteCalculated,
  createCopyCalculationScenario,
  createOrUpdateCalculationScenario,
  setModalView,
} from '@features/pt-constructor/store';
import {
  $SelectedCalculationScenario,
  SelectedCalculationScenarioApi,
} from '@features/pt-constructor/store/selected/store';

// Предоставляет обработчик который сначала отобразит модалку, а по результатом выполнит колбэк
export const useConfirms = () => {
  const { readonly } = useUnit($Calculations);
  const { id, name } = useUnit($SelectedCalculationScenario);
  const { clearSelectedCalculationScenario } = useUnit(
    SelectedCalculationScenarioApi,
  );
  const $calculationStatuses = useUnit($CalculationStatuses);
  const handlers = useUnit({
    setModalView,
    createOrUpdateCalculationScenario,
    createCopyCalculationScenario,
  });

  // Выход без сохранения данных
  const beforeDropChangeHandler = useCallback(
    (action: ModalEditCancellationData) => {
      handlers.setModalView({
        editDeny: true,
        action,
      });
    },
    [handlers],
  );

  const beforeDropCalculatedScenarioHandler = useCallback(
    (action: ModalRewriteCalculatedAction) => {
      handlers.setModalView({
        rewriteCalculated: true,
        action,
      });
    },
    [handlers],
  );

  // попытка пересчитать уже рассчитанный сценарий
  const beforeRecalculateHandler = useCallback(
    (action: ModalReCalculationAction) => {
      handlers.setModalView({
        recalculation: true,
        action,
      });
    },
    [handlers],
  );

  // Попытка удалить сценарий
  const beforeDeleteHandler = useCallback(
    (action: ModalDeleteCalculationAction) => {
      handlers.setModalView({
        deleteCalculation: true,
        action,
      });
    },
    [handlers],
  );

  const beforeSaveScenarioHandler = useCallback(
    (action: () => void) => {
      const status = $calculationStatuses.statuses.find(
        status => status.id === id,
      )?.status;

      // Если попытка сохранения уже рассчитанного ранее сценария
      if (status && status === ECalculationScenarioStatusType.Success) {
        beforeDropCalculatedScenarioHandler(solution => {
          if (solution === EModalRewriteCalculated.rewrite) {
            handlers.createOrUpdateCalculationScenario({ run: false });
          } else if (solution === EModalRewriteCalculated.copy) {
            handlers.createCopyCalculationScenario({ run: false });
          }
          action();
        });
        return;
      }

      handlers.createOrUpdateCalculationScenario({ run: false });
      action();
    },
    [$calculationStatuses, id, beforeDropCalculatedScenarioHandler, handlers],
  );

  const beforeRecalculateScenario = useCallback(
    (action: () => void) => {
      const status = $calculationStatuses.statuses.find(
        status => status.id === id,
      )?.status;

      // Если попытка расчета уже рассчитанного ранее сценария
      if (status && status === ECalculationScenarioStatusType.Success) {
        beforeRecalculateHandler(rewrite => {
          if (!rewrite) handlers.createCopyCalculationScenario({ run: true });
          else handlers.createOrUpdateCalculationScenario({ run: true });
          action();
        });
        return;
      }
      handlers.createOrUpdateCalculationScenario({ run: true });
      clearSelectedCalculationScenario();
      action();
    },
    [$calculationStatuses, id, beforeRecalculateHandler, handlers],
  );

  const beforeDropScenario = useCallback(
    (action: () => void) => {
      const status = $calculationStatuses.statuses.find(
        status => status.id === id,
      )?.status;
      // Если переход в режиме только чтения
      if (readonly) {
        action();
        return;
      }
      // Если переход из сценария со статусом рассчитано
      if (status && status === ECalculationScenarioStatusType.Success) {
        beforeDropCalculatedScenarioHandler(solution => {
          if (solution === EModalRewriteCalculated.rewrite) {
            handlers.createOrUpdateCalculationScenario({ run: false });
          } else if (solution === EModalRewriteCalculated.copy) {
            handlers.createCopyCalculationScenario({ run: false });
          }
          action();
        });
        return;
      }
      // Если переход из черновика у которого хотя бы есть имя TODO: Нехватает отображения ошибок
      if (name) {
        beforeDropChangeHandler(save => {
          if (save) {
            handlers.createOrUpdateCalculationScenario({ run: false });
          }
          action();
        });
        return;
      }
      action();
    },
    [
      $calculationStatuses,
      readonly,
      id,
      name,
      beforeDropCalculatedScenarioHandler,
      beforeDropChangeHandler,
      handlers,
    ],
  );

  return {
    beforeDropChangeHandler,
    beforeRecalculateHandler,
    beforeDeleteHandler,
    beforeDropCalculatedScenarioHandler,
    beforeDropScenario,
    beforeRecalculateScenario,
    beforeSaveScenarioHandler,
  };
};
