import { Circle } from 'react-konva';

import { IRuler } from '@features/tpu-simulation-model/types';

interface PlanAgentProps {
  color: string;
  ruler: IRuler;
  position: number[];
}

export const Agent = ({ position, ruler, color }: PlanAgentProps) => {
  const positionInRoomX = (position[0] / ruler.meters) * ruler.px;
  const positionInRoomY = (position[1] / ruler.meters) * ruler.px;

  return (
    <Circle
      x={positionInRoomX}
      y={positionInRoomY}
      radius={2.5}
      fill={color}
    />
  );
};
