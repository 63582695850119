import { sample } from 'effector';

import { EMapFeatureLayout } from '@constants/map';

import { EVehicleAg } from '@features/ag-project/constants/EVehicleAg';
import { $FilterMap } from '@features/ag-project/stores/filters';
import {
  $Vehicles,
  VehiclesApi,
} from '@features/ag-project/stores/lists/vehicles';
import {
  $LayersVisible,
  $SelectedFeatures,
  LayersVisibleApi,
  SelectedFeaturesApi,
  pipeBusTileLayer,
} from '@features/ag-project/stores/map';
import { $FeatureSettings } from '@features/ag-project/stores/settings';
import { createAgBaseGraphSource } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

import {
  EPipeTileLayer,
  PipeTileLayer,
} from '@utils/map/hooks/useInitTileLayer';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload && Object.keys(payload).includes(EMapFeatureLayout.graphBus),
  fn: payload =>
    ({
      action: EPipeTileLayer.setVisible,
      payload: payload![EMapFeatureLayout.graphBus],
    }) as PipeTileLayer,
  target: pipeBusTileLayer,
});

// Установка источника тайлов
sample({
  clock: VehiclesApi.setVehicles,
  source: { Vehicles: $Vehicles, FeatureSettings: $FeatureSettings },
  fn: ({ Vehicles, FeatureSettings }) => {
    if (!Vehicles || !FeatureSettings.projectUUID)
      throw Error('Vehicles not found');
    const autoVehicle = Vehicles.find(
      vehicle => vehicle.vehicleId === EVehicleAg.bus,
    );
    if (!autoVehicle) return null;
    return {
      action: EPipeTileLayer.changeSource,
      payload: createAgBaseGraphSource(
        autoVehicle.id,
        FeatureSettings.projectUUID,
      ),
    } as PipeTileLayer;
  },
  target: pipeBusTileLayer,
});

// Применение стиля к выбранным фичам
sample({
  clock: SelectedFeaturesApi.setSelectedFeatures,
  source: {
    LayersVisible: $LayersVisible,
    SelectedFeatures: $SelectedFeatures,
  },
  filter: ({ LayersVisible }) => LayersVisible[EMapFeatureLayout.graphBus],
  fn: ({ SelectedFeatures }) =>
    ({
      action: EPipeTileLayer.setStyle,
      payload: {
        selectedFeatureIds:
          SelectedFeatures.filter(
            item => item.layout === EMapFeatureLayout.graphBus,
          ).map(item => item.id) || [],
        featureIdMetaKey: 'id',
      },
    }) as PipeTileLayer,
  target: pipeBusTileLayer,
});

// Актуализация пропсов темы
sample({
  clock: [VehiclesApi.setVehicles, $FilterMap],
  source: { FilterMap: $FilterMap },
  fn: ({ FilterMap }) => {
    return {
      action: EPipeTileLayer.changeThemeProps,
      payload: {
        isActual: FilterMap.actual.isActual,
        actualYear: 2023,
        isPlanned: FilterMap.actual.isPlanned,
      },
    } as PipeTileLayer;
  },
  target: pipeBusTileLayer,
});
