import { Box, Typography } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { TPUList } from '@features/ag-forecast/components/TPUList/TPUList';

export const FilterContainer = () => {
  return (
    <AutoFullHeight
      style={{
        paddingTop: '24px',
        minWidth: '500px',
        maxWidth: '500px',
        overflowY: 'auto',
        backgroundColor: 'white',
        height: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.1) 5px 4px 2px 0px',
        zIndex: 2,
      }}
    >
      <Typography
        variant={'subtitle2'}
        pl={5.5}
      >
        Фильтры
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          mt: 2,
          py: 1,
          pl: 5.5,
        }}
      >
        <TPUList />
      </Box>
    </AutoFullHeight>
  );
};
