import { apiClient } from '@api/client';
import {
  IHubScenarioSimulationStateChoices,
  IQuery,
  ISimulationHeatmapType,
} from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { $Heatmap, getHeatmapFx, resetHeatmapStoreFn } from './heatmap.store';

import { TPU_CLIENT_NAME } from '@constants/api';

import { $SimulationData } from '@features/tpu-calculation-scenarios-viewing/stores';

$Heatmap.reset(resetHeatmapStoreFn).on(getHeatmapFx.doneData, (_, payload) => {
  return (
    payload?.reduce((acc, val) => {
      return { ...acc, [val.index]: val.data };
    }, {}) ?? null
  );
});

const simulationStateChange = sample({
  source: $SimulationData,
  fn: $simulationData => $simulationData?.state ?? null,
});

sample({
  clock: simulationStateChange,
  source: $SimulationData,
  filter: ({ simulationId }, state) =>
    !!simulationId && state === IHubScenarioSimulationStateChoices.Completed,
  fn: simulationData => ({
    simulationId: simulationData.simulationId as string,
    offset: 0,
    limit: 24,
  }),
  target: getHeatmapFx,
});

getHeatmapFx.use(async params => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query SimulationHeatmapBySimulationId(
        $simulationId: UUID!
        $offset: Int
        $limit: Int
      ) {
        simulationHeatmapBySimulationId(
          simulationId: $simulationId
          offset: $offset
          limit: $limit
        ) {
          index
          data
        }
      }
    `,
    variables: params,
    context: { clientName: TPU_CLIENT_NAME },
  });

  return (
    response.data.simulationHeatmapBySimulationId?.filter(
      (data): data is ISimulationHeatmapType => !!data,
    ) ?? null
  );
});
