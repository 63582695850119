import { Palette } from '@mui/material';

import { EVehicleAg } from '@features/ag-forecast/constants/EVehicleAg';

export const vehicleToPalette = (vehicle: EVehicleAg, palette: Palette) => {
  switch (vehicle) {
    case EVehicleAg.auto:
      return [palette.customs.autoV2, palette.customs.autoV2];
    case EVehicleAg.avia:
      return [palette.customs.otherTransport, palette.customs.otherTransport];
    case EVehicleAg.funicular:
      return [palette.customs.otherTransport, palette.customs.otherTransport];
    case EVehicleAg.metro:
      return [palette.customs.metro, palette.customs.metro];
    case EVehicleAg.bus:
      return [palette.customs.bus, palette.customs.bus];
    case EVehicleAg.pedestrain:
      return [palette.customs.otherTransport, palette.customs.otherTransport];
    case EVehicleAg.ropeWay:
      return [palette.customs.otherTransport, palette.customs.otherTransport];
    case EVehicleAg.trolleybus:
      return [palette.customs.trolleybus, palette.customs.trolleybus];
    case EVehicleAg.suburbanRailway:
      return ['white', palette.customs.train];
    case EVehicleAg.tram:
      return [palette.customs.expressways, palette.customs.expressways];
    case EVehicleAg.waterWay:
      return [palette.customs.waterTransport, palette.customs.waterTransport];
    case EVehicleAg.monoRailWay:
      return [palette.customs.otherTransport, palette.customs.otherTransport];
    default:
      return [palette.customs.otherTransport, palette.customs.otherTransport];
  }
};
