import { apiClient } from '@api/client';
import { IMutation, IMutationDownloadFileArgs } from '@api/gql/tpu-types';
import { FetchResult, gql } from '@apollo/client';

import { TPU_CLIENT_NAME } from '@constants/api';

export const downloadFile = async (
  args: IMutationDownloadFileArgs,
): Promise<FetchResult<IMutation>> => {
  return await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation DownloadFile(
        $objectId: UUID!
        $fileType: DownloadFileTypeEnum!
      ) {
        downloadFile(objectId: $objectId, fileType: $fileType) {
          errors {
            key
            message
          }
          ok
          file
          name
          extension
        }
      }
    `,
    variables: args,
    context: { clientName: TPU_CLIENT_NAME },
  });
};
