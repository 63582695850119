import { apiClient } from '@api/client';
import { IMutation, IMutationDeleteGraphItemArgs } from '@api/gql/types';
import { gql } from '@apollo/client';

export const mutationDeleteGraph = async (
  params: IMutationDeleteGraphItemArgs,
) => {
  const result = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation DeleteGraphItemMutation($graphItemId: UUID!) {
        deleteGraphItem(graphItemId: $graphItemId) {
          ok
        }
      }
    `,
    variables: params,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.deleteGraphItem;
};
