import { sample } from 'effector';
import { FeatureLike } from 'ol/Feature';

import { SelectedFeaturesApi, SelectedFeaturesState, clickMap } from './store';

import {
  EMapFeatureLayout,
  EMapFeatureMetaKeys,
  EMapLayerMetaKeys,
  EMapMetaKeys,
} from '@constants/map';
import { TILE_SERVICE_LAYERS } from '@constants/tileServiceLayers';

import { getFeatureAtPixel } from '@utils/map/tools/getFeatureAtPixel';

const areaBorderLayoutExtractor = (feature: FeatureLike) => {
  if (feature.get(EMapLayerMetaKeys.layout))
    return feature.get(EMapLayerMetaKeys.layout);
  if (
    feature.get('layer') ===
    TILE_SERVICE_LAYERS.AREA_BORDER_PASSENGER_FLOW.LAYER
  )
    return EMapFeatureLayout.areaBorderPassengerFlow;
  return '';
};

// Обработка клика по фиче на карте
sample({
  clock: clickMap,
  fn: payload => {
    const primaryClickedFeature = getFeatureAtPixel({
      map: payload.map,
      pixel: payload.pixel,
      layoutExtractor: areaBorderLayoutExtractor,
    });

    if (!primaryClickedFeature) return null;
    const prevSelectedFeatures =
      (payload.map.get(EMapMetaKeys.selectedFeatures) as FeatureLike[]) || [];
    const selectedFeatureId =
      primaryClickedFeature.get(EMapFeatureMetaKeys.olId) ||
      primaryClickedFeature.get('id');

    if (!selectedFeatureId)
      throw new Error('Selected feature id is not defined');
    const isReselected = prevSelectedFeatures.find(
      feature =>
        (feature.get(EMapFeatureMetaKeys.olId) || feature.get('id')) ===
        selectedFeatureId,
    );
    if (isReselected) throw new Error('Reselected feature');

    const layout = areaBorderLayoutExtractor(primaryClickedFeature);
    if (!layout) throw new Error('Layout is not defined');

    return {
      id: selectedFeatureId,
      layout,
      instance: primaryClickedFeature,
    } as SelectedFeaturesState;
  },
  target: SelectedFeaturesApi.setSelectedFeatures,
});
