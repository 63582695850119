import {
  Box,
  CircularProgress,
  DialogContent,
  Typography,
} from '@mui/material';

import { CheckOkIcon } from '@components/icons';

interface ContentProps {
  name: string | null;
  isResolve: boolean;
  isLoading: boolean;
}

interface InformerProps {
  name: string | null;
  isHidden: boolean;
}

interface InformerSuccessProps {
  isHidden: boolean;
}

const Informer = ({ name, isHidden }: InformerProps) => {
  if (isHidden) return null;

  return (
    <>
      <Typography
        sx={{
          fontSize: 'inherit',
          lineHeight: 'inherit',
          marginBottom: '19px',
        }}
      >
        Вы действительно хотите удалить расчет?
      </Typography>
      <Box
        sx={{
          backgroundColor: theme => theme.palette.customs.bgHover,
          padding: '12px',
          marginLeft: '8px',
          marginBottom: '16px',
          width: 'fit-content',
          borderRadius: '4px',
        }}
      >
        <Typography
          sx={{
            fontSize: 'inherit',
            lineHeight: 'inherit',
          }}
        >
          {name}
        </Typography>
      </Box>
      <Typography sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}>
        Удаление будет безвозвратным.
      </Typography>
    </>
  );
};

const InformerSuccess = ({ isHidden }: InformerSuccessProps) => {
  if (isHidden) return null;

  return (
    <Typography sx={{ display: 'inline-flex', alignItems: 'center' }}>
      <CheckOkIcon sx={{ color: '#fff', width: '44px', height: '44px' }} />
      Расчет удален.
    </Typography>
  );
};

export const Content = ({ name, isLoading, isResolve }: ContentProps) => {
  if (!name) return null;

  return (
    <DialogContent>
      {isLoading && <CircularProgress sx={{ margin: '0 auto' }} />}
      <Informer
        name={name}
        isHidden={isLoading || isResolve}
      />
      <InformerSuccess isHidden={!isResolve} />
    </DialogContent>
  );
};
