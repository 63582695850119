export const SOC_ECO_TH_4_3 = [
  {
    id: 'transportObject',
    label: 'ТПУ или станция',
  },
  {
    id: 'project',
    label: 'Проект',
  },
  {
    id: 'simultaneousMaximumPassTraffic',
    label: 'Единовременный максимальный пассажиропоток, чел.',
  },
  {
    id: 'numberDigitalTransportSystemsRequired',
    label: 'Число необходимых элементов цифровых транспортных систем',
  },
];
