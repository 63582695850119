import { IFloorFileInfoType } from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

interface FloorsDataType {
  floorsInfo: IFloorFileInfoType[];
  selectedFloor: string;
  backgroundUrl: string;
}

export const $Floors = createStore<FloorsDataType>({
  floorsInfo: [],
  selectedFloor: '',
  backgroundUrl: '',
});

export const selectFloorFn = createEvent<string>();

export const getFloorsInfoFx = createEffect<
  string,
  IFloorFileInfoType[] | null
>();

export const resetFloorFn = createEvent();

export const setBGUrlFn = createEvent<string>();
