import { Event, Subscription } from 'effector';
import { Map as MapOl } from 'ol';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

import { EMapFeatureMetaKeys, EMapLayerMetaKeys } from '@constants/map';

import { TileStyleProps } from '@utils/map/styles/types';
import {
  ETileLayerSources,
  getTileLayerSource,
} from '@utils/map/tools/getTileLayerSource';
import {
  ETileLayerStyles,
  getTileLayerStyle,
  getTileLayerStyleProps,
} from '@utils/map/tools/getTileLayerStyle';

export enum EPipeTileLayer {
  changeSource,
  setVisible,
  setStyle,
  changeTheme,
  changeThemeProps,
}

type themeProps = Omit<TileStyleProps, 'feature' | 'map' | 'resolution'>;

export type PipeTileLayer =
  | {
      action: EPipeTileLayer.changeSource;
      payload: VectorTileSource;
    }
  | {
      action: EPipeTileLayer.setVisible;
      payload: boolean;
    }
  | {
      action: EPipeTileLayer.setStyle;
      payload: {
        selectedFeatureIds?: string[];
        featureIdMetaKey?: string;
      };
    }
  | {
      action: EPipeTileLayer.changeTheme;
      payload: {
        theme: ETileLayerStyles;
        props?: themeProps;
      };
    }
  | {
      action: EPipeTileLayer.changeThemeProps;
      payload: themeProps;
    }
  | null;

export type useInitTileLayerProps = {
  mapRef: MutableRefObject<MapOl | null>;
  events: Event<PipeTileLayer>[];
  config: {
    readonly zIndex: number;
    // readonly layout: EMapFeatureLayout;
    readonly visible?: boolean;
    readonly source?: ETileLayerSources | VectorTileSource;
    readonly themeProps?: themeProps;
  } & getTileLayerStyleProps;
};

export const useInitTileLayer = (props: useInitTileLayerProps) => {
  const { mapRef, events, config } = props;

  const layerRef = useRef<VectorTileLayer>();
  const themeFnRef = useRef(getTileLayerStyle(config));
  const lastThemeProps = useRef<themeProps>({});
  const subscribersRef = useRef<Subscription[]>([]);

  const listener = useCallback((args: PipeTileLayer) => {
    if (!args) return;
    const { action, payload } = args;

    // Подмена источника тайлов
    if (action === EPipeTileLayer.changeSource) {
      layerRef.current?.setSource(payload);
    }
    // Изменение видимости слоя
    else if (action === EPipeTileLayer.setVisible) {
      layerRef.current?.setVisible(payload);
    }
    // Применение стилей к отдельным фичам вы установленной теме
    else if (action === EPipeTileLayer.setStyle) {
      layerRef.current?.setStyle((feature, resolution) =>
        themeFnRef.current({
          resolution,
          feature,
          map: mapRef.current!,
          isActive: payload.selectedFeatureIds?.includes(
            feature.get(payload.featureIdMetaKey || EMapFeatureMetaKeys.olId),
          ),
          ...lastThemeProps.current,
        }),
      );
    }
    // Изменение темы стилизации
    else if (action === EPipeTileLayer.changeTheme) {
      themeFnRef.current = getTileLayerStyle({
        layout: config.layout,
        theme: payload.theme,
      });
      lastThemeProps.current = payload.props || {};
      layerRef.current?.setStyle((feature, resolution) =>
        themeFnRef.current({
          resolution,
          feature,
          map: mapRef.current!,
          ...(payload.props || lastThemeProps.current),
        }),
      );
    }
    // Изменение пропсов темы
    else if (action === EPipeTileLayer.changeThemeProps) {
      lastThemeProps.current = payload || {};
      layerRef.current?.setStyle((feature, resolution) =>
        themeFnRef.current({
          resolution,
          feature,
          map: mapRef.current!,
          ...payload,
        }),
      );
    }
  }, []);

  /** Инициализация слоя **/
  useEffect(() => {
    if (!mapRef.current) throw new Error('Map is not initialized');

    layerRef.current = new VectorTileLayer({
      declutter: false,
      zIndex: config.zIndex,
      source: config.source
        ? config.source instanceof VectorTileSource
          ? config.source
          : getTileLayerSource(config.source)
        : undefined,
      renderMode: localStorage.getItem('mapDev') ? 'vector' : 'hybrid',
      visible: config.visible === undefined ? true : config.visible,
      style: (feature, resolution) =>
        themeFnRef.current({
          resolution,
          feature,
          map: mapRef.current!,
          ...config.themeProps,
        }),
    });

    lastThemeProps.current = config.themeProps || {};

    layerRef.current.set(EMapLayerMetaKeys.layout, config.layout);

    subscribersRef.current = events.map(event => event.watch(listener));

    mapRef.current!.addLayer(layerRef.current);

    return () => {
      subscribersRef.current.forEach(subscriber => subscriber.unsubscribe());
    };
  }, []);

  return layerRef;
};
