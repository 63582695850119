import { useUnit } from 'effector-react';

import { Box, Typography } from '@mui/material';

import { $Floors } from '@features/tpu-simulation-model/store';
import { $ListOfRulers } from '@features/tpu-simulation-model/store/ruler/listOfRulers.store';

interface AttributeProps {
  width: number;
  height: number;
  length?: number;
}
export function ObjectSize({ width, height, length }: AttributeProps) {
  const $listOfRulers = useUnit($ListOfRulers) ?? {};
  const $floors = useUnit($Floors);
  const ruler = $listOfRulers[$floors.selectedFloor];
  const convertedWidth = ruler
    ? Math.round((width / ruler.px) * ruler.meters)
    : width;
  const convertedHeight = ruler
    ? Math.round((height / ruler.px) * ruler.meters)
    : height;
  const convertedLenght = ruler
    ? Math.round(((length || 0) / ruler.px) * ruler.meters)
    : length;

  return (
    <Box>
      <Typography>
        Размеры,
        <Typography
          variant={'body1'}
          component={'span'}
          sx={{
            color: theme => theme.palette.text.secondary,
            display: 'inline-block',
            ml: '4px',
          }}
        >
          {ruler ? (
            <>
              м
              <Typography
                variant={'body1'}
                component={'sup'}
                sx={{
                  fontSize: theme => theme.typography.pxToRem(10),
                }}
              >
                2
              </Typography>
            </>
          ) : (
            'px'
          )}
        </Typography>
      </Typography>
      <Typography
        sx={{
          color: props => props.palette.text.secondary,
          fontSize: theme => theme.typography.pxToRem(12),
          mt: '4px',
        }}
      >
        Д: {convertedWidth} / Ш: {convertedHeight}
        {length && `/ В: ${convertedLenght}`}
      </Typography>
    </Box>
  );
}
