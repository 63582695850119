export const SOC_ECO_TH_2_1 = [
  {
    id: 'transportArea',
    label: 'Транспортный район',
  },
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'populationTransportArea',
    label: 'Численность населения транспортного района, чел. ',
  },
  {
    id: 'shoppingCenterArea',
    label: 'Площадь ТЦ',
  },
  {
    id: 'ShortageSCSupermarketsTransportArea',
    label: 'Дефицит ТЦ и супермаркетов в транспортном районе',
  },
];
