import { useUnit } from 'effector-react';

import { Actions } from './actions';
import { Content } from './content';

import { Dialog, DialogTitle, IconButton } from '@mui/material';

import { useContextPassengerTypesHandlers } from '@features/tpu-passenger-types/hooks';
import { $DeletePassengerType } from '@features/tpu-passenger-types/stores/delete-passenger-type/delete-passenger-type.store';

export const ModalDeletePassengerType = () => {
  const { isResolve, isLoading, name, isOpen } = useUnit($DeletePassengerType);
  const { closeModalDeletePassengerType, removePassengerType } =
    useContextPassengerTypesHandlers();

  const handleClose = () => {
    closeModalDeletePassengerType();
  };
  const handleDelete = () => {
    removePassengerType();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle>Удалить тип пассажира</DialogTitle>
      <IconButton
        onClick={handleClose}
        color={'primary'}
        sx={{ position: 'absolute', top: 24, right: 24, padding: 0 }}
      />
      <Content
        name={name}
        isResolve={isResolve}
        isLoading={isLoading}
      />
      <Actions
        onClose={handleClose}
        onDelete={handleDelete}
        isResolve={isResolve}
      />
    </Dialog>
  );
};
