import { apiClient } from '@api/client';
import {
  IQuery,
  IQueryGetProjectInfraPointByNameArgs,
} from '@api/gql/ag-types';
import { gql } from '@apollo/client';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

let querySearchInfraCancel: AbortController | null = null;

export const querySearchInfra = async (
  params: IQueryGetProjectInfraPointByNameArgs,
) => {
  if (querySearchInfraCancel) {
    querySearchInfraCancel.abort();
    querySearchInfraCancel = new AbortController();
  } else querySearchInfraCancel = new AbortController();

  const result = await apiClient.query<
    Pick<IQuery, 'getProjectInfraPointByName'>,
    IQueryGetProjectInfraPointByNameArgs
  >({
    query: gql`
      query QueryGetProjectInfraPointByName(
        $contains: String
        $projectId: String!
      ) {
        getProjectInfraPointByName(contains: $contains, projectId: $projectId) {
          name
          type
          id
          point {
            lon
            lat
          }
        }
      }
    `,
    variables: {
      contains: params.contains,
      projectId: params.projectId,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
      fetchOptions: {
        signal: querySearchInfraCancel.signal,
      },
    },
  });
  querySearchInfraCancel = null;
  return result.data.getProjectInfraPointByName || [];
};
