import { apiClient } from '@api/client';
import { IQuery, IQueryFindRegionArgs } from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';
import { toLonLat } from 'ol/proj';

import {
  $FilterMap,
  EFilterMapLayout,
  FilterMapApi,
  FilterMapStore,
  RegionOptionType,
  directionPoints,
  fetchTransportDistrictByPointFx,
} from '@features/pt-forecast-new/stores/filters/store';
import { clickMap } from '@features/pt-forecast-new/stores/map';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';

// Если выбрано направление установки поинта, установить флаг для UI
sample({
  clock: FilterMapApi.setDirectionPoint,
  fn: payload => payload !== null,
  target: UIApi.setIsSetPoint,
});

// Если режим установки поинта, перехватить клик карты, и запросить транспортный регион по координатам
sample({
  clock: clickMap,
  source: { UI: $UI },
  filter: ({ UI }) => UI.isSetPoint,
  fn: (_, event) => {
    const [lon, lat] = toLonLat(
      event.target.getCoordinateFromPixel(event.pixel),
    );
    return {
      lon: lat,
      lat: lon,
    } as IQueryFindRegionArgs;
  },
  target: fetchTransportDistrictByPointFx,
});

fetchTransportDistrictByPointFx.use(async params => {
  const response = await apiClient.query<
    Pick<IQuery, 'findRegion'>,
    IQueryFindRegionArgs
  >({
    query: gql`
      query QueryFindRegion($lat: Float!, $lon: Float!) {
        findRegion(lat: $lat, lon: $lon) {
          transportRegion {
            id
            name
          }
        }
      }
    `,
    variables: {
      lat: params.lat,
      lon: params.lon,
    },
  });
  return response.data.findRegion;
});

// TODO: Заменить на сплиттер
// Установить выбранную опцию региона отправления
sample({
  clock: fetchTransportDistrictByPointFx.done,
  source: { FilterMap: $FilterMap },
  filter: ({ FilterMap }) =>
    FilterMap.directionPointSelected === directionPoints.from,
  fn: (_, request) => {
    return {
      name: request.result?.transportRegion?.name || '',
      id: request.result?.transportRegion?.id || '',
    } as RegionOptionType;
  },
  target: FilterMapApi.setRegionFrom,
});

// TODO: Заменить на сплиттер
// Установить выбранную опцию региона прибытия
sample({
  clock: fetchTransportDistrictByPointFx.done,
  source: { FilterMap: $FilterMap },
  filter: ({ FilterMap }) =>
    FilterMap.directionPointSelected === directionPoints.to,
  fn: (_, request) => {
    return {
      name: request.result?.transportRegion?.name || '',
      id: request.result?.transportRegion?.id || '',
    } as RegionOptionType;
  },
  target: FilterMapApi.setRegionTo,
});

// Сбросить режим установки поинта
sample({
  clock: fetchTransportDistrictByPointFx.done,
  fn: () => null,
  target: FilterMapApi.setDirectionPoint,
});

// TODO: Временное решение. при переходе на слой инфры, проставляются все подкатегории
sample({
  clock: FilterMapApi.setLayout,
  filter: payload => payload === EFilterMapLayout.Infrastructure,
  fn: () => {
    return {
      auto: true,
      bus: true,
      nightTrain: true,
      expressTrain: true,
      dayTrain: true,
      suburbanTrain: true,
    } as Partial<FilterMapStore>;
  },
  target: FilterMapApi.setFilters,
});

// При включении режима редактирования инфры, сбросить корреспонденцию
sample({
  clock: UIApi.setEditMode,
  filter: payload => payload,
  target: FilterMapApi.clearRegions,
});
