export const tickFormatter = (value: number, unit: string) => {
  switch (unit) {
    case 'thousand':
      return `${value / 1e3}`;
    case 'million':
      return `${value / 1e6}`;
    case 'billion':
      return `${value / 1e9}`;
    default:
      return String(value);
  }
};
