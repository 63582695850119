import { isNonRenderableObject } from './assistantFunctions';
import { getMoreColors } from './getMoreColors';

import { colors } from '../constants/player';
import {
  DataForCommercialObjects,
  TreemapItem,
} from '../stores/chartsCommercialObjects/chartsCommercialObjects.store';

const createColors = (items: DataForCommercialObjects[]): void => {
  if (items?.length > colors?.length)
    colors.push(...getMoreColors(items.length, colors.length));
};

export const generateCommercialSquareItems = (
  items: DataForCommercialObjects[],
): TreemapItem[] => {
  createColors(items);

  return items?.map((item, i) => ({
    name: item.name,
    size: item.square,
    unit: 'кв. м',
    color: colors[i],
  }));
};

export const generateCommercialRevenueItems = (
  items: DataForCommercialObjects[],
): TreemapItem[] => {
  return items?.map((item, i) => ({
    name: item.name,
    size: isNonRenderableObject(item) ? 0 : item.cumulativeRevenue,
    unit: 'тыс. руб',
    color: colors[i],
  }));
};
