import {
  IAgentShareInfoCategoryEnum,
  IAgentShareInfoColorEnum,
  IAgentShareInfoType,
  ICreateAgentShareInfoInput,
  IUpdateAgentShareInfoInput,
} from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

import { IForm } from '@features/tpu-passenger-types/types';

export const $Form = createStore<IForm>({
  scenarioId: '',
  category: IAgentShareInfoCategoryEnum.Visitors,
  name: '',
  color: IAgentShareInfoColorEnum.Green,
  maximumTravelSpeed: 1.2,
  averageTimeBeforeTrain: 0,
  sharePassengerWithTickets: 0,
  shareVisitorBuyingTickets: 0,
  ticketOfficeProbability: 0,
  tpmProbability: 0,
  informationTableProbability: 20,
  foodProbability: 0,
  storeProbability: 0,
  bankProbability: 0,
  toiletProbability: 0,
  otherProbability: 0,
});

export const $IsOpenTypeForm = createStore<boolean>(false);
export const $TriggerSaveForm = createStore<boolean>(false);
export const setIsOpenTypeFormFn = createEvent<boolean>();
export const setIsTriggerSaveFormFn = createEvent<boolean>();
export const createPassengerTypeFn = createEvent<IForm>();
export const openCategoryFn = createEvent<boolean>();
export const openSharesFn = createEvent<boolean>();
export const setFormStateFn = createEvent<IForm>();
export const openTypeForCreateFn = createEvent();
export const openTypeForEditFn = createEvent<string>();
export const savePassengerTypeFn = createEvent<IForm>();
export const resetFormStateFn = createEvent();

export const createTypeFx = createEffect<
  ICreateAgentShareInfoInput,
  string | undefined
>();

export const updateTypeFx = createEffect<IUpdateAgentShareInfoInput, void>();

export const getTypeByIdFx = createEffect<string, IAgentShareInfoType | null>();
