import { IHighwayHighwayTypeChoices } from '@api/gql/types';

type getTractionTypeValueArgs = IHighwayHighwayTypeChoices | undefined;

type getTractionTypeValueReturn = string | void;

export const getHighwayTypeValue = (
  value: getTractionTypeValueArgs,
): getTractionTypeValueReturn => {
  switch (value) {
    case IHighwayHighwayTypeChoices.A_1:
      return 'региональные/местные';

    case IHighwayHighwayTypeChoices.A_2:
      return 'зимник';

    case IHighwayHighwayTypeChoices.A_3:
      return 'федеральные';

    case IHighwayHighwayTypeChoices.A_4:
      return 'зарубежные';

    case IHighwayHighwayTypeChoices.A_5:
      return 'переправа';
  }
};
