import { createEvent, createStore } from 'effector';

export enum EStateModalShareCalculation {
  share,
  success,
}

interface ModalShareCalculation {
  state: EStateModalShareCalculation;
}

const defaultState = {
  state: EStateModalShareCalculation.share,
};

export const $ModalShareCalculation =
  createStore<ModalShareCalculation>(defaultState);

export const setState = createEvent<EStateModalShareCalculation>();
export const resetModalShareCalculation = createEvent();

$ModalShareCalculation
  .on(setState, (state, payload) => {
    return { ...state, state: payload };
  })
  .reset(resetModalShareCalculation);
