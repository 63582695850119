import { getLineWidth } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { Stroke, Style } from 'ol/style';

import { TileStyleProps } from '@utils/map/styles/types';
import { createActiveFeatureBorder } from '@utils/map/tools/createActiveFeatureBorder';
import { getLineDash } from '@utils/map/tools/getLineDash';

const lineWidthMin = 1;
const lineWidthMax = 5;

export const plannedRailwayTileStyleDashed = (props: TileStyleProps) => {
  const { map, resolution, width, isActive } = props;

  const zoom = map.getView().getZoomForResolution(resolution) || 10;
  const styles = isActive ? [createActiveFeatureBorder(width || 5)] : [];

  styles.push(
    new Style({
      stroke: new Stroke({
        color: '#5A5A5A99',
        width:
          width ||
          getLineWidth(zoom, [
            0,
            lineWidthMin,
            6,
            lineWidthMin,
            6.00000001,
            lineWidthMax,
          ]) ||
          1,
        lineCap: 'square',
      }),
    }),
  );

  if (zoom > 6) {
    styles.push(
      new Style({
        stroke: new Stroke({
          lineCap: 'square',
          color: '#FFFFFF99',
          width: getLineWidth(zoom, [0, 1, 6, 2, 10, 2]) || 1,
          lineDashOffset: 4,
          lineDash: getLineDash(zoom, [
            0,
            [1, 1],
            6,
            [8, 6],
            6.5,
            [8, 6],
            7,
            [10, 10],
            8,
            [15, 10],
            10,
            [20, 10],
          ]),
        }),
      }),
    );
  }

  return styles;
};
