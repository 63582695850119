export const SOC_ECO_TH_5_8 = [
  {
    id: 'nameTransportInitiative',
    label: 'Название транспортной инициативы',
  },
  // {
  //   id: 'planningDocument',
  //   label: 'Документ планирования, в который внесено мероприятие',
  // },
  // {
  //   id: 'startYearImplementation',
  //   label: 'Год начала реализации',
  // },
  {
    id: 'yearCompletion',
    label: 'Год окончания строительства',
  },
  {
    id: 'price',
    label: 'Финансирование, млн руб.',
  },
];
