import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const PeopleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        d="M7.5 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM2 10.768C2 9.792 2.791 9 3.768 9h4.767A3.981 3.981 0 0 0 8 11a3.99 3.99 0 0 0 1.354 3H8.268c-1.187 0-2.2.747-2.593 1.796-1.498-.366-2.427-1.183-2.974-2.093C2 12.536 2 11.336 2 11.127v-.36ZM16 11a3.98 3.98 0 0 0-.535-2h4.767C21.21 9 22 9.792 22 10.768v.36c0 .208 0 1.408-.7 2.575-.548.91-1.477 1.727-2.975 2.094A2.769 2.769 0 0 0 15.732 14h-1.086A3.99 3.99 0 0 0 16 11Zm3.5-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-13 11.768c0-.976.791-1.768 1.768-1.768h7.464c.977 0 1.768.792 1.768 1.768v.36c0 .208 0 1.408-.7 2.575C16.057 20.937 14.613 22 12 22s-4.058-1.063-4.8-2.297c-.7-1.167-.7-2.367-.7-2.576v-.36ZM12 14a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        fill="#425A69"
      />
    </SvgIcon>
  );
};
