import { useUnit } from 'effector-react';
import { useCallback, useEffect } from 'react';

import { OneStep } from './steps/one';
import { TwoStep } from './steps/two';
import {
  $ModalTransport,
  EStepModalTransport,
  resetModalTransport,
  setErrorChecking,
  setTransportObjects,
} from './store';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { DialogCloseButton } from '@components/DialogCloseButton';

import { ScenarioType } from '@features/pt-constructor/constants/socialScenarioType';
import { $Calculations } from '@features/pt-constructor/store/lists/scenarios/store';
import { SelectedCalculationScenarioApi } from '@features/pt-constructor/store/selected/store';

type ModalTransportProps = {
  onClose(): void;
};

export const ModalTransport = ({ onClose }: ModalTransportProps) => {
  const $modalTransport = useUnit($ModalTransport);
  const $calculations = useUnit($Calculations);
  const { createTransportScenario } = useUnit(SelectedCalculationScenarioApi);

  const onNextHandler = useCallback(() => {
    // проверка ошибок в имени и наличии выбранного базового сценария, далее триггер на переход к следующему шагу, при отсутствии ошибок
    setErrorChecking();
  }, []);

  const handleSubmit = useCallback(() => {
    createTransportScenario({
      id: undefined,
      name: $modalTransport.name,
      transportProjects: $modalTransport.transportObjects,
      type: $modalTransport.option?.type as ScenarioType,
    });

    onClose();
  }, [
    $modalTransport.name,
    $modalTransport.option?.type,
    $modalTransport.transportObjects,
    createTransportScenario,
    onClose,
  ]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    return resetModalTransport;
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      open={true}
    >
      <DialogTitle
        sx={{
          position: 'relative',
          fontSize: theme => theme.typography.pxToRem(16),
        }}
      >
        Пользовательский транспортный сценарий
      </DialogTitle>

      <DialogCloseButton onClick={handleClose} />

      <DialogContent>
        {$modalTransport.step === EStepModalTransport.one && <OneStep />}

        {$modalTransport.step === EStepModalTransport.two && (
          <TwoStep
            transportName={$modalTransport.name}
            transportProjects={$calculations.transportProjects}
            onChangeTransportProject={setTransportObjects}
            optionTransportProjects={
              $calculations.transportScenarios.find(
                e => e.id === $modalTransport.option?.value,
              )?.transportProjects
            }
          />
        )}
      </DialogContent>

      <DialogActions>
        {$modalTransport.step === EStepModalTransport.one && (
          <Button onClick={onNextHandler}>Далее</Button>
        )}

        {$modalTransport.step === EStepModalTransport.two && (
          <Button onClick={handleSubmit}>Сохранить</Button>
        )}

        <Button
          variant={'outlined'}
          onClick={handleClose}
        >
          Отменить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
