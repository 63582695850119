import { SvgIcon, SvgIconProps } from '@mui/material';

export const Speed = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M7.64637 6.30274C7.75225 6.30274 7.85933 6.27136 7.95284 6.20621C8.78388 5.62735 9.70963 5.24396 10.7045 5.06659C10.9965 5.01471 11.1913 4.73538 11.1391 4.44309C11.0872 4.15079 10.8082 3.95653 10.5159 4.00872C9.36696 4.21353 8.29823 4.65635 7.33869 5.3248C7.09526 5.49432 7.03523 5.82915 7.20476 6.07288C7.30943 6.2225 7.47654 6.30274 7.64637 6.30274Z"
          fill="#273B48"
        />
        <path
          d="M4.44313 11.1396C4.47511 11.1453 4.50678 11.1481 4.53815 11.1481C4.79365 11.1481 5.02018 10.9653 5.06634 10.7052C5.24401 9.70981 5.6274 8.78436 6.20626 7.95363C6.37608 7.7096 6.31636 7.37477 6.07263 7.20524C5.8289 7.03511 5.49437 7.09544 5.32455 7.33887C4.6564 8.29781 4.21358 9.36684 4.00846 10.5161C3.95628 10.8084 4.15084 11.0874 4.44313 11.1396Z"
          fill="#273B48"
        />
        <path
          d="M10.7045 18.8138C9.70971 18.6364 8.78396 18.2527 7.95292 17.6742C7.70949 17.504 7.37467 17.5644 7.20484 17.8078C7.03531 18.0515 7.09504 18.3864 7.33877 18.5559C8.29801 19.2243 9.36704 19.6669 10.516 19.872C10.548 19.8777 10.5797 19.8804 10.611 19.8804C10.8665 19.8804 11.0931 19.6976 11.1392 19.4376C11.1914 19.145 10.9968 18.866 10.7045 18.8138Z"
          fill="#273B48"
        />
        <path
          d="M5.06633 13.1758C5.01414 12.8835 4.73482 12.6889 4.44313 12.7414C4.15113 12.7933 3.95627 13.0726 4.00845 13.3649C4.21327 14.5142 4.65609 15.5832 5.32454 16.5421C5.42921 16.6921 5.59602 16.7723 5.76584 16.7723C5.87172 16.7723 5.97881 16.7409 6.07262 16.6758C6.31605 16.5062 6.37577 16.1711 6.20625 15.9277C5.62739 15.0967 5.24369 14.1712 5.06633 13.1758Z"
          fill="#273B48"
        />
        <path
          d="M13.3644 4.00871C13.0715 3.95562 12.7931 4.15078 12.7409 4.44308C12.6887 4.73538 12.8833 5.0141 13.1753 5.06658C16.5072 5.66083 18.9252 8.5518 18.9252 11.9402C18.9252 15.3286 16.5072 18.2196 13.1756 18.8138C12.8836 18.866 12.689 19.145 12.7412 19.4373C12.7876 19.6976 13.0142 19.8801 13.2694 19.8801C13.3007 19.8801 13.3324 19.8774 13.3644 19.8717C17.2095 19.1857 20 15.8501 20 11.9402C20 8.03026 17.2095 4.69465 13.3644 4.00871Z"
          fill="#273B48"
        />
        <path
          d="M14.5666 11.9402C14.5666 11.6002 14.3907 11.2959 14.0963 11.1257L11.2618 9.48902C10.9677 9.31889 10.6159 9.31919 10.3215 9.48932C10.0274 9.65945 9.85156 9.96381 9.85156 10.3035V13.5766C9.85156 13.9163 10.0274 14.2206 10.3215 14.3908C10.4687 14.4758 10.6304 14.5184 10.7918 14.5184C10.9532 14.5184 11.1149 14.4758 11.2621 14.3911L14.0966 12.7546C14.3907 12.5845 14.5666 12.2801 14.5666 11.9402ZM10.9263 13.3441V10.5363L13.3576 11.9402L10.9263 13.3441Z"
          fill="#273B48"
        />
      </svg>
    </SvgIcon>
  );
};
