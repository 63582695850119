import { ITransportScenarioInstanceType, Maybe } from '@api/gql/types';
import { getScenarioType } from '@features/pt-constructor/constants/socialScenarioType';
import {
  TransportProject,
  TransportScenarioListItem,
} from '@features/pt-constructor/store';

export const transportScenariosReformatter = (
  data: Maybe<Maybe<ITransportScenarioInstanceType>[]>,
): TransportScenarioListItem[] =>
  data
    ? data.map(scenario => ({
        id: scenario?.id || '',
        name: scenario?.name || '',
        type: getScenarioType(scenario?.scenarioType || 'A_4'),
        transportProjects: scenario?.transportProjects?.map(scenario => ({
          name: scenario?.transportProject?.name || '',
          year: scenario?.year || '',
          id: scenario?.transportProject?.id || '',
        })) as TransportProject[],
      }))
    : [];
