import { useUnit } from 'effector-react/effector-react.umd';

import { Box, SxProps, Theme } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { TransportRegionInspector } from '../../components/Inspectors/TransportRegionInspector';
import { $UI, UIApi } from '../../stores/ui';

const InspectorContainerMuiStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '0',
  right: '0',
  zIndex: '1',
  background: theme => theme.palette.background.default,
  '& > *': {
    borderTop: '1px solid #EBECEF',
    padding: '32px 12px 32px 44px',
    boxShadow: 'rgba(0, 0, 0, 0.1) -5px 4px 2px 0px',
    width: '400px',
    maxWidth: '400px',
    overflowY: 'auto',
  },
};

export const InspectorContainer = () => {
  const { inspector } = useUnit($UI);
  const { toggleInspector } = useUnit(UIApi);

  return (
    inspector && (
      <Box sx={InspectorContainerMuiStyle}>
        <AutoFullHeight>
          <TransportRegionInspector onClose={toggleInspector} />
        </AutoFullHeight>
      </Box>
    )
  );
};
