import { Toolbar } from '@features/tpu-calculation-scenarios-viewing/components/Toolbar';
import { PlanContainer } from '@features/tpu-calculation-scenarios-viewing/containers/PlanContainer/PlanContainer';
import {
  $ComponentState,
  EViewerQueryParams,
} from '@features/tpu-calculation-scenarios-viewing/stores';
import { useUnit } from 'effector-react';

import { Box } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { ChartsPageContainer } from './containers/ChartsPageContainer/ChartsPageContainer';
import { CommercialAreasContainer } from './containers/CommercialAreasContainer/CommercialAreasContainer';
import { CommercialAreasPageContainer } from './containers/CommercialAreasPageContainer/CommercialAreasPageContainer';
import { ScenariosChartsContainer } from './containers/ScenariosChartsContainer/ScenariosChartsContainer';

export const Component = () => {
  const $componentState = useUnit($ComponentState);

  return (
    <Box sx={{ padding: 0 }}>
      <Toolbar />

      {$componentState === null && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '474px 1fr 474px',
            height: 'calc(100vh - 160px)',
          }}
        >
          <ScenariosChartsContainer />
          <PlanContainer />
          <CommercialAreasContainer />
        </Box>
      )}

      {$componentState === EViewerQueryParams.passengerFlowCharts && (
        <AutoFullHeight
          mb={24}
          style={{
            width: '100%',
            display: 'flex',
            padding: '24px',
            gap: '24px',
            boxSizing: 'border-box',
          }}
        >
          <ChartsPageContainer />
        </AutoFullHeight>
      )}

      {$componentState === EViewerQueryParams.commercialAreas && (
        <AutoFullHeight
          mb={24}
          style={{
            width: '100%',
            display: 'flex',
            padding: '24px',
            gap: '24px',
            boxSizing: 'border-box',
          }}
        >
          <CommercialAreasPageContainer />
        </AutoFullHeight>
      )}
    </Box>
  );
};
