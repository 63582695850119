import { EVehicleAg } from '@features/ag-forecast/constants/EVehicleAg';

export const VEHICLE_LABELS = {
  [EVehicleAg.auto]: 'Автомобильный транспорт',
  [EVehicleAg.trolleybus]: 'Троллейбус',
  [EVehicleAg.tram]: 'Трамвай',
  [EVehicleAg.metro]: 'Метрополитен',
  [EVehicleAg.waterWay]: 'Водный транспорт',
  [EVehicleAg.suburbanRailway]: 'Железнодорожный транспорт',
  [EVehicleAg.bus]: 'Автобусы',
  [EVehicleAg.avia]: 'Авиа',
  [EVehicleAg.pedestrain]: 'Пешеход',
  [EVehicleAg.monoRailWay]: 'Монорельс',
  [EVehicleAg.funicular]: 'Фуникулер',
  [EVehicleAg.ropeWay]: 'Канатные дороги',
} as const;
