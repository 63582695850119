import {
  IAgentShareInfoCategoryEnum,
  IAgentShareInfoColorEnum,
  IAgentShareInfoType,
} from '@api/gql/tpu-types';
import { IForm } from '@features/tpu-passenger-types/types';
import { createEffect, createEvent, createStore } from 'effector';

export const $Form = createStore<IForm>({
  scenarioId: '',
  category: IAgentShareInfoCategoryEnum.Visitors,
  name: '',
  color: IAgentShareInfoColorEnum.Green,
  maximumTravelSpeed: 0,
  averageTimeBeforeTrain: 0,
  sharePassengerWithTickets: 0,
  shareVisitorBuyingTickets: 0,
  ticketOfficeProbability: 0,
  tpmProbability: 0,
  foodProbability: 0,
  storeProbability: 0,
  bankProbability: 0,
  informationTableProbability: 0,
  toiletProbability: 0,
  otherProbability: 0,
});
export const $ScenarioId = createStore<string>('');

export const $Registry = createStore<
  Record<IAgentShareInfoCategoryEnum, IAgentShareInfoType[]>
>({
  [IAgentShareInfoCategoryEnum.DisembarkingCommuter]: [],
  [IAgentShareInfoCategoryEnum.DisembarkingLongDistance]: [],
  [IAgentShareInfoCategoryEnum.EmbarkingCommuter]: [],
  [IAgentShareInfoCategoryEnum.EmbarkingLongDistance]: [],
  [IAgentShareInfoCategoryEnum.Visitors]: [],
});

export const getAllAgentShareInfoCategoryFx = createEffect<
  string,
  IAgentShareInfoType[]
>();

export const setScenarioIdFn = createEvent();
