import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { EMapFeatureLayout } from '@constants/map';

import { GraphPassFlowInspector } from '@features/ag-forecast/components/Inspector/GraphPassFlowInspector/GraphPassFlowInspector';
import { GraphInspector } from '@features/ag-forecast/components/Inspector/GraphsInspector/GraphsInspector';
import { RailwayStationsInspector } from '@features/ag-forecast/components/Inspector/RailwayStationsInspector';
import { TpuGraphInspector } from '@features/ag-forecast/components/Inspector/TpuInspector/TpuGraphInspector';
import { TpuInspector } from '@features/ag-forecast/components/Inspector/TpuInspector/TpuInspector';
import {
  $Inspector,
  EInspectorType,
} from '@features/ag-forecast/stores/inspector';
import { $InfrastructureInspector } from '@features/ag-forecast/stores/inspector/infrastructure';
import { $PassFlowInspector } from '@features/ag-forecast/stores/inspector/passFlow';
import { $UI, UIApi } from '@features/ag-forecast/stores/ui';

const InspectorContainerMuiStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '0',
  right: '0',
  zIndex: '2',
  background: theme => theme.palette.background.default,
  '& > *': {
    borderTop: '1px solid #EBECEF',
    padding: '16px 32px 16px 32px',
    boxShadow: 'rgba(0, 0, 0, 0.1) -5px 4px 2px 0px',
    width: '500px',
    maxWidth: '500px',
    overflowY: 'auto',
  },
};

type InspectorType = {
  onChartClick: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InspectorContainer = (props: InspectorType) => {
  const { onChartClick } = props;
  const { inspector } = useUnit($UI);
  const { setInspector } = useUnit(UIApi);
  const { layout, inspectorType } = useUnit($Inspector);
  const { railwayStation, graphs } = useUnit($InfrastructureInspector);
  const { graphs: graphPassFlow } = useUnit($PassFlowInspector);

  const resetInspectorLayout = useCallback(() => {
    setInspector(false);
  }, [setInspector]);

  const InspectorLayout = useMemo(() => {
    const infrastructureSelector = () => {
      switch (layout) {
        case EMapFeatureLayout.graphTrolleybus:
        case EMapFeatureLayout.graphTram:
        case EMapFeatureLayout.graphPedestrain:
        case EMapFeatureLayout.graphMonoRailWay:
        case EMapFeatureLayout.graphMetro:
        case EMapFeatureLayout.graphFunicular:
        case EMapFeatureLayout.graphBus:
        case EMapFeatureLayout.graphAvia:
        case EMapFeatureLayout.graphSuburbanRailway:
        case EMapFeatureLayout.graphAuto:
        case EMapFeatureLayout.graphRopeWay:
        case EMapFeatureLayout.graphWaterWay:
          return (
            <GraphInspector
              onClose={resetInspectorLayout}
              fields={graphs}
            />
          );
        case EMapFeatureLayout.agRailwayStations:
          return (
            <RailwayStationsInspector
              onClose={resetInspectorLayout}
              fields={railwayStation}
            />
          );
      }
    };

    const passFlowSelector = () => {
      switch (layout) {
        case EMapFeatureLayout.graphTrolleybus:
        case EMapFeatureLayout.graphTram:
        case EMapFeatureLayout.graphPedestrain:
        case EMapFeatureLayout.graphMonoRailWay:
        case EMapFeatureLayout.graphMetro:
        case EMapFeatureLayout.graphFunicular:
        case EMapFeatureLayout.graphBus:
        case EMapFeatureLayout.graphAvia:
        case EMapFeatureLayout.graphSuburbanRailway:
        case EMapFeatureLayout.graphAuto:
        case EMapFeatureLayout.graphRopeWay:
        case EMapFeatureLayout.graphWaterWay:
          return (
            <GraphPassFlowInspector
              onClose={resetInspectorLayout}
              fields={graphPassFlow}
            />
          );
      }
    };

    switch (inspectorType) {
      case EInspectorType.passFlow:
        return passFlowSelector();
      case EInspectorType.infrastructure:
      case EInspectorType.graphs:
        return infrastructureSelector();
      case EInspectorType.tpu:
        return <TpuInspector />;
      case EInspectorType.tpuGraph:
        return <TpuGraphInspector />;
      default:
        return null;
      // return <ForecastInspector onChartClick={onChartClick} />;
    }
  }, [
    graphPassFlow,
    graphs,
    inspectorType,
    layout,
    onChartClick,
    railwayStation,
    resetInspectorLayout,
  ]);

  return (
    inspector && (
      <Box sx={InspectorContainerMuiStyle}>
        <AutoFullHeight>{InspectorLayout}</AutoFullHeight>
      </Box>
    )
  );
};
