import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import { Alert, Box } from '@mui/material';

import { UIApi } from '@widgets/widget-routes-params/stores/ui';

export const ErrorNotification = ({
  isShow,
  name,
  title,
}: ErrorNotificationType) => {
  const { setVisible } = useUnit(UIApi);

  useEffect(() => {
    if (!isShow) return;

    setTimeout(() => {
      setVisible({ [name]: false });
    }, 10000);
  });

  return (
    isShow && (
      <Box
        sx={{
          position: 'absolute',
          top: theme => theme.spacing(4),
          left: 'calc(50% - 200px)',
          zIndex: 10,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          icon={false}
          sx={{
            height: 'min-content',
            width: '400px',
          }}
        >
          {title}
        </Alert>
      </Box>
    )
  );
};

type ErrorNotificationType = {
  isShow: boolean;
  name: string;
  title: string;
};
