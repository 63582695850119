import { apiClient } from '@api/client';
import { IQuery } from '@api/gql/types';
import { gql } from '@apollo/client';
import { forward, sample } from 'effector';

import './layers';

import { EFilterMapLayout } from '@components/MapFilters';

import {
  $FilterMap,
  $Map,
  FilterRegionsApi,
  addMapTileLayer,
  changeMap,
  getAirportsFx,
  getTransportRegionsFx,
  setAllAirports,
  setAllTransportRegions,
  setFilters,
  setLayoutFn,
  setMapFn,
  setMapLayerStyle,
  setMapPassTraffic,
  setMapPassTrafficPercent,
  setPassengerTraffic,
  toggleInspectorPanelFn,
} from '@features/passenger-traffic/stores';
import { IQueryGetInfrastructureArgs } from '@features/passenger-traffic/stores/map/types';
import { EVehicle } from '@features/pt-forecast/constants/vehiclesEnum';

import { getPercent } from '@utils/getPercent';

// Любое изменение фильтров пинает эвент изменения отображения
sample({
  clock: [
    // инициализация карты
    setMapFn,
    // Изменение любого фильтра
    setFilters,
    // Изменение слоя
    setLayoutFn,
    // Установка корреспонденции (И А и Б)
    FilterRegionsApi.setDirections,
    FilterRegionsApi.setHighwayDirections,
    FilterRegionsApi.setRailwayDirections,
    FilterRegionsApi.clearSelectedRegions,
    // Получены все ТР
    setAllTransportRegions,
    // Получены все аэропорты
    setAllAirports,
    // Данные для поинта получены
    FilterRegionsApi.setSelectedRegion,
    // Оба поинта сброшено
    FilterRegionsApi.clearSelectedRegions,
    // Установка толщины линейных объектов
    setMapPassTrafficPercent,
    // setTotalPassengerTraffic,
    toggleInspectorPanelFn,
  ],
  target: changeMap,
});

sample({
  clock: setMapFn,
  fn: mapRef => {
    let currZoom = 0;
    mapRef!.on('moveend', () => {
      const newZoom = mapRef!.getView().getZoom();
      if (currZoom != newZoom) {
        console.log('new zoom: ' + newZoom);
        currZoom = newZoom!;
      }
    });
    return EFilterMapLayout.Infrastructure;
  },
  target: setLayoutFn,
});

// При добавлении тайлового слоя на карту, применить для него стили
forward({
  from: addMapTileLayer,
  to: setMapLayerStyle,
});

sample({
  clock: getAirportsFx.done,
  fn: request => request.result || [],
  target: setAllAirports,
});

sample({
  clock: setPassengerTraffic,
  target: setMapPassTraffic,
});

sample({
  clock: [setMapPassTraffic, setFilters],
  source: { Map: $Map, FilterMap: $FilterMap },
  fn: ({ Map, FilterMap }) => {
    const vehicles = Object.keys(EVehicle) as Array<
      Exclude<keyof typeof EVehicle, 'multimodal'>
    >;
    const total = vehicles.reduce(
      (acc, vehicle) =>
        FilterMap[vehicle] ? acc + Map.passengerTraffic[vehicle] : acc || 0,
      0,
    );
    const passengerTrafficPercent = Object.fromEntries(
      vehicles.map(vehicle => [
        vehicle,
        FilterMap[vehicle]
          ? getPercent(total, Map.passengerTraffic[vehicle]) || 0
          : 0,
      ]),
    ) as Record<Exclude<keyof typeof EVehicle, 'multimodal'>, number>;
    return passengerTrafficPercent;
  },
  target: setMapPassTrafficPercent,
});

sample({
  clock: getTransportRegionsFx.done,
  fn: request => request.result || [],
  target: setAllTransportRegions,
});

sample({
  clock: setMapFn,
  target: [getTransportRegionsFx, getAirportsFx],
});

// запрос на получение всех аэропортов
getAirportsFx.use(async () => {
  const response = await apiClient.query<IQuery, IQueryGetInfrastructureArgs>({
    query: gql`
      query QueryAirport {
        airport {
          id
          geometry
        }
      }
    `,
  });

  return response.data.airport;
});

// запрос на получение всех тр
getTransportRegionsFx.use(async () => {
  const response = await apiClient.query<IQuery, IQueryGetInfrastructureArgs>({
    query: gql`
      query QueryTransportRegion {
        transportRegion {
          id
          border {
            geometry
            id
          }
        }
      }
    `,
  });

  return response.data.transportRegion;
});
