import { useUnit } from 'effector-react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SxProps,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

import { CloseIcon } from '@components/icons';

import { useContextSimulationModelHandlers } from '@features/tpu-simulation-model/hooks';
import { $ModalResetRuler } from '@features/tpu-simulation-model/store/modal-reset-ruler';

const dialogSx: SxProps<Theme> = {
  '& .MuiDialogTitle-root': {
    padding: '24px',
  },
  '& .MuiDialogActions-root': {
    gap: '24px',
    padding: '24px',

    '& :not(style)~:not(style)': {
      marginLeft: 'unset',
    },
  },
  '& .MuiDialogContent-root': {
    padding: '0 24px 24px',
    fontSize: theme => theme.typography.pxToRem(14),
    lineHeight: theme => theme.typography.pxToRem(20),
  },
  '.MuiDialog-paper': {
    maxWidth: `640px`,
    width: `640px`,
  },
};

export const ModalResetRuler = () => {
  const { isOpen } = useUnit($ModalResetRuler);
  const { resetRuler, setIsOpenModalResetRuler } =
    useContextSimulationModelHandlers();

  const handleClose = () => {
    setIsOpenModalResetRuler(false);
  };

  const handleReset = () => {
    resetRuler();
  };

  return (
    <Dialog
      open={isOpen}
      sx={dialogSx}
      transitionDuration={0}
      onClose={handleClose}
    >
      <DialogTitle>Сбросить масштаб</DialogTitle>
      <IconButton
        onClick={handleClose}
        color={'primary'}
        sx={{ position: 'absolute', top: 24, right: 24, padding: 0 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}>
          Вы действительно хотите сбросить масштаб?
        </Typography>
        <Typography sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}>
          Все построенные объекты сохранятся, но дальнейшие построения будут не
          точными.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={handleClose}
        >
          Не сбрасывать
        </Button>
        <Button
          fullWidth
          variant={'outlined'}
          onClick={handleReset}
        >
          Сбросить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
