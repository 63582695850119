import { SvgIcon, SvgIconProps } from '@mui/material';

export const EditIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12.1597 8.76086L15.2394 11.8406M9.07973 18H6V14.9203L14.6948 6.22551C14.9954 5.92483 15.4829 5.92483 15.7836 6.22551L17.7745 8.21639C18.0752 8.51707 18.0752 9.00456 17.7745 9.30524L9.07973 18Z"
        stroke="currentColor"
        fill={'transparent'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
