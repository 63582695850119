import { useCallback, useEffect, useState } from 'react';

import { TextField, TextFieldProps } from '@mui/material';

type NumberFieldType = {
  value: string | number | undefined;
  getValue: (value: number | string | undefined) => void;
  disabled?: boolean;
  placeholder?: string;
};

export const NumberField = (props: NumberFieldType) => {
  const { value, disabled, placeholder, getValue } = props;

  const [currentValue, setCurrentValue] = useState<number | string>(0);

  useEffect(() => {
    if (value === '-' && currentValue === '-') return;

    if (value === undefined || value === '') {
      setCurrentValue('');
      return;
    }

    if ((+value).toFixed(2).toString().replace('.', ',') != currentValue)
      setCurrentValue(value.toString().replace('.', ','));
  }, [currentValue, value]);

  useEffect(() => {
    if (value == currentValue) return;

    if (value === undefined) {
      setCurrentValue('');
      return;
    }

    if (value === '-') {
      setCurrentValue(value);
      return;
    }

    let num: string | number = value || '';
    const decimal = num.toString().split('.')[1];

    if (decimal) {
      if (decimal.length > 1) {
        num = (+num).toFixed(2).toString().replace('.', ',');
      } else {
        num = (+num).toFixed(1).toString().replace('.', ',');
      }
    }

    if (value === undefined || num === currentValue) return;

    setCurrentValue(num.toString().replace('.', ','));
  }, [currentValue, value]);

  const onChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    e => {
      const num: number | string = (e.target as HTMLInputElement).value;
      const normalizedValue = num.replace(/^,/, '').replace(',', '.');

      if (
        /^-?\d{0,}(\.\d{0,2})?$/.test(normalizedValue) ||
        normalizedValue === '' ||
        normalizedValue === '-'
      ) {
        setCurrentValue(num);
        getValue(normalizedValue);
      }
    },
    [getValue],
  );

  const onBlur = useCallback<NonNullable<TextFieldProps['onBlur']>>(
    e => {
      const num: number | string = (e.target as HTMLInputElement).value;
      if (num === '-' || num === '') {
        getValue(0);
      }
    },
    [getValue],
  );

  return (
    <TextField
      disabled={disabled}
      value={currentValue}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={onBlur}
    />
  );
};
