import { sample } from 'effector';

import { EMapFeatureLayout } from '@constants/map';

import {
  EPipeTileLayer,
  PipeTileLayer,
} from '@utils/map/hooks/useInitTileLayer';

import {
  $SelectedFeatures,
  SelectedFeaturesApi,
  pipeAreaBordersPassengerFlowTileLayer,
} from '../store';

sample({
  clock: SelectedFeaturesApi.setSelectedFeatures,
  source: {
    SelectedFeatures: $SelectedFeatures,
  },
  fn: ({ SelectedFeatures }) =>
    ({
      action: EPipeTileLayer.setStyle,
      payload: {
        selectedFeatureIds:
          SelectedFeatures.filter(
            item => item.layout === EMapFeatureLayout.areaBorderPassengerFlow,
          ).map(item => item.id) || [],
        featureIdMetaKey: 'id',
      },
    }) as PipeTileLayer,
  target: pipeAreaBordersPassengerFlowTileLayer,
});
