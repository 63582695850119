import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';

import {
  $ModalsPtConstructor,
  EModalRewriteCalculated,
  ModalsPtConstructor,
} from '@features/pt-constructor/store';

export type ModalRewriteCalculatedAction = (
  solution: EModalRewriteCalculated,
) => void;

export type ModalRewriteCalculatedProps = {
  onClose: () => void;
};

export const ModalRewriteCalculated = (props: ModalRewriteCalculatedProps) => {
  const { onClose } = props;

  const $modalsPtConstructor = useUnit(
    $ModalsPtConstructor,
  ) as ModalsPtConstructor<ModalRewriteCalculatedAction>;

  const handleClickCopy = useCallback(() => {
    $modalsPtConstructor.action &&
      $modalsPtConstructor.action(EModalRewriteCalculated.copy);
    onClose();
  }, [$modalsPtConstructor, onClose]);

  const handleClickRewrite = useCallback(() => {
    $modalsPtConstructor.action &&
      $modalsPtConstructor.action(EModalRewriteCalculated.rewrite);
    onClose();
  }, [$modalsPtConstructor, onClose]);

  const handleClickUnsave = useCallback(() => {
    $modalsPtConstructor.action &&
      $modalsPtConstructor.action(EModalRewriteCalculated.unsave);
    onClose();
  }, [$modalsPtConstructor, onClose]);

  return (
    <Dialog open={true}>
      <DialogTitle
        variant="subtitle2"
        sx={{
          position: 'relative',
          fontSize: theme => theme.typography.pxToRem(16),
          pr: 10,
        }}
      >
        Сохранить изменения?
      </DialogTitle>

      <DialogContent>
        <Typography>
          Вы внесли изменения в уже рассчитанный сценарий, сохранение параметров
          повлечет за собой потерю вычисленной модели.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Stack
          direction="row"
          justifyContent="flex-end"
          minWidth="512px"
          gap={1}
        >
          <Button
            color={'secondary'}
            variant={'contained'}
            onClick={handleClickCopy}
          >
            Создать копию расчета
          </Button>

          <Button
            variant={'outlined'}
            onClick={handleClickRewrite}
          >
            Перезаписать
          </Button>

          <Button
            variant={'outlined'}
            onClick={handleClickUnsave}
          >
            Не сохранять
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
