import { apiClient } from '@api/client';
import {
  IMutation,
  IMutationCreateTpuArgs,
  IQuery,
  IQueryGetScenarioTpuArgs,
} from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';
import { toLonLat } from 'ol/proj';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';
import { EMapFeatureLayout } from '@constants/map';

import { $Vehicles } from '@features/ag-forecast/stores/lists/vehicles';
import { $SelectedFeatures, clickMap } from '@features/ag-forecast/stores/map';
import {
  EditorMapApi,
  EditorMapStore,
  TpuItem,
  createTpuFx,
  fetchTpuInfrastructure,
  getScenarioTpuInfrastructureFx,
} from '@features/ag-forecast/stores/map/editor';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';
import { $UI, UIApi } from '@features/ag-forecast/stores/ui';
import { $YearLineStore } from '@features/ag-forecast/stores/yearLine/store';

// Определить координаты клика, и запросить создание ТПУ
sample({
  clock: clickMap,
  source: {
    UI: $UI,
    FeatureSettings: $FeatureSettings,
    YearLineStore: $YearLineStore,
  },
  filter: ({ UI }) => UI.placeTPU,
  fn: ({ FeatureSettings, YearLineStore }, event) => {
    const coords = event.target.getCoordinateFromPixel(event.pixel);
    const lonLat = toLonLat(coords);
    return {
      scenarioId: FeatureSettings.scenarioUUID,
      year: YearLineStore.minYear,
      geometry: {
        lat: lonLat[1],
        lon: lonLat[0],
      },
    } as IMutationCreateTpuArgs;
  },
  target: createTpuFx,
});

createTpuFx.use(async params => {
  const response = await apiClient.mutate<
    Pick<IMutation, 'createTpu'>,
    IMutationCreateTpuArgs
  >({
    mutation: gql`
      mutation MutationCreateTpu(
        $geometry: PointInput
        $scenarioId: UUID!
        $year: Int!
      ) {
        createTpu(geometry: $geometry, scenarioId: $scenarioId, year: $year) {
          tpu {
            id
            name
          }
        }
      }
    `,
    variables: {
      geometry: params.geometry,
      scenarioId: params.scenarioId,
      year: params.year,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  return response.data?.createTpu || null;
});

// Если удалось создать ТПУ, то добавить его в карту
sample({
  clock: createTpuFx.done,
  fn: request => {
    return {
      geometry: request.params.geometry,
      id: request.result?.tpu?.id || '',
      name: request.result?.tpu?.name || 'Безымянное ТПУ',
      parentTpuId: null,
      capacity: 0,
      floorsNumber: 0,
      isSaved: false,
      isUpdated: true,
    } as TpuItem;
  },
  target: [EditorMapApi.addTpu, UIApi.togglePlaceTPU],
});

////////////////////////////////////////////////////////////////////////////////
//
// // Запросить данные по выбранному тпу
sample({
  clock: $SelectedFeatures,
  source: { SelectedFeatures: $SelectedFeatures },
  filter: ({ SelectedFeatures }) =>
    SelectedFeatures[0]?.layout === EMapFeatureLayout.tpu,
  fn: ({ SelectedFeatures }) => {
    return SelectedFeatures[0]?.id || null;
  },
  target: EditorMapApi.selectTpu,
});

////////////////////////////////////////////////////////////////////////////////

sample({
  clock: fetchTpuInfrastructure,
  source: { $featureSettings: $FeatureSettings },
  fn: ({ $featureSettings }) => {
    return {
      scenarioId: $featureSettings.scenarioUUID,
    } as IQueryGetScenarioTpuArgs;
  },
  target: [getScenarioTpuInfrastructureFx, EditorMapApi.reset],
});

getScenarioTpuInfrastructureFx.use(async params => {
  const response = await apiClient.query<
    Pick<IQuery, 'getScenarioTpu'>,
    IQueryGetScenarioTpuArgs
  >({
    query: gql`
      query QueryGetScenarioTpu($scenarioId: UUID!) {
        getScenarioTpu(scenarioId: $scenarioId) {
          capacity
          floorsNumber
          parentTpu {
            id
          }
          name
          id
          geometry

          graphScenario {
            id
            name
            geometry
            transport
            year
            capacity
            freeSpeed
            oneway
            laneNum
            category
            cost
            frequenceWeekend
            frequenceWeekdays
            parkingCo
            zone
            capacityHour
          }
        }
      }
    `,
    variables: {
      scenarioId: params.scenarioId,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data?.getScenarioTpu || null;
});

sample({
  clock: getScenarioTpuInfrastructureFx.done,
  source: { $vehicles: $Vehicles },
  fn: ({ $vehicles }, request) => {
    const tpus: EditorMapStore['tpus'] = [];
    const graphs: EditorMapStore['graphs'] = [];

    request.result?.forEach(tpu => {
      tpus.push({
        isSaved: true,
        isUpdated: false,
        id: tpu?.id || '',
        name: tpu?.name || '',
        geometry: {
          lon: tpu?.geometry?.coordinates[0] || 0,
          lat: tpu?.geometry?.coordinates[1] || 0,
        },
        parentTpuId: tpu?.parentTpu?.id || null,
        capacity: tpu?.capacity || 0,
        floorsNumber: tpu?.floorsNumber || 0,
        delete: false,
      });
      tpu?.graphScenario.forEach(graph => {
        if (!graph.geometry) return;
        graphs.push({
          id: graph?.id || '',
          name: graph?.name || '',
          geometry: graph?.geometry?.coordinates[0]?.map(
            ([lon, lat]: number[]) => ({ lon, lat }),
          ),
          vehicles: (graph?.transport || []).map(
            vehicleUUID =>
              $vehicles!.find(vehicle => vehicle.id === vehicleUUID)!.vehicleId,
          ),
          year: graph?.year || 0,
          capacity: graph?.capacity || 0,
          freeSpeed: graph?.freeSpeed || 0,
          oneway: graph?.oneway || false,
          laneNum: graph?.laneNum || 0,
          category: graph?.category || '',
          cost: graph?.cost || 0,
          frequencyWeekend: graph?.frequenceWeekend || 0,
          frequencyWeekdays: graph?.frequenceWeekdays || 0,
          parkingCo: graph?.parkingCo || 0,
          zone: graph?.zone || 0,
          capacityHour: graph?.capacityHour || 0,
          tpuId: tpu?.id || '',
          isSaved: true,
          isUpdated: false,
          separated: '',
          delete: false,
        });
      });
    });

    return { tpus, graphs } as Pick<EditorMapStore, 'graphs' | 'tpus'>;
  },
  target: EditorMapApi.setInfrastructure,
});
