import { useEffect, useMemo } from 'react';

import './stores';
import { debounced } from './utils/debounced';

import { Component } from '@features/tpu-calculation-scenarios-viewing/component';
import {
  CalculationScenariosViewingHandlersContext,
  useCalculationScenariosViewingHandlers,
} from '@features/tpu-calculation-scenarios-viewing/hooks/useCalculationScenariosViewingHandlers';
import { Modals } from '@features/tpu-calculation-scenarios-viewing/modals';

export const CalculationScenariosViewingContainer = () => {
  const value = useCalculationScenariosViewingHandlers();
  const debouncedGetScenarioId = useMemo(
    () => debounced(value.getScenarioId),
    [value.getScenarioId],
  );

  useEffect(() => {
    value.getComponentState();
    debouncedGetScenarioId();
  }, [debouncedGetScenarioId, value]);

  useEffect(() => {
    return () => {
      if (!window.location.pathname.match(/\/scenarios\/.+\/viewer/)) {
        value.resetCalculationScenariosViewing();
      }
    };
  }, [value]);

  return (
    <CalculationScenariosViewingHandlersContext.Provider value={value}>
      <Component />
      <Modals />
    </CalculationScenariosViewingHandlersContext.Provider>
  );
};
