import { Map as MapOl } from 'ol';
import { memo, useRef } from 'react';

import { Map } from '@components/Map';

import { EMapFeatureLayout } from '@constants/map';

import { useInitMapControls } from '@utils/map/hooks/useInitMapControls';
import { useInitTileLayer } from '@utils/map/hooks/useInitTileLayer';
import { useInitVectorLayer } from '@utils/map/hooks/useInitVectorLayer';
import { ETileLayerStyles } from '@utils/map/tools/getTileLayerStyle';
import { EVectorLayerStyles } from '@utils/map/tools/getVectorLayerStyle';

import { LayersZIndex } from '@widgets/widget-soceco-indicators/constants/map/layersZIndex';
import {
  pipeAllGraphsAgglomerationTileLayer,
  pipeAnimalPassageVectorLayer,
  pipeAnimalProtectionVibrationVectorLayer,
  pipeChangingRoutesVectorLayer,
  pipeCulvertsVectorLayer,
  pipeMapControls,
  pipeProtectionVibrationVectorLayer,
  pipeSafeCrossingVectorLayer,
  pipeStationAccessibilityVectorLayer,
  pipeTrafficCongestionTileLayer,
  pipeWalkingAreaVectorLayer,
} from '@widgets/widget-soceco-indicators/stores/map';

export const MapContainer = memo(() => {
  const mapRef = useRef<MapOl | null>(null);

  useInitMapControls({
    mapRef,
    events: [pipeMapControls],
  });

  useInitTileLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.allGraphsAgglomeration],
      layout: EMapFeatureLayout.allGraphsAgglomeration,
      theme: ETileLayerStyles.default,
      visible: true,
      source: undefined,
    },
    events: [pipeAllGraphsAgglomerationTileLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.animalPassage],
      layout: EMapFeatureLayout.animalPassage,
      theme: EVectorLayerStyles.default,
      visible: false,
    },
    events: [pipeAnimalPassageVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.animalProtectionVibration],
      layout: EMapFeatureLayout.animalProtectionVibration,
      theme: EVectorLayerStyles.default,
      visible: false,
    },
    events: [pipeAnimalProtectionVibrationVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.changingRoutes],
      layout: EMapFeatureLayout.changingRoutes,
      theme: EVectorLayerStyles.default,
      visible: false,
    },
    events: [pipeChangingRoutesVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.culverts],
      layout: EMapFeatureLayout.culverts,
      theme: EVectorLayerStyles.default,
      visible: false,
    },
    events: [pipeCulvertsVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.protectionVibration],
      layout: EMapFeatureLayout.protectionVibration,
      theme: EVectorLayerStyles.default,
      visible: false,
    },
    events: [pipeProtectionVibrationVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.safeCrossing],
      layout: EMapFeatureLayout.safeCrossing,
      theme: EVectorLayerStyles.default,
      visible: false,
    },
    events: [pipeSafeCrossingVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.stationAccessibility],
      layout: EMapFeatureLayout.stationAccessibility,
      theme: EVectorLayerStyles.default,
      visible: false,
    },
    events: [pipeStationAccessibilityVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.walkingArea],
      layout: EMapFeatureLayout.walkingArea,
      theme: EVectorLayerStyles.default,
      visible: true,
    },
    events: [pipeWalkingAreaVectorLayer],
  });

  useInitTileLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.trafficCongestion],
      layout: EMapFeatureLayout.trafficCongestion,
      theme: ETileLayerStyles.default,
      visible: true,
    },
    events: [pipeTrafficCongestionTileLayer],
  });

  return (
    <Map
      ref={mapRef}
      setMap={() => {}}
      onClick={() => {}}
    />
  );
});
