import { useUnit } from 'effector-react';
import { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { Stack, Typography } from '@mui/material';

import { $ComputedData } from '@features/pt-forecast-new/stores/computedData';

export const DailyPassChartContainer = () => {
  const { dailyUnevennessSummary } = useUnit($ComputedData);

  const data = useMemo(() => {
    return [
      {
        name: '00-03',
        value: (dailyUnevennessSummary[0] || 0) / 1000,
      },
      {
        name: '03-06',
        value: (dailyUnevennessSummary[1] || 0) / 1000,
      },
      {
        name: '06-09',
        value: (dailyUnevennessSummary[2] || 0) / 1000,
      },
      {
        name: '09-12',
        value: (dailyUnevennessSummary[3] || 0) / 1000,
      },
      {
        name: '12-15',
        value: (dailyUnevennessSummary[4] || 0) / 1000,
      },
      {
        name: '15-18',
        value: (dailyUnevennessSummary[5] || 0) / 1000,
      },
      {
        name: '18-21',
        value: (dailyUnevennessSummary[6] || 0) / 1000,
      },
      {
        name: '21-00',
        value: (dailyUnevennessSummary[7] || 0) / 1000,
      },
    ];
  }, [dailyUnevennessSummary]);

  return (
    <Stack direction="row">
      <ResponsiveContainer
        width="100%"
        height={300}
      >
        <BarChart data={data}>
          <CartesianGrid vertical={false} />

          <YAxis
            tickCount={8}
            fontSize={12}
            dataKey="value"
          >
            <Label
              value="Пассажиропоток, тыс. пасс."
              position="insideLeft"
              angle={-90}
              fontSize={12}
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>

          <XAxis
            fontSize={12}
            dataKey="name"
            angle={75}
            dy={20}
            dx={10}
            height={70}
            width={60}
          />
          <Bar
            dataKey="value"
            fill="#E21A1A"
            barSize={12}
            radius={[10, 10, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>

      <Typography
        position="absolute"
        bottom="0px"
        left="54%"
        fontSize={12}
        style={{
          opacity: 0.7,
        }}
      >
        Время ч.
      </Typography>
    </Stack>
  );
};
