import {
  IAirportType,
  IHighwayType,
  IRailwayType,
  ITransportRegionType,
  Scalars,
} from '@api/gql/types';
import { createEffect, createEvent, createStore } from 'effector';

import { EFilterMapPoint } from '@constants/map';

import { CustomMap } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { SetMapFeaturesTypes } from '@features/passenger-traffic/stores';
import { EVehicle } from '@features/pt-forecast/constants/vehiclesEnum';

export const default$MapStore: Store = {
  allRailways: [],
  allHighways: [],
  allAirports: [],
  allTransportRegions: [],
  map: null,
  passengerTraffic: {
    multimodal: 0,
    auto: 0,
    avia: 0,
    bus: 0,
    nightTrain: 0,
    suburbanTrain: 0,
    dayTrain: 0,
    expressTrain: 0,
  },
  passengerTrafficPercent: {
    multimodal: 0,
    auto: 0,
    bus: 0,
    avia: 0,
    dayTrain: 0,
    suburbanTrain: 0,
    nightTrain: 0,
    expressTrain: 0,
  },
};

export const $Map = createStore<Store>(default$MapStore);
// Эвент ререндера слоев карты
export const setMapFeatures = createEvent<SetMapFeaturesTypes>();
// Эвент инициализации карты
export const setMapFn = createEvent<Store['map']>();
//
export const clearPointsFn = createEvent();
// Единственный тригер который должен сообщать карте что что-то изменилось
export const changeMap = createEvent();
// Установка пасстрфика для стора карты
export const setMapPassTraffic = createEvent<Store['passengerTraffic']>();
// Установка процентного соотношения для пасстрафика стора карты
export const setMapPassTrafficPercent =
  createEvent<Store['passengerTrafficPercent']>();

export const setAllAirports = createEvent<IAirportType[]>();
export const setAllTransportRegions = createEvent<ITransportRegionType[]>();
// Флаг постановки поинта на карту кликом //TODO в рефакторинг
export const setStartPointFn = createEvent<EFilterMapPoint | undefined>();

export const resetMap = createEvent();

export const getTransportRegionsFx = createEffect<
  void,
  Scalars['MultiPolygonScalar']['output']
>();

export type Store = {
  allRailways: IRailwayType[];
  allHighways: IHighwayType[];
  allAirports: IAirportType[];
  allTransportRegions: ITransportRegionType[];
  passengerTrafficPercent: Record<keyof typeof EVehicle, number>;
  passengerTraffic: Record<keyof typeof EVehicle, number>;
  map: CustomMap | null | undefined;
};
