import { createElement } from 'react';

import { radioClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import { RadioButtonCheckedIcon, RadioButtonIcon } from '@components/icons';

import { palette } from '@system/theme/palette';

export const MuiRadio: Components<Theme>['MuiRadio'] = {
  styleOverrides: {
    root: {
      color: palette.background.paper,
      '&:hover': {
        background: 'none',
      },
      [`&.${radioClasses.checked}`]: {
        color: palette.secondary.main,
      },
    },
  },
  defaultProps: {
    checkedIcon: createElement(RadioButtonCheckedIcon),
    icon: createElement(RadioButtonIcon),
  },
};
