export const SOC_ECO_TH_1_5 = [
  {
    id: 'transportArea',
    label: 'Транспортный район',
  },
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'numberJobs',
    label: 'Число рабочих мест, мест',
  },
  {
    id: 'totalTransportCosts',
    label: 'Совокупные транспортные затраты, руб.',
  },
  {
    id: 'changeNumberAvailableJobs',
    label: 'Изменение числа доступных рабочих мест, мест',
  },
];
