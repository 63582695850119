import { useUnit } from 'effector-react';
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { AccordionFilter } from '@components/MapFilters/AccordionFilter';

import { EMapFeatureLayout } from '@constants/map';

import { $FilterMap, FilterMapApi } from '@features/ag-forecast/stores/filters';
import { $TimeSlots } from '@features/ag-forecast/stores/lists/timeSlots';
import { $Vehicles } from '@features/ag-forecast/stores/lists/vehicles';
import { AgLayersStore } from '@features/ag-forecast/stores/map';
import { vehicleIdToLayout } from '@features/ag-forecast/utils/vehicleIdToLayout';

export const VehicleFiltersPassFlow = () => {
  const $filterMap = useUnit($FilterMap);
  const $vehicles = useUnit($Vehicles);
  const { options: timeSlots } = useUnit($TimeSlots);

  const {
    setFilters,
    setOnOneVehicleInFilter,
    hideAllToCache,
    restoreAllFromCache,
  } = useUnit(FilterMapApi);

  const setTransportDistrictsRootHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFilters({ transportDistricts: checked });
    },
    [setFilters],
  );

  const radioValue = useMemo(() => {
    if ($filterMap.actual[EMapFeatureLayout.graphAuto])
      return EMapFeatureLayout.graphAuto;
    if ($filterMap.actual[EMapFeatureLayout.graphTrolleybus])
      return EMapFeatureLayout.graphTrolleybus;
    if ($filterMap.actual[EMapFeatureLayout.graphTram])
      return EMapFeatureLayout.graphTram;
    if ($filterMap.actual[EMapFeatureLayout.graphMetro])
      return EMapFeatureLayout.graphMetro;
    if ($filterMap.actual[EMapFeatureLayout.graphWaterWay])
      return EMapFeatureLayout.graphWaterWay;
    if ($filterMap.actual[EMapFeatureLayout.graphSuburbanRailway])
      return EMapFeatureLayout.graphSuburbanRailway;
    if ($filterMap.actual[EMapFeatureLayout.graphPedestrain])
      return EMapFeatureLayout.graphPedestrain;
    return null;
  }, [$filterMap.actual]);

  const changeVehicleHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      return setOnOneVehicleInFilter(event.target.value as keyof AgLayersStore);
    },
    [setOnOneVehicleInFilter],
  );

  const handleChangeTimeSlot = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, value: string) => {
      FilterMapApi.setTimeSlot(value);
    },
    [],
  );

  useEffect(() => {
    hideAllToCache();
    setOnOneVehicleInFilter(EMapFeatureLayout.graphAuto);
    return () => restoreAllFromCache();
  }, []);

  if (!$vehicles) return null;

  return (
    <Box
      style={{
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'clip',
        marginRight: '12px',
        marginTop: '8px',
        paddingRight: '16px',
        padding: '16px 32px 16px 44px',
      }}
    >
      <Typography
        fontWeight={600}
        mb={2}
      >
        Фильтры
      </Typography>

      <AccordionFilter title="По видам транспорта">
        <RadioGroup
          value={radioValue}
          onChange={changeVehicleHandler}
        >
          {$vehicles.map(vehicle =>
            vehicle.vehicleId === 2 ? null : (
              <FormControlLabel
                key={vehicle.id}
                control={<Radio />}
                label={
                  vehicle.vehicleId === 1
                    ? 'Автобус и личный транспорт'
                    : vehicle.name
                }
                value={vehicleIdToLayout(vehicle.vehicleId)}
              />
            ),
          )}
        </RadioGroup>
      </AccordionFilter>

      <Divider />

      <RadioGroup
        value={$filterMap.selectedTimeSlot || timeSlots?.[0]?.id}
        onChange={handleChangeTimeSlot}
        sx={{
          py: 2.5,
          pl: 2,
          ' .MuiFormControlLabel-root': {
            height: '32px',
            py: 2.5,
          },
        }}
      >
        {timeSlots?.map(timeSlot => (
          <FormControlLabel
            key={timeSlot?.id}
            value={timeSlot?.id}
            label={timeSlot!.name![0].toUpperCase() + timeSlot!.name!.slice(1)}
            control={<Radio />}
          />
        ))}
      </RadioGroup>

      <Divider />

      <Box
        pl={2}
        mt={2}
      >
        <FormControlLabel
          checked={$filterMap.actual.transportDistricts}
          onChange={setTransportDistrictsRootHandler}
          control={<Checkbox />}
          label="Границы транспортного района"
        />
      </Box>
    </Box>
  );
};
