import {
  IQuery,
  IQueryGetBusBetweenTrsArgs,
  IQueryGetPlaneBetweenTrsArgs,
  IQueryGetRailwayBetweenTrsArgs,
} from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';
import { apiClient } from 'src/api/provider';

import {
  $FilterRegions,
  FilterRegionsApi,
} from '@features/passenger-traffic/stores/filterRegions';
import { getPassengerTrafficForInspectorFx } from '@features/passenger-traffic/stores/inspector/PassengerTraffic';

import {
  getBusBetweenTrsFx,
  getPlaneBetweenTrsFx,
  getRailwayBetweenTrsFx,
  setBusBetweenTrs,
  setDataVehicles,
  setPlaneBetweenTrs,
  setRailwayBetweenTrs,
} from '.';
import { VehicleTypeCode } from '../types';

// триггер на получение ночных поездов между транспортными районами со станциями на пути
sample({
  clock: FilterRegionsApi.setSelectedRegion,
  source: { FilterRegions: $FilterRegions },
  filter: ({ FilterRegions }) =>
    Boolean(FilterRegions.selectedRegionA && FilterRegions.selectedRegionB),
  fn: ({ FilterRegions }) => ({
    trFrom: FilterRegions.selectedRegionA?.id,
    trTo: FilterRegions.selectedRegionB?.id,
    type: 1,
  }),
  target: getRailwayBetweenTrsFx,
});

// триггер на получение дневных поездов между транспортными районами со станциями на пути
sample({
  clock: FilterRegionsApi.setSelectedRegion,
  source: { FilterRegions: $FilterRegions },
  filter: ({ FilterRegions }) =>
    Boolean(FilterRegions.selectedRegionA && FilterRegions.selectedRegionB),
  fn: ({ FilterRegions }) => ({
    trFrom: FilterRegions.selectedRegionA?.id,
    trTo: FilterRegions.selectedRegionB?.id,
    type: 2,
  }),
  target: getRailwayBetweenTrsFx,
});

// триггер на получение пригородных поездов между транспортными районами со станциями на пути
sample({
  clock: FilterRegionsApi.setSelectedRegion,
  source: { FilterRegions: $FilterRegions },
  filter: ({ FilterRegions }) =>
    Boolean(FilterRegions.selectedRegionA && FilterRegions.selectedRegionB),
  fn: ({ FilterRegions }) => ({
    trFrom: FilterRegions.selectedRegionA?.id,
    trTo: FilterRegions.selectedRegionB?.id,
    type: 3,
  }),
  target: getRailwayBetweenTrsFx,
});

// триггер на получение автобусов между транспортными районами с автостанциями на пути
sample({
  clock: FilterRegionsApi.setSelectedRegion,
  source: { FilterRegions: $FilterRegions },
  filter: ({ FilterRegions }) =>
    Boolean(FilterRegions.selectedRegionA && FilterRegions.selectedRegionB),
  fn: ({ FilterRegions }) => ({
    trFrom: FilterRegions.selectedRegionA?.id,
    trTo: FilterRegions.selectedRegionB?.id,
    type: null,
  }),
  target: getBusBetweenTrsFx,
});

// триггер на получение самолетов между транспортными районами с аэропортами
sample({
  clock: FilterRegionsApi.setSelectedRegion,
  source: { FilterRegions: $FilterRegions },
  filter: ({ FilterRegions }) =>
    Boolean(FilterRegions.selectedRegionA && FilterRegions.selectedRegionB),
  fn: ({ FilterRegions }) => ({
    trFrom: FilterRegions.selectedRegionA?.id,
    trTo: FilterRegions.selectedRegionB?.id,
    type: null,
  }),
  target: getPlaneBetweenTrsFx,
});

// получение поездов между транспортными районами со станциями на пути
getRailwayBetweenTrsFx.use(async params => {
  const response = await apiClient.query<
    IQuery,
    IQueryGetRailwayBetweenTrsArgs
  >({
    query: gql`
      query QueryGetRailwayBetweenTrs(
        $trFrom: String!
        $trTo: String!
        $type: Int
      ) {
        getRailwayBetweenTrs(trFrom: $trFrom, trTo: $trTo, type: $type) {
          trainName
          pointFrom
          pointTo
          stations {
            id
            station {
              name
            }
          }
        }
      }
    `,
    variables: {
      trFrom: params.trFrom,
      trTo: params.trTo,
      type: params.type,
    },
  });

  return response.data.getRailwayBetweenTrs;
});

// получение автобусов между транспортными районами с автостанциями на пути
getBusBetweenTrsFx.use(async params => {
  const response = await apiClient.query<IQuery, IQueryGetBusBetweenTrsArgs>({
    query: gql`
      query QueryGetBusBetweenTrs($trFrom: String!, $trTo: String!) {
        getBusBetweenTrs(trFrom: $trFrom, trTo: $trTo) {
          busName
          pointTo
          pointFrom
          stations {
            id
            station {
              name
            }
          }
        }
      }
    `,
    variables: {
      trFrom: params.trFrom,
      trTo: params.trTo,
      type: params.type,
    },
  });

  return response.data.getBusBetweenTrs;
});

// получение самолетов между транспортными районами с аэропортами
getPlaneBetweenTrsFx.use(async params => {
  const response = await apiClient.query<IQuery, IQueryGetPlaneBetweenTrsArgs>({
    query: gql`
      query QueryGetPlaneBetweenTrs($trFrom: String!, $trTo: String!) {
        getPlaneBetweenTrs(trFrom: $trFrom, trTo: $trTo) {
          planeName
          pointTo
          pointFrom
          stations {
            id
            station {
              name
            }
          }
        }
      }
    `,
    variables: {
      trFrom: params.trFrom,
      trTo: params.trTo,
      type: params.type,
    },
  });

  return response.data.getPlaneBetweenTrs;
});

// Сеттер пар поездов по одному виду транспорта
sample({
  clock: getRailwayBetweenTrsFx.done,
  target: setRailwayBetweenTrs,
});

// Сеттер пар рейсов по автобусам
sample({
  clock: getBusBetweenTrsFx.done,
  target: setBusBetweenTrs,
});

// Сеттер пар рейсов по самолетам
sample({
  clock: getPlaneBetweenTrsFx.done,
  target: setPlaneBetweenTrs,
});

// сеттер пассажиропотока по видам транспорта
sample({
  clock: getPassengerTrafficForInspectorFx.done,
  fn: payload => {
    const result = payload.result;
    const regionFromId = payload.params.regionFrom;
    const regionToId = payload.params.regionTo;

    const nightTrain = result?.find(
      item =>
        ((regionFromId === item?.direction.regionFrom?.id &&
          regionToId === item?.direction.regionTo?.id) ||
          (regionFromId === item?.direction.regionTo?.id &&
            regionToId === item?.direction.regionFrom?.id)) &&
        item?.vehicle.code === VehicleTypeCode.nightTrain,
    );
    const dayTrain = result?.find(
      item =>
        ((regionFromId === item?.direction.regionFrom?.id &&
          regionToId === item?.direction.regionTo?.id) ||
          (regionFromId === item?.direction.regionTo?.id &&
            regionToId === item?.direction.regionFrom?.id)) &&
        item?.vehicle.code === VehicleTypeCode.dayTrain,
    );
    const suburbanTrain = result?.find(
      item =>
        ((regionFromId === item?.direction.regionFrom?.id &&
          regionToId === item?.direction.regionTo?.id) ||
          (regionFromId === item?.direction.regionTo?.id &&
            regionToId === item?.direction.regionFrom?.id)) &&
        item?.vehicle.code === VehicleTypeCode.suburbanTrain,
    );
    const bus = result?.find(
      item =>
        ((regionFromId === item?.direction.regionFrom?.id &&
          regionToId === item?.direction.regionTo?.id) ||
          (regionFromId === item?.direction.regionTo?.id &&
            regionToId === item?.direction.regionFrom?.id)) &&
        item?.vehicle.code === VehicleTypeCode.bus,
    );
    const auto = result?.find(
      item =>
        ((regionFromId === item?.direction.regionFrom?.id &&
          regionToId === item?.direction.regionTo?.id) ||
          (regionFromId === item?.direction.regionTo?.id &&
            regionToId === item?.direction.regionFrom?.id)) &&
        item?.vehicle.code === 'Личный автомобиль',
    );
    const avia = result?.find(
      item =>
        ((regionFromId === item?.direction.regionFrom?.id &&
          regionToId === item?.direction.regionTo?.id) ||
          (regionFromId === item?.direction.regionTo?.id &&
            regionToId === item?.direction.regionFrom?.id)) &&
        item?.vehicle.code === VehicleTypeCode.avia,
    );

    return {
      nightTrain: {
        price: nightTrain?.passtrafficvaluesSet[0].price,
        duration: nightTrain?.passtrafficvaluesSet[0].duration,
      },
      dayTrain: {
        price: dayTrain?.passtrafficvaluesSet[0].price,
        duration: dayTrain?.passtrafficvaluesSet[0].duration,
      },
      suburbanTrain: {
        price: suburbanTrain?.passtrafficvaluesSet[0].price,
        duration: suburbanTrain?.passtrafficvaluesSet[0].duration,
      },
      bus: {
        price: bus?.passtrafficvaluesSet[0].price,
        duration: bus?.passtrafficvaluesSet[0].duration,
        frequency: bus?.passtrafficvaluesSet[0].frequency,
      },
      auto: {
        price: auto?.passtrafficvaluesSet[0].price,
        duration: auto?.passtrafficvaluesSet[0].duration,
      },
      avia: {
        price: avia?.passtrafficvaluesSet[0].price,
        duration: avia?.passtrafficvaluesSet[0].duration,
        frequency: avia?.passtrafficvaluesSet[0].frequency,
      },
    };
  },
  target: setDataVehicles,
});
