import { sample } from 'effector';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EMapFeatureLayout, EMapFeatureMetaKeys } from '@constants/map';

import { LayersVisibleApi } from '@features/pt-forecast-new/stores/map/layersVisible';
import {
  initSources,
  pipePointsABVectorLayer,
} from '@features/pt-forecast-new/stores/map/store';
import {
  $VectorGeometry,
  VectorGeometryApi,
} from '@features/pt-forecast-new/stores/map/vectorFeatures';

import {
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload && Object.keys(payload).includes(EMapFeatureLayout.pointsAB),
  fn: payload => {
    return {
      action: EPipeVectorLayer.setVisible,
      payload: payload![EMapFeatureLayout.pointsAB],
    } as PipeVectorLayer;
  },
  target: pipePointsABVectorLayer,
});

// Рендер геометрии
sample({
  clock: [VectorGeometryApi.setDirectionPoints, initSources],
  source: { VectorGeometry: $VectorGeometry },
  fn: ({ VectorGeometry }) => {
    const features: Feature<Point>[] = [];

    if (VectorGeometry.directionPoints.from)
      features.push(
        new Feature({
          geometry: new Point(
            fromLonLat(VectorGeometry.directionPoints.from.coordinates),
          ),
          [EMapFeatureMetaKeys.olId]: VectorGeometry.directionPoints.from.id,
          [EMapFeatureMetaKeys.layout]: EMapFeatureLayout.pointsAB,
          direction: 'from',
        }),
      );
    if (VectorGeometry.directionPoints.to)
      features.push(
        new Feature({
          geometry: new Point(
            fromLonLat(VectorGeometry.directionPoints.to.coordinates),
          ),
          [EMapFeatureMetaKeys.olId]: VectorGeometry.directionPoints.to.id,
          [EMapFeatureMetaKeys.layout]: EMapFeatureLayout.pointsAB,
          direction: 'to',
        }),
      );

    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: { features },
    } as PipeVectorLayer;
  },
  target: pipePointsABVectorLayer,
});
