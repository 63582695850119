export const dateToDateTime = (dateString: string) => {
  const date = new Date(dateString);

  const formatter = new Intl.DateTimeFormat('ru', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });

  return formatter.format(date);
};
