import {
  Box,
  CircularProgress,
  DialogContent,
  Link,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { Table } from '@components/TableTPU';
import { CheckIcon } from '@components/icons';

import { OBJECT_NAMES } from '@features/tpu-simulation-model/constants';

import { PlanError } from '@utils/simulationModel/errors-analyzer/types';

interface ContentProps {
  isPlanSaved: boolean;
  errors: PlanError[];
  isLoading: boolean;
  onObjectClick: (arg: string) => void;
}

export const Content = ({
  isPlanSaved,
  isLoading,
  onObjectClick,
  errors,
}: ContentProps) => {
  const noErrors = !errors.length;
  const isTableVisible = !isLoading && !isPlanSaved && !noErrors;
  const isInformerVisible = !isLoading && (isPlanSaved || noErrors);

  const columns: GridColDef<PlanError>[] = [
    {
      field: 'type',
      width: 180,
      headerName: 'Тип объекта',
      valueGetter: ({ row }) => {
        return Array.isArray(row.objectType)
          ? row.objectType.map(type => `${OBJECT_NAMES[type]}`)
          : OBJECT_NAMES[row.objectType];
      },
    },
    {
      field: 'objects',
      width: 200,
      headerName: 'Название объекта',
      renderCell: ({ row }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
          }}
        >
          {row.objects?.map(item => {
            const name = 'name' in item && item.name;
            const type = Array.isArray(row.objectType)
              ? row.objectType[0]
              : row.objectType;
            const text = name || OBJECT_NAMES[type];

            return (
              <Link
                key={item.uuid}
                component={'a'}
                onClick={() => onObjectClick(item.uuid)}
                sx={{ cursor: 'pointer' }}
              >
                {text}
              </Link>
            );
          })}
        </Box>
      ),
    },
    {
      field: 'description',
      width: 515,
      headerName: 'Описание ошибки',
    },
  ];

  return (
    <DialogContent>
      {isLoading && (
        <CircularProgress sx={{ margin: '0 auto', display: 'block' }} />
      )}
      {isInformerVisible && (
        <Box sx={{ textAlign: 'center' }}>
          <CheckIcon
            sx={{ color: 'success.main', height: '44px', width: '44px' }}
          />
          {noErrors && <Typography>Ошибок в связях не найдено.</Typography>}
          {isPlanSaved && <Typography>План успешно сохранен.</Typography>}
        </Box>
      )}
      {isTableVisible && (
        <Table
          rows={errors}
          columns={columns}
          dataGridSx={{
            height: '250px',

            '.MuiDataGrid-cell': {
              padding: '14px 16px',
            },
          }}
        />
      )}
    </DialogContent>
  );
};
