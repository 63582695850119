import { SvgIcon, SvgIconProps } from '@mui/material';

export const Settings2 = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={19}
      height={18}
      viewBox="0 0 19 18"
      fill="none"
      {...props}
    >
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.4999 11.8726C11.2674 11.8726 12.7003 10.4397 12.7003 8.67212C12.7003 6.90457 11.2674 5.47169 9.4999 5.47169C7.73235 5.47169 6.29947 6.90457 6.29947 8.67212C6.29947 10.4397 7.73235 11.8726 9.4999 11.8726Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2343 5.42372L15.1817 5.33262C15.0366 5.08138 14.9625 4.7954 14.9674 4.50529L14.9913 3.08204C14.9962 2.79118 14.8429 2.52058 14.5908 2.37534L12.4012 1.11356C12.1498 0.968687 11.8396 0.971283 11.5907 1.12035L10.3752 1.84812C10.1268 1.99686 9.84272 2.07541 9.55319 2.07541H9.44781C9.15764 2.07541 8.87294 1.99652 8.62416 1.84716L7.40314 1.11413C7.15332 0.964144 6.8417 0.961837 6.58968 1.10811L4.40701 2.37491C4.15618 2.52049 4.00376 2.7904 4.00864 3.08037L4.03262 4.50529C4.0375 4.7954 3.96341 5.08138 3.81828 5.33262L3.76636 5.4225C3.62115 5.67388 3.41029 5.88103 3.15636 6.02175L1.91228 6.71117C1.65729 6.85248 1.49934 7.1213 1.5 7.41282L1.50573 9.93462C1.50639 10.2244 1.66365 10.4911 1.91687 10.632L3.15497 11.3208C3.40976 11.4625 3.62099 11.6712 3.76583 11.9242L3.82146 12.0214C3.96455 12.2714 4.03751 12.5554 4.03265 12.8433L4.0087 14.262C4.00379 14.5529 4.15715 14.8235 4.40921 14.9688L6.59881 16.2306C6.85022 16.3754 7.16037 16.3728 7.40932 16.2238L8.62475 15.496C8.87316 15.3473 9.15728 15.2687 9.44682 15.2687H9.55219C9.84235 15.2687 10.1271 15.3476 10.3758 15.497L11.5969 16.23C11.8467 16.38 12.1583 16.3823 12.4103 16.236L14.593 14.9692C14.8438 14.8236 14.9962 14.5537 14.9914 14.2637L14.9674 12.8388C14.9625 12.5487 15.0366 12.2627 15.1817 12.0115L15.2336 11.9216C15.3789 11.6702 15.5897 11.4631 15.8436 11.3224L17.0877 10.6329C17.3427 10.4916 17.5007 10.2228 17.5 9.93129L17.4943 7.4095C17.4936 7.11974 17.3363 6.85298 17.0831 6.71212L15.8421 6.0217C15.5891 5.881 15.3791 5.67433 15.2343 5.42372Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
