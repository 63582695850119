import { createApi, createStore } from 'effector';

export const $TransportCategories = createStore<string[]>([]);

export const TransportCategoriesApi = createApi($TransportCategories, {
  setCategories: (_, categories) => {
    return categories;
  },
  reset: () => [],
});
