type DashPattern = [number, number];
type Stop = number | DashPattern;
type StopsArray = Stop[];

export const getLineDash = (zoom: number, stops: StopsArray): DashPattern => {
  // Если зум меньше первого стопа, возвращаем первый паттерн пунктира
  if (typeof stops[0] === 'number' && zoom < stops[0]) {
    // Приводим к типу number для уверенности в типе
    return stops[1] as DashPattern; // Приводим к типу DashPattern
  }

  // Если зум больше последнего стопа, возвращаем последний паттерн пунктира
  // Индекс "lastIndex" должен указывать на последний заполненный уровень зума, поэтому -2 (не -4)
  const lastIndex = stops.length - 2;
  if (zoom >= (stops as number[])[lastIndex]) {
    return stops[lastIndex + 1] as DashPattern;
  }

  // Поиск двух стопов между которыми находится текущее значение зума
  for (let i = 0; i < lastIndex; i += 2) {
    const nextZoomLevel = stops[i + 2] as number;

    if (zoom < nextZoomLevel) {
      return stops[i + 1] as DashPattern;
    }
  }
  throw new Error('No dash pattern found for the given zoom level.');
};
