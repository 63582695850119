import { SOCECO_BLOCK_IDS } from '@widgets/widget-soceco-indicators/constants/tables/blockIds';
import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';

export const SOCECO_BLOCK_TITLES = {
  [SOCECO_BLOCK_IDS.BLOCK1]: 'Социально-экономическая устойчивость',
  [SOCECO_BLOCK_IDS.BLOCK2]: 'Комфортная среда',
  [SOCECO_BLOCK_IDS.BLOCK3]: 'Экологическая устойчивость',
  [SOCECO_BLOCK_IDS.BLOCK4]: 'Здоровье и безопасность',
  [SOCECO_BLOCK_IDS.BLOCK5]: 'Интеграции',
  [SOCECO_BLOCK_IDS.BLOCK6]: 'Открытость и прозрачность',
  [SOCECO_BLOCK_IDS.BLOCK7]: 'Технологичность',
};

export const SOCECO_TABLES_TITLES = {
  [SOCECO_TABLE_IDS.TABLE_1_1]:
    'Территории, где предполагается размещение общественно-деловой инфраструктуры в зоне пешеходной доступности',
  [SOCECO_TABLE_IDS.TABLE_1_2]:
    'План мероприятий по развитию туристической инфраструктуры',
  [SOCECO_TABLE_IDS.TABLE_1_3]:
    'План мероприятий по созданию условий для реализации интересов местного бизнес-сообщества и частных партнеров',
  [SOCECO_TABLE_IDS.TABLE_1_4]:
    'План мероприятий по повышению коммерческого потенциала',
  [SOCECO_TABLE_IDS.TABLE_1_5]:
    'План мероприятий по расширению возможностей трудоустройства',

  [SOCECO_TABLE_IDS.TABLE_2_1]:
    'План мероприятий по повышению разнообразия деловых, социальных и культурно-досуговых сервисов',
  [SOCECO_TABLE_IDS.TABLE_2_2]:
    'План мероприятий по созданию условий комфортного передвижения и транспортной доступности',
  [SOCECO_TABLE_IDS.TABLE_2_3]:
    'План мероприятий по созданию городских интермодальных систем пассажирского и грузового транспорта',
  [SOCECO_TABLE_IDS.TABLE_2_4]:
    'План мероприятий по созданию условий для беспрепятственного доступа маломобильных групп населения',
  [SOCECO_TABLE_IDS.TABLE_2_5]:
    'План мероприятий по обеспечению защиты от шума и вибраций',

  [SOCECO_TABLE_IDS.TABLE_3_1]: 'Планирование экологических мероприятий',
  [SOCECO_TABLE_IDS.TABLE_3_2]:
    'План мероприятий по обеспечению защиты от шума и вибраций',
  [SOCECO_TABLE_IDS.TABLE_3_3]:
    'План мероприятий по обеспечению снижения выбросов парниковых газов',
  [SOCECO_TABLE_IDS.TABLE_3_4]:
    'План мероприятий по применению природозащитных решений и инженерно-экологических технологий',
  [SOCECO_TABLE_IDS.TABLE_3_5]:
    'План мероприятий по минимизации техногенного воздействия на окружающую среду',
  [SOCECO_TABLE_IDS.TABLE_3_6]:
    'План мероприятий по рекультивации нарушенных территорий и восстановлению связанности ареалов обитания флоры и фауны',
  [SOCECO_TABLE_IDS.TABLE_3_7]:
    'Определение характера вредного воздействия на окружающую среду и применение равноценных компенсационных мероприятий по восстановлению',

  [SOCECO_TABLE_IDS.TABLE_4_1]:
    'План мероприятий по созданию инфраструктуры общественного транспорта для ведения активного образа жизни',
  [SOCECO_TABLE_IDS.TABLE_4_2]:
    'План мероприятий по созданию безопасных пересечений',
  [SOCECO_TABLE_IDS.TABLE_4_3]:
    'План мероприятий по созданию современных цифровых систем безопасности, мониторинга и оповещения',
  [SOCECO_TABLE_IDS.TABLE_4_4]:
    'Разработки комфортных и безопасных условий труда',

  [SOCECO_TABLE_IDS.TABLE_5_1]:
    'План мероприятий по эффективной реорганизации улично-дорожной сети',
  [SOCECO_TABLE_IDS.TABLE_5_2]:
    'План мероприятий по созданию новых и оптимизации существующих маршрутов общественного транспорта',
  [SOCECO_TABLE_IDS.TABLE_5_3]:
    'План мероприятий по обеспечению высокой транспортной и пешеходной доступности',
  [SOCECO_TABLE_IDS.TABLE_5_4]:
    'План мероприятий по созданию зон организованной парковки',
  [SOCECO_TABLE_IDS.TABLE_5_5]:
    'План мероприятий по созданию комплексной и доступной системы навигации',
  [SOCECO_TABLE_IDS.TABLE_5_6]:
    'План мероприятий по сохранению и модернизации существующих общественных зон и рекреационных связей',
  [SOCECO_TABLE_IDS.TABLE_5_7]:
    'Разработки организации транспортно-логистической системы',
  [SOCECO_TABLE_IDS.TABLE_5_8]:
    'План мероприятий по разработке рекомендаций по учету проектных решений и принципов реализации транспортной инициативы в существующих и разрабатываемых стратегических документах по развитию регионов, агломераций и городов.',

  [SOCECO_TABLE_IDS.TABLE_6_1]:
    'Разработки мероприятий по вовлечению местных, профессиональных и бизнес-сообществ в принятие решений по транспортной инициативе и разработку стратегий по развитию прилегающих территорий',
  [SOCECO_TABLE_IDS.TABLE_6_2]:
    'Разработки мероприятий по привлечению к участию в реализации транспортной инициативе корпоративных партнеров и инвесторов',
  [SOCECO_TABLE_IDS.TABLE_6_3]:
    'Разработки мероприятий по созданию единого цифрового портала для обсуждения решений',

  [SOCECO_TABLE_IDS.TABLE_7_1]:
    'Разработки мероприятий по созданию единого цифрового портала транспортной инициативы',
  [SOCECO_TABLE_IDS.TABLE_7_2]:
    'Разработки мероприятий по применению ресурсосберегающих технологий',
  [SOCECO_TABLE_IDS.TABLE_7_3]:
    'Разработки мероприятий по внедрению цифровых решений для формирования комфортного пользовательского опыта',
  [SOCECO_TABLE_IDS.TABLE_7_4]:
    'Разработки мероприятий по внедрению интеграции цифровой информационной железнодорожной инфраструктуры системы управления',
  [SOCECO_TABLE_IDS.TABLE_7_5]:
    'Разработки мероприятий по применению технологий в границах транспортной инициативы',
};
