import { useUnit } from 'effector-react';
import { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from 'recharts';

import { ChartTooltip } from '@features/pt-forecast-new/components/Dashboards/PassengerTrafficBySexChart/ChartTooltip';
import { getChartColor } from '@features/pt-forecast-new/components/Dashboards/PassengerTrafficBySexChart/getChartColor';
import { sexAvailableCodes } from '@features/pt-forecast-new/constants/eSex';
import { $ComputedData } from '@features/pt-forecast-new/stores/computedData';

export const PassengerTrafficBySexChart = () => {
  const { currentData } = useUnit($ComputedData);

  const data = useMemo(() => {
    return [
      {
        [sexAvailableCodes[0]]: currentData?.passengerTrafficBySex?.male || 0,
        [sexAvailableCodes[1]]: currentData?.passengerTrafficBySex?.female || 0,
      },
    ];
  }, [
    currentData?.passengerTrafficBySex?.female,
    currentData?.passengerTrafficBySex?.male,
  ]);

  return (
    <ResponsiveContainer
      width="100%"
      minHeight="180px"
    >
      <BarChart
        data={data}
        height={180}
        margin={{
          top: 35,
          bottom: 50,
          left: 15,
        }}
      >
        <CartesianGrid vertical={false} />

        <YAxis
          min={0}
          width={60}
          fontSize={12}
        >
          <Label
            value={'Пассажиропоток, чел.'}
            position="top"
            dx={45}
            dy={-10}
            style={{
              textAnchor: 'middle',
              fontSize: 12,
            }}
          />
        </YAxis>

        <Legend
          iconType="circle"
          align="right"
          wrapperStyle={{
            fontSize: '0.75rem',
            lineHeight: 1.75,
            paddingLeft: '12px',
            paddingBottom: '16px',
            minWidth: '150px',
            right: 0,
            width: 'calc(29ch + 20px)',
          }}
          layout="vertical"
          verticalAlign="middle"
          formatter={value => <span>{value} (чел.)</span>}
        />

        {sexAvailableCodes.map(code => (
          <Bar
            key={code}
            barSize={70}
            dataKey={code}
            stackId="passTraffic"
            fill={getChartColor(code)}
          />
        ))}

        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={ChartTooltip}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
