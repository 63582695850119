import { createApi, createStore } from 'effector';

export enum EPTPanels {
  filter,
  paramsForecast,
  forecast,
}

export type PTUI = {
  panels: PTPanels;
  disabled: PTFDisabled;
  statusRecalculation: boolean;
  isLoadingGeometry: boolean;
  backdrop: boolean;
};

export type PTPanels = {
  filter: boolean;
  paramsForecast: boolean;
  forecast: boolean;
};

export type PTFDisabled = {
  recalculation: boolean;
};

const initStore: PTUI = {
  panels: {
    filter: false,
    paramsForecast: false,
    forecast: false,
  },
  disabled: {
    recalculation: true,
  },
  statusRecalculation: false,
  isLoadingGeometry: false,
  backdrop: false,
};

export const $UI = createStore<PTUI>(initStore);

export const UIApi = createApi($UI, {
  toggleFilterPanel: state => {
    state.panels.filter = !state.panels.filter;

    return { ...state };
  },
  setVisibleFilterPanel: (state, payload: boolean) => {
    state.panels.filter = payload;

    return { ...state };
  },
  setVisibleProgressGeometry: (state, payload: boolean) => {
    state.isLoadingGeometry = payload;

    return { ...state };
  },
  setMapBackdrop: (state, payload: boolean) => {
    state.backdrop = payload;

    return { ...state };
  },
  toggleParamsForecastPanel: state => {
    state.panels.paramsForecast = !state.panels.paramsForecast;

    return { ...state };
  },
  setVisibleParamsForecastPanel: (state, payload: boolean) => {
    state.panels.paramsForecast = payload;

    return { ...state };
  },
  toggleForecastPanel: state => {
    state.panels.forecast = !state.panels.forecast;

    return { ...state };
  },
  setVisibleForecastPanel: (state, payload: boolean) => {
    state.panels.forecast = payload;

    return { ...state };
  },
  setDisabledRecalculation: (state, payload: boolean) => {
    state.disabled.recalculation = payload;

    return { ...state };
  },
  setVisibleStatusRecalculation: (state, payload: boolean) => {
    state.statusRecalculation = payload;

    return { ...state };
  },
  resetUI: state => ({
    ...state,
    panels: {
      ...state.panels,
      paramsForecast: false,
      forecast: false,
    },
  }),
});
