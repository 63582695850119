import { Image } from 'react-konva';
import useImage from 'use-image';

import BankIcon from '@components/icons/Bank.svg';
import BankomatIcon from '@components/icons/Bankomat.svg';
import BuffetIcon from '@components/icons/Buffet.svg';
import CartIcon from '@components/icons/Cart.svg';
import DoorSvg from '@components/icons/Door.svg';
import ElevatorSvg from '@components/icons/Elevator.svg';
import ExitIcon from '@components/icons/Exit.svg';
import InformationTableIcon from '@components/icons/InformationTable.svg';
import KioskIcon from '@components/icons/Kiosk.svg';
import PeopleIcon from '@components/icons/People.svg';
import RestaurantIcon from '@components/icons/Restaurant.svg';
import SecurityCheckpointIcon from '@components/icons/SecurityCheckpoint.svg';
import StoreIcon from '@components/icons/Store.svg';
import TicketPrintingMachineIcon from '@components/icons/TicketPrintingMachine.svg';
import TicketsIcon from '@components/icons/Tickets.svg';
import ToiletIcon from '@components/icons/Toilet.svg';
import TurnstileIcon from '@components/icons/Turnstile.svg';
import VendingIcon from '@components/icons/Vending.svg';
import WaitingRoomIcon from '@components/icons/WaitingRoom.svg';

import {
  EditableObjectType,
  FlexibleObjects,
} from '@features/tpu-simulation-model/types';

const ICON = {
  [EditableObjectType.Toilet]: ToiletIcon,
  [EditableObjectType.Waiting]: WaitingRoomIcon,
  [EditableObjectType.Turnstile]: TurnstileIcon,
  [EditableObjectType.SecurityCheckpoint]: SecurityCheckpointIcon,
  [EditableObjectType.InformationTable]: InformationTableIcon,
  [EditableObjectType.TicketPrintingMachine]: TicketPrintingMachineIcon,
  [EditableObjectType.TicketOffice]: TicketsIcon,
  [EditableObjectType.Exit]: ExitIcon,
  [EditableObjectType.Restaurant]: RestaurantIcon,
  [EditableObjectType.Bank]: BankIcon,
  [EditableObjectType.Bankomat]: BankomatIcon,
  [EditableObjectType.VendingMachine]: VendingIcon,
  [EditableObjectType.Buffet]: BuffetIcon,
  [EditableObjectType.Store]: StoreIcon,
  [EditableObjectType.Kiosk]: KioskIcon,
  [EditableObjectType.Other]: CartIcon,
  [EditableObjectType.PassengerGenerator]: PeopleIcon,
  [EditableObjectType.Door]: DoorSvg,
  [EditableObjectType.Elevator]: ElevatorSvg,
};

export const ServiceOrCommerceObject = (props: FlexibleObjects) => {
  const [icon] = useImage(ICON[props.type]);

  const { point, width, height, rotation } = props;

  return (
    <Image
      x={point.x}
      y={point.y}
      width={width}
      height={height}
      image={icon}
      rotation={rotation}
    />
  );
};
