import { IPieChartItem } from '@features/tpu-calculation-scenarios-viewing/stores/charts/charts.store';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { Box } from '@mui/material';

import { CustomChartTooltip } from '../CustomChartTooltip/CustomChartTooltip';
import { PieChartItem } from '../PieChartItem/PieChartItem';

interface ScenarioPieChartProps {
  chartData: IPieChartItem[];
  pieColors: string[];
  unit: string;
  width?: string;
  diameter: number;
  valueFormatter?: (value: number) => string;
  onClick?: () => void;
}

export const ScenarioPieChart = ({
  chartData,
  pieColors,
  unit,
  width,
  diameter,
  onClick,
  valueFormatter,
}: ScenarioPieChartProps) => {
  const totalValue = chartData.reduce((acc, item) => acc + item.value, 0);

  return (
    <Box
      height={'auto'}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        height={diameter}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        width={width}
      >
        <ResponsiveContainer
          width={diameter}
          height="100%"
        >
          <PieChart
            style={{ cursor: 'pointer' }}
            onClick={onClick}
          >
            <Pie
              dataKey="value"
              data={chartData}
              innerRadius={'70%'}
              outerRadius={'100%'}
              paddingAngle={0.5}
            >
              {chartData.map((_, index) => (
                <Cell
                  key={index}
                  fill={pieColors[index]}
                  style={{ outline: 'none' }}
                />
              ))}
            </Pie>

            <Tooltip
              content={
                <CustomChartTooltip
                  unit={unit}
                  valueFormatter={valueFormatter}
                  isPieChart
                />
              }
            />

            <text
              key="value"
              x="50%"
              y="50%"
              fill="#1E1E1E"
              fontSize="16px"
              textAnchor="middle"
              fontWeight="700"
              dy="0px"
            >
              {valueFormatter ? valueFormatter(totalValue) : totalValue}
            </text>

            <text
              key="label"
              x="50%"
              y="50%"
              fill="#606060"
              fontSize="14px"
              textAnchor="middle"
              fontWeight="400"
              dy="23px"
            >
              {unit}
            </text>
          </PieChart>
        </ResponsiveContainer>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {chartData.map((item, index) =>
          PieChartItem(pieColors, item, index, valueFormatter),
        )}
      </Box>
    </Box>
  );
};
