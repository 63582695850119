import { EMapFeatureLayout } from '@constants/map';

import { AgLayersStore } from '@features/ag-forecast/stores/map';

export const LayersZIndex: Record<keyof AgLayersStore, number> = {
  [EMapFeatureLayout.tpu]: 27,
  [EMapFeatureLayout.tpuGraphs]: 26,
  [EMapFeatureLayout.graphs]: 3,
  [EMapFeatureLayout.transportDistricts]: 24,
  [EMapFeatureLayout.agRailwayStations]: 25,
  [EMapFeatureLayout.graphAuto]: 6,
  [EMapFeatureLayout.graphBus]: 7,
  [EMapFeatureLayout.graphTrolleybus]: 8,
  [EMapFeatureLayout.graphTram]: 20,
  [EMapFeatureLayout.graphMetro]: 10,
  [EMapFeatureLayout.graphSuburbanRailway]: 22,
  [EMapFeatureLayout.graphRopeWay]: 12,
  [EMapFeatureLayout.graphWaterWay]: 13,
  [EMapFeatureLayout.graphFunicular]: 14,
  [EMapFeatureLayout.graphMonoRailWay]: 15,
  [EMapFeatureLayout.graphPedestrain]: 16,
  [EMapFeatureLayout.graphAvia]: 17,
  [EMapFeatureLayout.transportFlowDistricts]: 28,
  [EMapFeatureLayout.agSearchInfra]: 29,
};
