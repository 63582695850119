import { IHubScenarioSimulationStateChoices } from '@api/gql/tpu-types';
import { useUnit } from 'effector-react';

import { Box, Button, Tooltip } from '@mui/material';

import { DownloadIcon, InfoIcon } from '@components/icons';
import { Pause } from '@components/icons/Pause';
import { Play } from '@components/icons/Play';
import { Settings } from '@components/icons/Settings';
import { Speed } from '@components/icons/Speed';

import { useCalculationScenariosViewingHandlers } from '@features/tpu-calculation-scenarios-viewing/hooks/useCalculationScenariosViewingHandlers';
import { useScenarioPlayerTicks } from '@features/tpu-calculation-scenarios-viewing/hooks/useScenarioPlayerTicks';
import { $Report } from '@features/tpu-calculation-scenarios-viewing/stores';
import {
  $Player,
  $SimulationData,
} from '@features/tpu-calculation-scenarios-viewing/stores/scenarioPlayer/scenarioPlayer.store';

import { PlayerDynamicBar } from '../PlayerDynamicBar/PlayerDynamicBar';

const speedOptions: { [key: number]: number } = {
  [1]: 2,
  [2]: 5,
  [5]: 10,
  [10]: 1,
};

export const ScenarioPlayer = () => {
  useScenarioPlayerTicks();

  const { setPlay, setSpeedDivider, setWithAnimation, downloadReport } =
    useCalculationScenariosViewingHandlers();

  const { play, playDisabled, speedDivider, withAnimation } = useUnit($Player);
  const $simulationData = useUnit($SimulationData);
  const $report = useUnit($Report);

  const isCalculationCompleted =
    $simulationData.state === IHubScenarioSimulationStateChoices.Completed;

  const clickSpeedButtonHandler = () => {
    setSpeedDivider(speedOptions[speedDivider]);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: 103,
        }}
      >
        <Tooltip
          title={
            playDisabled
              ? 'Начат предварительный расчет модели. На завершение полного расчета потребуется 7-10 минут'
              : null
          }
          sx={{
            fontSize: '12px',
            lineHeight: '15px',
            fontWeight: '400',
            color: '#606060',
          }}
        >
          <span>
            <InfoIcon
              color={'error'}
              sx={{
                visibility: `${playDisabled ? 'visible' : 'hidden'}`,
                mt: '7px',
                mr: '16px',
                cursor: 'pointer',
              }}
            />
          </span>
        </Tooltip>

        <Tooltip
          title={
            playDisabled
              ? 'Дождитесь предварительного расчета первых 10 минут'
              : null
          }
          sx={{
            fontSize: '12px',
            lineHeight: '15px',
            fontWeight: '400',
            color: '#606060',
          }}
        >
          <span>
            <Button
              variant="outlined"
              sx={{
                '&:disabled': { borderColor: 'rgba(0, 0, 0, 0.26)' },
                paddingLeft: '20px',
                height: 44,
                width: 44,
                minWidth: 44,
                mr: 24,
                borderRadius: '50%',
              }}
              disabled={playDisabled}
              onClick={() => {
                setPlay(!play);
              }}
            >
              {play ? (
                <Pause />
              ) : (
                <Play
                  sx={{
                    fontSize: 18,
                    fill: `${playDisabled ? '#273B48' : '#00000042'}`,
                  }}
                />
              )}
            </Button>
          </span>
        </Tooltip>
      </Box>

      <PlayerDynamicBar />

      <Button
        startIcon={<Speed />}
        variant="outlined"
        sx={{
          justifyContent: 'flex-start',
          width: '96px',
          mr: '24px',
        }}
        onClick={clickSpeedButtonHandler}
      >
        {speedDivider} X
      </Button>

      <Tooltip
        title={
          withAnimation
            ? 'Исполнение модели будет выполнено с анимацией'
            : 'Исполнение модели будет выполнено без анимации.'
        }
        sx={{
          fontSize: '12px',
          lineHeight: '15px',
          fontWeight: '400',
          color: '#606060',
        }}
      >
        <Button
          startIcon={<Settings />}
          variant="outlined"
          sx={{
            mr: '24px',
          }}
          onClick={() => setWithAnimation(!withAnimation)}
        >
          Режим расчета
        </Button>
      </Tooltip>

      <Tooltip
        title={isCalculationCompleted ? null : 'Дождитесь окончания расчета'}
        sx={{
          fontSize: '12px',
          lineHeight: '15px',
          fontWeight: '400',
          color: '#606060',
        }}
      >
        <span>
          <Button
            variant={'text'}
            sx={{
              [`& .MuiButton-startIcon > *:nth-of-type(1)`]: {
                fontSize: '12px',
              },
            }}
            endIcon={<DownloadIcon />}
            color={'info'}
            onClick={downloadReport}
            disabled={!$report.isExist}
          >
            Скачать
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};
