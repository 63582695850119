import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const KioskIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        d="M5.73 17.217h5.231v-4.555h-5.23v4.555Zm2.003-2.553h1.226v.55H7.733v-.55Z"
        fill="#7E67DD"
      />
      <path
        d="M23 6.555V6.38l-.05-.1v-.05L20.397 2H4.003L1.1 6.155l-.075.125v.3c-.012.05-.02.1-.025.15v1.127a2.678 2.678 0 0 0 1.477 2.402v11.313h19.046V10.234A2.678 2.678 0 0 0 23 7.832V6.555ZM6.256 7.832v-.826l1.802-3.004h1.427L8.759 6.43v1.727a1.226 1.226 0 0 1-2.453 0l-.05-.325Zm4.455-.976.851-2.879h.75l.852 2.879v1.351a1.226 1.226 0 1 1-2.453 0V6.856Zm4.455-.2v-.2l-.726-2.429h1.752l1.477 2.954v1.251a1.227 1.227 0 1 1-2.453 0V6.68l-.05-.025Zm5.807.325v.85a.676.676 0 0 1-1.352 0V6.406l-.05-.125-1.126-2.278h.826l1.702 2.979ZM5.005 3.977h.7L4.38 6.18v.05l-.05.125v.175a.978.978 0 0 0 0 .1v1.177a.676.676 0 1 1-1.352 0V7.03l2.028-3.054ZM16.192 19.57h-2.428v-4.906h2.403l.025 4.906Zm2.003 0v-6.908h-6.433v6.908H4.404v-9.185l.525-.226a3.228 3.228 0 0 0 4.806.376 3.229 3.229 0 0 0 4.455 0 3.23 3.23 0 0 0 4.805-.376l.526.226v9.185h-1.326Z"
        fill="#7E67DD"
      />
    </SvgIcon>
  );
};
