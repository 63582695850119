import {
  IQueryGetProjectVehiclesArgs,
  IVehicleType,
  Maybe,
} from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

import { EVehicleAg } from '@features/ag-project/constants/EVehicleAg';

export const $Vehicles = createStore<VehicleType[] | null>(null);

export const VehiclesApi = createApi($Vehicles, {
  setVehicles: (_store, vehicles: VehicleType[]) => vehicles,
  reset: () => [],
});

export const getVehiclesFx = createEffect<
  IQueryGetProjectVehiclesArgs,
  Maybe<Maybe<IVehicleType>[]>
>();

export type VehicleType = {
  id: string;
  name: string;
  vehicleId: EVehicleAg;
  maxSpeed: number;
  isPublic: boolean;
};
