import { apiClient } from '@api/client';
import { IQuery, IQueryFindRegionByPrefixArgs } from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import {
  RegionOptionItem,
  RegionOptionsListApi,
  findRegionByPrefixFx,
  searchRegions,
} from '@features/pt-forecast-new/stores/lists/regionOptions/store';

// Получить набор опций для выпадающего списка с транспортными регионами
sample({
  clock: searchRegions,
  fn: payload => {
    return {
      name: payload,
      maxCount: 10,
    };
  },
  target: findRegionByPrefixFx,
});

findRegionByPrefixFx.use(async params => {
  const response = await apiClient.query<
    Pick<IQuery, 'findRegionByPrefix'>,
    IQueryFindRegionByPrefixArgs
  >({
    query: gql`
      query QueryFindRegionByPrefix($name: String, $maxCount: Int) {
        findRegionByPrefix(name: $name, maxCount: $maxCount) {
          id
          name
        }
      }
    `,
    variables: {
      name: params.name,
      maxCount: params.maxCount,
    },
  });

  return response.data.findRegionByPrefix || [];
});

// Получить набор опций для выпадающего списка с транспортными регионами
sample({
  clock: findRegionByPrefixFx.done,
  fn: response => {
    return response.result?.map(regionOption => ({
      id: regionOption?.id || '',
      name: regionOption?.name || '',
    })) as RegionOptionItem[];
  },
  target: RegionOptionsListApi.setRegionOptions,
});
