import { useUnit } from 'effector-react';
import { SyntheticEvent, useCallback } from 'react';

import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  IconButton,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { LocationIcon } from '@components/icons';

import {
  $RegionOptionsList,
  RegionOptionItem,
  searchRegions,
} from '@features/pt-forecast-new/stores/lists/regionOptions';

type DirectionPointFieldProps = {
  disabled: boolean;
  disabledOnlyPoints?: boolean;
  onSelectOption: (option: RegionOptionItem) => void;
  onClearSelectedOption: () => void;
  onSetPoint: () => void;
  value: RegionOptionItem | null;
  placeholder: string;
};

export const DirectionPointField = (props: DirectionPointFieldProps) => {
  const {
    disabled,
    onSelectOption,
    onClearSelectedOption,
    value,
    placeholder,
    onSetPoint,
    disabledOnlyPoints,
  } = props;
  const { options } = useUnit($RegionOptionsList);

  const changeHandler = useCallback(
    (
      _e: SyntheticEvent<Element, Event>,
      option: string | RegionOptionItem | null,
      reason: AutocompleteChangeReason,
    ) => {
      if (reason === 'clear' || !option || typeof option === 'string') return;
      onSelectOption(option);
    },
    [onSelectOption],
  );

  const changeInputHandler = useCallback(
    (
      _e: SyntheticEvent<Element, Event>,
      _value: string | null,
      reason: AutocompleteInputChangeReason,
    ) => {
      if (reason === 'clear') {
        onClearSelectedOption();
      }
    },
    [onClearSelectedOption],
  );

  const changeFieldInputHandler = useCallback(
    (e: SyntheticEvent<Element, Event>) => {
      const value = (e?.target as HTMLInputElement)?.value;
      searchRegions(value || '');
    },
    [],
  );

  const blurHandler = () => {
    searchRegions('');
  };

  return (
    <Grid
      xs={12}
      wrap={'nowrap'}
      display={'flex'}
    >
      <Autocomplete
        fullWidth
        value={value || ''}
        disabled={disabled}
        options={options}
        freeSolo
        onBlur={blurHandler}
        onChange={changeHandler}
        onInputChange={changeInputHandler}
        getOptionLabel={option =>
          typeof option === 'object' ? option.name : option
        }
        renderOption={(props, option) => (
          <Typography {...props}>{option.name || ''}</Typography>
        )}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        forcePopupIcon={false}
        // inputValue={
        //   point === EFilterMapPoint.A ? searchRegionA : searchRegionB
        // }
        clearText={'Очистить'}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={placeholder + ':'}
            onChange={changeFieldInputHandler}
          />
        )}
      />

      <ButtonLocation
        onClick={onSetPoint}
        disabled={disabledOnlyPoints || disabled}
      >
        <LocationIcon
          text={placeholder}
          sx={{ opacity: disabledOnlyPoints || disabled ? 0.3 : 1 }}
        />
      </ButtonLocation>
    </Grid>
  );
};

const ButtonLocation = styled(IconButton)(() => ({
  width: '56px',
  marginRight: '-11px',
  color: 'black',
  marginTop: '-6px',
  marginLeft: '8px',
}));
