import { Box, Typography } from '@mui/material';

interface NoDataAvailableProps {
  height?: number;
}

export const NoDataAvailable = ({ height }: NoDataAvailableProps) => {
  return (
    <Box
      sx={{
        height: height ? height : '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography sx={{ fontSize: '14px' }}>Нет данных</Typography>
    </Box>
  );
};
