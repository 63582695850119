import { BlocksChart } from '@features/tpu-calculation-scenarios-viewing/components/BlocksChart/BlocksChart';
import { CommercialAreasTable } from '@features/tpu-calculation-scenarios-viewing/components/CommercialAreasTable';
import { NoDataAvailable } from '@features/tpu-calculation-scenarios-viewing/components/NoDataAvailable/NoDataAvailable';
import {
  $ChartCommercialRevenue,
  $ChartCommercialSquare,
  $TableCommercialSquare,
} from '@features/tpu-calculation-scenarios-viewing/stores/chartsCommercialObjects/chartsCommercialObjects.store';
import { useUnit } from 'effector-react';

import { Box, Typography } from '@mui/material';

export const CommercialAreasPageContainer = () => {
  const $chartCommercialSquare = useUnit($ChartCommercialSquare);
  const $chartCommercialRevenue = useUnit($ChartCommercialRevenue);
  const $tableCommercialSquare = useUnit($TableCommercialSquare);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        gap: '24px',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme => theme.palette.background.paper,
          boxShadow: '0px 0px 8px 0px #00000026',
          padding: '24px',
          borderRadius: '4px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width: '42%',
        }}
      >
        <Typography fontWeight={'bold'}>
          Сравнение объектов по коммерческим площадям
        </Typography>

        {$chartCommercialSquare?.length ? (
          <BlocksChart data={$chartCommercialSquare} />
        ) : (
          <NoDataAvailable />
        )}

        <Typography
          fontWeight={'bold'}
          sx={{ mt: '10px' }}
        >
          Сравнение объектов по выручке
        </Typography>

        {$chartCommercialRevenue?.length ? (
          <BlocksChart data={$chartCommercialRevenue} />
        ) : (
          <NoDataAvailable />
        )}
      </Box>

      <Box
        sx={{
          backgroundColor: theme => theme.palette.background.paper,
          boxShadow: '0px 0px 8px 0px #00000026',
          padding: '24px',
          borderRadius: '4px',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          width: '56%',
        }}
      >
        <Typography fontWeight={'bold'}>
          Коммерческие площади (нарастающим итогом, на период симуляции)
        </Typography>

        {$tableCommercialSquare?.length ? (
          <CommercialAreasTable
            fullVariant
            rows={$tableCommercialSquare}
          />
        ) : (
          <NoDataAvailable />
        )}
      </Box>
    </Box>
  );
};
