import { SOC_ECO_TH_2_1 } from '@widgets/widget-soceco-indicators/constants/tables/heads/comfortableEnvironment/socEcoTH_2_1';
import { SOC_ECO_TH_2_2 } from '@widgets/widget-soceco-indicators/constants/tables/heads/comfortableEnvironment/socEcoTH_2_2';
import { SOC_ECO_TH_2_3 } from '@widgets/widget-soceco-indicators/constants/tables/heads/comfortableEnvironment/socEcoTH_2_3';
import { SOC_ECO_TH_2_4 } from '@widgets/widget-soceco-indicators/constants/tables/heads/comfortableEnvironment/socEcoTH_2_4';
import { SOC_ECO_TH_2_5 } from '@widgets/widget-soceco-indicators/constants/tables/heads/comfortableEnvironment/socEcoTH_2_5';
import { SOC_ECO_TH_3_1 } from '@widgets/widget-soceco-indicators/constants/tables/heads/environmentalSustainability/socEcoTH_3_1';
import { SOC_ECO_TH_3_2 } from '@widgets/widget-soceco-indicators/constants/tables/heads/environmentalSustainability/socEcoTH_3_2';
import { SOC_ECO_TH_3_3 } from '@widgets/widget-soceco-indicators/constants/tables/heads/environmentalSustainability/socEcoTH_3_3';
import { SOC_ECO_TH_3_4 } from '@widgets/widget-soceco-indicators/constants/tables/heads/environmentalSustainability/socEcoTH_3_4';
import { SOC_ECO_TH_3_5 } from '@widgets/widget-soceco-indicators/constants/tables/heads/environmentalSustainability/socEcoTH_3_5';
import { SOC_ECO_TH_3_6 } from '@widgets/widget-soceco-indicators/constants/tables/heads/environmentalSustainability/socEcoTH_3_6';
import { SOC_ECO_TH_3_7 } from '@widgets/widget-soceco-indicators/constants/tables/heads/environmentalSustainability/socEcoTH_3_7';
import { SOC_ECO_TH_4_1 } from '@widgets/widget-soceco-indicators/constants/tables/heads/healthAndSafety/socEcoTH_4_1';
import { SOC_ECO_TH_4_2 } from '@widgets/widget-soceco-indicators/constants/tables/heads/healthAndSafety/socEcoTH_4_2';
import { SOC_ECO_TH_4_3 } from '@widgets/widget-soceco-indicators/constants/tables/heads/healthAndSafety/socEcoTH_4_3';
import { SOC_ECO_TH_4_4 } from '@widgets/widget-soceco-indicators/constants/tables/heads/healthAndSafety/socEcoTH_4_4';
import { SOC_ECO_TH_5_1 } from '@widgets/widget-soceco-indicators/constants/tables/heads/integrations/socEcoTH_5_1';
import { SOC_ECO_TH_5_2 } from '@widgets/widget-soceco-indicators/constants/tables/heads/integrations/socEcoTH_5_2';
import { SOC_ECO_TH_5_3 } from '@widgets/widget-soceco-indicators/constants/tables/heads/integrations/socEcoTH_5_3';
import { SOC_ECO_TH_5_4 } from '@widgets/widget-soceco-indicators/constants/tables/heads/integrations/socEcoTH_5_4';
import { SOC_ECO_TH_5_5 } from '@widgets/widget-soceco-indicators/constants/tables/heads/integrations/socEcoTH_5_5';
import { SOC_ECO_TH_5_6 } from '@widgets/widget-soceco-indicators/constants/tables/heads/integrations/socEcoTH_5_6';
import { SOC_ECO_TH_5_7 } from '@widgets/widget-soceco-indicators/constants/tables/heads/integrations/socEcoTH_5_7';
import { SOC_ECO_TH_5_8 } from '@widgets/widget-soceco-indicators/constants/tables/heads/integrations/socEcoTH_5_8';
import { SOC_ECO_TH_7_1 } from '@widgets/widget-soceco-indicators/constants/tables/heads/manufacturability/socEcoTH_7_1';
import { SOC_ECO_TH_7_2 } from '@widgets/widget-soceco-indicators/constants/tables/heads/manufacturability/socEcoTH_7_2';
import { SOC_ECO_TH_7_3 } from '@widgets/widget-soceco-indicators/constants/tables/heads/manufacturability/socEcoTH_7_3';
import { SOC_ECO_TH_7_4 } from '@widgets/widget-soceco-indicators/constants/tables/heads/manufacturability/socEcoTH_7_4';
import { SOC_ECO_TH_7_5 } from '@widgets/widget-soceco-indicators/constants/tables/heads/manufacturability/socEcoTH_7_5';
import { SOC_ECO_TH_6_1 } from '@widgets/widget-soceco-indicators/constants/tables/heads/opennessAndTransparency/socEcoTH_6_1';
import { SOC_ECO_TH_6_2 } from '@widgets/widget-soceco-indicators/constants/tables/heads/opennessAndTransparency/socEcoTH_6_2';
import { SOC_ECO_TH_6_3 } from '@widgets/widget-soceco-indicators/constants/tables/heads/opennessAndTransparency/socEcoTH_6_3';
import { SOC_ECO_TH_1_1 } from '@widgets/widget-soceco-indicators/constants/tables/heads/socEcoSustainability/socEcoTH_1_1';
import { SOC_ECO_TH_1_2 } from '@widgets/widget-soceco-indicators/constants/tables/heads/socEcoSustainability/socEcoTH_1_2';
import { SOC_ECO_TH_1_3 } from '@widgets/widget-soceco-indicators/constants/tables/heads/socEcoSustainability/socEcoTH_1_3';
import { SOC_ECO_TH_1_4 } from '@widgets/widget-soceco-indicators/constants/tables/heads/socEcoSustainability/socEcoTH_1_4';
import { SOC_ECO_TH_1_5 } from '@widgets/widget-soceco-indicators/constants/tables/heads/socEcoSustainability/socEcoTH_1_5';
import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';

export const SOC_ECO_TABLE_HEADS = {
  [SOCECO_TABLE_IDS.TABLE_1_1]: SOC_ECO_TH_1_1,
  [SOCECO_TABLE_IDS.TABLE_1_2]: SOC_ECO_TH_1_2,
  [SOCECO_TABLE_IDS.TABLE_1_3]: SOC_ECO_TH_1_3,
  [SOCECO_TABLE_IDS.TABLE_1_4]: SOC_ECO_TH_1_4,
  [SOCECO_TABLE_IDS.TABLE_1_5]: SOC_ECO_TH_1_5,

  [SOCECO_TABLE_IDS.TABLE_2_1]: SOC_ECO_TH_2_1,
  [SOCECO_TABLE_IDS.TABLE_2_2]: SOC_ECO_TH_2_2,
  [SOCECO_TABLE_IDS.TABLE_2_3]: SOC_ECO_TH_2_3,
  [SOCECO_TABLE_IDS.TABLE_2_4]: SOC_ECO_TH_2_4,
  [SOCECO_TABLE_IDS.TABLE_2_5]: SOC_ECO_TH_2_5,

  [SOCECO_TABLE_IDS.TABLE_3_1]: SOC_ECO_TH_3_1,
  [SOCECO_TABLE_IDS.TABLE_3_2]: SOC_ECO_TH_3_2,
  [SOCECO_TABLE_IDS.TABLE_3_3]: SOC_ECO_TH_3_3,
  [SOCECO_TABLE_IDS.TABLE_3_4]: SOC_ECO_TH_3_4,
  [SOCECO_TABLE_IDS.TABLE_3_5]: SOC_ECO_TH_3_5,
  [SOCECO_TABLE_IDS.TABLE_3_6]: SOC_ECO_TH_3_6,
  [SOCECO_TABLE_IDS.TABLE_3_7]: SOC_ECO_TH_3_7,

  [SOCECO_TABLE_IDS.TABLE_4_1]: SOC_ECO_TH_4_1,
  [SOCECO_TABLE_IDS.TABLE_4_2]: SOC_ECO_TH_4_2,
  [SOCECO_TABLE_IDS.TABLE_4_3]: SOC_ECO_TH_4_3,
  [SOCECO_TABLE_IDS.TABLE_4_4]: SOC_ECO_TH_4_4,

  [SOCECO_TABLE_IDS.TABLE_5_1]: SOC_ECO_TH_5_1,
  [SOCECO_TABLE_IDS.TABLE_5_2]: SOC_ECO_TH_5_2,
  [SOCECO_TABLE_IDS.TABLE_5_3]: SOC_ECO_TH_5_3,
  [SOCECO_TABLE_IDS.TABLE_5_4]: SOC_ECO_TH_5_4,
  [SOCECO_TABLE_IDS.TABLE_5_5]: SOC_ECO_TH_5_5,
  [SOCECO_TABLE_IDS.TABLE_5_6]: SOC_ECO_TH_5_6,
  [SOCECO_TABLE_IDS.TABLE_5_7]: SOC_ECO_TH_5_7,
  [SOCECO_TABLE_IDS.TABLE_5_8]: SOC_ECO_TH_5_8,

  [SOCECO_TABLE_IDS.TABLE_6_1]: SOC_ECO_TH_6_1,
  [SOCECO_TABLE_IDS.TABLE_6_2]: SOC_ECO_TH_6_2,
  [SOCECO_TABLE_IDS.TABLE_6_3]: SOC_ECO_TH_6_3,

  [SOCECO_TABLE_IDS.TABLE_7_1]: SOC_ECO_TH_7_1,
  [SOCECO_TABLE_IDS.TABLE_7_2]: SOC_ECO_TH_7_2,
  [SOCECO_TABLE_IDS.TABLE_7_3]: SOC_ECO_TH_7_3,
  [SOCECO_TABLE_IDS.TABLE_7_4]: SOC_ECO_TH_7_4,
  [SOCECO_TABLE_IDS.TABLE_7_5]: SOC_ECO_TH_7_5,
};
