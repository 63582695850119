import { Box, Divider, IconButton, Typography } from '@mui/material';

import { ChevronRightIcon } from '@components/icons';

import { InfrastructureInspectorType } from '@features/ag-project/stores/inspector/infrastructure';
import { BoxData } from '@features/passenger-traffic/components/Inspector/BoxData';

type TransportDistrictInspectorProps = {
  fields: InfrastructureInspectorType['transportDistrict'];
  onClose: () => void;
};

export const TransportDistrictInspector = (
  props: TransportDistrictInspectorProps,
) => {
  const {
    fields: {
      name,
      workplaces,
      serviceWorkplaces,
      studyPlaces,
      retailSpaces,
      numOfGardenNoncommercialPartnerships,
      population,
      averageSalary,
      districtType,
    },
    onClose,
  } = props;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 3,
        }}
      >
        <IconButton
          sx={{
            width: '32px',
            height: '32px',
            backgroundColor: 'primary.main',
            borderRadius: 1,
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={onClose}
        >
          <ChevronRightIcon />
        </IconButton>
        <Typography variant="subtitle2">{name}</Typography>
      </Box>

      <BoxData
        name={'Тип района'}
        value={districtType || 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Население'}
        value={`${population} чел.`}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Места учебы'}
        value={`${studyPlaces} ед.`}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Торговые площади'}
        value={`${retailSpaces} кв м`}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Рабочие места'}
        value={`${workplaces} ед.`}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Рабочие места в сфере услуг'}
        value={`${serviceWorkplaces} ед.`}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Среднемесячная заработная плата'}
        value={`${averageSalary} руб.`}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Площадь садовых некоммерческих товариществ'}
        value={`${numOfGardenNoncommercialPartnerships} ед.`}
      />
    </>
  );
};
