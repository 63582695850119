export const unitsOfMeasure = [
  {
    value: 'unit',
    label: 'ед',
  },
  {
    value: 'thousand',
    label: 'тыс',
  },
  {
    value: 'million',
    label: 'млн',
  },
  {
    value: 'billion',
    label: 'млрд',
  },
];
