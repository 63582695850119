export const agComparisonTPUTableHeadCells = [
  {
    id: 'name',
    disablePadding: false,
    label: 'Название ТПУ',
  },
  {
    id: 'variant',
    disablePadding: false,
    label: 'Варианты размещения ТПУ',
  },
  {
    id: 'ttc',
    disablePadding: false,
    label: ['СТЗ, руб. *', 'СТЗ, руб. **'],
  },
  {
    id: 'passTraffic',
    disablePadding: false,
    label: 'Пассажиропоток, пасс.',
  },
];
