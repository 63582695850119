import { sample } from 'effector';
import { Feature } from 'ol';
import { Point, Polygon } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EMapFeatureLayout } from '@constants/map';

import {
  LayersVisibleApi,
  pipeAgSearchInfraVectorLayer,
} from '@features/ag-forecast/stores/map';
import {
  $SearchInfra,
  SearchInfraApi,
} from '@features/ag-forecast/stores/map/searchInfra';
import { $YearLineStore } from '@features/ag-forecast/stores/yearLine';

import { recursivelyFromLonLat } from '@utils/geometry';
import {
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';
import { EFeatureType } from '@utils/map/styles/agSearchInfraVectorStyle';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload && Object.keys(payload).includes(EMapFeatureLayout.agSearchInfra),
  fn: payload => {
    return {
      action: EPipeVectorLayer.setVisible,
      payload: payload![EMapFeatureLayout.agSearchInfra],
    } as PipeVectorLayer;
  },
  target: pipeAgSearchInfraVectorLayer,
});

// Рендер геометрии
sample({
  clock: SearchInfraApi.setGeometry,
  source: {
    YearLineStore: $YearLineStore,
    SearchInfra: $SearchInfra,
  },
  fn: ({ YearLineStore, SearchInfra }) => {
    const features: Feature<Point | Polygon>[] = [];

    if (SearchInfra.features.railwayStation) {
      features.push(
        new Feature({
          geometry: new Point(
            fromLonLat(SearchInfra.features.railwayStation.coordinates),
          ),
          isPlanned:
            SearchInfra.features.railwayStation.year >
            YearLineStore.selectedYear,
          isTpu: SearchInfra.features.railwayStation.isTpu,
          type: EFeatureType.station,
        }),
      );
    } else if (SearchInfra.features.transportDistrict) {
      features.push(
        new Feature({
          geometry: new Polygon(
            recursivelyFromLonLat(
              SearchInfra.features.transportDistrict.geometry,
            ),
          ),
          type: EFeatureType.distict,
        }),
      );
    } else if (SearchInfra.features.transportZone) {
      features.push(
        new Feature({
          geometry: new Polygon(
            recursivelyFromLonLat(SearchInfra.features.transportZone.geometry),
          ),
          type: EFeatureType.zone,
        }),
      );
    }

    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: {
        features,
      },
    } as PipeVectorLayer;
  },
  target: pipeAgSearchInfraVectorLayer,
});
