export enum EAgeGroups {
  underWorkingAge = 'Моложе трудоспособного',
  workingAge = 'Трудоспособный',
  overWorkingAge = 'Старше трудоспособного',
}

export enum ESex {
  male = 'Мужчины',
  female = 'Женщины',
}
