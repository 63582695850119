import { SvgIcon, SvgIconProps } from '@mui/material';

export const DoorOutIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill={props.fill ?? 'none'}
      {...props}
    >
      <rect
        x=".5"
        width="24"
        height="24"
        rx="2"
        fill={props.fill ?? 'none'}
      />
      <path
        fill={props.fill ?? 'none'}
        stroke="currentColor"
        strokeWidth="2"
        d="M2.5 2h20v20h-20z"
      />
      <path
        d="M12.235 11.308V14.5h.647V8.953l.862.902L15 11.169v-.774L12.552 7.73 10 10.401v.815l1.383-1.371.852-.845v2.308Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M7.5 16.5h10V15h-10v1.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
