import { IQuery, IQueryGetScenarioGraphArgs } from '@api/gql/types';
import { gql } from '@apollo/client';
import { apiClient } from 'src/api/provider';

export const queryGetScenarioGraph = async (
  params: IQueryGetScenarioGraphArgs,
) => {
  const result = await apiClient.query<IQuery, IQueryGetScenarioGraphArgs>({
    query: gql`
      query QueryGetScenarioGraph($scenarioId: UUID!) {
        getScenarioGraph(scenarioId: $scenarioId) {
          id
          scenarioStations {
            id
            name
            transportRegion {
              id
              name
            }
            vertex {
              id
              theGeom
            }
            railwayStation {
              id
            }
          }
          instances {
            startStation {
              id
            }
            endStation {
              id
            }
            id
            name
            geometry
            type
            year
            speed
            relatedStations {
              id
            }
          }
        }
      }
    `,
    variables: params,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result?.data?.getScenarioGraph;
};
