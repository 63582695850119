import { IconButton, Stack, TextField, Typography } from '@mui/material';

import { FormLabel } from '@components/FormLabel';
import { ChevronRightIcon } from '@components/icons';

type RailwayStationsInspectorProps = {
  fields: {
    name: string | null;
    capacity: number | null;
  };
  onClose: () => void;
};

export const RailwayStationsInspector = (
  props: RailwayStationsInspectorProps,
) => {
  const {
    fields: { name, capacity },
    onClose,
  } = props;

  return (
    <Stack
      height="100%"
      direction="column"
      justifyContent="space-between"
    >
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          mb={3}
        >
          <IconButton
            sx={{
              width: '32px',
              height: '32px',
              backgroundColor: 'primary.main',
              borderRadius: 1,
              color: 'white',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            }}
            onClick={onClose}
          >
            <ChevronRightIcon />
          </IconButton>
          <Typography variant="subtitle2">ЖД станция</Typography>
        </Stack>

        <FormLabel
          id="tpuName"
          title="Наименование"
        >
          <TextField
            disabled
            id="tpuName"
            placeholder={'Название ЖД станции'}
            value={name}
            sx={{
              mb: 3,
            }}
          />
        </FormLabel>

        <FormLabel
          id="tpuCapacity"
          title="Пропускная способность (пасс. / ч.)"
        >
          <TextField
            disabled
            id="tpuCapacity"
            value={capacity || 'н/д'}
            placeholder={'Пропускная способность станции'}
            sx={{
              mb: 3,
            }}
          />
        </FormLabel>
      </Stack>
    </Stack>
  );
};
