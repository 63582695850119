import { createApi, createStore } from 'effector';

import { EVehicle } from '@features/pt-forecast/constants/vehiclesEnum';

import { unitsOfMeasure } from '@utils/dashboardTools';

const initStore: PassTrafficExtendedType = {
  tableForYear: [],
  passengerTrafficPercent: [],
  unit: unitsOfMeasure[2].value,
};

export const $PassTrafficExtended =
  createStore<PassTrafficExtendedType>(initStore);

export const PassengerTrafficExtendedApi = createApi($PassTrafficExtended, {
  setPassTrafficExtended: (store, payload: PassTrafficExtendedType) => ({
    ...store,
    tableForYear: payload.tableForYear,
    passengerTrafficPercent: payload.passengerTrafficPercent,
  }),
  setUnit: (store, payload: string) => ({
    ...store,
    unit: payload,
  }),
  reset: () => initStore,
});

export type PassTrafficExtendedType = {
  passengerTrafficPercent: Array<
    Record<keyof typeof EVehicle, number> & { year: number }
  >;
  tableForYear: Array<
    Record<keyof typeof EVehicle, number> & {
      year: number;
      total: number;
    }
  >;
  unit: string;
};
