import { SvgIcon, SvgIconProps } from '@mui/material';

export const BusStationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="10"
          cy="10"
          r="9.5"
          fill="#FF792E"
          stroke="white"
        />
        <path
          d="M11.9758 5H9.97581H7.95968C7.00806 5 6.25 5.75806 6.25 6.67742V12.5645C6.25 13.3871 6.84677 14.0161 7.44355 14.1774V14.7097C7.44355 14.871 7.57258 15 7.73387 15H8.18548C8.34677 15 8.47581 14.871 8.47581 14.7097V14.1935H11.4758V14.7097C11.4758 14.871 11.6048 15 11.7661 15H12.2177C12.379 15 12.5081 14.871 12.5081 14.7097V14.1774C13.1048 14.0161 13.7016 13.3871 13.7016 12.5645V6.67742C13.6855 5.75806 12.9113 5 11.9758 5ZM8.99194 5.69355C8.99194 5.59677 9.07258 5.51613 9.16935 5.51613H10.75C10.8468 5.51613 10.9274 5.59677 10.9274 5.69355V6.16129C10.9274 6.25806 10.8468 6.33871 10.75 6.33871H9.16935C9.07258 6.33871 8.99194 6.25806 8.99194 6.16129V5.69355ZM7.91129 13.1774C7.55645 13.1774 7.28226 12.8871 7.28226 12.5323C7.28226 12.1774 7.57258 11.8871 7.91129 11.8871C8.25 11.8871 8.54032 12.1774 8.54032 12.5323C8.54032 12.9032 8.26613 13.1774 7.91129 13.1774ZM11.9919 13.1774C11.6371 13.1774 11.3629 12.8871 11.3629 12.5323C11.3629 12.1774 11.6532 11.8871 11.9919 11.8871C12.3468 11.8871 12.621 12.1774 12.621 12.5323C12.621 12.9032 12.3306 13.1774 11.9919 13.1774ZM12.6371 8.62903C12.6371 8.83871 12.5726 9.01613 12.4435 9.16129C12.3145 9.29032 12.121 9.37097 11.9113 9.37097H8.00806C7.79839 9.37097 7.60484 9.29032 7.47581 9.16129C7.34677 9.03226 7.28226 8.83871 7.28226 8.62903V7.56452C7.28226 7.19355 7.50806 6.82258 8.00806 6.82258H11.9113C12.4113 6.82258 12.6371 7.19355 12.6371 7.56452V8.62903Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
