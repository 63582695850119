import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { YearLineV2 } from '@components/YearLine_v2';

import { $UI } from '@features/pt-forecast-new/stores/ui';
import {
  $YearLine,
  YearLineApi,
} from '@features/pt-forecast-new/stores/yearLine';

export const YearLineContainer = () => {
  const { panels, showDashboard } = useUnit($UI);
  const { minYear, maxYear, selectedYear } = useUnit($YearLine);
  const { setSelectedYear } = YearLineApi;

  const ContainerSx = useMemo<SxProps<Theme>>(
    () => ({
      position: 'absolute',
      bottom: '0',
      left:
        panels.filter || panels.paramsForecast || panels.newInfrastructure
          ? '500px'
          : '0',
      right:
        (panels.inspector || panels.forecastDetails) && !showDashboard
          ? '500px'
          : '0',
      zIndex: '1',
      background: theme => theme.palette.background.default,
    }),
    [
      panels.filter,
      panels.forecastDetails,
      panels.inspector,
      panels.newInfrastructure,
      panels.paramsForecast,
      showDashboard,
    ],
  );

  const selectYearHandler = useCallback(
    (year: number) => {
      setSelectedYear(year);
    },
    [setSelectedYear],
  );

  return (
    <Box sx={ContainerSx}>
      <YearLineV2
        baseYear={minYear}
        finalYear={maxYear}
        selectedYear={selectedYear}
        onSelect={selectYearHandler}
      />
    </Box>
  );
};
