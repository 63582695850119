import { IAgentShareInfoType, IQuery } from '@api/gql/tpu-types';

import {
  EditableObjectType,
  ObjectOfTheSimulationModel,
  SimulationModelPlanData,
} from '@features/tpu-simulation-model/types';

import { AgentsShareAnalyzer } from '@utils/simulationModel/errors-analyzer/agents-share-analyzer';
import { EntranceExitErrorAnalyzer } from '@utils/simulationModel/errors-analyzer/entrance-exit-analyzer';
import { PathPlatformErrorAnalyzer } from '@utils/simulationModel/errors-analyzer/path-platfrom-analyzer';
import { PortalsErrorAnalyzer } from '@utils/simulationModel/errors-analyzer/portals-analyzer';
import { RoomErrorAnalyzer } from '@utils/simulationModel/errors-analyzer/room-analyzer';
import { RulerErrorAnalyzer } from '@utils/simulationModel/errors-analyzer/ruler-analyzer';
import { ServiceObjectsErrorAnalyzer } from '@utils/simulationModel/errors-analyzer/service-objects-analyzer';
import {
  IModelErrorAnalyzerType,
  PlanError,
} from '@utils/simulationModel/errors-analyzer/types';

export class ModelErrorAnalyzer implements IModelErrorAnalyzerType {
  _model: null | SimulationModelPlanData = null;
  _agentsShare: null | IAgentShareInfoType[] = null;
  _listOfObjects: null | ObjectOfTheSimulationModel[] = null;
  _rulerErrorAnalyzer = new RulerErrorAnalyzer();
  _entranceExitErrorAnalyzer = new EntranceExitErrorAnalyzer();
  _pathPlatformErrorAnalyzer = new PathPlatformErrorAnalyzer();
  _roomErrorAnalyzer = new RoomErrorAnalyzer();
  _portalsErrorAnalyzer = new PortalsErrorAnalyzer();
  _serviceObjectsErrorAnalyzer = new ServiceObjectsErrorAnalyzer();
  _agentsShareAnalyzer = new AgentsShareAnalyzer();

  _reset() {
    this._model = null;
    this._agentsShare = null;
    this._entranceExitErrorAnalyzer.reset();
    this._pathPlatformErrorAnalyzer.reset();
    this._roomErrorAnalyzer.reset();
    this._portalsErrorAnalyzer.reset();
    this._rulerErrorAnalyzer.reset();
    this._serviceObjectsErrorAnalyzer.reset();
    this._agentsShareAnalyzer.reset();
  }

  _prepareData() {
    if (this._model)
      this._listOfObjects = Object.values(this._model.plan).flatMap(
        Object.values,
      );
  }

  _transferDataToAnalyzer(data: ObjectOfTheSimulationModel) {
    switch (data.type) {
      case EditableObjectType.Room: {
        this._roomErrorAnalyzer.addRoom(data);
        this._portalsErrorAnalyzer.addObject(data);
        this._serviceObjectsErrorAnalyzer.addObject(data);
        break;
      }
      case EditableObjectType.Exit: {
        this._entranceExitErrorAnalyzer.addExit(data);
        this._portalsErrorAnalyzer.addObject(data);
        break;
      }
      case EditableObjectType.PassengerGenerator: {
        this._entranceExitErrorAnalyzer.addEntrance(data);
        this._portalsErrorAnalyzer.addObject(data);
        break;
      }
      case EditableObjectType.Path: {
        this._pathPlatformErrorAnalyzer.addPath(data);
        break;
      }
      case EditableObjectType.Platform: {
        this._pathPlatformErrorAnalyzer.addPlatform(data);
        this._portalsErrorAnalyzer.addObject(data);
        break;
      }
      case EditableObjectType.Ladder:
      case EditableObjectType.Elevator:
      case EditableObjectType.Door:
      case EditableObjectType.Turnstile:
      case EditableObjectType.SecurityCheckpoint: {
        this._portalsErrorAnalyzer.addObject(data);
        break;
      }
      case EditableObjectType.Bank:
      case EditableObjectType.TicketPrintingMachine:
      case EditableObjectType.TicketOffice:
      case EditableObjectType.InformationTable:
      case EditableObjectType.Toilet:
      case EditableObjectType.Waiting:
      case EditableObjectType.Restaurant:
      case EditableObjectType.Buffet:
      case EditableObjectType.VendingMachine:
      case EditableObjectType.Store:
      case EditableObjectType.Kiosk:
      case EditableObjectType.Bankomat:
      case EditableObjectType.Other: {
        this._agentsShareAnalyzer.addObject(data);
        this._serviceObjectsErrorAnalyzer.addObject(data);
        break;
      }
      default:
        break;
    }
  }

  updateData(data: {
    plan: SimulationModelPlanData | null;
    agents: IQuery['allAgentShareInfo'];
  }) {
    this._reset();
    this._model = data.plan;
    this._agentsShare =
      data.agents &&
      data.agents.filter((item): item is IAgentShareInfoType => !!item);
  }

  getErrors() {
    this._prepareData();

    this._agentsShareAnalyzer.updateData(this._agentsShare);

    if (this._listOfObjects) {
      this._listOfObjects.forEach(object =>
        this._transferDataToAnalyzer(object),
      );
    }
    if (this._model?.ruler) {
      this._rulerErrorAnalyzer.updateData(this._model.ruler);
    }

    const rulerErrors = this._rulerErrorAnalyzer.getErrors();
    const roomErrors = this._roomErrorAnalyzer.getErrors();
    const entranceExitErrors = this._entranceExitErrorAnalyzer.getErrors();
    const pathPlatformErrors = this._pathPlatformErrorAnalyzer.getErrors();
    const portalErrors = this._portalsErrorAnalyzer.getErrors();
    const serviceObjectsErrors = this._serviceObjectsErrorAnalyzer.getErrors();
    const agentsShareErrors = this._agentsShareAnalyzer.getErrors();

    return new Array<PlanError>().concat(
      rulerErrors,
      roomErrors,
      entranceExitErrors,
      pathPlatformErrors,
      portalErrors,
      serviceObjectsErrors,
      agentsShareErrors,
    );
  }
}
