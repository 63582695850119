import { useEffect } from 'react';

import './store';

import { Component } from '@features/tpu-simulation-model/component';
import {
  SimulationModelHandlersContext,
  useSimulationModelHandlers,
} from '@features/tpu-simulation-model/hooks';
import { Modals } from '@features/tpu-simulation-model/modals';

export const SimulationModelContainer = () => {
  const value = useSimulationModelHandlers();

  useEffect(() => {
    value.getScenarioId();

    return () => value.resetSimulationModel();
  }, [value]);

  return (
    <SimulationModelHandlersContext.Provider value={value}>
      <Component />
      <Modals />
    </SimulationModelHandlersContext.Provider>
  );
};
