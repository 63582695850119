import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const MuiDialogActions: Components<Theme>['MuiDialogActions'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      gap: theme.spacing(3),
      borderTop: '1px solid' + theme.palette.border.divider,
    }),
  },
};
