import { EVehicle } from '@features/pt-forecast/constants/vehiclesEnum';
import { createEvent, createStore } from 'effector';

export type UnevennessChartsState = {
  unevennessVehicle: Record<keyof typeof EVehicle, number[]> | null;
  unevennessSummary: number[];
};

export const $UnevennessCharts = createStore<UnevennessChartsState>({
  unevennessVehicle: null,
  unevennessSummary: [],
});

export const setUnevennessVehicle =
  createEvent<UnevennessChartsState['unevennessVehicle']>();
export const setUnevennessSummary =
  createEvent<UnevennessChartsState['unevennessSummary']>();

export const resetUnevenness = createEvent();

$UnevennessCharts
  .on(setUnevennessVehicle, (state, payload) => ({
    ...state,
    unevennessVehicle: payload,
  }))
  .on(setUnevennessSummary, (state, payload) => ({
    ...state,
    unevennessSummary: payload,
  }))
  .reset(resetUnevenness);
