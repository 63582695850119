import { apiClient } from '@api/client';
import { IQuery, IQueryGetScenarioByIdArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

export const querySocEcoTable_1_5 = async (
  scenario: IQueryGetScenarioByIdArgs,
) => {
  const result = await apiClient.query<
    Pick<IQuery, 'getScenarioById'>,
    IQueryGetScenarioByIdArgs
  >({
    query: gql`
      query QuerySocEcoTable_1_5($scenarioId: String!) {
        getScenarioById(scenarioId: $scenarioId) {
          increaseEmploymentOpportunities {
            year
            workplaces
            stzSum
            name
            id
            delta
            crsId
          }
        }
      }
    `,
    variables: scenario,
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.getScenarioById;
};
