import { Grid, Typography } from '@mui/material';

import { LineIcon, LineIconProps } from '@components/Map/LineIcon';

type LineItemProps = Pick<LineIconProps, 'color'> & {
  planned?: boolean;
  label: string;
};

const plannedStyle = {
  borderWidth: '2px',
  borderStyle: 'dashed',
};

export const LineItem = (props: LineItemProps) => {
  const { planned, label, color } = props;

  return (
    <Grid
      display={'grid'}
      container
      gridTemplateColumns={'2fr 10fr'}
      gap={2}
      alignItems={'center'}
    >
      <LineIcon
        color={color}
        style={planned ? plannedStyle : undefined}
        height={1}
      />
      <Typography
        variant="body2"
        color="text.secondary"
      >
        {label}
      </Typography>
    </Grid>
  );
};
