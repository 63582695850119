import { createEffect, createEvent, createStore } from 'effector';

interface DeletePassengerTypeDataType {
  isOpen: boolean;
  isResolve: boolean;
  isLoading: boolean;
  id: string | null;
  name: string | null;
}

export const $DeletePassengerType = createStore<DeletePassengerTypeDataType>({
  id: null,
  isLoading: false,
  isOpen: false,
  isResolve: false,
  name: null,
});

export const resetDeletePassengerTypeStoreFn = createEvent<void>();

export const openModalDeletePassengerTypeFn = createEvent<{
  id: string;
  name: string;
}>();

export const closeModalDeletePassengerTypeFn = createEvent<void>();

export const triggerRemovePassengerTypeFn = createEvent<void>();

export const removePassengerTypeFx = createEffect<string, boolean>();
