import { Icon, Stroke, Style } from 'ol/style';

import RailwayStationSVG from '@components/icons/RailwayStation.svg';
import RailwayStationRedSVG from '@components/icons/RailwayStationRed.svg';

import { EMapFeatureMetaKeys } from '@constants/map';

import { VectorStyleProps } from '@utils/map/styles/types';
import { createActiveFeatureBorder } from '@utils/map/tools/createActiveFeatureBorder';

export const agRailwayStationVectorStyle = ({
  feature,
  featureStyles,
}: VectorStyleProps) => {
  const isSelected =
    featureStyles &&
    Object.keys(featureStyles).includes(
      String(feature?.get(EMapFeatureMetaKeys.olId)),
    );

  const styles = isSelected ? [createActiveFeatureBorder(4)] : [];
  const isPlanned = feature?.get('isPlanned') || false;
  const isTpu = feature?.get('isTpu') || false;

  styles.push(
    new Style({
      image: new Icon({
        src: isTpu ? RailwayStationRedSVG : RailwayStationSVG,
        opacity: isPlanned ? 0.5 : 1,
      }),
      stroke: new Stroke({
        color: '#ffffff',
        width: 1,
        lineDash: [4, 4],
      }),
    }),
  );
  return styles;
};
