import { useUnit } from 'effector-react';
import { useMemo } from 'react';
import { Layer, Line, Rect } from 'react-konva';

import {
  $Floors,
  $Heatmap,
  $ListOfObjects,
  $PlanSettings,
  $Player,
  $Ruler,
} from '@features/tpu-calculation-scenarios-viewing/stores';
import {
  EditableObjectType,
  IPlatform,
  IRoom,
} from '@features/tpu-simulation-model/types';

const getColor = (value: number = 0) => {
  let color = '#b5aaf9';
  if (value > 0) color = '#b1fbcb';
  if (value > 10) color = '#f7faa8';
  if (value > 100) color = '#f9e0a2';
  if (value > 400) color = '#f2a4a9';
  if (value > 1000) color = '#d0352a';
  return color;
};

export const Heatmap = () => {
  const $heatMap = useUnit($Heatmap);
  const $listOfObjects = useUnit($ListOfObjects);
  const $floors = useUnit($Floors);
  const $ruler = useUnit($Ruler);
  const $player = useUnit($Player);
  const $planSettings = useUnit($PlanSettings);

  const ratio = ($ruler?.px ?? 100) / ($ruler?.meters ?? 1);
  const listByType = useMemo(() => {
    const rooms: IRoom[] = [];
    const platforms: IPlatform[] = [];

    if ($listOfObjects) {
      Object.values($listOfObjects).forEach(value => {
        if (value.type === EditableObjectType.Room) rooms.push(value);
        if (value.type === EditableObjectType.Platform) platforms.push(value);
      });
    }

    return {
      rooms,
      platforms,
    };
  }, [$listOfObjects]);
  const currentHeatmap =
    $heatMap?.[$player.lastCalculatedSegment]?.[$floors.selectedFloor];

  if (!currentHeatmap || !$planSettings.isHeatmapOn) return null;

  return (
    <Layer>
      {listByType.platforms.map(val => (
        <Rect
          key={val.uuid}
          fill={getColor()}
          x={val.point.x}
          y={val.point.y}
          opacity={0.5}
          rotation={val.rotation}
          width={val.width}
          height={val.height}
        />
      ))}
      {listByType.rooms.map(val => (
        <Line
          points={val.points.flatMap(({ x, y }) => [x, y])}
          fill={getColor()}
          closed
          opacity={0.5}
        />
      ))}
      {currentHeatmap.map((val, key) => (
        <Rect
          key={key}
          fill={getColor(val.q)}
          x={val.x * ratio}
          y={val.y * ratio}
          opacity={0.5}
          width={1.2 * ratio}
          height={1.2 * ratio}
        />
      ))}
    </Layer>
  );
};
