import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import { Box, Divider, IconButton, Typography } from '@mui/material';

import { ChevronRightIcon } from '@components/icons';

import { BoxData } from '@features/pt-forecast-new/components/Inspector/BoxData';
import { $InspectorInfrastructure } from '@features/pt-forecast-new/stores/inspector/infrastructure';
import { UIApi } from '@features/pt-forecast-new/stores/ui';

import { convertUnitOfMeasure } from '@utils/dataForInspector';

export const AirportInspector = () => {
  const { airport } = useUnit($InspectorInfrastructure);
  const { setVisibleInspector } = useUnit(UIApi);

  const onClose = useCallback(() => {
    setVisibleInspector(false);
  }, [setVisibleInspector]);

  const passTraffic = useMemo(() => {
    const value = airport?.passTraffic;

    if (!value) return 'н/д';

    return (
      convertUnitOfMeasure({
        value,
        unit: 'чел.',
        singleDigitAccuracy: 0,
      }) || 'н/д'
    );
  }, [airport?.passTraffic]);

  const capacityCommon = useMemo(() => {
    const value = airport?.capacity;

    if (!value) return 'н/д';

    return (
      convertUnitOfMeasure({
        value,
        unit: 'чел./час',
        singleDigitAccuracy: 0,
      }) || 'н/д'
    );
  }, [airport?.capacity]);

  const range = useMemo(() => {
    const value = airport?.range;

    if (!value) return 'н/д';

    return (
      convertUnitOfMeasure({
        value,
        unit: 'км.',
        singleDigitAccuracy: 0,
      }) || 'н/д'
    );
  }, [airport?.range]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 3,
        }}
      >
        <IconButton
          sx={{
            width: '32px',
            height: '32px',
            backgroundColor: 'primary.main',
            borderRadius: 1,
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={onClose}
        >
          <ChevronRightIcon />
        </IconButton>

        <Typography variant="subtitle2">Аэропорт</Typography>
      </Box>

      <BoxData
        name={'Название'}
        value={airport?.name || 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Пассажирооборот на базовый год'}
        value={passTraffic}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Пропускная способность'}
        value={capacityCommon}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Максимальная дальность рейсов'}
        value={range}
      />
    </>
  );
};
