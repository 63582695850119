import { sample } from 'effector';

import { EMapFeatureLayout } from '@constants/map';

import { EMapViewMode } from '@features/ag-forecast/constants/EMapViewMode';
import {
  EInspectorType,
  InspectorApi,
} from '@features/ag-forecast/stores/inspector/store';
import { $SelectedFeatures } from '@features/ag-forecast/stores/map';
import { $UI, UIApi } from '@features/ag-forecast/stores/ui';

// Отобразить инспектор, если есть выделенные фичи
sample({
  clock: $SelectedFeatures,
  fn: store => !!store.length,
  target: UIApi.setInspector,
});

// Установить вид инспектора
sample({
  clock: $SelectedFeatures,
  source: { SelectedFeatures: $SelectedFeatures },
  fn: ({ SelectedFeatures }) => {
    return SelectedFeatures[0]?.layout || null;
  },
  target: InspectorApi.setLayout,
});

// Определение типа инспектора
sample({
  clock: $SelectedFeatures,
  source: { SelectedFeatures: $SelectedFeatures, UI: $UI },
  fn: ({ SelectedFeatures, UI }) => {
    if (!SelectedFeatures.length) return null;
    switch (SelectedFeatures[0]?.layout) {
      case EMapFeatureLayout.tpu:
        return EInspectorType.tpu;
      case EMapFeatureLayout.tpuGraphs:
        return EInspectorType.tpuGraph;
      case EMapFeatureLayout.graphAuto:
      case EMapFeatureLayout.graphMetro:
      case EMapFeatureLayout.graphTrolleybus:
      case EMapFeatureLayout.graphSuburbanRailway:
      case EMapFeatureLayout.graphFunicular:
      case EMapFeatureLayout.graphBus:
      case EMapFeatureLayout.graphPedestrain:
      case EMapFeatureLayout.graphRopeWay:
      case EMapFeatureLayout.graphMonoRailWay:
      case EMapFeatureLayout.graphAvia:
      case EMapFeatureLayout.graphWaterWay:
      case EMapFeatureLayout.graphTram:
      case EMapFeatureLayout.agRailwayStations:
        return UI.viewMode === EMapViewMode.infrastructure
          ? EInspectorType.infrastructure
          : EInspectorType.passFlow;
      default:
        return null;
    }
  },
  target: InspectorApi.setInspectorType,
});

// Если нет выбранных фич, отобразить инспектор прогноза
sample({
  clock: $SelectedFeatures,
  filter: store => !store.length,
  fn: () => EInspectorType.passengerTraffic,
  target: InspectorApi.setInspectorType,
});
