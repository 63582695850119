type XAxisTickProps = {
  x: number;
  y: number;
  payload: {
    value: number;
  };
};

export const XAxisTick = (props: XAxisTickProps) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#666"
        fontSize={10}
      >
        {payload.value}
      </text>
    </g>
  );
};
