import { MouseEventHandler } from 'react';

import { IconButton } from '@mui/material';

import { CloseIcon } from '@components/icons';

interface DialogCloseButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const DialogCloseButton = (props: DialogCloseButtonProps) => {
  const { onClick } = props;

  return (
    <IconButton
      className="dialog-close-button"
      sx={{
        position: 'absolute',
        right: 24,
        top: 24,
        color: 'primary.main',
      }}
      onClick={onClick}
    >
      <CloseIcon />
    </IconButton>
  );
};
