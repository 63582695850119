import {
  CustomMap,
  getFeatureStyle,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import VectorTileLayer from 'ol/layer/VectorTile';
import { Style } from 'ol/style';

export type SetStyleTileLayerArgs = {
  map?: CustomMap | null;
  layout: EMapFeatureLayout;
  width?: number;
};

/** Базовая установка стилей для тайлового слоя */
export const setStyleTileLayer = (args: SetStyleTileLayerArgs) => {
  const { layout, map, width } = args;
  if (!map) return;

  map.getLayers().forEach(layer => {
    if (layer.getClassName() !== layout) return;
    // Не поддерживает не тайловые слои
    if (!(layer instanceof VectorTileLayer)) return;

    layer.setStyle(
      (feature, resolution) =>
        getFeatureStyle({
          layout: layer.getClassName() as EMapFeatureLayout,
          active: false,
          filterMapLayout: map.filterLayout,
          width,
          feature,
          resolution,
          map,
        }) as Style,
    );
  });
};
