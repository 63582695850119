export const SOC_ECO_TH_4_4 = [
  {
    id: 'transportArea',
    label: 'Транспортный район',
  },
  // {
  //   id: 'project',
  //   label: 'Проект',
  // },
  // {
  //   id: 'buildingAreaLength',
  //   label: 'Длина участка строительства',
  // },
  {
    id: 'numberJobsCreated',
    label: 'Число созданных рабочих мест',
  },
];
