import { IShareOfEntranceExitType } from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

export const $ListOfEntrances = createStore<IShareOfEntranceExitType[] | null>(
  [],
);
export const $ListOfExits = createStore<IShareOfEntranceExitType[] | null>([]);

export const getEntrancesExitsByScenarioIdFx = createEffect<
  string,
  {
    entrances: IShareOfEntranceExitType[];
    exits: IShareOfEntranceExitType[];
  }
>();

export const resetEntrancesExitsFn = createEvent();
