import { useUnit } from 'effector-react';

import { Box, Typography } from '@mui/material';

import { $Floors } from '@features/tpu-simulation-model/store';
import { $ListOfRulers } from '@features/tpu-simulation-model/store/ruler/listOfRulers.store';
import { IPoint } from '@features/tpu-simulation-model/types';

interface ObjectSizeM2RoomProps {
  points: IPoint[];
}
export function ObjectSizeM2Room({ points }: ObjectSizeM2RoomProps) {
  /*
   * Формула Гаусса для нахождения площади многоугольника по координатам его вершин:
   * S = 0.5 * |(x1y2 + x2y3 + ... + xn-1yn + xny1) - (y1x2 + y2x3 + ... + ynx1 + yn-1x1)|
   * Где (x1, y1), (x2, y2), ..., (xn, yn) - координаты вершин многоугольника, а |...| обозначает модуль разности двух выражений.
   */
  let summ1 = 0;
  let summ2 = 0;
  points.forEach((point, i) => {
    if (i < points.length - 1) {
      summ1 += point.x * points[i + 1].y;
      summ2 += point.y * points[i + 1].x;
    } else {
      summ1 += point.x * points[0].y;
      summ2 += point.y * points[0].x;
    }
  });
  let square = 0.5 * Math.abs(summ1 - summ2);
  const $listOfRulers = useUnit($ListOfRulers) ?? {};
  const $floors = useUnit($Floors);
  const ruler = $listOfRulers[$floors.selectedFloor];

  if (ruler) {
    square = (square / Math.pow(ruler.px, 2)) * Math.pow(ruler.meters, 2);
  }
  square = Math.round(square);

  return (
    <Box>
      <Typography>
        Размеры,
        <Typography
          variant={'body1'}
          component={'span'}
          sx={{
            color: theme => theme.palette.text.secondary,
            display: 'inline-block',
            ml: '4px',
          }}
        >
          {ruler ? (
            <>
              м
              <Typography
                variant={'body1'}
                component={'sup'}
                sx={{
                  fontSize: theme => theme.typography.pxToRem(10),
                  position: 'relative',
                  top: '-4px',
                }}
              >
                2
              </Typography>
            </>
          ) : (
            'px'
          )}
        </Typography>
      </Typography>
      <Typography
        sx={{
          color: theme => theme.palette.text.secondary,
          fontSize: theme => theme.typography.pxToRem(12),
          display: 'inline-block',
          ml: '4px',
        }}
      >
        {square}
      </Typography>
    </Box>
  );
}
