import {
  IQuery,
  IQueryGetBusBetweenTrsArgs,
  IQueryGetPlaneBetweenTrsArgs,
  IQueryGetRailwayBetweenTrsArgs,
  Maybe,
} from '@api/gql/types';
import { createEffect, createEvent, createStore } from 'effector';

export type RouteNetworkInspectorType = {
  nightTrains: IQuery['getRailwayBetweenTrs'];
  dayTrains: IQuery['getRailwayBetweenTrs'];
  suburbanTrains: IQuery['getRailwayBetweenTrs'];
  buses: IQuery['getBusBetweenTrs'];
  airplanes: IQuery['getPlaneBetweenTrs'];
  nightTrain: Omit<DataVehicleType, 'frequency'>;
  dayTrain: Omit<DataVehicleType, 'frequency'>;
  suburbanTrain: Omit<DataVehicleType, 'frequency'>;
  bus: DataVehicleType;
  auto: Omit<DataVehicleType, 'frequency'>;
  avia: DataVehicleType;
};

export type DataVehicleType = {
  price: Maybe<number> | undefined;
  duration: Maybe<number> | undefined;
  frequency: Maybe<number> | undefined;
};

export const $RouteNetworkInspector = createStore<RouteNetworkInspectorType>({
  nightTrains: [],
  dayTrains: [],
  suburbanTrains: [],
  buses: [],
  airplanes: [],
  nightTrain: {
    price: 0,
    duration: 0,
  },
  dayTrain: {
    price: 0,
    duration: 0,
  },
  suburbanTrain: {
    price: 0,
    duration: 0,
  },
  bus: {
    price: 0,
    duration: 0,
    frequency: 0,
  },
  auto: {
    price: 0,
    duration: 0,
  },
  avia: {
    price: 0,
    duration: 0,
    frequency: 0,
  },
});

export const getRailwayBetweenTrsFx = createEffect<
  IQueryGetRailwayBetweenTrsArgs,
  IQuery['getRailwayBetweenTrs']
>();

export const getBusBetweenTrsFx = createEffect<
  IQueryGetBusBetweenTrsArgs,
  IQuery['getBusBetweenTrs']
>();

export const getPlaneBetweenTrsFx = createEffect<
  IQueryGetPlaneBetweenTrsArgs,
  IQuery['getPlaneBetweenTrs']
>();

export const setRailwayBetweenTrs = createEvent<{
  result: IQuery['getRailwayBetweenTrs'];
  params: IQueryGetRailwayBetweenTrsArgs;
}>();

export const setBusBetweenTrs = createEvent<{
  result: IQuery['getBusBetweenTrs'];
  params: IQueryGetBusBetweenTrsArgs;
}>();

export const setPlaneBetweenTrs = createEvent<{
  result: IQuery['getPlaneBetweenTrs'];
  params: IQueryGetPlaneBetweenTrsArgs;
}>();

export const setDataVehicles = createEvent<{
  nightTrain: Omit<DataVehicleType, 'frequency'>;
  dayTrain: Omit<DataVehicleType, 'frequency'>;
  suburbanTrain: Omit<DataVehicleType, 'frequency'>;
  bus: DataVehicleType;
  auto: Omit<DataVehicleType, 'frequency'>;
  avia: DataVehicleType;
}>();

$RouteNetworkInspector
  .on(setRailwayBetweenTrs, (state, payload) => {
    switch (payload.params.type) {
      case 1:
        return {
          ...state,
          nightTrains: payload.result,
        };

      case 2:
        return {
          ...state,
          dayTrains: payload.result,
        };

      case 3:
        return {
          ...state,
          suburbanTrains: payload.result,
        };
    }
  })
  .on(setBusBetweenTrs, (state, payload) => ({
    ...state,
    buses: payload.result,
  }))
  .on(setPlaneBetweenTrs, (state, payload) => ({
    ...state,
    airplanes: payload.result,
  }))
  .on(setDataVehicles, (state, payload) => ({
    ...state,
    ...payload,
  }));
