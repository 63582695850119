import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

import { EMapFeatureMetaKeys } from '@constants/map';

import { EFeatureStyle } from '@utils/map/hooks/useInitVectorLayer';
import { VectorStyleProps } from '@utils/map/styles/types';

export const tpuGraphsVectorStyle = (props: VectorStyleProps) => {
  const { featureStyles, feature } = props;

  const defaultStroke = new Style({
    stroke: new Stroke({
      color: '#DB3F3F',
      width: 3,
    }),
    image: new CircleStyle({
      radius: 4,
      fill: new Fill({
        color: '#DB3F3F',
      }),
    }),
  });

  if (
    featureStyles &&
    Object.keys(featureStyles).includes(
      String(feature?.get(EMapFeatureMetaKeys.olId)),
    )
  ) {
    const style = featureStyles[feature?.get(EMapFeatureMetaKeys.olId)];
    switch (style) {
      case EFeatureStyle.selected:
        return [
          new Style({
            image: new CircleStyle({
              radius: 5,
              fill: new Fill({
                color: '#0a0a0a',
              }),
            }),
            stroke: new Stroke({
              color: '#0a0a0a',
              width: 4,
            }),
          }),
          new Style({
            image: new CircleStyle({
              radius: 4,
              fill: new Fill({
                color: '#DB3F3F',
              }),
            }),
            stroke: new Stroke({
              color: '#DB3F3F',
              width: 3,
            }),
          }),
        ];
      default:
        return defaultStroke;
    }
  }
  return defaultStroke;
};
