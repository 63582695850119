import { apiClient } from '@api/client';
import {
  IMutation,
  IMutationSaveCustomInfrastructureArgs,
  IMutationScenarioComputeArgs,
  IMutationUpdateCustomInfrastructureArgs,
} from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import './graph';
import './tpu';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import { $Vehicles } from '@features/ag-forecast/stores/lists/vehicles';
import {
  $EditorMap,
  fetchTpuInfrastructure,
  runComputeScenario,
  runComputeScenarioFx,
  saveInfrastructureFx,
  saveTpuInfrastructure,
  updateInfrastructure,
  updateInfrastructureFx,
} from '@features/ag-forecast/stores/map/editor';
import {
  $FeatureSettings,
  fetchScenarioStatus,
} from '@features/ag-forecast/stores/settings';

//
sample({
  clock: saveTpuInfrastructure,
  source: { EditorMap: $EditorMap },
  fn: ({ EditorMap }) => {
    return {
      graphIds: EditorMap.graphs
        .filter(graph => !graph.isSaved)
        .map(graph => graph.id),
      tpuIds: EditorMap.tpus.filter(tpu => !tpu.isSaved).map(tpu => tpu.id),
    } as IMutationSaveCustomInfrastructureArgs;
  },
  target: saveInfrastructureFx,
});

// Сохранить инфраструктуру
saveInfrastructureFx.use(async params => {
  const response = await apiClient.mutate<
    Pick<IMutation, 'saveCustomInfrastructure'>,
    IMutationSaveCustomInfrastructureArgs
  >({
    mutation: gql`
      mutation MutationSaveCustomInfrastructure(
        $graphIds: [UUID!]
        $tpuIds: [UUID!]
      ) {
        saveCustomInfrastructure(graphIds: $graphIds, tpuIds: $tpuIds) {
          ok
        }
      }
    `,
    variables: {
      graphIds: params.graphIds,
      tpuIds: params.tpuIds,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data?.saveCustomInfrastructure || null;
});

// Обновить параметры отредактированной инфраструктуры
sample({
  clock: updateInfrastructure,
  source: { EditorMap: $EditorMap, Vehicles: $Vehicles },
  fn: ({ EditorMap, Vehicles }) => {
    return {
      tpuParams: EditorMap.tpus
        .filter(tpu => tpu.isUpdated)
        .map(tpu => ({
          tpuId: tpu.id,
          name: tpu.name,
          capacity: tpu.capacity,
          floorsNumber: tpu.floorsNumber,
          parentTpuId: tpu.parentTpuId,
          delete: tpu.delete,
        })),
      graphParams: EditorMap.graphs
        .filter(graph => graph.isUpdated)
        .map(graph => ({
          name: graph.name,
          capacity: graph.capacity,
          laneNum: graph.laneNum,
          year: graph.year,
          capacityHour: graph.capacityHour,
          cost: graph.cost,
          category: graph.category,
          freeSpeed: graph.freeSpeed,
          oneway: graph.oneway,
          scenarioGraphId: graph.id,
          parkingCo: graph.parkingCo,
          zone: graph.zone,
          transports: graph.vehicles.map(
            vehicleId => Vehicles!.find(v => v.vehicleId === vehicleId)!.id,
          ),
          frequenceWeekend: graph.frequencyWeekend,
          frequenceWeekdays: graph.frequencyWeekdays,
          delete: graph.delete,
        })),
    } as IMutationUpdateCustomInfrastructureArgs;
  },
  target: updateInfrastructureFx,
});

updateInfrastructureFx.use(async params => {
  const response = await apiClient.mutate<
    Pick<IMutation, 'updateCustomInfrastructure'>,
    IMutationUpdateCustomInfrastructureArgs
  >({
    mutation: gql`
      mutation MutationUpdateCustomInfrastructure(
        $graphParams: [UpdateGraphParamsInput]
        $tpuParams: [UpdateTPUParamsInput]
      ) {
        updateCustomInfrastructure(
          graphParams: $graphParams
          tpuParams: $tpuParams
        ) {
          ok
        }
      }
    `,
    variables: {
      graphParams: params.graphParams,
      tpuParams: params.tpuParams,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data?.updateCustomInfrastructure || null;
});

// ЕСли обновление параметров инфраструктуры прошло успешно, перезапросить и запустить перерасчет
sample({
  clock: updateInfrastructureFx.done,
  target: [fetchTpuInfrastructure, runComputeScenario],
});

// Запустить текущий сценарий в расчет
sample({
  clock: runComputeScenario,
  source: { FeatureSettings: $FeatureSettings },
  fn: ({ FeatureSettings }) => {
    return {
      scenarioId: FeatureSettings.scenarioUUID,
    } as IMutationScenarioComputeArgs;
  },
  target: runComputeScenarioFx,
});

runComputeScenarioFx.use(async params => {
  const result = await apiClient.mutate<
    IMutation,
    IMutationScenarioComputeArgs
  >({
    mutation: gql`
      mutation ComputeScenarioMutation($scenarioId: String!) {
        scenarioCompute(scenarioId: $scenarioId) {
          ok
        }
      }
    `,
    variables: {
      scenarioId: params.scenarioId,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  return result.data?.scenarioCompute || null;
});

// если запуск расчета прошел успешно, перезапросить статус сценария
sample({
  clock: runComputeScenarioFx.done,
  target: fetchScenarioStatus,
});

