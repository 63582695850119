import { SetFeatureStyleProps } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/getFeatureStyle';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

import { EFilterMapLayout } from '@components/MapFilters';
import RailwayStationSVG from '@components/icons/RailwayStation.svg';
import RailwayStationSelectedSVG from '@components/icons/RailwayStationSelected.svg';

export type GetRailwayStationArgs = Pick<
  SetFeatureStyleProps,
  'active' | 'filterMapLayout'
>;

export const getRailwayStation = ({
  active,
  filterMapLayout,
}: GetRailwayStationArgs) => {
  if (filterMapLayout === EFilterMapLayout.RouteNetwork)
    return new Style({
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: '#FFF',
        }),
        stroke: new Stroke({
          color: '#5A5A5A',
          width: active ? 2 : 1,
        }),
      }),
      stroke: new Stroke({
        color: '#5A5A5A',
        width: 1,
      }),
      fill: new Fill({
        color: '#FFF',
      }),
    });
  else
    return new Style({
      image: new Icon({
        src: active ? RailwayStationSelectedSVG : RailwayStationSVG,
      }),
    });
};
