import {
  IComputingRegistryType,
  IQuery,
  IQueryGetComputingRegistryInstanceByIdArgs,
} from '@api/gql/types';
import { createApi, createEffect, createStore } from 'effector';

const initStore: TimelineType = {
  baseYear: null,
  finalYear: null,
  selectedYear: 0,
};

export const $Timeline = createStore<TimelineType>(initStore);

export const getYearsTimelineFx = createEffect<
  IQueryGetComputingRegistryInstanceByIdArgs,
  IQuery['getComputingRegistryInstanceById']
>();

export const TimelineApi = createApi($Timeline, {
  setYearsTimeline: (store, payload: Omit<TimelineType, 'selectedYear'>) => ({
    ...store,
    ...payload,
  }),
  selectYear: (store, payload: TimelineType['selectedYear']) => ({
    ...store,
    selectedYear: payload,
  }),
  reset: () => initStore,
});

export type TimelineType = Pick<IComputingRegistryType, 'baseYear'> &
  Pick<IComputingRegistryType, 'finalYear'> & { selectedYear: number };
