import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { Box, OutlinedInput, Typography } from '@mui/material';

interface AttributeProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  title: string;
  type?: string;
  min?: number;
  max?: number;
  step?: number;
}

export function Input<T extends FieldValues>({
  control,
  name,
  title,
  type,
  min = 0,
  max,
  step,
}: AttributeProps<T>) {
  const isNumber = type === 'number';

  return (
    <Box>
      <Typography mb={1}>{title}</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <OutlinedInput
            value={value}
            onChange={e => {
              let val: string | number = e.target.value;
              if (isNumber) {
                val = parseFloat(val);
                if (val < min) val = min;
                if (max && val > max) val = max;
              }
              onChange(val);
            }}
            fullWidth
            type={type}
            sx={{
              backgroundColor: theme =>
                `${theme.palette.background.default} !important`,
            }}
            inputProps={{
              step: step,
              onWheel: event => event.currentTarget.blur(),
            }}
          />
        )}
      />
    </Box>
  );
}
