import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={44}
      height={44}
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.667 22c0-10.084 8.25-18.334 18.333-18.334 10.083 0 18.333 8.25 18.333 18.333 0 10.084-8.25 18.334-18.333 18.334-10.084 0-18.334-8.25-18.334-18.334Zm15.674 9.164-7.874-7.873a1.83 1.83 0 0 1 2.585-2.589l5.289 5.274L29.854 15.17a1.834 1.834 0 1 1 2.594 2.594L19.341 31.164Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
