import { useUnit } from 'effector-react';
import Konva from 'konva';
import { useCallback, useEffect, useRef } from 'react';
import { Image, Layer, Stage } from 'react-konva';
import useImage from 'use-image';

import { Box } from '@mui/material';

import {
  AgentsMovement,
  Controllers,
  Heatmap,
  MiniMap,
  SpatialObjects,
} from '@features/tpu-calculation-scenarios-viewing/components/plan';
import { useContextCalculationsScenariosViewingHandlers } from '@features/tpu-calculation-scenarios-viewing/hooks/useCalculationScenariosViewingHandlers';
import {
  $Floors,
  $PlanSettings,
} from '@features/tpu-calculation-scenarios-viewing/stores';

const SCALE_BY = 1.1;

export const PlanContainer = () => {
  const $floors = useUnit($Floors);
  const $planSettings = useUnit($PlanSettings);

  const [floorImg] = useImage($floors.backgroundUrl);

  const {
    selectFloor,
    setScale,
    incrementScale,
    decrementScale,
    onTriggeredCenter,
    triggerCenter,
    setModelBlockSize,
    setPosition,
    setOpacity,
    toggleHeatmap,
  } = useContextCalculationsScenariosViewingHandlers();

  const stageRef = useRef<Konva.Stage | null>(null);
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const instance = ref.current;
    if (instance) {
      const updateRect = () => {
        const { width, height } = instance.getBoundingClientRect();

        setModelBlockSize({ width, height });
      };
      updateRect();
      window.addEventListener('resize', updateRect);

      return () => window.removeEventListener('resize', updateRect);
    }
  }, [setModelBlockSize]);

  useEffect(() => {
    if ($planSettings.shouldCenterStagePosition) {
      stageRef.current?.position({ x: 0, y: 0 });
      onTriggeredCenter();
    }
  }, [$planSettings.shouldCenterStagePosition, onTriggeredCenter]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: theme => theme.palette.background.default,
        position: 'relative',
        overflow: 'auto',
      }}
      ref={useCallback((instance: HTMLDivElement) => {
        ref.current = instance;
      }, [])}
    >
      <Controllers
        floors={$floors.floorsInfo}
        onSelectFloor={selectFloor}
        opacity={$planSettings.opacity}
        onOpacityChange={setOpacity}
        selectedFloor={$floors.selectedFloor}
        incrementScale={incrementScale}
        decrementScale={decrementScale}
        onCenter={triggerCenter}
        isHeatmapOn={$planSettings.isHeatmapOn}
        onToggleHeatmap={toggleHeatmap}
      />
      <Stage
        width={$planSettings.width}
        height={$planSettings.height}
        scaleX={$planSettings.scale}
        scaleY={$planSettings.scale}
        onDragEnd={() => {
          const stage = stageRef.current;
          if (!stage) return;
          setPosition(stage.position());
        }}
        onWheel={e => {
          // stop default scrolling
          e.evt.preventDefault();
          const stage = stageRef.current;
          const pointer = stage?.getPointerPosition();
          if (!stage || !pointer) return;

          const oldScale = stage.scaleX();

          const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
          };

          // how to scale? Zoom in? Or zoom out?
          let direction = e.evt.deltaY > 0 ? 1 : -1;

          // when we zoom on trackpad, e.evt.ctrlKey is true
          // in that case lets revert direction
          if (e.evt.ctrlKey) {
            direction = -direction;
          }

          let newScale =
            direction > 0 ? oldScale * SCALE_BY : oldScale / SCALE_BY;
          if (newScale > 3) {
            newScale = 3;
          }
          if (newScale < 0.25) {
            newScale = 0.25;
          }

          setScale(newScale);

          const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
          };
          stage.position(newPos);
          setPosition(newPos);
        }}
        draggable={true}
        ref={stageRef}
      >
        <Layer>
          <Image image={floorImg} />
        </Layer>
        <SpatialObjects />
        <Layer>
          <AgentsMovement />
        </Layer>
        <Heatmap />
      </Stage>
      <MiniMap />
    </Box>
  );
};
