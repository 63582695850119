import { SetFeatureStyleProps } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/getFeatureStyle';
import { Fill, Stroke, Style } from 'ol/style';

export type GetAreaBorderStyleArgs = Pick<
  SetFeatureStyleProps,
  'width' | 'active'
>;

export const getAreaBorderStyle = ({
  active,
  width,
}: GetAreaBorderStyleArgs) => [
  new Style({
    stroke: new Stroke({
      color: '#50A583',
      width,
    }),
    fill: new Fill({
      color: active ? 'rgba(80,165,131,0.2)' : 'transparent',
    }),
  }),
];
