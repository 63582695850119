import {
  IQuery,
  IQuery as IQueryAg,
  IQueryGetTransportDistrictByIdArgs,
} from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';
import { apiClient } from 'src/api/provider';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';
import { EMapFeatureLayout } from '@constants/map';

import { DISTRICT_TYPES } from '@features/ag-project/constants/DistrictTypes';
import { getDataForInspectorFn } from '@features/ag-project/stores/inspector';
import {
  GetObjectType,
  InfrastructureInspectorApi,
  InfrastructureInspectorType,
  getGraphsForInspectorFx,
  getRailwayStationForInspectorFx,
  getTransportDistrictForInspectorFx,
} from '@features/ag-project/stores/inspector/infrastructure/store';
import { $FeatureSettings } from '@features/ag-project/stores/settings';

const availableGraphs = [
  EMapFeatureLayout.graphAuto,
  EMapFeatureLayout.graphBus,
  EMapFeatureLayout.graphTrolleybus,
  EMapFeatureLayout.graphTram,
  EMapFeatureLayout.graphMetro,
  EMapFeatureLayout.graphSuburbanRailway,
  EMapFeatureLayout.graphRopeWay,
  EMapFeatureLayout.graphWaterWay,
  EMapFeatureLayout.graphFunicular,
  EMapFeatureLayout.graphMonoRailWay,
  EMapFeatureLayout.graphPedestrain,
  EMapFeatureLayout.graphAvia,
];

// Запросить данные для слоя из списка графов
sample({
  clock: getDataForInspectorFn,
  filter: payload =>
    Boolean(payload.layout && availableGraphs.includes(payload.layout)),
  target: getGraphsForInspectorFx,
});

// Запросить данные для слоя жд станции
sample({
  clock: getDataForInspectorFn,
  filter: payload =>
    Boolean(payload.layout === EMapFeatureLayout.agRailwayStations),
  target: getRailwayStationForInspectorFx,
});

// Запросить данные для слоя транспортного района
sample({
  clock: getDataForInspectorFn,
  source: { FeatureSettings: $FeatureSettings },
  filter: (_, { layout }) => layout === EMapFeatureLayout.transportDistricts,
  fn: ({ FeatureSettings }, { id }) => {
    return {
      projectId: FeatureSettings.projectUUID,
      districtId: id,
    } as IQueryGetTransportDistrictByIdArgs;
  },
  target: getTransportDistrictForInspectorFx,
});

// триггерим событие на отображение данных жд станции для инспектора на слое инфраструктуры
sample({
  clock: getRailwayStationForInspectorFx.done,
  fn: request =>
    ({
      id: request.result?.id || null,
      name: request.result?.name || '',
      year: request.result?.year || null,
      isTpu: request.result?.isTpu || null,
      capacity: request.result?.capacity || null,
    }) as InfrastructureInspectorType['railwayStation'],
  target: InfrastructureInspectorApi.setRailwayStationForInspector,
});

sample({
  clock: getGraphsForInspectorFx.done,
  fn: request => {
    return {
      transport: request.result?.transport || [],
      laneNum: request.result?.laneNum || 1,
      freeSpeed: request.result?.freeSpeed || 0,
      category: request.result?.category || '',
      capacity: request.result?.capacity || 0,
      oneway: request.result?.oneway || false,
    } as InfrastructureInspectorType['graphs'];
  },
  target: InfrastructureInspectorApi.setGraphsForInspector,
});

sample({
  clock: getTransportDistrictForInspectorFx.done,
  fn: request => {
    return {
      studyPlaces: request.result?.socEcoParams?.studyPlaces || 0,
      population: request.result?.socEcoParams?.population || 0,
      averageSalary: request.result?.socEcoParams?.averageSalary || 0,
      serviceWorkplaces: request.result?.socEcoParams?.serviceWorkplaces || 0,
      retailSpaces: request.result?.socEcoParams?.retailSpaces || 0,
      workplaces: request.result?.socEcoParams?.workplaces || 0,
      numOfGardenNoncommercialPartnerships:
        request.result?.socEcoParams?.numOfGardenNoncommercialPartnerships || 0,
      municipality: request.result?.municipality || '',
      name: request.result?.name || '',
      id: request.result?.socEcoParams?.id || null,
      districtType: request.result?.socEcoParams?.districtType
        ? DISTRICT_TYPES[request.result?.socEcoParams?.districtType]
        : null,
    } as InfrastructureInspectorType['transportDistrict'];
  },
  target: InfrastructureInspectorApi.setTransportDistrictForInspector,
});

getGraphsForInspectorFx.use(async params => {
  const response = await apiClient.query<
    IQueryAg,
    Omit<GetObjectType, 'layout'>
  >({
    query: gql`
      query QueryGetGraphsById($id: String!) {
        getGraphInstanceById(id: $id) {
          category
          transport
          laneNum
          oneway
          freeSpeed
          capacity
        }
      }
    `,
    variables: {
      id: params.id,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data.getGraphInstanceById;
});

getRailwayStationForInspectorFx.use(async params => {
  const response = await apiClient.query<
    IQueryAg,
    Omit<GetObjectType, 'layout'>
  >({
    query: gql`
      query QueryGetRailwayStationById($id: String!) {
        getRailwayStationInstanceById(id: $id) {
          id
          name
          isTpu
          year
          capacity
        }
      }
    `,
    variables: {
      id: params.id,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data.getRailwayStationInstanceById;
});

getTransportDistrictForInspectorFx.use(async params => {
  const response = await apiClient.query<
    Pick<IQuery, 'getTransportDistrictById'>,
    IQueryGetTransportDistrictByIdArgs
  >({
    query: gql`
      query QueryGetTransportDistrictById(
        $projectId: UUID!
        $districtId: UUID!
      ) {
        getTransportDistrictById(
          projectId: $projectId
          districtId: $districtId
        ) {
          name
          municipality
          socEcoParams {
            population
            studyPlaces
            averageSalary
            serviceWorkplaces
            retailSpaces
            workplaces
            numOfGardenNoncommercialPartnerships
            id
            districtType
          }
        }
      }
    `,
    variables: {
      projectId: params.projectId,
      districtId: params.districtId,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  return response.data.getTransportDistrictById || null;
});
