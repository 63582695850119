import { useUnit } from 'effector-react';

import { InfraLegend } from '@features/ag-forecast/components/MapLegend/InfraLegend';
import { PassFlowLegend } from '@features/ag-forecast/components/MapLegend/PassFlowLegend';
import { EMapViewMode } from '@features/ag-forecast/constants/EMapViewMode';
import { $UI } from '@features/ag-forecast/stores/ui';

export const MapLegend = () => {
  const { viewMode } = useUnit($UI);
  return viewMode === EMapViewMode.infrastructure ? (
    <InfraLegend />
  ) : (
    <PassFlowLegend />
  );
};
