import { styled } from '@mui/material';

interface FreeCircleIconProps {
  color: string;
  border: string;
  size?: number;
}

export const FreeCircleIcon = styled('div')<FreeCircleIconProps>(
  ({ theme, color, border, size = 12 }) => ({
    border: '1px solid',
    borderColor: border,
    borderRadius: '50%',
    width: theme.typography.pxToRem(size),
    height: theme.typography.pxToRem(size),
    backgroundColor: color,
  }),
);
