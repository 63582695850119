import { useUnit } from 'effector-react';
import { useMemo } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { YearLine } from '@components/YearLine';

import { $UI } from '@features/ag-project/stores/ui';
import { $YearLineStore } from '@features/ag-project/stores/yearLine';

export const YearLineContainer = () => {
  const { filter, inspector } = useUnit($UI);
  const { minYear, maxYear } = useUnit($YearLineStore);

  const ContainerSx = useMemo<SxProps<Theme>>(
    () => ({
      position: 'absolute',
      bottom: '0',
      left: '500px',
      right: inspector ? '400px' : '0',
      zIndex: '2',
      background: theme => theme.palette.background.default,
    }),
    [inspector],
  );

  return (
    <Box sx={ContainerSx}>
      {filter && (
        <YearLine
          baseYear={minYear}
          finalYear={maxYear}
          disabled={true}
        />
      )}
    </Box>
  );
};
