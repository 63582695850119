import {
  IComputingRegistryType,
  IHighwayType,
  IQuery,
  IQueryGetComputedPassTrafficArgs,
  IRailwayType,
} from '@api/gql/types';
import { createEffect, createEvent, createStore } from 'effector';

import { EVehicle } from '@features/pt-forecast/constants/vehiclesEnum';
import { EDailyUnevennessTime } from '@features/pt-forecast/stores/charts/dailyUnevenness/store';

const initStore: CalculationScenarioState = {
  computingresultsSet: [],
  isLoading: false,
  computingRegistry: {
    socEcoScenario: null,
    transportScenario: null,
    name: '',
    link: null,
  },
};

// Стор с результатами расчета по сценарию
export const $CalculationScenario =
  createStore<CalculationScenarioState>(initStore);

export const pushPassTrafficForSelectYear =
  createEvent<ComputingResults | null>();

// Сеттер стора
export const setComputingresultsSet =
  createEvent<CalculationScenarioState['computingresultsSet']>();
export const setComputingRegistry =
  createEvent<CalculationScenarioState['computingRegistry']>();

export const setComputingIsLoading = createEvent<boolean>();

export const updateLinkComputingRegistry =
  createEvent<IComputingRegistryType['link']>();

// Эффект запроса данных для сценария по id
export const fetchCalculationScenarioFx = createEffect<
  { id: string },
  Pick<IQuery, 'getComputingRegistryInstanceById'>
>();

export const getComputedPassTrafficFx = createEffect<
  IQueryGetComputedPassTrafficArgs,
  IQuery['getComputedPassTraffic']
>();

export const resetComputed = createEvent();

$CalculationScenario
  .on(setComputingresultsSet, (state, payload) => ({
    ...state,
    computingresultsSet: payload,
  }))
  .on(setComputingRegistry, (state, payload) => ({
    ...state,
    computingRegistry: payload,
  }))
  .on(setComputingIsLoading, (state, payload) => ({
    ...state,
    isLoading: payload,
  }))
  .on(updateLinkComputingRegistry, (state, payload) => ({
    ...state,
    computingRegistry: {
      ...state.computingRegistry,
      link: payload,
    },
  }))
  .reset(resetComputed);

export type ComputingResults = {
  id: string;
  year: number;
  passengerTraffic: Record<keyof typeof EVehicle, number> & {
    totalFlow: number;
  };
  price: Record<keyof typeof EVehicle, number>;
  frequency: Record<keyof typeof EVehicle, number>;
  duration: Record<keyof typeof EVehicle, number>;
  unevenness: Record<keyof typeof EVehicle, number[]>;
  dailyUnevenness: Record<
    keyof typeof EVehicle,
    Record<EDailyUnevennessTime, number | null>
  > | null;
  direction: {
    endDate: number;
    startDate: number;
    id: string;
    highways: IHighwayType[];
    railways: IRailwayType[];
  };
};

export type CalculationScenarioState = {
  computingresultsSet: ComputingResults[];
  isLoading: boolean;
  computingRegistry: Pick<
    IComputingRegistryType,
    'transportScenario' | 'socEcoScenario' | 'name' | 'link'
  >;
};
