export enum EVehicleAg {
  auto = 1,
  bus = 2,
  trolleybus = 3,
  tram = 4,
  metro = 5,
  suburbanRailway = 6,
  ropeWay = 7,
  waterWay = 8,
  funicular = 9,
  monoRailWay = 10,
  pedestrain = 11,
  avia = 12,
}
