import { Fill, Icon, Stroke, Style } from 'ol/style';

import RailwayStationSVG from '@components/icons/RailwayStation.svg';
import RailwayStationRedSVG from '@components/icons/RailwayStationRed.svg';

import { VectorStyleProps } from '@utils/map/styles/types';
import { createActiveFeatureBorder } from '@utils/map/tools/createActiveFeatureBorder';

export enum EFeatureType {
  'zone',
  'distict',
  'station',
}

export const agSearchInfraVectorStyle = (props: VectorStyleProps) => {
  const { feature } = props;
  const isSelected = true;
  const featureType = feature?.get('type') as EFeatureType;
  const styles: Style[] = [];

  if (featureType === EFeatureType.station) {
    const isPlanned = feature?.get('isPlanned') || false;
    const isTpu = feature?.get('isTpu') || false;
    if (isSelected) styles.push(createActiveFeatureBorder(4));

    styles.push(
      new Style({
        image: new Icon({
          src: isTpu ? RailwayStationRedSVG : RailwayStationSVG,
          opacity: isPlanned ? 0.5 : 1,
        }),
        stroke: new Stroke({
          color: '#ffffff',
          width: 1,
          lineDash: [4, 4],
        }),
      }),
    );
  } else if (featureType === EFeatureType.distict) {
    styles.push(
      new Style({
        stroke: new Stroke({
          color: 'rgba(119,177,208,0.70)',
          width: 2,
        }),
        fill: new Fill({
          color: 'rgba(169,218,239,0.40)',
        }),
      }),
    );
  } else if (featureType === EFeatureType.zone) {
    styles.push(
      new Style({
        stroke: new Stroke({
          color: '#50A583',
          width: 1,
        }),
        fill: new Fill({
          color: 'rgba(80,165,131,0.11)',
        }),
      }),
    );
  }

  return styles;
};
