import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import {
  Box,
  Button,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

import { ChevronRightIcon } from '@components/icons/ChevronRight';

import { CashedField } from '@features/pt-forecast-new/components/Inspector/CashedField';
import { YearCashedField } from '@features/pt-forecast-new/components/Inspector/YearCashedField';
import { ECustomGraphRailwayType } from '@features/pt-forecast-new/constants/eCustomGraphRailwayType';
import { speedsByInfrastructureTypes } from '@features/pt-forecast-new/constants/paramsByInfrastructureTypes';
import {
  $EditorMap,
  EditorMapApi,
  deleteGraphFx,
} from '@features/pt-forecast-new/stores/map/editor';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';
import { $YearLine } from '@features/pt-forecast-new/stores/yearLine';

export const InspectorCustomRailwayGraph = () => {
  const { selectedGraph, graphs } = useUnit($EditorMap);
  const { updateSelectedGraphParams } = useUnit(EditorMapApi);
  const { editMode } = useUnit($UI);
  const { setVisibleInspector } = useUnit(UIApi);
  const { minYear, maxYear } = useUnit($YearLine);

  const selectInfrastructureTypeHandler = (event: SelectChangeEvent) => {
    const value = event.target.value as keyof typeof ECustomGraphRailwayType;
    updateSelectedGraphParams({
      infrastructureType: value,
      maxSpeed: speedsByInfrastructureTypes[value],
    });
  };

  const selectedGraphItem = useMemo(() => {
    return graphs.find(station => station.id === selectedGraph);
  }, [graphs, selectedGraph]);

  const changeNameHandler = useCallback(
    (value: string) => {
      updateSelectedGraphParams({ name: value });
    },
    [updateSelectedGraphParams],
  );

  const changeMaxSpeedHandler = useCallback(
    (value: string) => {
      updateSelectedGraphParams({ maxSpeed: value });
    },
    [updateSelectedGraphParams],
  );

  const changeYearOfCommissioningHandler = useCallback(
    (value: string) => {
      updateSelectedGraphParams({ yearOfCommissioning: value });
    },
    [updateSelectedGraphParams],
  );

  const onClose = useCallback(() => {
    setVisibleInspector(false);
  }, [setVisibleInspector]);

  const deleteHandler = useCallback(() => {
    deleteGraphFx({ graphItemId: selectedGraph });
  }, [selectedGraph]);

  if (!selectedGraph || !selectedGraphItem) return null;

  return (
    <Stack
      height="100%"
      direction="column"
      justifyContent="space-between"
    >
      <Stack>
        <Stack
          alignItems={'center'}
          flexDirection={'row'}
          mb={3}
          columnGap={2}
        >
          <IconButton
            sx={{
              width: '32px',
              height: '32px',
              backgroundColor: 'primary.main',
              borderRadius: 1,
              color: 'white',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            }}
            onClick={onClose}
          >
            <ChevronRightIcon />
          </IconButton>

          <Typography variant="subtitle2">Железнодорожная линия</Typography>
        </Stack>

        <Stack rowGap={2}>
          <CashedField
            id="tpuName"
            title="Наименование"
            placeholder="Введите название ЖД пути"
            value={selectedGraphItem.name}
            onBlur={value => changeNameHandler(value as string)}
            disabled={!editMode}
          />

          <FormLabel
            id="infrastructureType"
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            Типы инфраструктуры
            <Select<string>
              id="infrastructureType"
              sx={{ mt: 1 }}
              value={selectedGraphItem.infrastructureType || ''}
              onChange={selectInfrastructureTypeHandler}
              disabled={!editMode}
            >
              {Object.entries(ECustomGraphRailwayType).map(type => (
                <MenuItem
                  key={type[0]}
                  value={type[0]}
                >
                  {type[1]}
                </MenuItem>
              ))}
            </Select>
          </FormLabel>

          <CashedField
            id="maxSpeed"
            title="Максимальная скорость, км/ч."
            placeholder="Введите максимальную скорость"
            value={selectedGraphItem.maxSpeed}
            onBlur={value => changeMaxSpeedHandler(value as string)}
            disabled={!editMode}
          />

          <YearCashedField
            id="yearOfCommissioning"
            title="Год ввода в эксплуатацию"
            placeholder="Введите год ввода в эксплуатацию"
            value={selectedGraphItem.yearOfCommissioning}
            onBlur={value => changeYearOfCommissioningHandler(value as string)}
            disabled={!editMode}
            minYear={minYear}
            maxYear={maxYear}
          />
        </Stack>
      </Stack>

      <Box
        pb={2}
        pt={2.25}
        px={5.5}
        mt={'auto'}
        style={{
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Button
          variant={'text'}
          fullWidth
          sx={{
            color: theme => theme.palette.secondary.main,
          }}
          onClick={deleteHandler}
        >
          Удалить ЖД линию
        </Button>
      </Box>
    </Stack>
  );
};
