import { SvgIcon, SvgIconProps } from '@mui/material';

<svg
  width="24"
  height="24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect
    width="24"
    height="24"
    rx="2"
    fill="#fff"
  />
  <path
    d="m14.915 7.686 1.944-2.021v-1.4l-1.477 1.4.077-.7V2.633h-1.088v3.032l-1.477-1.4v1.4l2.021 2.02ZM8.54 5.276v2.41H9.63v-2.41l-.078-.7 1.477 1.4v-1.4L9.085 2.633 7.063 4.576v1.4l1.478-1.4v.7ZM2.322 1H1v22h1.322V1ZM4.032 15.226c0-1.632.622-2.02 2.099-2.02h.777c1.4 0 2.1.388 2.1 2.02v1.633c0 .466-.39.7-.778.7v-2.41c0-.467-.156-.7-.389-.7v6.064c0 .7-.388.932-1.088.932v-3.809h-.467v3.81c-.7 0-1.088-.311-1.088-.933v-6.064c-.31 0-.389.233-.389.7v2.41c-.388 0-.777-.234-.777-.7v-1.633Zm2.488-4.43c.544 0 1.01.466 1.01 1.01a1.03 1.03 0 0 1-1.01 1.01 1.03 1.03 0 0 1-1.01-1.01c0-.544.466-1.01 1.01-1.01Zm3.031 4.43c0-1.632.622-2.02 2.1-2.02h.777c1.4 0 2.099.388 2.099 2.02v1.633c0 .466-.389.7-.778.7v-2.41c0-.467-.155-.7-.388-.7v6.064c0 .7-.389.932-1.089.932v-3.809h-.466v3.81c-.7 0-1.088-.311-1.088-.933v-6.064c-.311 0-.39.233-.39.7v2.41c-.388 0-.777-.234-.777-.7v-1.633Zm2.488-4.43c.544 0 1.01.466 1.01 1.01a1.03 1.03 0 0 1-1.01 1.01 1.03 1.03 0 0 1-1.01-1.01c0-.544.466-1.01 1.01-1.01Zm3.032 4.43c0-1.632.622-2.02 2.099-2.02h.777c1.4 0 2.1.388 2.1 2.02v1.633c0 .466-.39.7-.778.7v-2.41c0-.467-.156-.7-.389-.7v6.064c0 .7-.389.932-1.088.932v-3.809h-.467v3.81c-.7 0-1.088-.311-1.088-.933v-6.064c-.311 0-.389.233-.389.7v2.41c-.388 0-.777-.234-.777-.7v-1.633Zm2.487-4.43c.545 0 1.011.466 1.011 1.01a1.03 1.03 0 0 1-1.01 1.01 1.03 1.03 0 0 1-1.011-1.01c0-.544.466-1.01 1.01-1.01ZM3.41 23h17.103V9.318H3.41V23ZM23 1h-1.322v22H23V1Z"
    fill="#3679EA"
  />
</svg>;

export const ElevatorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <svg
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="24"
          height="24"
          rx="2"
          fill="#fff"
        />
        <path
          d="m14.915 7.686 1.944-2.021v-1.4l-1.477 1.4.077-.7V2.633h-1.088v3.032l-1.477-1.4v1.4l2.021 2.02ZM8.54 5.276v2.41H9.63v-2.41l-.078-.7 1.477 1.4v-1.4L9.085 2.633 7.063 4.576v1.4l1.478-1.4v.7ZM2.322 1H1v22h1.322V1ZM4.032 15.226c0-1.632.622-2.02 2.099-2.02h.777c1.4 0 2.1.388 2.1 2.02v1.633c0 .466-.39.7-.778.7v-2.41c0-.467-.156-.7-.389-.7v6.064c0 .7-.388.932-1.088.932v-3.809h-.467v3.81c-.7 0-1.088-.311-1.088-.933v-6.064c-.31 0-.389.233-.389.7v2.41c-.388 0-.777-.234-.777-.7v-1.633Zm2.488-4.43c.544 0 1.01.466 1.01 1.01a1.03 1.03 0 0 1-1.01 1.01 1.03 1.03 0 0 1-1.01-1.01c0-.544.466-1.01 1.01-1.01Zm3.031 4.43c0-1.632.622-2.02 2.1-2.02h.777c1.4 0 2.099.388 2.099 2.02v1.633c0 .466-.389.7-.778.7v-2.41c0-.467-.155-.7-.388-.7v6.064c0 .7-.389.932-1.089.932v-3.809h-.466v3.81c-.7 0-1.088-.311-1.088-.933v-6.064c-.311 0-.39.233-.39.7v2.41c-.388 0-.777-.234-.777-.7v-1.633Zm2.488-4.43c.544 0 1.01.466 1.01 1.01a1.03 1.03 0 0 1-1.01 1.01 1.03 1.03 0 0 1-1.01-1.01c0-.544.466-1.01 1.01-1.01Zm3.032 4.43c0-1.632.622-2.02 2.099-2.02h.777c1.4 0 2.1.388 2.1 2.02v1.633c0 .466-.39.7-.778.7v-2.41c0-.467-.156-.7-.389-.7v6.064c0 .7-.389.932-1.088.932v-3.809h-.467v3.81c-.7 0-1.088-.311-1.088-.933v-6.064c-.311 0-.389.233-.389.7v2.41c-.388 0-.777-.234-.777-.7v-1.633Zm2.487-4.43c.545 0 1.011.466 1.011 1.01a1.03 1.03 0 0 1-1.01 1.01 1.03 1.03 0 0 1-1.011-1.01c0-.544.466-1.01 1.01-1.01ZM3.41 23h17.103V9.318H3.41V23ZM23 1h-1.322v22H23V1Z"
          fill="#3679EA"
        />
      </svg>
    </SvgIcon>
  );
};
