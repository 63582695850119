import { IQuery, IVehicleTypeType } from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';
import { apiClient } from 'src/api/provider';

import {
  getVehicleTypesForInspectorFn,
  getVehicleTypesForInspectorFx,
  resetSelectedLayout,
  setVehicleTypesForInspector,
} from './store';

import { EFilterMapLayout } from '@components/MapFilters';

import {
  $FilterMap,
  setFilters,
  setLayoutFn,
} from '@features/passenger-traffic/stores/filters';
import {
  IQueryFindGetPassTrafficArgs,
  getPassengerTrafficForInspectorFx,
} from '@features/passenger-traffic/stores/inspector/PassengerTraffic/store';

// Получение доступных транспортных средств
sample({
  clock: getVehicleTypesForInspectorFn,
  target: getVehicleTypesForInspectorFx,
});

// Сеттер доступных транспортных средств
sample({
  clock: getVehicleTypesForInspectorFx.done,
  target: setVehicleTypesForInspector,
});

// Триггер сброса выбранного объекта в инспекторе при смене слоя
sample({
  clock: setLayoutFn,
  target: resetSelectedLayout,
});

// Триггер сброса выбранного объекта в инспекторе при смене фильтров, кроме слоя инфраструктуры
sample({
  clock: setFilters,
  source: { FilterMap: $FilterMap },
  filter: ({ FilterMap }) =>
    Boolean(FilterMap.layout !== EFilterMapLayout.Infrastructure),
  target: resetSelectedLayout,
});

getVehicleTypesForInspectorFx.use(async () => {
  const response = await apiClient.query<IQuery, IVehicleTypeType[]>({
    query: gql`
      query QueryGetVehicleTypes {
        vehicleType {
          code
          id
          description
          mode
        }
      }
    `,
  });

  return response.data.vehicleType as IVehicleTypeType[];
});

getPassengerTrafficForInspectorFx.use(async params => {
  const response = await apiClient.query<IQuery, IQueryFindGetPassTrafficArgs>({
    query: gql`
      query QueryGetPassTraffic(
        $regionFrom: String
        $regionTo: String
        $intermediateCorrespondence: Boolean
      ) {
        getPassTraffic(
          regionFrom: $regionFrom
          regionTo: $regionTo
          intermediateCorrespondence: $intermediateCorrespondence
        ) {
          id
          trafficTotal
          year
          vehicle {
            code
            description
          }
          direction {
            regionFrom {
              id
              name
              center {
                name
              }
            }
            regionTo {
              id
              name
              center {
                name
              }
            }
          }
          passtrafficvaluesSet {
            duration
            frequency
            id
            price
            year
          }
        }
      }
    `,
    variables: {
      regionFrom: params.regionFrom,
      regionTo: params.regionTo,
      intermediateCorrespondence: params.intermediateCorrespondence,
    },
  });

  return response.data.getPassTraffic;
});
