export enum VehicleTypeCode {
  multimodal = 'Мультимодальные поездки',
  avia = 'Самолет',
  railwayRoot = 'ЖД транспорт',
  suburbanTrain = 'Пригородный поезд',
  expressTrain = 'Высокоскоростной поезд',
  bus = 'Автобус',
  auto = 'Личный транспорт',
  dayTrain = 'Дневной поезд дальнего следования',
  nightTrain = 'Ночной поезд дальнего следования',
}
