import {
  IMutationUpdateComputedResultsParamsArgs,
  IUpdateComputedResultParams,
} from '@api/gql/types';
import { createApi, createEffect, createStore } from 'effector';

import { EVehicle } from '@features/pt-forecast/constants/vehiclesEnum';

const initState: RecalculateStore = {
  price: {
    auto: 0,
    bus: 0,
    dayTrain: 0,
    nightTrain: 0,
    suburbanTrain: 0,
    avia: 0,
    expressTrain: 0,
  },
  frequency: {
    bus: 0,
    dayTrain: 0,
    nightTrain: 0,
    suburbanTrain: 0,
    avia: 0,
    expressTrain: 0,
  },
  duration: {
    auto: 0,
    bus: 0,
    dayTrain: 0,
    nightTrain: 0,
    suburbanTrain: 0,
    avia: 0,
    expressTrain: 0,
  },
  id: '',
};

export const $Recalculate = createStore<RecalculateStore>(initState);

export const RecalculateApi = createApi($Recalculate, {
  setRecalculateParameters: (store, payload: RecalculateStore) => ({
    ...store,
    ...payload,
  }),
  reset: () => initState,
});

export const updateComputedResultParamsFx = createEffect<
  IMutationUpdateComputedResultsParamsArgs,
  IUpdateComputedResultParams | null | undefined
>();

export type RecalculateStore = {
  price: Record<Exclude<keyof typeof EVehicle, 'multimodal'>, number>;
  frequency: Record<
    Exclude<keyof typeof EVehicle, 'auto' | 'multimodal'>,
    number
  >;
  duration: Record<Exclude<keyof typeof EVehicle, 'multimodal'>, number>;
  id: string;
};

export type VehicleParameterRecalculate = {
  key: keyof typeof EVehicle;
  value: number | string;
};
