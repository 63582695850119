import {
  IDynamicsFlowType,
  IDynamicsStzType,
  IQuery,
  IQueryGetScenarioByIdArgs,
  Maybe,
} from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

export type FirstPageType = {
  stzDynamics: Maybe<IDynamicsStzType>;
  flowDynamics: Maybe<IDynamicsFlowType>;
  flowByTransport: FlowByTransportType[];
};

const FirstPageStore: FirstPageType = {
  stzDynamics: null,
  flowDynamics: null,
  flowByTransport: [],
};

export const $FirstPageDashboard = createStore<FirstPageType>(FirstPageStore);

export const FirstPageApi = createApi($FirstPageDashboard, {
  setTotalTransportCostsDynamics: (store, payload) => ({
    ...store,
    stzDynamics: payload.result.stzDynamics,
  }),
  setPassengerTrafficDynamics: (store, payload) => ({
    ...store,
    flowDynamics: payload.result.flowDynamics,
  }),
  setTotalPassengerTrafficByTransport: (
    store,
    payload: FirstPageType['flowByTransport'],
  ) => ({
    ...store,
    flowByTransport: payload,
  }),
  reset: () => FirstPageStore,
});

export type FlowByTransportType = {
  public: number;
  private: number;
  year: number;
};

export const queryDataDashboardP1Fx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();
