export const SOC_ECO_TH_3_1 = [
  {
    id: 'nameArea',
    label: 'Наименование участка',
  },
  {
    id: 'sectionLength',
    label: 'Длина участка, м.',
  },
  {
    id: 'numberPasses',
    label: 'Число проходов',
  },
];
