import { sample } from 'effector';

import { EMapFeatureLayout } from '@constants/map';

import { VehiclesApi } from '@features/ag-forecast/stores/lists/vehicles';
import {
  LayersVisibleApi,
  initSources,
  pipeTransportDistrictsTileLayer,
} from '@features/ag-forecast/stores/map';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';
import { createAgTransportDistrictsSource } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

import {
  EPipeTileLayer,
  PipeTileLayer,
} from '@utils/map/hooks/useInitTileLayer';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload &&
    Object.keys(payload).includes(EMapFeatureLayout.transportDistricts),
  fn: payload =>
    ({
      action: EPipeTileLayer.setVisible,
      payload: payload![EMapFeatureLayout.transportDistricts],
    }) as PipeTileLayer,
  target: pipeTransportDistrictsTileLayer,
});

// Установка источника тайлов
sample({
  clock: [VehiclesApi.setVehicles, initSources],
  source: {
    FeatureSettings: $FeatureSettings,
  },
  fn: ({ FeatureSettings }) => {
    if (!FeatureSettings.projectUUID) throw Error('Project UUID not found');
    return {
      action: EPipeTileLayer.changeSource,
      payload: createAgTransportDistrictsSource(FeatureSettings.projectUUID),
    } as PipeTileLayer;
  },
  target: pipeTransportDistrictsTileLayer,
});
