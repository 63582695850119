import { Fragment, createElement, useState } from 'react';

import {
  Button,
  Divider,
  Grid,
  Stack,
  SvgIconProps,
  Typography,
} from '@mui/material';

import {
  BankIcon,
  BankomatIcon,
  BuffetIcon,
  DoorIcon,
  ElevatorIcon,
  ExitIcon,
  InformationTableIcon,
  KioskIcon,
  LadderIcon,
  LayersIcon,
  PassangersIcon,
  PathIcon,
  PeopleIcon,
  PlatformIcon,
  RestaurantIcon,
  RoomIcon,
  SecurityCheckpointIcon,
  ShoppingCartIcon,
  StoreIcon,
  TicketIcon,
  TicketPrintingMachineIcon,
  ToiletIcon,
  TrainUnfillIcon,
  TurnstileIcon,
  VendingIcon,
  WaitingRoomIcon,
  WallIcon,
} from '@components/icons';

type LegendItem = { label: string; Icon: (arg: SvgIconProps) => JSX.Element };

const legendConfig: LegendItem[][] = [
  [
    {
      Icon: RoomIcon,
      label: 'Помещение',
    },
    {
      Icon: WallIcon,
      label: 'Стена',
    },
    {
      Icon: LadderIcon,
      label: 'Лестница',
    },
    {
      Icon: PlatformIcon,
      label: 'Платформа',
    },
    {
      Icon: (props: SvgIconProps) =>
        createElement(DoorIcon, {
          ...props,
          sx: { ...props.sx, color: '#3679EA' },
        }),
      label: 'Дверь',
    },
    {
      Icon: ElevatorIcon,
      label: 'Лифт',
    },
  ],
  [
    {
      Icon: TicketIcon,
      label: 'Билетная касса',
    },
    {
      Icon: TicketPrintingMachineIcon,
      label: 'Билетопечатающий аппарат (БПА)',
    },
    {
      Icon: TurnstileIcon,
      label: 'Турникет',
    },
    {
      Icon: SecurityCheckpointIcon,
      label: 'Пункт досмотра',
    },
    {
      Icon: TrainUnfillIcon,
      label: 'Поезд',
    },
    {
      Icon: InformationTableIcon,
      label: 'Информационное табло',
    },
    {
      Icon: ToiletIcon,
      label: 'Туалет',
    },
    {
      Icon: WaitingRoomIcon,
      label: 'Зал ожидания',
    },
  ],
  [
    {
      Icon: RestaurantIcon,
      label: 'Ресторан',
    },
    {
      Icon: BuffetIcon,
      label: 'Буфет',
    },
    {
      Icon: VendingIcon,
      label: 'Вендинговый аппарат',
    },
    {
      Icon: StoreIcon,
      label: 'Магазин',
    },
    {
      Icon: KioskIcon,
      label: 'Киоск',
    },
    {
      Icon: BankIcon,
      label: 'Банк',
    },
    {
      Icon: BankomatIcon,
      label: 'Банкомат',
    },
    {
      Icon: ShoppingCartIcon,
      label: 'Прочее',
    },
  ],
  [
    {
      Icon: PeopleIcon,
      label: 'Генератор пассажиров',
    },
    {
      Icon: PathIcon,
      label: 'Путь',
    },
    {
      Icon: ExitIcon,
      label: 'Выход',
    },
    {
      Icon: PassangersIcon,
      label: 'Пассажиры',
    },
  ],
];

export const Legend = () => {
  const [isLegendOpen, setIsLegendOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setIsLegendOpen(prev => !prev)}
        variant={'outlined'}
        color={'primary'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '48px',
          gap: '8px',
          marginLeft: 'auto',
          padding: '12px 24px',
          boxShadow: '0px 0px 8px 0px #00000026',
          pointerEvents: 'auto',
          border: 'unset',

          '&:hover': {
            border: 'unset',
          },
        }}
      >
        <LayersIcon />
        <Typography
          sx={{
            fontSize: theme => theme.typography.pxToRem(14),
            lineHeight: theme => theme.typography.pxToRem(16),
          }}
        >
          Условные обозначения
        </Typography>
      </Button>
      {isLegendOpen && (
        <Stack
          direction={'column'}
          flexWrap={'nowrap'}
          sx={{
            backgroundColor: theme => theme.palette.common.white,
            position: 'absolute',
            right: 24,
            top: 260,
            maxHeight: 'calc(100% - 284px)',
            width: '420px',
            marginLeft: 'auto',
            overflow: 'hidden',
            pointerEvents: 'all',
            borderRadius: theme => theme.shape.borderRadius,
            zIndex: 1,
            padding: '24px',
          }}
        >
          <Typography
            variant={'subtitle2'}
            mb={3}
            gutterBottom
          >
            Условные обозначения
          </Typography>

          <Stack
            sx={{ overflow: 'auto' }}
            gap={3}
          >
            {legendConfig.map((list, index) => (
              <Fragment key={index}>
                <Grid
                  container
                  item
                  gap={1.5}
                  pr={3}
                >
                  {list.map(({ label, Icon }) => (
                    <Grid
                      key={label}
                      display={'grid'}
                      container
                      gridTemplateColumns={'24px 1fr'}
                      gap={1.5}
                      alignItems={'center'}
                    >
                      <Icon />
                      <Typography
                        variant="body2"
                        color="text.secondary"
                      >
                        {label}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                {index < legendConfig.length - 1 && <Divider />}
              </Fragment>
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
};
