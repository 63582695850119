export enum EVehicle {
  multimodal = 'multimodal',
  auto = 'auto',
  bus = 'bus',
  dayTrain = 'dayTrain',
  nightTrain = 'nightTrain',
  suburbanTrain = 'suburbanTrain',
  expressTrain = 'expressTrain',
  avia = 'avia',
}

export const vehicleAvailableCodes: EVehicle[] = [
  EVehicle.multimodal,
  EVehicle.auto,
  EVehicle.bus,
  EVehicle.dayTrain,
  EVehicle.nightTrain,
  EVehicle.suburbanTrain,
  EVehicle.expressTrain,
  EVehicle.avia,
];
