import { Stroke, Style } from 'ol/style';

export const allGraphsAgglomerationTileStyle = () => {
  const styles: Style[] = [];

  styles.push(
    new Style({
      stroke: new Stroke({
        color: 'rgba(51,51,51,0.33)',
        width: 3,
      }),
    }),
  );

  return styles;
};
