import { $CurrentScenario } from '@features/ag-constructor/stores/currentScenario';
import { useUnit } from 'effector-react';

import { Grid, Typography } from '@mui/material';

export const Question = () => {
  const $currentScenario = useUnit($CurrentScenario);

  return (
    <Grid
      display={'flex'}
      direction={'column'}
      container
      rowGap={2}
    >
      <Typography variant="body2">
        Вы действительно хотите удалить расчет?
      </Typography>

      <Typography
        variant="body2"
        borderRadius={'4px'}
        bgcolor={'customs.bgHover'}
        ml={1}
        py={1}
        px={1.5}
      >
        {$currentScenario?.name || 'Безымянный проект'}
      </Typography>

      <Typography variant="body2">Удаление будет безвозвратным.</Typography>
    </Grid>
  );
};
