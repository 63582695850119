import { useUnit } from 'effector-react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { HourglassTopOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  UseAutocompleteProps,
  autocompleteClasses,
  inputBaseClasses,
  outlinedInputClasses,
} from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { FormLabel } from '@components/FormLabel';
import { NumberField } from '@components/NumberField';
import { CloseIcon } from '@components/icons';

import {
  EcoParam,
  ecoParams,
} from '@features/pt-constructor/constants/ecoParams';
import {
  ScenarioOption,
  SelectedCalculationScenarioApi,
  SocEcoScenarioListItem,
  SocEcoSelectedScenario,
  setModalView,
} from '@features/pt-constructor/store';
import {
  $ErrorsCreateCalculation,
  checkErrorSocioEconomicScenario,
} from '@features/pt-constructor/store/errorsCreateCalculation';

import { PaperCard } from '../../components/PaperCard';

export const SocioEconomic = ({
  selectedSocEco,
  socEcoScenarios,
  readonly,
}: Props) => {
  const [selectedListScenario, setSelectedListScenario] =
    useState<ScenarioOption | null>(null);

  const $errorsCreateCalculation = useUnit($ErrorsCreateCalculation);
  const { setParamSocEcoScenario, selectSocEcoScenario } = useUnit(
    SelectedCalculationScenarioApi,
  );

  const onCreateScenarioButtonClick = useCallback(
    () => setModalView({ socioEconomic: true }),
    [],
  );

  const onChangeParamValue = useCallback(
    (param: EcoParam) => (value_: number | string | undefined) => {
      const value = Number(value_);
      setParamSocEcoScenario({ param, value });
    },
    [setParamSocEcoScenario],
  );

  const onChangeScenarioType = useCallback<
    NonNullable<
      UseAutocompleteProps<ScenarioOption, false, false, false>['onChange']
    >
  >(
    (_e, val) => {
      if (!val) return;
      const socEcoScenario = socEcoScenarios.find(e => e.id === val.value);
      if (!socEcoScenario) return;
      selectSocEcoScenario(socEcoScenario);
      $errorsCreateCalculation.isCheckErrors &&
        checkErrorSocioEconomicScenario(socEcoScenario);
    },
    [
      $errorsCreateCalculation.isCheckErrors,
      selectSocEcoScenario,
      socEcoScenarios,
    ],
  );

  const scenariosList = useMemo<ScenarioOption[]>(
    () =>
      socEcoScenarios.map(scenario => ({
        value: scenario.id as string,
        label: scenario.name as string,
        type: scenario.type,
        disabled: !scenario.computed,
      })),
    [socEcoScenarios],
  );

  const handleClearScenario = useCallback(
    () => setSelectedListScenario(null),
    [],
  );

  useEffect(() => {
    if (!selectedSocEco) return setSelectedListScenario(null);
    const scenario = scenariosList.find(
      e => e.label === selectedSocEco.name,
    ) || {
      value: '',
      label: selectedSocEco.name || 'Безымянный',
      type: 4,
      disabled: false,
    };
    setSelectedListScenario(scenario);
  }, [scenariosList, selectedSocEco]);

  return (
    <PaperCard title={'Сценарий социально-экономического прогноза'}>
      <AutoFullHeight
        style={{
          overflowY: 'auto',
          paddingRight: '24px',
        }}
        mb={24 + 32}
      >
        <Stack
          gap={2}
          flexDirection={'row'}
          alignItems={'flex-end'}
          mb={$errorsCreateCalculation.isErrorSocioEconomicScenario ? 1.5 : 4}
        >
          <FormLabel
            sx={{ flex: 1 }}
            title={'Сценарий'}
            id="scenario-select"
          >
            <Autocomplete
              id="scenario-select"
              disablePortal
              onChange={readonly ? undefined : onChangeScenarioType}
              value={selectedListScenario}
              options={scenariosList}
              disabled={readonly}
              getOptionDisabled={option => !!option?.disabled}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              sx={{
                [`&.${autocompleteClasses.hasPopupIcon}.${autocompleteClasses.hasClearIcon} .${outlinedInputClasses.root}`]:
                  {
                    pr: 4,
                  },
                [`& .${inputBaseClasses.root}.${outlinedInputClasses.root}.${outlinedInputClasses.disabled}`]:
                  {
                    pointerEvents: 'none',
                  },
                [`&.${autocompleteClasses.root} .${autocompleteClasses.clearIndicator}`]:
                  {
                    display: 'none',
                  },
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  disabled={readonly}
                  placeholder="Сценарий"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {selectedListScenario && !readonly && (
                          <IconButton
                            size="small"
                            onClick={handleClearScenario}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={`${option.label}-${option.value}`}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography>{option.label}</Typography>
                    {option.disabled && <HourglassTopOutlined />}
                  </Stack>
                </li>
              )}
            />
          </FormLabel>

          <Button
            disabled={readonly}
            onClick={onCreateScenarioButtonClick}
          >
            Создать новый
          </Button>
        </Stack>

        {$errorsCreateCalculation.isErrorSocioEconomicScenario && (
          <Stack sx={{ mb: 4 }}>
            <Typography
              variant="caption"
              sx={{
                color: theme => theme.palette.secondary.main,
              }}
            >
              Необходимо выбрать существующий или создать новый сценарий
            </Typography>
          </Stack>
        )}

        <Typography variant={'subtitle1'}>Параметры прогнозирования</Typography>

        {ecoParams.map(param => (
          <FormLabel
            title={param.title}
            id={param.name}
            key={param.name}
            sx={{ mt: 3 }}
          >
            <NumberField
              disabled={
                readonly ||
                !(selectedListScenario && !selectedListScenario?.value)
              }
              value={selectedSocEco?.[param.name]}
              getValue={onChangeParamValue(param)}
              placeholder={param.title}
            />
          </FormLabel>
        ))}
      </AutoFullHeight>
    </PaperCard>
  );
};

interface Props {
  selectedSocEco: SocEcoSelectedScenario | null;
  socEcoScenarios: SocEcoScenarioListItem[];
  readonly?: boolean;
}
