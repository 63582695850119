export const agProjectsTableHeadCells = [
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: '№',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Проект',
  },
  {
    id: 'agglomeration',
    numeric: false,
    disablePadding: false,
    label: 'Агломерация',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Дата загрузки',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
  },
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'Пользователь',
  },
];
