import { useUnit } from 'effector-react';

import { Divider, Grid, Typography, alpha } from '@mui/material';

import { RailsIcon, TrainIcon } from '@components/icons';
import { TpuIcon } from '@components/icons/TPU';

import { CircleItem } from '@features/ag-forecast/components/MapLegend/CircleItem';
import { LineItem } from '@features/ag-forecast/components/MapLegend/LineItem';
import { $UI } from '@features/ag-forecast/stores/ui';

export const InfraLegend = () => {
  const { inspector } = useUnit($UI);

  return (
    <Grid
      container
      direction={'column'}
      flexWrap={'nowrap'}
      sx={{
        backgroundColor: theme => theme.palette.common.white,
        boxShadow: '0px 0px 8px 0px #00000026',
        maxHeight: 'calc(100% - 260px);',
        overflow: 'hidden',
        width: '420px',
        borderRadius: '5px',
        position: 'absolute',
        top: theme => theme.typography.pxToRem(16 + 40 + 12 + 6),
        right: theme => theme.typography.pxToRem(inspector ? 500 + 24 : 24),
        zIndex: 1,
      }}
      p={'44px 12px 32px 44px'}
    >
      <Grid
        item
        mb={3}
      >
        <Typography
          variant={'subtitle2'}
          gutterBottom
        >
          Инфраструктура
        </Typography>
      </Grid>

      <Grid
        container
        item
        gap={1.5}
        sx={{
          overflowY: 'auto',
        }}
        pr={3}
      >
        <LineItem
          label="Автомобильный транспорт"
          color="autoV2"
        />

        <LineItem
          label="Планируемый автомобильный транспорт"
          color="autoV2"
          planned
        />

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            width: '100%',
          }}
          flexItem
        />

        <LineItem
          label="Троллейбус"
          color="trolleybus"
        />

        <LineItem
          label="Планируемый троллейбус"
          color="trolleybus"
          planned
        />

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            width: '100%',
          }}
          flexItem
        />

        <LineItem
          label="Трамвай"
          color="expressways"
        />

        <LineItem
          label="Планируемый трамвай"
          color="expressways"
          planned
        />

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            width: '100%',
          }}
          flexItem
        />

        <LineItem
          label="Метрополитен"
          color="metro"
        />

        <LineItem
          label="Планируемый метрополитен"
          color="metro"
          planned
        />

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            width: '100%',
          }}
          flexItem
        />

        <LineItem
          label="Водный транспорт"
          color="waterTransport"
        />

        <LineItem
          label="Планируемый водный транспорт"
          color="waterTransport"
          planned
        />

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            width: '100%',
          }}
          flexItem
        />

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          <RailsIcon
            sx={{
              color: theme => theme.palette.customs.train,
              width: theme => theme.typography.pxToRem(48),
              justifySelf: 'center',
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Железные дороги
          </Typography>
        </Grid>

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          <RailsIcon
            sx={{
              color: theme => theme.palette.customs.trainPlanned,
              width: theme => theme.typography.pxToRem(48),
              justifySelf: 'center',
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Планируемые железные дороги
          </Typography>
        </Grid>

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          <TrainIcon
            sx={{
              color: theme => theme.palette.customs.train,
              width: theme => theme.typography.pxToRem(20),
              justifySelf: 'center',
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Железнодорожные станции
          </Typography>
        </Grid>

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          <TrainIcon
            sx={{
              color: theme => theme.palette.customs.trainPlanned,
              width: theme => theme.typography.pxToRem(20),
              justifySelf: 'center',
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Планируемые железнодорожные станции
          </Typography>
        </Grid>

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          <TrainIcon
            sx={{
              color: '#E21A1A',
              width: theme => theme.typography.pxToRem(20),
              justifySelf: 'center',
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Проектные ТПУ
          </Typography>
        </Grid>

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          <TrainIcon
            sx={{
              color: alpha('#E21A1A', 0.3),
              width: theme => theme.typography.pxToRem(20),
              justifySelf: 'center',
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Проектные планируемые ТПУ
          </Typography>
        </Grid>

        <Grid
          display={'grid'}
          container
          gridTemplateColumns={'2fr 10fr'}
          gap={2}
          alignItems={'center'}
        >
          <TpuIcon
            sx={{
              color: '#E21A1A',
              width: theme => theme.typography.pxToRem(20),
              justifySelf: 'center',
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Пользовательские ТПУ
          </Typography>
        </Grid>

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            width: '100%',
          }}
          flexItem
        />

        <LineItem
          label="Прочие виды транспорта"
          color="otherTransport"
        />

        <LineItem
          label="Планируемые прочие виды транспорта"
          color="otherTransport"
          planned
        />

        <Divider
          sx={{
            borderColor: theme => theme.palette.border.divider,
            width: '100%',
          }}
          flexItem
        />

        <LineItem
          label="Границы транспортных районов"
          color="transportAreaInspectorBoundaries"
        />

        <CircleItem
          label="Центры транспортных районов"
          color="transportAreaInspectorBoundaries"
        />
      </Grid>
    </Grid>
  );
};
