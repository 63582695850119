import { SvgIcon, SvgIconProps } from '@mui/material';

export const RestaurantIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        d="M11.669 1.465c0-.62-.852-.62-.852 0l.232 5.422H9.965V1.542c0-.697-.853-.697-.853 0v5.345H8.028l.232-5.422c0-.62-.774-.62-.852 0l-.387 6.894c-.078 1.007 0 1.937 1.317 1.937v11.542c0 .62.542 1.162 1.162 1.162.62 0 1.162-.542 1.162-1.162V10.296c1.317 0 1.394-.93 1.317-1.937l-.31-6.894ZM17.246 1c-2.401 0-3.486 1.007-3.486 3.563v8.212c0 1.162.31 1.317 1.24 1.859v7.204c0 .62.464 1.162 1.162 1.162.62 0 1.162-.542 1.162-1.162 0-6.972 0-13.944-.078-20.838Z"
        fill="#7E67DD"
      />
    </SvgIcon>
  );
};
