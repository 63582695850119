import { SvgIcon, SvgIconProps } from '@mui/material';

export const ClearIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M17.6569 6.34317C16.0947 4.78102 14.0473 4 12 4C9.95268 4 7.90536 4.78102 6.34312 6.34317C3.21896 9.46732 3.21896 14.5331 6.34312 17.6569C7.90526 19.2191 9.95268 20.0001 12 20.0001C14.0473 20.0001 16.0946 19.2191 17.6569 17.6569C20.781 14.5328 20.781 9.46732 17.6569 6.34317ZM7.7571 7.75737C8.89041 6.62418 10.3971 6.00005 11.9997 6.00005C13.2814 6.00005 14.4986 6.40545 15.5156 7.14489C15.4385 7.18842 15.3585 7.22723 15.2926 7.29296L7.29254 15.293C7.23054 15.355 7.19532 15.4308 7.15316 15.503C5.4587 13.1591 5.64756 9.86674 7.75697 7.75751L7.7571 7.75737ZM16.2426 16.2429C15.1093 17.376 13.6026 18.0002 12 18.0002C10.7182 18.0002 9.50109 17.5948 8.48409 16.8553C8.56123 16.8118 8.64121 16.773 8.70706 16.7073L16.7071 8.70719C16.7691 8.64518 16.8044 8.56941 16.8465 8.49724C18.541 10.8411 18.3521 14.1335 16.2427 16.2427L16.2426 16.2429Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
