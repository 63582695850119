import { IQuery, ITransportRegionDetailType } from '@api/gql/types';
import { createEffect, createEvent, createStore } from 'effector';

import { EVehicle } from '@features/pt-forecast/constants/vehiclesEnum';

export type PassengerTrafficInspectorType = {
  passengerTrafficResponse: IQuery['getPassTraffic'];
  passengerTraffic: Record<EVehicle, number>;
  passengerTrafficPercent: Record<EVehicle, number>;
  total: number;
  intermediateCorrespondences: intermediateCorrespondenceTableData[];
};

export type intermediateCorrespondenceTableData = {
  regionFrom: string;
  regionTo: string;
  trafficTotal: number;
};

export interface IQueryFindGetPassTrafficArgs {
  regionFrom: ITransportRegionDetailType['id'];
  regionTo: ITransportRegionDetailType['id'];
  intermediateCorrespondence: boolean;
}

export const $PassengerTrafficInspector =
  createStore<PassengerTrafficInspectorType>({
    passengerTrafficResponse: [],
    passengerTraffic: {
      nightTrain: 0,
      dayTrain: 0,
      suburbanTrain: 0,
      bus: 0,
      auto: 0,
      avia: 0,
      expressTrain: 0,
      multimodal: 0,
    },
    passengerTrafficPercent: {
      nightTrain: 0,
      dayTrain: 0,
      suburbanTrain: 0,
      bus: 0,
      auto: 0,
      avia: 0,
      expressTrain: 0,
      multimodal: 0,
    },
    total: 0,
    intermediateCorrespondences: [],
  });

export const getPassengerTrafficForInspectorFx = createEffect<
  IQueryFindGetPassTrafficArgs,
  IQuery['getPassTraffic']
>();

export const setPassengerTrafficResponse =
  createEvent<IQuery['getPassTraffic']>();

export const setPassengerTraffic =
  createEvent<PassengerTrafficInspectorType['passengerTraffic']>();

export const setTotalPassengerTraffic =
  createEvent<PassengerTrafficInspectorType['total']>();

export const setPassengerTrafficPercent =
  createEvent<PassengerTrafficInspectorType['passengerTrafficPercent']>();

export const setDataCorrespondences =
  createEvent<PassengerTrafficInspectorType['intermediateCorrespondences']>();

$PassengerTrafficInspector
  .on(setPassengerTrafficResponse, (state, payload) => ({
    ...state,
    passengerTrafficResponse: payload,
  }))
  .on(setPassengerTraffic, (state, payload) => ({
    ...state,
    passengerTraffic: payload,
  }))
  .on(setTotalPassengerTraffic, (state, payload) => ({
    ...state,
    total: payload,
  }))
  .on(setPassengerTrafficPercent, (state, payload) => ({
    ...state,
    passengerTrafficPercent: payload,
  }))
  .on(setDataCorrespondences, (state, payload) => ({
    ...state,
    intermediateCorrespondences: payload,
  }));
