import { rotate as rotateFromOrigin } from 'ol/coordinate';

import {
  FlexibleObjects,
  ILadder,
  IPath,
  IPlatform,
} from '@features/tpu-simulation-model/types';

import { degreeToRadian } from '@utils/simulationModel/degreeToRadian';

const rotate = (
  p: { x: number; y: number },
  mp: { x: number; y: number },
  angle: number,
): [number, number] => {
  const { x, y } = p;
  const { x: mx, y: my } = mp;
  const [newX, newY] = rotateFromOrigin([x - mx, y - my], angle);

  return [newX + mx, newY + my];
};

export const getCorners = (
  object: IPath | IPlatform | FlexibleObjects | ILadder,
): [number, number][] => {
  const { width, height, rotation, point } = object;

  if (!rotation) {
    const leftUp: [number, number] = [point.x, point.y];
    const leftDown: [number, number] = [point.x, point.y + height];
    const rightUp: [number, number] = [point.x + width, point.y];
    const rightDown: [number, number] = [point.x + width, point.y + height];

    return [leftUp, rightUp, rightDown, leftDown];
  }

  const rotatedMiddlePoint = {
    x: point.x + width / 2,
    y: point.y + height / 2,
  };
  const rotatedMiddlePointFromOrigin = [
    rotatedMiddlePoint.x - point.x,
    rotatedMiddlePoint.y - point.y,
  ];
  const middlePointFromOrigin = rotateFromOrigin(
    rotatedMiddlePointFromOrigin,
    degreeToRadian(rotation),
  );
  const middlePoint = {
    x: middlePointFromOrigin[0] + point.x,
    y: middlePointFromOrigin[1] + point.y,
  };

  const angleBetweenDiagonals = 2 * Math.atan(width / height);

  const anglesOfCornersRelativeToOrigin = [
    degreeToRadian(0),
    angleBetweenDiagonals,
    degreeToRadian(180),
    angleBetweenDiagonals - Math.PI,
  ];

  return anglesOfCornersRelativeToOrigin.map(angle =>
    rotate(point, middlePoint, angle),
  );
};
