import { useUnit } from 'effector-react';

import { Button, Stack, Tooltip, alpha, styled } from '@mui/material';

import { EMapViewMode } from '@features/ag-forecast/constants/EMapViewMode';
import { $MapStore } from '@features/ag-forecast/stores/map';
import { $UI, UIApi } from '@features/ag-forecast/stores/ui';

export const LayoutChanger = () => {
  const { viewMode, editTPUMode } = useUnit($UI);
  const { setViewMode } = useUnit(UIApi);
  const { isBusy } = useUnit($MapStore);

  const changeViewMode = (mode: EMapViewMode) => {
    setViewMode(mode);
  };

  return (
    <Stack direction="row">
      <SplitButton
        fullWidth
        position="left"
        selected={viewMode === EMapViewMode.infrastructure}
        disabled={isBusy}
        onClick={() =>
          viewMode !== EMapViewMode.infrastructure &&
          changeViewMode(EMapViewMode.infrastructure)
        }
      >
        Инфраструктура
      </SplitButton>
      <Tooltip
        title={
          editTPUMode ? 'Недоступно в режиме редактирования инфраструктуры' : ''
        }
      >
        <span style={{ width: '100%' }}>
          <SplitButton
            fullWidth
            position="right"
            disabled={isBusy || editTPUMode}
            selected={viewMode === EMapViewMode.passFlow}
            onClick={() =>
              viewMode !== EMapViewMode.passFlow &&
              changeViewMode(EMapViewMode.passFlow)
            }
          >
            Пассажиропоток
          </SplitButton>
        </span>
      </Tooltip>
    </Stack>
  );
};

type SplitButtonProps = {
  position: 'left' | 'right';
  selected: boolean;
};

const SplitButton = styled(Button)<SplitButtonProps>(
  ({ theme, position, selected }) => ({
    color: selected ? theme.palette.common.white : theme.palette.text.secondary,
    borderRadius: position === 'left' ? '6px 0 0 6px' : '0 6px 6px 0',
    border: `1px solid ${theme.palette.primary.main}`,
    background: selected ? theme.palette.primary.main : 'transparent',
    '&:hover': {
      background: selected
        ? alpha(theme.palette.primary.main, 0.85)
        : 'transparent',
    },
    '&:disabled': {
      background: selected
        ? alpha(theme.palette.primary.main, 0.5)
        : 'transparent',
      color: selected
        ? alpha(theme.palette.common.white, 0.5)
        : alpha(theme.palette.text.secondary, 0.5),
    },
  }),
);
