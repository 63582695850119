import { Component } from '@features/tpu-train-schedule/component';
import {
  TrainScheduleHandlersContext,
  useTrainScheduleHandlers,
} from '@features/tpu-train-schedule/hooks';

import './stores';

export const TrainScheduleContainer = () => {
  const value = useTrainScheduleHandlers();

  return (
    <TrainScheduleHandlersContext.Provider value={value}>
      <Component />
    </TrainScheduleHandlersContext.Provider>
  );
};
