import { v4 as uuid } from 'uuid';

import {
  IRuler,
  ListOfRulersDataType,
  RULER,
} from '@features/tpu-simulation-model/types';

import {
  IRulerErrorAnalyzer,
  PlanError,
} from '@utils/simulationModel/errors-analyzer/types';

export class RulerErrorAnalyzer implements IRulerErrorAnalyzer {
  _data: ListOfRulersDataType | null = null;
  _list: (IRuler | null)[] = [];
  _filteredList: IRuler[] = [];
  _errors = new Set<PlanError>();

  reset() {
    this._data = null;
    this._list = [];
    this._filteredList = [];
    this._errors.clear();
  }

  updateData(data: ListOfRulersDataType | null) {
    this._data = data;
    this._list = (this._data && Object.values(this._data)) ?? [];
    this._filteredList = this._list.filter((ruler): ruler is IRuler => !!ruler);
  }

  getErrors() {
    if (!this._data || Object.values(this._data).every(item => !item)) {
      this._errors.add({
        id: uuid(),
        objectType: RULER,
        description:
          'Не задан масштаб на всех этажах. Используйте инструмент "Линейка" для установки масштаба',
      });

      return Array.from(this._errors);
    }

    if (this._data && Object.values(this._data).some(item => !item)) {
      this._errors.add({
        id: uuid(),
        objectType: RULER,
        description:
          'Масштаб задан не на всех этажах. Используйте инструмент "Линейка" для установки масштаба',
      });

      return Array.from(this._errors);
    }

    return Array.from(this._errors);
  }
}
