import { Circle, Group, Image, Line } from 'react-konva';
import useImage from 'use-image';

import PlusSvg from '@components/icons/Plus.svg';

import { IPoint } from '@features/tpu-simulation-model/types';

interface RulerProps {
  isScaleBeingMeasured: boolean;
  startPoint: IPoint | null;
  endPoint: IPoint | null;
  mousePosition: IPoint;
}

interface PointProps {
  point: IPoint | null;
}

const Point = ({ point }: PointProps) => {
  const [plus] = useImage(PlusSvg);
  if (!point) return null;

  return (
    <Group>
      <Circle
        x={point.x}
        y={point.y}
        radius={12}
        stroke={'#fff'}
        fill={'#273B48'}
        strokeWidth={1}
      />
      <Image
        image={plus}
        x={point.x - 8}
        y={point.y - 8}
      />
    </Group>
  );
};

export const Ruler = ({ startPoint, mousePosition, endPoint }: RulerProps) => {
  if (!startPoint) return null;

  return (
    <>
      <Line
        strokeWidth={2}
        stroke="#3679EA"
        closed={false}
        points={[
          startPoint.x,
          startPoint.y,
          endPoint ? endPoint.x : mousePosition.x,
          endPoint ? endPoint.y : mousePosition.y,
        ]}
      />
      <Point point={startPoint} />
      <Point point={endPoint} />
    </>
  );
};
