import { useEffect } from 'react';

import './stores/project-registry/init';

import { Component } from '@features/tpu-project-registry/component';
import {
  PRHandlersContext,
  usePRHandlers,
} from '@features/tpu-project-registry/hooks';
import { Modals } from '@features/tpu-project-registry/modals';
import { Notifications } from '@features/tpu-project-registry/notifications';

export const TpuProjectRegistryContainer = () => {
  const value = usePRHandlers();

  useEffect(() => value.resetProjectRegistryPageData, [value]);

  return (
    <PRHandlersContext.Provider value={value}>
      <Component />
      <Modals />
      <Notifications />
    </PRHandlersContext.Provider>
  );
};
