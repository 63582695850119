import { useUnit } from 'effector-react';
import Konva from 'konva';
import { useEffect, useRef, useState } from 'react';
import { Image, Layer, Stage } from 'react-konva';
import useImage from 'use-image';

import { Constructor } from '@features/tpu-simulation-model/components/Editor/Constructor';
import { Ruler } from '@features/tpu-simulation-model/components/Editor/Ruler';
import { SpatialObjects } from '@features/tpu-simulation-model/components/Editor/SpatialObjects';
import { useSimulationModelHandlers } from '@features/tpu-simulation-model/hooks';
import { useEscapePress } from '@features/tpu-simulation-model/hooks/useEscapePress';
import { $Editor } from '@features/tpu-simulation-model/store';
import {
  EditableObjectType,
  IPoint,
} from '@features/tpu-simulation-model/types';

interface EditorProps {
  points: IPoint[] | null;
  createdObjectType: EditableObjectType | null;
  rulerStartPoint: IPoint | null;
  rulerEndPoint: IPoint | null;
  floor: string;
  isScaleBeingMeasured: boolean;
  width?: number;
  height?: number;
}

const getPoint = (instance: Konva.Stage | null): IPoint | null => {
  if (!instance) return null;
  const point = instance!.getRelativePointerPosition();
  return point ? { x: Math.round(point.x), y: Math.round(point.y) } : null;
};

const scaleBy = 1.1;

export const Editor = ({
  points,
  width,
  floor,
  isScaleBeingMeasured,
  height,
  rulerStartPoint,
  rulerEndPoint,
  createdObjectType,
}: EditorProps) => {
  const [floorImg] = useImage(floor);
  const stageRef = useRef<Konva.Stage | null>(null);
  const {
    addPoint,
    addRulerPoint,
    onComplete,
    cancelCreation,
    resetRulerMeasurement,
    setScale,
    setPosition,
    resetEditableObjectData,
    onTriggeredCenter,
    resetInputFindObject,
    removeLastPoint,
  } = useSimulationModelHandlers();
  const $editor = useUnit($Editor);

  const [mousePosition, setMousePosition] = useState<IPoint>({ x: 0, y: 0 });

  const handleEscapePress = () => {
    resetEditableObjectData();
    cancelCreation();
    resetRulerMeasurement();
    resetInputFindObject();
  };

  useEscapePress(handleEscapePress);

  useEffect(() => {
    if ($editor.shouldCenterStagePosition) {
      stageRef.current?.position({ x: 0, y: 0 });
      onTriggeredCenter();
    }
  }, [$editor.shouldCenterStagePosition, onTriggeredCenter]);

  return (
    <Stage
      scale={{ x: $editor.scale, y: $editor.scale }}
      onClick={e => {
        const point = getPoint(stageRef.current);
        if (point && e.evt.button === 0) {
          isScaleBeingMeasured ? addRulerPoint(point) : addPoint(point);
        }
      }}
      onContextMenu={e => {
        e.evt.preventDefault();
        removeLastPoint();
      }}
      onMouseMove={() => {
        const point = getPoint(stageRef.current);
        if (point) {
          setMousePosition(point);
        }
      }}
      onDragEnd={() => {
        const stage = stageRef.current;
        if (!stage) return;
        setPosition(stage.position());
      }}
      onWheel={e => {
        // stop default scrolling
        e.evt.preventDefault();
        const stage = stageRef.current;
        const pointer = stage?.getPointerPosition();
        if (!stage || !pointer) return;

        const oldScale = stage.scaleX();

        const mousePointTo = {
          x: (pointer.x - stage.x()) / oldScale,
          y: (pointer.y - stage.y()) / oldScale,
        };

        // how to scale? Zoom in? Or zoom out?
        let direction = e.evt.deltaY > 0 ? 1 : -1;

        // when we zoom on trackpad, e.evt.ctrlKey is true
        // in that case lets revert direction
        if (e.evt.ctrlKey) {
          direction = -direction;
        }

        let newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;
        if (newScale > 3) {
          newScale = 3;
        }
        if (newScale < 0.25) {
          newScale = 0.25;
        }

        setScale(newScale);

        const newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        };
        stage.position(newPos);
        setPosition(newPos);
      }}
      width={width}
      height={height}
      ref={stageRef}
      draggable={true}
    >
      {/* todo: попробовать перенести фон вне редактора */}
      <Layer>
        <Image image={floorImg} />
      </Layer>
      <SpatialObjects listening={!isScaleBeingMeasured && !createdObjectType} />
      <Layer>
        {createdObjectType && (
          <Constructor
            onComplete={onComplete}
            createdObjectType={createdObjectType}
            points={points}
            mousePosition={mousePosition}
          />
        )}
        <Ruler
          isScaleBeingMeasured={isScaleBeingMeasured}
          mousePosition={mousePosition}
          startPoint={rulerStartPoint}
          endPoint={rulerEndPoint}
        />
      </Layer>
    </Stage>
  );
};
