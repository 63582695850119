import { SvgIcon, SvgIconProps } from '@mui/material';

export const HeatIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M18.209 4C16.617 5.173 15 8.236 15 9.396c0 .946.474 1.778.973 2.604.138.248.287.493.435.738.288.473.575.946.716 1.372.416 1.251-.828 4.792-1.404 5.89 1.592-1.173 3.21-4.236 3.21-5.396 0-.946-.474-1.778-.974-2.604-.137-.248-.286-.493-.435-.738-.287-.473-.575-.946-.716-1.372-.416-1.251.829-4.792 1.404-5.89ZM13.209 4C11.617 5.173 10 8.236 10 9.396c0 .946.474 1.778.973 2.604.138.248.287.493.435.738.288.473.575.946.716 1.372.416 1.251-.828 4.792-1.404 5.89 1.592-1.173 3.21-4.236 3.21-5.396 0-.946-.474-1.778-.974-2.604a21.135 21.135 0 0 0-.435-.738c-.287-.473-.575-.946-.716-1.372-.416-1.251.829-4.792 1.404-5.89ZM8.209 4C6.617 5.173 5 8.236 5 9.396c0 .946.474 1.778.973 2.604.138.248.287.493.436.738.287.473.574.946.715 1.372.416 1.251-.828 4.792-1.404 5.89 1.592-1.173 3.21-4.236 3.21-5.396 0-.946-.474-1.778-.974-2.604-.137-.248-.286-.493-.435-.738-.287-.473-.575-.946-.716-1.372-.416-1.251.829-4.792 1.404-5.89Z"
        fill="#273B48"
      />
    </SvgIcon>
  );
};
