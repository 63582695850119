import { ChangeEvent, useCallback } from 'react';

import { EFilterMapLayout } from './interface';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

export type SelectLayoutProps = {
  layout: EFilterMapLayout;
  onChangeLayout?: (layout: EFilterMapLayout) => void;
};
export const SelectLayout = (props: SelectLayoutProps) => {
  const handleChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, value: string) => {
      if (props.onChangeLayout) props.onChangeLayout(value as EFilterMapLayout);
    },
    [props],
  );

  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      name="radio-buttons-group"
      value={props.layout}
      onChange={handleChange}
      sx={{
        p: '16px 32px 16px 44px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        '& .MuiFormControlLabel-root:has(.Mui-checked) ': {
          bgcolor: 'background.default',
        },
        '& .MuiFormControlLabel-root': {
          mr: 1,
          borderRadius: theme => theme.shape.borderRadius * 0.25,
        },
        paddingBottom: theme => theme.spacing(3.125),
      }}
    >
      <FormControlLabel
        value={EFilterMapLayout.Infrastructure}
        control={<Radio />}
        label="Инфраструктура"
      />
      <FormControlLabel
        value={EFilterMapLayout.RouteNetwork}
        control={<Radio />}
        label="Маршрутная сеть"
      />
      <FormControlLabel
        value={EFilterMapLayout.PassengerFlows}
        control={<Radio />}
        label="Пассажиропотоки"
      />
    </RadioGroup>
  );
};
