import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckOkOutlineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 12.2667L11.4667 13.7334L14.8 10.4M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
        stroke="white"
        color="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
