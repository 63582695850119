import { Toolbar } from '@features/tpu-calculation-scenarios/components/Toolbar';
import {
  FormCalculationScenarioContainer,
  RegistyCalculationScenariosContainer,
} from '@features/tpu-calculation-scenarios/containers';
import { useContextCSHandlers } from '@features/tpu-calculation-scenarios/hooks';
import { $Form } from '@features/tpu-calculation-scenarios/stores/form.store';
import { useUnit } from 'effector-react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { ROUTES } from '@constants/routes';

export const Component = () => {
  const navigate = useNavigate();
  const { openFormForCreateNewScenario, setIsOpenModalPlanErrors } =
    useContextCSHandlers();
  const $form = useUnit($Form);

  const onLaunchClick = () => {
    setIsOpenModalPlanErrors(true);
  };

  const goToSimulationViewer = () => {
    navigate(
      `${ROUTES.TPU_SCENARIOS}/${$form.id}${ROUTES.TPU_SCENARIOS_VIEWER}`,
    );
  };

  const goToSimulationModelEditor = () => {
    navigate(`${ROUTES.TPU_EDITOR}/${$form.id}`);
  };

  const handleClickBack = () => {
    navigate(ROUTES.TPU);
  };

  return (
    <Box sx={{ padding: '24px' }}>
      <Toolbar
        onCreateScenario={openFormForCreateNewScenario}
        onBack={handleClickBack}
        onLaunchClick={onLaunchClick}
        goToSimulationViewer={goToSimulationViewer}
        goToSimulationModelEditor={goToSimulationModelEditor}
      />
      <AutoFullHeight
        mb={24}
        style={{ display: 'flex', gap: '24px' }}
      >
        <RegistyCalculationScenariosContainer />
        <FormCalculationScenarioContainer />
      </AutoFullHeight>
    </Box>
  );
};
