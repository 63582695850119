import { apiClient } from '@api/client';
import { IQuery, IQueryGetProjectByIdArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import { getTimelineYearsFx } from '@features/ag-forecast/stores/yearLine';
import { YearLineApi } from '@features/ag-project/stores/yearLine/store';

getTimelineYearsFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getProjectById'>,
    IQueryGetProjectByIdArgs
  >({
    query: gql`
      query QueryTimelineYears($id: String!) {
        getProjectById(id: $id) {
          timelineEnd
          timelineStart
        }
      }
    `,
    variables: params,
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  return result.data?.getProjectById || null;
});

sample({
  clock: getTimelineYearsFx.done,
  fn: payload => payload.result?.timelineStart,
  target: YearLineApi.setMinYear,
});

sample({
  clock: getTimelineYearsFx.done,
  fn: payload => payload.result?.timelineEnd,
  target: YearLineApi.setMaxYear,
});
