import {
  $FilterRegions,
  FilterRegionsApi,
} from '@features/passenger-traffic/stores/filterRegions';
import {
  $FilterMap,
  resetAllFilters,
  setLayoutFn,
} from '@features/passenger-traffic/stores/filters';
import { sample } from 'effector';

import { EFilterMapLayout } from '@components/MapFilters';

import { getDataForInspectorFn } from '../inspector';
import { onCloseInspectorFn, setVisibleInspector } from './store';

// ЕСли обе точки установленны, отобразить инспектор
sample({
  clock: FilterRegionsApi.setSelectedRegion,
  source: { FilterMap: $FilterMap, FilterRegions: $FilterRegions },
  filter: ({ FilterMap, FilterRegions }) =>
    Boolean(
      FilterRegions.selectedRegionA &&
        FilterRegions.selectedRegionB &&
        (FilterMap.layout === EFilterMapLayout.PassengerFlows ||
          FilterMap.layout === EFilterMapLayout.RouteNetwork),
    ),
  fn: () => true,
  target: setVisibleInspector,
});

// Показ инспектора инфраструктуры при клике по объекту
sample({
  clock: getDataForInspectorFn,
  fn: () => true,
  target: setVisibleInspector,
});

// отображение и скрытие инспектора при переключении слоев
sample({
  clock: setLayoutFn,
  source: { FilterMap: $FilterMap, FilterRegions: $FilterRegions },
  fn: ({ FilterMap, FilterRegions }) => {
    if (
      (FilterMap.prevLayout === EFilterMapLayout.PassengerFlows ||
        FilterMap.prevLayout === EFilterMapLayout.RouteNetwork) &&
      FilterMap.layout === EFilterMapLayout.Infrastructure
    ) {
      return false;
    }

    if (FilterRegions.selectedRegionA && FilterRegions.selectedRegionB) {
      return true;
    } else {
      return false;
    }
  },
  target: setVisibleInspector,
});

// скрытие инспектора при сбросе фильтров
sample({
  clock: resetAllFilters,
  target: onCloseInspectorFn,
});
