import { useUnit } from 'effector-react';
import { useMemo } from 'react';

import { Box } from '@mui/material';

import { PieChart, PieChartProps } from '@components/PieChart';

import { VehicleTypeCode } from '@features/pt-forecast-new/constants/VehicleTypeCode';
import { EVehicle } from '@features/pt-forecast-new/constants/eVehicle';
import { $ComputedData } from '@features/pt-forecast-new/stores/computedData';
import { getTrafficColor } from '@features/pt-forecast-new/utils/getTrafficColor';

import { convertUnitOfMeasure } from '@utils/dataForInspector';

type StructurePassChartProps = {
  isFull: boolean;
};

export const StructurePassChart = (props: StructurePassChartProps) => {
  const { isFull } = props;
  const { passengerTrafficPercent, recalculatedTotal } = useUnit($ComputedData);

  /** Сумма пассажиропотока для графика */
  const totalChartInfo = useMemo<PieChartProps['totalInfo']>(() => {
    const data = convertUnitOfMeasure({
      value: recalculatedTotal,
      unit: 'пасс.',
      isReturnArray: true,
    });

    return {
      totalValue: data[0],
      totalLabel: `${data[1]} ${data[2]}`,
    };
  }, [recalculatedTotal]);

  /** Легенда графика */ //TODO: Требуется рефакторинг
  const pieChartData = useMemo<PieChartProps['data'] | null>(() => {
    const data = [];

    for (const vehicleId in passengerTrafficPercent) {
      const key = vehicleId as EVehicle;

      const label: VehicleTypeCode = VehicleTypeCode[key];

      if (key) {
        data.push({
          value: passengerTrafficPercent[key],
          label,
          color: getTrafficColor(label),
        });
      }
    }

    return data;
  }, [passengerTrafficPercent]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100%)',
      }}
    >
      {pieChartData && (
        <PieChart
          data={pieChartData}
          totalInfo={totalChartInfo}
          sizeParams={{
            width: isFull ? 320 : 140,
            height: isFull ? 220 : 140,
            innerRadius: isFull ? 70 : 45,
            outerRadius: isFull ? 110 : 70,
            paddingAngle: 1,
          }}
        />
      )}
    </Box>
  );
};
