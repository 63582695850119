import { Tooltip, TooltipProps, styled } from '@mui/material';

export const StyledTooltip = styled(({ children, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: props.className }}
  >
    {children}
  </Tooltip>
))({});
