import { IQuery, IQueryFindRegionArgs } from '@api/gql/types';
import { createApi, createEffect, createStore } from 'effector';

const initState: FeatureSettingsStore = {
  projectUUID: null,
  regionUUID: null,
};

export const $FeatureSettings = createStore<FeatureSettingsStore>(initState);

export const FeatureSettingsApi = createApi($FeatureSettings, {
  setProjectUUID: (store, projectUUID: string) => ({ ...store, projectUUID }),
  setRegionUUID: (store, regionUUID: string) => ({ ...store, regionUUID }),
  reset: () => initState,
});

export const FindRegionFx = createEffect<
  IQueryFindRegionArgs,
  IQuery['findRegion']
>();

type FeatureSettingsStore = {
  projectUUID: string | null;
  regionUUID: string | null;
};
