import {
  IScenarioBarChartResultKindEnum,
  IScenarioBarChartResultType,
  IScenarioPieChartResultKindEnum,
  IScenarioPieChartResultType,
} from '@api/gql/tpu-types';

import { nonRenderableObjectTypes } from './createTableRows';

import { DataForCommercialObjects } from '../stores/chartsCommercialObjects/chartsCommercialObjects.store';

export const isAgentQuantity = (item: IScenarioBarChartResultType): boolean =>
  item.kind === IScenarioBarChartResultKindEnum.AgentQuantity;

export const isCumulativeAgentsQuantity = (
  item: IScenarioBarChartResultType,
): boolean =>
  item.kind === IScenarioBarChartResultKindEnum.CumulativeTotalAgents;

export const isExit = (item: IScenarioPieChartResultType): boolean =>
  item.kind === IScenarioPieChartResultKindEnum.Exits;

export const isEntrance = (item: IScenarioPieChartResultType): boolean =>
  item.kind === IScenarioPieChartResultKindEnum.Entrances;

export const isNonRenderableObject = (
  item: DataForCommercialObjects,
): boolean => nonRenderableObjectTypes.includes(item.type);
