import { sample } from 'effector';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EMapFeatureLayout, EMapFeatureMetaKeys } from '@constants/map';

import { pipeSearchInfraVectorLayer } from '@features/pt-forecast-new/stores/map';
import { LayersVisibleApi } from '@features/pt-forecast-new/stores/map/layersVisible';
import {
  $SearchInfra,
  SearchInfraApi,
} from '@features/pt-forecast-new/stores/map/searchInfra';

import {
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload && Object.keys(payload).includes(EMapFeatureLayout.ptSearchInfra),
  fn: payload => {
    return {
      action: EPipeVectorLayer.setVisible,
      payload: payload![EMapFeatureLayout.ptSearchInfra],
    } as PipeVectorLayer;
  },
  target: pipeSearchInfraVectorLayer,
});

// Рендер геометрии
sample({
  clock: SearchInfraApi.setGeometry,
  source: { SearchInfra: $SearchInfra },
  fn: ({ SearchInfra }) => {
    const features: Feature<Point>[] = [];

    if (SearchInfra.features.airport) {
      features.push(
        new Feature({
          geometry: new Point(
            fromLonLat(SearchInfra.features.airport.geometry),
          ),
          [EMapFeatureMetaKeys.olId]: SearchInfra.features.airport.id,
          [EMapFeatureMetaKeys.layout]: EMapFeatureLayout.airport,
        }),
      );
    } else if (SearchInfra.features.busStation) {
      features.push(
        new Feature({
          geometry: new Point(
            fromLonLat(SearchInfra.features.busStation.geometry),
          ),
          [EMapFeatureMetaKeys.olId]: SearchInfra.features.busStation.id,
          [EMapFeatureMetaKeys.layout]: EMapFeatureLayout.busStation,
        }),
      );
    } else if (SearchInfra.features.railwayStation) {
      features.push(
        new Feature({
          geometry: new Point(
            fromLonLat(SearchInfra.features.railwayStation.geometry),
          ),
          [EMapFeatureMetaKeys.olId]: SearchInfra.features.railwayStation.id,
          [EMapFeatureMetaKeys.layout]: EMapFeatureLayout.railwayStation,
        }),
      );
    }

    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: {
        features,
      },
    } as PipeVectorLayer;
  },
  target: pipeSearchInfraVectorLayer,
});
