import { apiClient } from '@api/client';
import { IQuery, IWaysPlatformsType } from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { TPU_CLIENT_NAME } from '@constants/api';

import {
  $ScenarioId,
  resetWaysPlatformsFn,
} from '@features/tpu-simulation-model/store';
import {
  $ListOfWaysPlatforms,
  getWaysPlatformsByScenarioIdFx,
  getWaysPlatformsFn,
} from '@features/tpu-simulation-model/store/listOfWaysPlatforms/listOfWaysPlatforms.store';

$ListOfWaysPlatforms
  .reset(resetWaysPlatformsFn)
  .on(getWaysPlatformsByScenarioIdFx.done, (_, { result }) => {
    if (result) {
      return result;
    }
  });

sample({
  clock: getWaysPlatformsFn,
  source: $ScenarioId,
  filter: id => !!id,
  fn: id => id as string,
  target: getWaysPlatformsByScenarioIdFx,
});

getWaysPlatformsByScenarioIdFx.use(async scenarioId => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query WaysPlatformsByScenarioId($scenarioId: UUID!) {
        waysPlatformsByScenarioId(scenarioId: $scenarioId) {
          ways
          platforms
        }
      }
    `,
    variables: {
      scenarioId,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data.waysPlatformsByScenarioId as IWaysPlatformsType;
});
