import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Divider, Stack } from '@mui/material';

import { ROUTES } from '@constants/routes';

import { SearchInput } from '@features/passenger-traffic/components/ToolBar/SearchInput';

type ToolBarProps = {
  onToggleFilterPanel(): void;
};
export const ToolBar = (props: ToolBarProps) => {
  const { onToggleFilterPanel } = props;

  const navigate = useNavigate();

  const handleClickBuilder = useCallback(() => {
    navigate(ROUTES.PASSENGER_TRAFFIC_CONSTRUCTOR);
  }, [navigate]);

  return (
    <Stack
      alignItems={'center'}
      py={2}
      bgcolor={'common.white'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      px={5.5}
    >
      <Stack flexDirection={'row'}>
        <Stack
          width={'calc(500px - 44px)'}
          pr={5.5}
        >
          <Button
            fullWidth
            color={'primary'}
            variant={'contained'}
            onClick={onToggleFilterPanel}
          >
            Транспортные слои
          </Button>
        </Stack>
        <Divider
          sx={{ borderColor: '#EBECEF', mr: 5.5 }}
          orientation={'vertical'}
          flexItem
        />
        <SearchInput />
      </Stack>

      <Button
        color={'secondary'}
        variant={'contained'}
        onClick={handleClickBuilder}
      >
        Конструктор прогнозов
      </Button>
    </Stack>
  );
};
