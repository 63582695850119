import { useEffect } from 'react';

import { ToolBar } from './components/ToolBar';
import {
  SettlementRegisterContainer,
  SocioEconomicContainer,
  TransportationContainer,
} from './containers';
import { fetchAllScenarios } from './store/lists/scenarios/store';
import { fetchLongStatusesFx } from './store/lists/statuses/store';

import Grid from '@mui/material/Unstable_Grid2';

export const Component = () => {
  useEffect(() => {
    fetchAllScenarios();
    fetchLongStatusesFx();
  }, []);

  return (
    <Grid
      container
      spacing={4}
      px={1}
      m={0}
    >
      <Grid xs={12}>
        <ToolBar />
      </Grid>

      <SettlementRegisterContainer />

      <SocioEconomicContainer />

      <TransportationContainer />
    </Grid>
  );
};
