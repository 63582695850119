import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { $Modals } from '@features/ag-forecast/stores/modals';

export type ModalConfirmCancelEditTpuProps = {
  onClose: () => void;
};

export const ModalConfirmCancelEditTpu = ({
  onClose,
}: ModalConfirmCancelEditTpuProps) => {
  const { action } = useUnit($Modals);

  const handleClickSaveChanges = useCallback(() => {
    action && action(true);
    onClose();
  }, [action, onClose]);

  const handleClickDiscardChanges = useCallback(() => {
    action && action(false);
    onClose();
  }, []);

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog
      open={true}
      PaperProps={{ sx: { minWidth: '700px' } }}
    >
      <DialogTitle
        variant="subtitle2"
        sx={{
          position: 'relative',
          fontSize: theme => theme.typography.pxToRem(16),
          pr: 10,
        }}
      >
        Сохранить изменения?
      </DialogTitle>

      <DialogContent>
        <Typography>
          При выходе из режима редактирования вы потеряете все изменения. Вы
          действительно хотите выйти?
        </Typography>
      </DialogContent>

      <DialogActions sx={{ gridTemplateColumns: 'repeat(3, 1fr)!important' }}>
        <Button
          color={'secondary'}
          variant={'contained'}
          onClick={handleClickSaveChanges}
        >
          Сохранить изменения
        </Button>

        <Button
          variant={'outlined'}
          onClick={handleClickDiscardChanges}
        >
          Не сохранять
        </Button>

        <Button
          variant={'outlined'}
          onClick={handleCancel}
        >
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};
