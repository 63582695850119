import { IMutationUpdateComputedResultsParamsArgs } from '@api/gql/types';

import { RecalculateStore } from '@features/pt-forecast-new/stores/recalculate';

export const updateComputedResultsParamsReformatter = (
  params: RecalculateStore,
): IMutationUpdateComputedResultsParamsArgs => ({
  durationAuto: params.duration.auto,
  durationAvia: params.duration.avia,
  durationBus: params.duration.bus,
  durationDayTrain: params.duration.dayTrain,
  durationExpressSuburbanTrain: params.duration.expressTrain,
  durationNightTrain: params.duration.nightTrain,
  durationPlain: params.duration.avia,
  durationSuburbanTrain: params.duration.suburbanTrain,
  frequencyAvia: params.frequency.avia,
  frequencyBus: params.frequency.bus,
  frequencyDayTrain: params.frequency.dayTrain,
  frequencyExpressSuburbanTrain: params.frequency.expressTrain,
  frequencyNightTrain: params.frequency.nightTrain,
  frequencyPlain: params.frequency.avia,
  frequencySuburbanTrain: params.frequency.suburbanTrain,
  id: params.id,
  priceAuto: params.price.auto,
  priceAvia: params.price.avia,
  priceBus: params.price.bus,
  priceDayTrain: params.price.dayTrain,
  priceExpressSuburbanTrain: params.price.expressTrain,
  priceNightTrain: params.price.nightTrain,
  pricePlain: params.price.avia,
  priceSuburbanTrain: params.price.suburbanTrain,
});
