import { PassengerTypeForm } from '@features/tpu-passenger-types/forms';
import { useContextPassengerTypesHandlers } from '@features/tpu-passenger-types/hooks';
import {
  $Form,
  $IsOpenTypeForm,
} from '@features/tpu-passenger-types/stores/form.store';
import { useUnit } from 'effector-react';

export const FormPassengerTypeContainer = () => {
  const $form = useUnit($Form);
  const $isOpenForm = useUnit($IsOpenTypeForm);

  const { setIsOpenTypeForm, saveType, savePassengerType } =
    useContextPassengerTypesHandlers();

  const closeForm = () => setIsOpenTypeForm(false);

  if (!$isOpenForm) return null;

  return (
    <PassengerTypeForm
      formData={$form}
      onClose={closeForm}
      onSave={saveType}
      onSubmit={savePassengerType}
    />
  );
};
