export const UPLOAD_LIMIT = 100;
export const START_OF_SIMULATION_PERIOD = 10800000;
export const colors = [
  '#7E67DD',
  '#8AB0D2',
  '#A3A86B',
  '#00A1DC',
  '#FF792E',
  '#FF7F7F',
  '#83CA6B',
  '#79A2F2',
  '#FFCE7C',
  '#A0E5FF',
  '#FFB68D',
  '#D99CFF',
];
