import { useUnit } from 'effector-react';
import { CSSProperties, memo, useEffect } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { Loading } from '@components/Loading/Loading';
import { EFilterMapLayout } from '@components/MapFilters';

import {
  AirportInspector,
  AutoInspector,
  BusStationInspector,
  PassengerTrafficInspector,
  RailwayInspector,
  RailwayStationInspector,
  RouteNetworkInspector,
  TransportAreaInspector,
} from '@features/passenger-traffic/components/Inspector';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { setFeatureSelectedStyle } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/featureTools';
import { mapSelectedFeature } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/helpers';
import { usePTHandlers } from '@features/passenger-traffic/hooks';
import {
  $FilterMap,
  $FilterRegions,
  $InfrastructureInspector,
  $Inspector,
  $Map,
  $PassengerTrafficInspector,
  $RouteNetworkInspector,
  $UI,
} from '@features/passenger-traffic/stores';

const InspectorContainerMuiStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '0',
  right: '0',
  zIndex: '2',
  background: theme => theme.palette.background.default,
};

const AutoFullHeightStyle: CSSProperties = {
  borderTop: '1px solid #EBECEF',
  padding: '32px 12px 32px 44px',
  boxShadow: 'rgba(0, 0, 0, 0.1) -5px 4px 2px 0px',
  width: '500px',
  maxWidth: '500px',
  overflowY: 'auto',
};

export const InspectorContainer = memo(() => {
  const handlersToggleInspectorPanel = usePTHandlers().toggleInspectorPanel;
  const {
    railway,
    railwayStation,
    highway,
    busStation,
    airport,
    transportRegion,
  } = useUnit($InfrastructureInspector);
  const $routeNetworkInspector = useUnit($RouteNetworkInspector);
  const $passengerTrafficInspector = useUnit($PassengerTrafficInspector);
  const $inspector = useUnit($Inspector);
  const $filterMap = useUnit($FilterMap);
  const { map } = useUnit($Map);
  const $filterRegions = useUnit($FilterRegions);
  const { panels, loading } = useUnit($UI);

  const mapLayout = $filterMap.layout;
  const layout = $inspector.layout;

  const InspectorLayout = (() => {
    if (mapLayout === EFilterMapLayout.PassengerFlows) {
      return (
        <PassengerTrafficInspector
          passengerTrafficInspector={$passengerTrafficInspector}
          onClose={handlersToggleInspectorPanel}
          filter={$filterMap}
          filterRegions={$filterRegions}
        />
      );
    } else if (mapLayout === EFilterMapLayout.RouteNetwork) {
      return (
        <RouteNetworkInspector
          layout={layout}
          onClose={handlersToggleInspectorPanel}
          pointFrom={$filterRegions.selectedRegionA?.center?.name}
          pointTo={$filterRegions.selectedRegionB?.center?.name}
          filter={$filterMap}
          routeNetworkInspector={$routeNetworkInspector}
        />
      );
    } else {
      switch (layout) {
        case EMapFeatureLayout.railway:
          return (
            <RailwayInspector
              fields={railway}
              onClose={handlersToggleInspectorPanel}
            />
          );
        case EMapFeatureLayout.railwayStation:
          return (
            <RailwayStationInspector
              fields={railwayStation}
              onClose={handlersToggleInspectorPanel}
            />
          );
        case EMapFeatureLayout.highway:
          return (
            <AutoInspector
              fields={highway}
              onClose={handlersToggleInspectorPanel}
            />
          );
        case EMapFeatureLayout.busStation:
          return (
            <BusStationInspector
              fields={busStation}
              onClose={handlersToggleInspectorPanel}
            />
          );
        case EMapFeatureLayout.airport:
          return (
            <AirportInspector
              fields={airport}
              onClose={handlersToggleInspectorPanel}
            />
          );
        case EMapFeatureLayout.areaBorder:
          return (
            <TransportAreaInspector
              fields={transportRegion}
              onClose={handlersToggleInspectorPanel}
            />
          );
      }
    }
  })();

  // удаление стилей выбранного объекта на карте при закрытии инспектора
  useEffect(() => {
    if (panels.inspector || !map) {
      return;
    }

    setFeatureSelectedStyle({
      map: map,
      feature: map.get(mapSelectedFeature),
      isActive: false,
    });
  }, [map, panels.inspector]);

  return (
    panels.inspector && (
      <Box sx={InspectorContainerMuiStyle}>
        {(mapLayout === EFilterMapLayout.PassengerFlows ||
          mapLayout === EFilterMapLayout.RouteNetwork) && (
          <Loading
            isLoading={loading.inspectorPassTraffic}
            sx={{
              width: '500px',
              height: 'calc(100% - 150px)',
            }}
          />
        )}

        <AutoFullHeight style={AutoFullHeightStyle}>
          {InspectorLayout}
        </AutoFullHeight>
      </Box>
    )
  );
});
