import { apiClient } from '@api/client';
import {
  IMutation,
  ISocEcoParamsUpdateInput,
  ITransportParamsInput,
} from '@api/gql/types';
import { gql } from '@apollo/client';

import { TransportProject } from '@features/pt-constructor/store';

export type TransportProjectCreateInput = {
  name: TransportProject['name'];
  year: TransportProject['year'];
};

export type QueryCreateUserScenarioArgs = {
  name: string;
  socEcoScenarioId?: string;
  socEcoScenarioType?: number;
  socEcoScenarioParams?: ISocEcoParamsUpdateInput;
  transportScenarioId?: string;
  transportScenarioType?: number;
  transportScenarioParams?: ITransportParamsInput;
};

export const queryCreateUserScenario = async (
  newUserScenario: QueryCreateUserScenarioArgs,
) => {
  const result = await apiClient.mutate<IMutation, QueryCreateUserScenarioArgs>(
    {
      mutation: gql`
        mutation QueryCreateComputingRegistry(
          $name: String!
          $socEcoScenarioId: UUID
          $socEcoScenarioType: Int
          $socEcoScenarioParams: SocEcoParamsUpdateInput
          $transportScenarioParams: TransportParamsInput
          $transportScenarioId: UUID
          $transportScenarioType: Int
        ) {
          createComputingRegistry(
            name: $name
            socEcoScenarioParams: $socEcoScenarioParams
            socEcoScenarioId: $socEcoScenarioId
            socEcoScenarioType: $socEcoScenarioType
            transportScenarioParams: $transportScenarioParams
            transportScenarioId: $transportScenarioId
            transportScenarioType: $transportScenarioType
          ) {
            computingRegistryObj {
              id
              name
              socEcoScenario {
                growthRateInTheNumberOfPlacements
                scenarioType
                revenueGrowthRate
                growthRateOfMotorization
                populationGrowthRate
                jobGrowthRate
                salaryGrowthRate
                name
                id
              }
              transportScenario {
                id
                name
                scenarioType
                transportProjects {
                  id
                  year
                  transportProject {
                    name
                  }
                }
              }
            }
          }
        }
      `,
      variables: newUserScenario,
    },
  );
  if (result.errors?.length) throw new Error(result.errors[0].message);
  return result.data?.createComputingRegistry?.computingRegistryObj;
};
