import { apiClient } from '@api/client';
import { IQuery } from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';
import { matchPath } from 'react-router-dom';

import { TPU_CLIENT_NAME } from '@constants/api';
import { ROUTES } from '@constants/routes';

import {
  $ScenarioData,
  IScenarioData,
  getScenarioDataByScenarioIdFx,
  getScenarioIdFn,
} from '@features/tpu-calculation-scenarios-viewing/stores/scenarioId/scenarioId.store';

import { resetScenarioPlayerFn } from '../scenarioPlayer/scenarioPlayer.store';

$ScenarioData
  .reset(resetScenarioPlayerFn)
  .on(getScenarioIdFn, state => {
    const { pathname } = window.location;

    const matchedInfo = matchPath(
      { path: `${ROUTES.TPU_SCENARIOS}/:id/*` },
      pathname,
    );

    return { ...state, scenarioId: matchedInfo?.params?.id };
  })
  .on(getScenarioDataByScenarioIdFx.done, (state, { result }) => {
    if (result) {
      return {
        ...state,
        scenarioName: result.scenarioName,
        projectName: result.projectName,
        projectId: result.projectId,
      };
    }
  });

sample({
  clock: getScenarioIdFn,
  source: $ScenarioData,
  filter: ({ scenarioId }) => !!scenarioId,
  fn: sourceData => sourceData.scenarioId as string,
  target: getScenarioDataByScenarioIdFx,
});

getScenarioDataByScenarioIdFx.use(async id => {
  const result = {} as IScenarioData;

  const response = await apiClient.query<IQuery>({
    query: gql`
      query ScenarioById($id: UUID!) {
        scenarioById(id: $id) {
          name
          project {
            id
            name
          }
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  result.scenarioName = response.data.scenarioById?.name;
  result.projectName = response.data.scenarioById?.project?.name ?? undefined;
  result.projectId = response.data.scenarioById?.project?.id;

  return result;
});
