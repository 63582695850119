import { PropsWithChildren } from 'react';

import { Card, CardContent, CardProps, Typography } from '@mui/material';

export type DashboardCardProps = PropsWithChildren<{ title: string }> &
  CardProps;

export const DashboardCard = (props: DashboardCardProps) => {
  const { title, children, ...rest } = props;

  return (
    <Card
      sx={{ width: '100%' }}
      {...rest}
    >
      <CardContent sx={{ p: 2, height: '100%' }}>
        <Typography variant={'subtitle2'}>{title}</Typography>

        {children}
      </CardContent>
    </Card>
  );
};
