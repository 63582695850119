import { apiClient } from '@api/client';
import {
  IQuery,
  IQueryGetTransportDistrictByPointArgs,
} from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';
import { toLonLat } from 'ol/proj';

import { $FilterMap, fetchTransportDistrictXYFx } from './store';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import { FilterMapApi } from '@features/ag-forecast/stores/filters';
import { RegionOptionItem } from '@features/ag-forecast/stores/lists/regionsOptions';
import {
  $TimeSlots,
  TimeSlotsApi,
} from '@features/ag-forecast/stores/lists/timeSlots';

import { pointDirection } from '@utils/map/styles/agTransportFlowDistrictsVectorStyle';

import { clickMap } from '../map';
import { $FeatureSettings } from '../settings';
import { $UI, UIApi } from '../ui/store';

// сеттер дефолтного значения временного слота
sample({
  clock: TimeSlotsApi.setTimeSlots,
  source: { TimeSlots: $TimeSlots },
  fn: ({ TimeSlots }) => {
    return TimeSlots.options?.[0]?.id;
  },
  target: FilterMapApi.setTimeSlot,
});

// // обновление данных инспектора при изменении временного слота
// sample({
//   clock: FilterMapApi.setTimeSlot,
//   source: {
//     UI: $UI,
//     SelectedFeatures: $SelectedFeatures,
//     FeatureSettings: $FeatureSettings,
//     YearLineStore: $YearLineStore,
//     FilterMap: $FilterMap,
//   },
//   filter: ({ UI, SelectedFeatures, FeatureSettings, FilterMap }) =>
//     !!SelectedFeatures.length &&
//     UI.viewMode === EMapViewMode.passFlow &&
//     !!FeatureSettings.scenarioUUID &&
//     !!FilterMap.selectedTimeSlot,
//   fn: ({ SelectedFeatures, FeatureSettings, YearLineStore, FilterMap }) => {
//     return {
//       graphId: SelectedFeatures[0]!.id,
//       scenarioId: FeatureSettings.scenarioUUID,
//       year: YearLineStore.selectedYear,
//       timeSlotId: FilterMap.selectedTimeSlot,
//     } as IQueryGetComputedGraphByIdArgs;
//   },
//   target: getPassFlowGraphsForInspectorFx,
// });

// Если выбрано направление установки поинта, установить флаг для UI
sample({
  clock: FilterMapApi.setDirectionPoint,
  fn: payload => payload !== null,
  target: UIApi.setIsSetPoint,
});

// TODO: Вынести определение направления поинта в семпл обработки результата запроса
// Если режим установки поинта, перехватить клик карты, и запросить транспортный регион по координатам
sample({
  clock: clickMap,
  source: { UI: $UI, FeatureSettings: $FeatureSettings, FilterMap: $FilterMap },
  filter: ({ UI }) => UI.isSetPoint,
  fn: ({ FeatureSettings, FilterMap }, event) => {
    const [lon, lat] = toLonLat(
      event.target.getCoordinateFromPixel(event.pixel),
    );

    return {
      projectId: FeatureSettings.projectUUID,
      direction: FilterMap.directionPointSelected,
      lon,
      lat,
    } as IQueryGetTransportDistrictByPointArgs & {
      direction: pointDirection | null;
    };
  },
  target: fetchTransportDistrictXYFx,
});

fetchTransportDistrictXYFx.use(async params => {
  const response = await apiClient.query<
    Pick<IQuery, 'getTransportDistrictByPoint'>,
    IQueryGetTransportDistrictByPointArgs
  >({
    query: gql`
      query QueryGetTransportDistrictByPoint(
        $projectId: UUID!
        $lon: Float!
        $lat: Float!
      ) {
        getTransportDistrictByPoint(
          projectId: $projectId
          lon: $lon
          lat: $lat
        ) {
          id
          name
        }
      }
    `,
    variables: {
      projectId: params.projectId,
      lon: params.lon,
      lat: params.lat,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data.getTransportDistrictByPoint || null;
});

// Если по координатам есть регион отправления, добавить
sample({
  clock: fetchTransportDistrictXYFx.done,
  filter: request => request.params.direction === pointDirection.from,
  fn: request => {
    return [
      {
        value: request.result?.id || '',
        label: request.result?.name || '',
      },
    ] as RegionOptionItem[];
  },
  target: FilterMapApi.addRegionFrom,
});

// Если по координатам есть регион назначения, добавить
sample({
  clock: fetchTransportDistrictXYFx.done,
  filter: request => request.params.direction === pointDirection.to,
  fn: request => {
    return [
      {
        value: request.result?.id || '',
        label: request.result?.name || '',
      },
    ] as RegionOptionItem[];
  },
  target: FilterMapApi.addRegionTo,
});

// Сбросить режим установки поинта
sample({
  clock: fetchTransportDistrictXYFx.done,
  fn: () => null,
  target: FilterMapApi.setDirectionPoint,
});
