import { IQuery, IQueryGetProjectScenariosArgs } from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

import { EAgCalculationScenarioStatusType } from '@features/ag-constructor/constants/agCalculationScenarioStatuses';

const initState: CalculationStatusesState = {
  statuses: [],
};

export const $CalculationStatuses =
  createStore<CalculationStatusesState>(initState);

export const CalculationStatusesApi = createApi($CalculationStatuses, {
  setCalculationScenarioStatuses: (
    store,
    payload: UserCalculationsStatuses[],
  ) => ({
    ...store,
    statuses: payload,
  }),
  updateCalculationScenarioStatus: (
    store,
    payload: UserCalculationsStatuses,
  ) => ({
    ...store,
    statuses: [...store.statuses.filter(e => e.id !== payload.id), payload],
  }),
  reset: () => initState,
});

export const fetchScenariosStatusesFx = createEffect<
  IQueryGetProjectScenariosArgs,
  IQuery['getProjectScenarios']
>();

export type UserCalculationsStatuses = {
  id: string;
  status: EAgCalculationScenarioStatusType;
};

export type CalculationStatusesState = {
  statuses: UserCalculationsStatuses[];
};
