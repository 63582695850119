import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';

interface SearchInputProps {
  value: string;
  onSearchChange: (arg: string) => void;
}

export const SearchInput = ({ value, onSearchChange }: SearchInputProps) => {
  return (
    <TextField
      value={value}
      onChange={e => onSearchChange(e.target.value)}
      placeholder="Найти"
      size="medium"
      fullWidth
      InputProps={{
        type: 'search',
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
        sx: {
          backgroundColor: '#F6F6F6 !important',
        },
      }}
    />
  );
};
