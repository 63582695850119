import { Group, Line, Rect } from 'react-konva';

import { IPlatform } from '@features/tpu-simulation-model/types';

export const Platform = (props: IPlatform) => {
  const { point, width, height, rotation } = props;

  return (
    <Group
      x={point.x}
      y={point.y}
      width={width}
      height={height}
      rotation={rotation}
    >
      <Rect
        width={width}
        height={height}
        fill={'#A3D7EB'}
      />
      <Line
        strokeWidth={4}
        stroke="#3679EA"
        closed={true}
        dash={[10, 10]}
        lineJoin={'round'}
        points={[0, 0, width, 0, width, height, 0, height]}
      />
    </Group>
  );
};
