import { useUnit } from 'effector-react';
import { useMemo, useState } from 'react';

import { Box } from '@mui/material';

import { PieChart } from '@features/ag-forecast/containers/DashboardsContainer/components/PieChart';
import { $FirstPageDashboard } from '@features/ag-forecast/stores/dashboard';
import {
  EVehicleColor,
  EVehilceName,
  PassangerTrafficVehilceType,
} from '@features/ag-forecast/stores/dashboard/types';
import { $YearLineStore } from '@features/ag-forecast/stores/yearLine/store';

import { convertUnitOfMeasure } from '@utils/dataForInspector';

export const TotalTransportPassengerTraffic = () => {
  const [currentTotal, setCurrentTotal] = useState(0);

  const { flowByTransport } = useUnit($FirstPageDashboard);
  const { selectedYear } = useUnit($YearLineStore);

  const passengerTraffic = useMemo(() => {
    const initPassengerTraffic: PassangerTrafficVehilceType = {
      railway: 0,
      public: 0,
      private: 0,
    };

    const currentYear = flowByTransport?.find(
      item => item?.year === selectedYear,
    );
    if (!currentYear) return initPassengerTraffic;

    initPassengerTraffic.private = currentYear?.private;
    initPassengerTraffic.public = currentYear?.public;

    return initPassengerTraffic;
  }, [flowByTransport, selectedYear]);

  /** Сумма пассажиропотока для графика */
  const totalChartInfo = useMemo(() => {
    let total = 0;
    for (const vehicle in passengerTraffic) {
      const key = vehicle as keyof PassangerTrafficVehilceType;
      total += passengerTraffic[key];
    }

    const data = convertUnitOfMeasure({
      value: currentTotal,
      unit: 'пасс.',
      isReturnArray: true,
    });

    setCurrentTotal(total);

    return {
      totalValue: data[0],
      totalLabel: data[1] + ' ' + data[2],
    };
  }, [currentTotal, passengerTraffic]);

  /** Легенда графика */
  const pieChartData = useMemo(() => {
    const data = [];

    for (const vehicle in passengerTraffic) {
      const key = vehicle as keyof PassangerTrafficVehilceType;
      const label: EVehilceName = EVehilceName[key];

      if (vehicle) {
        data.push({
          value: passengerTraffic[key],
          label,
          color: EVehicleColor[key],
        });
      }
    }

    return data;
  }, [passengerTraffic]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100% - 92px)',
      }}
    >
      {pieChartData && (
        <PieChart
          data={pieChartData}
          totalInfo={totalChartInfo}
          sizeParams={{
            height: 150,
            width: 240,
            innerRadius: 50,
            outerRadius: 75,
            paddingAngle: 1,
          }}
        />
      )}
    </Box>
  );
};
