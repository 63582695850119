import { IWall } from '@features/tpu-simulation-model/types';

export const getWallLines = (wall: IWall) => {
  return wall.points.reduce<[[number, number], [number, number]][]>(
    (acc, point, currentIndex) => {
      const isLast = currentIndex === wall.points.length - 1;
      if (isLast && !wall.isClosed) return acc;

      const additionalPoint = isLast
        ? wall.points[0]
        : wall.points[currentIndex + 1];

      return [
        ...acc,
        [
          [point.x, point.y],
          [additionalPoint.x, additionalPoint.y],
        ],
      ];
    },
    [],
  );
};
