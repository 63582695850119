import { useUnit } from 'effector-react';
import { createElement } from 'react';
import { Layer } from 'react-konva';

import { SpatialObject } from './spatial-object';

import {
  $ListOfObjects,
  $PlanSettings,
} from '@features/tpu-calculation-scenarios-viewing/stores';
import {
  EditableObjectType,
  IPlatform,
  IRoom,
  IWall,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

export const SpatialObjects = () => {
  const $listOfObjects = useUnit($ListOfObjects);
  const $planSettings = useUnit($PlanSettings);

  if (!$listOfObjects) return null;

  const listByType = Object.values($listOfObjects).reduce(
    (acc, item) => {
      switch (item.type) {
        case EditableObjectType.Room:
          acc.rooms.push(item);
          break;
        case EditableObjectType.Platform:
          acc.platforms.push(item);
          break;
        case EditableObjectType.Wall:
          acc.walls.push(item);
          break;
        default:
          acc.other.push(item);
          break;
      }

      return acc;
    },
    {
      rooms: new Array<IRoom>(),
      platforms: new Array<IPlatform>(),
      walls: new Array<IWall>(),
      other: new Array<ObjectOfTheSimulationModel>(),
    },
  );
  const { rooms, walls, other, platforms } = listByType;
  const list = new Array<ObjectOfTheSimulationModel>().concat(
    rooms,
    platforms,
    walls,
    other,
  );

  return (
    <Layer>
      {list.map(simulationModelObject =>
        createElement(SpatialObject, {
          data: simulationModelObject,
          opacity: $planSettings.opacity,
          key: simulationModelObject.uuid,
        }),
      )}
    </Layer>
  );
};
