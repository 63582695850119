import { v4 as uuid } from 'uuid';

import {
  EditableObjectType,
  IPath,
  IPlatform,
} from '@features/tpu-simulation-model/types';

import {
  IPathPlatformErrorAnalyzer,
  PlanError,
} from '@utils/simulationModel/errors-analyzer/types';

export class PathPlatformErrorAnalyzer implements IPathPlatformErrorAnalyzer {
  _paths = new Set<IPath>();
  _platforms = new Set<IPlatform>();
  _errors = new Set<PlanError>();

  reset() {
    this._paths.clear();
    this._platforms.clear();
    this._errors.clear();
  }
  addPath(data: IPath) {
    this._paths.add(data);
  }
  addPlatform(data: IPlatform) {
    this._platforms.add(data);
  }
  _examIsPathOrPlatformsEmpty() {
    if (!this._paths.size) {
      this._errors.add({
        id: uuid(),
        objectType: EditableObjectType.Path,
        description: 'На плане отсутствуют пути',
      });
    }

    if (!this._platforms.size) {
      this._errors.add({
        id: uuid(),
        objectType: EditableObjectType.Platform,
        description: 'На плане отсутствуют платформы',
      });
    }
  }
  _examIsPathHaveEmptyFields() {
    this._paths.forEach(path => {
      if (!path.way) {
        this._errors.add({
          id: uuid(),
          objectType: EditableObjectType.Path,
          objects: [path],
          description: 'У объекта "Путь" не заполнен параметр "Номер пути"',
        });
      }
      if (!path.platforms.length) {
        this._errors.add({
          id: uuid(),
          objectType: EditableObjectType.Path,
          objects: [path],
          description: 'У объекта "Путь" не выбрана ни одна платформа',
        });
      }
    });
  }
  _examIsPlatformHaveEmptyFields() {
    this._platforms.forEach(platform => {
      if (!platform.platform) {
        this._errors.add({
          id: uuid(),
          objectType: EditableObjectType.Platform,
          objects: [platform],
          description:
            'У объекта "Платформа" не заполнен параметр "Номер платформы"',
        });
      }
    });
  }
  _examIsPathsHaveSameWayField() {
    const listPathsByWay: Record<string, IPath[]> = {};
    this._paths.forEach(path => {
      const { way } = path;
      if (!way) return;
      const list = listPathsByWay[way];
      listPathsByWay[way] = list ? [...list, path] : [path];
    });

    Object.entries(listPathsByWay).forEach(([way, list]) => {
      if (list.length > 1) {
        this._errors.add({
          id: uuid(),
          objectType: EditableObjectType.Path,
          objects: list,
          description: `Сразу в нескольких объектах выбран ${way} номер пути.`,
        });
      }
    });
  }
  getErrors() {
    this._examIsPathOrPlatformsEmpty();
    this._examIsPlatformHaveEmptyFields();
    this._examIsPathHaveEmptyFields();
    this._examIsPathsHaveSameWayField();

    return Array.from(this._errors);
  }
}
