import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const DiagramCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 19C8.14015 19 5 15.8598 5 12C5 8.14015 8.14015 5 12 5C15.8598 5 19 8.14015 19 12C19 15.8598 15.8598 19 12 19ZM12 6C8.6914 6 6 8.69165 6 12C6 15.3083 8.6914 18 12 18C15.3086 18 18 15.3083 18 12C18 8.69165 15.3086 6 12 6Z"
          fill="#273B48"
        />
        <path
          d="M18.5002 12.5H12.0002C11.8171 12.5 11.6486 12.4002 11.5612 12.2395L8.56121 6.73951C8.42886 6.49706 8.51821 6.19336 8.76091 6.06106C9.00211 5.92851 9.30681 6.01811 9.43911 6.26051L12.2971 11.5H18.5002C18.7765 11.5 19.0002 11.7239 19.0002 12C19.0002 12.2761 18.7765 12.5 18.5002 12.5Z"
          fill="#273B48"
        />
        <path
          d="M16.9996 16.5C16.8902 16.5 16.7798 16.4641 16.6876 16.3904L11.6876 12.3904C11.4722 12.2181 11.4371 11.9034 11.6094 11.6878C11.7828 11.4727 12.0967 11.437 12.3126 11.6096L17.3126 15.6096C17.5279 15.782 17.5631 16.0967 17.3907 16.3123C17.2916 16.4358 17.1466 16.5 16.9996 16.5Z"
          fill="#273B48"
        />
      </svg>
    </SvgIcon>
  );
};
