import { Route, Routes } from 'react-router-dom';

import { ContainerPassengerTraffic } from '@features/passenger-traffic';
import PassengerTrafficConstructor from '@features/pt-constructor';
import { PassengerTrafficForecastContainer } from '@features/pt-forecast-new/container';

export const PagePassengerTraffic = () => {
  return (
    <Routes>
      <Route
        path={'/forecast'}
        Component={PassengerTrafficForecastContainer}
      />

      <Route
        path={'/constructor'}
        Component={PassengerTrafficConstructor}
      />

      <Route
        index
        Component={ContainerPassengerTraffic}
      />
    </Routes>
  );
};
