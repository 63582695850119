import {
  IHubShareOfEntranceExitDirectionChoices,
  Scalars,
} from '@api/gql/tpu-types';
import { useUnit } from 'effector-react';
import { useForm } from 'react-hook-form';

import { Divider, Stack } from '@mui/material';

import { FormActions } from '@features/tpu-simulation-model/components/FormAttributes/FormActions';
import { Input } from '@features/tpu-simulation-model/components/FormAttributes/Input';
import { ObjectPosition } from '@features/tpu-simulation-model/components/FormAttributes/ObjectPosition';
import { RadioList as Radio } from '@features/tpu-simulation-model/components/FormAttributes/Radio';
import { SelectWithInput } from '@features/tpu-simulation-model/components/FormAttributes/SelectWithInput';
import { TimeRange } from '@features/tpu-simulation-model/components/FormAttributes/TimeRange';
import { Title } from '@features/tpu-simulation-model/components/FormAttributes/Title';
import { useFloors } from '@features/tpu-simulation-model/hooks/useFloors';
import { $ListOfEntrances } from '@features/tpu-simulation-model/store';
import {
  IPassengerGenerator,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';
import { getAttributeOptions } from '@features/tpu-simulation-model/utils/getAttributeOptions';

interface FormProps extends IPassengerGenerator {
  onUpdateAndClose: (arg: ObjectOfTheSimulationModel) => void;
  onDeleteAndClose: (arg: string) => void;
}

type Entrance = {
  direction: IHubShareOfEntranceExitDirectionChoices;
  eid: Scalars['String']['output'];
  name: Scalars['String']['output'];
  share: Scalars['Float']['output'];
};

export const PassengerGenerator = ({
  onUpdateAndClose,
  onDeleteAndClose,
  ...props
}: FormProps) => {
  const { handleSubmit, formState, control, setValue } =
    useForm<IPassengerGenerator>({
      values: { ...props, share: props.share * 100 },
    });
  const entrances = useUnit($ListOfEntrances);
  const { selectedFloorIndex } = useFloors();

  const entranceOptions =
    entrances
      ?.filter((item): item is Entrance => !!item.eid)
      ?.map(item => ({
        value: item.eid,
        label: item.name,
      })) ?? [];

  const handleEntranceChange = (value?: string) => {
    if (value) {
      const selectedEntrance = entrances?.find(({ eid }) => eid === value);
      selectedEntrance && setValue('share', selectedEntrance.share * 100);
    }
  };

  return (
    <Stack
      padding={3}
      gap={3}
      sx={{
        overflow: 'auto',
      }}
    >
      <Title title={'Генератор пассажиров (вход)'} />
      <SelectWithInput<IPassengerGenerator>
        control={control}
        name={'entranceId'}
        title={'ID входа'}
        placeholder={'Выберите id входа'}
        options={entranceOptions}
        value={
          entranceOptions.find(({ value }) => value === props.entranceId) ?? {
            value: props.entranceId,
            label: props.entranceId,
          }
        }
        onChange={handleEntranceChange}
      />
      <Input<IPassengerGenerator>
        control={control}
        name={'share'}
        title={'Доля пассажиров, %'}
        type={'number'}
      />
      <Radio<IPassengerGenerator>
        control={control}
        name={'status'}
        title={'Статус'}
        options={getAttributeOptions('status')}
      />
      <TimeRange<IPassengerGenerator>
        control={control}
        nameTime1={'openFrom'}
        nameTime2={'openUntil'}
      />
      <Divider />
      <ObjectPosition
        point={props.point}
        floor={selectedFloorIndex + 1}
      />
      <Divider />
      <FormActions
        onSave={handleSubmit(data => {
          const newFormData = { ...data };
          newFormData.share = newFormData.share ? newFormData.share / 100 : 0;
          newFormData.name =
            entranceOptions.find(
              ({ value }) => value === newFormData.entranceId,
            )?.label ?? '';

          onUpdateAndClose(newFormData);
        })}
        onDelete={() => onDeleteAndClose(props?.uuid)}
        disabled={!formState.isValid}
      />
    </Stack>
  );
};
