import {
  IQuery,
  IQueryGetTransportDistrictByPointArgs,
} from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

import { EMapFeatureLayout } from '@constants/map';

import { initLayersVisible } from '@features/ag-forecast/constants/inits';
import { RegionOptionItem } from '@features/ag-forecast/stores/lists/regionsOptions';
import { AgLayersStore } from '@features/ag-forecast/stores/map';

import { pointDirection } from '@utils/map/styles/agTransportFlowDistrictsVectorStyle';

export enum directionPoints {
  from,
  to,
}

const initState: FilterMapState = {
  actual: initLayersVisible,
  cache: null,
  fromSelectedRegions: [],
  toSelectedRegions: [],
  selectedTimeSlot: '',
  directionPointSelected: null,
};

export const $FilterMap = createStore<FilterMapState>(initState);

export const FilterMapApi = createApi($FilterMap, {
  setFilters: (store, payload: SetFiltersPayload) => ({
    ...store,
    actual: {
      ...store.actual,
      ...payload,
    },
  }),
  setOnOneVehicleInFilter: (store, key: keyof AgLayersStore) => ({
    ...store,
    actual: {
      ...(Object.fromEntries(
        Object.keys(initLayersVisible).map(key => [key, false]),
      ) as typeof initLayersVisible),
      [EMapFeatureLayout.transportDistricts]:
        store.actual[EMapFeatureLayout.transportDistricts],
      [EMapFeatureLayout.transportFlowDistricts]: true,
      [key]: true,
    },
  }),
  setTimeSlot: (store, payload: string) => ({
    ...store,
    selectedTimeSlot: payload,
  }),
  hideAllToCache: store => ({
    ...store,
    cache: store.actual,
    actual: {
      ...(Object.fromEntries(
        Object.keys(store.actual).map(key => [key, false]),
      ) as FilterMapState['actual']),
      [EMapFeatureLayout.transportFlowDistricts]: true,
    },
  }),
  restoreAllFromCache: store => ({
    ...store,
    actual: {
      ...(store.cache || initLayersVisible),
      [EMapFeatureLayout.transportFlowDistricts]: false,
    },
    cache: null,
  }),
  addRegionTo: (store, regions: RegionOptionItem[]) => ({
    ...store,
    toSelectedRegions: [
      ...store.toSelectedRegions,
      ...regions.filter(
        addRegion =>
          !store.toSelectedRegions.some(
            storedRegion => storedRegion.value === addRegion.value,
          ),
      ),
    ],
  }),
  addRegionFrom: (store, regions: RegionOptionItem[]) => ({
    ...store,
    fromSelectedRegions: [
      ...store.fromSelectedRegions,
      ...regions.filter(
        addRegion =>
          !store.fromSelectedRegions.some(
            storedRegion => storedRegion.value === addRegion.value,
          ),
      ),
    ],
  }),
  removeRegionTo: (store, region: RegionOptionItem) => ({
    ...store,
    toSelectedRegions: store.toSelectedRegions.filter(
      item => item.value !== region.value,
    ),
  }),
  removeRegionFrom: (store, region: RegionOptionItem) => ({
    ...store,
    fromSelectedRegions: store.fromSelectedRegions.filter(
      item => item.value !== region.value,
    ),
  }),
  clearRegions: store => ({
    ...store,
    fromSelectedRegions: [],
    toSelectedRegions: [],
  }),
  resetFilters: store => ({
    ...initState,
    selectedTimeSlot: store.selectedTimeSlot,
    actual: {
      ...initState.actual,
      [EMapFeatureLayout.transportFlowDistricts]:
        store.actual.transportFlowDistricts,
    },
  }),
  setDirectionPoint: (store, payload: directionPoints | null) => ({
    ...store,
    directionPointSelected: payload,
  }),
  reset: () => initState,
});

export const fetchTransportDistrictXYFx = createEffect<
  IQueryGetTransportDistrictByPointArgs & { direction: pointDirection | null },
  IQuery['getTransportDistrictByPoint']
>();

export type SetFiltersPayload = Partial<FilterMapState['actual']>;
export type FilterMapState = {
  fromSelectedRegions: RegionOptionItem[];
  toSelectedRegions: RegionOptionItem[];
  selectedTimeSlot: string;
  directionPointSelected: directionPoints | null;
  actual: Record<keyof AgLayersStore, boolean> & {
    transportDistricts: boolean;
    isActual: boolean;
    isPlanned: boolean;
  };
  cache:
    | (Record<keyof AgLayersStore, boolean> & {
        transportDistricts: boolean;
        isActual: boolean;
        isPlanned: boolean;
      })
    | null;
};
