import { Chip, Grid, Typography } from '@mui/material';

export const Pending = () => (
  <Grid
    display={'flex'}
    gap={1.5}
  >
    <Chip
      label="Подготовка..."
      color="default"
    />

    <Typography variant="body2">
      Выполняется проверка данных и постановка расчета в очередь
    </Typography>
  </Grid>
);
