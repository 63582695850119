import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import { FieldNameItem } from './FieldNameItem';

import {
  Chip,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  iconButtonClasses,
} from '@mui/material';

import { BasketIcon, CopyIcon, EditIcon, ShareIcon } from '@components/icons';

import { EAgCalculationScenarioStatusType } from '@features/ag-constructor/constants/agCalculationScenarioStatuses';
import {
  $CurrentScenario,
  CurrentScenarioApi,
} from '@features/ag-constructor/stores/currentScenario';
import { ComputedScenario } from '@features/ag-constructor/stores/lists/scenarios/store';
import { $CalculationStatuses } from '@features/ag-constructor/stores/lists/statuses';
import { ModalsAgConstructorApi } from '@features/ag-constructor/stores/modals';

type RegistryUserItemProps = {
  item?: ComputedScenario;
  readonly: boolean;
  disabled?: boolean;
  onClick: () => void;
  onCopy: () => void;
  onEdit: () => void;
};

export const RegistryUserItem = (props: RegistryUserItemProps) => {
  const { item, readonly, onClick, onCopy, onEdit } = props;

  const $currentScenario = useUnit($CurrentScenario);
  const { setName } = useUnit(CurrentScenarioApi);
  const { statuses } = useUnit($CalculationStatuses);

  const calculationStatus = useMemo(
    () => statuses.find(statusItem => statusItem.id === item?.id)?.status,
    [item, statuses],
  );

  const disabledCopy = useMemo(
    () =>
      !(
        calculationStatus === EAgCalculationScenarioStatusType.Success ||
        calculationStatus === EAgCalculationScenarioStatusType.Draft
      ),
    [calculationStatus],
  );

  const disabledEdit = useMemo(
    () =>
      !(
        calculationStatus === EAgCalculationScenarioStatusType.Success ||
        calculationStatus === EAgCalculationScenarioStatusType.Draft ||
        calculationStatus === EAgCalculationScenarioStatusType.Failure
      ),
    [calculationStatus],
  );

  const disabledShare = useMemo(
    () =>
      !(
        calculationStatus === EAgCalculationScenarioStatusType.Success ||
        calculationStatus === EAgCalculationScenarioStatusType.Draft
      ),
    [calculationStatus],
  );

  const disabledRemove = useMemo(
    () =>
      !(
        calculationStatus === EAgCalculationScenarioStatusType.Success ||
        calculationStatus === EAgCalculationScenarioStatusType.Draft ||
        calculationStatus === EAgCalculationScenarioStatusType.Failure
      ),
    [calculationStatus],
  );

  // Копирование сценария
  const handleCopyScenario = useCallback(
    (disabled: boolean) => {
      if (disabled) return;

      onCopy();
    },
    [onCopy],
  );

  // Редактирование сценария
  const handleEditScenario = useCallback(
    (disabled: boolean) => {
      if (disabled) return;

      onEdit();
    },
    [onEdit],
  );

  // Отобразить модалку - поделиться расчетом
  const handleShareScenario = useCallback((disabled: boolean) => {
    if (disabled) return;

    ModalsAgConstructorApi.setModalView({ shareCalculation: true });
  }, []);

  // Отобразить модалку - удалить расчет
  const handleRemoveScenario = useCallback((disabled: boolean) => {
    if (disabled) return;

    ModalsAgConstructorApi.setModalView({ deleteCalculation: true });
  }, []);

  return (
    <ListItem
      key={`user-scenario-${item?.id || 'new'}`}
      className={$currentScenario?.id === item?.id ? 'selected' : ''}
      onClick={onClick}
      sx={{
        pl: 4,
        py: 0.5,
        '&:hover, &.selected': {
          bgcolor: 'customs.bgHover',
          borderRadius: '4px',
        },
      }}
      secondaryAction={
        <Stack
          direction="row"
          gap={2}
          alignItems={'center'}
        >
          {calculationStatus && (
            <>
              {calculationStatus ===
                EAgCalculationScenarioStatusType.Success && (
                <Chip
                  color="success"
                  label={'Завершено'}
                  style={{ width: '96px' }}
                />
              )}
              {calculationStatus ===
                EAgCalculationScenarioStatusType.Pending && (
                <Chip
                  color="info"
                  label={'Расчет...'}
                  style={{ width: '96px' }}
                />
              )}
              {calculationStatus === EAgCalculationScenarioStatusType.Draft && (
                <Chip
                  color="default"
                  label={'Черновик'}
                  style={{ width: '96px' }}
                />
              )}
              {calculationStatus ===
                EAgCalculationScenarioStatusType.Failure && (
                <Chip
                  color="error"
                  label={'Ошибка'}
                  style={{ width: '96px' }}
                />
              )}
              {calculationStatus === EAgCalculationScenarioStatusType.Queue && (
                <Chip
                  color="default"
                  label={'В очереди'}
                  style={{ width: '96px' }}
                />
              )}
            </>
          )}
          <Stack direction="row">
            <Tooltip
              title={'Копировать'}
              placement="top"
            >
              <IconButton
                size={'small'}
                className={disabledCopy ? iconButtonClasses.disabled : ''}
                onClick={() => handleCopyScenario(disabledCopy)}
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={'Редактировать'}
              placement="top"
            >
              <IconButton
                size={'small'}
                className={disabledEdit ? iconButtonClasses.disabled : ''}
                onClick={() => handleEditScenario(disabledEdit)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={'Поделиться'}
              placement="top"
            >
              <IconButton
                size={'small'}
                className={disabledShare ? iconButtonClasses.disabled : ''}
                onClick={() => handleShareScenario(disabledShare)}
              >
                <ShareIcon />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={'Удалить'}
              placement="top"
            >
              <IconButton
                size={'small'}
                className={disabledRemove ? iconButtonClasses.disabled : ''}
                onClick={() => handleRemoveScenario(disabledRemove)}
              >
                <BasketIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      }
    >
      {item && $currentScenario?.id === item.id && !readonly ? (
        <FieldNameItem
          value={$currentScenario?.name || ''}
          onChange={setName}
          sx={{
            pl: 0,
          }}
        />
      ) : (
        <ListItemText
          primaryTypographyProps={{
            variant: 'body2',
            sx: {
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: 'calc(100% - 100px)',
            },
          }}
          primary={
            <Tooltip
              title={item?.name}
              placement="top-start"
            >
              <span>{item?.name}</span>
            </Tooltip>
          }
        />
      )}
    </ListItem>
  );
};
