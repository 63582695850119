export enum VehicleTypeCode {
  multimodal = 'Мультимодальные поездки',
  avia = 'Самолет',
  suburbanTrain = 'Пригородный поезд',
  expressTrain = 'Высокоскоростной поезд',
  bus = 'Автобус',
  auto = 'Личный транспорт',
  dayTrain = 'Дневной поезд дальнего следования',
  nightTrain = 'Ночной поезд дальнего следования',
}

export const vehicleTypeCodeAvailableCodes: VehicleTypeCode[] = [
  VehicleTypeCode.dayTrain,
  VehicleTypeCode.nightTrain,
  VehicleTypeCode.suburbanTrain,
  VehicleTypeCode.expressTrain,
  VehicleTypeCode.avia,
  VehicleTypeCode.bus,
  VehicleTypeCode.auto,
  VehicleTypeCode.multimodal,
];
