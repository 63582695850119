import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { Box, MenuItem, Select, Typography } from '@mui/material';

import { Option } from '@features/tpu-simulation-model/utils/getAttributeOptions';

interface AttributeProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  placeholder?: string;
  options: Option[];
  title?: string;
  disabled?: boolean;
  onChange?: (arg: string) => void;
}

export function SelectOption<T extends FieldValues>({
  control,
  name,
  title,
  placeholder,
  options,
  disabled,
  onChange,
}: AttributeProps<T>) {
  return (
    <Box>
      <Typography mb={1}>{title}</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            defaultValue=""
            value={field.value}
            onChange={e => {
              const value = e.target.value;
              field.onChange(value);
              onChange && onChange(value);
            }}
            ref={field.ref}
            onBlur={field.onBlur}
            displayEmpty={true}
            fullWidth
            disabled={disabled}
            sx={{
              backgroundColor: '#F6F6F6 !important',
            }}
          >
            {placeholder && (
              <MenuItem
                disabled
                value={''}
              >
                {placeholder}
              </MenuItem>
            )}
            {options.map(({ value, label }) => (
              <MenuItem
                key={value}
                value={value}
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </Box>
  );
}
