import { IAirportType, Maybe } from '@api/gql/types';
import { useMemo } from 'react';

import { Box, Divider, IconButton, Typography } from '@mui/material';

import { ChevronRightIcon } from '@components/icons';

import { BoxData } from '@features/passenger-traffic/components/Inspector/BoxData';

import { convertUnitOfMeasure } from '@utils/dataForInspector';

interface AirportInspectorProps {
  onClose?(): void;
  fields: Maybe<IAirportType>;
}

export const AirportInspector = (props: AirportInspectorProps) => {
  const { onClose, fields } = props;

  const passTraffic = useMemo(() => {
    const value = fields!.details![0]?.passTraffic;

    if (!value) return 'н/д';

    return (
      convertUnitOfMeasure({
        value,
        unit: 'чел.',
        singleDigitAccuracy: 0,
      }) || 'н/д'
    );
  }, [fields]);

  const capacityCommon = useMemo(() => {
    const value = fields!.details![0]?.capacityCommon;

    if (!value) return 'н/д';

    return (
      convertUnitOfMeasure({
        value,
        unit: 'чел./час',
        singleDigitAccuracy: 0,
      }) || 'н/д'
    );
  }, [fields]);

  const range = useMemo(() => {
    const value = fields!.details![0]?.range;

    if (!value) return 'н/д';

    return (
      convertUnitOfMeasure({
        value,
        unit: 'км.',
        singleDigitAccuracy: 0,
      }) || 'н/д'
    );
  }, [fields]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 3,
        }}
      >
        <IconButton
          sx={{
            width: '32px',
            height: '32px',
            backgroundColor: 'primary.main',
            borderRadius: 1,
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={onClose}
        >
          <ChevronRightIcon />
        </IconButton>

        <Typography variant="subtitle2">Аэропорт</Typography>
      </Box>

      <BoxData
        name={'Название'}
        value={fields?.name || 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Пассажирооборот на базовый год'}
        value={passTraffic}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Пропускная способность'}
        value={capacityCommon}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Максимальная дальность рейсов'}
        value={range}
      />
    </>
  );
};
