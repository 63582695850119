export const SOC_ECO_TH_3_7 = [
  {
    id: 'transportArea',
    label: 'Транспортный район',
  },
  // {
  //   id: 'area',
  //   label: 'Площадь нарушенных территорий, кв. м.',
  // },
  // {
  //   id: 'areaTPU',
  //   label: 'Площадь ТПУ, кв. м.',
  // },
  // {
  //   id: 'areaTerritoriesSubjectTransportLine',
  //   label: 'Площадь земель, отведённых под транспортную линию, кв. м.',
  // },
  // {
  //   id: 'areaTerritoriesSubjectReclamation',
  //   label: 'Площадь территорий, подлежащей рекультивации, кв. м.',
  // },
  {
    id: 'areaTerritoriesSubjectRestoration',
    label: 'Площадь территорий, подлежащей восстановлению, кв. м.',
  },
];
