import { useUnit } from 'effector-react';
import { useEffect, useMemo } from 'react';

import { Alert, Box } from '@mui/material';

import { EAgScenarioStatusType } from '@features/ag-forecast/constants/agScenarioStatuses';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';
import { $UI, UIApi } from '@features/ag-forecast/stores/ui';

export const RecalculationNotification = () => {
  const { scenarioStatus } = useUnit($FeatureSettings);
  const { filter, inspector, isNotificationStatus } = useUnit($UI);
  const { setVisible } = useUnit(UIApi);

  const offset = useMemo(() => {
    if (filter && inspector) return 0;
    else if (filter) return 290;
    else if (inspector) return -290;
    else return 0;
  }, [filter, inspector]);

  const title = useMemo(() => {
    switch (scenarioStatus) {
      case EAgScenarioStatusType.Queue:
        return 'Сценарий в очереди на расчет';
      case EAgScenarioStatusType.Pending:
        return 'Сценарий рассчитывается';
      case EAgScenarioStatusType.Success:
        return 'Изменения сохранены';
      case EAgScenarioStatusType.Failure:
        return 'Ошибка сохранения';
    }
  }, [scenarioStatus]);

  const color = useMemo(() => {
    switch (scenarioStatus) {
      case EAgScenarioStatusType.Queue:
      case EAgScenarioStatusType.Pending:
        return 'info';
      case EAgScenarioStatusType.Success:
        return 'success';
      case EAgScenarioStatusType.Failure:
        return 'error';
    }
  }, [scenarioStatus]);

  useEffect(() => {
    if (
      isNotificationStatus &&
      (scenarioStatus === EAgScenarioStatusType.Success ||
        scenarioStatus === EAgScenarioStatusType.Failure)
    )
      setTimeout(() => setVisible({ isNotificationStatus: false }), 10000);
  }, [isNotificationStatus, scenarioStatus, setVisible]);

  return (
    isNotificationStatus && (
      <Box
        sx={{
          position: 'absolute',
          top: theme => theme.spacing(10),
          left: 'calc(50% - 200px)',
          zIndex: 10,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Alert
          variant="filled"
          severity={color}
          icon={false}
          sx={{
            height: 'min-content',
            width: '400px',
            justifyContent: 'center',
            transform: `translateX(${offset}px)`,
          }}
        >
          {title}
        </Alert>
      </Box>
    )
  );
};
