import Konva from 'konva';
import { useEffect, useRef } from 'react';
import { Group, Line, Rect, Transformer } from 'react-konva';

import {
  IPlatform,
  IShape,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

export interface PlatformProps extends IPlatform {
  isSelected: boolean;
  onSelect: (arg: ObjectOfTheSimulationModel) => void;
  updateObjectData: (arg: ObjectOfTheSimulationModel) => void;
}

export const Platform = ({
  isSelected,
  onSelect,
  updateObjectData,
  ...props
}: PlatformProps) => {
  const shapeRef = useRef<Konva.Group | null>(null);
  const trRef = useRef<Konva.Transformer | null>(null);

  const { point, width, height, rotation } = props;

  const onChangeShape = ({ x, y, width, height, rotation }: IShape) => {
    updateObjectData({ ...props, point: { x, y }, width, height, rotation });
  };

  useEffect(() => {
    if (isSelected && trRef.current && shapeRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer()?.batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Group
        x={point.x}
        y={point.y}
        ref={shapeRef}
        width={width}
        height={height}
        rotation={rotation}
        draggable
        onClick={e => {
          e.cancelBubble = true;
          onSelect(props);
        }}
        onDragEnd={e => {
          onChangeShape({
            width,
            height,
            rotation,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={() => {
          const node = shapeRef.current;
          if (!node) return;

          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          onChangeShape({
            x: Math.round(node.x()),
            y: Math.round(node.y()),
            width: Math.max(24, Math.round(node.width() * scaleX)),
            height: Math.max(24, Math.round(node.height() * scaleY)),
            rotation: Math.round(node.rotation()),
          });
        }}
      >
        <Rect
          width={width}
          height={height}
          fill={'#A3D7EB'}
        />
        <Line
          strokeWidth={4}
          stroke="#3679EA"
          closed={true}
          dash={[10, 10]}
          lineJoin={'round'}
          points={[0, 0, width, 0, width, height, 0, height]}
        />
      </Group>
      {isSelected && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          rotationSnaps={[0, 90, 180, 270]}
          boundBoxFunc={(oldBox, newBox) => {
            if (Math.abs(newBox.width) < 12 || Math.abs(newBox.height) < 12) {
              return oldBox;
            }

            return newBox;
          }}
        />
      )}
    </>
  );
};
