import dayjs from 'dayjs';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { Box, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { addZeroAtStart } from '@features/tpu-simulation-model/utils/addZeroAtStart';

interface AttributeProps<T extends FieldValues> {
  control: Control<T>;
  nameTime1: Path<T>;
  nameTime2: Path<T>;
}

interface TimePickerProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

const TimePicker = <T extends FieldValues>({
  name,
  control,
}: TimePickerProps<T>) => {
  return (
    <Controller
      key={name}
      control={control}
      name={name}
      render={({ field }) => (
        <MuiTimePicker
          ampm={false}
          disableOpenPicker={true}
          value={dayjs(field.value, 'HH:mm:ss')}
          onChange={e => {
            const time =
              addZeroAtStart(dayjs(e).hour()) +
              ':' +
              addZeroAtStart(dayjs(e).minute()) +
              ':00';
            field.onChange(time);
          }}
          format="HH:mm"
          sx={{
            width: '200px',
            '.MuiInputBase-root': {
              backgroundColor: theme =>
                `${theme.palette.background.default} !important`,
            },
          }}
        />
      )}
    />
  );
};

export function TimeRange<T extends FieldValues>({
  control,
  nameTime1,
  nameTime2,
}: AttributeProps<T>) {
  return (
    <Box>
      <Typography mb={1}>Время работы</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            control={control}
            name={nameTime1}
          />
          –
          <TimePicker
            control={control}
            name={nameTime2}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
}
