export type StringScenarioType = 'A_1' | 'A_2' | 'A_3' | 'A_4';

// типы сценариев соц-эко
export enum ScenarioType {
  Basic = 1,
  Pessimistic = 2,
  Optimistic = 3,
  Custom = 4,
}

export const getScenarioType = (type: StringScenarioType) => {
  switch (type) {
    case 'A_1':
      return 1;
    case 'A_2':
      return 2;
    case 'A_3':
      return 3;
    case 'A_4':
      return 4;
  }
};
