import { IAgglomerationType, Maybe } from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

const defaultStore: ListAgglomerationsStore = {
  agglomerations: [],
};

export const $ListAgglomerations =
  createStore<ListAgglomerationsStore>(defaultStore);

export const ListAgglomerationsApi = createApi($ListAgglomerations, {
  setAgglomerations: (
    store,
    payload: ListAgglomerationsStore['agglomerations'],
  ) => ({
    ...store,
    agglomerations: payload,
  }),
  reset: () => defaultStore,
});

export const FetchAgglomerationsFx = createEffect<
  void,
  Maybe<IAgglomerationType>[]
>();

export type AgglomerationOption = {
  id: string;
  name: string;
};

export type ListAgglomerationsStore = {
  agglomerations: AgglomerationOption[];
};
