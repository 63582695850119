import styled from '@emotion/styled';
import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import {
  $DirectionsParamsTable,
  DirectionsParamsTableApi,
  requestUpdateParamsDirection,
} from '@widgets/widget-routes-params/stores/list/directionsParamsTable/store';
import { $UI, UIApi } from '@widgets/widget-routes-params/stores/ui';
import { checkErrorsInParams } from '@widgets/widget-routes-params/utils/checkErrorsInParams';

export const ToolBar = ({ closeRoutesParams }: ToolBarType) => {
  const { directions } = useUnit($DirectionsParamsTable);
  const { updateAllParamsDirections } = useUnit(DirectionsParamsTableApi);
  const { setVisible } = useUnit(UIApi);
  const { isAlertInputError, isUpdateParamsDirection } = useUnit($UI);

  const handleSaveParams = useCallback(() => {
    const { newDirection, isError } = checkErrorsInParams(directions);

    updateAllParamsDirections(newDirection);

    if (!isError) {
      requestUpdateParamsDirection();
      return;
    }

    !isAlertInputError && setVisible({ isAlertInputError: true });
  }, [directions, isAlertInputError, setVisible, updateAllParamsDirections]);

  return (
    <Wrapper>
      <Stack
        direction="row"
        alignItems={'center'}
        gap={3}
      >
        <Button
          variant="text"
          onClick={closeRoutesParams}
          sx={{
            [`& .MuiButton-startIcon > *:nth-of-type(1)`]: {
              fontSize: '12px',
            },
          }}
          startIcon={<ArrowBackIcon />}
          color={'info'}
        >
          Назад
        </Button>

        <Divider
          sx={{ borderColor: '#EBECEF', mr: 1.5, my: 2 }}
          orientation={'vertical'}
          flexItem
        />

        <Typography fontWeight={'bolder'}>Параметры сообщения</Typography>
      </Stack>

      <Stack
        direction="row"
        sx={{
          columnGap: 4,
          alignItems: 'center',
          ml: 'auto',
        }}
      >
        <Button
          color="secondary"
          onClick={handleSaveParams}
          sx={{
            width: theme => theme.spacing(25),
          }}
        >
          {isUpdateParamsDirection ? (
            <CircularProgress
              sx={{
                color: theme => theme.palette.common.white,
                p: theme => theme.spacing(1),
              }}
            />
          ) : (
            'Сохранить'
          )}
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={closeRoutesParams}
          sx={{
            width: theme => theme.spacing(25),
          }}
        >
          Отменить
        </Button>
      </Stack>
    </Wrapper>
  );
};

type ToolBarType = {
  closeRoutesParams: () => void;
};

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 5.5),
  gap: theme.spacing(3),
  height: theme.typography.pxToRem(80),
  backgroundColor: theme.palette.background.paper,
  flexDirection: 'row',
  justifyContent: 'space-between',
  display: 'flex',
}));
