export const SOC_ECO_TH_5_1 = [
  {
    id: 'nameArea',
    label: 'Наименование участка',
  },
  {
    id: 'numberPassengersCarried',
    label: 'Число перевезённых пассажиров, тыс. чел. / сутки',
  },
  {
    id: 'carryingCapacity',
    label: 'Провозная способность, тыс. чел. / сутки',
  },
  {
    id: 'networkCongestion',
    label: 'Загруженность участков сети, %',
  },
];
