import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { FormLabel, TextField } from '@mui/material';

export type YearCashedFieldProps<T = string | number | null | undefined> = {
  id: string;
  title: string;
  placeholder?: string;
  value: T;
  onBlur?: (value: T) => void;
  disabled?: boolean;
  minYear: number;
  maxYear: number;
};

export const YearCashedField = (props: YearCashedFieldProps) => {
  const { id, title, placeholder, value, onBlur, disabled, maxYear, minYear } =
    props;
  const [cashedValue, setCashedValue] = useState(value);
  const refCashedValue = useRef<number>(minYear);

  const changeCashedValueHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      refCashedValue.current = Number(event.target.value);
      setCashedValue(refCashedValue.current);
    },
    [],
  );

  const onBlurHandler = useCallback(() => {
    const numericValue = Number(refCashedValue.current);

    // Проверяем, является ли ввод числом, и находится ли он в указанных пределах
    if (
      !isNaN(numericValue) &&
      numericValue >= minYear &&
      numericValue <= maxYear
    ) {
      // Обновляем значение только если оно в допустимом диапазоне
      setCashedValue(numericValue);
      onBlur && onBlur(numericValue);
    } else {
      setCashedValue(minYear);
      onBlur && onBlur(minYear);
    }
  }, [maxYear, minYear, onBlur]);

  useEffect(() => {
    setCashedValue(value);
  }, [value]);

  return (
    <FormLabel id={id}>
      {title}

      <TextField
        id={id}
        placeholder={placeholder}
        value={cashedValue}
        onChange={changeCashedValueHandler}
        onBlur={onBlurHandler}
        disabled={disabled}
        fullWidth
        sx={{ mt: 1 }}
      />
    </FormLabel>
  );
};
