import { apiClient } from '@api/client';
import {
  IHubScenarioSimulationStateChoices,
  IMutation,
  IQuery,
} from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import {
  $Player,
  $SimulationData,
  createSimulationReportFx,
  getSimulationDataBySimulationIdFx,
  getSimulationIdByScenarioIdFx,
  playTickFn,
  resetScenarioPlayerFn,
  setCurrentTimeOfSimulationFn,
  setLastCalculatedSegmentFn,
  setPlayDisabledFn,
  setPlayFn,
  setPlaySpeedFn,
  setSimulationIdFn,
  setSpeedDividerFn,
  setUploadedTimeOfSimulationFn,
  setWithAnimationFn,
  startSimulationByScenarioIdFx,
  uploadTickFn,
} from './scenarioPlayer.store';

import { TPU_CLIENT_NAME } from '@constants/api';

import { UPLOAD_LIMIT } from '@features/tpu-calculation-scenarios-viewing/constants/player';
import { setIsReportExistFn } from '@features/tpu-calculation-scenarios-viewing/stores/report/report.store';
import {
  $ScenarioData,
  getScenarioIdFn,
} from '@features/tpu-calculation-scenarios-viewing/stores/scenarioId/scenarioId.store';
import { formatSeconds } from '@features/tpu-calculation-scenarios-viewing/utils/formatSeconds';

import { $ListOfRulers } from '../ruler/ruler.store';
import {
  EPlayerErrorType,
  addErrorFn,
} from '../scenarioErrors/scenarioErrors.store';

$Player
  .reset(resetScenarioPlayerFn)
  .on(setPlayFn, (state, payload) => ({
    ...state,
    play: payload,
  }))
  .on(setPlaySpeedFn, (state, payload) => ({
    ...state,
    playSpeed: payload,
  }))
  .on(setPlayDisabledFn, (state, payload) => ({
    ...state,
    playDisabled: payload,
  }))
  .on(setWithAnimationFn, (state, payload) => ({
    ...state,
    withAnimation: payload,
  }))
  .on(setSpeedDividerFn, (state, payload) => ({
    ...state,
    speedDivider: payload,
  }))
  .on(setCurrentTimeOfSimulationFn, (state, payload) => ({
    ...state,
    currentTimeOfSimulation: payload,
  }))
  .on(setLastCalculatedSegmentFn, (state, payload) => ({
    ...state,
    lastCalculatedSegment: payload,
  }))
  .on(setUploadedTimeOfSimulationFn, (state, payload) => ({
    ...state,
    uploadedTimeOfSimulation: payload,
  }))
  .on(getSimulationDataBySimulationIdFx.pending, (state, payload) => {
    return { ...state, isLoading: payload };
  });

$SimulationData
  .reset(resetScenarioPlayerFn)
  .on(setSimulationIdFn, (state, result) => {
    if (result) {
      return { ...state, simulationId: result };
    }
  })
  .on(getSimulationDataBySimulationIdFx.done, (state, { result }) => {
    if (result) {
      return {
        ...state,
        hasReport: result.hasReport,
        calculatedTicks: result.calculatedTicks ?? 0,
        state: result.state,
        ticks: result.ticks,
      };
    }
  })
  .on(startSimulationByScenarioIdFx.done, (state, { result }) => {
    if (result) {
      return { ...state, simulationId: result.simulationId };
    }
  })
  .on(createSimulationReportFx.doneData, (state, payload) => {
    return { ...state, hasReport: payload };
  });

sample({
  clock: $Player.map(({ speedDivider }) => speedDivider),
  fn: clockData => 1000 / clockData,
  target: setPlaySpeedFn,
});

sample({
  clock: $Player.map(
    ({ uploadedTimeOfSimulation }) => uploadedTimeOfSimulation,
  ),
  fn: clockData => clockData <= 300,
  target: setPlayDisabledFn,
});

sample({
  clock: $Player.map(({ currentTimeOfSimulation }) => currentTimeOfSimulation),
  fn: clockData => Math.trunc(clockData / 3600),
  target: setLastCalculatedSegmentFn,
});

sample({
  clock: playTickFn,
  source: $Player,
  filter: sourceData =>
    sourceData.currentTimeOfSimulation < sourceData.uploadedTimeOfSimulation &&
    sourceData.play,
  fn: sourceData => sourceData.currentTimeOfSimulation + 1,
  target: setCurrentTimeOfSimulationFn,
});

sample({
  clock: playTickFn,
  source: $Player,
  filter: ({ currentTimeOfSimulation, endOfSimulation }) =>
    currentTimeOfSimulation >= endOfSimulation,
  fn: () => false,
  target: setPlayFn,
});

sample({
  clock: uploadTickFn,
  source: {
    simulationData: $SimulationData,
    player: $Player,
  },
  filter: ({ simulationData, player }) => {
    const isSimulationProcessing =
      simulationData.state === IHubScenarioSimulationStateChoices.Processing;

    if (player.isLoading || !simulationData.simulationId) return false;

    return (
      !simulationData.state ||
      isSimulationProcessing ||
      (!isSimulationProcessing &&
        player.uploadedTimeOfSimulation < simulationData.calculatedTicks)
    );
  },
  fn: ({ simulationData, player }) => {
    const expectedValue = player.uploadedTimeOfSimulation + UPLOAD_LIMIT;
    const limit =
      expectedValue <= simulationData.calculatedTicks
        ? UPLOAD_LIMIT
        : simulationData?.calculatedTicks - player.uploadedTimeOfSimulation;

    return {
      id: simulationData?.simulationId as string,
      offset: player.uploadedTimeOfSimulation,
      limit,
    };
  },
  target: getSimulationDataBySimulationIdFx,
});

sample({
  clock: getSimulationDataBySimulationIdFx.done,
  filter: clockData => !!clockData,
  fn: clockData => clockData.params.offset + clockData.params.limit,
  target: setUploadedTimeOfSimulationFn,
});

sample({
  clock: $SimulationData.map(
    ({ state }) => state === IHubScenarioSimulationStateChoices.Failed,
  ),
  source: $SimulationData,
  filter: (_, clockData) => !!clockData,
  fn: sourceData => {
    const calculatedTicks = formatSeconds(sourceData?.calculatedTicks);

    return {
      type: EPlayerErrorType.ScenarioCalculation,
      description: `При расчете модели после ${calculatedTicks} возникла ошибка. Выполнение далее невозможно. Просмотр результатов моделирования доступен до ${calculatedTicks}.`,
    };
  },
  target: addErrorFn,
});

sample({
  clock: $ListOfRulers,
  filter: clockData =>
    !clockData || Object.values(clockData).some(item => !item),
  fn: () => ({
    type: EPlayerErrorType.Ruler,
    description:
      'Не задан масштаб. Используйте инструмент "Линейка" для установки масштаба',
  }),
  target: addErrorFn,
});

sample({
  clock: getScenarioIdFn,
  source: $ScenarioData,
  filter: sourceData => !!sourceData.scenarioId,
  fn: sourceData => sourceData.scenarioId as string,
  target: getSimulationIdByScenarioIdFx,
});

sample({
  clock: getSimulationIdByScenarioIdFx.done,
  filter: clockData => !!clockData.result?.simulationId,
  fn: clockData => clockData.result?.simulationId as string,
  target: setSimulationIdFn,
});

sample({
  clock: getSimulationIdByScenarioIdFx.done,
  filter: clockData => !clockData.result?.simulationId,
  fn: clockData => clockData.params as string,
  target: startSimulationByScenarioIdFx,
});

sample({
  clock: getSimulationDataBySimulationIdFx.done,
  source: $SimulationData,
  filter: (_, { result }) =>
    result?.state === IHubScenarioSimulationStateChoices.Completed &&
    !result.hasReport,
  fn: (_, { params }) => params.id,
  target: createSimulationReportFx,
});

sample({
  clock: [
    createSimulationReportFx.doneData,
    getSimulationDataBySimulationIdFx.doneData,
  ],
  fn: clockData => {
    const hasReport =
      typeof clockData === 'boolean' ? clockData : clockData?.hasReport;

    return !!hasReport;
  },
  target: setIsReportExistFn,
});

getSimulationIdByScenarioIdFx.use(async id => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query ScenarioById($id: UUID!) {
        scenarioById(id: $id) {
          simulationId
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data?.scenarioById;
});

startSimulationByScenarioIdFx.use(async id => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation StartSimulation($id: UUID!) {
        startSimulation(inputData: { scenarioId: $id }) {
          ok
          errors {
            key
            message
          }
          scenario {
            simulationId
          }
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data?.startSimulation?.scenario;
});

getSimulationDataBySimulationIdFx.use(async ({ id, offset, limit }) => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query simulationById($id: UUID!, $offset: Int!, $limit: Int!) {
        simulationById(simulationId: $id, offset: $offset, limit: $limit) {
          calculatedTicks
          state
          hasReport
          errors
          movements {
            data
            index
          }
          objectResults {
            index
            barChartResults {
              kind
              value
            }
            pieChartResults {
              serviceObjectId
              kind
              value
            }
            treeMapResults {
              serviceObjectId
              revenue
              square
              agentsQuantity
              cumulativeRevenue
              cumulativeAgentsQuantity
            }
          }
        }
      }
    `,
    variables: {
      id,
      offset,
      limit,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data?.simulationById;
});

createSimulationReportFx.use(async id => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation CreateSimulationReportMutation($id: UUID!) {
        createSimulationReport(simulationId: $id) {
          ok
          errors {
            key
            message
          }
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return !!response.data?.createSimulationReport?.ok;
});
