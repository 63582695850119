import { useUnit } from 'effector-react';

import { Grid, Typography } from '@mui/material';

import { $SelectedCalculationScenario } from '@features/pt-constructor/store';

export const Question = () => {
  const { name } = useUnit($SelectedCalculationScenario);

  return (
    <Grid
      display={'flex'}
      direction={'column'}
      container
      rowGap={2}
    >
      <Typography variant="body2">
        Вы действительно хотите удалить расчет?
      </Typography>

      <Typography
        variant="body2"
        borderRadius={'4px'}
        bgcolor={'customs.bgHover'}
        ml={1}
        py={1}
        px={1.5}
      >
        {name || 'Безымянный сценарий'}
      </Typography>

      <Typography variant="body2">Удаление будет безвозвратным.</Typography>
    </Grid>
  );
};
