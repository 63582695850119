import { IHubType, IProjectType, Maybe } from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

interface ProjectDataType {
  id: string | null;
  name: string | null;
  hub: Maybe<IHubType>;
  codeAsuExpress: string;
  address: string;
  platform: {
    long: number;
    short: number;
  };
}

export const $Project = createStore<ProjectDataType>({
  id: null,
  name: null,
  hub: null,
  codeAsuExpress: 'нет кода',
  address: 'нет адреса',
  platform: {
    long: 0,
    short: 0,
  },
});

export const getProjectInfoFromScenarioIdFx = createEffect<
  string,
  IProjectType | null
>();

export const resetProjectInfoFn = createEvent();
