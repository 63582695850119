export enum EFilterMapLayout {
  Infrastructure = 'Infrastructure',
  RouteNetwork = 'RouteNetwork',
  PassengerFlows = 'PassengerFlows',
}
export enum EFilterMapPoint {
  A = 'A',
  B = 'B',
}

export const StartPlannedYear = new Date().getFullYear() - 1;
export const EndPlannedYear = 2036;

export const PlannedYearsRange: number[] = [];

for (let i = StartPlannedYear; i <= EndPlannedYear; i++) {
  PlannedYearsRange.push(i);
}

export type MapFilterField = {
  multimodal: boolean;
  railwayRoot: boolean;
  nightTrain: boolean;
  dayTrain: boolean;
  suburbanTrain: boolean;
  expressTrain: boolean;
  autoRoot: boolean;
  bus: boolean;
  auto: boolean;
  avia: boolean;
  actual: boolean;
  planned: boolean;
  plannedYear: number;
  areaBorder: boolean;
  intermediateCorrespondents: boolean;
};

export type BaseFilterSlotProps = {
  hideFields?: Array<keyof MapFilterField>;
  fields: Partial<MapFilterField>;
  setFields(args: Partial<MapFilterField>): void;
};
