import { createEvent, createStore } from 'effector';

export enum EModalRewriteCalculated {
  copy,
  rewrite,
  unsave,
}

export const $ModalsPtConstructor = createStore<ModalsPtConstructor>({
  socioEconomic: false,
  transport: false,
  calculation: false,
  deleteCalculation: false,
  shareCalculation: false,
  editDeny: false,
  recalculation: false,
  rewriteCalculated: false,
  action: null,
});

// Открыть/закрыть определенное модальное окно
export const setModalView = createEvent<Partial<ModalsPtConstructor>>();

$ModalsPtConstructor.on(setModalView, (state, payload) => {
  return {
    ...state,
    ...payload,
  };
});

export type ModalsPtConstructor<T = unknown> = {
  socioEconomic: boolean;
  transport: boolean;
  calculation: boolean;
  deleteCalculation: boolean;
  shareCalculation: boolean;
  editDeny: boolean;
  recalculation: boolean;
  rewriteCalculated: boolean;
  action: T | null;
};
