import { SvgIcon, SvgIconProps } from '@mui/material';

export const ReturnIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M14.4436 7.78077H8.7356V5.35615C8.7356 5.05307 8.39884 4.90153 8.16312 5.0699L4.13891 8.25223C3.9537 8.38693 3.9537 8.67317 4.13891 8.80787L8.16312 11.9902C8.39884 12.1754 8.7356 12.007 8.7356 11.7039V9.27932H14.4436C16.683 9.27932 18.5183 11.0978 18.5183 13.3372C18.5183 15.5766 16.683 17.4119 14.4436 17.4119H7.23704C6.8161 17.4119 6.49619 17.7487 6.49619 18.1528C6.49619 18.5737 6.83294 18.8936 7.23704 18.8936H14.4436C17.508 18.8936 20 16.4017 20 13.3372C20 10.2727 17.508 7.78077 14.4436 7.78077Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
