import { ITrainType, ITrainTypeEnum } from '@api/gql/tpu-types';
import { Pagination } from '@features/tpu-project-registry/components/Pagination';
import { useContextTrainScheduleHandlers } from '@features/tpu-train-schedule/hooks';
import { $ScenarioId, $Schedule } from '@features/tpu-train-schedule/stores';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';

import { Table } from '@components/TableTPU';
import { Tabs } from '@components/Tabs';

import { ROUTES } from '@constants/routes';

const weekDays = [
  {
    value: 'MO',
    label: 'Пн.',
  },
  {
    value: 'TU',
    label: 'Вт.',
  },
  {
    value: 'WE',
    label: 'Ср.',
  },
  {
    value: 'TH',
    label: 'Чт.',
  },
  {
    value: 'FR',
    label: 'Пт.',
  },
  {
    value: 'SA',
    label: 'Сб.',
  },
  {
    value: 'SU',
    label: 'Вс.',
  },
];

export const Component = () => {
  const navigate = useNavigate();
  const $schedule = useUnit($Schedule);
  const $scenarioId = useUnit($ScenarioId);

  const {
    getScenarioId,
    setPageSize,
    setPage,
    setSort,
    setSearch,
    setTrainType,
    turnOnOffTrain,
  } = useContextTrainScheduleHandlers();

  const goToScenariosPage = () => {
    navigate(`${ROUTES.TPU_EDITOR}/${$scenarioId}`);
  };

  const columns: GridColDef<ITrainType>[] = [
    ...weekDays.map(
      ({ value, label }): GridColDef<ITrainType> => ({
        field: value,
        headerName: label,
        width: 60,
        sortable: false,
        renderCell: ({ row }) => (
          <Checkbox
            checked={row.regularity?.includes(value)}
            size={'small'}
            disabled={true}
            sx={{
              padding: 'unset',
              opacity: 0.5,
            }}
          />
        ),
      }),
    ),
    {
      field: 'arrivalTime',
      headerName: 'Время прибытия',
      width: 180,
    },
    {
      field: 'departureTime',
      headerName: 'Время отправления',
      width: 182,
    },
    {
      field: 'number',
      headerName: 'Номер поезда',
      width: 146,
    },
    {
      field: 'wagons',
      headerName: 'Кол-во вагонов',
      sortable: false,
      width: 132,
    },
    {
      field: 'route',
      headerName: 'Маршрут',
      width: 940,
    },
    {
      field: 'way',
      headerName: 'Путь',
      width: 140,
    },
    {
      field: 'platform',
      headerName: 'Платформа',
      width: 140,
    },
    {
      field: 'on',
      headerName: 'Вкл./Выкл.',
      width: 104,
      sortable: false,
      renderCell: ({ row }) => (
        <Checkbox
          checked={row.on}
          size={'small'}
          onChange={() => turnOnOffTrain({ trainId: row.id, on: !row.on })}
          sx={{
            padding: 'unset',
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    getScenarioId();
  }, [getScenarioId]);

  return (
    <Box
      sx={{
        margin: '24px',
        borderRadius: '8px',
        width: 'calc(100% - 48px)',
        height: 'calc(100vh - 48px - 80px)',
        boxShadow: '0px 0px 8px 0px #00000026',
        overflow: 'auto',
        backgroundColor: theme => theme.palette.background.paper,
        padding: '24px',
      }}
    >
      <Typography
        variant={'body2'}
        fontWeight={'bold'}
      >
        Измение расписание поездов
      </Typography>
      <Box sx={{ paddingY: '24px', display: 'flex', gap: '24px' }}>
        <Tabs
          value={$schedule.trainType}
          onChange={setTrainType}
          sx={{
            minWidth: '426px',
            minHeight: '44px',
            fontSize: '14px',
          }}
        >
          <Tab
            value={ITrainTypeEnum.LongDistance}
            label={'Дальние поезда'}
            sx={{
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
              minHeight: '44px',
            }}
          />
          <Tab
            value={ITrainTypeEnum.Commuter}
            label={'Пригородные поезда'}
            sx={{
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px',
              minHeight: '44px',
            }}
          />
        </Tabs>
        <TextField
          onChange={e => setSearch(e.target.value)}
          placeholder="Найти"
          size="medium"
          fullWidth
          InputProps={{
            type: 'search',
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: {
              backgroundColor: '#F6F6F6 !important',
            },
          }}
        />
        <Button
          variant={'outlined'}
          fullWidth
          onClick={goToScenariosPage}
        >
          Закрыть
        </Button>
      </Box>
      <Table
        rows={$schedule.list}
        columns={columns}
        dataGridSx={{
          '.MuiDataGrid-cell': {
            padding: '10px 16px',
          },
        }}
        dataGridProps={{
          rowHeight: 44,
          paginationMode: 'server',
          sortingMode: 'server',
          disableRowSelectionOnClick: true,
          onSortModelChange: setSort,
          rowCount: Math.ceil($schedule.count / $schedule.pageSize),
        }}
        wrapperSx={{
          mb: '24px',
          height: 'calc(100% - 20px - 66px - 92px - 24px)',
        }}
      />
      <Pagination
        page={$schedule.page - 1}
        rowsPerPage={$schedule.pageSize}
        count={$schedule.count}
        setPage={index => setPage(index + 1)}
        setRowsPerPage={setPageSize}
      />
    </Box>
  );
};
