import { createEvent, createStore } from 'effector';

export enum EStateModalShareProjects {
  share,
  success,
}

interface ModalShareProjects {
  state: EStateModalShareProjects;
}

const defaultState = {
  state: EStateModalShareProjects.share,
};

export const $ModalShareProjects =
  createStore<ModalShareProjects>(defaultState);

export const setState = createEvent<EStateModalShareProjects>();
export const resetModalShareProjects = createEvent();

$ModalShareProjects
  .on(setState, (state, payload) => {
    return { ...state, state: payload };
  })
  .reset(resetModalShareProjects);
