import { PropsWithChildren, ReactElement, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

type AccordionFilterProps = PropsWithChildren & {
  defaultExpanded?: boolean;
  title?: string;
  TitleComponent?: ReactElement;
  sx?: SxProps<Theme>;
  key?: React.Key | null | undefined;
};

export const AccordionFilter = (props: AccordionFilterProps) => {
  const { defaultExpanded = true, title, children, TitleComponent, sx } = props;

  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <Accordion
      expanded={expanded}
      onChange={(_event, value) => {
        setExpanded(value);
      }}
      disableGutters
      sx={{
        ...sx,
        background: 'transparent',
        border: 'none',
        boxShadow: 'none',
        '&:before': { display: 'none' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          flexDirection: 'row-reverse',
          paddingLeft: 0,
          '& > .MuiAccordionSummary-content': { pl: 2 },
        }}
      >
        {TitleComponent || <Typography>{title}</Typography>}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
