import { Order } from '@features/ag-projects-registry/components/TableProjects/TableProjects';
import { agProjectsTableHeadCells } from '@features/ag-projects-registry/components/TableProjects/configs/headCells';
import { ProjectType } from '@features/ag-projects-registry/store/lists/projects';
import * as React from 'react';

import { TableHead as MuiTableHead, TableCell, TableRow } from '@mui/material';

export type TableHeadProps = {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ProjectType,
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
};

export const TableProjectsHead = (props: TableHeadProps) => {
  const { orderBy, order } = props;

  return (
    <MuiTableHead
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}
    >
      <TableRow>
        {agProjectsTableHeadCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              bgcolor: theme => theme.palette.border.divider,
              '&:first-of-type': {
                borderTopLeftRadius: '6px',
              },
              '&:last-of-type': {
                borderTopRightRadius: '6px',
              },
            }}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            > */}
            {headCell.label}
            {/* {orderBy === headCell.id ? (
                <Box
                  component="span"
                  sx={visuallyHidden}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};
