import { SvgIcon, SvgIconProps } from '@mui/material';

export const ToiletIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="white"
      />
      <path
        d="M9.23311 8.0491C8.60734 6.1718 7.42533 5.75463 5.75662 5.75463C4.0879 5.75463 2.9059 6.24133 2.28013 8.0491L1.23718 10.9694C0.959062 11.8037 0.680943 12.5685 1.93248 13.0553C2.48872 11.5951 3.04496 10.135 3.60119 8.67486C3.74025 8.18816 4.15743 7.70144 4.64414 7.91003C3.60119 10.7608 2.48871 13.542 1.37624 16.5317H4.7832V20.773C4.7832 21.3293 5.26991 21.5379 5.75662 21.5379C6.24333 21.5379 6.73003 21.2598 6.73003 20.773V16.5317H10.137C9.02451 13.542 7.98157 10.7608 6.86909 7.91003C7.42533 7.70144 7.77298 8.18816 7.91204 8.67486C8.46828 10.135 9.02452 11.5951 9.58075 13.0553C10.7628 12.499 10.5542 11.7342 10.2761 10.9694L9.23311 8.0491ZM5.75662 5.26791C6.6605 5.26791 7.42533 4.50309 7.42533 3.66873C7.42533 2.76484 6.6605 2 5.75662 2C4.85273 2 4.0879 2.76484 4.0879 3.66873C4.0879 4.50309 4.85273 5.26791 5.75662 5.26791ZM11.8752 21.6769H12.9182V2H11.8752V21.6769ZM21.9571 6.6585C21.1227 5.82415 19.8016 5.82414 19.3845 5.82414H17.8548C17.3681 5.82414 16.1166 5.82415 15.2822 6.6585C14.4478 7.49286 14.4478 8.81392 14.4478 9.64828V12.0123C14.4478 12.9857 14.865 13.3334 15.7689 13.3334C15.7689 12.0818 15.7689 10.8998 15.7689 9.64828C15.7689 9.16157 15.908 8.53582 16.5337 8.53582V20.2168C16.5337 20.3559 16.5337 20.4949 16.5337 20.634C16.5337 20.8426 16.6728 21.1207 16.8814 21.3293C17.229 21.6769 17.6462 21.6769 17.9939 21.6769H18.3415V14.4458H19.1063V21.6769H19.454C19.8016 21.6769 20.2188 21.6769 20.5665 21.3293C20.775 21.1207 20.8446 20.8426 20.9141 20.634C20.9141 20.4949 20.9141 20.3559 20.9141 20.2168V8.60533C21.4703 8.60533 21.6789 9.2311 21.6789 9.71781C21.6789 10.9693 21.6789 12.1514 21.6789 13.4029C22.5828 13.4029 23 12.9857 23 12.0818V9.71781C22.7914 8.81392 22.7914 7.49286 21.9571 6.6585ZM18.6196 5.26791C19.5235 5.26791 20.2883 4.50309 20.2883 3.66873C20.2883 2.76484 19.5235 2 18.6196 2C17.7157 2 16.9509 2.76484 16.9509 3.66873C16.9509 4.50309 17.7157 5.26791 18.6196 5.26791Z"
        fill="#3679EA"
      />
    </SvgIcon>
  );
};
