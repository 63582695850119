import { useUnit } from 'effector-react';

import Grid from '@mui/material/Unstable_Grid2';

import { Transportation } from '@features/pt-constructor/forms/transportation';
import {
  $Calculations,
  $SelectedCalculationScenario,
} from '@features/pt-constructor/store';

export const TransportationContainer = () => {
  const {
    transportProjects,
    transportScenarios,
    readonly,
    isNewCalculationScenario,
  } = useUnit($Calculations);
  const { id, transportScenario } = useUnit($SelectedCalculationScenario);

  return (
    (id || isNewCalculationScenario) && (
      <Grid xs={4}>
        <Transportation
          selectedTransportScenario={transportScenario}
          transportProjects={transportProjects}
          transportScenarios={transportScenarios}
          readonly={readonly}
        />
      </Grid>
    )
  );
};
