import { sample } from 'effector';

import {
  $Floors,
  $Ruler,
  resetListOfRulersFn,
  resetRulerFn,
} from '@features/tpu-calculation-scenarios-viewing/stores';
import {
  $ListOfRulers,
  setListOfRulers,
} from '@features/tpu-calculation-scenarios-viewing/stores/ruler/ruler.store';

$ListOfRulers.reset(resetListOfRulersFn).on(setListOfRulers, (_, payload) => {
  if (payload.ruler) {
    return payload.ruler;
  }
});

$Ruler.reset(resetRulerFn);

sample({
  clock: $Floors,
  source: $ListOfRulers,
  fn: (listOfRulers, floors) => listOfRulers[floors.selectedFloor],
  target: $Ruler,
});
