import { SvgIcon, SvgIconProps } from '@mui/material';

export const LayersIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M12.9984 5.50127C12.9984 5.14832 12.6425 4.9065 12.3143 5.03647L6.44741 7.36014C5.87549 7.58665 5.49988 8.13942 5.49988 8.75457V14.4965C5.49988 14.8496 5.85589 15.0914 6.18409 14.9612L6.49976 14.8361V15.9106C5.53022 16.2551 4.5 15.5367 4.5 14.4965V8.75457C4.5 7.72933 5.12602 6.80805 6.07923 6.43051L11.9461 4.10684C12.9014 3.72851 13.9349 4.40029 13.9954 5.40785L12.9984 5.80276V5.50127ZM15.998 7.50152C15.998 7.14855 15.6421 6.90674 15.314 7.03672L9.13121 9.48547C8.74992 9.63648 8.49951 10.005 8.49951 10.4151V16.4968C8.49951 16.8498 8.85553 17.0916 9.18372 16.9615L10.4993 16.4399V17.5155L9.55227 17.891C8.56769 18.2813 7.49963 17.5559 7.49963 16.4968V10.4151C7.49963 9.59491 8.00045 8.85788 8.76301 8.55585L14.9457 6.10709C15.9302 5.71717 16.9979 6.44263 16.9979 7.50152V7.60393L15.998 7.99995V7.50152ZM18.8142 9.03545C19.1423 8.90549 19.4982 9.1473 19.4982 9.50027V16.1226C19.4982 16.3277 19.373 16.512 19.1823 16.5874L13.183 18.9636C12.8549 19.0935 12.499 18.8517 12.499 18.4987V11.8764C12.499 11.6713 12.6242 11.4871 12.8149 11.4116L18.8142 9.03545ZM20.4981 9.50027C20.4981 8.44138 19.4305 7.71591 18.4459 8.10584L12.4467 10.4819C11.8748 10.7085 11.4991 11.2612 11.4991 11.8764V18.4987C11.4991 19.5576 12.5667 20.2831 13.5513 19.8931L19.5506 17.517C20.1224 17.2906 20.4981 16.7378 20.4981 16.1226V9.50027Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
