import { SvgIcon, SvgIconProps } from '@mui/material';

export const DoorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill={props.fill ?? 'none'}
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill={props.fill ?? 'none'}
      />
      <path
        fill={props.fill ?? 'none'}
        stroke="currentColor"
        strokeWidth="2"
        d="M3 3h18v18H3z"
      />
      <path
        d="m8.417 15.636 2.674-2.91v-2.013l-1.925 2.014V8.363H7.668v4.364l-2.032-2.014v2.014l2.781 2.909ZM16.6 8.364l2.673 2.91v2.013l-1.925-2.014v4.364H15.85v-4.364l-2.032 2.014v-2.014L16.6 8.364Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
