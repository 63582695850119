import { IProjectSortEnum } from '@api/gql/tpu-types';

export const FIELD = {
  INDEX: 'index',
  DATE: 'modified',
  NAME: 'name',
  HUB_NAME: 'hubName',
  HUB_CODE: 'hubCode',
  AGGLOMERATION: 'agglomeration',
  STATE: 'state',
  USER: 'user',
};

export const SORT_VALUE = {
  asc: {
    [FIELD.INDEX]: IProjectSortEnum.CreatedAsc,
    [FIELD.NAME]: IProjectSortEnum.NameAsc,
    [FIELD.DATE]: IProjectSortEnum.CreatedAsc,
    [FIELD.HUB_NAME]: IProjectSortEnum.HubNameAsc,
    [FIELD.HUB_CODE]: IProjectSortEnum.HubCodeAsc,
    [FIELD.STATE]: IProjectSortEnum.StateAsc,
  },
  desc: {
    [FIELD.INDEX]: IProjectSortEnum.CreatedDesc,
    [FIELD.NAME]: IProjectSortEnum.NameDesc,
    [FIELD.DATE]: IProjectSortEnum.CreatedDesc,
    [FIELD.HUB_NAME]: IProjectSortEnum.HubNameDesc,
    [FIELD.HUB_CODE]: IProjectSortEnum.HubCodeDesc,
    [FIELD.STATE]: IProjectSortEnum.StateDesc,
  },
};
