import { sample } from 'effector';

import {
  $FilterMap,
  EFilterMapLayout,
} from '@features/pt-forecast-new/stores/filters';
import {
  GetForFeaturePayload,
  InspectorInfrastructureApi,
} from '@features/pt-forecast-new/stores/inspector/infrastructure';
import {
  InspectorApi,
  eInspectorType,
} from '@features/pt-forecast-new/stores/inspector/store';
import { $SelectedFeatures } from '@features/pt-forecast-new/stores/map/selectedFeatures';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';

// Событие для группы семплов открытия инспектора инфраструктуры
const openInfrastructureInspector = sample({
  clock: $SelectedFeatures,
  source: {
    SelectedFeatures: $SelectedFeatures,
    FilterMap: $FilterMap,
    UI: $UI,
  },
  filter: ({ FilterMap, SelectedFeatures }) =>
    FilterMap.layout === EFilterMapLayout.Infrastructure &&
    SelectedFeatures.length === 1,
});

// Указание типа инспектора
sample({
  clock: openInfrastructureInspector,
  fn: () => {
    return eInspectorType.infrastructure;
  },
  target: InspectorApi.setType,
});

// запрос данных для фичи
sample({
  clock: openInfrastructureInspector,
  fn: ({ SelectedFeatures }) => {
    return SelectedFeatures[0] as GetForFeaturePayload;
  },
  target: InspectorInfrastructureApi.getForFeature,
});

// Открытие инспектора
sample({
  clock: openInfrastructureInspector,
  fn: () => {
    return true;
  },
  target: UIApi.setVisibleInspector,
});
//
// // Событие для группы семплов закрытия инспектора инфраструктуры
// const closeInfrastructureInspector = sample({
//   clock: SelectedFeaturesApi.setSelectedFeatures,
//   source: {SelectedFeatures: $SelectedFeatures, FilterMap: $FilterMap, UI: $UI},
//   filter: ({ FilterMap, SelectedFeatures}) =>
//     FilterMap.layout === EFilterMapLayout.PassengerFlows ||
//     SelectedFeatures.length !== 1,
// })

// Сброс типа инспектора
sample({
  clock: UIApi.setVisibleInspector,
  filter: payload => !payload,
  fn: () => {
    return null;
  },
  target: InspectorApi.setType,
});

// Сброс запрошенных данных
sample({
  clock: UIApi.setVisibleInspector,
  filter: payload => !payload,
  fn: () => {
    return {
      layout: null,
      id: null,
    } as GetForFeaturePayload;
  },
  target: InspectorInfrastructureApi.getForFeature,
});
