import { sample } from 'effector';

import { queryGetAllScenarios } from '@features/pt-constructor/store/api/queryGetAllScenarios';
import { queryGetSocEcoAndTransportScenarios } from '@features/pt-constructor/store/api/queryGetSocEcoAndTransportScenarios';
import { socEcoScenariosReformatter } from '@features/pt-constructor/store/api/reformatters/socEcoScenariosReformatter';
import { transportProjectsReformatter } from '@features/pt-constructor/store/api/reformatters/transportProjectsReformatter';
import { transportScenariosReformatter } from '@features/pt-constructor/store/api/reformatters/transportScenariosReformatter';
import { userScenariosReformatter } from '@features/pt-constructor/store/api/reformatters/userScenariosReformatter';
import {
  CalculationsApi,
  fetchAllCalculationsDataFx,
  fetchAllScenarios,
  fetchScenariosFx,
} from '@features/pt-constructor/store/lists/scenarios/store';

sample({
  clock: fetchAllScenarios,
  target: fetchAllCalculationsDataFx,
});

sample({
  clock: CalculationsApi.pushCalculationScenarioInRegistry,
  target: fetchScenariosFx,
});

sample({
  clock: fetchScenariosFx.done,
  fn: request => {
    return {
      socEcoScenarios: socEcoScenariosReformatter(
        request.result.socEcoScenarioInstances,
      ),
      transportScenarios: transportScenariosReformatter(
        request.result.transportScenarioInstances,
      ),
    };
  },
  target: CalculationsApi.updateScenarios,
});

// Размапить ответ под список расчетов & список транспортных проектов & социальных сценариев & транспортных сценариев
sample({
  clock: fetchAllCalculationsDataFx.done,
  fn: request => {
    return {
      transportProjects: transportProjectsReformatter(
        request.result.transportProjectList,
      ),
      userScenarios: userScenariosReformatter(request.result.computingRegistry),
      socEcoScenarios: socEcoScenariosReformatter(
        request.result.socEcoScenarioInstances,
      ),
      transportScenarios: transportScenariosReformatter(
        request.result.transportScenarioInstances,
      ),
      accessibleScenarios: null,
      calculationResult: null,
    };
  },
  target: CalculationsApi.setCalculationsState,
});

fetchAllCalculationsDataFx.use(queryGetAllScenarios);

fetchScenariosFx.use(queryGetSocEcoAndTransportScenarios);
