import { apiClient } from '@api/client';
import { IMutation } from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import {
  $DownloadFile,
  DownloadFileApi,
  DownloadFileFx,
} from '@features/ag-projects-registry/store/downloadFile/store';
import { UIAgApi } from '@features/ag-projects-registry/store/ui';

import { base64ToBlob } from '@utils/fileConversion';

sample({
  clock: DownloadFileFx.done,
  source: { $downloadFile: $DownloadFile },
  fn: ({ $downloadFile }, payload) => {
    const blob = base64ToBlob(
      payload.result?.file || '',
      'application/octet-stream',
    );

    if ($downloadFile.url) URL.revokeObjectURL($downloadFile.url);

    return {
      ...payload.result,
      url: URL.createObjectURL(blob),
    };
  },
  target: DownloadFileApi.setDownloadFile,
});

sample({
  clock: DownloadFileApi.setDownloadFile,
  fn: () => ({ downloadFile: true }),
  target: UIAgApi.setModalView,
});

// Эффект скачивания файла
DownloadFileFx.use(async params => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation DownloadFileMutation($fileId: UUID!) {
        downloadFile(fileId: $fileId) {
          success
          file
          extension
          name
        }
      }
    `,
    variables: {
      fileId: params.fileId,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  return response.data?.downloadFile || null;
});
