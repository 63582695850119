import { createElement } from 'react';

import { Box, SxProps, styled } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import type {
  DataGridProps,
  GridColDef,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';

import { FilterAscIcon, FilterIcon } from '@components/icons';

interface TableProps<Row extends GridValidRowModel = GridValidRowModel> {
  rows: Row[];
  columns: GridColDef<Row>[];
  page?: number;
  rowsPerPage?: number;
  wrapperSx?: SxProps<Theme>;
  dataGridSx?: SxProps<Theme>;
  dataGridProps?: Omit<DataGridProps<Row>, 'rows' | 'columns'>;
  wrapperRefCallback?: (arg: HTMLDivElement | null) => void;
}

const StyledDataGrid = styled((props: DataGridProps) =>
  createElement(DataGrid, props),
)(({ theme }: { theme: Theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  lineHeight: theme.typography.pxToRem(16),

  '.MuiDataGrid-cell': {
    whiteSpace: 'normal !important',
    padding: '7px 16px',
    borderCollapse: 'collapse',
    border: `1px solid ${theme.palette.border.divider}`,
  },
  '.MuiDataGrid-columnHeader': {
    borderCollapse: 'collapse',
    border: `1px solid ${theme.palette.border.divider}`,
    padding: '0 16px',

    '& .MuiIconButton-root': {
      width: '24px',
      height: '24px',
    },
  },
  '.MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.background.default,
  },
  '.MuiDataGrid-iconButtonContainer': {
    visibility: 'visible',
    width: 'unset',
  },
  '.Mui-hovered': {
    backgroundColor: `${theme.palette.customs.bgHover} !important`,
  },
  '.Mui-selected': {
    backgroundColor: `${theme.palette.customs.bgHover} !important`,
  },
  '.MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '.MuiDataGrid-row': {
    '.DataGrid-rowActions': {
      visibility: 'hidden',
    },

    '&:hover': {
      '.DataGrid-rowActions': {
        visibility: 'visible',
      },
    },
  },
  '.MuiDataGrid-virtualScroller': {
    minHeight: '50px',
  },
}));

export function Table<Row extends GridValidRowModel = GridValidRowModel>({
  rows,
  columns,
  page,
  rowsPerPage,
  dataGridProps,
  dataGridSx = {},
  wrapperSx,
  wrapperRefCallback,
}: TableProps<Row>) {
  const isPaginationModelPassed = page && rowsPerPage;
  const paginationModel = isPaginationModelPassed
    ? { page: page, pageSize: rowsPerPage }
    : undefined;

  return (
    <Box
      sx={{ ...wrapperSx }}
      ref={wrapperRefCallback}
    >
      <StyledDataGrid
        rows={rows}
        columns={columns}
        sx={dataGridSx}
        columnHeaderHeight={44}
        getRowHeight={() => 'auto'}
        paginationModel={paginationModel}
        hideFooter
        disableColumnMenu
        slots={{
          columnSortedAscendingIcon: FilterAscIcon,
          columnSortedDescendingIcon: FilterIcon,
        }}
        {...dataGridProps}
      />
    </Box>
  );
}
