import { ECalculationScenarioStatusType } from '@features/pt-constructor/constants/calculationScenarioStatusType';
import { $CalculationStatuses } from '@features/pt-constructor/store';
import { createEvent, createStore, sample } from 'effector';

export enum EStateModalCalculation {
  calculation,
  stop,
  error,
  success,
  pending,
  queue,
}

export type SetStatusPayload = {
  status: EStateModalCalculation;
  id?: string;
  error?: string;
};

interface ModalCalculation {
  status: EStateModalCalculation;
  calculationError: string | null;
  id: string | null;
}

const defaultState: ModalCalculation = {
  status: EStateModalCalculation.pending,
  calculationError: null,
  id: null,
};

export const $ModalCalculation = createStore<ModalCalculation>(defaultState);

export const resetModalCalculation = createEvent();
export const setWatchModalCalculation = createEvent<string>();
const setModalCalculationStatus = createEvent<EStateModalCalculation>();

// TODO: Выпилить этот стор, можно взять сценария для отслеживания из стора текущего сценария, а статусы из стора статусов

$ModalCalculation
  // .on(setStatusProcessCalculation, (state, payload) => ({
  //   state
  //   status: payload.status,
  //   calculationError:
  //     payload.status === EStateModalCalculation.error
  //       ? payload?.error || 'Неизвестная ошибка'
  //       : null,
  // }))
  .on(setWatchModalCalculation, (state, payload) => ({
    ...state,
    id: payload,
  }))
  .on(setModalCalculationStatus, (state, payload) => ({
    ...state,
    status: payload,
  }))
  .reset(resetModalCalculation);

sample({
  clock: [setWatchModalCalculation, $CalculationStatuses],
  source: {
    modalCalculation: $ModalCalculation,
    calculationStatuses: $CalculationStatuses,
  },
  filter: ({ modalCalculation }) => !!modalCalculation.id,
  fn: ({ modalCalculation, calculationStatuses }) => {
    const status = calculationStatuses.statuses.find(
      scenario => scenario.id === modalCalculation.id,
    )?.status;
    if (!status || status === ECalculationScenarioStatusType.Draft)
      return EStateModalCalculation.pending;
    if (status === ECalculationScenarioStatusType.Pending)
      return EStateModalCalculation.calculation;
    if (status === ECalculationScenarioStatusType.Success)
      return EStateModalCalculation.success;
    if (status === ECalculationScenarioStatusType.Failure)
      return EStateModalCalculation.error;
    if (status === ECalculationScenarioStatusType.Queue)
      return EStateModalCalculation.queue;
    return EStateModalCalculation.error;
  },
  target: setModalCalculationStatus,
});
