export const getLineWidth = (zoom: number, stops: number[]): number => {
  // Если зум меньше первого стопа, возвращаем первую ширину линии
  if (zoom < stops[0]) {
    return stops[1];
  }

  // Если зум больше последнего стопа, возвращаем последнюю ширину линии
  const lastIndex = stops.length - 2;
  if (zoom >= stops[lastIndex]) {
    return stops[lastIndex + 1];
  }

  // Поиск двух стопов между которыми находится текущее значение зума
  for (let i = 0; i < lastIndex; i += 2) {
    const nextZoomLevel = stops[i + 2];

    if (zoom < nextZoomLevel) {
      const prevZoomLevel = stops[i];
      const prevLineWidth = stops[i + 1];
      const nextLineWidth = stops[i + 3];

      const interpolation =
        (zoom - prevZoomLevel) / (nextZoomLevel - prevZoomLevel);
      const result =
        prevLineWidth + (nextLineWidth - prevLineWidth) * interpolation;

      // Округление результата до ближайшего кратного 0.25
      return Math.round(result * 4) / 4;
    }
  }
  return 1;
};
