import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import { Box, Divider, IconButton, Typography } from '@mui/material';

import { ChevronRightIcon } from '@components/icons';

import { BoxData } from '@features/pt-forecast-new/components/Inspector/BoxData';
import { $InspectorInfrastructure } from '@features/pt-forecast-new/stores/inspector/infrastructure';
import { UIApi } from '@features/pt-forecast-new/stores/ui';

import {
  convertUnitOfMeasure,
  gapBetweenDigits,
} from '@utils/dataForInspector';

export const TransportRegionsInspector = () => {
  const { transportRegion } = useUnit($InspectorInfrastructure);
  const { setVisibleInspector } = useUnit(UIApi);

  const onClose = useCallback(() => {
    setVisibleInspector(false);
  }, [setVisibleInspector]);

  const population = useMemo(() => {
    const value = transportRegion?.population;

    if (!value) return 'н/д';

    return (
      convertUnitOfMeasure({
        value,
        unit: 'чел.',
      }) || 'н/д'
    );
  }, [transportRegion?.population]);

  const averageNumberOfEmployeesOfOrganizationsWithoutSmp = useMemo(() => {
    const value =
      transportRegion?.averageNumberOfEmployeesOfOrganizationsWithoutSmp;

    if (!value) return 'н/д';

    return (
      convertUnitOfMeasure({
        value,
        unit: 'чел.',
      }) || 'н/д'
    );
  }, [transportRegion?.averageNumberOfEmployeesOfOrganizationsWithoutSmp]);

  const averageMonthlySalary = useMemo(() => {
    const value = transportRegion?.averageMonthlySalary;

    if (!value) return 'н/д';

    return (
      convertUnitOfMeasure({
        value,
        unit: 'руб.',
      }) || 'н/д'
    );
  }, [transportRegion?.averageMonthlySalary]);

  const revenueWithTaxesOnProducts = useMemo(() => {
    const value = transportRegion?.revenueWithTaxesOnProducts;

    if (!value) return 'н/д';

    return (
      convertUnitOfMeasure({
        value: !isNaN(value) ? value * 1_000_000 : null,
        unit: 'руб.',
      }) || 'н/д'
    );
  }, [transportRegion?.revenueWithTaxesOnProducts]);

  const numberOfPlaces = useMemo(
    () =>
      transportRegion
        ? (gapBetweenDigits(transportRegion?.numberOfPlaces) || 0) + ' шт.'
        : 'н/д',
    [transportRegion],
  );

  const revenueTourismIndustry = useMemo(
    () =>
      transportRegion
        ? (gapBetweenDigits(transportRegion?.revenueTourismIndustry) || 0) +
          ' млн руб.'
        : 'н/д',
    [transportRegion],
  );

  const levelOfMotorization = useMemo(() => {
    const value = transportRegion?.levelOfMotorization as number;

    if (isNaN(value)) return 'н/д';

    return (
      gapBetweenDigits(value.toFixed(2)).replace('.', ',') + ' авто/тыс. чел.'
    );
  }, [transportRegion?.levelOfMotorization]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 3,
        }}
      >
        <IconButton
          sx={{
            width: '32px',
            height: '32px',
            backgroundColor: 'primary.main',
            borderRadius: 1,
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={onClose}
        >
          <ChevronRightIcon />
        </IconButton>

        <Typography variant="subtitle2">Транспортный район</Typography>
      </Box>

      <BoxData
        name={'Название'}
        value={transportRegion?.name || 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Численность населения'}
        value={population}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Среднесписочная численность работников'}
        value={averageNumberOfEmployeesOfOrganizationsWithoutSmp}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Среднемесячная заработная плата'}
        value={averageMonthlySalary}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Выручка (нетто) от продажи товаров, продукции, работ, услуг'}
        value={revenueWithTaxesOnProducts}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Число мест в коллективных средствах размещения'}
        value={numberOfPlaces}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'ВДС Туризм'}
        value={revenueTourismIndustry}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Уровень автомобилизации'}
        value={levelOfMotorization}
      />
    </>
  );
};
