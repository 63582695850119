import { useUnit } from 'effector-react';
import { useNavigate } from 'react-router-dom';

import { Dialog, DialogTitle, SxProps } from '@mui/material';
import { Box, Button, DialogActions } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

import { ROUTES } from '@constants/routes';

import { Content } from '@features/tpu-calculation-scenarios-viewing/components/ModalPlayerErrors/Content';
import { useCalculationScenariosViewingHandlers } from '@features/tpu-calculation-scenarios-viewing/hooks';
import {
  $ScenarioErrors,
  EPlayerErrorType,
} from '@features/tpu-calculation-scenarios-viewing/stores/scenarioErrors';
import { $ScenarioData } from '@features/tpu-calculation-scenarios-viewing/stores/scenarioId/scenarioId.store';

const dialogSx: SxProps<Theme> = {
  '& .MuiPaper-root': {
    width: '640px',
    minWidth: '640px',
    maxHeight: 'calc(100vh - 64px)',
  },
  '& .MuiDialogTitle-root': {
    padding: '24px',
  },
  '& .MuiDialogActions-root': {
    gap: '24px',
    padding: '24px',
    '&:not(:first-of-type)': {
      marginLeft: 'unset',
      display: 'flex',
    },
  },
  '& .MuiDialogContent-root': {
    padding: '0 24px 24px',
    justifyContent: 'center',
    display: 'grid',
    gridTemplateRows: '52px 24px 1fr',
    gridTemplateColumns: '1fr',
  },
};

export const ModalPlayerErrors = () => {
  const navigate = useNavigate();

  const $scenarioData = useUnit($ScenarioData);
  const $errors = useUnit($ScenarioErrors);

  const isErrors = !!$errors?.length;

  const { resetErrors } = useCalculationScenariosViewingHandlers();

  const noRulerError = !$errors?.some(
    error => error.type === EPlayerErrorType.Ruler,
  );

  const goToEditor = () => {
    navigate(`${ROUTES.TPU_EDITOR}/${$scenarioData.scenarioId}`);
  };

  const goToRegister = () => {
    navigate(`${ROUTES.TPU_SCENARIOS}/${$scenarioData?.projectId}`);
  };

  return (
    <Dialog
      open={isErrors}
      sx={dialogSx}
    >
      <DialogTitle>
        Результат проверки возможности запустить сценарий
      </DialogTitle>

      <Content errors={$errors} />

      <DialogActions sx={{ width: '100%' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '10px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <Button
              fullWidth
              onClick={goToEditor}
            >
              В редактор
            </Button>

            <Button
              color={'secondary'}
              fullWidth
              onClick={goToRegister}
            >
              В реестр сценариев прогноза
            </Button>
          </Box>

          {noRulerError && (
            <Button
              variant={'outlined'}
              onClick={resetErrors}
              fullWidth
            >
              Продолжить
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};
