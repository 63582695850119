import { useUnit } from 'effector-react';
import { useState } from 'react';

import { Box, Typography, styled } from '@mui/material';

import { useCalculationScenariosViewingHandlers } from '@features/tpu-calculation-scenarios-viewing/hooks/useCalculationScenariosViewingHandlers';
import { $Player } from '@features/tpu-calculation-scenarios-viewing/stores/scenarioPlayer/scenarioPlayer.store';
import { formatSeconds } from '@features/tpu-calculation-scenarios-viewing/utils/formatSeconds';

const TinyText = styled(Typography)({
  fontSize: 12,
  color: '#606060',
  fontWeight: 400,
});

export const PlayerDynamicBar = () => {
  const [pointerX, setPointerX] = useState(0);
  const [pointerVisible, setPointerVisible] = useState(false);

  const { setCurrentTimeOfSimulation } =
    useCalculationScenariosViewingHandlers();

  const { endOfSimulation, uploadedTimeOfSimulation, currentTimeOfSimulation } =
    useUnit($Player);

  const uploadedWidth = (300 / endOfSimulation) * uploadedTimeOfSimulation;
  const currentTimeWidth = (300 / endOfSimulation) * currentTimeOfSimulation;
  const pointerTimeConstant = endOfSimulation / 299;
  const pointerTime = Math.floor(pointerX * pointerTimeConstant);

  const onMouseMove = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    const x = event.nativeEvent.offsetX;

    if (x < 0) {
      setPointerX(0);
    } else if (x > 299) {
      setPointerX(299);
    } else {
      setPointerX(x);
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '300px',
        height: '6px',
        mt: '18px',
        marginRight: '24px',
        backgroundColor: '#F6F6F6',
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: '-22px',
        }}
      >
        <TinyText>{formatSeconds(currentTimeOfSimulation)}</TinyText>
        <TinyText>{formatSeconds(endOfSimulation)}</TinyText>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          width: `${uploadedWidth}px`,
          height: '6px',
          mt: '4px',
          marginRight: '24px',
          backgroundColor: '#6060604C',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
        onMouseMove={onMouseMove}
        onClick={() => setCurrentTimeOfSimulation(pointerTime)}
        onMouseEnter={() => setPointerVisible(true)}
        onMouseLeave={() => setPointerVisible(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            width: `${currentTimeWidth}px`,
            height: '6px',
            marginRight: '24px',
            backgroundColor: '#E21A1A',
            borderRadius: '8px',
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          padding: '12px',
          color: '#606060',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '12px',
          borderRadius: '8px',
          position: 'absolute',
          top: '-40px',
          right: `${255 - pointerX}px`,
          visibility: pointerVisible ? 'visible' : 'hidden',
          boxShadow: '0px 2px 4px 0px #0000001A',
          zIndex: 10,
        }}
      >
        {formatSeconds(pointerTime)}
      </Box>
    </Box>
  );
};
