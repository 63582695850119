import { Typography } from '@mui/material';

export const Stop = () => {
  return (
    <>
      <Typography variant="body2">Вы действительно хотите отменить?</Typography>

      <Typography
        variant="body2"
        mt={1.5}
      >
        Расчет модели иногда занимает много времени, если отменить, то придется
        ждать потом снова
      </Typography>
    </>
  );
};
