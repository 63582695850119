import { Fill, Icon, Stroke, Style } from 'ol/style';

import RadioButtonChecked from '@components/icons/RadioButtonChecked.svg';

export const walkingAreaVectorStyle = () =>
  new Style({
    stroke: new Stroke({
      color: '#E21A1A40',
      width: 2,
    }),
    fill: new Fill({
      color: '#E21A1A0D',
    }),
    image: new Icon({
      src: RadioButtonChecked,
    }),
  });
