import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import './stores';

import { Stack, Typography } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { Loading } from '@components/Loading/Loading';

import { palette } from '@system/theme/palette';

import { ErrorNotification } from '@widgets/widget-routes-params/components/Notifications/ErrorNotification';
import { TableRoutesParams } from '@widgets/widget-routes-params/components/TableRoutesParams/TableRoutesParams';
import { ToolBar } from '@widgets/widget-routes-params/components/ToolBar/ToolBar';
import {
  DirectionsParamsTableApi,
  getGraphDirectionsFx,
} from '@widgets/widget-routes-params/stores/list/directionsParamsTable/store';
import { SettingsApi } from '@widgets/widget-routes-params/stores/settings';
import { $UI, UIApi } from '@widgets/widget-routes-params/stores/ui';

export const WidgetRoutesParams = ({
  scenarioId,
  closeRoutesParams,
}: WidgetRoutesParamsType) => {
  const { isAlertInputError, isGetDirections, isRedirection } = useUnit($UI);
  const { resetUI } = useUnit(UIApi);
  const { setScenarioId } = useUnit(SettingsApi);
  const { resetDirectionsParams } = useUnit(DirectionsParamsTableApi);

  useEffect(() => {
    getGraphDirectionsFx({ scenarioId });
    setScenarioId(scenarioId);

    return () => {
      resetDirectionsParams();
      resetUI();
    };
  }, [resetDirectionsParams, resetUI, scenarioId, setScenarioId]);

  useEffect(() => {
    if (isRedirection) closeRoutesParams();
  }, [closeRoutesParams, isRedirection]);

  return (
    <>
      <ToolBar closeRoutesParams={closeRoutesParams} />

      <AutoFullHeight
        style={{
          padding: '24px',
          backgroundColor: palette.background.default,
        }}
      >
        <Stack
          sx={{
            backgroundColor: theme => theme.palette.common.white,
            borderRadius: 2,
            boxShadow: '0px 0px 8px 0px #00000026',
            width: '100%',
            height: '100%',
            p: 3,
            rowGap: 3,
            position: 'relative',
          }}
        >
          <TableRoutesParams />

          <Typography
            fontSize={12}
            color={theme => theme.palette.text.secondary}
          >
            *Доступны для ввода белые ячейки.
          </Typography>

          <Loading
            isLoading={isGetDirections}
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              borderRadius: 2,
            }}
          />
        </Stack>
      </AutoFullHeight>

      <ErrorNotification
        isShow={isAlertInputError}
        name="isAlertInputError"
        title="Не заполнены все параметры сообщения - проверьте что указано время в пути, стоимость и частота сообщения по необходимым видам транспорта"
      />
    </>
  );
};

type WidgetRoutesParamsType = {
  scenarioId: string;
  closeRoutesParams: () => void;
};
