import { IQuery } from '@api/gql/types';
import { createApi, createEffect, createStore } from 'effector';

const initStore: TimeLineStore = {
  selectedYear: 0,
  baseYear: 2022,
  finalYear: 2035,
};

export const $TimeLine = createStore<TimeLineStore>(initStore);

export const TimeLineApi = createApi($TimeLine, {
  setTimeLine: (_, payload) => ({
    selectedYear: 0,
    baseYear: payload.timelineStart,
    finalYear: payload.timelineEnd,
  }),
  reset: () => initStore,
});

export const getTimelineYearsFx = createEffect<void, IQuery['getTimeline']>();

type TimeLineStore = {
  selectedYear: number;
  baseYear: number;
  finalYear: number;
};
