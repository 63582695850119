import { sample } from 'effector';

import { createTrafficCongestionSource } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

import {
  EPipeTileLayer,
  PipeTileLayer,
} from '@utils/map/hooks/useInitTileLayer';

import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';
import {
  initTileSources,
  pipeTrafficCongestionTileLayer,
} from '@widgets/widget-soceco-indicators/stores/map';
import { LayersVisibleApi } from '@widgets/widget-soceco-indicators/stores/map/layersVisible';
import { $Settings } from '@widgets/widget-soceco-indicators/stores/settings';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload => Object.keys(payload).includes(SOCECO_TABLE_IDS.TABLE_2_2),
  fn: payload => {
    return {
      action: EPipeTileLayer.setVisible,
      payload: payload[SOCECO_TABLE_IDS.TABLE_2_2],
    } as PipeTileLayer;
  },
  target: pipeTrafficCongestionTileLayer,
});

// Установка источника тайлов
sample({
  clock: initTileSources,
  source: { Settings: $Settings },
  filter: ({ Settings }) =>
    !!Settings.projectId &&
    !!Settings.scenarioId &&
    !!Settings.timeSlots.length &&
    !!Settings.vehicles?.length &&
    !!Settings.baseYear,
  fn: ({ Settings }) => {
    const timeSlot = Settings.timeSlots.find(
      item => item.name === 'будний день, утренний пик',
    );
    if (!timeSlot) throw new Error('Не удалось найти timeSlot');
    const vehicle = Settings.vehicles!.find(item => item.vehicleId === 1);
    if (!vehicle) throw new Error('Не удалось найти vehicle');
    return {
      action: EPipeTileLayer.changeSource,
      payload: createTrafficCongestionSource(
        vehicle.id,
        Settings.projectId!,
        Settings.scenarioId!,
        timeSlot.id,
        Settings.baseYear!,
      ),
    } as PipeTileLayer;
  },
  target: pipeTrafficCongestionTileLayer,
});
