import { EAgeGroups } from '@features/pt-forecast-new/constants/eAgeGroups';

import { palette } from '@system/theme/palette';

export const getChartColor = (group: EAgeGroups) => {
  switch (group) {
    case EAgeGroups.underWorkingAge:
      return palette.customs.auto;
    case EAgeGroups.workingAge:
      return palette.customs.bus;
    case EAgeGroups.overWorkingAge:
      return palette.customs.trainDay;
    default:
      return 'black';
  }
};
