import { createBorderStyle } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/createBorderStyle';
import { SetFeatureStyleProps } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/getFeatureStyle';
import { Stroke, Style } from 'ol/style';

export type GetAirlineStyleArgs = Pick<
  SetFeatureStyleProps,
  'active' | 'width'
>;

export const getAirlineStyle = ({ active, width }: GetAirlineStyleArgs) => [
  createBorderStyle({ active, featureWidth: width || 3 }),
  new Style({
    stroke: new Stroke({
      lineCap: 'square',
      color: '#0065DC',
      width: width || 3,
    }),
  }),
];
