import { apiClient } from '@api/client';
import { IQuery } from '@api/gql/types';
import { gql } from '@apollo/client';

export const queryGetTimeline = async () => {
  const result = await apiClient.query<Pick<IQuery, 'getTimeline'>>({
    query: gql`
      query QueryGetTimeline {
        getTimeline {
          timelineEnd
          timelineStart
        }
      }
    `,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result?.data?.getTimeline;
};
