import {
  IHubShareOfEntranceExitDirectionChoices,
  Scalars,
} from '@api/gql/tpu-types';
import { useUnit } from 'effector-react';
import { useForm } from 'react-hook-form';

import { Divider, Stack } from '@mui/material';

import { FormActions } from '@features/tpu-simulation-model/components/FormAttributes/FormActions';
import { Input } from '@features/tpu-simulation-model/components/FormAttributes/Input';
import { ObjectPosition } from '@features/tpu-simulation-model/components/FormAttributes/ObjectPosition';
import { RadioList as Radio } from '@features/tpu-simulation-model/components/FormAttributes/Radio';
import { SelectWithInput } from '@features/tpu-simulation-model/components/FormAttributes/SelectWithInput';
import { TimeRange } from '@features/tpu-simulation-model/components/FormAttributes/TimeRange';
import { Title } from '@features/tpu-simulation-model/components/FormAttributes/Title';
import { useFloors } from '@features/tpu-simulation-model/hooks/useFloors';
import { $ListOfExits } from '@features/tpu-simulation-model/store';
import {
  IExit,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';
import { getAttributeOptions } from '@features/tpu-simulation-model/utils/getAttributeOptions';

type Exit = {
  direction: IHubShareOfEntranceExitDirectionChoices;
  eid: Scalars['String']['output'];
  name: Scalars['String']['output'];
  share: Scalars['Float']['output'];
};

interface FormProps extends IExit {
  onUpdateAndClose: (arg: ObjectOfTheSimulationModel) => void;
  onDeleteAndClose: (arg: string) => void;
}

export const Exit = ({
  onUpdateAndClose,
  onDeleteAndClose,
  ...props
}: FormProps) => {
  const { handleSubmit, formState, control, setValue } = useForm<IExit>({
    values: { ...props, share: props.share * 100 },
  });
  const exits = useUnit($ListOfExits);
  const { selectedFloorIndex } = useFloors();

  const exitOptions =
    exits
      ?.filter((item): item is Exit => !!item.eid)
      ?.map(item => ({ value: item.eid, label: item.name })) ?? [];

  const onExitIdChange = (value?: string) => {
    if (value) {
      const selectedExit = exits?.find(({ eid }) => eid === value);
      selectedExit && setValue('share', selectedExit.share * 100);
    }
  };

  return (
    <Stack
      padding={3}
      gap={3}
      sx={{
        overflow: 'auto',
      }}
    >
      <Title title={'Выход'} />
      <SelectWithInput<IExit>
        control={control}
        name={'exitId'}
        title={'ID выхода'}
        placeholder={'Выберите id выхода'}
        options={exitOptions}
        value={
          exitOptions.find(({ value }) => value === props.exitId) ?? {
            value: props.exitId,
            label: props.exitId,
          }
        }
        onChange={onExitIdChange}
      />
      <Input<IExit>
        control={control}
        name={'share'}
        title={'Доля пассажиров, %'}
        type={'number'}
      />
      <Radio<IExit>
        control={control}
        name={'status'}
        title={'Статус'}
        options={getAttributeOptions('status')}
      />
      <TimeRange<IExit>
        control={control}
        nameTime1={'openFrom'}
        nameTime2={'openUntil'}
      />
      <Divider />
      <ObjectPosition
        point={props.point}
        floor={selectedFloorIndex + 1}
      />
      <Divider />
      <FormActions
        onSave={handleSubmit(data => {
          const newFormData = { ...data };
          newFormData.share = newFormData.share ? newFormData.share / 100 : 0;
          newFormData.name =
            exitOptions.find(({ value }) => value === newFormData.exitId)
              ?.label ?? '';
          onUpdateAndClose(newFormData);
        })}
        onDelete={() => onDeleteAndClose(props?.uuid)}
        disabled={!formState.isValid}
      />
    </Stack>
  );
};
