import { createApi, createStore } from 'effector';

export const $UI = createStore<UIState>({
  filter: true,
  inspector: false,
  mapLegend: false,
  isAgNetworkViewMode: false,
  isShowFrame: false,
});

export const UIApi = createApi($UI, {
  toggleFilter: store => ({
    ...store,
    filter: !store.filter,
  }),
  toggleInspector: store => ({
    ...store,
    inspector: !store.inspector,
  }),
  toggleMapLegend: store => ({
    ...store,
    mapLegend: !store.mapLegend,
  }),
  setFilter: (store, payload: boolean) => ({
    ...store,
    filter: payload,
  }),
  setInspector: (store, payload: boolean) => ({
    ...store,
    inspector: payload,
  }),
  setMapLegend: (store, payload: boolean) => ({
    ...store,
    mapLegend: payload,
  }),
  setAgNetworkViewMode: (store, payload: boolean) => ({
    ...store,
    isAgNetworkViewMode: payload,
  }),
  setShowFrame: (store, payload: boolean) => ({
    ...store,
    isShowFrame: payload,
  }),
});

type UIState = {
  filter: boolean;
  inspector: boolean;
  mapLegend: boolean;
  isAgNetworkViewMode: boolean;
  isShowFrame: boolean;
};
