import { useMemo } from 'react';

import { InspectorCircleIcon } from '@components/InspectorCircleIcon/InspectorCircleIcon';
import { MapFilterField } from '@components/MapFilters';

import { AccordionInspector } from '@features/passenger-traffic/components/Inspector/AccordionInspector';
import { ContainerTransport } from '@features/passenger-traffic/components/Inspector/ContainerTransport';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { DataVehicleType } from '@features/passenger-traffic/stores/inspector/RouteNetwork';

import { endingCountingCouple } from '@utils/dataForInspector';

type AviaInspectorProps = {
  layout: EMapFeatureLayout | null;
  filter: MapFilterField;
  aviaData: DataVehicleType;
};

export const AviaInspector = (props: AviaInspectorProps) => {
  const { layout, filter, aviaData } = props;

  const layoutContent = useMemo(() => {
    return (
      <>
        {filter.avia && (
          <ContainerTransport
            title="Самолет"
            icon={{
              borderColor: 'avia',
              backgroundColor: 'avia',
            }}
            price={aviaData?.price}
            time={aviaData?.duration}
            isExists={
              !!(aviaData?.price || aviaData?.duration || aviaData?.frequency)
            }
          >
            {aviaData?.frequency ? (
              <>
                {`${Math.ceil(aviaData?.frequency)} пар${endingCountingCouple(
                  Math.ceil(aviaData?.frequency),
                )} рейсов в сутки`}
              </>
            ) : (
              'н/д'
            )}
          </ContainerTransport>
        )}
      </>
    );
  }, [aviaData?.duration, aviaData?.frequency, aviaData?.price, filter.avia]);

  const renderAviaInspector = useMemo(() => {
    if (layout === null && filter.avia) {
      return (
        <AccordionInspector
          icon={
            <InspectorCircleIcon
              borderColor={'avia'}
              backgroundColor={'avia'}
            />
          }
          title={'Авиатранспорт'}
        >
          {layoutContent}
        </AccordionInspector>
      );
    } else if (layout === EMapFeatureLayout.airline && filter.avia) {
      return layoutContent;
    }
  }, [filter.avia, layout, layoutContent]);

  return renderAviaInspector;
};
