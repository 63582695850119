import { useUnit } from 'effector-react';
import { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { Stack } from '@mui/material';

import { $ComputedData } from '@features/pt-forecast-new/stores/computedData';

export const UnevennessChartContainer = () => {
  const { unevennessSummary } = useUnit($ComputedData);

  const data = useMemo(() => {
    return [
      {
        name: 'Январь',
        uv: (unevennessSummary[0] || 0) / 1000,
      },
      {
        name: 'Февраль',
        uv: (unevennessSummary[1] || 0) / 1000,
      },
      {
        name: 'Март',
        uv: (unevennessSummary[2] || 0) / 1000,
      },
      {
        name: 'Апрель',
        uv: (unevennessSummary[3] || 0) / 1000,
      },
      {
        name: 'Май',
        uv: (unevennessSummary[4] || 0) / 1000,
      },
      {
        name: 'Июнь',
        uv: (unevennessSummary[5] || 0) / 1000,
      },
      {
        name: 'Июль',
        uv: (unevennessSummary[6] || 0) / 1000,
      },
      {
        name: 'Август',
        uv: (unevennessSummary[7] || 0) / 1000,
      },
      {
        name: 'Сентябрь',
        uv: (unevennessSummary[8] || 0) / 1000,
      },
      {
        name: 'Октябрь',
        uv: (unevennessSummary[9] || 0) / 1000,
      },
      {
        name: 'Ноябрь',
        uv: (unevennessSummary[10] || 0) / 1000,
      },
      {
        name: 'Декабрь',
        uv: (unevennessSummary[11] || 0) / 1000,
      },
    ];
  }, [unevennessSummary]);

  return (
    <Stack direction="row">
      <ResponsiveContainer
        width="100%"
        height={300}
      >
        <BarChart data={data}>
          <CartesianGrid vertical={false} />
          <YAxis
            fontSize={12}
            dataKey="uv"
          >
            <Label
              value="Пассажиропоток, тыс."
              position="insideLeft"
              angle={-90}
              fontSize={12}
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <XAxis
            fontSize={12}
            dataKey="name"
            angle={75}
            dy={2}
            dx={5}
            height={60}
            textAnchor={'start'}
          />
          <Bar
            dataKey="uv"
            fill="#E21A1A"
            barSize={6}
            radius={[10, 10, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  );
};
