import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import { Box, Typography } from '@mui/material';

export const CustomChartTooltip = ({
  unit,
  active,
  payload,
  valueFormatter,
  isPieChart,
}: TooltipProps<ValueType, NameType> & {
  unit?: string;
  isPieChart?: boolean;
  valueFormatter?: (value: number) => string;
}) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          padding: '8px 14px',
          backgroundColor: '#fff',
          borderRadius: '5px',
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            color: '#606060',
          }}
        >
          {`${
            isPieChart ? payload[0].payload.name : payload[0].payload.range
          }: ${
            valueFormatter
              ? valueFormatter(Number(payload[0].value))
              : payload[0].value
          } `}
          {unit}
        </Typography>
      </Box>
    );
  }

  return null;
};
