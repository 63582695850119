import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const UploadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <svg
        width="16"
        height="16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.667 2.667h7.11M8.222 13.333V5.037M11.185 8 8.222 5.037 5.26 8"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
