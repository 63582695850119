import {
  Box,
  Button,
  Tooltip,
  Typography,
  iconButtonClasses,
} from '@mui/material';

import { BasketIcon, DownloadIcon, ErrorMiniIcon } from '@components/icons';

interface IError {
  disabled: boolean;
  error: string | null;
  onDownload: () => void;
  onDelete: () => void;
  onUpload: () => void;
}

export const Error = (props: IError) => {
  const { disabled, error, onDownload, onDelete, onUpload } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        columnGap: 0.5,
      }}
    >
      <ErrorMiniIcon
        sx={{
          fontSize: theme => theme.typography.pxToRem(21),
          color: theme => theme.palette.secondary.main,
        }}
      />

      <Tooltip
        title={error}
        placement="top"
      >
        <Typography
          sx={{
            fontSize: theme => theme.typography.pxToRem(12),
            color: theme => theme.palette.secondary.main,
          }}
        >
          Ошибка
        </Typography>
      </Tooltip>

      <Tooltip
        title={'Загрузить'}
        placement="top"
      >
        <Button
          variant="text"
          sx={{
            height: '21px',
            minWidth: '15px',
          }}
          className={disabled ? iconButtonClasses.disabled : ''}
          onClick={onUpload}
        >
          <DownloadIcon
            sx={{
              transform: 'rotate(180deg)',
              cursor: 'pointer',
              fontSize: theme => theme.typography.pxToRem(21),
              ['&:hover']: {
                color: theme => theme.palette.text.secondary,
              },
            }}
          />
        </Button>
      </Tooltip>

      <Tooltip
        title={'Скачать'}
        placement="top"
      >
        <Button
          variant="text"
          disabled
          sx={{
            height: '18px',
            minWidth: '15px',
          }}
          className={
            disabled ? iconButtonClasses.disabled : iconButtonClasses.disabled
          }
          onClick={onDownload}
        >
          <DownloadIcon
            sx={{
              cursor: 'pointer',
              fontSize: theme => theme.typography.pxToRem(21),
              ['&:hover']: {
                color: theme => theme.palette.text.secondary,
              },
            }}
          />
        </Button>
      </Tooltip>

      <Tooltip
        title={'Удалить'}
        placement="top"
      >
        <Button
          variant="text"
          sx={{
            height: '18px',
            minWidth: '15px',
          }}
          className={disabled ? iconButtonClasses.disabled : ''}
          onClick={onDelete}
        >
          <BasketIcon
            sx={{
              cursor: 'pointer',
              fontSize: theme => theme.typography.pxToRem(21),
              ['&:hover']: {
                color: theme => theme.palette.text.secondary,
              },
            }}
          />
        </Button>
      </Tooltip>
    </Box>
  );
};
