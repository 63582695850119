import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const ShareIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={20}
      height={20}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12.504 8.522v-2.77a.75.75 0 0 1 1.187-.61l.082.068 5.996 5.752c.28.268.305.7.077.997l-.077.085-5.996 5.754a.75.75 0 0 1-1.262-.434l-.007-.107V14.53l-.344.03c-2.4.25-4.701 1.331-6.917 3.261-.519.453-1.322.025-1.237-.659.665-5.32 3.448-8.253 8.198-8.621l.3-.02Zm1.5-1.011v1.742a.75.75 0 0 1-.75.75c-3.875 0-6.276 1.676-7.314 5.158l-.079.279.353-.237c2.237-1.462 4.587-2.2 7.04-2.2a.75.75 0 0 1 .743.649l.007.102v1.743l4.162-3.994-4.162-3.992Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
