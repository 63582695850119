import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { Box, Checkbox, Typography } from '@mui/material';

interface AttributeProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  title: string;
  options: string[];
}

export function Checkboxes<T extends FieldValues>({
  control,
  name,
  title,
  options,
}: AttributeProps<T>) {
  return (
    <Box>
      <Typography mb={1}>{title}</Typography>

      {options.map(item => (
        <Controller
          name={name}
          key={item}
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  value={item}
                  inputProps={{
                    'aria-label': item,
                  }}
                  checked={value.includes(item)}
                  onChange={() => {
                    let data = [...value];
                    if (!value.includes(item)) {
                      data = [...value, item];
                    }
                    if (value.includes(item)) {
                      data = [...value.filter((i: string) => i !== item)];
                    }
                    onChange(data);
                  }}
                />
                <Typography>{item}</Typography>
              </Box>
            );
          }}
        />
      ))}
    </Box>
  );
}
