import type { Map as MapOl } from 'ol';
import { useCallback, useState } from 'react';

import { Legend } from './Legend';
import { MapButton } from './MapButton';

import { Button } from '@mui/material';

import { EFilterMapLayout } from '@components/MapFilters/interface';
import { LayersIcon } from '@components/icons/Layers';
import { MinusIcon } from '@components/icons/Minus';
import { PlusIcon } from '@components/icons/Plus';

interface MapControlsProps {
  map: MapOl | null | undefined;
  layout: EFilterMapLayout;
  isVisibleInspector?: boolean;
}

const MapControls = (props: MapControlsProps) => {
  const { map, layout, isVisibleInspector } = props;

  const [isVisibleLegend, setIsVisibleLegend] = useState<boolean>(false);

  return (
    <>
      <Button
        variant={'outlined'}
        sx={{
          marginBottom: theme => theme.typography.pxToRem(8),
          border: theme => '1px solid ' + theme.palette.text.disabled,
          gap: 1,
          position: 'absolute',
          top: theme => theme.typography.pxToRem(16),
          right: theme =>
            theme.typography.pxToRem(isVisibleInspector ? 500 + 24 : 24),
          zIndex: 1,
          '&:hover': {
            backgroundColor: theme => theme.palette.common.white,
            border: theme => '1px solid ' + theme.palette.text.disabled,
            boxShadow:
              '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
          },
        }}
        onClick={() => setIsVisibleLegend(prev => !prev)}
      >
        <LayersIcon />
        Условные обозначения
      </Button>

      <MapButton
        sx={{
          position: 'absolute',
          top: theme => theme.typography.pxToRem(16 + 44 + 16),
          right: theme =>
            theme.typography.pxToRem(isVisibleInspector ? 500 + 24 : 24),
          zIndex: 1,
        }}
        onClick={useCallback(() => {
          if (map) {
            const currentZoom = map.getView().getZoom();
            if (currentZoom) {
              map.getView().animate({
                zoom: currentZoom + 1,
                duration: 250,
              });
            }
          }
        }, [map])}
      >
        <PlusIcon />
      </MapButton>

      <MapButton
        sx={{
          position: 'absolute',
          top: theme => theme.typography.pxToRem(16 + 88 + 24),
          right: theme =>
            theme.typography.pxToRem(isVisibleInspector ? 500 + 24 : 24),
          zIndex: 1,
        }}
        onClick={useCallback(() => {
          if (map) {
            const currentZoom = map.getView().getZoom();
            if (currentZoom) {
              map.getView().animate({
                zoom: currentZoom - 1,
                duration: 250,
              });
            }
          }
        }, [map])}
      >
        <MinusIcon />
      </MapButton>

      {isVisibleLegend && (
        <Legend
          layout={layout}
          isVisibleInspector={isVisibleInspector}
        />
      )}
    </>
  );
};

export default MapControls;
