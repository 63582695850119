import { useUnit } from 'effector-react';
import { useMemo } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { EMapFeatureLayout } from '@constants/map';

import { GraphInspector } from '@features/ag-project/components/Inspector/GraphInspector';
import { RailwayStationsInspector } from '@features/ag-project/components/Inspector/RailwayStationsInspector';
import { TransportDistrictInspector } from '@features/ag-project/components/Inspector/TransportDistrictInspector';
import { $Inspector } from '@features/ag-project/stores/inspector';
import { $InfrastructureInspector } from '@features/ag-project/stores/inspector/infrastructure';
import { $UI, UIApi } from '@features/ag-project/stores/ui';

const InspectorContainerMuiStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '0',
  right: '0',
  zIndex: '1',
  background: theme => theme.palette.background.default,
  '& > *': {
    borderTop: '1px solid #EBECEF',
    padding: '32px 12px 32px 44px',
    boxShadow: 'rgba(0, 0, 0, 0.1) -5px 4px 2px 0px',
    width: '400px',
    maxWidth: '400px',
    overflowY: 'auto',
  },
};

export const InspectorContainer = () => {
  const { inspector } = useUnit($UI);
  const { layout } = useUnit($Inspector);
  const { graphs, railwayStation, transportDistrict } = useUnit(
    $InfrastructureInspector,
  );
  const { toggleInspector } = useUnit(UIApi);

  const InspectorLayout = useMemo(() => {
    switch (layout) {
      case EMapFeatureLayout.graphTrolleybus:
      case EMapFeatureLayout.graphTram:
      case EMapFeatureLayout.graphPedestrain:
      case EMapFeatureLayout.graphMonoRailWay:
      case EMapFeatureLayout.graphMetro:
      case EMapFeatureLayout.graphFunicular:
      case EMapFeatureLayout.graphBus:
      case EMapFeatureLayout.graphAvia:
      case EMapFeatureLayout.graphSuburbanRailway:
      case EMapFeatureLayout.graphAuto:
      case EMapFeatureLayout.graphRopeWay:
      case EMapFeatureLayout.graphWaterWay:
        return (
          <GraphInspector
            onClose={toggleInspector}
            fields={graphs}
          />
        );
      case EMapFeatureLayout.agRailwayStations:
        return (
          <RailwayStationsInspector
            onClose={toggleInspector}
            fields={railwayStation}
          />
        );
      case EMapFeatureLayout.transportDistricts:
        return (
          <TransportDistrictInspector
            onClose={toggleInspector}
            fields={transportDistrict}
          />
        );
    }
  }, [graphs, layout, railwayStation, toggleInspector, transportDistrict]);

  return (
    inspector && (
      <Box sx={InspectorContainerMuiStyle}>
        <AutoFullHeight>{InspectorLayout}</AutoFullHeight>
      </Box>
    )
  );
};
