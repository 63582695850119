import { Box, IconButton, Typography } from '@mui/material';

import { MapFilterField } from '@components/MapFilters';
import { ChevronRightIcon } from '@components/icons';

import { AutoInspector } from '@features/passenger-traffic/components/Inspector/layouts/RouteNetwork/AutoInspector';
import { AviaInspector } from '@features/passenger-traffic/components/Inspector/layouts/RouteNetwork/AviaInspector';
import { RailwayInspector } from '@features/passenger-traffic/components/Inspector/layouts/RouteNetwork/RailwayInspector';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { RouteNetworkInspectorType } from '@features/passenger-traffic/stores/inspector/RouteNetwork';

interface RouteNetworkInspectorProps {
  layout: EMapFeatureLayout | null;
  pointFrom: string | undefined;
  pointTo: string | undefined;
  filter: MapFilterField;
  routeNetworkInspector: RouteNetworkInspectorType;
  onClose?(): void;
}

export const RouteNetworkInspector = (props: RouteNetworkInspectorProps) => {
  const { layout, pointFrom, pointTo, filter, routeNetworkInspector, onClose } =
    props;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 3,
        }}
      >
        <IconButton
          sx={{
            width: '32px',
            height: '32px',
            backgroundColor: 'primary.main',
            borderRadius: 1,
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={onClose}
        >
          <ChevronRightIcon />
        </IconButton>

        <Typography variant="subtitle2">
          {pointFrom} — {pointTo}
        </Typography>
      </Box>

      <RailwayInspector
        layout={layout}
        filter={filter}
        nightTrainsData={routeNetworkInspector.nightTrain}
        dayTrainsData={routeNetworkInspector.dayTrain}
        suburbanTrainsData={routeNetworkInspector.suburbanTrain}
        nightTrains={routeNetworkInspector.nightTrains}
        dayTrains={routeNetworkInspector.dayTrains}
        suburbanTrains={routeNetworkInspector.suburbanTrains}
      />

      <AviaInspector
        layout={layout}
        filter={filter}
        aviaData={routeNetworkInspector.avia}
      />

      <AutoInspector
        layout={layout}
        filter={filter}
        busData={routeNetworkInspector.bus}
        autoData={routeNetworkInspector.auto}
      />
    </>
  );
};
