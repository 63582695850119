import { Button, Typography } from '@mui/material';
import { isNumber } from '@mui/x-data-grid/internals';

import { ArrowRightIcon } from '@components/icons';

interface PlanErrorsProps {
  numberOfErrors: number | null;
  onClick: () => void;
}

export const PlanErrors = ({ numberOfErrors, onClick }: PlanErrorsProps) => {
  if (!isNumber(numberOfErrors)) return null;

  return (
    <Button
      onClick={onClick}
      sx={{
        pointerEvents: 'auto',
        minWidth: '202px',
        width: 'fit-content',
        padding: '16px 12px',
        backgroundColor: theme => theme.palette.background.paper,
        color: theme => theme.palette.text.primary,
        borderRadius: '4px',
        boxShadow: '0px 0px 8px 0px #00000026',
        height: '48px',

        '&:hover': {
          backgroundColor: theme => theme.palette.customs.bgHover,
        },
      }}
    >
      <Typography
        sx={{
          fontSize: 'inherit',
          lineHeight: 'inherit',
        }}
      >
        Ошибок в связях:{' '}
        <Typography
          component={'span'}
          fontWeight={'bold'}
          sx={{
            fontSize: 'inherit',
            lineHeight: 'inherit',
            color: theme => theme.palette.secondary.main,
          }}
        >
          {numberOfErrors}
        </Typography>
      </Typography>
      <ArrowRightIcon sx={{ marginLeft: '8px' }} />
    </Button>
  );
};
