import { VehicleTypeCode } from '@features/passenger-traffic/stores/inspector/types';
import { ComputingResults } from '@features/pt-constructor/store';

export const mockComputingResults: ComputingResults[] = [
  {
    year: 2023,
    [VehicleTypeCode.dayTrain]: 3,
    [VehicleTypeCode.nightTrain]: 7,
    [VehicleTypeCode.avia]: 8,
    [VehicleTypeCode.bus]: 11,
    [VehicleTypeCode.auto]: 13,
  },
  {
    year: 2024,
    [VehicleTypeCode.dayTrain]: 3,
    [VehicleTypeCode.nightTrain]: 7,
    [VehicleTypeCode.avia]: 8,
    [VehicleTypeCode.bus]: 11,
    [VehicleTypeCode.auto]: 13,
  },
  {
    year: 2025,
    [VehicleTypeCode.dayTrain]: 3,
    [VehicleTypeCode.nightTrain]: 7,
    [VehicleTypeCode.avia]: 8,
    [VehicleTypeCode.bus]: 11,
    [VehicleTypeCode.auto]: 13,
  },
  {
    year: 2026,
    [VehicleTypeCode.dayTrain]: 3,
    [VehicleTypeCode.nightTrain]: 7,
    [VehicleTypeCode.avia]: 8,
    [VehicleTypeCode.bus]: 11,
    [VehicleTypeCode.auto]: 13,
  },
  {
    year: 2027,
    [VehicleTypeCode.dayTrain]: 3,
    [VehicleTypeCode.nightTrain]: 7,
    [VehicleTypeCode.avia]: 8,
    [VehicleTypeCode.bus]: 11,
    [VehicleTypeCode.auto]: 13,
  },
  {
    year: 2028,
    [VehicleTypeCode.dayTrain]: 3,
    [VehicleTypeCode.nightTrain]: 7,
    [VehicleTypeCode.avia]: 8,
    [VehicleTypeCode.bus]: 11,
    [VehicleTypeCode.auto]: 13,
  },
  {
    year: 2029,
    [VehicleTypeCode.dayTrain]: 3,
    [VehicleTypeCode.nightTrain]: 7,
    [VehicleTypeCode.avia]: 8,
    [VehicleTypeCode.bus]: 11,
    [VehicleTypeCode.auto]: 13,
  },
];
