import { createEffect, createEvent, createStore } from 'effector';

interface ReportDataType {
  isExist: boolean;
  url: null | string;
  name: null | string;
}

export interface DownloadedReportData {
  url: string;
  name: string;
}

export const $Report = createStore<ReportDataType>({
  isExist: false,
  url: null,
  name: null,
});

export const resetReportStoreFn = createEvent<void>();

export const setIsReportExistFn = createEvent<boolean>();

export const triggerDownloadReportFn = createEvent();

export const downloadReportOnClientFx = createEffect<
  DownloadedReportData,
  void
>();

export const downloadReportFormServerFx = createEffect<
  string,
  DownloadedReportData | null
>();
