import { useUnit } from 'effector-react';

import { InfoCannotDeleteCustomRailwayStation } from '@features/pt-forecast-new/components/Modals/InfoCannotDeleteCustomRailwayStation';
import { $Modals, ModalsApi } from '@features/pt-forecast-new/stores/modals';

export const ModalsContainer = () => {
  const { infoCannotDeleteCustomRailwayStation } = useUnit($Modals);
  const { setVisibleCannotDeleteCustomRailwayStation } = useUnit(ModalsApi);

  return (
    <>
      {infoCannotDeleteCustomRailwayStation && (
        <InfoCannotDeleteCustomRailwayStation
          onClose={setVisibleCannotDeleteCustomRailwayStation}
        />
      )}
    </>
  );
};

export type ModalsProps = {
  onClose: (a: boolean) => void;
};
