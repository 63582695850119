import {
  IQuery,
  IQueryGetComputedDirectionArgs,
  IQueryGetTransportRegionByIdArgs,
} from '@api/gql/types';
import { createApi, createEffect, createEvent, createStore } from 'effector';

import { pointDirection } from '@utils/map/styles/agTransportFlowDistrictsVectorStyle';

const initState: VectorGeometryStore = {
  allAirports: [],
  busStations: [],
  railwayStations: [],
  transportRegions: [],
  directionPoints: {
    from: null,
    to: null,
  },
  directionPassFlow: {
    highway: [],
    railway: [],
    airline: false,
    airports: {
      from: [],
      to: [],
      intersection: [],
    },
    regions: {
      from: null,
      to: null,
      intersection: [],
    },
    busStations: {
      from: [],
      to: [],
      intersection: [],
    },
    railwayStations: {
      from: [],
      to: [],
      intersection: [],
    },
  },
};

export const $VectorGeometry = createStore<VectorGeometryStore>(initState);

export const VectorGeometryApi = createApi($VectorGeometry, {
  setAllAirports: (store, payload: PointGeometryItem[]) => ({
    ...store,
    allAirports: payload,
  }),
  setBusStations: (store, payload: PointGeometryItem[]) => ({
    ...store,
    busStations: payload,
  }),
  setRailwayStations: (store, payload: PointGeometryItem[]) => ({
    ...store,
    railwayStations: payload,
  }),
  setTransportRegions: (store, payload: MultiPolygonGeometryItem[]) => ({
    ...store,
    transportRegions: payload,
  }),
  setDirectionPoints: (
    store,
    payload: Partial<VectorGeometryStore['directionPoints']>,
  ) => ({
    ...store,
    directionPoints: {
      ...store.directionPoints,
      ...payload,
    },
  }),
  setDirectionPassFlowHighway: (
    store,
    payload: ExtendedMultiLineStringGeometryItem[] | null,
  ) => ({
    ...store,
    directionPassFlow: {
      ...store.directionPassFlow,
      highway: payload || [],
    },
  }),
  setDirectionPassFlowRailway: (
    store,
    payload: ExtendedMultiLineStringGeometryItem[] | null,
  ) => ({
    ...store,
    directionPassFlow: {
      ...store.directionPassFlow,
      railway: payload || [],
    },
  }),
  setDirectionPassFlowRegionsFrom: (
    store,
    payload: MultiPolygonGeometryItem | null,
  ) => ({
    ...store,
    directionPassFlow: {
      ...store.directionPassFlow,
      regions: {
        ...store.directionPassFlow.regions,
        from: payload,
      },
    },
  }),
  setDirectionPassFlowRegionsTo: (
    store,
    payload: MultiPolygonGeometryItem | null,
  ) => ({
    ...store,
    directionPassFlow: {
      ...store.directionPassFlow,
      regions: {
        ...store.directionPassFlow.regions,
        to: payload,
      },
    },
  }),
  setDirectionPassFlowRegionsIntersection: (
    store,
    payload: MultiPolygonGeometryItem[] | null,
  ) => ({
    ...store,
    directionPassFlow: {
      ...store.directionPassFlow,
      regions: {
        ...store.directionPassFlow.regions,
        intersection: payload || [],
      },
    },
  }),
  setDirectionPassFlowRailwayStationsFrom: (
    store,
    payload: PointGeometryItem[] | null,
  ) => ({
    ...store,
    directionPassFlow: {
      ...store.directionPassFlow,
      railwayStations: {
        ...store.directionPassFlow.railwayStations,
        from: payload || [],
      },
    },
  }),
  setDirectionPassFlowRailwayStationsTo: (
    store,
    payload: PointGeometryItem[] | null,
  ) => ({
    ...store,
    directionPassFlow: {
      ...store.directionPassFlow,
      railwayStations: {
        ...store.directionPassFlow.railwayStations,
        to: payload || [],
      },
    },
  }),
  setDirectionPassFlowRailwayStationsIntersection: (
    store,
    payload: PointGeometryItem[] | null,
  ) => ({
    ...store,
    directionPassFlow: {
      ...store.directionPassFlow,
      railwayStations: {
        ...store.directionPassFlow.railwayStations,
        intersection: payload || [],
      },
    },
  }),
  setDirectionPassFlowBusStationsFrom: (
    store,
    payload: ExtendedPointGeometryItem[] | null,
  ) => ({
    ...store,
    directionPassFlow: {
      ...store.directionPassFlow,
      busStations: {
        ...store.directionPassFlow.busStations,
        from: payload || [],
      },
    },
  }),
  setDirectionPassFlowBusStationsTo: (
    store,
    payload: ExtendedPointGeometryItem[] | null,
  ) => ({
    ...store,
    directionPassFlow: {
      ...store.directionPassFlow,
      busStations: {
        ...store.directionPassFlow.busStations,
        to: payload || [],
      },
    },
  }),
  setDirectionPassFlowBusStationsIntersection: (
    store,
    payload: ExtendedPointGeometryItem[] | null,
  ) => ({
    ...store,
    directionPassFlow: {
      ...store.directionPassFlow,
      busStations: {
        ...store.directionPassFlow.busStations,
        intersection: payload || [],
      },
    },
  }),
  setDirectionPassFlowAirportsFrom: (
    store,
    payload: ExtendedPointGeometryItem[] | null,
  ) => ({
    ...store,
    directionPassFlow: {
      ...store.directionPassFlow,
      airports: {
        ...store.directionPassFlow.airports,
        from: payload || [],
      },
    },
  }),
  setDirectionPassFlowAirportsTo: (
    store,
    payload: ExtendedPointGeometryItem[] | null,
  ) => ({
    ...store,
    directionPassFlow: {
      ...store.directionPassFlow,
      airports: {
        ...store.directionPassFlow.airports,
        to: payload || [],
      },
    },
  }),
  setDirectionPassFlowAirportsIntersection: (
    store,
    payload: ExtendedPointGeometryItem[] | null,
  ) => ({
    ...store,
    directionPassFlow: {
      ...store.directionPassFlow,
      airports: {
        ...store.directionPassFlow.airports,
        intersection: payload || [],
      },
    },
  }),
  reset: () => initState,
});

export const VectorGeometryEvents = {
  fetchAirports: createEvent(),
  fetchBusStations: createEvent(),
  fetchRailwayStations: createEvent(),
  fetchTransportRegions: createEvent(),
  fetchDirectionPoints: createEvent(),
};

// Запрос всех эропортов
export const getAirportsFx = createEffect<void, IQuery['airport']>();

// Запрос всех автобусных остановок
export const getBusStationsFx = createEffect<void, IQuery['busStation']>();

// Запрос всех жд станций
export const getRailwayStationsFx = createEffect<
  void,
  IQuery['railwayStation']
>();

// запрос всех транспортных регионов в сценарии
export const getTransportRegionsFx = createEffect<
  void,
  IQuery['transportRegion']
>();

// Запрос на получение координат для поинта по клику
export const getTransportRegionByIdCenterFx = createEffect<
  IQueryGetTransportRegionByIdExtendedArgs,
  IQuery['getTransportRegionById']
>();

// Запрос на получение геометрии транспортного региона и всей внутренней геометрии
export const getTransportRegionByIdIncludedGeometryFx = createEffect<
  IQueryGetTransportRegionByIdExtendedArgs,
  IQuery['getTransportRegionById']
>();

// Запрос на получение геометрии жд в корреспонденции
export const getComputedDirectionRailwayFx = createEffect<
  IQueryGetComputedDirectionArgs,
  IQuery['getComputedDirection']
>();

// Запрос на получение геометрии жд в корреспонденции
export const getComputedDirectionHighwayFx = createEffect<
  IQueryGetComputedDirectionArgs,
  IQuery['getComputedDirection']
>();

// Запрос на получение геометрии транспортных регионов через которые проходит корреспонденция
export const getComputedDirectionTransportRegionsIntersectionFx = createEffect<
  IQueryGetComputedDirectionArgs,
  IQuery['getComputedDirection']
>();

// Запрос на получение геометрии жд станций через которые проходит корреспонденция
export const getComputedDirectionRailwayStationsIntersectionFx = createEffect<
  IQueryGetComputedDirectionArgs,
  IQuery['getComputedDirection']
>();

export type IQueryGetTransportRegionByIdExtendedArgs =
  IQueryGetTransportRegionByIdArgs & {
    direction: pointDirection;
  };

export type PointGeometryItem = {
  id: string;
  coordinates: number[];
};

export type LineStringGeometryItem = {
  id: string;
  coordinates: number[][];
};

export type MultiLineStringGeometryItem = {
  id: string;
  coordinates: number[][][];
};

export type MultiPolygonGeometryItem = {
  id: string;
  coordinates: number[][][][];
};

export type ExtendedMultiLineStringGeometryItem =
  MultiLineStringGeometryItem & {
    status: string;
    year: number;
  };

export type ExtendedLineStringGeometryItem = LineStringGeometryItem & {
  status: string;
  year: number;
};

export type ExtendedPointGeometryItem = PointGeometryItem & {
  year: number;
};

export type VectorGeometryStore = {
  /** Все аэропорты */
  allAirports: PointGeometryItem[];
  /** Все автобусные остановки */
  busStations: PointGeometryItem[];
  /** Все жд станции */
  railwayStations: PointGeometryItem[];
  /** Все транспортные регионы */
  transportRegions: MultiPolygonGeometryItem[];
  /** Геометрия АБ поинтов */
  directionPoints: {
    /** Поинт А */
    from: PointGeometryItem | null;
    /** Поинт Б */
    to: PointGeometryItem | null;
  };
  directionPassFlow: {
    /** Авто дороги в рамках корреспонденции */
    highway: ExtendedMultiLineStringGeometryItem[];
    /** Жд пути в рамках корреспонденции */
    railway: ExtendedMultiLineStringGeometryItem[];
    /** Авиа линия в рамках корреспонденции */
    airline: boolean;
    /** Транспортные регионы в рамках корреспонденции */
    regions: {
      from: MultiPolygonGeometryItem | null;
      to: MultiPolygonGeometryItem | null;
      intersection: MultiPolygonGeometryItem[];
    };
    /** ЖД станции в рамках корреспонденции */
    railwayStations: {
      from: PointGeometryItem[];
      to: PointGeometryItem[];
      intersection: PointGeometryItem[];
    };
    /** Автобусные остановки в рамках корреспонденции */
    busStations: {
      from: ExtendedPointGeometryItem[];
      to: ExtendedPointGeometryItem[];
      intersection: ExtendedPointGeometryItem[];
    };
    /** Аэропорты в рамках корреспонденции */
    airports: {
      from: ExtendedPointGeometryItem[];
      to: ExtendedPointGeometryItem[];
      intersection: ExtendedPointGeometryItem[];
    };
  };
};
