import { Icon, Style } from 'ol/style';

import PointBSVG from '@components/icons/PointB.svg';

export const getPointBStyle = () =>
  new Style({
    image: new Icon({
      src: PointBSVG,
    }),
  });
