import { IScenarioData } from '@features/tpu-calculation-scenarios-viewing/stores/scenarioId/scenarioId.store';

import { Box, Typography } from '@mui/material';

import { Point } from '@components/icons/Point';

interface ScenarioDataProps {
  data: IScenarioData | null;
}

export const ScenarioData = ({ data }: ScenarioDataProps) => {
  return (
    <Box
      sx={{
        mr: '24px',
        ml: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'auto',
        }}
      >
        <Point />
        <Typography
          variant={'subtitle2'}
          sx={{ width: 'calc(100vw / 4)', wordWrap: 'break-word' }}
        >
          {data?.projectName ?? 'Нет данных'}
        </Typography>
      </Box>

      <Box
        sx={{
          ml: '31px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        <Typography
          variant={'body2'}
          sx={{ width: 'calc(100vw / 4)', wordWrap: 'break-word' }}
        >
          {data?.scenarioName ?? 'Нет данных'}
        </Typography>
        <Typography variant={'body2'}>
          {data?.dateTime ?? 'Нет данных'}
        </Typography>
      </Box>
    </Box>
  );
};
