import { IDataFileStateEnum } from '@api/gql/tpu-types';

import { CheckCircle, Close, Report } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  Input,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { BasketIcon, DownloadIcon, UploadIcon } from '@components/icons';

interface ActionsProps {
  accept: string;
  isLoading?: boolean;
  status?: IDataFileStateEnum | null;
  percent?: number;
  errorText?: string | null;

  onDownloadFile: () => void;
  onCancel: () => void;
  onDelete: () => void;
  onFileChange: (_: File | undefined) => Promise<void>;
}

export const Actions = ({
  status,
  accept,
  isLoading,
  errorText,

  onDownloadFile,
  onDelete,
  onFileChange,
  onCancel,
}: ActionsProps) => {
  const buttonDelete = (
    <IconButton
      sx={{ padding: 'unset' }}
      color="primary"
      onClick={onDelete}
    >
      <BasketIcon sx={{ fontSize: theme => theme.typography.pxToRem(16) }} />
    </IconButton>
  );

  const buttonDownload = (
    <IconButton
      sx={{ padding: 'unset' }}
      color="primary"
      onClick={onDownloadFile}
    >
      <DownloadIcon sx={{ fontSize: theme => theme.typography.pxToRem(16) }} />
    </IconButton>
  );

  if (isLoading) {
    return (
      <Stack
        direction="row"
        display="flex"
        gap="4px"
        sx={{ alignItems: 'center' }}
      >
        <CircularProgress size={16} />
        <IconButton
          sx={{ padding: 'unset' }}
          color="primary"
          onClick={onCancel}
        >
          <Close
            sx={{
              fontSize: theme => theme.typography.pxToRem(16),
            }}
          />
        </IconButton>
      </Stack>
    );
  }

  switch (status) {
    case IDataFileStateEnum.Success:
      return (
        <Stack
          direction="row"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            color: theme => theme.palette.success.main,
          }}
        >
          <CheckCircle
            color="inherit"
            sx={{ fontSize: theme => theme.typography.pxToRem(16) }}
          />
          <Typography
            color="inherit"
            sx={{
              fontSize: theme => theme.typography.pxToRem(12),
              lineHeight: theme => theme.typography.pxToRem(16),
            }}
          >
            Успешно
          </Typography>
          {buttonDownload}

          {buttonDelete}
        </Stack>
      );
    case IDataFileStateEnum.Processing:
      return (
        <Stack
          direction="row"
          display="flex"
          gap="4px"
          sx={{ alignItems: 'center' }}
        >
          <Typography
            component="span"
            sx={{
              fontSize: theme => theme.typography.pxToRem(12),
              lineHeight: theme => theme.typography.pxToRem(16),
            }}
          >
            Обработка
          </Typography>

          {buttonDownload}
          {buttonDelete}
        </Stack>
      );
    case IDataFileStateEnum.Error:
      return (
        <Tooltip title={errorText}>
          <Typography
            color="secondary"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <Report
              color="inherit"
              sx={{ fontSize: theme => theme.typography.pxToRem(16) }}
            />
            <Typography
              component="span"
              color="inherit"
              fontSize="inherit"
              sx={{
                fontSize: theme => theme.typography.pxToRem(12),
                lineHeight: theme => theme.typography.pxToRem(16),
              }}
            >
              Ошибка
            </Typography>
            {buttonDownload}
            {buttonDelete}
          </Typography>
        </Tooltip>
      );
    default:
      return (
        <Link
          component="label"
          sx={{
            position: 'relative',
            fontSize: theme => theme.typography.pxToRem(12),
            lineHeight: theme => theme.typography.pxToRem(16),
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            gap: '4px',
          }}
        >
          <UploadIcon sx={{ fontSize: '16px' }} />
          <Typography fontSize="inherit">Загрузить</Typography>
          <Input
            type="file"
            inputProps={{ accept: accept }}
            onChange={e => {
              onFileChange &&
                onFileChange((e.target as HTMLInputElement).files?.[0]);
            }}
            sx={{
              width: '100%',
              height: '100%',
              clip: 'rect(0 0 0 0)',
              clipPath: 'inset(50%)',
              overflow: 'hidden',
              position: 'absolute',
              bottom: 0,
              left: 0,
              whiteSpace: 'nowrap',
            }}
          />
        </Link>
      );
  }
};
