import { sample } from 'effector';
import { Feature } from 'ol';
import { MultiLineString } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EMapFeatureLayout, EMapFeatureMetaKeys } from '@constants/map';

import { EVehicle } from '@features/pt-forecast-new/constants/eVehicle';
import {
  $ComputedData,
  ComputedDataApi,
} from '@features/pt-forecast-new/stores/computedData';
import {
  $FilterMap,
  EFilterMapLayout,
  FilterMapApi,
} from '@features/pt-forecast-new/stores/filters';
import {
  $LayersVisible,
  LayersVisibleApi,
} from '@features/pt-forecast-new/stores/map/layersVisible';
import { $SelectedFeatures } from '@features/pt-forecast-new/stores/map/selectedFeatures';
import {
  initSources,
  pipeDirectionHighwayVectorLayer,
} from '@features/pt-forecast-new/stores/map/store';
import {
  $VectorGeometry,
  VectorGeometryApi,
} from '@features/pt-forecast-new/stores/map/vectorFeatures';
import { getWidthLineByPercent } from '@features/pt-forecast-new/utils/getWidthLineByPercent';

import {
  EFeatureStyle,
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload &&
    Object.keys(payload).includes(EMapFeatureLayout.directionHighway),
  fn: payload => {
    return {
      action: EPipeVectorLayer.setVisible,
      payload: payload![EMapFeatureLayout.directionHighway],
    } as PipeVectorLayer;
  },
  target: pipeDirectionHighwayVectorLayer,
});

// Рендер геометрии
sample({
  clock: [VectorGeometryApi.setDirectionPassFlowHighway, initSources],
  source: { VectorGeometry: $VectorGeometry },
  fn: ({ VectorGeometry }) => {
    const features: Feature<MultiLineString>[] = [];

    if (VectorGeometry.directionPassFlow.highway.length)
      VectorGeometry.directionPassFlow.highway.forEach(item => {
        features.push(
          new Feature({
            geometry: new MultiLineString(
              item.coordinates.map(line =>
                line.map(coordinate => fromLonLat(coordinate)),
              ),
            ),
            [EMapFeatureMetaKeys.olId]: item.id,
            [EMapFeatureMetaKeys.layout]: EMapFeatureLayout.directionHighway,
            year: item.year,
            status: item.status,
          }),
        );
      });

    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: { features },
    } as PipeVectorLayer;
  },
  target: pipeDirectionHighwayVectorLayer,
});

// применение стилей к слою
sample({
  clock: [
    ComputedDataApi.setPassengerTrafficPercent,
    FilterMapApi.setLayout,
    initSources,
  ],
  source: { ComputedData: $ComputedData, FilterMap: $FilterMap },
  fn: ({ ComputedData, FilterMap }) => {
    const percent =
      (ComputedData.passengerTrafficPercent[EVehicle.auto] || 0) +
      (ComputedData.passengerTrafficPercent[EVehicle.bus] || 0);
    return {
      action: EPipeVectorLayer.changeThemeProps,
      payload: {
        width:
          FilterMap.layout === EFilterMapLayout.PassengerFlows
            ? getWidthLineByPercent(percent)
            : 3,
      },
    } as PipeVectorLayer;
  },
  target: pipeDirectionHighwayVectorLayer,
});

// применение стилей к фичам
sample({
  clock: [$SelectedFeatures, $LayersVisible],
  source: {
    SelectedFeatures: $SelectedFeatures,
    LayersVisible: $LayersVisible,
  },
  filter: ({ LayersVisible }) =>
    LayersVisible[EMapFeatureLayout.directionHighway],
  fn: ({ SelectedFeatures }) => {
    return {
      action: EPipeVectorLayer.setFeatureStyle,
      payload: {
        featureStyles: !SelectedFeatures.length
          ? {}
          : Object.fromEntries(
              SelectedFeatures.filter(
                item => item.layout === EMapFeatureLayout.directionHighway,
              ).map(item => [item.id, EFeatureStyle.selected]),
            ),
      },
    } as PipeVectorLayer;
  },
  target: pipeDirectionHighwayVectorLayer,
});
