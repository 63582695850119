import { Fill, Stroke, Style } from 'ol/style';

export const transportDistrictsVectorStyle = () => {
  return new Style({
    stroke: new Stroke({
      color: '#50A583',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(80,165,131,0.11)',
    }),
  });
};
