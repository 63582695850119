import {
  IDownloadFileMutation,
  IMutationDownloadFileArgs,
  Maybe,
} from '@api/gql/tpu-types';
import { createEffect } from 'effector';

interface Usage {
  isPlanBackground: boolean;
  isPlanReport: boolean;
}

export const downloadFileFx = createEffect<
  (IMutationDownloadFileArgs & Partial<Usage>) | null,
  Maybe<IDownloadFileMutation>
>();
