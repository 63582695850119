import { Map as MapOl } from 'ol';
import { memo, useEffect, useRef } from 'react';

import { Map } from '@components/Map';

import { EMapFeatureLayout } from '@constants/map';

import { MapCursorPoint } from '@features/pt-forecast-new/components/MapCursorPoint/MapCursorPoint';
import { LAYERS_Z_INDEX } from '@features/pt-forecast-new/constants/lyersZIndex';
import { initLayersVisible } from '@features/pt-forecast-new/stores/map/layersVisible';
import {
  clickMap,
  initSources,
  pipeAirportVectorLayer,
  pipeAreaBorderVectorLayer,
  pipeBusStationVectorLayer,
  pipeCustomRailwayGraphVectorLayer,
  pipeCustomRailwayStationVectorLayer,
  pipeDirectionAirlineVectorLayer,
  pipeDirectionHighwayVectorLayer,
  pipeDirectionRailwayVectorLayer,
  pipeHighwayTileLayer,
  pipeMapControls,
  pipeMapFollowPath,
  pipePointsABVectorLayer,
  pipeRailwayStationVectorLayer,
  pipeRailwayTileLayer,
  pipeSearchInfraVectorLayer,
} from '@features/pt-forecast-new/stores/map/store';

import { useInitMapControls } from '@utils/map/hooks/useInitMapControls';
import { useInitTileLayer } from '@utils/map/hooks/useInitTileLayer';
import { useInitVectorLayer } from '@utils/map/hooks/useInitVectorLayer';
import { useMapFollowPath } from '@utils/map/hooks/useMapFollowPath';
import { ETileLayerStyles } from '@utils/map/tools/getTileLayerStyle';
import { EVectorLayerStyles } from '@utils/map/tools/getVectorLayerStyle';

export const MapContainer = memo(() => {
  const mapRef = useRef<MapOl | null>(null);

  useInitMapControls({
    mapRef,
    events: [pipeMapControls],
  });

  useMapFollowPath({
    mapRef,
    event: pipeMapFollowPath,
    zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.customRailwayGraph],
    distanceSticking: {
      min: 100000000,
      max: 500000000000,
    },
  });

  useInitTileLayer({
    mapRef,
    config: {
      source: undefined,
      zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.railway],
      layout: EMapFeatureLayout.railway,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.railway],
    },
    events: [pipeRailwayTileLayer],
  });

  useInitTileLayer({
    mapRef,
    config: {
      source: undefined,
      zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.highway],
      layout: EMapFeatureLayout.highway,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.highway],
    },
    events: [pipeHighwayTileLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.areaBorder],
      layout: EMapFeatureLayout.areaBorder,
      theme: EVectorLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.areaBorder],
    },
    events: [pipeAreaBorderVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.directionHighway],
      layout: EMapFeatureLayout.directionHighway,
      theme: EVectorLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.directionHighway],
    },
    events: [pipeDirectionHighwayVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.directionRailway],
      layout: EMapFeatureLayout.directionRailway,
      theme: EVectorLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.directionRailway],
    },
    events: [pipeDirectionRailwayVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.directionAirline],
      layout: EMapFeatureLayout.directionAirline,
      theme: EVectorLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.directionAirline],
    },
    events: [pipeDirectionAirlineVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.busStation],
      layout: EMapFeatureLayout.busStation,
      theme: EVectorLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.busStation],
    },
    events: [pipeBusStationVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.railwayStation],
      layout: EMapFeatureLayout.railwayStation,
      theme: EVectorLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.railwayStation],
    },
    events: [pipeRailwayStationVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.airport],
      layout: EMapFeatureLayout.airport,
      theme: EVectorLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.airport],
    },
    events: [pipeAirportVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.pointsAB],
      layout: EMapFeatureLayout.pointsAB,
      theme: EVectorLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.pointsAB],
    },
    events: [pipePointsABVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.customRailwayStation],
      layout: EMapFeatureLayout.customRailwayStation,
      theme: EVectorLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.customRailwayStation],
    },
    events: [pipeCustomRailwayStationVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.customRailwayGraph],
      layout: EMapFeatureLayout.customRailwayGraph,
      theme: EVectorLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.customRailwayGraph],
    },
    events: [pipeCustomRailwayGraphVectorLayer],
  });

  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LAYERS_Z_INDEX[EMapFeatureLayout.ptSearchInfra],
      layout: EMapFeatureLayout.ptSearchInfra,
      theme: EVectorLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.ptSearchInfra],
    },
    events: [pipeSearchInfraVectorLayer],
  });

  useEffect(() => {
    initSources();
  }, []);

  return (
    <>
      <Map
        ref={mapRef}
        setMap={() => {}}
        onClick={clickMap}
      />
      <MapCursorPoint />
    </>
  );
});
