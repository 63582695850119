import {
  IGraphInstanceType,
  IQuery,
  IQueryGetTransportDistrictByIdArgs,
  IRailwayStationInstanceType,
  Maybe,
  Scalars,
} from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

import { GraphInspectorProps } from '@features/ag-project/components/Inspector/GraphInspector';
import { AgLayersStore } from '@features/ag-project/stores/map';

export const $InfrastructureInspector =
  createStore<InfrastructureInspectorType>({
    railwayStation: {
      id: null,
      name: '',
      isTpu: null,
      year: null,
      capacity: null,
    },
    graphs: {
      capacity: 0,
      category: '',
      freeSpeed: 0,
      laneNum: 0,
      oneway: false,
      transport: [],
    },
    transportDistrict: {
      id: null,
      name: null,
      municipality: null,
      averageSalary: 0,
      numOfGardenNoncommercialPartnerships: 0,
      population: 0,
      retailSpaces: 0,
      serviceWorkplaces: 0,
      studyPlaces: 0,
      workplaces: 0,
      districtType: null,
    },
  });

export const InfrastructureInspectorApi = createApi($InfrastructureInspector, {
  setRailwayStationForInspector: (
    store,
    payload: InfrastructureInspectorType['railwayStation'],
  ) => ({
    ...store,
    railwayStation: payload,
  }),
  setGraphsForInspector: (
    store,
    payload: InfrastructureInspectorType['graphs'],
  ) => ({
    ...store,
    graphs: payload,
  }),
  setTransportDistrictForInspector: (
    store,
    payload: InfrastructureInspectorType['transportDistrict'],
  ) => ({
    ...store,
    transportDistrict: payload,
  }),
});

export const getGraphsForInspectorFx = createEffect<
  GetObjectType,
  Maybe<IGraphInstanceType>
>();
export const getRailwayStationForInspectorFx = createEffect<
  GetObjectType,
  Maybe<IRailwayStationInstanceType>
>();
export const getTransportDistrictForInspectorFx = createEffect<
  IQueryGetTransportDistrictByIdArgs,
  IQuery['getTransportDistrictById']
>();

export type InfrastructureInspectorType = {
  graphs: GraphInspectorProps['fields'];
  railwayStation: Pick<
    IRailwayStationInstanceType,
    'id' | 'name' | 'isTpu' | 'year' | 'capacity'
  >;
  transportDistrict: {
    id: string | null;
    name: string | null;
    municipality: string | null;
    population: number;
    studyPlaces: number;
    averageSalary: number;
    serviceWorkplaces: number;
    retailSpaces: number;
    workplaces: number;
    numOfGardenNoncommercialPartnerships: number;
    districtType: string | null;
  };
};

export type GetObjectType = {
  layout: keyof AgLayersStore | null;
  id: Scalars['UUID']['output'];
};
