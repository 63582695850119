import { IQueryGetProjectInfraPointByNameArgs } from '@api/gql/ag-types';
import { sample } from 'effector';

import { querySearchInfra } from '@features/ag-forecast/stores/api/querySearchInfra';
import {
  SearchOption,
  SearchOptionsApi,
  getSearchOptions,
  querySearchOptionsFx,
} from '@features/ag-forecast/stores/lists/searchOptions/store';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';

sample({
  clock: getSearchOptions,
  source: { FeatureSettings: $FeatureSettings },
  fn: ({ FeatureSettings }, payload) => {
    return {
      contains: payload,
      projectId: FeatureSettings.projectUUID,
    } as IQueryGetProjectInfraPointByNameArgs;
  },
  target: querySearchOptionsFx,
});

querySearchOptionsFx.use(querySearchInfra);

sample({
  clock: querySearchOptionsFx.done,
  fn: request => {
    return (request.result || []).map(item => ({
      lonLat: [item!.point!.lat, item!.point!.lon],
      name: item!.name,
      type: item!.type,
      value: item!.id,
    })) as SearchOption[];
  },
  target: SearchOptionsApi.setOptions,
});
