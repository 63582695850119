import {
  IAgglomerationType,
  ICopyProjectMutation,
  IDataFileTypeEnum,
  IDownloadFileTypeEnum,
  IFloorFileInfoType,
  IHubType,
  IUploadDataFileMutation,
  Maybe,
} from '@api/gql/tpu-types';
import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

import {
  NotificationType,
  addFloorFileFieldFn,
  addNotificationFn,
  changeFloorFilesOrderFn,
  copyProjectFx,
  createDraftProjectFx,
  deleteDataFileFn,
  deleteFloorFileFn,
  deleteProjectFn,
  downloadFileFx,
  editProjectFn,
  getFileTemplatesFx,
  getListOfProjectsFn,
  hideNotificationFn,
  openDeleteProjectModalFn,
  removeNotificationFn,
  resetFileFormatModalStateFn,
  resetFormStateFn,
  resetModalDeleteProjectFn,
  resetNotificationsFn,
  resetProjectDataFn,
  saveProjectFn,
  setAgglomerationFn,
  setDataFileFn,
  setFloorFileDescriptionFn,
  setFloorFileFn,
  setHubFn,
  setHubSearchFn,
  setIsOpenFileFormatModalFn,
  setNameFn,
  setPageFn,
  setRowsPerPageFn,
  setSearchFn,
  setSortFn,
} from '@features/tpu-project-registry/stores';

export interface UseProjectRegistryHandlers {
  addFloorFileField(): void;
  setFloorFileDescription(payload: {
    description: string;
    index: number;
  }): void;
  setFloorFile(payload: {
    data: Omit<IFloorFileInfoType, 'created' | 'modified' | 'description'>;
    index: number;
  }): void;
  setDataFile(payload: IUploadDataFileMutation['fileInfo']): void;
  setName(payload: string): void;
  setHub(payload: IHubType | null): void;
  setAgglomeration(payload: IAgglomerationType | null): void;
  resetFormState(): void;
  getListOfProjects(): void;
  setSearch(payload: string): void;
  setPage(payload: number): void;
  setRowsPerPage(payload: number): void;
  setSort(payload: GridSortModel): void;
  editProject(payload: string): void;
  createDraftProject(): Promise<string | undefined>;
  saveProject(): void;
  getFileTemplates(): Promise<string>;
  deleteProject(): void;
  copyProject(
    payload: string,
  ): Promise<Maybe<ICopyProjectMutation> | undefined>;
  saveProject(): void;
  deleteFloorFile(payload: number): void;
  deleteDataFile(payload: IDataFileTypeEnum): void;
  resetProjectData(): void;
  setHubSearch(payload: string): void;
  setIsOpenFileFormatModal(payload: boolean): void;
  changeFloorFilesOrder(payload: { transferId: string; catchId: string }): void;
  resetProjectRegistryPageData(): void;
  downloadFile(payload: {
    id: string;
    type: IDownloadFileTypeEnum;
  }): Promise<void>;

  addNotification(payload: {
    type: NotificationType;
    isSuccess: boolean;
  }): void;
  hideNotification(payload: string): void;
  removeNotification(payload: string): void;
  resetNotifications(): void;
  resetModalDeleteProject(): void;
  openDeleteProjectModal(payload: { name: string; id: string }): void;
}

export const usePRHandlers = (): UseProjectRegistryHandlers => {
  const events = useUnit({
    addFloorFileFieldFn,
    setFloorFileDescriptionFn,
    setFloorFileFn,
    setDataFileFn,
    setNameFn,
    setHubFn,
    setAgglomerationFn,
    resetFormStateFn,
    getListOfProjectsFn,
    setSearchFn,
    setPageFn,
    setRowsPerPageFn,
    setSortFn,
    editProjectFn,
    saveProjectFn,
    deleteFloorFileFn,
    deleteDataFileFn,
    resetProjectDataFn,
    setHubSearchFn,
    changeFloorFilesOrderFn,
    setIsOpenFileFormatModalFn,
    downloadFileFx,
    createDraftProjectFx,
    getFileTemplatesFx,
    copyProjectFx,
    resetFileFormatModalStateFn,
    addNotificationFn,
    hideNotificationFn,
    removeNotificationFn,
    resetNotificationsFn,

    openDeleteProjectModalFn,
    resetModalDeleteProjectFn,
    deleteProjectFn,
  });

  const resetProjectRegistryPageData = useCallback(() => {
    events.resetFileFormatModalStateFn();
    events.resetProjectDataFn();
    events.resetFormStateFn();
    events.resetNotificationsFn();
    events.resetModalDeleteProjectFn();
  }, [events]);

  return {
    addFloorFileField: events.addFloorFileFieldFn,
    setFloorFileDescription: events.setFloorFileDescriptionFn,
    setFloorFile: events.setFloorFileFn,
    setDataFile: events.setDataFileFn,
    setName: events.setNameFn,
    setHub: events.setHubFn,
    setAgglomeration: events.setAgglomerationFn,
    getFileTemplates: events.getFileTemplatesFx,
    resetFormState: events.resetFormStateFn,
    getListOfProjects: events.getListOfProjectsFn,
    setSearch: events.setSearchFn,
    setPage: events.setPageFn,
    setRowsPerPage: events.setRowsPerPageFn,
    setSort: events.setSortFn,
    editProject: events.editProjectFn,
    saveProject: events.saveProjectFn,
    createDraftProject: events.createDraftProjectFx,
    copyProject: events.copyProjectFx,
    deleteFloorFile: events.deleteFloorFileFn,
    deleteDataFile: events.deleteDataFileFn,
    resetProjectData: events.resetProjectDataFn,
    setHubSearch: events.setHubSearchFn,
    setIsOpenFileFormatModal: events.setIsOpenFileFormatModalFn,
    changeFloorFilesOrder: events.changeFloorFilesOrderFn,
    resetProjectRegistryPageData,
    downloadFile: events.downloadFileFx,
    addNotification: events.addNotificationFn,
    hideNotification: events.hideNotificationFn,
    removeNotification: events.removeNotificationFn,
    resetNotifications: events.resetNotificationsFn,
    openDeleteProjectModal: events.openDeleteProjectModalFn,
    resetModalDeleteProject: events.resetModalDeleteProjectFn,
    deleteProject: events.deleteProjectFn,
  };
};
