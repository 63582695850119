import { SocEcoContainer } from '@features/ag-constructor/containers/SocEcoContainer/SocEcoContainer';
import { TakeIntoInfraContainer } from '@features/ag-constructor/containers/TakeIntoInfraContainer/TakeIntoInfraContainer';
import { $CurrentScenario } from '@features/ag-constructor/stores/currentScenario';
import { useUnit } from 'effector-react';

import { Stack } from '@mui/material';

export const ScenarioEditorContainer = () => {
  const $currentScenario = useUnit($CurrentScenario);

  if (!$currentScenario) return null;

  return (
    <Stack gap={2}>
      <TakeIntoInfraContainer />
      <SocEcoContainer />
    </Stack>
  );
};
