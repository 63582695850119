import { Line } from 'react-konva';

import { IRoom } from '@features/tpu-simulation-model/types';

interface RoomProps extends IRoom {
  opacity: number;
}

export const Room = (props: RoomProps) => {
  const { points, opacity } = props;
  return (
    <Line
      opacity={opacity}
      strokeWidth={4}
      stroke={'#E21A1A'}
      fill={'#E9EEF3'}
      closed={true}
      points={points.flatMap(({ x, y }) => [x, y])}
    />
  );
};
