import { IAgentShareInfoType } from '@api/gql/tpu-types';

type GetIncreasedEquallyList = (arg: {
  list: IAgentShareInfoType[];
  changedItemId: string;
  diff: number;
}) => IAgentShareInfoType[];

type GetIncreasedProportionallyList = (arg: {
  list: IAgentShareInfoType[];
  listWithoutChangedItem: IAgentShareInfoType[];
  diffAtPercent: number;
}) => IAgentShareInfoType[];

type GetSubtractedProportionallyList = (arg: {
  list: IAgentShareInfoType[];
  listWithoutChangedItem: IAgentShareInfoType[];
  diffAtPercent: number;
}) => IAgentShareInfoType[];

const getParamsForCalculation = (
  data: IAgentShareInfoType[],
  changedItemId: string,
) => {
  const list = structuredClone(data);
  const changedItem = list.find(item => item.id === changedItemId);
  const changedItemValue = changedItem?.share ?? 0;
  const listWithoutChangedItem = list.filter(({ id }) => id !== changedItemId);
  const sum = list.reduce((acc, item) => acc + item.share, 0);
  const sumUnchangedItems = sum - changedItemValue;
  const diff = sum - 100;
  const diffWithoutSign = Math.abs(diff);
  const diffAtPercent = diffWithoutSign / sumUnchangedItems;
  const diffSign = Math.sign(diff);

  return {
    list,
    listWithoutChangedItem,
    diff: diffWithoutSign,
    diffSign,
    changedItem,
    sumUnchangedItems,
    diffAtPercent,
  };
};

const getResetList = (list: IAgentShareInfoType[], id: string) => {
  return list.map(item => (id === item.id ? item : { ...item, share: 0 }));
};

const getIncreasedEquallyList: GetIncreasedEquallyList = params => {
  const { diff, list, changedItemId } = params;
  let reminder = 0;

  const listLengthWithoutChangedItem = list.length - 1;
  const indexLastUnchangedItem =
    list.at(-1)?.id === changedItemId ? list.length - 2 : list.length - 1;
  const diffAtItem = Math.floor(diff / listLengthWithoutChangedItem);

  reminder = diff - diffAtItem * listLengthWithoutChangedItem;

  return list.map((item, index) => {
    if (item.id === changedItemId) return item;
    const isLast = index === indexLastUnchangedItem;
    const addedValue = isLast ? diffAtItem + reminder : diffAtItem;

    return { ...item, share: item.share + addedValue };
  });
};

export const getIncreasedProportionallyList: GetIncreasedProportionallyList =
  params => {
    const { listWithoutChangedItem, list, diffAtPercent } = params;
    let reminder = 0;

    const descList = listWithoutChangedItem.sort((a, b) => b.share - a.share);
    const listAsHashTable = new Map<string, IAgentShareInfoType>(
      list.map(item => [item.id, item]),
    );

    descList.forEach((agentShareInfo, index) => {
      const isLast = index === descList.length - 1;
      const addedValue = diffAtPercent * agentShareInfo.share;
      const fractionalPart = addedValue % 1;
      const { id, share } = agentShareInfo;

      if (fractionalPart) {
        reminder += fractionalPart;
      }

      if (isLast) {
        listAsHashTable.set(id, {
          ...agentShareInfo,
          share: share + Math.floor(addedValue) + Math.round(reminder),
        });
        return;
      }

      listAsHashTable.set(id, {
        ...agentShareInfo,
        share: share + Math.floor(addedValue),
      });
    });

    return Array.from(listAsHashTable.values());
  };

export const getSubtractedProportionallyList: GetSubtractedProportionallyList =
  params => {
    const { listWithoutChangedItem, list, diffAtPercent } = params;
    let reminder = 0;

    const ascList = listWithoutChangedItem.sort((a, b) => a.share - b.share);
    const listAsHashTable = new Map<string, IAgentShareInfoType>(
      list.map(item => [item.id, item]),
    );

    ascList.forEach((agentShareInfo, index) => {
      const { id, share } = agentShareInfo;
      const isLast = index === ascList.length - 1;
      const subtrahend = diffAtPercent * agentShareInfo.share;
      const newValue = share - Math.floor(subtrahend);
      const isNewValueNegative = newValue < 0;

      if (subtrahend % 1) {
        reminder += subtrahend % 1;
      }

      if (isNewValueNegative) {
        reminder += -newValue;
      }

      if (isLast) {
        listAsHashTable.set(id, {
          ...agentShareInfo,
          share: newValue - Math.round(reminder),
        });
        return;
      }

      listAsHashTable.set(id, {
        ...agentShareInfo,
        share: Math.max(newValue, 0),
      });
    });

    return Array.from(listAsHashTable.values());
  };

export const recountShare = (
  data: IAgentShareInfoType[],
  changedItemId: string,
): IAgentShareInfoType[] => {
  const {
    list,
    listWithoutChangedItem,
    diff,
    diffSign,
    changedItem,
    sumUnchangedItems,
    diffAtPercent,
  } = getParamsForCalculation(data, changedItemId);

  if (diff === 0 || !changedItem) return data;

  if (list.length === 1) return [{ ...changedItem, share: 100 }];

  if (changedItem.share === 100) return getResetList(data, changedItemId);

  if (sumUnchangedItems === 0)
    return getIncreasedEquallyList({ diff, changedItemId, list });

  if (diffSign < 0)
    return getIncreasedProportionallyList({
      diffAtPercent,
      listWithoutChangedItem,
      list,
    });

  return getSubtractedProportionallyList({
    diffAtPercent,
    listWithoutChangedItem,
    list,
  });
};
