export const SOC_ECO_TH_1_4 = [
  {
    id: 'nameTransportObject',
    label: 'Наименование транспортного объекта',
  },
  {
    id: 'transportArea',
    label: 'Транспортный район',
  },
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'averageDailyPassengerTraffic',
    label: 'Среднесуточный пассажиропоток, чел. ',
  },
  {
    id: 'tradeArea',
    label: 'Торговая площадь, кв. м.',
  },
  {
    id: 'annualRevenue',
    label: 'Годовая выручка, тыс. руб. ',
  },
];
