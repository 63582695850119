import { MapBrowserEvent, Map as MapOl } from 'ol';
import { applyStyle } from 'ol-mapbox-style';
import View from 'ol/View.js';
import VectorTileLayer from 'ol/layer/VectorTile';
import 'ol/ol.css';
import { fromLonLat } from 'ol/proj';
import { forwardRef, useEffect, useMemo, useRef } from 'react';

import { Box } from '@mui/material';

import { CustomMap } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/helpers';

const sharedTopographicLayer = new VectorTileLayer({
  declutter: false,
  className: 'topographic',
  zIndex: 0,
  background: '#eaeaea',
});

export interface MapProps {
  readonly center?: number[];
  readonly extent?: number[];
  readonly zoom?: number;
  readonly setMap: (map: CustomMap) => void;
  readonly onClick: (event: MapBrowserEvent<UIEvent>) => void;
  readonly onHover?: (event: MapBrowserEvent<UIEvent>) => void;
  readonly uniqueTopographicInstance?: boolean;
}

export const Map = forwardRef<MapOl, MapProps>((props, ref) => {
  const {
    extent,
    center = [35, 58],
    zoom = 7.5,
    uniqueTopographicInstance,
    onClick,
    setMap,
    onHover,
  } = props;

  const refMapContainer = useRef<HTMLElement | null>(null);

  const topographicLayer = useMemo(
    () =>
      !uniqueTopographicInstance
        ? null
        : new VectorTileLayer({
            declutter: false,
            className: 'topographic',
            zIndex: 0,
            background: '#eaeaea',
          }),
    [],
  );

  useEffect(() => {
    if (!refMapContainer.current) return;

    const map = new MapOl({
      target: refMapContainer.current,
      layers: [
        uniqueTopographicInstance ? topographicLayer! : sharedTopographicLayer,
      ],
      view: new View({
        center: fromLonLat(center),
        extent,
        zoom,
      }),
      controls: [],
    });

    applyStyle(
      uniqueTopographicInstance ? topographicLayer! : sharedTopographicLayer,
      import.meta.env.APP_MAP_STYLE_SOURCE,
    );

    map.on('singleclick', onClick);

    onHover && map.on('pointermove', onHover);

    if (typeof ref === 'function') ref(map);
    else if (ref) ref.current = map;
    setMap(map);

    // Освобождение ресурсов карты при размонтировании компонента
    return () => map.setTarget(undefined);
  }, [center, extent, onClick, setMap, zoom]);

  return (
    <Box
      ref={refMapContainer}
      sx={{
        height: '100%',
        width: '100%',
      }}
    />
  );
});
