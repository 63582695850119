import { apiClient } from '@api/client';
import {
  IMutation,
  IMutationUpdateComputedResultsParamsArgs,
} from '@api/gql/types';
import { gql } from '@apollo/client';

export const queryUpdateComputedResultsParams = async (
  params: IMutationUpdateComputedResultsParamsArgs,
) => {
  const result = await apiClient.mutate<
    IMutation,
    IMutationUpdateComputedResultsParamsArgs
  >({
    mutation: gql`
      mutation QueryUpdateComputedResultsParams(
        $durationAuto: Float
        $durationAvia: Float
        $durationBus: Float
        $durationDayTrain: Float
        $durationExpressSuburbanTrain: Float
        $durationNightTrain: Float
        $durationPlain: Float
        $durationSuburbanTrain: Float
        $frequencyAvia: Float
        $frequencyBus: Float
        $frequencyDayTrain: Float
        $frequencyExpressSuburbanTrain: Float
        $frequencyNightTrain: Float
        $frequencyPlain: Float
        $frequencySuburbanTrain: Float
        $id: UUID!
        $priceAuto: Float
        $priceAvia: Float
        $priceBus: Float
        $priceDayTrain: Float
        $priceExpressSuburbanTrain: Float
        $priceNightTrain: Float
        $pricePlain: Float
        $priceSuburbanTrain: Float
      ) {
        updateComputedResultsParams(
          durationAuto: $durationAuto
          durationAvia: $durationAvia
          durationBus: $durationBus
          durationDayTrain: $durationDayTrain
          durationExpressSuburbanTrain: $durationExpressSuburbanTrain
          durationNightTrain: $durationNightTrain
          durationPlain: $durationPlain
          durationSuburbanTrain: $durationSuburbanTrain
          frequencyAvia: $frequencyAvia
          frequencyBus: $frequencyBus
          frequencyDayTrain: $frequencyDayTrain
          frequencyExpressSuburbanTrain: $frequencyExpressSuburbanTrain
          frequencyNightTrain: $frequencyNightTrain
          frequencyPlain: $frequencyPlain
          frequencySuburbanTrain: $frequencySuburbanTrain
          id: $id
          priceAuto: $priceAuto
          priceAvia: $priceAvia
          priceBus: $priceBus
          priceDayTrain: $priceDayTrain
          priceExpressSuburbanTrain: $priceExpressSuburbanTrain
          priceNightTrain: $priceNightTrain
          pricePlain: $pricePlain
          priceSuburbanTrain: $priceSuburbanTrain
        ) {
          computingResultsObj {
            durationAuto
            durationAvia
            durationBus
            durationDayTrain
            durationExpressSuburbanTrain
            durationNightTrain
            durationPlain
            durationSuburbanTrain
            frequencyAvia
            frequencyBus
            frequencyDayTrain
            frequencyExpressSuburbanTrain
            frequencyNightTrain
            frequencyPlain
            frequencySuburbanTrain
            id
            priceAuto
            priceAvia
            priceBus
            priceDayTrain
            priceExpressSuburbanTrain
            priceNightTrain
            pricePlain
            priceSuburbanTrain
            year
          }
          result
          errors
        }
      }
    `,
    variables: params,
  });
  if (result.errors?.length) throw new Error(result.errors[0].message);
  return result.data?.updateComputedResultsParams;
};
