export const SOC_ECO_TH_6_2 = [
  {
    id: 'tpu',
    label: 'ТПУ',
  },
  {
    id: 'numberPassengersTransportedViaTPU',
    label: 'Число перевезённых пассажиров по ТПУ, тыс. чел.',
  },
  {
    id: 'costImprovementActivities',
    label: 'Стоимость мероприятий по благоустройству, млн. руб.',
  },
  {
    id: 'numberParkingSpacesWithinTPU',
    label: 'Число парковочных мест в пределах ТПУ, шт. ',
  },
  {
    id: 'numberPersonalMobilityDevices',
    label: 'Число средств индивидуальной мобильности, шт.',
  },
];
