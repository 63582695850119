import { List, ListItem, ListItemText } from '@mui/material';

interface ErrorsListProps {
  errors: string[];
}

export const ErrorsList = ({ errors }: ErrorsListProps) => {
  return (
    <>
      <List
        sx={{
          padding: '12px 0 0 0',
          overflow: 'auto',
        }}
      >
        {errors.map((error, index) => (
          <ListItem
            key={index}
            sx={{
              borderTop: theme => `1px solid ${theme.palette.border.divider}`,
              padding: '12px 0',
            }}
          >
            <ListItemText
              secondary={'#235792:'}
              secondaryTypographyProps={{
                sx: {
                  fontSize: theme => theme.typography.pxToRem(16),
                  lineHeight: theme => theme.typography.pxToRem(20),
                },
              }}
              sx={{
                width: 'fit-content',
                minWidth: 'unset',
                flex: 'unset',
                mr: '8px',
              }}
            />
            <ListItemText
              primary={error}
              primaryTypographyProps={{
                sx: {
                  fontSize: theme => theme.typography.pxToRem(16),
                  lineHeight: theme => theme.typography.pxToRem(20),
                  wordBreak: 'break-word',
                },
              }}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};
