import {
  ICreateScenarioInput,
  IDayTimeTypeEnum,
  IScenarioDayTypeEnum,
  IScenarioStateEnum,
  IScenarioType,
  ITurnstileModeEnum,
  IUpdateScenarioInput,
} from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

export interface FormData {
  id: string;
  name: string;
  dayType: IScenarioDayTypeEnum;
  dayTimeType: IDayTimeTypeEnum;
  turnstileMode: ITurnstileModeEnum;
  state: IScenarioStateEnum;
  changePassengerTrafficIntensity: string;
  isOpenForViewing: boolean;
}

export const $Form = createStore<FormData>({
  id: '',
  name: '',
  changePassengerTrafficIntensity: '',
  state: IScenarioStateEnum.NotCalculated,
  dayType: IScenarioDayTypeEnum.Workday,
  dayTimeType: IDayTimeTypeEnum.AllDay,
  turnstileMode: ITurnstileModeEnum.DoubleSided,
  isOpenForViewing: false,
});

export const $IsOpenForm = createStore<boolean>(false);

export const setIsOpenFormFn = createEvent<boolean>();

export const saveScenarioFn = createEvent<FormData>();

export const openScenarioForEditFn = createEvent<string>();

export const openScenarioForViewingFn = createEvent<string>();

export const resetFormStateFn = createEvent();

export const closeFormStateFn = createEvent();

export const openFormForCreateNewScenarioFn = createEvent();

export const createScenarioFx = createEffect<
  ICreateScenarioInput,
  string | undefined
>();

export const updateScenarioFx = createEffect<IUpdateScenarioInput, void>();

export const getScenarioByIdFx = createEffect<string, IScenarioType | null>();
