import { ReactNode } from 'react';

import { Button, SxProps, Theme } from '@mui/material';

interface MapButtonProps {
  onClick: () => void;
  children: ReactNode | string;
  sx?: SxProps<Theme>;
}

export const MapButton = (props: MapButtonProps) => {
  const { onClick, sx, children } = props;

  return (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: theme => theme.palette.common.white,
        color: theme => theme.palette.primary.main,
        border: theme => '1px solid ' + theme.palette.text.disabled,
        minWidth: theme => theme.typography.pxToRem(44),
        width: theme => theme.typography.pxToRem(44),
        '&:hover': {
          backgroundColor: theme => theme.palette.common.white,
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};
