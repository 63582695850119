import { VehicleTypeCode } from '@features/passenger-traffic/stores';

import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';

import { useMapFiltersHandlers } from '@components/MapFilters/useMapFiltersHandlers';

import { AccordionFilter } from './AccordionFilter';
import { BaseFilterSlotProps } from './interface';
import { typographyWhitCircleStyle } from './utils';

export const RouteNetworkSlot = (props: BaseFilterSlotProps) => {
  const { fields, hideFields = [] } = props;
  const {
    setSuburbanTrainHandler,
    setDayTrainHandler,
    setNightTrainHandler,
    setBusHandler,
    setAutoHandler,
    setRailwayRootHandler,
    setAviaHandler,
    setAutoRootHandler,
    setExpressTrainHandler,
  } = useMapFiltersHandlers(props);

  const railwayRootChild = [
    fields.dayTrain,
    fields.nightTrain,
    fields.suburbanTrain,
    fields.expressTrain,
  ];
  const autoRootChild = [fields.bus, fields.auto];
  const indeterminateRailwayRoot =
    railwayRootChild.some(Boolean) !== railwayRootChild.every(Boolean);
  const indeterminateAutoRoot =
    autoRootChild.some(Boolean) !== autoRootChild.every(Boolean);

  return (
    <AccordionFilter title={'По видам транспорта'}>
      <Stack direction={'column'}>
        <FormControlLabel
          checked={fields.railwayRoot}
          onChange={setRailwayRootHandler}
          control={<Checkbox indeterminate={indeterminateRailwayRoot} />}
          label={
            <Typography
              sx={typographyWhitCircleStyle('white', '#7A5A5A')}
              variant={'body2'}
            >
              ЖД транспорт
            </Typography>
          }
        />
        <Stack
          pl={4}
          direction={'column'}
        >
          {!hideFields.includes('nightTrain') && (
            <FormControlLabel
              checked={fields.nightTrain}
              onChange={setNightTrainHandler}
              control={<Checkbox />}
              label={VehicleTypeCode.nightTrain}
            />
          )}
          {!hideFields.includes('dayTrain') && (
            <FormControlLabel
              checked={fields.dayTrain}
              onChange={setDayTrainHandler}
              control={<Checkbox />}
              label={VehicleTypeCode.dayTrain}
            />
          )}
          {!hideFields.includes('suburbanTrain') && (
            <FormControlLabel
              checked={fields.suburbanTrain}
              onChange={setSuburbanTrainHandler}
              control={<Checkbox />}
              label={VehicleTypeCode.suburbanTrain}
            />
          )}

          {!hideFields.includes('expressTrain') && (
            <FormControlLabel
              checked={fields.expressTrain}
              onChange={setExpressTrainHandler}
              control={<Checkbox />}
              label={VehicleTypeCode.expressTrain}
            />
          )}
        </Stack>

        <FormControlLabel
          checked={fields.autoRoot}
          onChange={setAutoRootHandler}
          control={<Checkbox indeterminate={indeterminateAutoRoot} />}
          label={
            <Typography
              sx={typographyWhitCircleStyle('#FF792E', '#FF792E')}
              variant={'body2'}
            >
              Автомобильный транспорт
            </Typography>
          }
        />
        <Stack
          pl={4}
          direction={'column'}
        >
          {!hideFields.includes('bus') && (
            <FormControlLabel
              checked={fields.bus}
              onChange={setBusHandler}
              control={<Checkbox />}
              label={VehicleTypeCode.bus}
            />
          )}
          {!hideFields.includes('auto') && (
            <FormControlLabel
              checked={fields.auto}
              onChange={setAutoHandler}
              control={<Checkbox />}
              label={VehicleTypeCode.auto}
            />
          )}
        </Stack>

        {!hideFields.includes('avia') && (
          <FormControlLabel
            checked={fields.avia}
            onChange={setAviaHandler}
            control={<Checkbox />}
            label={
              <Typography
                sx={typographyWhitCircleStyle('#00A1DC', '#00A1DC')}
                variant={'body2'}
              >
                Авиатранспорт
              </Typography>
            }
          />
        )}
      </Stack>
    </AccordionFilter>
  );
};
