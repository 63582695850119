import { IVehicleTypeType } from '@api/gql/types';
import { createApi, createStore } from 'effector';

import { AgLayersStore } from '@features/ag-forecast/stores/map';

export enum EInspectorType {
  passengerTraffic,
  infrastructure,
  tpu,
  tpuGraph,
  graphs,
  passFlow,
}

export const $Inspector = createStore<InspectorState>({
  layout: null,
  inspectorType: null,
});

export const InspectorApi = createApi($Inspector, {
  setLayout: (state, layout: keyof AgLayersStore | null) => ({
    ...state,
    layout,
  }),
  setVehicleTypes: (state, vehicleTypes: IVehicleTypeType[]) => ({
    ...state,
    vehicleTypes,
  }),
  setInspectorType: (state, type: EInspectorType | null) => ({
    ...state,
    inspectorType: type,
  }),
  resetLayout: state => ({ ...state, layout: null }),
});

type InspectorState = {
  layout: keyof AgLayersStore | null;
  inspectorType: EInspectorType | null;
};
