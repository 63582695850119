import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

import {
  $ModalsPtConstructor,
  ModalsPtConstructor,
} from '@features/pt-constructor/store';

export type ModalEditCancellationData = (result: boolean) => void;

export type ModalEditCancellationProps = {
  onClose: () => void;
};

export const ModalEditCancellation = (props: ModalEditCancellationProps) => {
  const { onClose } = props;
  const $modalsPtConstructor = useUnit(
    $ModalsPtConstructor,
  ) as ModalsPtConstructor<ModalEditCancellationData>;

  const handleClickDeny = useCallback(() => {
    $modalsPtConstructor.action && $modalsPtConstructor.action(false);
    onClose();
  }, [$modalsPtConstructor, onClose]);

  const handleClickSave = useCallback(() => {
    $modalsPtConstructor.action && $modalsPtConstructor.action(true);
    onClose();
  }, [$modalsPtConstructor, onClose]);

  return (
    <Dialog open={true}>
      <DialogTitle
        variant="subtitle2"
        sx={{
          position: 'relative',
          fontSize: theme => theme.typography.pxToRem(16),
          pr: 10,
        }}
      >
        Данные не были сохранены
      </DialogTitle>

      <DialogActions>
        <Button
          color={'secondary'}
          variant={'contained'}
          onClick={handleClickSave}
        >
          Сохранить
        </Button>

        <Button
          variant={'outlined'}
          onClick={handleClickDeny}
        >
          Не сохранять
        </Button>
      </DialogActions>
    </Dialog>
  );
};
