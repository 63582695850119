import {
  ModalDeleteProject,
  ModalFileFormatRequirements,
} from '@features/tpu-project-registry/components';

export const Modals = () => {
  return (
    <>
      <ModalFileFormatRequirements />
      <ModalDeleteProject />
    </>
  );
};
