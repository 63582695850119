import { ITransportProjectListType, Maybe } from '@api/gql/types';
import { TransportProject } from '@features/pt-constructor/store';

export const transportProjectsReformatter = (
  data: Maybe<Maybe<ITransportProjectListType>[]>,
): TransportProject[] =>
  data
    ? data.map(project => ({
        name: project?.name || '',
        id: project?.id || '',
        year: 2030,
      }))
    : [];
