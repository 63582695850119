import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { Option } from '@features/tpu-simulation-model/utils/getAttributeOptions';

interface AttributeProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  title: string;
  options: Option[];
}

export function RadioList<T extends FieldValues>({
  control,
  name,
  title,
  options,
}: AttributeProps<T>) {
  return (
    <Box>
      <Typography mb={1}>{title}</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <RadioGroup>
            {options.map(item => {
              return (
                <FormControlLabel
                  key={item.value}
                  checked={item.value === value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                  onChange={onChange}
                />
              );
            })}
          </RadioGroup>
        )}
      />
    </Box>
  );
}
