import { IPassengerTrafficByIncomeGroupType } from '@api/gql/types';
import { createApi, createStore } from 'effector';

const initStore: IPassengerTrafficByIncomeGroupType[] | null = [];

export const $IncomeByGroups = createStore<IncomeByGroupsStore>(initStore);

export const IncomeByGroupsApi = createApi($IncomeByGroups, {
  setIncomeByGroups: (_, payload: IncomeByGroupsStore) => payload,
  reset: () => initStore,
});

export type IncomeByGroupsStore = IPassengerTrafficByIncomeGroupType[] | null;
