import { useUnit } from 'effector-react';
import React, { useCallback } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { AccordionFilter } from '@components/MapFilters/AccordionFilter';

import { ChipItem } from '@features/ag-forecast/components/DirectionPoints/ChipItem';
import { PointItem } from '@features/ag-forecast/components/DirectionPoints/PointItem';
import {
  $FilterMap,
  FilterMapApi,
  directionPoints,
} from '@features/ag-forecast/stores/filters';
import {
  $RegionOptions,
  RegionOptionItem,
  searchRegions,
} from '@features/ag-forecast/stores/lists/regionsOptions';
import { $UI } from '@features/ag-forecast/stores/ui';

export const DirectionPoints = () => {
  const { options } = useUnit($RegionOptions);
  const { fromSelectedRegions, toSelectedRegions } = useUnit($FilterMap);
  const {
    addRegionFrom,
    removeRegionFrom,
    addRegionTo,
    removeRegionTo,
    clearRegions,
  } = useUnit(FilterMapApi);
  const { isSetPoint } = useUnit($UI);
  const { setDirectionPoint } = useUnit(FilterMapApi);

  const ClearHandler = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      clearRegions();
    },
    [clearRegions],
  );

  const fromSelectHandler = useCallback(
    (items: RegionOptionItem[]) => {
      if (
        items.length === 1 &&
        fromSelectedRegions.some(region => region.value === items[0].value)
      )
        return removeRegionFrom(items[0]);
      addRegionFrom(
        items.filter(
          item =>
            !fromSelectedRegions.some(
              selectedItem => selectedItem.value === item.value,
            ),
        ),
      );
    },
    [addRegionFrom, fromSelectedRegions, removeRegionFrom],
  );

  const fromRemoveHandler = useCallback(
    (item: RegionOptionItem) => {
      removeRegionFrom(item);
    },
    [removeRegionFrom],
  );

  const toSelectHandler = useCallback(
    (items: RegionOptionItem[]) => {
      if (
        items.length === 1 &&
        toSelectedRegions.some(region => region.value === items[0].value)
      )
        return removeRegionTo(items[0]);
      addRegionTo(
        items.filter(
          item =>
            !toSelectedRegions.some(
              selectedItem => selectedItem.value === item.value,
            ),
        ),
      );
    },
    [addRegionTo, removeRegionTo, toSelectedRegions],
  );

  const toRemoveHandler = useCallback(
    (item: RegionOptionItem) => {
      removeRegionTo(item);
    },
    [removeRegionTo],
  );

  const onSearchHandler = useCallback((search: string) => {
    searchRegions(search);
  }, []);

  const toggleIsSetPoint = useCallback(
    (direction: directionPoints) => {
      if (isSetPoint) setDirectionPoint(null);
      else setDirectionPoint(direction);
    },
    [isSetPoint, setDirectionPoint],
  );

  return (
    <Box pl={5}>
      <AccordionFilter
        title={''}
        TitleComponent={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography>Между транспортными районами</Typography>
            <Typography
              onClick={ClearHandler}
              sx={theme => ({
                fontSize: theme.typography.pxToRem(13),
                color: '#3679EA',
              })}
            >
              Очистить
            </Typography>
          </Stack>
        }
      >
        <Grid
          container
          spacing={2}
          flexDirection={'column'}
        >
          <PointItem
            disabled={isSetPoint}
            options={options}
            pointLabel={'А'}
            selected={fromSelectedRegions}
            onSelected={fromSelectHandler}
            onSearch={onSearchHandler}
            onClickPoint={() => toggleIsSetPoint(directionPoints.from)}
          />
          <Stack
            mb={0.5}
            ml={1}
            mt="-2px"
            direction="row"
            gap={1}
            width="calc(100% - 70px)"
            flexWrap="wrap"
          >
            {fromSelectedRegions.map(selectedOption => (
              <ChipItem
                key={`selected-${selectedOption.value}`}
                label={selectedOption.label}
                onDelete={() => fromRemoveHandler(selectedOption)}
              />
            ))}
          </Stack>
          <PointItem
            disabled={isSetPoint}
            options={options}
            selected={toSelectedRegions}
            onSelected={toSelectHandler}
            onSearch={onSearchHandler}
            pointLabel={'Б'}
            onClickPoint={() => toggleIsSetPoint(directionPoints.to)}
          />
          <Stack
            mb={0.5}
            ml={1}
            mt="-2px"
            direction="row"
            gap={1}
            width="calc(100% - 70px)"
            flexWrap="wrap"
          >
            {toSelectedRegions.map(selectedOption => (
              <ChipItem
                key={`selected-${selectedOption.value}`}
                label={selectedOption.label}
                onDelete={() => toRemoveHandler(selectedOption)}
              />
            ))}
          </Stack>
        </Grid>
      </AccordionFilter>
    </Box>
  );
};
