import {
  IQuery,
  IQueryGetComputingRegistryInstanceByIdArgs,
} from '@api/gql/types';
import { createApi, createEffect, createStore } from 'effector';

const initState: YearLineStore = {
  selectedYear: 2023,
  maxYear: 2027,
  minYear: 2023,
};

export const $YearLine = createStore<YearLineStore>(initState);

export const YearLineApi = createApi($YearLine, {
  setSelectedYear: (store, year) => ({ ...store, selectedYear: year }),
  setMaxYear: (store, year) => ({ ...store, maxYear: year || store.maxYear }),
  setMinYear: (store, year) => ({ ...store, nimYear: year || store.minYear }),

  setMinMaxYear: (store, payload: SetMinMaxYearPayload) => ({
    ...store,
    minYear: payload.minYear,
    maxYear: payload.maxYear,
    selectedYear: payload.minYear,
  }),
  reset: () => initState,
});

export const getYearLineFx = createEffect<
  IQueryGetComputingRegistryInstanceByIdArgs,
  IQuery['getComputingRegistryInstanceById']
>();

export type SetMinMaxYearPayload = {
  minYear: number;
  maxYear: number;
};

type YearLineStore = {
  selectedYear: number;
  maxYear: number;
  minYear: number;
};
