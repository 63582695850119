import { ReactNode, SyntheticEvent } from 'react';

import {
  Alert,
  AlertColor,
  Snackbar,
  SnackbarCloseReason,
} from '@mui/material';

import { ClearIcon } from '@components/icons';
import { CheckOkOutlineIcon } from '@components/icons';

interface NotificationProps {
  isOpen: boolean;
  isSuccess: boolean;
  autoHideDuration?: number;
  successText?: string;
  errorText?: string;
  top?: number;

  onClose: () => void;
}

export const Notification = ({
  isOpen,
  onClose,
  autoHideDuration = 3000,
  top = 24,
  isSuccess,
  successText,
  errorText,
}: NotificationProps) => {
  const handleClose = (
    evt: Event | SyntheticEvent<unknown, Event>,
    reason: SnackbarCloseReason,
  ) => {
    evt?.preventDefault();
    if (reason !== 'clickaway') {
      onClose();
    }
  };

  const config: { icon: ReactNode; text?: string; severity: AlertColor } = {
    icon: isSuccess ? (
      <CheckOkOutlineIcon
        color={'success'}
        sx={{
          width: '24px',
          height: '24px',
        }}
      />
    ) : (
      <ClearIcon />
    ),
    text: isSuccess ? successText : errorText,
    severity: isSuccess ? 'success' : 'error',
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      sx={{
        borderRadius: '4px',

        '&.MuiSnackbar-root': {
          top: top,
        },
        '&:not(style)~:not(style)': {
          marginTop: 'unset',
        },
        '.MuiPaper-root': { alignItems: 'center', width: '532px' },
      }}
    >
      <Alert
        severity={config.severity}
        variant="filled"
        icon={config.icon}
        sx={{
          '.MuiAlert-icon': {
            margin: 'unset',
            paddingRight: '4px',
          },
        }}
      >
        {config.text}
      </Alert>
    </Snackbar>
  );
};
