import { sample } from 'effector';

import {
  $FilterMap,
  pushPassTrafficForSelectYear,
  resetAllFilters,
} from '@features/pt-forecast/stores';
import {
  PassengerTrafficType,
  filterVehicleChange,
} from '@features/pt-forecast/stores/charts/passTraffic';
import {
  $UnevennessCharts,
  resetUnevenness,
  setUnevennessSummary,
  setUnevennessVehicle,
} from '@features/pt-forecast/stores/charts/unevenness/store';

sample({
  clock: pushPassTrafficForSelectYear,
  fn: payload => {
    if (!payload) return null;
    return payload.unevenness;
  },
  target: setUnevennessVehicle,
});

sample({
  clock: [setUnevennessVehicle, filterVehicleChange],
  source: {
    FilterMap: $FilterMap,
    UnevennessCharts: $UnevennessCharts,
  },
  fn: ({ FilterMap, UnevennessCharts }) => {
    if (!UnevennessCharts.unevennessVehicle) return [];
    const result: number[] = Array.from({ length: 12 }).map((_, index) => {
      return Object.keys(UnevennessCharts.unevennessVehicle!).reduce(
        (acc, curr) => {
          const key = curr as keyof PassengerTrafficType['passengerTraffic'];
          const traffic = UnevennessCharts.unevennessVehicle![key][index] || 0;

          if (FilterMap[key]) return acc + traffic;
          else return acc;
        },
        0,
      );
    });
    return result;
  },
  target: setUnevennessSummary,
});

// Сброс графика неравномерности пассажиропотока
sample({
  clock: resetAllFilters,
  target: resetUnevenness,
});
