import { IPieChartItem } from '@features/tpu-calculation-scenarios-viewing/stores/charts/charts.store';

import { Box, Typography } from '@mui/material';

export const PieChartItem = (
  pieColors: string[],
  item: IPieChartItem,
  index: number,
  valueFormatter?: (value: number) => string,
) => {
  return (
    <Box
      key={index}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <Typography
        align={'right'}
        sx={{
          width: '65px',
          color: `${pieColors[index]}`,
          fontSize: '14px',
          lineHeight: '14px',
          fontWeight: '400',
          paddingRight: '8px',
        }}
      >
        {valueFormatter ? valueFormatter(item.value) : item.value}
      </Typography>

      <Box
        style={{
          height: '12px',
          width: '12px',
          borderRadius: '50%',
          backgroundColor: `${pieColors[index]}`,
        }}
      ></Box>

      <Typography
        sx={{
          color: '#606060',
          fontSize: '14px',
          lineHeight: '14px',
          fontWeight: '400',
          paddingY: '5px',
          paddingLeft: '8px',
        }}
      >
        {item.name}
      </Typography>
    </Box>
  );
};
