import { createApi, createStore } from 'effector';

const initState: SettingsStore = {
  readonly: true,
};

export const $Settings = createStore<SettingsStore>(initState);

export const SettingsApi = createApi($Settings, {
  setStateReadonly: (store, readonly: boolean) => ({ ...store, readonly }),
  reset: () => initState,
});

type SettingsStore = {
  readonly: boolean;
};
