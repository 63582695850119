import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import { Box, SxProps, Theme, Typography } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { TableTPU } from '@features/ag-forecast/containers/DashboardsContainer/components/SecondPage/TableTPU';
import { YearLineContainer } from '@features/ag-forecast/containers/DashboardsContainer/containers/YearLineContainer/YearLineContainer';
import { getTPUFx } from '@features/ag-forecast/stores/dashboard/secondPage/store';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';

// import { FilterContainer } from './FilterContainer';

// type SecondPageDashboardContainerType = {
//   onHideDashboardView: () => void;
// };

const ContainerMuiStyle: SxProps<Theme> = {
  borderTop: theme => `1px solid ${theme.palette.border.divider}`,
  display: 'flex',
};

export const SecondPageDashboardContainer = () =>
  // props: SecondPageDashboardContainerType,
  {
    // const { onHideDashboardView } = props;
    const { scenarioUUID } = useUnit($FeatureSettings);

    useEffect(() => {
      getTPUFx({ scenarioId: scenarioUUID || '' });
    }, [scenarioUUID]);

    return (
      <Box sx={ContainerMuiStyle}>
        {/* <FilterContainer onHideDashboardView={onHideDashboardView} /> */}

        <AutoFullHeight
          style={{
            padding: '24px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: theme => theme.palette.common.white,
              rowGap: '24px',
              height: 'calc(100% - 90px)',
              // height: '100%',
              overflowY: 'auto',
              p: 3,
            }}
          >
            <Typography
              variant={'subtitle2'}
              fontSize={theme => theme.typography.pxToRem(16)}
            >
              Сравнение транспортнопересадочных узлов в агломерации
            </Typography>

            <TableTPU />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 1.5,
              }}
            >
              <Typography fontSize={theme => theme.typography.pxToRem(12)}>
                *Совокупные транспортные затраты на поездку из центров
                транспортных районов до данного ТПУ (средневзвешенные по
                численности населения).
              </Typography>

              <Typography fontSize={theme => theme.typography.pxToRem(12)}>
                **Совокупные транспортные затраты на поездку из центров
                транспортных районов до данного ТПУ (средневзвешенные по
                пассажиропотоку).
              </Typography>
            </Box>
          </Box>

          <YearLineContainer />
        </AutoFullHeight>
      </Box>
    );
  };
