import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const MuiDialogTitle: Components<Theme>['MuiDialogTitle'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.subtitle1.fontWeight,
      [`&:has(+ .dialog-close-button)`]: {
        paddingRight: theme.spacing(8),
      },
    }),
  },
};
