export const SOC_ECO_TH_1_3 = [
  {
    id: 'transportArea',
    label: 'Транспортный район',
  },
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'projectedPassTraffic',
    label: 'Прогнозируемый пассажиропоток, тыс. чел.',
  },
  {
    id: 'economicallyActivePopulation',
    label: 'Экономически активное население, тыс. чел.',
  },
];
