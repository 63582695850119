import { SvgIcon, SvgIconProps } from '@mui/material';

export const PlatformIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        fill="#A3D7EB"
        d="M4 11h16v6H4zM10 7h10v4H10z"
      />
    </SvgIcon>
  );
};
