// import { IComputingRegistryScenarioResultStatusChoices } from '@api/gql/ag-types';
import { EAgCalculationScenarioStatusType } from '@features/ag-constructor/constants/agCalculationScenarioStatuses';

export const getCalculationScenarioStatusType = (
  // type: IComputingRegistryScenarioResultStatusChoices,
  type: number,
) => {
  switch (type) {
    // case IComputingRegistryScenarioResultStatusChoices.A_1:
    case 1:
      return EAgCalculationScenarioStatusType.Success;
    // case IComputingRegistryScenarioResultStatusChoices.A_2:
    case 2:
      return EAgCalculationScenarioStatusType.Pending;
    // case IComputingRegistryScenarioResultStatusChoices.A_3:
    case 3:
      return EAgCalculationScenarioStatusType.Failure;
    // case IComputingRegistryScenarioResultStatusChoices.A_4:
    case 4:
      return EAgCalculationScenarioStatusType.Draft;
    // case IComputingRegistryScenarioResultStatusChoices.A_5:
    case 5:
      return EAgCalculationScenarioStatusType.Queue;
    default:
      return EAgCalculationScenarioStatusType.Failure;
  }
};
