import { apiClient } from '@api/client';
import { IMutation } from '@api/gql/types';
import { gql } from '@apollo/client';

export type QueryRunCalculateUserScenarioArgs = {
  id: string;
};

export const queryRunCalculateUserScenario = async ({
  id,
}: QueryRunCalculateUserScenarioArgs) => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  const result = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation RunCalcComputingRegistry($id: UUID) {
        runCalcComputingRegistry(id: $id) {
          result
          errors
        }
      }
    `,
    variables: { id },
  });
  if (result.errors?.length) throw new Error(result.errors[0].message);
  return { id };
};
