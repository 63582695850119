import { useState } from 'react';
import { Circle, Group, Image, Line } from 'react-konva';
import useImage from 'use-image';

import PlusSvg from '@components/icons/Plus.svg';
import CheckOkSvg from '@components/icons/Plus.svg';

import { UseSimulationModelHandlersType } from '@features/tpu-simulation-model/hooks';
import { IPoint } from '@features/tpu-simulation-model/types';
import { getBoundPoint } from '@features/tpu-simulation-model/utils/getBoundPoint';

interface RoomConstructorProps {
  points: IPoint[] | null;
  mousePosition: IPoint;
  onComplete: UseSimulationModelHandlersType['onComplete'];
}
interface StartPointProps {
  point: IPoint;
  isStartPoint?: boolean;
  opacity?: number;
  onComplete?: UseSimulationModelHandlersType['onComplete'];
  isThreePoints?: boolean;
}

const Point = ({
  point,
  isStartPoint,
  opacity,
  onComplete,
  isThreePoints,
}: StartPointProps) => {
  const [plus] = useImage(PlusSvg);
  const [checkOk] = useImage(CheckOkSvg);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const isPossibleToComplete = Boolean(isThreePoints && isStartPoint);
  const isPointSuccessful = isPossibleToComplete && isMouseOver;

  return (
    <Group
      onClick={e => {
        e.cancelBubble = !!onComplete;

        if (isPossibleToComplete && onComplete) onComplete();
      }}
      onMouseOver={() => setIsMouseOver(true)}
      onMouseOut={() => setIsMouseOver(false)}
      opacity={opacity}
    >
      <Circle
        x={point.x}
        y={point.y}
        radius={12}
        stroke={'#fff'}
        fill={isPointSuccessful ? '#83CA6B' : '#273B48'}
        strokeWidth={1}
      />
      <Image
        image={isPointSuccessful ? checkOk : plus}
        x={point.x - 8}
        y={point.y - 8}
      />
    </Group>
  );
};

export const Room = ({
  points,
  mousePosition,
  onComplete,
}: RoomConstructorProps) => {
  const isThreePoints = !!points && points.length >= 3;
  const boundMousePosition: IPoint = getBoundPoint(mousePosition, points);

  return (
    <>
      <Line
        strokeWidth={2}
        stroke="#3679EA"
        closed={false}
        points={points
          ?.flatMap(point => [point.x, point.y])
          ?.concat([boundMousePosition.x, boundMousePosition.y])}
      />
      {points?.map((point, index) => {
        return (
          <Point
            key={`${point.x}` + `${point.y}`}
            point={point}
            isStartPoint={!index}
            onComplete={onComplete}
            isThreePoints={isThreePoints}
          />
        );
      })}
      {!points && (
        <Point
          point={mousePosition}
          opacity={0.5}
        />
      )}
    </>
  );
};
