import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';

import { useMapFiltersHandlers } from '@components/MapFilters/useMapFiltersHandlers';

import { AccordionFilter } from './AccordionFilter';
import { BaseFilterSlotProps } from './interface';
import { typographyWhitCircleStyle } from './utils';

export const InfrastructureSlot = (props: BaseFilterSlotProps) => {
  const { fields } = props;
  const { setRailwayRootHandler, setAviaHandler, setAutoRootHandler } =
    useMapFiltersHandlers(props);

  return (
    <AccordionFilter title={'По видам транспорта'}>
      <Stack flexDirection={'column'}>
        <FormControlLabel
          checked={fields.railwayRoot}
          onChange={setRailwayRootHandler}
          control={<Checkbox />}
          label={
            <Typography
              sx={typographyWhitCircleStyle('white', '#7A5A5A')}
              variant={'body2'}
            >
              ЖД транспорт
            </Typography>
          }
        />
        <FormControlLabel
          checked={fields.autoRoot}
          onChange={setAutoRootHandler}
          control={<Checkbox />}
          label={
            <Typography
              sx={typographyWhitCircleStyle('#FF792E', '#FF792E')}
              variant={'body2'}
            >
              Автомобильный транспорт
            </Typography>
          }
        />
        <FormControlLabel
          checked={fields.avia}
          onChange={setAviaHandler}
          control={<Checkbox />}
          label={
            <Typography
              sx={typographyWhitCircleStyle('#00A1DC', '#00A1DC')}
              variant={'body2'}
            >
              Авиатранспорт
            </Typography>
          }
        />
      </Stack>
    </AccordionFilter>
  );
};
