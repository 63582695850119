import {
  $ComponentState,
  EViewerQueryParams,
} from '@features/tpu-calculation-scenarios-viewing/stores/componentState/componentState.store';
import { $ScenarioData } from '@features/tpu-calculation-scenarios-viewing/stores/scenarioId/scenarioId.store';
import { useUnit } from 'effector-react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { Box, Button } from '@mui/material';

import { ROUTES } from '@constants/routes';

import { ScenarioData } from '../ScenarioData';
import { ScenarioPlayer } from '../ScenarioPlayer';

export const Toolbar = () => {
  const navigate = useNavigate();

  const $componentState = useUnit($ComponentState);
  const $scenarioData = useUnit($ScenarioData);

  const isPlayer = $componentState === null;
  const isCharts = $componentState === EViewerQueryParams.passengerFlowCharts;
  const isCommercialArea =
    $componentState === EViewerQueryParams.commercialAreas;

  const goToRegister = () => {
    navigate(`${ROUTES.TPU_SCENARIOS}/${$scenarioData?.projectId}`);
  };

  const goToViewer = () => {
    navigate(
      `${ROUTES.TPU_SCENARIOS}/${$scenarioData.scenarioId}${ROUTES.TPU_SCENARIOS_VIEWER}`,
    );
  };

  const goToCharts = () => {
    navigate(
      `${ROUTES.TPU_SCENARIOS}/${$scenarioData.scenarioId}${ROUTES.TPU_SCENARIOS_VIEWER}` +
        `?page=${EViewerQueryParams.passengerFlowCharts}`,
    );
  };

  const goToCommercialAreas = () => {
    navigate(
      `${ROUTES.TPU_SCENARIOS}/${$scenarioData.scenarioId}${ROUTES.TPU_SCENARIOS_VIEWER}` +
        `?page=${EViewerQueryParams.commercialAreas}`,
    );
  };

  return (
    <Box
      sx={{
        padding: '7px 0',
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme => theme.palette.background.paper,
        borderBottom: '1px solid #EBECEF',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {(isCharts || isCommercialArea) && (
          <Button
            variant={'text'}
            onClick={goToViewer}
            sx={{
              [`& .MuiButton-startIcon > *:nth-of-type(1)`]: {
                fontSize: '12px',
              },
              mr: '10px',
              ml: '30px',
            }}
            startIcon={<ArrowBackIcon />}
            color={'info'}
          >
            Назад
          </Button>
        )}

        <ScenarioData data={$scenarioData} />
      </Box>

      <ScenarioPlayer />

      {isPlayer && (
        <Box sx={{ width: '479px' }}>
          <Button
            sx={{
              mr: '24px',
              padding: '',
              width: '134px',
            }}
            onClick={goToCharts}
          >
            Дашборд
          </Button>
          <Button
            color={'secondary'}
            sx={{
              mr: '24px',
              width: '292px',
            }}
            onClick={goToRegister}
          >
            В реестр сценариев прогноза
          </Button>
        </Box>
      )}

      {(isCharts || isCommercialArea) && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              onClick={goToCharts}
              sx={{
                width: '18px',
                height: '18px',
                borderRadius: '50%',
                border: '1px solid #E21A1A',
                backgroundColor: isCharts ? '#E21A1A' : 'transparent',
                mr: '8px',
              }}
            />
            <Box
              onClick={goToCommercialAreas}
              sx={{
                width: '18px',
                height: '18px',
                borderRadius: '50%',
                border: '1px solid #E21A1A',
                backgroundColor: isCommercialArea ? '#E21A1A' : 'transparent',
                mr: '24px',
              }}
            />
          </Box>
          <Button
            variant={'outlined'}
            endIcon={
              isCharts && <ExpandCircleDownOutlinedIcon color="secondary" />
            }
            startIcon={
              isCommercialArea && (
                <ExpandCircleDownOutlinedIcon color="secondary" />
              )
            }
            sx={{
              mr: '40px',
              padding: '',
              width: '158px',
              '.MuiButton-endIcon': {
                position: 'relative',
                transform: 'rotate(-90deg)',
              },
              '.MuiButton-startIcon': {
                position: 'relative',
                transform: 'rotate(90deg)',
              },
            }}
            onClick={isCharts ? goToCommercialAreas : goToCharts}
          >
            {isCharts ? 'Следующий' : 'Предыдущий'}
          </Button>
        </Box>
      )}
    </Box>
  );
};
