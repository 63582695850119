import { MouseEventHandler } from 'react';

import { Button, DialogActions } from '@mui/material';

interface ActionsProps {
  onClose: MouseEventHandler<HTMLButtonElement>;
  onDelete: MouseEventHandler<HTMLButtonElement>;
  isResolve: boolean;
}

export const Actions = ({ onClose, onDelete, isResolve }: ActionsProps) => {
  return (
    <DialogActions>
      {!isResolve && (
        <Button
          fullWidth
          onClick={onClose}
        >
          Не удалять
        </Button>
      )}
      {!isResolve && (
        <Button
          fullWidth
          variant={'outlined'}
          onClick={onDelete}
        >
          Удалить
        </Button>
      )}
      {isResolve && (
        <Button
          onClick={onClose}
          sx={{ width: '272px' }}
        >
          Закрыть
        </Button>
      )}
    </DialogActions>
  );
};
