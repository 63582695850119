import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  IconButton,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { LocationIcon } from '@components/icons';

import { RegionOptionItem } from '@features/ag-forecast/stores/lists/regionsOptions';

type PointItemProps = {
  disabled: boolean;
  options: RegionOptionItem[];
  selected: RegionOptionItem[];
  onSelected: (selected: RegionOptionItem[]) => void;
  onSearch: (search: string) => void;
  onClickPoint: () => void;
  pointLabel: string;
};

export const PointItem = (props: PointItemProps) => {
  const {
    disabled,
    options,
    selected,
    pointLabel,
    onSelected,
    onSearch,
    onClickPoint,
  } = props;

  const [value, setValue] = useState<RegionOptionItem | null | string>('');

  const onSelectHandler = useCallback(
    (
      _event: React.SyntheticEvent<Element, Event>,
      value: string | RegionOptionItem | null,
    ) => {
      if (!!value && typeof value !== 'string' && value.value === 'all') {
        onSelected(options);
      } else if (typeof value !== 'string' && value !== null) {
        onSelected([value]);
      }

      setValue(value);
    },
    [onSelected, options],
  );

  const onSearchHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onSearch(event.target.value);
    },
    [onSearch],
  );

  useEffect(() => {
    if (value) setValue('');
  }, [value]);

  const extendedOptions = useMemo(() => {
    if (options.length)
      return [{ label: 'Выбрать все регионы', value: 'all' }, ...options];
    return [];
  }, [options]);

  return (
    <Grid
      xs={12}
      wrap={'nowrap'}
      display={'flex'}
      pb={0}
    >
      <Autocomplete
        sx={{ pb: '8px' }}
        fullWidth
        value={value}
        disabled={disabled}
        options={extendedOptions}
        freeSolo
        onChange={onSelectHandler}
        filterOptions={options => options}
        renderOption={(props, option) => (
          <FormControlLabel
            {...(props as FormControlLabelProps)}
            checked={
              option.value !== 'all'
                ? selected.some(
                    selectedOption => selectedOption.value === option.value,
                  )
                : options.length !== 0 && options.length === selected.length
            }
            control={<Checkbox />}
            label={<Typography variant={'body2'}>{option.label}</Typography>}
          />
        )}
        forcePopupIcon={false}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={'Найти'}
            onChange={onSearchHandler}
            onFocus={() => onSearch('')}
            onBlur={() => onSearch('')}
          />
        )}
      />

      <ButtonLocation
        disabled={disabled}
        onClick={onClickPoint}
      >
        <LocationIcon
          text={pointLabel}
          sx={{ opacity: disabled ? 0.3 : 1 }}
        />
      </ButtonLocation>
    </Grid>
  );
};

const ButtonLocation = styled(IconButton)(() => ({
  width: '56px',
  marginRight: '-11px',
  color: 'black',
  marginTop: '-6px',
  marginLeft: '8px',
}));
