import { apiClient } from '@api/client';
import { IMutation, IQuery } from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { TPU_CLIENT_NAME } from '@constants/api';

import {
  createBankFx,
  createBankomatFx,
  createBuffetFx,
  createDoorFx,
  createElevatorFx,
  createExitFx,
  createInformationTableFx,
  createKioskFx,
  createLadderFx,
  createOtherFx,
  createPassengerGeneratorFx,
  createPathFx,
  createPlatformFx,
  createRestaurantFx,
  createRoomFx,
  createSecurityCheckpointFx,
  createStoreFx,
  createTicketOfficeFx,
  createTicketPrintingMachineFx,
  createToiletFx,
  createTurnstileFx,
  createVendingMachineFx,
  createWaitingFx,
  createWallFx,
} from '@features/tpu-simulation-model/store/createdObject/createdObject.store';
import { triggerFindErrorsFn } from '@features/tpu-simulation-model/store/errorsModal/errorsModal.store';
import {
  $Floors,
  getFloorsInfoFx,
} from '@features/tpu-simulation-model/store/floors';
import {
  $ListOfFloorsWithListOfObjects,
  $ListOfObjects,
  $ListOfObjectsSortedByType,
  addObjectInListFn,
  clearObjectsInfoOnCurrentFloorFn,
  createListOfFloorsWithListsOfSimulationModelObjectsFn,
  deleteAllObjectsOnCurrentFloorFn,
  deleteObjectFn,
  getSimulationModelDataFx,
  resetListOfFloorsWithListOfObjectsFn,
  savePlanFn,
  savePlanFx,
  setListOfFloorsWithListOfObjectsFn,
  triggerForDeleteObjectFn,
  triggerForUpdateObjectDataByFloorIdFn,
  triggerForUpdateObjectDataFn,
  updateObjectDataByFloorIdFn,
  updateObjectDataFn,
} from '@features/tpu-simulation-model/store/listOfObjects/listOfObjects.store';
import {
  $ListOfRulers,
  setListOfRulersFn,
} from '@features/tpu-simulation-model/store/ruler/listOfRulers.store';
import { $ScenarioId } from '@features/tpu-simulation-model/store/scenario/scenario.store';
import {
  IListOfObjectsSortedByType,
  ListOfFloorsWithListOfObjectsDataType,
  ObjectOfTheSimulationModel,
  SaveSimulationModelPlanArgs,
  SimulationModelPlanData,
} from '@features/tpu-simulation-model/types';
import { preparePlanBeforeUpload } from '@features/tpu-simulation-model/utils/prepare-plan-before-upload';

$ListOfFloorsWithListOfObjects
  .reset(resetListOfFloorsWithListOfObjectsFn)
  .on(addObjectInListFn, (state, { data, floorId }) => {
    const { uuid } = data;
    state[floorId][uuid] = data;
    return { ...state };
  })
  .on(deleteObjectFn, (state, { objectId, floorId }) => {
    delete state[floorId][objectId];
    return { ...state };
  })
  .on(updateObjectDataByFloorIdFn, (state, { data, floorId }) => {
    const { uuid } = data;
    state[floorId][uuid] = data;
    return { ...state };
  })
  .on(updateObjectDataFn, (state, { data, floorId }) => {
    const { uuid } = data;
    state[floorId][uuid] = data;
    return { ...state };
  })
  .on(
    createListOfFloorsWithListsOfSimulationModelObjectsFn,
    (state, floors) => {
      if (!floors) return state;

      return floors.reduce<ListOfFloorsWithListOfObjectsDataType>(
        (acc, floor) => {
          return { ...acc, [floor.extraId]: {} };
        },
        {},
      );
    },
  )
  .on(setListOfFloorsWithListOfObjectsFn, (state, payload) => {
    const newState = { ...state };
    const listOfFloors = Object.keys(newState);
    const { plan } = payload;

    listOfFloors.forEach(floorId => {
      if (floorId in plan) {
        newState[floorId] = plan[floorId];
      }
    });

    return { ...newState };
  })
  .on(clearObjectsInfoOnCurrentFloorFn, (state, floorId) => {
    state[floorId] = {};
    return { ...state };
  });

sample({
  source: {
    floors: $Floors,
    listOfFloorsWithListOfObjects: $ListOfFloorsWithListOfObjects,
  },
  fn: ({ floors, listOfFloorsWithListOfObjects }) => {
    return { ...listOfFloorsWithListOfObjects?.[floors.selectedFloor] } ?? null;
  },
  target: $ListOfObjects,
});

sample({
  clock: [
    createRoomFx.done,
    createWallFx.done,
    createLadderFx.done,
    createPlatformFx.done,
    createDoorFx.done,
    createElevatorFx.done,
    createPathFx.done,
    createPassengerGeneratorFx.done,
    createExitFx.done,
    createTicketOfficeFx.done,
    createTicketPrintingMachineFx.done,
    createTurnstileFx.done,
    createSecurityCheckpointFx.done,
    createInformationTableFx.done,
    createToiletFx.done,
    createWaitingFx.done,
    createRestaurantFx.done,
    createBuffetFx.done,
    createVendingMachineFx.done,
    createStoreFx.done,
    createKioskFx.done,
    createBankFx.done,
    createBankomatFx.done,
    createOtherFx.done,
  ],
  source: $Floors,
  filter: (_, { result }) => !!result,
  fn: (sourceData, { result }) => ({
    data: result as ObjectOfTheSimulationModel,
    floorId: sourceData.selectedFloor,
  }),
  target: addObjectInListFn,
});

sample({
  source: $ListOfObjects,
  fn: sourceData => {
    return (
      sourceData &&
      Object.values(sourceData).reduce((acc, modelObject) => {
        const list = acc[modelObject.type];
        return {
          ...acc,
          [modelObject.type]: list ? [...list, modelObject] : [modelObject],
        };
      }, {} as IListOfObjectsSortedByType)
    );
  },
  target: $ListOfObjectsSortedByType,
});

sample({
  clock: triggerForUpdateObjectDataFn,
  source: $Floors,
  fn: (sourceData, clockData) => ({
    data: clockData,
    floorId: sourceData.selectedFloor,
  }),
  target: updateObjectDataFn,
});

sample({
  clock: triggerForUpdateObjectDataByFloorIdFn,
  source: $Floors,
  fn: (_, clockData) => ({
    data: clockData,
    floorId: clockData.floorId,
  }),
  target: updateObjectDataByFloorIdFn,
});

sample({
  clock: triggerForDeleteObjectFn,
  source: $Floors,
  fn: (sourceData, clockData) => ({
    objectId: clockData,
    floorId: sourceData.selectedFloor,
  }),
  target: deleteObjectFn,
});

sample({
  clock: getFloorsInfoFx.done,
  fn: ({ result }) => result,
  target: createListOfFloorsWithListsOfSimulationModelObjectsFn,
});

sample({
  clock: createListOfFloorsWithListsOfSimulationModelObjectsFn,
  source: $ScenarioId,
  filter: (id): id is string => !!id,
  target: getSimulationModelDataFx,
});

sample({
  clock: savePlanFn,
  source: {
    plan: $ListOfFloorsWithListOfObjects,
    id: $ScenarioId,
    ruler: $ListOfRulers,
  },
  filter: (sourceData): sourceData is SaveSimulationModelPlanArgs =>
    !!sourceData.id,
  target: savePlanFx,
});

sample({
  clock: deleteAllObjectsOnCurrentFloorFn,
  source: $Floors,
  fn: sourceData => sourceData.selectedFloor,
  target: clearObjectsInfoOnCurrentFloorFn,
});

sample({
  clock: getSimulationModelDataFx.done,
  filter: ({ result }) => !!result,
  fn: ({ result }) => JSON.parse(result as string) as SimulationModelPlanData,
  target: [
    setListOfFloorsWithListOfObjectsFn,
    setListOfRulersFn,
    triggerFindErrorsFn,
  ],
});

getSimulationModelDataFx.use(async id => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query PlanByScenarioId($id: UUID!) {
        planByScenarioId(scenarioId: $id) {
          document
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data.planByScenarioId?.document ?? null;
});

savePlanFx.use(async ({ plan, id, ruler }) => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation UpdatePlan($document: JSONString, $id: UUID!) {
        updatePlan(document: $document, scenarioId: $id) {
          ok
          errors {
            key
            message
          }
        }
      }
    `,
    variables: {
      id,
      document: preparePlanBeforeUpload({ plan, ruler }),
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  console.log('План сохранен?:', response.data?.updatePlan?.ok);

  return !!response.data?.updatePlan?.ok;
});
