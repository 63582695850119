import { sample } from 'effector';

import {
  PassTrafficBySexApi,
  PassTrafficBySexStore,
} from '@features/pt-forecast/stores/charts/passTrafficBySex/store';
import { getComputedPassTrafficFx } from '@features/pt-forecast/stores/computed/store';
import { FilterRegionApi } from '@features/pt-forecast/stores/filterRegion/store';
import { resetAllFilters } from '@features/pt-forecast/stores/filters/store';

// Запись пасс потока по половым группам в стор
sample({
  clock: getComputedPassTrafficFx.done,
  fn: payload =>
    payload.result?.map(item => ({
      ...item?.passengerTrafficBySex,
      year: item?.year,
    })) as PassTrafficBySexStore,
  target: PassTrafficBySexApi.setPassTrafficBySex,
});

// Сброс данных при сбросе фильтров
sample({
  clock: [resetAllFilters, FilterRegionApi.reset],
  target: PassTrafficBySexApi.reset,
});
