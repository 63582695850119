import { useUnit } from 'effector-react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';

import { useSimulationModelHandlers } from '@features/tpu-simulation-model/hooks';
import {
  $InputFindObject,
  $ListOfFloorsWithListOfObjects,
  $Project,
} from '@features/tpu-simulation-model/store';
import {
  ActionBeforeOpenErrorsWindow,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

export const Toolbar = () => {
  const $project = useUnit($Project);
  const $listOfFloorsWithListOfObjects = useUnit(
    $ListOfFloorsWithListOfObjects,
  );
  const $inputFindObject = useUnit($InputFindObject);
  const {
    openDefaultHubInfo,
    triggerFindErrors,
    openPlanErrorsWindow,
    chooseValueForInputFindObject,
  } = useSimulationModelHandlers();

  const objectOptions = Object.values($listOfFloorsWithListOfObjects)
    .flatMap<ObjectOfTheSimulationModel>(Object.values)
    .reduce<{ label: string; id: string }[]>((acc, item) => {
      if ('name' in item) {
        return [...acc, { label: item.name, id: item.uuid }];
      }

      return acc;
    }, []);

  const openErrorsModal = (action: ActionBeforeOpenErrorsWindow) => () => {
    triggerFindErrors({ saveAfterAnalysis: true });
    openPlanErrorsWindow(action);
  };

  return (
    <Stack
      sx={{
        padding: '18px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Button
        variant={'text'}
        onClick={openDefaultHubInfo}
        sx={{
          fontWeight: 'bold',
        }}
      >
        {$project.name ?? ''}
      </Button>
      <Box>
        <Autocomplete
          value={$inputFindObject.value}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={'Найти объект'}
              sx={{
                mr: '22px',
                width: '450px',
              }}
              size="medium"
              InputProps={{
                ...params.InputProps,
                type: 'search',
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: '#F6F6F6 !important',

                  '&.MuiOutlinedInput-root': {
                    paddingRight: '10px !important',
                  },
                },
              }}
            />
          )}
          options={objectOptions}
          getOptionLabel={option => option.label}
          getOptionKey={option => option.id}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(_, value) => chooseValueForInputFindObject(value)}
          sx={{
            display: 'inline-flex',
          }}
        />
        <Button
          sx={{ padding: '14px 44px' }}
          onClick={openErrorsModal(
            ActionBeforeOpenErrorsWindow.GoToPassengerTypesEditor,
          )}
        >
          Редактор типов пассажиров
        </Button>
        <Button
          sx={{ padding: '14px 44px', ml: '20px' }}
          onClick={openErrorsModal(ActionBeforeOpenErrorsWindow.Save)}
        >
          Сохранить план
        </Button>
      </Box>
      <Button
        color={'secondary'}
        sx={{ padding: '14px 44px' }}
        onClick={openErrorsModal(
          ActionBeforeOpenErrorsWindow.GoToScenarioRegistry,
        )}
      >
        В реестр сценариев прогноза
      </Button>
    </Stack>
  );
};
