import { apiClient } from '@api/client';
import { IQuery } from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { TPU_CLIENT_NAME } from '@constants/api';

import { $Floors } from '@features/tpu-calculation-scenarios-viewing/stores/floors/floors.store';
import {
  $ListOfObjects,
  $Plan,
  getPlanFx,
  resetPlanFn,
  setPlanFn,
} from '@features/tpu-calculation-scenarios-viewing/stores/plan/plan.store';
import { setListOfRulers } from '@features/tpu-calculation-scenarios-viewing/stores/ruler/ruler.store';
import {
  $ScenarioData,
  getScenarioIdFn,
} from '@features/tpu-calculation-scenarios-viewing/stores/scenarioId/scenarioId.store';
import { SimulationModelPlanData } from '@features/tpu-simulation-model/types';

$Plan.reset(resetPlanFn).on(setPlanFn, (_, payload) => {
  return payload.plan;
});

sample({
  clock: getScenarioIdFn,
  source: $ScenarioData,
  filter: ({ scenarioId }) => !!scenarioId,
  fn: scenarioData => scenarioData.scenarioId as string,
  target: getPlanFx,
});

sample({
  clock: getPlanFx.done,
  filter: ({ result }) => !!result,
  fn: ({ result }) => JSON.parse(result as string) as SimulationModelPlanData,
  target: [setPlanFn, setListOfRulers],
});

sample({
  source: {
    floors: $Floors,
    plan: $Plan,
  },
  fn: ({ floors, plan }) => {
    return { ...plan?.[floors.selectedFloor] } ?? null;
  },
  target: $ListOfObjects,
});

getPlanFx.use(async id => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query PlanByScenarioId($id: UUID!) {
        planByScenarioId(scenarioId: $id) {
          document
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data.planByScenarioId?.document ?? null;
});
