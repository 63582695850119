import {
  IQuery,
  IQueryGetProjectTransportDistrictsByPrefixArgs,
} from '@api/gql/ag-types';
import { createApi, createEffect, createEvent, createStore } from 'effector';

const defaultStore = {
  from: [],
  to: [],
  options: [],
};

export const $RegionOptions = createStore<RegionOptionsStore>(defaultStore);

export const RegionOptionsApi = createApi($RegionOptions, {
  setFrom: (store, payload: RegionOptionItem[]) => ({
    ...store,
    from: payload,
  }),
  setTo: (store, payload: RegionOptionItem[]) => ({
    ...store,
    to: payload,
  }),
  setOptions: (store, payload: RegionOptionItem[]) => ({
    ...store,
    options: payload,
  }),
  clearFrom: store => ({
    ...store,
    from: [],
  }),
  clearTo: store => ({
    ...store,
    to: [],
  }),
  clearOptions: store => ({
    ...store,
    options: [],
  }),
  reset: () => defaultStore,
});

export const searchRegions = createEvent<string>();

export const getTransportDistrictsByPrefixFx = createEffect<
  IQueryGetProjectTransportDistrictsByPrefixArgs,
  IQuery['getProjectTransportDistrictsByPrefix']
>();

export type RegionOptionItem = {
  value: string;
  label: string;
};

export type RegionOptionsStore = {
  from: RegionOptionItem[];
  to: RegionOptionItem[];
  options: RegionOptionItem[];
};
