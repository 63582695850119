import { Maybe } from 'graphql/jsutils/Maybe';

type convertUnitOfMeasureArgs = {
  value: Maybe<number> | undefined;
  isNumberNamingSystem?: boolean;
  unit?: string;
  isReturnArray?: boolean;
  singleDigitAccuracy?: number;
};

type convertUnitOfMeasureReturn = string | [string, string, string];

/**
 * Изменение числа в высший возможный разряд
 * @param value - число
 * @param isNumberNamingSystem - добавлять ли имя разряда
 * @param unit - единица измерения после числа и разряда
 * @param isReturnArray - вывод результата массивом
 * @param singleDigitAccuracy - количество единиц после запятой для числа в единичном разряде
 * @return строка с числом в новом разряде или массив с числом, разрядом и единицей измерения
 */
export const convertUnitOfMeasure = ({
  value,
  isNumberNamingSystem = true,
  unit = '',
  isReturnArray = false,
  singleDigitAccuracy = 2,
}: convertUnitOfMeasureArgs): convertUnitOfMeasureReturn => {
  if (value === null || value === undefined || isNaN(+value)) return '0';

  const number = +value;
  const numberLength = Math.trunc(number).toString().length;

  if (numberLength > 12) {
    const numberStr = String((number / 1_000_000_000_000).toFixed(2)).replace(
      '.',
      ',',
    );

    return isReturnArray
      ? [numberStr, 'трлн', unit]
      : numberStr + (isNumberNamingSystem ? ` трлн ${unit}` : ` ${unit}`);
  } else if (numberLength > 9) {
    const numberStr = String((number / 1_000_000_000).toFixed(2)).replace(
      '.',
      ',',
    );

    return isReturnArray
      ? [numberStr, 'млрд', unit]
      : numberStr + (isNumberNamingSystem ? ` млрд ${unit}` : ` ${unit}`);
  } else if (numberLength > 6) {
    const numberStr = String((number / 1_000_000).toFixed(2)).replace('.', ',');

    return isReturnArray
      ? [numberStr, 'млн', unit]
      : numberStr + (isNumberNamingSystem ? ` млн ${unit}` : ` ${unit}`);
  } else if (numberLength > 3) {
    const numberStr = String((number / 1_000).toFixed(2)).replace('.', ',');

    return isReturnArray
      ? [numberStr, 'тыс', unit]
      : numberStr + (isNumberNamingSystem ? ` тыс. ${unit}` : ` ${unit}`);
  } else {
    const numberStr = String(number.toFixed(singleDigitAccuracy)).replace(
      '.',
      ',',
    );

    return isReturnArray ? [numberStr, '', unit] : numberStr + ` ${unit}`;
  }
};

/**
 * Разделение разрядов числа пробелами
 * @param value - число или строковое число
 * @param fractionAcuracy - количество цифр дробной части числа
 * @return строка с пробелами между разрядами и запятой разделителем дробной части
 */
export const gapBetweenDigits = (
  value: number | string | undefined | null = 0,
  fractionAcuracy: number | undefined = 2,
) => {
  if (value === null || value === undefined) {
    return '';
  }

  const number = value.toString().split('.');
  number[0] = number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  if (number[1]) {
    number[1] = number[1].slice(0, fractionAcuracy);
  }

  if (!number[1]) {
    return number[0];
  }

  return number.join(',');
};

/**
 * Окончание исчисляемых слов
 * @param num - число определения окончания
 * @return окончание для исчисляемого слова
 */
export const endingCountingCouple = (num: number | undefined): string => {
  if (
    num === 11 ||
    num === 12 ||
    num === 13 ||
    num === 14 ||
    num === undefined
  ) {
    return '';
  }

  switch (num % 10) {
    case 1:
      return 'а';
    case 2:
    case 3:
    case 4:
      return 'ы';
    default:
      return '';
  }
};
