import { useUnit } from 'effector-react';

import { Box, Typography } from '@mui/material';

import { $Floors } from '@features/tpu-simulation-model/store';
import { $ListOfRulers } from '@features/tpu-simulation-model/store/ruler/listOfRulers.store';

interface AttributeProps {
  width: number;
  height: number;
}
export function ObjectSizeM2({ width, height }: AttributeProps) {
  let square = width * height;
  const $listOfRulers = useUnit($ListOfRulers) ?? {};
  const $floors = useUnit($Floors);
  const ruler = $listOfRulers[$floors.selectedFloor];

  if (ruler) {
    square = (square / Math.pow(ruler.px, 2)) * Math.pow(ruler.meters, 2);
  }
  square = Math.round(square);

  return (
    <Box>
      <Typography>
        Размеры,
        <Typography
          variant={'body1'}
          component={'span'}
          sx={{
            color: theme => theme.palette.text.secondary,
            display: 'inline-block',
            ml: '4px',
          }}
        >
          {ruler ? (
            <>
              м
              <Typography
                variant={'body1'}
                component={'sup'}
                sx={{
                  fontSize: theme => theme.typography.pxToRem(10),
                }}
              >
                2
              </Typography>
            </>
          ) : (
            'px'
          )}
        </Typography>
      </Typography>
      <Typography
        sx={{
          color: theme => theme.palette.text.secondary,
          fontSize: theme => theme.typography.pxToRem(12),
          display: 'inline-block',
          ml: '4px',
        }}
      >
        {square}
      </Typography>
    </Box>
  );
}
