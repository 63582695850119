import { Box, Divider } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { Toolbar } from '@features/tpu-simulation-model/components/Toolbar';
import { EditableObjectsContainer } from '@features/tpu-simulation-model/containers/EditableObjectsContainer';
import { EditingObjectForm } from '@features/tpu-simulation-model/containers/EditingObjectForm/EditingObjectForm';
import { HubInfoContainer } from '@features/tpu-simulation-model/containers/HubInfoContainer';
import { ModelEditorContainer } from '@features/tpu-simulation-model/containers/ModelEditorContainer';

export const Component = () => {
  return (
    <AutoFullHeight
      style={{
        backgroundColor: '#fff',
        height: '100%',
      }}
    >
      <Toolbar />
      <Divider sx={{ borderColor: 'border.divider' }} />
      <Box
        sx={{
          height: 'calc(100vh - 80px - 90px - 1px)',
          display: 'grid',
          gridTemplateColumns: '474px 1fr 474px',
          gridTemplateAreas: '"a b c"',
        }}
      >
        <EditableObjectsContainer />
        <ModelEditorContainer />
        <HubInfoContainer />
        <EditingObjectForm />
      </Box>
    </AutoFullHeight>
  );
};
