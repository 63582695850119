import { EVehicleAg } from '@features/ag-forecast/constants/EVehicleAg';
import { AgLayersStore } from '@features/ag-forecast/stores/map';

export const layoutToVehicleId = (layout: keyof AgLayersStore) => {
  switch (layout) {
    case 'graphAuto':
      return EVehicleAg.auto;
    case 'graphAvia':
      return EVehicleAg.avia;
    case 'graphBus':
      return EVehicleAg.bus;
    case 'graphFunicular':
      return EVehicleAg.funicular;
    case 'graphMetro':
      return EVehicleAg.metro;
    case 'graphMonoRailWay':
      return EVehicleAg.monoRailWay;
    case 'graphPedestrain':
      return EVehicleAg.pedestrain;
    case 'graphRopeWay':
      return EVehicleAg.ropeWay;
    case 'graphSuburbanRailway':
      return EVehicleAg.suburbanRailway;
    case 'graphTrolleybus':
      return EVehicleAg.trolleybus;
    case 'graphTram':
      return EVehicleAg.tram;
    case 'graphWaterWay':
      return EVehicleAg.waterWay;
    default:
      throw new Error(`Unknown layout - ${layout}`);
  }
};
