import { IQuery, IQueryGetGraphDirectionsArgs } from '@api/gql/types';
import { gql } from '@apollo/client';
import { apiClient } from 'src/api/provider';

export const queryGetGraphDirections = async (
  params: IQueryGetGraphDirectionsArgs,
) => {
  const result = await apiClient.query<IQuery, IQueryGetGraphDirectionsArgs>({
    query: gql`
      query QueryGetGraphDirections($scenarioId: UUID!) {
        getGraphDirections(scenarioId: $scenarioId) {
          id
        }
      }
    `,
    variables: params,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result?.data?.getGraphDirections;
};
