import { useEffect } from 'react';

export const useEscapePress = (onPress: () => void) => {
  useEffect(() => {
    const onCancel = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onPress();
      }
    };

    window.addEventListener('keydown', onCancel);

    return () => window.removeEventListener('keydown', onCancel);
  }, [onPress]);
};
