import { apiClient } from '@api/client';
import {
  IComputingRegistryType,
  IQuery,
  IQueryGetComputingRegistryInstanceByIdArgs,
} from '@api/gql/types';
import { gql } from '@apollo/client';

export const queryGetScenarioStatuses = async (
  params: IQueryGetComputingRegistryInstanceByIdArgs,
) => {
  const result = await apiClient.query<
    Pick<IQuery, 'getComputingRegistryInstanceById'>
  >({
    query: gql`
      query QueryGetScenarioStatuses($id: String) {
        getComputingRegistryInstanceById(id: $id) {
          resultStatus
          link
        }
      }
    `,
    variables: params,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result?.data?.getComputingRegistryInstanceById;
};

export type PollingStatusesResponse = Pick<
  IComputingRegistryType,
  'id' | 'resultStatus'
>[];
