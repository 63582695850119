import { sample } from 'effector';

import { PassFlowTableContentApi } from '@features/ag-forecast/stores/lists/passFlowTableContent';
import { SelectedFeaturesApi } from '@features/ag-forecast/stores/map';
import { UIApi } from '@features/ag-forecast/stores/ui/store';

// Если открылась панель редактирования тпу, сбросить выделенные фичи
sample({
  clock: UIApi.setEditTPUMode,
  filter: payload => !!payload,
  fn: () => null,
  target: SelectedFeaturesApi.setSelectedFeatures,
});

// Если вышли из режима редактирования, сбросить флаги установки инфры
sample({
  clock: UIApi.setEditTPUMode,
  filter: payload => !payload,
  fn: () => false,
  target: [UIApi.setPlaceTPU, UIApi.setCreateGraph, UIApi.setInspector],
});

// Сброс выделенных фич при смене режима фильтра
sample({
  clock: UIApi.setViewMode,
  fn: () => null,
  target: SelectedFeaturesApi.setSelectedFeatures,
});

// Закрытие редактора ТПУ при переходе на слой паспотока
sample({
  clock: UIApi.setViewMode,
  fn: () => false,
  target: UIApi.setEditTPUMode,
});

// Закрытие таблицы при переходе на слой инфры
sample({
  clock: UIApi.setViewMode,
  fn: () => false,
  target: UIApi.setShowPassFlowTable,
});

// Сброс флага установки поинта при переключении типа отображения
sample({
  clock: UIApi.setViewMode,
  fn: () => false,
  target: UIApi.setIsSetPoint,
});

// Если данные таблицы были очищены, закрыть таблицу
sample({
  clock: PassFlowTableContentApi.clearRows,
  fn: () => false,
  target: UIApi.setShowPassFlowTable,
});
