import { Notification as NotificationBase } from '@components/notification';

import { useContextPRHandlers } from '@features/tpu-project-registry/hooks';
import {
  INotification,
  NotificationType,
} from '@features/tpu-project-registry/stores';

interface NotificationProps {
  data: INotification;
  index: number;
}

const config = {
  [NotificationType.UpdateProject]: {
    successText: 'Проект успешно сохранен',
    errorText: 'Ошибка сохранения проекта',
  },
  [NotificationType.CopyProject]: {
    successText: 'Проект успешно скопирован',
    errorText: 'Ошибка копирования проекта',
  },
  [NotificationType.NotUniqueProjectName]: {
    successText: '',
    errorText: 'Не уникальное имя проекта',
  },
};

export const Notification = ({ data, index }: NotificationProps) => {
  const { isOpen, isSuccess, type, id } = data;
  const { hideNotification, removeNotification } = useContextPRHandlers();

  const handleClose = () => {
    hideNotification(id);
    setTimeout(removeNotification, 195, id);
  };

  const text = config[type];

  return (
    <NotificationBase
      isOpen={isOpen}
      isSuccess={isSuccess}
      successText={text.successText}
      errorText={text.errorText}
      top={index * (8 + 50) + 24}
      onClose={handleClose}
    />
  );
};
