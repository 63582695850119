import { SvgIcon, SvgIconProps } from '@mui/material';

export const Play = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="currentColor"
      {...props}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="currentColor"
      >
        <path
          d="M0 2.00744C0 0.486733 1.62693 -0.480209 2.9627 0.246608L13.9761 6.23922C15.3714 6.99839 15.3714 9.00157 13.9761 9.76082L2.9627 15.7534C1.62693 16.4802 0 15.5132 0 13.9926V2.00744Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
