import { createElement, memo } from 'react';

import { Ladder } from './ladder';
import { Path } from './path';
import { Platform } from './platform';
import { Room } from './room';
import { ServiceOrCommerceObject } from './service-or-commerce-object';
import { Wall } from './wall';

import {
  EditableObjectType,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

interface SpatialObjectComponentProps {
  data: ObjectOfTheSimulationModel;
  opacity: number;
}

const SpatialObjectComponent = (props: SpatialObjectComponentProps) => {
  const { data, opacity } = props;
  switch (data.type) {
    case EditableObjectType.Room:
      return createElement(Room, { ...data, opacity });
    case EditableObjectType.Wall:
      return createElement(Wall, { ...data, opacity });
    case EditableObjectType.Ladder:
      return createElement(Ladder, data);
    case EditableObjectType.Platform:
      return createElement(Platform, data);
    case EditableObjectType.Path:
      return createElement(Path, data);
    case EditableObjectType.PassengerGenerator:
    case EditableObjectType.Exit:
    case EditableObjectType.TicketOffice:
    case EditableObjectType.TicketPrintingMachine:
    case EditableObjectType.SecurityCheckpoint:
    case EditableObjectType.Toilet:
    case EditableObjectType.Turnstile:
    case EditableObjectType.Waiting:
    case EditableObjectType.InformationTable:
    case EditableObjectType.Restaurant:
    case EditableObjectType.Buffet:
    case EditableObjectType.VendingMachine:
    case EditableObjectType.Store:
    case EditableObjectType.Kiosk:
    case EditableObjectType.Bank:
    case EditableObjectType.Bankomat:
    case EditableObjectType.Other:
    case EditableObjectType.Elevator:
    case EditableObjectType.Door:
      return createElement(ServiceOrCommerceObject, data);
    default:
      null;
  }
};

export const SpatialObject = memo(SpatialObjectComponent);
