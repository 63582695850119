import { Map as MapOl } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { Pixel } from 'ol/pixel';

import { EMapFeatureLayout, EMapLayerMetaKeys } from '@constants/map';

import { layerLayoutKey } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { LAYERS_Z_INDEX } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/layersZIndex';

export type GetFeatureAtPixelArgs = {
  map: MapOl;
  pixel: Pixel;
  excludeLayouts?: string[];
  includeLayouts?: string[];
  hitTolerance?: number;
  layoutExtractor?: (feature: FeatureLike) => string;
  layersZIndex?: Partial<Record<EMapFeatureLayout, number>>;
};

const defaultLayoutExtractor = (feature: FeatureLike) =>
  feature.get(EMapLayerMetaKeys.layout);

/** Получение приоритетной фичи рядом с кликом  */
export const getFeatureAtPixel = (args: GetFeatureAtPixelArgs) => {
  const {
    map,
    excludeLayouts = [],
    includeLayouts,
    pixel,
    hitTolerance = 5,
    layoutExtractor = defaultLayoutExtractor,
    layersZIndex = LAYERS_Z_INDEX, // TODO: Отвязать от паспотока
  } = args;

  const allowedLayouts = includeLayouts || Object.values(EMapFeatureLayout);
  const clickedFeatures: FeatureLike[] = [];

  // const clickedFeaturesTest: FeatureLike[] = [];

  map.forEachFeatureAtPixel(
    pixel,
    feature => {
      // clickedFeaturesTest.push(feature);

      // Получить название слоя фичи
      const featureLayout = layoutExtractor(feature);

      // Игнорировать слой без названия
      if (!featureLayout) return;
      // Игнорировать слой из списка исключенных
      if (excludeLayouts.includes(featureLayout)) return;
      // Игнорировать если слоя нет в списке разрешенных
      if (!allowedLayouts.includes(featureLayout)) return;
      clickedFeatures.push(feature);
    },
    { hitTolerance },
  );

  // console.log(clickedFeaturesTest);

  if (!clickedFeatures.length) return null;

  // Сортировка фич по z индексу слоя
  return sortFeaturesByZIndex(clickedFeatures, layersZIndex)[0];
};

// Сортировка фич по z индексу слоя
const sortFeaturesByZIndex = (
  clickedFeatures: FeatureLike[],
  layersZIndex: Partial<Record<EMapFeatureLayout, number>>,
) =>
  clickedFeatures.sort((a, b) => {
    const aZIndex =
      layersZIndex[a.getProperties()[layerLayoutKey] as EMapFeatureLayout] || 0;
    const bZIndex =
      layersZIndex[b.getProperties()[layerLayoutKey] as EMapFeatureLayout] || 0;
    return aZIndex > bZIndex ? -1 : aZIndex > bZIndex ? 1 : 0;
  });
