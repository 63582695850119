import { IQuery, IQueryGetAgglomeration12Args } from '@api/gql/types';
import { gql } from '@apollo/client';
import { apiClient } from 'src/api/provider';

export const querySocEcoTable_1_2 = async (
  params: IQueryGetAgglomeration12Args,
) => {
  const result = await apiClient.query<IQuery, IQueryGetAgglomeration12Args>({
    query: gql`
      query QuerySocEcoTable_1_2($lon: Float!, $lat: Float!) {
        getAgglomeration12(lon: $lon, lat: $lat) {
          diff
          numberOfPlacements
          sumTotalFlow
          year
        }
      }
    `,
    variables: params,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result?.data?.getAgglomeration12;
};
