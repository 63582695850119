import { SvgIcon, SvgIconProps } from '@mui/material';

type RadioButtonCheckedIconProps = SvgIconProps & {
  hexcolor?: string;
};

export const RadioButtonCheckedIcon = (props: RadioButtonCheckedIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="12"
        fill={props.hexcolor || 'currentColor'}
      />

      <rect
        x="7"
        y="7"
        width="10"
        height="10"
        rx="5"
        fill="#F6F6F6"
      />
    </SvgIcon>
  );
};
