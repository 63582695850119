import { useUnit } from 'effector-react';
import { PropsWithChildren, useCallback } from 'react';

import {
  Box,
  Divider,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import { Accordion } from '@components/Accordion';
import { AutoFullHeight } from '@components/AutoFullHeight';
import { ChevronRightIcon } from '@components/icons';

import { DailyPassChartContainer } from '@features/pt-forecast-new/containers/ChartsContainer/DailyPassChartContainer/DailyPassChartContainer';
import { StructurePassChartContainer } from '@features/pt-forecast-new/containers/ChartsContainer/StructurePassChartContainer/StructurePassChartContainer';
import { UnevennessChartContainer } from '@features/pt-forecast-new/containers/ChartsContainer/UnevennessChartContainer/UnevennessChartContainer';
import { $ComputedData } from '@features/pt-forecast-new/stores/computedData';
import { $FilterMap } from '@features/pt-forecast-new/stores/filters';
import { UIApi } from '@features/pt-forecast-new/stores/ui';

export const ForecastDetailsContainer = () => {
  const { setShowDashboard, toggleForecastPanel } = useUnit(UIApi);
  const { toSelectedRegion, fromSelectedRegion } = useUnit($FilterMap);
  const { currentData } = useUnit($ComputedData);

  const openDashboardsHandler = useCallback(() => {
    setShowDashboard(true);
  }, [setShowDashboard]);

  const ClickableChartContainer = useCallback(
    ({ children }: PropsWithChildren) => (
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={openDashboardsHandler}
      >
        {children}
      </Box>
    ),
    [openDashboardsHandler],
  );

  const NoDataChartWrapper = useCallback(
    ({ children, force }: PropsWithChildren & { force?: boolean }) => {
      if (!currentData || force)
        return (
          <Typography
            sx={{
              width: '100%',
              textAlign: 'center',
              py: '16px',
              opacity: 0.7,
            }}
          >
            Нет данных
          </Typography>
        );
      return children;
    },
    [currentData],
  );

  return (
    <Box sx={ForecastContainerMuiStyle}>
      <AutoFullHeight
        style={{
          paddingTop: '32px',
          width: '500px',
          maxWidth: '500px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            mb: 3,
            pl: theme => theme.typography.pxToRem(44),
            pr: theme => theme.typography.pxToRem(24),
          }}
        >
          <IconButton
            sx={{
              width: '32px',
              height: '32px',
              backgroundColor: 'primary.main',
              borderRadius: 1,
              color: 'white',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            }}
            onClick={toggleForecastPanel}
          >
            <ChevronRightIcon />
          </IconButton>

          <Typography variant="subtitle2">
            {`${fromSelectedRegion?.name || ''} — ${
              toSelectedRegion?.name || ''
            }`}
          </Typography>
        </Box>

        <Accordion
          sx={{
            pl: theme => theme.typography.pxToRem(44),
            pr: theme => theme.typography.pxToRem(24),
          }}
          summaryComponent={
            <Typography variant={'subtitle2'}>
              Структура пассажиропотока
            </Typography>
          }
          detailsSx={{ padding: '0' }}
        >
          <NoDataChartWrapper>
            <ClickableChartContainer>
              <StructurePassChartContainer />
            </ClickableChartContainer>
          </NoDataChartWrapper>
        </Accordion>

        <Divider sx={{ my: '24px' }} />

        {currentData?.dailyUnevenness !== null && (
          <>
            <Accordion
              sx={{
                pl: theme => theme.typography.pxToRem(44),
                pr: theme => theme.typography.pxToRem(24),
              }}
              summaryComponent={
                <Typography variant={'subtitle2'}>
                  Суточная неравномерность
                </Typography>
              }
              detailsSx={{ padding: '0' }}
            >
              <NoDataChartWrapper>
                <ClickableChartContainer>
                  <DailyPassChartContainer />
                </ClickableChartContainer>
              </NoDataChartWrapper>
            </Accordion>

            <Divider sx={{ my: '24px' }} />
          </>
        )}

        <Accordion
          sx={{
            pl: theme => theme.typography.pxToRem(44),
            pr: theme => theme.typography.pxToRem(24),
            pb: theme => theme.typography.pxToRem(44),
          }}
          summaryComponent={
            <Typography variant={'subtitle2'}>
              Сезонная неравномерность
            </Typography>
          }
          detailsSx={{ padding: '0' }}
        >
          <NoDataChartWrapper>
            <ClickableChartContainer>
              <UnevennessChartContainer />
            </ClickableChartContainer>
          </NoDataChartWrapper>
        </Accordion>
      </AutoFullHeight>
    </Box>
  );
};

const ForecastContainerMuiStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '0',
  right: '0',
  zIndex: '2',
  background: theme => theme.palette.background.default,
  '& > *': {
    borderTop: '1px solid #EBECEF',
    boxShadow: 'rgba(0, 0, 0, 0.1) -5px 4px 2px 0px',
    overflowY: 'auto',
  },
};
