import { IFloorFileInfoType } from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

import {
  IElevator,
  ILadder,
  IListOfObjectsSortedByType,
  ListOfFloorsWithListOfObjectsDataType,
  ListOfObjectsDataType,
  ObjectOfTheSimulationModel,
  SaveSimulationModelPlanArgs,
  SimulationModelPlanData,
} from '@features/tpu-simulation-model/types';

export const $ListOfFloorsWithListOfObjects =
  createStore<ListOfFloorsWithListOfObjectsDataType>({});

export const $ListOfObjects = createStore<ListOfObjectsDataType | null>(null);

export const $ListOfObjectsSortedByType =
  createStore<Partial<IListOfObjectsSortedByType> | null>(null);

export const triggerForDeleteObjectFn = createEvent<string>();

export const deleteObjectFn = createEvent<{
  objectId: string;
  floorId: string;
}>();

export const addObjectInListFn = createEvent<{
  data: ObjectOfTheSimulationModel;
  floorId: string;
}>();

export const triggerForUpdateObjectDataFn =
  createEvent<ObjectOfTheSimulationModel>();

export const updateObjectDataFn = createEvent<{
  data: ObjectOfTheSimulationModel;
  floorId: string;
}>();

export const triggerForUpdateObjectDataByFloorIdFn = createEvent<
  ILadder | IElevator
>();

export const updateObjectDataByFloorIdFn = createEvent<{
  data: ILadder | IElevator;
  floorId: string;
}>();

export const createListOfFloorsWithListsOfSimulationModelObjectsFn =
  createEvent<IFloorFileInfoType[] | null>();

export const deleteAllObjectsOnCurrentFloorFn = createEvent();

export const clearObjectsInfoOnCurrentFloorFn = createEvent<string>();

export const getSimulationModelDataFx = createEffect<string, string | null>();

export const savePlanFn = createEvent<void>();

export const setListOfFloorsWithListOfObjectsFn =
  createEvent<SimulationModelPlanData>();

export const savePlanFx = createEffect<SaveSimulationModelPlanArgs, boolean>();

export const resetListOfFloorsWithListOfObjectsFn = createEvent();
