import {
  IMutation,
  IProjectSortEnum,
  IProjectType,
  IQuery,
  IQueryAllProjectsArgs,
} from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

interface IProjectRegistryData {
  projects: IProjectType[];
  page: number;
  pageSize: number;
  sort: IProjectSortEnum[] | null;
  search: string;
  count: number;
  loading: boolean;
}

export const $ProjectRegistry = createStore<IProjectRegistryData>({
  projects: [],
  page: 1,
  pageSize: 10,
  sort: null,
  search: '',
  count: 0,
  loading: false,
});

export const getListOfProjectsFn = createEvent();

export const getListOfProjectsFx = createEffect<
  IQueryAllProjectsArgs,
  IQuery['allProjects']
>();

export const setSearchFn = createEvent<string>();

export const setPageFn = createEvent<number>();

export const setRowsPerPageFn = createEvent<number>();

export const setSortFn = createEvent<GridSortModel>();

export const resetProjectRegistryFn = createEvent<void>();

export const copyProjectFx = createEffect<
  string,
  IMutation['copyProject'] | undefined
>();
