import { useForm } from 'react-hook-form';

import { Divider, Stack } from '@mui/material';

import { Coordinates } from '@features/tpu-simulation-model/components/FormAttributes/Coordinates';
import { FormActions } from '@features/tpu-simulation-model/components/FormAttributes/FormActions';
import { Input } from '@features/tpu-simulation-model/components/FormAttributes/Input';
import { ObjectPositionForPoints } from '@features/tpu-simulation-model/components/FormAttributes/ObjectPositionForPoints';
import { Title } from '@features/tpu-simulation-model/components/FormAttributes/Title';
import { useFloors } from '@features/tpu-simulation-model/hooks/useFloors';
import {
  IWall,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

interface FormProps extends IWall {
  onUpdateAndClose: (arg: ObjectOfTheSimulationModel) => void;
  onDeleteAndClose: (arg: string) => void;
}

export const Wall = ({
  onUpdateAndClose,
  onDeleteAndClose,
  ...props
}: FormProps) => {
  const { handleSubmit, formState, control } = useForm<IWall>({
    values: props,
  });
  const selectedFloorIndex = useFloors().selectedFloorIndex;

  return (
    <Stack
      padding={3}
      gap={3}
    >
      <Title title={'Стена'} />
      <Input<IWall>
        control={control}
        name={'name'}
        title={'Название объекта'}
        type={'string'}
      />
      <Divider />
      <Coordinates points={props.points} />
      <ObjectPositionForPoints
        floor={selectedFloorIndex + 1}
        points={props.points}
      />
      <Divider />
      <FormActions
        onSave={handleSubmit(onUpdateAndClose)}
        onDelete={() => onDeleteAndClose(props?.uuid)}
        disabled={!formState.isValid}
      />
    </Stack>
  );
};
