import { useForm } from 'react-hook-form';

import { Divider, Stack } from '@mui/material';

import { FormActions } from '@features/tpu-simulation-model/components/FormAttributes/FormActions';
import { Input } from '@features/tpu-simulation-model/components/FormAttributes/Input';
import { ObjectPosition } from '@features/tpu-simulation-model/components/FormAttributes/ObjectPosition';
import { ObjectSizeM2 } from '@features/tpu-simulation-model/components/FormAttributes/ObjectSizeM2';
import { RadioList as Radio } from '@features/tpu-simulation-model/components/FormAttributes/Radio';
import { SelectOption as Select } from '@features/tpu-simulation-model/components/FormAttributes/Select';
import { Time } from '@features/tpu-simulation-model/components/FormAttributes/Time';
import { TimeRange } from '@features/tpu-simulation-model/components/FormAttributes/TimeRange';
import { Title } from '@features/tpu-simulation-model/components/FormAttributes/Title';
import { useListOfRoomsElevatorsLadders } from '@features/tpu-simulation-model/hooks';
import { useFloors } from '@features/tpu-simulation-model/hooks/useFloors';
import {
  IRestaurant,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';
import { getAttributeOptions } from '@features/tpu-simulation-model/utils/getAttributeOptions';

interface FormProps extends IRestaurant {
  onUpdateAndClose: (arg: ObjectOfTheSimulationModel) => void;
  onDeleteAndClose: (arg: string) => void;
}

export const Restaurant = ({
  onUpdateAndClose,
  onDeleteAndClose,
  ...props
}: FormProps) => {
  const { handleSubmit, formState, control } = useForm<IRestaurant>({
    values: props,
  });
  const roomOptions = useListOfRoomsElevatorsLadders().roomOptions;
  const selectedFloorIndex = useFloors().selectedFloorIndex;

  return (
    <Stack
      padding={3}
      gap={3}
      sx={{
        overflow: 'auto',
      }}
    >
      <Title title={'Ресторан'} />
      <Input<IRestaurant>
        control={control}
        name={'name'}
        title={'Название объекта'}
        type={'string'}
      />
      <Select<IRestaurant>
        control={control}
        name={'room'}
        title={'Помещение'}
        placeholder={''}
        options={roomOptions}
      />
      <Input<IRestaurant>
        control={control}
        name={'servedAtSameTime'}
        title={'Количество пассажиров, одновременно обслуживаемых объектом'}
        type={'number'}
      />
      <Time<IRestaurant>
        control={control}
        name={'averageServiceTime'}
        title={'Среднее время обслуживания'}
        defaultValue={props.averageServiceTime}
      />
      <Input<IRestaurant>
        control={control}
        name={'averageRevenue'}
        title={'Средняя выручка с 1 обслуживаемого пассажира, руб'}
        type={'number'}
      />
      <Input<IRestaurant>
        control={control}
        name={'averageCostPerDay'}
        title={'Средние затраты на 1 м2 в день, руб'}
        type={'number'}
      />
      <Input<IRestaurant>
        control={control}
        name={'bounceRate'}
        title={'Процент отказа в обслуживании, %'}
        type={'number'}
      />
      <TimeRange<IRestaurant>
        control={control}
        nameTime1={'openFrom'}
        nameTime2={'openUntil'}
      />
      <Radio<IRestaurant>
        control={control}
        name={'status'}
        title={'Статус'}
        options={getAttributeOptions('status')}
      />
      <Divider />
      <ObjectSizeM2
        width={props.width}
        height={props.height}
      />
      <ObjectPosition
        point={props.point}
        floor={selectedFloorIndex + 1}
      />
      <Divider />
      <Radio<IRestaurant>
        control={control}
        name={'fillsRoom'}
        title={'Занимает всю площадь помещения'}
        options={getAttributeOptions('fillsRoom')}
      />
      <FormActions
        onSave={handleSubmit(onUpdateAndClose)}
        onDelete={() => onDeleteAndClose(props?.uuid)}
        disabled={!formState.isValid}
      />
    </Stack>
  );
};
