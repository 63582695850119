import { SvgIcon, SvgIconProps } from '@mui/material';

export const Point = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <svg
        viewBox="0 -7 18 30"
        fill="#E21A1A"
      >
        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
      </svg>
    </SvgIcon>
  );
};
