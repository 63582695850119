import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';

import { YearLineV2 } from '@components/YearLine_v2';

import {
  $YearLineStore,
  YearLineApi,
} from '@features/ag-forecast/stores/yearLine/store';

export const YearLineContainer = () => {
  const { minYear, maxYear, selectedYear } = useUnit($YearLineStore);
  const { setSelectedYear } = YearLineApi;

  const selectYearHandler = useCallback(
    (year: number) => {
      setSelectedYear(year);
    },
    [setSelectedYear],
  );

  return (
    <Box sx={ContainerSx}>
      <YearLineV2
        baseYear={minYear}
        finalYear={maxYear}
        selectedYear={selectedYear}
        onSelect={selectYearHandler}
      />
    </Box>
  );
};

const ContainerSx: SxProps<Theme> = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  right: '0',
  zIndex: '1',
  background: theme => theme.palette.common.white,
  boxShadow:
    '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
};
