import {
  IAgglomerationType,
  IDataFileInfoType,
  IDataFileTypeEnum,
  IFloorFileInfoType,
  IHubProjectStateChoices,
  IHubType,
  IProjectType,
  IQueryProjectNameIsUniqueArgs,
  IUploadDataFileMutation,
  Maybe,
} from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

import {
  DEFAULT_DATA_FILES_STATE,
  DEFAULT_FLOOR_FILES_STATE,
} from '@features/tpu-project-registry/constants';
import { IFloorFile } from '@features/tpu-project-registry/types/form';

export interface FormData {
  projectId: string;
  name: string;
  agglomeration: IAgglomerationType | null;
  state: IHubProjectStateChoices;
  floorFiles: IFloorFile[];
  dataFiles: Record<IDataFileTypeEnum, IDataFileInfoType | null>;
  hubSearch: string;
  hub: IHubType | null;
}

export const $Form = createStore<FormData>({
  projectId: '',
  name: '',
  agglomeration: null,
  floorFiles: DEFAULT_FLOOR_FILES_STATE,
  dataFiles: DEFAULT_DATA_FILES_STATE,
  state: IHubProjectStateChoices.Draft,
  hubSearch: '',
  hub: null,
});

export const $IsOpenForm = createStore<boolean>(false);
export const $IsButtonSaveDisabled = createStore<boolean>(false);

export const $FileTemplates = createStore<string>('');

export const addFloorFileFieldFn = createEvent();

export const setFloorFileDescriptionFn = createEvent<{
  description: string;
  index: number;
}>();

export const setFloorFileFn = createEvent<{
  data: Omit<IFloorFileInfoType, 'created' | 'modified' | 'description'>;
  index: number;
}>();

export const setDataFileFn = createEvent<IUploadDataFileMutation['fileInfo']>();

export const setNameFn = createEvent<string>();

export const setHubFn = createEvent<IHubType | null>();

export const setAgglomerationFn = createEvent<IAgglomerationType | null>();

export const resetFormStateFn = createEvent();

export const openProjectForEditFn = createEvent<IProjectType>();

export const editProjectFn = createEvent<string>();

export const saveProjectFn = createEvent();

export const deleteFloorFileFn = createEvent<number>();

export const deleteDataFileFn = createEvent<IDataFileTypeEnum>();

export const resetProjectDataFn = createEvent();

export const setHubSearchFn = createEvent<string>();

export const changeFloorFilesOrderFn = createEvent<{
  transferId: string;
  catchId: string;
}>();

export const createDraftProjectFx = createEffect<void, string | undefined>();

export const updateProjectFx = createEffect<FormData, boolean>();

export const getFileTemplatesFx = createEffect<void, string>();

export const getProjectByIdFx = createEffect<
  string,
  Maybe<IProjectType> | undefined
>();

export const checkIsNameUniqueFx = createEffect<
  IQueryProjectNameIsUniqueArgs,
  boolean
>();
