import { styled } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

export interface LineIconProps {
  color: keyof Theme['palette']['customs'];
  height?: number;
}

export const LineIcon = styled('div')<LineIconProps>(
  ({ theme, color, height = 2 }) => ({
    border: '1px solid',
    borderColor: theme.palette.customs[color],
    borderRadius: theme.shape.borderRadius,
    width: theme.typography.pxToRem(48),
    height: theme.typography.pxToRem(height),
    justifySelf: 'center',
  }),
);
