import { IAirportType, ITransportRegionType } from '@api/gql/types';
import { Feature, Map as OLMap } from 'ol';
import { Geometry, MultiPolygon, Point } from 'ol/geom';
import VectorTileLayer from 'ol/layer/VectorTile';
import { fromLonLat } from 'ol/proj';
import { Source } from 'ol/source';
import { Style } from 'ol/style';

import { EFilterMapLayout, EFilterMapPoint } from '@components/MapFilters';

import {
  EMapFeatureLayout,
  EMapLayoutTypes,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { LAYER_TYPE } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/layersType';
import { addVectorLayer } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/layerTools';
import { getFeatureStyle } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools';
import { VehicleTypeCode } from '@features/passenger-traffic/stores/inspector/types';

/** Тип инфраструктуры */
export const layerLayoutKey = 'layout';
/** Идентификатор инфраструктуры */
export const layerIdKey = 'ol_uid';
/** Ключ активного стиля инфраструктуры */
export const layerSelectedKey = 'selected';
/** Ключ выделенной фичи на карте */
export const mapSelectedFeature = 'selectedFeature';

/**
 * Установка инфраструктуры на карту
 * @param data - данные о инфраструктуре и элементах
 */
export const setLayerFeaturesToMap = ({
  features,
  layout,
  map,
  width,
}: SetLayerFeaturesToMapProps) => {
  // Если метод пытается добавить тайловый слой
  if (LAYER_TYPE[layout] === EMapLayoutTypes.tile) {
    const layer = getMapLayer({ map: map, layout });
    return layer?.setVisible(true);
  }

  // установка стилей для элементов инфраструктуры
  features.forEach(feature => {
    feature.setStyle(
      (feature_, resolution) =>
        getFeatureStyle({
          layout,
          active: feature.get(layerSelectedKey),
          filterMapLayout: map.filterLayout || EFilterMapLayout.PassengerFlows, //TODO!! вынести из мапы, получать в аргументах
          width,
          feature: feature_,
          resolution,
          map,
        }) as Style,
    );
    feature.set('width', width);
  });

  // Создать слой с инфраструктурой
  addVectorLayer({ layout, map, width, features });
};

/**
 * Получить инфраструктуры слоя карта
 * @param data
 * @returns
 */
export const getMapLayerFeatures = ({
  map,
  layout,
}: GetMapLayerFeaturesProps) => {
  if (!map) {
    return [];
  }

  const layer = getMapLayer({ map: map, layout });
  if (layer instanceof VectorTileLayer) return [];

  const source = layer?.getSource() as LayerSource | null;

  if (!source) {
    return [];
  } else {
    return source.getFeatures();
  }
};

/**
 * Удаление инфраструктуры с карты
 */
export const removeLayerFeaturesFromMap = ({
  layout,
  map,
}: RemoveLayerFeaturesFromMapProps) => {
  const removeLayer = getMapLayer({ map, layout });
  if (removeLayer) {
    if (removeLayer instanceof VectorTileLayer) {
      removeLayer.setVisible(false);
    } else map?.removeLayer(removeLayer);
  }
};

export const getLineWidth = (zoom: number, stops: number[]) => {
  // Если зум меньше первого стопа, возвращаем первую ширину линии
  if (zoom < stops[0]) {
    return stops[1];
  }

  // Если зум больше последнего стопа, возвращаем последнюю ширину линии
  const lastIndex = stops.length - 2;
  if (zoom >= stops[lastIndex]) {
    return stops[lastIndex + 1];
  }

  // Поиск двух стопов между которыми находится текущее значение зума
  for (let i = 0; i < lastIndex; i += 2) {
    const nextZoomLevel = stops[i + 2];

    if (zoom < nextZoomLevel) {
      const prevZoomLevel = stops[i];
      const prevLineWidth = stops[i + 1];
      const nextLineWidth = stops[i + 3];

      const interpolation =
        (zoom - prevZoomLevel) / (nextZoomLevel - prevZoomLevel);
      const result =
        prevLineWidth + (nextLineWidth - prevLineWidth) * interpolation;

      // Округление результата до ближайшего кратного 0.25
      return Math.round(result * 4) / 4;
    }
  }
};

/**
 * Получить слой инфраструктуры карты
 * @param data
 * @returns
 */
const getMapLayer = ({ map, layout }: GetMapLayoutLayerProps) => {
  return map.getAllLayers().find(layer => layer.getClassName() === layout);
};

// TODO: Временное решение
export const exchangeProxyLayouts = (layout: EMapFeatureLayout) => {
  switch (layout) {
    case EMapFeatureLayout.directionHighway:
      return EMapFeatureLayout.highway;
    case EMapFeatureLayout.directionRailway:
      return EMapFeatureLayout.railway;
    default:
      return layout;
  }
};

export const createMultiAreaBordersFeature = (
  regions: ITransportRegionType[],
) =>
  regions.map(item => {
    const multipolygon = item.border?.geometry.coordinates.map(
      ([polygon]: number[][][]) => polygon.map(item => fromLonLat(item)),
    );

    return new Feature({
      geometry: new MultiPolygon([multipolygon]),
      [layerIdKey]: item.id,
      [layerLayoutKey]: EMapFeatureLayout.areaBorder,
    });
  });

// Создание фич из массива станций TODO: Добавить типизацию для автобусных остановок и жд станций
export const createStationFeatures = (
  stations: IAirportType[],
  layout: EMapFeatureLayout,
) =>
  stations.map(
    item =>
      new Feature({
        geometry: new Point(fromLonLat(item.geometry.coordinates)),
        [layerIdKey]: item.id,
        [layerLayoutKey]: layout,
      }),
  );

/**
 * Получение толщины линии объекта для карты, для отображения процента от общего пассажиропотока.
 * @param percent - процент пассажиропотока от общего
 * @return толщина в пикселях отображаемой линии на карте
 */
export const getWidthLine = (percent: number): number => {
  if (percent <= 9) {
    return 3;
  } else if (percent <= 19) {
    return 4;
  } else if (percent <= 29) {
    return 5;
  } else if (percent <= 39) {
    return 6;
  } else if (percent <= 49) {
    return 7;
  } else if (percent <= 59) {
    return 8;
  } else if (percent <= 69) {
    return 9;
  } else if (percent <= 79) {
    return 10;
  } else if (percent <= 89) {
    return 11;
  } else if (percent <= 100) {
    return 12;
  }
  return 3;
};

export const getTrafficColor = (vehicle: VehicleTypeCode) => {
  switch (vehicle) {
    case VehicleTypeCode.multimodal:
      return '#3362BC';
    case VehicleTypeCode.railwayRoot:
      return '#7E67DD';
    case VehicleTypeCode.avia:
      return '#00A1DC';
    case VehicleTypeCode.bus:
      return '#F3A015';
    case VehicleTypeCode.auto:
      return '#FF792E';
    case VehicleTypeCode.nightTrain:
      return '#8AB0D2';
    case VehicleTypeCode.dayTrain:
      return '#A3A86B';
    case VehicleTypeCode.suburbanTrain:
      return '#DDAB71';
    case VehicleTypeCode.expressTrain:
      return '#7E67DD';
    default:
      return 'black';
  }
};

export interface CustomMap extends OLMap {
  activeFeature?: ClickedFeature | null;
  isSetPoint?: EFilterMapPoint;
  filterLayout?: EFilterMapLayout;
}

export type ClickedFeature = Feature<Geometry> & {
  zIndexLayer: number | undefined;
  [layerIdKey]: string;
};

interface SetLayerFeaturesToMapProps {
  features: Feature<Geometry>[];
  layout: EMapFeatureLayout;
  map: CustomMap;
  width?: number;
}

interface RemoveLayerFeaturesFromMapProps {
  layout: EMapFeatureLayout;
  map: CustomMap;
}

interface GetMapLayoutLayerProps {
  layout: EMapFeatureLayout;
  map: CustomMap;
}

interface GetMapLayerFeaturesProps {
  layout: EMapFeatureLayout;
  map: CustomMap | null | undefined;
}

interface LayerSource extends Source {
  getFeatures: () => Feature<Geometry>[];
}
