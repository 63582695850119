import { useUnit } from 'effector-react';
import { useMemo } from 'react';

import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';

import { SOCECO_TABLE_BODYS_MOCK } from '@widgets/widget-soceco-indicators/constants/tables/body_mock';
import { SOC_ECO_TABLE_HEADS } from '@widgets/widget-soceco-indicators/constants/tables/heads/heads';
import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';
import { $SocEcoTable } from '@widgets/widget-soceco-indicators/stores/tables/store';

export const TableParams = () => {
  const { selectedTable, tables } = useUnit($SocEcoTable);

  const displayHeadCells = useMemo(() => {
    switch (selectedTable) {
      case SOCECO_TABLE_IDS.TABLE_6_1:
      case SOCECO_TABLE_IDS.TABLE_6_3:
      case SOCECO_TABLE_IDS.TABLE_7_1:
      case SOCECO_TABLE_IDS.TABLE_7_4:
        return [
          ...SOC_ECO_TABLE_HEADS[selectedTable],
          ...tables[selectedTable].data.map(item => ({
            id: item.year as string,
            label: item.year as string,
          })),
        ];
      default:
        return SOC_ECO_TABLE_HEADS[selectedTable];
    }
  }, [selectedTable, tables]);

  const displayBodyRows = useMemo(() => {
    switch (selectedTable) {
      case SOCECO_TABLE_IDS.TABLE_6_1:
      case SOCECO_TABLE_IDS.TABLE_7_1:
      case SOCECO_TABLE_IDS.TABLE_7_4:
        return [
          [
            'Численность населения, тыс. чел.',
            ...displayHeadCells
              .map(
                headCell =>
                  tables[selectedTable].data.find(
                    item => item.year === headCell.id,
                  )?.population,
              )
              .slice(1),
          ],
          [
            'Площадь ТЦ, тыс. м2',
            ...displayHeadCells
              .map(
                headCell =>
                  tables[selectedTable].data.find(
                    item => item.year === headCell.id,
                  )?.shoppingCenterArea,
              )
              .slice(1),
          ],
          [
            'Число средств коллективного размещения, мест',
            ...displayHeadCells
              .map(
                headCell =>
                  tables[selectedTable].data.find(
                    item => item.year === headCell.id,
                  )?.numberCollectiveAccommodationFacilities,
              )
              .slice(1),
          ],
          [
            'Финансирование строительства, млн. руб.',
            ...displayHeadCells
              .map(
                headCell =>
                  tables[selectedTable].data.find(
                    item => item.year === headCell.id,
                  )?.constructionFinancing,
              )
              .slice(1),
          ],
          [
            'Частота курсирования по перспективной линии, рейсов в сутки',
            ...displayHeadCells
              .map(
                headCell =>
                  tables[selectedTable].data.find(
                    item => item.year === headCell.id,
                  )?.frequencyServicePromisingLine,
              )
              .slice(1),
          ],
        ];
      case SOCECO_TABLE_IDS.TABLE_6_3:
        return [
          [
            'Численность населения, тыс. чел.',
            ...displayHeadCells
              .map(
                headCell =>
                  tables[selectedTable].data.find(
                    item => item.year === headCell.id,
                  )?.population,
              )
              .slice(1),
          ],
          [
            'Сокращение объёмов выбросов CO2, тонн',
            ...displayHeadCells
              .map(
                headCell =>
                  tables[selectedTable].data.find(
                    item => item.year === headCell.id,
                  )?.reducingCarbonDioxideEmissions,
              )
              .slice(1),
          ],
          [
            'Площадь нарушенных земель, га.',
            ...displayHeadCells
              .map(
                headCell =>
                  tables[selectedTable].data.find(
                    item => item.year === headCell.id,
                  )?.areaDisturbedLands,
              )
              .slice(1),
          ],
        ];
      default:
        return tables[selectedTable].data.length
          ? tables[selectedTable].data
          : SOCECO_TABLE_BODYS_MOCK[selectedTable];
    }
  }, [displayHeadCells, selectedTable, tables]);

  const createCells = (
    row: BodyRowsType | (string | number | null | undefined)[],
  ) => {
    if (!row) return;

    const cells: (string | number | null | undefined)[] = [];

    switch (selectedTable) {
      case SOCECO_TABLE_IDS.TABLE_6_1:
      case SOCECO_TABLE_IDS.TABLE_6_3:
      case SOCECO_TABLE_IDS.TABLE_7_1:
      case SOCECO_TABLE_IDS.TABLE_7_4:
        cells.push(...(row as (string | number | null | undefined)[]));
        break;
      default:
        for (const headCell of displayHeadCells) {
          for (const [key, value] of Object.entries(row)) {
            if (headCell.id === key) {
              cells.push(value);
              break;
            }
          }
        }
        break;
    }

    return cells.map((cell, i) => (
      <TableCell
        key={i}
        sx={rowStyleSX}
      >
        {cell}
      </TableCell>
    ));
  };

  return (
    <TableContainer
      sx={{
        height: '100%',
      }}
    >
      <Table
        size="medium"
        aria-labelledby="tableTitle"
        sx={{
          width: '100%',
        }}
      >
        <TableHead>
          <TableRow>
            {displayHeadCells.map(headCell => (
              <TableCell
                key={headCell.id}
                align={'left'}
                padding={'normal'}
                sx={THCellStyleSX}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {displayBodyRows.map((row, i) => (
            <TableRow key={i}>{createCells(row)}</TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type BodyRowsType = Record<string, string | number | null | undefined>;

const rowStyleSX: SxProps<Theme> = {
  fontSize: theme => theme.typography.pxToRem(12),
  ['&:not(:first-of-type)']: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
};

const THCellStyleSX: SxProps<Theme> = {
  fontSize: theme => theme.typography.pxToRem(12),
  bgcolor: theme => theme.palette.border.divider,
  ['&:not(:first-of-type)']: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  lineHeight: 1.25,
  boxShadow: theme => 'inset 0 0 1px 0px ' + theme.palette.border.stroke,
  position: 'sticky',
  top: 0,
  zIndex: 1,
};
