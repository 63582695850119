import { createEvent, createStore } from 'effector';

interface IFileFormatModalState {
  isOpen: boolean;
}

export const $FileFormatModalState = createStore<IFileFormatModalState>({
  isOpen: false,
});

export const setIsOpenFileFormatModalFn = createEvent<boolean>();

export const resetFileFormatModalStateFn = createEvent<void>();
