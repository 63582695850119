import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { Box, Button } from '@mui/material';

import { DoorIcon, DoorInIcon, DoorOutIcon } from '@components/icons';

import { Option } from '@features/tpu-simulation-model/utils/getAttributeOptions';

interface AttributeProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  options: Option[];
}

export function Direction<T extends FieldValues>({
  control,
  name,
  options,
}: AttributeProps<T>) {
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Box
            sx={{
              '&': {
                display: 'flex',
                flexDirection: 'row !important',
                gap: '8px',
                mt: '8px',
              },
              '& .MuiFormControlLabel-root': {
                display: 'inline-block',
                m: 0,
              },
            }}
          >
            {options.map(item => {
              return (
                <Box
                  key={item.value}
                  sx={{
                    display: 'inline-block',
                  }}
                >
                  <Button
                    sx={{
                      padding: '8px 12px',
                      fontSize: theme => theme.typography.pxToRem(14),
                      '.MuiSvgIcon-root': {
                        mr: '5px',
                      },
                    }}
                    variant={
                      item.value === field.value ? 'contained' : 'outlined'
                    }
                    value={item.value}
                    onClick={() => {
                      field.onChange(item.value);
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                      }}
                    >
                      {item.value === 'in' && <DoorInIcon />}
                      {item.value === 'out' && <DoorOutIcon />}
                      {item.value === 'inAndOut' && <DoorIcon />}
                      {item.label}
                    </Box>
                  </Button>
                </Box>
              );
            })}
          </Box>
        )}
      />
    </Box>
  );
}
