import { useUnit } from 'effector-react';

import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { CloseIcon } from '@components/icons';

import { DirectionPoints } from '@features/pt-forecast-new/components/MapFilters/DirectionPoints/DirectionPoints';
import { LayoutChanger } from '@features/pt-forecast-new/components/MapFilters/LayoutChanger';
import { TransportDistrictFilter } from '@features/pt-forecast-new/components/MapFilters/TransportDistrictFilter';
import { VehiclesFilters } from '@features/pt-forecast-new/components/MapFilters/VehiclesFilters/VehiclesFilters';
import { FilterMapApi } from '@features/pt-forecast-new/stores/filters';
import { $UI } from '@features/pt-forecast-new/stores/ui';

export const MapFiltersContainer = () => {
  const {
    showDashboard,
    panels: { filter },
  } = useUnit($UI);
  const { reset } = useUnit(FilterMapApi);

  if (!filter) return null;
  return (
    <Box sx={FiltersContainerMuiStyle}>
      <Stack direction="column">
        <AutoFullHeight
          style={{
            borderTop: '1px solid #EBECEF',
            width: '500px',
            maxWidth: '500px',
            overflowY: 'auto',
          }}
          mb={77}
        >
          <Typography
            variant="subtitle2"
            mt={1}
            p={'16px 32px 0 32px'}
          >
            Фильтры
          </Typography>
          <Stack
            marginLeft={4}
            marginRight={4}
          >
            {!showDashboard && (
              <>
                <LayoutChanger />
                <Divider />
              </>
            )}
            <DirectionPoints />
            <Divider />
            <VehiclesFilters />
            {!showDashboard && (
              <>
                <Divider />
                <TransportDistrictFilter />
              </>
            )}
          </Stack>
        </AutoFullHeight>
        <Box
          pb="26px"
          pt={3}
          px={5.5}
          mt={'auto'}
          style={{
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Button
            variant={'text'}
            startIcon={<CloseIcon />}
            fullWidth
            onClick={reset}
            disabled={showDashboard}
          >
            Сбросить все
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

const FiltersContainerMuiStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: '2',
  boxShadow: 'rgba(0, 0, 0, 0.1) 5px 4px 2px 0px',
  background: theme => theme.palette.background.default,
};
