import { useUnit } from 'effector-react';

import { StructurePassChart } from '@features/pt-forecast-new/components/Dashboards/StructurePassChart/StructurePassChart';
import { $UI } from '@features/pt-forecast-new/stores/ui';

export const StructurePassChartContainer = () => {
  const { showDashboard } = useUnit($UI);

  return <StructurePassChart isFull={!showDashboard} />;
};
