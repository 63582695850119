import { SvgIcon, SvgIconProps } from '@mui/material';

export const FilterAscIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M10.546 8.303a.727.727 0 1 1 0-1.455h2.909a.727.727 0 1 1 0 1.455h-2.91Zm-2.91 4.848a.727.727 0 1 1 0-1.454h8.728a.727.727 0 1 1 0 1.455H7.636ZM4.727 18a.727.727 0 1 1 0-1.454h14.546a.727.727 0 1 1 0 1.454H4.727Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
