import { $IsOpenTypeForm } from '@features/tpu-passenger-types/stores/form.store';
import { useUnit } from 'effector-react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button } from '@mui/material';

import { PlusIcon } from '@components/icons';

interface ToolbarProps {
  onCreateType: () => void;
  onBack: () => void;
  onSaveShare: () => void;
  onSave: () => void;
}

export const Toolbar = ({ onCreateType, onBack }: ToolbarProps) => {
  const $isOpenForm = useUnit($IsOpenTypeForm);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 0 24px 0',
      }}
    >
      <Box>
        <Button
          variant="text"
          onClick={onBack}
          sx={{
            [`& .MuiButton-startIcon > *:nth-of-type(1)`]: {
              fontSize: '12px',
            },
            mr: '24px',
          }}
          startIcon={<ArrowBackIcon />}
          color="info"
        >
          Назад
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            width: '292px',
          }}
          disabled={$isOpenForm}
          onClick={onCreateType}
        >
          <PlusIcon sx={{ mr: '8px' }} />
          Создать новый
        </Button>
      </Box>
    </Box>
  );
};
