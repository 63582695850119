import { useRouteError } from 'react-router-dom';

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  const message = (() => {
    if (error && typeof error === 'object' && 'statusText' in error)
      return String(error.statusText);
    if (error && typeof error === 'object' && 'message' in error)
      return String(error.message);

    return 'unknown error';
  })();

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{message}</i>
      </p>
    </div>
  );
};
