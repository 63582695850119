import { EVehicleAg } from '@features/ag-forecast/constants/EVehicleAg';

import { EMapFeatureLayout } from '@constants/map';

// Возвращает слой на котором находится переданный тип транспорта
export const vehicleIdToLayout = (id: EVehicleAg) => {
  switch (id) {
    case EVehicleAg.auto:
      return EMapFeatureLayout.graphAuto;
    case EVehicleAg.bus:
      return EMapFeatureLayout.graphBus;
    case EVehicleAg.trolleybus:
      return EMapFeatureLayout.graphTrolleybus;
    case EVehicleAg.tram:
      return EMapFeatureLayout.graphTram;
    case EVehicleAg.metro:
      return EMapFeatureLayout.graphMetro;
    case EVehicleAg.suburbanRailway:
      return EMapFeatureLayout.graphSuburbanRailway;
    case EVehicleAg.ropeWay:
      return EMapFeatureLayout.graphRopeWay;
    case EVehicleAg.waterWay:
      return EMapFeatureLayout.graphWaterWay;
    case EVehicleAg.funicular:
      return EMapFeatureLayout.graphFunicular;
    case EVehicleAg.monoRailWay:
      return EMapFeatureLayout.graphMonoRailWay;
    case EVehicleAg.pedestrain:
      return EMapFeatureLayout.graphPedestrain;
    case EVehicleAg.avia:
      return EMapFeatureLayout.graphAvia;
    default:
      throw new Error(`Unknown vehicle id - ${id}`);
  }
};
