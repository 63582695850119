import { sample } from 'effector';

import { queryGetScenarioTPU } from '@features/ag-forecast/stores/api/queryGetScenarioTPU';
import {
  TPUType,
  TPUsApi,
  TPUsType,
  getScenarioTPUFx,
} from '@features/ag-forecast/stores/lists/TPUs/store';

getScenarioTPUFx.use(queryGetScenarioTPU);

// Записать в стор списка тпу
sample({
  clock: getScenarioTPUFx.done,
  fn: payload => {
    const tpus = payload.result as TPUsType['TPUs'];

    return tpus?.map((tpu: TPUType | null) => ({
      ...tpu,
      selectedTPU: tpu?.id,
    })) as TPUsType['TPUs'];
  },
  target: TPUsApi.setTPUs,
});
