import {
  IDeleteProjectMutation,
  IProjectPagination,
  Maybe,
} from '@api/gql/ag-types';
import { AgglomerationOption } from '@features/ag-projects-registry/store/lists/agglomerations';
import { createApi, createEffect, createEvent, createStore } from 'effector';

export enum EListProject {
  A_1 = 'В очереди',
  A_2 = 'Идёт обработка данных',
  A_3 = 'Успешно обработан',
  A_4 = 'Ошибка',
}

const defaultStore: ListProjectStore = {
  projects: [],
  order: 'asc',
  orderBy: 'id',
  contains: '',
  selected: [],
  page: 0,
  rowsPerPage: 5,
  totalCount: 0,
};

export const $ListProject = createStore<ListProjectStore>(defaultStore);

export const ListProjectsApi = createApi($ListProject, {
  setProjects: (
    store,
    {
      projects,
      totalCount,
    }: Pick<ListProjectStore, 'projects'> &
      Pick<ListProjectStore, 'totalCount'>,
  ) => ({
    ...store,
    projects,
    totalCount,
  }),
  setPage: (store, payload: ListProjectStore['page']) => ({
    ...store,
    page: payload,
  }),
  setRowsPerPage: (store, payload: ListProjectStore['rowsPerPage']) => ({
    ...store,
    rowsPerPage: payload,
  }),
  setOrder: (store, payload: ListProjectStore['order']) => ({
    ...store,
    order: payload,
  }),
  setOrderBy: (store, payload: ListProjectStore['orderBy']) => ({
    ...store,
    orderBy: payload,
  }),
  setSelected: (store, payload: ListProjectStore['selected']) => ({
    ...store,
    selected: payload,
  }),
  setContains: (store, payload: ListProjectStore['contains']) => ({
    ...store,
    contains: payload,
  }),
  reset: () => defaultStore,
});

export const FetchProjects = createEvent();

export const FetchProjectsFx = createEffect<
  FetchProjectsRequestParams,
  Maybe<IProjectPagination>
>();

export const DeleteProjectFx = createEffect<
  DeleteProjectParams,
  Maybe<IDeleteProjectMutation>
>();

export type ListProjectStore = {
  projects: ProjectType[];
  order: OrderType;
  orderBy: string;
  selected: string[];
  page: number;
  rowsPerPage: number;
  contains: string;
  totalCount: number;
};

export type OrderType = 'asc' | 'desc';

export type ProjectType = {
  id: string;
  agglomeration: AgglomerationOption;
  status: keyof typeof EListProject;
  date: string;
  name: string;
  number: number;
  user: string;
};

export type FetchProjectsRequestParams = {
  limit: number;
  offset: number;
  orderBy?: string;
  contains?: string;
};

export type DeleteProjectParams = {
  id: string;
};
