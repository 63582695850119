export enum EAgeGroups {
  underWorkingAge = 'Моложе трудоспособного',
  workingAge = 'Трудоспособный',
  overWorkingAge = 'Старше трудоспособного',
}

export const ageGroupsAvailableCodes: EAgeGroups[] = [
  EAgeGroups.underWorkingAge,
  EAgeGroups.workingAge,
  EAgeGroups.overWorkingAge,
];
