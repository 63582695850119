import styled from '@emotion/styled';
import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button } from '@mui/material';

import { PlusIcon } from '@components/icons/Plus';

import { ROUTES } from '@constants/routes';

import { Draft } from '@features/pt-constructor/components/ModalCalculation/states/Draft';
import { ErrorInfo } from '@features/pt-constructor/components/ModalCalculation/states/ErrorInfo';
import { Queue } from '@features/pt-constructor/components/ModalCalculation/states/Queue';
import { Running } from '@features/pt-constructor/components/ModalCalculation/states/Running';
import { Success } from '@features/pt-constructor/components/ModalCalculation/states/Success';
import { ECalculationScenarioStatusType } from '@features/pt-constructor/constants/calculationScenarioStatusType';
import { useConfirms } from '@features/pt-constructor/hooks/useConfirms';
import {
  $CalculationStatuses,
  $SelectedCalculationScenario,
  SelectedCalculationScenarioApi,
  createCopyCalculationScenario,
  createOrUpdateCalculationScenario,
  onlyRunCalculationScenario,
  setModalView,
} from '@features/pt-constructor/store';
import {
  setErrorCheckingReCalculation,
  setErrorCheckingSaveCalculation,
} from '@features/pt-constructor/store/errorsCreateCalculation';
import { checkingNameCalculation } from '@features/pt-constructor/store/errorsCreateCalculation/utils';
import {
  $Calculations,
  CalculationsApi,
} from '@features/pt-constructor/store/lists/scenarios/store';

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 0, 0, 0),
  gap: theme.spacing(3),
  display: 'flex',
}));

export const ToolBar = () => {
  const { statuses } = useUnit($CalculationStatuses);
  const { id, name, socEcoScenario, transportScenario } = useUnit(
    $SelectedCalculationScenario,
  );
  const { clearSelectedCalculationScenario, setNameCalculationScenario } =
    useUnit(SelectedCalculationScenarioApi);
  const { isNewCalculationScenario, readonly } = useUnit($Calculations);
  const { setReadonly, setVisibleFormCreateCalculationScenario } =
    useUnit(CalculationsApi);

  const handlers = useUnit({
    setModalView,
    createOrUpdateCalculationScenario,
    createCopyCalculationScenario,
    onlyRunCalculationScenario,
  });

  const navigate = useNavigate();
  const {
    beforeRecalculateScenario,
    beforeDropScenario,
    beforeSaveScenarioHandler,
  } = useConfirms();

  const isSelectedScenario = !!id || isNewCalculationScenario;
  // Статус выбранного сценария
  const calculationStatus = useMemo(() => {
    if (!isSelectedScenario) return null;
    const status = statuses.find(e => e.id === id)?.status;
    if (!status) return null;

    return status;
  }, [statuses, id, isSelectedScenario]);

  const isEditMode = !readonly;
  const isPending =
    calculationStatus === ECalculationScenarioStatusType.Pending ||
    calculationStatus === ECalculationScenarioStatusType.Queue;
  const isSuccess =
    calculationStatus === ECalculationScenarioStatusType.Success;

  // Сохранить изменения в выбранном сценарии
  const handleClickSaveCalculationScenario = useCallback(() => {
    setErrorCheckingSaveCalculation({
      name,
      socioEconomicScenario: socEcoScenario,
      transportationScenario: transportScenario,
    });

    if (
      checkingNameCalculation(name.trim()) ||
      !socEcoScenario ||
      !transportScenario
    ) {
      setNameCalculationScenario(name.trim());
      return;
    }

    beforeSaveScenarioHandler(() => {
      setVisibleFormCreateCalculationScenario(false);
      clearSelectedCalculationScenario();
    });
  }, [
    name,
    socEcoScenario,
    transportScenario,
    beforeSaveScenarioHandler,
    setNameCalculationScenario,
    setVisibleFormCreateCalculationScenario,
    clearSelectedCalculationScenario,
  ]);

  // Сбросить выбранный расчет
  const handleClickBack = useCallback(() => {
    beforeDropScenario(() => {
      navigate(ROUTES.PASSENGER_TRAFFIC);
      clearSelectedCalculationScenario();
    });
  }, [beforeDropScenario, clearSelectedCalculationScenario, navigate]);

  // Создать новый пустой сценарий расчета
  const handleClickCreateCalculationScenario = useCallback(() => {
    beforeDropScenario(() => {
      clearSelectedCalculationScenario();
      setVisibleFormCreateCalculationScenario(true);
      setReadonly(false);
    });
  }, [
    beforeDropScenario,
    clearSelectedCalculationScenario,
    setReadonly,
    setVisibleFormCreateCalculationScenario,
  ]);

  // Запустить перерасчет сценария
  const handleClickStartReCalculation = useCallback(() => {
    setErrorCheckingReCalculation({
      name,
      socioEconomicScenario: socEcoScenario,
      transportationScenario: transportScenario,
    });

    if (
      checkingNameCalculation(name.trim()) ||
      !socEcoScenario ||
      !transportScenario
    ) {
      setNameCalculationScenario(name.trim());
      return;
    }

    beforeRecalculateScenario(() => {
      setVisibleFormCreateCalculationScenario(false);
      handlers.setModalView({ calculation: true });
    });
  }, [
    name,
    socEcoScenario,
    transportScenario,
    beforeRecalculateScenario,
    setNameCalculationScenario,
    setVisibleFormCreateCalculationScenario,
    handlers,
  ]);

  // Запустить расчет на черновике
  const handleCalculationClick = useCallback(() => {
    handlers.setModalView({ calculation: true });
    handlers.onlyRunCalculationScenario();
    // handlers.createOrUpdateCalculationScenario({ run: true });
  }, [handlers]);

  // Перейти на страницу результатов расчета
  const handleClickGoToResult = useCallback(() => {
    if (!isSelectedScenario) return;
    const query = new URLSearchParams({ id: id || '' });

    navigate(`${ROUTES.PASSENGER_TRAFFIC_FORECAST}?${query.toString()}`);
  }, [id, isSelectedScenario, navigate]);

  const StatusComponent = useMemo(() => {
    switch (calculationStatus) {
      case ECalculationScenarioStatusType.Draft:
        return () => <Draft />;
      case ECalculationScenarioStatusType.Failure:
        return () => <ErrorInfo errorMessage={'Неизвестная ошибка'} />;
      case ECalculationScenarioStatusType.Pending:
        return () => <Running />;
      case ECalculationScenarioStatusType.Success:
        return () => <Success />;
      case ECalculationScenarioStatusType.Queue:
        return () => <Queue />;
      default:
        return () => null;
    }
  }, [calculationStatus]);

  return (
    <Wrapper>
      <Button
        variant={'text'}
        onClick={handleClickBack}
        sx={{
          [`& .MuiButton-startIcon > *:nth-of-type(1)`]: {
            fontSize: '12px',
          },
        }}
        startIcon={<ArrowBackIcon />}
        color={'info'}
      >
        Назад
      </Button>

      <Button
        variant={'contained'}
        color={'secondary'}
        sx={{
          gap: 1,
        }}
        onClick={handleClickCreateCalculationScenario}
      >
        <>
          <PlusIcon />
          Создать расчет
        </>
      </Button>

      {isSelectedScenario && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            ml: 'auto',
            gap: '30px',
          }}
        >
          {isEditMode && (
            <>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickSaveCalculationScenario}
              >
                Сохранить параметры
              </Button>

              <Button
                color="secondary"
                onClick={handleClickStartReCalculation}
                disabled={isPending}
              >
                Сохранить и рассчитать
              </Button>
            </>
          )}

          {!isEditMode && (
            <>
              <StatusComponent />
              {isSuccess ? (
                <Button
                  color="secondary"
                  onClick={handleClickGoToResult}
                >
                  Перейти к расчету
                </Button>
              ) : (
                <Button
                  color="secondary"
                  onClick={handleCalculationClick}
                  disabled={isPending}
                >
                  Рассчитать
                </Button>
              )}
            </>
          )}
        </Box>
      )}
    </Wrapper>
  );
};
