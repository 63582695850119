import { SetFeatureStyleProps } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/getFeatureStyle';
import { Icon, Style } from 'ol/style';

import BusStationSVG from '@components/icons/BusStation.svg';
import BusStationSelectedSVG from '@components/icons/BusStationSelected.svg';

export type GetBusStationStyleArgs = Pick<SetFeatureStyleProps, 'active'>;

export const getBusStationStyle = ({ active }: GetBusStationStyleArgs) =>
  new Style({
    image: new Icon({
      src: active ? BusStationSelectedSVG : BusStationSVG,
    }),
  });
