import { ESex } from '@features/pt-forecast/stores/types';

export const getChartColor = (group: ESex) => {
  switch (group) {
    case ESex.male:
      return '#8FAADC';
    case ESex.female:
      return '#F8CBAD';
    default:
      return 'black';
  }
};
