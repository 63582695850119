import { ImplicitLabelType } from 'recharts/types/component/Label';

import { gapBetweenDigits } from '@utils/dataForInspector';

export const LineLabel: ImplicitLabelType = props => {
  const { x, y, stroke, value } = props;

  if (+value === 0) return <></>;

  return (
    <>
      <text
        x={x}
        y={y}
        dy={12}
        dx={30}
        fill={stroke}
        fontSize={10}
        textAnchor="middle"
      >
        {gapBetweenDigits(value)}
      </text>
    </>
  );
};
