import { createBrowserRouter } from 'react-router-dom';

import { Layout } from '@components/layout';

import { ACCESS_GROUPS } from '@constants/accessGroups';
import { ROUTES } from '@constants/routes';

import { PageAgglomeration } from '@pages/agglomeration';
import { ErrorPage } from '@pages/error';
import { PageModules } from '@pages/modules';
import { PagePassengerTraffic } from '@pages/passenger-traffic';
import { PageTPU } from '@pages/tpu';
import { UIKit } from '@pages/ui-kit';

import { PrivateRoute } from '@system/routing/PrivateRoute';

import { WidgetPassengerFlowContainer } from '@widgets/widget-passenger-flow';

export const router = createBrowserRouter([
  {
    path: '/dev',
    element: (
      <WidgetPassengerFlowContainer
        regionUUID={'5f7dbb09-a53f-48c9-9ac3-672c79fc9bf4'}
      />
    ),
  },
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: `${ROUTES.MAIN}/*`,
        element: <PrivateRoute element={<PageModules />} />,
      },
      {
        path: `${ROUTES.GENERAL}/*`,
        element: <PrivateRoute element={<PageModules />} />,
      },
      {
        path: `${ROUTES.PASSENGER_TRAFFIC}/*`,
        element: (
          <PrivateRoute
            element={<PagePassengerTraffic />}
            requiredAccess={ACCESS_GROUPS.PASS_FLOW}
          />
        ),
      },
      {
        path: `${ROUTES.AGGLOMERATION}/*`,
        element: (
          <PrivateRoute
            element={<PageAgglomeration />}
            requiredAccess={ACCESS_GROUPS.AGGLOMERATION}
          />
        ),
      },
      {
        path: `${ROUTES.TPU}/*`,
        element: (
          <PrivateRoute
            element={<PageTPU />}
            requiredAccess={ACCESS_GROUPS.TPU}
          />
        ),
      },

      {
        path: ROUTES.UI_KIT,
        element: <PrivateRoute element={<UIKit />} />,
      },
    ],
  },
]);
