import { sample } from 'effector';

import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { SetMapFeaturesTypes } from '@features/passenger-traffic/stores';
import { changeMap, setMapFeatures } from '@features/pt-forecast/stores';

sample({
  clock: changeMap,
  fn: () => {
    const layer: SetMapFeaturesTypes = {
      params: {
        layout: EMapFeatureLayout.busStation,
      },
      features: [],
    };

    return layer;
  },
  target: setMapFeatures,
});
