import {
  $ListProject,
  DeleteProjectFx,
} from '@features/ag-projects-registry/store/lists/projects';
import {
  $UIAg,
  UIAGProjectsRegistry,
} from '@features/ag-projects-registry/store/ui';
import { useUnit } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { DialogCloseButton } from '@components/DialogCloseButton';

import { Question } from './states/question';
import { Success } from './states/success';
import {
  $ModalDeleteProjects,
  EStateModalDeleteProjects,
  resetModalDeleteProjects,
  setState,
} from './store';

export type ModalDeleteProjectsAction = (isDelete: boolean) => void;

interface ModalDeleteProjectsProps {
  onClose(): void;
}

export const ModalDeleteProjects = (props: ModalDeleteProjectsProps) => {
  const { onClose } = props;
  const $listProject = useUnit($ListProject);
  const $modalDeleteProjects = useUnit($ModalDeleteProjects);
  const handlers = useUnit({ resetModalDeleteProjects, setState });
  const $modalsPtConstructor = useUnit(
    $UIAg,
  ) as UIAGProjectsRegistry<ModalDeleteProjectsAction>;

  const DescriptionState = useMemo(() => {
    switch ($modalDeleteProjects.state) {
      case EStateModalDeleteProjects.question:
        return Question;

      case EStateModalDeleteProjects.success:
        return Success;
    }
  }, [$modalDeleteProjects.state]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    $modalsPtConstructor.action && $modalsPtConstructor.action(true);
    handlers.setState(EStateModalDeleteProjects.success);
    const id = $listProject.projects.find(
      project => project.id === $listProject.selected[0],
    )?.id;
    id && DeleteProjectFx({ id: id });
  }, [
    $listProject.projects,
    $listProject.selected,
    $modalsPtConstructor,
    handlers,
  ]);

  useEffect(() => {
    return handlers.resetModalDeleteProjects;
  }, [handlers.resetModalDeleteProjects]);

  return (
    <Dialog
      onClose={handleClose}
      open={true}
    >
      <DialogTitle
        variant="subtitle2"
        sx={{
          position: 'relative',
          fontSize: theme => theme.typography.pxToRem(16),
          pr: 10,
        }}
      >
        Удалить расчет
      </DialogTitle>

      <DialogCloseButton onClick={handleClose} />

      <DialogContent>
        <DescriptionState />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          {$modalDeleteProjects.state === EStateModalDeleteProjects.question
            ? 'Не удалять'
            : 'Закрыть'}
        </Button>

        {$modalDeleteProjects.state === EStateModalDeleteProjects.question && (
          <Button
            variant={'outlined'}
            onClick={handleSubmit}
          >
            Удалить
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
