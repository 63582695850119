import { sample } from 'effector';
import { Feature } from 'ol';
import { Geometry, LineString, Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EMapFeatureLayout } from '@constants/map';

import {
  $SelectedFeatures,
  initSources,
  pipeTpuLayer,
} from '@features/ag-forecast/stores/map';
import {
  $EditorMap,
  EditorMapApi,
  TpuItem,
} from '@features/ag-forecast/stores/map/editor';
import {
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

import {
  EFeatureStyle,
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';

sample({
  clock: [
    EditorMapApi.addTpu,
    EditorMapApi.setInfrastructure,
    EditorMapApi.removeTpu,
    EditorMapApi.updateSelectedTpuParams,
    initSources,
  ],
  source: { EditorMap: $EditorMap },
  fn: ({ EditorMap }) => {
    const features: Feature<Geometry>[] = [];

    if (EditorMap.tpus) {
      EditorMap.tpus.forEach(tpu => {
        if (tpu.delete) return;
        features.push(
          new Feature({
            geometry: new Point(
              fromLonLat([tpu.geometry.lon, tpu.geometry.lat]),
            ),
            [layerIdKey]: tpu.id,
            [layerLayoutKey]: EMapFeatureLayout.tpu,
            isAlt: !!tpu.parentTpuId,
          }),
        );
        if (!tpu.parentTpuId) return;
        const parentTpu = EditorMap.tpus.find(
          item => item.id === tpu.parentTpuId,
        );
        if (!parentTpu) return;
        features.push(
          new Feature({
            geometry: new LineString([
              fromLonLat([tpu.geometry.lon, tpu.geometry.lat]),
              fromLonLat([parentTpu.geometry.lon, parentTpu.geometry.lat]),
            ]),
          }),
        );
      });
    }
    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: { features },
    } as PipeVectorLayer;
  },
  target: pipeTpuLayer,
});

// применение стилей к тпу
sample({
  clock: [$EditorMap, $SelectedFeatures],
  source: { EditorMap: $EditorMap, SelectedFeatures: $SelectedFeatures },
  fn: ({ EditorMap, SelectedFeatures }) => {
    const selectedTpuIds = SelectedFeatures.filter(
      item => item.layout === EMapFeatureLayout.tpu,
    ).map(item => item.id);

    return {
      action: EPipeVectorLayer.setFeatureStyle,
      payload: {
        featureStyles: Object.fromEntries(
          EditorMap.tpus.map(item => [
            item.id,
            getTpuStyle(
              item,
              selectedTpuIds,
              EditorMap.drawGraph?.startPointId,
            ),
          ]),
        ),
      },
    } as PipeVectorLayer;
  },
  target: pipeTpuLayer,
});

// Определения стиля для переданного тпу
const getTpuStyle = (
  tpu: TpuItem,
  selectedIds: string[],
  startedId?: string,
) => {
  const isSelected = selectedIds.includes(tpu.id);

  if (tpu.parentTpuId) {
    if (isSelected) return EFeatureStyle.alternateSelected;
    if (startedId === tpu.id) return EFeatureStyle.started;
    return EFeatureStyle.alternate;
  }

  if (startedId === tpu.id) return EFeatureStyle.started;

  if (isSelected) return EFeatureStyle.selected;

  return EFeatureStyle.default;
};
