import { IQuery } from '@api/gql/tpu-types';

import { SimulationModelPlanData } from '@features/tpu-simulation-model/types';

import { ModelErrorAnalyzer } from '@utils/simulationModel/errors-analyzer';

const modelErrorAnalyzer = new ModelErrorAnalyzer();

export const getPlanErrors = (data: {
  plan: SimulationModelPlanData | null;
  agents: IQuery['allAgentShareInfo'];
}) => {
  modelErrorAnalyzer.updateData(data);
  return modelErrorAnalyzer.getErrors();
};
