import { IGravModelType, ITransportRegionType, Maybe } from '@api/gql/types';
import { useUnit } from 'effector-react';
import { useEffect, useMemo, useState } from 'react';

import { Box, Divider, IconButton, Typography } from '@mui/material';

import { ChevronRightIcon } from '@components/icons';

import { BoxData } from '@features/passenger-traffic/components/Inspector/BoxData';
import { $TimeLine } from '@features/passenger-traffic/stores/timeline/store';

import {
  convertUnitOfMeasure,
  gapBetweenDigits,
} from '@utils/dataForInspector';

interface TransportAreaInspectorProps {
  onClose?(): void;
  fields: Maybe<ITransportRegionType>;
}

export const TransportAreaInspector = (props: TransportAreaInspectorProps) => {
  const { onClose, fields } = props;

  const { baseYear } = useUnit($TimeLine);

  const [currentFullDetail, setCurrentFullDetail] =
    useState<Maybe<IGravModelType>>(null);

  useEffect(() => {
    const details: Maybe<IGravModelType[]> = JSON.parse(
      JSON.stringify(fields?.details),
    );

    if (details?.length) {
      details.sort((a: IGravModelType, b: IGravModelType) => {
        if (a.year > b.year) {
          return 1;
        }
        if (a.year < b.year) {
          return -1;
        }
        return 0;
      });

      let isIndex = true;
      let currentYearIndex = details?.findIndex(
        detail => detail?.year === baseYear,
      );

      if (currentYearIndex === -1 && details.length === 0) {
        setCurrentFullDetail({
          averageMonthlySalary: 0,
          averageNumberOfEmployeesOfOrganizationsWithoutSmp: 0,
          id: '',
          levelOfMotorization: 0,
          numberOfPlaces: 0,
          population: 0,
          revenueTourismIndustry: 0,
          revenueWithTaxesOnProducts: 0,
          transportRegion: null as unknown as ITransportRegionType,
          year: 0,
        });

        return;
      } else if (currentYearIndex === -1) {
        currentYearIndex = details.length - 1;
      }

      while (isIndex) {
        if (
          details![currentYearIndex].population &&
          details![currentYearIndex]
            .averageNumberOfEmployeesOfOrganizationsWithoutSmp &&
          details![currentYearIndex].averageMonthlySalary &&
          details![currentYearIndex].revenueWithTaxesOnProducts &&
          details![currentYearIndex].numberOfPlaces &&
          details![currentYearIndex].revenueTourismIndustry &&
          details![currentYearIndex].levelOfMotorization
        ) {
          setCurrentFullDetail(details![currentYearIndex]);
          isIndex = !isIndex;
        } else if (currentYearIndex - 1 >= 0) {
          currentYearIndex--;
        } else {
          setCurrentFullDetail(null);
          isIndex = !isIndex;
        }
      }
      return;
    }
    setCurrentFullDetail(null);
  }, [baseYear, fields?.details]);

  const population = useMemo(() => {
    const value = currentFullDetail?.population;

    if (!value) return 'н/д';

    return (
      convertUnitOfMeasure({
        value,
        unit: 'чел.',
      }) || 'н/д'
    );
  }, [currentFullDetail?.population]);

  const averageNumberOfEmployeesOfOrganizationsWithoutSmp = useMemo(() => {
    const value =
      currentFullDetail?.averageNumberOfEmployeesOfOrganizationsWithoutSmp;

    if (!value) return 'н/д';

    return (
      convertUnitOfMeasure({
        value,
        unit: 'чел.',
      }) || 'н/д'
    );
  }, [currentFullDetail?.averageNumberOfEmployeesOfOrganizationsWithoutSmp]);

  const averageMonthlySalary = useMemo(() => {
    const value = currentFullDetail?.averageMonthlySalary;

    if (!value) return 'н/д';

    return (
      convertUnitOfMeasure({
        value,
        unit: 'руб.',
      }) || 'н/д'
    );
  }, [currentFullDetail?.averageMonthlySalary]);

  const revenueWithTaxesOnProducts = useMemo(() => {
    const value = currentFullDetail?.revenueWithTaxesOnProducts;

    if (!value) return 'н/д';

    return (
      convertUnitOfMeasure({
        value: !isNaN(value) ? value * 1_000_000 : null,
        unit: 'руб.',
      }) || 'н/д'
    );
  }, [currentFullDetail?.revenueWithTaxesOnProducts]);

  const numberOfPlaces = useMemo(
    () =>
      currentFullDetail
        ? (gapBetweenDigits(currentFullDetail?.numberOfPlaces) || 0) + ' шт.'
        : 'н/д',
    [currentFullDetail],
  );

  const revenueTourismIndustry = useMemo(
    () =>
      currentFullDetail
        ? (gapBetweenDigits(currentFullDetail?.revenueTourismIndustry) || 0) +
          ' млн руб.'
        : 'н/д',
    [currentFullDetail],
  );

  const levelOfMotorization = useMemo(() => {
    const value = currentFullDetail?.levelOfMotorization as number;

    if (isNaN(value)) return 'н/д';

    return (
      gapBetweenDigits(value.toFixed(2)).replace('.', ',') + ' авто/тыс. чел.'
    );
  }, [currentFullDetail?.levelOfMotorization]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 3,
        }}
      >
        <IconButton
          sx={{
            width: '32px',
            height: '32px',
            backgroundColor: 'primary.main',
            borderRadius: 1,
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={onClose}
        >
          <ChevronRightIcon />
        </IconButton>

        <Typography variant="subtitle2">Транспортный район</Typography>
      </Box>

      <BoxData
        name={'Название'}
        value={fields?.name || 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Численность населения'}
        value={population}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Среднесписочная численность работников'}
        value={averageNumberOfEmployeesOfOrganizationsWithoutSmp}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Среднемесячная заработная плата'}
        value={averageMonthlySalary}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Выручка (нетто) от продажи товаров, продукции, работ, услуг'}
        value={revenueWithTaxesOnProducts}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Число мест в коллективных средствах размещения'}
        value={numberOfPlaces}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'ВДС Туризм'}
        value={revenueTourismIndustry}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Уровень автомобилизации'}
        value={levelOfMotorization}
      />
    </>
  );
};
