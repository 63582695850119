export interface SocEcoScenarioParams {
  revenueGrowthRate: number;
  populationGrowthRate: number;
  growthRateOfMotorization: number;
  growthRateInTheNumberOfPlacements: number;
  jobGrowthRate: number;
  salaryGrowthRate: number;
}

export interface EcoParam {
  name: keyof SocEcoScenarioParams;
  title: string;
}

// Параметры соц-эко
export const ecoParams: EcoParam[] = [
  {
    name: 'revenueGrowthRate',
    title: 'Среднегодовой темп роста ВВП, %',
  },
  {
    name: 'populationGrowthRate',
    title: 'Среднегодовой темп роста населения, %',
  },
  {
    name: 'growthRateOfMotorization',
    title: 'Среднегодовой темп роста автомобилизации, %',
  },
  {
    name: 'growthRateInTheNumberOfPlacements',
    title: 'Среднегодовой темп роста числа мест размещения, %',
  },
  {
    name: 'salaryGrowthRate',
    title: 'Среднегодовой темп роста заработной платы, %',
  },
];
