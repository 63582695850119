import { useUnit } from 'effector-react';
import { ReactElement, useEffect } from 'react';

import { FilterMapApi } from '@features/ag-forecast/stores/filters';
import {
  MapStoreApi,
  initSources,
  pipeMapControls,
} from '@features/ag-forecast/stores/map';

import { EPipeMapControls } from '@utils/map/hooks/useInitMapControls';

export type MapCentredWrapperProps = {
  centerCoords: number[] | null;
  children: ReactElement;
};

export const MapCentredWrapper = (props: MapCentredWrapperProps) => {
  const { centerCoords, children } = props;
  const handlers = useUnit({
    pipeMapControls,
    initSources,
  });
  const { hideAllToCache, restoreAllFromCache } = useUnit(FilterMapApi);
  const { setIsBusy } = useUnit(MapStoreApi);

  useEffect(() => {
    if (!centerCoords) return;
    setIsBusy(true);
    handlers.initSources();
    hideAllToCache();
    handlers.pipeMapControls({
      action: EPipeMapControls.center,
      payload: centerCoords,
    });

    setTimeout(() => {
      handlers.pipeMapControls({
        action: EPipeMapControls.setZoom,
        payload: 14,
      });
      setTimeout(() => {
        restoreAllFromCache();
        setIsBusy(false);
      }, 300);
    }, 600);
  }, [centerCoords, handlers, hideAllToCache, restoreAllFromCache, setIsBusy]);

  return children;
};
