import {
  IMutationUpdateScenarioParamsArgs,
  IQueryGetScenarioParamsArgs,
} from '@api/gql/types';
import { sample } from 'effector';

import { mutationUpdateScenarioParams } from '@widgets/widget-routes-params/stores/api/mutationUpdateScenarioParams';
import { queryGetGraphDirections } from '@widgets/widget-routes-params/stores/api/queryGetGraphDirections';
import { queryGetScenarioParams } from '@widgets/widget-routes-params/stores/api/queryGetScenarioParams';
import {
  $DirectionsParamsTable,
  DirectionsParamsTableApi,
  GraphParamsType,
  getGraphDirectionsFx,
  getScenarioParamsFx,
  requestUpdateParamsDirection,
  updateScenarioParamsFx,
} from '@widgets/widget-routes-params/stores/list/directionsParamsTable/store';
import { $Settings } from '@widgets/widget-routes-params/stores/settings';

getGraphDirectionsFx.use(queryGetGraphDirections);

// Формирование списка id направлений, для получения их параметров
sample({
  clock: getGraphDirectionsFx.done,
  source: { Settings: $Settings },
  fn: ({ Settings }, payload) =>
    ({
      scenarioId: Settings.scenarioId,
      directionIds: payload.result?.map(item => item?.id),
    }) as IQueryGetScenarioParamsArgs,
  target: getScenarioParamsFx,
});

getScenarioParamsFx.use(queryGetScenarioParams);

// Получение параметров направлений и запись их стор
sample({
  clock: getScenarioParamsFx.done,
  fn: payload =>
    payload.result?.map(item => ({
      id: item?.graphItemId,
      name: item?.itemName,
      year: item?.year,
      regionFromId: item?.regionFromId,
      regionToId: item?.regionToId,
      regionFromName: item?.regionFromName,
      regionToName: item?.regionToName,
      expressTrainTime: item?.durationExpressSuburbanTrain || 0,
      nightTrainTime: item?.durationNightTrain || 0,
      dayTrainTime: item?.durationDayTrain || 0,
      suburbanTrainTime: item?.durationSuburbanTrain || 0,
      expressTrainCost: item?.priceExpressSuburbanTrain || 0,
      nightTrainCost: item?.priceNightTrain || 0,
      dayTrainCost: item?.priceDayTrain || 0,
      suburbanTrainCost: item?.priceSuburbanTrain || 0,
      expressTrainFrequency: item?.frequencyExpressSuburbanTrain || 0,
      nightTrainFrequency: item?.frequencyNightTrain || 0,
      dayTrainFrequency: item?.frequencyDayTrain || 0,
      suburbanTrainFrequency: item?.frequencySuburbanTrain || 0,
      isErrorExpressTrainTime: false,
      isErrorNightTrainTime: false,
      isErrorDayTrainTime: false,
      isErrorSuburbanTrainTime: false,
      isErrorExpressTrainCost: false,
      isErrorNightTrainCost: false,
      isErrorDayTrainCost: false,
      isErrorSuburbanTrainCost: false,
      isErrorExpressTrainFrequency: false,
      isErrorNightTrainFrequency: false,
      isErrorDayTrainFrequency: false,
      isErrorSuburbanTrainFrequency: false,
    })) as GraphParamsType[],
  target: DirectionsParamsTableApi.updateAllParamsDirections,
});

// Обновление параметров направлений
sample({
  clock: requestUpdateParamsDirection,
  source: {
    DirectionsParamsTable: $DirectionsParamsTable,
    Settings: $Settings,
  },
  fn: ({ DirectionsParamsTable, Settings }) =>
    ({
      scenarioId: Settings.scenarioId as string,
      data: DirectionsParamsTable.directions?.map(direction => ({
        durationDayTrain: direction.dayTrainTime,
        durationExpressSuburbanTrain: direction.expressTrainTime,
        durationNightTrain: direction.nightTrainTime,
        durationSuburbanTrain: direction.suburbanTrainTime,
        frequencyDayTrain: direction.dayTrainFrequency,
        frequencyExpressSuburbanTrain: direction.expressTrainFrequency,
        frequencyNightTrain: direction.nightTrainFrequency,
        frequencySuburbanTrain: direction.suburbanTrainFrequency,
        priceDayTrain: direction.dayTrainCost,
        priceExpressSuburbanTrain: direction.expressTrainCost,
        priceNightTrain: direction.nightTrainCost,
        priceSuburbanTrain: direction.suburbanTrainCost,
        regionFromId: direction.regionFromId,
        regionToId: direction.regionToId,
        graphItemId: direction.id,
        year: direction.year,
      })),
    }) as IMutationUpdateScenarioParamsArgs,
  target: updateScenarioParamsFx,
});

updateScenarioParamsFx.use(mutationUpdateScenarioParams);
