import { createApi, createStore } from 'effector';

import { LayersVisibleStore } from '@features/pt-forecast-new/stores/map/layersVisible';

export const $SelectedFeatures = createStore<SelectedFeaturesStore[]>([]);

export const SelectedFeaturesApi = createApi($SelectedFeatures, {
  setSelectedFeatures: (_, payload: SelectedFeaturesStore[]) => payload,
  selectAndFocusFeature: (_, payload: SelectAndFocusFeaturePayload) => [
    {
      id: payload.id,
      layout: payload.layout,
    },
  ],
  reset: () => [],
});

export type SelectedFeaturesStore = {
  layout: keyof LayersVisibleStore;
  id: string;
};

export type SelectAndFocusFeaturePayload = SelectedFeaturesStore & {
  coords: number[] | number[][];
};
