import { useUnit } from 'effector-react';
import { useMemo } from 'react';

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { InspectorInfrastructure } from '@features/pt-forecast-new/components/Inspector/Infrastructure/InspectorInfrastructure';
import { InspectorCustomRailwayGraph } from '@features/pt-forecast-new/components/Inspector/customRailwayGraph/InspectorCustomRailwayGraph';
import { InspectorCustomRailwayStation } from '@features/pt-forecast-new/components/Inspector/customRailwayStation/InspectorCustomRailwayStation';
import {
  $Inspector,
  eInspectorType,
} from '@features/pt-forecast-new/stores/inspector';

export const InspectorContainer = () => {
  const { type } = useUnit($Inspector);

  const InspectorContent = useMemo(() => {
    switch (type) {
      case eInspectorType.infrastructure:
        return <InspectorInfrastructure />;
      case eInspectorType.customRailwayStation:
        return <InspectorCustomRailwayStation />;
      case eInspectorType.customRailwayGraph:
        return <InspectorCustomRailwayGraph />;
      default:
        return null;
    }
  }, [type]);

  return (
    <Box sx={InspectorContainerMuiStyle}>
      <AutoFullHeight>{InspectorContent}</AutoFullHeight>
    </Box>
  );
};

const InspectorContainerMuiStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '0',
  right: '0',
  zIndex: '2',
  background: theme => theme.palette.background.default,
  '& > *': {
    borderTop: '1px solid #EBECEF',
    padding: '16px 32px 16px 32px',
    boxShadow: 'rgba(0, 0, 0, 0.1) -5px 4px 2px 0px',
    width: '500px',
    maxWidth: '500px',
    overflowY: 'auto',
  },
};
