import { styled } from '@mui/material';

export interface InspectorCircleIconProps {
  borderColor: 'black' | 'train' | 'auto' | 'expressways' | 'avia';
  backgroundColor:
    | 'white'
    | 'auto'
    | 'trainNight'
    | 'trainDay'
    | 'trainHighSpeed'
    | 'trainLocal'
    | 'trainSpeedLocal'
    | 'expressways'
    | 'avia';
}

export const InspectorCircleIcon = styled('div')<InspectorCircleIconProps>(
  ({ theme, borderColor, backgroundColor }) => ({
    border: '1px solid',
    borderColor:
      borderColor === 'black'
        ? borderColor
        : theme.palette.customs[borderColor],
    borderRadius: '50%',
    width: theme.typography.pxToRem(12),
    height: theme.typography.pxToRem(12),
    minWidth: theme.typography.pxToRem(12),
    backgroundColor:
      backgroundColor === 'white'
        ? backgroundColor
        : theme.palette.customs[backgroundColor],
  }),
);
