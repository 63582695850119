import * as turf from '@turf/turf';
import { fromLonLat } from 'ol/proj';

/**
 *  @TODO НУЖНО ДОДЕЛАТЬ, на короткие отрезки слишком выгибается
 *  @name getAirLineCurve
 *  @description Функция для создания выгнутой линии(Кривая безье)
 *  @param a Координата 1
 *  @param b  Координата 2
 *  @param deltaCurve смещение от центра
 * */
export const getAirLineCurve = (
  a: Array<number>,
  b: Array<number>,
  deltaCurve: number = 0.8,
) => {
  const center = turf.getCoords(turf.center(turf.points([a, b])));

  const arc = [center[0] + deltaCurve, center[1] + deltaCurve];

  return turf.getCoords(
    turf.bezierSpline(
      turf.lineString([fromLonLat(a), fromLonLat(arc), fromLonLat(b)]),
    ),
  );
};
