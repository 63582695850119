import { apiClient } from '@api/client';
import { IMutation, IMutationScenarioComputeArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

export const mutationRunComputeScenario = async (
  params: IMutationScenarioComputeArgs,
) => {
  const result = await apiClient.mutate<
    IMutation,
    IMutationScenarioComputeArgs
  >({
    mutation: gql`
      mutation ComputeScenarioMutation($scenarioId: String!) {
        scenarioCompute(scenarioId: $scenarioId) {
          ok
        }
      }
    `,
    variables: {
      scenarioId: params.scenarioId,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  return result.data?.scenarioCompute || null;
};
