import { createEvent, createStore } from 'effector';

export const enum EPlayerErrorType {
  Ruler = 'ruler',
  ScenarioCalculation = 'scenarioCalculation',
}

export interface IPlayerError {
  type: EPlayerErrorType;
  description: string;
}
export const $ScenarioErrors = createStore<IPlayerError[] | null>(null);

export const resetErrorsFn = createEvent<void>();
export const addErrorFn = createEvent<IPlayerError>();
