import { ITrainSortEnum } from '@api/gql/tpu-types';

export const FIELD = {
  NUMBER: 'number',
  PLATFORM: 'platform',
  WAY: 'way',
  ROUTE: 'route',
  ARRIVAL_TIME: 'arrivalTime',
  DEPARTURE_TIME: 'departureTime',
  WAGONS: 'wagons',
};

export const SORT_VALUE = {
  asc: {
    [FIELD.NUMBER]: ITrainSortEnum.NumberAsc,
    [FIELD.PLATFORM]: ITrainSortEnum.PlatformAsc,
    [FIELD.WAY]: ITrainSortEnum.WayAsc,
    [FIELD.ROUTE]: ITrainSortEnum.RouteAsc,
    [FIELD.ARRIVAL_TIME]: ITrainSortEnum.ArrivalTimeAsc,
    [FIELD.DEPARTURE_TIME]: ITrainSortEnum.DepartureTimeAsc,
    [FIELD.WAGONS]: ITrainSortEnum.WagonsAsc,
  },
  desc: {
    [FIELD.NUMBER]: ITrainSortEnum.NumberDesc,
    [FIELD.PLATFORM]: ITrainSortEnum.PlatformDesc,
    [FIELD.WAY]: ITrainSortEnum.WayDesc,
    [FIELD.ROUTE]: ITrainSortEnum.RouteDesc,
    [FIELD.ARRIVAL_TIME]: ITrainSortEnum.ArrivalTimeDesc,
    [FIELD.DEPARTURE_TIME]: ITrainSortEnum.DepartureTimeDesc,
    [FIELD.WAGONS]: ITrainSortEnum.WagonsDesc,
  },
};
