import { SelectedComputingScenario } from '@features/pt-constructor/store';
import { QueryEditUserScenarioArgs } from '@features/pt-constructor/store/api/queryEditUserScenario';
import { divisionBy100AndFractionalPartUpTo4Digits } from '@features/pt-constructor/utils';

export const editScenarioReformatter = (
  scenario: SelectedComputingScenario | null,
): QueryEditUserScenarioArgs => {
  let body: QueryEditUserScenarioArgs = {
    name: scenario?.name || '',
    id: scenario?.id || '',
  };

  if (scenario?.socEcoScenario?.isNew)
    body = {
      ...body,
      socEcoScenarioParams: {
        socEcoParentId: scenario?.socEcoScenario?.id,
        socEcoComputeName:
          scenario?.socEcoScenario?.name || 'Безымянный сценарий',
        revenueGrowthRate: divisionBy100AndFractionalPartUpTo4Digits(
          scenario?.socEcoScenario?.revenueGrowthRate,
        ),
        growthRateOfMotorization: divisionBy100AndFractionalPartUpTo4Digits(
          scenario?.socEcoScenario?.growthRateOfMotorization,
        ),
        growthRateInTheNumberOfPlacements:
          divisionBy100AndFractionalPartUpTo4Digits(
            scenario?.socEcoScenario?.growthRateInTheNumberOfPlacements,
          ),
        jobGrowthRate: divisionBy100AndFractionalPartUpTo4Digits(
          scenario?.socEcoScenario?.jobGrowthRate,
        ),
        populationGrowthRate: divisionBy100AndFractionalPartUpTo4Digits(
          scenario?.socEcoScenario?.populationGrowthRate,
        ),
        salaryGrowthRate: divisionBy100AndFractionalPartUpTo4Digits(
          scenario?.socEcoScenario?.salaryGrowthRate,
        ),
      },
      socEcoScenarioType: scenario?.socEcoScenario?.type || 4,
    };
  else
    body = {
      ...body,
      socEcoScenarioId: scenario?.socEcoScenario?.id || '',
      socEcoScenarioType: scenario?.socEcoScenario?.type || 4,
    };
  if (scenario?.transportScenario?.isNew)
    body = {
      ...body,
      transportScenarioParams: {
        transportComputeName:
          scenario?.transportScenario?.name || 'Безымянный сценарий',
        // transportParentId: scenario.transportScenario.id,
        projectObjects: (
          scenario?.transportScenario?.transportProjects || []
        ).map(project => ({
          projectId: project.id,
          year: project.year,
        })),
      },
      transportScenarioType: scenario?.transportScenario?.type || 4,
    };
  else
    body = {
      ...body,
      transportScenarioId: scenario?.transportScenario?.id || '',
      transportScenarioType: scenario?.transportScenario?.type || 4,
    };
  return body;
};
