import {
  EMapFeatureLayout,
  EMapSelectFeaturesModes,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';

export const FEATURES_SELECT_MODE: Record<
  EMapFeatureLayout,
  EMapSelectFeaturesModes
> = {
  [EMapFeatureLayout.areaBorder]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.auto]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.highway]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.plannedTileHighway]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.plannedHighway]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.directionHighway]: EMapSelectFeaturesModes.path,
  [EMapFeatureLayout.railway]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.plannedTileRailway]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.plannedRailway]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.directionRailway]: EMapSelectFeaturesModes.path,
  [EMapFeatureLayout.avia]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.airline]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.busStation]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.railwayStation]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.intermediateCorrespondents]:
    EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.airport]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.pointA]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.pointB]: EMapSelectFeaturesModes.segment,
  [EMapFeatureLayout.multimodal]: EMapSelectFeaturesModes.segment,
};
