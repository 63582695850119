import { sample } from 'effector';
import { matchPath } from 'react-router-dom';

import { ROUTES } from '@constants/routes';

import {
  $ScenarioId,
  getScenarioIdFn,
  getTurnstilesByScenarioIdFx,
  getWaysPlatformsByScenarioIdFx,
  resetScenarioIdFn,
} from '@features/tpu-simulation-model/store';

$ScenarioId.reset(resetScenarioIdFn).on(getScenarioIdFn, () => {
  const matchedInfo = matchPath(
    { path: `${ROUTES.TPU_EDITOR}/:id` },
    window.location.pathname,
  );

  return matchedInfo?.params.id ?? null;
});

sample({
  clock: getScenarioIdFn,
  source: $ScenarioId,
  filter: id => !!id,
  fn: id => id as string,
  target: [getWaysPlatformsByScenarioIdFx, getTurnstilesByScenarioIdFx],
});
