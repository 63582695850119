import { IScenarioType } from '@api/gql/tpu-types';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import { Box, List, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { Accordion } from '@components/Accordion';
import { Accesses, Actions, Table } from '@components/TableTPU';

import { Status } from '@features/tpu-calculation-scenarios/components/Status';
import { useContextCSHandlers } from '@features/tpu-calculation-scenarios/hooks';
import { $Form } from '@features/tpu-calculation-scenarios/stores/form.store';
import { $ProjectId } from '@features/tpu-calculation-scenarios/stores/project.store';
import { $Registry } from '@features/tpu-calculation-scenarios/stores/registry.store';

import { dateToDateTime } from '@utils/dateToDateTime';
import { formatDate } from '@utils/formatDate';

export const RegistyCalculationScenariosContainer = () => {
  const $registry = useUnit($Registry);
  const $form = useUnit($Form);
  const $projectId = useUnit($ProjectId);

  const {
    getAllScenarios,
    openScenarioForEdit,
    copyScenario,
    openScenarioForViewing,
    openDeleteScenarioModal,
  } = useContextCSHandlers();

  const columns: GridColDef<IScenarioType>[] = [
    {
      field: 'name',
      headerName: 'Название',
      minWidth: 357,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <Accesses />
          <Box sx={{ width: '90%', wordWrap: 'break-word' }}>{row.name}</Box>
        </>
      ),
    },
    {
      field: 'created',
      headerName: 'Дата создания',
      width: 160,
      sortable: false,
      renderCell: ({ row }) => formatDate(row.created),
    },
    {
      field: 'modified',
      headerName: 'Изменен',
      width: 160,
      sortable: false,
      renderCell: ({ row }) =>
        dateToDateTime(row.modified) === dateToDateTime(row.created)
          ? null
          : formatDate(row.modified),
    },
    {
      field: 'state',
      headerName: 'Статус',
      width: 300,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Status value={row.state} />
          <Actions
            onEdit={openScenarioForEdit}
            onDelete={() =>
              openDeleteScenarioModal({ id: row.id, name: row.name })
            }
            onCopy={copyScenario}
            id={row.id}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    if ($projectId) {
      getAllScenarios($projectId);
    }
  }, [getAllScenarios, $projectId]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        flex: '541',
        boxShadow: '0px 0px 8px 0px #00000026',
        borderRadius: '8px',
        backgroundColor: theme => theme.palette.background.paper,
        padding: '24px',
      }}
    >
      <Typography variant={'subtitle1'}>Реестр сценариев расчета</Typography>

      <Accordion
        summaryComponent={
          <Typography variant={'subtitle1'}>Пользовательские</Typography>
        }
      >
        <List sx={{ ml: -1, mt: -1 }}>
          <Table
            rows={$registry.own}
            columns={columns}
            dataGridProps={{
              getRowId: row => row.id,
              rowSelectionModel: $form.id,
              onCellClick: row => {
                openScenarioForViewing(row.id as string);
              },
            }}
          />
        </List>
      </Accordion>
    </Box>
  );
};
