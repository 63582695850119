import { Box, Typography } from '@mui/material';

import { IPoint } from '@features/tpu-simulation-model/types';

interface AttributeProps {
  points: IPoint[];
}
export function Coordinates({ points }: AttributeProps) {
  return (
    <Box>
      <Typography>Координаты опорных точек</Typography>
      {points.map((item, index) => (
        <Typography
          key={item.x + item.y + index}
          sx={{
            color: props => props.palette.text.secondary,
            fontSize: theme => theme.typography.pxToRem(12),
            mt: '4px',
          }}
        >
          X: {item.x} / Y: {item.y}
        </Typography>
      ))}
    </Box>
  );
}
