import {
  $ModalSocioEconomic,
  ModalSocioEconomicApi,
} from '@features/ag-constructor/components/ModalSocEco/store';
import {
  AgEcoParam,
  AgSocEcoScenarioParams,
  agEcoParams,
} from '@features/ag-constructor/constants/agEcoParams';
import { $ComputedScenarioList } from '@features/ag-constructor/stores/lists/scenarios';
import { SliderSocioEconomic } from '@features/pt-constructor/components/sliderSocioEconomic';
import {
  baseEcoParamValue,
  maxEcoParamValue,
  minEcoParamValue,
} from '@features/pt-constructor/store';
import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { FormLabel } from '@components/FormLabel';
import { NumberField } from '@components/NumberField';

export const TwoStep = () => {
  const $modalSocioEconomic = useUnit($ModalSocioEconomic);
  const { setParams } = useUnit(ModalSocioEconomicApi);
  const { socEcoScenarios } = useUnit($ComputedScenarioList);

  const baseParams = useMemo<AgSocEcoScenarioParams | null>(() => {
    if (!$modalSocioEconomic.params) return null;
    const baseScenario = socEcoScenarios.find(
      scenario => scenario.id === $modalSocioEconomic.selectedId,
    );
    if (!baseScenario) return null;
    return {
      annualPopulationGrowthRate: Number(
        baseScenario.annualPopulationGrowthRate.toFixed(2),
      ),
      annualEducationPlacesGrowthRate: Number(
        baseScenario.annualEducationPlacesGrowthRate.toFixed(2),
      ),
      annualRetailSpaceGrowthRate: Number(
        baseScenario.annualRetailSpaceGrowthRate.toFixed(2),
      ),
      annualJobsGrowthRate: Number(
        baseScenario.annualJobsGrowthRate.toFixed(2),
      ),
      annualServiceJobsGrowthRate: Number(
        baseScenario.annualServiceJobsGrowthRate.toFixed(2),
      ),
      annualSalariesGrowthRate: Number(
        baseScenario.annualSalariesGrowthRate.toFixed(2),
      ),
      annualGardenPartnershipGrowthRate: Number(
        baseScenario.annualGardenPartnershipGrowthRate.toFixed(2),
      ),
    };
  }, [
    $modalSocioEconomic.params,
    $modalSocioEconomic.selectedId,
    socEcoScenarios,
  ]);

  const applyParam = useCallback(
    (param: AgEcoParam, value: number | string | undefined) => {
      if (Number(value) > maxEcoParamValue) value = maxEcoParamValue;
      if (Number(value) < minEcoParamValue) value = minEcoParamValue;

      setParams({ [param.name]: value });
    },
    [setParams],
  );

  const onChangeParamValue = useCallback(
    (param: AgEcoParam) => (value: number | string | undefined) => {
      applyParam(param, value);
    },
    [applyParam],
  );

  const getValueSlider = useCallback(
    (param: AgEcoParam) => (value: number) => {
      applyParam(param, value);
    },
    [applyParam],
  );

  return (
    <>
      <Typography variant="subtitle2">{$modalSocioEconomic.name}</Typography>

      <Grid
        container
        display={'flex'}
        direction={'column'}
        gap={3}
        mt={3}
      >
        {agEcoParams.map(param => (
          <FormLabel
            title={param.title}
            id={param.name}
            key={param.name}
          >
            <Grid
              display={'grid'}
              gridTemplateColumns={'repeat(2, 1fr)'}
              alignItems={'center'}
              gap={2}
              mt={0.5}
            >
              <NumberField
                value={
                  $modalSocioEconomic.params[param.name] ?? baseEcoParamValue
                }
                getValue={onChangeParamValue(param)}
                placeholder={param.title}
              />

              <SliderSocioEconomic
                value={
                  $modalSocioEconomic.params[param.name] ?? baseEcoParamValue
                }
                base={baseParams ? baseParams[param.name] : 0}
                getValue={getValueSlider(param)}
              />
            </Grid>
          </FormLabel>
        ))}
      </Grid>
    </>
  );
};
