import * as React from 'react';

import {
  Box,
  Button,
  SxProps,
  TableCell,
  TableRow,
  Theme,
} from '@mui/material';

import { TableProjectCellActions } from '@features/ag-projects-registry/components/TableProjects/TableProjectCellActions';
import { EAgProjectStatusType } from '@features/ag-projects-registry/constants/agProjectStatuses';
import {
  EListProject,
  ProjectType,
} from '@features/ag-projects-registry/store/lists/projects';

import { palette } from '@system/theme/palette';

export type TableProjectRowProps = {
  isSelected: boolean;
  labelId: string;
  row: ProjectType;
  onClickRow: (_event: React.MouseEvent<unknown>, row: ProjectType) => void;
  onClickProjectButton: (
    _event: React.MouseEvent<unknown>,
    row: ProjectType,
  ) => void;
};

export const TableProjectRow = (props: TableProjectRowProps) => {
  const { labelId, isSelected, row, onClickRow, onClickProjectButton } = props;

  return (
    <TableRow
      hover
      aria-checked={isSelected}
      selected={isSelected}
      tabIndex={-1}
      sx={{ cursor: 'pointer' }}
      onClick={e => onClickRow(e, row)}
    >
      <TableCell
        component="th"
        scope="row"
        id={labelId}
        sx={TableCellSX}
      >
        {row.number}
      </TableCell>
      <TableCell sx={TableCellSX}>
        <Button
          variant="text"
          sx={{
            fontSize: theme => theme.typography.pxToRem(12),
            display: 'contents',
          }}
          onClick={e => onClickProjectButton(e, row)}
        >
          {row.name}
        </Button>
      </TableCell>
      <TableCell sx={TableCellSX}>{row.agglomeration.name}</TableCell>
      <TableCell sx={TableCellSX}>{row.date}</TableCell>
      <TableCell
        sx={{
          ...TableCellSX,
          ...ColorStatusSX(row.status),
        }}
      >
        {EListProject[row.status]}
      </TableCell>

      <TableCell sx={TableCellSX}>
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {row.user}

          {isSelected && <TableProjectCellActions row={row} />}
        </Box>
      </TableCell>
    </TableRow>
  );
};

const TableCellSX: SxProps<Theme> = {
  fontSize: theme => theme.typography.pxToRem(12),
  py: theme => theme.spacing(1.5),
  lineHeight: 2,
};

const ColorStatusSX = (status: keyof typeof EListProject) => {
  let color;

  switch (status) {
    case EAgProjectStatusType.Queue:
      color = palette.text.secondary;
      break;
    case EAgProjectStatusType.Calculation:
      color = palette.text.primary;
      break;
    case EAgProjectStatusType.Success:
      color = palette.success.main;
      break;
    case EAgProjectStatusType.Failure:
      color = palette.secondary.main;
      break;
  }

  return { color };
};
