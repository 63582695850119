import { ITrainTypeEnum, ITurnOnOffTrainInput } from '@api/gql/tpu-types';
import {
  getScenarioIdFn,
  setPageFn,
  setPageSizeFn,
  setSearchFn,
  setSortFn,
  setTrainTypeFn,
  turnOnOffTrainFx,
} from '@features/tpu-train-schedule/stores';
import { useUnit } from 'effector-react';

import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

export interface UseTrainScheduleHandlersType {
  setPage(payload: number): void;
  setPageSize(payload: number): void;
  setSearch(payload: string): void;
  setSort(payload: GridSortModel): void;
  getScenarioId(): void;
  setTrainType(payload: ITrainTypeEnum): void;
  turnOnOffTrain(payload: ITurnOnOffTrainInput): void;
}

export const useTrainScheduleHandlers = (): UseTrainScheduleHandlersType => {
  const events = useUnit({
    setPageFn,
    setPageSizeFn,
    setSearchFn,
    setSortFn,
    getScenarioIdFn,
    setTrainTypeFn,
    turnOnOffTrainFx,
  });

  return {
    setPage: events.setPageFn,
    setPageSize: events.setPageSizeFn,
    setSearch: events.setSearchFn,
    setSort: events.setSortFn,
    getScenarioId: events.getScenarioIdFn,
    setTrainType: events.setTrainTypeFn,
    turnOnOffTrain: events.turnOnOffTrainFx,
  };
};
