import { createApi, createStore } from 'effector';

import { VehiclesFilters } from '@features/pt-forecast-new/stores/filters';

const initState: UIStore = {
  panels: {
    filter: false,
    paramsForecast: false,
    forecastDetails: false,
    inspector: false,
    newInfrastructure: false,
  },
  disabled: {
    recalculation: true,
  },
  statusRecalculation: false,
  isLoadingGeometry: false,
  backdrop: false,
  showDashboard: false,
  dashboardPage: 1,
  hideFilterFields: ['expressTrain'],
  isSetPoint: false,
  mapLegend: false,
  editMode: false,
  isDrawGraph: false,
  placeRailwayStation: false,
  widgetRoutesParams: false,
};

export const $UI = createStore<UIStore>(initState);

export const UIApi = createApi($UI, {
  toggleFilterPanel: store => ({
    ...store,
    panels: {
      ...store.panels,
      filter: !store.panels.filter,
    },
  }),
  setVisibleFilterPanel: (store, payload: boolean) => ({
    ...store,
    panels: {
      ...store.panels,
      filter: payload,
    },
  }),
  setVisibleProgressGeometry: (store, payload: boolean) => ({
    ...store,
    isLoadingGeometry: payload,
  }),
  setMapBackdrop: (store, payload: boolean) => ({
    ...store,
    backdrop: payload,
  }),
  toggleParamsForecastPanel: store => ({
    ...store,
    panels: {
      ...store.panels,
      paramsForecast: !store.panels.paramsForecast,
    },
  }),
  setVisibleParamsForecastPanel: (store, payload: boolean) => ({
    ...store,
    panels: {
      ...store.panels,
      paramsForecast: payload,
    },
  }),
  setVisibleNewInfrastructurePanel: (store, payload: boolean) => ({
    ...store,
    panels: {
      ...store.panels,
      newInfrastructure: payload,
    },
  }),
  setVisibleWidgetRoutesParams: (store, payload: boolean) => ({
    ...store,
    widgetRoutesParams: payload,
  }),
  toggleForecastPanel: store => ({
    ...store,
    panels: {
      ...store.panels,
      forecastDetails: !store.panels.forecastDetails,
    },
  }),
  setVisibleForecastPanel: (store, payload: boolean) => ({
    ...store,
    panels: {
      ...store.panels,
      forecastDetails: payload,
    },
  }),
  setDisabledRecalculation: (store, payload: boolean) => ({
    ...store,
    disabled: {
      ...store.disabled,
      recalculation: payload,
    },
  }),
  setVisibleStatusRecalculation: (store, payload: boolean) => ({
    ...store,
    statusRecalculation: payload,
  }),
  setShowDashboard: (store, payload: boolean) => ({
    ...store,
    showDashboard: payload,
  }),
  setDashboardPage: (store, payload: 1 | 2) => ({
    ...store,
    dashboardPage: payload,
  }),
  setHideFilterFields: (store, payload: Array<keyof VehiclesFilters>) => ({
    ...store,
    hideFilterFields: payload,
  }),
  setIsSetPoint: (store, payload: boolean) => ({
    ...store,
    isSetPoint: payload,
  }),
  toggleMapLegend: store => ({
    ...store,
    mapLegend: !store.mapLegend,
  }),
  setVisibleInspector: (store, payload: boolean) => ({
    ...store,
    panels: {
      ...store.panels,
      inspector: payload,
    },
  }),
  setEditMode: (store, payload: boolean) => ({
    ...store,
    editMode: payload,
  }),
  setPlaceRailwayStations: (store, payload: boolean) => ({
    ...store,
    placeRailwayStation: payload,
  }),
  togglePlaceRailwayStation: store => ({
    ...store,
    placeRailwayStation: !store.placeRailwayStation,
  }),
  setDrawGraph: (store, payload: boolean) => ({
    ...store,
    isDrawGraph: payload,
  }),
  reset: () => initState,
});

type UIStore = {
  /** Видимость панелей */
  panels: {
    /** Видимость блока фильтров */
    filter: boolean;
    /** Видимость Блока параметров перерасчета */
    paramsForecast: boolean;
    /** Видимость панели детализации расчета по корреспонденции */
    forecastDetails: boolean;
    /** Видимость инспектора фичи */
    inspector: boolean;
    /** Видимость панели новой инфраструктуры */
    newInfrastructure: boolean;
  };
  /** Заблокированные интерфейсы */
  disabled: {
    /** Панель параметров перерасчета */
    recalculation: boolean;
  };
  /** Статус расчета текущего сценария */
  statusRecalculation: boolean;
  /** Статус загрузки геометрии */
  isLoadingGeometry: boolean;
  /** deprecated */
  backdrop: boolean;
  /** Видимость страницы даш бордов */
  showDashboard: boolean;
  /** выбранная странрица дашбордов */
  dashboardPage: 1 | 2;
  /** Перечень фильтров недоступных в текущем сценарии */
  hideFilterFields: Array<keyof VehiclesFilters>;
  /** Флаг установки поинта АБ курсором */
  isSetPoint: boolean;
  /** Видимость легенды на карте */
  mapLegend: boolean;
  /** Режим редактирования */
  editMode: boolean;
  /** Режим размещения жд станции */
  placeRailwayStation: boolean;
  /** Режим построения графа */
  isDrawGraph: boolean;
  /** Видимость виджета параметра сообщения при редактировании инфраструктуры */
  widgetRoutesParams: boolean;
};
