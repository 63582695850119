import { SvgIcon, SvgIconProps } from '@mui/material';

export const ExitIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        d="M15.46 6.13v11.428l5.052 3.11V3.488L15.46 6.13Zm-5.987-.155c.778 0 1.322-.622 1.322-1.399s-.622-1.321-1.4-1.321c-.777 0-1.32.621-1.32 1.399 0 .777.621 1.321 1.399 1.321ZM1 1v1.322h20.678v19.357H1V23h22V1H1Zm4.742 7.385L4.42 11.573c-.233.544-.077 1.088.545 1.243l.31.078L6.83 9.163c.156-.311.39-.545.622-.7l-.31 3.965-.623 4.042-1.788 2.488s-.233.31 0 .777c.156.311.778.7.778.7l2.72-3.654.623-3.81h.077l1.866 6.686s.078.39.311.622c.233.233.622.233.622.233l.777-.155-2.099-8.24v-3.81c0-1.166-.31-1.865-1.788-1.943-.155.078-1.943-.233-2.876 2.021Zm7.696 3.732.078-.156-2.332-1.632-.078 1.477 1.166.7c.466.233.855.155 1.166-.39Z"
        fill="#425A69"
      />
    </SvgIcon>
  );
};
