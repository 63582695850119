export const SOC_ECO_TH_2_4 = [
  {
    id: 'transportObject',
    label: 'ТПУ',
  },
  {
    id: 'transportArea',
    label: 'Транспортный район',
  },
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'simultaneousMaximumPassengerTraffic',
    label: 'Единовременный максимальный пассажиропоток, чел.',
  },
  {
    id: 'numberFloorsTPU',
    label: 'Этажность ТПУ',
  },
  {
    id: 'numberElevatorsTPU',
    label: 'Требуемое количество лифтов в ТПУ',
  },
  {
    id: 'numberEscalatorsTPU',
    label: 'Требуемое количество эскалаторов в ТПУ',
  },
];
