import {
  IQuery,
  IQueryGetScenarioByIdArgs,
  IScenarioTpuFlowByYearType,
} from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

const initStore = {
  scenarioTpusFlow: null,
};

export const $SecondPageDashboard = createStore<SecondPageType>(initStore);

export const SecondPageApi = createApi($SecondPageDashboard, {
  setTPU: (store, payload) => ({
    ...store,
    scenarioTpusFlow: payload.result.scenarioTpusFlow,
  }),
  reset: () => initStore,
});

export const getTPUFx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();

export type SecondPageType = {
  scenarioTpusFlow: IScenarioTpuFlowByYearType[] | null;
};
