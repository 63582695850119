import { IFloorFileInfoType } from '@api/gql/tpu-types';

import { Box, MenuItem, Select, Typography } from '@mui/material';

import { ChevronDownIcon, FloorsIcon } from '@components/icons';

interface FloorsProps {
  options: IFloorFileInfoType[];
  value: string;
  onChange: (arg: string) => void;
}

export const Floors = ({ options, value, onChange }: FloorsProps) => {
  const getIndexOfSelected = (id: string) => {
    return options.findIndex(option => option.extraId === id);
  };

  if (!options.length) return null;

  return (
    <Select
      value={value}
      onChange={e => onChange(e.target.value)}
      defaultValue={options[0]?.extraId}
      renderValue={selectedId => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            mr: '8px',
          }}
        >
          <FloorsIcon />
          {options.find(({ extraId }) => extraId === selectedId)?.description ??
            ''}
          <Typography
            sx={{
              color: theme => theme.palette.text.secondary,
              fontSize: 'inherit',
              lineHeight: 'inherit',
            }}
          >{`(${getIndexOfSelected(selectedId) + 1}/${
            options.length
          })`}</Typography>
        </Box>
      )}
      IconComponent={ChevronDownIcon}
      sx={{
        height: '48px',
        pointerEvents: 'auto',
        width: 'fit-content',

        '.MuiSelect-select': {
          padding: '12px 24px',
          border: 'unset',
          borderRadius: '4px',
          boxShadow: '0px 0px 8px 0px #00000026',
        },

        '.MuiOutlinedInput-notchedOutline': {
          border: 'unset',
        },
      }}
    >
      {options.map(({ extraId, description }) => (
        <MenuItem
          value={extraId}
          key={extraId}
        >
          {description}
        </MenuItem>
      ))}
    </Select>
  );
};
