import { useUnit } from 'effector-react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './stores';
import { DisplayedPageType } from './stores/dashboard/types';

import { Box, Stack } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { ROUTES } from '@constants/routes';

import { RecalculationNotification } from '@features/ag-forecast/components/RecalculationNotification/RecalculationNotification';
import { DashboardToolBarContainer } from '@features/ag-forecast/containers/DashboardToolBarContainer/DashboardToolBarContainer';
import { DashboardsContainers } from '@features/ag-forecast/containers/DashboardsContainer';
import { InspectorContainer } from '@features/ag-forecast/containers/InspectorContainer/InspectorContainer';
import { MapCentredWrapper } from '@features/ag-forecast/containers/MapContainer/MapCentredWrapper';
import { MapContainer } from '@features/ag-forecast/containers/MapContainer/MapContainer';
import { MapControlsContainer } from '@features/ag-forecast/containers/MapControlsContainer/MapControlsContainer';
import { MapFiltersContainer } from '@features/ag-forecast/containers/MapFiltersContainer/MapFiltersContainer';
import { ModalsContainer } from '@features/ag-forecast/containers/ModalsContainer/ModalsContainer';
import { ToolBarContainer } from '@features/ag-forecast/containers/ToolBarContainer/ToolBarContainer';
import { YearLineContainer } from '@features/ag-forecast/containers/YearLineContainer/YearLineContainer';
import { getScenarioTPUFx } from '@features/ag-forecast/stores/lists/TPUs/store';
import { fetchMainTpuOptions } from '@features/ag-forecast/stores/lists/mainTpuOptions';
import { fetchTimeSlotsFx } from '@features/ag-forecast/stores/lists/timeSlots';
import { getVehiclesFx } from '@features/ag-forecast/stores/lists/vehicles';
import {
  $AgGeometry,
  FetchAgGeometryFx,
} from '@features/ag-forecast/stores/map';
import { fetchTpuInfrastructure } from '@features/ag-forecast/stores/map/editor';
import {
  FeatureSettingsApi,
  fetchScenarioNameFx,
  fetchScenarioStatus,
} from '@features/ag-forecast/stores/settings';
import { $UI } from '@features/ag-forecast/stores/ui';
import { storesReset } from '@features/ag-forecast/stores/unmountReset';
import { getTimelineYearsFx } from '@features/ag-forecast/stores/yearLine';

import { useBlockNavigation } from '@utils/NavigationBlockContext/NavigationBlockContext';

const ContainerMuiStyle: SxProps<Theme> = {
  position: 'relative',
};

export const AgglomerationForecastContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setBlockNavigation } = useBlockNavigation();
  const queryParams = new URLSearchParams(location.search);
  const [projectId] = useState(queryParams.get('projectId') || '');
  const [scenarioId] = useState(queryParams.get('scenarioId') || '');
  const { editTPUMode } = useUnit($UI);

  //TODO:L Вынести в стор UI
  const [isShowDashboards, setIsShowDashboards] = useState(false);
  const [dashboardPage, setDashboardPage] = useState<DisplayedPageType>(1);
  const { setProjectUUID, setScenarioUUID, setScenarioStatus } =
    useUnit(FeatureSettingsApi);
  const handlers = useUnit({
    getVehiclesFx,
    FetchAgGeometryFx,
  });

  const { center } = useUnit($AgGeometry);

  const hideDashboardView = useCallback(() => {
    setIsShowDashboards(false);
  }, []);

  const showDashboardView = useCallback(() => {
    setIsShowDashboards(true);
  }, []);

  const onChangeView = useCallback((view: DisplayedPageType) => {
    setDashboardPage(view);
  }, []);

  useEffect(() => {
    if (!projectId) {
      navigate(ROUTES.AGGLOMERATION);
      return;
    }

    if (!scenarioId) {
      navigate(`${ROUTES.AGGLOMERATION_CONSTRUCTOR}?projectId=${projectId}`);
      return;
    }

    fetchTimeSlotsFx();
    getTimelineYearsFx({ id: projectId });

    /** Получение доступных видов транспорта */
    handlers.getVehiclesFx({ projectId });
    handlers.FetchAgGeometryFx({ id: projectId });
    setProjectUUID(projectId || '');
    fetchScenarioNameFx({ scenarioId });
    setScenarioUUID(scenarioId || '');
    getScenarioTPUFx({ scenarioId });

    fetchScenarioStatus();
  }, [
    handlers,
    navigate,
    projectId,
    scenarioId,
    setProjectUUID,
    setScenarioUUID,
  ]);

  useEffect(() => {
    fetchTpuInfrastructure();
    fetchMainTpuOptions();
  }, [center]);

  useEffect(() => {
    if (editTPUMode) setBlockNavigation(true);
    else setBlockNavigation(false);
  }, [editTPUMode, setBlockNavigation]);

  useEffect(
    () => () => {
      storesReset();
      setScenarioStatus(null);
    },
    [setScenarioStatus],
  );

  return (
    <>
      <ModalsContainer />

      {isShowDashboards ? (
        <DashboardToolBarContainer
          onBackButtonClick={hideDashboardView}
          onChangeView={onChangeView}
        />
      ) : (
        <ToolBarContainer showDashboardView={showDashboardView} />
      )}
      {isShowDashboards ? (
        <DashboardsContainers
          page={dashboardPage}
          // onHideDashboardView={hideDashboardView}
        />
      ) : (
        <Box sx={ContainerMuiStyle}>
          <MapFiltersContainer />

          <Stack direction="row">
            <Stack flex={1}>
              <AutoFullHeight
                style={{ backgroundColor: 'white', overflow: 'hidden' }}
              >
                <MapCentredWrapper centerCoords={center}>
                  <MapContainer />
                </MapCentredWrapper>
              </AutoFullHeight>
            </Stack>
          </Stack>

          <MapControlsContainer />

          <InspectorContainer onChartClick={showDashboardView} />

          <YearLineContainer />
          <RecalculationNotification />
        </Box>
      )}
    </>
  );
};
