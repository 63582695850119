import { IDownloadFileTypeEnum } from '@api/gql/tpu-types';
import { downloadFile } from '@api/queries';
import { sample } from 'effector';

import {
  $Report,
  $SimulationData,
  DownloadedReportData,
  downloadReportFormServerFx,
  downloadReportOnClientFx,
  resetReportStoreFn,
  setIsReportExistFn,
  triggerDownloadReportFn,
} from '@features/tpu-calculation-scenarios-viewing/stores';

import { base64ToBlob } from '@utils/fileConversion';

const resetStore = sample({
  clock: resetReportStoreFn,
  source: $Report,
  fn: sourceData => {
    if (sourceData.url) {
      URL.revokeObjectURL(sourceData.url);
    }
  },
});

$Report
  .reset(resetStore)
  .on(setIsReportExistFn, (state, payload) => {
    return { ...state, isExist: payload };
  })
  .on(downloadReportFormServerFx.doneData, (state, payload) => {
    if (!payload) return { ...state, url: null, name: null };

    return { ...state, url: payload.url, name: payload.name };
  });

sample({
  clock: triggerDownloadReportFn,
  source: $Report,
  filter: sourceData => !!(sourceData.url && sourceData.name),
  fn: sourceData => {
    return {
      url: sourceData.url,
      name: sourceData.name,
    } as DownloadedReportData;
  },
  target: downloadReportOnClientFx,
});

sample({
  clock: triggerDownloadReportFn,
  source: { $report: $Report, $simulationData: $SimulationData },
  filter: ({ $report, $simulationData }) =>
    !$report.url && !!$simulationData.simulationId,
  fn: ({ $simulationData }) => {
    return $simulationData.simulationId as string;
  },
  target: downloadReportFormServerFx,
});

sample({
  clock: downloadReportFormServerFx.doneData,
  filter: (clockData): clockData is { url: string; name: string } =>
    !!clockData,
  target: downloadReportOnClientFx,
});

downloadReportOnClientFx.use(({ url, name }) => {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('target', '_blank');
  a.setAttribute('download', name);
  a.click();
});

downloadReportFormServerFx.use(async id => {
  const response = await downloadFile({
    objectId: id,
    fileType: IDownloadFileTypeEnum.SimulationReport,
  });
  const fileInfo = response.data?.downloadFile;

  if (!(fileInfo && fileInfo.file && fileInfo.name)) {
    return null;
  }

  const { file, name } = fileInfo;
  const blob = base64ToBlob(file, 'application/octet-stream');

  return { url: URL.createObjectURL(blob), name };
});
