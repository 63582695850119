import { sample } from 'effector';
import { Feature } from 'ol';
import { LineString } from 'ol/geom';

import { EFilterMapLayout } from '@components/MapFilters';

import {
  getWidthLine,
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import {
  $FilterMap,
  $FilterRegions,
  $Map,
  $PassengerTrafficInspector,
  SetMapFeaturesTypes,
  changeMap,
  setMapFeatures,
} from '@features/passenger-traffic/stores';

import { getAirLineCurve } from '@utils/geometry';

// Контроль рендера фичи авиалинии
sample({
  clock: changeMap,
  source: {
    FilterMap: $FilterMap,
    FilterRegions: $FilterRegions,
    PassengerTrafficInspector: $PassengerTrafficInspector,
    Map: $Map,
  },
  fn: ({ FilterMap, FilterRegions, PassengerTrafficInspector, Map }) => {
    const layer: SetMapFeaturesTypes = {
      params: {
        layout: EMapFeatureLayout.airline,
        width: undefined,
      },
      features: [],
    };

    // В фильтре отключено авиа
    if (!FilterMap.avia) return layer;

    // Не выбрано отображение существующих структур
    if (
      !FilterMap.actual &&
      FilterMap.layout === EFilterMapLayout.Infrastructure
    )
      return layer;

    // Не отображать авиалинию на слое инфраструктуры
    if (FilterMap.layout === EFilterMapLayout.Infrastructure) return layer;

    // Не установлены оба поина АБ
    if (!FilterRegions.selectedRegionA || !FilterRegions.selectedRegionB)
      return layer;

    // При отсутствии пасс потока авиа не строим
    if (!PassengerTrafficInspector.passengerTraffic.avia) return layer;

    // НЕ блокирующее условие определения толщины линии
    if (FilterMap.layout === EFilterMapLayout.PassengerFlows) {
      layer.params.width = getWidthLine(Map.passengerTrafficPercent.avia || 0);
    }

    //Если поинты АБ установлены, и в ТР есть аэропорты, можно построить авиалинию между районами
    if (FilterRegions.selectedRegionA && FilterRegions.selectedRegionB) {
      if (FilterRegions.selectedRegionA.airportSet.length === 0) return layer;
      if (FilterRegions.selectedRegionB.airportSet.length === 0) return layer;

      const coordinateA =
        FilterRegions.selectedRegionA.center?.geometry.coordinates;
      const coordinateB =
        FilterRegions.selectedRegionB.center?.geometry.coordinates;

      layer.features = [
        new Feature({
          geometry: new LineString(getAirLineCurve(coordinateA, coordinateB)),
          [layerIdKey]: EMapFeatureLayout.airline,
          [layerLayoutKey]: EMapFeatureLayout.airline,
        }),
      ];
    }

    // Пока глобально отображать нечего
    return layer;
  },
  target: setMapFeatures,
});
