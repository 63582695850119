import { useUnit } from 'effector-react';

import Grid from '@mui/material/Unstable_Grid2';

import { SocioEconomic } from '@features/pt-constructor/forms/socio-economic';
import {
  $Calculations,
  $SelectedCalculationScenario,
} from '@features/pt-constructor/store';

export const SocioEconomicContainer = () => {
  const { readonly, socEcoScenarios } = useUnit($Calculations);
  const { id, socEcoScenario } = useUnit($SelectedCalculationScenario);
  const { isNewCalculationScenario } = useUnit($Calculations);

  return (
    (id || isNewCalculationScenario) && (
      <Grid xs={4}>
        <SocioEconomic
          selectedSocEco={socEcoScenario}
          socEcoScenarios={socEcoScenarios}
          readonly={readonly}
        />
      </Grid>
    )
  );
};
