import { useUnit } from 'effector-react';
import { useEffect, useRef, useState } from 'react';

import { Notification } from '@components/notification';

import { RulerButton } from '@features/tpu-simulation-model/components/Controllers/Ruler/Button';
import { RulerInputWindow } from '@features/tpu-simulation-model/components/Controllers/Ruler/InputWindow';
import { Clue } from '@features/tpu-simulation-model/components/Controllers/Ruler/clue';
import { useSimulationModelHandlers } from '@features/tpu-simulation-model/hooks';
import { $RulerTool } from '@features/tpu-simulation-model/store';

export const RulerContainer = () => {
  const ref = useRef<HTMLButtonElement>(null);
  const $ruler = useUnit($RulerTool);
  const {
    setIsOpenModalResetRuler,
    resetRulerMeasurement,
    rulerLengthSetter,
    startRulerMeasurement,
    setIsOpenNotification,
  } = useSimulationModelHandlers();

  const [rect, setRect] = useState<DOMRect | null>(null);

  const top = rect ? rect.y + rect.height + 24 : undefined;

  useEffect(() => {
    const instance = ref.current;
    if (instance) {
      setRect(instance.getBoundingClientRect());
    }
  }, []);

  return (
    <>
      <RulerButton
        isOpenRulerInputWindow={$ruler.isOpenRulerInputWindow}
        isScaleExist={!!($ruler.px && $ruler.meters)}
        onReset={() => {
          setIsOpenModalResetRuler(true);
        }}
        onClick={startRulerMeasurement}
        ref={ref}
      />
      {$ruler.isOpenRulerInputWindow && (
        <RulerInputWindow
          onSave={rulerLengthSetter}
          top={top}
          onClose={resetRulerMeasurement}
        />
      )}
      <Clue
        startPoint={$ruler.startPoint}
        endPoint={$ruler.endPoint}
        isScaleBeingMeasured={$ruler.isScaleBeingMeasured}
      />
      <Notification
        isSuccess={$ruler.isSuccess}
        isOpen={$ruler.isOpenNotification}
        successText={'Масштаб успешно определен'}
        errorText={'Что-то пошло не так'}
        onClose={() => setIsOpenNotification(false)}
      />
    </>
  );
};
