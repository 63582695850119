import styled from '@emotion/styled';
import {
  $CurrentScenario,
  CurrentScenarioApi,
} from '@features/ag-constructor/stores/currentScenario';
import { useUnit } from 'effector-react';
import { SyntheticEvent, useCallback } from 'react';

import { Checkbox, FormControlLabel, typographyClasses } from '@mui/material';

export const TakeIntoInfraContainer = () => {
  const $currentScenario = useUnit($CurrentScenario);
  const { setTakeIntoInfra } = useUnit(CurrentScenarioApi);

  const readonly = $currentScenario?.readonly !== false;

  const onChangeHandler = useCallback(
    (_: SyntheticEvent, checked: boolean) => {
      setTakeIntoInfra(checked);
    },
    [setTakeIntoInfra],
  );

  return (
    <Wrapper>
      <FormControlLabel
        checked={$currentScenario?.takeIntoInfra || false}
        onChange={onChangeHandler}
        disabled={readonly}
        control={<Checkbox />}
        label="Учитывать вводимую инфраструктуру в пассажиропотоках"
      />
    </Wrapper>
  );
};

export const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 1, 2, 4),
  borderRadius: theme.shape.borderRadius * 2,
  background: theme.palette.background.paper,
  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.15)',
  [`& > .${typographyClasses.subtitle1}`]: {
    marginBottom: theme.spacing(2),
  },
}));
