import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import { Alert, Box } from '@mui/material';

import { ECalculateStatus } from '@features/pt-forecast-new/constants/ECalculateStatus';
import { $FeatureSettings } from '@features/pt-forecast-new/stores/settings';
import { $UI, UIApi } from '@features/pt-forecast/stores';

export const FinallyCalculationNotification = () => {
  const { scenarioCalculateStatus } = useUnit($FeatureSettings);
  const { statusRecalculation } = useUnit($UI);
  const { setVisibleStatusRecalculation } = useUnit(UIApi);

  useEffect(() => {
    if (!statusRecalculation) return;

    setTimeout(() => {
      setVisibleStatusRecalculation(false);
    }, 10000);
  });

  return (
    statusRecalculation &&
    (scenarioCalculateStatus === ECalculateStatus.SUCCESS ||
      scenarioCalculateStatus === ECalculateStatus.FAILURE) && (
      <Box
        sx={{
          position: 'absolute',
          top: theme => theme.spacing(20),
          left: 'calc(50% - 200px)',
          zIndex: 10,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {scenarioCalculateStatus === ECalculateStatus.SUCCESS && (
          <Alert
            variant="filled"
            severity="success"
            icon={false}
            sx={{
              height: 'min-content',
              width: '400px',
            }}
          >
            Изменения сохранены
          </Alert>
        )}

        {scenarioCalculateStatus === ECalculateStatus.FAILURE && (
          <Alert
            variant="filled"
            severity="error"
            icon={false}
            sx={{
              height: 'min-content',
              width: '400px',
            }}
          >
            Ошибка сохранения
          </Alert>
        )}
      </Box>
    )
  );
};
