import {
  ICreateScenario,
  IMutation,
  IMutationCreateScenarioArgs,
  IMutationCreateScenarioSocEcoArgs,
  IMutationScenarioComputeArgs,
  IMutationUpdateScenarioArgs,
  IQuery,
  IQueryGetScenarioByIdArgs,
  Maybe,
} from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

import { AgSocEcoScenarioParams } from '@features/ag-constructor/constants/agEcoParams';

// import { SocEcoScenarioListItem } from '@features/ag-constructor/stores/lists/scenarios';

export type SelectedSocEcoScenario = AgSocEcoScenarioParams & {
  name: string | null;
  id?: string;
};

type SelectedScenarioState = {
  name: string;
  id?: string;
  socEcoScenario: SelectedSocEcoScenario | null;
  readonly: boolean;
  takeIntoInfra: boolean;
};

const emptySocEcoScenario: SelectedSocEcoScenario = {
  name: '',
  annualPopulationGrowthRate: 1,
  annualGardenPartnershipGrowthRate: 1,
  annualRetailSpaceGrowthRate: 2,
  annualServiceJobsGrowthRate: 1,
  annualEducationPlacesGrowthRate: 3,
  annualSalariesGrowthRate: 1,
  annualJobsGrowthRate: 1,
};

const emptyScenarioState: SelectedScenarioState = {
  name: '',
  socEcoScenario: null,
  takeIntoInfra: true,
  readonly: true,
};

export const $CurrentScenario = createStore<SelectedScenarioState | null>(null);

export const CurrentScenarioApi = createApi($CurrentScenario, {
  setScenario: (_, scenario: SetScenarioPayload) => ({
    ...scenario,
    readonly: true,
  }),
  setName: (state, name: string) => ({
    ...(state || emptyScenarioState),
    name,
  }),
  setReadonly: (state, readonly: boolean) => ({
    ...(state || emptyScenarioState),
    readonly,
  }),
  setTakeIntoInfra: (state, takeIntoInfra: boolean) => ({
    ...(state || emptyScenarioState),
    takeIntoInfra,
  }),
  setSocEcoField: (state, payload: Partial<SelectedSocEcoScenario>) => ({
    ...(state || emptyScenarioState),
    socEcoScenario: {
      ...(state?.socEcoScenario || emptySocEcoScenario),
      ...payload,
    },
  }),
  setEditMode: (state, payload: boolean) => ({
    ...(state || emptyScenarioState),
    editMode: payload,
  }),
  createSocEcoScenario: (state, payload: SelectedSocEcoScenario) => ({
    ...(state || emptyScenarioState),
    socEcoScenario: {
      ...payload,
    },
  }),
  selectSocEcoScenario: (state, payload: SelectedSocEcoScenario) => ({
    ...(state || emptyScenarioState),
    socEcoScenario: {
      ...payload,
    },
  }),
  editCurrentScenario: (
    _,
    payload: Omit<SelectedScenarioState, 'readonly'>,
  ) => ({
    ...payload,
    readonly: false,
  }),
  createEmptyScenario: () => ({
    ...emptyScenarioState,
    readonly: false,
  }),
  reset: () => null,
});

export const getScenarioByIdFx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();

export const createScenarioFx = createEffect<
  IMutationCreateScenarioArgs,
  Maybe<ICreateScenario> | undefined
>();

export const fetchCreateScenarioSocEcoFx = createEffect<
  IMutationCreateScenarioSocEcoArgs,
  IMutation['createScenarioSocEco'] | undefined
>();

export const updateScenarioFx = createEffect<
  IMutationUpdateScenarioArgs,
  IMutation['updateScenario'] | undefined
>();

export const runComputeScenarioFx = createEffect<
  IMutationScenarioComputeArgs,
  IMutation['scenarioCompute']
>();

export type SetScenarioPayload = Omit<SelectedScenarioState, 'readonly'>;
