import { sample } from 'effector';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EFilterMapLayout } from '@components/MapFilters';

import {
  createStationFeatures,
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import {
  $FilterMap,
  $FilterRegions,
  $Map,
  SetMapFeaturesTypes,
  changeMap,
  setMapFeatures,
} from '@features/passenger-traffic/stores';

sample({
  clock: changeMap,
  source: { Map: $Map, FilterMap: $FilterMap, FilterRegions: $FilterRegions },
  fn: ({ Map, FilterMap, FilterRegions }) => {
    const layer: SetMapFeaturesTypes = {
      params: {
        layout: EMapFeatureLayout.airport,
      },
      features: [],
    };

    // В фильтре отключено отображение авиа
    if (!FilterMap.avia) return layer;

    // Не выбрано отображение существующих структур
    if (
      !FilterMap.actual &&
      FilterMap.layout === EFilterMapLayout.Infrastructure
    )
      return layer;

    // Находимся не на слое инфраструктуры
    if (FilterMap.layout !== EFilterMapLayout.Infrastructure) return layer;

    //Если поинты АБ установлены, отобразить аэропорты только в их ТР
    if (FilterRegions.selectedRegionA && FilterRegions.selectedRegionB) {
      const stations = [
        ...(FilterRegions.selectedRegionA?.airportSet || []),
        ...(FilterRegions.selectedRegionB?.airportSet || []),
      ];
      layer.features = stations.map(
        airport =>
          new Feature({
            geometry: new Point(fromLonLat(airport.geometry.coordinates)),
            [layerIdKey]: airport.id,
            [layerLayoutKey]: EMapFeatureLayout.airport,
          }),
      );
      return layer;
    }

    // Глобальное отображение
    layer.features = createStationFeatures(
      Map.allAirports,
      EMapFeatureLayout.airport,
    );
    return layer;
  },
  target: setMapFeatures,
});
