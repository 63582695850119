import { useUnit } from 'effector-react';
import { ChangeEvent, useCallback } from 'react';

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';

import { AccordionFilter } from '@components/MapFilters/AccordionFilter';

import { $TPUs, TPUsApi } from '@features/ag-forecast/stores/lists/TPUs';

export const TPUList = () => {
  const { TPUs } = useUnit($TPUs);
  const { setTPUs } = useUnit(TPUsApi);

  const handleSelectTPU = useCallback(
    (e: ChangeEvent<HTMLInputElement>, id: string) => {
      const findTPU = TPUs?.find(item => item.id === id);
      findTPU!.selectedTPU = e.target.value;
      setTPUs(TPUs);
    },
    [TPUs, setTPUs],
  );

  return (
    <Stack>
      {TPUs?.map(
        tpu =>
          !tpu.parentTpu?.id && (
            <AccordionFilter
              TitleComponent={
                <Typography variant="subtitle2">{tpu.name}</Typography>
              }
              key={tpu.id}
            >
              <RadioGroup
                defaultValue={tpu.id}
                onChange={e => handleSelectTPU(e, tpu.id)}
                sx={{
                  ' .MuiFormControlLabel-root': {
                    height: '32px',
                  },
                }}
              >
                <FormControlLabel
                  value={tpu.id}
                  label="Основное размещение ТПУ"
                  control={<Radio />}
                />
                {tpu.childrenTpu.map(alt => (
                  <FormControlLabel
                    key={alt.id}
                    value={alt.id}
                    label={alt.name}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>
            </AccordionFilter>
          ),
      )}
    </Stack>
  );
};
