import { apiClient } from '@api/client';
import {
  IMutation,
  IQuery,
  IQueryTrainsByScenarioIdArgs,
  ITrainType,
} from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';
import { matchPath } from 'react-router-dom';

import { TPU_CLIENT_NAME } from '@constants/api';
import { ROUTES } from '@constants/routes';

import { SORT_VALUE } from '@features/tpu-train-schedule/constants';
import {
  $ScenarioId,
  getScenarioIdFn,
} from '@features/tpu-train-schedule/stores/scenarioId.store';
import {
  $Schedule,
  defaultState,
  getListOfTrainsFx,
  setPageFn,
  setPageSizeFn,
  setSearchFn,
  setSortFn,
  setTrainTypeFn,
  turnOnOffTrainFx,
} from '@features/tpu-train-schedule/stores/schedule.store';

$ScenarioId.on(getScenarioIdFn, () => {
  const matchedInfo = matchPath(
    { path: `${ROUTES.TPU_TRAIN_SCHEDULE}/:id` },
    window.location.pathname,
  );

  return matchedInfo?.params.id ?? null;
});

$Schedule
  .on(setPageFn, (state, page) => {
    return { ...state, page };
  })
  .on(setTrainTypeFn, (state, payload) => {
    return { ...state, trainType: payload, page: defaultState.page };
  })
  .on(setPageSizeFn, (state, pageSize) => {
    return { ...state, pageSize, page: 1 };
  })
  .on(setSortFn, (state, payload) => {
    const isSortModelExist = !!payload.length;
    if (!isSortModelExist) return { ...state, sort: null };

    const [{ field, sort }] = payload;

    return { ...state, sort: [SORT_VALUE[sort as 'asc' | 'desc'][field]] };
  })
  .on(setSearchFn, (state, search) => {
    return { ...state, search };
  })
  .on(getListOfTrainsFx.done, (state, { result }) => {
    const list = result?.results?.filter(Boolean) as ITrainType[] | undefined;
    const count = result?.pagination?.count ?? 0;
    return {
      ...state,
      list: list ?? [],
      count,
    };
  })
  .on(turnOnOffTrainFx.done, (state, { params, result }) => {
    if (result) {
      return {
        ...state,
        list: state.list.map((item: ITrainType) =>
          item.id === params.trainId ? { ...item, on: params.on } : item,
        ),
      };
    }
  });

sample({
  clock: [
    getScenarioIdFn,
    setPageFn,
    setPageSizeFn,
    setSortFn,
    setSearchFn,
    setTrainTypeFn,
  ],
  source: { scenarioId: $ScenarioId, schedule: $Schedule },
  filter: ({ scenarioId }) => !!scenarioId,
  fn: ({ scenarioId, schedule }) => {
    const { page, pageSize, search, sort, trainType } = schedule;
    const returnedValue: IQueryTrainsByScenarioIdArgs = {
      scenarioId: scenarioId as string,
      page,
      pageSize,
      search,
      trainType,
      sort,
    };

    return returnedValue;
  },
  target: getListOfTrainsFx,
});

getListOfTrainsFx.use(async params => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query TrainsByScenarioId(
        $page: Int
        $pageSize: Int
        $scenarioId: UUID!
        $search: String
        $sort: [TrainSortEnum!]
        $trainType: TrainTypeEnum
      ) {
        trainsByScenarioId(
          page: $page
          pageSize: $pageSize
          scenarioId: $scenarioId
          search: $search
          sort: $sort
          trainType: $trainType
        ) {
          results {
            id
            on
            trainType
            arrivalTime
            departureTime
            number
            route
            wagons
            way
            platform
            embarking
            disembarking
            regularity
          }
          pagination {
            count
          }
        }
      }
    `,
    variables: params,
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data.trainsByScenarioId ?? null;
});

turnOnOffTrainFx.use(async params => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation TurnOnOffTrainMutation($trainId: UUID!, $on: Boolean!) {
        turnOnTrain(inputData: { trainId: $trainId, on: $on }) {
          ok
          train {
            trainType
          }
        }
      }
    `,
    variables: params,
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data?.turnOnTrain ?? null;
});
