import { Line } from 'react-konva';

import {
  IRoom,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

interface RoomProps extends IRoom {
  opacity: number;
  onSelect: (arg: ObjectOfTheSimulationModel) => void;
}

export const Room = ({ onSelect, opacity, ...props }: RoomProps) => {
  const { points } = props;

  return (
    <Line
      onClick={() => {
        onSelect(props);
      }}
      opacity={opacity}
      strokeWidth={4}
      stroke={'#E21A1A'}
      fill={'#E9EEF3'}
      closed={true}
      points={points.flatMap(({ x, y }) => [x, y])}
    />
  );
};
