import { SvgIcon, SvgIconProps } from '@mui/material';

export const AirportIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <circle
        cx="10"
        cy="10"
        r="10"
        fill="currentColor"
      />
      <circle
        cx="10"
        cy="10"
        r="10"
        stroke="white"
      />
      <path
        d="M13.977 7.68333C14.927 6.73333 15.2436 5.71667 14.7603 5.23333C14.6103 5.08333 14.3936 5 14.1603 5C13.6436 5 12.9436 5.38333 12.3103 6.01667L11.027 7.3L10.9936 7.31667L6.62695 6.5C6.49362 6.48333 6.31028 6.53333 6.22695 6.63333L5.76028 7.1C5.66028 7.2 5.61028 7.33333 5.64362 7.45C5.67695 7.56667 5.76028 7.66667 5.89362 7.73333L9.39362 9.11667L7.09362 11.9167L5.82695 11.6833C5.69362 11.6667 5.51028 11.7167 5.42695 11.8167L5.12695 12.1167C5.02695 12.2167 4.97695 12.35 5.01028 12.4667C5.04362 12.5833 5.12695 12.6833 5.26028 12.75L6.66028 13.3C6.67695 13.3 6.71028 13.3333 6.71028 13.35L7.26028 14.75C7.32695 14.9 7.46028 15 7.61028 15C7.71028 15 7.81028 14.95 7.89362 14.8833L8.19362 14.5833C8.29362 14.4833 8.34362 14.3167 8.32695 14.1833L8.09362 12.9167L10.8936 10.6167L12.277 14.1167C12.3436 14.2667 12.477 14.3667 12.627 14.3667C12.727 14.3667 12.827 14.3167 12.9103 14.25L13.377 13.7833C13.477 13.6833 13.527 13.5167 13.5103 13.3833L12.6936 9C12.6936 8.98333 12.6936 8.96667 12.7103 8.96667L13.977 7.68333Z"
        fill="white"
      />
    </SvgIcon>
  );
};
