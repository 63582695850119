import { CircularProgress, DialogContent, Typography } from '@mui/material';

import { CheckIcon, ErrorIcon } from '@components/icons';

import { ErrorsList } from '@features/tpu-calculation-scenarios/components/ModalPlanErrors/ErrorsList';

interface ContentProps {
  errors: string[] | null;
  isLoading: boolean;
}
const SuccessfulInformer = () => {
  return (
    <>
      <CheckIcon
        sx={{
          color: 'success.main',
          height: '44px',
          width: '44px',
          margin: '0 auto 8px auto',
        }}
      />
      <Typography>Проверка прошла успешно</Typography>
    </>
  );
};

export const ErrorInformer = () => {
  return (
    <>
      <ErrorIcon
        sx={{ height: '44px', width: '44px', margin: '0 auto 8px auto' }}
      />
      <Typography>Ошибка</Typography>
    </>
  );
};

export const Content = ({ errors, isLoading }: ContentProps) => {
  const withErrors = !!errors?.length;

  if (isLoading) {
    return (
      <DialogContent sx={{ textAlign: 'center' }}>
        <CircularProgress sx={{ margin: '0 auto' }} />
      </DialogContent>
    );
  }

  return (
    <DialogContent sx={{ textAlign: 'center' }}>
      {!withErrors && <SuccessfulInformer />}
      {withErrors && <ErrorInformer />}
      {withErrors && <ErrorsList errors={errors} />}
    </DialogContent>
  );
};
