import { useUnit } from 'effector-react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  UseAutocompleteProps,
  autocompleteClasses,
  inputBaseClasses,
  outlinedInputClasses,
} from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { FormLabel } from '@components/FormLabel';
import { CloseIcon } from '@components/icons';

import {
  ScenarioOption,
  TransportProject,
  TransportScenarioListItem,
  TransportSelectedScenario,
  setModalView,
} from '@features/pt-constructor/store';
import {
  $ErrorsCreateCalculation,
  checkErrorTransportationScenario,
} from '@features/pt-constructor/store/errorsCreateCalculation';
import { SelectedCalculationScenarioApi } from '@features/pt-constructor/store/selected/store';

import { PaperCard } from '../../components/PaperCard';

export const Transportation = ({
  selectedTransportScenario,
  transportProjects,
  transportScenarios,
  readonly,
}: Props) => {
  const [selectedListScenario, setSelectedListScenario] =
    useState<ScenarioOption | null>(null);
  const [transportProjectsList, setTransportProjectsList] =
    useState<TransportProject[]>(transportProjects);

  const $errorsCreateCalculation = useUnit($ErrorsCreateCalculation);
  const { selectTransportScenario, selectProjectsTransportScenario } = useUnit(
    SelectedCalculationScenarioApi,
  );

  // Изменить транспортный сценарий у выбранного сценария расчета
  const onChangeTransportType = useCallback<
    NonNullable<
      UseAutocompleteProps<ScenarioOption, false, false, false>['onChange']
    >
  >(
    (_e, val) => {
      if (!val) return;
      const transportScenario = transportScenarios.find(
        e => e.id === val.value,
      );
      if (!transportScenario) return;
      selectTransportScenario(transportScenario);
      $errorsCreateCalculation.isCheckErrors &&
        checkErrorTransportationScenario(transportScenario);
    },
    [
      $errorsCreateCalculation.isCheckErrors,
      selectTransportScenario,
      transportScenarios,
    ],
  );

  const scenariosList = useMemo<ScenarioOption[]>(
    () =>
      transportScenarios.map(scenario => ({
        value: scenario.id as string,
        label: scenario.name,
        type: scenario.type,
      })),
    [transportScenarios],
  );

  const onSearchScenario = useCallback<NonNullable<TextFieldProps['onChange']>>(
    e => {
      const value = (e.target as HTMLInputElement).value;

      if (value) {
        const containsScenarios = transportProjects.filter(e =>
          e.name.toLowerCase().includes(value.toLowerCase()),
        );

        setTransportProjectsList(containsScenarios);
      } else {
        setTransportProjectsList(transportProjects);
      }
    },
    [transportProjects],
  );

  const onChangeSelectTransportProject = useCallback(
    (project: TransportProject, checked: boolean) => {
      const currentTransportProjects =
        selectedTransportScenario?.transportProjects || [];
      if (checked)
        selectProjectsTransportScenario([...currentTransportProjects, project]);
      else
        selectProjectsTransportScenario(
          currentTransportProjects.filter(e => e.id !== project.id),
        );
    },
    [
      selectProjectsTransportScenario,
      selectedTransportScenario?.transportProjects,
    ],
  );

  // TODO: Тормозит рендер, оптимизировать
  const TransportationList = useMemo(() => {
    const selectedListNames = new Set(
      selectedTransportScenario?.transportProjects.map(
        scenario => scenario.name,
      ),
    );

    const selectedYars = Object.fromEntries(
      (selectedTransportScenario?.transportProjects || []).map(scenario => [
        scenario.name,
        scenario.year,
      ]),
    );

    return transportProjectsList.map(project => {
      const labelId = `checkbox-list-label-${project.id}`;
      const selected = selectedListNames.has(project.name);
      const disabled =
        readonly || !(selectedListScenario && !selectedListScenario?.value);
      return (
        <ListItem
          key={project.id}
          sx={{ justifyContent: 'space-between', mb: '2px' }}
          disablePadding
        >
          <FormControlLabel
            id={labelId}
            onChange={(_, checked) =>
              onChangeSelectTransportProject(project, checked)
            }
            control={
              <Checkbox
                disabled={disabled}
                checked={selected}
              />
            }
            label={project.name}
            sx={{
              ml: 0,
            }}
          />

          <ListItemSecondaryAction>
            <Typography
              variant={'body2'}
              sx={{ opacity: disabled ? 0.6 : 1 }}
            >
              {selectedYars[project.name] || project.year}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  }, [
    onChangeSelectTransportProject,
    readonly,
    selectedListScenario,
    selectedTransportScenario?.transportProjects,
    transportProjectsList,
  ]);

  const handleClearScenario = useCallback(
    () => setSelectedListScenario(null),
    [],
  );

  useEffect(() => {
    if (!selectedTransportScenario) return setSelectedListScenario(null);
    const scenario = scenariosList.find(
      e => e.value === selectedTransportScenario.id,
    ) || {
      value: '',
      label: selectedTransportScenario.name || '',
      type: 4,
    };
    setSelectedListScenario(scenario);
    setTransportProjectsList(transportProjects);
  }, [scenariosList, selectedTransportScenario, transportProjects]);

  useEffect(() => {
    setTransportProjectsList(transportProjects);
  }, [transportProjects]);

  return (
    <PaperCard title={'Транспортный сценарий'}>
      <AutoFullHeight
        style={{
          overflowY: 'auto',
          paddingRight: '24px',
        }}
        mb={24 + 32}
        maxHeight
      >
        <Stack
          gap={2}
          flexDirection={'row'}
          alignItems={'flex-end'}
          mb={$errorsCreateCalculation.isErrorTransportationScenario ? 1.5 : 4}
        >
          <FormLabel
            sx={{ flex: 1 }}
            title={'Сценарий'}
            id="scenario-select"
          >
            <Autocomplete
              id="scenario-select"
              disablePortal
              onChange={readonly ? undefined : onChangeTransportType}
              value={selectedListScenario}
              options={scenariosList}
              disabled={readonly}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              sx={{
                [`&.${autocompleteClasses.hasPopupIcon}.${autocompleteClasses.hasClearIcon} .${outlinedInputClasses.root}`]:
                  {
                    pr: 4,
                  },
                [`& .${inputBaseClasses.root}.${outlinedInputClasses.root}.${outlinedInputClasses.disabled}`]:
                  {
                    pointerEvents: 'none',
                  },
                [`&.${autocompleteClasses.root} .${autocompleteClasses.clearIndicator}`]:
                  {
                    display: 'none',
                  },
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  disabled={readonly}
                  placeholder="Сценарий"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {selectedListScenario && !readonly && (
                          <IconButton
                            size="small"
                            onClick={handleClearScenario}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={`${option.label}-${option.value}`}
                >
                  <Typography>{option.label}</Typography>
                </li>
              )}
            />
          </FormLabel>

          <Button
            disabled={readonly}
            onClick={useCallback(() => setModalView({ transport: true }), [])}
          >
            Создать новый
          </Button>
        </Stack>

        {$errorsCreateCalculation.isErrorTransportationScenario && (
          <Stack sx={{ mb: 4 }}>
            <Typography
              variant="caption"
              sx={{
                color: theme => theme.palette.secondary.main,
              }}
            >
              Необходимо выбрать существующий или создать новый сценарий
            </Typography>
          </Stack>
        )}

        <Typography variant={'subtitle1'}>Инфраструктурные проекты</Typography>

        <Stack
          gap={2}
          flexDirection={'row'}
          alignItems={'flex-end'}
          mb={4}
        >
          <TextField
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            disabled={readonly}
            onChange={onSearchScenario}
            placeholder="Найти проект"
          />

          {/* TODO: функционал добавления */}
          {/* <Button color={'secondary'}>Добавить проект</Button> */}
        </Stack>

        <List>
          {transportProjectsList.length ? (
            <ListItem
              sx={{ mb: 2 }}
              disablePadding
            >
              <ListItemSecondaryAction>
                <Typography variant={'body2'}>
                  {'Год ввода в экспл.'}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          ) : (
            <Typography variant={'body2'}>{'Проекты не найдены'}</Typography>
          )}

          {TransportationList}
        </List>
      </AutoFullHeight>
    </PaperCard>
  );
};

interface Props {
  selectedTransportScenario: TransportSelectedScenario | null;
  transportProjects: TransportProject[];
  transportScenarios: TransportScenarioListItem[];
  readonly: boolean;
}
