import { apiClient } from '@api/client';
import { IShareOfEntranceExitType } from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { TPU_CLIENT_NAME } from '@constants/api';

import {
  $ScenarioId,
  resetEntrancesExitsFn,
} from '@features/tpu-simulation-model/store';
import {
  $ListOfEntrances,
  $ListOfExits,
  getEntrancesExitsByScenarioIdFx,
} from '@features/tpu-simulation-model/store/listOfEntrancesExits/listOfEntrancesExits.store';

$ListOfEntrances
  .reset(resetEntrancesExitsFn)
  .on(getEntrancesExitsByScenarioIdFx.done, (_, { result }) => {
    return result.entrances;
  });

$ListOfExits
  .reset(resetEntrancesExitsFn)
  .on(getEntrancesExitsByScenarioIdFx.done, (_, { result }) => {
    return result.exits;
  });

sample({
  source: $ScenarioId,
  filter: (scenarioId): scenarioId is string => !!scenarioId,
  target: getEntrancesExitsByScenarioIdFx,
});

getEntrancesExitsByScenarioIdFx.use(async scenarioId => {
  const response = await apiClient.query<{
    entrances: IShareOfEntranceExitType[];
    exits: IShareOfEntranceExitType[];
  }>({
    query: gql`
      fragment FieldOfEntranceExit on ShareOfEntranceExitType {
        name
        share
        eid
      }

      query SampleShareOfEntrancesExitsByScenarioId($scenarioId: UUID!) {
        entrances: sampleShareOfEntrancesExitsByScenarioId(
          direction: ENTRANCE
          scenarioId: $scenarioId
        ) {
          ...FieldOfEntranceExit
        }

        exits: sampleShareOfEntrancesExitsByScenarioId(
          direction: EXIT
          scenarioId: $scenarioId
        ) {
          ...FieldOfEntranceExit
        }
      }
    `,
    variables: {
      scenarioId: scenarioId,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data;
});
