import { IPoint } from '@features/tpu-simulation-model/types';

interface ExtremePoints {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

const getExtremePoints = (points: IPoint[]): ExtremePoints | null => {
  if (!points.length) return null;

  return points.reduce(
    (acc, point) => {
      const { x, y } = point;
      const { left, right, top, bottom } = acc;

      return {
        left: x < left ? x : left,
        right: x > right ? x : right,
        top: y < top ? y : top,
        bottom: y > bottom ? y : bottom,
      };
    },
    {
      left: points[0].x,
      right: points[0].x,
      top: points[0].y,
      bottom: points[0].y,
    },
  );
};

export const getCenterBottomPoint = (points: IPoint[]): IPoint => {
  const extremePoints = getExtremePoints(points);

  if (!extremePoints) return { x: 0, y: 0 };

  return {
    x: Math.round((extremePoints.left + extremePoints.right) / 2),
    y: extremePoints.bottom,
  };
};
