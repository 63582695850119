import { SvgIcon, SvgIconProps } from '@mui/material';

export const BuffetIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        d="M1 20.738A2.26 2.26 0 0 0 3.254 23h17.491A2.26 2.26 0 0 0 23 20.738H1ZM18.258 10.673h.388a2.426 2.426 0 0 1 2.41 2.419 2.426 2.426 0 0 1-2.41 2.418h-.388v-4.837ZM7.53 19.801h8.784a1.928 1.928 0 0 0 1.944-1.95v-.858h.388c2.177 0 3.965-1.795 3.965-3.98 0-2.184-1.788-3.978-3.965-3.978H5.51v8.738c.078 1.17.933 2.028 2.021 2.028ZM9.085 5.837c0 .546-.233.936-.622 1.248a.597.597 0 0 0-.155.39c0 .312.155.468.466.468.078 0 .155 0 .233-.078.622-.468.933-1.092.933-1.95 0-.546-.233-1.092-.7-1.638-.466-.468-.7-.937-.7-1.405 0-.468.156-.78.467-1.092.078-.078.156-.156.156-.312 0-.312-.156-.468-.467-.468-.077 0-.233 0-.31.156-.467.468-.778 1.014-.778 1.716 0 .625.233 1.17.7 1.717.544.39.777.858.777 1.248ZM12.117 5.837c0 .546-.234.936-.622 1.248a.597.597 0 0 0-.156.39c0 .312.156.468.467.468.077 0 .155 0 .233-.078.622-.468.933-1.092.933-1.95 0-.546-.233-1.092-.7-1.638-.466-.468-.7-.937-.7-1.405 0-.468.156-.78.467-1.092.078-.078.155-.156.155-.312 0-.312-.155-.468-.466-.468-.078 0-.233 0-.311.156-.466.468-.777 1.014-.777 1.716 0 .625.233 1.17.7 1.717.543.39.777.858.777 1.248ZM15.226 5.837c0 .546-.233.936-.622 1.248a.597.597 0 0 0-.155.39c0 .312.155.468.466.468.078 0 .156 0 .233-.078.622-.468.933-1.092.933-1.95 0-.546-.233-1.092-.7-1.638-.466-.468-.7-.937-.7-1.405 0-.468.156-.78.467-1.092.078-.078.156-.156.156-.312 0-.312-.156-.468-.467-.468-.077 0-.233 0-.31.156-.467.468-.778 1.014-.778 1.716 0 .625.233 1.17.7 1.717.544.39.777.858.777 1.248Z"
        fill="#7E67DD"
      />
    </SvgIcon>
  );
};
