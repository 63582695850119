/**
 * Проверка имени проекта на минимальную длину
 * @param value имя
 * @returns строка с описанием ошибки
 */
export const checkingProjectNameMinLength = (value: string) => {
  if (value.length < 3) return 'Имя должно быть не меньше 3 символов';
};

/**
 * Проверка имени проекта на максимальную длину
 * @param value имя
 * @returns строка с описанием ошибки
 */
export const checkingProjectNameMaxLength = (value: string) => {
  if (value.length > 140) return 'Имя должно быть не больше 140 символов';
};

/**
 * Проверка имени проекта на максимальную длину
 * @param value имя
 * @returns массив строк с описанием ошибок
 */
export const checkingErrorsProjectName = (value: string): string[] => {
  const nameErrorMessages: string[] = [];

  errorsNameFns.forEach(fn => {
    const error = fn(value);

    error && nameErrorMessages.push(error);
  });

  return nameErrorMessages;
};

/** Массив функций валидаций для имени проекта, принимают имя */
export const errorsNameFns = [
  checkingProjectNameMinLength,
  checkingProjectNameMaxLength,
];
