import { apiClient } from '@api/client';
import { IQuery, IQueryGetScenarioTpuArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

export const queryGetScenarioTPU = async (
  project: IQueryGetScenarioTpuArgs,
) => {
  const result = await apiClient.query<
    Pick<IQuery, 'getScenarioTpu'>,
    IQueryGetScenarioTpuArgs
  >({
    query: gql`
      query QueryGetScenarioTPU($scenarioId: UUID!) {
        getScenarioTpu(scenarioId: $scenarioId) {
          id
          name
          parentTpu {
            id
          }
          childrenTpu {
            id
            name
          }
        }
      }
    `,
    variables: project,
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.getScenarioTpu;
};
