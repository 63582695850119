import { apiClient } from '@api/client';
import {
  IAgentShareInfoCategoryEnum,
  IAgentShareInfoColorEnum,
  IAgentShareInfoType,
  IMutation,
  IQuery,
  IUpdateAgentShareInfoInput,
} from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';
import { matchPath } from 'react-router-dom';

import { TPU_CLIENT_NAME } from '@constants/api';
import { ROUTES } from '@constants/routes';

import {
  $Form,
  $IsOpenTypeForm,
  $TriggerSaveForm,
  createPassengerTypeFn,
  createTypeFx,
  getTypeByIdFx,
  openTypeForCreateFn,
  openTypeForEditFn,
  savePassengerTypeFn,
  setFormStateFn,
  setIsOpenTypeFormFn,
  setIsTriggerSaveFormFn,
  updateTypeFx,
} from '@features/tpu-passenger-types/stores/form.store';
import {
  $IsOpenShareForm,
  $ShareForm,
  $TriggerSaveShareForm,
  openShareForEditFn,
  savePassengerShareFn,
  setIsOpenShareFormFn,
  setIsTriggerSaveShareFormFn,
  updateShareFx,
} from '@features/tpu-passenger-types/stores/formShare.store';
import {
  $ScenarioId,
  setScenarioIdFn,
} from '@features/tpu-passenger-types/stores/registry.store';
import {
  $Registry,
  getAllAgentShareInfoCategoryFx,
} from '@features/tpu-passenger-types/stores/registry.store';
import { IShareForm } from '@features/tpu-passenger-types/types';

$IsOpenTypeForm.on(setIsOpenTypeFormFn, (_, value) => value);
$TriggerSaveForm.on(setIsTriggerSaveFormFn, (_, value) => value);
$IsOpenShareForm.on(setIsOpenShareFormFn, (_, value) => value);
$TriggerSaveShareForm.on(setIsTriggerSaveShareFormFn, (_, value) => value);

$Registry.on(getAllAgentShareInfoCategoryFx.done, (_, { result }) => {
  return {
    [IAgentShareInfoCategoryEnum.Visitors]: result.filter(
      item => item.category === IAgentShareInfoCategoryEnum.Visitors,
    ),
    [IAgentShareInfoCategoryEnum.DisembarkingCommuter]: result.filter(
      item =>
        item.category === IAgentShareInfoCategoryEnum.DisembarkingCommuter,
    ),
    [IAgentShareInfoCategoryEnum.DisembarkingLongDistance]: result.filter(
      item =>
        item.category === IAgentShareInfoCategoryEnum.DisembarkingLongDistance,
    ),
    [IAgentShareInfoCategoryEnum.EmbarkingCommuter]: result.filter(
      item => item.category === IAgentShareInfoCategoryEnum.EmbarkingCommuter,
    ),
    [IAgentShareInfoCategoryEnum.EmbarkingLongDistance]: result.filter(
      item =>
        item.category === IAgentShareInfoCategoryEnum.EmbarkingLongDistance,
    ),
  };
});

$IsOpenTypeForm.on(setIsOpenShareFormFn, () => false);

$ScenarioId.on(setScenarioIdFn, () => {
  const matchedInfo = matchPath(
    { path: `${ROUTES.TPU_PASSENGER_TYPES}/:id` },
    window.location.pathname,
  );

  return matchedInfo?.params.id;
});

$Form
  .on(getTypeByIdFx.done, (_, { result }) => {
    if (result) {
      return {
        scenarioId: result?.scenario?.id,
        agentShareInfoId: result.id,
        category: result.category ?? IAgentShareInfoCategoryEnum.Visitors,
        name: result.name,
        share: result.share,
        color: result.color ?? IAgentShareInfoColorEnum.Green,
        absoluteQuantity: result.absoluteQuantity,
        maximumTravelSpeed: result?.maximumTravelSpeed,
        averageTimeBeforeTrain: result.averageTimeBeforeTrain,
        sharePassengerWithTickets: result.sharePassengerWithTickets,
        shareVisitorBuyingTickets: result.shareVisitorBuyingTickets,
        ticketOfficeProbability: result.ticketOfficeProbability,
        tpmProbability: result.tpmProbability,
        foodProbability: result.foodProbability,
        storeProbability: result.storeProbability,
        bankProbability: result.bankProbability,
        informationTableProbability: result.informationTableProbability,
        toiletProbability: result?.toiletProbability,
        otherProbability: result.otherProbability,
      };
    }
  })
  .on(setIsOpenTypeFormFn, state => ({
    ...state,
    agentShareInfoId: '',
  }))
  .on(openTypeForCreateFn, state => ({
    ...state,
    category: IAgentShareInfoCategoryEnum.Visitors,
    name: '',
    color: IAgentShareInfoColorEnum.Green,
    maximumTravelSpeed: 1.2,
    averageTimeBeforeTrain: 0,
    sharePassengerWithTickets: 0,
    shareVisitorBuyingTickets: 0,
    ticketOfficeProbability: 0,
    tpmProbability: 0,
    foodProbability: 0,
    storeProbability: 0,
    bankProbability: 0,
    informationTableProbability: 20,
    toiletProbability: 0,
    otherProbability: 0,
  }))
  .on(setFormStateFn, (state, result) => {
    return {
      ...state,
      category: result.category ?? IAgentShareInfoCategoryEnum.Visitors,
      name: result.name,
      color: result.color ?? IAgentShareInfoColorEnum.Green,
      maximumTravelSpeed: result?.maximumTravelSpeed,
      averageTimeBeforeTrain: result.averageTimeBeforeTrain,
      sharePassengerWithTickets: result.sharePassengerWithTickets,
      shareVisitorBuyingTickets: result.shareVisitorBuyingTickets,
      ticketOfficeProbability: result.ticketOfficeProbability,
      tpmProbability: result.tpmProbability,
      foodProbability: result.foodProbability,
      storeProbability: result.storeProbability,
      bankProbability: result.bankProbability,
      informationTableProbability: result?.informationTableProbability,
      toiletProbability: result?.toiletProbability,
      otherProbability: result.otherProbability,
    };
  });

sample({
  clock: savePassengerShareFn,
  fn: ({ category, scenarioId, shareInfo }) => ({
    category,
    scenarioId,
    shareInfo: shareInfo.map(({ absoluteQuantity, id, share }) => ({
      share,
      absoluteQuantity,
      infoId: id,
    })),
  }),
  target: updateShareFx,
});

sample({
  clock: openShareForEditFn,
  source: { scenarioId: $ScenarioId, registry: $Registry },
  fn: ({ scenarioId, registry }, category) => {
    const typeState = registry[category];
    const data: IShareForm = {
      scenarioId,
      category,
      shareInfo: typeState,
    };
    return data;
  },
  target: $ShareForm,
});

sample({
  clock: openShareForEditFn,
  fn: type => !!type,
  target: $IsOpenShareForm,
});

sample({
  clock: openShareForEditFn,
  fn: () => false,
  target: $IsOpenTypeForm,
});

sample({
  clock: openTypeForCreateFn,
  fn: () => true,
  target: $IsOpenTypeForm,
});

sample({
  clock: [openTypeForCreateFn, openTypeForEditFn],
  fn: () => false,
  target: $IsOpenShareForm,
});

sample({
  clock: createPassengerTypeFn,
  source: { scenarioId: $ScenarioId },
  fn: ({ scenarioId }, clockData) => ({
    ...clockData,
    scenarioId,
  }),
  target: createTypeFx,
});

sample({
  clock: savePassengerTypeFn,
  source: { form: $Form },
  fn: (_, clockData) => ({ ...(clockData as IUpdateAgentShareInfoInput) }),
  target: updateTypeFx,
});

sample({
  clock: [updateTypeFx.done, updateShareFx.done],
  source: { scenarioId: $ScenarioId },
  fn: ({ scenarioId }) => scenarioId,
  target: getAllAgentShareInfoCategoryFx,
});
sample({
  clock: updateTypeFx.done,
  fn: () => false,
  target: setIsOpenTypeFormFn,
});
sample({
  clock: updateShareFx.done,
  fn: () => false,
  target: setIsOpenShareFormFn,
});

sample({
  clock: openTypeForEditFn,
  target: getTypeByIdFx,
});
sample({
  clock: openTypeForEditFn,
  fn: () => true,
  target: setIsOpenTypeFormFn,
});
sample({
  clock: createTypeFx.done,
  source: { scenarioId: $ScenarioId },
  fn: ({ scenarioId }) => scenarioId,
  target: getAllAgentShareInfoCategoryFx,
});

sample({
  clock: createTypeFx.done,
  fn: () => false,
  target: setIsOpenTypeFormFn,
});

getAllAgentShareInfoCategoryFx.use(async scenarioId => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query allAgentShareInfo($scenarioId: UUID!) {
        allAgentShareInfo(scenarioId: $scenarioId) {
          id
          category
          name
          share
          absoluteQuantity
          color
        }
      }
    `,
    variables: {
      scenarioId,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });
  return (response.data.allAgentShareInfo?.filter(Boolean) ??
    []) as IAgentShareInfoType[];
});

createTypeFx.use(async inputData => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation createAgentShareInfoMutation(
        $scenarioId: UUID!
        $category: AgentShareInfoCategoryEnum!
        $color: AgentShareInfoColorEnum!
        $name: String!
        $averageTimeBeforeTrain: Int!
        $maximumTravelSpeed: Float!
        $sharePassengerWithTickets: Int!
        $shareVisitorBuyingTickets: Int!
        $ticketOfficeProbability: Int!
        $tpmProbability: Int!
        $foodProbability: Int!
        $storeProbability: Int!
        $bankProbability: Int!
        $informationTableProbability: Int!
        $toiletProbability: Int!
        $otherProbability: Int!
      ) {
        createAgentShareInfo(
          inputData: {
            scenarioId: $scenarioId
            category: $category
            color: $color
            name: $name
            averageTimeBeforeTrain: $averageTimeBeforeTrain
            maximumTravelSpeed: $maximumTravelSpeed
            sharePassengerWithTickets: $sharePassengerWithTickets
            shareVisitorBuyingTickets: $shareVisitorBuyingTickets
            ticketOfficeProbability: $ticketOfficeProbability
            tpmProbability: $tpmProbability
            foodProbability: $foodProbability
            storeProbability: $storeProbability
            bankProbability: $bankProbability
            informationTableProbability: $informationTableProbability
            toiletProbability: $toiletProbability
            otherProbability: $otherProbability
          }
        ) {
          agentShareInfo {
            id
          }
          ok
          errors {
            key
            message
          }
        }
      }
    `,
    variables: inputData,
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data?.createAgentShareInfo?.agentShareInfo?.id;
});

updateTypeFx.use(async inputData => {
  await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation updateAgentShareInfo(
        $agentShareInfoId: UUID!
        $color: AgentShareInfoColorEnum!
        $name: String!
        $averageTimeBeforeTrain: Int!
        $maximumTravelSpeed: Float!
        $sharePassengerWithTickets: Int!
        $shareVisitorBuyingTickets: Int!
        $ticketOfficeProbability: Int!
        $tpmProbability: Int!
        $foodProbability: Int!
        $storeProbability: Int!
        $bankProbability: Int!
        $informationTableProbability: Int!
        $toiletProbability: Int!
        $otherProbability: Int!
      ) {
        updateAgentShareInfo(
          inputData: {
            agentShareInfoId: $agentShareInfoId
            color: $color
            name: $name
            averageTimeBeforeTrain: $averageTimeBeforeTrain
            maximumTravelSpeed: $maximumTravelSpeed
            sharePassengerWithTickets: $sharePassengerWithTickets
            shareVisitorBuyingTickets: $shareVisitorBuyingTickets
            ticketOfficeProbability: $ticketOfficeProbability
            tpmProbability: $tpmProbability
            foodProbability: $foodProbability
            storeProbability: $storeProbability
            bankProbability: $bankProbability
            informationTableProbability: $informationTableProbability
            toiletProbability: $toiletProbability
            otherProbability: $otherProbability
          }
        ) {
          ok
          errors {
            key
            message
          }
        }
      }
    `,
    variables: inputData,
    context: { clientName: TPU_CLIENT_NAME },
  });
});

getTypeByIdFx.use(async id => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query agentShareInfoById($id: UUID!) {
        agentShareInfoById(id: $id) {
          id
          category
          color
          name
          share
          absoluteQuantity
          maximumTravelSpeed
          averageTimeBeforeTrain
          sharePassengerWithTickets
          shareVisitorBuyingTickets
          ticketOfficeProbability
          tpmProbability
          foodProbability
          storeProbability
          bankProbability
          informationTableProbability
          toiletProbability
          otherProbability
          scenario {
            id
          }
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });
  return response.data?.agentShareInfoById;
});

updateShareFx.use(async inputData => {
  await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation updateAgentShareInfoByCategory(
        $scenarioId: UUID!
        $category: AgentShareInfoCategoryEnum!
        $shareInfo: [AgentShareInfoByCategoryInput]!
      ) {
        updateAgentShareInfoByCategory(
          inputData: {
            scenarioId: $scenarioId
            category: $category
            shareInfo: $shareInfo
          }
        ) {
          ok
          errors {
            key
            message
          }
        }
      }
    `,
    variables: inputData,
    context: { clientName: TPU_CLIENT_NAME },
  });
});
