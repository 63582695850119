import { useUnit } from 'effector-react';
import { useCallback, useEffect, useRef } from 'react';

import { Box } from '@mui/material';

import { Controllers } from '@features/tpu-simulation-model/components/Controllers';
import {
  Editor,
  MiniMap,
} from '@features/tpu-simulation-model/components/Editor';
import { InteractionWindow } from '@features/tpu-simulation-model/components/InteractionWindow';
import { EDITOR_CONTAINER_ID } from '@features/tpu-simulation-model/constants';
import { useContextSimulationModelHandlers } from '@features/tpu-simulation-model/hooks';
import {
  $EditedObject,
  $Editor,
  $ErrorsModal,
  $RulerTool,
  $UI,
} from '@features/tpu-simulation-model/store';
import { $Floors } from '@features/tpu-simulation-model/store';
import { $CreatedObject } from '@features/tpu-simulation-model/store/createdObject/createdObject.store';
import { ActionBeforeOpenErrorsWindow } from '@features/tpu-simulation-model/types';

export const ModelEditorContainer = () => {
  const ref = useRef<HTMLDivElement>();

  const $editor = useUnit($Editor);
  const $createdObject = useUnit($CreatedObject);
  const $editedObject = useUnit($EditedObject);
  const $floors = useUnit($Floors);
  const $rulerTool = useUnit($RulerTool);
  const $ui = useUnit($UI);
  const $errorsModal = useUnit($ErrorsModal);

  const {
    deleteObject,
    selectFloor,
    incrementScale,
    decrementScale,
    triggerCenter,
    setOpacity,
    triggerFindErrors,
    openPlanErrorsWindow,
    setModelBlockSize,
  } = useContextSimulationModelHandlers();

  useEffect(() => {
    const instance = ref.current;
    if (instance) {
      const updateRect = () => {
        const { width, height } = instance.getBoundingClientRect();
        setModelBlockSize({ width, height });
      };
      updateRect();
      window.addEventListener('resize', updateRect);

      return () => window.removeEventListener('resize', updateRect);
    }
  }, [setModelBlockSize]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: theme => theme.palette.background.default,
        position: 'relative',
        overflow: 'auto',
        gridColumnStart: 'b',
        gridColumnEnd:
          $ui.isOpenDefaultHUBInfo || $editedObject.data ? undefined : 'c',
      }}
      id={EDITOR_CONTAINER_ID}
      ref={useCallback((instance: HTMLDivElement) => {
        ref.current = instance;
      }, [])}
    >
      <Controllers
        floors={$floors.floorsInfo}
        selectedFloor={$floors.selectedFloor}
        opacity={$editor.opacity}
        numberOfErrors={$errorsModal.numberOfErrors}
        onPlanErrorsClick={() => {
          triggerFindErrors({ saveAfterAnalysis: false });
          openPlanErrorsWindow(ActionBeforeOpenErrorsWindow.CheckErrors);
        }}
        onSelectFloor={selectFloor}
        onCenter={triggerCenter}
        incrementScale={incrementScale}
        decrementScale={decrementScale}
        onOpacityChange={setOpacity}
      />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          position: 'absolute',
          pointerEvents: 'none',
          zIndex: 1,
        }}
      >
        <InteractionWindow
          {...$editedObject}
          onDelete={() =>
            $editedObject.data?.uuid && deleteObject($editedObject.data?.uuid)
          }
        />
      </Box>
      <Editor
        width={$editor.width}
        height={$editor.height}
        floor={$floors.backgroundUrl}
        createdObjectType={$createdObject.type}
        points={$createdObject.points}
        rulerStartPoint={$rulerTool.startPoint}
        rulerEndPoint={$rulerTool.endPoint}
        isScaleBeingMeasured={$rulerTool.isScaleBeingMeasured}
      />
      <MiniMap />
    </Box>
  );
};
