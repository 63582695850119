import { Stroke, Style } from 'ol/style';

import { EMapFeatureMetaKeys } from '@constants/map';

import { getLineWidth } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

import { EFeatureStyle } from '@utils/map/hooks/useInitVectorLayer';
import { VectorStyleProps } from '@utils/map/styles/types';
import { createActiveFeatureBorder } from '@utils/map/tools/createActiveFeatureBorder';
import { getLineDash } from '@utils/map/tools/getLineDash';

const lineWidthMin = 1;
const lineWidthMax = 5;

export const ptDirectionRailwayVectorStyleDashed = (
  props: VectorStyleProps,
) => {
  const { map, feature, featureStyles, width, resolution } = props;

  const currentWidth = (width || 3) + 2;
  let active = false;

  if (
    featureStyles &&
    Object.keys(featureStyles).includes(
      String(feature?.get(EMapFeatureMetaKeys.olId)),
    )
  ) {
    const style = featureStyles[feature?.get(EMapFeatureMetaKeys.olId)];
    switch (style) {
      case EFeatureStyle.selected:
        active = true;
    }
  }

  const styles = active ? [createActiveFeatureBorder(currentWidth + 1)] : [];

  const zoom = map.getView().getZoomForResolution(resolution) || 10;

  styles.push(
    new Style({
      stroke: new Stroke({
        color: '#5A5A5A',
        width:
          currentWidth ||
          getLineWidth(zoom, [
            0,
            lineWidthMin,
            6,
            lineWidthMin,
            6.00000001,
            lineWidthMax,
          ]) ||
          1,
        lineCap: 'square',
      }),
    }),
  );

  if (zoom > 6) {
    styles.push(
      new Style({
        stroke: new Stroke({
          lineCap: 'square',
          color: 'white',
          width: getLineWidth(zoom, [0, 1, 6, 2, 10, 2]) || 1,
          lineDashOffset: 4,
          lineDash: getLineDash(zoom, [
            0,
            [1, 1],
            6,
            [8, 6],
            6.5,
            [8, 6],
            7,
            [10, 10],
            8,
            [15, 10],
            10,
            [20, 10],
          ]),
        }),
      }),
    );
  }

  return styles;
};
