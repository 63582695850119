import { Button, iconButtonClasses } from '@mui/material';

import { DownloadIcon } from '@components/icons';

interface IUpload {
  disabled: boolean;
  onClick: () => void;
}

export const Upload = (props: IUpload) => {
  const { disabled, onClick } = props;

  return (
    <Button
      variant="text"
      sx={{
        fontSize: theme => theme.typography.pxToRem(12),
        color: theme => theme.palette.customs.link,
        ['& .MuiButton-startIcon']: {
          mr: 0,
        },
      }}
      className={disabled ? iconButtonClasses.disabled : ''}
      startIcon={
        <DownloadIcon
          sx={{
            transform: 'rotate(180deg)',
            fontSize: theme => theme.typography.pxToRem(18),
          }}
        />
      }
      onClick={onClick}
    >
      Загрузить
    </Button>
  );
};
