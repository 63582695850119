import { getLineWidth } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/helpers';
import { createBorderStyle } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/createBorderStyle';
import { SetFeatureStyleProps } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/getFeatureStyle';
import { Stroke, Style } from 'ol/style';

export type GetHighwayStyleArgs = Pick<
  SetFeatureStyleProps,
  'map' | 'resolution' | 'active' | 'width' | 'feature'
>;

export const getHighwayStyle = (args: GetHighwayStyleArgs) => {
  const { map, width, resolution, active, feature } = args;

  const zoom = map.getView().getZoomForResolution(resolution) || 10;

  let lineWidth = width;

  if (!width) {
    if (feature && feature.getProperties()['type'] === 3)
      lineWidth = getLineWidth(zoom, [0, 0.4, 4, 1, 9, 2.5, 22, 4]);
    else lineWidth = getLineWidth(zoom, [0, 0.2, 4, 0.5, 9, 2, 22, 2]);
  }

  return [
    createBorderStyle({ active, featureWidth: width || 3 }),
    new Style({
      stroke: new Stroke({
        color: '#eb8042',
        width: lineWidth,
      }),
    }),
  ];
};
