import {
  highwaySource,
  railwaySource,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

export enum ETileLayerSources {
  highway,
  railway,
  plannedHighway,
  plannedRailway,
}

export const getTileLayerSource = (layout: ETileLayerSources) => {
  switch (layout) {
    case ETileLayerSources.highway:
      return highwaySource();
    case ETileLayerSources.railway:
      return railwaySource();
    case ETileLayerSources.plannedHighway:
      return highwaySource();
    case ETileLayerSources.plannedRailway:
      return railwaySource();
  }
};
