import { ICreateProjectMutation, IMutation, Maybe } from '@api/gql/ag-types';
import { createApi, createEffect, createEvent, createStore } from 'effector';

import { AgglomerationOption } from '@features/ag-projects-registry/store/lists/agglomerations';

const defaultStore: CurrentProjectStore = {
  id: null,
  name: '',
  selectedAgglomeration: null,
};

export const $CurrentProject = createStore<CurrentProjectStore>(defaultStore);

export const CurrentProjectApi = createApi($CurrentProject, {
  createEmptyProject: () => defaultStore,
  selectProject: (store, payload: CurrentProjectStore) => ({
    ...store,
    id: payload.id,
    name: payload.name,
    selectedAgglomeration: payload.selectedAgglomeration,
  }),
  setAgglomerationOption: (store, payload: AgglomerationOption | null) => ({
    ...store,
    selectedAgglomeration: payload,
  }),
  setProjectName: (store, payload: string) => ({
    ...store,
    name: payload,
  }),
  reset: () => defaultStore,
});

export const CreateProject = createEvent();

export const CopyProject = createEvent();

export const UpdateProject = createEvent();

export const CreateProjectFx = createEffect<
  CreateProjectRequestParams,
  Maybe<ICreateProjectMutation>
>();

export const UpdateProjectFx = createEffect<
  UpdateProjectRequestParams,
  IMutation['updateProject']
>();

export type CurrentProjectStore = {
  id: string | null;
  name: string;
  selectedAgglomeration: AgglomerationOption | null;
};

export type CreateProjectRequestParams = {
  isCopy?: boolean;
  name: string;
  agglomeration: string;
  files: string[];
};

export type UpdateProjectRequestParams = {
  id: string;
  name: string;
  agglomerationId: string;
  files: string[];
};
