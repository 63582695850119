import { Chip, Grid, Typography } from '@mui/material';

export const Success = () => (
  <Grid
    display={'flex'}
    gap={1.5}
    alignItems="center"
  >
    <Chip
      label="Успешно"
      color="success"
    />

    <Typography variant="body2">Расчет произведен успешно</Typography>
  </Grid>
);
