import { IQuery } from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

interface ModalPlanErrorsDataType {
  isOpen: boolean;
  isLoading: boolean;
}

export interface IModelData {
  agents: IQuery['allAgentShareInfo'];
  plan: IQuery['planByScenarioId'];
}

export const $ModalPlanErrors = createStore<ModalPlanErrorsDataType>({
  isOpen: false,
  isLoading: false,
});

export const $Errors = createStore<string[] | null>(null);

export const setIsOpenModalPlanErrorsFn = createEvent<boolean>();

export const getPlanDataFx = createEffect<string, IModelData>();

export const setErrorsFn = createEvent<string[]>();

export const resetModalPlanErrorsFn = createEvent<void>();

export const resetErrorsFn = createEvent<void>();

export const validateScenarioFx = createEffect<string, boolean>();
