import { useUnit } from 'effector-react';

import { FirstPageDashboardContainer } from '@features/pt-forecast-new/containers/DashboardsContainer/FirstPageDashboardContainer';
import { SecondPageDashboardContainer } from '@features/pt-forecast-new/containers/DashboardsContainer/SecondPageDashboardContainer';
import { $UI } from '@features/pt-forecast-new/stores/ui';

export const DashboardsContainer = () => {
  const { dashboardPage } = useUnit($UI);

  switch (dashboardPage) {
    case 1:
      return <FirstPageDashboardContainer />;
    case 2:
      return <SecondPageDashboardContainer />;
    default:
      return null;
  }
};
