import { sample } from 'effector';

import { EMapFeatureLayout } from '@constants/map';

import { EMapViewMode } from '@features/ag-forecast/constants/EMapViewMode';
import { EVehicleAg } from '@features/ag-forecast/constants/EVehicleAg';
import { $FilterMap, FilterMapApi } from '@features/ag-forecast/stores/filters';
import {
  $Vehicles,
  VehiclesApi,
} from '@features/ag-forecast/stores/lists/vehicles';
import {
  $LayersVisible,
  $SelectedFeatures,
  LayersVisibleApi,
  SelectedFeaturesApi,
  initSources,
  pipeTrolleybusTileLayer,
} from '@features/ag-forecast/stores/map';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';
import { $UI, UIApi } from '@features/ag-forecast/stores/ui';
import {
  $YearLineStore,
  YearLineApi,
} from '@features/ag-forecast/stores/yearLine/store';
import { createAgComputedGraphSource } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

import {
  EPipeTileLayer,
  PipeTileLayer,
} from '@utils/map/hooks/useInitTileLayer';
import { ETileLayerStyles } from '@utils/map/tools/getTileLayerStyle';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload &&
    Object.keys(payload).includes(EMapFeatureLayout.graphTrolleybus),
  fn: payload =>
    ({
      action: EPipeTileLayer.setVisible,
      payload: payload![EMapFeatureLayout.graphTrolleybus],
    }) as PipeTileLayer,
  target: pipeTrolleybusTileLayer,
});

// Установка источника тайлов
sample({
  clock: [
    VehiclesApi.setVehicles,
    YearLineApi.setSelectedYear,
    FilterMapApi.setTimeSlot,
    initSources,
  ],
  source: {
    Vehicles: $Vehicles,
    FeatureSettings: $FeatureSettings,
    YearLine: $YearLineStore,
    FilterMap: $FilterMap,
  },
  filter: ({ Vehicles }) => !!Vehicles,
  fn: ({ Vehicles, FeatureSettings, YearLine, FilterMap }) => {
    if (!Vehicles || !FeatureSettings.projectUUID)
      throw Error('Vehicles not found');
    const autoVehicle = Vehicles.find(
      vehicle => vehicle.vehicleId === EVehicleAg.trolleybus,
    );
    if (!autoVehicle) return null;
    if (!FeatureSettings.scenarioUUID)
      throw Error('FeatureSettings.scenarioUUID not found');
    return {
      action: EPipeTileLayer.changeSource,
      payload: createAgComputedGraphSource(
        autoVehicle.id,
        FeatureSettings.projectUUID,
        FeatureSettings.scenarioUUID,
        FilterMap.selectedTimeSlot,
        YearLine.selectedYear,
      ),
    } as PipeTileLayer;
  },
  target: pipeTrolleybusTileLayer,
});

// Применение стиля к выбранным фичам
sample({
  clock: SelectedFeaturesApi.setSelectedFeatures,
  source: {
    LayersVisible: $LayersVisible,
    SelectedFeatures: $SelectedFeatures,
  },
  filter: ({ LayersVisible }) =>
    LayersVisible[EMapFeatureLayout.graphTrolleybus],
  fn: ({ SelectedFeatures }) =>
    ({
      action: EPipeTileLayer.setStyle,
      payload: {
        selectedFeatureIds:
          SelectedFeatures.filter(
            item => item.layout === EMapFeatureLayout.graphTrolleybus,
          ).map(item => item.id) || [],
        featureIdMetaKey: 'id',
      },
    }) as PipeTileLayer,
  target: pipeTrolleybusTileLayer,
});

// Смена темы стиля слоя при переключении режима просмотра карты
sample({
  clock: UIApi.setViewMode,
  source: { UI: $UI },
  fn: ({ UI }) => {
    return {
      action: EPipeTileLayer.changeTheme,
      payload: {
        theme:
          UI.viewMode === EMapViewMode.infrastructure
            ? ETileLayerStyles.default
            : ETileLayerStyles.passFlow,
      },
    } as PipeTileLayer;
  },
  target: pipeTrolleybusTileLayer,
});

// Актуализация пропсов темы
sample({
  clock: [VehiclesApi.setVehicles, YearLineApi.setSelectedYear, $FilterMap],
  source: { YearLineStore: $YearLineStore, FilterMap: $FilterMap },
  fn: ({ YearLineStore, FilterMap }) => {
    return {
      action: EPipeTileLayer.changeThemeProps,
      payload: {
        isActual: FilterMap.actual.isActual,
        actualYear: YearLineStore.selectedYear,
        isPlanned: FilterMap.actual.isPlanned,
      },
    } as PipeTileLayer;
  },
  target: pipeTrolleybusTileLayer,
});
