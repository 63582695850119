import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddGraphIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="11.5"
        fill="#E21A1A"
        stroke="white"
      />
      <path
        d="M1 14L5.40237 10.3314C6.27445 9.60463 7.57585 9.7486 8.26799 10.6484L10.9209 14.0972C11.537 14.8981 12.6537 15.1137 13.5237 14.5997L23 9"
        stroke="white"
      />
      <circle
        cx="17"
        cy="12"
        r="4"
        fill="white"
      />
      <circle
        cx="16.9997"
        cy="11.9997"
        r="2.66667"
        fill="#E21A1A"
      />
    </SvgIcon>
  );
};
