import { apiClient } from '@api/client';
import { IQuery, IQueryGetTransportsRegionStarArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import { $FilterMap, FilterMapApi } from '@features/ag-forecast/stores/filters';
import {
  PassFlowTableContentApi,
  fetchPassFlowTableContent,
} from '@features/ag-forecast/stores/lists/passFlowTableContent';
import { $Vehicles } from '@features/ag-forecast/stores/lists/vehicles';
import {
  DistrictPassFlowApi,
  SetDirectionsPayload,
  getDistrictPassFlowFx,
} from '@features/ag-forecast/stores/map/districtPassFlow/store';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';
import { $YearLineStore } from '@features/ag-forecast/stores/yearLine/store';
import { getVehicleByFilter } from '@features/ag-forecast/utils/getVehicleByFilter';

// Если выбран хотя бы один регион от И до
sample({
  clock: [
    FilterMapApi.addRegionTo,
    FilterMapApi.addRegionFrom,
    FilterMapApi.removeRegionFrom,
    FilterMapApi.removeRegionTo,
    FilterMapApi.setOnOneVehicleInFilter,
    FilterMapApi.setTimeSlot,
  ],
  source: {
    FilterMap: $FilterMap,
    FeatureSettings: $FeatureSettings,
    YearLineStore: $YearLineStore,
    Vehicles: $Vehicles,
  },
  filter: ({ FilterMap }) =>
    !!FilterMap.fromSelectedRegions.length &&
    !!FilterMap.toSelectedRegions.length,
  fn: ({ FeatureSettings, FilterMap, YearLineStore, Vehicles }) => {
    const vehicle = getVehicleByFilter(FilterMap, Vehicles);
    return {
      scenarioId: FeatureSettings.scenarioUUID,
      transportDistrictsFrom: FilterMap.fromSelectedRegions.map(
        ({ value }) => value,
      ),
      transportDistrictsTo: FilterMap.toSelectedRegions.map(
        ({ value }) => value,
      ),
      year: YearLineStore.selectedYear,
      timeSlot: FilterMap.selectedTimeSlot,
      isPublic: vehicle.isPublic,
    } as IQueryGetTransportsRegionStarArgs;
  },
  target: getDistrictPassFlowFx,
});

// Запросить данные для таблицы
sample({
  clock: getDistrictPassFlowFx,
  target: fetchPassFlowTableContent,
});

getDistrictPassFlowFx.use(async params => {
  const response = await apiClient.query<
    Pick<IQuery, 'getTransportsRegionStar'>,
    IQueryGetTransportsRegionStarArgs
  >({
    query: gql`
      query QueryGetTransportsRegionStar(
        $scenarioId: UUID!
        $transportDistrictsFrom: [UUID]!
        $transportDistrictsTo: [UUID]!
        $year: Int!
        $timeSlot: UUID!
        $isPublic: Boolean!
      ) {
        getTransportsRegionStar(
          scenarioId: $scenarioId
          transportDistrictsFrom: $transportDistrictsFrom
          transportDistrictsTo: $transportDistrictsTo
          year: $year
          timeSlot: $timeSlot
          isPublic: $isPublic
        ) {
          max {
            flow
          }
          star {
            id
            regionTo {
              id
              center {
                name
                geometry
              }
            }
            regionFrom {
              id
              center {
                name
                geometry
              }
            }
            flow
          }
        }
      }
    `,
    variables: {
      scenarioId: params.scenarioId,
      transportDistrictsFrom: params.transportDistrictsFrom,
      transportDistrictsTo: params.transportDistrictsTo,
      year: params.year,
      isPublic: params.isPublic,
      timeSlot: params.timeSlot,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  return response.data.getTransportsRegionStar || null;
});

//
sample({
  clock: getDistrictPassFlowFx.done,
  fn: request => {
    return {
      directions: request.result?.star?.map(item => ({
        id: item?.id || '',
        fromPoint: [
          item?.regionFrom?.center?.geometry?.coordinates[0],
          item?.regionFrom?.center?.geometry?.coordinates[1],
        ],
        toPoint: [
          item?.regionTo?.center?.geometry?.coordinates[0],
          item?.regionTo?.center?.geometry?.coordinates[1],
        ],
        flow: item?.flow || 0,
        name: `${item?.regionFrom?.center?.name || ''} - ${
          item?.regionTo?.center?.name || ''
        }`,
      })),
      maxFlow: request.result?.max?.flow || 0,
    } as SetDirectionsPayload;
  },
  target: DistrictPassFlowApi.setDirections,
});

// Очистка потоков если требуется
sample({
  clock: [
    FilterMapApi.addRegionTo,
    FilterMapApi.addRegionFrom,
    FilterMapApi.removeRegionFrom,
    FilterMapApi.removeRegionTo,
    FilterMapApi.resetFilters,
    FilterMapApi.clearRegions,
  ],
  source: { FilterMap: $FilterMap },
  filter: ({ FilterMap }) =>
    !FilterMap.fromSelectedRegions.length ||
    !FilterMap.toSelectedRegions.length,
  target: [
    DistrictPassFlowApi.clearDirections,
    PassFlowTableContentApi.clearRows,
  ],
});
