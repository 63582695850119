import { SvgIcon, SvgIconProps } from '@mui/material';

export const ErrorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={44}
      height={44}
      viewBox="0 0 44 44"
      fill="none"
      {...props}
    >
      <path
        d="M23.833 22a1.833 1.833 0 0 1-3.666 0v-7.333a1.833 1.833 0 1 1 3.666 0V22Zm-3.666 7.333a1.833 1.833 0 1 1 3.666 0 1.833 1.833 0 0 1-3.666 0ZM28.838 5.5H15.162L5.5 15.162v13.676l9.662 9.662h13.676l9.662-9.662V15.162L28.838 5.5Z"
        fill="#CE0C3C"
      />
    </SvgIcon>
  );
};
