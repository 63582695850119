import { Stroke, Style } from 'ol/style';

import { EMapFeatureMetaKeys } from '@constants/map';

import { VectorStyleProps } from '@utils/map/styles/types';
import { createActiveFeatureBorder } from '@utils/map/tools/createActiveFeatureBorder';

export const graphsVectorStyle = ({
  feature,
  featureStyles,
}: VectorStyleProps) => {
  const isSelected =
    featureStyles &&
    Object.keys(featureStyles).includes(
      String(feature?.get(EMapFeatureMetaKeys.olId)),
    );

  const styles = isSelected ? [createActiveFeatureBorder(4)] : [];

  styles.push(
    new Style({
      stroke: new Stroke({
        color: '#169fe8',
        width: 3,
      }),
    }),
  );

  return styles;
};
