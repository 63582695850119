import { IQuery, IQueryGetScenarioTpuArgs } from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

const initState: TPUsType = {
  TPUs: [],
  selectedTPUs: [],
};

export const $TPUs = createStore<TPUsType>(initState);

export const TPUsApi = createApi($TPUs, {
  setTPUs: (store, TPUs: TPUsType['TPUs']) => ({
    ...store,
    TPUs,
  }),
  reset: () => initState,
});

export const getScenarioTPUFx = createEffect<
  IQueryGetScenarioTpuArgs,
  IQuery['getScenarioTpu'] | undefined
>();

export type TPUsType = {
  TPUs: TPUType[] | null | undefined;
  selectedTPUs: [];
};

export type TPUType = {
  id: string;
  name: string;
  parentTpu: { id?: string };
  childrenTpu: TPUType[];
  selectedTPU: string;
};
