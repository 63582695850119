import { sample } from 'effector';

import {
  $FilterMap,
  pushPassTrafficForSelectYear,
  resetAllFilters,
} from '@features/pt-forecast/stores';
import {
  $DailyUnevenness,
  DailyUnevennessTimeList,
  resetDailyUnevenness,
  setDailyUnevennessSummary,
  setDailyUnevennessVehicle,
} from '@features/pt-forecast/stores/charts/dailyUnevenness/store';
import {
  PassengerTrafficType,
  filterVehicleChange,
} from '@features/pt-forecast/stores/charts/passTraffic';

sample({
  clock: pushPassTrafficForSelectYear,
  fn: payload => {
    if (!payload) return null;
    return payload.dailyUnevenness;
  },
  target: setDailyUnevennessVehicle,
});

sample({
  clock: [setDailyUnevennessVehicle, filterVehicleChange],
  source: {
    FilterMap: $FilterMap,
    DailyUnevenness: $DailyUnevenness,
  },
  fn: ({ FilterMap, DailyUnevenness }) => {
    if (!DailyUnevenness.dailyUnevennessVehicle) return [];

    const result: number[] = Array.from({ length: 8 }).map(
      (_, index: number) => {
        return Object.keys(DailyUnevenness.dailyUnevennessVehicle!).reduce(
          (acc, curr) => {
            const key = curr as keyof PassengerTrafficType['passengerTraffic'];
            const time = DailyUnevennessTimeList[index];
            const traffic =
              DailyUnevenness.dailyUnevennessVehicle![key][time] || 0;

            if (FilterMap[key]) return acc + traffic;
            else return acc;
          },
          0,
        );
      },
    );

    return result;
  },
  target: setDailyUnevennessSummary,
});

// Сброс графика неравномерности пассажиропотока
sample({
  clock: resetAllFilters,
  target: resetDailyUnevenness,
});
