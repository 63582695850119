import { sample } from 'effector';

import { gapBetweenDigits } from '@utils/dataForInspector';

import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';
import { querySocEcoTable_2_1 } from '@widgets/widget-soceco-indicators/stores/api/comfortableEnvironment/querySocEcoTable_2_1';
import { querySocEcoTable_2_2 } from '@widgets/widget-soceco-indicators/stores/api/comfortableEnvironment/querySocEcoTable_2_2';
import { querySocEcoTable_2_3 } from '@widgets/widget-soceco-indicators/stores/api/comfortableEnvironment/querySocEcoTable_2_3';
import { querySocEcoTable_2_4 } from '@widgets/widget-soceco-indicators/stores/api/comfortableEnvironment/querySocEcoTable_2_4';
import { querySocEcoTable_2_5 } from '@widgets/widget-soceco-indicators/stores/api/comfortableEnvironment/querySocEcoTable_2_5';
import { querySocEcoTable_3_1 } from '@widgets/widget-soceco-indicators/stores/api/environmentalSustainability/querySocEcoTable_3_1';
import { querySocEcoTable_3_2 } from '@widgets/widget-soceco-indicators/stores/api/environmentalSustainability/querySocEcoTable_3_2';
import { querySocEcoTable_3_3 } from '@widgets/widget-soceco-indicators/stores/api/environmentalSustainability/querySocEcoTable_3_3';
import { querySocEcoTable_3_4 } from '@widgets/widget-soceco-indicators/stores/api/environmentalSustainability/querySocEcoTable_3_4';
import { querySocEcoTable_3_5 } from '@widgets/widget-soceco-indicators/stores/api/environmentalSustainability/querySocEcoTable_3_5';
import { querySocEcoTable_3_6 } from '@widgets/widget-soceco-indicators/stores/api/environmentalSustainability/querySocEcoTable_3_6';
import { querySocEcoTable_3_7 } from '@widgets/widget-soceco-indicators/stores/api/environmentalSustainability/querySocEcoTable_3_7';
import { querySocEcoTable_4_1 } from '@widgets/widget-soceco-indicators/stores/api/healthAndSafety/querySocEcoTable_4_1';
import { querySocEcoTable_4_2 } from '@widgets/widget-soceco-indicators/stores/api/healthAndSafety/querySocEcoTable_4_2';
import { querySocEcoTable_4_4 } from '@widgets/widget-soceco-indicators/stores/api/healthAndSafety/querySocEcoTable_4_4';
import { querySocEcoTable_5_2 } from '@widgets/widget-soceco-indicators/stores/api/integrations/querySocEcoTable_5_2';
import { querySocEcoTable_5_3 } from '@widgets/widget-soceco-indicators/stores/api/integrations/querySocEcoTable_5_3';
import { querySocEcoTable_5_4 } from '@widgets/widget-soceco-indicators/stores/api/integrations/querySocEcoTable_5_4';
import { querySocEcoTable_5_5 } from '@widgets/widget-soceco-indicators/stores/api/integrations/querySocEcoTable_5_5';
import { querySocEcoTable_5_6 } from '@widgets/widget-soceco-indicators/stores/api/integrations/querySocEcoTable_5_6';
import { querySocEcoTable_5_8 } from '@widgets/widget-soceco-indicators/stores/api/integrations/querySocEcoTable_5_8';
import { querySocEcoTable_7_1 } from '@widgets/widget-soceco-indicators/stores/api/manufacturability/querySocEcoTable_7_1';
import { querySocEcoTable_7_2 } from '@widgets/widget-soceco-indicators/stores/api/manufacturability/querySocEcoTable_7_2';
import { querySocEcoTable_7_3 } from '@widgets/widget-soceco-indicators/stores/api/manufacturability/querySocEcoTable_7_3';
import { querySocEcoTable_7_4 } from '@widgets/widget-soceco-indicators/stores/api/manufacturability/querySocEcoTable_7_4';
import { querySocEcoTable_7_5 } from '@widgets/widget-soceco-indicators/stores/api/manufacturability/querySocEcoTable_7_5';
import { querySocEcoTable_6_1 } from '@widgets/widget-soceco-indicators/stores/api/opennessAndTransparency/querySocEcoTable_6_1';
import { querySocEcoTable_6_2 } from '@widgets/widget-soceco-indicators/stores/api/opennessAndTransparency/querySocEcoTable_6_2';
import { querySocEcoTable_6_3 } from '@widgets/widget-soceco-indicators/stores/api/opennessAndTransparency/querySocEcoTable_6_3';
import { querySocEcoTable_1_1 } from '@widgets/widget-soceco-indicators/stores/api/socEcoSustainability/querySocEcoTable_1_1';
import { querySocEcoTable_1_2 } from '@widgets/widget-soceco-indicators/stores/api/socEcoSustainability/querySocEcoTable_1_2';
import { querySocEcoTable_1_3 } from '@widgets/widget-soceco-indicators/stores/api/socEcoSustainability/querySocEcoTable_1_3';
import { querySocEcoTable_1_4 } from '@widgets/widget-soceco-indicators/stores/api/socEcoSustainability/querySocEcoTable_1_4';
import { querySocEcoTable_1_5 } from '@widgets/widget-soceco-indicators/stores/api/socEcoSustainability/querySocEcoTable_1_5';
import { $Settings } from '@widgets/widget-soceco-indicators/stores/settings/store';
import {
  getSocEcoTable_1_1Fx,
  getSocEcoTable_1_2Fx,
  getSocEcoTable_1_3Fx,
  getSocEcoTable_1_4Fx,
  getSocEcoTable_1_5Fx,
  getSocEcoTable_2_1Fx,
  getSocEcoTable_2_2Fx,
  getSocEcoTable_2_3Fx,
  getSocEcoTable_2_4Fx,
  getSocEcoTable_2_5Fx,
  getSocEcoTable_3_1Fx,
  getSocEcoTable_3_2Fx,
  getSocEcoTable_3_3Fx,
  getSocEcoTable_3_4Fx,
  getSocEcoTable_3_5Fx,
  getSocEcoTable_3_6Fx,
  getSocEcoTable_3_7Fx,
  getSocEcoTable_4_1Fx,
  getSocEcoTable_4_2Fx,
  getSocEcoTable_4_4Fx,
  getSocEcoTable_5_2Fx,
  getSocEcoTable_5_3Fx,
  getSocEcoTable_5_4Fx,
  getSocEcoTable_5_5Fx,
  getSocEcoTable_5_6Fx,
  getSocEcoTable_5_8Fx,
  getSocEcoTable_6_1Fx,
  getSocEcoTable_6_2Fx,
  getSocEcoTable_6_3Fx,
  getSocEcoTable_7_1Fx,
  getSocEcoTable_7_2Fx,
  getSocEcoTable_7_3Fx,
  getSocEcoTable_7_4Fx,
  getSocEcoTable_7_5Fx,
} from '@widgets/widget-soceco-indicators/stores/tables/effects';
import {
  SetDataForTablePayload,
  SocEcoTableApi,
} from '@widgets/widget-soceco-indicators/stores/tables/store';

getSocEcoTable_1_1Fx.use(querySocEcoTable_1_1);

sample({
  clock: getSocEcoTable_1_1Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_1_1,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     tpu: 'Красноярск-Пассажирский',
//     year: 2023,
//     passTraffic: '3629,3',
//     zoneRadius: '640',
//   },
// ]
sample({
  clock: getSocEcoTable_1_1Fx.done,
  fn: payload => {
    const result: SetDataForTablePayload['data'] = [];

    payload.result?.scenarioTpusFlow?.forEach(itemYear => {
      result.push(
        ...(itemYear?.scenarioTpus?.map(item => ({
          tpu: item?.tpu?.name,
          year: itemYear.year,
          passTraffic: gapBetweenDigits((item?.mainFlow || 0) / 1_000),
        })) || []),
      );
    });

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_1_1,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_1_1Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_1_1,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_1_2Fx.use(querySocEcoTable_1_2);

sample({
  clock: getSocEcoTable_1_2Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_1_2,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     // tpu: 'Красноярск-Пассажирский',
//     year: 2023,
//     projectedPassTraffic: '3629,3',
//     availabilityAccommodationFacilities: 15000,
//     shortageAccommodationFacilities: 0,
//   }
// ]
sample({
  clock: getSocEcoTable_1_2Fx.done,
  fn: payload => {
    const result = payload.result?.map(item => ({
      year: item?.year,
      projectedPassTraffic: gapBetweenDigits((item?.sumTotalFlow || 0) / 1_000),
      availabilityAccommodationFacilities: gapBetweenDigits(
        item?.numberOfPlacements || 0,
      ),
      shortageAccommodationFacilities: gapBetweenDigits(item?.diff || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_1_2,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_1_2Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_1_2,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_1_3Fx.use(querySocEcoTable_1_3);

sample({
  clock: getSocEcoTable_1_3Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_1_3,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportArea: 'Железнодорожный вокзал',
//     year: 2023,
//     projectedPassTraffic: '3629,3',
//     economicallyActivePopulation: '14,9',
//   }
// ]
sample({
  clock: getSocEcoTable_1_3Fx.done,
  source: { Settings: $Settings },
  fn: ({ Settings }, payload) => {
    const filtered = payload.result?.economicallyActivePopulation?.filter(
      item => item?.year === Settings.baseYear,
    );

    const result = filtered?.map(item => ({
      transportArea: item?.name,
      year: item?.year,
      projectedPassTraffic: gapBetweenDigits((item?.flowSum || 0) / 1_000),
      economicallyActivePopulation: gapBetweenDigits(
        (item?.population || 0) / 1_000,
      ),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_1_3,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_1_3Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_1_3,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_1_4Fx.use(querySocEcoTable_1_4);

sample({
  clock: getSocEcoTable_1_4Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_1_4,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     nameTransportObject: 'Красноярск-Пассажирский',
//     transportArea: '',
//     year: 2023,
//     averageDailyPassengerTraffic: '9943,3',
//     tradeArea: '994,3',
//     annualRevenue: '248582,2',
//   }
// ]
sample({
  clock: getSocEcoTable_1_4Fx.done,
  fn: payload => {
    const result = payload.result?.tpuRetailSpacesIncome?.map(item => ({
      nameTransportObject: item?.tpuName,
      transportArea: item?.districtName,
      year: item?.year,
      averageDailyPassengerTraffic: gapBetweenDigits(item?.stzFlow || 0),
      tradeArea: gapBetweenDigits(item?.retailSpaces || 0),
      annualRevenue: gapBetweenDigits((item?.annualIncome || 0) / 1_000),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_1_4,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_1_4Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_1_4,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_1_5Fx.use(querySocEcoTable_1_5);

sample({
  clock: getSocEcoTable_1_5Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_1_5,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportArea: 'Мебельная фабрика',
//     year: 2027,
//     numberJobs: 6403,
//     totalTransportCosts: 95,
//     changeNumberAvailableJobs: 260,
//   }
// ]
sample({
  clock: getSocEcoTable_1_5Fx.done,
  fn: payload => {
    const result = payload.result?.increaseEmploymentOpportunities?.map(
      item => ({
        transportArea: item?.name,
        year: item?.year,
        numberJobs: gapBetweenDigits(item?.workplaces || 0),
        totalTransportCosts: gapBetweenDigits(item?.stzSum || 0),
        changeNumberAvailableJobs: gapBetweenDigits(item?.delta || 0),
      }),
    );

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_1_5,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_1_5Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_1_5,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_2_1Fx.use(querySocEcoTable_2_1);

sample({
  clock: getSocEcoTable_2_1Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_2_1,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportArea: 'Железнодорожный вокзал',
//     year: 2023,
//     populationTransportArea: '24937',
//     shoppingCenterArea: 7630,
//     ShortageSCSupermarketsTransportArea: 22793,
//   }
// ]
sample({
  clock: getSocEcoTable_2_1Fx.done,
  fn: payload => {
    const result = payload.result?.deficitOfRetailSpace?.map(item => ({
      transportArea: item?.districtName,
      year: item?.year,
      populationTransportArea: gapBetweenDigits(item?.population || 0),
      shoppingCenterArea: gapBetweenDigits(item?.retailSpaces || 0),
      ShortageSCSupermarketsTransportArea: gapBetweenDigits(item?.deficit || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_2_1,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_2_1Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_2_1,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_2_2Fx.use(querySocEcoTable_2_2);

sample({
  clock: getSocEcoTable_2_2Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_2_2,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     nameArea: 'Красноярск-ТЭЦ – Агентство Аэрофлота',
//     year: '',
//     numberPassengersCarried: '10,0',
//     carryingCapacity: '12,0',
//     networkAreaCongestion: '83,3%',
//   }
// ]
sample({
  clock: getSocEcoTable_2_2Fx.done,
  fn: payload => {
    const result = payload.result?.graphOverload?.map(item => ({
      nameArea: item?.graph?.name,
      year: item?.year,
      numberPassengersCarried: gapBetweenDigits(item?.flow || 0),
      carryingCapacity: gapBetweenDigits(item?.capacity || 0),
      networkAreaCongestion: gapBetweenDigits((item?.graphLoad || 0) * 100),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_2_2,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_2_2Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_2_2,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_2_3Fx.use(querySocEcoTable_2_3);

sample({
  clock: getSocEcoTable_2_3Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_2_3,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     nameTPU: 'Красноярск-Пассажирский',
//     TPUStatus: 'Существующий',
//     year: 2023,
//     passengerTraffic: '3629,3',
//   }
// ]
sample({
  clock: getSocEcoTable_2_3Fx.done,
  fn: payload => {
    const result = payload.result?.perspectiveTpus?.map(item => ({
      nameTPU: item?.tpuName,
      transportArea: item?.districtName,
      year: item?.year,
      TPUStatus: item?.tpuStatus,
      passengerTraffic: gapBetweenDigits((item?.averageFlow || 0) / 1_000),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_2_3,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_2_3Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_2_3,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_2_4Fx.use(querySocEcoTable_2_4);

sample({
  clock: getSocEcoTable_2_4Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_2_4,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportObject: 'Красноярск-Пассажирский',
//     transportArea: '',
//     year: 2023,
//     simultaneousMaximumPassengerTraffic: '1657,2',
//     numberFloorsTPU: 3,
//     numberElevatorsTPU: 4,
//     numberEscalatorsTPU: 6,
//   },
// ]
sample({
  clock: getSocEcoTable_2_4Fx.done,
  fn: payload => {
    const result = payload.result?.lifts?.map(item => ({
      transportObject: item?.tpuName,
      transportArea: item?.districtName,
      year: item?.year,
      simultaneousMaximumPassengerTraffic: gapBetweenDigits(item?.maxFlow || 0),
      numberFloorsTPU: gapBetweenDigits(item?.floorsNumber || 0),
      numberElevatorsTPU: gapBetweenDigits(item?.lift || 0),
      numberEscalatorsTPU: gapBetweenDigits(item?.escalator || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_2_4,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_2_4Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_2_4,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_2_5Fx.use(querySocEcoTable_2_5);

sample({
  clock: getSocEcoTable_2_5Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_2_5,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     nameArea: 'Аэропорт Красноярск  Семидарское',
//     noiseScreenSize: 4858,
//   }
// ]
sample({
  clock: getSocEcoTable_2_5Fx.done,
  fn: payload => {
    const result = payload.result?.noiseProtection?.map(item => ({
      nameArea: item?.name,
      noiseScreenSize: gapBetweenDigits(item?.stLen || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_2_5,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_2_5Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_2_5,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_3_1Fx.use(querySocEcoTable_3_1);

sample({
  clock: getSocEcoTable_3_1Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_3_1,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     nameArea: 'Каракуша– Дрокино',
//     sectionLength: 2197,
//     numberPasses: 1,
//   },
// ]
sample({
  clock: getSocEcoTable_3_1Fx.done,
  fn: payload => {
    const result = payload.result?.animalWall?.map(item => ({
      nameArea: item?.name,
      sectionLength: gapBetweenDigits(item?.lenMeter || 0),
      numberPasses: gapBetweenDigits(item?.wallCount || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_3_1,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_3_1Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_3_1,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_3_2Fx.use(querySocEcoTable_3_2);

sample({
  clock: getSocEcoTable_3_2Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_3_2,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     nameArea: 'Каркуши-Дрокино',
//     noiseScreenSize: 5504,
//   }
// ]
sample({
  clock: getSocEcoTable_3_2Fx.done,
  fn: payload => {
    const result = payload.result?.noiseProtectionForest?.map(item => ({
      nameArea: item?.name,
      noiseScreenSize: gapBetweenDigits(item?.stLen || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_3_2,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_3_2Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_3_2,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_3_3Fx.use(querySocEcoTable_3_3);

sample({
  clock: getSocEcoTable_3_3Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_3_3,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     year: 2023,
//     transport: 'Железнодорожный',
//     emissionsVolume: '1034,3',
//   }
// ]
sample({
  clock: getSocEcoTable_3_3Fx.done,
  fn: payload => {
    const result = payload.result?.emission?.map(item => ({
      year: item?.year,
      transport: item?.name,
      emissionsVolume: gapBetweenDigits(item?.emsn || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_3_3,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_3_3Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_3_3,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_3_4Fx.use(querySocEcoTable_3_4);

sample({
  clock: getSocEcoTable_3_4Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_3_4,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     nameArea: 'Каркуши-Дрокино',
//     lineLength: 5264,
//     numberCulverts: 3,
//   }
// ]
sample({
  clock: getSocEcoTable_3_4Fx.done,
  fn: payload => {
    const result = payload.result?.pipes?.map(item => ({
      nameArea: item?.name,
      lineLength: gapBetweenDigits(item?.lenMeter || 0),
      numberCulverts: gapBetweenDigits(item?.pipesCount || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_3_4,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_3_4Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_3_4,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_3_5Fx.use(querySocEcoTable_3_5);

sample({
  clock: getSocEcoTable_3_5Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_3_5,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     year: 2022
//     graphCategory: '',
//     volumesTechnogenicImpact: '7,722',
//   }
// ]
sample({
  clock: getSocEcoTable_3_5Fx.done,
  fn: payload => {
    const result = payload.result?.polution?.map(item => ({
      year: item?.year,
      graphCategory: item?.category,
      volumesTechnogenicImpact: gapBetweenDigits(item?.polution || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_3_5,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_3_5Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_3_5,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_3_6Fx.use(querySocEcoTable_3_6);

sample({
  clock: getSocEcoTable_3_6Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_3_6,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportArea: 'Бугач – Каракуша',
//     // areaTerritoriesSubjectBuild: 585000,
//     // areaTerritoriesSubjectTransportLine: 351000,
//     // areaTPU: 1000,
//     areaTerritoriesSubjectReclamation: 233000,
//   }
// ]
sample({
  clock: getSocEcoTable_3_6Fx.done,
  fn: payload => {
    const result = payload.result?.floraAndFauna?.map(item => ({
      transportArea: item?.name,
      areaTerritoriesSubjectReclamation: gapBetweenDigits(item?.square || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_3_6,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_3_6Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_3_6,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_3_7Fx.use(querySocEcoTable_3_7);

sample({
  clock: getSocEcoTable_3_7Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_3_7,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportArea: 'Бугач – Каракуша',
//     // area: 780000,
//     // areaTPU: 500,
//     // areaTerritoriesSubjectTransportLine: 351000,
//     // areaTerritoriesSubjectReclamation: 233000,
//     areaTerritoriesSubjectRestoration: 195500,
//   }
// ]
sample({
  clock: getSocEcoTable_3_7Fx.done,
  fn: payload => {
    const result = payload.result?.recovery?.map(item => ({
      transportArea: item?.name,
      areaTerritoriesSubjectRestoration: gapBetweenDigits(item?.sum || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_3_7,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_3_7Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_3_7,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_4_1Fx.use(querySocEcoTable_4_1);

sample({
  clock: getSocEcoTable_4_1Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_4_1,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportObject: 'Аэропорт Красноярск',
//     transportArea: '',
//     year: 2023,
//     numberPassengersPerDay: 0,
//     requiredNumberPersonalMobilityDevices: 0,
//   },
// ]
sample({
  clock: getSocEcoTable_4_1Fx.done,
  fn: payload => {
    const result = payload.result?.personalMobilityEquipment?.map(item => ({
      transportObject: item?.districtName,
      transportArea: item?.tpuName,
      year: item?.year,
      numberPassengersPerDay: gapBetweenDigits(item?.averageFlow || 0),
      requiredNumberPersonalMobilityDevices: gapBetweenDigits(
        item?.personalMobilityEquipment || 0,
      ),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_4_1,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_4_1Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_4_1,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_4_2Fx.use(querySocEcoTable_4_2);

sample({
  clock: getSocEcoTable_4_2Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_4_2,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportArea: '------',
//     project:
//       'Железнодорожная линия Бугач – Аэропорт Емельяново - автомобильная дорога ОП РЗ 04К-297 Красноярск - Элита',
//     totalNumberIntersectionsRequired: 1,
//   }
// ]
sample({
  clock: getSocEcoTable_4_2Fx.done,
  fn: payload => {
    const result = payload.result?.plannedRailwayIntersection?.map(item => ({
      project: item?.projectName,
      totalNumberIntersectionsRequired: gapBetweenDigits(item?.num || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_4_2,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_4_2Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_4_2,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_4_4Fx.use(querySocEcoTable_4_4);

sample({
  clock: getSocEcoTable_4_4Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_4_4,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportArea(projectArea): 'Бугач – Каракуша',
//     // project: 'Железнодорожная линия Бугач – Аэропорт Емельяново',
//     // buildingAreaLength: '7,8',
//     numberJobsCreated: 66,
//   }
// ]
sample({
  clock: getSocEcoTable_4_4Fx.done,
  fn: payload => {
    const result = payload.result?.safeWorkingConditions?.map(item => ({
      transportArea: item?.name,
      numberJobsCreated: gapBetweenDigits(item?.number || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_4_4,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_4_4Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_4_4,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_5_2Fx.use(querySocEcoTable_5_2);

sample({
  clock: getSocEcoTable_5_2Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_5_2,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportObject: 'Бугач',
//     transportArea: 'Ботанический бульвар',
//     simultaneousMaximumPassTraffic: 200,
//     numberPublicTransportRoutes: 8,
//   }
// ]
sample({
  clock: getSocEcoTable_5_2Fx.done,
  fn: payload => {
    const result = payload.result?.transfer?.map(item => ({
      transportObject: item?.tpuName,
      transportArea: item?.transportDistrictName,
      simultaneousMaximumPassTraffic: gapBetweenDigits(item?.mfMax || 0),
      numberPublicTransportRoutes: gapBetweenDigits(item?.max || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_5_2,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_5_2Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_5_2,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_5_3Fx.use(querySocEcoTable_5_3);

sample({
  clock: getSocEcoTable_5_3Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_5_3,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportObject: 'Бугач',
//     transportArea: '',
//     year: 2027,
//     // averageTimeTransferFromRoadTransport: 4,
//     averageTimeTransferFromPublicTransport: 8,
//   }
// ]
sample({
  clock: getSocEcoTable_5_3Fx.done,
  fn: payload => {
    const result = payload.result?.closestPointToTpu?.map(item => ({
      transportObject: item?.tpuName,
      transportArea: item?.transportDistrictName,
      year: item?.graphYear,
      averageTimeTransferFromPublicTransport: gapBetweenDigits(
        item?.minTime || 0,
      ),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_5_3,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_5_3Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_5_3,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_5_4Fx.use(querySocEcoTable_5_4);

sample({
  clock: getSocEcoTable_5_4Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_5_4,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportObject: 'Аэропорт Красноярск',
//     transportArea: '',
//     year: 2023,
//     simultaneousMaximumPassTraffic: 0,
//     requiredNumberParkingSpaces: 0,
//   }
// ]
sample({
  clock: getSocEcoTable_5_4Fx.done,
  fn: payload => {
    const result = payload.result?.parkingPlaces?.map(item => ({
      transportObject: item?.districtName,
      transportArea: item?.tpuName,
      year: item?.year,
      simultaneousMaximumPassTraffic: gapBetweenDigits(item?.maxFlow || 0),
      requiredNumberParkingSpaces: gapBetweenDigits(item?.parkingPlace || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_5_4,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_5_4Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_5_4,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_5_5Fx.use(querySocEcoTable_5_5);

sample({
  clock: getSocEcoTable_5_5Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_5_5,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportObject: 'Бугач',
//     transportArea: '',
//     // year: '2007',
//     // simultaneousMaximumPassTraffic: '124',
//     numberNavigationElements: 6,
//   }
// ]
sample({
  clock: getSocEcoTable_5_5Fx.done,
  fn: payload => {
    const result = payload.result?.navigationSystems?.map(item => ({
      transportObject: item?.districtName,
      transportArea: item?.tpuName,
      numberNavigationElements: gapBetweenDigits(item?.navigationSystems || 0),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_5_5,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_5_5Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_5_5,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_5_6Fx.use(querySocEcoTable_5_6);

sample({
  clock: getSocEcoTable_5_6Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_5_6,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportObject: 'Бугач',
//     transportArea: '',
//     // year: '',
//     adjacentArea: 1000,
//     costImprovementActivities: '6,3',
//   }
// ]
sample({
  clock: getSocEcoTable_5_6Fx.done,
  fn: payload => {
    const result = payload.result?.modernizeExistingPublicArea?.map(item => ({
      transportObject: item?.districtName,
      transportArea: item?.tpuName,
      adjacentArea: gapBetweenDigits(item?.square || 0),
      costImprovementActivities: gapBetweenDigits(
        (item?.price || 0) / 1_000_000,
      ),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_5_6,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_5_6Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_5_6,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_5_8Fx.use(querySocEcoTable_5_8);

sample({
  clock: getSocEcoTable_5_8Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_5_8,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     nameTransportInitiative:
//       'Строительство железнодорожной линии Бугач – Аэропорт Емельяново',
//     // planningDocument:
//     //   'Стратегия социально-экономического развития Красноярского края до 2030 года',
//     // startYearImplementation: 2024,
//     yearCompletion: 2027,
//     price: ''
//   }
// ]
sample({
  clock: getSocEcoTable_5_8Fx.done,
  fn: payload => {
    const result = payload.result?.futureProjects?.map(item => ({
      nameTransportInitiative: item?.name,
      yearCompletion: item?.year,
      price: gapBetweenDigits((item?.price || 0) / 1_000_000),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_5_8,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_5_8Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_5_8,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_6_1Fx.use(querySocEcoTable_6_1);

sample({
  clock: getSocEcoTable_6_1Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_6_1,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     year: '2023',
//     population: '1171,1',
//     shoppingCenterArea: '922,3',
//     numberCollectiveAccommodationFacilities: '17070',
//     constructionFinancing: '0',
//     frequencyServicePromisingLine: '0',
//   },
// ]
sample({
  clock: getSocEcoTable_6_1Fx.done,
  fn: payload => {
    const request = payload.result?.point61;
    const years = new Set<number>();

    request?.commonSocEco?.forEach(item => years.add(item?.year as number));
    request?.priceAndFreq?.forEach(item => years.add(item?.year as number));

    const result = [...years]
      .sort((a, b) => a - b)
      .map(year => ({
        year: year,
        population:
          gapBetweenDigits(
            request?.commonSocEco?.find(item => item?.year === year)
              ?.sumPopulation || 0,
          ) || '-',
        shoppingCenterArea:
          gapBetweenDigits(
            (request?.commonSocEco?.find(item => item?.year === year)
              ?.sumRetailSpaces || 0) / 1_000,
          ) || '-',
        numberCollectiveAccommodationFacilities:
          gapBetweenDigits(
            request?.commonSocEco?.find(item => item?.year === year)
              ?.sumServiceWorkplaces || 0,
          ) || '-',
        constructionFinancing:
          gapBetweenDigits(
            request?.priceAndFreq?.find(item => item?.year === year)?.price ||
              0,
          ) || '-',
        frequencyServicePromisingLine:
          gapBetweenDigits(
            request?.priceAndFreq?.find(item => item?.year === year)
              ?.frequenceWeekdays || 0,
          ) || '-',
      }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_6_1,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_6_1Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_6_1,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_6_2Fx.use(querySocEcoTable_6_2);

sample({
  clock: getSocEcoTable_6_2Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_6_2,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     tpu: 'Бугач',
//     numberPassengersTransportedViaTPU: '135,8',
//     costImprovementActivities: '6,3',
//     numberParkingSpacesWithinTPU: '13',
//     numberPersonalMobilityDevices: '2',
//   }
// ]
sample({
  clock: getSocEcoTable_6_2Fx.done,
  fn: payload => {
    const result = payload.result?.point62?.map(item => ({
      tpu: item?.tpuName,
      numberPassengersTransportedViaTPU: gapBetweenDigits(
        (item?.averageFlow || 0) / 1_000,
      ),
      costImprovementActivities: gapBetweenDigits(
        (item?.price || 0) / 1_000_000,
      ),
      numberParkingSpacesWithinTPU: gapBetweenDigits(item?.parkingPlace || 0),
      numberPersonalMobilityDevices: gapBetweenDigits(
        item?.personalMobilityEquipment || 0,
      ),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_6_2,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_6_2Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_6_2,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_6_3Fx.use(querySocEcoTable_6_3);

sample({
  clock: getSocEcoTable_6_3Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_6_3,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     year: '2023',
//     population: '1171,1',
//     reducingCarbonDioxideEmissions: '0',
//     areaDisturbedLands: '0',
//   }
// ]
sample({
  clock: getSocEcoTable_6_3Fx.done,
  fn: payload => {
    const request = payload.result?.point63;
    const years = new Set<number>();

    request?.population?.forEach(item => years.add(item?.year as number));
    request?.emission?.forEach(item => years.add(item?.year as number));
    request?.polution?.forEach(item => years.add(item?.Year as number));

    const result = [...years]
      .sort((a, b) => a - b)
      .map(year => ({
        year: year,
        population:
          gapBetweenDigits(
            (request?.population?.find(item => item?.year === year)
              ?.sumPopulation || 0) / 1_000,
          ) || '-',
        reducingCarbonDioxideEmissions:
          gapBetweenDigits(
            request?.emission?.find(item => item?.year === year)?.sumEmsn || 0,
          ) || '-',
        areaDisturbedLands:
          gapBetweenDigits(
            (request?.polution?.find(item => item?.Year === year)?.sum || 0) /
              10_000,
          ) || '-',
      }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_6_3,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_6_3Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_6_3,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_7_1Fx.use(querySocEcoTable_7_1);

sample({
  clock: getSocEcoTable_7_1Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_7_1,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     year: '2023',
//     population: '1171,1',
//     shoppingCenterArea: '922,3',
//     numberCollectiveAccommodationFacilities: '17070',
//     constructionFinancing: '0',
//     frequencyServicePromisingLine: '0',
//   },
// ]
sample({
  clock: getSocEcoTable_7_1Fx.done,
  fn: payload => {
    const request = payload.result?.point61;
    const years = new Set<number>();

    request?.commonSocEco?.forEach(item => years.add(item?.year as number));
    request?.priceAndFreq?.forEach(item => years.add(item?.year as number));

    const result = [...years]
      .sort((a, b) => a - b)
      .map(year => ({
        year: year,
        population:
          gapBetweenDigits(
            request?.commonSocEco?.find(item => item?.year === year)
              ?.sumPopulation || 0,
          ) || '-',
        shoppingCenterArea:
          gapBetweenDigits(
            (request?.commonSocEco?.find(item => item?.year === year)
              ?.sumRetailSpaces || 0) / 1_000,
          ) || '-',
        numberCollectiveAccommodationFacilities:
          gapBetweenDigits(
            request?.commonSocEco?.find(item => item?.year === year)
              ?.sumServiceWorkplaces || 0,
          ) || '-',
        constructionFinancing:
          gapBetweenDigits(
            request?.priceAndFreq?.find(item => item?.year === year)?.price ||
              0,
          ) || '-',
        frequencyServicePromisingLine:
          gapBetweenDigits(
            request?.priceAndFreq?.find(item => item?.year === year)
              ?.frequenceWeekdays || 0,
          ) || '-',
      }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_7_1,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_7_1Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_7_1,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_7_2Fx.use(querySocEcoTable_7_2);

sample({
  clock: getSocEcoTable_7_2Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_7_2,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     nameTransportLineSection: 'Бугач - Аэропорт Красноярск',
//     year: 2023,
//     volumeTransportWork: '-',
//     amountElectricityConsumed: '-',
//   }
// ]
sample({
  clock: getSocEcoTable_7_2Fx.done,
  fn: payload => {
    const result = payload.result?.resourceSavingTechnologies?.map(item => ({
      nameTransportLineSection: item?.name,
      year: item?.year,
      volumeTransportWork: gapBetweenDigits(item?.transportWork || 0),
      amountElectricityConsumed: gapBetweenDigits(
        item?.consumedElectricity || 0,
      ),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_7_2,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_7_2Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_7_2,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_7_3Fx.use(querySocEcoTable_7_3);

sample({
  clock: getSocEcoTable_7_3Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_7_3,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     transportObject: 'Бугач',
//     areaTPU: 2000,
//     numberInteractiveMapsRequired: 1,
//   }
// ]
sample({
  clock: getSocEcoTable_7_3Fx.done,
  fn: payload => {
    const result = payload.result?.interactiveMaps?.map(item => ({
      transportObject: item?.name,
      areaTPU: item?.square,
      numberInteractiveMapsRequired: gapBetweenDigits(
        item?.interactiveMaps || 0,
      ),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_7_3,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_7_3Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_7_3,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_7_4Fx.use(querySocEcoTable_7_4);

sample({
  clock: getSocEcoTable_7_4Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_7_4,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     year: '2023',
//     population: '1171,1',
//     shoppingCenterArea: '922,3',
//     numberCollectiveAccommodationFacilities: '17070',
//     constructionFinancing: '0',
//     frequencyServicePromisingLine: '0',
//   },
// ]
sample({
  clock: getSocEcoTable_7_4Fx.done,
  fn: payload => {
    const request = payload.result?.point61;
    const years = new Set<number>();

    request?.commonSocEco?.forEach(item => years.add(item?.year as number));
    request?.priceAndFreq?.forEach(item => years.add(item?.year as number));

    const result = [...years]
      .sort((a, b) => a - b)
      .map(year => ({
        year: year,
        population:
          gapBetweenDigits(
            request?.commonSocEco?.find(item => item?.year === year)
              ?.sumPopulation || 0,
          ) || '-',
        shoppingCenterArea:
          gapBetweenDigits(
            (request?.commonSocEco?.find(item => item?.year === year)
              ?.sumRetailSpaces || 0) / 1_000,
          ) || '-',
        numberCollectiveAccommodationFacilities:
          gapBetweenDigits(
            request?.commonSocEco?.find(item => item?.year === year)
              ?.sumServiceWorkplaces || 0,
          ) || '-',
        constructionFinancing:
          gapBetweenDigits(
            request?.priceAndFreq?.find(item => item?.year === year)?.price ||
              0,
          ) || '-',
        frequencyServicePromisingLine:
          gapBetweenDigits(
            request?.priceAndFreq?.find(item => item?.year === year)
              ?.frequenceWeekdays || 0,
          ) || '-',
      }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_7_4,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_7_4Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_7_4,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});

getSocEcoTable_7_5Fx.use(querySocEcoTable_7_5);

sample({
  clock: getSocEcoTable_7_5Fx,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_7_5,
    isLoading: true,
  }),
  target: SocEcoTableApi.setLoading,
});

// [
//   {
//     nameTransportLineSection: 'Бугач - Аэропорт Красноярск',
//     year: 2023,
//     volumeTransportWork: '-',
//     amountElectricityConsumed: '-',
//   }
// ]
sample({
  clock: getSocEcoTable_7_5Fx.done,
  fn: payload => {
    const result = payload.result?.resourceSavingTechnologies?.map(item => ({
      nameTransportLineSection: item?.name,
      year: item?.year,
      volumeTransportWork: gapBetweenDigits(item?.transportWork || 0),
      amountElectricityConsumed: gapBetweenDigits(
        item?.consumedElectricity || 0,
      ),
    }));

    return {
      tableId: SOCECO_TABLE_IDS.TABLE_7_5,
      data: result,
    } as SetDataForTablePayload;
  },
  target: SocEcoTableApi.setDataForTable,
});

sample({
  clock: getSocEcoTable_7_5Fx.finally,
  fn: () => ({
    tableId: SOCECO_TABLE_IDS.TABLE_7_5,
    isLoading: false,
  }),
  target: SocEcoTableApi.setLoading,
});
