import { IScenarioStateEnum } from '@api/gql/tpu-types';

import { Typography } from '@mui/material';

interface StatusProps {
  value: IScenarioStateEnum | null;
}

const STATUS_TRANSLATE = {
  [IScenarioStateEnum.Calculated]: 'Рассчитан',
  [IScenarioStateEnum.NotCalculated]: 'Не рассчитан',
};

export const Status = ({ value }: StatusProps) => {
  if (!value) return null;

  return (
    <Typography
      sx={{
        color: value === IScenarioStateEnum.Calculated ? '#4EC221' : '#EFA71C',
        fontSize: theme => theme.typography.pxToRem(12),
        lineHeight: theme => theme.typography.pxToRem(16),
      }}
    >
      {STATUS_TRANSLATE[value]}
    </Typography>
  );
};
