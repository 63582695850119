import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';

import {
  $FilterMap,
  FilterMapApi,
} from '@features/pt-forecast-new/stores/filters';

export const TransportDistrictFilter = () => {
  const { transportRegions } = useUnit($FilterMap);
  const { setFilters } = useUnit(FilterMapApi);

  const setTransportRegionFilter = useCallback(() => {
    setFilters({ transportRegions: !transportRegions });
  }, [setFilters, transportRegions]);

  return (
    <FormControlLabel
      checked={transportRegions}
      onChange={setTransportRegionFilter}
      control={<Checkbox />}
      label={'Показывать границы транспортных регионов'}
      sx={{
        pt: '16px',
        pl: '16px',
      }}
    />
  );
};
