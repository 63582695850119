import { IAgentShareInfoCategoryEnum } from '@api/gql/tpu-types';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

import { Accordion } from '@components/Accordion';
import { BasketIcon } from '@components/icons';

import { CATEGORY_TYPE_TRANSLATE } from '@features/tpu-passenger-types/constants';
import { useContextPassengerTypesHandlers } from '@features/tpu-passenger-types/hooks';
import {
  $Registry,
  $ScenarioId,
} from '@features/tpu-passenger-types/stores/registry.store';

export const RegistyPassengerTypesContainer = () => {
  const $registry = useUnit($Registry);
  const $scenarioId = useUnit($ScenarioId);
  const {
    getAllAgentShareInfoCategory,
    openTypeForEdit,
    openShareForEdit,
    openModalDeletePassengerType,
  } = useContextPassengerTypesHandlers();

  useEffect(() => {
    if ($scenarioId) getAllAgentShareInfoCategory($scenarioId);
  }, [getAllAgentShareInfoCategory, $scenarioId]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        boxShadow: '0px 0px 8px 0px #00000026',
        borderRadius: '8px',
        backgroundColor: theme => theme.palette.background.paper,
        padding: '24px',
        width: '100%',
      }}
    >
      <Typography variant={'subtitle1'}>Типы пассажиров</Typography>
      {Object.keys($registry).map(item => (
        <Accordion
          key={item}
          summaryComponent={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ flexGrow: 1 }}
              >
                {CATEGORY_TYPE_TRANSLATE[item as IAgentShareInfoCategoryEnum]}
              </Typography>
              <Button
                sx={{
                  p: '0px 10px',
                  maxHeight: '32px',
                  backgroundColor: theme =>
                    `${theme.palette.background.default} !important`,
                  ml: 'auto',
                  fontSize: theme => theme.typography.pxToRem(14),
                  lineHeight: theme => theme.typography.pxToRem(14),
                }}
                variant={'outlined'}
                onClick={e => {
                  e.stopPropagation();
                  openShareForEdit(item);
                }}
              >
                Настроить доли типов
              </Button>
            </Box>
          }
        >
          <List
            dense={true}
            sx={{
              p: 0,
              border: theme => `1px solid ${theme.palette.border.divider}`,
              borderRadius: '8px',
              backgroundColor: theme => theme.palette.background.paper,
            }}
          >
            {$registry[item as keyof typeof CATEGORY_TYPE_TRANSLATE].map(
              share => (
                <ListItem
                  key={share.id}
                  sx={{
                    m: 0,
                    borderBottom: theme =>
                      `1px solid ${theme.palette.border.divider}`,
                    [`&:last-child`]: {
                      borderBottom: '0',
                    },
                  }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() =>
                        openModalDeletePassengerType({
                          id: share.id,
                          name: share.name,
                        })
                      }
                    >
                      <BasketIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    key={share.id}
                    primary={share.name}
                    onClick={e => {
                      e.stopPropagation();
                      openTypeForEdit(share.id);
                    }}
                  />
                </ListItem>
              ),
            )}
          </List>
        </Accordion>
      ))}
    </Box>
  );
};
