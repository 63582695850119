export const SOC_ECO_TH_1_2 = [
  // {
  //   id: 'tpu',
  //   label: 'ТПУ',
  // },
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'projectedPassTraffic',
    label: 'Прогнозируемый пассажиропоток, тыс. чел.',
  },
  {
    id: 'availabilityAccommodationFacilities',
    label: 'Наличие средств размещения, мест',
  },
  {
    id: 'shortageAccommodationFacilities',
    label: 'Дефицит средств размещения, мест',
  },
];
