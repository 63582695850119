import { sample } from 'effector';
import { Feature } from 'ol';
import { MultiLineString } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EMapFeatureLayout, EMapFeatureMetaKeys } from '@constants/map';

import { EVehicle } from '@features/pt-forecast-new/constants/eVehicle';
import {
  $ComputedData,
  ComputedDataApi,
} from '@features/pt-forecast-new/stores/computedData';
import {
  $FilterMap,
  EFilterMapLayout,
  FilterMapApi,
} from '@features/pt-forecast-new/stores/filters';
import {
  $LayersVisible,
  LayersVisibleApi,
} from '@features/pt-forecast-new/stores/map/layersVisible';
import { $SelectedFeatures } from '@features/pt-forecast-new/stores/map/selectedFeatures';
import {
  initSources,
  pipeDirectionRailwayVectorLayer,
} from '@features/pt-forecast-new/stores/map/store';
import {
  $VectorGeometry,
  VectorGeometryApi,
} from '@features/pt-forecast-new/stores/map/vectorFeatures';
import { getWidthLineByPercent } from '@features/pt-forecast-new/utils/getWidthLineByPercent';

import {
  EFeatureStyle,
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';
import { EVectorLayerStyles } from '@utils/map/tools/getVectorLayerStyle';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload &&
    Object.keys(payload).includes(EMapFeatureLayout.directionRailway),
  fn: payload => {
    return {
      action: EPipeVectorLayer.setVisible,
      payload: payload![EMapFeatureLayout.directionRailway],
    } as PipeVectorLayer;
  },
  target: pipeDirectionRailwayVectorLayer,
});

// Рендер геометрии
sample({
  clock: [VectorGeometryApi.setDirectionPassFlowRailway, initSources],
  source: { VectorGeometry: $VectorGeometry },
  fn: ({ VectorGeometry }) => {
    const features: Feature<MultiLineString>[] = [];

    if (VectorGeometry.directionPassFlow.railway.length) {
      // const lines = VectorGeometry.directionPassFlow.railway.map(
      //   item => item.coordinates,
      // )
      // features.push(
      //   new Feature({
      //     geometry: new MultiLineString(
      //       lines.flat().map(line =>
      //         line.map(coordinate => fromLonLat(coordinate)),
      //       ),
      //     ),
      //     [EMapFeatureMetaKeys.olId]: 'test',
      //     [EMapFeatureMetaKeys.layout]: EMapFeatureLayout.directionRailway,
      //   })
      // )

      VectorGeometry.directionPassFlow.railway.forEach(item => {
        features.push(
          new Feature({
            geometry: new MultiLineString(
              item.coordinates.map(line =>
                line.map(coordinate => fromLonLat(coordinate)),
              ),
            ),
            [EMapFeatureMetaKeys.olId]: item.id,
            [EMapFeatureMetaKeys.layout]: EMapFeatureLayout.directionRailway,
            year: item.year,
            status: item.status,
          }),
        );
      });
    }

    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: { features },
    } as PipeVectorLayer;
  },
  target: pipeDirectionRailwayVectorLayer,
});

// Изменение темы
sample({
  clock: FilterMapApi.setLayout,
  source: { FilterMap: $FilterMap },
  fn: ({ FilterMap }) => {
    const theme =
      FilterMap.layout === EFilterMapLayout.PassengerFlows
        ? EVectorLayerStyles.default
        : EVectorLayerStyles.railwayDashed;
    return {
      action: EPipeVectorLayer.changeTheme,
      payload: { theme, isNoReplace: true },
    } as PipeVectorLayer;
  },
  target: pipeDirectionRailwayVectorLayer,
});

// применение стилей к слою
sample({
  clock: [
    ComputedDataApi.setPassengerTrafficPercent,
    FilterMapApi.setLayout,
    initSources,
  ],
  source: { ComputedData: $ComputedData, FilterMap: $FilterMap },
  fn: ({ ComputedData, FilterMap }) => {
    const percent =
      (ComputedData.passengerTrafficPercent[EVehicle.nightTrain] || 0) +
      (ComputedData.passengerTrafficPercent[EVehicle.dayTrain] || 0) +
      (ComputedData.passengerTrafficPercent[EVehicle.suburbanTrain] || 0) +
      (ComputedData.passengerTrafficPercent[EVehicle.expressTrain] || 0);
    return {
      action: EPipeVectorLayer.changeThemeProps,
      payload: {
        width:
          FilterMap.layout === EFilterMapLayout.PassengerFlows
            ? getWidthLineByPercent(percent)
            : 3,
      },
    } as PipeVectorLayer;
  },
  target: pipeDirectionRailwayVectorLayer,
});

// применение стилей к фичам
sample({
  clock: [$SelectedFeatures, $LayersVisible],
  source: {
    SelectedFeatures: $SelectedFeatures,
    LayersVisible: $LayersVisible,
  },
  filter: ({ LayersVisible }) =>
    LayersVisible[EMapFeatureLayout.directionRailway],
  fn: ({ SelectedFeatures }) => {
    return {
      action: EPipeVectorLayer.setFeatureStyle,
      payload: {
        featureStyles: !SelectedFeatures.length
          ? {}
          : Object.fromEntries(
              SelectedFeatures.filter(
                item => item.layout === EMapFeatureLayout.directionRailway,
              ).map(item => [item.id, EFeatureStyle.selected]),
            ),
      },
    } as PipeVectorLayer;
  },
  target: pipeDirectionRailwayVectorLayer,
});
