import {
  createAreaBordersPassengerFlowSource,
  createPathPassengerFlowSource,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { Map as MapOl } from 'ol';
import { easeOut } from 'ol/easing';
import { fromLonLat } from 'ol/proj';
import { memo, useEffect, useRef } from 'react';

import { Map } from '@components/Map';

import { EMapFeatureLayout } from '@constants/map';

import { useInitMapControls } from '@utils/map/hooks/useInitMapControls';
import { useInitTileLayer } from '@utils/map/hooks/useInitTileLayer';
import { ETileLayerStyles } from '@utils/map/tools/getTileLayerStyle';

import { MapFeatureTooltip } from '../../components/MapFeatureTooltip/MapFeatureTooltip';
import {
  clickMap,
  pipeAreaBordersPassengerFlowTileLayer,
  pipeMapControls,
  pipePathPassengerFlowTileLayer,
} from '../../stores/map';

type MapContainerProps = {
  regionUUID: string;
};

export const MapContainer = memo((props: MapContainerProps) => {
  const { regionUUID } = props;
  const mapRef = useRef<MapOl | null>(null);

  useInitMapControls({
    mapRef,
    events: [pipeMapControls],
  });

  useInitTileLayer({
    mapRef,
    config: {
      source: createAreaBordersPassengerFlowSource(regionUUID),
      zIndex: 2,
      layout: EMapFeatureLayout.areaBorderPassengerFlow,
      theme: ETileLayerStyles.default,
    },
    events: [pipeAreaBordersPassengerFlowTileLayer],
  });

  useInitTileLayer({
    mapRef,
    config: {
      source: createPathPassengerFlowSource(regionUUID),
      zIndex: 1,
      layout: EMapFeatureLayout.pathPassengerFlow,
      theme: ETileLayerStyles.default,
    },
    events: [pipePathPassengerFlowTileLayer],
  });

  useEffect(() => {
    mapRef.current?.getView().animate({
      center: fromLonLat([92.6033, 63.1836]),
      zoom: 4,
      duration: 1500,
      easing: easeOut,
    });
  }, []);

  return (
    <>
      <Map
        ref={mapRef}
        setMap={() => {}}
        onClick={clickMap}
        uniqueTopographicInstance
      />
      <MapFeatureTooltip mapRef={mapRef} />
    </>
  );
});
