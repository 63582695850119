import { useEffect, useState } from 'react';

import {
  StepPoint,
  StepPointConnector,
  StepPointIcon,
  StepPointIconProps,
} from './style';

import { Box, Stack, Step, Stepper, Typography } from '@mui/material';

type YearLineType = {
  baseYear?: number | null;
  finalYear?: number | null;
  selectedYear?: number | null;
  onSelect?: (step: number) => void;
  disabled?: boolean;
};

const currentYear = Number(new Date().getFullYear().toString());

const Notification = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '-30px',
      left: 'calc(50% - 250px)',
      p: 1.5,
      borderRadius: theme => theme.spacing(1),
      bgcolor: theme => theme.palette.common.white,
    }}
  >
    <Typography color={theme => theme.palette.secondary.main}>
      Для построения прогноза перейдите в конструктор
    </Typography>
  </Box>
);

export const YearLine = (props: YearLineType) => {
  const baseYear = props.baseYear ? props.baseYear : 2022;
  const finalYear = props.finalYear ? props.finalYear : 2035;
  const selectedYear = props.selectedYear ? props.selectedYear : 0;

  const getSelectedYear = props.onSelect;

  const [notification, setNotification] = useState(false);

  const steps = Array(finalYear - baseYear + 1)
    .fill(baseYear)
    .map((n, i) => n + i);

  const handleStep = (step: number) => () => {
    if (!getSelectedYear) {
      setNotification(true);
    }
    getSelectedYear && getSelectedYear(step);
  };

  useEffect(() => {
    !getSelectedYear &&
      notification &&
      setTimeout(() => {
        setNotification(false);
      }, 3000);
  }, [getSelectedYear, notification]);

  return (
    <>
      {notification && <Notification />}

      <Stack
        sx={{ width: '100%', p: 2 }}
        spacing={4}
      >
        <Stepper
          activeStep={selectedYear}
          connector={<StepPointConnector />}
        >
          {steps.map((label, i) => (
            <Step
              key={label}
              sx={{ p: 0, zIndex: 1 }}
              onClick={handleStep(i)}
            >
              <StepPoint
                StepIconComponent={(props: StepPointIconProps) => (
                  <StepPointIcon
                    {...props}
                    isComing={!props.active && label <= currentYear}
                  />
                )}
                disabled={props.disabled}
                sx={{
                  '& span, & .MuiStepLabel-label.Mui-active, & .MuiStepLabel-label.Mui-completed':
                    {
                      fontWeight: label <= currentYear ? 900 : 500,
                    },
                }}
              >
                {label}
              </StepPoint>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </>
  );
};
