import { sample } from 'effector';

import {
  $FilterMap,
  EFilterMapLayout,
  FilterMapApi,
} from '@features/pt-forecast-new/stores/filters';
import {
  clickMap,
  pipeMapControls,
} from '@features/pt-forecast-new/stores/map';
import {
  $SearchInfra,
  SearchInfraApi,
  SearchInfraStore,
} from '@features/pt-forecast-new/stores/map/searchInfra/store';
import {
  SelectedFeaturesApi,
  SelectedFeaturesStore,
} from '@features/pt-forecast-new/stores/map/selectedFeatures';
import { $VectorGeometry } from '@features/pt-forecast-new/stores/map/vectorFeatures';
import { UIApi } from '@features/pt-forecast-new/stores/ui';

import {
  EPipeMapControls,
  PipeMapControls,
} from '@utils/map/hooks/useInitMapControls';

// Найти геометрию фичи аэропорта по идентификатору
sample({
  clock: SearchInfraApi.setSelectedOption,
  source: { VectorGeometry: $VectorGeometry, SearchInfra: $SearchInfra },
  filter: ({ SearchInfra }) => SearchInfra.selectedOption?.type === 'airport',
  fn: ({ SearchInfra, VectorGeometry }) => {
    const geometry = VectorGeometry.allAirports.find(
      item => item.id === SearchInfra.selectedOption?.value,
    );

    return {
      airport: {
        geometry: geometry?.coordinates,
        id: geometry?.id,
      },
      busStation: null,
      railwayStation: null,
    } as SearchInfraStore['features'];
  },
  target: SearchInfraApi.setGeometry,
});

// Найти геометрию фичи аэропорта по идентификатору
sample({
  clock: SearchInfraApi.setSelectedOption,
  source: { VectorGeometry: $VectorGeometry, SearchInfra: $SearchInfra },
  filter: ({ SearchInfra }) =>
    SearchInfra.selectedOption?.type === 'busStation',
  fn: ({ SearchInfra, VectorGeometry }) => {
    const geometry = VectorGeometry.busStations.find(
      item => item.id === SearchInfra.selectedOption?.value,
    );

    return {
      busStation: {
        geometry: geometry?.coordinates,
        id: geometry?.id,
      },
      airport: null,
      railwayStation: null,
    } as SearchInfraStore['features'];
  },
  target: SearchInfraApi.setGeometry,
});

// Найти геометрию фичи аэропорта по идентификатору
sample({
  clock: SearchInfraApi.setSelectedOption,
  source: { VectorGeometry: $VectorGeometry, SearchInfra: $SearchInfra },
  filter: ({ SearchInfra }) =>
    SearchInfra.selectedOption?.type === 'railwayStation',
  fn: ({ SearchInfra, VectorGeometry }) => {
    const geometry = VectorGeometry.railwayStations.find(
      item => item.id === SearchInfra.selectedOption?.value,
    );

    return {
      railwayStation: {
        geometry: geometry?.coordinates,
        id: geometry?.id,
      },
      busStation: null,
      airport: null,
    } as SearchInfraStore['features'];
  },
  target: SearchInfraApi.setGeometry,
});

// отцентрировать карту
sample({
  clock: SearchInfraApi.setGeometry,
  source: { SearchInfra: $SearchInfra },
  filter: ({ SearchInfra }) => !!SearchInfra.selectedOption,
  fn: ({ SearchInfra }) => {
    return {
      action: EPipeMapControls.center,
      payload: SearchInfra.selectedOption!.lonLat,
    } as PipeMapControls;
  },
  target: pipeMapControls,
});

// Отзумировать карту
sample({
  clock: SearchInfraApi.setGeometry,
  source: { SearchInfra: $SearchInfra },
  filter: ({ SearchInfra }) => !!SearchInfra.selectedOption,
  fn: () => {
    return {
      action: EPipeMapControls.setZoom,
      payload: 12,
    } as PipeMapControls;
  },
  target: pipeMapControls,
});

// Сброс поиска при изменении фильтров или клике на карте
sample({
  clock: [clickMap, FilterMapApi.setFilters],
  target: SearchInfraApi.reset,
});

// Запросить информацию о выделенной фиче для инспектора
sample({
  clock: SearchInfraApi.setGeometry,
  source: { SearchInfra: $SearchInfra, FilterMap: $FilterMap },
  filter: ({ SearchInfra, FilterMap }) =>
    !!SearchInfra.selectedOption &&
    FilterMap.layout !== EFilterMapLayout.PassengerFlows,
  fn: ({ SearchInfra }) => {
    return [
      {
        id: SearchInfra.selectedOption!.value,
        layout: SearchInfra.selectedOption!.type,
      },
    ] as SelectedFeaturesStore[];
  },
  target: SelectedFeaturesApi.setSelectedFeatures,
});

sample({
  clock: SearchInfraApi.setGeometry,
  source: { SearchInfra: $SearchInfra, FilterMap: $FilterMap },
  filter: ({ SearchInfra, FilterMap }) =>
    !!SearchInfra.selectedOption &&
    FilterMap.layout !== EFilterMapLayout.PassengerFlows,
  fn: () => true,
  target: UIApi.setVisibleInspector,
});
