import {
  IQuery,
  IQueryFindRegionByPrefixArgs,
  IQueryGetRegionByIdArgs,
  ITransportRegionType,
} from '@api/gql/types';
import { createApi, createEffect, createStore } from 'effector';

const initState: TransportRegionsOptionsStore = {
  queryOptions: [],
};

export const $TransportRegionsOptions =
  createStore<TransportRegionsOptionsStore>(initState);

export const TransportRegionsOptionsApi = createApi($TransportRegionsOptions, {
  setRegionsOptions: (
    store,
    payload: TransportRegionsOptionsStore['queryOptions'],
  ) => ({
    ...store,
    queryOptions: payload,
  }),
  clearRegionsOptions: store => ({
    ...store,
    queryOptions: [],
  }),
  reset: () => initState,
});

export const fetchTransportRegionsFx = createEffect<
  IQueryFindRegionByPrefixArgs,
  IQuery['findRegionByPrefix']
>();

export const getRegionByIdFx = createEffect<
  IQueryGetRegionByIdArgs,
  IQuery['getRegionById']
>();

export type TransportRegionsOptionsStore = {
  queryOptions: Pick<ITransportRegionType, 'id' | 'name'>[];
};
