import { apiClient } from '@api/client';
import {
  IQuery,
  IQueryGetComputingRegistryInstanceByIdArgs,
} from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { FeatureSettingsApi } from '@features/pt-forecast-new/stores/settings';
import {
  SetMinMaxYearPayload,
  YearLineApi,
  getYearLineFx,
} from '@features/pt-forecast-new/stores/yearLine/store';

sample({
  clock: FeatureSettingsApi.setScenarioId,
  filter: payload => !!payload,
  fn: payload => {
    return {
      id: payload!,
    } as IQueryGetComputingRegistryInstanceByIdArgs;
  },
  target: getYearLineFx,
});

getYearLineFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getComputingRegistryInstanceById'>,
    IQueryGetComputingRegistryInstanceByIdArgs
  >({
    query: gql`
      query QueryGetYearsTimeline($id: String) {
        getComputingRegistryInstanceById(id: $id) {
          baseYear
          finalYear
        }
      }
    `,
    variables: params,
  });
  return result.data?.getComputingRegistryInstanceById;
});

sample({
  clock: getYearLineFx.done,
  fn: request => {
    return {
      minYear: request.result?.baseYear || 2000,
      maxYear: request.result?.finalYear || 2030,
    } as SetMinMaxYearPayload;
  },
  target: YearLineApi.setMinMaxYear,
});
