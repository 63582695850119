import { TLine, TPoint } from '@features/tpu-simulation-model/types';

export const getPolygonLines = (coordinates: TPoint[]): TLine[] => {
  return coordinates.map<TLine>((point, index) => {
    const isLast = index === coordinates.length - 1;
    const additionalPoint = isLast ? coordinates[0] : coordinates[index + 1];

    return [point, additionalPoint];
  });
};
