import { IQuery } from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';
import { apiClient } from 'src/api/provider';

import { EFilterMapLayout } from '@components/MapFilters/interface';

import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { $FilterMap } from '@features/passenger-traffic/stores/filters';
import {
  IQueryGetInfrastructureByIdArgs,
  InfrastructureInspectorApi,
  getAirportForInspectorFx,
  getBusStationForInspectorFx,
  getHighwayForInspectorFx,
  getRailwayForInspectorFx,
  getRailwayStationForInspectorFx,
  getRegionForInspectorFx,
} from '@features/passenger-traffic/stores/inspector/Infrastructure';
import { getDataForInspectorFn } from '@features/passenger-traffic/stores/inspector/store';

// триггерим событие на получение жд для инспектора на слое инфраструктуры
sample({
  clock: getDataForInspectorFn,
  source: { FilterMap: $FilterMap },
  filter: ({ FilterMap }, payload) =>
    Boolean(
      payload.layout === EMapFeatureLayout.railway &&
        FilterMap.layout === EFilterMapLayout.Infrastructure,
    ),
  fn: (_FilterMap, payload) => payload,
  target: [getRailwayForInspectorFx, InfrastructureInspectorApi.reset],
});

// триггерим событие на получение жд станции для инспектора на слое инфраструктуры
sample({
  clock: getDataForInspectorFn,
  source: { FilterMap: $FilterMap },
  filter: ({ FilterMap }, payload) =>
    Boolean(
      payload.layout === EMapFeatureLayout.railwayStation &&
        FilterMap.layout === EFilterMapLayout.Infrastructure,
    ),
  fn: (_FilterMap, payload) => payload,
  target: [getRailwayStationForInspectorFx, InfrastructureInspectorApi.reset],
});

// триггерим событие на получение автодороги для инспектора на слое инфраструктуры
sample({
  clock: getDataForInspectorFn,
  source: { FilterMap: $FilterMap },
  filter: ({ FilterMap }, payload) =>
    Boolean(
      payload.layout === EMapFeatureLayout.highway &&
        FilterMap.layout === EFilterMapLayout.Infrastructure,
    ),
  fn: (_FilterMap, payload) => payload,
  target: [getHighwayForInspectorFx, InfrastructureInspectorApi.reset],
});

// триггерим событие на получение автовокзала для инспектора на слое инфраструктуры
sample({
  clock: getDataForInspectorFn,
  source: { FilterMap: $FilterMap },
  filter: ({ FilterMap }, payload) =>
    Boolean(
      payload.layout === EMapFeatureLayout.busStation &&
        FilterMap.layout === EFilterMapLayout.Infrastructure,
    ),
  fn: (_FilterMap, payload) => payload,
  target: [getBusStationForInspectorFx, InfrastructureInspectorApi.reset],
});

// триггерим событие на получение аэропорта для инспектора на слое инфраструктуры
sample({
  clock: getDataForInspectorFn,
  source: { FilterMap: $FilterMap },
  filter: ({ FilterMap }, payload) =>
    Boolean(
      payload.layout === EMapFeatureLayout.airport &&
        FilterMap.layout === EFilterMapLayout.Infrastructure,
    ),
  fn: (_FilterMap, payload) => payload,
  target: [getAirportForInspectorFx, InfrastructureInspectorApi.reset],
});

// триггерим событие на получение тр для инспектора на слое инфраструктуры
sample({
  clock: getDataForInspectorFn,
  source: { FilterMap: $FilterMap },
  filter: ({ FilterMap }, payload) =>
    Boolean(
      payload.layout === EMapFeatureLayout.areaBorder &&
        FilterMap.layout === EFilterMapLayout.Infrastructure,
    ),
  fn: (_FilterMap, payload) => payload,
  target: [getRegionForInspectorFx, InfrastructureInspectorApi.reset],
});

// триггерим событие на отображение данных жд для инспектора на слое инфраструктуры
sample({
  clock: getRailwayForInspectorFx.done,
  target: InfrastructureInspectorApi.setRailwayForInspector,
});

// триггерим событие на отображение данных жд станции для инспектора на слое инфраструктуры
sample({
  clock: getRailwayStationForInspectorFx.done,
  target: InfrastructureInspectorApi.setRailwayStationForInspector,
});

// триггерим событие на отображение данных автодороги для инспектора на слое инфраструктуры
sample({
  clock: getHighwayForInspectorFx.done,
  target: InfrastructureInspectorApi.setHighwayForInspector,
});

// триггерим событие на отображение данных автостанции для инспектора на слое инфраструктуры
sample({
  clock: getBusStationForInspectorFx.done,
  target: InfrastructureInspectorApi.setBusStationForInspector,
});

// триггерим событие на отображение данных аэропорта для инспектора на слое инфраструктуры
sample({
  clock: getAirportForInspectorFx.done,
  target: InfrastructureInspectorApi.setAirportForInspector,
});

// триггерим событие на отображение данных тр для инспектора на слое инфраструктуры
sample({
  clock: getRegionForInspectorFx.done,
  target: InfrastructureInspectorApi.setTransportRegionForInspector,
});

// запрос на получение данных жд для инспектора на слое инфраструктуры
getRailwayForInspectorFx.use(async params => {
  const response = await apiClient.query<
    IQuery,
    IQueryGetInfrastructureByIdArgs
  >({
    query: gql`
      query QueryGetRailwayById($id: String) {
        getRailwayById(id: $id) {
          capacity
          country
          id
          speed
          railwayName
          tracks
          status
          tractionType
        }
      }
    `,
    variables: {
      id: params.id,
      layout: params.layout,
    },
  });

  return response.data.getRailwayById;
});

// запрос на получение данных жд станции для инспектора на слое инфраструктуры
getRailwayStationForInspectorFx.use(async params => {
  const response = await apiClient.query<
    IQuery,
    IQueryGetInfrastructureByIdArgs
  >({
    query: gql`
      query QueryGetRailwayStationById($id: String) {
        getRailwayStationById(id: $id) {
          id
          name
          details {
            passTraffic
          }
        }
      }
    `,
    variables: {
      id: params.id,
      layout: params.layout,
    },
  });

  return response.data.getRailwayStationById;
});

// запрос на получение данных автодороги для инспектора на слое инфраструктуры
getHighwayForInspectorFx.use(async params => {
  const response = await apiClient.query<
    IQuery,
    IQueryGetInfrastructureByIdArgs
  >({
    query: gql`
      query QueryGetHighwayById($id: String) {
        getHighwayById(id: $id) {
          id
          highwayName
          speed
          type
          payment
          lengthKm
          lanes
        }
      }
    `,
    variables: {
      id: params.id,
      layout: params.layout,
    },
  });

  return response.data.getHighwayById;
});

// запрос на получение данных автостанции для инспектора на слое инфраструктуры
getBusStationForInspectorFx.use(async params => {
  const response = await apiClient.query<
    IQuery,
    IQueryGetInfrastructureByIdArgs
  >({
    query: gql`
      query QueryGetBusStationById($id: String) {
        getBusStationById(id: $id) {
          id
          name
          details {
            passTraffic
          }
        }
      }
    `,
    variables: {
      id: params.id,
      layout: params.layout,
    },
  });

  return response.data.getBusStationById;
});

// запрос на получение данных аэропорта для инспектора на слое инфраструктуры
getAirportForInspectorFx.use(async params => {
  const response = await apiClient.query<
    IQuery,
    IQueryGetInfrastructureByIdArgs
  >({
    query: gql`
      query QueryGetAirportById($id: String) {
        getAirportById(id: $id) {
          id
          name
          details {
            capacityCommon
            passTraffic
            range
          }
        }
      }
    `,
    variables: {
      id: params.id,
      layout: params.layout,
    },
  });

  return response.data.getAirportById;
});

// запрос на получение данных тр для инспектора на слое инфраструктуры
getRegionForInspectorFx.use(async params => {
  const response = await apiClient.query<
    IQuery,
    IQueryGetInfrastructureByIdArgs
  >({
    query: gql`
      query QueryGetRegionById($id: String) {
        getRegionById(id: $id) {
          id
          name
          details {
            year
            revenueWithTaxesOnProducts
            revenueTourismIndustry
            population
            numberOfPlaces
            levelOfMotorization
            id
            averageNumberOfEmployeesOfOrganizationsWithoutSmp
            averageMonthlySalary
          }
        }
      }
    `,
    variables: {
      id: params.id,
      layout: params.layout,
    },
  });

  return response.data.getRegionById;
});
