import { useUnit } from 'effector-react';

import {
  decrementScaleFn,
  getComponentStateFn,
  getDataForCommercialObjectsFn,
  getScenarioIdFn,
  incrementScaleFn,
  playTickFn,
  resetAgentsFn,
  resetComponentStateFn,
  resetDataForCommercialObjectsFn,
  resetErrorsFn,
  resetFloorFn,
  resetHeatmapStoreFn,
  resetListOfRulersFn,
  resetMovementsLogFn,
  resetPlanFn,
  resetPlanSettingFn,
  resetReportStoreFn,
  resetRulerFn,
  resetScenarioChartsDataFn,
  resetScenarioPlayerFn,
  selectFloorFn,
  setCurrentTimeOfSimulationFn,
  setModelBlockSizeFn,
  setOpacityFn,
  setPlayFn,
  setPositionFn,
  setScaleFn,
  setShouldCenterStagePositionAndResetScaleFn,
  setSpeedDividerFn,
  setWithAnimationFn,
  toggleHeatmapFn,
  triggerDownloadReportFn,
  uploadTickFn,
} from '@features/tpu-calculation-scenarios-viewing/stores';

export interface UseCalculationScenariosViewingHandlers {
  getScenarioId(): void;
  selectFloor(payload: string): void;
  setScale(payload: number): void;
  triggerCenter(): void;
  onTriggeredCenter(): void;
  incrementScale(): void;
  decrementScale(): void;
  setOpacity(payload: number): void;

  resetErrors(): void;
  setPlay(payload: boolean): void;
  setSpeedDivider(payload: number): void;
  setWithAnimation(payload: boolean): void;
  setCurrentTimeOfSimulation(payload: number): void;
  playTick(): void;
  uploadTick(): void;
  getDataForCommercialObjects(): void;
  downloadReport(): void;

  getComponentState(): void;
  resetCalculationScenariosViewing(): void;
  setPosition(payload: { x: number; y: number }): void;
  setModelBlockSize(payload: { width: number; height: number }): void;

  toggleHeatmap(): void;
}

export const useCalculationScenariosViewingHandlers =
  (): UseCalculationScenariosViewingHandlers => {
    const events = useUnit({
      getScenarioIdFn,
      selectFloorFn,
      setScaleFn,
      incrementScaleFn,
      decrementScaleFn,
      setShouldCenterStagePositionAndResetScaleFn,
      triggerDownloadReportFn,
      setPositionFn,
      setModelBlockSizeFn,
      setOpacityFn,

      resetErrorsFn,
      resetReportStoreFn,
      setPlayFn,
      setSpeedDividerFn,
      setWithAnimationFn,
      setCurrentTimeOfSimulationFn,
      playTickFn,
      uploadTickFn,
      getDataForCommercialObjectsFn,
      resetRulerFn,
      resetMovementsLogFn,

      getComponentStateFn,

      resetAgentsFn,
      resetScenarioChartsDataFn,
      resetDataForCommercialObjectsFn,
      resetComponentStateFn,
      resetPlanSettingFn,
      resetFloorFn,
      resetScenarioPlayerFn,
      resetPlanFn,
      resetListOfRulersFn,

      resetHeatmapStoreFn,
      toggleHeatmapFn,
    });

    const triggerCenter = () =>
      setShouldCenterStagePositionAndResetScaleFn(true);

    const onTriggeredCenter = () =>
      setShouldCenterStagePositionAndResetScaleFn(false);

    const resetCalculationScenariosViewing = () => {
      events.resetAgentsFn();
      events.resetScenarioChartsDataFn();
      events.resetDataForCommercialObjectsFn();
      events.resetComponentStateFn();
      events.resetPlanSettingFn();
      events.resetFloorFn();
      events.resetScenarioPlayerFn();
      events.resetPlanFn();
      events.resetListOfRulersFn();
      events.resetRulerFn();
      events.resetMovementsLogFn();
      events.resetReportStoreFn();
      events.resetHeatmapStoreFn();
    };

    return {
      getScenarioId: events.getScenarioIdFn,
      selectFloor: events.selectFloorFn,
      setScale: events.setScaleFn,
      incrementScale: events.incrementScaleFn,
      decrementScale: events.decrementScaleFn,
      setOpacity: events.setOpacityFn,
      triggerCenter,
      onTriggeredCenter,
      downloadReport: triggerDownloadReportFn,
      setPosition: setPositionFn,
      setModelBlockSize: setModelBlockSizeFn,

      resetErrors: events.resetErrorsFn,
      setPlay: events.setPlayFn,
      setSpeedDivider: events.setSpeedDividerFn,
      setWithAnimation: events.setWithAnimationFn,
      setCurrentTimeOfSimulation: events.setCurrentTimeOfSimulationFn,
      playTick: events.playTickFn,
      uploadTick: events.uploadTickFn,
      getDataForCommercialObjects: events.getDataForCommercialObjectsFn,

      getComponentState: events.getComponentStateFn,
      toggleHeatmap: events.toggleHeatmapFn,
      resetCalculationScenariosViewing,
    };
  };
