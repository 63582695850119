import { Fill, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

export const stationAccessibilityVectorStyle = () => {
  return new Style({
    image: new CircleStyle({
      radius: 4,
      fill: new Fill({
        color: '#FF0000',
      }),
    }),
  });
};
