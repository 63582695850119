import { SvgIcon, SvgIconProps } from '@mui/material';

export const SecurityCheckpointIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        d="M15.77 6.286c-.932-.933-2.41-.933-2.876-.933h-1.71c-.544 0-1.943 0-2.876.933-.933.933-.933 2.41-.933 3.343v2.643c0 1.088.466 1.477 1.477 1.477V9.63c0-.544.156-1.244.855-1.244v13.449c0 .233.156.544.389.777.389.389.855.389 1.244.389h.388v-8.085h.856V23h.388c.389 0 .855 0 1.244-.389.233-.233.31-.544.389-.777V8.385c.622 0 .855.7.855 1.244v4.12c1.01 0 1.477-.466 1.477-1.477V9.63c-.233-.933-.233-2.41-1.166-3.343Zm-3.73-1.632c1.01 0 1.865-.855 1.865-1.788 0-1.01-.855-1.866-1.866-1.866-1.01 0-1.865.855-1.865 1.866 0 1.01.777 1.788 1.865 1.788ZM1.933 1H1v22h.933V1ZM23 1h-.933v22H23V1Z"
        fill="#3679EA"
      />
      <path
        d="M18.103 11.96c0-1.477.621-2.798 1.554-3.731l-.233-.311c-1.088 1.01-1.71 2.488-1.71 4.042 0 1.633.7 3.032 1.71 4.12l.233-.31c-.933-1.011-1.554-2.333-1.554-3.81Z"
        fill="#F14848"
      />
      <path
        d="M19.269 11.96c0-1.088.388-2.02 1.088-2.72l-.233-.311c-.778.777-1.244 1.865-1.244 3.11 0 1.165.466 2.254 1.244 3.109l.233-.311c-.7-.855-1.088-1.788-1.088-2.877Z"
        fill="#F14848"
      />
      <path
        d="M20.513 11.961c0-.622.233-1.243.544-1.71l-.233-.31c-.467.543-.7 1.243-.7 2.02 0 .778.311 1.477.7 2.1l.233-.312c-.311-.544-.544-1.088-.544-1.788ZM5.898 11.961a5.286 5.286 0 0 1-1.555 3.732l.233.31c1.088-1.01 1.71-2.487 1.71-4.042 0-1.632-.7-3.032-1.71-4.12l-.233.311c1.01 1.01 1.555 2.332 1.555 3.81Z"
        fill="#F14848"
      />
      <path
        d="M4.731 11.961c0 1.088-.388 2.021-1.088 2.72l.233.312c.778-.778 1.244-1.866 1.244-3.11 0-1.166-.466-2.254-1.244-3.11l-.233.312c.7.855 1.088 1.865 1.088 2.876Z"
        fill="#F14848"
      />
      <path
        d="M3.488 11.96c0 .622-.234 1.244-.545 1.71l.234.312c.466-.545.7-1.244.7-2.022 0-.777-.312-1.477-.7-2.099l-.234.311c.311.544.545 1.166.545 1.788Z"
        fill="#F14848"
      />
    </SvgIcon>
  );
};
