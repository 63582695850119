import { getLineWidth } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { Stroke, Style } from 'ol/style';

import { TileStyleProps } from '@utils/map/styles/types';
import { createActiveFeatureBorder } from '@utils/map/tools/createActiveFeatureBorder';

const lineWidthMin = 1;
const lineWidthMax = 5;

export const railwayTileStyleFilledGray = (props: TileStyleProps) => {
  const { map, resolution, width, isActive } = props;

  const zoom = map.getView().getZoomForResolution(resolution) || 10;
  const styles = isActive ? [createActiveFeatureBorder(width || 3)] : [];

  styles.push(
    new Style({
      stroke: new Stroke({
        color: '#5A5A5A',
        width:
          width ||
          getLineWidth(zoom, [
            0,
            lineWidthMin,
            6,
            lineWidthMin,
            6.00000001,
            lineWidthMax,
          ]) ||
          1,
        lineCap: 'square',
      }),
    }),
  );

  return styles;
};
