import { IComputingRegistryComputingRegistryResultStatusChoices } from '@api/gql/types';
import { sample } from 'effector';

import {
  $FilterRegions,
  FilterRegionApi,
  UIApi,
  getComputedPassTrafficFx,
  pushPassTrafficForSelectYear,
  resetAllFilters,
} from '@features/pt-forecast/stores';
import { queryGetScenarioStatuses } from '@features/pt-forecast/stores/api/queryGetScenarioStatuses';
import { queryUpdateComputedResultsParams } from '@features/pt-forecast/stores/api/queryUpdateComputedResultsParams';
import {
  $Recalculate,
  RecalculateApi,
  default$Recalculate,
  updateComputedResultParamsFx,
  updateComputedStatusFx,
} from '@features/pt-forecast/stores/recalculate/store';
import { $Settings } from '@features/pt-forecast/stores/settings/store';

sample({
  clock: pushPassTrafficForSelectYear,
  fn: payload => {
    if (!payload) return default$Recalculate;

    return {
      price: payload.price,
      frequency: payload.frequency,
      duration: payload.duration,
      id: payload.id,
    };
  },
  target: RecalculateApi.setRecalculateParameters,
});

// Сброс параметров пересчета
sample({
  clock: resetAllFilters,
  target: RecalculateApi.resetRecalcula,
});
sample({
  clock: FilterRegionApi.reset,
  target: RecalculateApi.resetRecalcula,
});

updateComputedResultParamsFx.use(async params => {
  const result = await queryUpdateComputedResultsParams(params);
  return result;
});

sample({
  clock: updateComputedResultParamsFx.finally,
  source: { Settings: $Settings },
  fn: ({ Settings }) => ({ id: Settings.scenarioId || '' }),
  target: updateComputedStatusFx,
});

// ---------------------------------

updateComputedStatusFx.use(queryGetScenarioStatuses);

// Обновление данных сценария расчета при успешном расчете
sample({
  clock: updateComputedStatusFx.done,
  source: {
    FilterRegions: $FilterRegions,
    Settings: $Settings,
    Recalculate: $Recalculate,
  },
  filter: ({ FilterRegions, Recalculate }, payload) => {
    const status = payload.result?.resultStatus;

    if (
      status === IComputingRegistryComputingRegistryResultStatusChoices.A_1 &&
      Recalculate.resultStatus !==
        IComputingRegistryComputingRegistryResultStatusChoices.A_1 &&
      Recalculate.resultStatus !== '' &&
      FilterRegions.selectedRegionA &&
      FilterRegions.selectedRegionB
    ) {
      return true;
    }
    return false;
  },
  fn: ({ FilterRegions, Settings }) => ({
    regionFrom: FilterRegions.selectedRegionA?.id,
    regionTo: FilterRegions.selectedRegionB?.id,
    computingRegistryId: Settings.scenarioId,
  }),
  target: getComputedPassTrafficFx,
});

// Отображение уведомления о статусе расчета
sample({
  clock: updateComputedStatusFx.done,
  source: { Recalculate: $Recalculate },
  filter: ({ Recalculate }, payload) => {
    const status = payload.result?.resultStatus;

    if (
      (status === IComputingRegistryComputingRegistryResultStatusChoices.A_1 &&
        Recalculate.resultStatus !==
          IComputingRegistryComputingRegistryResultStatusChoices.A_1 &&
        Recalculate.resultStatus !== '') ||
      (status === IComputingRegistryComputingRegistryResultStatusChoices.A_2 &&
        Recalculate.resultStatus !==
          IComputingRegistryComputingRegistryResultStatusChoices.A_2 &&
        Recalculate.resultStatus !== '') ||
      (status === IComputingRegistryComputingRegistryResultStatusChoices.A_3 &&
        Recalculate.resultStatus !==
          IComputingRegistryComputingRegistryResultStatusChoices.A_3 &&
        Recalculate.resultStatus !== '')
    ) {
      return true;
    }
    return false;
  },
  fn: () => true,
  target: UIApi.setVisibleStatusRecalculation,
});

// Обновление блокировки расчета
sample({
  clock: updateComputedStatusFx.done,
  fn: payload => {
    const status = payload.result?.resultStatus;

    switch (status) {
      case IComputingRegistryComputingRegistryResultStatusChoices.A_1:
      case IComputingRegistryComputingRegistryResultStatusChoices.A_3:
      case IComputingRegistryComputingRegistryResultStatusChoices.A_4:
        return false;
      default:
        return true;
    }
  },
  target: UIApi.setDisabledRecalculation,
});

// Обновление статуса при обновлении данных расчета
sample({
  clock: RecalculateApi.setRecalculateParameters,
  source: { Settings: $Settings },
  fn: ({ Settings }) => ({ id: Settings.scenarioId || '' }),
  target: updateComputedStatusFx,
});

// Обновление статуса в сторе
sample({
  clock: updateComputedStatusFx.done,
  fn: payload => payload.result?.resultStatus || '',
  target: RecalculateApi.setStatusRecalculation,
});
