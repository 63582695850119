import { IQuery, IQueryFindRegionByPrefixArgs } from '@api/gql/types';
import { createApi, createEffect, createEvent, createStore } from 'effector';

const initState: RegionOptionsListStore = {
  options: [],
};

export const $RegionOptionsList =
  createStore<RegionOptionsListStore>(initState);

export const RegionOptionsListApi = createApi($RegionOptionsList, {
  setRegionOptions: (store, payload: RegionOptionItem[]) => ({
    ...store,
    options: payload,
  }),
  reset: () => initState,
});

export const searchRegions = createEvent<string>();
export const findRegionByPrefixFx = createEffect<
  IQueryFindRegionByPrefixArgs,
  IQuery['findRegionByPrefix']
>();

type RegionOptionsListStore = {
  options: RegionOptionItem[];
};

export type RegionOptionItem = {
  id: string;
  name: string;
};
