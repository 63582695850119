// import { $SelectedCalculationScenario } from '@features/pt-constructor/store';
import { $ListProject } from '@features/ag-projects-registry/store/lists/projects';
import { useUnit } from 'effector-react';

import { Grid, Typography } from '@mui/material';

export const Question = () => {
  const $listProject = useUnit($ListProject);

  return (
    <Grid
      display={'flex'}
      direction={'column'}
      container
      rowGap={2}
    >
      <Typography variant="body2">
        Вы действительно хотите удалить проект?
      </Typography>

      <Typography
        variant="body2"
        borderRadius={'4px'}
        bgcolor={'customs.bgHover'}
        ml={1}
        py={1}
        px={1.5}
      >
        {$listProject.projects.find(
          project => project.id === $listProject.selected[0],
        )?.name || 'Безымянный проект'}
      </Typography>

      <Typography variant="body2">Удаление будет безвозвратным.</Typography>
    </Grid>
  );
};
