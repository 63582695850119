import { createEffect, createEvent, createStore } from 'effector';

import {
  EditableObjectType,
  IBank,
  IBankomat,
  IBuffet,
  IDoor,
  IElevator,
  IExit,
  IInformationTable,
  IKiosk,
  ILadder,
  IOther,
  IPassengerGenerator,
  IPath,
  IPlatform,
  IPoint,
  IRestaurant,
  IRoom,
  ISecurityCheckpoint,
  IStore,
  ITicketOffice,
  ITicketPrintingMachine,
  IToilet,
  ITurnstile,
  IVendingMachine,
  IWaiting,
  IWall,
} from '@features/tpu-simulation-model/types';

export interface CreatedObjectDataType<
  T extends EditableObjectType = EditableObjectType,
> {
  points: IPoint[] | null;
  type: T | null;
}

export type IOnCompleteArgs = {
  name?: string;
  isClosed?: boolean;
  fillsRoom?: 'fills' | 'notFills';
};

export const $CreatedObject = createStore<CreatedObjectDataType>({
  points: null,
  type: null,
});

export const addPointFn = createEvent<IPoint>();

export const setCreatedObjectFn = createEvent<EditableObjectType | null>();

export const resetCreatedObjectDataFn = createEvent();
export const cancelCreationFn = createEvent();

export const onCompleteFn = createEvent<IOnCompleteArgs | void>();

export const createRoomFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Room>,
  IRoom | void
>();

export const createWallFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Wall> & IOnCompleteArgs,
  IWall | void
>();

export const createLadderFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Ladder>,
  ILadder | void
>();

export const createPlatformFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Platform>,
  IPlatform | void
>();

export const createDoorFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Door>,
  IDoor | void
>();

export const createElevatorFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Elevator>,
  IElevator | void
>();

export const createPathFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Path>,
  IPath | void
>();

export const createPassengerGeneratorFx = createEffect<
  CreatedObjectDataType<EditableObjectType.PassengerGenerator>,
  IPassengerGenerator | void
>();

export const createExitFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Exit>,
  IExit | void
>();

export const createTicketOfficeFx = createEffect<
  CreatedObjectDataType<EditableObjectType.TicketOffice>,
  ITicketOffice | void
>();

export const createTicketPrintingMachineFx = createEffect<
  CreatedObjectDataType<EditableObjectType.TicketPrintingMachine>,
  ITicketPrintingMachine | void
>();

export const createTurnstileFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Turnstile>,
  ITurnstile | void
>();

export const createSecurityCheckpointFx = createEffect<
  CreatedObjectDataType<EditableObjectType.SecurityCheckpoint>,
  ISecurityCheckpoint | void
>();

export const createInformationTableFx = createEffect<
  CreatedObjectDataType<EditableObjectType.InformationTable>,
  IInformationTable | void
>();

export const createToiletFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Toilet> & IOnCompleteArgs,
  IToilet | void
>();

export const createWaitingFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Waiting> & IOnCompleteArgs,
  IWaiting | void
>();

export const createRestaurantFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Restaurant> & IOnCompleteArgs,
  IRestaurant | void
>();

export const createBuffetFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Buffet> & IOnCompleteArgs,
  IBuffet | void
>();

export const createVendingMachineFx = createEffect<
  CreatedObjectDataType<EditableObjectType.VendingMachine>,
  IVendingMachine | void
>();

export const createStoreFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Store> & IOnCompleteArgs,
  IStore | void
>();

export const createKioskFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Kiosk>,
  IKiosk | void
>();
export const createBankFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Bank> & IOnCompleteArgs,
  IBank | void
>();
export const createBankomatFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Bankomat>,
  IBankomat | void
>();

export const createOtherFx = createEffect<
  CreatedObjectDataType<EditableObjectType.Other> & IOnCompleteArgs,
  IOther | void
>();

export const removeLastPointFn = createEvent<void>();
