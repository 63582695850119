import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddTpuIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="11.5"
        fill="#E21A1A"
        stroke="white"
      />
      <path
        d="M7.33301 11.9997H16.6663M11.9997 7.33301L11.9997 16.6663"
        stroke="white"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
