import { ReactNode } from 'react';

import { SxProps, Tabs as TabsMui } from '@mui/material';

interface TabsProps<V> {
  value: V;
  children: ReactNode;
  onChange: (arg: V) => void;
  sx?: SxProps;
}

export function Tabs<V>({ value, onChange, children, sx }: TabsProps<V>) {
  return (
    <TabsMui
      value={value}
      onChange={(_, value) => onChange(value)}
      variant={'fullWidth'}
      sx={{
        '& .Mui-selected': {
          backgroundColor: theme => theme.palette.primary.main,
          color: '#fff !important',
        },
        '& .MuiTab-root': {
          fontSize: theme => theme.typography.pxToRem(14),
          lineHeight: theme => theme.typography.pxToRem(16),
          textTransform: 'none',
          color: theme => theme.palette.primary.main,
          border: theme => `1px solid ${theme.palette.primary.main}`,
        },
        '& .MuiTabs-indicator': {
          display: 'none',
        },
        ...sx,
      }}
    >
      {children}
    </TabsMui>
  );
}
