import { sample } from 'effector';

import { EMapFeatureLayout } from '@constants/map';

import { VehiclesApi } from '@features/ag-project/stores/lists/vehicles';
import {
  $LayersVisible,
  $SelectedFeatures,
  LayersVisibleApi,
  SelectedFeaturesApi,
  pipeTransportDistrictsTileLayer,
} from '@features/ag-project/stores/map';
import { $FeatureSettings } from '@features/ag-project/stores/settings';
import { createAgTransportDistrictsSource } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

import {
  EPipeTileLayer,
  PipeTileLayer,
} from '@utils/map/hooks/useInitTileLayer';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload &&
    Object.keys(payload).includes(EMapFeatureLayout.transportDistricts),
  fn: payload =>
    ({
      action: EPipeTileLayer.setVisible,
      payload: payload![EMapFeatureLayout.transportDistricts],
    }) as PipeTileLayer,
  target: pipeTransportDistrictsTileLayer,
});

// Установка источника тайлов
sample({
  clock: VehiclesApi.setVehicles,
  source: {
    FeatureSettings: $FeatureSettings,
  },
  fn: ({ FeatureSettings }) => {
    if (!FeatureSettings.projectUUID) throw Error('Project UUID not found');
    return {
      action: EPipeTileLayer.changeSource,
      payload: createAgTransportDistrictsSource(FeatureSettings.projectUUID),
    } as PipeTileLayer;
  },
  target: pipeTransportDistrictsTileLayer,
});

// Применение стиля к выбранным фичам
sample({
  clock: SelectedFeaturesApi.setSelectedFeatures,
  source: {
    LayersVisible: $LayersVisible,
    SelectedFeatures: $SelectedFeatures,
  },
  filter: ({ LayersVisible }) =>
    LayersVisible[EMapFeatureLayout.transportDistricts],
  fn: ({ SelectedFeatures }) =>
    ({
      action: EPipeTileLayer.setStyle,
      payload: {
        selectedFeatureIds:
          SelectedFeatures.filter(
            item => item.layout === EMapFeatureLayout.transportDistricts,
          ).map(item => item.id) || [],
        featureIdMetaKey: 'id',
      },
    }) as PipeTileLayer,
  target: pipeTransportDistrictsTileLayer,
});
