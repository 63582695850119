export const SOC_ECO_TH_1_1 = [
  {
    id: 'tpu',
    label: 'ТПУ',
  },
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'passTraffic',
    label: 'Пассажиропоток ТПУ, тыс. чел. ',
  },
  // {
  //   id: 'zoneRadius',
  //   label: 'Радиус зоны тяготения, м.',
  // },
];
