import {
  Box,
  Button,
  Tooltip,
  Typography,
  iconButtonClasses,
} from '@mui/material';

import { BasketIcon, CheckMiniIcon, DownloadIcon } from '@components/icons';

interface ISuccess {
  disabled: boolean;
  onDownload: () => void;
  onDelete: () => void;
}

export const Success = (props: ISuccess) => {
  const { disabled, onDownload, onDelete } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        columnGap: 0.5,
      }}
    >
      <Box
        sx={{
          color: theme => theme.palette.customs.trainSpeedLocal,
          display: 'flex',
          alignItems: 'center',
          columnGap: 0.5,
        }}
      >
        <CheckMiniIcon
          sx={{
            fontSize: theme => theme.typography.pxToRem(21),
          }}
        />

        <Typography
          sx={{
            fontSize: theme => theme.typography.pxToRem(12),
          }}
        >
          Успешно
        </Typography>
      </Box>

      <Tooltip
        title={'Скачать'}
        placement="top"
      >
        <Button
          variant="text"
          sx={{
            height: '18px',
            minWidth: '15px',
          }}
          className={disabled ? iconButtonClasses.disabled : ''}
          onClick={onDownload}
        >
          <DownloadIcon
            sx={{
              cursor: 'pointer',
              fontSize: theme => theme.typography.pxToRem(21),
              ['&:hover']: {
                color: theme => theme.palette.text.secondary,
              },
            }}
          />
        </Button>
      </Tooltip>

      <Tooltip
        title={'Удалить'}
        placement="top"
      >
        <Button
          variant="text"
          sx={{
            height: '18px',
            minWidth: '15px',
          }}
          className={disabled ? iconButtonClasses.disabled : ''}
          onClick={onDelete}
        >
          <BasketIcon
            sx={{
              cursor: 'pointer',
              fontSize: theme => theme.typography.pxToRem(21),
              ['&:hover']: {
                color: theme => theme.palette.text.secondary,
              },
            }}
          />
        </Button>
      </Tooltip>
    </Box>
  );
};
