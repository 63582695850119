import { sample } from 'effector';

import {
  $ComputedData,
  ComputedDataApi,
} from '@features/pt-forecast-new/stores/computedData';
import {
  $FilterMap,
  EFilterMapLayout,
  FilterMapApi,
  VehiclesFilters,
} from '@features/pt-forecast-new/stores/filters';
import { initSources } from '@features/pt-forecast-new/stores/map';
import { $SelectedFeatures } from '@features/pt-forecast-new/stores/map/selectedFeatures';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui/store';

// Управление видимостью панели детализации прогноза
sample({
  clock: [
    ComputedDataApi.setComputedData,
    ComputedDataApi.reset,
    FilterMapApi.setLayout,
    initSources,
  ],
  source: { ComputedData: $ComputedData, FilterMap: $FilterMap },
  fn: ({ ComputedData, FilterMap }) =>
    !!ComputedData.currentData &&
    FilterMap.layout === EFilterMapLayout.PassengerFlows,
  target: UIApi.setVisibleForecastPanel,
});

// Скрытие фильтров
sample({
  clock: [ComputedDataApi.setComputedData],
  source: { ComputedData: $ComputedData },
  fn: ({ ComputedData }) => {
    if (!ComputedData.currentData) return [];
    if (ComputedData.currentData.passengerTraffic.expressTrain) return [];
    return ['expressTrain'] as Array<keyof VehiclesFilters>;
  },
  target: UIApi.setHideFilterFields,
});

// Если изменился слой отображения закрыть инспектор
sample({
  clock: FilterMapApi.setLayout,
  fn: () => false,
  target: [UIApi.setVisibleInspector, UIApi.setVisibleParamsForecastPanel],
});

// Если фича не выбрана, или выбрано несколько фич, а инспектор открыт, закрыть его
sample({
  clock: $SelectedFeatures,
  source: { UI: $UI },
  filter: ({ UI }, payload) => payload.length !== 1 && UI.panels.inspector,
  fn: () => false,
  target: UIApi.setVisibleInspector,
});

// При открытии страницы дашбордов, открыть фильтр
sample({
  clock: UIApi.setShowDashboard,
  filter: payload => payload,
  target: UIApi.setVisibleFilterPanel,
});
