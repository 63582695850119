import { sample } from 'effector';

import {
  getGraphDirectionsFx,
  getScenarioParamsFx,
  updateScenarioParamsFx,
} from '@widgets/widget-routes-params/stores/list/directionsParamsTable';
import { UIApi } from '@widgets/widget-routes-params/stores/ui/store';

// Отображать лоадер загрузки параметров направлений
sample({
  clock: getGraphDirectionsFx,
  fn: () => ({ isGetDirections: true }),
  target: UIApi.setVisible,
});

// Скрытие лоадера загрузки параметров направлений
sample({
  clock: [getScenarioParamsFx.finally, getGraphDirectionsFx.finally],
  fn: () => ({ isGetDirections: false }),
  target: UIApi.setVisible,
});

// Отображать лоадер обновления параметров направлений в кнопке сохранения, блокировка кнопки сохранить
sample({
  clock: updateScenarioParamsFx,
  fn: () => ({ isUpdateParamsDirection: true }),
  target: UIApi.setVisible,
});

// Скрытие лоадера обновления параметров направлений в кнопке сохранения, снятие блока с кнопки сохранить
sample({
  clock: updateScenarioParamsFx.finally,
  fn: () => ({ isUpdateParamsDirection: false }),
  target: UIApi.setVisible,
});

// Скрытие страницы с параметрами направлений после сохранения
sample({
  clock: updateScenarioParamsFx.finally,
  fn: () => ({ isRedirection: true }),
  target: UIApi.setVisible,
});
