export interface IRuler {
  meters: number;
  px: number;
}

export interface IPoint {
  x: number;
  y: number;
}
export interface IShape extends IPoint {
  width: number;
  height: number;
  rotation: number;
}
export enum EditableObjectType {
  Room = 'ROOM',
  Wall = 'WALL',
  Ladder = 'LADDER',
  Platform = 'PLATFORM',
  Door = 'DOOR',
  Elevator = 'ELEVATOR',
  Path = 'PATH',
  PassengerGenerator = 'PASSENGER_GENERATOR',
  Exit = 'EXIT',
  TicketOffice = 'TICKET_OFFICE',
  TicketPrintingMachine = 'TICKET_PRINTING_MACHINE',
  Turnstile = 'TURNSTILE',
  SecurityCheckpoint = 'SECURITY_CHECKPOINT',
  InformationTable = 'INFORMATION_TABLE',
  Toilet = 'TOILET',
  Waiting = 'WAITING',
  Restaurant = 'RESTAURANT',
  Buffet = 'BUFFET',
  VendingMachine = 'VENDING_MACHINE',
  Store = 'STORE',
  Kiosk = 'KIOSK',
  Bank = 'BANK',
  Bankomat = 'BANKOMAT',
  Other = 'OTHER',
}

export interface BaseObjectOfTheSimulationModel {
  uuid: string;
}

export type TypeOfFlexibleObject =
  | EditableObjectType.Restaurant
  | EditableObjectType.PassengerGenerator
  | EditableObjectType.Exit
  | EditableObjectType.Buffet
  | EditableObjectType.Bankomat
  | EditableObjectType.Kiosk
  | EditableObjectType.Bank
  | EditableObjectType.Other
  | EditableObjectType.VendingMachine
  | EditableObjectType.Store
  | EditableObjectType.Waiting
  | EditableObjectType.TicketOffice
  | EditableObjectType.TicketPrintingMachine
  | EditableObjectType.Turnstile
  | EditableObjectType.SecurityCheckpoint
  | EditableObjectType.InformationTable
  | EditableObjectType.Toilet;

export interface FlexibleObject<T extends EditableObjectType>
  extends BaseObjectOfTheSimulationModel {
  point: IPoint;
  width: number;
  height: number;
  rotation: number;
  type: T;
}
export interface IRoom extends BaseObjectOfTheSimulationModel {
  points: IPoint[];
  name: string;
  walls: string[];
  type: EditableObjectType.Room;
}
export interface IWall extends BaseObjectOfTheSimulationModel {
  name: string;
  points: IPoint[];
  isClosed: boolean;
  type: EditableObjectType.Wall;
}
export interface ILadder extends FlexibleObject<EditableObjectType.Ladder> {
  name: string;
  room: string;
  connectedFloors: string[];
  connectedObjects: string[];
  pedestrianSpeed: number;
  status: 'open' | 'close';
  direction: DoorDirections;
  openFrom: string;
  openUntil: string;
  width: number;
  height: number;
  length: number;
  floorId: string;
  type: EditableObjectType.Ladder;
}
export interface IPlatform extends BaseObjectOfTheSimulationModel {
  platform: number;
  point: IPoint;
  width: number;
  height: number;
  rotation: number;
  walls: string[];
  type: EditableObjectType.Platform;
}
export type DoorDirections = 'in' | 'out' | 'inAndOut';
export interface IDoor extends FlexibleObject<EditableObjectType.Door> {
  name: string;
  status: 'open' | 'close';
  firstRoom: string;
  secondRoom: string;
  direction: DoorDirections;
  openFrom: string;
  openUntil: string;
}
export interface IElevator extends FlexibleObject<EditableObjectType.Elevator> {
  name: string;
  room: string;
  connectedFloors: string[];
  connectedObjects: string[];
  direction: DoorDirections;
  openFrom: string;
  openUntil: string;
  status: 'open' | 'close';
  waitingZone: 'exist' | 'notExist';
  point: IPoint;
  width: number;
  height: number;
  floorId: string;
  type: EditableObjectType.Elevator;
}
export interface ITicketOffice
  extends FlexibleObject<EditableObjectType.TicketOffice> {
  name: string;
  room: string;
  numberOfWindowsQueues: number;
  passengersType: 'longDistance' | 'commuter';
  averageServiceTime: number;
  failuresProbability: number;
  status: 'open' | 'close';
  waitingZone: 'exist' | 'notExist';
  openFrom: string;
  openUntil: string;
}
export interface ITicketPrintingMachine
  extends FlexibleObject<EditableObjectType.TicketPrintingMachine> {
  name: string;
  room: string;
  averageServiceTime: number;
  failuresProbability: number;
  status: 'open' | 'close';
  waitingZone: 'exist' | 'notExist';
  openFrom: string;
  openUntil: string;
}
export interface ITurnstile
  extends FlexibleObject<EditableObjectType.Turnstile> {
  turnstileId: string;
  name: string;
  firstRoom: string;
  secondRoom: string;
  throughput: number;
  failuresProbability: number;
  status: 'open' | 'close';
  waitingZone: 'exist' | 'notExist';
  openFrom: string;
  openUntil: string;
  direction: DoorDirections;
}
export interface ISecurityCheckpoint
  extends FlexibleObject<EditableObjectType.SecurityCheckpoint> {
  name: string;
  firstRoom: string;
  secondRoom: string;
  status: 'open' | 'close';
  waitingZone: 'exist' | 'notExist';
  failuresProbability: number;
  openFrom: string;
  openUntil: string;
  direction: DoorDirections;
  throughput: number;
}
export interface IInformationTable
  extends FlexibleObject<EditableObjectType.InformationTable> {
  name: string;
  room: string;
  averageServiceTime: number;
  status: 'open' | 'close';
  waitingZone: 'exist' | 'notExist';
}
export interface IToilet extends FlexibleObject<EditableObjectType.Toilet> {
  name: string;
  room: string;
  servedAtSameTime: number;
  status: 'open' | 'close';
  waitingZone: 'exist' | 'notExist';
  openFrom: string;
  openUntil: string;
  fillsRoom: 'fills' | 'notFills';
}
export interface IWaiting extends FlexibleObject<EditableObjectType.Waiting> {
  name: string;
  room: string;
  servedAtSameTime: number;
  averageRevenue: number;
  status: 'open' | 'close';
  waitingZone: 'exist' | 'notExist';
  openFrom: string;
  openUntil: string;
  fillsRoom: 'fills' | 'notFills';
}
export interface IRestaurant
  extends FlexibleObject<EditableObjectType.Restaurant> {
  name: string;
  room: string;
  servedAtSameTime: number;
  averageServiceTime: number;
  averageRevenue: number;
  averageCostPerDay: number;
  bounceRate: number;
  openFrom: string;
  openUntil: string;
  status: 'open' | 'close';
  fillsRoom: 'fills' | 'notFills';
}
export interface IBuffet extends FlexibleObject<EditableObjectType.Buffet> {
  name: string;
  room: string;
  servedAtSameTime: number;
  averageServiceTime: number;
  averageRevenue: number;
  averageCostPerDay: number;
  bounceRate: number;
  openFrom: string;
  openUntil: string;
  status: 'open' | 'close';
  waitingZone: 'exist' | 'notExist';
  fillsRoom: 'fills' | 'notFills';
}
export interface IVendingMachine
  extends FlexibleObject<EditableObjectType.VendingMachine> {
  name: string;
  room: string;
  averageServiceTime: number;
  averageRevenue: number;
  averageCostPerDay: number;
  bounceRate: number;
  status: 'open' | 'close';
  openFrom: string;
  openUntil: string;
  waitingZone: 'exist' | 'notExist';
}
export interface IStore extends FlexibleObject<EditableObjectType.Store> {
  name: string;
  room: string;
  servedAtSameTime: number;
  averageServiceTime: number;
  averageRevenue: number;
  averageCostPerDay: number;
  bounceRate: number;
  openFrom: string;
  openUntil: string;
  status: 'open' | 'close';
  fillsRoom: 'fills' | 'notFills';
}
export interface IKiosk extends FlexibleObject<EditableObjectType.Kiosk> {
  name: string;
  room: string;
  servedAtSameTime: number;
  averageServiceTime: number;
  averageRevenue: number;
  averageCostPerDay: number;
  bounceRate: number;
  openFrom: string;
  openUntil: string;
  status: 'open' | 'close';
  waitingZone: 'exist' | 'notExist';
}
export interface IBank extends FlexibleObject<EditableObjectType.Bank> {
  name: string;
  room: string;
  servedAtSameTime: number;
  averageServiceTime: number;
  averageRevenue: number;
  averageCostPerDay: number;
  bounceRate: number;
  openFrom: string;
  openUntil: string;
  status: 'open' | 'close';
  fillsRoom: 'fills' | 'notFills';
}
export interface IBankomat extends FlexibleObject<EditableObjectType.Bankomat> {
  name: string;
  room: string;
  averageServiceTime: number;
  averageRevenue: number;
  averageCostPerDay: number;
  bounceRate: number;
  openFrom: string;
  openUntil: string;
  status: 'open' | 'close';
  waitingZone: 'exist' | 'notExist';
}
export interface IOther extends FlexibleObject<EditableObjectType.Other> {
  name: string;
  room: string;
  servedAtSameTime: number;
  averageServiceTime: number;
  averageRevenue: number;
  averageCostPerDay: number;
  bounceRate: number;
  openFrom: string;
  openUntil: string;
  status: 'open' | 'close';
  fillsRoom: 'fills' | 'notFills';
}
export interface IPassengerGenerator
  extends FlexibleObject<EditableObjectType.PassengerGenerator> {
  entranceId: string;
  status: 'open' | 'close';
  openFrom: string;
  name: string;
  openUntil: string;
  share: number;
}
export interface IPath extends FlexibleObject<EditableObjectType.Path> {
  way: number;
  platforms: number[];
}
export interface IExit extends FlexibleObject<EditableObjectType.Exit> {
  status: 'open' | 'close';
  openFrom: string;
  name: string;
  openUntil: string;
  exitId: string;
  share: number;
}

export type FlexibleObjects =
  | IPassengerGenerator
  | IExit
  | ITicketOffice
  | ITicketPrintingMachine
  | ITurnstile
  | ISecurityCheckpoint
  | IInformationTable
  | IToilet
  | IWaiting
  | IRestaurant
  | IBuffet
  | IBankomat
  | IKiosk
  | IBank
  | IOther
  | IVendingMachine
  | IStore
  | IDoor
  | IElevator;

export type Portal =
  | ILadder
  | IDoor
  | IElevator
  | ITurnstile
  | ISecurityCheckpoint;

export type ObjectOfTheSimulationModel =
  | IWall
  | IRoom
  | IPath
  | IPlatform
  | FlexibleObjects
  | ILadder;

export type ObjectsWithRoomField =
  | ITicketPrintingMachine
  | ITicketOffice
  | IInformationTable
  | IToilet
  | IWaiting
  | IRestaurant
  | IBuffet
  | IVendingMachine
  | IStore
  | IKiosk
  | IBank
  | IBankomat
  | IOther;

/** Ключ - id объекта имит. модели */
export type ListOfObjectsDataType = Record<string, ObjectOfTheSimulationModel>;

/** Ключ - id этажа */
export type ListOfFloorsWithListOfObjectsDataType = Record<
  string,
  ListOfObjectsDataType
>;

export type ListOfRulersDataType = Record<string, IRuler | null>;

export interface SimulationModelPlanData {
  ruler: ListOfRulersDataType | null;
  plan: ListOfFloorsWithListOfObjectsDataType;
}

export interface SaveSimulationModelPlanArgs {
  ruler: ListOfRulersDataType | null;
  plan: ListOfFloorsWithListOfObjectsDataType;
  id: string;
}

export const RULER = 'RULER';

export type Ruler = typeof RULER;

export enum ActionBeforeOpenErrorsWindow {
  CheckErrors,
  Save,
  GoToScenarioRegistry,
  GoToPassengerTypesEditor,
  GoToTrainSchedule,
}

export const enum ActionEnum {
  ADD = 'add',
  REMOVE = 'remove',
}

export interface IListOfObjectsSortedByType {
  [EditableObjectType.Room]: IRoom[];
  [EditableObjectType.Wall]: IWall[];
  [EditableObjectType.Door]: IDoor[];
  [EditableObjectType.Platform]: IPlatform[];
  [EditableObjectType.Ladder]: ILadder[];
  [EditableObjectType.Elevator]: IElevator[];
  [EditableObjectType.PassengerGenerator]: IPassengerGenerator[];
  [EditableObjectType.TicketOffice]: ITicketOffice[];
  [EditableObjectType.TicketPrintingMachine]: ITicketPrintingMachine[];
  [EditableObjectType.Turnstile]: ITurnstile[];
  [EditableObjectType.SecurityCheckpoint]: ISecurityCheckpoint[];
  [EditableObjectType.InformationTable]: IInformationTable[];
  [EditableObjectType.Toilet]: IToilet[];
  [EditableObjectType.Waiting]: IWaiting[];
  [EditableObjectType.Restaurant]: IRestaurant[];
  [EditableObjectType.Buffet]: IBuffet[];
  [EditableObjectType.VendingMachine]: IVendingMachine[];
  [EditableObjectType.Store]: IStore[];
  [EditableObjectType.Kiosk]: IKiosk[];
  [EditableObjectType.Bank]: IBank[];
  [EditableObjectType.Bankomat]: IBankomat[];
  [EditableObjectType.Other]: IOther[];
  [EditableObjectType.Exit]: IExit[];
  [EditableObjectType.Path]: IPath[];
}
