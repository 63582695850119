import { apiClient } from '@api/client';
import { IQuery } from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import {
  TimeSlotOption,
  TimeSlotsApi,
  fetchTimeSlotsFx,
} from '@features/ag-forecast/stores/lists/timeSlots/store';

fetchTimeSlotsFx.use(async () => {
  const response = await apiClient.query<Pick<IQuery, 'timeSlots'>>({
    query: gql`
      query QueryTimeSlots {
        timeSlots {
          id
          name
        }
      }
    `,
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data.timeSlots || null;
});

sample({
  clock: fetchTimeSlotsFx.done,
  fn: request => {
    return (
      (request.result?.map(timeSlot => ({
        id: timeSlot?.id || '',
        name: timeSlot?.name || '',
      })) as TimeSlotOption[]) || []
    );
  },
  target: TimeSlotsApi.setTimeSlots,
});
