import { useCallback, useMemo } from 'react';
import {
  Cell,
  Pie,
  PieChart as RePieChart,
  Tooltip,
  TooltipProps,
} from 'recharts';
import type { Props } from 'recharts/types/polar/Pie';

import { Box } from '@mui/material';

import { palette } from '@system/theme/palette';

import { DataInfo } from './DataInfo';

export const Chart = ({ data, totalInfo, sizeParams }: ChartProps) => {
  const CustomTooltip = useCallback(
    ({ active, payload }: TooltipProps<number, string>) => {
      if (!active || !payload?.length) {
        return null;
      }

      const data = payload[0].payload.payload as Data;

      return (
        <Box
          sx={{
            backgroundColor: 'white',
            boxShadow: '0px 2px 4px 0px #0000001A',
            borderRadius: '8px',
            padding: '12px',
          }}
        >
          <DataInfo
            value={data.value}
            color={data.color}
            label={data.label}
            reverse
          />
        </Box>
      );
    },
    [],
  );

  const displayTotalValue = useMemo(() => {
    return totalInfo.totalValue.toString().replace('.', ',') || 0;
  }, [totalInfo.totalValue]);

  const ceilData = useMemo(() => {
    return data.map(i => ({ ...i, value: i.value && Math.ceil(i.value) }));
  }, [data]);

  return (
    <RePieChart
      width={sizeParams.width}
      height={sizeParams.height}
    >
      <Pie
        {...sizeParams}
        outerRadius={sizeParams.outerRadius}
        innerRadius={sizeParams.innerRadius}
        paddingAngle={sizeParams.paddingAngle}
        cx="50%"
        cy="50%"
        data={ceilData}
        dataKey="value"
      >
        {data.map(({ color }, i) => (
          <Cell
            key={i}
            fill={color}
            style={{ outline: 'none' }}
          />
        ))}
      </Pie>

      {/* Информация в центре */}
      <circle
        key="circle"
        cx={'50%'}
        cy={'50%'}
        r={sizeParams.innerRadius}
        fill={'transparent'}
        stroke="none"
      />
      <text
        key="value"
        x="50%"
        y="50%"
        color={palette.text.primary}
        fontSize="16px"
        textAnchor="middle"
        fontWeight="700"
        dy="0px"
      >
        {displayTotalValue}
      </text>
      <text
        key="label"
        x="50%"
        y="50%"
        color={palette.text.secondary}
        fontSize="14px"
        textAnchor="middle"
        fontWeight="400"
        dy="16px"
      >
        {totalInfo.totalLabel}
      </text>

      <Tooltip content={CustomTooltip} />
    </RePieChart>
  );
};

export interface ChartProps {
  data: Data[];
  totalInfo: TotalInfo;
  sizeParams: Pick<Props, 'outerRadius' | 'innerRadius' | 'paddingAngle'> & {
    width: number;
    height: number;
  };
}

export interface Data {
  label: string;
  value: number | null;
  color: string;
}

interface TotalInfo {
  totalValue: number | string;
  totalLabel: string;
}
