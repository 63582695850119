import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { Box, Button, Stack } from '@mui/material';

import { MapButton } from '@components/Map/MapButton';
import { EditIcon, LayersIcon, MinusIcon, PlusIcon } from '@components/icons';

import { EditeTpuToolbar } from '@features/ag-forecast/components/EditTpuToolbar/EditeTpuToolbar';
import { MapLegend } from '@features/ag-forecast/components/MapLegend/MapLegend';
import { PassFlowTable } from '@features/ag-forecast/components/PassFlowTable/PassFlowTable';
import { EMapViewMode } from '@features/ag-forecast/constants/EMapViewMode';
import { EAgScenarioStatusType } from '@features/ag-forecast/constants/agScenarioStatuses';
import { $PassFlowTableContent } from '@features/ag-forecast/stores/lists/passFlowTableContent';
import { $MapStore, pipeMapControls } from '@features/ag-forecast/stores/map';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';
import { $UI, UIApi } from '@features/ag-forecast/stores/ui';

import { EPipeMapControls } from '@utils/map/hooks/useInitMapControls';

export const MapControlsContainer = () => {
  const {
    mapLegend,
    inspector,
    filter,
    editTPUMode,
    viewMode,
    isShowPassFlowTable,
  } = useUnit($UI);
  const { toggleMapLegend, setEditTPUMode, setShowPassFlowTable } =
    useUnit(UIApi);
  const mapControls = useUnit(pipeMapControls);
  const { isBusy } = useUnit($MapStore);
  const { rows } = useUnit($PassFlowTableContent);
  const { scenarioStatus } = useUnit($FeatureSettings);

  const isRecalculation =
    scenarioStatus === EAgScenarioStatusType.Queue ||
    scenarioStatus === EAgScenarioStatusType.Pending;

  const onClickZoomIn = useCallback(() => {
    mapControls({ action: EPipeMapControls.touchZoom, payload: 'in' });
  }, [mapControls]);

  const onClickZoomOut = useCallback(() => {
    mapControls({ action: EPipeMapControls.touchZoom, payload: 'out' });
  }, [mapControls]);

  const onClickOnTPUEditMode = useCallback(() => {
    setEditTPUMode(true);
  }, [setEditTPUMode]);

  const onClickShowPassFlowTable = useCallback(() => {
    setShowPassFlowTable(true);
  }, [setShowPassFlowTable]);

  const onClickHidePassFlowTable = useCallback(() => {
    setShowPassFlowTable(false);
  }, [setShowPassFlowTable]);

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          left: theme => theme.typography.pxToRem(filter ? 500 + 24 : 24),
          top: theme => theme.typography.pxToRem(24),
          zIndex: 1,
          boxShadow: '0px 0px 8px 0px #00000026',
        }}
      >
        {viewMode === EMapViewMode.infrastructure && (
          <>
            {editTPUMode ? (
              <EditeTpuToolbar
                width={`calc(100vw - ${filter ? '550px' : '50px'} - ${
                  inspector ? '500px' : '1px'
                })`}
              />
            ) : (
              <Button
                onClick={onClickOnTPUEditMode}
                disabled={isBusy || isRecalculation}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                >
                  <EditIcon />
                  <span>Редактировать инфраструктуру</span>
                </Stack>
              </Button>
            )}
          </>
        )}
      </Box>

      <Button
        variant={'outlined'}
        sx={{
          marginBottom: theme => theme.typography.pxToRem(8),
          border: theme => '1px solid ' + theme.palette.text.disabled,
          gap: 1,
          position: 'absolute',
          top: theme => theme.typography.pxToRem(24 + (editTPUMode ? 108 : 0)),
          right: theme => theme.typography.pxToRem(inspector ? 500 + 24 : 24),
          boxShadow: '0px 0px 8px 0px #00000026',
          zIndex: 1,
          '&:hover': {
            backgroundColor: theme => theme.palette.common.white,
            border: theme => '1px solid ' + theme.palette.text.disabled,
            boxShadow:
              '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
          },
        }}
        onClick={toggleMapLegend}
      >
        <LayersIcon />
        Условные обозначения
      </Button>

      <MapButton
        sx={{
          position: 'absolute',
          top: theme =>
            theme.typography.pxToRem(24 + 44 + 16 + (editTPUMode ? 108 : 0)),
          right: theme => theme.typography.pxToRem(inspector ? 500 + 24 : 24),
          boxShadow: '0px 0px 8px 0px #00000026',
          zIndex: 1,
        }}
        onClick={onClickZoomIn}
      >
        <PlusIcon />
      </MapButton>

      <MapButton
        sx={{
          position: 'absolute',
          top: theme =>
            theme.typography.pxToRem(24 + 88 + 24 + (editTPUMode ? 108 : 0)),
          right: theme => theme.typography.pxToRem(inspector ? 500 + 24 : 24),
          boxShadow: '0px 0px 8px 0px #00000026',
          zIndex: 1,
        }}
        onClick={onClickZoomOut}
      >
        <MinusIcon />
      </MapButton>

      {mapLegend && <MapLegend />}

      {viewMode === EMapViewMode.passFlow && !isShowPassFlowTable && (
        <Button
          sx={{
            margin: 'auto',
            width: '200px',
            border: 'none',
            position: 'absolute',
            bottom: theme => theme.typography.pxToRem(filter ? 96 : 16),
            right: inspector ? 500 : 0,
            left: filter ? 500 : 0,
            boxShadow: '0px 0px 8px 0px #00000026',
            zIndex: 1,
          }}
          disabled={!rows.length}
          onClick={onClickShowPassFlowTable}
        >
          Открыть таблицу
        </Button>
      )}

      {viewMode === EMapViewMode.passFlow && isShowPassFlowTable && (
        <Button
          sx={{
            margin: 'auto',
            width: '200px',
            border: 'none',
            position: 'absolute',
            bottom: theme =>
              theme.typography.pxToRem(filter ? 96 + 265 : 16 + 265),
            right: inspector ? 500 : 0,
            left: filter ? 500 : 0,
            boxShadow: '0px 0px 8px 0px #00000026',
            zIndex: 1,
          }}
          onClick={onClickHidePassFlowTable}
        >
          Скрыть таблицу
        </Button>
      )}

      {isShowPassFlowTable && (
        <Box
          sx={{
            position: 'absolute',
            left: filter ? 500 : 0,
            right: inspector ? 500 : 0,
            bottom: 96,
            margin: 'auto',
            zIndex: 1,
            width: `calc(100vw - ${filter ? 550 : 50}px - ${
              inspector ? 500 : 0
            }px)`,
          }}
        >
          <PassFlowTable />
        </Box>
      )}
    </>
  );
};
