import { useMemo } from 'react';

import { Divider } from '@mui/material';

import { InspectorCircleIcon } from '@components/InspectorCircleIcon/InspectorCircleIcon';
import { MapFilterField } from '@components/MapFilters';

import { AccordionInspector } from '@features/passenger-traffic/components/Inspector/AccordionInspector';
import { ContainerTransport } from '@features/passenger-traffic/components/Inspector/ContainerTransport';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { DataVehicleType } from '@features/passenger-traffic/stores/inspector/RouteNetwork';

import { endingCountingCouple } from '@utils/dataForInspector';

type AutoInspectorProps = {
  layout: EMapFeatureLayout | null;
  filter: MapFilterField;
  busData: DataVehicleType;
  autoData: Omit<DataVehicleType, 'frequency'>;
};

export const AutoInspector = (props: AutoInspectorProps) => {
  const { layout, filter, busData, autoData } = props;

  const layoutContent = useMemo(() => {
    return (
      <>
        {filter.bus && (
          <ContainerTransport
            title="Автобус"
            icon={{
              borderColor: 'auto',
              backgroundColor: 'auto',
            }}
            price={busData?.price}
            time={busData?.duration}
            isExists={
              !!(busData?.price || busData?.duration || busData?.frequency)
            }
          >
            {busData?.frequency ? (
              <>
                {`${Math.ceil(busData?.frequency)} пар${endingCountingCouple(
                  Math.ceil(busData?.frequency),
                )} рейсов в сутки`}
              </>
            ) : (
              'н/д'
            )}
          </ContainerTransport>
        )}

        {filter.bus && filter.auto && <Divider sx={{ my: 2 }} />}

        {filter.auto && (
          <ContainerTransport
            title="Личный транспорт"
            icon={{
              borderColor: 'expressways',
              backgroundColor: 'expressways',
            }}
            price={autoData?.price}
            time={autoData?.duration}
            isExists={!!(autoData?.price || autoData?.duration)}
          />
        )}
      </>
    );
  }, [
    filter.bus,
    filter.auto,
    busData?.price,
    busData?.duration,
    busData?.frequency,
    autoData?.price,
    autoData?.duration,
  ]);

  const renderAutoInspector = useMemo(() => {
    if (layout === null && filter.autoRoot) {
      return (
        <AccordionInspector
          icon={
            <InspectorCircleIcon
              borderColor={'auto'}
              backgroundColor={'auto'}
            />
          }
          title={'Автомобильный транспорт'}
        >
          {layoutContent}
        </AccordionInspector>
      );
    } else if (
      (layout === EMapFeatureLayout.auto ||
        layout === EMapFeatureLayout.highway) &&
      filter.autoRoot
    ) {
      return layoutContent;
    }
  }, [filter.autoRoot, layout, layoutContent]);

  return renderAutoInspector;
};
