import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddRailwayGraph = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={25}
      height={26}
      viewBox="0 0 25 26"
      fill="none"
      {...props}
    >
      <circle
        cx="12.5"
        cy="13"
        r="11.5"
        fill="#E21A1A"
        stroke="white"
      />
      <rect
        x="12.9531"
        y="0.925537"
        width="1"
        height="22"
        transform="rotate(26.5997 12.9531 0.925537)"
        fill="white"
      />
      <rect
        x="21"
        y="4.95532"
        width="1"
        height="22"
        transform="rotate(26.5997 21 4.95532)"
        fill="white"
      />
      <rect
        x="13.3984"
        y="4.50354"
        width="1"
        height="6"
        transform="rotate(-63.4003 13.3984 4.50354)"
        fill="white"
      />
      <rect
        x="11.6094"
        y="8.08008"
        width="1"
        height="6"
        transform="rotate(-63.4003 11.6094 8.08008)"
        fill="white"
      />
      <rect
        x="9.81641"
        y="11.6567"
        width="1"
        height="6"
        transform="rotate(-63.4003 9.81641 11.6567)"
        fill="white"
      />
      <rect
        x="8.02734"
        y="15.2334"
        width="1"
        height="6"
        transform="rotate(-63.4003 8.02734 15.2334)"
        fill="white"
      />
      <rect
        x="6.23438"
        y="18.8099"
        width="1"
        height="6"
        transform="rotate(-63.4003 6.23438 18.8099)"
        fill="white"
      />
    </SvgIcon>
  );
};
