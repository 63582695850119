import { sample } from 'effector';

import {
  PassTrafficByAgeApi,
  PassTrafficByAgeStore,
} from '@features/pt-forecast/stores/charts/passTrafficByAge/store';
import { getComputedPassTrafficFx } from '@features/pt-forecast/stores/computed/store';
import { FilterRegionApi } from '@features/pt-forecast/stores/filterRegion/store';
import { resetAllFilters } from '@features/pt-forecast/stores/filters/store';

// Запись пасс потока по возростным группам в стор
sample({
  clock: getComputedPassTrafficFx.done,
  fn: payload =>
    payload.result?.map(item => ({
      ...item?.passengerTrafficByAge,
      year: item?.year,
    })) as PassTrafficByAgeStore,
  target: PassTrafficByAgeApi.setPassTrafficByAge,
});

// Сброс данных при сбросе фильтров
sample({
  clock: [resetAllFilters, FilterRegionApi.reset],
  target: PassTrafficByAgeApi.reset,
});
