import { Stroke, Style } from 'ol/style';

export const pathPassengerFlowTileStyle = () => {
  return new Style({
    stroke: new Stroke({
      color: 'rgb(255,255,255)',
      width: 4,
    }),
  });
};
