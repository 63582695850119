import { TransportProject } from '@features/pt-constructor/store';
import { useCallback, useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';

export const TwoStep = ({
  transportProjects,
  transportName,
  onChangeTransportProject,
}: Props) => {
  const [transportProjectsList, setTransportProjectsList] = useState<
    TransportProject[]
  >([]);
  const [selectedTransportProjects, setSelectedTransportProjects] = useState<
    TransportProject[]
  >([]);

  const onSelectTransportProject = useCallback(
    (newProject: TransportProject) => () => {
      const isSelectedProject = selectedTransportProjects.find(
        e => e.id === newProject.id,
      );

      if (isSelectedProject) {
        setSelectedTransportProjects(prev =>
          prev.filter(project => project.id !== newProject.id),
        );
      } else {
        setSelectedTransportProjects(prev => [...prev, newProject]);
      }
    },
    [selectedTransportProjects],
  );

  const onSearchScenario = useCallback<NonNullable<TextFieldProps['onChange']>>(
    e => {
      const value = (e.target as HTMLInputElement).value;

      if (value) {
        const containsScenarios = transportProjects.filter(({ name }) =>
          name.toLowerCase().includes(value.toLowerCase()),
        );

        setTransportProjectsList(containsScenarios);
      } else {
        setTransportProjectsList(transportProjects);
      }
    },
    [transportProjects],
  );

  const onChangeTransportProjectYear = useCallback<
    (project: TransportProject) => NonNullable<TextFieldProps['onChange']>
  >(
    project => e => {
      const year = (e.target as HTMLInputElement).value;

      if (year && year.length === 4) {
        setSelectedTransportProjects(prev =>
          prev.map(prevProject => {
            if (prevProject.id === project.id) {
              return { ...prevProject, year: Number(year) };
            }

            return prevProject;
          }),
        );
      }
    },
    [],
  );

  const onSelectAll = useCallback<
    NonNullable<FormControlLabelProps['onChange']>
  >(
    (_e, checked) => {
      setSelectedTransportProjects(checked ? transportProjectsList : []);
    },
    [transportProjectsList],
  );

  useEffect(() => {
    setTransportProjectsList(transportProjects);
  }, [transportProjects]);

  useEffect(() => {
    onChangeTransportProject(selectedTransportProjects);
  }, [onChangeTransportProject, selectedTransportProjects]);

  // useEffect(() => {
  //   if (optionTransportProjects) {
  //     setSelectedTransportProjects(optionTransportProjects);
  //   }
  // }, [optionTransportProjects]);

  return (
    <>
      <Typography variant="subtitle2">{transportName}</Typography>

      <Stack
        gap={2}
        flexDirection={'row'}
        alignItems={'flex-end'}
        my={3}
      >
        <TextField
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={onSearchScenario}
          placeholder="Найти проект"
        />

        {/* <Button color={'secondary'}>Добавить проект</Button> */}
      </Stack>

      <List
        sx={{
          padding: 0,
        }}
      >
        <ListItem
          disablePadding
          sx={{
            mb: 0.5,
          }}
        >
          <FormControlLabel
            control={<Checkbox />}
            label={'Выбрать все'}
            onChange={onSelectAll}
            sx={{
              color: 'customs.link',
            }}
          />

          <ListItemSecondaryAction>
            <Typography variant={'body2'}>{'Год ввода в экспл.'}</Typography>
          </ListItemSecondaryAction>
        </ListItem>

        {transportProjectsList.map(project => {
          const labelId = `checkbox-list-label-${project.id}`;
          const selected = Boolean(
            selectedTransportProjects.find(e => e.id === project.id),
          );

          return (
            <ListItem
              key={project.id}
              sx={{ justifyContent: 'space-between', mb: '2px' }}
              disablePadding
            >
              <FormControlLabel
                id={labelId}
                control={<Checkbox checked={selected} />}
                label={project.name}
                onChange={onSelectTransportProject(project)}
                sx={{
                  ml: 0,
                }}
              />

              <TextField
                type="number"
                disabled={!selected}
                defaultValue={project.year}
                sx={{ width: '138px', minWidth: '138px', maxWidth: '138px' }}
                onChange={onChangeTransportProjectYear(project)}
                onInput={e =>
                  ((e.target as HTMLInputElement).value = Math.max(
                    0,
                    parseInt((e.target as HTMLInputElement).value),
                  )
                    .toString()
                    .slice(0, 4))
                }
                required
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

interface Props {
  transportName: string;
  transportProjects: TransportProject[];
  optionTransportProjects: TransportProject[] | undefined;
  onChangeTransportProject: (transportProjects: TransportProject[]) => void;
}
