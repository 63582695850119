import { SvgIcon, SvgIconProps } from '@mui/material';

export const TrainUnfillIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        d="m11.542 14.827 2.604-2.604h4.669V5.49c0-.987 0-2.514-.988-3.501C16.84 1 15.403 1 14.864 1H8.22c-.539 0-1.975 0-2.963.988-.988.987-.988 2.514-.988 3.501v6.734h4.67l2.603 2.604Zm0-13.109c.808 0 1.437.629 1.437 1.437S12.35 4.59 11.542 4.59a1.418 1.418 0 0 1-1.437-1.436c0-.808.629-1.437 1.437-1.437ZM5.706 6.477c0-.27 0-.628.27-.898.269-.27.628-.27.897-.27h9.428c.27 0 .628 0 .898.27s.27.629.27.898v3.86H5.795v-3.86h-.09Zm10.595 12.48a2.829 2.829 0 0 0 1.526-.808c.988-.987.988-2.514.988-3.501V13.03h-4.31l-2.963 2.963-2.963-2.963H4.27v1.617c0 .987 0 2.514.988 3.501a2.83 2.83 0 0 0 1.526.808L4 22.997c1.167 0 1.616.09 2.155-.897l1.077-1.616h8.62l1.077 1.616c.54.808.898.808 2.155.808l-2.783-3.95Zm-9.158-1.616a1.418 1.418 0 0 1-1.437-1.436c0-.808.628-1.437 1.437-1.437.808 0 1.436.629 1.436 1.437s-.628 1.436-1.436 1.436Zm7.272-1.436c0-.808.629-1.437 1.437-1.437s1.436.629 1.436 1.437-.628 1.436-1.436 1.436a1.418 1.418 0 0 1-1.437-1.436Z"
        fill="#273B48"
      />
    </SvgIcon>
  );
};
