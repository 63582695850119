import { ISimulationType } from '@api/gql/tpu-types';

import { ListOfFloorsWithListOfObjectsDataType } from '@features/tpu-simulation-model/types/simultaionModel';

import { DataForCommercialObjects } from '../stores/chartsCommercialObjects';

const sortCommercialObjectsByName = (
  a: DataForCommercialObjects,
  b: DataForCommercialObjects,
) => {
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;

  return 0;
};

export const parseDataForCommercialObjects = (
  data: ISimulationType | null,
  planData: ListOfFloorsWithListOfObjectsDataType | null,
) => {
  if (!planData || !data) return null;

  const { objectResults } = data;
  const objectResultsItem = objectResults ? objectResults[0] : null;

  const plan = new Map(
    Object.entries(planData).flatMap(([, floorObjects]) => {
      return Object.values(floorObjects).map(item => [
        item.uuid,
        {
          name: 'name' in item ? item.name : 'не обнаружено',
          type: item.type,
        },
      ]);
    }),
  );

  const items: DataForCommercialObjects[] = [];

  objectResultsItem?.treeMapResults.forEach(treeMapItem => {
    const currentObjectFromPlan = plan.get(treeMapItem.serviceObjectId);

    if (currentObjectFromPlan)
      items.push({
        name: currentObjectFromPlan.name,
        type: currentObjectFromPlan.type,
        square: Math.round(treeMapItem.square),
        agentsQuantity: treeMapItem.agentsQuantity,
        revenue: treeMapItem.revenue,
        cumulativeAgentsQuantity: treeMapItem.cumulativeAgentsQuantity,
        cumulativeRevenue: treeMapItem.cumulativeRevenue,
      });
  });

  return {
    [`${objectResultsItem?.index ? objectResultsItem.index / 3600 : -1}`]:
      items.sort(sortCommercialObjectsByName),
  };
};
