import { TooltipProps } from 'recharts';
import { NameType } from 'recharts/types/component/DefaultTooltipContent';

import { Card, CardContent, Typography } from '@mui/material';

import { gapBetweenDigits } from '@utils/dataForInspector';

type TooltipContentProps =
  | TooltipProps<number, NameType>
  | Readonly<TooltipProps<number, NameType>>;

export const TooltipContent = (props: TooltipContentProps) => {
  const { active, payload, label } = props;

  if (active && payload && payload.length) {
    return (
      <Card>
        <CardContent sx={{ p: 1 }}>
          <Typography fontSize={theme => theme.typography.pxToRem(12)}>
            {label}
          </Typography>

          <Typography
            fontSize={theme => theme.typography.pxToRem(12)}
            color={theme => theme.palette.customs.autoV2}
          >
            {`Доход: ${gapBetweenDigits(payload[0].value)}`}
          </Typography>
        </CardContent>
      </Card>
    );
  }
};
