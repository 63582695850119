import { createEvent, createStore } from 'effector';

import { EditableObjectType } from '@features/tpu-simulation-model/types';

export interface TreemapItem {
  name: string;
  size: number;
  unit: string;
  color: string;
}

export interface ITableRow {
  icon: string;
  name: string;
  square: number;
  revenue: number | null;
  revenuePercent: number | null;
  revenuePerSqMeter: number | null;
  revenuePerSqMeterPercent: number | null;
  agentsQuantity: number;
  agentsQuantityPercent: number;
}
export interface DataForCommercialObjects {
  name: string;
  type: EditableObjectType;
  square: number;
  agentsQuantity: number;
  revenue: number;
  cumulativeAgentsQuantity: number;
  cumulativeRevenue: number;
}

export interface ListForCommercialObjects {
  [key: string]: DataForCommercialObjects[];
}

export const $DataForCommercialObjects = createStore<ListForCommercialObjects>(
  {},
);
export const $ChartCommercialSquare = createStore<TreemapItem[]>([]);
export const $ChartCommercialRevenue = createStore<TreemapItem[]>([]);
export const $TableCommercialSquare = createStore<ITableRow[]>([]);

export const getDataForCommercialObjectsFn = createEvent();
export const addUploadedDataForCommercialObjectsFn =
  createEvent<ListForCommercialObjects | null>();
export const resetDataForCommercialObjectsFn = createEvent();
