import {
  IAgentShareInfoCategoryEnum,
  IAgentShareInfoColorEnum,
} from '@api/gql/tpu-types';
import { useUnit } from 'effector-react';

import { Agent } from '@features/tpu-calculation-scenarios-viewing/components/plan/AgentsMovement/Agent';
import {
  $Agents,
  $Floors,
  $MovementsLog,
  $Player,
  $Ruler,
} from '@features/tpu-calculation-scenarios-viewing/stores';
import { SimulationModelAgentCategory } from '@features/tpu-calculation-scenarios-viewing/types';
import { COLORS } from '@features/tpu-passenger-types/constants';
import { IRuler } from '@features/tpu-simulation-model/types';

const DEFAULT_RULER: IRuler = { meters: 1, px: 100 };

const SIMULATION_MODEL_AGENT_CATEGORIES_COMPARATOR = {
  Visitor: IAgentShareInfoCategoryEnum.Visitors,
  'Suburban Boarding Passenger': IAgentShareInfoCategoryEnum.EmbarkingCommuter,
  'Suburban Arriving Passenger':
    IAgentShareInfoCategoryEnum.DisembarkingCommuter,
  'Interregional Boarding Passenger':
    IAgentShareInfoCategoryEnum.EmbarkingLongDistance,

  'Interregional Arriving Passenger':
    IAgentShareInfoCategoryEnum.DisembarkingLongDistance,
};

export const AgentsMovement = () => {
  const $agents = useUnit($Agents);
  const $ruler = useUnit($Ruler);
  const $movementsLog = useUnit($MovementsLog);
  const $floors = useUnit($Floors);
  const { currentTimeOfSimulation, withAnimation } = useUnit($Player);

  const people =
    $movementsLog?.[currentTimeOfSimulation]?.[$floors.selectedFloor];
  const ruler = $ruler ?? DEFAULT_RULER;

  const getColor = (simulationModelCategory: SimulationModelAgentCategory) => {
    const agentCategory =
      SIMULATION_MODEL_AGENT_CATEGORIES_COMPARATOR[simulationModelCategory];
    const colorName = $agents?.[agentCategory] ?? IAgentShareInfoColorEnum.Blue;

    return COLORS[colorName];
  };

  if (!withAnimation || !people) return null;

  return people.map(({ coordinates, category }) => {
    return coordinates.map((coordinate, index) => (
      <Agent
        key={index}
        color={getColor(category)}
        ruler={ruler}
        position={coordinate}
      />
    ));
  });
};
