import { EMapFeatureLayout } from '@constants/map';

import { AgLayersStore } from '@features/ag-forecast/stores/map';

export const agAvailableLayouts: Array<keyof AgLayersStore> = [
  EMapFeatureLayout.graphs,
  EMapFeatureLayout.transportDistricts,
  EMapFeatureLayout.agRailwayStations,
  EMapFeatureLayout.graphAuto,
  EMapFeatureLayout.graphBus,
  EMapFeatureLayout.graphTrolleybus,
  EMapFeatureLayout.graphTram,
  EMapFeatureLayout.graphMetro,
  EMapFeatureLayout.graphSuburbanRailway,
  EMapFeatureLayout.graphRopeWay,
  EMapFeatureLayout.graphWaterWay,
  EMapFeatureLayout.graphFunicular,
  EMapFeatureLayout.graphMonoRailWay,
  EMapFeatureLayout.graphPedestrain,
  EMapFeatureLayout.transportFlowDistricts,
];
