import { alpha, outlinedInputClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import { palette } from '@system/theme/palette';

export const MuiInputBase: Components<Theme>['MuiInputBase'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: theme.typography.pxToRem(44),
      fontSize: theme.typography.pxToRem(14),
      [`&.${outlinedInputClasses.root}`]: {
        backgroundColor: palette.background.paper,
        outline: 'none',
        input: {
          padding: theme.spacing(1, 2),
          color: palette.text.secondary,
        },
        fieldset: {
          top: 0,
          borderColor: palette.border.stroke,
        },
        legend: {
          display: 'none',
        },
        [`&.${outlinedInputClasses.focused}`]: {
          fieldset: {
            borderColor: palette.secondary.main,
            borderWidth: theme.typography.pxToRem(1),
          },
          input: {
            color: palette.text.primary,
          },
        },
        '&:hover': {
          borderColor: palette.secondary.main,
        },
        '&:hover fieldset': {
          borderColor: palette.secondary.main,
        },
        [`&.${outlinedInputClasses.disabled}`]: {
          backgroundColor: palette.border.divider,
          '&:hover fieldset': {
            borderColor: 'initial',
          },
        },
      },
      [`&.${outlinedInputClasses.error}`]: {
        backgroundColor: alpha(palette.customs.bgError, 0.1),
        fieldset: {
          borderColor: palette.customs.bgError,
        },
      },
      '&.MuiInputBase-colorWarning': {
        backgroundColor: alpha(palette.customs.bus, 0.1),
        fieldset: {
          borderColor: palette.customs.bus,
        },
      },
    }),
  },
};
