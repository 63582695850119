import { sample } from 'effector';

import { createAgAllGraphSource } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

import {
  EPipeTileLayer,
  PipeTileLayer,
} from '@utils/map/hooks/useInitTileLayer';

import {
  initTileSources,
  pipeAllGraphsAgglomerationTileLayer,
} from '@widgets/widget-soceco-indicators/stores/map';

sample({
  clock: initTileSources,
  fn: payload => {
    return {
      action: EPipeTileLayer.changeSource,
      payload: createAgAllGraphSource(payload),
    } as PipeTileLayer;
  },
  target: pipeAllGraphsAgglomerationTileLayer,
});
