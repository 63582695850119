import { Icon, Stroke, Style } from 'ol/style';

import TPUSVG from '@components/icons/TPU.svg';
import TPUAlt from '@components/icons/TPUAlt.svg';
import TPUAltSelected from '@components/icons/TPUAltSelected.svg';
import TPUSelectedSVG from '@components/icons/TPUSelected.svg';
import TPUStartedSVG from '@components/icons/TPUStarted.svg';

import { EMapFeatureMetaKeys } from '@constants/map';

import { EFeatureStyle } from '@utils/map/hooks/useInitVectorLayer';
import { VectorStyleProps } from '@utils/map/styles/types';

export const tpuVectorStyle = ({
  feature,
  featureStyles,
}: VectorStyleProps) => {
  let image = TPUSVG;
  // const isAlt = feature?.get('isAlt');

  if (
    featureStyles &&
    Object.keys(featureStyles).includes(
      String(feature?.get(EMapFeatureMetaKeys.olId)),
    )
  ) {
    const style = featureStyles[feature?.get(EMapFeatureMetaKeys.olId)];
    switch (style) {
      case EFeatureStyle.selected:
        image = TPUSelectedSVG;
        break;
      case EFeatureStyle.started:
        image = TPUStartedSVG;
        break;
      case EFeatureStyle.alternate:
        image = TPUAlt;
        break;
      case EFeatureStyle.alternateSelected:
        image = TPUAltSelected;
    }
  }

  // if (isAlt) image = TPUAlt;

  return new Style({
    image: new Icon({
      src: image,
    }),
    stroke: new Stroke({
      color: '#101010',
      width: 1,
      lineDash: [4, 4],
    }),
  });
};
