import { Icon, Stroke, Style } from 'ol/style';

import AnimalPassageBridgeSVG from '@components/icons/AnimalPassageBridge.svg';

export const animalPassageVectorStyle = () => {
  return new Style({
    stroke: new Stroke({
      color: '#FF0000',
      width: 2,
    }),
    image: new Icon({
      src: AnimalPassageBridgeSVG,
    }),
  });
};
