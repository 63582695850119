import { sample } from 'effector';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EMapFeatureLayout, EMapFeatureMetaKeys } from '@constants/map';

import {
  $FilterMap,
  EFilterMapLayout,
  FilterMapApi,
} from '@features/pt-forecast-new/stores/filters';
import {
  $EditorMap,
  EditorMapApi,
} from '@features/pt-forecast-new/stores/map/editor';
import {
  $LayersVisible,
  LayersVisibleApi,
} from '@features/pt-forecast-new/stores/map/layersVisible';
import { $SelectedFeatures } from '@features/pt-forecast-new/stores/map/selectedFeatures';
import {
  initSources,
  pipeRailwayStationVectorLayer,
} from '@features/pt-forecast-new/stores/map/store';
import {
  $VectorGeometry,
  VectorGeometryApi,
} from '@features/pt-forecast-new/stores/map/vectorFeatures';

import {
  EFeatureStyle,
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload &&
    Object.keys(payload).includes(EMapFeatureLayout.railwayStation),
  fn: payload => {
    return {
      action: EPipeVectorLayer.setVisible,
      payload: payload![EMapFeatureLayout.railwayStation],
    } as PipeVectorLayer;
  },
  target: pipeRailwayStationVectorLayer,
});

// Рендер геометрии для слоя пассажиропотока
sample({
  clock: [
    EditorMapApi.setInfrastructure,
    VectorGeometryApi.setDirectionPassFlowRailwayStationsFrom,
    VectorGeometryApi.setDirectionPassFlowRailwayStationsTo,
    VectorGeometryApi.setDirectionPassFlowRailwayStationsIntersection,
    VectorGeometryApi.setRailwayStations,
    FilterMapApi.setLayout,
    FilterMapApi.setRegionTo,
    FilterMapApi.setRegionFrom,
    FilterMapApi.clearRegions,
    FilterMapApi.reset,
    initSources,
  ],
  source: {
    VectorGeometry: $VectorGeometry,
    FilterMap: $FilterMap,
    EditorMap: $EditorMap,
  },
  fn: ({ VectorGeometry, FilterMap, EditorMap }) => {
    const features: Feature<Point>[] = [];

    const geometry = [];

    if (FilterMap.toSelectedRegion || FilterMap.fromSelectedRegion) {
      geometry.push(
        ...VectorGeometry.directionPassFlow.railwayStations.from,
        ...VectorGeometry.directionPassFlow.railwayStations.to,
        ...VectorGeometry.directionPassFlow.railwayStations.intersection,
      );
    } else if (FilterMap.layout === EFilterMapLayout.Infrastructure) {
      geometry.push(...VectorGeometry.railwayStations);
    }

    geometry.forEach(railwayStationGeometry => {
      features.push(
        new Feature({
          geometry: new Point(fromLonLat(railwayStationGeometry.coordinates)),
          [EMapFeatureMetaKeys.olId]: railwayStationGeometry.id,
          [EMapFeatureMetaKeys.layout]: EMapFeatureLayout.railwayStation,
          isForceVisible: EditorMap.involvedStations.includes(
            railwayStationGeometry.id,
          ),
        }),
      );
    });

    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: { features },
    } as PipeVectorLayer;
  },
  target: pipeRailwayStationVectorLayer,
});

// применение стилей к слою
sample({
  clock: [
    FilterMapApi.setLayout,
    FilterMapApi.setRegionTo,
    FilterMapApi.setRegionFrom,
    FilterMapApi.clearRegions,
    initSources,
  ],
  source: { FilterMap: $FilterMap },
  fn: ({ FilterMap }) => {
    return {
      action: EPipeVectorLayer.changeThemeProps,
      payload: {
        maxRenderZoom:
          FilterMap.layout === EFilterMapLayout.Infrastructure ? 8 : 6,
      },
    } as PipeVectorLayer;
  },
  target: pipeRailwayStationVectorLayer,
});

// применение стилей к фичам
sample({
  clock: [$SelectedFeatures, $LayersVisible],
  source: {
    SelectedFeatures: $SelectedFeatures,
    LayersVisible: $LayersVisible,
  },
  filter: ({ LayersVisible }) =>
    LayersVisible[EMapFeatureLayout.railwayStation],
  fn: ({ SelectedFeatures }) => {
    return {
      action: EPipeVectorLayer.setFeatureStyle,
      payload: {
        featureStyles: !SelectedFeatures.length
          ? {}
          : Object.fromEntries(
              SelectedFeatures.filter(
                item => item.layout === EMapFeatureLayout.railwayStation,
              ).map(item => [item.id, EFeatureStyle.selected]),
            ),
      },
    } as PipeVectorLayer;
  },
  target: pipeRailwayStationVectorLayer,
});
