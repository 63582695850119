import { apiClient } from '@api/client';
import { IMutation, IMutationDeleteScenarioArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

export const mutationDeleteScenario = async (
  scenario: IMutationDeleteScenarioArgs,
) => {
  const result = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation DeleteScenarioMutation($scenarioId: String!) {
        deleteScenario(scenarioId: $scenarioId) {
          error
          ok
        }
      }
    `,
    variables: scenario,
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.deleteScenario;
};
