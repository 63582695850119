import Konva from 'konva';
import { useEffect, useRef } from 'react';
import { Circle, Group, Image, Rect, Transformer } from 'react-konva';
import useImage from 'use-image';

import ArrowRightSvg from '@components/icons/ArrowRight.svg';
import LadderSvg from '@components/icons/Ladder.svg';

import {
  ILadder,
  IShape,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

export interface LadderProps extends ILadder {
  isSelected: boolean;
  onSelect: (arg: ObjectOfTheSimulationModel) => void;
  updateObjectData: (arg: ObjectOfTheSimulationModel) => void;
}

interface ArrowProps {
  ratio: number;
  point: { x: number; y: number };
  enter?: boolean;
}

const DEFAULT_SIZE = 44;

const Arrow = ({ ratio, point, enter }: ArrowProps) => {
  const [arrowRight] = useImage(ArrowRightSvg);

  return (
    <Group
      x={point.x}
      y={point.y}
    >
      <Circle
        fill={enter ? '#3679EA' : '#F3A015'}
        radius={8 * ratio}
      />
      <Image
        image={arrowRight}
        x={-4 * ratio}
        y={-4 * ratio}
        width={8 * ratio}
        height={8 * ratio}
      />
    </Group>
  );
};

export const Ladder = ({
  isSelected,
  onSelect,
  updateObjectData,
  ...props
}: LadderProps) => {
  const [ladder] = useImage(LadderSvg);

  const shapeRef = useRef<Konva.Group | null>(null);
  const trRef = useRef<Konva.Transformer | null>(null);

  const { point, width, height, rotation } = props;
  const ratioW = width / DEFAULT_SIZE;
  const ratioH = height / DEFAULT_SIZE;

  const onChangeShape = ({ x, y, width, height, rotation }: IShape) => {
    updateObjectData({ ...props, point: { x, y }, width, height, rotation });
  };

  useEffect(() => {
    if (isSelected && trRef.current && shapeRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer()?.batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Group
        x={point.x}
        y={point.y}
        ref={shapeRef}
        width={width}
        height={height}
        rotation={rotation}
        draggable
        onClick={e => {
          e.cancelBubble = true;
          onSelect(props);
        }}
        onDragEnd={e => {
          onChangeShape({
            width,
            height,
            rotation,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={() => {
          const node = shapeRef.current;
          if (!node) return;

          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          onChangeShape({
            x: Math.round(node.x()),
            y: Math.round(node.y()),
            width: Math.max(24, Math.round(node.width() * scaleX)),
            height: Math.max(24, Math.round(node.height() * scaleY)),
            rotation: Math.round(node.rotation()),
          });
        }}
      >
        <Rect
          width={width}
          height={height}
          fill={'#fff'}
          strokeWidth={1}
          stroke={'#3679EA'}
          strokeScaleEnabled={false}
        />
        <Image
          image={ladder}
          width={24 * ratioW}
          height={24 * ratioH}
          x={10 * ratioW}
          y={10 * ratioH}
        />
        <Arrow
          enter
          ratio={Math.min(ratioW, ratioH)}
          point={{ x: 0, y: height / 2 }}
        />
        <Arrow
          point={{ x: width, y: height / 2 }}
          ratio={Math.min(ratioW, ratioH)}
        />
      </Group>
      {isSelected && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          rotationSnaps={[0, 90, 180, 270]}
          boundBoxFunc={(oldBox, newBox) => {
            if (Math.abs(newBox.width) < 12 || Math.abs(newBox.height) < 12) {
              return oldBox;
            }

            return newBox;
          }}
        />
      )}
    </>
  );
};
