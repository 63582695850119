import {
  IQuery as IQueryAg,
  IQueryGetScenarioByIdArgs,
} from '@api/gql/ag-types';
import { IQuery, IQueryFindRegionArgs } from '@api/gql/types';
import { createApi, createEffect, createEvent, createStore } from 'effector';

import { EAgScenarioStatusType } from '@features/ag-forecast/constants/agScenarioStatuses';

const initState: FeatureSettingsStore = {
  projectUUID: null,
  regionUUID: null,
  scenarioUUID: null,
  scenarioName: null,
  scenarioStatus: null,
};

export const $FeatureSettings = createStore<FeatureSettingsStore>(initState);

export const FeatureSettingsApi = createApi($FeatureSettings, {
  setProjectUUID: (store, projectUUID: string) => ({ ...store, projectUUID }),
  setRegionUUID: (store, regionUUID: string) => ({ ...store, regionUUID }),
  setScenarioUUID: (store, scenarioUUID: string) => ({
    ...store,
    scenarioUUID,
  }),
  setScenarioName: (store, scenarioName: string | null) => ({
    ...store,
    scenarioName,
  }),
  setScenarioStatus: (store, scenarioStatus: EAgScenarioStatusType | null) => ({
    ...store,
    scenarioStatus,
  }),
  reset: () => initState,
});

export const fetchScenarioStatus = createEvent();

export const FindRegionFx = createEffect<
  IQueryFindRegionArgs,
  IQuery['findRegion']
>();

export const fetchScenarioStatusFx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQueryAg['getScenarioById']
>();

export const fetchScenarioNameFx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQueryAg['getScenarioById']
>();

type FeatureSettingsStore = {
  projectUUID: string | null;
  regionUUID: string | null;
  scenarioUUID: string | null;
  scenarioName: string | null;
  scenarioStatus: EAgScenarioStatusType | null;
};
