export const base64ToBlob = (base64: string, mimeType: string) => {
  // Декодирование строки Base64 в бинарные данные.
  const byteCharacters = atob(base64);
  // Создание массива для байтов.
  const byteArrays = [];

  // Определяем размер блока для обработки.
  const sliceSize = 512; // Можно выбрать другое значение для размера блока.

  // Разбиваем бинарные данные на блоки размером с sliceSize.
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    // Создание нового объекта Uint8Array из массива byteNumbers и добавление его в byteArrays.
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  // Создание blob с использованием массива байтов и MIME-типа.
  const blob = new Blob(byteArrays, { type: mimeType });
  return blob;
};
