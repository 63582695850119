import { createEffect, createEvent, createStore } from 'effector';

interface ModalDeleteScenarioDataType {
  isOpen: boolean;
  id: string | null;
  name: string | null;
  isLoading: boolean;
  isResolve: boolean;
  isRejected: boolean;
}

export const $ModalDeleteScenario = createStore<ModalDeleteScenarioDataType>({
  isOpen: false,
  id: null,
  name: null,
  isLoading: false,
  isResolve: false,
  isRejected: false,
});

export const openDeleteScenarioModalFn = createEvent<{
  id: string;
  name: string;
}>();

export const resetModalDeleteScenarioFn = createEvent<void>();

export const deleteScenarioFn = createEvent<void>();

export const removeScenarioFx = createEffect<string, boolean>();
