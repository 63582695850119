import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { ModalDeleteProjects } from './components/ModalDeleteProjects';
import { ModalDownloadFile } from './components/ModalDownloadFile';
import { ModalFileRequirements } from './components/ModalFileRequirements';
import { ModalShareProjects } from './components/ModalShareProjects';
import { $UIAg, UIAgApi } from './store/ui';

export const Modals = () => {
  const { fileRequirements, shareProject, deleteProject, downloadFile } =
    useUnit($UIAg);
  const { setModalView } = useUnit(UIAgApi);

  const handleCloseModalShareProjects = useCallback(() => {
    setModalView({ shareProject: false });
  }, [setModalView]);

  const handleCloseModalDeleteProjects = useCallback(() => {
    setModalView({ deleteProject: false });
  }, [setModalView]);

  const handleCloseFileRequirements = useCallback(() => {
    setModalView({ fileRequirements: false });
  }, [setModalView]);

  const handleCloseDownloadFile = useCallback(() => {
    setModalView({ downloadFile: false });
  }, [setModalView]);

  return (
    <>
      {shareProject && (
        <ModalShareProjects
          onClose={handleCloseModalShareProjects}
          onExecute={() => {}}
        />
      )}

      {deleteProject && (
        <ModalDeleteProjects onClose={handleCloseModalDeleteProjects} />
      )}

      {fileRequirements && (
        <ModalFileRequirements onClose={handleCloseFileRequirements} />
      )}

      {downloadFile && <ModalDownloadFile onClose={handleCloseDownloadFile} />}
    </>
  );
};
