import { createEvent, createStore } from 'effector';

export const enum NotificationType {
  UpdateProject,
  NotUniqueProjectName,
  CopyProject,
}

export interface INotification {
  id: string;
  type: NotificationType;
  isSuccess: boolean;
  isOpen: boolean;
}

export const $Notifications = createStore<INotification[]>([]);

export const resetNotificationsFn = createEvent<void>();

export const addNotificationFn = createEvent<{
  type: NotificationType;
  isSuccess: boolean;
}>();

export const hideNotificationFn = createEvent<string>();

export const removeNotificationFn = createEvent<string>();
