import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import { CloseOutlined, InfoOutlined } from '@mui/icons-material';
import { Button, IconButton, Paper, Stack, Typography } from '@mui/material';

import { AddGraphIcon } from '@components/icons/AddGraph';
import { AddTpuIcon } from '@components/icons/AddTpu';
import { DownloadIcon } from '@components/icons/Download';
import { SaveIcon } from '@components/icons/Save';

import {
  $EditorMap,
  fetchTpuInfrastructure,
  updateInfrastructure,
} from '@features/ag-forecast/stores/map/editor';
import { ModalsApi } from '@features/ag-forecast/stores/modals';
import { $UI, UIApi } from '@features/ag-forecast/stores/ui';

type EditeTpuToolbarProps = {
  width: string;
};

export const EditeTpuToolbar = ({ width }: EditeTpuToolbarProps) => {
  const { setEditTPUMode, togglePlaceTPU, toggleDrawGraph } = useUnit(UIApi);
  const { placeTPU, drawGraph } = useUnit($UI);
  const { setModalView } = useUnit(ModalsApi);
  const { tpus, graphs } = useUnit($EditorMap);

  const handleHideToolbar = () => {
    if (!isUpdatedInfrastructure) return setEditTPUMode(false);

    setModalView({
      confirmCancelEditTpu: true,
      action: save => {
        if (save) {
          updateInfrastructure();
        } else {
          fetchTpuInfrastructure();
        }
        setEditTPUMode(false);
      },
    });
  };

  const handleSaveInfrastructure = useCallback(() => {
    updateInfrastructure();
    setEditTPUMode(false);
  }, [setEditTPUMode]);

  const isUpdatedInfrastructure = useMemo(() => {
    return (
      !!tpus.find(tpu => tpu.isUpdated) ||
      !!graphs.find(graph => graph.isUpdated)
    );
  }, [graphs, tpus]);

  return (
    <Paper
      sx={{
        backgroundColor: theme => theme.palette.common.white,
        boxShadow: '0px 0px 8px 0px #00000026',
        borderRadius: '6px',
        width,
        p: 2.5,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{}}
      >
        <Stack
          direction="row"
          gap={3.5}
        >
          <Button
            sx={({ palette }) => ({
              width: '162px',
              background: placeTPU
                ? palette.primary.light
                : palette.primary.main,
            })}
            disabled={drawGraph}
            onClick={togglePlaceTPU}
          >
            <AddTpuIcon style={{ marginRight: '8px' }} />
            ТПУ
          </Button>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
          >
            <Button
              sx={({ palette }) => ({
                width: '162px',
                background: drawGraph
                  ? palette.primary.light
                  : palette.primary.main,
              })}
              onClick={toggleDrawGraph}
              disabled={!tpus.length || placeTPU}
            >
              <AddGraphIcon
                style={{
                  marginRight: '8px',
                  opacity: !tpus.length ? 0.5 : 1,
                }}
              />
              Элемент сети
            </Button>
            <IconButton style={{ width: '24px', height: '24px' }}>
              <InfoOutlined style={{ opacity: 0.5 }} />
            </IconButton>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap={3.5}
        >
          <Button
            variant="text"
            style={{ opacity: 0.5 }}
          >
            <Typography>Скачать</Typography>
            <DownloadIcon style={{ marginLeft: '8px' }} />
          </Button>
          <Button
            style={{ minWidth: '44px', padding: '4px' }}
            onClick={handleSaveInfrastructure}
            disabled={!isUpdatedInfrastructure}
          >
            <SaveIcon
              style={{ opacity: isUpdatedInfrastructure ? 0.85 : 0.5 }}
            />
          </Button>
          <Button
            variant="outlined"
            style={{ minWidth: '44px', padding: '4px' }}
            onClick={handleHideToolbar}
          >
            <CloseOutlined />
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};
