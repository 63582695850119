import { apiClient } from '@api/client';
import {
  IDownloadFileMutation,
  IDownloadFileTypeEnum,
  IFloorFileInfoType,
  IQuery,
} from '@api/gql/tpu-types';
import { downloadFile } from '@api/queries';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { TPU_CLIENT_NAME } from '@constants/api';

import { resetEditableObjectDataFn } from '@features/tpu-simulation-model/store/editedObject/editedObject.store';
import {
  $Floors,
  downloadFileFx,
  getFloorsInfoFx,
  resetFloorsInfoFn,
  selectFloorFn,
  selectFloorWithoutResetEditedObjectFn,
  setBGUrlFn,
} from '@features/tpu-simulation-model/store/floors/floors.store';
import { getProjectInfoFromScenarioIdFx } from '@features/tpu-simulation-model/store/project/project.store';

import { base64ToBlob } from '@utils/fileConversion';

$Floors
  .reset(resetFloorsInfoFn)
  .on(getFloorsInfoFx.done, (state, { result }) => {
    if (!result) return { ...state, floorsInfo: [], selectedFloor: '' };

    return {
      floorsInfo: result,
      selectedFloor: result[0]?.extraId,
      backgroundUrl: '',
    };
  })
  .on(selectFloorWithoutResetEditedObjectFn, (state, { floorId }) => {
    URL.revokeObjectURL(state.backgroundUrl);

    return { ...state, selectedFloor: floorId, backgroundUrl: '' };
  })
  .on(selectFloorFn, (state, payload) => {
    URL.revokeObjectURL(state.backgroundUrl);

    return { ...state, selectedFloor: payload, backgroundUrl: '' };
  })
  .on(setBGUrlFn, (state, payload) => {
    return { ...state, backgroundUrl: payload };
  });

sample({
  clock: getProjectInfoFromScenarioIdFx.done,
  filter: ({ result }) => !!result?.id,
  fn: ({ result }) => result!.id as string,
  target: getFloorsInfoFx,
});

sample({
  clock: selectFloorFn,
  target: resetEditableObjectDataFn,
});

sample({
  clock: downloadFileFx.doneData,
  filter: (clockData): clockData is IDownloadFileMutation => !!clockData,
  fn: (clockData: IDownloadFileMutation) => {
    const fileData = clockData?.file || '';
    const blob = base64ToBlob(fileData, 'application/octet-stream');

    const url = URL.createObjectURL(blob);

    return url;
  },
  target: setBGUrlFn,
});

getFloorsInfoFx.use(async id => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query ProjectById($id: UUID!) {
        projectById(id: $id) {
          floorFiles {
            id
            extraId
            description
          }
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data.projectById?.floorFiles ?? null;
});

sample({
  clock: [selectFloorFn, selectFloorWithoutResetEditedObjectFn],
  source: $Floors,
  fn: (sourceData, clockData) => {
    const extraId =
      typeof clockData === 'string' ? clockData : clockData.floorId;

    return (
      sourceData.floorsInfo.find(floor => floor.extraId === extraId)?.id ?? ''
    );
  },
  target: downloadFileFx,
});

sample({
  clock: getFloorsInfoFx.doneData,
  filter: (clockData): clockData is IFloorFileInfoType[] => !!clockData,
  fn: (clockData: IFloorFileInfoType[]) => {
    return clockData[0].id;
  },
  target: downloadFileFx,
});

downloadFileFx.use(async id => {
  const response = await downloadFile({
    objectId: id,
    fileType: IDownloadFileTypeEnum.Floor,
  });

  return response.data?.downloadFile;
});
