import { EditableObjectType } from '@features/tpu-simulation-model/types';

export const RU_OBJECT_TYPE = {
  [EditableObjectType.Room]: 'Помещение',
  [EditableObjectType.Platform]: 'Платформа',
  [EditableObjectType.Exit]: 'Выход',
  [EditableObjectType.PassengerGenerator]: 'Генератор пассажиров (вход)',
  [EditableObjectType.Path]: 'Путь',
  [EditableObjectType.Ladder]: 'Лестница',
  [EditableObjectType.Elevator]: 'Лифт',
  [EditableObjectType.Door]: 'Дверь',
  [EditableObjectType.Turnstile]: 'Турникет',
  [EditableObjectType.SecurityCheckpoint]: 'Пункт досмотра',
  [EditableObjectType.TicketOffice]: 'Билетная касса',
  [EditableObjectType.TicketPrintingMachine]: 'Билетопечатающий аппарат (БПА)',
  [EditableObjectType.InformationTable]: 'Информационное табло',
  [EditableObjectType.Toilet]: 'Туалет',
  [EditableObjectType.Waiting]: 'Комната ожидания',
  [EditableObjectType.Restaurant]: 'Ресторан',
  [EditableObjectType.Buffet]: 'Буфет',
  [EditableObjectType.VendingMachine]: 'Вендинговый аппарат',
  [EditableObjectType.Store]: 'Магазин',
  [EditableObjectType.Kiosk]: 'Киоск',
  [EditableObjectType.Bank]: 'Банк',
  [EditableObjectType.Bankomat]: 'Банкомат',
  [EditableObjectType.Other]: 'Прочее',
};

export const WORD_ENDING_ISOLATED = {
  [EditableObjectType.Room]: 'о',
  [EditableObjectType.Platform]: 'а',
  [EditableObjectType.Exit]: '',
  [EditableObjectType.PassengerGenerator]: '',
  [EditableObjectType.Path]: '',
};
