import { apiClient } from '@api/client';
import { IMutation } from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { TPU_CLIENT_NAME } from '@constants/api';

import {
  $DeletePassengerType,
  closeModalDeletePassengerTypeFn,
  openModalDeletePassengerTypeFn,
  removePassengerTypeFx,
  resetDeletePassengerTypeStoreFn,
  triggerRemovePassengerTypeFn,
} from '@features/tpu-passenger-types/stores/delete-passenger-type/delete-passenger-type.store';
import {
  $ScenarioId,
  getAllAgentShareInfoCategoryFx,
} from '@features/tpu-passenger-types/stores/registry.store';

$DeletePassengerType
  .reset(resetDeletePassengerTypeStoreFn)
  .on(openModalDeletePassengerTypeFn, (_, payload) => {
    const { name, id } = payload;
    return { isOpen: true, isLoading: false, isResolve: false, name, id };
  })
  .on(closeModalDeletePassengerTypeFn, state => {
    return { ...state, isOpen: false };
  })
  .on(removePassengerTypeFx.doneData, (state, payload) => {
    return { ...state, isResolve: payload };
  })
  .on(removePassengerTypeFx.pending, (state, payload) => {
    return { ...state, isLoading: payload };
  });

sample({
  clock: removePassengerTypeFx.doneData,
  source: { scenarioId: $ScenarioId },
  filter: (_, clockData) => clockData,
  fn: ({ scenarioId }) => scenarioId,
  target: getAllAgentShareInfoCategoryFx,
});

sample({
  clock: triggerRemovePassengerTypeFn,
  source: $DeletePassengerType,
  filter: sourceData => !!sourceData.id,
  fn: sourceData => {
    return sourceData.id as string;
  },
  target: removePassengerTypeFx,
});

removePassengerTypeFx.use(async id => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation deleteAgentShareInfo($id: UUID!) {
        deleteAgentShareInfo(agentShareInfoId: $id) {
          ok
          errors {
            key
            message
          }
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return !!response.data?.deleteAgentShareInfo?.ok;
});
