import { useUnit } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';

import { Share } from './states/share';
import { Success } from './states/success';
import {
  $ModalShareCalculation,
  EStateModalShareCalculation,
  resetModalShareCalculation,
  setState,
} from './store';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { DialogCloseButton } from '@components/DialogCloseButton';

interface ModalShareCalculationProps {
  onClose(): void;
  onExecute(): void;
}

export const ModalShareCalculation = (props: ModalShareCalculationProps) => {
  const { onClose, onExecute } = props;
  const $modalShareCalculation = useUnit($ModalShareCalculation);
  const handlers = useUnit({ resetModalShareCalculation, setState });

  const DescriptionState = useMemo(() => {
    switch ($modalShareCalculation.state) {
      case EStateModalShareCalculation.share:
        return Share;

      case EStateModalShareCalculation.success:
        return Success;
    }
  }, [$modalShareCalculation.state]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    if ($modalShareCalculation.state === EStateModalShareCalculation.share) {
      onExecute();
      handlers.setState(EStateModalShareCalculation.success);
    } else {
      onClose();
    }
  }, [$modalShareCalculation.state, handlers, onClose, onExecute]);

  useEffect(() => {
    return handlers.resetModalShareCalculation;
  }, [handlers.resetModalShareCalculation]);

  return (
    <Dialog
      onClose={handleClose}
      open={true}
    >
      <DialogTitle
        variant="subtitle2"
        sx={{
          position: 'relative',
          fontSize: theme => theme.typography.pxToRem(16),
          pr: 10,
        }}
      >
        Поделиться расчетом
      </DialogTitle>

      <DialogCloseButton onClick={handleClose} />

      <DialogContent>
        <DescriptionState />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSubmit}>
          {$modalShareCalculation.state === EStateModalShareCalculation.share
            ? 'Поделиться'
            : 'Закрыть'}
        </Button>

        {$modalShareCalculation.state === EStateModalShareCalculation.share && (
          <Button
            variant={'outlined'}
            onClick={handleClose}
          >
            Отменить
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
