// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import VectorTileSource from 'ol/source/VectorTile';

import { customLoadFeaturesXhr } from '@utils/map/tools/customLoadFeaturesXhr';

export const ApiMapPrivateTiles: (
  token: string,
) => VectorTileSource['tileLoadFunction'] = token => (tile, url) => {
  tile.setLoader(
    /**
     * @param {import("../extent.js").Extent} extent Extent.
     * @param {number} resolution Resolution.
     * @param {import("../proj/Projection.js").default} projection Projection.
     */
    function (extent, resolution, projection) {
      customLoadFeaturesXhr(
        url,
        tile.getFormat(),
        extent,
        resolution,
        projection,
        tile.onLoad.bind(tile),
        tile.onError.bind(tile),
        token,
      );
    },
  );
};
