import { Stack, Step, Stepper } from '@mui/material';

import {
  StepPointConnectorV2,
  StepPointIconPropsV2,
  StepPointIconV2,
  StepPointV2,
} from '@components/YearLine_v2/style';

type YearLineType = {
  baseYear?: number;
  finalYear?: number;
  selectedYear?: number;
  onSelect?: (step: number) => void;
  disabled?: boolean;
};

const currentYear = Number(new Date().getFullYear().toString());

export const YearLineV2 = (props: YearLineType) => {
  const {
    baseYear = 2022,
    finalYear = 2035,
    selectedYear = 2022,
    disabled = false,
    onSelect,
  } = props;

  const steps = Array(finalYear - baseYear + 1)
    .fill(baseYear)
    .map((n, i) => n + i);

  const handleClickStep = (year: number) => () => {
    onSelect && onSelect(year);
  };
  return (
    <Stack
      sx={{ width: '100%', p: 2 }}
      spacing={4}
    >
      <Stepper
        activeStep={selectedYear - baseYear}
        connector={<StepPointConnectorV2 />}
      >
        {steps.map(label => (
          <Step
            key={label}
            sx={{ p: 0, zIndex: 1 }}
            onClick={handleClickStep(label)}
          >
            <StepPointV2
              StepIconComponent={(props: StepPointIconPropsV2) => (
                <StepPointIconV2
                  {...props}
                  isComing={!props.active && label <= currentYear}
                />
              )}
              disabled={disabled}
              sx={{
                '& span, & .MuiStepLabel-label.Mui-active, & .MuiStepLabel-label.Mui-completed':
                  {
                    fontWeight: label <= currentYear ? 900 : 500,
                  },
              }}
            >
              {label}
            </StepPointV2>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};
