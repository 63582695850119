import { createEvent, createStore } from 'effector';

export enum EStateModalDeleteCalculation {
  question,
  success,
}

interface ModalDeleteCalculation {
  state: EStateModalDeleteCalculation;
}

const defaultState = {
  state: EStateModalDeleteCalculation.question,
};

export const $ModalDeleteCalculation =
  createStore<ModalDeleteCalculation>(defaultState);

export const setState = createEvent<EStateModalDeleteCalculation>();
export const resetModalDeleteCalculation = createEvent();

$ModalDeleteCalculation
  .on(setState, (state, payload) => {
    return { ...state, state: payload };
  })
  .reset(resetModalDeleteCalculation);
