import {
  EMapFeatureLayout,
  EMapLayoutTypes,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';

export const LAYER_TYPE: Record<EMapFeatureLayout, EMapLayoutTypes> = {
  [EMapFeatureLayout.areaBorder]: EMapLayoutTypes.vector,
  [EMapFeatureLayout.auto]: EMapLayoutTypes.vector,
  [EMapFeatureLayout.highway]: EMapLayoutTypes.tile,
  [EMapFeatureLayout.plannedHighway]: EMapLayoutTypes.vector,
  [EMapFeatureLayout.directionHighway]: EMapLayoutTypes.vector,
  [EMapFeatureLayout.plannedTileHighway]: EMapLayoutTypes.tile,
  [EMapFeatureLayout.railway]: EMapLayoutTypes.tile,
  [EMapFeatureLayout.plannedTileRailway]: EMapLayoutTypes.tile,
  [EMapFeatureLayout.plannedRailway]: EMapLayoutTypes.vector,
  [EMapFeatureLayout.directionRailway]: EMapLayoutTypes.vector,
  [EMapFeatureLayout.avia]: EMapLayoutTypes.vector,
  [EMapFeatureLayout.airline]: EMapLayoutTypes.vector,
  [EMapFeatureLayout.busStation]: EMapLayoutTypes.vector,
  [EMapFeatureLayout.railwayStation]: EMapLayoutTypes.vector,
  [EMapFeatureLayout.intermediateCorrespondents]: EMapLayoutTypes.vector,
  [EMapFeatureLayout.airport]: EMapLayoutTypes.vector,
  [EMapFeatureLayout.pointA]: EMapLayoutTypes.vector,
  [EMapFeatureLayout.pointB]: EMapLayoutTypes.vector,
  [EMapFeatureLayout.multimodal]: EMapLayoutTypes.vector,
};
