import { SvgIcon, SvgIconProps } from '@mui/material';

type PathWithBorderProps = SvgIconProps & {
  path: string;
  border: string;
};

export const PathWithBorderIcon = (props: PathWithBorderProps) => {
  const { path, border } = props;

  return (
    <SvgIcon
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <rect
        x="9"
        width="2"
        height="20"
        fill={path}
      />
      <line
        x1="8.5"
        y1="20"
        x2="8.5"
        stroke={border}
      />
      <line
        x1="11.5"
        y1="20"
        x2="11.5"
        stroke={border}
      />
    </SvgIcon>
  );
};
