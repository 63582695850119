export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  GenericScalar: { input: any; output: any; }
  JSONString: { input: any; output: any; }
  Time: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type IAgentPositionType = {
  __typename?: 'AgentPositionType';
  data: Maybe<Scalars['GenericScalar']['output']>;
  index: Scalars['Int']['output'];
};

export type IAgentShareInfoByCategoryInput = {
  absoluteQuantity: Scalars['Int']['input'];
  infoId: Scalars['UUID']['input'];
  share: Scalars['Int']['input'];
};

/** An enumeration. */
export const enum IAgentShareInfoCategoryEnum {
  DisembarkingCommuter = 'DISEMBARKING_COMMUTER',
  DisembarkingLongDistance = 'DISEMBARKING_LONG_DISTANCE',
  EmbarkingCommuter = 'EMBARKING_COMMUTER',
  EmbarkingLongDistance = 'EMBARKING_LONG_DISTANCE',
  Visitors = 'VISITORS'
};

/** An enumeration. */
export const enum IAgentShareInfoColorEnum {
  Blue = 'BLUE',
  Green = 'GREEN',
  GreenBlue = 'GREEN_BLUE',
  Grey = 'GREY',
  LightBlue = 'LIGHT_BLUE',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Red = 'RED',
  Yellow = 'YELLOW'
};

export type IAgentShareInfoType = {
  __typename?: 'AgentShareInfoType';
  absoluteQuantity: Scalars['Int']['output'];
  averageTimeBeforeTrain: Scalars['Int']['output'];
  bankProbability: Scalars['Int']['output'];
  category: Maybe<IAgentShareInfoCategoryEnum>;
  color: Maybe<IAgentShareInfoColorEnum>;
  foodProbability: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  informationTableProbability: Scalars['Int']['output'];
  maximumTravelSpeed: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  otherProbability: Scalars['Int']['output'];
  scenario: IScenarioType;
  share: Scalars['Int']['output'];
  sharePassengerWithTickets: Scalars['Int']['output'];
  shareVisitorBuyingTickets: Scalars['Int']['output'];
  storeProbability: Scalars['Int']['output'];
  ticketOfficeProbability: Scalars['Int']['output'];
  toiletProbability: Scalars['Int']['output'];
  tpmProbability: Scalars['Int']['output'];
};

export type IAgglomerationType = {
  __typename?: 'AgglomerationType';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type ICommercialObjectType = {
  __typename?: 'CommercialObjectType';
  attendancePerDay: Scalars['Int']['output'];
  averageBill: Scalars['Float']['output'];
  averageCostPerDay: Scalars['Int']['output'];
  averageRevenuePerPassenger: Scalars['Int']['output'];
  averageServiceTime: Scalars['Int']['output'];
  bounceRate: Scalars['Float']['output'];
  eid: Scalars['String']['output'];
  name: Scalars['String']['output'];
  objectType: IHubCommercialObjectObjectTypeChoices;
  proportionCustomerVisitors: Scalars['Float']['output'];
  rentalRate: Scalars['Int']['output'];
  servedAtSameTime: Scalars['Int']['output'];
  serviceArea: Scalars['Float']['output'];
  totalArea: Scalars['Float']['output'];
};

export type ICopyProjectMutation = {
  __typename?: 'CopyProjectMutation';
  errors: Array<Maybe<IErrorType>>;
  newProject: Maybe<IProjectType>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
};

export type ICopyScenarioMutation = {
  __typename?: 'CopyScenarioMutation';
  errors: Array<Maybe<IErrorType>>;
  newScenario: Maybe<IScenarioType>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
};

export type ICreateAgentShareInfoInput = {
  averageTimeBeforeTrain: Scalars['Int']['input'];
  bankProbability: Scalars['Int']['input'];
  category: IAgentShareInfoCategoryEnum;
  color: IAgentShareInfoColorEnum;
  foodProbability: Scalars['Int']['input'];
  informationTableProbability: Scalars['Int']['input'];
  maximumTravelSpeed: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  otherProbability: Scalars['Int']['input'];
  scenarioId: Scalars['UUID']['input'];
  sharePassengerWithTickets: Scalars['Int']['input'];
  shareVisitorBuyingTickets: Scalars['Int']['input'];
  storeProbability: Scalars['Int']['input'];
  ticketOfficeProbability: Scalars['Int']['input'];
  toiletProbability: Scalars['Int']['input'];
  tpmProbability: Scalars['Int']['input'];
};

export type ICreateAgentShareInfoMutation = {
  __typename?: 'CreateAgentShareInfoMutation';
  agentShareInfo: Maybe<IAgentShareInfoType>;
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
};

export type ICreateDraftProjectMutation = {
  __typename?: 'CreateDraftProjectMutation';
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  projectId: Maybe<Scalars['UUID']['output']>;
  query: Maybe<IQuery>;
};

export type ICreateScenarioInput = {
  changePassengerTrafficIntensity: Scalars['Float']['input'];
  dayTimeType: IDayTimeTypeEnum;
  dayType: IScenarioDayTypeEnum;
  name: Scalars['String']['input'];
  projectId: Scalars['UUID']['input'];
  turnstileMode: ITurnstileModeEnum;
};

export type ICreateScenarioMutation = {
  __typename?: 'CreateScenarioMutation';
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
  scenario: Maybe<IScenarioType>;
};

export type ICreateSimulationReportMutation = {
  __typename?: 'CreateSimulationReportMutation';
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
  simulation: Maybe<ISimulationType>;
};

export type IDataFileInfoType = {
  __typename?: 'DataFileInfoType';
  dataType: Maybe<IDataFileTypeEnum>;
  errors: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  state: Maybe<IDataFileStateEnum>;
};

/** An enumeration. */
export const enum IDataFileStateEnum {
  Error = 'ERROR',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
};

/** An enumeration. */
export const enum IDataFileTypeEnum {
  CommercialObjects = 'COMMERCIAL_OBJECTS',
  CommuterTrainFlow = 'COMMUTER_TRAIN_FLOW',
  CommuterTrainSchedules = 'COMMUTER_TRAIN_SCHEDULES',
  Distribution = 'DISTRIBUTION',
  EpureVisitors = 'EPURE_VISITORS',
  LongDistanceTrainFlow = 'LONG_DISTANCE_TRAIN_FLOW',
  LongDistanceTrainSchedules = 'LONG_DISTANCE_TRAIN_SCHEDULES',
  ShareOfEntrances = 'SHARE_OF_ENTRANCES',
  ShareOfExits = 'SHARE_OF_EXITS',
  TicketOffices = 'TICKET_OFFICES',
  Turnstiles = 'TURNSTILES',
  Visitors = 'VISITORS'
};

/** An enumeration. */
export const enum IDayTimeTypeEnum {
  AllDay = 'ALL_DAY',
  EveningRushHour = 'EVENING_RUSH_HOUR',
  MorningRushHour = 'MORNING_RUSH_HOUR'
};

export type IDeleteAgentShareInfoMutation = {
  __typename?: 'DeleteAgentShareInfoMutation';
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
};

/** An enumeration. */
export const enum IDirectionEntranceExitEnum {
  Entrance = 'ENTRANCE',
  Exit = 'EXIT'
};

export type IDownloadFileMutation = {
  __typename?: 'DownloadFileMutation';
  errors: Array<Maybe<IErrorType>>;
  extension: Maybe<Scalars['String']['output']>;
  file: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
};

export const enum IDownloadFileTypeEnum {
  Data = 'DATA',
  Floor = 'FLOOR',
  SimulationReport = 'SIMULATION_REPORT'
};

export type IErrorType = {
  __typename?: 'ErrorType';
  key: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

export type IFloorFileInfoType = {
  __typename?: 'FloorFileInfoType';
  created: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  extraId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  modified: Scalars['DateTime']['output'];
  order: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export const enum IHubCommercialObjectObjectTypeChoices {
  /** Atm */
  Atm = 'ATM',
  /** Bank */
  Bank = 'BANK',
  /** Buffet */
  Buffet = 'BUFFET',
  /** Kiosk */
  Kiosk = 'KIOSK',
  /** Other */
  Other = 'OTHER',
  /** Restaurant */
  Restaurant = 'RESTAURANT',
  /** Shop */
  Shop = 'SHOP',
  /** Vending Machine */
  VendingMachine = 'VENDING_MACHINE'
};

/** An enumeration. */
export const enum IHubCreationTypeEnum {
  Custom = 'CUSTOM',
  Real = 'REAL'
};

export type IHubInput = {
  id: InputMaybe<Scalars['UUID']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
};

/** An enumeration. */
export const enum IHubProjectStateChoices {
  /** Draft */
  Draft = 'DRAFT',
  /** Error */
  Error = 'ERROR',
  /** Processing */
  Processing = 'PROCESSING',
  /** Queue */
  Queue = 'QUEUE',
  /** Success */
  Success = 'SUCCESS'
};

/** An enumeration. */
export const enum IHubScenarioSimulationStateChoices {
  /** Completed */
  Completed = 'COMPLETED',
  /** Failed */
  Failed = 'FAILED',
  /** Pending */
  Pending = 'PENDING',
  /** Processing */
  Processing = 'PROCESSING'
};

/** An enumeration. */
export const enum IHubShareOfEntranceExitDirectionChoices {
  /** Entrance */
  Entrance = 'ENTRANCE',
  /** Exit */
  Exit = 'EXIT'
};

/** An enumeration. */
export const enum IHubTicketOfficeObjectTypeChoices {
  /** Commuter */
  Commuter = 'COMMUTER',
  /** Long Distance */
  LongDistance = 'LONG_DISTANCE',
  /** Tpm */
  Tpm = 'TPM',
  /** Universal */
  Universal = 'UNIVERSAL'
};

export type IHubType = {
  __typename?: 'HubType';
  agglomeration: Maybe<IAgglomerationType>;
  code: Maybe<Scalars['String']['output']>;
  creationType: Maybe<IHubCreationTypeEnum>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type IMutation = {
  __typename?: 'Mutation';
  copyProject: Maybe<ICopyProjectMutation>;
  copyScenario: Maybe<ICopyScenarioMutation>;
  createAgentShareInfo: Maybe<ICreateAgentShareInfoMutation>;
  createDraftProject: Maybe<ICreateDraftProjectMutation>;
  createScenario: Maybe<ICreateScenarioMutation>;
  createSimulationReport: Maybe<ICreateSimulationReportMutation>;
  deleteAgentShareInfo: Maybe<IDeleteAgentShareInfoMutation>;
  downloadFile: Maybe<IDownloadFileMutation>;
  removeProject: Maybe<IRemoveProjectMutation>;
  removeScenario: Maybe<IRemoveScenarioMutation>;
  startSimulation: Maybe<IStartSimulationMutation>;
  turnOnTrain: Maybe<ITurnOnOffTrainMutation>;
  updateAgentShareInfo: Maybe<IUpdateAgentShareInfoMutation>;
  updateAgentShareInfoByCategory: Maybe<IUpdateAgentShareInfoByCategoryMutation>;
  updatePlan: Maybe<IUpdatePlanMutation>;
  updateProject: Maybe<IUpdateProjectMutation>;
  updateScenario: Maybe<IUpdateScenarioMutation>;
  uploadDataFile: Maybe<IUploadDataFileMutation>;
  uploadFloorFile: Maybe<IUploadFloorFileMutation>;
  validateBeforeSimulation: Maybe<IValidateBeforeSimulationMutation>;
};


export type IMutationCopyProjectArgs = {
  projectId: Scalars['UUID']['input'];
};


export type IMutationCopyScenarioArgs = {
  scenarioId: Scalars['UUID']['input'];
};


export type IMutationCreateAgentShareInfoArgs = {
  inputData: ICreateAgentShareInfoInput;
};


export type IMutationCreateScenarioArgs = {
  inputData: ICreateScenarioInput;
};


export type IMutationCreateSimulationReportArgs = {
  simulationId: Scalars['UUID']['input'];
};


export type IMutationDeleteAgentShareInfoArgs = {
  agentShareInfoId: Scalars['UUID']['input'];
};


export type IMutationDownloadFileArgs = {
  fileType: IDownloadFileTypeEnum;
  objectId: Scalars['UUID']['input'];
};


export type IMutationRemoveProjectArgs = {
  projectId: Scalars['UUID']['input'];
};


export type IMutationRemoveScenarioArgs = {
  scenarioId: Scalars['UUID']['input'];
};


export type IMutationStartSimulationArgs = {
  inputData: IStartSimulationInput;
};


export type IMutationTurnOnTrainArgs = {
  inputData: ITurnOnOffTrainInput;
};


export type IMutationUpdateAgentShareInfoArgs = {
  inputData: IUpdateAgentShareInfoInput;
};


export type IMutationUpdateAgentShareInfoByCategoryArgs = {
  inputData: IUpdateAgentShareInfoByCategoryInput;
};


export type IMutationUpdatePlanArgs = {
  document?: InputMaybe<Scalars['JSONString']['input']>;
  scenarioId: Scalars['UUID']['input'];
};


export type IMutationUpdateProjectArgs = {
  inputData: IUpdateProjectInput;
};


export type IMutationUpdateScenarioArgs = {
  inputData: IUpdateScenarioInput;
};


export type IMutationUploadDataFileArgs = {
  dataType: IDataFileTypeEnum;
  file: Scalars['Upload']['input'];
  projectId: Scalars['UUID']['input'];
};


export type IMutationUploadFloorFileArgs = {
  file: Scalars['Upload']['input'];
  projectId: Scalars['UUID']['input'];
};


export type IMutationValidateBeforeSimulationArgs = {
  scenarioId: Scalars['UUID']['input'];
};

export type IPaginationPayloadType = {
  __typename?: 'PaginationPayloadType';
  count: Scalars['Int']['output'];
  next: Maybe<Scalars['Int']['output']>;
  page: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  previous: Maybe<Scalars['Int']['output']>;
};

export type IPlanType = {
  __typename?: 'PlanType';
  document: Maybe<Scalars['JSONString']['output']>;
  scenario: IScenarioType;
};

export type IProjectFloorFileInfoInput = {
  description: InputMaybe<Scalars['String']['input']>;
  fileInfoId: Scalars['UUID']['input'];
};

export type IProjectListType = {
  __typename?: 'ProjectListType';
  pagination: Maybe<IPaginationPayloadType>;
  results: Maybe<Array<Maybe<IProjectType>>>;
};

export const enum IProjectSortEnum {
  AgglomerationNameAsc = 'AGGLOMERATION_NAME_ASC',
  AgglomerationNameDesc = 'AGGLOMERATION_NAME_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  HubCodeAsc = 'HUB_CODE_ASC',
  HubCodeDesc = 'HUB_CODE_DESC',
  HubNameAsc = 'HUB_NAME_ASC',
  HubNameDesc = 'HUB_NAME_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC'
};

export type IProjectType = {
  __typename?: 'ProjectType';
  created: Scalars['DateTime']['output'];
  dataFiles: Array<IDataFileInfoType>;
  floorFiles: Array<IFloorFileInfoType>;
  hub: Maybe<IHubType>;
  id: Scalars['UUID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Maybe<Scalars['String']['output']>;
  scenarios: Array<IScenarioType>;
  state: IHubProjectStateChoices;
};

export type IQuery = {
  __typename?: 'Query';
  agentShareInfoById: Maybe<IAgentShareInfoType>;
  allAgentShareInfo: Maybe<Array<Maybe<IAgentShareInfoType>>>;
  allAgglomerations: Maybe<Array<Maybe<IAgglomerationType>>>;
  allHubs: Maybe<Array<Maybe<IHubType>>>;
  allProjects: Maybe<IProjectListType>;
  allScenarios: Maybe<Array<Maybe<IScenarioType>>>;
  fileTemplates: Maybe<Scalars['String']['output']>;
  planByScenarioId: Maybe<IPlanType>;
  projectById: Maybe<IProjectType>;
  projectNameIsUnique: Maybe<Scalars['Boolean']['output']>;
  sampleCommercialObjectsByScenarioId: Maybe<Array<Maybe<ICommercialObjectType>>>;
  sampleShareOfEntrancesExitsByScenarioId: Maybe<Array<Maybe<IShareOfEntranceExitType>>>;
  sampleTicketOfficesByScenarioId: Maybe<Array<Maybe<ITicketOfficeType>>>;
  sampleTurnstilesByScenarioId: Maybe<Array<Maybe<ITurnstileType>>>;
  scenarioById: Maybe<IScenarioType>;
  scenarioNameIsUnique: Maybe<Scalars['Boolean']['output']>;
  simulationById: Maybe<ISimulationType>;
  simulationHeatmapBySimulationId: Maybe<Array<Maybe<ISimulationHeatmapType>>>;
  simulatorInputData: Maybe<Scalars['GenericScalar']['output']>;
  trainsByScenarioId: Maybe<ITrainListType>;
  waysPlatformsByScenarioId: Maybe<IWaysPlatformsType>;
};


export type IQueryAgentShareInfoByIdArgs = {
  id: Scalars['UUID']['input'];
};


export type IQueryAllAgentShareInfoArgs = {
  scenarioId: Scalars['UUID']['input'];
};


export type IQueryAllHubsArgs = {
  agglomerationId: InputMaybe<Scalars['UUID']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
};


export type IQueryAllProjectsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search: InputMaybe<Scalars['String']['input']>;
  sort: InputMaybe<Array<IProjectSortEnum>>;
};


export type IQueryAllScenariosArgs = {
  projectId: Scalars['UUID']['input'];
};


export type IQueryPlanByScenarioIdArgs = {
  scenarioId: Scalars['UUID']['input'];
};


export type IQueryProjectByIdArgs = {
  id: Scalars['UUID']['input'];
};


export type IQueryProjectNameIsUniqueArgs = {
  excludeProjectId: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
};


export type IQuerySampleCommercialObjectsByScenarioIdArgs = {
  scenarioId: Scalars['UUID']['input'];
};


export type IQuerySampleShareOfEntrancesExitsByScenarioIdArgs = {
  direction: InputMaybe<IDirectionEntranceExitEnum>;
  scenarioId: Scalars['UUID']['input'];
};


export type IQuerySampleTicketOfficesByScenarioIdArgs = {
  scenarioId: Scalars['UUID']['input'];
};


export type IQuerySampleTurnstilesByScenarioIdArgs = {
  scenarioId: Scalars['UUID']['input'];
};


export type IQueryScenarioByIdArgs = {
  id: Scalars['UUID']['input'];
};


export type IQueryScenarioNameIsUniqueArgs = {
  name: Scalars['String']['input'];
  projectId: Scalars['UUID']['input'];
};


export type IQuerySimulationByIdArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  simulationId: Scalars['UUID']['input'];
};


export type IQuerySimulationHeatmapBySimulationIdArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  simulationId: Scalars['UUID']['input'];
};


export type IQuerySimulatorInputDataArgs = {
  scenarioId: Scalars['UUID']['input'];
};


export type IQueryTrainsByScenarioIdArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  scenarioId: Scalars['UUID']['input'];
  search: InputMaybe<Scalars['String']['input']>;
  sort: InputMaybe<Array<ITrainSortEnum>>;
  trainType: InputMaybe<ITrainTypeEnum>;
};


export type IQueryWaysPlatformsByScenarioIdArgs = {
  scenarioId: Scalars['UUID']['input'];
};

export type IRemoveProjectMutation = {
  __typename?: 'RemoveProjectMutation';
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
};

export type IRemoveScenarioMutation = {
  __typename?: 'RemoveScenarioMutation';
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
};

/** An enumeration. */
export const enum IScenarioBarChartResultKindEnum {
  AgentQuantity = 'AGENT_QUANTITY',
  CumulativeTotalAgents = 'CUMULATIVE_TOTAL_AGENTS'
};

export type IScenarioBarChartResultType = {
  __typename?: 'ScenarioBarChartResultType';
  kind: Maybe<IScenarioBarChartResultKindEnum>;
  scenarioServiceObjectResult: IScenarioServiceObjectResultType;
  value: Scalars['Float']['output'];
};

/** An enumeration. */
export const enum IScenarioDayTypeEnum {
  Weekend = 'WEEKEND',
  Workday = 'WORKDAY'
};

/** An enumeration. */
export const enum IScenarioPieChartResultKindEnum {
  Entrances = 'ENTRANCES',
  Exits = 'EXITS'
};

export type IScenarioPieChartResultType = {
  __typename?: 'ScenarioPieChartResultType';
  kind: Maybe<IScenarioPieChartResultKindEnum>;
  scenarioServiceObjectResult: IScenarioServiceObjectResultType;
  serviceObjectId: Scalars['UUID']['output'];
  value: Scalars['Float']['output'];
};

export type IScenarioServiceObjectResultType = {
  __typename?: 'ScenarioServiceObjectResultType';
  barChartResults: Array<IScenarioBarChartResultType>;
  index: Scalars['Int']['output'];
  pieChartResults: Array<IScenarioPieChartResultType>;
  simulation: ISimulationType;
  treeMapResults: Array<IScenarioTreeMapResultType>;
};

/** An enumeration. */
export const enum IScenarioStateEnum {
  Calculated = 'CALCULATED',
  NotCalculated = 'NOT_CALCULATED'
};

export type IScenarioTreeMapResultType = {
  __typename?: 'ScenarioTreeMapResultType';
  agentsQuantity: Scalars['Float']['output'];
  cumulativeAgentsQuantity: Scalars['Float']['output'];
  cumulativeRevenue: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
  scenarioServiceObjectResult: IScenarioServiceObjectResultType;
  serviceObjectId: Scalars['UUID']['output'];
  square: Scalars['Float']['output'];
};

export type IScenarioType = {
  __typename?: 'ScenarioType';
  changePassengerTrafficIntensity: Scalars['Float']['output'];
  created: Scalars['DateTime']['output'];
  dayTimeType: Maybe<IDayTimeTypeEnum>;
  dayType: Maybe<IScenarioDayTypeEnum>;
  id: Scalars['UUID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  plan: Maybe<IPlanType>;
  project: IProjectType;
  sampleCommercialObjects: Maybe<Array<Maybe<ICommercialObjectType>>>;
  sampleTicketOffices: Maybe<Array<Maybe<ITicketOfficeType>>>;
  sampleTurnstiles: Maybe<Array<Maybe<ITurnstileType>>>;
  simulationId: Maybe<Scalars['UUID']['output']>;
  state: Maybe<IScenarioStateEnum>;
  trains: Maybe<Array<Maybe<ITrainType>>>;
  turnstileMode: Maybe<ITurnstileModeEnum>;
};

export type IShareOfEntranceExitType = {
  __typename?: 'ShareOfEntranceExitType';
  direction: IHubShareOfEntranceExitDirectionChoices;
  eid: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  share: Scalars['Float']['output'];
};

export type ISimulationHeatmapType = {
  __typename?: 'SimulationHeatmapType';
  data: Maybe<Scalars['GenericScalar']['output']>;
  index: Scalars['Int']['output'];
};

export type ISimulationType = {
  __typename?: 'SimulationType';
  calculatedTicks: Scalars['Int']['output'];
  errors: Maybe<Scalars['String']['output']>;
  hasReport: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  movements: Maybe<Array<Maybe<IAgentPositionType>>>;
  objectResults: Maybe<Array<Maybe<IScenarioServiceObjectResultType>>>;
  simDate: Scalars['Date']['output'];
  startTime: Scalars['Time']['output'];
  state: IHubScenarioSimulationStateChoices;
  ticks: Scalars['Int']['output'];
};

export type IStartSimulationInput = {
  scenarioId: Scalars['UUID']['input'];
  simDate: InputMaybe<Scalars['Date']['input']>;
  startTime: InputMaybe<Scalars['Time']['input']>;
  ticks: InputMaybe<Scalars['Int']['input']>;
};

export type IStartSimulationMutation = {
  __typename?: 'StartSimulationMutation';
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
  scenario: Maybe<IScenarioType>;
};

export type ITicketOfficeType = {
  __typename?: 'TicketOfficeType';
  averageServiceTime: Scalars['Int']['output'];
  bounceRate: Scalars['Float']['output'];
  eid: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  objectType: IHubTicketOfficeObjectTypeChoices;
  openFrom: Scalars['Time']['output'];
  openUntil: Scalars['Time']['output'];
};

export type ITrainListType = {
  __typename?: 'TrainListType';
  pagination: Maybe<IPaginationPayloadType>;
  results: Maybe<Array<Maybe<ITrainType>>>;
};

export const enum ITrainSortEnum {
  ArrivalTimeAsc = 'ARRIVAL_TIME_ASC',
  ArrivalTimeDesc = 'ARRIVAL_TIME_DESC',
  DepartureTimeAsc = 'DEPARTURE_TIME_ASC',
  DepartureTimeDesc = 'DEPARTURE_TIME_DESC',
  NumberAsc = 'NUMBER_ASC',
  NumberDesc = 'NUMBER_DESC',
  PlatformAsc = 'PLATFORM_ASC',
  PlatformDesc = 'PLATFORM_DESC',
  RouteAsc = 'ROUTE_ASC',
  RouteDesc = 'ROUTE_DESC',
  WagonsAsc = 'WAGONS_ASC',
  WagonsDesc = 'WAGONS_DESC',
  WayAsc = 'WAY_ASC',
  WayDesc = 'WAY_DESC'
};

export type ITrainType = {
  __typename?: 'TrainType';
  arrivalTime: Maybe<Scalars['Time']['output']>;
  departureTime: Maybe<Scalars['Time']['output']>;
  disembarking: Maybe<Scalars['Int']['output']>;
  embarking: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  number: Maybe<Scalars['String']['output']>;
  on: Scalars['Boolean']['output'];
  platform: Maybe<Scalars['String']['output']>;
  regularity: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  route: Maybe<Scalars['String']['output']>;
  trainType: Maybe<ITrainTypeEnum>;
  wagons: Maybe<Scalars['Int']['output']>;
  way: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export const enum ITrainTypeEnum {
  Commuter = 'COMMUTER',
  LongDistance = 'LONG_DISTANCE'
};

export type ITurnOnOffTrainInput = {
  on: Scalars['Boolean']['input'];
  trainId: InputMaybe<Scalars['UUID']['input']>;
};

export type ITurnOnOffTrainMutation = {
  __typename?: 'TurnOnOffTrainMutation';
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
  train: Maybe<ITrainType>;
};

/** An enumeration. */
export const enum ITurnstileModeEnum {
  DoubleSided = 'DOUBLE_SIDED',
  OneSided = 'ONE_SIDED'
};

export type ITurnstileType = {
  __typename?: 'TurnstileType';
  bandwidthPerHour: Scalars['Int']['output'];
  eid: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  numberOfEnters: Scalars['Int']['output'];
  numberOfExits: Scalars['Int']['output'];
  openFrom: Scalars['Time']['output'];
  openUntil: Scalars['Time']['output'];
  width: Scalars['Int']['output'];
};

export type IUpdateAgentShareInfoByCategoryInput = {
  category: IAgentShareInfoCategoryEnum;
  scenarioId: Scalars['UUID']['input'];
  shareInfo: Array<InputMaybe<IAgentShareInfoByCategoryInput>>;
};

export type IUpdateAgentShareInfoByCategoryMutation = {
  __typename?: 'UpdateAgentShareInfoByCategoryMutation';
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
};

export type IUpdateAgentShareInfoInput = {
  agentShareInfoId: Scalars['UUID']['input'];
  averageTimeBeforeTrain: Scalars['Int']['input'];
  bankProbability: Scalars['Int']['input'];
  color: IAgentShareInfoColorEnum;
  foodProbability: Scalars['Int']['input'];
  informationTableProbability: Scalars['Int']['input'];
  maximumTravelSpeed: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  otherProbability: Scalars['Int']['input'];
  sharePassengerWithTickets: Scalars['Int']['input'];
  shareVisitorBuyingTickets: Scalars['Int']['input'];
  storeProbability: Scalars['Int']['input'];
  ticketOfficeProbability: Scalars['Int']['input'];
  toiletProbability: Scalars['Int']['input'];
  tpmProbability: Scalars['Int']['input'];
};

export type IUpdateAgentShareInfoMutation = {
  __typename?: 'UpdateAgentShareInfoMutation';
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
};

export type IUpdatePlanMutation = {
  __typename?: 'UpdatePlanMutation';
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  plan: Maybe<IPlanType>;
  query: Maybe<IQuery>;
};

export type IUpdateProjectInput = {
  agglomerationId: InputMaybe<Scalars['UUID']['input']>;
  dataFileIds: Array<InputMaybe<Scalars['UUID']['input']>>;
  floorFiles: Array<InputMaybe<IProjectFloorFileInfoInput>>;
  hub: IHubInput;
  name: Scalars['String']['input'];
  projectId: Scalars['UUID']['input'];
};

export type IUpdateProjectMutation = {
  __typename?: 'UpdateProjectMutation';
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
};

export type IUpdateScenarioInput = {
  changePassengerTrafficIntensity: Scalars['Float']['input'];
  dayTimeType: IDayTimeTypeEnum;
  dayType: IScenarioDayTypeEnum;
  name: Scalars['String']['input'];
  scenarioId: Scalars['UUID']['input'];
  turnstileMode: ITurnstileModeEnum;
};

export type IUpdateScenarioMutation = {
  __typename?: 'UpdateScenarioMutation';
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
  scenario: Maybe<IScenarioType>;
};

export type IUploadDataFileMutation = {
  __typename?: 'UploadDataFileMutation';
  errors: Array<Maybe<IErrorType>>;
  fileInfo: Maybe<IDataFileInfoType>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
};

export type IUploadFloorFileMutation = {
  __typename?: 'UploadFloorFileMutation';
  errors: Array<Maybe<IErrorType>>;
  fileInfo: Maybe<IFloorFileInfoType>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
};

export type IValidateBeforeSimulationMutation = {
  __typename?: 'ValidateBeforeSimulationMutation';
  errors: Array<Maybe<IErrorType>>;
  ok: Scalars['Boolean']['output'];
  query: Maybe<IQuery>;
};

export type IWaysPlatformsType = {
  __typename?: 'WaysPlatformsType';
  platforms: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ways: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};
