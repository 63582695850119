import { SvgIcon, SvgIconProps } from '@mui/material';

export const DoorInIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill={props.fill ?? 'none'}
      {...props}
    >
      <rect
        x=".5"
        width="24"
        height="24"
        rx="2"
        fill={props.fill ?? 'none'}
      />
      <path
        fill={props.fill ?? 'none'}
        stroke="currentColor"
        strokeWidth="2"
        d="M2.5 2h20v20h-20z"
      />
      <path
        d="M12.235 12.192V9h.647V14.547l.862-.902L15 12.332v.774l-2.448 2.664L10 13.099v-.815l1.383 1.371.852.845v-2.308Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M7.5 7h10v1.5h-10V7Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
