import { IQuery, IQueryGetScenarioByIdArgs } from '@api/gql/ag-types';
import { createApi, createEffect, createEvent, createStore } from 'effector';

const initState: MainTpuOptionsStore = {
  options: [],
};

export const $MainTpuOptions = createStore<MainTpuOptionsStore>(initState);

export const MainTpuOptionsApi = createApi($MainTpuOptions, {
  setOptions: (store, payload: MainTpuOption[]) => ({
    ...store,
    options: payload,
  }),
  reset: () => initState,
});

export const fetchMainTpuOptions = createEvent();

export const fetchMainTpuOptionsFx = createEffect<
  IQueryGetScenarioByIdArgs,
  IQuery['getScenarioById']
>();

export type MainTpuOption = {
  name: string;
  id: string;
};

export type MainTpuOptionsStore = {
  options: MainTpuOption[];
};
