import { createBorderStyle } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/createBorderStyle';
import { SetFeatureStyleProps } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/getFeatureStyle';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

export type GetIntermediateCorrespondentsStyleArgs = Pick<
  SetFeatureStyleProps,
  'active' | 'width'
>;

export const getIntermediateCorrespondentsStyle = ({
  active,
  width,
}: GetIntermediateCorrespondentsStyleArgs) => [
  createBorderStyle({ active, featureWidth: width || 1 }),
  new Style({
    image: new CircleStyle({
      radius: 4,
    }),
    stroke: new Stroke({
      color: '#5A5A5A',
      width,
    }),
    fill: new Fill({
      color: '#FFF',
    }),
  }),
];
