import { useForm } from 'react-hook-form';

import { Button, Divider, Stack } from '@mui/material';

import { FormActions } from '@features/tpu-simulation-model/components/FormAttributes/FormActions';
import { ObjectPosition } from '@features/tpu-simulation-model/components/FormAttributes/ObjectPosition';
import { ObjectSize } from '@features/tpu-simulation-model/components/FormAttributes/ObjectSize';
import { SelectOption as Select } from '@features/tpu-simulation-model/components/FormAttributes/Select';
import { Title } from '@features/tpu-simulation-model/components/FormAttributes/Title';
import { useContextSimulationModelHandlers } from '@features/tpu-simulation-model/hooks';
import { useFloors } from '@features/tpu-simulation-model/hooks/useFloors';
import { useWaysPlatforms } from '@features/tpu-simulation-model/hooks/useListOfWaysPlatforms';
import {
  ActionBeforeOpenErrorsWindow,
  IPlatform,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

interface FormProps extends IPlatform {
  onUpdateAndClose: (arg: ObjectOfTheSimulationModel) => void;
  onDeleteAndClose: (arg: string) => void;
}

export const Platform = ({
  onUpdateAndClose,
  onDeleteAndClose,
  ...props
}: FormProps) => {
  const { handleSubmit, formState, control } = useForm<IPlatform>({
    values: props,
  });
  const { selectedFloorIndex } = useFloors();
  const { platformOptions } = useWaysPlatforms();
  const { triggerFindErrors, openPlanErrorsWindow } =
    useContextSimulationModelHandlers();

  const openErrorsModal = (action: ActionBeforeOpenErrorsWindow) => () => {
    triggerFindErrors({ saveAfterAnalysis: true });
    openPlanErrorsWindow(action);
  };

  return (
    <Stack
      padding={3}
      gap={3}
      sx={{
        overflow: 'auto',
      }}
    >
      <Title title={'Платформа'} />
      <Select<IPlatform>
        control={control}
        name={'platform'}
        title={'Номер платформы'}
        placeholder={'Выберите номер платформы'}
        options={platformOptions}
      />
      <Button
        variant={'outlined'}
        onClick={openErrorsModal(
          ActionBeforeOpenErrorsWindow.GoToTrainSchedule,
        )}
      >
        Расписание поездов
      </Button>
      <Divider />
      <ObjectSize
        width={props.width}
        height={props.height}
      />
      <ObjectPosition
        point={props.point}
        floor={selectedFloorIndex + 1}
      />
      <Divider />
      <FormActions
        onSave={handleSubmit(onUpdateAndClose)}
        onDelete={() => onDeleteAndClose(props?.uuid)}
        disabled={!formState.isValid}
      />
    </Stack>
  );
};
