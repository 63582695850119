import { MouseEvent } from 'react';

import { Box, IconButton } from '@mui/material';

import {
  BasketIcon,
  CopyIcon,
  EditIcon,
  ShareOutlinedIcon,
} from '@components/icons';

interface ActionsProps {
  id: string;
  onDelete?: (arg: string) => void;
  onCopy?: (arg: string) => void;
  onEdit?: (arg: string) => void;
}

export const Actions = ({ onDelete, onCopy, onEdit, id }: ActionsProps) => {
  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDelete && onDelete(id);
  };
  const handleCopy = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCopy && onCopy(id);
  };
  const handleEdit = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onEdit && onEdit(id);
  };

  return (
    <Box
      sx={{ margin: '0 0 0 auto', color: theme => theme.palette.primary.main }}
      className="DataGrid-rowActions"
    >
      <IconButton
        size="small"
        color="inherit"
        onClick={handleCopy}
      >
        <CopyIcon />
      </IconButton>
      <IconButton
        size="small"
        color="inherit"
        onClick={handleEdit}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        size="small"
        color="inherit"
      >
        <ShareOutlinedIcon />
      </IconButton>
      <IconButton
        size="small"
        color="inherit"
        onClick={handleDelete}
      >
        <BasketIcon />
      </IconButton>
    </Box>
  );
};
