import { createApi, createStore } from 'effector';

import { SocEcoInfrastructureType } from '@widgets/widget-soceco-indicators/constants/map/SocEcoInfrastructureType';
import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';

const initState: LayersVisibleStore = {
  [SOCECO_TABLE_IDS.TABLE_1_1]: false,
  [SOCECO_TABLE_IDS.TABLE_2_2]: false,
  [SOCECO_TABLE_IDS.TABLE_2_5]: false,
  [SOCECO_TABLE_IDS.TABLE_3_1]: false,
  [SOCECO_TABLE_IDS.TABLE_3_2]: false,
  [SOCECO_TABLE_IDS.TABLE_3_4]: false,
  [SOCECO_TABLE_IDS.TABLE_4_2]: false,
  [SOCECO_TABLE_IDS.TABLE_5_2]: false,
  [SOCECO_TABLE_IDS.TABLE_5_3]: false,
};

export const $LayersVisible = createStore<LayersVisibleStore>(initState);

export const LayersVisibleApi = createApi($LayersVisible, {
  setVisible: (state, payload: Partial<LayersVisibleStore>) => ({
    ...state,
    ...payload,
  }),
  reset: () => initState,
});

export type LayersVisibleStore = Record<
  keyof typeof SocEcoInfrastructureType,
  boolean | undefined
>;
