import { apiClient } from '@api/client';
import {
  IQuery,
  IQueryGetTransportDistrictCenterInstanceByIdArgs,
  IQueryGetTransportDistrictInstanceByIdArgs,
  IQueryGetTransportZoneCenterInstanceByIdArgs,
  IQueryGetTransportZoneInstanceByIdArgs,
} from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';
import { EMapFeatureLayout } from '@constants/map';

import { FilterMapApi } from '@features/ag-project/stores/filters';
import {
  $AgGeometry,
  LayersVisibleApi,
  SelectedFeaturesApi,
  SetSelectedFeaturesPayload,
  SetVisiblePayload,
  clickMap,
  pipeMapControls,
} from '@features/ag-project/stores/map';
import {
  $SearchInfra,
  SearchInfraApi,
  SearchInfraStore,
  getTransportDistrictFx,
  getTransportZoneFx,
} from '@features/ag-project/stores/map/searchInfra/store';

import {
  EPipeMapControls,
  PipeMapControls,
} from '@utils/map/hooks/useInitMapControls';

import '../store';

// Если выбранная опция соответствует типу - транспортный регион
sample({
  clock: SearchInfraApi.setSelectedOption,
  filter: payload => payload.type === 'distict',
  fn: payload => {
    return {
      id: payload.value,
    } as IQueryGetTransportDistrictInstanceByIdArgs;
  },
  target: getTransportDistrictFx,
});

// Получение геометрии транспортного региона по id
getTransportDistrictFx.use(async params => {
  const response = await apiClient.query<
    Pick<IQuery, 'getTransportDistrictCenterInstanceById'>,
    IQueryGetTransportDistrictCenterInstanceByIdArgs
  >({
    query: gql`
      query QueryGetTransportDistrictInstanceById($id: String!) {
        getTransportDistrictCenterInstanceById(id: $id) {
          district {
            geometry
            id
          }
        }
      }
    `,
    variables: {
      id: params.id,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data.getTransportDistrictCenterInstanceById || null;
});

// Запись полученной геометрии транспортного региона
sample({
  clock: getTransportDistrictFx.done,
  fn: payload => {
    return {
      railwayStation: null,
      transportZone: null,
      transportDistrict: {
        geometry: payload.result?.district?.geometry?.coordinates[0],
        id: payload.result?.district?.id,
      },
    } as SearchInfraStore['features'];
  },
  target: SearchInfraApi.setGeometry,
});

// Если выбранная опция соответствует типу - транспортная зона
sample({
  clock: SearchInfraApi.setSelectedOption,
  filter: payload => payload.type === 'zone',
  fn: payload => {
    return {
      id: payload.value,
    } as IQueryGetTransportZoneInstanceByIdArgs;
  },
  target: getTransportZoneFx,
});

// Получение геометрии транспортной зоны по id
getTransportZoneFx.use(async params => {
  const response = await apiClient.query<
    Pick<IQuery, 'getTransportZoneCenterInstanceById'>,
    IQueryGetTransportZoneCenterInstanceByIdArgs
  >({
    query: gql`
      query QueryGetTransportZoneInstanceById($id: String!) {
        getTransportZoneCenterInstanceById(id: $id) {
          zone {
            geometry
          }
        }
      }
    `,
    variables: {
      id: params.id,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data.getTransportZoneCenterInstanceById || null;
});

// Запись полученной геометрии транспортной зоны
sample({
  clock: getTransportZoneFx.done,
  fn: payload => {
    return {
      transportDistrict: null,
      railwayStation: null,
      transportZone: {
        geometry: payload.result?.zone?.geometry?.coordinates[0],
      },
    } as SearchInfraStore['features'];
  },
  target: SearchInfraApi.setGeometry,
});

// найти фичу жд станции по id
sample({
  clock: SearchInfraApi.setSelectedOption,
  source: { AgGeometry: $AgGeometry },
  filter: (_, payload) => payload.type === 'station',
  fn: ({ AgGeometry }, payload) => {
    const geometry = AgGeometry.railwayStations?.find(
      station => station.id === payload.value,
    );
    return {
      transportDistrict: null,
      transportZone: null,
      railwayStation: geometry || null,
    } as SearchInfraStore['features'];
  },
  target: SearchInfraApi.setGeometry,
});

// Управление видимостью слоя
sample({
  clock: $SearchInfra,
  fn: payload => {
    return {
      [EMapFeatureLayout.agSearchInfra]: !!payload.selectedOption,
    } as SetVisiblePayload;
  },
  target: LayersVisibleApi.setVisible,
});

// отцентрировать карту
sample({
  clock: SearchInfraApi.setGeometry,
  source: { SearchInfra: $SearchInfra },
  filter: ({ SearchInfra }) => !!SearchInfra.selectedOption,
  fn: ({ SearchInfra }) => {
    return {
      action: EPipeMapControls.center,
      payload: SearchInfra.selectedOption!.lonLat,
    } as PipeMapControls;
  },
  target: pipeMapControls,
});

// Отзумировать карту
sample({
  clock: SearchInfraApi.setGeometry,
  source: { SearchInfra: $SearchInfra },
  filter: ({ SearchInfra }) => !!SearchInfra.selectedOption,
  fn: ({ SearchInfra }) => {
    return {
      action: EPipeMapControls.setZoom,
      payload: SearchInfra.selectedOption!.type === 'station' ? 16 : 13,
    } as PipeMapControls;
  },
  target: pipeMapControls,
});

// Сброс поиска при изменении фильтров или клике на карте
sample({
  clock: [clickMap, FilterMapApi.setFilters],
  target: SearchInfraApi.reset,
});
//
// // Сброс поиска при выборе другой фиче на карте // TODO: мб лишнее
// sample({
//   clock: SelectedFeaturesApi.setSelectedFeatures,
//   filter: payload => !!payload,
//   target: SearchInfraApi.reset,
// });

// Запросить информацию о выделенной фиче для инспектора
sample({
  clock: SearchInfraApi.setGeometry,
  source: { SearchInfra: $SearchInfra },
  filter: ({ SearchInfra }) =>
    !!SearchInfra.selectedOption &&
    SearchInfra.selectedOption!.type !== 'zone' &&
    (!!SearchInfra.features.transportDistrict ||
      !!SearchInfra.features.transportZone ||
      !!SearchInfra.features.railwayStation),
  fn: ({ SearchInfra }) => {
    return {
      id:
        SearchInfra.selectedOption!.type === 'distict'
          ? SearchInfra.features.transportDistrict!.id
          : SearchInfra.selectedOption!.value,
      layout:
        SearchInfra.selectedOption!.type === 'distict'
          ? EMapFeatureLayout.transportDistricts
          : SearchInfra.selectedOption!.type === 'station'
          ? EMapFeatureLayout.agRailwayStations
          : EMapFeatureLayout.transportZones,
    } as SetSelectedFeaturesPayload;
  },
  target: SelectedFeaturesApi.setSelectedFeatures,
});
