import { SyntheticEvent, useCallback } from 'react';

import { BaseFilterSlotProps } from '@components/MapFilters/interface';

export const useMapFiltersHandlers = (props: BaseFilterSlotProps) => {
  const { setFields, fields } = props;

  const setRailwayRootHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFields({
        railwayRoot: checked,
        nightTrain: checked,
        dayTrain: checked,
        suburbanTrain: checked,
        expressTrain: checked,
      });
    },
    [setFields],
  );

  const setNightTrainHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFields({
        nightTrain: checked,
        railwayRoot:
          fields.dayTrain ||
          fields.suburbanTrain ||
          fields.expressTrain ||
          checked,
      });
    },
    [fields.dayTrain, fields.expressTrain, fields.suburbanTrain, setFields],
  );

  const setDayTrainHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFields({
        dayTrain: checked,
        railwayRoot:
          fields.nightTrain ||
          fields.suburbanTrain ||
          fields.expressTrain ||
          checked,
      });
    },
    [fields.expressTrain, fields.nightTrain, fields.suburbanTrain, setFields],
  );

  const setSuburbanTrainHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFields({
        suburbanTrain: checked,
        railwayRoot:
          fields.nightTrain ||
          fields.dayTrain ||
          fields.expressTrain ||
          checked,
      });
    },
    [fields.dayTrain, fields.expressTrain, fields.nightTrain, setFields],
  );

  const setExpressTrainHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFields({
        expressTrain: checked,
        railwayRoot:
          fields.nightTrain ||
          fields.dayTrain ||
          fields.suburbanTrain ||
          checked,
      });
    },
    [fields.dayTrain, fields.nightTrain, fields.suburbanTrain, setFields],
  );

  const setAutoRootHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFields({
        autoRoot: checked,
        bus: checked,
        auto: checked,
      });
    },
    [setFields],
  );

  const setBusHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFields({
        bus: checked,
        autoRoot: fields.auto || checked,
      });
    },
    [fields.auto, setFields],
  );

  const setAutoHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFields({
        auto: checked,
        autoRoot: fields.bus || checked,
      });
    },
    [fields.bus, setFields],
  );

  const setAviaHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFields({ avia: checked });
    },
    [setFields],
  );

  return {
    setRailwayRootHandler,
    setNightTrainHandler,
    setDayTrainHandler,
    setSuburbanTrainHandler,
    setExpressTrainHandler,
    setAutoRootHandler,
    setBusHandler,
    setAutoHandler,
    setAviaHandler,
  };
};
