import { sample } from 'effector';
import { Feature } from 'ol';
import { MultiLineString } from 'ol/geom';

import { EMapFeatureLayout } from '@constants/map';

import {
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

import { recursivelyFromLonLat } from '@utils/geometry';
import {
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';

import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';
import { pipeChangingRoutesVectorLayer } from '@widgets/widget-soceco-indicators/stores/map';
import { SocEcoInfrastructureApi } from '@widgets/widget-soceco-indicators/stores/map/infrastructure';
import { LayersVisibleApi } from '@widgets/widget-soceco-indicators/stores/map/layersVisible';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload => Object.keys(payload).includes(SOCECO_TABLE_IDS.TABLE_5_2),
  fn: payload => {
    return {
      action: EPipeVectorLayer.setVisible,
      payload: payload[SOCECO_TABLE_IDS.TABLE_5_2],
    } as PipeVectorLayer;
  },
  target: pipeChangingRoutesVectorLayer,
});

// Размещение геометрии на слое
sample({
  clock: SocEcoInfrastructureApi.setFeatures,
  filter: payload => payload.type === SOCECO_TABLE_IDS.TABLE_5_2,
  fn: payload => {
    const features: Feature<MultiLineString>[] = [];

    payload.data.length &&
      payload.data.forEach(feature => {
        features.push(
          new Feature({
            geometry: new MultiLineString(
              recursivelyFromLonLat(feature.geometry.multiline!),
            ),
            [layerIdKey]: feature.id,
            [layerLayoutKey]: EMapFeatureLayout.changingRoutes,
          }),
        );
      });

    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: {
        features,
      },
    } as PipeVectorLayer;
  },
  target: pipeChangingRoutesVectorLayer,
});
