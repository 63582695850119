import { Box, Grid, Typography } from '@mui/material';

export type GradientLineItemProps = {
  label: string;
};

export const GradientLineItem = (props: GradientLineItemProps) => {
  const { label } = props;

  return (
    <Grid
      display={'grid'}
      container
      gridTemplateColumns={'2fr 10fr'}
      gap={2}
      alignItems={'center'}
    >
      <Box
        sx={theme => ({
          // border: '5px solid',
          // borderColor: 'linear-gradient(90deg, red, green)',
          // borderRadius: theme.shape.borderRadius,
          background: 'linear-gradient(to right, red, #ac9206, green)',
          width: theme.typography.pxToRem(48),
          height: theme.typography.pxToRem(2),
          justifySelf: 'center',
        })}
      />
      <Typography
        variant="body2"
        color="text.secondary"
      >
        {label}
      </Typography>
    </Grid>
  );
};
