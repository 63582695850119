import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
} from '@mui/material';

import {
  $ModalsPtConstructor,
  ModalsPtConstructor,
} from '@features/pt-constructor/store';

export type ModalReCalculationAction = (result: boolean) => void;

export type ModalEditCancellationProps = {
  onClose: () => void;
};

export const ModalReCalculation = (props: ModalEditCancellationProps) => {
  const { onClose } = props;
  const $modalsPtConstructor = useUnit(
    $ModalsPtConstructor,
  ) as ModalsPtConstructor<ModalReCalculationAction>;

  const handleClickCopy = useCallback(() => {
    $modalsPtConstructor.action && $modalsPtConstructor.action(false);
    onClose();
  }, [$modalsPtConstructor, onClose]);

  const handleClickRewrite = useCallback(() => {
    $modalsPtConstructor.action && $modalsPtConstructor.action(true);
    onClose();
  }, [$modalsPtConstructor, onClose]);

  return (
    <Dialog open={true}>
      <DialogTitle
        variant="subtitle2"
        sx={{
          position: 'relative',
          fontSize: theme => theme.typography.pxToRem(16),
          pr: 10,
        }}
      >
        Ранее рассчитанный прогноз будет удален. Уверены?
      </DialogTitle>

      <DialogActions>
        <Stack
          direction="row"
          justifyContent="flex-end"
          minWidth="512px"
          gap={1}
        >
          <Button
            color={'secondary'}
            variant={'contained'}
            onClick={handleClickCopy}
          >
            Создать копию расчета
          </Button>

          <Button
            variant={'outlined'}
            onClick={handleClickRewrite}
          >
            Перезаписать
          </Button>

          <Button
            variant={'outlined'}
            onClick={onClose}
          >
            Отмена
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
