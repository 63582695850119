import { Box } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { InspectorContainer } from './containers/InspectorContainer/InspectorContainer';
import { MapContainer } from './containers/MapContainer/MapContainer';
import './stores';

type WidgetPassengerFlowContainerProps = {
  regionUUID: string;
};

export const WidgetPassengerFlowContainer = (
  props: WidgetPassengerFlowContainerProps,
) => {
  const { regionUUID } = props;

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <AutoFullHeight>
        <InspectorContainer />
        <MapContainer regionUUID={regionUUID} />
      </AutoFullHeight>
    </Box>
  );
};
