import { IDataFileStateEnum } from '@api/gql/tpu-types';
import { useRef, useState } from 'react';

import { Actions } from './Actions';

import { Stack, SxProps, Typography } from '@mui/material';

import { DragButton } from '@features/tpu-project-registry/components/project-form/FileUploader/DragButton';
import { Label } from '@features/tpu-project-registry/components/project-form/FileUploader/Label';
import { UseProjectRegistryHandlers } from '@features/tpu-project-registry/hooks';

export interface FileUploaderProps {
  label: string;
  /** В формате '.jpeg, .png, .xls' */
  accept: string;
  uuid?: string;
  draggable?: boolean;
  sx?: SxProps;
  fileStatus?: IDataFileStateEnum | null;
  errorText?: string | null;

  onDownloadFile: () => void;
  onFileChange: (file: File | undefined, signal: AbortSignal) => Promise<void>;
  onLabelChange?: (arg: string) => void;
  onDelete: () => void;
  changeFloorFilesOrder?: UseProjectRegistryHandlers['changeFloorFilesOrder'];
}

export const FileUploader = ({
  label,
  accept,
  sx,
  uuid,
  draggable,
  fileStatus,
  errorText,
  onDownloadFile,
  onLabelChange,
  onFileChange,
  changeFloorFilesOrder,
  onDelete,
}: FileUploaderProps) => {
  const controllerRef = useRef<AbortController>();
  const isDraggingAllowed = useRef(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = async (file: File | undefined) => {
    try {
      setIsLoading(true);

      const controller = new AbortController();
      controllerRef.current = controller;

      await onFileChange(file, controller.signal);
    } finally {
      setIsLoading(false);
    }
  };

  const onCancelUploading = () => controllerRef.current?.abort();

  return (
    <Stack
      direction="row"
      draggable={draggable}
      id={uuid}
      onDrop={e => {
        e.preventDefault();

        const transferId = e.dataTransfer.getData('uuid');

        if (transferId && uuid && changeFloorFilesOrder) {
          changeFloorFilesOrder({ catchId: uuid, transferId });
        }
      }}
      onDragOver={e => e.preventDefault()}
      onDragStart={e => {
        if (!isDraggingAllowed.current || !uuid) {
          e.preventDefault();
          return;
        }
        isDraggingAllowed.current = false;
        e.dataTransfer.setData('uuid', uuid);
      }}
      sx={{
        ...sx,
        display: 'flex',
        alignItems: 'center',
        border: theme => `1px solid ${theme.palette.border.divider}`,
        fontSize: theme => theme.typography.pxToRem(12),
        lineHeight: theme => theme.typography.pxToRem(16),
        padding: '16px',
        borderRadius: '4px',
        textAlign: 'center',

        '&:hover': {
          backgroundColor: theme => theme.palette.customs.bgHover,
        },
      }}
    >
      <Label
        value={label}
        onLabelChange={onLabelChange}
      />
      <Typography
        variant="body2"
        component="span"
        fontSize="inherit"
        sx={{
          color: theme => theme.palette.text.secondary,
          mr: 'auto',
          ml: '5px',
        }}
      >
        ({accept})
      </Typography>

      <Actions
        status={fileStatus}
        accept={accept}
        onDownloadFile={onDownloadFile}
        isLoading={isLoading}
        errorText={errorText}
        onFileChange={handleFileChange}
        onCancel={onCancelUploading}
        onDelete={onDelete}
      />

      <DragButton
        isHidden={!draggable}
        buttonProps={{
          onMouseDown: () => (isDraggingAllowed.current = true),
          onMouseUp: () => (isDraggingAllowed.current = false),
        }}
      />
    </Stack>
  );
};
