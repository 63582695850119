import { sample } from 'effector';
import { Feature } from 'ol';
import { Geometry, LineString, Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EMapFeatureLayout } from '@constants/map';

import {
  $SelectedFeatures,
  initSources,
  pipeTpuGraphsLayer,
} from '@features/ag-forecast/stores/map';
import {
  $EditorMap,
  EditorMapApi,
} from '@features/ag-forecast/stores/map/editor';
import {
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

import { recursivelyFromLonLat } from '@utils/geometry';
import {
  EFeatureStyle,
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';

type GraphItem = {
  geometry: number[][];
  id: string;
};

// рендер графов
sample({
  clock: [
    EditorMapApi.addGraphNode,
    EditorMapApi.addGraph,
    EditorMapApi.clearDrawGraph,
    EditorMapApi.setInfrastructure,
    EditorMapApi.removeTpu,
    EditorMapApi.removeGraph,
    initSources,
  ],
  source: { EditorMap: $EditorMap },
  fn: ({ EditorMap }) => {
    const features: Feature<Geometry>[] = [];
    const graphs: GraphItem[] = [];

    // Добавить в набор временный граф
    if (EditorMap.drawGraph?.nodes) {
      graphs.push({
        id: 'temp',
        geometry: recursivelyFromLonLat(
          EditorMap.drawGraph.nodes,
        ) as unknown as number[][],
      });
    }

    // добавить в набор постоянные графы
    if (EditorMap.graphs) {
      EditorMap.graphs.forEach(graph => {
        if (!graph.delete)
          graphs.push({
            id: graph.id,
            geometry: recursivelyFromLonLat(
              graph.geometry.map(({ lon, lat }) => [lon, lat]),
            ) as unknown as number[][],
          });
      });
    }

    // Создание фич линий
    graphs.forEach(graph => {
      features.push(
        new Feature({
          geometry: new LineString(graph.geometry),
          [layerIdKey]: graph.id,
          [layerLayoutKey]: EMapFeatureLayout.tpuGraphs,
        }),
      );
    });

    // Создание фич точек
    graphs.forEach(graph =>
      graph.geometry.forEach(coords =>
        features.push(
          new Feature({
            geometry: new Point(coords),
            [layerIdKey]: graph.id,
            [layerLayoutKey]: EMapFeatureLayout.tpuGraphs,
          }),
        ),
      ),
    );

    if (EditorMap.drawGraph?.nodes) {
      features.push(
        new Feature({
          geometry: new LineString(
            recursivelyFromLonLat(
              EditorMap.drawGraph.nodes,
            ) as unknown as number[][],
          ),
          [layerIdKey]: 'temp-graph',
          [layerLayoutKey]: EMapFeatureLayout.tpuGraphs,
        }),
      );

      EditorMap.drawGraph.nodes.forEach((node, index) => {
        features.push(
          new Feature({
            geometry: new Point(fromLonLat(node)),
            [layerIdKey]: `temp-point-${index}`,
            [layerLayoutKey]: EMapFeatureLayout.tpuGraphs,
          }),
        );
      });
    }

    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: { features },
    } as PipeVectorLayer;
  },
  target: pipeTpuGraphsLayer,
});

// применение стилей к графу тпу
sample({
  clock: [$SelectedFeatures],
  source: { SelectedFeatures: $SelectedFeatures },
  fn: ({ SelectedFeatures }) => {
    const selectedGraph = SelectedFeatures.find(
      item => item.layout === EMapFeatureLayout.tpuGraphs,
    );

    return {
      action: EPipeVectorLayer.setFeatureStyle,
      payload: {
        featureStyles: selectedGraph
          ? {
              [selectedGraph.id]: EFeatureStyle.selected,
            }
          : {},
      },
    } as PipeVectorLayer;
  },
  target: pipeTpuGraphsLayer,
});
