import { useForm } from 'react-hook-form';
import { CartesianGrid, LineChart, XAxis, YAxis } from 'recharts';

import { Box, Button, Typography } from '@mui/material';

import { CloseIcon } from '@components/icons';

import {
  InputPassengerShare,
  SliderShare,
} from '@features/tpu-passenger-types/components';
import { CATEGORY_TYPE_TRANSLATE } from '@features/tpu-passenger-types/constants';
import { IPassengerShareForm } from '@features/tpu-passenger-types/types';
import { recountShare } from '@features/tpu-passenger-types/utils';

const CHART_DATA = [...new Array(11)].map((_, i) => ({ percent: i * 10 }));

interface PassengerShareFormProps {
  formData: IPassengerShareForm;
  onClose(): void;
  onSave(payload: IPassengerShareForm): void;
}

export const PassengerShareForm = ({
  formData,
  onSave,
  onClose,
}: PassengerShareFormProps) => {
  const { watch, control, setValue, handleSubmit } =
    useForm<IPassengerShareForm>({
      values: formData,
    });

  const formValues = watch();
  const { shareInfo } = formValues;

  const recount = (changedItemId: string) => {
    setValue('shareInfo', recountShare(shareInfo, changedItemId));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '24px',
        width: '100%',
      }}
    >
      <Button
        sx={{
          width: '214px',
        }}
        onClick={handleSubmit(onSave)}
      >
        Сохранить
      </Button>
      <Box
        sx={{
          boxShadow: '0px 0px 8px 0px #00000026',
          borderRadius: '8px',
          backgroundColor: theme => theme.palette.background.paper,
          padding: '24px',
          overflow: 'auto',
          width: '100%',
        }}
      >
        <Box
          sx={{
            gap: '12px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '10px',
              minHeight: '48px',
            }}
          >
            <Box>
              <Typography
                component="span"
                sx={{
                  fontSize: theme => theme.typography.pxToRem(16),
                  fontWeight: '600',
                  mr: '6px',
                }}
              >
                Доли типов пассажиров на ТПУ
              </Typography>

              <Typography
                component="span"
                sx={{
                  fontSize: theme => theme.typography.pxToRem(16),
                  color: theme => theme.palette.text.secondary,
                  fontWeight: '600',
                }}
              >
                ({CATEGORY_TYPE_TRANSLATE[formData.category]})
              </Typography>
            </Box>

            <Button
              variant="text"
              onClick={onClose}
              sx={{
                padding: '10px',
                minWidth: 'unset',
                width: '44px',
                height: '44px',
              }}
            >
              <CloseIcon />
            </Button>
          </Box>

          <Box
            sx={{
              position: 'relative',
              width: '600px',
              height: '222px',
              ml: '25px',
            }}
          >
            <Typography
              sx={{
                position: 'absolute',
                transform: 'rotate(-90deg)',
                left: '-13%',
                top: '35%',
                textAlign: 'center',
                fontSize: theme => theme.typography.pxToRem(14),
                color: theme => theme.palette.text.secondary,
              }}
            >
              Типы пассажиров
            </Typography>

            <LineChart
              data={CHART_DATA}
              width={600}
              height={200}
              margin={{ left: -20 }}
            >
              <CartesianGrid strokeDasharray="0 0" />
              <XAxis dataKey="percent" />
              <YAxis />
            </LineChart>

            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '170px',
                pl: '40px',
                overflowX: 'hidden',
                top: 0,
                '::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              {shareInfo.map((item, index) => (
                <SliderShare
                  key={item.id}
                  control={control}
                  fieldName={`shareInfo.${index}.share`}
                  agentShareInfo={item}
                  recount={recount}
                />
              ))}
            </Box>
          </Box>

          <Typography
            sx={{
              mt: '-24px',
              mb: '10px',
              textAlign: 'center',
              fontSize: theme => theme.typography.pxToRem(14),
              color: theme => theme.palette.text.secondary,
            }}
          >
            Количество пассажиров, %
          </Typography>

          {shareInfo.map((item, index) => (
            <InputPassengerShare
              key={item.id}
              control={control}
              agentShareInfo={item}
              fieldName={`shareInfo.${index}.share`}
              recount={recount}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
