import { useUnit } from 'effector-react';
import { ChangeEvent, useCallback } from 'react';

import { TextField, Typography } from '@mui/material';

import {
  $CurrentProject,
  CurrentProjectApi,
} from '@features/ag-projects-registry/store/currentProject';
import {
  $Errors,
  ErrorsApi,
} from '@features/ag-projects-registry/store/errors';
import { $Settings } from '@features/ag-projects-registry/store/settings/store';
import { checkingErrorsProjectName } from '@features/ag-projects-registry/utils/validation';

export const ProjectName = () => {
  const currentAgglomeration = useUnit($CurrentProject);
  const { readonly } = useUnit($Settings);
  const { setProjectName } = useUnit(CurrentProjectApi);
  const { errorsName, isCheckErrors } = useUnit($Errors);
  const { setErrorsName } = useUnit(ErrorsApi);

  const handleChangeName = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setProjectName(event.target.value);

      if (isCheckErrors) {
        setErrorsName(checkingErrorsProjectName(event.target.value));
      }
    },
    [isCheckErrors, setErrorsName, setProjectName],
  );

  if (!currentAgglomeration) return null;

  return (
    <>
      <TextField
        placeholder="Название проекта"
        disabled={readonly}
        value={currentAgglomeration.name}
        onChange={handleChangeName}
        sx={{ mt: 1.25 }}
      />

      {isCheckErrors &&
        errorsName.map(error => (
          <Typography
            key={error}
            variant="caption"
            sx={{
              color: theme => theme.palette.secondary.main,
              mt: 1,
            }}
          >
            {error}
          </Typography>
        ))}
    </>
  );
};
