import styled from '@emotion/styled';
import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Stack } from '@mui/material';

import { PlusIcon } from '@components/icons';

import { ROUTES } from '@constants/routes';

import {
  Draft,
  ErrorInfo,
  Running,
  Success,
} from '@features/ag-constructor/components/StatusChips';
import { Queue } from '@features/ag-constructor/components/StatusChips/Queue';
import { EAgCalculationScenarioStatusType } from '@features/ag-constructor/constants/agCalculationScenarioStatuses';
import {
  $CurrentScenario,
  CurrentScenarioApi,
  createScenarioFx,
  runComputeScenarioFx,
  updateScenarioFx,
} from '@features/ag-constructor/stores/currentScenario';
import { $CalculationStatuses } from '@features/ag-constructor/stores/lists/statuses';
import { $FeatureSettings } from '@features/ag-constructor/stores/settings';

export const ToolBarContainer = () => {
  const { createEmptyScenario } = useUnit(CurrentScenarioApi);
  const { projectUUID } = useUnit($FeatureSettings);
  const $currentScenario = useUnit($CurrentScenario);
  const $calculationStatuses = useUnit($CalculationStatuses);

  const navigate = useNavigate();
  // const { beforeDeleteHandler } = useConfirms()

  // Статус выбранного сценария
  const calculationStatus = useMemo(() => {
    if (!$currentScenario) return null;
    const status = $calculationStatuses.statuses.find(
      e => e.id === $currentScenario.id,
    )?.status;
    if (!status) return null;

    return status;
  }, [$calculationStatuses.statuses, $currentScenario]);

  const isEditMode = !$currentScenario?.readonly;
  const isSelectedScenario = !!$currentScenario;
  const isPending =
    calculationStatus === EAgCalculationScenarioStatusType.Pending ||
    calculationStatus === EAgCalculationScenarioStatusType.Queue;
  const isSuccess =
    calculationStatus === EAgCalculationScenarioStatusType.Success;

  const handleClickGoToResult = useCallback(() => {
    navigate(
      `${ROUTES.AGGLOMERATION_FORECAST}?projectId=${projectUUID}&scenarioId=${$currentScenario?.id}`,
    );
  }, [$currentScenario?.id, navigate, projectUUID]);

  const handleClickBack = useCallback(() => {
    navigate(`${ROUTES.AGGLOMERATION_PROJECT}?projectId=${projectUUID}`);
  }, [navigate, projectUUID]);

  const handleClickCreateScenario = useCallback(() => {
    createScenarioFx({
      name: $currentScenario?.name || '',
      projectId: projectUUID,
      socEcoScenarioId: $currentScenario?.socEcoScenario?.id,
      takeIntoInfra: $currentScenario?.takeIntoInfra || false,
    });
  }, [
    $currentScenario?.name,
    $currentScenario?.socEcoScenario?.id,
    $currentScenario?.takeIntoInfra,
    projectUUID,
  ]);

  const handleUpdateScenario = useCallback(() => {
    if (!$currentScenario?.id) return;

    updateScenarioFx({
      name: $currentScenario?.name || '',
      scenarioId: $currentScenario?.id,
      socEcoScenario: $currentScenario?.socEcoScenario?.id,
      takeIntoInfra: $currentScenario?.takeIntoInfra || false,
    });
  }, [
    $currentScenario?.id,
    $currentScenario?.name,
    $currentScenario?.socEcoScenario?.id,
    $currentScenario?.takeIntoInfra,
  ]);

  const handleCalculationClick = useCallback(() => {
    if (!$currentScenario?.id) return;
    runComputeScenarioFx({
      scenarioId: $currentScenario?.id,
    });
  }, [$currentScenario?.id]);

  const StatusComponent = useMemo(() => {
    switch (calculationStatus) {
      case EAgCalculationScenarioStatusType.Draft:
        return () => <Draft />;
      case EAgCalculationScenarioStatusType.Failure:
        return () => <ErrorInfo errorMessage={'Неизвестная ошибка'} />;
      case EAgCalculationScenarioStatusType.Pending:
        return () => <Running />;
      case EAgCalculationScenarioStatusType.Success:
        return () => <Success />;
      case EAgCalculationScenarioStatusType.Queue:
        return () => <Queue />;
      default:
        return () => null;
    }
  }, [calculationStatus]);

  return (
    <Wrapper>
      <Stack
        direction="row"
        gap={3}
      >
        <Button
          variant="text"
          onClick={handleClickBack}
          sx={{
            [`& .MuiButton-startIcon > *:nth-of-type(1)`]: {
              fontSize: '12px',
            },
          }}
          startIcon={<ArrowBackIcon />}
          color={'info'}
        >
          Назад
        </Button>

        <Button
          variant={'contained'}
          color={'secondary'}
          sx={{
            gap: 1,
          }}
          onClick={createEmptyScenario}
        >
          <>
            <PlusIcon />
            Создать сценарий
          </>
        </Button>
      </Stack>
      <Stack
        direction="row"
        sx={{
          columnGap: 2,
        }}
      >
        {isSelectedScenario && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: 'auto',
              gap: '30px',
            }}
          >
            {isEditMode && !$currentScenario.id && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickCreateScenario}
              >
                Сохранить параметры
              </Button>
            )}

            {isEditMode && $currentScenario.id && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleUpdateScenario}
              >
                Перезаписать параметры
              </Button>
            )}

            {!isEditMode && (
              <>
                <StatusComponent />
                {isSuccess ? (
                  <Button
                    color="secondary"
                    onClick={handleClickGoToResult}
                  >
                    Перейти к расчету
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    onClick={handleCalculationClick}
                    disabled={isPending}
                  >
                    Рассчитать
                  </Button>
                )}
              </>
            )}
          </Box>
        )}
      </Stack>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 0, 0, 0),
  gap: theme.spacing(3),
  flexDirection: 'row',
  justifyContent: 'space-between',
  display: 'flex',
}));
