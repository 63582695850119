export const TILE_SERVICE_LAYERS = {
  COMPUTED_GRAPHS: {
    LAYER: 'public.transport_flow_graph_view_v5',
    PATH: 'transport_flow_graph_view_v5',
  },
  BASE_GRAPHS: {
    LAYER: 'public.transport_graph_view_v4',
    PATH: 'transport_graph_view_v4',
  },
  TRANSPORT_DISTRICTS: {
    LAYER: 'public.infrastructure_transportdistrictinstance_v',
    PATH: 'infrastructure_transportdistrictinstance_v',
  },
  AREA_BORDER_PASSENGER_FLOW: {
    LAYER: 'public.railway_connected_regions_v',
    PATH: 'railway_connected_regions_v',
  },
  PATH_PASSENGER_FLOW: {
    LAYER: 'public.railway_outer_directions',
    PATH: 'railway_outer_directions',
  },
  TEST: {
    LAYER: 'public.il_transport_graph_view3',
    PATH: 'il_transport_graph_view3',
  },
};
