export const SOCECO_TABLE_IDS = {
  TABLE_1_1: '1-1',
  TABLE_1_2: '1-2',
  TABLE_1_3: '1-3',
  TABLE_1_4: '1-4',
  TABLE_1_5: '1-5',

  TABLE_2_1: '2-1',
  TABLE_2_2: '2-2',
  TABLE_2_3: '2-3',
  TABLE_2_4: '2-4',
  TABLE_2_5: '2-5',

  TABLE_3_1: '3-1',
  TABLE_3_2: '3-2',
  TABLE_3_3: '3-3',
  TABLE_3_4: '3-4',
  TABLE_3_5: '3-5',
  TABLE_3_6: '3-6',
  TABLE_3_7: '3-7',

  TABLE_4_1: '4-1',
  TABLE_4_2: '4-2',
  TABLE_4_3: '4-3',
  TABLE_4_4: '4-4',

  TABLE_5_1: '5-1',
  TABLE_5_2: '5-2',
  TABLE_5_3: '5-3',
  TABLE_5_4: '5-4',
  TABLE_5_5: '5-5',
  TABLE_5_6: '5-6',
  TABLE_5_7: '5-7',
  TABLE_5_8: '5-8',

  TABLE_6_1: '6-1',
  TABLE_6_2: '6-2',
  TABLE_6_3: '6-3',

  TABLE_7_1: '7-1',
  TABLE_7_2: '7-2',
  TABLE_7_3: '7-3',
  TABLE_7_4: '7-4',
  TABLE_7_5: '7-5',
};
