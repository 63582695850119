import { Table, TableContainer } from '@mui/material';

import { TableComparisonTPUBody } from './TableComparisonTPUBody';
import { TableComparisonTPUHead } from './TableComparisonTPUHead';

export const TableTPU = () => {
  return (
    <TableContainer
      sx={{
        maxHeight: '500px',
        overflow: 'auto',
      }}
    >
      <Table
        size="medium"
        aria-labelledby="tableTitle"
        sx={{
          minWidth: 750,
          width: '100%',
          overflow: 'scroll',
        }}
      >
        <TableComparisonTPUHead />

        <TableComparisonTPUBody />
      </Table>
    </TableContainer>
  );
};
