import { useUnit } from 'effector-react';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

import { FormLabel } from '@components/FormLabel';

import { CashedField } from '@features/ag-forecast/components/Inspector/TpuInspector/CashedField';
import {
  $EditorMap,
  EditorMapApi,
} from '@features/ag-forecast/stores/map/editor';
import { $UI } from '@features/ag-forecast/stores/ui';

export const TpuInspector = () => {
  const { selectedTpu, tpus } = useUnit($EditorMap);
  const { updateSelectedTpuParams, removeTpu } = useUnit(EditorMapApi);
  const { editTPUMode } = useUnit($UI);

  const [isAlt, setIsAlt] = useState(false);

  const selectedTpuItem = useMemo(() => {
    return tpus.find(tpu => tpu.id === selectedTpu);
  }, [selectedTpu, tpus]);

  const freeTpusOptions = useMemo(
    () => tpus.filter(item => !item.parentTpuId && item.id !== selectedTpu),
    [selectedTpu, tpus],
  );

  const isMainCurrentTpu = useMemo(
    () => !!tpus.find(tpu => tpu.parentTpuId === selectedTpu),
    [selectedTpu, tpus],
  );

  const changeNameHandler = useCallback(
    (value: string) => {
      updateSelectedTpuParams({ name: value });
    },
    [updateSelectedTpuParams],
  );

  const changeCapacityHandler = useCallback(
    (value: number) => {
      updateSelectedTpuParams({ capacity: value });
    },
    [updateSelectedTpuParams],
  );

  const changeFloorsNumberHandler = useCallback(
    (value: number) => {
      updateSelectedTpuParams({ floorsNumber: value });
    },
    [updateSelectedTpuParams],
  );

  const changeIsAltHandler = useCallback(
    (_: SyntheticEvent<Element, Event>, checked: boolean) => {
      if (checked) {
        setIsAlt(checked);
      } else {
        updateSelectedTpuParams({ parentTpuId: null });
      }
    },
    [updateSelectedTpuParams],
  );

  const changeParentTpuHandler = useCallback(
    (event: SelectChangeEvent<string | null>) => {
      updateSelectedTpuParams({ parentTpuId: event.target.value });
    },
    [updateSelectedTpuParams],
  );

  useEffect(() => {
    setIsAlt(!!selectedTpuItem?.parentTpuId);
  }, [selectedTpuItem?.parentTpuId]);

  if (!selectedTpu || !selectedTpuItem) return null;
  return (
    <Stack
      height="100%"
      direction="column"
      justifyContent="space-between"
    >
      <Stack>
        <Typography
          variant="subtitle2"
          mb={3}
        >
          Новый объект ТПУ
        </Typography>

        <Stack gap={2}>
          <CashedField
            id="tpuName"
            title="Наименование"
            placeholder="Введите название ТПУ"
            value={selectedTpuItem.name}
            onBlur={value => changeNameHandler(value as string)}
            disabled={!editTPUMode}
          />

          <CashedField
            id="tpuCapacity"
            title="Пропускная способность (пасс. / ч.)"
            placeholder="Введите пропускную способность для этого ТПУ"
            value={selectedTpuItem.capacity}
            onBlur={value => changeCapacityHandler(Number(value))}
            disabled={!editTPUMode}
            type="number"
          />

          <CashedField
            id="tpuFloorsNumber"
            title="Этажность"
            placeholder="Введите количество этажей для этого ТПУ"
            value={selectedTpuItem.floorsNumber}
            onBlur={value => changeFloorsNumberHandler(Number(value))}
            disabled={!editTPUMode}
            type="number"
          />

          <FormControlLabel
            disabled={!tpus.length || isMainCurrentTpu || !editTPUMode}
            sx={{
              pl: '2px',
            }}
            checked={isAlt}
            onChange={changeIsAltHandler}
            control={<Checkbox />}
            label={
              <Typography
                variant={'body2'}
                sx={{
                  opacity: !tpus.length || isMainCurrentTpu ? 0.5 : 1,
                }}
              >
                Альтернативный вариант ТПУ
              </Typography>
            }
          />

          {isAlt && (
            <FormLabel
              id="parentId"
              title="Основное ТПУ"
            >
              <Select
                value={selectedTpuItem.parentTpuId || ''}
                onChange={changeParentTpuHandler}
                disabled={!editTPUMode}
              >
                {freeTpusOptions &&
                  freeTpusOptions.map(option => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormLabel>
          )}
        </Stack>
      </Stack>
      {editTPUMode && (
        <Stack gap={2}>
          <Divider />
          <Button
            variant="text"
            color="error"
            onClick={() => removeTpu(selectedTpu)}
          >
            Удалить ТПУ
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
