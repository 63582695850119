import { useUnit } from 'effector-react';

import { Box, Typography } from '@mui/material';

import { $Floors } from '@features/tpu-simulation-model/store';
import { $ListOfRulers } from '@features/tpu-simulation-model/store/ruler/listOfRulers.store';
import { IPoint } from '@features/tpu-simulation-model/types';

interface AttributeProps {
  point: IPoint;
  floor: number;
}
export function ObjectPosition({ point, floor }: AttributeProps) {
  const $listOfRulers = useUnit($ListOfRulers) ?? {};
  const $floors = useUnit($Floors);
  const ruler = $listOfRulers[$floors.selectedFloor];
  const convertedX = ruler
    ? Math.round((point.x / ruler.px) * ruler.meters)
    : point.x;
  const convertedY = ruler
    ? Math.round((point.y / ruler.px) * ruler.meters)
    : point.y;

  return (
    <Box>
      <Typography>Расположение</Typography>
      <Typography
        sx={{
          color: props => props.palette.text.secondary,
          fontSize: theme => theme.typography.pxToRem(12),
          mt: '4px',
        }}
      >
        {floor} этаж, X: {convertedX} / Y: {convertedY}
      </Typography>
    </Box>
  );
}
