export const SOC_ECO_TH_5_6 = [
  {
    id: 'transportObject',
    label: 'Транспортный объект',
  },
  {
    id: 'transportArea',
    label: 'Транспортный район',
  },
  // {
  //   id: 'year',
  //   label: 'Год',
  // },
  {
    id: 'adjacentArea',
    label: 'Площадь прилегающей территории, кв. м.',
  },
  {
    id: 'costImprovementActivities',
    label: 'Стоимость мероприятий по благоустройству, млн. руб.',
  },
];
