export const SOC_ECO_TH_7_3 = [
  {
    id: 'transportObject',
    label: 'Транспортный объект',
  },
  {
    id: 'areaTPU',
    label: 'Площадь ТПУ, кв. м.',
  },
  {
    id: 'numberInteractiveMapsRequired',
    label: 'Число требуемых интерактивных карт, шт.',
  },
];
