import { sample } from 'effector';

import { CalculationStatusesApi, fetchLongStatusesFx } from './index';

import { getCalculationScenarioStatusType } from '@features/pt-constructor/constants/calculationScenarioStatusType';

import { queryGetUserScenarioStatuses } from '../../api/queryGetUserScenarioStatuses';

// Размапить статусы в коррекный формат
sample({
  clock: [fetchLongStatusesFx.done],
  fn: request =>
    request.result.map(scenario => ({
      id: scenario.id,
      status: getCalculationScenarioStatusType(scenario.resultStatus),
    })),
  target: CalculationStatusesApi.setCalculationScenarioStatuses,
});

// Получить статусы для всех сценариев с задержкой (для пулинга)
fetchLongStatusesFx.use(queryGetUserScenarioStatuses);
