import { apiClient } from '@api/client';
import { IQuery, IQueryGetProjectByIdArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';
import { FeatureLike } from 'ol/Feature';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';
import {
  EMapFeatureLayout,
  EMapFeatureMetaKeys,
  EMapMetaKeys,
} from '@constants/map';

import {
  $FilterMap,
  SetFiltersPayload,
} from '@features/ag-forecast/stores/filters';
import { TransportCategoriesApi } from '@features/ag-forecast/stores/lists/transportCategories';
import { $Vehicles } from '@features/ag-forecast/stores/lists/vehicles';
import {
  AgGeometryApi,
  AgGeometryStore,
  FetchAgGeometryFx,
  LayersVisibleApi,
  SelectedFeaturesApi,
  SetSelectedFeaturesPayload,
  clickMap,
} from '@features/ag-forecast/stores/map/store';
import { $UI } from '@features/ag-forecast/stores/ui';
import { graphLayoutExtractor } from '@features/ag-forecast/utils/graphLayoutExtractor';

import { getFeatureAtPixel } from '@utils/map/tools/getFeatureAtPixel';

// Управление видимостью слоев в зависимости от выбранных параметров фильтра
sample({
  clock: $FilterMap,
  source: { FilterMap: $FilterMap },
  fn: ({ FilterMap }) => {
    return FilterMap.actual as SetFiltersPayload;
  },
  target: LayersVisibleApi.setVisible,
});

// Выделение геометрии на карте
sample({
  clock: clickMap,
  source: { UI: $UI, Vehicles: $Vehicles },
  // Запретить выделение фич при установке поинта
  // Запретить выделение фич при установке ТПУ
  // Запретить выделение фич при рисовании графов
  filter: ({ UI }) => !UI.isSetPoint && !UI.placeTPU && !UI.drawGraph,
  fn: ({ Vehicles }, event) => {
    const primaryClickedFeature = getFeatureAtPixel({
      map: event.map,
      pixel: event.pixel,
      excludeLayouts: [
        EMapFeatureLayout.pointA,
        EMapFeatureLayout.pointB,
        EMapFeatureLayout.tpuDash,
        EMapFeatureLayout.transportDistricts,
      ],
      layoutExtractor: feature => graphLayoutExtractor(feature, Vehicles!),
    });

    if (!primaryClickedFeature) return null;
    const prevSelectedFeatures =
      (event.map.get(EMapMetaKeys.selectedFeatures) as FeatureLike[]) || [];
    const selectedFeatureId =
      primaryClickedFeature.get(EMapFeatureMetaKeys.olId) ||
      primaryClickedFeature.get('id');

    if (!selectedFeatureId)
      throw new Error('Selected feature id is not defined');

    const isReselected = prevSelectedFeatures.find(
      feature =>
        (feature.get(EMapFeatureMetaKeys.olId) || feature.get('id')) ===
        selectedFeatureId,
    );
    if (isReselected) throw new Error('Reselected feature');

    const layout = graphLayoutExtractor(primaryClickedFeature, Vehicles!);
    if (!layout) throw new Error('Layout is not defined');

    return {
      id: selectedFeatureId,
      layout,
    } as SetSelectedFeaturesPayload;
  },
  target: SelectedFeaturesApi.setSelectedFeatures,
});

// Получена геометрия агломерации
sample({
  clock: FetchAgGeometryFx.done,
  fn: request => {
    return {
      railwayStations: request.result?.railwayStations?.length
        ? request.result?.railwayStations[0]?.instances?.map(
            railwayStation => ({
              id: railwayStation?.id,
              coordinates: railwayStation?.geometry.coordinates,
              year: railwayStation?.year,
              isTpu: railwayStation?.isTpu,
            }),
          ) || null
        : null,
      center: request.result?.agglomeration?.center?.coordinates || null,
    } as AgGeometryStore;
  },
  target: AgGeometryApi.setAgGeometry,
});

FetchAgGeometryFx.use(async params => {
  const response = await apiClient.query<
    Pick<IQuery, 'getProjectById'>,
    IQueryGetProjectByIdArgs
  >({
    query: gql`
      query QueryGetProjectById($id: String!) {
        getProjectById(id: $id) {
          id
          transportCategories
          agglomeration {
            id
            center
          }
          railwayStations {
            id
            finished
            instances {
              id
              geometry
              year
              isTpu
            }
          }
        }
      }
    `,
    variables: {
      id: params.id,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data?.getProjectById || null;
});

sample({
  clock: FetchAgGeometryFx.done,
  fn: request => {
    return request.result?.transportCategories || [];
  },
  target: TransportCategoriesApi.setCategories,
});
