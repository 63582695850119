import { SvgIcon, SvgIconProps } from '@mui/material';

export const Settings = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M11.9999 14.8726C13.7674 14.8726 15.2003 13.4397 15.2003 11.6721C15.2003 9.90457 13.7674 8.47169 11.9999 8.47169C10.2324 8.47169 8.79947 9.90457 8.79947 11.6721C8.79947 13.4397 10.2324 14.8726 11.9999 14.8726Z"
          stroke="#273B48"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.7343 8.42372L17.6817 8.33262C17.5366 8.08138 17.4625 7.7954 17.4674 7.50529L17.4913 6.08204C17.4962 5.79118 17.3429 5.52058 17.0908 5.37534L14.9012 4.11356C14.6498 3.96869 14.3396 3.97128 14.0907 4.12035L12.8752 4.84812C12.6268 4.99686 12.3427 5.07541 12.0532 5.07541H11.9478C11.6576 5.07541 11.3729 4.99652 11.1242 4.84716L9.90314 4.11413C9.65332 3.96414 9.3417 3.96184 9.08968 4.10811L6.90701 5.37491C6.65618 5.52049 6.50376 5.7904 6.50864 6.08037L6.53262 7.50529C6.5375 7.7954 6.46341 8.08138 6.31828 8.33262L6.26636 8.4225C6.12115 8.67388 5.91029 8.88103 5.65636 9.02175L4.41228 9.71117C4.15729 9.85248 3.99934 10.1213 4 10.4128L4.00573 12.9346C4.00639 13.2244 4.16365 13.4911 4.41687 13.632L5.65497 14.3208C5.90976 14.4625 6.12099 14.6712 6.26583 14.9242L6.32146 15.0214C6.46455 15.2714 6.53751 15.5554 6.53265 15.8433L6.5087 17.262C6.50379 17.5529 6.65715 17.8235 6.90921 17.9688L9.09881 19.2306C9.35022 19.3754 9.66037 19.3728 9.90932 19.2238L11.1248 18.496C11.3732 18.3473 11.6573 18.2687 11.9468 18.2687H12.0522C12.3424 18.2687 12.6271 18.3476 12.8758 18.497L14.0969 19.23C14.3467 19.38 14.6583 19.3823 14.9103 19.236L17.093 17.9692C17.3438 17.8236 17.4962 17.5537 17.4914 17.2637L17.4674 15.8388C17.4625 15.5487 17.5366 15.2627 17.6817 15.0115L17.7336 14.9216C17.8789 14.6702 18.0897 14.4631 18.3436 14.3224L19.5877 13.6329C19.8427 13.4916 20.0007 13.2228 20 12.9313L19.9943 10.4095C19.9936 10.1197 19.8363 9.85298 19.5831 9.71212L18.3421 9.0217C18.0891 8.881 17.8791 8.67433 17.7343 8.42372Z"
          stroke="#273B48"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
