export enum EMapFeatureLayout {
  pointA = 'pointA',
  pointB = 'pointB',
  highway = 'highway',
  plannedTileHighway = 'plannedTileHighway',
  plannedHighway = 'plannedHighway',
  directionHighway = 'directionHighway',
  busStation = 'busStation',
  railway = 'railway',
  plannedTileRailway = 'plannedTileRailway',
  plannedRailway = 'plannedRailway',
  directionRailway = 'directionRailway',
  railwayStation = 'railwayStation',
  areaBorder = 'areaBorder',
  avia = 'avia',
  airport = 'airport',
  airline = 'airline',
  auto = 'auto',
  intermediateCorrespondents = 'intermediateCorrespondents',
  multimodal = 'multimodal',
}

export enum EMapLayoutTypes {
  tile = 'tile',
  vector = 'vector',
}

export enum EMapSelectFeaturesModes {
  segment = 'segment',
  path = 'path',
}
