import { Box, Switch, Typography, styled } from '@mui/material';

import { HeatIcon } from '@components/icons';

const StyledSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-thumb': {
    border: '1px solid #BBC0CB',
    width: '24px',
    height: '24px',
    transform: 'translate(0, -2px)',
  },

  '& .MuiSwitch-track': {
    backgroundColor: '#F6F6F6',
    opacity: 1,
    boxShadow: '0px 1px 2px 1px #0000000D inset',
  },

  '& .MuiSwitch-switchBase': {
    color: '#EBECEF',

    '&.Mui-checked': {
      color: '#E21A1A !important',
      transform: 'translateX(22px)',

      '& .MuiSwitch-thumb': {
        border: 'unset',
      },
      '& + .MuiSwitch-track': {
        backgroundColor: '#F6F6F6',
        opacity: 1,
        boxShadow: '0px 1px 2px 1px #0000000D inset',
      },
    },
  },
})) as typeof Switch;

interface HeatmapProps {
  value: boolean;
  onChange: () => void;
}

export const Heatmap = ({ value, onChange }: HeatmapProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '12px',
        pointerEvents: 'all',
        width: '212px',
        backgroundColor: theme => theme.palette.background.paper,
        boxShadow: '0px 0px 8px 0px #00000026',
        borderRadius: '4px',
        mr: 'auto',
      }}
    >
      <HeatIcon sx={{ mr: '8px' }} />
      <Typography
        sx={{
          fontSize: theme => theme.typography.pxToRem(12),
          lineHeight: theme => theme.typography.pxToRem(16),
        }}
        component={'span'}
      >
        Тепловая карта
      </Typography>
      <StyledSwitch
        checked={value}
        onChange={onChange}
      />
    </Box>
  );
};
