import { useForm } from 'react-hook-form';

import { Divider, Stack } from '@mui/material';

import { FormActions } from '@features/tpu-simulation-model/components/FormAttributes/FormActions';
import { Input } from '@features/tpu-simulation-model/components/FormAttributes/Input';
import { ObjectSizeM2Room } from '@features/tpu-simulation-model/components/FormAttributes/ObjectSizeM2Room';
import { Title } from '@features/tpu-simulation-model/components/FormAttributes/Title';
import {
  IRoom,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

interface FormProps extends IRoom {
  onUpdateAndClose: (arg: ObjectOfTheSimulationModel) => void;
  onDeleteAndClose: (arg: string) => void;
}

export const Room = ({
  onUpdateAndClose,
  onDeleteAndClose,
  ...props
}: FormProps) => {
  const { handleSubmit, formState, control } = useForm<IRoom>({
    values: props,
  });
  return (
    <Stack
      padding={3}
      gap={3}
    >
      <Title title={'Помещение'} />
      <Input<IRoom>
        control={control}
        name={'name'}
        title={'Название объекта'}
        type={'string'}
      />
      <Divider />
      <ObjectSizeM2Room points={props.points} />
      <Divider />
      <FormActions
        onSave={handleSubmit(onUpdateAndClose)}
        onDelete={() => onDeleteAndClose(props?.uuid)}
        disabled={!formState.isValid}
      />
    </Stack>
  );
};
