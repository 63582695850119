import { IQuery } from '@api/gql/ag-types';
import { useUnit } from 'effector-react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Divider, List, Typography } from '@mui/material';

import { Accordion } from '@components/Accordion';
import { AutoFullHeight } from '@components/AutoFullHeight';

import { FieldNameItem } from '@features/ag-constructor/components/RegistryItems/FieldNameItem';
import { RegistryUserItem } from '@features/ag-constructor/components/RegistryItems/RegistryUserItem';
import {
  $CurrentScenario,
  CurrentScenarioApi,
  createScenarioFx,
  getScenarioByIdFx,
} from '@features/ag-constructor/stores/currentScenario';
import {
  $ComputedScenarioList,
  fetchComputedScenarioListFx,
  fetchSocEcoScenarioListFx,
} from '@features/ag-constructor/stores/lists/scenarios/store';
import { fetchScenariosStatusesFx } from '@features/ag-constructor/stores/lists/statuses';
import { $FeatureSettings } from '@features/ag-constructor/stores/settings';
import { PaperCard } from '@features/pt-constructor/components/PaperCard';

export const RegistryContainer = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [projectId] = useState(queryParams.get('projectId') || '');

  const { userScenarios } = useUnit($ComputedScenarioList);
  const $currentScenario = useUnit($CurrentScenario);
  const { projectUUID } = useUnit($FeatureSettings);
  const { editCurrentScenario, setName } = useUnit(CurrentScenarioApi);

  const handlers = useUnit({
    getScenarioByIdFx,
  });
  const isNew =
    $currentScenario &&
    $currentScenario?.id === undefined &&
    $currentScenario.readonly === false;

  const selectScenarioHandler = useCallback(
    (id: string) => {
      if ($currentScenario && $currentScenario.id === id) return;
      handlers.getScenarioByIdFx({ scenarioId: id }).then();
    },
    [$currentScenario, handlers],
  );

  const handleCopyScenario = useCallback(
    (id: string) => {
      handlers
        .getScenarioByIdFx({ scenarioId: id })
        .then((res: IQuery['getScenarioById']) => {
          createScenarioFx({
            name: res?.name + ' (копия)' || '',
            projectId: projectUUID,
            socEcoScenarioId: res?.socEcoScenario?.id,
            takeIntoInfra: res?.takeIntoInfra || false,
          });
        });
    },
    [handlers, projectUUID],
  );

  const handlerEditScenario = useCallback(
    (id: string) => {
      handlers
        .getScenarioByIdFx({ scenarioId: id })
        .then((res: IQuery['getScenarioById']) => {
          editCurrentScenario({
            name: res?.name || '',
            id: res?.id || '',
            socEcoScenario: {
              id: res?.socEcoScenario?.id,
              name: res?.socEcoScenario?.name || '',
              annualEducationPlacesGrowthRate:
                res?.socEcoScenario?.annualEducationPlacesGrowthRate || 0,
              annualGardenPartnershipGrowthRate:
                res?.socEcoScenario?.annualGardenPartnershipGrowthRate || 0,
              annualJobsGrowthRate:
                res?.socEcoScenario?.annualJobsGrowthRate || 0,
              annualPopulationGrowthRate:
                res?.socEcoScenario?.annualPopulationGrowthRate || 0,
              annualRetailSpaceGrowthRate:
                res?.socEcoScenario?.annualRetailSpaceGrowthRate || 0,
              annualSalariesGrowthRate:
                res?.socEcoScenario?.annualSalariesGrowthRate || 0,
              annualServiceJobsGrowthRate:
                res?.socEcoScenario?.annualServiceJobsGrowthRate || 0,
            },
            takeIntoInfra: res?.takeIntoInfra || false,
          });
        });
    },
    [editCurrentScenario, handlers],
  );

  useEffect(() => {
    const scenarioListUpdateTimer = setTimeout(() => {
      fetchComputedScenarioListFx({ projectId });
      fetchScenariosStatusesFx({ projectId });
      fetchSocEcoScenarioListFx({ projectId });
    }, 5000);

    return () => clearTimeout(scenarioListUpdateTimer);
  });

  return (
    <PaperCard title="Реестр сценариев расчетов">
      <AutoFullHeight
        style={{
          overflowY: 'auto',
          paddingRight: '24px',
        }}
        mb={24 + 32}
      >
        <Accordion
          summaryComponent={
            <Typography variant="subtitle1">Пользовательские</Typography>
          }
        >
          <List sx={{ ml: -1, mt: -3 }}>
            {isNew && (
              <FieldNameItem
                value={$currentScenario?.name || ''}
                onChange={setName}
              />
            )}

            {userScenarios.map(item => (
              <RegistryUserItem
                key={`user-scenario-${item.id}`}
                item={item}
                readonly={$currentScenario?.readonly !== false}
                onClick={() => selectScenarioHandler(item.id)}
                onCopy={() => handleCopyScenario(item.id)}
                onEdit={() => handlerEditScenario(item.id)}
              />
            ))}
          </List>
        </Accordion>

        <Divider />

        {/*<Accordion*/}
        {/*  summaryComponent={*/}
        {/*    <Typography variant="subtitle1">Доступные</Typography>*/}
        {/*  }*/}
        {/*>*/}
        {/*  {availableScenarios.map(item => (*/}
        {/*    <ListItem*/}
        {/*      key={`available-scenario-${item.id}`}*/}
        {/*      sx={{*/}
        {/*        pl: 4,*/}
        {/*        py: 0,*/}
        {/*      }}*/}
        {/*      secondaryAction={*/}
        {/*        <IconButton*/}
        {/*          size="small"*/}
        {/*          sx={{ mr: 12.5 }}*/}
        {/*        >*/}
        {/*          <CopyIcon />*/}
        {/*        </IconButton>*/}
        {/*      }*/}
        {/*    >*/}
        {/*      <ListItemText*/}
        {/*        primaryTypographyProps={{*/}
        {/*          variant: 'body2',*/}
        {/*          sx: {*/}
        {/*            whiteSpace: 'nowrap',*/}
        {/*            textOverflow: 'ellipsis',*/}
        {/*            overflow: 'hidden',*/}
        {/*            mawWidth: 'calc(100% - 24px)',*/}
        {/*          },*/}
        {/*        }}*/}
        {/*        secondaryTypographyProps={{*/}
        {/*          variant: 'caption',*/}
        {/*          sx: {*/}
        {/*            color: 'customs.link',*/}
        {/*          },*/}
        {/*        }}*/}
        {/*        secondary={item.user}*/}
        {/*        primary={item.name}*/}
        {/*      />*/}
        {/*    </ListItem>*/}
        {/*  ))}*/}
        {/*</Accordion>*/}
      </AutoFullHeight>
    </PaperCard>
  );
};
