import { Fill, Stroke, Style } from 'ol/style';

import { EMapFeatureMetaKeys } from '@constants/map';

import { EFeatureStyle } from '@utils/map/hooks/useInitVectorLayer';
import { VectorStyleProps } from '@utils/map/styles/types';

export const ptAreaBorderVictorStyle = (props: VectorStyleProps) => {
  const { feature, featureStyles } = props;

  const width = 1;
  const styles = [];
  let active = false;

  if (
    featureStyles &&
    Object.keys(featureStyles).includes(
      String(feature?.get(EMapFeatureMetaKeys.olId)),
    )
  ) {
    const style = featureStyles[feature?.get(EMapFeatureMetaKeys.olId)];
    switch (style) {
      case EFeatureStyle.selected:
        active = true;
    }
  }

  styles.push(
    new Style({
      stroke: new Stroke({
        color: '#50A583',
        width,
      }),
      fill: new Fill({
        color: active ? 'rgba(80,165,131,0.2)' : 'transparent',
      }),
    }),
  );

  return styles;
};
