import styled from '@emotion/styled';

export const WrapLogo = styled.div`
  background: ${props => props.theme.palette.secondary.main};
  width: 230px;
  margin-left: -50px;
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: center;

  & > img {
    margin-left: 10px;
    transform: skew(35deg);
  }
`;
