import { sample } from 'effector';
import { Feature } from 'ol';
import { MultiLineString } from 'ol/geom';

import { EFilterMapLayout } from '@components/MapFilters';

import {
  getWidthLine,
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import {
  $FilterMap,
  $FilterRegions,
  $Map,
  $TimeLine,
  SetMapFeaturesTypes,
  changeMap,
  setMapFeatures,
} from '@features/passenger-traffic/stores';

import { recursivelyFromLonLat } from '@utils/geometry';

sample({
  clock: changeMap,
  source: {
    FilterMap: $FilterMap,
    FilterRegions: $FilterRegions,
    Map: $Map,
    Timeline: $TimeLine,
  },
  fn: ({ FilterMap, FilterRegions, Map, Timeline }) => {
    const layer: SetMapFeaturesTypes = {
      params: {
        layout: EMapFeatureLayout.plannedRailway,
        width: 5,
      },
      features: [],
    };

    // В фильтре отключено отображение ЖД
    if (!FilterMap.railwayRoot) return layer;

    // Не выбрано отображение планируемых структур
    if (!FilterMap.planned) return layer;

    // НЕ блокирующее условие определения толщины линии
    if (FilterMap.layout === EFilterMapLayout.PassengerFlows) {
      layer.params.width = getWidthLine(
        (layer.params.width = getWidthLine(
          (FilterMap.nightTrain
            ? Map.passengerTrafficPercent.nightTrain || 0
            : 0) +
            (FilterMap.dayTrain
              ? Map.passengerTrafficPercent.dayTrain || 0
              : 0) +
            (FilterMap.suburbanTrain
              ? Map.passengerTrafficPercent.suburbanTrain || 0
              : 0),
        )),
      );
    }

    //Если поинты АБ установлены, можно добавить планируемые пути в жд путь
    if (FilterRegions.selectedRegionA && FilterRegions.selectedRegionB) {
      layer.features = FilterRegions.directions.railway
        .filter(
          item =>
            item.details![0]?.status === false &&
            item.details![0].year! > Timeline.baseYear,
        )
        .map(
          item =>
            new Feature({
              geometry: new MultiLineString(
                recursivelyFromLonLat(item.geometry.coordinates),
              ),
              [layerIdKey]: item.id,
              [layerLayoutKey]: EMapFeatureLayout.directionHighway,
            }),
        );
      return layer;
    }

    // Пока глобально отображать нечего
    return layer;
  },
  target: setMapFeatures,
});
