import { createEffect, sample } from 'effector';
import { Coordinate } from 'ol/coordinate';
import { boundingExtent } from 'ol/extent';
import { fromLonLat } from 'ol/proj';

import {
  initSources,
  pipeMapControls,
} from '@features/pt-forecast-new/stores/map/store';
import {
  $VectorGeometry,
  VectorGeometryApi,
} from '@features/pt-forecast-new/stores/map/vectorFeatures';
import { $UI } from '@features/pt-forecast-new/stores/ui';

import {
  EPipeMapControls,
  PipeMapControls,
} from '@utils/map/hooks/useInitMapControls';

const extentDelayFx = createEffect();

sample({
  clock: [
    VectorGeometryApi.setDirectionPassFlowRegionsFrom,
    VectorGeometryApi.setDirectionPassFlowRegionsTo,
    initSources,
  ],
  target: extentDelayFx,
});

extentDelayFx.use(async () => {
  await new Promise(r => setTimeout(r, 300));
});

// Центрировать карту на выбранной корреспонденции
sample({
  clock: extentDelayFx.finally,
  source: { VectorGeometry: $VectorGeometry, UI: $UI },
  filter: ({ VectorGeometry }) =>
    !!VectorGeometry.directionPassFlow.regions.from &&
    !!VectorGeometry.directionPassFlow.regions.to,
  fn: ({ VectorGeometry, UI }) => {
    // Пример функции для преобразования мультиполигона
    const multipolygonFrom =
      VectorGeometry.directionPassFlow.regions.from!.coordinates.map(polygon =>
        polygon.map(ring =>
          ring.map(
            coordinates => fromLonLat(coordinates), // Теперь передаётся только один параметр
          ),
        ),
      );
    const multipolygonTo =
      VectorGeometry.directionPassFlow.regions.to!.coordinates.map(polygon =>
        polygon.map(ring =>
          ring.map(
            coordinates => fromLonLat(coordinates), // Так же передаётся только один параметр
          ),
        ),
      );
    // Функция для преобразования массива мультиполигонов в массив точек
    const flattenMultiPolygon = (multiPolygon: Coordinate[][][]) => {
      return multiPolygon.flat(2); // Использование flat с уровнем 2 для преобразования в массив точек
    };

    // Объединяем все точки из обоих мультиполигонов
    const allPoints = [
      ...flattenMultiPolygon(multipolygonFrom),
      ...flattenMultiPolygon(multipolygonTo),
    ];

    // Вычисляем ограничивающий прямоугольник (extent)
    const extent = boundingExtent(allPoints);

    return {
      action: EPipeMapControls.fitExtent,
      payload: {
        extent,
        padding: [
          150,
          (UI.panels.forecastDetails || UI.panels.inspector ? 500 : 0) + 100,
          150,
          (UI.panels.filter ? 500 : 0) +
            (UI.panels.paramsForecast ? 500 : 0) +
            100,
        ],
      },
    } as PipeMapControls;
  },
  target: pipeMapControls,
});
