import {
  IGraphInstanceType,
  IRailwayStationInstanceType,
} from '@api/gql/ag-types';
import { Maybe, Scalars } from '@api/gql/types';
import { createApi, createEffect, createEvent, createStore } from 'effector';

import { AgLayersStore } from '@features/ag-forecast/stores/map';
import { GraphInspectorProps } from '@features/ag-project/components/Inspector/GraphInspector';

const initState: InfrastructureInspectorStore = {
  railwayStation: {
    id: null,
    name: '',
    isTpu: null,
    year: null,
    capacity: null,
    floorsNumber: null,
  },
  graphs: {
    capacity: 0,
    category: '',
    freeSpeed: 0,
    laneNum: 0,
    oneway: false,
    transport: [],
  },
};

export const $InfrastructureInspector =
  createStore<InfrastructureInspectorStore>(initState);

export const InfrastructureInspectorApi = createApi($InfrastructureInspector, {
  setRailwayStationForInspector: (
    store,
    payload: InfrastructureInspectorStore['railwayStation'],
  ) => ({
    ...store,
    railwayStation: payload,
  }),
  setGraphsForInspector: (
    store,
    payload: InfrastructureInspectorStore['graphs'],
  ) => ({
    ...store,
    graphs: payload,
  }),
  reset: () => initState,
});

// Сеттер вида инспектора инфраструктуры
export const getDataForInspectorFn = createEvent<GetObjectType>();

// Эффекты для получения данных по выбранному типу объекта карты
export const getGraphsForInspectorFx = createEffect<
  GetObjectType,
  Maybe<IGraphInstanceType>
>();
export const getRailwayStationForInspectorFx = createEffect<
  GetObjectType,
  Maybe<IRailwayStationInstanceType>
>();

export type InfrastructureInspectorStore = {
  graphs: GraphInspectorProps['fields'];
  railwayStation: Pick<
    IRailwayStationInstanceType,
    'id' | 'name' | 'isTpu' | 'year' | 'capacity' | 'floorsNumber'
  >;
};

export type GetObjectType = {
  layout: keyof AgLayersStore | null;
  id: Scalars['UUID']['output'];
};
