import { apiClient } from '@api/client';
import { IMutation, IMutationCreateGraphItemArgs } from '@api/gql/types';
import { gql } from '@apollo/client';

export const mutationCreateGraphItem = async (
  newGraphItem: IMutationCreateGraphItemArgs,
) => {
  const result = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation CreateGraphItemMutation(
        $geometry: MultiLineStringInput!
        $scenarioId: UUID!
        $year: Int!
        $name: String!
        $startStationId: UUID!
        $endStationId: UUID!
      ) {
        createGraphItem(
          geometry: $geometry
          scenarioId: $scenarioId
          year: $year
          name: $name
          startStationId: $startStationId
          endStationId: $endStationId
        ) {
          instance {
            name
            geometry
            id
            type
            speed
            year
            endStation {
              id
            }
            startStation {
              id
            }
            relatedStations {
              id
            }
          }
        }
      }
    `,
    variables: newGraphItem,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.createGraphItem;
};
