import { IQuery, IQueryGetScenarioParamsArgs } from '@api/gql/types';
import { gql } from '@apollo/client';
import { apiClient } from 'src/api/provider';

export const queryGetScenarioParams = async (
  params: IQueryGetScenarioParamsArgs,
) => {
  const result = await apiClient.query<IQuery, IQueryGetScenarioParamsArgs>({
    query: gql`
      query QueryGetScenarioParams($scenarioId: UUID!, $directionIds: [UUID]) {
        getScenarioParams(
          scenarioId: $scenarioId
          directionIds: $directionIds
        ) {
          durationDayTrain
          durationExpressSuburbanTrain
          durationNightTrain
          durationSuburbanTrain
          frequencyDayTrain
          frequencyExpressSuburbanTrain
          frequencyNightTrain
          frequencySuburbanTrain
          priceDayTrain
          priceExpressSuburbanTrain
          priceNightTrain
          priceSuburbanTrain
          regionFromId
          regionToId
          regionFromName
          regionToName
          itemName
          graphItemId
          year
        }
      }
    `,
    variables: params,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result?.data?.getScenarioParams;
};
