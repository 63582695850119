import { Route, Routes } from 'react-router-dom';

import { CalculationScenariosContainer } from '@features/tpu-calculation-scenarios';
import { CalculationScenariosViewingContainer } from '@features/tpu-calculation-scenarios-viewing';
import { PassengerTypesContainer } from '@features/tpu-passenger-types';
import { TpuProjectRegistryContainer } from '@features/tpu-project-registry';
import { SimulationModelContainer } from '@features/tpu-simulation-model';
import { TrainScheduleContainer } from '@features/tpu-train-schedule';

export const PageTPU = () => {
  return (
    <Routes>
      <Route
        path={'/scenarios/:projectId'}
        Component={CalculationScenariosContainer}
      />

      <Route
        path={'/scenarios/:scenarioId/viewer'}
        Component={CalculationScenariosViewingContainer}
      />

      <Route
        path={'/editor/:projectId'}
        Component={SimulationModelContainer}
      />

      <Route
        path={'/passenger-types/:scenarioId'}
        Component={PassengerTypesContainer}
      />

      <Route
        path={'/train-schedule/:scenarioId'}
        Component={TrainScheduleContainer}
      />

      <Route
        index
        Component={TpuProjectRegistryContainer}
      />
    </Routes>
  );
};
