import { createEffect, createEvent, createStore } from 'effector';

export interface IScenarioData {
  scenarioId?: string;
  scenarioName?: string;
  projectId?: string;
  projectName?: string;
  dateTime?: string;
}

export const $ScenarioData = createStore<IScenarioData>({});

export const getScenarioIdFn = createEvent<void>();

export const getScenarioDataByScenarioIdFx = createEffect<
  string,
  IScenarioData | null
>();
