export const SOC_ECO_TH_4_2 = [
  // {
  //   id: 'transportArea',
  //   label: 'Транспортный район',
  // },
  {
    id: 'project',
    label: 'Проект',
  },
  {
    id: 'totalNumberIntersectionsRequired',
    label: 'Общее число необходимых пересечений',
  },
];
