import { useUnit } from 'effector-react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Autocomplete,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
  UseAutocompleteProps,
  autocompleteClasses,
  inputBaseClasses,
  outlinedInputClasses,
} from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { FormLabel } from '@components/FormLabel';
import { NumberField } from '@components/NumberField';
import { CloseIcon } from '@components/icons';

import {
  AgEcoParam,
  agEcoParams,
} from '@features/ag-constructor/constants/agEcoParams';
import {
  $CurrentScenario,
  CurrentScenarioApi,
} from '@features/ag-constructor/stores/currentScenario';
import { $ComputedScenarioList } from '@features/ag-constructor/stores/lists/scenarios/store';
import { ModalsAgConstructorApi } from '@features/ag-constructor/stores/modals';
import { PaperCard } from '@features/pt-constructor/components/PaperCard';

export const SocEcoContainer = () => {
  const [selectedScenario, setSelectedScenario] =
    useState<ScenarioOption | null>(null);
  const { socEcoScenarios } = useUnit($ComputedScenarioList);
  const { selectSocEcoScenario, setSocEcoField } = useUnit(CurrentScenarioApi);
  const $currentScenario = useUnit($CurrentScenario);
  const { setModalView } = useUnit(ModalsAgConstructorApi);

  const readonly = $currentScenario?.readonly !== false;

  const scenariosList = useMemo<ScenarioOption[]>(
    () =>
      socEcoScenarios.map(scenario => ({
        label: scenario?.name || '',
        value: scenario?.id as string,
      })),
    [socEcoScenarios],
  );

  const onChange = useCallback<
    NonNullable<
      UseAutocompleteProps<ScenarioOption, false, false, false>['onChange']
    >
  >(
    (_e, val) => {
      if (!val) return;
      const socEcoScenario = socEcoScenarios.find(e => e.id === val.value);
      if (!socEcoScenario) return;
      selectSocEcoScenario(socEcoScenario);
    },
    [selectSocEcoScenario, socEcoScenarios],
  );

  const openCreateSocEco = useCallback(() => {
    setModalView({ socioEconomic: true });
  }, [setModalView]);

  const onChangeParamValue = useCallback(
    (param: AgEcoParam) => (value_: number | string | undefined) => {
      const value = Number(value_);
      setSocEcoField({ [param.name]: value });
    },
    [setSocEcoField],
  );

  const handleClearScenario = useCallback(() => setSelectedScenario(null), []);

  useEffect(() => {
    if (!$currentScenario?.socEcoScenario) return setSelectedScenario(null);

    const scenario = scenariosList.find(
      e => e.label === $currentScenario.socEcoScenario?.name,
    );
    setSelectedScenario(scenario || null);
  }, [$currentScenario?.socEcoScenario, scenariosList]);

  return (
    <PaperCard title="Сценарий социально-экономического прогноза">
      <AutoFullHeight
        style={{
          overflowY: 'auto',
          paddingRight: '24px',
        }}
        mb={24 + 32}
      >
        <Stack
          gap={2}
          flexDirection="row"
          alignItems="flex-end"
          mb={4}
        >
          <FormLabel
            sx={{ flex: 1 }}
            title="Сценарий"
            id="soc-eco-select"
          >
            <Autocomplete
              id="soc-eco-select"
              disablePortal
              onChange={onChange}
              value={selectedScenario}
              options={scenariosList}
              disabled={readonly}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              fullWidth
              sx={{
                [`&.${autocompleteClasses.hasPopupIcon}.${autocompleteClasses.hasClearIcon} .${outlinedInputClasses.root}`]:
                  {
                    pr: 4,
                  },
                [`& .${inputBaseClasses.root}.${outlinedInputClasses.root}.${outlinedInputClasses.disabled}`]:
                  {
                    pointerEvents: 'none',
                  },
                [`&.${autocompleteClasses.root} .${autocompleteClasses.clearIndicator}`]:
                  {
                    display: 'none',
                  },
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  disabled={readonly}
                  placeholder="Сценарий"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {selectedScenario && !readonly && (
                          <IconButton
                            size="small"
                            onClick={handleClearScenario}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option.value}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography>{option.label}</Typography>
                  </Stack>
                </li>
              )}
            />
          </FormLabel>
          <Button
            disabled={readonly}
            onClick={openCreateSocEco}
          >
            Создать новый
          </Button>
        </Stack>

        <Typography variant="subtitle1">Параметры прогнозирования</Typography>

        {agEcoParams.map(param => (
          <FormLabel
            title={param.title}
            id={param.name}
            key={param.name}
            sx={{ mt: 3 }}
          >
            <NumberField
              disabled={true}
              value={$currentScenario?.socEcoScenario?.[param.name] || 0}
              getValue={onChangeParamValue(param)}
              placeholder={param.title}
            />
          </FormLabel>
        ))}
      </AutoFullHeight>
    </PaperCard>
  );
};

export type ScenarioOption = {
  label: string;
  value: string;
  disabled?: boolean;
};
