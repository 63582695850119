export const SOC_ECO_TH_2_5 = [
  {
    id: 'nameArea',
    label: 'Наименование участка',
  },
  {
    id: 'noiseScreenSize',
    label: 'Размер шумовых экранов, длина в м.',
  },
];
