import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { Box, Button, Divider, Stack, Typography } from '@mui/material';

import { Direction } from '@features/tpu-simulation-model/components/FormAttributes/Direction';
import { FormActions } from '@features/tpu-simulation-model/components/FormAttributes/FormActions';
import { Input } from '@features/tpu-simulation-model/components/FormAttributes/Input';
import { ObjectPosition } from '@features/tpu-simulation-model/components/FormAttributes/ObjectPosition';
import { ObjectSize } from '@features/tpu-simulation-model/components/FormAttributes/ObjectSize';
import { RadioList as Radio } from '@features/tpu-simulation-model/components/FormAttributes/Radio';
import { SelectOption as Select } from '@features/tpu-simulation-model/components/FormAttributes/Select';
import { TimeRange } from '@features/tpu-simulation-model/components/FormAttributes/TimeRange';
import { Title } from '@features/tpu-simulation-model/components/FormAttributes/Title';
import {
  useListOfRoomsElevatorsLadders,
  useRoomsEntrancesExits,
} from '@features/tpu-simulation-model/hooks';
import { useConnectedObjects } from '@features/tpu-simulation-model/hooks/useConnectedObjects';
import { useFloors } from '@features/tpu-simulation-model/hooks/useFloors';
import {
  ActionEnum,
  IElevator,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';
import { getAttributeOptions } from '@features/tpu-simulation-model/utils/getAttributeOptions';

interface FormProps extends IElevator {
  onUpdateAndClose: (arg: ObjectOfTheSimulationModel) => void;
  onDeleteAndClose: (arg: string) => void;
}

export const Elevator = ({
  onUpdateAndClose,
  onDeleteAndClose,
  ...props
}: FormProps) => {
  const { selectedFloorId, floorOptions, selectedFloorIndex, getFloorIndex } =
    useFloors();
  const { formState, control, watch, setValue } = useForm<IElevator>({
    values: {
      ...props,
      connectedFloors: !props.connectedFloors.length
        ? ['']
        : props.connectedFloors,
      floorId: selectedFloorId,
    },
  });
  const roomEntrancesExitsPlatformsOptions = useRoomsEntrancesExits();
  const { elevatorOptions } = useListOfRoomsElevatorsLadders();
  const { changeConnectedObjects } = useConnectedObjects();
  const formValues = watch();
  const { connectedFloors } = formValues;

  const getAvailableFloorOptions = useCallback(
    (index: number) => {
      return floorOptions.filter(
        item =>
          !connectedFloors.includes(item.value) ||
          connectedFloors[index] === item.value,
      );
    },
    [connectedFloors, floorOptions],
  );

  const getAvailableElevatorOptions = useCallback(
    (id: string) => {
      return elevatorOptions(id).filter(
        i => i.value !== formValues.uuid && i.room !== formValues.room,
      );
    },
    [elevatorOptions, formValues],
  );

  return (
    <Stack
      padding={3}
      gap={3}
      sx={{
        overflow: 'auto',
      }}
    >
      <Title title={'Лифт'} />
      <Input<IElevator>
        control={control}
        name={'name'}
        title={'Название объекта'}
        type={'string'}
      />
      <Select<IElevator>
        control={control}
        name={'room'}
        title={'Помещение'}
        placeholder={''}
        options={roomEntrancesExitsPlatformsOptions}
      />
      <Box>
        <Typography>Соединяемые этажи</Typography>
        {connectedFloors.map((_, index) => (
          <Select<IElevator>
            key={index}
            control={control}
            name={`connectedFloors.${index}`}
            placeholder={'Выберите соединяемые этажи'}
            options={getAvailableFloorOptions(index)}
          />
        ))}
        <Button
          variant="text"
          onClick={() => {
            const isLastConnectedFloorHasId =
              connectedFloors.length &&
              !connectedFloors[connectedFloors.length - 1];
            const isConnectedFloorEqualCurrentFloor =
              connectedFloors[connectedFloors.length - 1] === selectedFloorId;

            if (isLastConnectedFloorHasId || isConnectedFloorEqualCurrentFloor)
              return;
            setValue(`connectedFloors.${connectedFloors.length}`, '');
          }}
          sx={{
            cursor: 'pointer',
            color: props => props.palette.customs.link,
            fontSize: theme => theme.typography.pxToRem(12),
            mt: '6px',
          }}
        >
          Добавить этаж
        </Button>
      </Box>
      <Box>
        <Typography>Направление прохода</Typography>
        {connectedFloors.map((item, index) => (
          <Select<IElevator>
            key={item}
            control={control}
            name={`connectedObjects.${index}`}
            placeholder={`Выберите объект лифта на ${
              getFloorIndex(item) ? getFloorIndex(item)! + 1 : ''
            } этаже`}
            options={getAvailableElevatorOptions(item)}
            disabled={!connectedFloors[index]}
          />
        ))}
        <Direction<IElevator>
          control={control}
          name={'direction'}
          options={getAttributeOptions('direction')}
        />
      </Box>
      <TimeRange<IElevator>
        control={control}
        nameTime1={'openFrom'}
        nameTime2={'openUntil'}
      />
      <Radio<IElevator>
        control={control}
        name={'status'}
        title={'Статус'}
        options={getAttributeOptions('status')}
      />
      <Radio<IElevator>
        control={control}
        name={'waitingZone'}
        title={'Зона ожидания'}
        options={getAttributeOptions('waitingZone')}
      />
      <Divider />
      <ObjectPosition
        point={props.point}
        floor={selectedFloorIndex + 1}
      />
      <ObjectSize
        width={props.width}
        height={props.height}
      />
      <Divider />
      <FormActions
        onSave={() =>
          onUpdateAndClose(changeConnectedObjects(formValues, ActionEnum.ADD))
        }
        onDelete={() => {
          changeConnectedObjects(props, ActionEnum.REMOVE);
          onDeleteAndClose(formValues.uuid);
        }}
        disabled={!formState.isValid}
      />
    </Stack>
  );
};
