import { useUnit } from 'effector-react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  SxProps,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

import { CloseIcon } from '@components/icons';

import { useContextPRHandlers } from '@features/tpu-project-registry/hooks/usePRHandlers/useContextPRHandlers';
import { $FileFormatModalState } from '@features/tpu-project-registry/stores';

export const ModalFileFormatRequirements = () => {
  const { setIsOpenFileFormatModal } = useContextPRHandlers();

  const { isOpen } = useUnit($FileFormatModalState);

  const closeFileFormatModalWindow = () => setIsOpenFileFormatModal(false);

  const dialogSx: SxProps<Theme> = {
    '& .MuiDialogTitle-root': {
      padding: '24px',
    },
    '& .MuiDialogContent-root': {
      padding: '0 24px 24px',
      justifyContent: 'center',
    },
    '.MuiDialog-paper': {
      maxWidth: `960px`,
      borderRadius: '8px',
    },
  };

  const filesFormats = [
    'Поэтажный план ТПУ - формат .jpeg/.jpg/.png',
    'Расписание дальние - формат .xls/.xlsx',
    'Расписание пригородные - формат .xls/.xlsx',
    'Поток дальние - формат .xls/.xlsx',
    'Поток пригородные - формат .xls/.xlsx',
    'Посетители - формат .xls/.xlsx',
    'Доли входов - формат .xls/.xlsx',
    'Доли выходов - формат .xls/.xlsx',
    'Техническое распределение - формат .xls/.xlsx ',
    'Эпюра посетителей - формат .xls/.xlsx',
    'Кассы - формат .xls/.xlsx',
    'АСОКУПЭ - формат .xls/.xlsx',
    'Перечень коммерческих объектов - формат .xls/.xlsx',
  ];

  return (
    <Dialog
      open={isOpen}
      sx={dialogSx}
      transitionDuration={0}
      onClose={closeFileFormatModalWindow}
    >
      <DialogTitle>Требования к форматам файлов</DialogTitle>

      <IconButton
        onClick={closeFileFormatModalWindow}
        color={'primary'}
        sx={{ position: 'absolute', top: 24, right: 24, padding: 0 }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Typography
          sx={{ mb: 2 }}
          variant="body2"
        >
          Загружаемые файлы проходят двухуровневый форматно-логический контроль
          на соответствие критериям качества данных.
        </Typography>

        <Typography
          sx={{ mb: 1 }}
          variant="body2"
        >
          1. На уровне форматного контроля файл проверяется на соблюдение
          структуры данных и отсутствие пропущенных значений (для форматов
          .xls/.xlsx).
        </Typography>

        <Typography
          sx={{ mb: 3 }}
          variant="body2"
        >
          2. На уровне логического контроля записи файла проверяются по заданным
          форматам, сопоставляются ключи записей с ключами справочников ПКМПП,
          проверяется наличие дублей записей.
        </Typography>

        {filesFormats.map((content, index) => (
          <Typography
            key={index}
            sx={{ mb: 0.5 }}
            variant="body2"
          >
            {index + 1 + '. ' + content}
          </Typography>
        ))}
      </DialogContent>
    </Dialog>
  );
};
