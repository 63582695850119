import { EMapFeatureLayout } from '@constants/map';

import { FilterMapState } from '@features/ag-forecast/stores/filters';
import { VehicleType } from '@features/ag-forecast/stores/lists/vehicles';
import { AgLayersStore } from '@features/ag-forecast/stores/map';
import { layoutToVehicleId } from '@features/ag-forecast/utils/layoutToVehicleId';

const vehicles = [
  EMapFeatureLayout.graphAuto,
  EMapFeatureLayout.graphBus,
  EMapFeatureLayout.graphTrolleybus,
  EMapFeatureLayout.graphTram,
  EMapFeatureLayout.graphMetro,
  EMapFeatureLayout.graphSuburbanRailway,
  EMapFeatureLayout.graphRopeWay,
  EMapFeatureLayout.graphWaterWay,
  EMapFeatureLayout.graphFunicular,
  EMapFeatureLayout.graphMonoRailWay,
  EMapFeatureLayout.graphPedestrain,
  EMapFeatureLayout.graphAvia,
];

// Достает сущность транспорта в зависимости от выбранного фильтра
export const getVehicleByFilter = (
  FilterMap: FilterMapState,
  Vehicles: VehicleType[] | null,
) => {
  const selectedVehicle = Object.keys(FilterMap.actual).find(key => {
    const typedKey = key as keyof typeof FilterMap.actual;
    return (
      vehicles.includes(key as EMapFeatureLayout) && FilterMap.actual[typedKey]
    );
  }) as keyof AgLayersStore;
  if (!selectedVehicle) throw new Error('No selected vehicle');
  const vehicleId = layoutToVehicleId(selectedVehicle);
  if (!vehicleId) throw new Error('No vehicle id in dictionary');
  const vehicle = Vehicles?.find(item => item.vehicleId === Number(vehicleId));
  if (!vehicle) throw new Error('No vehicle in list');
  return vehicle;
};
