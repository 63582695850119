import { AgglomerationConstructorContainer } from '@features/ag-constructor';
import { AgglomerationForecastContainer } from '@features/ag-forecast';
import { AgglomerationProjectContainer } from '@features/ag-project/container';
import { AgglomerationProjectsRegistryContainer } from '@features/ag-projects-registry/container';
import { Route, Routes } from 'react-router-dom';

export const PageAgglomeration = () => {
  return (
    <Routes>
      <Route
        index
        Component={AgglomerationProjectsRegistryContainer}
      />
      <Route
        path={'/project'}
        Component={AgglomerationProjectContainer}
      />
      <Route
        path={'/constructor'}
        Component={AgglomerationConstructorContainer}
      />
      <Route
        path={'/forecast'}
        Component={AgglomerationForecastContainer}
      />
    </Routes>
  );
};
