import { SvgIcon, SvgIconProps } from '@mui/material';

export const WallOuterDottedLineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1377_75616)">
          <rect
            x="10"
            y="4"
            width="4"
            height="16"
            fill="#F14848"
          />
          <rect
            x="0.5"
            y="0.5"
            width="23"
            height="23"
            stroke="#3679EA"
            strokeDasharray="4 4"
          />
        </g>
        <defs>
          <clipPath id="clip0_1377_75616">
            <rect
              width="24"
              height="24"
              rx="2"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
