import {
  IQuery,
  IQueryGetAirportByIdArgs,
  IQueryGetBusStationByIdArgs,
  IQueryGetHighwayByIdArgs,
  IQueryGetRailwayByIdArgs,
  IQueryGetRailwayStationByIdArgs,
  IQueryGetRegionByIdArgs,
} from '@api/gql/types';
import { createApi, createEffect, createStore } from 'effector';

import { EMapFeatureLayout } from '@constants/map';

const initState: InspectorInfrastructureStore = {
  id: null,
  layout: null,
  railway: null,
  highway: null,
  railwayStation: null,
  busStation: null,
  airport: null,
  transportRegion: null,
};

export const $InspectorInfrastructure =
  createStore<InspectorInfrastructureStore>(initState);

export const InspectorInfrastructureApi = createApi($InspectorInfrastructure, {
  getForFeature: (_, payload: GetForFeaturePayload) => ({
    ...initState,
    ...payload,
  }),
  setRailway: (store, payload: RailwayInspectorState | null) => ({
    ...store,
    railway: payload,
  }),
  setHighway: (store, payload: HighwayInspectorState | null) => ({
    ...store,
    highway: payload,
  }),
  setRailwayStation: (store, payload: RailwayStationInspectorState | null) => ({
    ...store,
    railwayStation: payload,
  }),
  setBusStation: (store, payload: BusStationInspectorState | null) => ({
    ...store,
    busStation: payload,
  }),
  setAirport: (store, payload: AirportInspectorState | null) => ({
    ...store,
    airport: payload,
  }),
  setTransportRegion: (
    store,
    payload: TransportRegionInspectorState | null,
  ) => ({
    ...store,
    transportRegion: payload,
  }),
  reset: () => initState,
});

export const getRailwayForInspectorFx = createEffect<
  IQueryGetRailwayByIdArgs,
  IQuery['getRailwayById']
>();
export const getHighwayForInspectorFx = createEffect<
  IQueryGetHighwayByIdArgs,
  IQuery['getHighwayById']
>();
export const getRailwayStationForInspectorFx = createEffect<
  IQueryGetRailwayStationByIdArgs,
  IQuery['getRailwayStationById']
>();
export const getBusStationForInspectorFx = createEffect<
  IQueryGetBusStationByIdArgs,
  IQuery['getBusStationById']
>();
export const getAirportForInspectorFx = createEffect<
  IQueryGetAirportByIdArgs,
  IQuery['getAirportById']
>();
export const getRegionForInspectorFx = createEffect<
  IQueryGetRegionByIdArgs,
  IQuery['getRegionById']
>();

export type RailwayInspectorState = {
  railwayName: string;
  tractionType: string;
  tracks: number;
  capacity: number;
  speed: number;
};

export type HighwayInspectorState = {
  name: string;
  category: string;
  speed: number;
};

export type RailwayStationInspectorState = {
  name: string;
  passTraffic: number;
};

export type BusStationInspectorState = {
  name: string;
  passTraffic: number;
};

export type AirportInspectorState = {
  name: string;
  passTraffic: number;
  capacity: number;
  range: number;
};

export type TransportRegionInspectorState = {
  name: string;
  population: number;
  averageNumberOfEmployeesOfOrganizationsWithoutSmp: number;
  averageMonthlySalary: number;
  revenueWithTaxesOnProducts: number;
  numberOfPlaces: number;
  revenueTourismIndustry: number;
  levelOfMotorization: number;
};

export type GetForFeaturePayload = {
  layout: EMapFeatureLayout | null;
  id: string | null;
};

export type InspectorInfrastructureStore = {
  layout: EMapFeatureLayout | null;
  id: string | null;
  railway: RailwayInspectorState | null;
  highway: HighwayInspectorState | null;
  railwayStation: RailwayStationInspectorState | null;
  busStation: BusStationInspectorState | null;
  airport: AirportInspectorState | null;
  transportRegion: TransportRegionInspectorState | null;
};
