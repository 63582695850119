import './store';
import { Component } from './сomponent';

import { Modals } from '@features/pt-constructor/modals';

const ContainerPassengerTrafficConstructor = () => {
  return (
    <>
      <Component />
      <Modals />
    </>
  );
};

export default ContainerPassengerTrafficConstructor;
