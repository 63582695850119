import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const Diagram = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.51 16.4011H4.98015V6.48999C4.98015 6.21937 4.76078 6 4.48999 6C4.21937 6 4 6.21937 4 6.48999V16.8913C4 17.1617 4.21937 17.3811 4.48999 17.3811H19.51C19.7808 17.3811 20 17.1617 20 16.8913C20 16.6205 19.7808 16.4011 19.51 16.4011Z"
          fill="#273B48"
        />
        <path
          d="M14.2129 7.88939C14.0511 7.87605 13.8951 7.94204 13.7928 8.06647L10.5277 12.0423L9.2815 10.8061C9.17918 10.7047 9.03668 10.6529 8.894 10.6657C8.75062 10.6782 8.6197 10.7533 8.53652 10.8709L5.9504 14.5281C5.79403 14.749 5.84651 15.0549 6.06746 15.2111C6.28841 15.3675 6.5943 15.3146 6.75031 15.0939L9.00246 11.9097L10.2199 13.1167C10.3176 13.2141 10.4514 13.2659 10.5896 13.2583C10.7274 13.2515 10.8559 13.1867 10.9433 13.08L14.0985 9.23861L17.4147 15.0537C17.5051 15.212 17.6704 15.301 17.841 15.301C17.9231 15.301 18.0065 15.2802 18.0833 15.2367C18.3185 15.1025 18.4003 14.8032 18.266 14.5681L14.5973 8.13491C14.5174 7.99486 14.3735 7.9029 14.2129 7.88939Z"
          fill="#273B48"
        />
      </svg>
    </SvgIcon>
  );
};
