import { MapBrowserEvent } from 'ol';
import { toLonLat } from 'ol/proj';
import { useCallback } from 'react';

import { MapProps } from '@components/Map';
import { EFilterMapLayout } from '@components/MapFilters';

import {
  CustomMap,
  exchangeProxyLayouts,
  getFeatureAtPixel,
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { setFeatureSelectedStyle } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/featureTools';
import { $FilterMap, FindRegionArgs } from '@features/passenger-traffic/stores';
import { getDataForInspectorFn } from '@features/passenger-traffic/stores/inspector/store';
import { UsePTForecastHandlersType } from '@features/pt-forecast/hooks/usePTForecastHandlers/usePTForecastHandlers';

export type UseMapToolsProps = Pick<
  UsePTForecastHandlersType,
  'setStopPoint'
> & {
  findRegion: (args: FindRegionArgs) => void;
};

export const useMapTools = (props: UseMapToolsProps) => {
  const { setStopPoint, findRegion } = props;

  // Обработчик выделения фичи на карте
  const handlerGetObjectForInspector = useCallback(
    (e: MapBrowserEvent<UIEvent>) => {
      const map = e.map as CustomMap;

      const primaryClickedFeature = getFeatureAtPixel({
        map: e.map,
        pixel: e.pixel,
        excludeLayouts: [EMapFeatureLayout.pointA, EMapFeatureLayout.pointB],
      });

      if (!primaryClickedFeature) return;

      // Клик по той же фиче - игнор
      if (
        map.activeFeature &&
        map.activeFeature.get(layerIdKey) ===
          primaryClickedFeature.get(layerIdKey)
      )
        return;

      const layout = primaryClickedFeature.getProperties()[layerLayoutKey];

      // Запретить клик по станциям в маршрутной сети
      if (
        $FilterMap!.getState().layout === EFilterMapLayout.RouteNetwork &&
        layout === 'railwayStation'
      )
        return;

      // Запретить клик по ТР в маршрутной сети
      if (
        $FilterMap!.getState().layout === EFilterMapLayout.RouteNetwork &&
        layout === 'areaBorder'
      )
        return;

      // Получить данные по фиче
      getDataForInspectorFn({
        id: primaryClickedFeature.getProperties()[layerIdKey],
        layout: exchangeProxyLayouts(layout),
      });

      setFeatureSelectedStyle({ map, feature: primaryClickedFeature });
    },
    [],
  );

  // Промежуточный интерцептор клика для установки поинтов
  const handlerOnClickMap = useCallback<MapProps['onClick']>(
    e => {
      const map = e.target as CustomMap;

      if (map.isSetPoint) {
        const coords = map.getCoordinateFromPixel(e.pixel);
        const llCoords = toLonLat(coords);

        findRegion({
          lat: llCoords[0],
          lon: llCoords[1],
          point: map.isSetPoint,
        });

        setStopPoint();
      } else {
        handlerGetObjectForInspector(e);
      }
    },
    [findRegion, handlerGetObjectForInspector, setStopPoint],
  );

  return {
    handlerOnClickMap,
    handlerGetObjectForInspector,
  };
};
