import { Close } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';

interface FooterControlsProps {
  isButtonSaveDisabled: boolean;
  onSave(): void;
  onReset(): void;
}

export const FooterControls = ({
  onSave,
  onReset,
  isButtonSaveDisabled,
}: FooterControlsProps) => {
  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
    >
      <Button
        disabled={isButtonSaveDisabled}
        onClick={onSave}
        sx={{ width: '268px' }}
      >
        Сохранить
      </Button>
      <Button
        variant="text"
        sx={{ width: '268px' }}
        onClick={onReset}
      >
        <Close
          fontSize="small"
          sx={{ mr: '8px' }}
        />
        Сбросить все
      </Button>
    </Stack>
  );
};
