import {
  $ScenarioErrors,
  addErrorFn,
  resetErrorsFn,
} from './scenarioErrors.store';

import { resetScenarioPlayerFn } from '../scenarioPlayer/scenarioPlayer.store';

$ScenarioErrors
  .reset(resetScenarioPlayerFn, resetErrorsFn)
  .on(addErrorFn, (state, payload) =>
    state ? [...state, payload] : [payload],
  );
