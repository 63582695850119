import { IQueryGetProjectScenariosArgs } from '@api/gql/ag-types';
import { sample } from 'effector';

// import { EAgCalculationScenarioStatusType } from '@features/ag-constructor/constants/agCalculationScenarioStatuses';
import { queryGetScenarioStatuses } from '@features/ag-constructor/stores/api/queryGetScenarioStatuses';
import { runComputeScenarioFx } from '@features/ag-constructor/stores/currentScenario';
import {
  $CalculationStatuses,
  CalculationStatusesApi,
  UserCalculationsStatuses,
  fetchScenariosStatusesFx,
} from '@features/ag-constructor/stores/lists/statuses/store';
import { $FeatureSettings } from '@features/ag-constructor/stores/settings';
import { getCalculationScenarioStatusType } from '@features/ag-constructor/utils/getCalculationScenarioStatusType';

// Получение статусов сценариев расчета
sample({
  clock: runComputeScenarioFx.done,
  source: {
    featureSettings: $FeatureSettings,
    calculationStatuses: $CalculationStatuses,
  },
  fn: ({ featureSettings }) =>
    ({
      projectId: featureSettings.projectUUID,
    }) as IQueryGetProjectScenariosArgs,
  target: fetchScenariosStatusesFx,
});

fetchScenariosStatusesFx.use(queryGetScenarioStatuses);

// Размапить статусы в корректный формат
sample({
  clock: fetchScenariosStatusesFx.done,
  fn: request =>
    request.result?.map(item => ({
      id: item?.id,
      status: getCalculationScenarioStatusType(
        item?.resultStatus || 3,
        // IComputingRegistryScenarioResultStatusChoices.A_3,
      ),
    })) as UserCalculationsStatuses[],
  target: CalculationStatusesApi.setCalculationScenarioStatuses,
});
