import { IDownloadFile, Maybe } from '@api/gql/ag-types';
import { createApi, createStore } from 'effector';
import { createEffect } from 'effector/effector.umd';

const initFileState = {
  extension: '',
  file: '',
  name: '',
  success: false,
  url: '',
};

export const $DownloadFile = createStore<DownloadFileStore>(initFileState);

export const DownloadFileFx = createEffect<
  DownloadFileParams,
  Maybe<IDownloadFile>
>();

export const DownloadFileApi = createApi($DownloadFile, {
  setDownloadFile: (store, payload) => {
    return {
      ...store,
      ...payload,
    };
  },
});

type DownloadFileStore = IDownloadFile & { url: string };

export type DownloadFileParams = {
  fileId: string;
};
