import { useUnit } from 'effector-react';
import { Image, Layer, Rect, Stage } from 'react-konva';
import useImage from 'use-image';

import { Box, Typography } from '@mui/material';

import { SpatialObjects } from '@features/tpu-simulation-model/components/Editor/SpatialObjects';
import { $Editor, $Floors } from '@features/tpu-simulation-model/store';

export const MiniMap = () => {
  const $editor = useUnit($Editor);
  const $floors = useUnit($Floors);
  const [img] = useImage($floors.backgroundUrl);

  const scale = $editor.scale;
  const scalePercent = Math.round(scale * 100);
  const ratio = 1 / scale;

  if (scalePercent <= 100) return null;

  return (
    <Box
      sx={{
        padding: '8px 12px',
        position: 'absolute',
        left: 24,
        bottom: 24,
        backgroundColor: theme => theme.palette.background.paper,
      }}
    >
      <Stage
        style={{ margin: '0 0 8px 0' }}
        width={200}
        height={100}
        scaleX={0.1}
        scaleY={0.1}
        listening={false}
      >
        <Layer>
          <Image
            image={img}
            width={img?.width}
            height={img?.height}
          />
        </Layer>
        <SpatialObjects listening={false} />
        <Layer>
          <Rect
            x={-$editor.positionX * ratio}
            y={-$editor.positionY * ratio}
            width={$editor.width * ratio}
            height={$editor.height * ratio}
            stroke={'red'}
            strokeWidth={20}
          />
        </Layer>
      </Stage>
      <Typography
        sx={{
          fontSize: theme => theme.typography.pxToRem(12),
          lineHeight: theme => theme.typography.pxToRem(16),
          margin: '0 auto',
          width: 'fit-content',
        }}
      >
        {scalePercent}%
      </Typography>
    </Box>
  );
};
