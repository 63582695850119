import { apiClient } from '@api/client';
import { IMutation, IMutationUpdateStationArgs } from '@api/gql/types';
import { gql } from '@apollo/client';

export const mutationUpdateStation = async (
  station: IMutationUpdateStationArgs,
) => {
  const result = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation UpdateStationMutation($name: String!, $stationId: UUID!) {
        updateStation(name: $name, stationId: $stationId) {
          station {
            id
          }
        }
      }
    `,
    variables: station,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.updateStation;
};
