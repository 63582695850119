import styled from '@emotion/styled';
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from 'react';

import { Typography, typographyClasses } from '@mui/material';

type PaperCardProps = PropsWithChildren<
  {
    title: string;
  } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>;

export const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 1, 4, 4),
  borderRadius: theme.shape.borderRadius * 2,
  background: theme.palette.background.paper,
  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.15)',
  [`& > .${typographyClasses.subtitle1}`]: {
    marginBottom: theme.spacing(2),
  },
}));

export const PaperCard = (props: PaperCardProps) => {
  const { title, children, ...rest } = props;
  return (
    <Wrapper {...rest}>
      <Typography variant={'subtitle1'}>{title}</Typography>
      {children}
    </Wrapper>
  );
};
