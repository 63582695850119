import { BoxData } from '@features/passenger-traffic/components/Inspector/BoxData';
import { useUnit } from 'effector-react';
import { useMemo } from 'react';

import { Box, Divider, IconButton, Typography } from '@mui/material';

import { ChevronRightIcon } from '@components/icons';

import { $Inspector } from '../../stores/inspector';

type TransportRegionInspectorProps = {
  onClose: () => void;
};

export const TransportRegionInspector = (
  props: TransportRegionInspectorProps,
) => {
  const { onClose } = props;
  const { transportRegion } = useUnit($Inspector);

  const indicators = useMemo(
    () => ({
      population: transportRegion?.population
        ? (transportRegion.population / 1000).toFixed(2)
        : 0,
      average_number_of_employees_of_organizations_without_smp:
        transportRegion?.average_number_of_employees_of_organizations_without_smp
          ? (
              transportRegion.average_number_of_employees_of_organizations_without_smp /
              1000
            ).toFixed(2)
          : 0,
      average_monthly_salary: transportRegion?.average_monthly_salary
        ? (transportRegion.average_monthly_salary / 1000).toFixed(2)
        : 0,
      revenue_with_taxes_on_products:
        transportRegion?.revenue_with_taxes_on_products
          ? (transportRegion.revenue_with_taxes_on_products / 1000).toFixed(2)
          : 0,
    }),
    [transportRegion],
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 3,
        }}
      >
        <IconButton
          sx={{
            width: '32px',
            height: '32px',
            backgroundColor: 'primary.main',
            borderRadius: 1,
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={onClose}
        >
          <ChevronRightIcon />
        </IconButton>
        <Typography variant="subtitle2">{transportRegion?.name}</Typography>
      </Box>

      <BoxData
        name={'Численность населения'}
        value={
          indicators.population ? `${indicators.population} тыс. чел.` : 'н/д'
        }
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Среднесписочная численность работников'}
        value={
          indicators?.average_number_of_employees_of_organizations_without_smp
            ? `${indicators.average_number_of_employees_of_organizations_without_smp} тыс. чел.`
            : 'н/д'
        }
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Среднемесячная заработная плата'}
        value={
          indicators?.average_monthly_salary
            ? `${indicators.average_monthly_salary} тыс. руб.`
            : 'н/д'
        }
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Выручка (нетто) от продажи товаров, продукции, работ, услуг'}
        value={
          indicators?.revenue_with_taxes_on_products
            ? `${indicators.revenue_with_taxes_on_products} млрд руб.`
            : 'н/д'
        }
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Число мест в коллективных средствах размещения'}
        value={
          transportRegion?.number_of_places
            ? `${transportRegion?.number_of_places} шт.`
            : 'н/д'
        }
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'ВДС туризм'}
        value={
          transportRegion?.revenue_tourism_industry
            ? `${transportRegion?.revenue_tourism_industry} млн руб.`
            : 'н/д'
        }
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Уровень автомобилизации'}
        value={
          transportRegion?.level_of_motorization
            ? `${transportRegion?.level_of_motorization} авто/тыс. чел.`
            : 'н/д'
        }
      />
    </>
  );
};
