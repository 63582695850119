import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './stores';

import Grid from '@mui/material/Unstable_Grid2';

import { ROUTES } from '@constants/routes';

import { Modals } from '@features/ag-constructor/Modals';
import { RegistryContainer } from '@features/ag-constructor/containers/RegistryContainer/RegistryContainer';
import { ScenarioEditorContainer } from '@features/ag-constructor/containers/ScenarioEditorContainer/ScenarioEditorContainer';
import { ToolBarContainer } from '@features/ag-constructor/containers/ToolBarContainer/ToolBarContainer';
import {
  fetchComputedScenarioListFx,
  fetchSocEcoScenarioListFx,
} from '@features/ag-constructor/stores/lists/scenarios';
import { fetchScenariosStatusesFx } from '@features/ag-constructor/stores/lists/statuses';
import { FeatureSettingsApi } from '@features/ag-constructor/stores/settings';

export const AgglomerationConstructorContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [projectId] = useState(queryParams.get('projectId') || '');

  const handlers = useUnit({
    fetchComputedScenarioListFx,
    fetchSocEcoScenarioListFx,
    fetchScenariosStatusesFx,
  });
  const { setProjectUUID } = useUnit(FeatureSettingsApi);

  useEffect(() => {
    if (!projectId) {
      navigate(ROUTES.AGGLOMERATION);
      return;
    }

    setProjectUUID(projectId || '');
    handlers.fetchComputedScenarioListFx({ projectId });
    handlers.fetchSocEcoScenarioListFx({ projectId });
    handlers.fetchScenariosStatusesFx({ projectId });
  }, [handlers]);

  return (
    <>
      <Grid
        container
        spacing={2}
        px={1}
        m={2}
      >
        <Grid
          xs={12}
          pl={4}
        >
          <ToolBarContainer />
        </Grid>
        <Grid xs={7}>
          <RegistryContainer />
        </Grid>
        <Grid xs={5}>
          <ScenarioEditorContainer />
        </Grid>
      </Grid>
      <Modals />
    </>
  );
};
