import { createApi, createStore } from 'effector';

const initState: SettingsType = {
  scenarioId: null,
};

export const $Settings = createStore<SettingsType>(initState);

export const SettingsApi = createApi($Settings, {
  setScenarioId: (store, scenarioId: SettingsType['scenarioId']) => ({
    ...store,
    scenarioId,
  }),
  reset: () => initState,
});

type SettingsType = {
  scenarioId: string | null;
};
