import { sample } from 'effector';

import { queryDataDashboardP1 } from '@features/ag-forecast/stores/api/queryDataDashboardP1';
import {
  FirstPageApi,
  FirstPageType,
  queryDataDashboardP1Fx,
} from '@features/ag-forecast/stores/dashboard/firstPage/store';

queryDataDashboardP1Fx.use(queryDataDashboardP1);

// Запись в стор данных "Динамика совокупных транспортных затрат по времени суток"
sample({
  clock: queryDataDashboardP1Fx.done,
  target: FirstPageApi.setTotalTransportCostsDynamics,
});

// ----------------------------

// Запись в стор данных "Динамика пассажиропотока по времени суток"
sample({
  clock: queryDataDashboardP1Fx.done,
  target: FirstPageApi.setPassengerTrafficDynamics,
});

// ----------------------------

// Запись в стор данных "Структура суммарного пассажиропотока по видам транспорта в агломерации"
sample({
  clock: queryDataDashboardP1Fx.done,
  fn: request => {
    return request.result?.flowByTransport?.map(item => ({
      year: item?.year || 0,
      public: item?.publicMovementsYearly || 0,
      private: item?.privateMovementsYearly || 0,
    })) as FirstPageType['flowByTransport'];
  },
  target: FirstPageApi.setTotalPassengerTrafficByTransport,
});
