import { TooltipWarning } from '@features/ag-project/components/TooltipWarning/TooltipWarning';

import { Button, Stack, alpha, styled } from '@mui/material';

export const LayoutChanger = () => {
  return (
    <Stack direction="row">
      <SplitButton
        fullWidth
        position="left"
        selected={true}
      >
        Инфраструктура
      </SplitButton>
      <TooltipWarning label="Пассажиропоток доступен после расчета">
        <span style={{ width: '100%' }}>
          <SplitButton
            fullWidth
            position="right"
            selected={false}
            disabled
          >
            Пассажиропоток
          </SplitButton>
        </span>
      </TooltipWarning>
    </Stack>
  );
};

type SplitButtonProps = {
  position: 'left' | 'right';
  selected: boolean;
};

const SplitButton = styled(Button)<SplitButtonProps>(
  ({ theme, position, selected }) => ({
    color: selected ? theme.palette.common.white : theme.palette.text.secondary,
    borderRadius: position === 'left' ? '6px 0 0 6px' : '0 6px 6px 0',
    border: `1px solid ${theme.palette.primary.main}`,
    background: selected ? theme.palette.primary.main : 'transparent',
    '&:hover': {
      background: selected
        ? alpha(theme.palette.primary.main, 0.85)
        : 'transparent',
    },
    '&:disabled': {
      background: selected
        ? alpha(theme.palette.primary.main, 0.5)
        : 'transparent',
      color: selected
        ? alpha(theme.palette.common.white, 0.5)
        : alpha(theme.palette.text.secondary, 0.5),
    },
  }),
);
