import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import {
  CustomMap,
  getMapLayerFeatures,
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { setFeatureSelectedStyle } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/featureTools/setFeatureSelectedStyle';
import {
  changeMap,
  getDataForInspectorFn,
} from '@features/passenger-traffic/stores';
import { setMapFeatures } from '@features/pt-forecast/stores';

export const mapFocusKit = {
  focusAirport: (
    item: OptionItem,
    map: CustomMap,
    setFeaturesCb: typeof setMapFeatures,
    changeMapCb: typeof changeMap,
    inspectorCb?: typeof getDataForInspectorFn,
  ) => {
    // Запросить данные для инспектора
    inspectorCb &&
      inspectorCb({
        id: item.value,
        layout: EMapFeatureLayout.airport,
      });

    // Преобразовать координаты
    const coords = fromLonLat(item.lonLat);

    // Переместить камеру на объект
    map.getView().animate({
      center: coords,
      zoom: 10,
      duration: 150,
    });

    // Сбросить предыдущие изменения
    changeMapCb();

    // Получить существующие фичи слоя
    const existingFeatures = getMapLayerFeatures({
      map,
      layout: EMapFeatureLayout.airport,
    });

    // Найти указанную фичу среди существующих
    let feature = existingFeatures.find(
      feature => feature.get('ol_uid') === item.value,
    );

    // Если не найдено, создать
    if (!feature) {
      feature = new Feature({
        geometry: new Point(coords),
        [layerIdKey]: item.value,
        [layerLayoutKey]: EMapFeatureLayout.airport,
      });

      // Разместить фичу на слое
      setFeaturesCb({
        params: {
          layout: EMapFeatureLayout.airport,
        },
        features: [feature],
      });
    }

    // Применить стиль выделения
    setFeatureSelectedStyle({
      map,
      feature: feature,
      isReselect: true,
      isActive: true,
    });
  },

  focusBusStation: (
    item: OptionItem,
    map: CustomMap,
    setFeaturesCb: typeof setMapFeatures,
    changeMapCb: typeof changeMap,
    inspectorCb?: typeof getDataForInspectorFn,
  ) => {
    // Запросить данные для инспектора
    inspectorCb &&
      inspectorCb({
        id: item.value,
        layout: EMapFeatureLayout.busStation,
      });

    // Преобразовать координаты
    const coords = fromLonLat(item.lonLat);

    // Переместить камеру на объект
    map.getView().animate({
      center: coords,
      zoom: 10,
      duration: 150,
    });

    // Сбросить предыдущие изменения
    changeMapCb();

    // Получить существующие фичи слоя
    const existingFeatures = getMapLayerFeatures({
      map,
      layout: EMapFeatureLayout.busStation,
    });

    // Найти указанную фичу среди существующих
    let feature = existingFeatures.find(
      feature => feature.get('ol_uid') === item.value,
    );

    // Если не найдено, создать
    if (!feature) {
      feature = new Feature({
        geometry: new Point(coords),
        [layerIdKey]: item.value,
        [layerLayoutKey]: EMapFeatureLayout.busStation,
      });

      setFeaturesCb({
        params: {
          layout: EMapFeatureLayout.busStation,
        },
        features: [feature],
      });
    }

    // Применить стиль выделения
    setFeatureSelectedStyle({
      map,
      feature: feature,
      isReselect: true,
      isActive: true,
    });
  },

  focusRailwayStation: (
    item: OptionItem,
    map: CustomMap,
    setFeaturesCb: typeof setMapFeatures,
    changeMapCb: typeof changeMap,
    inspectorCb?: typeof getDataForInspectorFn,
  ) => {
    // Запросить данные для инспектора
    inspectorCb &&
      inspectorCb({
        id: item.value,
        layout: EMapFeatureLayout.railwayStation,
      });

    // Преобразовать координаты
    const coords = fromLonLat(item.lonLat);

    // Переместить камеру на объект
    map.getView().animate({
      center: coords,
      zoom: 10,
      duration: 150,
    });

    // Сбросить предыдущие изменения
    changeMapCb();

    // Получить существующие фичи слоя
    const existingFeatures = getMapLayerFeatures({
      map,
      layout: EMapFeatureLayout.airport,
    });

    // Найти указанную фичу среди существующих
    let feature = existingFeatures.find(
      feature => feature.get('ol_uid') === item.value,
    );

    // Если не найдено, создать
    if (!feature) {
      feature = new Feature({
        geometry: new Point(coords),
        [layerIdKey]: item.value,
        [layerLayoutKey]: EMapFeatureLayout.railwayStation,
      });

      setFeaturesCb({
        params: {
          layout: EMapFeatureLayout.railwayStation,
        },
        features: [feature],
      });
    }

    // Применить стиль выделения
    setFeatureSelectedStyle({
      map,
      feature: feature,
      isReselect: true,
      isActive: true,
    });
  },
};

type OptionItem = {
  name: string;
  value: string;
  lonLat: number[];
  type: string;
};
