import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';

import { AccordionFilter } from '@components/MapFilters/AccordionFilter';
import { typographyWhitCircleStyle } from '@components/MapFilters/utils';

import { VehicleTypeCode } from '@features/pt-forecast-new/constants/VehicleTypeCode';
import {
  $FilterMap,
  EFilterMapLayout,
  FilterMapApi,
} from '@features/pt-forecast-new/stores/filters';
import { $UI } from '@features/pt-forecast-new/stores/ui';

export const VehiclesFilters = () => {
  const {
    auto,
    bus,
    avia,
    dayTrain,
    nightTrain,
    expressTrain,
    suburbanTrain,
    layout,
  } = useUnit($FilterMap);
  const { setFilters } = useUnit(FilterMapApi);
  const { hideFilterFields } = useUnit($UI);

  const railwayRootStatus = useMemo(() => {
    if (
      (hideFilterFields.includes('dayTrain') || dayTrain) &&
      (hideFilterFields.includes('nightTrain') || nightTrain) &&
      (hideFilterFields.includes('expressTrain') || expressTrain) &&
      (hideFilterFields.includes('suburbanTrain') || suburbanTrain)
    )
      return 1;
    if (dayTrain || nightTrain || expressTrain || suburbanTrain) return 0;
    return -1;
  }, [dayTrain, expressTrain, hideFilterFields, nightTrain, suburbanTrain]);

  const highwayRootStatus = useMemo(() => {
    if (
      (hideFilterFields.includes('bus') || bus) &&
      (hideFilterFields.includes('auto') || auto)
    )
      return 1;
    if (bus || auto) return 0;
    return -1;
  }, [auto, bus, hideFilterFields]);

  const changeRailwayRoot = useCallback(() => {
    if (railwayRootStatus !== 1)
      setFilters({
        nightTrain: true,
        dayTrain: true,
        expressTrain: true,
        suburbanTrain: true,
      });
    else {
      setFilters({
        nightTrain: false,
        dayTrain: false,
        expressTrain: false,
        suburbanTrain: false,
      });
    }
  }, [railwayRootStatus, setFilters]);

  const setNightTrainHandler = useCallback(() => {
    setFilters({ nightTrain: !nightTrain });
  }, [nightTrain, setFilters]);
  const setDayTrainHandler = useCallback(() => {
    setFilters({ dayTrain: !dayTrain });
  }, [dayTrain, setFilters]);
  const setExpressTrainHandler = useCallback(() => {
    setFilters({ expressTrain: !expressTrain });
  }, [expressTrain, setFilters]);
  const setSuburbanTrainHandler = useCallback(() => {
    setFilters({ suburbanTrain: !suburbanTrain });
  }, [suburbanTrain, setFilters]);

  const changeHighwayRoot = useCallback(() => {
    if (highwayRootStatus !== 1)
      setFilters({
        bus: true,
        auto: true,
      });
    else {
      setFilters({
        bus: false,
        auto: false,
      });
    }
  }, [highwayRootStatus, setFilters]);

  const setAutoHandler = useCallback(() => {
    setFilters({ auto: !auto });
  }, [auto, setFilters]);
  const setBusHandler = useCallback(() => {
    setFilters({ bus: !bus });
  }, [bus, setFilters]);

  const setAviaHandler = useCallback(() => {
    setFilters({ avia: !avia });
  }, [avia, setFilters]);

  return (
    <AccordionFilter title={'По видам транспорта'}>
      <Stack direction={'column'}>
        <FormControlLabel
          checked={railwayRootStatus !== -1}
          onChange={changeRailwayRoot}
          control={<Checkbox indeterminate={railwayRootStatus === 0} />}
          label={
            <Typography
              sx={typographyWhitCircleStyle('white', '#7A5A5A')}
              variant={'body2'}
            >
              ЖД транспорт
            </Typography>
          }
        />
        {layout === EFilterMapLayout.PassengerFlows && (
          <Stack
            pl={4}
            direction={'column'}
          >
            {!hideFilterFields.includes('nightTrain') && (
              <FormControlLabel
                checked={nightTrain}
                onChange={setNightTrainHandler}
                control={<Checkbox />}
                label={VehicleTypeCode.nightTrain}
              />
            )}
            {!hideFilterFields.includes('dayTrain') && (
              <FormControlLabel
                checked={dayTrain}
                onChange={setDayTrainHandler}
                control={<Checkbox />}
                label={VehicleTypeCode.dayTrain}
              />
            )}
            {!hideFilterFields.includes('suburbanTrain') && (
              <FormControlLabel
                checked={suburbanTrain}
                onChange={setSuburbanTrainHandler}
                control={<Checkbox />}
                label={VehicleTypeCode.suburbanTrain}
              />
            )}
            {!hideFilterFields.includes('expressTrain') && (
              <FormControlLabel
                checked={expressTrain}
                onChange={setExpressTrainHandler}
                control={<Checkbox />}
                label={VehicleTypeCode.expressTrain}
              />
            )}
          </Stack>
        )}

        <FormControlLabel
          checked={highwayRootStatus === 1}
          onChange={changeHighwayRoot}
          control={<Checkbox indeterminate={highwayRootStatus === 0} />}
          label={
            <Typography
              sx={typographyWhitCircleStyle('#FF792E', '#FF792E')}
              variant={'body2'}
            >
              Автомобильный транспорт
            </Typography>
          }
        />
        {layout === EFilterMapLayout.PassengerFlows && (
          <Stack
            pl={4}
            direction={'column'}
          >
            {!hideFilterFields.includes('bus') && (
              <FormControlLabel
                checked={bus}
                onChange={setBusHandler}
                control={<Checkbox />}
                label={VehicleTypeCode.bus}
              />
            )}
            {!hideFilterFields.includes('auto') && (
              <FormControlLabel
                checked={auto}
                onChange={setAutoHandler}
                control={<Checkbox />}
                label={VehicleTypeCode.auto}
              />
            )}
          </Stack>
        )}

        {!hideFilterFields.includes('avia') && (
          <FormControlLabel
            checked={avia}
            onChange={setAviaHandler}
            control={<Checkbox />}
            label={
              <Typography
                sx={typographyWhitCircleStyle('#00A1DC', '#00A1DC')}
                variant={'body2'}
              >
                Авиатранспорт
              </Typography>
            }
          />
        )}
      </Stack>
    </AccordionFilter>
  );
};
