import { SvgIcon, SvgIconProps } from '@mui/material';

export const TransparancyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <svg
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 7a3 3 0 0 0-3-3H6.93A2.93 2.93 0 0 0 4 6.93V14a3 3 0 0 0 3 3 3 3 0 0 0 3 3h7.07A2.93 2.93 0 0 0 20 17.07V10a3 3 0 0 0-3-3ZM7 10v6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2h-6a3 3 0 0 0-3 3Zm6.293 6h-1.586L16 11.707v1.586L13.293 16Zm2.446-1.032c-.18.323-.448.59-.771.771l.771-.771ZM10.293 16H8.707L16 8.707v1.586L10.293 16Zm.414-8h1.586L8 12.293v-1.586L10.707 8ZM8.261 9.032a2 2 0 0 1 .771-.771l-.771.771ZM13.707 8h1.586L8 15.293v-1.586L13.707 8ZM19 17.07A1.93 1.93 0 0 1 17.07 19H10a2.002 2.002 0 0 1-2-2h6.07A2.93 2.93 0 0 0 17 14.07V8a2.003 2.003 0 0 1 2 2v7.07Z"
          fill="#273B48"
        />
      </svg>
    </SvgIcon>
  );
};
