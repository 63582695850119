import { InfoOutlined } from '@mui/icons-material';
import { Stack, Tooltip, Typography } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';

type TooltipWarningProps = {
  label: string;
  children: TooltipProps['children'];
};

export const TooltipWarning = ({ children, label }: TooltipWarningProps) => (
  <Tooltip
    title={
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
      >
        <InfoOutlined color={'error'} />
        <Typography>{label}</Typography>
      </Stack>
    }
    componentsProps={{
      popper: {
        sx: ({ palette }) => ({
          '& .MuiTooltip-tooltip': {
            backgroundColor: palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: '0px 2px 4px 0px #0000001A',
            maxWidth: '520px',
            padding: '10px 16px',
          },
        }),
      },
    }}
    placement="top"
  >
    {children}
  </Tooltip>
);
