import { IQueryGetComputingRegistryInstanceByIdArgs } from '@api/gql/types';
import { createApi, createEffect, createStore } from 'effector';

import { EVehicle } from '@features/pt-forecast-new/constants/eVehicle';
import { ComputedResulItem } from '@features/pt-forecast-new/stores/lists/computed';

const initState: PassengerTrafficStore = {
  currentData: null,
  passengerTrafficPercent: {
    [EVehicle.auto]: 0,
    [EVehicle.suburbanTrain]: 0,
    [EVehicle.nightTrain]: 0,
    [EVehicle.dayTrain]: 0,
    [EVehicle.bus]: 0,
    [EVehicle.multimodal]: 0,
    [EVehicle.avia]: 0,
    [EVehicle.expressTrain]: 0,
  },
  recalculatedTotal: 0,
  dailyUnevennessSummary: [],
  unevennessSummary: [],
};

export const $ComputedData = createStore<PassengerTrafficStore>(initState);

export const ComputedDataApi = createApi($ComputedData, {
  setComputedData: (store, payload: ComputedResulItem | null) => ({
    ...store,
    currentData: payload,
  }),
  setPassengerTraffic: (store, payload: Record<EVehicle, number>) => ({
    ...store,
    passengerTraffic: payload,
  }),
  setPassengerTrafficPercent: (store, payload: Record<EVehicle, number>) => ({
    ...store,
    passengerTrafficPercent: payload,
  }),
  setTotal: (store, payload: number) => ({
    ...store,
    recalculatedTotal: payload,
  }),
  setAll: (store, payload: PassengerTrafficStore) => ({
    ...store,
    ...payload,
  }),
  setDailyUnevennessSummary: (store, payload: number[]) => ({
    ...store,
    dailyUnevennessSummary: payload,
  }),
  setUnevennessSummary: (store, payload: number[]) => ({
    ...store,
    unevennessSummary: payload,
  }),
  reset: () => initState,
});

export const getDownloadLinkFx = createEffect<
  IQueryGetComputingRegistryInstanceByIdArgs,
  boolean
>();

export type PassengerTrafficStore = {
  currentData: ComputedResulItem | null;
  passengerTrafficPercent: Record<EVehicle, number>;
  recalculatedTotal: number;
  dailyUnevennessSummary: number[];
  unevennessSummary: number[];
};
