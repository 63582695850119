import { apiClient } from '@api/client';
import {
  IMutation,
  IMutationUpdateComputedResultsParamsArgs,
  IQueryGetComputingRegistryInstanceByIdArgs,
} from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import {
  $ComputedData,
  ComputedDataApi,
} from '@features/pt-forecast-new/stores/computedData';
import { FilterMapApi } from '@features/pt-forecast-new/stores/filters';
import {
  RecalculateApi,
  RecalculateStore,
  updateComputedResultParamsFx,
} from '@features/pt-forecast-new/stores/recalculate/store';
import {
  $FeatureSettings,
  getScenarioComputedStatusFx,
} from '@features/pt-forecast-new/stores/settings';

// При сбросе сета данных, сбросить стор перерасчета
sample({
  clock: [ComputedDataApi.reset, FilterMapApi.reset],
  target: RecalculateApi.reset,
});

// При получении выборки данных внести их в стор перерасчета
sample({
  clock: ComputedDataApi.setComputedData,
  source: { ComputedData: $ComputedData },
  filter: ({ ComputedData }) => ComputedData !== null,
  fn: ({ ComputedData }) => {
    return {
      price: ComputedData.currentData!.price,
      frequency: ComputedData.currentData!.frequency,
      duration: ComputedData.currentData!.duration,
      id: ComputedData.currentData!.id,
    } as RecalculateStore;
  },
  target: RecalculateApi.setRecalculateParameters,
});

updateComputedResultParamsFx.use(async params => {
  const result = await apiClient.mutate<
    Pick<IMutation, 'updateComputedResultsParams'>,
    IMutationUpdateComputedResultsParamsArgs
  >({
    mutation: gql`
      mutation QueryUpdateComputedResultsParams(
        $durationAuto: Float
        $durationAvia: Float
        $durationBus: Float
        $durationDayTrain: Float
        $durationExpressSuburbanTrain: Float
        $durationNightTrain: Float
        $durationPlain: Float
        $durationSuburbanTrain: Float
        $frequencyAvia: Float
        $frequencyBus: Float
        $frequencyDayTrain: Float
        $frequencyExpressSuburbanTrain: Float
        $frequencyNightTrain: Float
        $frequencyPlain: Float
        $frequencySuburbanTrain: Float
        $id: UUID!
        $priceAuto: Float
        $priceAvia: Float
        $priceBus: Float
        $priceDayTrain: Float
        $priceExpressSuburbanTrain: Float
        $priceNightTrain: Float
        $pricePlain: Float
        $priceSuburbanTrain: Float
      ) {
        updateComputedResultsParams(
          durationAuto: $durationAuto
          durationAvia: $durationAvia
          durationBus: $durationBus
          durationDayTrain: $durationDayTrain
          durationExpressSuburbanTrain: $durationExpressSuburbanTrain
          durationNightTrain: $durationNightTrain
          durationPlain: $durationPlain
          durationSuburbanTrain: $durationSuburbanTrain
          frequencyAvia: $frequencyAvia
          frequencyBus: $frequencyBus
          frequencyDayTrain: $frequencyDayTrain
          frequencyExpressSuburbanTrain: $frequencyExpressSuburbanTrain
          frequencyNightTrain: $frequencyNightTrain
          frequencyPlain: $frequencyPlain
          frequencySuburbanTrain: $frequencySuburbanTrain
          id: $id
          priceAuto: $priceAuto
          priceAvia: $priceAvia
          priceBus: $priceBus
          priceDayTrain: $priceDayTrain
          priceExpressSuburbanTrain: $priceExpressSuburbanTrain
          priceNightTrain: $priceNightTrain
          pricePlain: $pricePlain
          priceSuburbanTrain: $priceSuburbanTrain
        ) {
          computingResultsObj {
            durationAuto
            durationAvia
            durationBus
            durationDayTrain
            durationExpressSuburbanTrain
            durationNightTrain
            durationPlain
            durationSuburbanTrain
            frequencyAvia
            frequencyBus
            frequencyDayTrain
            frequencyExpressSuburbanTrain
            frequencyNightTrain
            frequencyPlain
            frequencySuburbanTrain
            id
            priceAuto
            priceAvia
            priceBus
            priceDayTrain
            priceExpressSuburbanTrain
            priceNightTrain
            pricePlain
            priceSuburbanTrain
            year
          }
          result
          errors
        }
      }
    `,
    variables: params,
  });

  return result.data?.updateComputedResultsParams || null;
});

// Если запрос на перерасчет прошел успешно, получить статус перерасчета
sample({
  clock: updateComputedResultParamsFx.done,
  source: { FeatureSettings: $FeatureSettings },
  fn: ({ FeatureSettings }) => {
    return {
      id: FeatureSettings.scenarioId || '',
    } as IQueryGetComputingRegistryInstanceByIdArgs;
  },
  target: getScenarioComputedStatusFx,
});
