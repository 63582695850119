import { sample } from 'effector';

import { $SelectedFeatures } from '../map';
import { UIApi } from '../ui';
import { InspectorApi, InspectorState } from './store';

sample({
  clock: $SelectedFeatures,
  source: { SelectedFeatures: $SelectedFeatures },
  fn: ({ SelectedFeatures }) => {
    if (!SelectedFeatures.length) return null;
    return {
      average_monthly_salary:
        Number(SelectedFeatures[0].instance.get('average_monthly_salary')) ||
        'н/д',
      average_number_of_employees_of_organizations_without_smp:
        Number(
          SelectedFeatures[0].instance.get(
            'average_number_of_employees_of_organizations_without_smp',
          ),
        ) || 'н/д',
      level_of_motorization:
        Number(SelectedFeatures[0].instance.get('level_of_motorization')) ||
        'н/д',
      name: SelectedFeatures[0].instance.get('name') || 'н/д',
      number_of_places:
        Number(SelectedFeatures[0].instance.get('number_of_places')) || 'н/д',
      revenue_tourism_industry:
        Number(SelectedFeatures[0].instance.get('revenue_tourism_industry')) ||
        'н/д',
      population:
        Number(SelectedFeatures[0].instance.get('population')) || 'н/д',
      revenue_with_taxes_on_products:
        Number(
          SelectedFeatures[0].instance.get('revenue_with_taxes_on_products'),
        ) || 'н/д',
      total_flow:
        Number(SelectedFeatures[0].instance.get('total_flow')) || 'н/д',
    } as InspectorState['transportRegion'];
  },
  target: InspectorApi.setTransportRegion,
});

sample({
  clock: $SelectedFeatures,
  source: { SelectedFeatures: $SelectedFeatures },
  fn: ({ SelectedFeatures }) => {
    return !!SelectedFeatures.length;
  },
  target: UIApi.setInspector,
});
