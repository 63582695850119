import { EMapFeatureLayout } from '@constants/map';

import { tpuGraphsVectorStyle } from '@utils/map/styles/TPUGraphsVectorStyle';
import { tpuVectorStyle } from '@utils/map/styles/TPUVectorStyle';
import { agRailwayStationVectorStyle } from '@utils/map/styles/agRailwayStationVectorStyle';
import { agSearchInfraVectorStyle } from '@utils/map/styles/agSearchInfraVectorStyle';
import { agTransportFlowDistrictsVectorStyle } from '@utils/map/styles/agTransportFlowDistrictsVectorStyle';
import { graphsVectorStyle } from '@utils/map/styles/graphsVectorStyle';
import { ptAirportVectorStyle } from '@utils/map/styles/passengerTraffic/vector/ptAirportVectorStyle';
import { ptAreaBorderVictorStyle } from '@utils/map/styles/passengerTraffic/vector/ptAreaBorderVictorStyle';
import { ptBusStationVectorStyle } from '@utils/map/styles/passengerTraffic/vector/ptBusStationVectorStyle';
import { ptCustomRailwayGraphVectorStyle } from '@utils/map/styles/passengerTraffic/vector/ptCustomRailwayGraphVectoreStyle';
import { ptCustomRailwayStationVectorStyle } from '@utils/map/styles/passengerTraffic/vector/ptCustomRailwayStationVectorStyle';
import { ptDirectionAirlineVectorStyle } from '@utils/map/styles/passengerTraffic/vector/ptDirectionAirlineVectorStyle';
import { ptDirectionHighwayVectorStyle } from '@utils/map/styles/passengerTraffic/vector/ptDirectionHighwayVectorStyle';
import { ptDirectionRailwayVectorStyleDashed } from '@utils/map/styles/passengerTraffic/vector/ptDirectionRailwayThemes/ptDirectionRailwayVectorStyleDashed';
import { ptDirectionRailwayVectorStyleFilledGray } from '@utils/map/styles/passengerTraffic/vector/ptDirectionRailwayThemes/ptDirectionRailwayVectorStyleFilledGray';
import { ptPointsABVectorStyle } from '@utils/map/styles/passengerTraffic/vector/ptPointsABVectorStyle';
import { ptRailwayStationVectorStyle } from '@utils/map/styles/passengerTraffic/vector/ptRailwayStationVectorStyle';
import { ptSearchInfraVectorStyle } from '@utils/map/styles/passengerTraffic/vector/ptSearchInfraVectorStyle';
import { animalPassageVectorStyle } from '@utils/map/styles/socEcoWidget/animalPassageVectorStyle';
import { animalProtectionVibrationVectorStyle } from '@utils/map/styles/socEcoWidget/animalProtectionVibrationVectorStyle';
import { changingRoutesVectorStyle } from '@utils/map/styles/socEcoWidget/changingRoutesVectorStyle';
import { culvertsVectorStyle } from '@utils/map/styles/socEcoWidget/culvertsVectorStyle';
import { protectionVibrationVectorStyle } from '@utils/map/styles/socEcoWidget/protectionVibrationVectorStyle';
import { safeCrossingVectorStyle } from '@utils/map/styles/socEcoWidget/safeCrossingVectorStyle';
import { stationAccessibilityVectorStyle } from '@utils/map/styles/socEcoWidget/stationAccessibilityVectorStyle';
import { walkingAreaVectorStyle } from '@utils/map/styles/socEcoWidget/walkingAreaVectorStyle';
import { transportDistrictsVectorStyle } from '@utils/map/styles/transportDistrictsVectorStyle';

export enum EVectorLayerStyles {
  default = 'default',
  railwayDashed = 'railwayDashed',
}

export type getVectorLayerStyleProps = {
  theme: EVectorLayerStyles;
  layout: EMapFeatureLayout;
};

export const getVectorLayerStyle = ({
  layout,
  theme,
}: getVectorLayerStyleProps) => {
  switch (layout) {
    case EMapFeatureLayout.tpu:
      return tpuVectorStyle;
    case EMapFeatureLayout.tpuGraphs:
      return tpuGraphsVectorStyle;
    case EMapFeatureLayout.graphs:
      return graphsVectorStyle;
    case EMapFeatureLayout.transportDistricts:
      return transportDistrictsVectorStyle;
    case EMapFeatureLayout.agRailwayStations:
      return agRailwayStationVectorStyle;
    case EMapFeatureLayout.transportFlowDistricts:
      return agTransportFlowDistrictsVectorStyle;
    case EMapFeatureLayout.agSearchInfra:
      return agSearchInfraVectorStyle;
    case EMapFeatureLayout.animalPassage:
      return animalPassageVectorStyle;
    case EMapFeatureLayout.animalProtectionVibration:
      return animalProtectionVibrationVectorStyle;
    case EMapFeatureLayout.changingRoutes:
      return changingRoutesVectorStyle;
    case EMapFeatureLayout.culverts:
      return culvertsVectorStyle;
    case EMapFeatureLayout.protectionVibration:
      return protectionVibrationVectorStyle;
    case EMapFeatureLayout.safeCrossing:
      return safeCrossingVectorStyle;
    case EMapFeatureLayout.stationAccessibility:
      return stationAccessibilityVectorStyle;
    case EMapFeatureLayout.walkingArea:
      return walkingAreaVectorStyle;
    case EMapFeatureLayout.areaBorder:
      return ptAreaBorderVictorStyle;
    case EMapFeatureLayout.directionHighway:
      return ptDirectionHighwayVectorStyle;
    case EMapFeatureLayout.directionRailway:
      switch (theme) {
        case EVectorLayerStyles.default:
          return ptDirectionRailwayVectorStyleFilledGray;
        case EVectorLayerStyles.railwayDashed:
          return ptDirectionRailwayVectorStyleDashed;
        default:
          throw new Error(`Unknown theme - ${theme} for layer - ${layout}`);
      }
    case EMapFeatureLayout.directionAirline:
      return ptDirectionAirlineVectorStyle;
    case EMapFeatureLayout.busStation:
      return ptBusStationVectorStyle;
    case EMapFeatureLayout.railwayStation:
      return ptRailwayStationVectorStyle;
    case EMapFeatureLayout.airport:
      return ptAirportVectorStyle;
    case EMapFeatureLayout.pointsAB:
      return ptPointsABVectorStyle;
    case EMapFeatureLayout.customRailwayStation:
      return ptCustomRailwayStationVectorStyle;
    case EMapFeatureLayout.customRailwayGraph:
      return ptCustomRailwayGraphVectorStyle;
    case EMapFeatureLayout.ptSearchInfra:
      return ptSearchInfraVectorStyle;
    default:
      throw new Error(`Unknown layer - ${layout}`);
  }
};
