import { IGraphInstanceType } from '@api/gql/ag-types';
import { $Vehicles } from '@features/ag-forecast/stores/lists/vehicles';
import { BoxData } from '@features/passenger-traffic/components/Inspector/BoxData';
import { useUnit } from 'effector-react';
import { useMemo } from 'react';

import { Box, Divider, IconButton, Typography } from '@mui/material';

import { ChevronRightIcon } from '@components/icons';

export type GraphInspectorProps = {
  onClose: () => void;
  fields: Pick<
    IGraphInstanceType,
    'category' | 'transport' | 'laneNum' | 'oneway' | 'freeSpeed' | 'capacity'
  >;
};

export const GraphInspector = (props: GraphInspectorProps) => {
  const {
    fields: { category, laneNum, oneway, freeSpeed, capacity, transport },
    onClose,
  } = props;
  const $vehicles = useUnit($Vehicles);

  const name = category
    ? category[0].toUpperCase() + category.slice(1)
    : 'Безымянный';

  const transportString = useMemo(() => {
    if (!$vehicles) return 'н/д';
    return transport
      ? transport
          .map(
            vehicleId =>
              $vehicles.find(vehicle => vehicle && vehicle.id === vehicleId)
                ?.name,
          )
          .join(', ')
      : 'н/д';
  }, [$vehicles, transport]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 3,
        }}
      >
        <IconButton
          sx={{
            width: '32px',
            height: '32px',
            backgroundColor: 'primary.main',
            borderRadius: 1,
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={onClose}
        >
          <ChevronRightIcon />
        </IconButton>
        <Typography variant="subtitle2">{name}</Typography>
      </Box>

      <BoxData
        name={'Категория'}
        value={name || 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Разрешенные виды транспорта'}
        value={transportString}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Число полос движения'}
        value={laneNum || 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Наличие выделенного / обособленного пути для маршрутных ТС'}
        value={typeof oneway === 'boolean' ? (oneway ? 'Да' : 'Нет') : 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Разрешенная скорость движения'}
        value={freeSpeed ? `До ${freeSpeed} км/ч.` : 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={`Провозная возможность для - ${name}`}
        value={capacity ? `${capacity} пасс./ч.` : 'н/д'}
      />
    </>
  );
};
