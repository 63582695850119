import { Map as MapOl } from 'ol';
import { memo, useRef } from 'react';

import { Map } from '@components/Map';

import { EMapFeatureLayout } from '@constants/map';

import { LayersZIndex } from '@features/ag-project/constants/LayersZIndex';
import { initLayersVisible } from '@features/ag-project/constants/inits';
import {
  clickMap,
  pipeAgSearchInfraVectorLayer,
  pipeAutoTileLayer,
  pipeAviaTileLayer,
  pipeBusTileLayer,
  pipeFunicularTileLayer,
  pipeMapControls,
  pipeMetroTileLayer,
  pipeMonoRailWayTileLayer,
  pipePedestrainTileLayer,
  pipeRailwayStationsLayer,
  pipeRopeWayTileLayer,
  pipeSuburbanRailwayTileLayer,
  pipeTramTileLayer,
  pipeTransportDistrictsTileLayer,
  pipeTrolleybusTileLayer,
  pipeWaterWayTileLayer,
} from '@features/ag-project/stores/map';

import { useInitMapControls } from '@utils/map/hooks/useInitMapControls';
import { useInitTileLayer } from '@utils/map/hooks/useInitTileLayer';
import { useInitVectorLayer } from '@utils/map/hooks/useInitVectorLayer';
import { ETileLayerStyles } from '@utils/map/tools/getTileLayerStyle';
import { EVectorLayerStyles } from '@utils/map/tools/getVectorLayerStyle';

export const MapContainer = memo(() => {
  const mapRef = useRef<MapOl | null>(null);

  useInitMapControls({
    mapRef,
    events: [pipeMapControls],
  });

  // Граф автодорог
  useInitTileLayer({
    mapRef,
    config: {
      source: undefined,
      zIndex: LayersZIndex[EMapFeatureLayout.graphAuto],
      layout: EMapFeatureLayout.graphAuto,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.graphAuto],
    },
    events: [pipeAutoTileLayer],
  });

  // Граф автобусов
  useInitTileLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.graphBus],
      layout: EMapFeatureLayout.graphBus,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.graphBus],
    },
    events: [pipeBusTileLayer],
  });

  // Граф Троллейбусов
  useInitTileLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.graphTrolleybus],
      layout: EMapFeatureLayout.graphTrolleybus,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.graphTrolleybus],
    },
    events: [pipeTrolleybusTileLayer],
  });

  // Граф трамваев
  useInitTileLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.graphTram],
      layout: EMapFeatureLayout.graphTram,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.graphTram],
    },
    events: [pipeTramTileLayer],
  });

  // Граф метро
  useInitTileLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.graphMetro],
      layout: EMapFeatureLayout.graphMetro,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.graphMetro],
    },
    events: [pipeMetroTileLayer],
  });

  // Граф жд
  useInitTileLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.graphSuburbanRailway],
      layout: EMapFeatureLayout.graphSuburbanRailway,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.graphSuburbanRailway],
    },
    events: [pipeSuburbanRailwayTileLayer],
  });

  // Граф канатных дорог
  useInitTileLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.graphRopeWay],
      layout: EMapFeatureLayout.graphRopeWay,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.graphRopeWay],
    },
    events: [pipeRopeWayTileLayer],
  });

  // Граф водного транспорта
  useInitTileLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.graphWaterWay],
      layout: EMapFeatureLayout.graphWaterWay,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.graphWaterWay],
    },
    events: [pipeWaterWayTileLayer],
  });

  // Граф фуникулеров
  useInitTileLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.graphFunicular],
      layout: EMapFeatureLayout.graphFunicular,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.graphFunicular],
    },
    events: [pipeFunicularTileLayer],
  });

  // Граф монорельсов
  useInitTileLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.graphMonoRailWay],
      layout: EMapFeatureLayout.graphMonoRailWay,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.graphMonoRailWay],
    },
    events: [pipeMonoRailWayTileLayer],
  });

  // Граф пешеходных дорог
  useInitTileLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.graphPedestrain],
      layout: EMapFeatureLayout.graphPedestrain,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.graphPedestrain],
    },
    events: [pipePedestrainTileLayer],
  });

  // Граф авиа
  useInitTileLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.graphAvia],
      layout: EMapFeatureLayout.graphAvia,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.graphAvia],
    },
    events: [pipeAviaTileLayer],
  });

  //Транспортные зоны
  useInitTileLayer({
    mapRef,
    config: {
      source: undefined,
      zIndex: LayersZIndex[EMapFeatureLayout.transportDistricts],
      layout: EMapFeatureLayout.transportDistricts,
      theme: ETileLayerStyles.default,
      visible: initLayersVisible.transportDistricts,
    },
    events: [pipeTransportDistrictsTileLayer],
  });

  // Жд станции
  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.agRailwayStations],
      layout: EMapFeatureLayout.agRailwayStations,
      theme: EVectorLayerStyles.default,
      visible: initLayersVisible[EMapFeatureLayout.agRailwayStations],
    },
    events: [pipeRailwayStationsLayer],
  });

  // Слой поисковых элементов
  useInitVectorLayer({
    mapRef,
    config: {
      zIndex: LayersZIndex[EMapFeatureLayout.agSearchInfra],
      layout: EMapFeatureLayout.agSearchInfra,
      theme: EVectorLayerStyles.default,
      visible: false,
    },
    events: [pipeAgSearchInfraVectorLayer],
  });

  return (
    <Map
      ref={mapRef}
      setMap={() => {}}
      onClick={clickMap}
    />
  );
});
