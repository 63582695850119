import { EMapFeatureLayout } from '@constants/map';

export const LAYERS_Z_INDEX: Partial<Record<EMapFeatureLayout, number>> = {
  [EMapFeatureLayout.plannedTileHighway]: 3,
  [EMapFeatureLayout.plannedTileRailway]: 4,
  [EMapFeatureLayout.areaBorder]: 1,
  [EMapFeatureLayout.auto]: 2,
  [EMapFeatureLayout.highway]: 3,
  [EMapFeatureLayout.plannedHighway]: 3,
  [EMapFeatureLayout.directionHighway]: 3,
  [EMapFeatureLayout.railway]: 4,
  [EMapFeatureLayout.plannedRailway]: 4,
  [EMapFeatureLayout.directionRailway]: 4,
  [EMapFeatureLayout.avia]: 5,
  [EMapFeatureLayout.airline]: 5,
  [EMapFeatureLayout.busStation]: 6,
  [EMapFeatureLayout.railwayStation]: 7,
  [EMapFeatureLayout.intermediateCorrespondents]: 7,
  [EMapFeatureLayout.airport]: 8,
  [EMapFeatureLayout.pointA]: 9,
  [EMapFeatureLayout.pointB]: 9,
  [EMapFeatureLayout.tpuGraphs]: 11,
  [EMapFeatureLayout.tpu]: 12,
  [EMapFeatureLayout.tpuDash]: 13,
  [EMapFeatureLayout.graphs]: 3,
  [EMapFeatureLayout.transportDistricts]: 4,
  [EMapFeatureLayout.agRailwayStations]: 5,
  [EMapFeatureLayout.multimodal]: 3,
};
