import {
  IDataFileTypeEnum,
  IDownloadFileTypeEnum,
  IHubCreationTypeEnum,
  IHubProjectStateChoices,
  IMutation,
  IQuery,
} from '@api/gql/tpu-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import {
  Box,
  Button,
  Divider,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { AsyncAutocomplete } from '@components/AsyncAutocomplete';
import { CloseIcon, DownloadIcon, InfoOutlinedIcon } from '@components/icons';

import { TPU_CLIENT_NAME } from '@constants/api';

import {
  GET_ALL_AGGLOMERATIONS_QUERY,
  GET_ALL_HUBS_QUERY,
  UPLOAD_DATA_FILE,
  UPLOAD_FLOOR_FILE,
} from '@features/tpu-project-registry/api';
import {
  FileUploader,
  FloorFilesUploader,
  FooterControls,
} from '@features/tpu-project-registry/components/project-form';
import { DATA_FILES_LABEL } from '@features/tpu-project-registry/constants';
import { useContextPRHandlers } from '@features/tpu-project-registry/hooks';
import {
  $FileTemplates,
  $Form,
  $IsButtonSaveDisabled,
  $IsOpenForm,
} from '@features/tpu-project-registry/stores/form/form.store';

export const ProjectEditorContainer = () => {
  const {
    saveProject,
    setDataFile,
    setFloorFile,
    setFloorFileDescription,
    setAgglomeration,
    setHub,
    setName,
    resetFormState,
    getFileTemplates,
    addFloorFileField,
    deleteFloorFile,
    deleteDataFile,
    resetProjectData,
    setHubSearch,
    setIsOpenFileFormatModal,
    changeFloorFilesOrder,
    downloadFile,
  } = useContextPRHandlers();
  const $form = useUnit($Form);
  const $isOpenForm = useUnit($IsOpenForm);
  const $fileTemplates = useUnit($FileTemplates);
  const $isButtonSaveDisabled = useUnit($IsButtonSaveDisabled);
  const [getAllHubs] = useLazyQuery<IQuery>(GET_ALL_HUBS_QUERY, {
    context: { clientName: TPU_CLIENT_NAME },
    variables: { id: $form.agglomeration?.id, search: $form.hubSearch },
  });
  const [getAllAgglomerations] = useLazyQuery<IQuery>(
    GET_ALL_AGGLOMERATIONS_QUERY,
    {
      context: { clientName: TPU_CLIENT_NAME },
    },
  );

  const [uploadFloorFile] = useMutation<IMutation>(UPLOAD_FLOOR_FILE);
  const [uploadDataFile] = useMutation<IMutation>(UPLOAD_DATA_FILE);

  const onChangeDataFile =
    (dataType: IDataFileTypeEnum) =>
    async (file: File | undefined, signal: AbortSignal) => {
      const response = await uploadDataFile({
        variables: {
          dataType,
          file,
          projectId: $form.projectId,
        },
        context: {
          clientName: TPU_CLIENT_NAME,
          fetchOptions: {
            signal,
          },
        },
      });

      const fileInfo = response.data?.uploadDataFile?.fileInfo;

      fileInfo && setDataFile(fileInfo);
    };

  const onFloorFileChange = async (
    file: File | undefined,
    index: number,
    signal: AbortSignal,
  ) => {
    const response = await uploadFloorFile({
      variables: {
        file,
        projectId: $form.projectId,
      },
      context: {
        clientName: TPU_CLIENT_NAME,
        fetchOptions: {
          signal,
        },
      },
    });

    const fileInfo = response.data?.uploadFloorFile?.fileInfo;

    fileInfo && setFloorFile({ index, data: fileInfo });
  };

  const onLabelChange = (description: string, index: number) => {
    setFloorFileDescription({ description, index });
  };

  const onDeleteDataFile = (dataType: IDataFileTypeEnum) => () => {
    deleteDataFile(dataType);
  };

  const onDeleteFloorFile = (index: number) => {
    deleteFloorFile(index);
  };

  const openFileFormatModalWindow = () => setIsOpenFileFormatModal(true);

  useEffect(() => {
    getFileTemplates();
  }, [getFileTemplates]);

  if (!$isOpenForm) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: theme => theme.palette.background.paper,
        flexDirection: 'column',
        boxShadow: '0px 0px 8px 0px #00000026',
        padding: '24px',
        borderRadius: '4px',
        overflow: 'auto',
        flex: '1',
        gap: '24px',
      }}
    >
      <Button fullWidth>Выбрать проект ТПУ из модуля Агломерация</Button>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          fontWeight="bold"
          variant="body2"
        >
          {$form.state === IHubProjectStateChoices.Draft
            ? 'Создать новый проект'
            : 'Редактировать проект'}
        </Typography>

        <Button
          variant="text"
          onClick={resetFormState}
          sx={{
            padding: '10px',
            minWidth: 'unset',
            width: '44px',
            height: '44px',
          }}
        >
          <CloseIcon />
        </Button>
      </Box>
      <Stack>
        <AsyncAutocomplete
          placeholder={'Агломерация'}
          clearText={'Очистить'}
          preload
          disabled={
            $form.hub?.creationType === IHubCreationTypeEnum.Real &&
            !!$form.agglomeration?.name
          }
          value={$form.agglomeration}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          getOptionLabel={value => value?.name ?? ''}
          getOptionKey={option => option?.id}
          loadOptionsMethod={async () => {
            const response = await getAllAgglomerations();
            return response.data?.allAgglomerations ?? [];
          }}
          onChange={(_, value) => {
            setAgglomeration(value);
          }}
          sx={{ mb: '12px' }}
        />
        <AsyncAutocomplete
          placeholder={'Название ТПУ'}
          clearText={'Очистить'}
          preload
          value={$form.hub}
          onInputValueChange={setHubSearch}
          getOptionLabel={value => value?.name ?? ''}
          getOptionKey={option => option?.id}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          loadOptionsMethod={async () => {
            const response = await getAllHubs();

            return response.data?.allHubs ?? [];
          }}
          onChange={(_, value) => {
            setHub(value);
          }}
          sx={{ mb: '12px' }}
        />
        <TextField
          value={$form.hub?.code ?? ''}
          placeholder={'Код ТПУ'}
          disabled
          onChange={() => {}}
          sx={{ mb: '12px' }}
        />
        <TextField
          fullWidth
          placeholder={'Название проекта'}
          value={$form.name}
          onChange={e =>
            setName(
              e.target.value.replace(/[.*+?^${}()@!#%&_"<>№:=/';,|[\]\\]/g, ''),
            )
          }
          sx={{
            mb: '12px',
          }}
        />
        <Stack gap="4px">
          <Link
            sx={{
              fontSize: theme => theme.typography.pxToRem(12),
              lineHeight: theme => theme.typography.pxToRem(16),
              display: 'flex',
              alignItems: 'center',
              height: '24px',
              gap: '6px',
              cursor: 'pointer',
            }}
            onClick={openFileFormatModalWindow}
          >
            <InfoOutlinedIcon color="inherit" />
            <Typography
              color="inherit"
              fontSize="inherit"
              component="span"
            >
              Требования к форматам файлов
            </Typography>
          </Link>

          <Link
            sx={{
              fontSize: theme => theme.typography.pxToRem(12),
              lineHeight: theme => theme.typography.pxToRem(16),
              display: 'flex',
              alignItems: 'center',
              height: '24px',
              gap: '6px',
            }}
            href={$fileTemplates}
            download
          >
            <DownloadIcon color="inherit" />
            <Typography
              color="inherit"
              fontSize="inherit"
              component="span"
            >
              Скачать все шаблоны файлов
            </Typography>
          </Link>
        </Stack>
      </Stack>
      <Divider sx={{ borderColor: theme => theme.palette.border.divider }} />
      <FloorFilesUploader
        floorFiles={$form.floorFiles}
        onFileChange={onFloorFileChange}
        onAddField={addFloorFileField}
        onLabelChange={onLabelChange}
        onDeleteFile={onDeleteFloorFile}
        changeFloorFilesOrder={changeFloorFilesOrder}
        downloadFile={downloadFile}
      />
      <Typography
        sx={{
          fontSize: theme => theme.typography.pxToRem(12),
          lineHeight: theme => theme.typography.pxToRem(16),
          display: 'flex',
          alignItems: 'center',
          height: '24px',
          gap: '6px',
        }}
      >
        <InfoOutlinedIcon sx={{ color: theme => theme.palette.customs.auto }} />
        <Typography
          fontSize="inherit"
          component="span"
          sx={{ color: theme => theme.palette.text.secondary }}
        >
          *Обязательные для загрузки
        </Typography>
      </Typography>

      {Object.entries($form.dataFiles).map(([type, info]) => {
        const dataFileType = type as IDataFileTypeEnum;
        return (
          <FileUploader
            key={dataFileType}
            accept={'.xls, .xlsx'}
            fileStatus={info?.state}
            onDownloadFile={() => {
              info?.id &&
                downloadFile({
                  id: info?.id,
                  type: IDownloadFileTypeEnum.Data,
                });
            }}
            errorText={info?.errors}
            label={DATA_FILES_LABEL[dataFileType]}
            onFileChange={onChangeDataFile(dataFileType)}
            onDelete={onDeleteDataFile(dataFileType)}
          />
        );
      })}

      <FooterControls
        isButtonSaveDisabled={$isButtonSaveDisabled}
        onSave={saveProject}
        onReset={resetProjectData}
      />
    </Box>
  );
};
