import { checkIntersectionWallPlatform } from './check-intersection-wall-platform';
import { checkIntersectionWallRoom } from './check-intersection-wall-room';

import {
  EditableObjectType,
  IPlatform,
  IRoom,
  IWall,
  ListOfObjectsDataType,
} from '@features/tpu-simulation-model/types';

export const addWallsToRoomsPlatformsAtIntersections = (
  data: [string, ListOfObjectsDataType],
) => {
  const walls: IWall[] = [];
  const roomsCollection = new Map<string, IRoom>();
  const platformsCollection = new Map<string, IPlatform>();
  const [floorId, list] = data;

  Object.values(list).forEach(item => {
    if (item.type === EditableObjectType.Room) {
      const newWalls = walls
        .map(wall => checkIntersectionWallRoom(wall, item) && wall.uuid)
        .filter((id): id is string => !!id);

      roomsCollection.set(item.uuid, {
        ...item,
        walls: newWalls,
      });
    }

    if (item.type === EditableObjectType.Platform) {
      const newWalls = walls
        .map(wall => checkIntersectionWallPlatform(wall, item) && wall.uuid)
        .filter((id): id is string => !!id);

      platformsCollection.set(item.uuid, {
        ...item,
        walls: newWalls,
      });
    }

    if (item.type === EditableObjectType.Wall) {
      walls.push(item);

      roomsCollection.forEach((room, key) => {
        if (checkIntersectionWallRoom(item, room)) {
          roomsCollection.set(key, {
            ...room,
            walls: room.walls.concat(item.uuid),
          });
        }
      });

      platformsCollection.forEach((platform, key) => {
        if (checkIntersectionWallPlatform(item, platform)) {
          platformsCollection.set(key, {
            ...platform,
            walls: platform.walls.concat(item.uuid),
          });
        }
      });
    }
  });

  return [
    floorId,
    {
      ...list,
      ...Object.fromEntries(roomsCollection),
      ...Object.fromEntries(platformsCollection),
    },
  ];
};
