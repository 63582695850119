import { sample } from 'effector';

import { EVehicle } from '@features/pt-forecast/constants/vehiclesEnum';
import {
  $CalculationScenario,
  pushPassTrafficForSelectYear,
  resetAllFilters,
} from '@features/pt-forecast/stores';
import {
  PassTrafficExtendedType,
  PassengerTrafficExtendedApi,
} from '@features/pt-forecast/stores/charts/passTrafficExtended/store';
import { FilterRegionApi } from '@features/pt-forecast/stores/filterRegion/store';

import { unitsOfMeasure } from '@utils/dashboardTools';
import { getPercent } from '@utils/getPercent';

sample({
  clock: pushPassTrafficForSelectYear,
  source: {
    CalculationScenario: $CalculationScenario,
  },
  fn: ({ CalculationScenario }) => {
    const result: PassTrafficExtendedType = {
      passengerTrafficPercent: [],
      tableForYear: [],
      unit: unitsOfMeasure[2].value,
    };
    const vehicles = Object.keys(EVehicle) as Array<keyof typeof EVehicle>;

    CalculationScenario.computingresultsSet.forEach(item => {
      const total = vehicles.reduce(
        (acc, vehicle) => item.passengerTraffic[vehicle] + acc,
        0,
      );

      const passengerTrafficPercent = Object.fromEntries(
        vehicles.map(vehicle => [
          vehicle,
          getPercent(total, item.passengerTraffic[vehicle]) || 0,
        ]),
      ) as Record<keyof typeof EVehicle, number>;
      const tableForYear = Object.fromEntries(
        vehicles.map(vehicle => [vehicle, item.passengerTraffic[vehicle]]),
      ) as Record<keyof typeof EVehicle, number>;

      result.passengerTrafficPercent.push({
        ...passengerTrafficPercent,
        year: item.year,
      });
      result.tableForYear.push({
        ...tableForYear,
        year: item.year,
        total,
      });
    });

    return result;
  },
  target: PassengerTrafficExtendedApi.setPassTrafficExtended,
});

// Сброс графика сезонности пассажиропотока
sample({
  clock: [resetAllFilters, FilterRegionApi.reset],
  target: PassengerTrafficExtendedApi.reset,
});
