import { ModalDeleteCalculation } from '@features/ag-constructor/components/ModalDeleteCalculation';
import { ModalShareCalculation } from '@features/ag-constructor/components/ModalShareCalculation';
import { ModalSocEco } from '@features/ag-constructor/components/ModalSocEco/ModalSocEco';
import {
  $ModalsAgConstructor,
  ModalsAgConstructorApi,
} from '@features/ag-constructor/stores/modals';
import { useUnit } from 'effector-react';
import { useCallback } from 'react';

export const Modals = () => {
  const $modalsAgConstructor = useUnit($ModalsAgConstructor);
  const { setModalView } = ModalsAgConstructorApi;

  const handleCloseModalSocioEconomic = useCallback(() => {
    setModalView({ socioEconomic: false });
  }, [setModalView]);

  const handleCloseModalShareCalculation = useCallback(() => {
    setModalView({ shareCalculation: false });
  }, [setModalView]);

  const handleCloseModalDeleteCalculation = useCallback(() => {
    setModalView({ deleteCalculation: false });
  }, [setModalView]);

  return (
    <>
      {$modalsAgConstructor.socioEconomic && (
        <ModalSocEco onClose={handleCloseModalSocioEconomic} />
      )}

      {$modalsAgConstructor.shareCalculation && (
        <ModalShareCalculation
          onClose={handleCloseModalShareCalculation}
          onExecute={() => {}}
        />
      )}

      {$modalsAgConstructor.deleteCalculation && (
        <ModalDeleteCalculation onClose={handleCloseModalDeleteCalculation} />
      )}
    </>
  );
};
