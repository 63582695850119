import { Stroke, Style } from 'ol/style';

import { EFilterMapLayout } from '@components/MapFilters';

import { getLineWidth } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/helpers';
import { createBorderStyle } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/createBorderStyle';
import { SetFeatureStyleProps } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/getFeatureStyle';

export type GetRailwayStyleArgs = Pick<
  SetFeatureStyleProps,
  'filterMapLayout' | 'map' | 'resolution' | 'width' | 'active'
>;

export const getRailwayStyle = (args: GetRailwayStyleArgs) => {
  const { filterMapLayout, resolution, width, map, active } = args;

  const viewMode: EFilterMapLayout =
    filterMapLayout || EFilterMapLayout.Infrastructure;

  const zoom = map.getView().getZoomForResolution(resolution) || 10;
  const borderLineWidthMin =
    viewMode === EFilterMapLayout.PassengerFlows
      ? 2
      : viewMode === EFilterMapLayout.RouteNetwork
      ? 3
      : 1;
  const borderLineWidthMax =
    viewMode === EFilterMapLayout.PassengerFlows ? 3 : 5;

  const isDash = viewMode === EFilterMapLayout.Infrastructure;

  const railwayStyle: Style[] = [];

  if (active)
    railwayStyle.push(
      createBorderStyle({ active, featureWidth: width ? width + 1 : 5 }),
    );

  railwayStyle.push(
    new Style({
      stroke: new Stroke({
        color: '#5A5A5A',
        width:
          width ||
          getLineWidth(zoom, [
            0,
            borderLineWidthMin,
            6,
            borderLineWidthMin,
            6.00000001,
            borderLineWidthMax,
          ]) ||
          1,
        lineCap: 'square',
      }),
    }),
  );

  if (zoom > 6 && isDash) {
    railwayStyle.push(
      new Style({
        stroke: new Stroke({
          lineCap: 'square',
          color: '#5A5A5A',
          width: getLineWidth(zoom, [0, 1, 6, 2, 10, 2]) || 1,
        }),
      }),
    );
  }

  if (
    viewMode === EFilterMapLayout.RouteNetwork ||
    (viewMode === EFilterMapLayout.Infrastructure && zoom > 6)
  )
    railwayStyle.push(
      new Style({
        stroke: new Stroke({
          lineCap: 'square',
          color: 'white',
          width: getLineWidth(zoom, [0, 1, 6, 2, 10, 2]) || 1,
          lineDash:
            viewMode === EFilterMapLayout.Infrastructure
              ? getLineDash(zoom, [
                  0,
                  [1, 1],
                  6,
                  [8, 6],
                  6.5,
                  [8, 6],
                  7,
                  [10, 10],
                  8,
                  [15, 10],
                  10,
                  [20, 10],
                ])
              : undefined,
          lineDashOffset: 4,
        }),
      }),
    );

  return railwayStyle;
};

type DashPattern = [number, number];
type Stop = number | DashPattern;
type StopsArray = Stop[];

const getLineDash = (zoom: number, stops: StopsArray): DashPattern => {
  // Если зум меньше первого стопа, возвращаем первый паттерн пунктира
  if (typeof stops[0] === 'number' && zoom < stops[0]) {
    // Приводим к типу number для уверенности в типе
    return stops[1] as DashPattern; // Приводим к типу DashPattern
  }

  // Если зум больше последнего стопа, возвращаем последний паттерн пунктира
  // Индекс "lastIndex" должен указывать на последний заполненный уровень зума, поэтому -2 (не -4)
  const lastIndex = stops.length - 2;
  if (zoom >= (stops as number[])[lastIndex]) {
    return stops[lastIndex + 1] as DashPattern;
  }

  // Поиск двух стопов между которыми находится текущее значение зума
  for (let i = 0; i < lastIndex; i += 2) {
    const nextZoomLevel = stops[i + 2] as number;

    if (zoom < nextZoomLevel) {
      return stops[i + 1] as DashPattern;
    }
  }
  throw new Error('No dash pattern found for the given zoom level.');
};
