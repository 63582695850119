import { createEffect, createEvent, createStore } from 'effector';

import {
  ListOfFloorsWithListOfObjectsDataType,
  ListOfObjectsDataType,
  SimulationModelPlanData,
} from '@features/tpu-simulation-model/types';

export const $Plan = createStore<ListOfFloorsWithListOfObjectsDataType | null>(
  null,
);

export const $ListOfObjects = createStore<ListOfObjectsDataType | null>(null);

export const getPlanFx = createEffect<string, string | null>();

export const setPlanFn = createEvent<SimulationModelPlanData>();

export const resetPlanFn = createEvent();
