import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckBoxIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="3.5"
        stroke="currentColor"
        fill={'#F6F6F6'}
      />
    </SvgIcon>
  );
};
