import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { Box, Button, IconButton, Stack } from '@mui/material';

import { MapButton } from '@components/Map/MapButton';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EditIcon,
  LayersIcon,
  MinusIcon,
  PlusIcon,
} from '@components/icons';

import { MapEditToolBar } from '@features/pt-forecast-new/components/MapEditToolBar/MapEditToolBar';
import { MapLegend } from '@features/pt-forecast-new/components/MapLegend/MapLegend';
import { $ComputedData } from '@features/pt-forecast-new/stores/computedData';
import {
  $FilterMap,
  EFilterMapLayout,
} from '@features/pt-forecast-new/stores/filters';
import { pipeMapControls } from '@features/pt-forecast-new/stores/map';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';

import { EPipeMapControls } from '@utils/map/hooks/useInitMapControls';

export const MapControlsContainer = () => {
  const {
    panels: {
      inspector,
      forecastDetails,
      filter,
      paramsForecast,
      newInfrastructure,
    },
    mapLegend,
    editMode,
  } = useUnit($UI);
  const {
    toggleMapLegend,
    toggleForecastPanel,
    toggleParamsForecastPanel,
    setEditMode,
    setVisibleNewInfrastructurePanel,
  } = useUnit(UIApi);

  const { currentData } = useUnit($ComputedData);
  const { layout } = useUnit($FilterMap);

  const mapControls = useUnit(pipeMapControls);

  const onClickZoomIn = useCallback(() => {
    mapControls({ action: EPipeMapControls.touchZoom, payload: 'in' });
  }, [mapControls]);

  const onClickZoomOut = useCallback(() => {
    mapControls({ action: EPipeMapControls.touchZoom, payload: 'out' });
  }, [mapControls]);

  const onClickEditeMode = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const onClickHiddenNewInfrastructurePanel = useCallback(() => {
    setVisibleNewInfrastructurePanel(true);
  }, [setVisibleNewInfrastructurePanel]);

  return (
    <>
      {editMode &&
        !newInfrastructure &&
        layout === EFilterMapLayout.Infrastructure && (
          <IconButton
            sx={{
              width: '32px',
              height: '32px',
              backgroundColor: 'primary.main',
              borderRadius: 1,
              color: 'white',
              position: 'absolute',
              top: 'calc(50% - 16px - 42px)',
              left: `${
                (filter ? 500 : 0) + (newInfrastructure ? 500 : 0) + 16
              }px`,
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            }}
            onClick={onClickHiddenNewInfrastructurePanel}
          >
            <ChevronRightIcon />
          </IconButton>
        )}

      {layout === EFilterMapLayout.PassengerFlows && (
        <IconButton
          sx={{
            width: '32px',
            height: '32px',
            backgroundColor: 'primary.main',
            borderRadius: 1,
            color: 'white',
            position: 'absolute',
            top: 'calc(50% - 16px - 42px)',
            left: `${(filter ? 500 : 0) + (paramsForecast ? 500 : 0) + 16}px`,
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={toggleParamsForecastPanel}
        >
          {paramsForecast ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      )}

      {!forecastDetails &&
        !!currentData &&
        layout === EFilterMapLayout.PassengerFlows && (
          <IconButton
            sx={{
              width: '32px',
              height: '32px',
              backgroundColor: 'primary.main',
              borderRadius: 1,
              color: 'white',
              position: 'absolute',
              top: 'calc(50% - 16px - 42px)',
              right: '16px',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            }}
            onClick={toggleForecastPanel}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}

      <Button
        variant={'outlined'}
        sx={{
          marginBottom: theme => theme.typography.pxToRem(8),
          border: theme => '1px solid ' + theme.palette.text.disabled,
          gap: 1,
          position: 'absolute',
          top: theme => theme.typography.pxToRem(24 + (editMode ? 100 : 0)),
          right: theme =>
            theme.typography.pxToRem(
              inspector || forecastDetails ? 500 + 24 : 24,
            ),
          boxShadow: '0px 0px 8px 0px #00000026',
          zIndex: 1,
          '&:hover': {
            backgroundColor: theme => theme.palette.common.white,
            border: theme => '1px solid ' + theme.palette.text.disabled,
            boxShadow:
              '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
          },
        }}
        onClick={toggleMapLegend}
      >
        <LayersIcon />
        Условные обозначения
      </Button>

      <MapButton
        sx={{
          position: 'absolute',
          top: theme =>
            theme.typography.pxToRem(24 + 44 + 16 + (editMode ? 100 : 0)),
          right: theme =>
            theme.typography.pxToRem(
              inspector || forecastDetails ? 500 + 24 : 24,
            ),
          boxShadow: '0px 0px 8px 0px #00000026',
          zIndex: 1,
        }}
        onClick={onClickZoomIn}
      >
        <PlusIcon />
      </MapButton>

      <MapButton
        sx={{
          position: 'absolute',
          top: theme =>
            theme.typography.pxToRem(24 + 88 + 24 + (editMode ? 100 : 0)),
          right: theme =>
            theme.typography.pxToRem(
              inspector || forecastDetails ? 500 + 24 : 24,
            ),
          boxShadow: '0px 0px 8px 0px #00000026',
          zIndex: 1,
        }}
        onClick={onClickZoomOut}
      >
        <MinusIcon />
      </MapButton>

      {layout === EFilterMapLayout.Infrastructure && (
        <Box
          sx={{
            position: 'absolute',
            left: theme =>
              theme.typography.pxToRem(
                filter || newInfrastructure ? 500 + 24 : 24,
              ),
            top: theme => theme.typography.pxToRem(24),
            zIndex: 1,
            boxShadow: '0px 0px 8px 0px #00000026',
          }}
        >
          {editMode ? (
            <MapEditToolBar />
          ) : (
            <Button onClick={onClickEditeMode}>
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
              >
                <EditIcon />
                <span>Редактировать железнодорожную инфраструктуру</span>
              </Stack>
            </Button>
          )}
        </Box>
      )}

      {mapLegend && <MapLegend />}
    </>
  );
};
