import { ReactElement, useEffect } from 'react';

import { EPipeMapControls } from '@utils/map/hooks/useInitMapControls';

import { pipeMapControls } from '@widgets/widget-soceco-indicators/stores/map';

export type MapCentredWrapperProps = {
  centerCoords: number[] | null;
  children: ReactElement;
};

export const MapCentredWrapper = (props: MapCentredWrapperProps) => {
  const { centerCoords, children } = props;

  useEffect(() => {
    if (!centerCoords) return;

    pipeMapControls({
      action: EPipeMapControls.center,
      payload: centerCoords,
    });

    setTimeout(() => {
      pipeMapControls({
        action: EPipeMapControls.setZoom,
        payload: 11,
      });
    }, 300);
  }, [centerCoords]);

  return children;
};
