import { createApi, createEvent, createStore } from 'effector';
import { MapBrowserEvent } from 'ol';
import { FeatureLike } from 'ol/Feature';

import { EMapFeatureLayout } from '@constants/map';

import { PipeMapControls } from '@utils/map/hooks/useInitMapControls';
import { PipeTileLayer } from '@utils/map/hooks/useInitTileLayer';

export const pipeMapControls = createEvent<PipeMapControls>();
export const pipeAreaBordersPassengerFlowTileLayer =
  createEvent<PipeTileLayer>();
export const pipePathPassengerFlowTileLayer = createEvent<PipeTileLayer>();

//////////////////////////////////////////////////////////////////////////////////////////////

export const $SelectedFeatures = createStore<SelectedFeaturesState[]>([]);

export const SelectedFeaturesApi = createApi($SelectedFeatures, {
  setSelectedFeatures: (_, payload: SelectedFeaturesState | null) =>
    payload ? ([payload] as unknown as SelectedFeaturesState[]) : [],
});

export const clickMap = createEvent<MapBrowserEvent<UIEvent>>();

//////////////////////////////////////////////////////////////////////////////////////////////

export type HoveredFeatureState = {
  id: string;
  name: string;
  totalFlow: number;
  population: number;
  center: number[];
};

export type SelectedFeaturesState = {
  layout: EMapFeatureLayout;
  id: string;
  instance: FeatureLike;
};
