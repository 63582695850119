import { createApi, createStore } from 'effector';

const initState = {
  infoCannotDeleteCustomRailwayStation: false,
};

export const $Modals = createStore(initState);

export const ModalsApi = createApi($Modals, {
  setVisibleCannotDeleteCustomRailwayStation: (store, payload: boolean) => ({
    ...store,
    infoCannotDeleteCustomRailwayStation: payload,
  }),
});

export type ModalsStore = {
  infoCannotDeleteCustomRailwayStation: boolean;
};
