import { createEvent, createStore } from 'effector';

import { colors } from '@features/tpu-calculation-scenarios-viewing/constants/player';

export interface IPieChartItem {
  value: number;
  name: string;
}

export interface IChartsData {
  agentsQuantity: number[];
  cumulativeAgentsQuantity: number[];
  entranceDistribution: IPieChartItem[];
  exitsDistribution: IPieChartItem[];
}

export interface IUploadedChartsData {
  agentsQuantity: number[];
  cumulativeAgentsQuantity: number[];
  entranceDistribution: { index: number; items: IPieChartItem[] }[];
  exitsDistribution: { index: number; items: IPieChartItem[] }[];
}

const chartsDataInitial = {
  agentsQuantity: [0],
  cumulativeAgentsQuantity: [0],
  entranceDistribution: [],
  exitsDistribution: [],
};

const uploadedDataInitial = {
  agentsQuantity: [],
  cumulativeAgentsQuantity: [],
  entranceDistribution: [],
  exitsDistribution: [],
};

export const $CurrentChartsData = createStore<IChartsData>(chartsDataInitial);
export const $UploadedChartsData =
  createStore<IUploadedChartsData>(uploadedDataInitial);
export const $PieChartColors = createStore<string[]>(colors);

export const getMoreColorsFn = createEvent<number>();
export const addUploadedDataForChartsFn =
  createEvent<IUploadedChartsData | null>();
export const resetScenarioChartsDataFn = createEvent<void>();
