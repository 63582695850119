import { sample } from 'effector';

import {
  CreateProjectFx,
  CurrentProjectApi,
  UpdateProjectFx,
} from '@features/ag-projects-registry/store/currentProject';
import { ErrorsApi } from '@features/ag-projects-registry/store/errors';

sample({
  clock: [CreateProjectFx.done, UpdateProjectFx.done],
  fn: () => false,
  target: ErrorsApi.setCheckErrors,
});

sample({
  clock: [CreateProjectFx.done, UpdateProjectFx.done],
  fn: () => [],
  target: ErrorsApi.setErrorsName,
});

sample({
  clock: CurrentProjectApi.selectProject,
  fn: () => false,
  target: ErrorsApi.setCheckErrors,
});

sample({
  clock: CurrentProjectApi.selectProject,
  fn: () => [],
  target: ErrorsApi.setErrorsName,
});
