import { v4 as uuid } from 'uuid';

import {
  EditableObjectType,
  IRoom,
  ITicketOffice,
  ObjectsWithRoomField,
} from '@features/tpu-simulation-model/types';

import { RU_OBJECT_TYPE } from '@utils/simulationModel/errors-analyzer/constants';
import { PlanError } from '@utils/simulationModel/errors-analyzer/types';
import { IServiceObjectsErrorAnalyzer } from '@utils/simulationModel/errors-analyzer/types';
import { isObjectInsideRoom } from '@utils/simulationModel/isObjectInsdeRoom';

export class ServiceObjectsErrorAnalyzer
  implements IServiceObjectsErrorAnalyzer
{
  _serviceObjects = new Map<string, ObjectsWithRoomField>();
  _rooms = new Map<string, IRoom>();
  _ticketOffices = new Map<string, ITicketOffice>();
  _errors = new Set<PlanError>();
  _fillsRoomIds: string[] = [];
  _fillsObjectIds: string[] = [];

  reset() {
    this._serviceObjects.clear();
    this._ticketOffices.clear();
    this._rooms.clear();
    this._errors.clear();
    this._fillsRoomIds = [];
    this._fillsObjectIds = [];
  }

  addObject(data: ObjectsWithRoomField | IRoom) {
    switch (data.type) {
      case EditableObjectType.Room:
        this._rooms.set(data.uuid, data);
        return;
      case EditableObjectType.TicketOffice:
        this._ticketOffices.set(data.uuid, data);
        return;
      default:
        this._serviceObjects.set(data.uuid, data);
    }
  }

  getErrors() {
    if (!this._ticketOffices.size) {
      this._errors.add({
        id: uuid(),
        objectType: EditableObjectType.TicketOffice,
        description: 'На плане отсутствуют кассы',
      });
    }

    [...this._ticketOffices.values(), ...this._serviceObjects.values()].forEach(
      serviceObject => {
        const room = this._rooms.get(serviceObject.room);
        if (!room || !isObjectInsideRoom(serviceObject, room)) {
          this._errors.add({
            id: uuid(),
            objectType: serviceObject.type,
            objects: [serviceObject],
            description: `Объект "${
              RU_OBJECT_TYPE[serviceObject.type]
            }" находится вне помещения, с которым он связан`,
          });
        }
      },
    );

    [...this._ticketOffices.values(), ...this._serviceObjects.values()].forEach(
      serviceObject => {
        const room = this._rooms.get(serviceObject.room);
        if (
          room &&
          'fillsRoom' in serviceObject &&
          serviceObject.fillsRoom === 'fills'
        ) {
          if (this._fillsRoomIds.includes(room.uuid)) {
            this._errors.add({
              id: uuid(),
              objectType: serviceObject.type,
              objects: [serviceObject],
              description: `Объект "${serviceObject.name}" находится в помещении которое полностью занимает другой объект`,
            });

            this._fillsObjectIds.push(serviceObject.uuid);
          } else {
            this._fillsRoomIds.push(room.uuid);
            this._fillsObjectIds.push(serviceObject.uuid);
          }
        }
      },
    );

    [...this._ticketOffices.values(), ...this._serviceObjects.values()].forEach(
      serviceObject => {
        const room = this._rooms.get(serviceObject.room);
        if (
          room &&
          this._fillsRoomIds.includes(room.uuid) &&
          !this._fillsObjectIds.includes(serviceObject.uuid)
        ) {
          this._errors.add({
            id: uuid(),
            objectType: serviceObject.type,
            objects: [serviceObject],
            description: `Объект "${serviceObject.name}" находится в помещении которое полностью занимает другой объект`,
          });
        }
      },
    );

    return Array.from(this._errors);
  }
}
