import { SvgIcon, SvgIconProps } from '@mui/material';

type RailwayStationProps = SvgIconProps & {
  mainColor: string;
  background: string;
};

export const RailwayStationIcon = (props: RailwayStationProps) => {
  const { mainColor, background } = props;

  return (
    <SvgIcon
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle
        cx="10"
        cy="10"
        r="9.5"
        fill={background}
        stroke={mainColor}
      />
      <path
        d="M13.4521 14.8865L11.9486 12.617C12.445 12.4751 12.9415 11.9645 12.9415 11.2694V6.39001C12.9415 5.62405 12.3032 4.99994 11.523 4.99994H9.86348H8.18972C7.40957 4.99994 6.77128 5.62405 6.77128 6.39001V11.2694C6.77128 11.9503 7.26773 12.4751 7.76418 12.617L6.26064 14.8865C6.24645 14.9148 6.24645 14.929 6.26064 14.9574C6.27482 14.9858 6.30319 14.9999 6.31738 14.9999H7.21099C7.23936 14.9999 7.25355 14.9858 7.26773 14.9716L8.35993 13.3545H11.3387L12.4309 14.9574C12.445 14.9716 12.4592 14.9858 12.4876 14.9858H13.3954C13.4238 14.9858 13.4379 14.9716 13.4521 14.9432C13.4663 14.929 13.4663 14.9006 13.4521 14.8865ZM7.62234 8.00703V7.1276C7.62234 6.81554 7.80674 6.51767 8.23227 6.51767H11.4805C11.8918 6.51767 12.0904 6.82973 12.0904 7.1276V8.00703C12.0904 8.17724 12.0337 8.33327 11.9202 8.44675C11.8067 8.56022 11.6507 8.61696 11.4805 8.61696H8.23227C8.06206 8.61696 7.90603 8.56022 7.79255 8.44675C7.67908 8.33327 7.62234 8.17724 7.62234 8.00703ZM8.16135 10.7163C8.44504 10.7163 8.68617 10.9574 8.68617 11.2411C8.68617 11.5389 8.44504 11.7659 8.16135 11.7659C7.87766 11.7659 7.63652 11.5248 7.63652 11.2411C7.63652 10.9574 7.86347 10.7163 8.16135 10.7163ZM11.523 10.7163C11.8067 10.7163 12.0479 10.9574 12.0479 11.2411C12.0479 11.5389 11.8067 11.7659 11.523 11.7659C11.2394 11.7659 10.9982 11.5248 10.9982 11.2411C11.0124 10.9574 11.2394 10.7163 11.523 10.7163Z"
        fill={mainColor}
      />
    </SvgIcon>
  );
};
