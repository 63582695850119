import { Fragment, ReactNode, createElement, useState } from 'react';

import { Accordion, ListItem } from './components/Accordion';
import { TabContent } from './components/TabContent';
import { ListItemAccordion, ListItemBase } from './types';

import { Box, Divider, Link, Tab, Typography } from '@mui/material';

import { Tabs } from '@components/Tabs';

interface SidebarProps {
  allObjectsConfig: ListItemAccordion[];
  builtObjectsConfig: ListItemAccordion[];
  onDeleteAll: () => void;
}

const TAB = {
  ALL: 0,
  BUILT: 1,
};

const doesElementHaveChildren = (
  element: ListItemAccordion | ListItemBase,
): element is ListItemAccordion => {
  return 'children' in element;
};

const getElementsFromObjects = (
  list: (ListItemAccordion | ListItemBase)[],
): ReactNode[] => {
  return list.map((item, i) => {
    if (doesElementHaveChildren(item)) {
      return (
        <Fragment key={i}>
          <Accordion
            label={item.label}
            Icon={item.Icon}
          >
            {item.children && getElementsFromObjects(item.children)}
          </Accordion>
          {item.withSeparator && <Divider />}
        </Fragment>
      );
    }

    return createElement(ListItem, { ...item, key: item.key ?? i });
  });
};

export const Sidebar = ({
  builtObjectsConfig,
  allObjectsConfig,
  onDeleteAll,
}: SidebarProps) => {
  const [value, setValue] = useState(TAB.ALL);

  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.background.paper,
        overflow: 'auto',
      }}
    >
      <Box sx={{ padding: '24px' }}>
        <Tabs
          value={value}
          onChange={setValue}
        >
          <Tab
            value={TAB.ALL}
            label={'Все объекты'}
            sx={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
          />
          <Tab
            value={TAB.BUILT}
            label={'Построенные объекты'}
            sx={{ borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }}
          />
        </Tabs>
      </Box>
      <Divider sx={{ borderColor: 'border.divider' }} />
      <Box
        sx={{
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        <TabContent
          value={TAB.ALL}
          choosenTab={value}
        >
          <Typography
            variant={'subtitle1'}
            sx={{ mb: '24px' }}
          >
            Объекты
          </Typography>
          <>{getElementsFromObjects(allObjectsConfig)}</>
        </TabContent>
        <TabContent
          value={TAB.BUILT}
          choosenTab={value}
        >
          <Typography
            variant={'subtitle1'}
            sx={{ mb: '24px' }}
          >
            Построенные объекты
          </Typography>
          <>{getElementsFromObjects(builtObjectsConfig)}</>
          <Link
            sx={{
              mt: '24px',
              mx: 'auto',
              fontSize: theme => theme.typography.pxToRem(14),
              lineHeight: theme => theme.typography.pxToRem(16),
            }}
            onClick={onDeleteAll}
          >
            Удалить все объекты
          </Link>
        </TabContent>
      </Box>
    </Box>
  );
};
