import { Box, Slider, Typography, sliderClasses } from '@mui/material';

import { TransparancyIcon } from '@components/icons';

import { palette } from '@system/theme/palette';

interface OpacityProps {
  value: number;
  onChange(arg: number): void;
}

export const Opacity = ({ value, onChange }: OpacityProps) => {
  const percent = Math.round(value * 100);
  const handleChange = (_: Event, val: number | number[]) => {
    onChange((val as number) / 100);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        width: '218px',
        backgroundColor: 'background.paper',
        height: '48px',
        padding: '12px 24px',
        borderRadius: '4px',
        boxShadow: '0px 0px 8px 0px #00000026',
        pointerEvents: 'auto',
        marginLeft: 'auto',
      }}
    >
      <TransparancyIcon />
      <Slider
        size={'small'}
        value={percent}
        onChange={handleChange}
        sx={{
          padding: '7px 0',

          [`& .${sliderClasses.rail}`]: {
            height: '6px',
          },
          [`& .${sliderClasses.track}`]: {
            height: '6px',
          },

          [`& .${sliderClasses.thumb}`]: {
            height: '16px',
            width: '16px',
            boxShadow: 'inset 0 0 0px 4px ' + palette.secondary.main,
            backgroundColor: palette.common.white,
            ':hover': {
              boxShadow: 'inset 0 0 0px 4px ' + palette.secondary.dark,
            },
            [`&.${sliderClasses.focusVisible}`]: {
              boxShadow: 'inset 0 0 0px 4px ' + palette.secondary.dark,
            },
          },
        }}
      />
      <Typography
        sx={{
          fontSize: theme => theme.typography.pxToRem(12),
          lineHeight: theme => theme.typography.pxToRem(16),
        }}
      >
        {percent + '%'}
      </Typography>
    </Box>
  );
};
