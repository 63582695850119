import { IQueryGetInfraPointByNameArgs } from '@api/gql/types';
import { sample } from 'effector';

import { queryGetInfraPointByName } from '@features/pt-forecast-new/stores/api/queryGetInfraPointByName';
import {
  SearchOption,
  SearchOptionsApi,
  getSearchOptions,
  querySearchOptionsFx,
} from '@features/pt-forecast-new/stores/lists/searchOptions';
import { SearchInfraStore } from '@features/pt-forecast-new/stores/map/searchInfra';

querySearchOptionsFx.use(queryGetInfraPointByName);

sample({
  clock: getSearchOptions,
  fn: payload => {
    return {
      contains: payload,
    } as IQueryGetInfraPointByNameArgs;
  },
  target: querySearchOptionsFx,
});

sample({
  clock: querySearchOptionsFx.done,
  fn: request => {
    return request.result?.map(item => ({
      lonLat: [item!.point!.lat, item!.point!.lon],
      name: item!.name,
      type: parsePointType(item!.type!),
      value: item!.id,
    })) as SearchOption[];
  },
  target: SearchOptionsApi.setOptions,
});

const parsePointType = (type: string): keyof SearchInfraStore['features'] => {
  switch (type) {
    case 'airport':
      return 'airport';
    case 'bus_station':
      return 'busStation';
    case 'railway_station':
      return 'railwayStation';
    default:
      throw new Error(`Unknown point type: ${type}`);
  }
};
