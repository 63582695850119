import { useCallback, useEffect, useMemo, useRef } from 'react';

import {
  Box,
  Grid,
  Slider,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';

import {
  InspectorCircleIcon,
  InspectorCircleIconProps,
} from '@components/InspectorCircleIcon/InspectorCircleIcon';

type TimeBlockType = {
  icon: InspectorCircleIconProps;
  name: string;
  value: number | string;
  baseValue: number;
  disabled: boolean;
  getValue: (value: number) => void;
};

export const TrafficFrequencyBlock = (props: TimeBlockType) => {
  const { icon, name, value, baseValue, disabled, getValue } = props;
  const prevProps = useRef(props);

  /**
   * Верстка тултипа для слайдера
   */
  const valueLabelFormat = () => {
    const percent =
      baseValue === 0 || isNaN(baseValue)
        ? 0
        : ((+value - baseValue) / baseValue) * 100;
    const percentString = Math.round(percent);

    return (
      <>
        <Typography>Базовое значение {Math.round(baseValue)}</Typography>
        <Typography
          color={'customs.trainSpeedLocal'}
          textAlign={'center'}
        >
          {percent > 0 ? '+' : ''}
          {percentString}%
        </Typography>
      </>
    );
  };

  /**
   * Функция изменения значения слайдера с обновлением приходящего значения частоты сообщения в компонент
   */
  const handlerChangeField = useCallback<
    NonNullable<TextFieldProps['onChange']>
  >(
    e => {
      let value: number | string = (e.target as HTMLInputElement).value;
      value = +value.toString().replace(/\D/g, '');

      getValue(value);
    },
    [getValue],
  );

  /**
   * Функция изменения значения слайдера с обновлением приходящего значения частоты сообщения в компонент
   */
  const handlerChangeSlider = useCallback(
    (_event: Event, newValue: number | number[]) =>
      getValue(newValue as number),
    [getValue],
  );

  /**
   * Нижняя граница значения для слайдера
   */
  const minValue = useMemo(() => {
    if (isNaN(baseValue) || baseValue === 0) return 0;

    return Math.round(+(baseValue / 2));
  }, [baseValue]);

  /**
   * Верхняя граница значения для слайдера
   */
  const maxValue = useMemo(() => {
    if (isNaN(baseValue) || baseValue === 0) return 1;

    return Math.round(+(baseValue * 1.5));
  }, [baseValue]);

  useEffect(() => {
    if (+prevProps.current.value !== +value) {
      prevProps.current = props;
    }
  }, [getValue, props, value]);

  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      rowGap={1}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          columnGap: 1,
        }}
      >
        <InspectorCircleIcon
          borderColor={icon.borderColor}
          backgroundColor={icon.backgroundColor}
        />

        <Typography>{name}</Typography>
      </Box>

      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          columnGap: 1.5,
          mt: 1,
        }}
      >
        <TextField
          value={value}
          disabled={disabled}
          sx={{
            width: theme => theme.typography.pxToRem(220),
          }}
          onChange={handlerChangeField}
        />

        <Slider
          color="secondary"
          marks={[{ value: baseValue }]}
          min={minValue}
          max={maxValue}
          step={1}
          value={+value}
          disabled={disabled}
          onChange={handlerChangeSlider}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
        />
      </Box>
    </Grid>
  );
};
