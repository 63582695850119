import { useMemo, useState } from 'react';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';

import { PassFlowTableContentRow } from '@features/ag-forecast/stores/lists/passFlowTableContent';

type PassFlowTableRowProps = {
  rowData: PassFlowTableContentRow;
};

export const PassFlowTableRow = (props: PassFlowTableRowProps) => {
  const { rowData } = props;
  const [open, setOpen] = useState(false);

  const flow = useMemo(() => {
    const fPrivate = Math.round(rowData.flowPrivate);
    const fPublic = Math.round(rowData.flowPublic);
    const total = fPrivate + fPublic;
    return { fPrivate, fPublic, total };
  }, [rowData.flowPrivate, rowData.flowPublic]);

  return (
    <>
      <TableRow>
        <TableCell
          padding={'none'}
          key={'expand'}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell
          key={'fromName'}
          sx={CellSx}
        >
          {rowData.fromName}
        </TableCell>
        <TableCell
          key={'toName'}
          sx={CellSx}
        >
          {rowData.toName}
        </TableCell>
        <TableCell
          key={'time'}
          sx={CellSx}
        >
          {`${rowData.timePrivate}/${rowData.timePublic}`}
        </TableCell>
        <TableCell
          key={'stz'}
          sx={CellSx}
        >
          {`${rowData.stzPrivate.toFixed(2)}/${rowData.stzPublic.toFixed(2)}`}
        </TableCell>
        <TableCell
          key={'flow'}
          sx={CellSx}
        >
          {`${flow.fPrivate}/${flow.fPublic}/${flow.total}`}
        </TableCell>
        <TableCell
          key={'percent'}
          sx={CellSx}
        >
          {`${rowData.percent}%`}
        </TableCell>
      </TableRow>

      {/*<TableRow>*/}
      {/*  <TableCell padding="none" />*/}
      {/*  <TableCell*/}
      {/*    style={{ padding: 0 }}*/}
      {/*    colSpan={6}*/}
      {/*  >*/}
      {/*    <Collapse*/}
      {/*      in={open}*/}
      {/*      timeout="auto"*/}
      {/*      unmountOnExit*/}
      {/*    >*/}
      {/*      <Table>*/}
      {/*        <TableBody>*/}
      {/*          {(rowData.vehicles as Record<string, string>[]).map(*/}
      {/*            (vehicleRow, index) => (*/}
      {/*              <TableRow key={index}>*/}
      {/*                {Object.entries(vehicleRow).map(([key, value]) => (*/}
      {/*                  <TableCell*/}
      {/*                    key={key}*/}
      {/*                    padding="none"*/}
      {/*                    sx={{*/}
      {/*                      padding: '14px 6px',*/}
      {/*                      width: `${passTrafficTableHeadCells.find(*/}
      {/*                        cell => cell.id === key,*/}
      {/*                      )?.minWidth}px`,*/}
      {/*                      fontSize: '12px',*/}
      {/*                      fontWeight: 400,*/}
      {/*                      opacity: 0.7,*/}
      {/*                    }}*/}
      {/*                  >*/}
      {/*                    {value}*/}
      {/*                  </TableCell>*/}
      {/*                ))}*/}
      {/*              </TableRow>*/}
      {/*            ),*/}
      {/*          )}*/}
      {/*        </TableBody>*/}
      {/*      </Table>*/}
      {/*    </Collapse>*/}
      {/*  </TableCell>*/}
      {/*</TableRow>*/}
    </>
  );
};

const CellSx = {
  padding: '14px 6px',
  fontSize: '12px',
  fontWeight: 400,
};
