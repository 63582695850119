import { Close } from '@mui/icons-material';
import { Chip, ChipProps } from '@mui/material';

type ChipItemProps = {
  label: string;
  onDelete: ChipProps['onDelete'];
};
export const ChipItem = (props: ChipItemProps) => {
  const { label, onDelete } = props;
  return (
    <Chip
      label={label}
      onDelete={onDelete}
      sx={{
        width: 'min-content',
        cursor: 'default',
        '& .MuiChip-deleteIcon': {
          color: '#333',
        },
      }}
      deleteIcon={
        <Close
          sx={{
            width: '12px',
            paddingTop: '2px',
          }}
        />
      }
    />
  );
};
