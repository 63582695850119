import {
  $ModalSocioEconomic,
  checkErrorName,
  checkErrorScenario,
  setSocEcoName,
  setSocEcoType,
} from '@features/pt-constructor/components/ModalSocioEconomic/store';
import { $Calculations, ScenarioOption } from '@features/pt-constructor/store';
import { useUnit } from 'effector-react';
import { ChangeEvent, useCallback, useMemo } from 'react';

import { HourglassTopOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Stack,
  TextField,
  Typography,
  UseAutocompleteProps,
} from '@mui/material';

import { FormLabel } from '@components/FormLabel';

export const OneStep = () => {
  const $calculations = useUnit($Calculations);
  const $modalSocioEconomic = useUnit($ModalSocioEconomic);

  const onChangeScenarioType = useCallback<
    NonNullable<
      UseAutocompleteProps<ScenarioOption, false, false, false>['onChange']
    >
  >(
    (_e, val) => {
      if (val) {
        setSocEcoType(val);
        $modalSocioEconomic.isCheckErrors && checkErrorScenario();
      }
    },
    [$modalSocioEconomic.isCheckErrors],
  );

  const onChangeName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSocEcoName(event.target.value);
      $modalSocioEconomic.isCheckErrors && checkErrorName();
    },
    [$modalSocioEconomic.isCheckErrors],
  );

  const scenariosList = useMemo<ScenarioOption[]>(
    () =>
      $calculations.socEcoScenarios.map(scenario => ({
        value: scenario.id as string,
        label: scenario.name as string,
        type: scenario.type,
        disabled: !scenario.computed,
      })),
    [$calculations.socEcoScenarios],
  );

  return (
    <div>
      <FormLabel
        id="oneStepName"
        title={'Название'}
      >
        <TextField
          id="oneStepName"
          placeholder={'Название'}
          onChange={onChangeName}
          value={$modalSocioEconomic.name}
          sx={{
            mb: 0.75,
          }}
        />

        {$modalSocioEconomic.isErrorName && (
          <Typography
            variant="caption"
            sx={{
              color: theme => theme.palette.secondary.main,
            }}
          >
            Имя должно быть не меньше двух символов
          </Typography>
        )}
      </FormLabel>

      <FormLabel
        sx={{ mt: 3 }}
        title={'На основе сценария'}
        id="oneStepBasedOnTheScenario"
      >
        <Autocomplete
          id="scenario-select"
          disablePortal={false}
          onChange={onChangeScenarioType}
          options={scenariosList}
          getOptionDisabled={option => !!option?.disabled}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Сценарий"
            />
          )}
          sx={{
            mb: 0.75,
          }}
          renderOption={(props, option) => (
            <li
              {...props}
              key={`${option.value}-${option.label}`}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
              >
                <Typography>{option.label}</Typography>
                {option.disabled && <HourglassTopOutlined />}
              </Stack>
            </li>
          )}
        />

        {$modalSocioEconomic.isErrorScenario && (
          <Typography
            variant="caption"
            sx={{
              color: theme => theme.palette.secondary.main,
            }}
          >
            Необходимо выбрать сценарий
          </Typography>
        )}
      </FormLabel>
    </div>
  );
};
