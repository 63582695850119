import { EMapFeatureLayout } from '@constants/map';

import { allGraphsAgglomerationTileStyle } from '@utils/map/styles/agGraphs/allGraphsAgglomerationTileStyle';
import { graphAutoPassFlowTileStyle } from '@utils/map/styles/agGraphs/auto/graphAutoPassFlowTileStyle';
import { graphAutoTileStyle } from '@utils/map/styles/agGraphs/auto/graphAutoTileStyle';
import { graphAviaPassFlowTileStyle } from '@utils/map/styles/agGraphs/avia/graphAviaPassFlowTileStyle';
import { graphAviaTileStyle } from '@utils/map/styles/agGraphs/avia/graphAviaTileStyle';
import { graphBusPassFlowTileStyle } from '@utils/map/styles/agGraphs/bus/graphBusPassFlowTileStyle';
import { graphBusTileStyle } from '@utils/map/styles/agGraphs/bus/graphBusTileStyle';
import { graphFunicularPassFlowTileStyle } from '@utils/map/styles/agGraphs/funicular/graphFunicularPassFlowTileStyle';
import { graphFunicularTileStyle } from '@utils/map/styles/agGraphs/funicular/graphFunicularTileStyle';
import { graphMetroPassFlowTileStyle } from '@utils/map/styles/agGraphs/metro/graphMetroPassFlowTileStyle';
import { graphMetroTileStyle } from '@utils/map/styles/agGraphs/metro/graphMetroTileStyle';
import { graphMonoRailWayPassFlowTileStyle } from '@utils/map/styles/agGraphs/monoRailway/graphMonoRailWayPassFlowTileStyle';
import { graphMonoRailWayTileStyle } from '@utils/map/styles/agGraphs/monoRailway/graphMonoRailWayTileStyle';
import { graphPedestrainPassFlowTileStyle } from '@utils/map/styles/agGraphs/pedestrain/graphPedestrainPassFlowTileStyle';
import { graphPedestrainTileStile } from '@utils/map/styles/agGraphs/pedestrain/graphPedestrainTileStile';
import { graphRopeWayPassFlowTileStyle } from '@utils/map/styles/agGraphs/ropeway/graphRopeWayPassFlowTileStyle';
import { graphRopeWayTileStyle } from '@utils/map/styles/agGraphs/ropeway/graphRopeWayTileStyle';
import { graphSuburbanRailwayPassFlowTileStyle } from '@utils/map/styles/agGraphs/suburbianRailway/graphSuburbanRailwayPassFlowTileStyle';
import { graphSuburbanRailwayTileStyle } from '@utils/map/styles/agGraphs/suburbianRailway/graphSuburbanRailwayTileStyle';
import { trafficCongestionTileStyle } from '@utils/map/styles/agGraphs/trafficCongestionTileStyle';
import { graphTramPassFlowTileStyle } from '@utils/map/styles/agGraphs/tram/graphTramPassFlowTileStyle';
import { graphTramTileStyle } from '@utils/map/styles/agGraphs/tram/graphTramTileStyle';
import { graphTrolleybusPassFlowTileStyle } from '@utils/map/styles/agGraphs/trolleybus/graphTrolleybusPassFlowTileStyle';
import { graphTrolleybusTileStyle } from '@utils/map/styles/agGraphs/trolleybus/graphTrolleybusTileStyle';
import { graphWaterWayPassFlowTileStyle } from '@utils/map/styles/agGraphs/waterway/graphWaterWayPassFlowTileStyle';
import { graphWaterWayTileStyle } from '@utils/map/styles/agGraphs/waterway/graphWaterWayTileStyle';
import { areaBordersPassengerFlowTileStyle } from '@utils/map/styles/areaBordersPassengerFlowTileStyle';
import { highwayTileStyle } from '@utils/map/styles/highwayTileStyle';
import { ptHighwayTileStyle } from '@utils/map/styles/passengerTraffic/tile/ptHighwayTileStyle';
import { pathPassengerFlowTileStyle } from '@utils/map/styles/pathPassengerFlowTileStyle';
import { plannedHighwayTileStyle } from '@utils/map/styles/plannedHighwayTileStyle';
import {
  plannedRailwayTileStyleDashed,
  plannedRailwayTileStyleFilledGray,
  plannedRailwayTileStyleFilledWhite,
} from '@utils/map/styles/plannedRailwayTileStyles';
import {
  railwayTileStyleDashed,
  railwayTileStyleFilledGray,
  railwayTileStyleFilledWhite,
} from '@utils/map/styles/railwayTileStyles';
import { transportDistrictTileStyle } from '@utils/map/styles/transportDistrictTileStyle';

export enum ETileLayerStyles {
  default = 'default',
  plannedHighway = 'plannedHighway',
  railwayFilledGray = 'railwayFilledGray',
  railwayFilledWhite = 'railwayFilledWhite',
  railwayPlannedFilledGray = 'railwayPlannedFilledGray',
  railwayPlannedFilledWhite = 'railwayPlannedFilledWhite',
  railwayPlannedDashed = 'railwayPlannedDashed',
  passFlow = 'passFlow',
}

export type getTileLayerStyleProps = {
  layout: EMapFeatureLayout;
  theme: ETileLayerStyles;
};

export const getTileLayerStyle = ({
  layout,
  theme,
}: getTileLayerStyleProps) => {
  switch (layout) {
    case EMapFeatureLayout.railway:
      switch (theme) {
        case ETileLayerStyles.default:
          return railwayTileStyleDashed;
        case ETileLayerStyles.railwayFilledGray:
          return railwayTileStyleFilledGray;
        case ETileLayerStyles.railwayFilledWhite:
          return railwayTileStyleFilledWhite;
        case ETileLayerStyles.railwayPlannedDashed:
          return plannedRailwayTileStyleDashed;
        case ETileLayerStyles.railwayPlannedFilledGray:
          return plannedRailwayTileStyleFilledGray;
        case ETileLayerStyles.railwayPlannedFilledWhite:
          return plannedRailwayTileStyleFilledWhite;
        default:
          throw new Error(`Unknown theme for railway layer - ${layout}`);
      }
    case EMapFeatureLayout.auto:
      switch (theme) {
        case ETileLayerStyles.default:
          return highwayTileStyle;
        case ETileLayerStyles.plannedHighway:
          return plannedHighwayTileStyle;
        default:
          throw new Error(`Unknown theme for highway layer - ${layout}`);
      }
    case EMapFeatureLayout.graphAuto:
      switch (theme) {
        case ETileLayerStyles.default:
          return graphAutoTileStyle;
        case ETileLayerStyles.passFlow:
          return graphAutoPassFlowTileStyle;
        default:
          throw new Error(`Unknown theme for graphAuto layer - ${layout}`);
      }
    case EMapFeatureLayout.graphAvia:
      switch (theme) {
        case ETileLayerStyles.default:
          return graphAviaTileStyle;
        case ETileLayerStyles.passFlow:
          return graphAviaPassFlowTileStyle;
        default:
          throw new Error(`Unknown theme for graphAvia layer - ${layout}`);
      }
    case EMapFeatureLayout.graphBus:
      switch (theme) {
        case ETileLayerStyles.default:
          return graphBusTileStyle;
        case ETileLayerStyles.passFlow:
          return graphBusPassFlowTileStyle;
        default:
          throw new Error(`Unknown theme for graphBus layer - ${layout}`);
      }
    case EMapFeatureLayout.graphFunicular:
      switch (theme) {
        case ETileLayerStyles.default:
          return graphFunicularTileStyle;
        case ETileLayerStyles.passFlow:
          return graphFunicularPassFlowTileStyle;
        default:
          throw new Error(`Unknown theme for graphFunicular layer - ${layout}`);
      }
    case EMapFeatureLayout.graphMetro:
      switch (theme) {
        case ETileLayerStyles.default:
          return graphMetroTileStyle;
        case ETileLayerStyles.passFlow:
          return graphMetroPassFlowTileStyle;
        default:
          throw new Error(`Unknown theme for graphMetro layer - ${layout}`);
      }
    case EMapFeatureLayout.graphMonoRailWay:
      switch (theme) {
        case ETileLayerStyles.default:
          return graphMonoRailWayTileStyle;
        case ETileLayerStyles.passFlow:
          return graphMonoRailWayPassFlowTileStyle;
        default:
          throw new Error(
            `Unknown theme for graphMonoRailWay layer - ${layout}`,
          );
      }
    case EMapFeatureLayout.graphPedestrain:
      switch (theme) {
        case ETileLayerStyles.default:
          return graphPedestrainTileStile;
        case ETileLayerStyles.passFlow:
          return graphPedestrainPassFlowTileStyle;
        default:
          throw new Error(
            `Unknown theme for graphPedestrain layer - ${layout}`,
          );
      }
    case EMapFeatureLayout.graphRopeWay:
      switch (theme) {
        case ETileLayerStyles.default:
          return graphRopeWayTileStyle;
        case ETileLayerStyles.passFlow:
          return graphRopeWayPassFlowTileStyle;
        default:
          throw new Error(`Unknown theme for graphRopeWay layer - ${layout}`);
      }
    case EMapFeatureLayout.graphSuburbanRailway:
      switch (theme) {
        case ETileLayerStyles.default:
          return graphSuburbanRailwayTileStyle;
        case ETileLayerStyles.passFlow:
          return graphSuburbanRailwayPassFlowTileStyle;
        default:
          throw new Error(
            `Unknown theme for graphSuburbanRailway layer - ${layout}`,
          );
      }
    case EMapFeatureLayout.graphTram:
      switch (theme) {
        case ETileLayerStyles.default:
          return graphTramTileStyle;
        case ETileLayerStyles.passFlow:
          return graphTramPassFlowTileStyle;
        default:
          throw new Error(`Unknown theme for graphTram layer - ${layout}`);
      }
    case EMapFeatureLayout.graphTrolleybus:
      switch (theme) {
        case ETileLayerStyles.default:
          return graphTrolleybusTileStyle;
        case ETileLayerStyles.passFlow:
          return graphTrolleybusPassFlowTileStyle;
        default:
          throw new Error(
            `Unknown theme for graphTrolleybus layer - ${layout}`,
          );
      }
    case EMapFeatureLayout.graphWaterWay:
      switch (theme) {
        case ETileLayerStyles.default:
          return graphWaterWayTileStyle;
        case ETileLayerStyles.passFlow:
          return graphWaterWayPassFlowTileStyle;
        default:
          throw new Error(`Unknown theme for graphWaterWay layer - ${layout}`);
      }
    case EMapFeatureLayout.transportDistricts:
      return transportDistrictTileStyle;
    case EMapFeatureLayout.areaBorderPassengerFlow:
      return areaBordersPassengerFlowTileStyle;
    case EMapFeatureLayout.pathPassengerFlow:
      return pathPassengerFlowTileStyle;
    case EMapFeatureLayout.allGraphsAgglomeration:
      return allGraphsAgglomerationTileStyle;
    case EMapFeatureLayout.trafficCongestion:
      return trafficCongestionTileStyle;
    case EMapFeatureLayout.highway:
      switch (theme) {
        case ETileLayerStyles.default:
          return ptHighwayTileStyle;
        default:
          throw new Error(`Unknown theme for highway layer - ${layout}`);
      }
    default:
      throw new Error(`Unknown layer - ${layout}`);
  }
};
