import type { ForwardedRef, SyntheticEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { useDebounce } from 'react-use';

import type {
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
} from '@mui/base/useAutocomplete/useAutocomplete';
import type { AutocompleteFreeSoloValueMapping } from '@mui/base/useAutocomplete/useAutocomplete';
import type { AutocompleteProps, InputBaseProps } from '@mui/material';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

interface AsyncAutocompleteProps<Value> {
  readonly inputProps?: InputBaseProps['inputProps'];
  readonly placeholder?: string;
  readonly required?: boolean;
  readonly disabled?: boolean;
  readonly error?: boolean;
  readonly helperText?: React.ReactNode;
  readonly loadOptionsMethod: (search?: string) => Promise<Value[]>;
  readonly preload?: boolean;
  readonly startIcon?: React.ReactNode;
  readonly onCreateNoOptions?: (payload: { text: string }) => void;
  readonly onInputValueChange?: (arg: string) => void;
}

type DefaultOption = {
  id: number;
  name: string;
};

/**
 * @description Компонент асинхронной загрузки списка
 * @example
 *   <AsyncAutocomplete
 *           preload={true}
 *           loadOptionsMethod={async (search?: string | undefined) => {
 *             const response = await Services.dictionary.trainCategory({
 *               search,
 *               limit: 20,
 *               skip: 0,
 *             });
 *
 *             return response.data;
 *           }}
 *         />
 */
const defaultGetOptionLabel = <Value, FreeSolo>(
  option: Value | AutocompleteFreeSoloValueMapping<FreeSolo> | undefined,
) =>
  option && typeof option === 'object' && 'name' in option && !!option.name
    ? String(option.name)
    : '';

function AsyncAutocompleteComponent<
  Value = DefaultOption,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>(
  props: AsyncAutocompleteProps<Value> &
    Partial<AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>>,
  ref: ForwardedRef<AsyncAutocompleteProps<Value>>,
) {
  const {
    onOpen,
    onClose,
    inputProps,
    placeholder,
    error,
    helperText,
    required,
    disabled,
    loadOptionsMethod,
    preload,
    value,
    getOptionLabel = defaultGetOptionLabel,
    startIcon,
    onCreateNoOptions,
    onInputValueChange,
    ...restProp
  } = props;

  const pointerEventsValue = () => (disabled ? 'none' : 'auto');
  const [options, setOptions] = useState<Value[]>([]);
  const [search, setSearch] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [, cancel] = useDebounce(
    () => {
      if (!open || !search) {
        if (!(open && preload)) {
          return undefined;
        }
      }

      setIsLoading(true);
      loadOptionsMethod(search || '')
        .then(payload => {
          setOptions(payload);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    500,
    [open, search, preload],
  );

  useEffect(() => {
    return cancel;
  }, [cancel]);

  useEffect(() => {
    setInputValue(getOptionLabel(value as Value));
  }, [value]);

  return (
    <Autocomplete
      ref={ref}
      getOptionLabel={getOptionLabel}
      disabled={disabled}
      noOptionsText={
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          gap={2}
        >
          <Typography variant={'body2'}>
            {isLoading ? 'Загрузка...' : 'Нет данных'}
          </Typography>
          {onCreateNoOptions && (
            <Button
              sx={{
                height: '30px',
              }}
              onClick={() => {
                setSearch('');
                onInputValueChange && onInputValueChange('');
                setOpen(false);
                onCreateNoOptions({
                  text: search,
                });
              }}
            >
              Создать
            </Button>
          )}
        </Stack>
      }
      loading={isLoading}
      loadingText={'Загрузка...'}
      {...restProp}
      value={value}
      freeSolo={false as FreeSolo}
      clearOnBlur={!value}
      clearOnEscape={false}
      openOnFocus={!search}
      inputValue={props.multiple ? search : undefined}
      options={options}
      open={open}
      onOpen={useCallback(
        (event: SyntheticEvent) => {
          setOpen(true);
          onOpen?.(event);
        },
        [onOpen],
      )}
      onClose={useCallback(
        (event: SyntheticEvent, reason: AutocompleteCloseReason) => {
          setOpen(false);
          onClose?.(event, reason);
        },
        [onClose],
      )}
      onInputChange={useCallback(
        (
          _: SyntheticEvent,
          value: string,
          reason: AutocompleteInputChangeReason,
        ) => {
          if (reason === 'input') {
            setSearch(value);
            onInputValueChange && onInputValueChange(value);
          }

          if (reason === 'clear') {
            setSearch('');
            onInputValueChange && onInputValueChange('');
          }
        },
        [],
      )}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          required={required}
          fullWidth={true}
          InputProps={{
            ...params.InputProps,
            endAdornment: isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <>{params.InputProps.endAdornment}</>
            ),
            startAdornment: startIcon || params.InputProps.startAdornment,
          }}
          inputProps={{
            ...params.inputProps,
            ...inputProps,
            autoComplete: 'none',
            'aria-autocomplete': 'none',
            value: inputValue,
          }}
          sx={{
            pointerEvents: pointerEventsValue,
          }}
          onChange={e => {
            const value = e.target.value.replace(
              /[.*+?^${}()@!#%&_"<>№:=/';,|[\]\\]/g,
              '',
            );

            setSearch(value);
            setInputValue(value);
          }}
        />
      )}
    />
  );
}

export const AsyncAutocomplete = React.forwardRef(
  AsyncAutocompleteComponent,
) as <
  Value = DefaultOption,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
>(
  props: AsyncAutocompleteProps<Value> &
    Partial<AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>> & {
      ref?: ForwardedRef<AsyncAutocompleteProps<Value>>;
    },
) => JSX.Element;
