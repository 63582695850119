import { apiClient } from '@api/client';
import { IMutation, IQuery } from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { TPU_CLIENT_NAME } from '@constants/api';

import {
  $ScenarioId,
  closePlanErrorsWindowFn,
  errorsModalDefaultState,
  getAgentsShareInfoFx,
  openPlanErrorsWindowFn,
  resetErrorsFn,
  resetErrorsModalFn,
  savePlanAfterCheckingErrorsFx,
  setNumberOfErrorsFn,
  setPlanErrorsFn,
  triggerSavePlanAfterCheckingErrorsFn,
} from '@features/tpu-simulation-model/store';
import { $ListOfFloorsWithListOfObjects } from '@features/tpu-simulation-model/store';
import {
  $Errors,
  triggerFindErrorsFn,
} from '@features/tpu-simulation-model/store/errorsModal/errorsModal.store';
import { $ErrorsModal } from '@features/tpu-simulation-model/store/errorsModal/errorsModal.store';
import { $ListOfRulers } from '@features/tpu-simulation-model/store/ruler/listOfRulers.store';
import { SaveSimulationModelPlanArgs } from '@features/tpu-simulation-model/types';
import { preparePlanBeforeUpload } from '@features/tpu-simulation-model/utils/prepare-plan-before-upload';

import { getPlanErrors } from '@utils/simulationModel';

$ErrorsModal
  .on([resetErrorsModalFn, closePlanErrorsWindowFn], state => ({
    ...errorsModalDefaultState,
    numberOfErrors: state.numberOfErrors,
  }))
  .on(openPlanErrorsWindowFn, (state, payload) => {
    return {
      ...state,
      isOpen: true,
      actionBeforeModalOpen: payload,
    };
  })
  .on(savePlanAfterCheckingErrorsFx.done, (state, { result }) => {
    return {
      ...state,
      isSaveResolved: result,
      isSaveLoading: false,
      isSaveRejected: false,
    };
  })
  .on(savePlanAfterCheckingErrorsFx.pending, (state, payload) => {
    return {
      ...state,
      isSaveResolved: payload ? false : state.isSaveResolved,
      isSaveRejected: payload ? false : state.isSaveRejected,
      isSaveLoading: payload,
    };
  })
  .on(setNumberOfErrorsFn, (state, { numberOfErrors }) => {
    return { ...state, numberOfErrors: numberOfErrors ?? null };
  });

$Errors.reset(resetErrorsFn).on(setPlanErrorsFn, (_, payload) => {
  return payload.errors;
});

sample({
  clock: triggerFindErrorsFn,
  source: $ScenarioId,
  filter: ($scenarioId): $scenarioId is string => !!$scenarioId,
  fn: ($scenarioId: string, clockData) => ({
    id: $scenarioId,
    saveAfterAnalysis: !!clockData?.saveAfterAnalysis,
  }),
  target: getAgentsShareInfoFx,
});

sample({
  clock: getAgentsShareInfoFx.done,
  source: { ruler: $ListOfRulers, plan: $ListOfFloorsWithListOfObjects },
  fn: (sourceData, { params, result }) => {
    const errors = getPlanErrors({
      plan: sourceData,
      agents: result,
    });

    return {
      errors,
      saveAfterAnalysis: params.saveAfterAnalysis,
    };
  },
  target: setPlanErrorsFn,
});

sample({
  clock: setPlanErrorsFn,
  source: $Errors,
  filter: (sourceData, { saveAfterAnalysis }) =>
    saveAfterAnalysis && !sourceData?.length,
  target: triggerSavePlanAfterCheckingErrorsFn,
});

sample({
  clock: triggerSavePlanAfterCheckingErrorsFn,
  source: {
    plan: $ListOfFloorsWithListOfObjects,
    id: $ScenarioId,
    ruler: $ListOfRulers,
  },
  filter: (sourceData): sourceData is SaveSimulationModelPlanArgs =>
    !!sourceData.id,
  target: savePlanAfterCheckingErrorsFx,
});

sample({
  clock: closePlanErrorsWindowFn,
  target: resetErrorsModalFn,
});

sample({
  source: $Errors,
  fn: sourceData => ({
    numberOfErrors: sourceData?.length,
  }),
  target: setNumberOfErrorsFn,
});

sample({
  source: savePlanAfterCheckingErrorsFx.done,
  filter: ({ result, params }) => !!(result && params.closeWindowAfterSave),
  target: closePlanErrorsWindowFn,
});

getAgentsShareInfoFx.use(async params => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query AllAgentShareInfo($id: UUID!) {
        allAgentShareInfo(scenarioId: $id) {
          storeProbability
          bankProbability
          foodProbability
          otherProbability
          toiletProbability
          informationTableProbability
        }
      }
    `,
    variables: params,
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data.allAgentShareInfo;
});

savePlanAfterCheckingErrorsFx.use(async ({ plan, id, ruler }) => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation UpdatePlan($document: JSONString, $id: UUID!) {
        updatePlan(document: $document, scenarioId: $id) {
          ok
          errors {
            key
            message
          }
        }
      }
    `,
    variables: {
      id,
      document: preparePlanBeforeUpload({ plan, ruler }),
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  console.log('План сохранен?:', response.data?.updatePlan?.ok);

  return !!response.data?.updatePlan?.ok;
});
