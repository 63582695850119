import { useUnit } from 'effector-react';
import { useMemo } from 'react';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { Props } from 'recharts/types/component/DefaultLegendContent';
import { NameType } from 'recharts/types/component/DefaultTooltipContent';

import { Box, Card, CardContent, Typography } from '@mui/material';

import { $FirstPageDashboard } from '@features/ag-forecast/stores/dashboard';
import { $YearLineStore } from '@features/ag-forecast/stores/yearLine/store';

const data = [
  {
    name: 'Утр. пик',
    'Личный траспорт': 0,
    'Общественный транспорт': 0,
  },
  {
    name: 'День',
    'Личный траспорт': 0,
    'Общественный транспорт': 0,
  },
  {
    name: 'Веч. пик',
    'Личный траспорт': 0,
    'Общественный транспорт': 0,
  },
  {
    name: 'Вечер',
    'Личный траспорт': 0,
    'Общественный транспорт': 0,
  },
  {
    name: 'Ночь',
    'Личный траспорт': 0,
    'Общественный транспорт': 0,
  },
];

const TooltipContent = (
  props:
    | TooltipProps<number, NameType>
    | Readonly<TooltipProps<number, NameType>>,
) => {
  const { active, payload, label } = props;

  const formattedValue = (value: number | string = 0) => {
    if (value.toString().indexOf('.') >= 0) {
      return (+value).toFixed(0);
    }
    return value;
  };

  if (active && payload && payload.length) {
    return (
      <Card sx={{}}>
        <CardContent sx={{ p: 1 }}>
          <Typography fontSize={theme => theme.typography.pxToRem(12)}>
            {label}
          </Typography>

          <Typography
            fontSize={theme => theme.typography.pxToRem(12)}
            color={'#83CA6B'}
          >
            {`Личный траспорт: ${formattedValue(payload[0].value)}`}
          </Typography>

          <Typography
            fontSize={theme => theme.typography.pxToRem(12)}
            color={'#79A2F2'}
          >
            {`Общественный транспорт: ${formattedValue(payload[1].value)}`}
          </Typography>
        </CardContent>
      </Card>
    );
  }
};

const LegendContent = (props: Props) => {
  const payload = props.payload;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        mt: 3,
      }}
    >
      {payload?.map((entry, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 1,
          }}
        >
          <Box
            sx={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor: entry.color,
            }}
          />

          <Typography fontSize={theme => theme.typography.pxToRem(12)}>
            {entry.value}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export const PassTrafficDynamics = () => {
  const { flowDynamics } = useUnit($FirstPageDashboard);
  const { selectedYear } = useUnit($YearLineStore);

  const dynamics = useMemo(() => {
    const privateDynamics = flowDynamics?.private?.find(
      item => item?.year === selectedYear,
    )?.params;
    const publicDynamics = flowDynamics?.public?.find(
      item => item?.year === selectedYear,
    )?.params;

    return data.map((item, i) => {
      item['Личный траспорт'] = privateDynamics?.[i]?.flow || 0;
      item['Общественный транспорт'] = publicDynamics?.[i]?.flow || 0;
      return item;
    });
  }, [selectedYear, flowDynamics?.private, flowDynamics?.public]);

  return (
    <ResponsiveContainer
      width="100%"
      minHeight="200px"
    >
      <BarChart
        width={500}
        height={200}
        data={dynamics}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 45,
        }}
      >
        <XAxis
          dataKey="name"
          label={{
            value: 'Время, ч.',
            position: 'bottom',
            fontSize: 12,
            textAnchor: 'middle',
          }}
          fontSize={12}
        />
        <YAxis
          tickCount={6}
          label={{
            value: 'Пассажиропоток, пасс.',
            angle: -90,
            position: 'left',
            fontSize: 11,
            textAnchor: 'middle',
          }}
          fontSize={12}
        />
        <Tooltip content={TooltipContent} />
        <Legend content={LegendContent} />
        <Bar
          dataKey="Личный траспорт"
          fill="#83CA6B"
        />
        <Bar
          dataKey="Общественный транспорт"
          fill="#79A2F2"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
