import { Image } from 'react-konva';
import useImage from 'use-image';

import BankIcon from '@components/icons/Bank.svg';
import BankomatIcon from '@components/icons/Bankomat.svg';
import BuffetIcon from '@components/icons/Buffet.svg';
import ShoppingCartIcon from '@components/icons/Cart.svg';
import DoorIcon from '@components/icons/Door.svg';
import ElevatorIcon from '@components/icons/Elevator.svg';
import ExitIcon from '@components/icons/Exit.svg';
import InformationTableIcon from '@components/icons/InformationTable.svg';
import KioskIcon from '@components/icons/Kiosk.svg';
import PathIcon from '@components/icons/Path.svg';
import PeopleIcon from '@components/icons/People.svg';
import PlatformIcon from '@components/icons/Platform.svg';
import RestaurantIcon from '@components/icons/Restaurant.svg';
import SecurityCheckpointIcon from '@components/icons/SecurityCheckpoint.svg';
import StoreIcon from '@components/icons/Store.svg';
import TicketPrintingMachineIcon from '@components/icons/TicketPrintingMachine.svg';
import TicketIcon from '@components/icons/Tickets.svg';
import ToiletIcon from '@components/icons/Toilet.svg';
import TurnstileIcon from '@components/icons/Turnstile.svg';
import VendingIcon from '@components/icons/Vending.svg';
import WaitingRoomIcon from '@components/icons/WaitingRoom.svg';

import {
  EditableObjectType,
  IPoint,
} from '@features/tpu-simulation-model/types';

interface SinglePointObjectProps {
  type:
    | EditableObjectType.Platform
    | EditableObjectType.Door
    | EditableObjectType.Elevator
    | EditableObjectType.Path
    | EditableObjectType.PassengerGenerator
    | EditableObjectType.Exit
    | EditableObjectType.TicketOffice
    | EditableObjectType.TicketPrintingMachine
    | EditableObjectType.Toilet
    | EditableObjectType.Turnstile
    | EditableObjectType.SecurityCheckpoint
    | EditableObjectType.Waiting
    | EditableObjectType.InformationTable
    | EditableObjectType.Restaurant
    | EditableObjectType.Buffet
    | EditableObjectType.Bankomat
    | EditableObjectType.Kiosk
    | EditableObjectType.Bank
    | EditableObjectType.Other
    | EditableObjectType.VendingMachine
    | EditableObjectType.Store;
  point: IPoint;
}

const ICON = {
  [EditableObjectType.Platform]: PlatformIcon,
  [EditableObjectType.Door]: DoorIcon,
  [EditableObjectType.Elevator]: ElevatorIcon,
  [EditableObjectType.TicketOffice]: TicketIcon,
  [EditableObjectType.TicketPrintingMachine]: TicketPrintingMachineIcon,
  [EditableObjectType.Turnstile]: TurnstileIcon,
  [EditableObjectType.SecurityCheckpoint]: SecurityCheckpointIcon,
  [EditableObjectType.InformationTable]: InformationTableIcon,
  [EditableObjectType.Toilet]: ToiletIcon,
  [EditableObjectType.Waiting]: WaitingRoomIcon,
  [EditableObjectType.Restaurant]: RestaurantIcon,
  [EditableObjectType.Buffet]: BuffetIcon,
  [EditableObjectType.VendingMachine]: VendingIcon,
  [EditableObjectType.Store]: StoreIcon,
  [EditableObjectType.Kiosk]: KioskIcon,
  [EditableObjectType.Bank]: BankIcon,
  [EditableObjectType.Bankomat]: BankomatIcon,
  [EditableObjectType.Other]: ShoppingCartIcon,
  [EditableObjectType.PassengerGenerator]: PeopleIcon,
  [EditableObjectType.Path]: PathIcon,
  [EditableObjectType.Exit]: ExitIcon,
};

export const SinglePointObject = ({ type, point }: SinglePointObjectProps) => {
  const [icon] = useImage(ICON[type]);

  return (
    <Image
      image={icon}
      x={point.x}
      y={point.y}
      opacity={0.7}
    />
  );
};
