import { sample } from 'effector';

import {
  openObjectForEditByIdFromAnyFloorFn,
  outputObjectIdInConsoleFx,
} from '@features/tpu-simulation-model/store/editedObject/editedObject.store';
import {
  $EditedObject,
  openObjectForEditFn,
  resetEditableObjectDataFn,
  updateEditWindowDataFn,
} from '@features/tpu-simulation-model/store/editedObject/editedObject.store';
import { closePlanErrorsWindowFn } from '@features/tpu-simulation-model/store/errorsModal/errorsModal.store';
import { selectFloorWithoutResetEditedObjectFn } from '@features/tpu-simulation-model/store/floors/floors.store';
import {
  $InputFindObject,
  resetInputFindObjectFn,
} from '@features/tpu-simulation-model/store/inputFindObject/inputFindObject';
import {
  $ListOfFloorsWithListOfObjects,
  addObjectInListFn,
  deleteObjectFn,
  updateObjectDataFn,
} from '@features/tpu-simulation-model/store/listOfObjects/listOfObjects.store';
import { ObjectOfTheSimulationModel } from '@features/tpu-simulation-model/types';
import { getCenterBottomPoint } from '@features/tpu-simulation-model/utils/getCenterBottomPoint';

$EditedObject
  .reset(resetEditableObjectDataFn)
  .on([openObjectForEditFn, updateEditWindowDataFn], (_, payload) => {
    if (!payload) return;
    const objectForEdit =
      'objectForEdit' in payload ? payload.objectForEdit : payload;
    const centerBottomPoint =
      'points' in objectForEdit
        ? getCenterBottomPoint(objectForEdit.points)
        : {
            x: objectForEdit.point.x + objectForEdit.width / 2,
            y: objectForEdit.point.y + objectForEdit.height,
          };

    return { data: objectForEdit, interactionWindowPoint: centerBottomPoint };
  });

sample({
  clock: deleteObjectFn,
  source: { editedObject: $EditedObject },
  filter: ({ editedObject }, { objectId }) =>
    objectId === editedObject.data?.uuid,
  target: resetEditableObjectDataFn,
});

sample({
  clock: updateObjectDataFn,
  source: $EditedObject,
  filter: (sourceData, clockData) =>
    sourceData.data?.uuid === clockData.data.uuid,
  fn: (_, clockData) => clockData.data,
  target: updateEditWindowDataFn,
});

sample({
  clock: addObjectInListFn,
  fn: clockData => clockData.data,
  target: openObjectForEditFn,
});

sample({
  clock: openObjectForEditByIdFromAnyFloorFn,
  source: {
    listOfFloorsWithListOfObjects: $ListOfFloorsWithListOfObjects,
  },
  fn: ({ listOfFloorsWithListOfObjects }, objectIdForEdit) => {
    let floorIdForSelect = '';
    let objectForEdit: ObjectOfTheSimulationModel | null = null;

    for (const floorId in listOfFloorsWithListOfObjects) {
      if (
        !Object.prototype.hasOwnProperty.call(
          listOfFloorsWithListOfObjects,
          floorId,
        )
      )
        continue;

      const listOfObjectOnCurrentFloor = listOfFloorsWithListOfObjects[floorId];
      for (const objectId in listOfObjectOnCurrentFloor) {
        if (objectId === objectIdForEdit) {
          floorIdForSelect = floorId;
          objectForEdit = listOfObjectOnCurrentFloor[objectId];
          break;
        }
      }
    }

    if (!objectForEdit) return;

    return { floorId: floorIdForSelect, objectForEdit };
  },
  target: [selectFloorWithoutResetEditedObjectFn, openObjectForEditFn],
});

sample({
  clock: openObjectForEditByIdFromAnyFloorFn,
  target: closePlanErrorsWindowFn,
});

sample({
  clock: openObjectForEditFn,
  source: { input: $InputFindObject, form: $EditedObject },
  filter: ({ input, form }) => input.value?.id !== form.data?.uuid,
  target: resetInputFindObjectFn,
});

sample({
  clock: openObjectForEditFn,
  filter: (
    clockData,
  ): clockData is
    | ObjectOfTheSimulationModel
    | { objectForEdit: ObjectOfTheSimulationModel } => !!clockData,
  fn: (
    clockData:
      | ObjectOfTheSimulationModel
      | { objectForEdit: ObjectOfTheSimulationModel },
  ) => {
    const data =
      'objectForEdit' in clockData ? clockData.objectForEdit : clockData;
    return data.uuid;
  },
  target: outputObjectIdInConsoleFx,
});

outputObjectIdInConsoleFx.use(id =>
  console.log(`uuid редактируемого объекта: ${id}`),
);
