import {
  IAgentShareInfoCategoryEnum,
  IAgentShareInfoColorEnum,
} from '@api/gql/tpu-types';

export const CATEGORY_TYPE_TRANSLATE: Record<
  IAgentShareInfoCategoryEnum,
  string
> = {
  [IAgentShareInfoCategoryEnum.DisembarkingCommuter]:
    'На высадку, пригородное сообщение',
  [IAgentShareInfoCategoryEnum.DisembarkingLongDistance]:
    'На высадку, дальнее сообщение',
  [IAgentShareInfoCategoryEnum.EmbarkingCommuter]:
    'На посадку, пригородное сообщение',
  [IAgentShareInfoCategoryEnum.EmbarkingLongDistance]:
    'На посадку, дальнее сообщение',
  [IAgentShareInfoCategoryEnum.Visitors]: 'Посетители',
};

export const CATEGORY_TYPES = [
  {
    value: IAgentShareInfoCategoryEnum.DisembarkingCommuter,
    label: 'На высадку, пригородное сообщение',
  },
  {
    value: IAgentShareInfoCategoryEnum.DisembarkingLongDistance,
    label: 'На высадку, дальнее сообщение',
  },
  {
    value: IAgentShareInfoCategoryEnum.EmbarkingCommuter,
    label: 'На посадку, пригородное сообщение',
  },
  {
    value: IAgentShareInfoCategoryEnum.EmbarkingLongDistance,
    label: 'На посадку, дальнее сообщение',
  },
  { value: IAgentShareInfoCategoryEnum.Visitors, label: 'Посетители' },
];

export const COLORS = {
  [IAgentShareInfoColorEnum.Green]: '#5FD039',
  [IAgentShareInfoColorEnum.Blue]: '#3362BC',
  [IAgentShareInfoColorEnum.Purple]: '#A03FDB',
  [IAgentShareInfoColorEnum.Pink]: '#F41ED2',
  [IAgentShareInfoColorEnum.LightBlue]: '#00C2FF',
  [IAgentShareInfoColorEnum.Red]: '#DB3F3F',
  [IAgentShareInfoColorEnum.Orange]: '#F38503',
  [IAgentShareInfoColorEnum.Yellow]: '#FFD600',
  [IAgentShareInfoColorEnum.GreenBlue]: '#273B48',
  [IAgentShareInfoColorEnum.Grey]: '#808080',
};

export const COLOR_TYPES = [
  { value: IAgentShareInfoColorEnum.Green, label: '', color: '#5FD039' },
  { value: IAgentShareInfoColorEnum.Blue, label: '', color: '#3362BC' },
  { value: IAgentShareInfoColorEnum.Purple, label: '', color: '#A03FDB' },
  { value: IAgentShareInfoColorEnum.Pink, label: '', color: '#F41ED2' },
  { value: IAgentShareInfoColorEnum.LightBlue, label: '', color: '#00C2FF' },
  { value: IAgentShareInfoColorEnum.Red, label: '', color: '#DB3F3F' },
  { value: IAgentShareInfoColorEnum.Orange, label: '', color: '#F38503' },
  { value: IAgentShareInfoColorEnum.Yellow, label: '', color: '#FFD600' },
  { value: IAgentShareInfoColorEnum.GreenBlue, label: '', color: '#273B48' },
  { value: IAgentShareInfoColorEnum.Grey, label: '', color: '#808080' },
];

export const BUYING_TICKETS: {
  value: 'ticketOfficeProbability' | 'tpmProbability';
  label: string;
}[] = [
  {
    value: 'ticketOfficeProbability',
    label: 'В кассах',
  },
  { value: 'tpmProbability', label: 'В БПА' },
];

type TProbability =
  | 'informationTableProbability'
  | 'foodProbability'
  | 'storeProbability'
  | 'bankProbability'
  | 'otherProbability'
  | 'toiletProbability';
export const PROBABILITIES: {
  value: TProbability;
  label: string;
}[] = [
  {
    value: 'informationTableProbability',
    label: 'Информационное табло',
  },
  {
    value: 'foodProbability',
    label: 'Рестораны, кафе, буфеты',
  },
  {
    value: 'storeProbability',
    label: 'Магазины, вендинговые аппараты, киоски',
  },
  { value: 'bankProbability', label: 'Банкоматы и отделения банков' },
  { value: 'otherProbability', label: 'Прочее' },
  { value: 'toiletProbability', label: 'Туалет' },
];
