import { apiClient } from '@api/client';
import { IMutation } from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import {
  $ModalDeleteProject,
  deleteProjectFn,
  openDeleteProjectModalFn,
  removeProjectFx,
  resetModalDeleteProjectFn,
} from './modal-delete-project.store';

import { TPU_CLIENT_NAME } from '@constants/api';

$ModalDeleteProject
  .reset(resetModalDeleteProjectFn)
  .on(openDeleteProjectModalFn, (state, payload) => {
    return { ...state, id: payload.id, name: payload.name, isOpen: true };
  })
  .on(removeProjectFx.doneData, (state, payload) => {
    return { ...state, isResolve: payload, isRejected: !payload };
  })
  .on(removeProjectFx.pending, (state, payload) => {
    return { ...state, isLoading: payload };
  });

sample({
  clock: deleteProjectFn,
  source: $ModalDeleteProject,
  filter: sourceData => !!sourceData.id,
  fn: sourceData => {
    return sourceData.id as string;
  },
  target: removeProjectFx,
});

removeProjectFx.use(async id => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation RemoveProjectMutation($projectId: UUID!) {
        removeProject(projectId: $projectId) {
          ok
          errors {
            key
            message
          }
        }
      }
    `,
    variables: {
      projectId: id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return !!response.data?.removeProject?.ok;
});
