export const SOC_ECO_TH_4_1 = [
  {
    id: 'transportObject',
    label: 'Транспортный объект',
  },
  {
    id: 'transportArea',
    label: 'Транспортный район',
  },
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'numberPassengersPerDay',
    label: 'Число пассажиров в сутки, чел.',
  },
  {
    id: 'requiredNumberPersonalMobilityDevices',
    label: 'Необходимое число средств индивидуальной мобильности, шт.',
  },
];
