import { createApi, createStore } from 'effector';

export const $UI = createStore<UIState>({
  inspector: false,
});

export const UIApi = createApi($UI, {
  toggleInspector: store => ({
    ...store,
    inspector: !store.inspector,
  }),
  setInspector: (store, payload: boolean) => ({
    ...store,
    inspector: payload,
  }),
});

type UIState = {
  inspector: boolean;
};
