import {
  // IComputingRegistryPredictionScenarioSocEcoTypeChoices,
  IQueryGetProjectSocEcoScenariosArgs,
} from '@api/gql/ag-types';
import { sample } from 'effector';

import { mutationDeleteScenario } from '@features/ag-constructor/stores/api/mutationDeleteScenario';
import { queryGetProjectScenarios } from '@features/ag-constructor/stores/api/queryGetProjectScenarios';
import { queryGetProjectSocEcoScenarios } from '@features/ag-constructor/stores/api/queryGetProjectSocEcoScenarios';
import { CurrentScenarioApi } from '@features/ag-constructor/stores/currentScenario';
import {
  ComputedScenarioListApi,
  fetchComputedScenarioListFx,
  fetchDeleteScenarioFx,
  fetchSocEcoScenarioListFx,
} from '@features/ag-constructor/stores/lists/scenarios/store';
import { $FeatureSettings } from '@features/ag-constructor/stores/settings';

fetchComputedScenarioListFx.use(queryGetProjectScenarios);

// Запись в стор сценариев расчета
sample({
  clock: fetchComputedScenarioListFx.done,
  fn: request => {
    return (request.result || []).map(item => ({
      id: item?.id || '',
      name: item?.name || 'Безымянный',
    }));
  },
  target: ComputedScenarioListApi.setUserComputedScenarioList,
});

//------------------

fetchSocEcoScenarioListFx.use(queryGetProjectSocEcoScenarios);

// Запись в стор сценариев соц эко
sample({
  clock: fetchSocEcoScenarioListFx.done,
  fn: request => {
    return (request.result || []).map(item => ({
      annualEducationPlacesGrowthRate:
        item?.annualEducationPlacesGrowthRate || 0,
      annualGardenPartnershipGrowthRate:
        item?.annualGardenPartnershipGrowthRate || 0,
      annualJobsGrowthRate: item?.annualJobsGrowthRate || 0,
      annualPopulationGrowthRate: item?.annualPopulationGrowthRate || 0,
      annualRetailSpaceGrowthRate: item?.annualRetailSpaceGrowthRate || 0,
      annualSalariesGrowthRate: item?.annualSalariesGrowthRate || 0,
      annualServiceJobsGrowthRate: item?.annualServiceJobsGrowthRate || 0,
      created: item?.created || new Date(),
      errors: item?.errors || '',
      finished: item?.finished || false,
      id: item?.id || '',
      modified: item?.modified || new Date(),
      name: item?.name || '',
      scenarioId: item?.scenarioId || 0,
      type: item?.type || 1,
      // IComputingRegistryPredictionScenarioSocEcoTypeChoices.A_1,
    }));
  },
  target: ComputedScenarioListApi.setSocEcoComputedScenarioList,
});

//------------------

fetchDeleteScenarioFx.use(mutationDeleteScenario);

// Получение сценариев расчета и очистка данных выбранного сценария
sample({
  clock: fetchDeleteScenarioFx.done,
  source: $FeatureSettings,
  fn: store =>
    ({
      projectId: store.projectUUID || '',
    }) as IQueryGetProjectSocEcoScenariosArgs,
  target: [fetchComputedScenarioListFx, CurrentScenarioApi.reset],
});
