import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckMiniIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33325 7.99967C1.33325 4.33301 4.33325 1.33301 7.99992 1.33301C11.6666 1.33301 14.6666 4.33301 14.6666 7.99967C14.6666 11.6663 11.6666 14.6663 7.99992 14.6663C4.33325 14.6663 1.33325 11.6663 1.33325 7.99967ZM7.033 11.3323L4.17 8.46927C3.91024 8.20951 3.91024 7.78837 4.17 7.52861C4.42949 7.26911 4.85013 7.26882 5.10999 7.52796L7.033 9.44561L10.856 5.51666C11.1172 5.25551 11.5408 5.25626 11.801 5.51833C12.06 5.77909 12.0592 6.20015 11.7994 6.45999L7.033 11.3323Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
