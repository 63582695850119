import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const PassangersIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <circle
        cx="3"
        cy="3"
        r="2"
        fill="#425A69"
      />
      <circle
        cx="9"
        cy="9"
        r="2"
        fill="#425A69"
      />
      <circle
        cx="15"
        cy="15"
        r="2"
        fill="#425A69"
      />
      <circle
        cx="21"
        cy="21"
        r="2"
        fill="#425A69"
      />
    </SvgIcon>
  );
};
