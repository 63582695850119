import { initLayersVisible } from '@features/ag-project/constants/inits';
import { AgLayersStore } from '@features/ag-project/stores/map';
import { createApi, createStore } from 'effector';

const initState: FilterMapState = {
  actual: initLayersVisible,
  cache: null,
};

export const $FilterMap = createStore<FilterMapState>(initState);

export const FilterMapApi = createApi($FilterMap, {
  setFilters: (store, payload: SetFiltersPayload) => ({
    ...store,
    actual: {
      ...store.actual,
      ...payload,
    },
  }),
  hideAllToCache: store => ({
    cache: store.actual,
    actual: Object.fromEntries(
      Object.keys(store.actual).map(key => [key, false]),
    ) as FilterMapState['actual'],
  }),
  restoreAllFromCache: store => ({
    actual: store.cache || initLayersVisible,
    cache: null,
  }),
  reset: () => initState,
});

export type SetFiltersPayload = Partial<FilterMapState['actual']>;
export type FilterMapState = {
  actual: Record<keyof AgLayersStore, boolean> & {
    transportDistricts: boolean;
    isActual: boolean;
    isPlanned: boolean;
  };
  cache:
    | (Record<keyof AgLayersStore, boolean> & {
        transportDistricts: boolean;
        isActual: boolean;
        isPlanned: boolean;
      })
    | null;
};
