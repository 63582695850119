import { apiClient } from '@api/client';
import {
  IQuery,
  IQueryFindRegionArgs,
  IQueryFindRegionByPrefixArgs,
} from '@api/gql/types';
import { gql } from '@apollo/client';
import { guard, sample } from 'effector';
import { boundingExtent } from 'ol/extent';
import { fromLonLat } from 'ol/proj';

import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import {
  $FilterMap,
  $FilterRegions,
  $Map,
  $TimeLine,
  $isIdleFindRegionFx,
  FilterRegionsApi,
  FilterRegionsData,
  IQueryGetDirectionWithLayoutArgs,
  SetListRegionsPayload,
  findRegionByPrefixFn,
  findRegionByPrefixFx,
  findRegionFn,
  findRegionFx,
  getDirectionFx,
  getDirectionHighwayFx,
  getDirectionRailwayFx,
  setFilters,
  setRegionFn,
} from '@features/passenger-traffic/stores';

// триггерим событие на установку подходящих регионов
sample({
  clock: findRegionByPrefixFx.done,
  fn: request => {
    return {
      point: request.params.point,
      options: request.result,
    } as SetListRegionsPayload;
  },
  target: FilterRegionsApi.setListRegions,
});

// Очистить выбранные точки и сообщить карте
sample({
  clock: setRegionFn,
  filter: payload => !payload.option,
  target: FilterRegionsApi.clearSelectedRegions,
});

// Получить расширенную информацию по искому региону и записать в стор
sample({
  clock: findRegionFx.done,
  fn: request => ({
    point: request.params.point,
    option: request.result.transportRegion,
  }),
  target: FilterRegionsApi.setSelectedRegion,
});

// триггерим событие на поиск региона
sample({
  clock: findRegionFn,
  filter: $isIdleFindRegionFx,
  target: findRegionFx,
});

// триггерим событие на поиск подходящих регионов через ввод
sample({
  clock: findRegionByPrefixFn,
  target: findRegionByPrefixFx,
});

// триггерим событие на поиск региона через поле ввода
sample({
  clock: setRegionFn,
  filter: payload => Boolean(payload.option),
  fn: payload => {
    return {
      lat: payload.option?.center?.geometry.coordinates[0],
      lon: payload.option?.center?.geometry.coordinates[1],
      point: payload.point,
    };
  },
  target: findRegionFx,
});

const setPlannedGuarded = guard(setFilters, {
  filter: payload => {
    return payload?.planned !== undefined || payload?.plannedYear !== undefined;
  },
});

// ЕСли обе точки установленны, запросить корреспонденцию
sample({
  clock: [FilterRegionsApi.setSelectedRegion, setPlannedGuarded],
  source: {
    FilterRegions: $FilterRegions,
    FilterMap: $FilterMap,
    Timeline: $TimeLine,
  },
  filter: ({ FilterRegions }) =>
    Boolean(FilterRegions.selectedRegionA && FilterRegions.selectedRegionB),
  fn: ({ FilterRegions, FilterMap, Timeline }) => {
    const args = {
      regionFrom: FilterRegions.selectedRegionA?.id,
      regionTo: FilterRegions.selectedRegionB?.id,
      layout: EMapFeatureLayout.areaBorder,
      includePayment: true,
      plannedYear: Timeline.baseYear,
    };
    if (FilterMap.planned) args.plannedYear = FilterMap.plannedYear;
    return args;
  },
  target: [
    getDirectionFx,
    getDirectionHighwayFx,
    getDirectionRailwayFx,
    FilterRegionsApi.clearDirections,
  ],
});

sample({
  clock: [
    FilterRegionsApi.setSelectedRegion,
    FilterRegionsApi.clearSelectedRegions,
  ],
  fn: () => ({
    railway: [],
    highway: [],
    regions: [],
    stations: [],
  }),
  target: FilterRegionsApi.setDirections,
});

// Получение геометрии для корреспонденции станции и тр
sample({
  clock: getDirectionFx.done,
  source: { Map: $Map, FilterRegions: $FilterRegions },
  fn: ({ Map, FilterRegions }, request) => {
    // Центрирование между выбранными регионами
    const a = FilterRegions.selectedRegionA?.center?.geometry?.coordinates;
    const b = FilterRegions.selectedRegionB?.center?.geometry?.coordinates;
    if (a?.length && b?.length) {
      const extent = boundingExtent([fromLonLat(a), fromLonLat(b)]);
      Map.map
        ?.getView()
        .fit(extent, { padding: [150, 150, 150, 500], duration: 200 });
    }

    return {
      regions: request.result?.getTransportRegionsIntersection || [],
      stations: request.result?.getRailwayStationsIntersection || [],
    } as FilterRegionsData['directions'];
  },
  target: FilterRegionsApi.setDirections,
});

// Получение геометрии для авто корреспонденции
sample({
  clock: getDirectionHighwayFx.done,
  fn: request => request.result?.highways || [],
  target: FilterRegionsApi.setHighwayDirections,
});

// Получение геометрии для ж/д корреспонденции
sample({
  clock: getDirectionRailwayFx.done,
  fn: request => {
    return request.result?.railways || [];
  },
  target: FilterRegionsApi.setRailwayDirections,
});

// Запрос на получение геометрии ж/д
getDirectionRailwayFx.use(async params => {
  const response = await apiClient.query<
    IQuery,
    IQueryGetDirectionWithLayoutArgs
  >({
    query: gql`
      query QueryGetRailwayDirection(
        $regionFrom: String
        $regionTo: String
        $plannedYear: Int
        $includePayment: Boolean
      ) {
        getDirection(
          regionFrom: $regionFrom
          regionTo: $regionTo
          plannedYear: $plannedYear
          includePayment: $includePayment
        ) {
          railways {
            geometry
            id
            details {
              status
              year
            }
          }
        }
      }
    `,
    variables: {
      regionFrom: params.regionFrom,
      regionTo: params.regionTo,
      layout: EMapFeatureLayout.areaBorder,
      includePayment: true,
      plannedYear: params.plannedYear,
    },
  });
  return response.data.getDirection;
});

// Запрос на получение геометрии дорог
getDirectionHighwayFx.use(async params => {
  const response = await apiClient.query<
    IQuery,
    IQueryGetDirectionWithLayoutArgs
  >({
    query: gql`
      query QueryGetRailwayDirection(
        $regionFrom: String
        $regionTo: String
        $plannedYear: Int
        $includePayment: Boolean
      ) {
        getDirection(
          regionFrom: $regionFrom
          regionTo: $regionTo
          plannedYear: $plannedYear
          includePayment: $includePayment
        ) {
          highways {
            geometry
            id
            details {
              status
              year
            }
          }
        }
      }
    `,
    variables: {
      regionFrom: params.regionFrom,
      regionTo: params.regionTo,
      layout: EMapFeatureLayout.areaBorder,
      includePayment: true,
      plannedYear: params.plannedYear,
    },
  });
  return response.data.getDirection;
});

//
getDirectionFx.use(async params => {
  const response = await apiClient.query<
    IQuery,
    IQueryGetDirectionWithLayoutArgs
  >({
    query: gql`
      query QueryGetDirection(
        $regionFrom: String
        $regionTo: String
        $plannedYear: Int
        $includePayment: Boolean
      ) {
        getDirection(
          regionFrom: $regionFrom
          regionTo: $regionTo
          plannedYear: $plannedYear
          includePayment: $includePayment
        ) {
          id
          getTransportRegionsIntersection {
            id
            border {
              geometry
            }
          }
          getRailwayStationsIntersection {
            id
            name
            geometry
          }
        }
      }
    `,
    variables: {
      regionFrom: params.regionFrom,
      regionTo: params.regionTo,
      layout: EMapFeatureLayout.areaBorder,
      includePayment: true,
      plannedYear: params.plannedYear,
    },
  });

  return response.data.getDirection;
});

// запрос на получение тр по точке на карте
findRegionFx.use(async params => {
  const response = await apiClient.query<IQuery, IQueryFindRegionArgs>({
    query: gql`
      query QueryFindRegion($lat: Float!, $lon: Float!) {
        findRegion(lat: $lat, lon: $lon) {
          transportRegion {
            id
            name
            center {
              name
              id
              geometry
            }
            railwaystationSet {
              geometry
              id
              isTerminal
              details {
                year
              }
            }
            busstationSet {
              geometry
              id
              details {
                year
              }
            }
            airportSet {
              geometry
              id
              details {
                year
              }
            }
          }
          geometry
        }
      }
    `,
    variables: {
      lon: params.lon,
      lat: params.lat,
    },
  });

  return response.data.findRegion!;
});

findRegionByPrefixFx.use(async params => {
  if (params.name!.length < (params.maxCount || 3)) return [];

  const response = await apiClient.query<IQuery, IQueryFindRegionByPrefixArgs>({
    query: gql`
      query QueryFindRegionByPrefix($name: String, $maxCount: Int) {
        findRegionByPrefix(name: $name, maxCount: $maxCount) {
          id
          name
          trId
          center {
            geometry
            id
            name
            transportRegion {
              center {
                geometry
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      name: params.name,
      maxCount: params.maxCount,
    },
  });

  return response.data.findRegionByPrefix;
});
