import { $CurrentScenario } from '@features/ag-constructor/stores/currentScenario';
import { fetchDeleteScenarioFx } from '@features/ag-constructor/stores/lists/scenarios';
import {
  $ModalsAgConstructor,
  ModalsAgConstructor,
} from '@features/ag-constructor/stores/modals';
import { useUnit } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { DialogCloseButton } from '@components/DialogCloseButton';

import { Question } from './states/question';
import { Success } from './states/success';
import {
  $ModalDeleteCalculation,
  EStateModalDeleteCalculation,
  resetModalDeleteCalculation,
  setState,
} from './store';

export type ModalDeleteCalculationAction = (isDelete: boolean) => void;

interface ModalDeleteCalculationProps {
  onClose(): void;
}

export const ModalDeleteCalculation = (props: ModalDeleteCalculationProps) => {
  const { onClose } = props;
  const $modalDeleteCalculation = useUnit($ModalDeleteCalculation);
  const $currentScenario = useUnit($CurrentScenario);
  const handlers = useUnit({ resetModalDeleteCalculation, setState });
  const $modalsAgConstructor = useUnit(
    $ModalsAgConstructor,
  ) as ModalsAgConstructor<ModalDeleteCalculationAction>;

  const DescriptionState = useMemo(() => {
    switch ($modalDeleteCalculation.state) {
      case EStateModalDeleteCalculation.question:
        return Question;

      case EStateModalDeleteCalculation.success:
        return Success;
    }
  }, [$modalDeleteCalculation.state]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    $modalsAgConstructor.action && $modalsAgConstructor.action(true);
    handlers.setState(EStateModalDeleteCalculation.success);
    $currentScenario?.id &&
      fetchDeleteScenarioFx({ scenarioId: $currentScenario.id });
  }, [$currentScenario?.id, $modalsAgConstructor, handlers]);

  useEffect(() => {
    return handlers.resetModalDeleteCalculation;
  }, [handlers.resetModalDeleteCalculation]);

  return (
    <Dialog
      onClose={handleClose}
      open={true}
    >
      <DialogTitle
        variant="subtitle2"
        sx={{
          position: 'relative',
          fontSize: theme => theme.typography.pxToRem(16),
          pr: 10,
        }}
      >
        Удалить расчет
      </DialogTitle>

      <DialogCloseButton onClick={handleClose} />

      <DialogContent>
        <DescriptionState />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          {$modalDeleteCalculation.state ===
          EStateModalDeleteCalculation.question
            ? 'Не удалять'
            : 'Закрыть'}
        </Button>

        {$modalDeleteCalculation.state ===
          EStateModalDeleteCalculation.question && (
          <Button
            variant={'outlined'}
            onClick={handleSubmit}
          >
            Удалить
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
