import { useUnit } from 'effector-react';

import {
  closeFormStateFn,
  openFormForCreateNewScenarioFn,
  openScenarioForEditFn,
  openScenarioForViewingFn,
  resetFormStateFn,
  saveScenarioFn,
  setIsOpenFormFn,
} from '@features/tpu-calculation-scenarios/stores/form.store';
import { FormData } from '@features/tpu-calculation-scenarios/stores/form.store';
import {
  deleteScenarioFn,
  openDeleteScenarioModalFn,
  resetModalDeleteScenarioFn,
} from '@features/tpu-calculation-scenarios/stores/modal-delete-scenario.store';
import {
  resetErrorsFn,
  resetModalPlanErrorsFn,
  setIsOpenModalPlanErrorsFn,
} from '@features/tpu-calculation-scenarios/stores/modalPlanErrors.store';
import { getScenarioIdFn } from '@features/tpu-calculation-scenarios/stores/project.store';
import {
  copyScenarioFx,
  getAllScenariosFx,
  resetAllScenariosFn,
} from '@features/tpu-calculation-scenarios/stores/registry.store';

export interface UseCalculationScenariosHandlers {
  saveScenario(payload: FormData): void;
  getProjectId(): void;
  setIsOpenForm(payload: boolean): void;
  openScenarioForEdit(payload: string): void;
  openScenarioForViewing(payload: string): void;
  getAllScenarios(projectId: string | undefined): void;
  copyScenario(payload: string): void;
  resetFormState(): void;
  closeFormState(): void;
  openFormForCreateNewScenario(): void;
  resetScenarios(): void;
  resetModalDeleteScenario(): void;
  setIsOpenModalPlanErrors(payload: boolean): void;

  openDeleteScenarioModal(payload: { name: string; id: string }): void;
  deleteScenario(): void;
}

export const useCSHandlers = (): UseCalculationScenariosHandlers => {
  const events = useUnit({
    saveScenarioFn,
    getScenarioIdFn,
    setIsOpenFormFn,
    openScenarioForEditFn,
    openFormForCreateNewScenarioFn,
    resetFormStateFn,
    closeFormStateFn,
    openScenarioForViewingFn,
    setIsOpenModalPlanErrorsFn,
    resetModalPlanErrorsFn,
    resetErrorsFn,
    resetAllScenariosFn,
    getAllScenariosFx,
    deleteScenarioFn,
    copyScenarioFx,
    resetModalDeleteScenarioFn,
    openDeleteScenarioModalFn,
  });

  const resetScenarios = () => {
    events.resetModalPlanErrorsFn();
    events.resetErrorsFn();
    events.resetAllScenariosFn();
    events.resetFormStateFn();
  };

  return {
    saveScenario: events.saveScenarioFn,
    getProjectId: events.getScenarioIdFn,
    setIsOpenForm: events.setIsOpenFormFn,
    openFormForCreateNewScenario: events.openFormForCreateNewScenarioFn,
    openScenarioForEdit: events.openScenarioForEditFn,
    resetFormState: events.resetFormStateFn,
    closeFormState: events.closeFormStateFn,
    openScenarioForViewing: events.openScenarioForViewingFn,
    setIsOpenModalPlanErrors: events.setIsOpenModalPlanErrorsFn,
    getAllScenarios: events.getAllScenariosFx,
    deleteScenario: events.deleteScenarioFn,
    copyScenario: events.copyScenarioFx,
    resetScenarios,
    resetModalDeleteScenario: events.resetModalDeleteScenarioFn,
    openDeleteScenarioModal: events.openDeleteScenarioModalFn,
  };
};
