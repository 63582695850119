import { IVehicleTypeType, Maybe, Scalars } from '@api/gql/types';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { createEffect, createEvent, createStore } from 'effector';

export type InspectorType = {
  layout: EMapFeatureLayout | null;
  vehicleTypes: IVehicleTypeType[];
};

export type GetObjectType = {
  layout: EMapFeatureLayout | null;
  id: Scalars['UUID']['output'];
};

export const $Inspector = createStore<InspectorType>({
  layout: null,
  vehicleTypes: [],
});

// Сеттер вида инспектора инфраструктуры
export const getDataForInspectorFn = createEvent<GetObjectType>();

// Ивент для запроса получения данных транспортов
export const getVehicleTypesForInspectorFn = createEvent();

// Эффект на получение данных транспортов
export const getVehicleTypesForInspectorFx = createEffect<
  unknown,
  Maybe<IVehicleTypeType[]>
>();

// Сеттер данных видов транспорта
export const setVehicleTypesForInspector = createEvent<{
  result: Maybe<IVehicleTypeType[]>;
}>();

// Сброс отображаемых данных в инспекторе, если выбрано отображение определенного объекта
export const resetSelectedLayout = createEvent();

$Inspector
  .on(setVehicleTypesForInspector, (state, payload) => {
    if (!payload.result?.length) {
      return state;
    }
    return {
      ...state,
      vehicleTypes: payload.result,
    };
  })
  .on(getDataForInspectorFn, (state, payload) => ({
    ...state,
    layout: payload.layout,
  }))
  .on(resetSelectedLayout, state => ({
    ...state,
    layout: null,
  }));
