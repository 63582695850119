export const passTrafficTableHeadCells = [
  {
    id: 'fromName',
    label: 'Откуда',
    minWidth: 280,
  },
  {
    id: 'toName',
    label: 'Куда',
    minWidth: 280,
  },
  {
    id: 'time',
    label: (
      <>
        Время в пути <span style={{ opacity: 0.7 }}>час. и мин.</span>
        <br /> личный/общественный
      </>
    ),
    minWidth: 200,
  },
  {
    id: 'stz',
    label: (
      <>
        СТЗ, <span style={{ opacity: 0.7 }}>руб.</span>
        <br /> личный/общественный
      </>
    ),
    minWidth: 200,
  },
  {
    id: 'flow',
    label: (
      <>
        Пассажиропоток, суммарный, <span style={{ opacity: 0.7 }}>чел.</span>
        <br /> общественный/личный/суммарный
      </>
    ),
    minWidth: 280,
  },
  {
    id: 'percent',
    label: '% пассажиропотока',
    minWidth: 160,
  },
];
