import {
  CSHandlersContext,
  UseCalculationScenariosHandlers,
} from '@features/tpu-calculation-scenarios/hooks';
import { useContext } from 'react';

export const useContextCSHandlers = (): UseCalculationScenariosHandlers => {
  const context = useContext(CSHandlersContext);
  return context;
};
