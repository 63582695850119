import { apiClient } from '@api/client';
import { IQuery, IQueryGetScenarioByIdArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

export const queryDataDashboardP1 = async (
  scenario: IQueryGetScenarioByIdArgs,
) => {
  const result = await apiClient.query<
    Pick<IQuery, 'getScenarioById'>,
    IQueryGetScenarioByIdArgs
  >({
    query: gql`
      query QueryDataDashboardP1($scenarioId: String!) {
        getScenarioById(scenarioId: $scenarioId) {
          id
          stzDynamics {
            private {
              year
              params {
                stz
                timeSlot {
                  id
                  name
                }
              }
            }
            public {
              year
              params {
                stz
                timeSlot {
                  id
                  name
                }
              }
            }
          }
          flowDynamics {
            private {
              year
              params {
                timeSlot {
                  id
                }
                flow
              }
            }
            public {
              year
              params {
                timeSlot {
                  id
                }
                flow
              }
            }
          }
          flowByTransport {
            year
            publicMovementsYearly
            privateMovementsYearly
          }
        }
      }
    `,
    variables: scenario,
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.getScenarioById;
};
