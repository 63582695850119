import { guard, sample } from 'effector';

import {
  $FilterMap,
  pushPassTrafficForSelectYear,
  resetAllFilters,
  setFilters,
} from '@features/pt-forecast/stores';
import {
  $PassengerTraffic,
  PassengerTrafficApi,
  PassengerTrafficType,
} from '@features/pt-forecast/stores/charts/passTraffic/store';
import { FilterRegionApi } from '@features/pt-forecast/stores/filterRegion/store';

import { getPercent } from '@utils/getPercent';

sample({
  clock: pushPassTrafficForSelectYear,
  fn: payload =>
    ({
      multimodal: payload?.passengerTraffic.multimodal || 0,
      nightTrain: payload?.passengerTraffic.nightTrain || 0,
      dayTrain: payload?.passengerTraffic.dayTrain || 0,
      suburbanTrain: payload?.passengerTraffic.suburbanTrain || 0,
      bus: payload?.passengerTraffic.bus || 0,
      auto: payload?.passengerTraffic.auto || 0,
      avia: payload?.passengerTraffic.avia || 0,
      expressTrain: payload?.passengerTraffic.expressTrain || 0,
    }) as PassengerTrafficType['passengerTraffic'],
  target: PassengerTrafficApi.setPassengerTraffic,
});

export const filterVehicleChange = guard(setFilters, {
  filter: payload =>
    Object.keys(payload).some(e =>
      [
        'multimodal',
        'auto',
        'avia',
        'bus',
        'suburbanTrain',
        'dayTrain',
        'nightTrain',
        'expressTrain',
      ].includes(e),
    ),
});

// сеттер суммы всего пассажиропотока в зависимости от фильтра
sample({
  clock: [PassengerTrafficApi.setPassengerTraffic, filterVehicleChange],
  source: {
    passengerTrafficChart: $PassengerTraffic,
    FilterMap: $FilterMap,
  },
  fn: ({ passengerTrafficChart, FilterMap }) => {
    const keys = Object.keys(passengerTrafficChart.passengerTraffic);

    return keys.reduce((acc, curr) => {
      const key = curr as keyof PassengerTrafficType['passengerTraffic'];
      const traffic = passengerTrafficChart.passengerTraffic[key] || 0;

      if (FilterMap[key]) {
        return (acc += traffic ?? 0);
      } else {
        return acc;
      }
    }, 0);
  },
  target: PassengerTrafficApi.setTotalPassengerTraffic,
});

// сеттер процентов пассажиропотока от общего по видам транспорта
sample({
  clock: [PassengerTrafficApi.setTotalPassengerTraffic],
  source: {
    passengerTrafficChart: $PassengerTraffic,
    FilterMap: $FilterMap,
  },
  fn: ({ passengerTrafficChart, FilterMap }) => {
    if (!passengerTrafficChart.total) {
      return {
        multimodal: 0,
        nightTrain: 0,
        dayTrain: 0,
        suburbanTrain: 0,
        bus: 0,
        auto: 0,
        avia: 0,
        expressTrain: 0,
      } as PassengerTrafficType['passengerTrafficPercent'];
    }

    return {
      multimodal: FilterMap.multimodal
        ? getPercent(
            passengerTrafficChart.total,
            passengerTrafficChart.passengerTraffic.multimodal,
          )
        : 0,
      nightTrain: FilterMap.nightTrain
        ? getPercent(
            passengerTrafficChart.total,
            passengerTrafficChart.passengerTraffic.nightTrain,
          )
        : 0,
      dayTrain: FilterMap.dayTrain
        ? getPercent(
            passengerTrafficChart.total,
            passengerTrafficChart.passengerTraffic.dayTrain,
          )
        : 0,
      suburbanTrain: FilterMap.suburbanTrain
        ? getPercent(
            passengerTrafficChart.total,
            passengerTrafficChart.passengerTraffic.suburbanTrain,
          )
        : 0,
      bus: FilterMap.bus
        ? getPercent(
            passengerTrafficChart.total,
            passengerTrafficChart.passengerTraffic.bus,
          )
        : 0,
      auto: FilterMap.auto
        ? getPercent(
            passengerTrafficChart.total,
            passengerTrafficChart.passengerTraffic.auto,
          )
        : 0,
      avia: FilterMap.avia
        ? getPercent(
            passengerTrafficChart.total,
            passengerTrafficChart.passengerTraffic.avia,
          )
        : 0,
      expressTrain: FilterMap.expressTrain
        ? getPercent(
            passengerTrafficChart.total,
            passengerTrafficChart.passengerTraffic.expressTrain,
          )
        : 0,
    } as PassengerTrafficType['passengerTrafficPercent'];
  },
  target: PassengerTrafficApi.setPassengerTrafficPercent,
});

// Сброс графика пассажиропотока
sample({
  clock: [resetAllFilters, FilterRegionApi.reset],
  target: PassengerTrafficApi.reset,
});
