import { createApi, createStore } from 'effector';

const initStore: SettingsType = {
  scenarioId: null,
};

export const $Settings = createStore<SettingsType>(initStore);

export const SettingsApi = createApi($Settings, {
  setScenarioId: (store, payload: SettingsType['scenarioId']) => ({
    ...store,
    scenarioId: payload,
  }),
  reset: () => initStore,
});

export type SettingsType = {
  scenarioId: string | null;
};
