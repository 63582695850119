import { SvgIcon, SvgIconProps } from '@mui/material';

export const TicketV2Icon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M13.8437 20.0319C14.1723 20.0319 14.5009 19.9042 14.7382 19.6669L15.7239 18.6811C15.9064 18.4986 15.943 18.1883 15.7787 17.9692C15.3223 17.3485 15.3953 16.5088 15.9247 15.9612C16.4723 15.4136 17.312 15.3588 17.9327 15.8152C18.1517 15.9795 18.4438 15.943 18.6446 15.7604L19.6303 14.7747C20.1232 14.2818 20.1232 13.4604 19.6303 12.9675L11.0873 4.36965C10.5944 3.87678 9.77296 3.87678 9.28009 4.36965L8.29435 5.35539C8.11181 5.53794 8.0753 5.84826 8.23959 6.06731C8.69595 6.68796 8.62293 7.52767 8.09355 8.0753C7.54592 8.62293 6.70622 8.6777 6.08557 8.22134C5.86651 8.05705 5.57444 8.09355 5.37365 8.2761L4.36965 9.28009C3.87678 9.77296 3.87678 10.5944 4.36965 11.0873L12.931 19.6486C13.1865 19.9042 13.5151 20.0319 13.8437 20.0319ZM18.9367 13.8437C18.9367 13.8802 18.9367 13.935 18.8819 13.9715L18.1883 14.6651C17.1843 14.1723 15.9795 14.3548 15.158 15.1763C14.3366 15.9977 14.154 17.2025 14.6469 18.2065L13.9532 18.9002C13.8985 18.9549 13.8437 18.9549 13.8254 18.9549C13.8072 18.9549 13.7342 18.9549 13.6977 18.9002L7.98403 13.1683L13.15 8.00228L18.8819 13.7342C18.9367 13.7707 18.9367 13.8254 18.9367 13.8437ZM5.09983 10.1928C5.09983 10.1563 5.09983 10.1015 5.15459 10.065L5.84826 9.37136C6.85225 9.86423 8.05704 9.68169 8.87849 8.86024C9.69994 8.03879 9.88249 6.834 9.38962 5.83001L10.0833 5.13634C10.138 5.08157 10.1928 5.08157 10.2111 5.08157C10.2476 5.08157 10.3023 5.08157 10.3388 5.13634L12.3833 7.21734L7.21734 12.3833L5.15459 10.3206C5.09983 10.2658 5.09983 10.2111 5.09983 10.1928Z"
        fill="currentColor"
      />
      <path
        d="M13.765 11.1014L11.106 13.7604C10.8921 13.9743 10.8921 14.321 11.106 14.5349C11.3198 14.7487 11.6666 14.7487 11.8804 14.5349L14.5394 11.8759C14.7533 11.662 14.7533 11.3153 14.5394 11.1014C14.3256 10.8876 13.9788 10.8876 13.765 11.1014Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
