import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { Box, Button, Divider, Stack } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { CloseIcon } from '@components/icons';

import { DirectionPoints } from '@features/ag-forecast/components/DirectionPoints/DirectionPoints';
import { LayoutChanger } from '@features/ag-forecast/components/LayoutChanger/LayoutChanger';
import { VehicleFiltersInfra } from '@features/ag-forecast/components/VehicleFilters/VehicleFiltersInfra';
import { VehicleFiltersPassFlow } from '@features/ag-forecast/components/VehicleFilters/VehicleFiltersPassFlow';
import { EMapViewMode } from '@features/ag-forecast/constants/EMapViewMode';
import { FilterMapApi } from '@features/ag-forecast/stores/filters';
import { $UI } from '@features/ag-forecast/stores/ui';

const FiltersContainerMuiStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: '2',
  boxShadow: 'rgba(0, 0, 0, 0.1) 5px 4px 2px 0px',
  background: theme => theme.palette.background.default,
};

export const MapFiltersContainer = () => {
  const { filter, viewMode } = useUnit($UI);
  const { resetFilters } = useUnit(FilterMapApi);

  const onResetButtonClick = useCallback(() => {
    resetFilters();
  }, [resetFilters]);

  if (!filter) return null;

  return (
    <Box sx={FiltersContainerMuiStyle}>
      <Stack direction="column">
        <AutoFullHeight
          style={{
            borderTop: '1px solid #EBECEF',
            width: '500px',
            maxWidth: '500px',
            overflowY: 'auto',
          }}
          mb={77}
        >
          <Box
            style={{
              marginTop: 24,
              marginBottom: 24,
              paddingRight: 44,
              paddingLeft: 44,
            }}
          >
            <LayoutChanger />
          </Box>

          {viewMode === EMapViewMode.passFlow && <DirectionPoints />}

          <Divider />

          {viewMode === EMapViewMode.infrastructure ? (
            <VehicleFiltersInfra isShowCircles />
          ) : (
            <VehicleFiltersPassFlow />
          )}
        </AutoFullHeight>
        <Box
          pb="26px"
          pt={3}
          px={5.5}
          mt={'auto'}
          style={{
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Button
            variant={'text'}
            startIcon={<CloseIcon />}
            fullWidth
            onClick={onResetButtonClick}
          >
            Сбросить все
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
