import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { Box } from '@mui/material';

import { CustomChartTooltip } from '../CustomChartTooltip/CustomChartTooltip';
import { CustomCursor } from '../CustomCursor/CustomCursor';

const range = [
  '03-04',
  '04-05',
  '05-06',
  '06-07',
  '07-08',
  '08-09',
  '09-10',
  '10-11',
  '11-12',
  '12-13',
  '13-14',
  '14-15',
  '15-16',
  '16-17',
  '17-18',
  '18-19',
  '19-20',
  '20-21',
  '21-22',
  '22-23',
  '23-00',
  '00-01',
];

export interface CustomCursorStyle {
  color: string;
  opacity: number;
}

interface axisLabelSettings {
  dX?: number;
  dY?: number;
  unit?: string;
}

interface ScenarioBarChartProps {
  chartData: number[];
  color: string;
  width?: string;
  height?: number;
  customCursor?: CustomCursorStyle;
  yLabel?: string;
  yLabelSettings?: axisLabelSettings;
  xLabel?: string;
  xLabelSettings?: axisLabelSettings;
  valueFormatter?: (value: number) => string;
  valueFormatterForTooltip?: (value: number) => string;
  onClick?: () => void;
}

export const ScenarioBarChart = ({
  color,
  width,
  height = 300,
  chartData,
  customCursor,
  yLabel,
  yLabelSettings,
  xLabel,
  xLabelSettings,
  onClick,
  valueFormatter,
  valueFormatterForTooltip,
}: ScenarioBarChartProps) => {
  const data = range.map((item, index) => ({
    range: item,
    value: chartData[index],
    x: index,
  }));

  return (
    <Box
      height={height}
      width={width}
    >
      <ResponsiveContainer
        width="100%"
        height="98%"
      >
        <BarChart
          data={data}
          margin={{
            top: 13,
            left: 10,
            bottom: 13,
          }}
          style={{ cursor: 'pointer' }}
          onClick={onClick}
        >
          <CartesianGrid vertical={false} />

          <XAxis
            dataKey="range"
            hide
            xAxisId={0}
          />
          <XAxis
            padding={{ left: -2 }}
            dataKey="x"
            scale="band"
            xAxisId={1}
            tickMargin={100}
            stroke="#1E1E1E"
          />
          <XAxis
            tickLine={false}
            xAxisId={2}
            dataKey="range"
            axisLine={false}
            tickSize={0}
            interval={0}
            angle={-90}
            fontSize={12}
            stroke="#1E1E1E"
            dy={-5}
            dx={-5}
            label={{
              fontSize: '12px',
              weight: 400,
              value: xLabel,
              dx: xLabelSettings?.dX,
              dy: xLabelSettings?.dY,
            }}
          />

          <YAxis
            fontSize={12}
            stroke="#1E1E1E"
            tickFormatter={valueFormatter}
            label={{
              paddingRight: '10px',
              fontSize: '12px',
              weight: 400,
              value: yLabel,
              angle: -90,
              dx: yLabelSettings?.dX,
              dy: yLabelSettings?.dY,
            }}
          />

          <Tooltip
            content={
              <CustomChartTooltip
                unit={yLabelSettings?.unit}
                valueFormatter={valueFormatterForTooltip}
              />
            }
            cursor={customCursor ? <CustomCursor {...customCursor} /> : false}
          />

          <Bar
            dataKey="value"
            fill={color}
            barSize={5}
            radius={5}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
