import { useUnit } from 'effector-react';
import { useForm } from 'react-hook-form';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { Direction } from '@features/tpu-simulation-model/components/FormAttributes/Direction';
import { FormActions } from '@features/tpu-simulation-model/components/FormAttributes/FormActions';
import { Input } from '@features/tpu-simulation-model/components/FormAttributes/Input';
import { ObjectPosition } from '@features/tpu-simulation-model/components/FormAttributes/ObjectPosition';
import { ObjectSize } from '@features/tpu-simulation-model/components/FormAttributes/ObjectSize';
import { RadioList as Radio } from '@features/tpu-simulation-model/components/FormAttributes/Radio';
import { SelectOption as Select } from '@features/tpu-simulation-model/components/FormAttributes/Select';
import { TimeRange } from '@features/tpu-simulation-model/components/FormAttributes/TimeRange';
import { Title } from '@features/tpu-simulation-model/components/FormAttributes/Title';
import { useRoomsEntrancesExits } from '@features/tpu-simulation-model/hooks';
import { useFloors } from '@features/tpu-simulation-model/hooks/useFloors';
import { $ListOfTurnstiles } from '@features/tpu-simulation-model/store';
import {
  ITurnstile,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';
import { getAttributeOptions } from '@features/tpu-simulation-model/utils/getAttributeOptions';

interface FormProps extends ITurnstile {
  onUpdateAndClose: (arg: ObjectOfTheSimulationModel) => void;
  onDeleteAndClose: (arg: string) => void;
}

export const Turnstile = ({
  onUpdateAndClose,
  onDeleteAndClose,
  ...props
}: FormProps) => {
  const { handleSubmit, formState, control, setValue } = useForm<ITurnstile>({
    values: props,
  });
  const roomEntrancesExitsPlatformsOptions = useRoomsEntrancesExits();
  const listOfTurnstiles = useUnit($ListOfTurnstiles);
  const { selectedFloorIndex } = useFloors();

  const turnstileOptions = listOfTurnstiles.map(item => ({
    value: item.eid,
    label: item.eid,
  }));

  const onIdChange = (value: string) => {
    const selectedTurnstile = listOfTurnstiles.find(item => item.eid === value);
    if (selectedTurnstile) {
      setValue('openFrom', selectedTurnstile.openFrom);
      setValue('openUntil', selectedTurnstile.openUntil);
      setValue('throughput', selectedTurnstile.bandwidthPerHour);
    }
  };

  return (
    <Stack
      padding={3}
      gap={3}
      sx={{
        overflow: 'auto',
      }}
    >
      <Title title={'Турникет'} />
      <Select<ITurnstile>
        control={control}
        name={'turnstileId'}
        title={'Выберите id'}
        options={turnstileOptions}
        onChange={onIdChange}
      />
      <Input<ITurnstile>
        control={control}
        name={'name'}
        title={'Название объекта'}
        type={'string'}
      />
      <Box>
        <Typography>Направление прохода</Typography>
        <Select<ITurnstile>
          control={control}
          name={'firstRoom'}
          title={''}
          placeholder={'Помещение 1'}
          options={roomEntrancesExitsPlatformsOptions}
        />
        <Select<ITurnstile>
          control={control}
          name={'secondRoom'}
          title={''}
          placeholder={'Помещение 2'}
          options={roomEntrancesExitsPlatformsOptions}
        />
        <Direction<ITurnstile>
          control={control}
          name={'direction'}
          options={getAttributeOptions('direction')}
        />
      </Box>
      <Input<ITurnstile>
        control={control}
        name={'throughput'}
        title={'Пропускная способность в час в одну сторону, чел'}
        type={'number'}
      />
      <Input<ITurnstile>
        control={control}
        name={'failuresProbability'}
        title={'Вероятность сбоев, %'}
        type={'number'}
      />
      <Radio<ITurnstile>
        control={control}
        name={'status'}
        title={'Статус'}
        options={getAttributeOptions('status')}
      />
      <Radio<ITurnstile>
        control={control}
        name={'waitingZone'}
        title={'Зона ожидания'}
        options={getAttributeOptions('waitingZone')}
      />
      <TimeRange<ITurnstile>
        control={control}
        nameTime1={'openFrom'}
        nameTime2={'openUntil'}
      />
      <Divider />
      <ObjectSize
        width={props.width}
        height={props.height}
      />
      <ObjectPosition
        point={props.point}
        floor={selectedFloorIndex + 1}
      />
      <Divider />
      <FormActions
        onSave={handleSubmit(onUpdateAndClose)}
        onDelete={() => onDeleteAndClose(props?.uuid)}
        disabled={!formState.isValid}
      />
    </Stack>
  );
};
