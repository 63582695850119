import { Stroke, Style } from 'ol/style';

import { TileStyleProps } from '@utils/map/styles/types';
import { createActiveFeatureBorder } from '@utils/map/tools/createActiveFeatureBorder';
import { getLineWidth } from '@utils/map/tools/getLineWidth';

export const ptHighwayTileStyle = (props: TileStyleProps) => {
  const { isActive, map, resolution, feature } = props;
  const zoom = map.getView().getZoomForResolution(resolution) || 10;

  const isFederal = feature?.get('type') === 3;
  const width = isFederal ? 4 : 3;
  const lineWidth = getLineWidth(zoom, [
    10,
    width * 0.3,
    11,
    width * 0.4,
    12,
    width * 0.5,
    13,
    width * 0.6,
    14,
    width * 0.7,
    15,
    width * 0.8,
    16,
    width,
  ]);

  const styles = isActive ? [createActiveFeatureBorder(lineWidth - 1)] : [];

  styles.push(
    new Style({
      stroke: new Stroke({
        color: '#eb8042',
        width: lineWidth,
        lineCap: 'square',
      }),
    }),
  );
  return styles;
};
