import {
  CustomMap,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { LAYERS_Z_INDEX } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/layersZIndex';
import { FeatureLike } from 'ol/Feature';
import { Pixel } from 'ol/pixel';

export type GetFeatureAtPixelArgs = {
  map: CustomMap;
  pixel: Pixel;
  excludeLayouts?: string[];
  includeLayouts?: string[];
};

/** Получение приоритетной фичи рядом с кликом  */
export const getFeatureAtPixel = (args: GetFeatureAtPixelArgs) => {
  const { map, excludeLayouts = [], includeLayouts, pixel } = args;

  const allowedLayouts = includeLayouts || Object.values(EMapFeatureLayout);
  const clickedFeatures: FeatureLike[] = [];

  map.forEachFeatureAtPixel(
    pixel,
    (feature, layer) => {
      // Получить название слоя фичи
      const featureLayout = layer.getClassName();
      // Игнорировать слой без названия
      if (!featureLayout) return;
      // Игнорировать слой из списка исключенных
      if (excludeLayouts.includes(featureLayout)) return;
      // Игнорировать если слоя нет в списке разрешенных
      if (!allowedLayouts.includes(featureLayout)) return;
      clickedFeatures.push(feature);
    },
    { hitTolerance: 5 },
  );

  if (!clickedFeatures.length) return null;

  const primaryClickedFeature = sortFeaturesByZIndex(clickedFeatures)[0];

  return primaryClickedFeature;
};

// Сортировка фич по z индексу слоя
const sortFeaturesByZIndex = (clickedFeatures: FeatureLike[]) =>
  clickedFeatures.sort((a, b) => {
    const aZIndex =
      LAYERS_Z_INDEX[a.getProperties()[layerLayoutKey] as EMapFeatureLayout] ||
      0;
    const bZIndex =
      LAYERS_Z_INDEX[b.getProperties()[layerLayoutKey] as EMapFeatureLayout] ||
      0;
    return aZIndex > bZIndex ? -1 : aZIndex > bZIndex ? 1 : 0;
  });
