import {
  PassengerTypesHandlersContext,
  UsePassengerTypesHandlers,
} from '@features/tpu-passenger-types/hooks';
import { useContext } from 'react';

export const useContextPassengerTypesHandlers =
  (): UsePassengerTypesHandlers => {
    const context = useContext(PassengerTypesHandlersContext);
    return context;
  };
