import {
  IQuery,
  IQueryGetTransportsRegionStarTableArgs,
} from '@api/gql/ag-types';
import { createApi, createEffect, createEvent, createStore } from 'effector';

const initState: PassFlowTableContentStore = {
  rows: [],
};

export const $PassFlowTableContent =
  createStore<PassFlowTableContentStore>(initState);

export const PassFlowTableContentApi = createApi($PassFlowTableContent, {
  setRows: (state, payload: PassFlowTableContentRow[]) => ({
    ...state,
    rows: payload,
  }),
  clearRows: state => ({
    ...state,
    rows: [],
  }),
  reset: () => initState,
});

export const fetchPassFlowTableContent = createEvent();

export const fetchPassFlowTableContentFx = createEffect<
  IQueryGetTransportsRegionStarTableArgs,
  IQuery['getTransportsRegionStarTable']
>();

export type PassFlowTableContentRow = {
  fromName: string;
  toName: string;
  timePrivate: number;
  timePublic: number;
  stzPrivate: number;
  stzPublic: number;
  flowPrivate: number;
  flowPublic: number;
  percent: number;
};

export type PassFlowTableContentStore = {
  rows: PassFlowTableContentRow[];
};
