import { IWaysPlatformsType } from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

export const $ListOfWaysPlatforms = createStore<IWaysPlatformsType>({
  ways: [],
  platforms: [],
});

export const getWaysPlatformsFn = createEvent();

export const getWaysPlatformsByScenarioIdFx = createEffect<
  string,
  IWaysPlatformsType
>();

export const resetWaysPlatformsFn = createEvent();
