import { apiClient } from '@api/client';
import {
  IQuery,
  IQueryGetClosestInstancePointByPointArgs,
} from '@api/gql/types';
import { gql } from '@apollo/client';

export const queryGetClosestInstancePointByPoint = async (
  params: IQueryGetClosestInstancePointByPointArgs,
) => {
  const result = await apiClient.query<
    Pick<IQuery, 'getClosestInstancePointByPoint'>,
    IQueryGetClosestInstancePointByPointArgs
  >({
    query: gql`
      query QueryGetClosestInstancePointByPoint(
        $instanceId: UUID!
        $lat: Float!
        $lon: Float!
      ) {
        getClosestInstancePointByPoint(
          instanceId: $instanceId
          lat: $lat
          lon: $lon
        ) {
          point {
            lon
            lat
          }
        }
      }
    `,
    variables: {
      instanceId: params.instanceId,
      lat: params.lat,
      lon: params.lon,
    },
  });

  return result.data?.getClosestInstancePointByPoint;
};
