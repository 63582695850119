import { apiClient } from '@api/client';
import { IMutation, IMutationAddStationsToItemArgs } from '@api/gql/types';
import { gql } from '@apollo/client';

export const mutationAddStationToItem = async (
  params: IMutationAddStationsToItemArgs,
) => {
  const result = await apiClient.mutate<
    Pick<IMutation, 'addStationsToItem'>,
    IMutationAddStationsToItemArgs
  >({
    mutation: gql`
      mutation MutationAddStationsToItem(
        $graphItemId: UUID!
        $stationId: UUID
      ) {
        addStationsToItem(graphItemId: $graphItemId, stationId: $stationId) {
          instance {
            id
          }
        }
      }
    `,
    variables: {
      ...params,
    },
  });

  return result.data?.addStationsToItem;
};
