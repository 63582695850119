import { sample } from 'effector';

import {
  $ChartCommercialRevenue,
  $ChartCommercialSquare,
  TreemapItem,
  addUploadedDataForCommercialObjectsFn,
} from './chartsCommercialObjects.store';

import {
  $DataForCommercialObjects,
  $Plan,
  $Player,
  $TableCommercialSquare,
  DataForCommercialObjects,
  getSimulationDataBySimulationIdFx,
  resetDataForCommercialObjectsFn,
} from '@features/tpu-calculation-scenarios-viewing/stores';
import { generateCommercialSquareList } from '@features/tpu-calculation-scenarios-viewing/utils/createTableRows';
import {
  generateCommercialRevenueItems,
  generateCommercialSquareItems,
} from '@features/tpu-calculation-scenarios-viewing/utils/createTreemapItems';
import { parseDataForCommercialObjects } from '@features/tpu-calculation-scenarios-viewing/utils/сommercialObjectsParsers';

$DataForCommercialObjects
  .reset(resetDataForCommercialObjectsFn)
  .on(addUploadedDataForCommercialObjectsFn, (state, result) => {
    if (result) {
      return { ...state, ...result };
    }
  });

sample({
  clock: $DataForCommercialObjects,
  fn: data => {
    if (data) {
      const firstKey = Object.keys(data)[0];
      const firstItem = data[firstKey] as DataForCommercialObjects[];

      return generateCommercialSquareItems(firstItem);
    }

    return [];
  },
  target: $ChartCommercialSquare,
});

sample({
  clock: $Player.map(({ lastCalculatedSegment }) => lastCalculatedSegment),
  source: $DataForCommercialObjects,
  fn: (data, segment) => {
    if (segment > 0) {
      const currentItem = data[segment] as DataForCommercialObjects[];

      return generateCommercialRevenueItems(currentItem) as TreemapItem[];
    }

    return [];
  },
  target: $ChartCommercialRevenue,
});

sample({
  clock: $Player.map(({ lastCalculatedSegment }) => lastCalculatedSegment),
  source: $DataForCommercialObjects,
  fn: (data, segment) => {
    if (segment > 0) {
      const currentItem = data[segment] as DataForCommercialObjects[];

      return generateCommercialSquareList(currentItem);
    }

    return [];
  },
  target: $TableCommercialSquare,
});

sample({
  clock: getSimulationDataBySimulationIdFx.done,
  source: {
    currentData: $DataForCommercialObjects,
    planData: $Plan,
  },
  filter: (_, clockData) => !!clockData.result?.objectResults?.length,
  fn: (sourceData, clockData) =>
    parseDataForCommercialObjects(clockData.result, sourceData.planData),
  target: addUploadedDataForCommercialObjectsFn,
});
