import { sample } from 'effector';
import { FeatureLike } from 'ol/Feature';
import { boundingExtent } from 'ol/extent';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';

import {
  EMapFeatureLayout,
  EMapFeatureMetaKeys,
  EMapLayerMetaKeys,
  EMapMetaKeys,
} from '@constants/map';

import {
  $FilterMap,
  EFilterMapLayout,
} from '@features/pt-forecast-new/stores/filters';
import {
  clickMap,
  pipeMapControls,
} from '@features/pt-forecast-new/stores/map';
import {
  SelectedFeaturesApi,
  SelectedFeaturesStore,
} from '@features/pt-forecast-new/stores/map/selectedFeatures/store';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';

import {
  EPipeMapControls,
  PipeMapControls,
} from '@utils/map/hooks/useInitMapControls';
import { getFeatureAtPixel } from '@utils/map/tools/getFeatureAtPixel';

// Выделение геометрии на карте
sample({
  clock: clickMap,
  source: { UI: $UI, FilterMap: $FilterMap },
  filter: ({ UI }) =>
    !UI.isSetPoint && !UI.isDrawGraph && !UI.placeRailwayStation,
  fn: ({ FilterMap, UI }, event) => {
    const excludeLayouts = [EMapFeatureLayout.pointsAB];
    if (FilterMap.layout === EFilterMapLayout.PassengerFlows) {
      excludeLayouts.push(
        EMapFeatureLayout.highway,
        EMapFeatureLayout.railway,
        EMapFeatureLayout.areaBorder,
        EMapFeatureLayout.busStation,
        EMapFeatureLayout.railwayStation,
        EMapFeatureLayout.airport,
      );
    }
    if (FilterMap.layout === EFilterMapLayout.Infrastructure) {
      if (UI.editMode)
        excludeLayouts.push(
          EMapFeatureLayout.highway,
          EMapFeatureLayout.railway,
          EMapFeatureLayout.areaBorder,
          EMapFeatureLayout.busStation,
          EMapFeatureLayout.airport,
          EMapFeatureLayout.directionRailway,
          EMapFeatureLayout.directionHighway,
          EMapFeatureLayout.directionAirline,
        );
      else
        excludeLayouts.push(
          EMapFeatureLayout.directionRailway,
          EMapFeatureLayout.directionHighway,
          EMapFeatureLayout.directionAirline,
        );
    }

    const primaryClickedFeature = getFeatureAtPixel({
      map: event.map,
      pixel: event.pixel,
      excludeLayouts,
    });

    if (!primaryClickedFeature) return [];
    const prevSelectedFeatures =
      (event.map.get(EMapMetaKeys.selectedFeatures) as FeatureLike[]) || [];
    const selectedFeatureId =
      primaryClickedFeature.get(EMapFeatureMetaKeys.olId) ||
      primaryClickedFeature.get('id');

    if (!selectedFeatureId)
      throw new Error('Selected feature id is not defined');

    const isReselected = prevSelectedFeatures.find(
      feature => feature.get(EMapFeatureMetaKeys.olId) === selectedFeatureId,
    );
    if (isReselected) throw new Error('Reselected feature');
    const layout = primaryClickedFeature.get(EMapFeatureMetaKeys.layout);

    if (
      ![
        EMapFeatureLayout.directionHighway,
        EMapFeatureLayout.directionRailway,
      ].includes(layout)
    )
      return [
        {
          id: selectedFeatureId,
          layout,
        },
      ] as SelectedFeaturesStore[];

    let allFeatures: SelectedFeaturesStore[] = [];
    event.map.getLayers().forEach(layer => {
      if (layer.get(EMapLayerMetaKeys.layout) === layout) {
        if (!(layer instanceof VectorLayer)) return;
        const features = layer.getSource().getFeatures() as FeatureLike[];
        allFeatures = features.map(feature => ({
          id: feature.get(EMapFeatureMetaKeys.olId),
          layout,
        }));
      }
    });
    return allFeatures;
  },
  target: SelectedFeaturesApi.setSelectedFeatures,
});

// Если закрылся инспектор, сбросить выделенную фичу
sample({
  clock: UIApi.setVisibleInspector,
  filter: payload => !payload,
  fn: () => [],
  target: SelectedFeaturesApi.setSelectedFeatures,
});

// В режиме редактирования инфраструктуры, сбрасывать выделенные фичи
sample({
  clock: [
    UIApi.setEditMode,
    UIApi.setPlaceRailwayStations,
    UIApi.setDrawGraph,
    UIApi.togglePlaceRailwayStation,
  ],
  target: SelectedFeaturesApi.reset,
});

// Костыльная фокусировка на поинте, где через симметричное смещение указывается зум, нужно для того что бы сместить центрирование от края экрана
sample({
  clock: SelectedFeaturesApi.selectAndFocusFeature,
  filter: payload => !Array.isArray(payload.coords[0]),
  fn: payload => {
    const coords = payload.coords as number[];
    const extent = boundingExtent([
      fromLonLat([coords[0] + 0.2, coords[1]]),
      fromLonLat([coords[0] - 0.2, coords[1]]),
    ]);
    return {
      action: EPipeMapControls.fitExtent,
      payload: {
        extent: extent,
        padding: [200, 600, 200, 1100],
      },
    } as PipeMapControls;
  },
  target: pipeMapControls,
});

// Если координаты многомерные, сфокусировать карту на экстенте сложной геометрии
sample({
  clock: SelectedFeaturesApi.selectAndFocusFeature,
  filter: payload => Array.isArray(payload.coords[0]),
  fn: payload => {
    const extent = boundingExtent(
      (payload.coords as number[][]).map(coords => fromLonLat(coords)),
    );
    return {
      action: EPipeMapControls.fitExtent,
      payload: {
        extent: extent,
        padding: [200, 600, 200, 1100],
      },
    } as PipeMapControls;
  },
  target: pipeMapControls,
});
