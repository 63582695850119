import { sliderClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import { palette } from '@system/theme/palette';

export const MuiSlider: Components<Theme>['MuiSlider'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [`& .${sliderClasses.mark}`]: {
        height: '8px',
        backgroundColor: palette.common.white,
      },
      [`& .${sliderClasses.rail}`]: {
        backgroundColor: palette.background.default,
        height: '8px',
      },
      [`& .${sliderClasses.track}`]: {
        height: '8px',
        border: 'none',
      },
      [`& .${sliderClasses.thumb}`]: {
        boxShadow: 'inset 0 0 0px 6px ' + palette.secondary.main,
        backgroundColor: palette.common.white,
        ':hover': {
          boxShadow: 'inset 0 0 0px 6px ' + palette.secondary.dark,
        },
        [`&.${sliderClasses.focusVisible}`]: {
          boxShadow: 'inset 0 0 0px 6px ' + palette.secondary.dark,
        },
      },
      [`& .${sliderClasses.valueLabel}`]: {
        padding: theme.typography.pxToRem(12),
        borderRadius: theme.shape.borderRadius * 2,
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
        backgroundColor: palette.common.white,
        color: theme.palette.text.secondary,
        fontSize: theme.typography.pxToRem(12),
      },
    }),
    colorPrimary: {
      [`& .${sliderClasses.track}`]: {
        backgroundColor: palette.border.stroke,
      },
    },
    colorSecondary: {
      [`& .${sliderClasses.track}`]: {
        backgroundColor: palette.secondary.main,
      },
      [`& .${sliderClasses.rail}`]: {
        backgroundColor: palette.border.stroke,
      },
    },
  },
};
