import { sample } from 'effector';

import { resetRulerFn } from '@features/tpu-simulation-model/store';
import {
  $ModalResetRuler,
  resetModalResetRulerFn,
  setIsOpenModalResetRulerFn,
} from '@features/tpu-simulation-model/store/modal-reset-ruler/modal-reset-ruler.store';

$ModalResetRuler
  .reset(resetModalResetRulerFn)
  .on(setIsOpenModalResetRulerFn, (_, payload) => {
    return { isOpen: payload };
  });

sample({
  clock: resetRulerFn,
  fn: () => false,
  target: setIsOpenModalResetRulerFn,
});
