import { sample } from 'effector';
import { Feature } from 'ol';
import { LineString, MultiPoint } from 'ol/geom';

import { EMapFeatureLayout } from '@constants/map';

import {
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

import { recursivelyFromLonLat } from '@utils/geometry';
import {
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';

import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';
import { pipeCulvertsVectorLayer } from '@widgets/widget-soceco-indicators/stores/map';
import { SocEcoInfrastructureApi } from '@widgets/widget-soceco-indicators/stores/map/infrastructure';
import { LayersVisibleApi } from '@widgets/widget-soceco-indicators/stores/map/layersVisible';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload => Object.keys(payload).includes(SOCECO_TABLE_IDS.TABLE_3_4),
  fn: payload => {
    return {
      action: EPipeVectorLayer.setVisible,
      payload: payload[SOCECO_TABLE_IDS.TABLE_3_4],
    } as PipeVectorLayer;
  },
  target: pipeCulvertsVectorLayer,
});

// Размещение геометрии на слое
sample({
  clock: SocEcoInfrastructureApi.setFeatures,
  filter: payload => payload.type === SOCECO_TABLE_IDS.TABLE_3_4,
  fn: payload => {
    const features: Feature<LineString | MultiPoint>[] = [];

    payload.data.length &&
      payload.data.forEach(feature => {
        if (feature.geometry.line?.length)
          features.push(
            new Feature({
              geometry: new LineString(
                recursivelyFromLonLat(
                  feature.geometry.line!,
                ) as unknown as number[][],
              ),
              [layerIdKey]: feature.id,
              [layerLayoutKey]: EMapFeatureLayout.culverts,
            }),
          );
        if (feature.geometry.multipoint?.length)
          features.push(
            new Feature({
              geometry: new MultiPoint(
                recursivelyFromLonLat(
                  feature.geometry.multipoint!,
                ) as unknown as number[][],
              ),
              [layerIdKey]: feature.id,
              [layerLayoutKey]: EMapFeatureLayout.culverts,
            }),
          );
      });

    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: {
        features,
      },
    } as PipeVectorLayer;
  },
  target: pipeCulvertsVectorLayer,
});
