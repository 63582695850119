import { IRailwayType, Maybe } from '@api/gql/types';
import { useMemo } from 'react';

import { Box, Divider, IconButton, Typography } from '@mui/material';

import { ChevronRightIcon } from '@components/icons';

import { BoxData } from '@features/passenger-traffic/components/Inspector/BoxData';

import { getTractionTypeValue } from '@utils/dataForInspector/railways';

interface RailwayInspectorProps {
  onClose?(): void;
  fields: Maybe<IRailwayType>;
}

export const RailwayInspector = (props: RailwayInspectorProps) => {
  const { onClose, fields } = props;

  const tractionType = useMemo(
    () => getTractionTypeValue(fields?.tractionType) || 'н/д',
    [fields?.tractionType],
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 3,
        }}
      >
        <IconButton
          sx={{
            width: '32px',
            height: '32px',
            backgroundColor: 'primary.main',
            borderRadius: 1,
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={onClose}
        >
          <ChevronRightIcon />
        </IconButton>

        <Typography variant="subtitle2">ЖД линия</Typography>
      </Box>

      <BoxData
        name={'Название'}
        value={fields?.railwayName || 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Род тяги'}
        value={tractionType}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Количество главных путей'}
        value={fields?.tracks || 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Пропускная способность'}
        value={fields?.capacity ? fields?.capacity + ' ед./сут.' : 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Скоростной режим'}
        value={fields?.speed ? 'До ' + fields?.speed + ' км/ч' : 'н/д'}
      />
    </>
  );
};
