import { useUnit } from 'effector-react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Timer } from './Timer';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  buttonClasses,
} from '@mui/material';

import { DialogCloseButton } from '@components/DialogCloseButton';

import { $DownloadFile } from '@features/ag-projects-registry/store/downloadFile';

import { palette } from '@system/theme/palette';

interface ModalDownloadFileProps {
  onClose(): void;
}

export const ModalDownloadFile = (props: ModalDownloadFileProps) => {
  const { onClose } = props;
  const [disabled, setDisabled] = useState(false);
  const $downloadFile = useUnit($DownloadFile);
  const ref = useRef<HTMLAnchorElement>(null);

  const handleSubmit = useCallback(() => {
    onClose();
    // URL.revokeObjectURL($downloadFile.url);
  }, [onClose]);

  useEffect(() => {
    if ($downloadFile.url === '' || ref.current === null) return;

    ref.current.click();
    setDisabled(true);
  }, [$downloadFile.url]);

  return (
    <>
      <Dialog
        onClose={onClose}
        open={true}
        keepMounted={true}
        disablePortal
      >
        <DialogTitle
          variant="subtitle2"
          sx={{
            position: 'relative',
            fontSize: theme => theme.typography.pxToRem(16),
            pr: 10,
          }}
        >
          Скачать файл
        </DialogTitle>

        <DialogCloseButton onClick={onClose} />

        <DialogContent>
          <Stack rowGap={4}>
            <Typography>
              Если файл не начал скачиваться со временем, нажмите на кнопку
              скачать.
            </Typography>

            <Timer setDisabled={setDisabled} />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            sx={{
              [`&.${buttonClasses.disabled}`]: {
                color: theme => theme.palette.secondary.main,
                borderColor: theme => theme.palette.secondary.main,
              },
              color: theme => theme.palette.customs.link,
              borderColor: theme => theme.palette.customs.link,
              p: 0,
            }}
            variant="outlined"
            onClick={handleSubmit}
            disabled={disabled}
          >
            <a
              ref={ref}
              href={$downloadFile.url}
              download={$downloadFile.name || 'Безымянный'}
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: disabled ? palette.secondary.main : palette.customs.link,
              }}
            >
              Скачать
            </a>
          </Button>

          <Button onClick={onClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
