import { useUnit } from 'effector-react';

import { Box, CircularProgress } from '@mui/material';

import { $UI } from '@features/pt-forecast-new/stores/ui';

export const Loading = () => {
  const { isLoadingGeometry } = useUnit($UI);
  return (
    isLoadingGeometry && (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#00000066',
        }}
      >
        <CircularProgress
          sx={{
            color: theme => theme.palette.secondary.main,
          }}
        />
      </Box>
    )
  );
};
