import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';
import {
  getSocEcoTable_1_1Fx,
  getSocEcoTable_1_2Fx,
  getSocEcoTable_1_3Fx,
  getSocEcoTable_1_4Fx,
  getSocEcoTable_1_5Fx,
  getSocEcoTable_2_1Fx,
  getSocEcoTable_2_2Fx,
  getSocEcoTable_2_3Fx,
  getSocEcoTable_2_4Fx,
  getSocEcoTable_2_5Fx,
  getSocEcoTable_3_1Fx,
  getSocEcoTable_3_2Fx,
  getSocEcoTable_3_3Fx,
  getSocEcoTable_3_4Fx,
  getSocEcoTable_3_5Fx,
  getSocEcoTable_3_6Fx,
  getSocEcoTable_3_7Fx,
  getSocEcoTable_4_1Fx,
  getSocEcoTable_4_2Fx,
  getSocEcoTable_4_3Fx,
  getSocEcoTable_4_4Fx,
  getSocEcoTable_5_1Fx,
  getSocEcoTable_5_2Fx,
  getSocEcoTable_5_3Fx,
  getSocEcoTable_5_4Fx,
  getSocEcoTable_5_5Fx,
  getSocEcoTable_5_6Fx,
  getSocEcoTable_5_7Fx,
  getSocEcoTable_5_8Fx,
  getSocEcoTable_6_1Fx,
  getSocEcoTable_6_2Fx,
  getSocEcoTable_6_3Fx,
  getSocEcoTable_7_1Fx,
  getSocEcoTable_7_2Fx,
  getSocEcoTable_7_3Fx,
  getSocEcoTable_7_4Fx,
  getSocEcoTable_7_5Fx,
} from '@widgets/widget-soceco-indicators/stores/tables/effects';

/**
 * Выполнение запросов для получения данных таблиц по их id
 * @param tableId - id таблицы
 * @param scenarioId - id сценария
 * @param centerAGCoords - массив координаты центра агломерации
 */
export const getDataForSocEcoTable = (
  tableId: string,
  scenarioId: string,
  centerAGCoords: number[],
) => {
  switch (tableId) {
    case SOCECO_TABLE_IDS.TABLE_1_1:
      getSocEcoTable_1_1Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_1_2:
      getSocEcoTable_1_2Fx({ lon: centerAGCoords[1], lat: centerAGCoords[0] });
      break;
    case SOCECO_TABLE_IDS.TABLE_1_3:
      getSocEcoTable_1_3Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_1_4:
      getSocEcoTable_1_4Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_1_5:
      getSocEcoTable_1_5Fx({ scenarioId });
      break;

    case SOCECO_TABLE_IDS.TABLE_2_1:
      getSocEcoTable_2_1Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_2_2:
      getSocEcoTable_2_2Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_2_3:
      getSocEcoTable_2_3Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_2_4:
      getSocEcoTable_2_4Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_2_5:
      getSocEcoTable_2_5Fx({ scenarioId });
      break;

    case SOCECO_TABLE_IDS.TABLE_3_1:
      getSocEcoTable_3_1Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_3_2:
      getSocEcoTable_3_2Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_3_3:
      getSocEcoTable_3_3Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_3_4:
      getSocEcoTable_3_4Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_3_5:
      getSocEcoTable_3_5Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_3_6:
      getSocEcoTable_3_6Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_3_7:
      getSocEcoTable_3_7Fx({ scenarioId });
      break;

    case SOCECO_TABLE_IDS.TABLE_4_1:
      getSocEcoTable_4_1Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_4_2:
      getSocEcoTable_4_2Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_4_3:
      getSocEcoTable_4_3Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_4_4:
      getSocEcoTable_4_4Fx({ scenarioId });
      break;

    case SOCECO_TABLE_IDS.TABLE_5_1:
      getSocEcoTable_5_1Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_5_2:
      getSocEcoTable_5_2Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_5_3:
      getSocEcoTable_5_3Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_5_4:
      getSocEcoTable_5_4Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_5_5:
      getSocEcoTable_5_5Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_5_6:
      getSocEcoTable_5_6Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_5_7:
      getSocEcoTable_5_7Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_5_8:
      getSocEcoTable_5_8Fx({ scenarioId });
      break;

    case SOCECO_TABLE_IDS.TABLE_6_1:
      getSocEcoTable_6_1Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_6_2:
      getSocEcoTable_6_2Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_6_3:
      getSocEcoTable_6_3Fx({ scenarioId });
      break;

    case SOCECO_TABLE_IDS.TABLE_7_1:
      getSocEcoTable_7_1Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_7_2:
      getSocEcoTable_7_2Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_7_3:
      getSocEcoTable_7_3Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_7_4:
      getSocEcoTable_7_4Fx({ scenarioId });
      break;
    case SOCECO_TABLE_IDS.TABLE_7_5:
      getSocEcoTable_7_5Fx({ scenarioId });
      break;
  }
};
