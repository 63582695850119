import { ModalPlanErrors } from '@features/tpu-simulation-model/components/ModalPlanErrors/ModalPlanErrors';
import { ModalResetRuler } from '@features/tpu-simulation-model/components/modal-reset-ruler';

export const Modals = () => {
  return (
    <>
      <ModalPlanErrors />
      <ModalResetRuler />
    </>
  );
};
