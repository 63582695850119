import { useUnit } from 'effector-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Divider, Stack } from '@mui/material';

import { ROUTES } from '@constants/routes';

import { SearchInput } from '@features/ag-project/containers/ToolBarContainer/SearchInput';
import { $FeatureSettings } from '@features/ag-project/stores/settings';
import { UIApi } from '@features/ag-project/stores/ui';

export const ToolBarContainer = () => {
  const { toggleFilter, setShowFrame } = useUnit(UIApi);
  const { regionUUID, projectUUID } = useUnit($FeatureSettings);

  const navigate = useNavigate();

  const handleClickBuilder = useCallback(() => {
    navigate(`${ROUTES.AGGLOMERATION_CONSTRUCTOR}?projectId=${projectUUID}`);
  }, [navigate, projectUUID]);

  const handleClickRegistry = useCallback(() => {
    navigate(ROUTES.AGGLOMERATION);
  }, [navigate]);

  const handleClickTRLinksShow = useCallback(() => {
    setShowFrame(true);
  }, [setShowFrame]);

  return (
    <>
      <Stack
        alignItems={'center'}
        py={2}
        bgcolor={'common.white'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        px={5.5}
      >
        <Stack flexDirection={'row'}>
          <Stack
            width={'calc(500px - 44px)'}
            pr={5.5}
          >
            <Button
              fullWidth
              color={'primary'}
              variant={'contained'}
              onClick={toggleFilter}
            >
              Транспортные слои
            </Button>
          </Stack>
          <Divider
            sx={{ borderColor: '#EBECEF', mr: 5.5 }}
            orientation={'vertical'}
            flexItem
          />
          <SearchInput />
          {regionUUID && (
            <Button
              sx={{
                ml: 4,
              }}
              onClick={handleClickTRLinksShow}
            >
              Связь с другими территориями
            </Button>
          )}
        </Stack>

        <Box
          sx={{
            display: 'flex',
            columnGap: 1,
          }}
        >
          <Button
            variant={'contained'}
            onClick={handleClickRegistry}
          >
            В реестр проектов
          </Button>

          <Button
            color={'secondary'}
            variant={'contained'}
            onClick={handleClickBuilder}
          >
            Конструктор прогнозов
          </Button>
        </Box>
      </Stack>
    </>
  );
};
