import { createEvent, createStore } from 'effector';

interface $UIDataType {
  isOpenDefaultHUBInfo: boolean;
}

export const $UI = createStore<$UIDataType>({
  isOpenDefaultHUBInfo: true,
});

export const setIsOpenDefaultHUBInfoFn = createEvent<boolean>();

export const resetUIFn = createEvent();
