import { Box, Tooltip, TooltipProps, Typography, styled } from '@mui/material';

import { EyeIcon } from '@components/icons';

const StyledTooltip = styled(({ children, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: props.className }}
  >
    {children}
  </Tooltip>
))(({ theme }) => ({
  marginRight: '8px',

  [`& .MuiTooltip-tooltip`]: {
    boxShadow: '0px 2px 4px 0px #0000001A',
    borderRadius: '8px',
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    backgroundColor: theme.palette.background.paper,
  },
}));

export const Accesses = () => {
  return (
    <StyledTooltip
      placement={'top'}
      title={
        <>
          <Typography
            sx={{
              color: 'text.secondary',
              fontSize: theme => theme.typography.pxToRem(12),
              lineHeight: theme => theme.typography.pxToRem(16),
            }}
          >
            Доступ открыт для
          </Typography>
          <Typography
            variant={'body1'}
            sx={{
              color: 'text.primary',
              fontSize: theme => theme.typography.pxToRem(12),
              lineHeight: theme => theme.typography.pxToRem(16),
            }}
          >
            Марина Петровна
          </Typography>
        </>
      }
    >
      <Box>
        <EyeIcon />
      </Box>
    </StyledTooltip>
  );
};
