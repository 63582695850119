import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { ModalConfirmCancelEditTpu } from '@features/ag-forecast/components/Modals/ModalConfirmCancelEditTpu';
import { ModalPassFlow } from '@features/ag-forecast/components/Modals/ModalPassFlow';
import { $Modals, ModalsApi } from '@features/ag-forecast/stores/modals';

export const ModalsContainer = () => {
  const { confirmCancelEditTpu, passFlow } = useUnit($Modals);
  const { setModalView } = useUnit(ModalsApi);

  const handleCloseModalPassFlow = useCallback(() => {
    setModalView({ passFlow: false });
  }, [setModalView]);

  const handleCloseModalConfirmCancelEditTpu = useCallback(() => {
    setModalView({ confirmCancelEditTpu: false });
  }, [setModalView]);

  return (
    <>
      {passFlow && <ModalPassFlow onClose={handleCloseModalPassFlow} />}

      {confirmCancelEditTpu && (
        <ModalConfirmCancelEditTpu
          onClose={handleCloseModalConfirmCancelEditTpu}
        />
      )}
    </>
  );
};
