import { $UI } from '@features/ag-forecast/stores/ui';
import {
  $YearLineStore,
  YearLineApi,
} from '@features/ag-forecast/stores/yearLine/store';
import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { SxProps } from '@mui/system';

import { YearLineV2 } from '@components/YearLine_v2';

export const YearLineContainer = () => {
  const { filter, inspector } = useUnit($UI);
  const { minYear, maxYear, selectedYear } = useUnit($YearLineStore);
  const { setSelectedYear } = YearLineApi;

  const ContainerSx = useMemo<SxProps<Theme>>(
    () => ({
      position: 'absolute',
      bottom: '0',
      left: '500px',
      right: inspector ? '500px' : '0',
      zIndex: '1',
      background: theme => theme.palette.background.default,
    }),
    [inspector],
  );

  const selectYearHandler = useCallback(
    (year: number) => {
      setSelectedYear(year);
    },
    [setSelectedYear],
  );

  return (
    <Box sx={ContainerSx}>
      {filter && (
        <YearLineV2
          baseYear={minYear}
          finalYear={maxYear}
          selectedYear={selectedYear}
          onSelect={selectYearHandler}
        />
      )}
    </Box>
  );
};
