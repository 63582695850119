import { apiClient } from '@api/client';
import { IQuery, IQueryGetScenarioByIdArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

export const queryDataDashboardP2 = async (
  scenario: IQueryGetScenarioByIdArgs,
) => {
  const result = await apiClient.query<
    Pick<IQuery, 'getScenarioById'>,
    IQueryGetScenarioByIdArgs
  >({
    query: gql`
      query QueryDataDashboardP2($scenarioId: String!) {
        getScenarioById(scenarioId: $scenarioId) {
          scenarioTpusFlow {
            year
            scenarioTpus {
              mainFlow
              mainStz {
                stzAvgPassTraffic
                stzAvgPopulation
              }
              tpu {
                name
              }
              alternative {
                flow
                stz {
                  stzAvgPassTraffic
                  stzAvgPopulation
                }
                alternateTpu {
                  name
                }
              }
            }
          }
        }
      }
    `,
    variables: scenario,
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.getScenarioById;
};
