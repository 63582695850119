import {
  EcoParam,
  SocEcoScenarioParams,
  ecoParams,
} from '@features/pt-constructor/constants/ecoParams';
import {
  baseEcoParamValue,
  maxEcoParamValue,
  minEcoParamValue,
} from '@features/pt-constructor/store';
import { useCallback, useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';

import { FormLabel } from '@components/FormLabel';
import { NumberField } from '@components/NumberField';

import { SliderSocioEconomic } from '../../sliderSocioEconomic';

export const TwoStep = ({
  onChangeEcoParam,
  ecoScenarioName,
  socEcoParams,
  baseParams,
}: Props) => {
  const [ecoValues, setEcoValues] =
    useState<SocEcoScenarioParams>(socEcoParams);

  const applyParam = useCallback(
    (param: EcoParam, value: number | string | undefined) => {
      if (Number(value) > maxEcoParamValue) value = maxEcoParamValue;
      if (Number(value) < minEcoParamValue) value = minEcoParamValue;

      setEcoValues(prev => ({ ...prev, [param.name]: value }));
    },
    [],
  );

  const onChangeParamValue = useCallback(
    (param: EcoParam) => (value: number | string | undefined) => {
      applyParam(param, value);
    },
    [applyParam],
  );

  const getValueSlider = useCallback(
    (param: EcoParam) => (value: number) => {
      applyParam(param, value);
    },
    [applyParam],
  );

  useEffect(() => {
    onChangeEcoParam(ecoValues);
  }, [ecoValues, onChangeEcoParam]);

  return (
    <>
      <Typography variant="subtitle2">{ecoScenarioName}</Typography>

      <Grid
        container
        display={'flex'}
        direction={'column'}
        gap={3}
        mt={3}
      >
        {ecoParams.map(param => (
          <FormLabel
            title={param.title}
            id={param.name}
            key={param.name}
          >
            <Grid
              display={'grid'}
              gridTemplateColumns={'repeat(2, 1fr)'}
              alignItems={'center'}
              gap={2}
              mt={0.5}
            >
              <NumberField
                value={ecoValues[param.name] ?? baseEcoParamValue}
                getValue={onChangeParamValue(param)}
                placeholder={param.title}
              />

              <SliderSocioEconomic
                value={ecoValues[param.name] ?? baseEcoParamValue}
                base={baseParams[param.name] ?? 0}
                getValue={getValueSlider(param)}
              />
            </Grid>
          </FormLabel>
        ))}
      </Grid>
    </>
  );
};

interface Props {
  ecoScenarioName: string;
  socEcoParams: SocEcoScenarioParams;
  baseParams: SocEcoScenarioParams;
  onChangeEcoParam: (params: SocEcoScenarioParams) => void;
}
