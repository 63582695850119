import { SvgIcon, SvgIconProps } from '@mui/material';

export const FloorsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.6819 14.9487L17.2571 13.7363L11.7583 16.4858C11.6782 16.5259 11.5899 16.5468 11.5004 16.5468C11.4108 16.5468 11.3225 16.5259 11.2425 16.4858L5.74363 13.7363L3.31889 14.9487C3.22309 14.9964 3.1425 15.07 3.08617 15.161C3.02984 15.252 3 15.3569 3 15.4639C3 15.571 3.02984 15.6759 3.08617 15.7669C3.1425 15.8579 3.22309 15.9314 3.31889 15.9792L11.2425 19.9398C11.3225 19.9799 11.4108 20.0008 11.5004 20.0008C11.5899 20.0008 11.6782 19.9799 11.7583 19.9398L19.6819 15.978C19.7774 15.9301 19.8577 15.8567 19.9138 15.7658C19.9699 15.6749 19.9996 15.5702 19.9996 15.4634C19.9996 15.3565 19.9699 15.2518 19.9138 15.1609C19.8577 15.0701 19.7774 14.9966 19.6819 14.9487Z"
          stroke="#273B48"
        />
        <path
          d="M19.6819 8.02164L11.7583 4.061C11.6782 4.02089 11.5899 4 11.5004 4C11.4108 4 11.3225 4.02089 11.2425 4.061L3.31889 8.02279C3.22309 8.07053 3.1425 8.14405 3.08617 8.23506C3.02984 8.32608 3 8.43098 3 8.53802C3 8.64506 3.02984 8.74998 3.08617 8.841C3.1425 8.93201 3.22309 9.00551 3.31889 9.05325L11.2425 13.0139C11.3225 13.054 11.4108 13.0749 11.5004 13.0749C11.5899 13.0749 11.6782 13.054 11.7583 13.0139L19.6819 9.0521C19.7777 9.00436 19.8583 8.93084 19.9146 8.83985C19.9709 8.74883 20.0008 8.64391 20.0008 8.53687C20.0008 8.42983 19.9709 8.32493 19.9146 8.23391C19.8583 8.1429 19.7777 8.06938 19.6819 8.02164Z"
          stroke="#273B48"
        />
        <path
          d="M19.6819 11.4858L17.2571 10.2734L11.7583 13.0229C11.6782 13.063 11.5899 13.0839 11.5004 13.0839C11.4108 13.0839 11.3225 13.063 11.2425 13.0229L5.74363 10.2734L3.31889 11.4858C3.22309 11.5336 3.1425 11.6071 3.08617 11.6981C3.02984 11.7891 3 11.894 3 12.001C3 12.1081 3.02984 12.213 3.08617 12.304C3.1425 12.395 3.22309 12.4685 3.31889 12.5163L11.2425 16.4769C11.3225 16.517 11.4108 16.5379 11.5004 16.5379C11.5899 16.5379 11.6782 16.517 11.7583 16.4769L19.6819 12.5151C19.7774 12.4673 19.8577 12.3938 19.9138 12.3029C19.9699 12.212 19.9996 12.1073 19.9996 12.0005C19.9996 11.8937 19.9699 11.7889 19.9138 11.698C19.8577 11.6072 19.7774 11.5337 19.6819 11.4858Z"
          stroke="#273B48"
        />
      </svg>
    </SvgIcon>
  );
};
