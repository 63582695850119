import { useUnit } from 'effector-react';

import { Box, Button, Typography } from '@mui/material';

import { ChevronRightIcon } from '@components/icons';

import { useContextSimulationModelHandlers } from '@features/tpu-simulation-model/hooks';
import { $EditedObject, $UI } from '@features/tpu-simulation-model/store';
import { $Project } from '@features/tpu-simulation-model/store/project/project.store';
import { ActionBeforeOpenErrorsWindow } from '@features/tpu-simulation-model/types';

export const HubInfoContainer = () => {
  const $ui = useUnit($UI);
  const $editedObject = useUnit($EditedObject);
  const $project = useUnit($Project);
  const { closeDefaultHubInfo, triggerFindErrors, openPlanErrorsWindow } =
    useContextSimulationModelHandlers();

  const openErrorsModal = (action: ActionBeforeOpenErrorsWindow) => () => {
    triggerFindErrors({ saveAfterAnalysis: true });
    openPlanErrorsWindow(action);
  };

  if (!$ui.isOpenDefaultHUBInfo || $editedObject.data) return null;

  return (
    <Box
      sx={{
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          variant={'contained'}
          sx={{
            padding: '4px',
            mr: '12px',
            minWidth: 'unset',
            minHeight: 'unset',
            height: 'unset',
          }}
          onClick={closeDefaultHubInfo}
        >
          <ChevronRightIcon />
        </Button>
        <Typography
          variant={'body1'}
          fontWeight={'bold'}
        >
          {$project.hub?.name ?? ''}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant={'body2'}
          fontWeight={'bold'}
          sx={{
            mb: '4px',
          }}
        >
          Код ЕСР
        </Typography>
        <Typography
          variant={'body2'}
          sx={{
            color: theme => theme.palette.text.secondary,
          }}
        >
          {$project.hub?.code ?? ''}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant={'body2'}
          fontWeight={'bold'}
          sx={{
            mb: '4px',
          }}
        >
          Код АСУ ЭКСПРЕСС
        </Typography>
        <Typography
          variant={'body2'}
          sx={{
            color: theme => theme.palette.text.secondary,
          }}
        >
          {$project.codeAsuExpress}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant={'body2'}
          fontWeight={'bold'}
          sx={{
            mb: '4px',
          }}
        >
          Адрес
        </Typography>
        <Typography
          variant={'body2'}
          sx={{
            color: theme => theme.palette.text.secondary,
          }}
        >
          {$project.address}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant={'body2'}
          fontWeight={'bold'}
          sx={{
            mb: '4px',
          }}
        >
          Платформы дальнего сообщения
        </Typography>
        <Typography
          variant={'body2'}
          sx={{
            color: theme => theme.palette.text.secondary,
          }}
        >
          {$project.platform.long}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant={'body2'}
          fontWeight={'bold'}
          sx={{
            mb: '4px',
          }}
        >
          Платформы пригородного сообщения
        </Typography>
        <Typography
          variant={'body2'}
          sx={{
            color: theme => theme.palette.text.secondary,
          }}
        >
          {$project.platform.short}
        </Typography>
      </Box>
      <Button
        variant={'outlined'}
        onClick={openErrorsModal(
          ActionBeforeOpenErrorsWindow.GoToTrainSchedule,
        )}
      >
        Расписание поездов
      </Button>
    </Box>
  );
};
