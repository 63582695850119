import { apiClient } from '@api/client';
import {
  IQuery,
  IQueryGetComputedDirectionArgs,
  IQueryGetTransportRegionByIdArgs,
} from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import {
  $FilterMap,
  FilterMapApi,
} from '@features/pt-forecast-new/stores/filters';
import {
  $VectorGeometry,
  ExtendedMultiLineStringGeometryItem,
  ExtendedPointGeometryItem,
  IQueryGetTransportRegionByIdExtendedArgs,
  MultiPolygonGeometryItem,
  PointGeometryItem,
  VectorGeometryApi,
  VectorGeometryEvents,
  VectorGeometryStore,
  getAirportsFx,
  getBusStationsFx,
  getComputedDirectionHighwayFx,
  getComputedDirectionRailwayFx,
  getComputedDirectionRailwayStationsIntersectionFx,
  getComputedDirectionTransportRegionsIntersectionFx,
  getRailwayStationsFx,
  getTransportRegionByIdCenterFx,
  getTransportRegionByIdIncludedGeometryFx,
  getTransportRegionsFx,
} from '@features/pt-forecast-new/stores/map/vectorFeatures/store';
import { $FeatureSettings } from '@features/pt-forecast-new/stores/settings';
import {
  $YearLine,
  YearLineApi,
} from '@features/pt-forecast-new/stores/yearLine';

import { pointDirection } from '@utils/map/styles/agTransportFlowDistrictsVectorStyle';

// Запрос на получение всех аэропортов (если требуется инъекция аргументов в запрос)
sample({
  clock: VectorGeometryEvents.fetchAirports,
  target: getAirportsFx,
});

getAirportsFx.use(async () => {
  const response = await apiClient.query<Pick<IQuery, 'airport'>>({
    query: gql`
      query QueryAirport {
        airport {
          id
          geometry
        }
      }
    `,
  });
  return response.data.airport || null;
});

// Сеттинг результатов запроса в стор
sample({
  clock: getAirportsFx.done,
  fn: request => {
    return request.result?.map(airport => ({
      id: airport?.id || '',
      coordinates: airport?.geometry?.coordinates || [],
    })) as PointGeometryItem[];
  },
  target: VectorGeometryApi.setAllAirports,
});

// Запрос на получение всех автобусных остановок (если требуется инъекция аргументов в запрос)
sample({
  clock: VectorGeometryEvents.fetchBusStations,
  target: getBusStationsFx,
});

getBusStationsFx.use(async () => {
  const result = await apiClient.query<Pick<IQuery, 'busStation'>>({
    query: gql`
      query QueryBusStation {
        busStation {
          id
          geometry
        }
      }
    `,
  });
  return result.data.busStation || null;
});

// Сеттинг результатов запроса в стор
sample({
  clock: getBusStationsFx.done,
  fn: request => {
    return request.result?.map(busStation => ({
      id: busStation?.id || '',
      coordinates: busStation?.geometry?.coordinates || [],
    })) as PointGeometryItem[];
  },
  target: VectorGeometryApi.setBusStations,
});

// Запрос на получение всех жд станций (если требуется инъекция аргументов в запрос)
sample({
  clock: VectorGeometryEvents.fetchRailwayStations,
  target: getRailwayStationsFx,
});

getRailwayStationsFx.use(async () => {
  const result = await apiClient.query<Pick<IQuery, 'railwayStation'>>({
    query: gql`
      query QueryRailwayStation {
        railwayStation {
          id
          geometry
        }
      }
    `,
  });
  return result.data.railwayStation || null;
});

// Сеттинг результатов запроса в стор
sample({
  clock: getRailwayStationsFx.done,
  fn: request => {
    return request.result?.map(railwayStation => ({
      id: railwayStation?.id || '',
      coordinates: railwayStation?.geometry?.coordinates || [],
    })) as PointGeometryItem[];
  },
  target: VectorGeometryApi.setRailwayStations,
});

// Запрос на получение всех транспортных регионов (если требуется инъекция аргументов в запрос)
sample({
  clock: VectorGeometryEvents.fetchTransportRegions,
  target: getTransportRegionsFx,
});

getTransportRegionsFx.use(async () => {
  const result = await apiClient.query<Pick<IQuery, 'transportRegion'>>({
    query: gql`
      query QueryTransportRegion {
        transportRegion {
          id
          border {
            geometry
            id
          }
        }
      }
    `,
  });
  return result.data.transportRegion || null;
});

// Сеттинг результатов запроса в стор
sample({
  clock: getTransportRegionsFx.done,
  fn: request => {
    return request.result?.map(transportRegion => ({
      id: transportRegion?.id || '',
      coordinates: transportRegion?.border?.geometry?.coordinates || [],
    })) as MultiPolygonGeometryItem[];
  },
  target: VectorGeometryApi.setTransportRegions,
});

// Если в инпуте выбран регион отправления запросить координаты для поинта
sample({
  clock: FilterMapApi.setRegionFrom,
  filter: payload => !!payload,
  fn: payload => {
    return {
      id: payload!.id,
      direction: pointDirection.from,
    } as IQueryGetTransportRegionByIdExtendedArgs;
  },
  target: getTransportRegionByIdCenterFx,
});

// Если в инпуте выбран регион прибытия запросить координаты для поинта
sample({
  clock: FilterMapApi.setRegionTo,
  filter: payload => !!payload,
  fn: payload => {
    return {
      id: payload!.id,
      direction: pointDirection.to,
    } as IQueryGetTransportRegionByIdExtendedArgs;
  },
  target: getTransportRegionByIdCenterFx,
});

// Если в каком-то или боих инпутах выбранных регионов отсутствует значение - удалить геометрию
sample({
  clock: [
    FilterMapApi.setRegionFrom,
    FilterMapApi.setRegionTo,
    FilterMapApi.clearRegions,
    FilterMapApi.reset,
  ],
  source: { FilterMap: $FilterMap },
  filter: ({ FilterMap }) =>
    !FilterMap.toSelectedRegion || !FilterMap.fromSelectedRegion,
  fn: ({ FilterMap }) => {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!', FilterMap);

    const payload: Partial<VectorGeometryStore['directionPoints']> = {};
    if (!FilterMap.fromSelectedRegion) payload.from = null;
    if (!FilterMap.toSelectedRegion) payload.to = null;
    return payload;
  },
  target: VectorGeometryApi.setDirectionPoints,
});

getTransportRegionByIdCenterFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getTransportRegionById'>,
    IQueryGetTransportRegionByIdArgs
  >({
    query: gql`
      query QueryGetTransportRegionByIdCenter($id: UUID) {
        getTransportRegionById(id: $id) {
          center {
            transportRegion {
              id
            }
            geometry
          }
        }
      }
    `,
    variables: {
      id: params.id,
    },
  });
  return result.data.getTransportRegionById || null;
});

// запись геометрии поинта для выбранного региона
sample({
  clock: getTransportRegionByIdCenterFx.done,
  fn: request => {
    if (request.params.direction === pointDirection.from)
      return {
        from: {
          id: request.result?.center?.transportRegion?.id || '',
          coordinates: request.result?.center?.geometry?.coordinates || [],
        },
      } as Partial<VectorGeometryStore['directionPoints']>;
    else
      return {
        to: {
          id: request.result?.center?.transportRegion?.id || '',
          coordinates: request.result?.center?.geometry?.coordinates || [],
        },
      } as Partial<VectorGeometryStore['directionPoints']>;
  },
  target: VectorGeometryApi.setDirectionPoints,
});

// После установки поинта запросить всю геометрию транспортного региона (с целью оптимизации)
sample({
  clock: getTransportRegionByIdCenterFx.done,
  fn: request => request.params,
  target: getTransportRegionByIdIncludedGeometryFx,
});

getTransportRegionByIdIncludedGeometryFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getTransportRegionById'>,
    IQueryGetTransportRegionByIdArgs
  >({
    query: gql`
      query QueryGetTransportRegionByIdIncludedGeometry($id: UUID) {
        getTransportRegionById(id: $id) {
          id
          border {
            geometry
          }
          #          railwaystationSet {
          #            id
          #            geometry
          #            details {
          #              year
          #            }
          #          }
          #          busstationSet {
          #            id
          #            geometry
          #            details {
          #              year
          #            }
          #          }
          airportSet {
            id
            geometry
            details {
              year
            }
          }
        }
      }
    `,
    variables: {
      id: params.id,
    },
  });
  return result.data.getTransportRegionById || null;
});

// Сеттим геометрию транспортного региона отправления
sample({
  clock: getTransportRegionByIdIncludedGeometryFx.done,
  filter: request => request.params.direction === pointDirection.from,
  fn: request => {
    return {
      id: request.result?.id,
      coordinates: request.result?.border?.geometry?.coordinates || [],
    } as MultiPolygonGeometryItem;
  },
  target: VectorGeometryApi.setDirectionPassFlowRegionsFrom,
});

// Сеттим геометрию транспортного региона прибытия
sample({
  clock: getTransportRegionByIdIncludedGeometryFx.done,
  filter: request => request.params.direction === pointDirection.to,
  fn: request => {
    return {
      id: request.result?.id,
      coordinates: request.result?.border?.geometry?.coordinates || [],
    } as MultiPolygonGeometryItem;
  },
  target: VectorGeometryApi.setDirectionPassFlowRegionsTo,
});

// // Сеттим геометрию жд станций района отправления
// sample({
//   clock: getTransportRegionByIdIncludedGeometryFx.done,
//   filter: request => request.params.direction === pointDirection.from,
//   fn: request => {
//     return request.result?.railwaystationSet?.map(item => ({
//       id: item.id,
//       coordinates: item.geometry.coordinates,
//     })) as PointGeometryItem[];
//   },
//   target: VectorGeometryApi.setDirectionPassFlowRailwayStationsFrom,
// });

// // Сеттим геометрию жд станций района прибытия
// sample({
//   clock: getTransportRegionByIdIncludedGeometryFx.done,
//   filter: request => request.params.direction === pointDirection.to,
//   fn: request => {
//     return request.result?.railwaystationSet?.map(item => ({
//       id: item.id,
//       coordinates: item.geometry.coordinates,
//     })) as PointGeometryItem[];
//   },
//   target: VectorGeometryApi.setDirectionPassFlowRailwayStationsTo,
// });

// // Сеттим геометрию автобусных остановок района отправления
// sample({
//   clock: getTransportRegionByIdIncludedGeometryFx.done,
//   filter: request => request.params.direction === pointDirection.from,
//   fn: request => {
//     return request.result?.busstationSet?.map(item => ({
//       id: item.id,
//       coordinates: item.geometry.coordinates,
//       year: item?.details?.[0]?.year || 0,
//     })) as ExtendedPointGeometryItem[];
//   },
//   target: VectorGeometryApi.setDirectionPassFlowBusStationsFrom,
// });

// // Сеттим геометрию автобусных остановок района прибытия
// sample({
//   clock: getTransportRegionByIdIncludedGeometryFx.done,
//   filter: request => request.params.direction === pointDirection.to,
//   fn: request => {
//     return request.result?.busstationSet?.map(item => ({
//       id: item.id,
//       coordinates: item.geometry.coordinates,
//       year: item?.details?.[0]?.year || 0,
//     })) as ExtendedPointGeometryItem[];
//   },
//   target: VectorGeometryApi.setDirectionPassFlowBusStationsTo,
// });

// Сеттим геометрию аэропортов района отправления
sample({
  clock: getTransportRegionByIdIncludedGeometryFx.done,
  filter: request => request.params.direction === pointDirection.from,
  fn: request => {
    return request.result?.airportSet?.map(item => ({
      id: item.id,
      coordinates: item.geometry.coordinates,
      year: item?.details?.[0]?.year || 0,
    })) as ExtendedPointGeometryItem[];
  },
  target: VectorGeometryApi.setDirectionPassFlowAirportsFrom,
});

// Сеттим геометрию аэропортов района прибытия
sample({
  clock: getTransportRegionByIdIncludedGeometryFx.done,
  filter: request => request.params.direction === pointDirection.to,
  fn: request => {
    return request.result?.airportSet?.map(item => ({
      id: item.id,
      coordinates: item.geometry.coordinates,
      year: item?.details?.[0]?.year || 0,
    })) as ExtendedPointGeometryItem[];
  },
  target: VectorGeometryApi.setDirectionPassFlowAirportsTo,
});

//////////////////////////////////////////////////////////////////////////////////////////

// Если А и Б регионы выбраны, запросить геометрию корреспонденции
sample({
  clock: [
    FilterMapApi.setRegionFrom,
    FilterMapApi.setRegionTo,
    YearLineApi.setSelectedYear,
  ],
  source: {
    FilterMap: $FilterMap,
    FeatureSettings: $FeatureSettings,
    YearLine: $YearLine,
  },
  filter: ({ FilterMap }) =>
    !!FilterMap.fromSelectedRegion && !!FilterMap.toSelectedRegion,
  fn: ({ FilterMap, FeatureSettings, YearLine }) => {
    return {
      computingRegistryId: FeatureSettings.scenarioId!,
      includePayment: true,
      plannedYear: YearLine.selectedYear,
      regionFrom: FilterMap.fromSelectedRegion!.id,
      regionTo: FilterMap.toSelectedRegion!.id,
    } as IQueryGetComputedDirectionArgs;
  },
  target: [
    getComputedDirectionRailwayFx,
    getComputedDirectionHighwayFx,
    getComputedDirectionTransportRegionsIntersectionFx,
    getComputedDirectionRailwayStationsIntersectionFx,
  ],
});

getComputedDirectionRailwayFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getComputedDirection'>,
    IQueryGetComputedDirectionArgs
  >({
    query: gql`
      query QueryGetComputedDirectionRailway(
        $computingRegistryId: String
        $includePayment: Boolean
        $plannedYear: Int
        $regionFrom: String
        $regionTo: String
      ) {
        getComputedDirection(
          computingRegistryId: $computingRegistryId
          includePayment: $includePayment
          plannedYear: $plannedYear
          regionFrom: $regionFrom
          regionTo: $regionTo
        ) {
          railways {
            id
            geometry
            details {
              status
              year
            }
          }
        }
      }
    `,
    variables: params,
  });
  return result.data.getComputedDirection || null;
});

getComputedDirectionHighwayFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getComputedDirection'>,
    IQueryGetComputedDirectionArgs
  >({
    query: gql`
      query QueryGetComputedDirectionHighway(
        $computingRegistryId: String
        $includePayment: Boolean
        $plannedYear: Int
        $regionFrom: String
        $regionTo: String
      ) {
        getComputedDirection(
          computingRegistryId: $computingRegistryId
          includePayment: $includePayment
          plannedYear: $plannedYear
          regionFrom: $regionFrom
          regionTo: $regionTo
        ) {
          highways {
            id
            geometry
            details {
              status
              year
            }
          }
        }
      }
    `,
    variables: params,
  });
  return result.data.getComputedDirection || null;
});

getComputedDirectionTransportRegionsIntersectionFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getComputedDirection'>,
    IQueryGetComputedDirectionArgs
  >({
    query: gql`
      query QueryGetComputedDirectionTransportRegionsIntersection(
        $computingRegistryId: String
        $includePayment: Boolean
        $plannedYear: Int
        $regionFrom: String
        $regionTo: String
      ) {
        getComputedDirection(
          computingRegistryId: $computingRegistryId
          includePayment: $includePayment
          plannedYear: $plannedYear
          regionFrom: $regionFrom
          regionTo: $regionTo
        ) {
          getTransportRegionsIntersection {
            id
            border {
              geometry
            }
          }
        }
      }
    `,
    variables: params,
  });
  return result.data.getComputedDirection || null;
});

getComputedDirectionRailwayStationsIntersectionFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getComputedDirection'>,
    IQueryGetComputedDirectionArgs
  >({
    query: gql`
      query QueryGetComputedDirectionRailwayStationsIntersection(
        $computingRegistryId: String
        $includePayment: Boolean
        $plannedYear: Int
        $regionFrom: String
        $regionTo: String
      ) {
        getComputedDirection(
          computingRegistryId: $computingRegistryId
          includePayment: $includePayment
          plannedYear: $plannedYear
          regionFrom: $regionFrom
          regionTo: $regionTo
        ) {
          getRailwayStationsIntersection {
            id
            geometry
          }
        }
      }
    `,
    variables: params,
  });
  return result.data.getComputedDirection || null;
});

// Запись жд путей в корреспонденции
sample({
  clock: getComputedDirectionRailwayFx.done,
  fn: request => {
    return request.result?.railways.map(item => ({
      id: item?.id || '',
      coordinates: item?.geometry?.coordinates || [],
      status: item?.details?.[0]?.status || '',
      year: item?.details?.[0]?.year || 0,
    })) as ExtendedMultiLineStringGeometryItem[];
  },
  target: VectorGeometryApi.setDirectionPassFlowRailway,
});

// Запись авто дорог в корреспонденции
sample({
  clock: getComputedDirectionHighwayFx.done,
  fn: request => {
    return request.result?.highways.map(item => ({
      id: item?.id || '',
      coordinates: item?.geometry?.coordinates || [],
      status: item?.details?.[0]?.status || '',
      year: item?.details?.[0]?.year || 0,
    })) as ExtendedMultiLineStringGeometryItem[];
  },
  target: VectorGeometryApi.setDirectionPassFlowHighway,
});

// Запись промежуточных транспортных регионов в корреспонденции
sample({
  clock: getComputedDirectionTransportRegionsIntersectionFx.done,
  fn: request => {
    return (
      request.result?.getTransportRegionsIntersection?.map(item => ({
        id: item?.id || '',
        coordinates: item?.border?.geometry?.coordinates || [],
      })) || ([] as MultiPolygonGeometryItem[])
    );
  },
  target: VectorGeometryApi.setDirectionPassFlowRegionsIntersection,
});

// Запись промежуточных жд станций в корреспонденции
sample({
  clock: getComputedDirectionRailwayStationsIntersectionFx.done,
  fn: request => {
    return (
      request.result?.getRailwayStationsIntersection?.map(item => ({
        id: item?.id || '',
        coordinates: item?.geometry?.coordinates || [],
      })) || ([] as PointGeometryItem[])
    );
  },
  target: VectorGeometryApi.setDirectionPassFlowRailwayStationsIntersection,
});

// Если изменился регион отправления очистить его прошлую геометрию, и промежуточную
sample({
  clock: FilterMapApi.setRegionFrom,
  fn: () => null,
  target: [
    VectorGeometryApi.setDirectionPassFlowRegionsFrom,
    VectorGeometryApi.setDirectionPassFlowRegionsIntersection,
    VectorGeometryApi.setDirectionPassFlowRailwayStationsFrom,
    VectorGeometryApi.setDirectionPassFlowRailwayStationsIntersection,
    VectorGeometryApi.setDirectionPassFlowBusStationsFrom,
    VectorGeometryApi.setDirectionPassFlowBusStationsIntersection,
    VectorGeometryApi.setDirectionPassFlowAirportsFrom,
    VectorGeometryApi.setDirectionPassFlowAirportsIntersection,
    VectorGeometryApi.setDirectionPassFlowHighway,
    VectorGeometryApi.setDirectionPassFlowRailway,
  ],
});

// Если изменился регион прибытия очистить его прошлую геометрию, и промежуточную
sample({
  clock: FilterMapApi.setRegionTo,
  fn: () => null,
  target: [
    VectorGeometryApi.setDirectionPassFlowRegionsTo,
    VectorGeometryApi.setDirectionPassFlowRegionsIntersection,
    VectorGeometryApi.setDirectionPassFlowRailwayStationsTo,
    VectorGeometryApi.setDirectionPassFlowRailwayStationsIntersection,
    VectorGeometryApi.setDirectionPassFlowBusStationsTo,
    VectorGeometryApi.setDirectionPassFlowBusStationsIntersection,
    VectorGeometryApi.setDirectionPassFlowAirportsTo,
    VectorGeometryApi.setDirectionPassFlowAirportsIntersection,
    VectorGeometryApi.setDirectionPassFlowHighway,
    VectorGeometryApi.setDirectionPassFlowRailway,
  ],
});

// Если другой регион не выбран или ранее сохраненная геометрия отличается от выбранного региона, очистить его геометрию
sample({
  clock: FilterMapApi.setRegionTo,
  source: { FilterMap: $FilterMap, VectorGeometry: $VectorGeometry },
  filter: ({ FilterMap, VectorGeometry }) =>
    !FilterMap.fromSelectedRegion ||
    VectorGeometry.directionPassFlow.regions.from?.id !==
      FilterMap.fromSelectedRegion?.id,
  fn: () => null,
  target: [
    VectorGeometryApi.setDirectionPassFlowRegionsFrom,
    VectorGeometryApi.setDirectionPassFlowRailwayStationsFrom,
    VectorGeometryApi.setDirectionPassFlowBusStationsFrom,
    VectorGeometryApi.setDirectionPassFlowAirportsFrom,
  ],
});

// Если другой регион не выбран или ранее сохраненная геометрия отличается от выбранного региона, очистить его геометрию
sample({
  clock: FilterMapApi.setRegionFrom,
  source: { FilterMap: $FilterMap, VectorGeometry: $VectorGeometry },
  filter: ({ FilterMap, VectorGeometry }) =>
    !FilterMap.toSelectedRegion ||
    VectorGeometry.directionPassFlow.regions.to?.id !==
      FilterMap.toSelectedRegion?.id,
  fn: () => null,
  target: [
    VectorGeometryApi.setDirectionPassFlowRegionsTo,
    VectorGeometryApi.setDirectionPassFlowRailwayStationsTo,
    VectorGeometryApi.setDirectionPassFlowBusStationsTo,
    VectorGeometryApi.setDirectionPassFlowAirportsTo,
  ],
});
