import {
  $ModalSocioEconomic,
  ModalSocioEconomicApi,
} from '@features/ag-constructor/components/ModalSocEco/store';
import { ScenarioOption } from '@features/ag-constructor/containers/SocEcoContainer/SocEcoContainer';
import { $ComputedScenarioList } from '@features/ag-constructor/stores/lists/scenarios';
import { useUnit } from 'effector-react';
import { ChangeEvent, SyntheticEvent, useCallback, useMemo } from 'react';

import { HourglassTopOutlined } from '@mui/icons-material';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';

import { FormLabel } from '@components/FormLabel';

export const OneStep = () => {
  const $modalSocioEconomic = useUnit($ModalSocioEconomic);
  const { setName, setId } = useUnit(ModalSocioEconomicApi);
  const { socEcoScenarios } = useUnit($ComputedScenarioList);

  const scenariosList = useMemo<ScenarioOption[]>(
    () =>
      socEcoScenarios.map(scenario => ({
        label: scenario.name || '',
        value: scenario.id as string,
        disabled: !scenario.finished,
      })),
    [socEcoScenarios],
  );

  const onChangeName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    [setName],
  );

  const onSelectParent = useCallback(
    (_: SyntheticEvent, value: ScenarioOption | null) => {
      if (!value) return;
      setId(value.value);
    },
    [setId],
  );

  return (
    <>
      <FormLabel
        id="oneStepName"
        title="Название"
      >
        <TextField
          id="oneStepName"
          placeholder={'Название'}
          onChange={onChangeName}
          value={$modalSocioEconomic.name}
          sx={{
            mb: 0.75,
          }}
        />
      </FormLabel>

      <FormLabel
        sx={{ mt: 3 }}
        title={'На основе сценария'}
        id="oneStepBasedOnTheScenario"
      >
        <Autocomplete
          id="scenario-select"
          disablePortal={false}
          onChange={onSelectParent}
          options={scenariosList}
          getOptionDisabled={option => !!option?.disabled}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Сценарий"
            />
          )}
          sx={{
            mb: 0.75,
          }}
          renderOption={(props, option) => (
            <li
              {...props}
              key={option.value}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
              >
                <Typography>{option.label}</Typography>
                {option.disabled && <HourglassTopOutlined />}
              </Stack>
            </li>
          )}
        />
      </FormLabel>
    </>
  );
};
