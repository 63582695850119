import { apiClient } from '@api/client';
import { IMutation } from '@api/gql/types';
import { gql } from '@apollo/client';

export type QueryDeleteUserScenarioArgs = {
  id: string;
};

export const queryDeleteUserScenario = async ({
  id,
}: QueryDeleteUserScenarioArgs) => {
  await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation DeleteScenarioSocEco($id: UUID) {
        deleteComputingRegistry(id: $id) {
          scenarioObj {
            id
          }
        }
      }
    `,
    variables: {
      id,
    },
  });
};
