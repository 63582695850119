import { useUnit } from 'effector-react';
import { useMemo, useState } from 'react';

import {
  Box,
  Card,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { Table, TableProps } from '@components/Table';

import { getTrafficColor } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { VehicleTypeCode } from '@features/passenger-traffic/stores';
import { YearlyPassChart } from '@features/pt-forecast-new/components/Dashboards/YearlyPassChart/YearlyPassChart';
import { vehicleTypeCodeAvailableCodes } from '@features/pt-forecast-new/constants/VehicleTypeCode';
import { unitsOfMeasure } from '@features/pt-forecast-new/constants/unitsOfMeasure';
import { $Computed } from '@features/pt-forecast-new/stores/lists/computed';

import { formatByThree } from '@utils/formatByThree';

export const SecondPageDashboardContainer = () => {
  const [selectedUnit, setSelectedUnit] = useState(unitsOfMeasure[2]);
  const { sampleResults } = useUnit($Computed);

  const selectUnitHandler = (event: SelectChangeEvent<string>) => {
    const selectedOption = unitsOfMeasure.find(
      option => option.value === event.target.value,
    );
    if (selectedOption) setSelectedUnit(selectedOption);
  };

  const tableData = useMemo(() => {
    return sampleResults.map(yearData => [
      String(yearData.year),
      formatByThree(yearData.passengerTraffic.totalFlow || 0),
      formatByThree(yearData.passengerTraffic.dayTrain || 0),
      formatByThree(yearData.passengerTraffic.nightTrain || 0),
      formatByThree(yearData.passengerTraffic.suburbanTrain || 0),
      formatByThree(yearData.passengerTraffic.expressTrain || 0),
      formatByThree(yearData.passengerTraffic.avia || 0),
      formatByThree(yearData.passengerTraffic.bus || 0),
      formatByThree(yearData.passengerTraffic.auto || 0),
      formatByThree(yearData.passengerTraffic.multimodal || 0),
    ]);
  }, [sampleResults]);

  const tableHeader = useMemo<TableProps['header']>(() => {
    const codeValues = vehicleTypeCodeAvailableCodes.map((code, i) => (
      <Box
        key={i}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <Box
          sx={{
            borderRadius: '50%',
            backgroundColor: getTrafficColor(code),
            width: '12px',
            height: '12px',
            minWidth: '12px',
          }}
        />

        {transformVehicleTypeCode(code)}
      </Box>
    ));

    return ['Год', 'Сумма', ...codeValues];
  }, []);

  return (
    <Box
      sx={{
        borderTop: theme => `1px solid ${theme.palette.border.divider}`,
        display: 'flex',
      }}
    >
      <AutoFullHeight
        style={{
          padding: '8px 24px',
          width: 'calc(100% - 500px)',
          marginLeft: '500px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '8px',
        }}
      >
        <Card sx={{ p: '24px', height: 'calc(50% )' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{ mb: '24px' }}
              variant={'subtitle2'}
              gutterBottom
            >
              Прогноз пассажиропотока по видам транспорта
            </Typography>

            <Select<string>
              value={selectedUnit.value}
              onChange={selectUnitHandler}
              autoWidth
            >
              {unitsOfMeasure.map(unit => (
                <MenuItem
                  key={unit.value}
                  value={unit.value}
                >
                  {unit.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <YearlyPassChart unit={selectedUnit.value} />
        </Card>
        <Card
          sx={{
            p: '8px 24px',
            height: 'calc(50%)',
            overflowY: 'auto',
          }}
        >
          <Table
            data={tableData}
            header={tableHeader}
            fullHeightContainer
          />
        </Card>
      </AutoFullHeight>
    </Box>
  );
};

const transformVehicleTypeCode = (code: VehicleTypeCode) => {
  switch (code) {
    case VehicleTypeCode.dayTrain:
      return 'Дневной поезд';
    case VehicleTypeCode.nightTrain:
      return 'Ночной поезд';
    default:
      return code;
  }
};
