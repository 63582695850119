import { ModalEditCancellation } from '@features/pt-constructor/components/ModalEditCancellation';
import { ModalReCalculation } from '@features/pt-constructor/components/ModalReCalculation/ModalEditCancellation';
import { ModalRewriteCalculated } from '@features/pt-constructor/components/ModalRewriteCalculated/ModalRewriteCalculated';
import { ModalSocioEconomic } from '@features/pt-constructor/components/ModalSocioEconomic';
import { ModalTransport } from '@features/pt-constructor/components/ModalTransport';
import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { ModalCalculation } from './components/ModalCalculation';
import { ModalDeleteCalculation } from './components/ModalDeleteCalculation';
import { ModalShareCalculation } from './components/ModalShareCalculation';
import { $ModalsPtConstructor, setModalView } from './store';

export const Modals = () => {
  const [$modalsPtConstructor, setModalViewFn] = useUnit([
    $ModalsPtConstructor,
    setModalView,
  ]);

  const handleCloseModalSocioEconomic = useCallback(() => {
    setModalViewFn({ socioEconomic: false });
  }, [setModalViewFn]);

  const handleCloseModalModalTransport = useCallback(() => {
    setModalViewFn({ transport: false });
  }, [setModalViewFn]);

  const handleCloseModalCalculation = useCallback(() => {
    setModalViewFn({ calculation: false });
  }, [setModalViewFn]);

  const handleCloseModalDeleteCalculation = useCallback(() => {
    setModalViewFn({ deleteCalculation: false });
  }, [setModalViewFn]);

  const handleCloseModalShareCalculation = useCallback(() => {
    setModalViewFn({ shareCalculation: false });
  }, [setModalViewFn]);

  const handleCloseModalEditCancellation = useCallback(() => {
    setModalViewFn({ editDeny: false });
  }, [setModalViewFn]);

  const handleCloseModalRecalculation = useCallback(() => {
    setModalViewFn({ recalculation: false });
  }, [setModalViewFn]);

  const handleCloseModalRewriteCalculated = useCallback(() => {
    setModalViewFn({ rewriteCalculated: false });
  }, [setModalViewFn]);

  return (
    <>
      {$modalsPtConstructor.socioEconomic && (
        <ModalSocioEconomic onClose={handleCloseModalSocioEconomic} />
      )}

      {$modalsPtConstructor.transport && (
        <ModalTransport onClose={handleCloseModalModalTransport} />
      )}

      {$modalsPtConstructor.calculation && (
        <ModalCalculation onClose={handleCloseModalCalculation} />
      )}

      {$modalsPtConstructor.deleteCalculation && (
        <ModalDeleteCalculation onClose={handleCloseModalDeleteCalculation} />
      )}

      {$modalsPtConstructor.shareCalculation && (
        <ModalShareCalculation
          onClose={handleCloseModalShareCalculation}
          onExecute={() => {}}
        />
      )}

      {$modalsPtConstructor.editDeny && (
        <ModalEditCancellation onClose={handleCloseModalEditCancellation} />
      )}

      {$modalsPtConstructor.recalculation && (
        <ModalReCalculation onClose={handleCloseModalRecalculation} />
      )}

      {$modalsPtConstructor.rewriteCalculated && (
        <ModalRewriteCalculated onClose={handleCloseModalRewriteCalculated} />
      )}
    </>
  );
};
