import { useUnit } from 'effector-react';

import MapControls from '@components/Map/MapControls';

import { $FilterMap, $UI } from '@features/passenger-traffic/stores';
import { $Map } from '@features/passenger-traffic/stores/map/store';

export const MapControlsContainer = () => {
  const { layout } = useUnit($FilterMap);
  const { panels } = useUnit($UI);
  const { map } = useUnit($Map);

  return (
    map && (
      <MapControls
        layout={layout}
        map={map}
        isVisibleInspector={panels.inspector}
      />
    )
  );
};
