import { categoryParams } from '@widgets/widget-routes-params/configs/categoryParams';
import { GraphParamsType } from '@widgets/widget-routes-params/stores/list/directionsParamsTable/store';

export const checkErrorsInParams = (
  directions: GraphParamsType[],
): { newDirection: GraphParamsType[]; isError: boolean } => {
  let isError = false;
  directions.forEach(direction => {
    if (
      (!!direction.expressTrainTime &&
        !!direction.expressTrainCost &&
        !!direction.expressTrainFrequency) ||
      (!direction.expressTrainTime &&
        !direction.expressTrainCost &&
        !direction.expressTrainFrequency)
    ) {
      categoryParams[0].forEach(
        param =>
          (direction[
            'isError' + param.charAt(0).toUpperCase() + param.slice(1)
          ] = false),
      );
    } else {
      categoryParams[0].forEach(param => {
        if (direction[param]) {
          direction[
            'isError' + param.charAt(0).toUpperCase() + param.slice(1)
          ] = false;
        } else {
          direction[
            'isError' + param.charAt(0).toUpperCase() + param.slice(1)
          ] = true;
          isError = true;
        }
      });
    }

    if (
      (!!direction.nightTrainTime &&
        !!direction.nightTrainCost &&
        !!direction.nightTrainFrequency) ||
      (!direction.nightTrainTime &&
        !direction.nightTrainCost &&
        !direction.nightTrainFrequency)
    ) {
      categoryParams[1].forEach(
        param =>
          (direction[
            'isError' + param.charAt(0).toUpperCase() + param.slice(1)
          ] = false),
      );
    } else {
      categoryParams[1].forEach(param => {
        if (direction[param]) {
          direction[
            'isError' + param.charAt(0).toUpperCase() + param.slice(1)
          ] = false;
        } else {
          direction[
            'isError' + param.charAt(0).toUpperCase() + param.slice(1)
          ] = true;
          isError = true;
        }
      });
    }

    if (
      (!!direction.dayTrainTime &&
        !!direction.dayTrainCost &&
        !!direction.dayTrainFrequency) ||
      (!direction.dayTrainTime &&
        !direction.dayTrainCost &&
        !direction.dayTrainFrequency)
    ) {
      categoryParams[2].forEach(
        param =>
          (direction[
            'isError' + param.charAt(0).toUpperCase() + param.slice(1)
          ] = false),
      );
    } else {
      categoryParams[2].forEach(param => {
        if (direction[param]) {
          direction[
            'isError' + param.charAt(0).toUpperCase() + param.slice(1)
          ] = false;
        } else {
          direction[
            'isError' + param.charAt(0).toUpperCase() + param.slice(1)
          ] = true;
          isError = true;
        }
      });
    }

    if (
      (!!direction.suburbanTrainTime &&
        !!direction.suburbanTrainCost &&
        !!direction.suburbanTrainFrequency) ||
      (!direction.suburbanTrainTime &&
        !direction.suburbanTrainCost &&
        !direction.suburbanTrainFrequency)
    ) {
      categoryParams[3].forEach(
        param =>
          (direction[
            'isError' + param.charAt(0).toUpperCase() + param.slice(1)
          ] = false),
      );
    } else {
      categoryParams[3].forEach(param => {
        if (direction[param]) {
          direction[
            'isError' + param.charAt(0).toUpperCase() + param.slice(1)
          ] = false;
        } else {
          direction[
            'isError' + param.charAt(0).toUpperCase() + param.slice(1)
          ] = true;
          isError = true;
        }
      });
    }
  });

  return {
    newDirection: directions,
    isError,
  };
};
