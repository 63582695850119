import dayjs from 'dayjs';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { Box, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface AttributeProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  title: string;
  defaultValue: number;
}

export function Time<T extends FieldValues>({
  control,
  name,
  title,
  defaultValue,
}: AttributeProps<T>) {
  return (
    <Box>
      <Typography mb={1}>{title}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange } }) => (
              <TimePicker
                ampm={false}
                disableOpenPicker={true}
                value={dayjs(defaultValue * 1000)}
                onChange={e => {
                  const time = dayjs(e).minute() * 60 + dayjs(e).second();
                  onChange(time);
                }}
                format="mm:ss"
                sx={{
                  width: '100%',
                  '.MuiInputBase-root': {
                    backgroundColor: theme =>
                      `${theme.palette.background.default} !important`,
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
}
