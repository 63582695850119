import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { CustomMap } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/helpers';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';

export type AddTileLayerProps = {
  map: CustomMap;
  source: VectorTileSource;
  layout: EMapFeatureLayout;
  zIndex: number;
};

/** Добавить на карту тайловый слой */
export const addTileLayer = (args: AddTileLayerProps) => {
  const { map, layout, source, zIndex } = args;
  map.addLayer(
    new VectorTileLayer({
      declutter: true,
      className: layout,
      zIndex,
      source,
      renderMode: localStorage.getItem('mapDev') ? 'vector' : 'hybrid',
      visible: false,
    }),
  );
};
