import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckBoxCheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="4"
        fill="currentColor"
      />
      <path
        d="M17.7898 7.21022C18.0701 7.4905 18.0701 7.94494 17.7898 8.22523L9.66093 16.3541L6.2103 12.9034C5.9299 12.6231 5.9299 12.1684 6.21029 11.8881C6.49045 11.6079 6.94457 11.6076 7.22507 11.8874L9.66093 14.3173L16.775 7.20998C17.0553 6.92991 17.5096 6.93002 17.7898 7.21022Z"
        fill="white"
      />
    </SvgIcon>
  );
};
