import {
  IComputingRegistrySocioEconomicScenarioInstanceScenarioTypeChoices,
  IComputingRegistryTransportScenarioInstanceScenarioTypeChoices,
  IComputingRegistryType,
} from '@api/gql/types';

import { mockComputingResults } from '@features/pt-constructor/constants/mock/mockComputingResults';
import { getScenarioType } from '@features/pt-constructor/constants/socialScenarioType';
import { CalculationScenario } from '@features/pt-constructor/store';

export const getScenarioReformatter = (
  scenario: IComputingRegistryType,
): CalculationScenario => ({
  id: scenario.id,
  name: scenario.name || '',
  revenueGrowthRate: scenario.socEcoScenario?.revenueGrowthRate || 0,
  growthRateInTheNumberOfPlacements:
    scenario.socEcoScenario?.growthRateInTheNumberOfPlacements || 0,
  growthRateOfMotorization:
    scenario.socEcoScenario?.growthRateOfMotorization || 0,
  jobGrowthRate: scenario.socEcoScenario?.jobGrowthRate || 0,
  populationGrowthRate: scenario.socEcoScenario?.populationGrowthRate || 0,
  salaryGrowthRate: scenario.socEcoScenario?.salaryGrowthRate || 0,
  projectObjects: [], // где?
  socEcoScenarioType: getScenarioType(
    scenario.socEcoScenario?.scenarioType ||
      IComputingRegistrySocioEconomicScenarioInstanceScenarioTypeChoices.A_1,
  ),
  socEcoComputeName: scenario.socEcoScenario?.name || '',
  socEcoScenarioId: scenario.socEcoScenario?.id || '',
  transportScenarioType: getScenarioType(
    scenario.transportScenario?.scenarioType ||
      IComputingRegistryTransportScenarioInstanceScenarioTypeChoices.A_1,
  ),
  transportComputeName: scenario.transportScenario?.name || '',
  transportScenarioId: scenario.transportScenario?.id || '',
  computingResults: mockComputingResults,
  computingresultsSet: null,
});
