import { ISexType } from '@api/gql/types';
import { createApi, createStore } from 'effector';

const initStore: PassTrafficBySexStore = [];

export const $PassTrafficBySex = createStore<PassTrafficBySexStore>(initStore);

export const PassTrafficBySexApi = createApi($PassTrafficBySex, {
  setPassTrafficBySex: (_, payload: PassTrafficBySexStore) => payload,
  reset: () => initStore,
});

export type PassTrafficBySexStore =
  | (ISexType & { year: number | null })[]
  | null;
