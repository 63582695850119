import {
  $FileFormatModalState,
  resetFileFormatModalStateFn,
  setIsOpenFileFormatModalFn,
} from './file-format-requirements-modal.store';

$FileFormatModalState
  .reset(resetFileFormatModalStateFn)
  .on(setIsOpenFileFormatModalFn, (state, payload) => ({
    ...state,
    isOpen: payload,
  }));
