import { createEffect, createEvent, createStore } from 'effector';

interface ModalDeleteProjectDataType {
  isOpen: boolean;
  id: string | null;
  name: string | null;
  isLoading: boolean;
  isResolve: boolean;
  isRejected: boolean;
}

export const $ModalDeleteProject = createStore<ModalDeleteProjectDataType>({
  id: null,
  isLoading: false,
  isOpen: false,
  isRejected: false,
  isResolve: false,
  name: null,
});

export const openDeleteProjectModalFn = createEvent<{
  id: string;
  name: string;
}>();

export const resetModalDeleteProjectFn = createEvent<void>();

export const deleteProjectFn = createEvent<void>();

export const removeProjectFx = createEffect<string, boolean>();
