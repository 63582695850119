import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { FormLabel, TextField } from '@mui/material';

export type CashedFieldProps<T = string | number | null | undefined> = {
  id: string;
  title: string;
  placeholder?: string;
  value: T;
  onBlur?: (value: T) => void;
  disabled?: boolean;
};

export const CashedField = (props: CashedFieldProps) => {
  const { id, title, placeholder, value, onBlur, disabled } = props;
  const [cashedValue, setCashedValue] = useState(value);

  const changeCashedValueHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCashedValue(event.target.value);
    },
    [],
  );

  const onBlurHandler = useCallback(() => {
    onBlur && onBlur(cashedValue);
  }, [cashedValue, onBlur]);

  useEffect(() => {
    setCashedValue(value);
  }, [value]);

  return (
    <FormLabel id={id}>
      {title}

      <TextField
        id={id}
        placeholder={placeholder}
        value={cashedValue}
        onChange={changeCashedValueHandler}
        onBlur={onBlurHandler}
        disabled={disabled}
        fullWidth
        sx={{ mt: 1 }}
      />
    </FormLabel>
  );
};
