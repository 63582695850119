import {
  IQuerySimulationHeatmapBySimulationIdArgs,
  ISimulationHeatmapType,
} from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

export interface HeatmapLog {
  x: number;
  y: number;
  q: number;
}

export type HeatmapLogOnFloors = Record<string, HeatmapLog[]>;

export type HeatmapLogPerTime = Record<string, HeatmapLogOnFloors>;

export const $Heatmap = createStore<HeatmapLogPerTime | null>(null);

export const resetHeatmapStoreFn = createEvent<void>();

export const getHeatmapFx = createEffect<
  IQuerySimulationHeatmapBySimulationIdArgs,
  ISimulationHeatmapType[] | null
>();
