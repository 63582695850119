import { SvgIcon, SvgIconProps } from '@mui/material';

export const WallIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={4}
      viewBox="0 0 24 4"
      fill="none"
      {...props}
    >
      <path
        fill="#F14848"
        d="M24 0v4H0V0z"
      />
    </SvgIcon>
  );
};
