import { useUnit } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { DialogCloseButton } from '@components/DialogCloseButton';

import { Share } from './states/share';
import { Success } from './states/success';
import {
  $ModalShareProjects,
  EStateModalShareProjects,
  resetModalShareProjects,
  setState,
} from './store';

interface ModalShareProjectsProps {
  onClose(): void;
  onExecute(): void;
}

export const ModalShareProjects = (props: ModalShareProjectsProps) => {
  const { onClose, onExecute } = props;
  const $modalShareProjects = useUnit($ModalShareProjects);
  const handlers = useUnit({ resetModalShareProjects, setState });

  const DescriptionState = useMemo(() => {
    switch ($modalShareProjects.state) {
      case EStateModalShareProjects.share:
        return Share;

      case EStateModalShareProjects.success:
        return Success;
    }
  }, [$modalShareProjects.state]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    if ($modalShareProjects.state === EStateModalShareProjects.share) {
      onExecute();
      handlers.setState(EStateModalShareProjects.success);
    } else {
      onClose();
    }
  }, [$modalShareProjects.state, handlers, onClose, onExecute]);

  useEffect(() => {
    return handlers.resetModalShareProjects;
  }, [handlers.resetModalShareProjects]);

  return (
    <Dialog
      onClose={handleClose}
      open={true}
    >
      <DialogTitle
        variant="subtitle2"
        sx={{
          position: 'relative',
          fontSize: theme => theme.typography.pxToRem(16),
          pr: 10,
        }}
      >
        Поделиться расчетом
      </DialogTitle>

      <DialogCloseButton onClick={handleClose} />

      <DialogContent>
        <DescriptionState />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSubmit}>
          {$modalShareProjects.state === EStateModalShareProjects.share
            ? 'Поделиться'
            : 'Закрыть'}
        </Button>

        {$modalShareProjects.state === EStateModalShareProjects.share && (
          <Button
            variant={'outlined'}
            onClick={handleClose}
          >
            Отменить
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
