import { useUnit } from 'effector-react';

import {
  PassengerShareForm,
  VisitorsShareForm,
} from '@features/tpu-passenger-types/forms';
import { useContextPassengerTypesHandlers } from '@features/tpu-passenger-types/hooks';
import {
  $IsOpenShareForm,
  $ShareForm,
} from '@features/tpu-passenger-types/stores/formShare.store';
import {
  isPassengerFormData,
  isVisitorsFormData,
} from '@features/tpu-passenger-types/types';

export const FormPassengerShareContainer = () => {
  const $isOpenShareForm = useUnit($IsOpenShareForm);
  const $shareForm = useUnit($ShareForm);

  const { setIsOpenShareForm, savePassengerShare } =
    useContextPassengerTypesHandlers();

  const closeForm = () => setIsOpenShareForm(false);

  if (!$isOpenShareForm) return null;

  if (isVisitorsFormData($shareForm))
    return (
      <VisitorsShareForm
        formData={$shareForm}
        onClose={closeForm}
        onSave={savePassengerShare}
      />
    );

  if (isPassengerFormData($shareForm)) {
    return (
      <PassengerShareForm
        formData={$shareForm}
        onSave={savePassengerShare}
        onClose={closeForm}
      />
    );
  }

  return null;
};
