import { useUnit } from 'effector-react';
import { useCallback, useEffect } from 'react';

import { OneStep } from './steps/one';
import { TwoStep } from './steps/two';
import {
  $ModalSocioEconomic,
  EStepModalSocioEconomic,
  resetModalSocioEconomic,
  setErrorChecking,
  setSocEcoParams,
} from './store';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { DialogCloseButton } from '@components/DialogCloseButton';

import { SelectedCalculationScenarioApi } from '@features/pt-constructor/store/selected/store';

type ModalSocioEconomicProps = {
  onClose(): void;
};

export const ModalSocioEconomic = ({ onClose }: ModalSocioEconomicProps) => {
  const $modalSocioEconomic = useUnit($ModalSocioEconomic);
  const { createSocEcoScenario } = useUnit(SelectedCalculationScenarioApi);

  const onNextHandler = useCallback(() => {
    // проверка ошибок в имени и наличии выбранного базового сценария, далее триггер на переход к следующему шагу, при отсутствии ошибок
    setErrorChecking();
  }, []);

  const onSaveHandler = useCallback(() => {
    createSocEcoScenario({
      ...$modalSocioEconomic.params,
      id: $modalSocioEconomic.selectedId,
      name: $modalSocioEconomic.name,
      computed: false,
      type: 4,
    });

    onClose();
  }, [
    $modalSocioEconomic.name,
    $modalSocioEconomic.params,
    $modalSocioEconomic.selectedId,
    createSocEcoScenario,
    onClose,
  ]);

  useEffect(() => {
    return resetModalSocioEconomic;
  }, []);

  return (
    <Dialog
      onClose={onClose}
      open={true}
    >
      <DialogTitle
        sx={{
          position: 'relative',
          fontSize: theme => theme.typography.pxToRem(16),
        }}
      >
        Пользовательский сценарий социально-экономического прогноза
      </DialogTitle>

      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        {$modalSocioEconomic.step === EStepModalSocioEconomic.one && (
          <OneStep />
        )}

        {$modalSocioEconomic.step === EStepModalSocioEconomic.two && (
          <TwoStep
            ecoScenarioName={$modalSocioEconomic.name}
            onChangeEcoParam={setSocEcoParams}
            socEcoParams={$modalSocioEconomic.params}
            baseParams={$modalSocioEconomic.base}
          />
        )}
      </DialogContent>

      <DialogActions>
        {$modalSocioEconomic.step === EStepModalSocioEconomic.one && (
          <Button onClick={onNextHandler}>Далее</Button>
        )}

        {$modalSocioEconomic.step === EStepModalSocioEconomic.two && (
          <Button onClick={onSaveHandler}>Сохранить</Button>
        )}

        <Button
          variant={'outlined'}
          onClick={onClose}
        >
          Отменить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
