import { IPoint } from '@features/tpu-simulation-model/types';

/**
 * @description Функция для помощи в построении прямоугольников. Функция проверяет находится ли текущая точка в пределах радиуса одной из координат другой точки.
 * Если да, то возвращается соответствующая координата.
 *
 * @param {IPoint} currPoint координаты
 * @param {IPoint[]} points координаты крайних точек
 * @param {number }radius радиус при котором currPoint примагничивается
 */
export const getBoundPoint = (
  currPoint: IPoint,
  points: IPoint[] | null,
  radius: number = 4,
) => {
  const firstPoint = points?.at(0);
  const lastPoint = points?.at(-1);

  if (!lastPoint || !firstPoint) return currPoint;

  const diffWithPrevX = currPoint.x - lastPoint.x;
  const diffWithPrevY = currPoint.y - lastPoint.y;
  const diffWithFirstX = currPoint.x - firstPoint.x;
  const diffWithFirstY = currPoint.y - firstPoint.y;

  let x = currPoint.x;
  let y = currPoint.y;

  if (diffWithPrevX > -radius && diffWithPrevX < radius) x = lastPoint.x;
  if (diffWithPrevY > -radius && diffWithPrevY < radius) y = lastPoint.y;

  if (diffWithFirstX > -radius && diffWithFirstX < radius) x = firstPoint.x;
  if (diffWithFirstY > -radius && diffWithFirstY < radius) y = firstPoint.y;

  return {
    x,
    y,
  };
};
