import {
  IQuery,
  IQueryGetTransportDistrictCenterInstanceByIdArgs,
  IQueryGetTransportZoneInstanceByIdArgs,
} from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

import { SearchOption } from '@features/ag-forecast/stores/lists/searchOptions';
import { AgGeometryRailwayStation } from '@features/ag-forecast/stores/map';

const initState: SearchInfraStore = {
  selectedOption: null,
  features: {
    transportDistrict: null,
    transportZone: null,
    railwayStation: null,
  },
};

export const $SearchInfra = createStore<SearchInfraStore>(initState);

export const SearchInfraApi = createApi($SearchInfra, {
  setSelectedOption: (state, payload: SearchOption) => ({
    ...state,
    selectedOption: payload,
  }),
  setGeometry: (state, payload: SearchInfraStore['features']) => ({
    ...state,
    features: payload,
  }),
  reset: () => initState,
});

export const getTransportDistrictFx = createEffect<
  IQueryGetTransportDistrictCenterInstanceByIdArgs,
  IQuery['getTransportDistrictCenterInstanceById']
>();

export const getTransportZoneFx = createEffect<
  IQueryGetTransportZoneInstanceByIdArgs,
  IQuery['getTransportZoneCenterInstanceById']
>();

export type SearchInfraStore = {
  selectedOption: SearchOption | null;
  features: {
    transportDistrict: {
      geometry: number[][];
      id: string;
    } | null;
    transportZone: {
      geometry: number[][];
    } | null;
    railwayStation: AgGeometryRailwayStation | null;
  };
};
