import { SvgIcon, SvgIconProps } from '@mui/material';

export const RadioButtonIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <g>
        <circle
          cx="12"
          cy="12"
          r="12"
          fill="#F6F6F6"
        />
      </g>
      <circle
        cx="12"
        cy="12"
        r="11.5"
        stroke="#BBC0CB"
      />
    </SvgIcon>
  );
};
