export type AgSocEcoScenarioParams = {
  annualPopulationGrowthRate: number;
  annualEducationPlacesGrowthRate: number;
  annualRetailSpaceGrowthRate: number;
  annualJobsGrowthRate: number;
  annualServiceJobsGrowthRate: number;
  annualSalariesGrowthRate: number;
  annualGardenPartnershipGrowthRate: number;
};

export type AgEcoParam = {
  name: keyof AgSocEcoScenarioParams;
  title: string;
};

export const agEcoParams: AgEcoParam[] = [
  {
    name: 'annualPopulationGrowthRate',
    title: 'Среднегодовые темпы роста населения, %',
  },
  {
    name: 'annualEducationPlacesGrowthRate',
    title: 'Среднегодовые темпы роста мест учебы, %',
  },
  {
    name: 'annualRetailSpaceGrowthRate',
    title: 'Среднегодовые темпы роста торговых площадей, %',
  },
  {
    name: 'annualJobsGrowthRate',
    title: 'Среднегодовые темпы роста рабочих мест, %',
  },
  {
    name: 'annualServiceJobsGrowthRate',
    title: 'Среднегодовые темпы роста рабочих мест в сфере услуг, %',
  },
  {
    name: 'annualSalariesGrowthRate',
    title: 'Среднегодовые темпы роста заработной платы, %',
  },
  {
    name: 'annualGardenPartnershipGrowthRate',
    title: 'Среднегодовые темпы роста площадей СНТ, %',
  },
];
