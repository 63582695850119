import { EMapFeatureLayout } from '@constants/map';

export const LayersZIndex = {
  [EMapFeatureLayout.animalPassage]: 1,
  [EMapFeatureLayout.animalProtectionVibration]: 2,
  [EMapFeatureLayout.changingRoutes]: 3,
  [EMapFeatureLayout.culverts]: 4,
  [EMapFeatureLayout.protectionVibration]: 5,
  [EMapFeatureLayout.safeCrossing]: 6,
  [EMapFeatureLayout.stationAccessibility]: 7,
  [EMapFeatureLayout.allGraphsAgglomeration]: 8,
  [EMapFeatureLayout.walkingArea]: 9,
  [EMapFeatureLayout.trafficCongestion]: 10,
};
