import { Chip, Grid, Typography } from '@mui/material';

export const Draft = () => (
  <Grid
    display={'flex'}
    gap={1.5}
    alignItems="center"
  >
    <Chip
      label="Черновик"
      color="default"
    />

    <Typography variant="body2">Расчет еще не производился</Typography>
  </Grid>
);
