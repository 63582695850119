import {
  CustomMap,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { LAYERS_Z_INDEX } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/layersZIndex';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

export type AddVectorLayerArgs = {
  features: Feature<Geometry>[];
  layout: EMapFeatureLayout;
  map: CustomMap;
  width?: number;
};

export const addVectorLayer = (args: AddVectorLayerArgs) => {
  const { features, map, layout } = args;

  const vectorSource = new VectorSource({ features });
  const vectorLayer = new VectorLayer({
    source: vectorSource,
    className: layout,
    zIndex: LAYERS_Z_INDEX[layout] || 98,
  });
  vectorLayer.set(layerLayoutKey, layout);

  map.addLayer(vectorLayer);
};
