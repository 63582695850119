import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { Autocomplete, Box, TextField, Typography } from '@mui/material';

import { Option } from '@features/tpu-simulation-model/utils/getAttributeOptions';

interface AttributeProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  options: Option[];
  value: Option;
  title?: string;
  placeholder?: string;
  onChange?: (value?: string) => void;
}

export function SelectWithInput<T extends FieldValues>({
  control,
  name,
  title,
  placeholder,
  options,
  value,
  onChange,
}: AttributeProps<T>) {
  return (
    <Box>
      <Typography mb={1}>{title}</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Autocomplete
            fullWidth
            freeSolo
            options={options}
            value={value}
            onChange={(_, option) => {
              if (option && typeof option !== 'string') {
                field.onChange(option?.value);
                onChange && onChange(option?.value);
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                onChange={e => {
                  const val = e.target.value;
                  field.onChange(val);
                  onChange && onChange(val);
                }}
                size="medium"
                placeholder={placeholder}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option.value}
              >
                <Typography>{option.label}</Typography>
              </li>
            )}
            sx={{
              '.MuiInputBase-root': {
                backgroundColor: theme =>
                  `${theme.palette.background.default} !important`,
              },
            }}
          />
        )}
      />
    </Box>
  );
}
