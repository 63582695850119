import { Box, CircularProgress, SxProps, Theme } from '@mui/material';

type LoadingType = {
  isLoading?: boolean;
  sx?: SxProps<Theme>;
  circularColor?: string;
};

export const Loading = (props: LoadingType) => {
  const { isLoading = false, sx, circularColor } = props;

  return (
    isLoading && (
      <Box
        sx={{
          position: 'fixed',
          height: '100%',
          width: '100%',
          zIndex: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#00000066',
          ...sx,
        }}
      >
        <CircularProgress
          sx={{
            color: theme => circularColor || theme.palette.secondary.main,
          }}
        />
      </Box>
    )
  );
};
