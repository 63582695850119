import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddTerminalRailwayStation = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={25}
      height={26}
      viewBox="0 0 25 26"
      fill="none"
      {...props}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="10"
          cy="10"
          r="9.5"
          fill="white"
          stroke="#7A5A5A"
        />
        <path
          d="M13.4521 14.8865L11.9486 12.617C12.445 12.4752 12.9415 11.9645 12.9415 11.2695V6.39007C12.9415 5.62411 12.3032 5 11.523 5H9.86348H8.18972C7.40957 5 6.77128 5.62411 6.77128 6.39007V11.2695C6.77128 11.9504 7.26773 12.4752 7.76418 12.617L6.26064 14.8865C6.24645 14.9149 6.24645 14.9291 6.26064 14.9574C6.27482 14.9858 6.30319 15 6.31738 15H7.21099C7.23936 15 7.25355 14.9858 7.26773 14.9716L8.35993 13.3546H11.3387L12.4309 14.9574C12.445 14.9716 12.4592 14.9858 12.4876 14.9858H13.3954C13.4238 14.9858 13.4379 14.9716 13.4521 14.9433C13.4663 14.9291 13.4663 14.9007 13.4521 14.8865ZM7.62234 8.00709V7.12766C7.62234 6.8156 7.80674 6.51773 8.23227 6.51773H11.4805C11.8918 6.51773 12.0904 6.82979 12.0904 7.12766V8.00709C12.0904 8.17731 12.0337 8.33333 11.9202 8.44681C11.8067 8.56028 11.6507 8.61702 11.4805 8.61702H8.23227C8.06206 8.61702 7.90603 8.56028 7.79255 8.44681C7.67908 8.33333 7.62234 8.17731 7.62234 8.00709ZM8.16135 10.7163C8.44504 10.7163 8.68617 10.9574 8.68617 11.2411C8.68617 11.539 8.44504 11.766 8.16135 11.766C7.87766 11.766 7.63652 11.5248 7.63652 11.2411C7.63652 10.9574 7.86347 10.7163 8.16135 10.7163ZM11.523 10.7163C11.8067 10.7163 12.0479 10.9574 12.0479 11.2411C12.0479 11.539 11.8067 11.766 11.523 11.766C11.2394 11.766 10.9982 11.5248 10.9982 11.2411C11.0124 10.9574 11.2394 10.7163 11.523 10.7163Z"
          fill="#7A5A5A"
        />
      </svg>
    </SvgIcon>
  );
};
