import {
  EditableObjectType,
  RULER,
  Ruler,
} from '@features/tpu-simulation-model/types';

export const EDITOR_CONTAINER_ID = 'EDITOR_CONTAINER_ID';

export const OBJECT_NAMES: Record<EditableObjectType | Ruler, string> = {
  [EditableObjectType.Room]: 'Помещение',
  [EditableObjectType.Wall]: 'Стена',
  [EditableObjectType.Ladder]: 'Лестница',
  [EditableObjectType.Platform]: 'Платформа',
  [EditableObjectType.Door]: 'Дверь',
  [EditableObjectType.Elevator]: 'Лифт',
  [EditableObjectType.Path]: 'Путь',
  [EditableObjectType.PassengerGenerator]: 'Входы',
  [EditableObjectType.Exit]: 'Выходы',
  [EditableObjectType.TicketOffice]: 'Билетная касса',
  [EditableObjectType.TicketPrintingMachine]: 'Билетопечатающий аппарат (БПА)',
  [EditableObjectType.Turnstile]: 'Турникет',
  [EditableObjectType.SecurityCheckpoint]: 'Пункт досмотра',
  [EditableObjectType.InformationTable]: 'Информационное табло',
  [EditableObjectType.Toilet]: 'Туалет',
  [EditableObjectType.Waiting]: 'Комната ожидания',
  [EditableObjectType.Restaurant]: 'Ресторан',
  [EditableObjectType.Buffet]: 'Буфет',
  [EditableObjectType.VendingMachine]: 'Вендинговый аппарат',
  [EditableObjectType.Store]: 'Магазин',
  [EditableObjectType.Kiosk]: 'Киоск',
  [EditableObjectType.Bank]: 'Банк',
  [EditableObjectType.Bankomat]: 'Банкомат',
  [EditableObjectType.Other]: 'Прочее',
  [RULER]: 'Линейка',
};
