import { SvgIcon, SvgIconProps } from '@mui/material';

export const InfoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.75C7.99594 4.75 4.75 7.99594 4.75 12C4.75 16.0041 7.99594 19.25 12 19.25C16.0041 19.25 19.25 16.0041 19.25 12C19.25 7.99594 16.0041 4.75 12 4.75ZM3.25 12C3.25 7.16751 7.16751 3.25 12 3.25C16.8325 3.25 20.75 7.16751 20.75 12C20.75 16.8325 16.8325 20.75 12 20.75C7.16751 20.75 3.25 16.8325 3.25 12Z"
        fill="currentColor"
      />
      <path
        d="M11.932 17.5898C11.5215 17.5898 11.1887 17.2571 11.1887 16.8466V10.6967C11.1887 10.2862 11.5215 9.95348 11.932 9.95348C12.3425 9.95348 12.6752 10.2862 12.6752 10.6967V16.8466C12.6752 17.2571 12.3425 17.5898 11.932 17.5898ZM11.9395 8.77521C11.6809 8.77521 11.4589 8.68904 11.2733 8.51669C11.091 8.34103 10.9998 8.13222 10.9998 7.89027C10.9998 7.645 11.091 7.4362 11.2733 7.26385C11.4589 7.08819 11.6809 7.00035 11.9395 7.00035C12.198 7.00035 12.4184 7.08819 12.6007 7.26385C12.7863 7.4362 12.8791 7.645 12.8791 7.89027C12.8791 8.13222 12.7863 8.34103 12.6007 8.51669C12.4184 8.68904 12.198 8.77521 11.9395 8.77521Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
