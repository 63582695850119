import { createBorderStyle } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/createBorderStyle';
import { SetFeatureStyleProps } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/getFeatureStyle';
import { Stroke, Style } from 'ol/style';

export type GetPlannedRailwayStyleArgs = Pick<
  SetFeatureStyleProps,
  'active' | 'width'
>;

export const getPlannedRailwayStyle = ({
  active,
  width,
}: GetPlannedRailwayStyleArgs) => [
  createBorderStyle({ active, featureWidth: width || 5 }),
  new Style({
    stroke: new Stroke({
      lineCap: 'square',
      color: '#5A5A5A99',
      width,
    }),
  }),
  new Style({
    stroke: new Stroke({
      lineDash: [4, 10],
      lineCap: 'square',
      color: '#FFFFFF99',
      width: 3,
    }),
  }),
];
