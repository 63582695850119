import { apiClient } from '@api/client';
import {
  IDownloadFileMutation,
  IDownloadFileTypeEnum,
  IFloorFileInfoType,
  IQuery,
} from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { TPU_CLIENT_NAME } from '@constants/api';

import { downloadFileFx } from '@features/tpu-calculation-scenarios-viewing/stores/download-file/download-file';
import {
  $Floors,
  getFloorsInfoFx,
  resetFloorFn,
  selectFloorFn,
  setBGUrlFn,
} from '@features/tpu-calculation-scenarios-viewing/stores/floors/floors.store';
import {
  $ScenarioData,
  getScenarioIdFn,
} from '@features/tpu-calculation-scenarios-viewing/stores/scenarioId/scenarioId.store';

import { base64ToBlob } from '@utils/fileConversion';

$Floors
  .reset(resetFloorFn)
  .on(getFloorsInfoFx.done, (state, { result }) => {
    if (!result) return { ...state, floorsInfo: [], selectedFloor: '' };

    return {
      floorsInfo: result,
      selectedFloor: result[0]?.extraId,
      backgroundUrl: '',
    };
  })
  .on(selectFloorFn, (state, payload) => {
    URL.revokeObjectURL(state.backgroundUrl);

    return { ...state, selectedFloor: payload, backgroundUrl: '' };
  })
  .on(setBGUrlFn, (state, payload) => {
    return { ...state, backgroundUrl: payload };
  });

sample({
  clock: downloadFileFx.done,
  filter: clockData => {
    const { result, params } = clockData;

    return !!(result && params?.isPlanBackground);
  },
  fn: clockData => {
    const result = clockData.result as IDownloadFileMutation;
    const fileData = result?.file || '';
    const blob = base64ToBlob(fileData, 'application/octet-stream');

    return URL.createObjectURL(blob);
  },
  target: setBGUrlFn,
});

sample({
  clock: selectFloorFn,
  source: $Floors,
  fn: (sourceData, clockData) => {
    const chosenFloor = sourceData.floorsInfo.find(
      floor => floor.extraId === clockData,
    );

    if (!chosenFloor) return null;

    return {
      objectId: chosenFloor.id,
      fileType: IDownloadFileTypeEnum.Floor,
      isPlanBackground: true,
    };
  },
  target: downloadFileFx,
});

sample({
  clock: getFloorsInfoFx.doneData,
  filter: (clockData): clockData is IFloorFileInfoType[] => !!clockData,
  fn: (clockData: IFloorFileInfoType[]) => ({
    objectId: clockData[0].id,
    fileType: IDownloadFileTypeEnum.Floor,
    isPlanBackground: true,
  }),
  target: downloadFileFx,
});

sample({
  clock: getScenarioIdFn,
  source: $ScenarioData,
  filter: ({ scenarioId }) => !!scenarioId,
  fn: scenarioData => scenarioData.scenarioId as string,
  target: getFloorsInfoFx,
});

getFloorsInfoFx.use(async id => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query FloorsInfo($id: UUID!) {
        scenarioById(id: $id) {
          project {
            floorFiles {
              id
              description
              extraId
            }
          }
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data.scenarioById?.project?.floorFiles ?? null;
});
