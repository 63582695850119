import { Maybe } from 'graphql/jsutils/Maybe';
import { PropsWithChildren, useMemo } from 'react';

import { Typography } from '@mui/material';

import {
  InspectorCircleIcon,
  InspectorCircleIconProps,
} from '@components/InspectorCircleIcon/InspectorCircleIcon';

import { BoxData } from '@features/passenger-traffic/components/Inspector/BoxData';

import { gapBetweenDigits } from '@utils/dataForInspector';

type ContainerTransportProps = PropsWithChildren & {
  title: string;
  icon: InspectorCircleIconProps;
  price: Maybe<number> | undefined;
  time: Maybe<number> | undefined;
  isExists?: boolean;
};

export const ContainerTransport = (props: ContainerTransportProps) => {
  const { title, icon, price, time, isExists = true, children } = props;

  const rightTime = useMemo(() => {
    if (time === undefined || time === null || +time === 0) return 'н/д';

    let minutes: string | number = time.toString().split('.')[1];

    if (minutes === undefined) {
      minutes = '00';
    } else if (minutes.toString().length === 1) {
      minutes = +minutes * 10;
    }
    minutes = minutes.toString().slice(0, 2);

    minutes = Math.round(+minutes * 0.6);

    return `${Math.trunc(time)}:${
      minutes.toString().length === 1
        ? '0' + minutes
        : minutes.toString().slice(0, 2)
    }`;
  }, [time]);

  const priceDisplay = useMemo(() => {
    if (price === undefined || price === null || +price === 0) return 'н/д';

    return gapBetweenDigits(price.toFixed(2)) + ' руб.';
  }, [price]);

  return (
    <>
      <BoxData
        name={title}
        icon={
          <InspectorCircleIcon
            borderColor={icon.borderColor}
            backgroundColor={icon.backgroundColor}
          />
        }
        sx={{
          pl: 2,
          columnGap: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      />
      {isExists ? (
        <>
          <BoxData
            name={'Время в пути'}
            value={rightTime}
            sx={{
              pt: 1.5,
              pl: 4.5,
            }}
          />

          <BoxData
            name={'Стоимость проезда'}
            value={priceDisplay}
            sx={{
              pt: 1.5,
              pl: 4.5,
            }}
          />

          {children && (
            <BoxData
              name={'Частота сообщения'}
              value={children}
              sx={{
                pt: 1.5,
                pl: 4.5,
              }}
            />
          )}
        </>
      ) : (
        <Typography
          variant="body2"
          sx={{ mt: 2, mx: 4.5 }}
        >
          Маршрут не существует
        </Typography>
      )}
    </>
  );
};
