import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import { palette } from '@system/theme/palette';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      gap: theme.typography.pxToRem(8),
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1.25),
      fontSize: theme.typography.pxToRem(12),
      boxShadow: '0px 2px 4px 0px #0000001A',
      backgroundColor: palette.common.white,
      color: palette.text.secondary,
    }),
  },
};
