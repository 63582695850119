import { IAgeType } from '@api/gql/types';
import { createApi, createStore } from 'effector';

const initStore: PassTrafficByAgeStore = [];

export const $PassTrafficByAge = createStore<PassTrafficByAgeStore>(initStore);

export const PassTrafficByAgeApi = createApi($PassTrafficByAge, {
  setPassTrafficByAge: (_, payload: PassTrafficByAgeStore) => payload,
  reset: () => initStore,
});

export type PassTrafficByAgeStore =
  | (IAgeType & { year: number | null })[]
  | null;
