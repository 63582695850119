import {
  FindRegionArgs,
  FindRegionByPrefixArgs,
  SetRegionArg,
  findRegionByPrefixFn,
  findRegionFn,
  resetAllFilters,
  setLayoutFn,
  setRegionFn,
  setStartPointFn,
} from '@features/passenger-traffic/stores';
import { getVehicleTypesForInspectorFn } from '@features/passenger-traffic/stores/inspector/store';
import { useUnit } from 'effector-react';

import {
  EFilterMapLayout,
  EFilterMapPoint,
  MapFilterField,
} from '@components/MapFilters/interface';

import { clearPointsFn, setMapFeatures } from '../../stores/map/store';
import {
  setMapBackdropFn,
  toggleFilterPanelFn,
  toggleInspectorPanelFn,
} from '../../stores/ui/store';

export type UsePassengerTrafficHandlers = {
  setMapBackdrop(value: boolean): void;
  changeLayout(layout: EFilterMapLayout): void;
  resetFilters(): void;
  setStartPoint(point: EFilterMapPoint): void;
  setStopPoint(): void;
  toggleInspectorPanel(): void;
  toggleFilterPanel(): void;
  findRegion(value: FindRegionArgs): void;
  findRegionByPrefix(value: FindRegionByPrefixArgs): void;
  setRegion(region: SetRegionArg): void;
  clearPoint(filter: MapFilterField): void;
  getVehicleTypesForInspector(): void;
};

export function usePTHandlers(): UsePassengerTrafficHandlers {
  const events = useUnit({
    setMapBackdropFn,
    /** запуск эвентов скрытия/отображения элементов карты согласно установленному фильтру */
    setLayoutFn,
    /** удаление установленной точки на карте */
    setStartPointFn,
    toggleInspectorPanelFn,
    toggleFilterPanelFn,
    findRegionFn,
    findRegionByPrefixFn,
    setRegionFn,
    setMapFeatures,
    /** удаление точек А и Б, и установленных точек инфраструктуры между ними */
    clearPointsFn,
    getVehicleTypesForInspectorFn,
    resetAllFilters,
  });

  const findRegion = (value: FindRegionArgs) => {
    events.findRegionFn(value);
  };

  const clearPoint = () => {
    events.clearPointsFn();
  };

  const findRegionByPrefix = (value: FindRegionByPrefixArgs) => {
    events.findRegionByPrefixFn(value);
  };

  const setRegion = (region: SetRegionArg) => {
    events.setRegionFn(region);
  };

  const setMapBackdrop = (value: boolean) => {
    events.setMapBackdropFn(value);
  };

  const changeLayout = (layout: EFilterMapLayout) => {
    events.setLayoutFn(layout);
  };

  const resetFilters = () => {
    events.resetAllFilters();
    events.setStartPointFn(undefined);
    events.clearPointsFn();
  };
  const setStartPoint = (point: EFilterMapPoint) => {
    events.setStartPointFn(point);
    events.setMapBackdropFn(true);
  };
  const setStopPoint = () => {
    events.setStartPointFn(undefined);
    events.setMapBackdropFn(false);
  };
  const toggleFilterPanel = () => {
    events.toggleFilterPanelFn();
  };

  const toggleInspectorPanel = () => {
    events.toggleInspectorPanelFn();
  };

  const getVehicleTypesForInspector = () => {
    events.getVehicleTypesForInspectorFn();
  };

  return {
    setMapBackdrop,
    changeLayout,
    resetFilters,
    setStartPoint,
    setStopPoint,
    toggleInspectorPanel,
    toggleFilterPanel,
    findRegion,
    findRegionByPrefix,
    setRegion,
    clearPoint,
    getVehicleTypesForInspector,
  };
}
