import { IAgentShareInfoType } from '@api/gql/tpu-types';
import { Control, Controller, FieldPath } from 'react-hook-form';

import { Slider } from '@mui/material';

import { COLORS } from '@features/tpu-passenger-types/constants';
import { IPassengerShareForm } from '@features/tpu-passenger-types/types';

interface SlideShareProps {
  control: Control<IPassengerShareForm>;
  fieldName: FieldPath<IPassengerShareForm>;
  agentShareInfo: IAgentShareInfoType;
  recount: (id: string) => void;
}

export const SliderShare = ({
  control,
  fieldName,
  agentShareInfo,
  recount,
}: SlideShareProps) => {
  const { id } = agentShareInfo;
  const color = agentShareInfo.color
    ? COLORS[agentShareInfo.color]
    : 'transparent';

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{ max: 100, min: 0 }}
      render={({ field }) => (
        <Slider
          {...field}
          onChangeCommitted={() => {
            recount(id);
          }}
          onChange={e => {
            const value = parseInt((e.target as HTMLInputElement).value) || 0;
            field.onChange(value);
          }}
          size="small"
          min={0}
          max={100}
          color="secondary"
          sx={{
            position: 'relative',
            '.MuiSlider-rail': {
              height: '2px',
              background: color,
            },
            '.MuiSlider-track': {
              display: 'none',
            },
            '.MuiSlider-thumb': {
              width: '32px',
              height: '16px',
              backgroundColor: color,
              boxShadow: 'none',
              borderRadius: '8px',
              '&:hover': {
                boxShadow: 'none',
              },
            },
            '.MuiSlider-root': {
              padding: '22px 0',
            },
            '&:before': {
              content: "''",
              display: 'block',
              width: '16px',
              height: '16px',
              position: 'absolute',
              borderRadius: '50%',
              top: '5px',
              left: '-30px',
              backgroundColor: color,
              zIndex: '1',
            },
            '&:after': {
              content: "''",
              display: 'block',
              width: '14px',
              height: '18px',
              position: 'absolute',
              top: '5px',
              left: '-15px',
              backgroundColor: '#fff',
            },
          }}
        />
      )}
    />
  );
};
