import Konva from 'konva';
import { useEffect, useRef } from 'react';
import { Image, Transformer } from 'react-konva';
import useImage from 'use-image';

import BankIcon from '@components/icons/Bank.svg';
import BankomatIcon from '@components/icons/Bankomat.svg';
import BuffetIcon from '@components/icons/Buffet.svg';
import CartIcon from '@components/icons/Cart.svg';
import DoorIcon from '@components/icons/Door.svg';
import ElevatorIcon from '@components/icons/Elevator.svg';
import ExitIcon from '@components/icons/Exit.svg';
import InformationTableIcon from '@components/icons/InformationTable.svg';
import KioskIcon from '@components/icons/Kiosk.svg';
import PeopleIcon from '@components/icons/People.svg';
import RestaurantIcon from '@components/icons/Restaurant.svg';
import SecurityCheckpointIcon from '@components/icons/SecurityCheckpoint.svg';
import StoreIcon from '@components/icons/Store.svg';
import TicketPrintingMachineIcon from '@components/icons/TicketPrintingMachine.svg';
import TicketsIcon from '@components/icons/Tickets.svg';
import ToiletIcon from '@components/icons/Toilet.svg';
import TurnstileIcon from '@components/icons/Turnstile.svg';
import VendingIcon from '@components/icons/Vending.svg';
import WaitingRoomIcon from '@components/icons/WaitingRoom.svg';

import {
  EditableObjectType,
  FlexibleObjects,
  IShape,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

interface FlexibleObjectProps {
  data: FlexibleObjects;
  isSelected: boolean;
  onSelect: (arg: ObjectOfTheSimulationModel) => void;
  updateObjectData: (arg: ObjectOfTheSimulationModel) => void;
}

const ICON = {
  [EditableObjectType.Toilet]: ToiletIcon,
  [EditableObjectType.Waiting]: WaitingRoomIcon,
  [EditableObjectType.Turnstile]: TurnstileIcon,
  [EditableObjectType.SecurityCheckpoint]: SecurityCheckpointIcon,
  [EditableObjectType.InformationTable]: InformationTableIcon,
  [EditableObjectType.TicketPrintingMachine]: TicketPrintingMachineIcon,
  [EditableObjectType.TicketOffice]: TicketsIcon,
  [EditableObjectType.Exit]: ExitIcon,
  [EditableObjectType.Restaurant]: RestaurantIcon,
  [EditableObjectType.Bank]: BankIcon,
  [EditableObjectType.Bankomat]: BankomatIcon,
  [EditableObjectType.VendingMachine]: VendingIcon,
  [EditableObjectType.Buffet]: BuffetIcon,
  [EditableObjectType.Store]: StoreIcon,
  [EditableObjectType.Kiosk]: KioskIcon,
  [EditableObjectType.Other]: CartIcon,
  [EditableObjectType.Door]: DoorIcon,
  [EditableObjectType.PassengerGenerator]: PeopleIcon,
  [EditableObjectType.Elevator]: ElevatorIcon,
};

export const FlexibleObject = ({
  isSelected,
  onSelect,
  updateObjectData,
  data,
}: FlexibleObjectProps) => {
  const [icon] = useImage(ICON[data.type]);

  const shapeRef = useRef<Konva.Image | null>(null);
  const trRef = useRef<Konva.Transformer | null>(null);

  const { point, width, height, rotation } = data;

  const onChangeShape = ({ x, y, width, height, rotation }: IShape) => {
    updateObjectData({ ...data, point: { x, y }, width, height, rotation });
  };

  useEffect(() => {
    if (isSelected && trRef.current && shapeRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer()?.batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Image
        x={point.x}
        y={point.y}
        width={width}
        height={height}
        image={icon}
        rotation={rotation}
        draggable
        ref={shapeRef}
        onDragEnd={e => {
          onChangeShape({
            width,
            height,
            rotation,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={() => {
          const node = shapeRef.current;
          if (!node) return;

          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          node.scaleX(1);
          node.scaleY(1);

          onChangeShape({
            x: Math.round(node.x()),
            y: Math.round(node.y()),
            width: Math.max(24, Math.round(node.width() * scaleX)),
            height: Math.max(24, Math.round(node.height() * scaleY)),
            rotation: Math.round(node.rotation()),
          });
        }}
        onClick={e => {
          e.cancelBubble = true;
          onSelect(data);
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          rotationSnaps={[0, 90, 180, 270]}
          boundBoxFunc={(oldBox, newBox) => {
            if (Math.abs(newBox.width) < 12 || Math.abs(newBox.height) < 12) {
              return oldBox;
            }

            return newBox;
          }}
        />
      )}
    </>
  );
};
