import { createEvent, createStore } from 'effector';

import { EVehicle } from '@features/pt-forecast/constants/vehiclesEnum';

export type DailyUnevennessType = {
  dailyUnevennessVehicle: Record<
    keyof typeof EVehicle,
    Record<EDailyUnevennessTime, number | null>
  > | null;
  dailyUnevennessSummary: number[];
};

export const $DailyUnevenness = createStore<DailyUnevennessType>({
  dailyUnevennessVehicle: null,
  dailyUnevennessSummary: [],
});

export const setDailyUnevennessVehicle =
  createEvent<DailyUnevennessType['dailyUnevennessVehicle']>();
export const setDailyUnevennessSummary =
  createEvent<DailyUnevennessType['dailyUnevennessSummary']>();

export const resetDailyUnevenness = createEvent();

$DailyUnevenness
  .on(setDailyUnevennessVehicle, (state, payload) => ({
    ...state,
    dailyUnevennessVehicle: payload,
  }))
  .on(setDailyUnevennessSummary, (state, payload) => ({
    ...state,
    dailyUnevennessSummary: payload,
  }))
  .reset(resetDailyUnevenness);

export enum EDailyUnevennessTime {
  '0:00 - 3:00' = '0:00 - 3:00',
  '3:00 - 6:00' = '3:00 - 6:00',
  '6:00 - 9:00' = '6:00 - 9:00',
  '9:00 - 12:00' = '9:00 - 12:00',
  '12:00 - 15:00' = '12:00 - 15:00',
  '15:00 - 18:00' = '15:00 - 18:00',
  '18:00 - 21:00' = '18:00 - 21:00',
  '21:00 - 00:00' = '21:00 - 00:00',
}

export const DailyUnevennessTimeList: EDailyUnevennessTime[] = [
  EDailyUnevennessTime['0:00 - 3:00'],
  EDailyUnevennessTime['3:00 - 6:00'],
  EDailyUnevennessTime['6:00 - 9:00'],
  EDailyUnevennessTime['9:00 - 12:00'],
  EDailyUnevennessTime['12:00 - 15:00'],
  EDailyUnevennessTime['15:00 - 18:00'],
  EDailyUnevennessTime['18:00 - 21:00'],
  EDailyUnevennessTime['21:00 - 00:00'],
];
