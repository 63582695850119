import { Box } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { DashboardCard } from '@features/pt-forecast-new/components/Dashboards/DashboardCard';
import { IncomeByGroupsChart } from '@features/pt-forecast-new/components/Dashboards/IncomeByGroupsChart/IncomeByGroupsChart';
import { PassengerTrafficByAgeChart } from '@features/pt-forecast-new/components/Dashboards/PassengerTrafficByAgeChart/PassengerTrafficByAgeChart';
import { PassengerTrafficBySexChart } from '@features/pt-forecast-new/components/Dashboards/PassengerTrafficBySexChart/PassengerTrafficBySexChart';
import { StructurePassChartContainer } from '@features/pt-forecast-new/containers/ChartsContainer/StructurePassChartContainer/StructurePassChartContainer';

export const FirstPageDashboardContainer = () => {
  return (
    <Box
      sx={{
        borderTop: theme => `1px solid ${theme.palette.border.divider}`,
        display: 'flex',
      }}
    >
      <AutoFullHeight
        style={{
          padding: '24px',
          width: 'calc(100% - 500px)',
          marginLeft: '500px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '24px',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              columnGap: '24px',
              width: '100%',
              height: 'calc(50% - 55px)',
            }}
          >
            <Box sx={{ width: '65%', height: '100%' }}>
              <DashboardCard
                title="Среднедушевой доход по двадцати-процентным группам населения (руб.)"
                sx={{
                  minHeight: '180px',
                  height: '100%',
                }}
              >
                <IncomeByGroupsChart />
              </DashboardCard>
            </Box>
            <Box sx={{ width: '35%', height: '100%' }}>
              <DashboardCard
                title="Диаграмма распределения пассажиропотока по возрасту"
                sx={{
                  minHeight: '180px',
                  height: '100%',
                }}
              >
                <PassengerTrafficByAgeChart />
              </DashboardCard>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              columnGap: '24px',
              width: '100%',
              height: 'calc(50% - 55px)',
            }}
          >
            <Box sx={{ width: '65%', height: '100%' }}>
              <DashboardCard
                title="Структура пассажиропотока по видам транспорта"
                sx={{
                  minHeight: '180px',
                  height: '100%',
                  width: '100%',
                  overflow: 'auto',
                }}
              >
                <StructurePassChartContainer />
              </DashboardCard>
            </Box>

            <Box sx={{ width: '35%', height: '100%' }}>
              <DashboardCard
                title="Диаграмма распределения пассажиропотока по полу"
                sx={{
                  minHeight: '180px',
                  height: '100%',
                }}
              >
                <PassengerTrafficBySexChart />
              </DashboardCard>
            </Box>
          </Box>
        </Box>
      </AutoFullHeight>
    </Box>
  );
};
