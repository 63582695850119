import { useUnit } from 'effector-react';

import { $ListOfWaysPlatforms } from '@features/tpu-simulation-model/store';
import { Option } from '@features/tpu-simulation-model/utils/getAttributeOptions';

interface ListOfObjects {
  platformOptions: Option[] | [];
  wayOptions: Option[] | [];
}

export const useWaysPlatforms = (): ListOfObjects => {
  const listOfPlatforms = useUnit($ListOfWaysPlatforms).platforms as [];
  const platformOptions = listOfPlatforms?.map(
    item =>
      ({
        value: item as string,
        label: item as string,
      }) ?? [],
  );

  const listOfWays = useUnit($ListOfWaysPlatforms).ways as [];
  const wayOptions =
    listOfWays.map(item => ({
      value: item,
      label: item,
    })) ?? [];

  return {
    platformOptions,
    wayOptions,
  };
};
