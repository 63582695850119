import { SvgIcon, SvgIconProps } from '@mui/material';

export const LocationIcon = (props: SvgIconProps & { text: string }) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 29 40"
      sx={{ fontSize: '40px', height: '40px', width: '29px', ...props.sx }}
    >
      <path
        fill={'#E21A1A'}
        stroke={'white'}
        strokeWidth={'1px'}
        d="M14.48,0c-2.87,0-5.67,0.86-8.05,2.45c-2.38,1.6-4.24,3.87-5.34,6.53s-1.39,5.58-0.83,8.4s1.94,5.41,3.96,7.45
	C6.84,27.46,14.11,36,14.48,40c0.37-4,7.63-12.53,10.25-15.16c2.03-2.03,3.41-4.63,3.97-7.45c0.56-2.82,0.27-5.75-0.82-8.4
	c-1.1-2.66-2.96-4.93-5.34-6.53C20.15,0.86,17.34,0,14.48,0z"
      />
      <path
        fill={'#ffffff'}
        d="M14.5,26.57c-1.58,0-3.14-0.31-4.59-0.92c-1.46-0.6-2.78-1.49-3.89-2.61c-1.11-1.12-2-2.44-2.6-3.9
	c-0.6-1.46-0.91-3.02-0.91-4.6s0.31-3.14,0.91-4.6c0.6-1.46,1.49-2.78,2.6-3.9c1.11-1.12,2.44-2,3.89-2.61
	c1.46-0.6,3.02-0.92,4.59-0.92c3.18,0,6.23,1.27,8.49,3.52c2.25,2.25,3.51,5.31,3.51,8.5s-1.26,6.25-3.51,8.5
	C20.73,25.3,17.68,26.57,14.5,26.57z"
      />
      {props.text && (
        <text
          transform="matrix(1 0 0 1 10.6884 17.6431)"
          style={{
            fontSize: '12px',
          }}
        >
          {props.text}
        </text>
      )}
    </SvgIcon>
  );
};
