import { v4 as uuid } from 'uuid';

import {
  EditableObjectType,
  IExit,
  IPassengerGenerator,
} from '@features/tpu-simulation-model/types';

import {
  IEntranceExitErrorAnalyzer,
  PlanError,
} from '@utils/simulationModel/errors-analyzer/types';

export class EntranceExitErrorAnalyzer implements IEntranceExitErrorAnalyzer {
  _entrances = new Set<IPassengerGenerator>();
  _exits = new Set<IExit>();
  _sumOfEntrancesShare = 0;
  _sumOfExitsShare = 0;
  _errors = new Set<PlanError>();

  reset() {
    this._entrances.clear();
    this._exits.clear();
    this._errors.clear();
    this._sumOfEntrancesShare = 0;
    this._sumOfExitsShare = 0;
  }

  addEntrance(data: IPassengerGenerator) {
    this._entrances.add(data);
  }

  addExit(data: IExit) {
    this._exits.add(data);
  }

  getErrors() {
    this._entrances.forEach(entrance => {
      this._sumOfEntrancesShare += Math.round(entrance.share * 100);
    });
    this._exits.forEach(exit => {
      this._sumOfExitsShare += Math.round(exit.share * 100);
    });

    if (!this._entrances.size) {
      this._errors.add({
        objectType: EditableObjectType.PassengerGenerator,
        id: uuid(),
        description: 'На плане отсутствуют входы',
      });
    }

    if (!this._exits.size) {
      this._errors.add({
        objectType: EditableObjectType.PassengerGenerator,
        id: uuid(),
        description: 'На плане отсутствуют выходы',
      });
    }

    if (this._sumOfEntrancesShare !== 100) {
      this._errors.add({
        objectType: EditableObjectType.PassengerGenerator,
        objects: Array.from(this._entrances.values()),
        description:
          'Сумма значений параметра "Доля пассажиров" для всех видов добавленных входов не равна 100%',
        id: uuid(),
      });
    }

    if (this._sumOfExitsShare !== 100) {
      this._errors.add({
        objectType: EditableObjectType.Exit,
        objects: Array.from(this._exits.values()),
        description:
          'Сумма значений параметра "Доля пассажиров" для всех видов добавленных выходов не равна 100%',
        id: uuid(),
      });
    }

    return Array.from(this._errors);
  }
}
