import { Button, Stack } from '@mui/material';

import { MinusIcon, PlusIcon } from '@components/icons';

interface ScaleProps {
  onCenter: () => void;
  increment: () => void;
  decrement: () => void;
}

export const Scale = ({ increment, onCenter, decrement }: ScaleProps) => {
  return (
    <Stack
      direction={'column'}
      sx={{
        gridArea: 'scale',
        marginLeft: 'auto',
        gap: '8px',
        pointerEvents: 'all',
      }}
    >
      <Button
        sx={{
          minWidth: 'unset',
          width: '44px',
          height: '44px !important',
          backgroundColor: theme => theme.palette.background.paper,
          boxShadow: '0px 0px 8px 0px #00000026',
        }}
        variant={'text'}
        onClick={increment}
      >
        <PlusIcon />
      </Button>
      <Button
        sx={{
          minWidth: 'unset',
          width: '44px',
          height: '44px !important',
          backgroundColor: theme => theme.palette.background.paper,
          boxShadow: '0px 0px 8px 0px #00000026',
          fontSize: theme => theme.typography.pxToRem(12),
          lineHeight: theme => theme.typography.pxToRem(16),
        }}
        variant={'text'}
        onClick={onCenter}
      >
        100%
      </Button>
      <Button
        sx={{
          minWidth: 'unset',
          width: '44px',
          height: '44px !important',
          backgroundColor: theme => theme.palette.background.paper,
          boxShadow: '0px 0px 8px 0px #00000026',
        }}
        variant={'text'}
        onClick={decrement}
      >
        <MinusIcon />
      </Button>
    </Stack>
  );
};
