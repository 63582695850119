import { IQuery, IQueryGetTransportsRegionStarArgs } from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

const initState: DistrictPassFlowStore = {
  directions: [],
  maxFlow: 0,
};

export const $DistrictPassFlow = createStore<DistrictPassFlowStore>(initState);

export const DistrictPassFlowApi = createApi($DistrictPassFlow, {
  setDirections: (store, payload: SetDirectionsPayload) => ({
    ...store,
    ...payload,
  }),
  clearDirections: store => ({
    ...store,
    directions: [],
    maxFlow: 0,
  }),
  reset: () => initState,
});

export const getDistrictPassFlowFx = createEffect<
  IQueryGetTransportsRegionStarArgs,
  IQuery['getTransportsRegionStar']
>();

export type SetDirectionsPayload = {
  directions: DirectionType[];
  maxFlow: number;
};

export type DirectionType = {
  id: string;
  fromPoint: number[];
  toPoint: number[];
  flow: number;
  name: string;
};

export type DistrictPassFlowStore = {
  directions: DirectionType[];
  maxFlow: number;
};
