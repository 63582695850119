import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const WaitingRoomIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        d="M22.768 16.62c0-1.004-.543-1.623-1.55-1.623l-2.943-.077v-1.47h3.64c0-1.005-.387-1.392-1.394-1.392h-2.324V10.28C18.43 7.186 15.1 6.8 14.79 9.197v7.81c0 .31.232.542.542.542h5.345c.155.696.31 3.17.465 4.33.155.928.93 1.238 1.859 1.083l-.232-6.341ZM16.26 7.033c.93 0 1.626-.696 1.626-1.624 0-.928-.697-1.624-1.626-1.624-.93 0-1.627.696-1.627 1.624 0 .85.697 1.624 1.627 1.624Zm-6.12 3.17-.078 4.64-2.71.078c-.466 0-.776.154-1.085.309l-4.648 4.33c.542.696 1.317 1.083 2.091.542.465-.387 1.317-1.005 2.014-1.624l-.155 4.408c.853.077 1.705-.155 1.86-1.083.155-1.16.387-3.634.464-4.33h5.113a.53.53 0 0 0 .542-.542V9.12c-.31-2.397-3.64-2.01-3.408 1.082ZM4.02 1.696a2.329 2.329 0 0 1 2.324 2.32c0 1.315-1.007 2.32-2.324 2.32a2.329 2.329 0 0 1-2.324-2.32C1.62 2.7 2.704 1.696 4.021 1.696Zm0 5.336c1.627 0 3.021-1.315 3.021-3.016a3.022 3.022 0 0 0-6.042 0 3.022 3.022 0 0 0 3.021 3.016Zm.155-2.63 1.162-.54c.155-.078.155-.155.155-.31-.078-.155-.232-.31-.387-.232l-.775.31V2.47c0-.387-.62-.387-.62 0v1.623c0 .232.233.387.465.31Zm7.746-.618c-.929 0-1.626.696-1.626 1.624 0 .928.697 1.624 1.627 1.624s1.626-.696 1.626-1.624c0-.928-.774-1.624-1.627-1.624Z"
        fill="#3679EA"
      />
    </SvgIcon>
  );
};
