import { useUnit } from 'effector-react';
import { createElement } from 'react';

import {
  Bank,
  Bankomat,
  Buffet,
  Door,
  Elevator,
  Exit,
  InformationTable,
  Kiosk,
  Ladder,
  Other,
  PassengerGenerator,
  Path,
  Platform,
  Restaurant,
  Room,
  SecurityCheckpoint,
  Store,
  TicketOffice,
  TicketPrintingMachine,
  Toilet,
  Turnstile,
  VendingMachine,
  Waiting,
  Wall,
} from '@features/tpu-simulation-model/form';
import { useSimulationModelHandlers } from '@features/tpu-simulation-model/hooks';
import { $EditedObject } from '@features/tpu-simulation-model/store';
import {
  EditableObjectType,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

export const EditingObjectForm = () => {
  const $editedObject = useUnit($EditedObject);
  const { updateObjectData, resetEditableObjectData, deleteObject } =
    useSimulationModelHandlers();

  const data = $editedObject.data;

  const onUpdateAndClose = (newData: ObjectOfTheSimulationModel) => {
    updateObjectData(newData);
    resetEditableObjectData();
  };

  const onDeleteAndClose = (id: string) => {
    deleteObject(id);
    resetEditableObjectData();
  };

  switch (data?.type) {
    case EditableObjectType.Room:
      return createElement(Room, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Wall:
      return createElement(Wall, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });

    case EditableObjectType.Ladder:
      return createElement(Ladder, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Platform:
      return createElement(Platform, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Door:
      return createElement(Door, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Elevator:
      return createElement(Elevator, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Path:
      return createElement(Path, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.PassengerGenerator:
      return createElement(PassengerGenerator, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Exit:
      return createElement(Exit, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.TicketOffice:
      return createElement(TicketOffice, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.TicketPrintingMachine:
      return createElement(TicketPrintingMachine, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Turnstile:
      return createElement(Turnstile, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.SecurityCheckpoint:
      return createElement(SecurityCheckpoint, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.InformationTable:
      return createElement(InformationTable, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Toilet:
      return createElement(Toilet, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Waiting:
      return createElement(Waiting, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Restaurant:
      return createElement(Restaurant, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Buffet:
      return createElement(Buffet, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Bankomat:
      return createElement(Bankomat, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Kiosk:
      return createElement(Kiosk, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Bank:
      return createElement(Bank, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Other:
      return createElement(Other, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.VendingMachine:
      return createElement(VendingMachine, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    case EditableObjectType.Store:
      return createElement(Store, {
        onUpdateAndClose,
        onDeleteAndClose,
        ...data,
      });
    default:
      return null;
  }
};
