import { createEvent, sample } from 'effector';

import { FilterMapApi } from '@features/ag-forecast/stores/filters';
import { MainTpuOptionsApi } from '@features/ag-forecast/stores/lists/mainTpuOptions';
import { PassFlowTableContentApi } from '@features/ag-forecast/stores/lists/passFlowTableContent';
import { RegionOptionsApi } from '@features/ag-forecast/stores/lists/regionsOptions';
import { SearchOptionsApi } from '@features/ag-forecast/stores/lists/searchOptions';
import { TimeSlotsApi } from '@features/ag-forecast/stores/lists/timeSlots';
import { TransportCategoriesApi } from '@features/ag-forecast/stores/lists/transportCategories';
import { VehiclesApi } from '@features/ag-forecast/stores/lists/vehicles';
import { UIApi } from '@features/ag-forecast/stores/ui';

export const storesReset = createEvent();

sample({
  clock: storesReset,
  target: [
    FilterMapApi.reset,
    MainTpuOptionsApi.reset,
    PassFlowTableContentApi.reset,
    RegionOptionsApi.reset,
    SearchOptionsApi.reset,
    TimeSlotsApi.reset,
    TransportCategoriesApi.reset,
    VehiclesApi.reset,
    UIApi.reset,
  ],
});
