import {
  IAirportType,
  IBusStationType,
  IHighwayHighwayTypeChoices,
  IHighwayType,
  IQuery,
  IRailwayRailwayStatusChoices,
  IRailwayRailwayTractionTypeChoices,
  IRailwayStationType,
  IRailwayType,
  ITransportRegionType,
  Maybe,
} from '@api/gql/types';
import { createApi, createEffect, createStore } from 'effector';

import { GetObjectType } from '../store';

export type InfrastructureInspectorType = {
  railway: IQuery['getRailwayById'];
  railwayStation: IQuery['getRailwayStationById'];
  highway: IQuery['getHighwayById'];
  busStation: IQuery['getBusStationById'];
  airport: IQuery['getAirportById'];
  transportRegion: IQuery['getRegionById'];
};

export type IQueryGetInfrastructureByIdArgs = GetObjectType;

const initState: InfrastructureInspectorType = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  railway: {
    capacity: null,
    country: null,
    details: [],
    direction: [],
    geometry: null,
    id: null,
    level: null,
    objectId: null,
    pointFrom: null,
    pointTo: null,
    railwayName: null,
    speed: null,
    station1: null,
    station2: null,
    status: IRailwayRailwayStatusChoices.A_1,
    tracks: null,
    tractionType: IRailwayRailwayTractionTypeChoices.A_1,
    prjId: null,
    prjSectionId: null,
    scenarioStations: [],
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  railwayStation: {
    code: null,
    details: [],
    geometry: null,
    id: null,
    isTerminal: false,
    name: '',
    objectId: null,
    railwayFrom: [],
    railwayTo: [],
    timetable: [],
    timetableEnd: [],
    timetableStPrev: [],
    timetableStart: [],
    trainTimetable: [],
    trainTimetableEnd: [],
    trainTimetableStart: [],
    trainTimetableStPrev: [],
    transportRegion: null,
    prjId: null,
    scenarioStations: [],
  },
  highway: {
    capacity: null,
    country: null,
    details: [],
    direction: [],
    geometry: null,
    highwayName: null,
    id: null,
    lanes: null,
    lengthKm: null,
    objectId: null,
    payment: false,
    pointEnd: null,
    pointStart: null,
    regionFrom: null,
    regionTo: null,
    speed: null,
    prjId: null,
    oneSection: false,
    prjLanes: null,
    prjName: null,
    prjSectionId: null,
    prjSectionName: null,
    prjYear: null,
    prjSpeed: null,
    prjCapacity: null,
    type: IHighwayHighwayTypeChoices.A_1,
  },
  busStation: {
    code: null,
    details: [],
    geometry: null,
    id: null,
    name: '',
    objectId: null,
    passTraffic: null,
    region: '',
    show: false,
    transportRegion: null,
    year: null,
    busTimetable: [],
    busTimetableEnd: [],
    busTimetableStart: [],
    busTimetableStPrev: [],
  },
  airport: {
    name: null,
    details: [],
    geometry: null,
    id: null,
    objectId: null,
    region: null,
    show: false,
    transportRegion: null,
    planeTimetable: [],
    planeTimetableEnd: [],
    planeTimetableStart: [],
    planeTimetableStPrev: [],
  },
  transportRegion: {
    airportSet: [],
    border: null,
    busstationSet: [],
    center: null,
    details: [],
    directionsFrom: [],
    directionsTo: [],
    gravmodelSet: [],
    heighwaysFrom: [],
    heighwaysTo: [],
    id: '',
    name: '',
    scenarios: [],
    railwaystationSet: [],
    railwaystationinstanceSet: [],
    trId: 0,
  },
};

export const $InfrastructureInspector =
  createStore<InfrastructureInspectorType>(initState);

export const InfrastructureInspectorApi = createApi($InfrastructureInspector, {
  setRailwayForInspector: (store, payload: SetRailwayForInspectorPayload) => ({
    ...store,
    railway: payload.result,
  }),
  setRailwayStationForInspector: (
    store,
    payload: SetRailwayStationForInspectorPayload,
  ) => ({
    ...store,
    railwayStation: payload.result,
  }),
  setHighwayForInspector: (store, payload: SetHighwayForInspectorPayload) => ({
    ...store,
    highway: payload.result,
  }),
  setBusStationForInspector: (
    store,
    payload: SetBusStationForInspectorPayload,
  ) => ({
    ...store,
    busStation: payload.result,
  }),
  setAirportForInspector: (store, payload: SetAirportForInspectorPayload) => ({
    ...store,
    airport: payload.result,
  }),
  setTransportRegionForInspector: (
    store,
    payload: SetTransportRegionForInspectorPayload,
  ) => ({
    ...store,
    transportRegion: payload.result,
  }),
  reset: () => initState,
});

// Эффекты для получения данных по выбранному типу объекта карты
export const getRailwayForInspectorFx = createEffect<
  IQueryGetInfrastructureByIdArgs,
  Maybe<IRailwayType>
>();
export const getRailwayStationForInspectorFx = createEffect<
  IQueryGetInfrastructureByIdArgs,
  Maybe<IRailwayStationType>
>();
export const getHighwayForInspectorFx = createEffect<
  IQueryGetInfrastructureByIdArgs,
  Maybe<IHighwayType>
>();
export const getBusStationForInspectorFx = createEffect<
  IQueryGetInfrastructureByIdArgs,
  Maybe<IBusStationType>
>();
export const getAirportForInspectorFx = createEffect<
  IQueryGetInfrastructureByIdArgs,
  Maybe<IAirportType>
>();
export const getRegionForInspectorFx = createEffect<
  IQueryGetInfrastructureByIdArgs,
  Maybe<ITransportRegionType>
>();

type SetRailwayForInspectorPayload = {
  result: Maybe<IRailwayType>;
  params: GetObjectType;
};

type SetRailwayStationForInspectorPayload = {
  result: Maybe<IRailwayStationType>;
  params: GetObjectType;
};

type SetHighwayForInspectorPayload = {
  result: Maybe<IHighwayType>;
  params: GetObjectType;
};

type SetBusStationForInspectorPayload = {
  result: Maybe<IBusStationType>;
  params: GetObjectType;
};

type SetAirportForInspectorPayload = {
  result: Maybe<IAirportType>;
  params: GetObjectType;
};

type SetTransportRegionForInspectorPayload = {
  result: Maybe<ITransportRegionType>;
  params: GetObjectType;
};
