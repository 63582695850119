import { ResponsiveContainer, Tooltip, TooltipProps, Treemap } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

import { Box, Typography } from '@mui/material';

import { dividingNumber } from '@utils/dividingNumber';

interface DataItem {
  name: string;
  size: number;
  unit: string;
  color: string;
}
export const BlocksChart = ({ data }: { data: DataItem[] }) => {
  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            padding: '8px 14px',
            backgroundColor: '#fff',
            borderRadius: '5px',
          }}
        >
          <Typography
            sx={{
              fontSize: '10px',
              color: '#606060',
            }}
          >{`${payload[0].payload.name}: ${dividingNumber(
            payload[0].value as number,
          )} ${payload[0].payload.unit}`}</Typography>
        </Box>
      );
    }

    return null;
  };

  const CustomizedContent = (props: {
    color?: string;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    name?: string;
  }) => {
    const { color, x = 0, y = 0, width = 0, height = 0, name } = props;

    return (
      <g
        style={{
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: color,
          }}
        />
        <foreignObject
          x={x}
          y={y}
          width={width}
          height={height}
        >
          <Typography
            sx={{
              fontSize: '12px',
              textAlign: 'center',
              position: 'relative',
              top: '50%',
              transform: 'translateY(-50%)',
              wordBreak: 'break-word',
            }}
          >
            {name}
          </Typography>
        </foreignObject>
      </g>
    );
  };

  return (
    <ResponsiveContainer
      width="100%"
      height="50%"
    >
      <Treemap
        width={730}
        height={250}
        data={data}
        dataKey="size"
        aspectRatio={4 / 3}
        stroke="#fff"
        fill="#8884d8"
        content={<CustomizedContent />}
      >
        <Tooltip content={<CustomTooltip />} />
      </Treemap>
    </ResponsiveContainer>
  );
};
