import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Avatar,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';

import { ClearIcon, ReturnIcon } from '@components/icons';

import User1Img from '../../../../../assets/User1.png';
import User2Img from '../../../../../assets/User2.png';

export const Share = () => {
  return (
    <Grid
      display={'flex'}
      direction={'column'}
      container
      rowGap={2}
    >
      <Typography variant="body2">
        Выберите пользователя для доступа к вашему расчету:
      </Typography>

      <Autocomplete
        sx={{ flex: 1 }}
        id="share-select"
        disableClearable
        options={[{ label: 'Юзер', value: 'base' }]}
        forcePopupIcon={false}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Найти пользователя"
          />
        )}
      />
      <Typography variant="body2">
        Пользователи, у которых есть доступ к расчету:
      </Typography>

      <Grid
        container
        item
        display={'flex'}
        direction={'column'}
        rowGap={1}
      >
        <Grid
          container
          item
          display={'flex'}
          alignItems={'center'}
          wrap="nowrap"
          height={theme => theme.typography.pxToRem(44)}
          columnGap={3}
          px={1.5}
          sx={{
            '&:hover': {
              bgcolor: 'customs.bgHover',
              borderRadius: '4px',
            },
          }}
        >
          <Avatar
            alt="Remy Sharp"
            src={User1Img}
          />

          <Typography
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            maxWidth={'calc(100% - 342px)'}
            display={'block'}
            variant="body2"
          >
            Марина Петрова
          </Typography>

          <Grid
            container
            display={'flex'}
            alignItems={'center'}
            wrap={'nowrap'}
            justifyContent={'end'}
            columnGap={3}
            width={'auto'}
            ml={'auto'}
          >
            <Typography variant="caption">Доступ запрещен</Typography>

            <Link fontSize={theme => theme.typography.pxToRem(12)}>
              отменить
            </Link>

            <IconButton>
              <ReturnIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Grid
          container
          item
          display={'flex'}
          alignItems={'center'}
          wrap="nowrap"
          height={theme => theme.typography.pxToRem(44)}
          columnGap={3}
          px={1.5}
          sx={{
            '&:hover': {
              bgcolor: 'customs.bgHover',
              borderRadius: '4px',
            },
          }}
        >
          <Avatar
            alt="Remy Sharp"
            src={User2Img}
          />

          <Typography
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            maxWidth={'calc(100% - 342px)'}
            display={'block'}
            variant="body2"
          >
            Иван Петров
          </Typography>

          <Grid
            container
            display={'flex'}
            alignItems={'center'}
            wrap={'nowrap'}
            justifyContent={'end'}
            columnGap={3}
            width={'auto'}
            ml={'auto'}
          >
            <IconButton>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
