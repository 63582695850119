import { TPUObjectType } from './configs/bodyContent';

import {
  Box,
  LinearProgress,
  Stack,
  SxProps,
  TableCell,
  TableRow,
  Theme,
  linearProgressClasses,
} from '@mui/material';

import { gapBetweenDigits } from '@utils/dataForInspector';

type TableComparisonTPURowType = {
  item: TPUObjectType;
  maxTTC: number;
  maxPassTraffic: number;
};

const rowStyleSX: SxProps<Theme> = {
  fontSize: theme => theme.typography.pxToRem(12),
  outline: '1px solid rgba(224, 224, 224, 1)',
  outlineOffset: '-1px',
  border: 0,
  ['&:last-of-type']: {
    outline: '1px solid rgba(224, 224, 224, 1)',
    outlineOffset: '-1px',
  },
};

export const TableComparisonTPURow = (props: TableComparisonTPURowType) => {
  const { item, maxTTC, maxPassTraffic } = props;

  const normaliseTTC = (value: number) => ((value - 0) * 100) / (maxTTC - 0);

  const normalisePassTraffic = (value: number) =>
    ((value - 0) * 100) / (maxPassTraffic - 0);

  return (
    <>
      {item.children.map((row, i) => (
        <TableRow key={i}>
          {i === 0 && (
            <TableCell
              rowSpan={item.children.length}
              sx={{
                fontWeight: 700,
                outline: '1px solid rgba(224, 224, 224, 1)',
                outlineOffset: '-1px',
              }}
            >
              {item.name}
            </TableCell>
          )}

          <TableCell sx={rowStyleSX}>{row.variant}</TableCell>

          <TableCell sx={rowStyleSX}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 1,
              }}
            >
              {gapBetweenDigits(row.ttcPopulationWeightedAverage)}

              <LinearProgress
                variant="determinate"
                value={normaliseTTC(row.ttcPopulationWeightedAverage)}
                sx={{
                  width: '100%',
                  minWidth: '250px',
                  height: 6,
                  borderRadius: 5,
                  [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: 'transparent',
                  },
                  [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: 5,
                    backgroundColor: theme => theme.palette.customs.bus,
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 1,
              }}
            >
              {gapBetweenDigits(row.ttcWeightedAveragePassTraffic)}

              <LinearProgress
                variant="determinate"
                value={normaliseTTC(row.ttcWeightedAveragePassTraffic)}
                sx={{
                  width: '100%',
                  minWidth: '250px',
                  height: 6,
                  borderRadius: 5,
                  [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: 'transparent',
                  },
                  [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: 5,
                    backgroundColor: '#F5D83E',
                  },
                }}
              />
            </Box>
          </TableCell>

          <TableCell sx={rowStyleSX}>
            <Stack>
              {gapBetweenDigits(row.passTraffic)}

              <LinearProgress
                variant="determinate"
                value={normalisePassTraffic(row.passTraffic)}
                sx={{
                  width: '100%',
                  minWidth: '250px',
                  height: 6,
                  borderRadius: 5,
                  [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: 'transparent',
                  },
                  [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: 5,
                    backgroundColor: '#79A2F2',
                  },
                }}
              />
            </Stack>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
