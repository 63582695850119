import { ISimulationType } from '@api/gql/tpu-types';

import {
  isAgentQuantity,
  isCumulativeAgentsQuantity,
  isEntrance,
  isExit,
} from './assistantFunctions';

import { ListOfFloorsWithListOfObjectsDataType } from '@features/tpu-simulation-model/types/simultaionModel';

import {
  IChartsData,
  IPieChartItem,
  IUploadedChartsData,
} from '../stores/charts/charts.store';

const chartsDataInitial: IChartsData = {
  agentsQuantity: [0],
  cumulativeAgentsQuantity: [0],
  entranceDistribution: [],
  exitsDistribution: [],
};

const parsedDataInitial: IUploadedChartsData = {
  agentsQuantity: [],
  cumulativeAgentsQuantity: [],
  entranceDistribution: [],
  exitsDistribution: [],
};

export const getCurrentDataForCharts = (
  uploadedChartsData: IUploadedChartsData,
  lastCalculatedSegment: number,
) => {
  if (lastCalculatedSegment === 0) return chartsDataInitial;

  const agentsQuantity = uploadedChartsData.agentsQuantity.slice(
    0,
    lastCalculatedSegment,
  );
  const cumulativeAgentsQuantity =
    uploadedChartsData.cumulativeAgentsQuantity.slice(0, lastCalculatedSegment);
  const entranceDistribution =
    uploadedChartsData.entranceDistribution.find(
      item => item.index === lastCalculatedSegment,
    )?.items ?? [];
  const exitsDistribution =
    uploadedChartsData.exitsDistribution.find(
      item => item.index === lastCalculatedSegment,
    )?.items ?? [];

  return {
    agentsQuantity,
    cumulativeAgentsQuantity,
    entranceDistribution,
    exitsDistribution,
  };
};

export const parseDataForCharts = (
  data: ISimulationType | null,
  planData: ListOfFloorsWithListOfObjectsDataType | null,
) => {
  if (!planData || !data) return parsedDataInitial;

  const plan = new Map<string, string>(
    Object.values(planData).flatMap(object =>
      Object.values(object).map(object => [
        object.uuid,
        'name' in object ? object.name : 'не обнаружено',
      ]),
    ),
  );

  const { objectResults } = data;
  const objectResultsItem = objectResults ? objectResults[0] : null;

  const agentsQuantity: number[] = [];
  const cumulativeAgentsQuantity: number[] = [];
  const exitsDistribution: IUploadedChartsData['exitsDistribution'] = [];
  const entranceDistribution: IUploadedChartsData['entranceDistribution'] = [];

  const entrances: IPieChartItem[] = [];
  const exits: IPieChartItem[] = [];
  const cumulativeAgents = objectResultsItem?.barChartResults.find(
    isCumulativeAgentsQuantity,
  )?.value;

  objectResultsItem?.pieChartResults.forEach(pieChartItem => {
    if (isExit(pieChartItem))
      exits.push({
        name: plan.get(pieChartItem.serviceObjectId) ?? 'не обнаружено',
        value: pieChartItem.value,
      });

    if (isEntrance(pieChartItem))
      entrances.push({
        name: plan.get(pieChartItem.serviceObjectId) ?? 'не обнаружено',
        value: pieChartItem.value,
      });
  });

  agentsQuantity.push(
    objectResultsItem?.barChartResults.find(isAgentQuantity)?.value ?? 0,
  );

  cumulativeAgentsQuantity.push(cumulativeAgents ? cumulativeAgents / 1000 : 0);

  exitsDistribution.push({
    index: objectResultsItem?.index ? objectResultsItem.index / 3600 : -1,
    items: exits,
  });

  entranceDistribution.push({
    index: objectResultsItem?.index ? objectResultsItem.index / 3600 : -1,
    items: entrances,
  });

  return {
    agentsQuantity,
    cumulativeAgentsQuantity,
    exitsDistribution,
    entranceDistribution,
  };
};
