import { EFilterMapPoint } from '@components/MapFilters/interface';

export const typographyWhitCircleStyle = (bg: string, color: string) => ({
  position: 'relative',
  userSelect: 'none',
  ml: 2,
  '&:before': {
    content: "''",
    display: 'block',
    width: '10px',
    height: '10px',
    position: 'absolute',
    borderRadius: '50%',
    background: bg,
    top: '5px',
    left: '-16px',
    border: `1px solid ${color}`,
  },
});

export const getNamePoint = (point: EFilterMapPoint) => {
  switch (point) {
    case EFilterMapPoint.A:
      return 'А';
    case EFilterMapPoint.B:
      return 'Б';
  }
};
