import { IComputingRegistryComputingRegistryResultStatusChoices } from '@api/gql/types';

export enum ECalculationScenarioStatusType {
  Success = 1,
  Pending = 2,
  Failure = 3,
  Draft = 4,
  Queue = 5,
}

export const getCalculationScenarioStatusType = (
  type: IComputingRegistryComputingRegistryResultStatusChoices,
) => {
  switch (type) {
    case IComputingRegistryComputingRegistryResultStatusChoices.A_1:
      return 1;
    case IComputingRegistryComputingRegistryResultStatusChoices.A_2:
      return 2;
    case IComputingRegistryComputingRegistryResultStatusChoices.A_3:
      return 3;
    case IComputingRegistryComputingRegistryResultStatusChoices.A_4:
      return 4;
    case IComputingRegistryComputingRegistryResultStatusChoices.A_5:
      return 5;
    default:
      return 3;
  }
};
