import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { Box, Button, Stack } from '@mui/material';

import { MapButton } from '@components/Map/MapButton';
import { EditIcon, LayersIcon, MinusIcon, PlusIcon } from '@components/icons';

import { MapLegend } from '@features/ag-project/components/MapLegend/MapLegend';
import { TooltipWarning } from '@features/ag-project/components/TooltipWarning/TooltipWarning';
import { EFilterMapLayout } from '@features/ag-project/constants/EFilterMapLayout';
import { pipeMapControls } from '@features/ag-project/stores/map';
import { $UI, UIApi } from '@features/ag-project/stores/ui';

import { EPipeMapControls } from '@utils/map/hooks/useInitMapControls';

export const MapControlsContainer = () => {
  const { mapLegend, inspector, filter } = useUnit($UI);
  const { toggleMapLegend } = useUnit(UIApi);
  const mapControls = useUnit(pipeMapControls);

  const onClickZoomIn = useCallback(() => {
    mapControls({ action: EPipeMapControls.touchZoom, payload: 'in' });
  }, [mapControls]);

  const onClickZoomOut = useCallback(() => {
    mapControls({ action: EPipeMapControls.touchZoom, payload: 'out' });
  }, [mapControls]);

  return (
    <>
      <TooltipWarning label="Для редактирования инфраструктуры сначала необходимо выбрать или создать сценарий расчета">
        <Box
          sx={{
            position: 'absolute',
            left: theme => theme.typography.pxToRem(filter ? 500 + 24 : 24),
            top: theme => theme.typography.pxToRem(24),
            zIndex: 1,
            boxShadow: '0px 0px 8px 0px #00000026',
          }}
        >
          <Button disabled>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
            >
              <EditIcon />
              <span>Редактировать инфраструктуру</span>
            </Stack>
          </Button>
        </Box>
      </TooltipWarning>

      <Button
        variant={'outlined'}
        sx={{
          marginBottom: theme => theme.typography.pxToRem(8),
          border: theme => '1px solid ' + theme.palette.text.disabled,
          gap: 1,
          position: 'absolute',
          top: theme => theme.typography.pxToRem(16),
          right: theme => theme.typography.pxToRem(inspector ? 400 + 24 : 24),
          boxShadow: '0px 0px 8px 0px #00000026',
          zIndex: 1,
          '&:hover': {
            backgroundColor: theme => theme.palette.common.white,
            border: theme => '1px solid ' + theme.palette.text.disabled,
            boxShadow:
              '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
          },
        }}
        onClick={toggleMapLegend}
      >
        <LayersIcon />
        Условные обозначения
      </Button>

      <MapButton
        sx={{
          position: 'absolute',
          top: theme => theme.typography.pxToRem(16 + 44 + 16),
          right: theme => theme.typography.pxToRem(inspector ? 400 + 24 : 24),
          boxShadow: '0px 0px 8px 0px #00000026',
          zIndex: 1,
        }}
        onClick={onClickZoomIn}
      >
        <PlusIcon />
      </MapButton>

      <MapButton
        sx={{
          position: 'absolute',
          top: theme => theme.typography.pxToRem(16 + 88 + 24),
          right: theme => theme.typography.pxToRem(inspector ? 400 + 24 : 24),
          boxShadow: '0px 0px 8px 0px #00000026',
          zIndex: 1,
        }}
        onClick={onClickZoomOut}
      >
        <MinusIcon />
      </MapButton>

      {mapLegend && (
        <MapLegend
          isVisibleInspector={inspector}
          layout={EFilterMapLayout.Infrastructure}
        />
      )}
    </>
  );
};
