import {
  IQuery,
  IQueryGetComputingRegistryInstanceByIdArgs,
} from '@api/gql/types';
import { gql } from '@apollo/client';
import { apiClient } from 'src/api/provider';

export const queryGetYearsTimeline = async (
  params: IQueryGetComputingRegistryInstanceByIdArgs,
) => {
  const result = await apiClient.query<
    IQuery,
    IQueryGetComputingRegistryInstanceByIdArgs
  >({
    query: gql`
      query QueryGetYearsTimeline($id: String) {
        getComputingRegistryInstanceById(id: $id) {
          baseYear
          finalYear
        }
      }
    `,
    variables: params,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result?.data?.getComputingRegistryInstanceById;
};
