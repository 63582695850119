import { useUnit } from 'effector-react';

import { Actions } from './actions';
import { Content } from './content';

import { Dialog, DialogTitle, IconButton, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

import { CloseIcon } from '@components/icons';

import { useContextPRHandlers } from '@features/tpu-project-registry/hooks';
import { $ModalDeleteProject } from '@features/tpu-project-registry/stores';

const dialogSx: SxProps<Theme> = {
  '& .MuiPaper-root': {
    width: '640px',
    minWidth: '640px',
    maxHeight: 'calc(100vh - 64px)',
  },
  '& .MuiDialogTitle-root': {
    fontSize: theme => theme.typography.pxToRem(16),
    lineHeight: theme => theme.typography.pxToRem(20),
  },
  '& .MuiDialogActions-root': {
    gap: '32px',

    '& :not(style)~:not(style)': {
      marginLeft: 'unset',
    },
  },
  '& .MuiDialogContent-root': {
    fontSize: theme => theme.typography.pxToRem(14),
    lineHeight: theme => theme.typography.pxToRem(14),
  },
};

export const ModalDeleteProject = () => {
  const $modalDeleteScenario = useUnit($ModalDeleteProject);

  const { resetModalDeleteProject, deleteProject } = useContextPRHandlers();

  const handleClose = () => {
    resetModalDeleteProject();
  };

  const handleDelete = () => {
    deleteProject();
  };

  return (
    <Dialog
      open={$modalDeleteScenario.isOpen}
      sx={dialogSx}
      onClose={handleClose}
      transitionDuration={0}
    >
      <DialogTitle>Удалить расчет</DialogTitle>
      <IconButton
        onClick={handleClose}
        color={'primary'}
        sx={{ position: 'absolute', top: 24, right: 24, padding: 0 }}
      >
        <CloseIcon />
      </IconButton>
      <Content
        name={$modalDeleteScenario.name}
        isResolve={$modalDeleteScenario.isResolve}
        isLoading={$modalDeleteScenario.isLoading}
      />
      <Actions
        onClose={handleClose}
        onDelete={handleDelete}
        isResolve={$modalDeleteScenario.isResolve}
      />
    </Dialog>
  );
};
