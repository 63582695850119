import { ISocioEconomicScenarioInstanceType, Maybe } from '@api/gql/types';

import { getScenarioType } from '@features/pt-constructor/constants/socialScenarioType';
import { SocEcoScenarioListItem } from '@features/pt-constructor/store';

export const socEcoScenariosReformatter = (
  data: Maybe<Maybe<ISocioEconomicScenarioInstanceType>[]>,
): SocEcoScenarioListItem[] =>
  data
    ? data.map(scenario => ({
        id: scenario?.id || '',
        name: scenario?.name || '',
        type: getScenarioType(scenario?.scenarioType || 'A_4'),
        revenueGrowthRate: scenario?.revenueGrowthRate || 0,
        populationGrowthRate: scenario?.populationGrowthRate || 0,
        growthRateOfMotorization: scenario?.growthRateOfMotorization || 0,
        growthRateInTheNumberOfPlacements:
          scenario?.growthRateInTheNumberOfPlacements || 0,
        jobGrowthRate: scenario?.jobGrowthRate || 0,
        salaryGrowthRate: scenario?.salaryGrowthRate || 0,
        computed: scenario?.computed || false,
      }))
    : [];
