import { createApi, createStore } from 'effector';

export enum eInspectorType {
  infrastructure = 'infrastructure',
  customRailwayStation = 'customRailwayStation',
  customRailwayGraph = 'customRailwayGraph',
}

const initState: InspectorStore = {
  type: null,
};

export const $Inspector = createStore<InspectorStore>(initState);

export const InspectorApi = createApi($Inspector, {
  setType: (store, payload: eInspectorType | null) => ({
    ...store,
    type: payload,
  }),
  reset: () => initState,
});

export type InspectorStore = {
  type: eInspectorType | null;
};
