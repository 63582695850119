import {
  IMutationAddStationsToItemArgs,
  IQueryGetScenarioGraphArgs,
} from '@api/gql/types';
import { sample } from 'effector';
import { LineString } from 'ol/geom';
import { toLonLat } from 'ol/proj';

import { EMapFeatureLayout, EMapFeatureMetaKeys } from '@constants/map';

import { mutationAddStationToItem } from '@features/pt-forecast-new/stores/api/mutationAddStationToItem';
import { mutationCreateStation } from '@features/pt-forecast-new/stores/api/mutationCreateStation';
import { mutationDeleteStation } from '@features/pt-forecast-new/stores/api/mutationDeleteStation';
import { queryGetClosestInstancePointByPoint } from '@features/pt-forecast-new/stores/api/queryGetClosestInstancePointByPoint';
import {
  InspectorApi,
  eInspectorType,
} from '@features/pt-forecast-new/stores/inspector';
import { clickMap } from '@features/pt-forecast-new/stores/map';
import {
  $EditorMap,
  CustomRailwayStationItem,
  EditorMapApi,
  ExtendedIMutationCreateStationArgs,
  SelectStationPayload,
  addStationToItemFx,
  createStationFx,
  deleteStationFx,
  getClosestInstancePointByPointFx,
  getGraphItemsFx,
} from '@features/pt-forecast-new/stores/map/editor/store';
import { $SelectedFeatures } from '@features/pt-forecast-new/stores/map/selectedFeatures';
import { $FeatureSettings } from '@features/pt-forecast-new/stores/settings';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';

import { getFeatureAtPixel } from '@utils/map/tools/getFeatureAtPixel';

getClosestInstancePointByPointFx.use(queryGetClosestInstancePointByPoint);
addStationToItemFx.use(mutationAddStationToItem);

// Определить координаты клика, и запросить создание вершины
const addStation = sample({
  clock: clickMap,
  source: {
    UI: $UI,
    FeatureSettings: $FeatureSettings,
  },
  filter: ({ UI }) => UI.placeRailwayStation,
  fn: ({ FeatureSettings }, event) => {
    const clickCoords = event.target.getCoordinateFromPixel(event.pixel);

    let targetCoords: number[] | null = null;
    let targetFeatureUUID: string | null = null;
    let targetLayerId: string | null = null;

    const featureAtPixel = getFeatureAtPixel({
      map: event.map,
      pixel: event.pixel,
      includeLayouts: [
        EMapFeatureLayout.customRailwayGraph,
        // EMapFeatureLayout.railway,
      ],
      layersZIndex: {
        // [EMapFeatureLayout.railway]: 1,
        [EMapFeatureLayout.customRailwayGraph]: 2,
      },
      hitTolerance: 14,
    });

    if (featureAtPixel) {
      const geometry = featureAtPixel.getGeometry();
      if (geometry instanceof LineString) {
        targetFeatureUUID = featureAtPixel.get(
          EMapFeatureMetaKeys.olId,
        ) as string;
        targetLayerId = featureAtPixel.get(
          EMapFeatureMetaKeys.layout,
        ) as string;
        targetCoords = geometry.getClosestPoint(event.coordinate);
      }
    }

    if (targetLayerId === EMapFeatureLayout.customRailwayGraph) {
      return {
        isGraph: true,
        isCustom: true,
        coords: toLonLat(targetCoords!),
        scenarioId: FeatureSettings.scenarioId,
        graphId: targetFeatureUUID!,
      };
    } else {
      return {
        isGraph: false,
        isCustom: false,
        coords: toLonLat(clickCoords),
        scenarioId: FeatureSettings.scenarioId,
        graphId: null,
      };
    }
  },
});

// Если нужно создать станцию в пустом месте
sample({
  clock: addStation,
  filter: payload =>
    // Если клик не по графу
    !payload.isGraph,
  fn: payload => {
    return {
      name: 'Безымянная станция',
      point: {
        lat: payload.coords[1],
        lon: payload.coords[0],
      },
      scenarioId: payload.scenarioId,
      vertexId: undefined,
      isForGraph: false,
    } as ExtendedIMutationCreateStationArgs;
  },
  target: createStationFx,
});

// Если нужно создать станцию в на пользовательском графе
sample({
  clock: addStation,
  filter: payload =>
    // Если клик по графу
    payload.isGraph &&
    // Если графу пользовательский
    payload.isCustom,
  fn: payload => {
    return {
      name: 'Безымянная станция',
      point: {
        lat: payload.coords[1],
        lon: payload.coords[0],
      },
      scenarioId: payload.scenarioId,
      vertexId: undefined,
      isForGraph: false,
      isIntermediate: true,
      intermediateForGraphId: payload.graphId,
    } as ExtendedIMutationCreateStationArgs;
  },
  target: createStationFx,
});

createStationFx.use(mutationCreateStation);

// Если удалось создать ТПУ, то добавить его в карту
sample({
  clock: createStationFx.done,
  fn: request => {
    const [lon, lat] = request?.result?.instance?.vertex.theGeom
      .coordinates || [undefined, undefined];

    return {
      geometry: { lon, lat },
      id: request.result?.instance?.id || '',
      name: request.result?.instance?.name || 'Безымянная станция',
      transportArea: request.result?.instance?.transportRegion?.name,
      vertexId: request.result?.instance?.vertex.id,
      isSaved: false,
      isUpdated: true,
      isGhost: !!request.params.railwayStationId,
    } as CustomRailwayStationItem;
  },
  target: [EditorMapApi.addStation],
});

// Если созданная станция является промежуточной для графа, то добавить её в граф
sample({
  clock: createStationFx.done,
  filter: request =>
    // Если станция является промежуточной
    !!request.params.isIntermediate &&
    // Если был передан идентификатор графа
    !!request.params.intermediateForGraphId,
  fn: request => {
    return {
      graphItemId: request.params.intermediateForGraphId || '',
      stationId: request.result?.instance?.id || '',
    } as IMutationAddStationsToItemArgs;
  },
  target: addStationToItemFx,
});

// Добавить промежуточную станцию в граф
sample({
  clock: addStationToItemFx.done,
  fn: request => {
    return {
      graphId: request.params.graphItemId || '',
      stationId: request.params.stationId || '',
    } as { graphId: string; stationId: string };
  },
  target: EditorMapApi.addIntermediateStation,
});

// После добавления промежуточной станции перезапросить всю пользовательскую инфраструктуру для синхронизации порядка станций
sample({
  clock: addStationToItemFx.done,
  source: { FeatureSettings: $FeatureSettings },
  fn: ({ FeatureSettings }) => {
    return {
      scenarioId: FeatureSettings.scenarioId,
    } as IQueryGetScenarioGraphArgs;
  },
  target: getGraphItemsFx,
});

// Сеттер последнего шага построения на карте (станции)
sample({
  clock: createStationFx.done,
  fn: payload => ({
    isStation: true,
    stationId: payload.result?.instance?.id,
    coords: payload.result?.instance?.railwayStation?.geometry,
  }),
  target: EditorMapApi.setLastStep,
});

// Если удалось создать ТПУ, выйти из режима "построить станцию"
sample({
  clock: EditorMapApi.addStation,
  fn: () => false,
  target: UIApi.setPlaceRailwayStations,
});

////////////////////////////////////////////////////////////////////////////////

deleteStationFx.use(mutationDeleteStation);

// После удаления Станции перезапросить всю кастомную геометрию
sample({
  clock: deleteStationFx.done,
  source: { FeatureSettings: $FeatureSettings },
  fn: ({ FeatureSettings }) => {
    return {
      scenarioId: FeatureSettings.scenarioId,
    } as IQueryGetScenarioGraphArgs;
  },
  target: [getGraphItemsFx, EditorMapApi.clearSelectedStation],
});

// Закрыть инспектор при сбросе выбранной станции
sample({
  clock: EditorMapApi.clearSelectedStation,
  fn: () => false,
  target: UIApi.setVisibleInspector,
});

////////////////////////////////////////////////////////////////////////////////

// // Запросить данные по выбранному тпу
sample({
  clock: $SelectedFeatures,
  source: { SelectedFeatures: $SelectedFeatures, EditorMap: $EditorMap },
  filter: ({ SelectedFeatures, EditorMap }) =>
    (SelectedFeatures[0]?.layout === EMapFeatureLayout.customRailwayStation &&
      SelectedFeatures[0]?.id !== EditorMap.selectedStation) ||
    !SelectedFeatures.length,
  fn: ({ SelectedFeatures }) => {
    return {
      id: SelectedFeatures[0]?.id || null,
    } as SelectStationPayload;
  },
  target: EditorMapApi.selectStation,
});

// Указание типа инспектора
sample({
  clock: EditorMapApi.selectStation,
  fn: () => {
    return eInspectorType.customRailwayStation;
  },
  target: InspectorApi.setType,
});

////////////////////////////////////////////////////////////////////////////////
