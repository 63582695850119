import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import { palette } from '@system/theme/palette';

export const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  styleOverrides: {
    inputRoot: ({ theme }) => ({
      padding: theme.spacing(0.5, 1.25),
    }),
    paper: ({ theme }) => ({
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(0.25),
      backgroundColor: palette.common.white,
      borderRadius: theme.shape.borderRadius * 2,
      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.15)',
    }),
    option: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      '&:hover': {
        backgroundColor: palette.customs.bgHover,
        color: palette.text.primary,
      },
    }),
    listbox: ({ theme }) => ({
      padding: theme.spacing(0),
    }),
  },
};
