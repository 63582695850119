import { MouseEvent, useCallback, useState } from 'react';

import { Link, Menu, MenuItem, Typography } from '@mui/material';

import { modules } from '@constants/modules';

import { useCustomNavigate } from '@utils/NavigationBlockContext/useCustomNavigate';

export const HeaderMenu = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const navigate = useCustomNavigate();

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorElUser(null);
  }, []);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <Typography
        sx={{ cursor: 'pointer' }}
        onClick={handleOpenMenu}
      >
        Модули
      </Typography>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseMenu}
      >
        {modules.map(module => (
          <Link
            onClick={() => handleNavigate(module.path)}
            key={module.path}
          >
            <MenuItem
              title={module.description}
              onClick={handleCloseMenu}
            >
              <Typography
                color={'primary'}
                sx={{ textDecoration: 'none' }}
                textAlign="center"
              >
                {module.title}
              </Typography>
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </>
  );
};
