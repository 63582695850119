/**
 * Получение целочисленного процента от числа, если меньше 1, то округляет до десятых
 * @param total - исходное число
 * @param number - вычисляемый процент числа
 * @returns получение процента от числа
 */
export const getPercent = (total: number, number: number): number => {
  if (!number) return 0;

  const percent = Math.round((number / total) * 100);

  return percent < 1 ? +((number / total) * 100).toFixed(1) : percent;
};
