import { apiClient } from '@api/client';
import { IMutation, IMutationCreateScenarioArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

export const mutationCreateScenario = async (
  newScenario: IMutationCreateScenarioArgs,
) => {
  const result = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation CreateScenarioMutation(
        $name: String!
        $projectId: UUID!
        $socEcoScenarioId: UUID!
        $takeIntoInfra: Boolean!
      ) {
        createScenario(
          name: $name
          projectId: $projectId
          socEcoScenarioId: $socEcoScenarioId
          takeIntoInfra: $takeIntoInfra
        ) {
          scenarioInstance {
            id
            name
            takeIntoInfra
            resultStatus
          }
        }
      }
    `,
    variables: newScenario,
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.createScenario;
};
