import { TextField, Typography } from '@mui/material';

interface LabelProps {
  value: string;
  onLabelChange?: (arg: string) => void;
}

export const Label = ({ value, onLabelChange }: LabelProps) => {
  if (!onLabelChange) {
    return (
      <Typography
        variant="body2"
        fontSize="inherit"
      >
        {value}
      </Typography>
    );
  }

  const inputWidth = value.length * 8;

  return (
    <TextField
      variant="standard"
      value={value}
      onChange={e => {
        onLabelChange(e.target.value);
      }}
      InputProps={{
        sx: {
          fontSize: theme => theme.typography.pxToRem(12),
          height: '16px',
          width: `${Math.max(10, inputWidth ?? 0)}px`,
          '::before': { all: 'unset' },
          '::after': { all: 'unset' },
        },
      }}
    />
  );
};
