import {
  CSSProperties,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export const AutoFullHeight = (
  props: PropsWithChildren<{
    mb?: number;
    style?: CSSProperties | undefined;
    maxHeight?: boolean;
    autoHeight?: boolean;
  }>,
) => {
  const {
    mb = 0,
    style: pStyle,
    maxHeight = false,
    autoHeight = false,
    children,
  } = props;
  const [rect, setRect] = useState<DOMRect>();
  const ref = useRef<HTMLDivElement>(null);

  const style = useMemo(() => {
    if (rect) {
      return {
        position: 'relative',
        ...pStyle,
        [maxHeight ? 'maxHeight' : 'height']: autoHeight
          ? '100%'
          : document.documentElement.clientHeight - rect.top - mb,
      };
    }

    return {};
  }, [autoHeight, maxHeight, mb, pStyle, rect]);

  useEffect(() => {
    if (ref.current) {
      window.addEventListener('resize', () => {
        setRect(ref.current!.getBoundingClientRect());
      });
    }
  }, []);

  return (
    <div
      style={style}
      ref={useCallback(
        (instance: HTMLDivElement | null) => {
          if (instance && !rect) {
            setRect(instance.getBoundingClientRect());
          }
          if (instance) {
            Reflect.set(ref, 'current', instance);
          }
        },
        [rect],
      )}
    >
      {children}
    </div>
  );
};
