import { DialogContent, Typography } from '@mui/material';

import { ErrorIcon } from '@components/icons';

import { ErrorsList } from '@features/tpu-calculation-scenarios-viewing/components/ModalPlayerErrors/ErrorsList';
import { IPlayerError } from '@features/tpu-calculation-scenarios-viewing/stores/scenarioErrors/scenarioErrors.store';

interface ContentProps {
  errors: IPlayerError[] | null;
}

export const Content = ({ errors }: ContentProps) => {
  return (
    <DialogContent sx={{ textAlign: 'center' }}>
      <ErrorIcon
        sx={{ height: '44px', width: '44px', margin: '0 auto 8px auto' }}
      />

      <Typography>Ошибка</Typography>

      <ErrorsList errors={errors} />
    </DialogContent>
  );
};
