import { apiClient } from '@api/client';
import { IQuery, IQueryGetRailwayStationByIdArgs } from '@api/gql/types';
import { gql } from '@apollo/client';

export const queryGetParentStationParams = async (
  params: IQueryGetRailwayStationByIdArgs,
) => {
  const result = await apiClient.query<
    Pick<IQuery, 'getRailwayStationById'>,
    IQueryGetRailwayStationByIdArgs
  >({
    query: gql`
      query QueryGetRailwayStationById($id: String!) {
        getRailwayStationById(id: $id) {
          id
          name
          geometry
        }
      }
    `,
    variables: {
      id: params.id,
    },
  });

  return result.data?.getRailwayStationById;
};
