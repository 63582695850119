import { useUnit } from 'effector-react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { PassFlowTableRow } from '@features/ag-forecast/components/PassFlowTable/PassFlowTableRow';
import { passTrafficTableHeadCells } from '@features/ag-forecast/components/PassFlowTable/config/headCells';
import { $PassFlowTableContent } from '@features/ag-forecast/stores/lists/passFlowTableContent';

export const PassFlowTable = () => {
  const { rows } = useUnit($PassFlowTableContent);

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: '250px',
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                maxWidth: '56px',
                width: '56px',
                backgroundColor: '#EBECEF',
              }}
            />
            {passTrafficTableHeadCells.map(cell => (
              <TableCell
                key={cell.id}
                align="left"
                padding="none"
                sx={{
                  padding: '6px 6px',
                  width: `${cell.minWidth}px`,
                  backgroundColor: '#EBECEF',
                  fontSize: '12px',
                  fontWeight: 400,
                }}
              >
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <PassFlowTableRow
              key={i}
              rowData={row}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
