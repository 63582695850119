import { v4 as uuid } from 'uuid';

import {
  EditableObjectType,
  IRoom,
} from '@features/tpu-simulation-model/types';

import {
  IRoomErrorAnalyzer,
  PlanError,
} from '@utils/simulationModel/errors-analyzer/types';

export class RoomErrorAnalyzer implements IRoomErrorAnalyzer {
  _rooms = new Map<string, IRoom>();
  _errors = new Set<PlanError>();

  reset() {
    this._errors.clear();
    this._rooms.clear();
  }
  addRoom(data: IRoom) {
    this._rooms.set(data.uuid, data);
  }

  getErrors() {
    if (!this._rooms.size) {
      this._errors.add({
        id: uuid(),
        objectType: EditableObjectType.Room,
        description: 'На плане отсутствуют помещения',
      });
    }

    return Array.from(this._errors);
  }
}
