import { useUnit } from 'effector-react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './stores';

import { Box, Dialog, Stack, SxProps, Theme } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { ROUTES } from '@constants/routes';

import { InspectorContainer } from '@features/ag-project/containers/InspectorContainer/InspectorContainer';
import { MapContainer } from '@features/ag-project/containers/MapContainer/MapContainer';
import { MapControlsContainer } from '@features/ag-project/containers/MapControlsContainer/MapControlsContainer';
import { MapFiltersContainer } from '@features/ag-project/containers/MapFiltersContainer/MapFiltersContainer';
import { ToolBarContainer } from '@features/ag-project/containers/ToolBarContainer/ToolBarContainer';
import { YearLineContainer } from '@features/ag-project/containers/YearLineContainer/YearLineContainer';
import { FilterMapApi } from '@features/ag-project/stores/filters';
import { getVehiclesFx } from '@features/ag-project/stores/lists/vehicles';
import {
  $AgGeometry,
  FetchAgGeometryFx,
  MapStoreApi,
  pipeMapControls,
} from '@features/ag-project/stores/map';
import {
  $FeatureSettings,
  FeatureSettingsApi,
} from '@features/ag-project/stores/settings/store';
import { $UI, UIApi } from '@features/ag-project/stores/ui';
import { storesReset } from '@features/ag-project/stores/unmountReset';
import { getTimelineYearsFx } from '@features/ag-project/stores/yearLine';

import { EPipeMapControls } from '@utils/map/hooks/useInitMapControls';

import { WidgetPassengerFlowContainer } from '@widgets/widget-passenger-flow';

const ContainerMuiStyle: SxProps<Theme> = {
  position: 'relative',
};

export const AgglomerationProjectContainer = () => {
  const handlers = useUnit({
    pipeMapControls,
    FetchAgGeometryFx,
    getVehiclesFx,
  });
  const { center } = useUnit($AgGeometry);
  const { setProjectUUID } = useUnit(FeatureSettingsApi);
  const { hideAllToCache, restoreAllFromCache } = useUnit(FilterMapApi);
  const { isShowFrame } = useUnit($UI);
  const { regionUUID } = useUnit($FeatureSettings);
  const { setShowFrame } = useUnit(UIApi);
  const { setIsBusy } = useUnit(MapStoreApi);

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [projectId] = useState(queryParams.get('projectId'));

  const closeFrameHandler = useCallback(() => {
    setShowFrame(false);
  }, [setShowFrame]);

  useEffect(() => {
    if (!projectId) {
      navigate(ROUTES.AGGLOMERATION);
      return;
    }

    /** Получение доступных видов транспорта */
    getTimelineYearsFx({ id: projectId || '' });
    handlers.getVehiclesFx({ projectId: projectId || '' });
    handlers.FetchAgGeometryFx({ id: projectId || '' });
    setProjectUUID(projectId || '');
  }, []);

  useEffect(() => {
    if (!center) return;
    setIsBusy(true);
    hideAllToCache();
    handlers.pipeMapControls({
      action: EPipeMapControls.center,
      payload: center,
    });

    setTimeout(() => {
      handlers.pipeMapControls({
        action: EPipeMapControls.setZoom,
        payload: 14,
      });
      setTimeout(() => {
        restoreAllFromCache();
        setIsBusy(false);
      }, 300);
    }, 600);
  }, [center]);

  useEffect(
    () => () => {
      storesReset();
    },
    [],
  );

  return (
    <>
      {isShowFrame && regionUUID && (
        <Dialog
          onClose={closeFrameHandler}
          open={true}
          fullWidth={true}
          maxWidth="xl"
          PaperProps={{ style: { width: '90vw', height: '90vh' } }}
        >
          <WidgetPassengerFlowContainer regionUUID={regionUUID} />
        </Dialog>
      )}

      <ToolBarContainer />
      <Box sx={ContainerMuiStyle}>
        <MapFiltersContainer />

        <Stack direction="row">
          <Stack flex={1}>
            <AutoFullHeight style={{ backgroundColor: 'white' }}>
              <MapContainer />
            </AutoFullHeight>
          </Stack>
        </Stack>

        <MapControlsContainer />

        <InspectorContainer />

        <YearLineContainer />
      </Box>
    </>
  );
};
