import { SvgIcon, SvgIconProps } from '@mui/material';

export const DragDropIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M10.8 6.4a1.4 1.4 0 1 1-2.8 0 1.4 1.4 0 0 1 2.8 0ZM10.8 12A1.4 1.4 0 1 1 8 12a1.4 1.4 0 0 1 2.8 0ZM10.8 17.6a1.4 1.4 0 1 1-2.8 0 1.4 1.4 0 0 1 2.8 0ZM16.4 6.4a1.4 1.4 0 1 1-2.8 0 1.4 1.4 0 0 1 2.8 0ZM16.4 12a1.4 1.4 0 1 1-2.8 0 1.4 1.4 0 0 1 2.8 0ZM16.4 17.6a1.4 1.4 0 1 1-2.8 0 1.4 1.4 0 0 1 2.8 0Z"
        fill="#273B48"
      />
    </SvgIcon>
  );
};
