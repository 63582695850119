import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { Box, Divider, IconButton, Typography } from '@mui/material';

import { ChevronRightIcon } from '@components/icons';

import { BoxData } from '@features/pt-forecast-new/components/Inspector/BoxData';
import { $InspectorInfrastructure } from '@features/pt-forecast-new/stores/inspector/infrastructure';
import { UIApi } from '@features/pt-forecast-new/stores/ui';

export const RailwayInspector = () => {
  const { railway } = useUnit($InspectorInfrastructure);
  const { setVisibleInspector } = useUnit(UIApi);

  const onClose = useCallback(() => {
    setVisibleInspector(false);
  }, [setVisibleInspector]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 3,
        }}
      >
        <IconButton
          sx={{
            width: '32px',
            height: '32px',
            backgroundColor: 'primary.main',
            borderRadius: 1,
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={onClose}
        >
          <ChevronRightIcon />
        </IconButton>

        <Typography variant="subtitle2">ЖД линия</Typography>
      </Box>

      <BoxData
        name={'Название'}
        value={railway?.railwayName || 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Род тяги'}
        value={railway?.tractionType || 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Количество главных путей'}
        value={railway?.tracks || 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Пропускная способность'}
        value={railway?.capacity ? railway?.capacity + ' ед./сут.' : 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Скоростной режим'}
        value={railway?.speed ? 'До ' + railway?.speed + ' км/ч' : 'н/д'}
      />
    </>
  );
};
