import {
  IComputingRegistryType,
  IQuery,
  IQueryGetComputingRegistryInstanceByIdArgs,
} from '@api/gql/types';
import { createApi, createEffect, createEvent, createStore } from 'effector';

import {
  EcoParam,
  SocEcoScenarioParams,
} from '@features/pt-constructor/constants/ecoParams';
import { ScenarioType } from '@features/pt-constructor/constants/socialScenarioType';
import { shorteningValueAndMultiplyingBy100 } from '@features/pt-constructor/utils';

import { QueryCreateUserScenarioArgs } from '../api/queryCreateUserScenario';
import { QueryEditUserScenarioArgs } from '../api/queryEditUserScenario';
import {
  CalculationScenario,
  SocEcoScenarioListItem,
  TransportProject,
  TransportScenarioListItem,
} from '../lists/scenarios';

const initStore: SelectedComputingScenario = {
  name: '',
  id: undefined,
  socEcoScenario: null,
  transportScenario: null,
};

export const $SelectedCalculationScenario =
  createStore<SelectedComputingScenario>(initStore);

export const SelectedCalculationScenarioApi = createApi(
  $SelectedCalculationScenario,
  {
    selectCalculationScenario: (_, payload: CalculationScenario) => ({
      //Todo: умножение на 100 для приведения параметров к процентам
      name: payload?.name,
      id: payload?.id,
      socEcoScenario: {
        id: payload?.socEcoScenarioId,
        type: payload?.socEcoScenarioType,
        name: payload?.socEcoComputeName,
        revenueGrowthRate: shorteningValueAndMultiplyingBy100(
          payload?.revenueGrowthRate,
        ),
        growthRateInTheNumberOfPlacements: shorteningValueAndMultiplyingBy100(
          payload?.growthRateInTheNumberOfPlacements,
        ),
        growthRateOfMotorization: shorteningValueAndMultiplyingBy100(
          payload?.growthRateOfMotorization,
        ),
        jobGrowthRate: shorteningValueAndMultiplyingBy100(
          payload?.jobGrowthRate,
        ),
        populationGrowthRate: shorteningValueAndMultiplyingBy100(
          payload?.populationGrowthRate,
        ),
        salaryGrowthRate: shorteningValueAndMultiplyingBy100(
          payload?.salaryGrowthRate,
        ),
        isNew: false,
      },
      transportScenario: {
        id: payload?.transportScenarioId,
        name: payload?.transportComputeName,
        type: payload?.transportScenarioType,
        transportProjects: payload?.projectObjects,
        isNew: false,
      },
    }),
    setTransportProjectsForScenario: (store, payload: TransportProject[]) => ({
      ...store,
      transportScenario: !store.transportScenario
        ? null
        : {
            ...store.transportScenario,
            transportProjects: payload,
          },
    }),
    setNameCalculationScenario: (
      store,
      payload: SelectedComputingScenario['name'],
    ) => ({
      ...store,
      name: payload,
    }),
    setParamSocEcoScenario: (
      store,
      payload: {
        param: Omit<EcoParam, 'title'>;
        value: number;
      },
    ) => {
      if (!store.socEcoScenario) return store;

      const socEcoScenario = store.socEcoScenario;

      socEcoScenario[payload.param.name] = payload.value;

      return {
        ...store,
        socEcoScenario,
      };
    },
    createSocEcoScenario: (store, payload: SocEcoScenarioListItem) => ({
      ...store,
      socEcoScenario: {
        ...payload,
        type: ScenarioType.Custom,
        isNew: true,
      },
    }),
    createTransportScenario: (store, payload: TransportScenarioListItem) => ({
      ...store,
      transportScenario: {
        ...payload,
        type: ScenarioType.Custom,
        isNew: true,
      },
    }),
    selectSocEcoScenario: (store, payload: SocEcoScenarioListItem) => {
      const socEco = {
        ...payload,
        revenueGrowthRate: shorteningValueAndMultiplyingBy100(
          payload?.revenueGrowthRate,
        ),
        growthRateInTheNumberOfPlacements: shorteningValueAndMultiplyingBy100(
          payload?.growthRateInTheNumberOfPlacements,
        ),
        growthRateOfMotorization: shorteningValueAndMultiplyingBy100(
          payload?.growthRateOfMotorization,
        ),
        jobGrowthRate: shorteningValueAndMultiplyingBy100(
          payload?.jobGrowthRate,
        ),
        populationGrowthRate: shorteningValueAndMultiplyingBy100(
          payload?.populationGrowthRate,
        ),
        salaryGrowthRate: shorteningValueAndMultiplyingBy100(
          payload?.salaryGrowthRate,
        ),
      };

      return {
        ...store,
        socEcoScenario: {
          ...socEco,
          isNew: false,
        },
      };
    },
    selectTransportScenario: (store, payload: TransportScenarioListItem) => ({
      ...store,
      transportScenario: {
        ...payload,
        isNew: false,
      },
    }),
    selectProjectsTransportScenario: (store, payload: TransportProject[]) => {
      if (!store.transportScenario) return store;
      return {
        ...store,
        transportScenario: {
          ...store.transportScenario,
          transportProjects: payload,
        },
      };
    },
    clearSelectedCalculationScenario: () => initStore,
  },
);

// Копировать переданный сценарий расчета
export const copyUserCalculation = createEvent<CalculationScenario>();
// Запустить пайплайн сохранения/создания/запуска выбранного сценария расчета
export const createOrUpdateCalculationScenario =
  createEvent<CreateOrUpdateCalculationScenarioPayload>();
export const onlyRunCalculationScenario = createEvent();
// Прервать процесс расчета сценария
export const cancelCalculation = createEvent();
// Создать пустой сценарий расчета
export const createEmptyCalculationScenario = createEvent();
// Автоматическое создание сценария без указания названия
export const createCopyCalculationScenario = createEvent<{ run: boolean }>();
// Удалить пользовательский сценарий
export const deleteUserScenario = createEvent<string>();

//TODO Сделать типизацию когда выкатят эндпоинт
export const runCalculationScenarioFx = createEffect<
  { id: string },
  { id: string }
>();
// Создание расчета
export const createUserScenarioFx = createEffect<
  QueryCreateUserScenarioArgs,
  IComputingRegistryType | null | undefined
>();
// Создание расчета
export const createUserScenarioAndRunFx = createEffect<
  QueryCreateUserScenarioArgs,
  IComputingRegistryType | null | undefined
>();
// Создать копию пользовательского сценария
export const copyUserScenarioFx = createEffect<
  QueryCreateUserScenarioArgs,
  IComputingRegistryType | null | undefined
>();
// Создать копию пользовательского сценария и запустить её расчет
export const copyUserScenarioAndRunFx = createEffect<
  QueryCreateUserScenarioArgs,
  IComputingRegistryType | null | undefined
>();
// Применение изменений к существующему расчету
export const editUserScenarioFx = createEffect<
  QueryEditUserScenarioArgs,
  IComputingRegistryType | null | undefined
>();
// Применение изменений к существующему расчету и запуск расчета
export const editUserScenarioFxAndRunFx = createEffect<
  QueryEditUserScenarioArgs,
  IComputingRegistryType | null | undefined
>();
// Удаление пользовательского сценария
export const deleteCalculationFx = createEffect<
  QueryDeleteScenarioArgs,
  void
>();
// Запрос проектов для сценария расчета
export const getTransportProjectsForScenarioFx = createEffect<
  IQueryGetComputingRegistryInstanceByIdArgs,
  IQuery['getComputingRegistryInstanceById']
>();

export type CreateOrUpdateCalculationScenarioPayload = {
  run: boolean;
};

export type QueryDeleteScenarioArgs = {
  id: CalculationScenario['id'];
};

export type SocEcoSelectedScenario = SocEcoScenarioParams & {
  id?: string;
  name: string;
  type: ScenarioType;
  isNew: boolean;
};

export type TransportSelectedScenario = {
  id?: string;
  name: string;
  isNew: boolean;
  type: ScenarioType;
  transportProjects: TransportScenarioListItem['transportProjects'];
};

export type SelectedComputingScenario = {
  id?: string;
  name: string;
  socEcoScenario: SocEcoSelectedScenario | null;
  transportScenario: TransportSelectedScenario | null;
};
