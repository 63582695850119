import { Event } from 'effector';
import { Map as MapOl } from 'ol';
import { easeOut } from 'ol/easing';
import { Extent } from 'ol/extent';
import { fromLonLat } from 'ol/proj';
import { RefObject, useCallback, useEffect } from 'react';

export enum EPipeMapControls {
  touchZoom,
  setZoom,
  center,
  fitExtent,
}

export type PipeMapControls =
  | {
      action: EPipeMapControls.touchZoom;
      payload: 'in' | 'out';
    }
  | {
      action: EPipeMapControls.setZoom;
      payload: number;
    }
  | {
      action: EPipeMapControls.center;
      payload: number[];
    }
  | {
      action: EPipeMapControls.fitExtent;
      payload: FitExtentPayload;
    };

type FitExtentPayload = {
  extent: Extent;
  padding?: number[];
  duration?: number;
};

export type useInitMapControlsProps = {
  mapRef: RefObject<MapOl | null>;
  events: Event<PipeMapControls>[];
};

export const useInitMapControls = (props: useInitMapControlsProps) => {
  const { mapRef, events } = props;

  const touchZoomHandler = useCallback((direction: 'in' | 'out') => {
    const currentZoom = mapRef.current?.getView().getZoom();
    if (currentZoom)
      mapRef.current?.getView().animate({
        zoom: currentZoom + (direction === 'in' ? 1 : -1),
        duration: 250,
      });
  }, []);

  const setZoomHandler = useCallback((zoom: number) => {
    mapRef.current?.getView().animate({
      zoom,
      duration: 250,
    });
  }, []);

  const centerHandler = useCallback((coordinate: number[]) => {
    mapRef.current?.getView().animate({
      center: fromLonLat(coordinate),
      duration: 250,
      easing: easeOut,
    });
  }, []);

  const fitExtentHandler = useCallback(
    ({ extent, padding, duration }: FitExtentPayload) => {
      mapRef.current
        ?.getView()
        .fit(extent, { padding, duration: duration || 200 });
    },
    [],
  );

  const listener = useCallback(({ action, payload }: PipeMapControls) => {
    switch (action) {
      case EPipeMapControls.touchZoom:
        touchZoomHandler(payload);
        break;
      case EPipeMapControls.center:
        centerHandler(payload);
        break;
      case EPipeMapControls.setZoom:
        setZoomHandler(payload);
        break;
      case EPipeMapControls.fitExtent:
        fitExtentHandler(payload);
        break;
    }
  }, []);

  /** Инициализация слушателей **/
  useEffect(() => {
    const subscribers = events.map(event => event.watch(listener));

    let currZoom = mapRef.current?.getView().getZoom();
    mapRef.current?.on('moveend', () => {
      const newZoom = mapRef.current?.getView().getZoom();
      if (currZoom != newZoom) {
        console.log('new zoom: ' + newZoom);
        currZoom = newZoom;
      }
    });

    return () => {
      subscribers.forEach(subscriber => subscriber.unsubscribe());
    };
  }, []);
};
