import { Group, Rect } from 'react-konva';

import { IPath } from '@features/tpu-simulation-model/types';

const Support = ({ x }: { x: number }) => {
  return (
    <Rect
      width={3}
      height={20}
      x={2 + 13 * x}
      y={2}
      fill={'#5A5A5A'}
    />
  );
};

const Rail = ({ width, y }: { width: number; y: number }) => {
  return (
    <Rect
      x={0}
      y={y}
      width={width}
      height={2}
      fill={'#5A5A5A'}
      strokeWidth={1}
      stroke={'#fff'}
    />
  );
};

export const Path = (props: IPath) => {
  const { point, width, height, rotation } = props;

  const numberOfSupports = Math.round((width - 4) / 13);

  return (
    <Group
      x={point.x}
      y={point.y}
      width={width}
      height={height}
      rotation={rotation}
    >
      <Rect
        width={width}
        height={height}
        fill={'#fff'}
      />
      {[...new Array(numberOfSupports)].map((_, i) => (
        <Support
          x={i}
          key={i}
        />
      ))}
      <Rail
        width={width}
        y={5}
      />
      <Rail
        width={width}
        y={17}
      />
    </Group>
  );
};
