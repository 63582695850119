import { sample } from 'effector';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import {
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import {
  $FilterRegions,
  SetMapFeaturesTypes,
  changeMap,
  setMapFeatures,
} from '@features/passenger-traffic/stores';

// Отображение поинта А
sample({
  clock: changeMap,
  source: { FilterRegions: $FilterRegions },
  fn: ({ FilterRegions }) => {
    const layer: SetMapFeaturesTypes = {
      params: {
        layout: EMapFeatureLayout.pointA,
      },
      features: [],
    };

    // Если поинт не выбран
    if (!FilterRegions.selectedRegionA) return layer;

    // Если поинт выбран
    layer.features.push(
      new Feature({
        geometry: new Point(
          fromLonLat(
            FilterRegions.selectedRegionA.center?.geometry.coordinates,
          ),
        ),
        [layerIdKey]: FilterRegions.selectedRegionA.id,
        [layerLayoutKey]: EMapFeatureLayout.pointA,
      }),
    );

    return layer;
  },
  target: setMapFeatures,
});

// Отображение поинта Б
sample({
  clock: changeMap,
  source: { FilterRegions: $FilterRegions },
  fn: ({ FilterRegions }) => {
    const layer: SetMapFeaturesTypes = {
      params: {
        layout: EMapFeatureLayout.pointB,
      },
      features: [],
    };

    // Если поинт не выбран
    if (!FilterRegions.selectedRegionB) return layer;

    // Если поинт выбран
    layer.features.push(
      new Feature({
        geometry: new Point(
          fromLonLat(
            FilterRegions.selectedRegionB.center?.geometry.coordinates,
          ),
        ),
        [layerIdKey]: FilterRegions.selectedRegionB.id,
        [layerLayoutKey]: EMapFeatureLayout.pointB,
      }),
    );

    return layer;
  },
  target: setMapFeatures,
});
