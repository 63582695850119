export const formatDate = (str: string) => {
  const date = new Date(str);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const twoDigitDay = day < 9 ? `0${day}` : day;
  const twoDigitMonth = month < 9 ? `0${month}` : month;

  return `${twoDigitDay}.${twoDigitMonth}.${year}`;
};
