import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import { Box, Divider, IconButton, List, ListItem, Stack } from '@mui/material';

import { BasketIcon, PathWithBorderIcon } from '@components/icons';
import { AddTerminalRailwayStation } from '@components/icons/AddTerminalRailwayStation';
import { FreeCircleIcon } from '@components/icons/FreeCircleIcon/FreeCircleIcon';

import { EMapFeatureLayout } from '@constants/map';

import {
  $EditorMap,
  CustomRailwayStationItem,
  deleteGraphFx,
  deleteStationFx,
} from '@features/pt-forecast-new/stores/map/editor';
import { SelectedFeaturesApi } from '@features/pt-forecast-new/stores/map/selectedFeatures';
import { ModalsApi } from '@features/pt-forecast-new/stores/modals';

export const AssociatedStations = () => {
  const { graphs, stations, selectedStation, selectedGraph } =
    useUnit($EditorMap);
  const { selectAndFocusFeature } = useUnit(SelectedFeaturesApi);
  const { setVisibleCannotDeleteCustomRailwayStation } = useUnit(ModalsApi);

  const handleSelectStation = useCallback(
    (id: string) => {
      const station = stations.find(station => station.id === id);
      if (!station) return;
      const coords = [station.geometry.lon, station.geometry.lat];
      selectAndFocusFeature({
        id,
        layout: EMapFeatureLayout.customRailwayStation,
        coords,
      });
    },
    [selectAndFocusFeature, stations],
  );

  const handleSelectGraph = useCallback(
    (id: string) => {
      const graph = graphs.find(graph => graph.id === id);
      if (!graph) return;
      const coords = graph.geometry.map(({ lon, lat }) => [lon, lat]);
      selectAndFocusFeature({
        id,
        layout: EMapFeatureLayout.customRailwayGraph,
        coords,
      });
    },
    [graphs, selectAndFocusFeature],
  );

  const listObjects = useMemo(
    () =>
      graphs.map(graph => {
        const foundStations: CustomRailwayStationItem[] = [];

        const startStation = stations.find(
          station => station.id === graph.startStationId,
        );
        startStation && foundStations.push(startStation);

        if (graph.intermediateStations.length)
          graph.intermediateStations.forEach(stationId => {
            foundStations.push(stations.find(s => s.id === stationId)!);
          });

        const endStation = stations.find(
          station => station.id === graph.endStationId,
        );
        endStation && foundStations.push(endStation);

        return {
          graph,
          stations: foundStations,
        };
      }),
    [graphs, stations],
  );

  const stationExists = useCallback(
    (station: CustomRailwayStationItem, data: typeof listObjects) =>
      data.some(item =>
        item.stations.some(
          s =>
            s.geometry.lat === station.geometry.lat &&
            s.geometry.lon === station.geometry.lon,
        ),
      ),
    [],
  );

  const missingStations = useMemo(
    () => stations.filter(station => !stationExists(station, listObjects)),
    [listObjects, stationExists, stations],
  );

  const handleDeleteGraph = useCallback((graphItemId: string) => {
    deleteGraphFx({ graphItemId });
  }, []);

  const handleDeleteStation = useCallback(
    (id: string) => {
      const isLastStation = !!graphs.find(
        graph =>
          graph.endStationId === selectedStation ||
          graph.startStationId === selectedStation,
      );
      if (isLastStation) {
        setVisibleCannotDeleteCustomRailwayStation(true);
      } else {
        deleteStationFx({ id });
      }
    },
    [graphs, selectedStation, setVisibleCannotDeleteCustomRailwayStation],
  );

  const handleDeleteStationExist = useCallback((tpuId: string) => {
    deleteStationFx({ id: tpuId });
  }, []);

  return (
    <Stack>
      {listObjects.map((object, i) => {
        const keyGraph = object.graph.id + ' ' + i;

        return (
          <Box
            key={'railway-' + object.graph.id}
            sx={{ px: 3 }}
          >
            {!!i && <Divider sx={{ pt: 2, mb: 2 }} />}

            <List>
              <ListItem
                key={keyGraph}
                className={selectedGraph === object.graph.id ? 'selected' : ''}
                sx={{
                  pl: 0.5,
                  py: 0,
                  columnGap: 1,
                  '&:hover, &.selected': {
                    bgcolor: 'customs.bgHover',
                    borderRadius: '4px',
                  },
                }}
                secondaryAction={
                  selectedGraph === object.graph.id && (
                    <IconButton
                      size="small"
                      color="inherit"
                      sx={{ p: 0.4 }}
                      onClick={() => handleDeleteGraph(object.graph.id)}
                    >
                      <BasketIcon />
                    </IconButton>
                  )
                }
                onClick={() => handleSelectGraph(object.graph.id)}
              >
                <FreeCircleIcon
                  color="white"
                  border="#7A5A5A"
                  size={14}
                />

                {object.graph.name}
              </ListItem>

              {object.stations.map((station, j) => {
                const keyStation = station.id + ' ' + object.graph.id + ' ' + j;

                return (
                  <Box key={keyStation}>
                    <Box sx={{ my: -1 }}>
                      <PathWithBorderIcon
                        path="white"
                        border="#7A5A5A"
                      />
                    </Box>

                    <ListItem
                      className={
                        selectedStation === station.id ? 'selected' : ''
                      }
                      sx={{
                        pl: 0,
                        py: 0,
                        columnGap: 0.5,
                        '&:hover, &.selected': {
                          bgcolor: 'customs.bgHover',
                          borderRadius: '4px',
                        },
                      }}
                      secondaryAction={
                        selectedStation === station.id && (
                          <IconButton
                            size="small"
                            color="inherit"
                            sx={{ p: 0.4 }}
                            onClick={() => handleDeleteStation(station.id)}
                          >
                            <BasketIcon />
                          </IconButton>
                        )
                      }
                      onClick={() => handleSelectStation(station.id)}
                    >
                      <AddTerminalRailwayStation
                        width="32px"
                        height="32px"
                      />
                      {station.name}
                    </ListItem>
                  </Box>
                );
              })}
            </List>
          </Box>
        );
      })}

      {!!listObjects.length && !!missingStations.length && (
        <Divider sx={{ pt: 3, mb: 3 }} />
      )}

      {missingStations.map((station, i) => (
        <Box
          key={'station-' + i}
          sx={{ px: 3 }}
        >
          {!!i && <Divider sx={{ pt: 2, mb: 2 }} />}

          <List>
            <ListItem
              key={station.id + i}
              className={selectedStation === station.id ? 'selected' : ''}
              sx={{
                pl: 0,
                py: 0,
                columnGap: 0.5,
                '&:hover, &.selected': {
                  bgcolor: 'customs.bgHover',
                  borderRadius: '4px',
                },
              }}
              secondaryAction={
                selectedStation === station.id && (
                  <IconButton
                    size="small"
                    color="inherit"
                    sx={{ p: 0.4 }}
                    onClick={() => handleDeleteStationExist(station.id)}
                  >
                    <BasketIcon />
                  </IconButton>
                )
              }
              onClick={() => handleSelectStation(station.id)}
            >
              <AddTerminalRailwayStation
                width="32px"
                height="32px"
              />

              {station.name}
            </ListItem>
          </List>
        </Box>
      ))}
    </Stack>
  );
};
