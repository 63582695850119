import { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
  Typography,
  styled,
} from '@mui/material';

const StyledDialog = styled((props: DialogProps) => (
  <Dialog
    {...props}
    open={true}
    className={props.className}
  />
))(() => ({
  '& .MuiDialog-container': {
    height: 'unset',
  },
  '& .MuiDialogTitle-root': {
    padding: 'unset',
    gridArea: 'title',
  },
  '& .MuiDialogActions-root': {
    padding: 'unset',
    display: 'block',
    width: '100%',
    gridArea: 'actions',
  },
  '& .MuiDialogContent-root': {
    padding: 'unset',
    gridArea: 'content',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  '& .MuiDialog-paper': {
    display: 'grid',
    gridTemplate: '"title title" 20px \n "content actions" 44px / 180px 125px',
    gridColumnGap: '12px',
    gridRowGap: '8px',
    height: '120px',
    width: '366px',
    padding: '24px',
    maxHeight: 'unset',
    margin: 'unset',
  },
}));

export interface RulerInputWindowProps {
  top?: number;
  onSave: (arg: number) => void;
  onClose: () => void;
}

export const RulerInputWindow = ({
  top,
  onSave,
  onClose,
}: RulerInputWindowProps) => {
  const [value, setValue] = useState('');

  const onClick = () => {
    onSave(Number(value));
  };

  return (
    <StyledDialog
      sx={{ top }}
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          fontSize: theme => theme.typography.pxToRem(16),
          lineHeight: theme => theme.typography.pxToRem(20),
          fontWeight: 400,
        }}
      >
        Длина линии
        <Typography
          component={'span'}
          sx={{ color: theme => theme.palette.text.secondary }}
        >
          (м)
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          '.MuiDialogContent-root': {
            padding: 'unset ',
          },
        }}
      >
        <TextField
          placeholder={'От 1 до 5000'}
          value={value}
          onChange={e => setValue(e.target.value)}
          sx={{
            width: '180px',

            '.MuiInputBase-root': {
              backgroundColor: theme =>
                `${theme.palette.background.default} !important`,
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color={'secondary'}
          fullWidth
          sx={{
            minWidth: 'unset',
          }}
          onClick={onClick}
        >
          Сохранить
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
