export const speedsByInfrastructureTypes: Record<string, number> = {
  A_1: 400,
  A_2: 250,
  A_3: 150,
};

export const parsingInfrastructureTypesForRequest: Record<string, number> = {
  A_1: 1,
  A_2: 2,
  A_3: 3,
};
