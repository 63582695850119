import { Typography } from '@mui/material';

interface TitleProps {
  title: string;
}

export function Title({ title }: TitleProps) {
  return (
    <Typography
      variant={'subtitle1'}
      sx={{
        lineHeight: theme => theme.typography.pxToRem(20),
      }}
    >{`Параметры объекта «${title}»`}</Typography>
  );
}
