import { apiClient } from '@api/client';
import { IQuery } from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import './layers';

import { EFilterMapLayout } from '@components/MapFilters';

import {
  removeLayerFeaturesFromMap,
  setLayerFeaturesToMap,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { IQueryGetInfrastructureArgs } from '@features/passenger-traffic/stores/map/types';
import { EVehicle } from '@features/pt-forecast/constants/vehiclesEnum';
import {
  $FilterMap,
  $Map,
  FilterRegionApi,
  changeMap,
  getTransportRegionsFx,
  resetAllFilters,
  resetMap,
  setAllAirports,
  setAllTransportRegions,
  setFilters,
  setLayoutFn,
  setMapFeatures,
  setMapFn,
  setMapPassTraffic,
  setMapPassTrafficPercent,
  setStartPointFn,
} from '@features/pt-forecast/stores';
import { PassengerTrafficApi } from '@features/pt-forecast/stores/charts/passTraffic/store';

import { getPercent } from '@utils/getPercent';

$Map
  .on(setMapFeatures, (state, payload) => {
    if (!state.map) {
      return state;
    }
    removeLayerFeaturesFromMap({
      layout: payload.params.layout,
      map: state.map,
    });

    if (payload.features.length || payload.params.visible === true) {
      setLayerFeaturesToMap({
        features: payload.features,
        layout: payload.params.layout,
        map: state.map,
        width: payload.params.width,
      });
    }

    return state;
  })
  .on(setAllAirports, (state, payload) => {
    return {
      ...state,
      allAirports: payload,
    };
  })
  .on(setAllTransportRegions, (state, payload) => {
    return {
      ...state,
      allTransportRegions: payload,
    };
  })
  .on(setMapFn, (store, payload) => {
    store.map = payload;
    if (store.map) store.map.filterLayout = EFilterMapLayout.PassengerFlows;
    return store;
  })
  .on(setStartPointFn, (state, payload) => {
    if (!state.map) {
      return state;
    }

    state.map.isSetPoint = payload;
    return state;
  })
  .on(setMapPassTrafficPercent, (state, payload) => ({
    ...state,
    passengerTrafficPercent: payload,
  }))
  .on(setMapPassTraffic, (state, payload) => ({
    ...state,
    passengerTraffic: payload,
  }))
  .reset(resetMap);

// Любое изменение фильтров пинает эвент изменения отображения
sample({
  clock: [
    // инициализация карты
    setMapFn,
    // Изменение любого фильтра
    setFilters,
    // Изменение слоя
    setLayoutFn,
    // Установка корреспонденции
    FilterRegionApi.setDirections,
    FilterRegionApi.setRailwayDirections,
    FilterRegionApi.setHighwayDirections,
    // Получены все ТР
    setAllTransportRegions,
    // Получены все аэропорты
    setAllAirports,
    // Данные для поинта получены
    FilterRegionApi.setSelectedRegion,
    // Оба поинта сброшено
    FilterRegionApi.clearSelectedRegions,
    // Установка толщины линейных объектов
    setMapPassTrafficPercent,
    // Сброс карты
    resetMap,
  ],
  target: changeMap,
});

sample({
  clock: PassengerTrafficApi.setPassengerTraffic,
  target: setMapPassTraffic,
});

sample({
  clock: [setMapPassTraffic, setFilters],
  source: { Map: $Map, FilterMap: $FilterMap },
  fn: ({ Map, FilterMap }) => {
    const vehicles = Object.keys(EVehicle) as Array<keyof typeof EVehicle>;
    const total = vehicles.reduce(
      (acc, vehicle) =>
        FilterMap[vehicle] ? acc + Map.passengerTraffic[vehicle] : acc || 0,
      0,
    );
    const passengerTrafficPercent = Object.fromEntries(
      vehicles.map(vehicle => [
        vehicle,
        FilterMap[vehicle]
          ? getPercent(total, Map.passengerTraffic[vehicle]) || 0
          : 0,
      ]),
    ) as Record<keyof typeof EVehicle, number>;
    return passengerTrafficPercent;
  },
  target: setMapPassTrafficPercent,
});

// Сброс геометрии на карте
sample({
  clock: resetAllFilters,
  target: resetMap,
});

sample({
  clock: setMapFn,
  target: [getTransportRegionsFx],
});

sample({
  clock: getTransportRegionsFx.done,
  fn: request => request.result || [],
  target: setAllTransportRegions,
});

// запрос на получение всех тр
getTransportRegionsFx.use(async () => {
  const response = await apiClient.query<IQuery, IQueryGetInfrastructureArgs>({
    query: gql`
      query QueryTransportRegion {
        transportRegion {
          id
          border {
            geometry
            id
          }
        }
      }
    `,
  });

  return response.data.transportRegion;
});
