import { useCallback } from 'react';

import { SxProps, TextField, TextFieldProps, Theme } from '@mui/material';

type FieldNameItemType = {
  value: string;
  onChange: (payload: string) => string;
  sx?: SxProps<Theme>;
};

export const FieldNameItem = (props: FieldNameItemType) => {
  const { value, onChange, sx } = props;

  const onChangeScenarioName = useCallback<
    NonNullable<TextFieldProps['onChange']>
  >(
    e => {
      const value: string = (e.target as HTMLInputElement).value;
      onChange(value);
    },
    [onChange],
  );

  return (
    <TextField
      autoFocus
      value={value}
      onChange={onChangeScenarioName}
      placeholder="Введите название сценария расчета..."
      sx={{
        pl: 3.5,
        pr: 2,
        width: '100%',
        maxWidth: '480px',
        ...sx,
      }}
    />
  );
};
