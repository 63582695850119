import { apiClient } from '@api/client';
import { IQuery, IQueryGetProjectScenariosArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

export const queryGetProjectScenarios = async (
  project: IQueryGetProjectScenariosArgs,
) => {
  const result = await apiClient.query<
    Pick<IQuery, 'getProjectScenarios'>,
    IQueryGetProjectScenariosArgs
  >({
    query: gql`
      query QueryGetProjectScenarios($projectId: String!) {
        getProjectScenarios(projectId: $projectId) {
          id
          name
        }
      }
    `,
    variables: project,
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.getProjectScenarios;
};
