import { Control, Controller } from 'react-hook-form';

import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

import { FormData } from '@features/tpu-calculation-scenarios/stores/form.store';

interface IOption<V> {
  label: string;
  value: V;
}

interface RadioButtonProps<
  Name extends keyof Pick<
    FormData,
    'dayType' | 'dayTimeType' | 'turnstileMode'
  >,
> {
  control: Control<FormData>;
  name: Name;
  label: string;
  options: IOption<FormData[Name]>[];
}

export function RadioButton<
  Name extends keyof Pick<
    FormData,
    'dayType' | 'dayTimeType' | 'turnstileMode'
  >,
>({ control, name, options, label }: RadioButtonProps<Name>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <RadioGroup
          value={value}
          onChange={(_, value) => {
            onChange(value);
          }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              lineHeight: theme => theme.typography.pxToRem(20),
            }}
          >
            {label}
          </Typography>
          {options.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={
                <Radio
                  sx={{
                    padding: '0',
                    margin: '0 8px 0 10px',
                  }}
                />
              }
              label={label}
            />
          ))}
        </RadioGroup>
      )}
    />
  );
}
