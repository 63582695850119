import { Box, styled } from '@mui/material';

type CardModuleStyledProps = {
  bg?: string;
  border?: string;
};

export const CardModuleStyled = styled(Box)<CardModuleStyledProps>(
  ({ theme, bg, border }) => ({
    maxHeight: '404px',
    minHeight: '404px',
    maxWidth: '355px',
    minWidth: '355px',
    borderRadius: '8px',
    boxShadow: '0px 0px 8px 0px #00000026',
    background: bg || theme.palette.background.paper,
    border: border || '1px solid #BBC0CB',
    position: 'relative',
  }),
);
