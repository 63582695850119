import { OneStep } from '@features/ag-constructor/components/ModalSocEco/steps/One';
import { TwoStep } from '@features/ag-constructor/components/ModalSocEco/steps/Two';
import {
  $ModalSocioEconomic,
  ModalSocioEconomicApi,
} from '@features/ag-constructor/components/ModalSocEco/store';
import { fetchCreateScenarioSocEcoFx } from '@features/ag-constructor/stores/currentScenario';
import { ModalsAgConstructorApi } from '@features/ag-constructor/stores/modals';
import { $FeatureSettings } from '@features/ag-constructor/stores/settings';
import { EStepModalSocioEconomic } from '@features/pt-constructor/components/ModalSocioEconomic/store';
import { useUnit } from 'effector-react';
import { useCallback, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { DialogCloseButton } from '@components/DialogCloseButton';

type ModalSocEcoProps = {
  onClose: () => void;
};

export const ModalSocEco = ({ onClose }: ModalSocEcoProps) => {
  const $modalSocioEconomic = useUnit($ModalSocioEconomic);
  const { setStep, reset } = useUnit(ModalSocioEconomicApi);
  const { setModalView } = useUnit(ModalsAgConstructorApi);
  const { projectUUID } = useUnit($FeatureSettings);

  const onSaveHandler = useCallback(() => {
    fetchCreateScenarioSocEcoFx({
      ...$modalSocioEconomic.params,
      name: $modalSocioEconomic.name,
      parent: $modalSocioEconomic.selectedId,
      projectId: projectUUID || '',
    });
    setModalView({ socioEconomic: false });
  }, [
    $modalSocioEconomic.name,
    $modalSocioEconomic.params,
    $modalSocioEconomic.selectedId,
    projectUUID,
    setModalView,
  ]);

  const onNextHandler = () => {
    setStep(EStepModalSocioEconomic.two);
  };

  useEffect(() => {
    return reset;
  }, []);

  return (
    <Dialog
      onClose={onClose}
      open={true}
    >
      <DialogTitle
        sx={{
          position: 'relative',
          fontSize: theme => theme.typography.pxToRem(16),
        }}
      >
        Пользовательский сценарий социально-экономического прогноза
      </DialogTitle>

      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        {$modalSocioEconomic.step === EStepModalSocioEconomic.one && (
          <OneStep />
        )}

        {$modalSocioEconomic.step === EStepModalSocioEconomic.two && (
          <TwoStep />
        )}
      </DialogContent>

      <DialogActions>
        {$modalSocioEconomic.step === EStepModalSocioEconomic.one && (
          <Button onClick={onNextHandler}>Далее</Button>
        )}

        {$modalSocioEconomic.step === EStepModalSocioEconomic.two && (
          <Button onClick={onSaveHandler}>Сохранить</Button>
        )}

        <Button
          variant={'outlined'}
          onClick={onClose}
        >
          Отменить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
