import {
  IMutationCreateGraphItemArgs,
  IQueryGetScenarioGraphArgs,
} from '@api/gql/types';
import { sample } from 'effector';
import { Point } from 'ol/geom';
import { toLonLat } from 'ol/proj';

import { EMapFeatureLayout, EMapFeatureMetaKeys } from '@constants/map';

import { speedsByInfrastructureTypes } from '@features/pt-forecast-new/constants/paramsByInfrastructureTypes';
import { mutationCreateGraphItem } from '@features/pt-forecast-new/stores/api/mutationCreateGraphItem';
import { mutationDeleteGraph } from '@features/pt-forecast-new/stores/api/mutationDeleteGraph';
import { queryGetParentStationParams } from '@features/pt-forecast-new/stores/api/queryGetParentStationParams';
import {
  $FilterMap,
  FilterMapApi,
} from '@features/pt-forecast-new/stores/filters';
import {
  InspectorApi,
  eInspectorType,
} from '@features/pt-forecast-new/stores/inspector';
import {
  clickMap,
  pipeMapFollowPath,
} from '@features/pt-forecast-new/stores/map';
import {
  $EditorMap,
  CustomRailwayGraphGraphItem,
  DrawGraphState,
  EditorMapApi,
  ExtendedIMutationCreateStationArgs,
  ExtendedIQueryGetRailwayStationByIdArgs,
  SelectStationPayload,
  createGraphItemFx,
  createStationFx,
  deleteGraphFx,
  getGraphItemsFx,
  getParentStationParamsFx,
} from '@features/pt-forecast-new/stores/map/editor';
import { $SelectedFeatures } from '@features/pt-forecast-new/stores/map/selectedFeatures';
import { $FeatureSettings } from '@features/pt-forecast-new/stores/settings';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';
import { $YearLine } from '@features/pt-forecast-new/stores/yearLine';

import {
  EPipeMapFollowPath,
  PipeMapFollowPath,
} from '@utils/map/hooks/useMapFollowPath';
import { getFeatureAtPixel } from '@utils/map/tools/getFeatureAtPixel';

getParentStationParamsFx.use(queryGetParentStationParams);

createGraphItemFx.use(mutationCreateGraphItem);

// Указание узлов нового графа
const addNode = sample({
  clock: clickMap,
  source: { UI: $UI, FeatureSettings: $FeatureSettings, EditorMap: $EditorMap },
  filter: ({ UI }) => UI.isDrawGraph,
  fn: ({ EditorMap }, event) => {
    pipeMapFollowPath({
      action: EPipeMapFollowPath.stopFollow,
      payload: null,
    });
    const clickCoords = toLonLat(event.map.getCoordinateFromPixel(event.pixel));
    let targetCoords: number[] | null = null;
    let targetFeatureUUID: string | null = null;
    let targetLayerId: string | null = null;

    // Поиск инфраструктуры непосредственно под курсором
    const featureAtPixel = getFeatureAtPixel({
      map: event.map,
      pixel: event.pixel,
      includeLayouts: [
        EMapFeatureLayout.customRailwayStation,
        EMapFeatureLayout.railwayStation,
      ],
      layersZIndex: {
        [EMapFeatureLayout.railwayStation]: 1,
        [EMapFeatureLayout.customRailwayStation]: 2,
      },
      hitTolerance: 1,
    });

    if (featureAtPixel) {
      const geometry = featureAtPixel.getGeometry();
      if (geometry instanceof Point) {
        targetCoords = toLonLat(geometry.getCoordinates());
        targetFeatureUUID = featureAtPixel.get(
          EMapFeatureMetaKeys.olId,
        ) as string;
        targetLayerId = featureAtPixel.get(
          EMapFeatureMetaKeys.layout,
        ) as string;
      } else throw new Error('Geometry is not Point');
    } else {
      // Проверка инфраструктуры в трассировщике
      pipeMapFollowPath({
        action: EPipeMapFollowPath.requestPointCoordinate,
        payload: {
          cb: args => {
            if (args) {
              targetCoords = toLonLat(args.coords);
              targetFeatureUUID = args.featureUUID;
              targetLayerId = args.layerId;
            }
          },
        },
      });
    }

    if (targetLayerId === EMapFeatureLayout.customRailwayStation)
      return {
        isStation: true,
        isCustom: true,
        isStart: !EditorMap.drawGraph,
        isEnd:
          !!EditorMap.drawGraph?.startStationId &&
          EditorMap.drawGraph.startStationId !== targetFeatureUUID,
        stationId: targetFeatureUUID!,
        coords: targetCoords!,
      };
    else if (targetLayerId === EMapFeatureLayout.railwayStation)
      return {
        isStation: true,
        isCustom: false,
        isStart: !EditorMap.drawGraph,
        isEnd:
          !!EditorMap.drawGraph?.startStationId &&
          EditorMap.drawGraph.startStationId !== targetFeatureUUID,
        stationId: targetFeatureUUID!,
        coords: targetCoords!,
      };
    else
      return {
        isStation: false,
        isCustom: false,
        isStart: false,
        isEnd: false,
        stationId: null,
        coords: clickCoords,
      };
  },
});

// Кеширование последнего действия при по стороении точки (точка или станция)
sample({
  clock: addNode,
  target: EditorMapApi.setLastStep,
});

// Клик по пользовательской станции для старта - запустить построение графа
sample({
  clock: addNode,
  filter: payload =>
    // Допустима инициация построения графа
    payload.isStart &&
    // Недопустимо завершение построения графа
    !payload.isEnd &&
    // Клик по станции
    payload.isStation &&
    // Станция пользовательская
    payload.isCustom,
  fn: payload => {
    return {
      startStationId: payload.stationId!,
      endStationId: null,
      intermediateStations: [],
      nodes: [],
    } as DrawGraphState;
  },
  target: EditorMapApi.startDraw,
});

// Клик по пользовательской станции для завершения - указать станцию в качестве конечной
sample({
  clock: addNode,
  filter: payload =>
    // Если клик не допустим как старт построения графа
    !payload.isStart &&
    // Если клик допустим как завершение построения графа
    payload.isEnd &&
    // Если клик по станции
    payload.isStation &&
    // Если станция пользовательская
    payload.isCustom,
  fn: ({ stationId }) => stationId!,
  target: EditorMapApi.addDrawGraphEndStation,
});

// Клик в пустом месте или по станции - добавить узел
sample({
  clock: addNode,
  fn: ({ coords }) => coords,
  target: EditorMapApi.addGraphNode,
});

// Если станция из сущпола. Запросить её данные
sample({
  clock: addNode,
  filter: payload =>
    // Если клик по станции
    payload.isStation &&
    // Если станция сущпола
    !payload.isCustom,
  fn: payload => {
    return {
      id: payload.stationId,
      isEnd: payload.isEnd,
      isStart: payload.isStart,
    } as ExtendedIQueryGetRailwayStationByIdArgs;
  },
  target: getParentStationParamsFx,
});

// если данные по станции из сущпола получены, инициализировать создание её пользовательского клона
sample({
  clock: getParentStationParamsFx.done,
  source: { FeatureSettings: $FeatureSettings },
  fn: ({ FeatureSettings }, request) => {
    return {
      isForGraph: true,
      isStart: request.params.isStart,
      isEnd: request.params.isEnd,
      point: {
        lon: request.result?.geometry.coordinates[0],
        lat: request.result?.geometry.coordinates[1],
      },
      name: request.result?.name || 'Безымянная станция',
      scenarioId: FeatureSettings.scenarioId,
      vertexId: undefined,
      railwayStationId: request.result?.id,
    } as ExtendedIMutationCreateStationArgs;
  },
  target: createStationFx,
});

// Если удалось создать станцию в качестве конечной точки графа, то записать её id
sample({
  clock: createStationFx.done,
  filter: request => !!request.params.isForGraph && !!request.params.isEnd,
  fn: request => {
    return request.result?.instance?.id || '';
  },
  target: [EditorMapApi.addDrawGraphEndStation],
});

// Если удалось создать станцию в качестве начальной, инициировать создание графа
sample({
  clock: createStationFx.done,
  filter: request => !!request.params.isForGraph && !!request.params.isStart,
  fn: request => {
    return {
      startStationId: request.result?.instance?.id || null,
      endStationId: null,
      intermediateStations: [],
      nodes: [request.result?.instance?.vertex.theGeom.coordinates],
    } as DrawGraphState;
  },
  target: EditorMapApi.startDraw,
});

// Если указана конечная станция - закончить построение графа
sample({
  clock: EditorMapApi.addDrawGraphEndStation,
  source: { EditorMap: $EditorMap },
  fn: () => false,
  target: UIApi.setDrawGraph,
});

// Если пользователь отменил построение графа
sample({
  clock: [UIApi.setDrawGraph],
  source: { UI: $UI, EditorMap: $EditorMap, FeatureSettings: $FeatureSettings },
  filter: ({ UI, EditorMap }) =>
    !UI.isDrawGraph &&
    !!EditorMap.drawGraph &&
    EditorMap.drawGraph.nodes.length < 2,
  target: EditorMapApi.clearDrawGraph,
});

//  По завершении строительства графа, если конечная точка не была указана станцией, создать там вершину
sample({
  clock: [UIApi.setDrawGraph],
  source: { UI: $UI, EditorMap: $EditorMap, FeatureSettings: $FeatureSettings },
  filter: ({ UI, EditorMap }) =>
    // отключен режим построения графа
    !UI.isDrawGraph &&
    // В сторе редактора есть наброски
    !!EditorMap.drawGraph &&
    // В набросках больше одной точки
    EditorMap.drawGraph.nodes.length > 1 &&
    // Нет конечной точки
    !EditorMap.drawGraph.endStationId &&
    // последняя точка не станция
    !EditorMap.lastStep!.isStation,
  fn: ({ EditorMap, FeatureSettings }) => {
    const lastNode =
      EditorMap.drawGraph!.nodes[EditorMap.drawGraph!.nodes.length - 1];

    return {
      scenarioId: FeatureSettings.scenarioId,
      isForGraph: true,
      isEnd: true,
      isStart: false,
      point: {
        lon: lastNode[0],
        lat: lastNode[1],
      },
      name: 'Безымянная станция',
      vertexId: undefined,
    } as ExtendedIMutationCreateStationArgs;
  },
  target: createStationFx,
});

// По завершении строительства графа, если у него есть и начальная и конечная точки отправить его на бэк
sample({
  clock: [EditorMapApi.addDrawGraphEndStation],
  source: {
    UI: $UI,
    EditorMap: $EditorMap,
    FeatureSettings: $FeatureSettings,
    YearLine: $YearLine,
  },
  filter: ({ UI, EditorMap }) =>
    !UI.isDrawGraph &&
    !!EditorMap.drawGraph &&
    EditorMap.drawGraph.nodes.length > 1 &&
    !!EditorMap.drawGraph.endStationId,
  fn: ({ EditorMap, FeatureSettings, YearLine }) => {
    return {
      geometry: {
        coordinates: EditorMap.drawGraph?.nodes.map(node => ({
          lat: node[1],
          lon: node[0],
        })),
      },
      scenarioId: FeatureSettings.scenarioId || '',
      year: YearLine.selectedYear,
      name: 'Безымянная линия',
      startStationId: EditorMap.drawGraph?.startStationId,
      endStationId: EditorMap.drawGraph?.endStationId,
    } as IMutationCreateGraphItemArgs;
  },
  target: createGraphItemFx,
});

// Переместить построенный граф из временного хранилища в постоянное
sample({
  clock: createGraphItemFx.done,
  source: { EditorMap: $EditorMap },
  fn: ({ EditorMap }, request) => {
    return {
      id: request.result?.instance?.id,
      name: request.result?.instance?.name || 'Безымянная линия',
      startStationId: EditorMap.drawGraph?.startStationId,
      endStationId: EditorMap.drawGraph?.endStationId,
      intermediateStations: EditorMap.drawGraph?.intermediateStations,
      geometry: request.params.geometry?.coordinates,
      yearOfCommissioning: request.result?.instance?.year,
      maxSpeed:
        request.result?.instance?.speed ||
        speedsByInfrastructureTypes[request.result?.instance?.type || 'A_3'],
      infrastructureType: request.result?.instance?.type,
      delete: false,
      isUpdated: true,
      isSaved: false,
    } as CustomRailwayGraphGraphItem;
  },
  target: EditorMapApi.addGraph,
});

// Сбросить стейт рисуемого графа
sample({
  clock: createGraphItemFx.done,
  target: EditorMapApi.clearDrawGraph,
});

// // Получить параметры выбранного графа жд
sample({
  clock: $SelectedFeatures,
  source: { SelectedFeatures: $SelectedFeatures, EditorMap: $EditorMap },
  filter: ({ SelectedFeatures, EditorMap }) =>
    (SelectedFeatures[0]?.layout === EMapFeatureLayout.customRailwayGraph &&
      SelectedFeatures[0]?.id !== EditorMap.selectedGraph) ||
    !SelectedFeatures.length,
  fn: ({ SelectedFeatures }) => {
    return {
      id: SelectedFeatures[0]?.id || null,
    } as SelectStationPayload;
  },
  target: EditorMapApi.selectGraph,
});

// Указание типа инспектора
sample({
  clock: EditorMapApi.selectGraph,
  fn: () => {
    return eInspectorType.customRailwayGraph;
  },
  target: InspectorApi.setType,
});

////////////////////////////////////////////////////////////////////////////////

deleteGraphFx.use(mutationDeleteGraph);

// После удаления графа перезапросить всю кастомную геометрию
sample({
  clock: deleteGraphFx.done,
  source: { FeatureSettings: $FeatureSettings },
  fn: ({ FeatureSettings }) => {
    return {
      scenarioId: FeatureSettings.scenarioId,
    } as IQueryGetScenarioGraphArgs;
  },
  target: [getGraphItemsFx, EditorMapApi.clearSelectedGraph],
});

// Закрыть инспектор при сбросе выбранного графа
sample({
  clock: EditorMapApi.clearSelectedGraph,
  fn: () => false,
  target: UIApi.setVisibleInspector,
});

// Слежение за изменением фильтров, и актуализация списка контроллера визуализации построения графа
sample({
  clock: [FilterMapApi.setFilters, EditorMapApi.startDraw],
  source: { Filters: $FilterMap },
  fn: () => {
    const layers = [];

    layers.push(EMapFeatureLayout.customRailwayStation);
    layers.push(EMapFeatureLayout.railwayStation);
    // layers.push(EMapFeatureLayout.railway);

    return {
      action: EPipeMapFollowPath.updateTrackedLayers,
      payload: {
        layers: layers,
      },
    } as PipeMapFollowPath;
  },
  target: pipeMapFollowPath,
});

//////////////////////////////////////////////////////////////////////////

// Если пользователь начал строить граф, пнуть утилку для визуализации построения графа и передать ей координаты последней ноды
sample({
  clock: [EditorMapApi.addGraphNode, EditorMapApi.startDraw],
  source: { EditorMap: $EditorMap },
  filter: ({ EditorMap }) => !!EditorMap.drawGraph?.nodes?.length,
  fn: ({ EditorMap }) => {
    return {
      action: EPipeMapFollowPath.startFollow,
      payload: {
        coords:
          EditorMap.drawGraph!.nodes[EditorMap.drawGraph!.nodes.length - 1],
      },
    } as PipeMapFollowPath;
  },
  target: pipeMapFollowPath,
});

// Если построение графа завершено - остановить трассировку построения графа
sample({
  clock: UIApi.setDrawGraph,
  filter: payload => !payload,
  fn: () => {
    return {
      action: EPipeMapFollowPath.stopFollow,
      payload: null,
    } as PipeMapFollowPath;
  },
  target: pipeMapFollowPath,
});

// Если построение графа сброшено - остановить трассировку построения графа
sample({
  clock: EditorMapApi.clearDrawGraph,
  fn: () => {
    return {
      action: EPipeMapFollowPath.stopFollow,
      payload: null,
    } as PipeMapFollowPath;
  },
  target: pipeMapFollowPath,
});

// Если инициировано создание конечной станции, остановить трассировку построения графа
sample({
  clock: createStationFx,
  filter: request => !!request.isForGraph && !!request.isEnd,
  fn: () => {
    return {
      action: EPipeMapFollowPath.stopFollow,
      payload: null,
    } as PipeMapFollowPath;
  },
  target: pipeMapFollowPath,
});

// После завершения строительства графа, сбросить кэш
sample({
  clock: createGraphItemFx.done,
  fn: () => {
    return {
      action: EPipeMapFollowPath.clearLastShot,
      payload: null,
    } as PipeMapFollowPath;
  },
  target: pipeMapFollowPath,
});
