import { apiClient } from '@api/client';
import {
  IQuery,
  IQueryGetProjectTransportDistrictsByPrefixArgs,
} from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import {
  RegionOptionItem,
  RegionOptionsApi,
  getTransportDistrictsByPrefixFx,
  searchRegions,
} from '@features/ag-forecast/stores/lists/regionsOptions/store';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';

sample({
  clock: searchRegions,
  source: { FeatureSettings: $FeatureSettings },
  fn: ({ FeatureSettings }, value) => {
    return {
      projectId: FeatureSettings.projectUUID,
      contains: value,
    } as IQueryGetProjectTransportDistrictsByPrefixArgs;
  },
  target: getTransportDistrictsByPrefixFx,
});

getTransportDistrictsByPrefixFx.use(async params => {
  const response = await apiClient.query<
    Pick<IQuery, 'getProjectTransportDistrictsByPrefix'>,
    IQueryGetProjectTransportDistrictsByPrefixArgs
  >({
    query: gql`
      query QueryGetProjectTransportDistrictsByPrefix(
        $projectId: String!
        $contains: String!
      ) {
        getProjectTransportDistrictsByPrefix(
          projectId: $projectId
          contains: $contains
        ) {
          name
          district {
            id
          }
        }
      }
    `,
    variables: {
      projectId: params.projectId,
      contains: params.contains,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data.getProjectTransportDistrictsByPrefix;
});

sample({
  clock: getTransportDistrictsByPrefixFx.done,
  fn: request => {
    return (
      (request.result?.map(item => ({
        value: item?.district?.id,
        label: item?.name,
      })) as RegionOptionItem[]) || []
    );
  },
  target: RegionOptionsApi.setOptions,
});
