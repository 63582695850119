import { createApi, createStore } from 'effector';

import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';

const initState: SocEcoTableType = {
  selectedTable: SOCECO_TABLE_IDS.TABLE_1_1,
  tables: {
    [SOCECO_TABLE_IDS.TABLE_1_1]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_1_2]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_1_3]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_1_4]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_1_5]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_2_1]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_2_2]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_2_3]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_2_4]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_2_5]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_3_1]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_3_2]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_3_3]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_3_4]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_3_5]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_3_6]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_3_7]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_4_1]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_4_2]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_4_3]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_4_4]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_5_1]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_5_2]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_5_3]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_5_4]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_5_5]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_5_6]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_5_7]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_5_8]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_6_1]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_6_2]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_6_3]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_7_1]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_7_2]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_7_3]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_7_4]: {
      page: 1,
      isLoading: false,
      data: [],
    },
    [SOCECO_TABLE_IDS.TABLE_7_5]: {
      page: 1,
      isLoading: false,
      data: [],
    },
  },
};

export const $SocEcoTable = createStore<SocEcoTableType>(initState);

export const SocEcoTableApi = createApi($SocEcoTable, {
  setTable: (store, payload) => ({
    ...store,
    selectedTable: payload,
  }),
  setPage: (store, payload: { tableId: SocEcoTableIdsType; page: number }) => ({
    ...store,
    tables: {
      ...store.tables,
      [payload.tableId]: {
        ...store.tables[payload.tableId],
        page: payload.page,
      },
    },
  }),
  setLoading: (store, payload: SetLoadingForTablePayload) => ({
    ...store,
    tables: {
      ...store.tables,
      [payload.tableId]: {
        ...store.tables[payload.tableId],
        isLoading: payload.isLoading,
      },
    },
  }),
  setDataForTable: (store, payload: SetDataForTablePayload) => ({
    ...store,
    tables: {
      ...store.tables,
      [payload.tableId]: {
        ...store.tables[payload.tableId],
        data: payload.data || [],
      },
    },
  }),
  clearDataForTable: (store, payload: SocEcoTableIdsType) => ({
    ...store,
    tables: {
      ...store.tables,
      [payload]: {
        ...store.tables[payload],
        isLoading: false,
        data: [],
      },
    },
  }),
});

export type SetLoadingForTablePayload = {
  tableId: SocEcoTableIdsType;
  isLoading: TableState['isLoading'];
};

export type SetDataForTablePayload = {
  tableId: SocEcoTableIdsType;
  data: TableState['data'];
};

type TableState = {
  data: Record<string, string | number | null | undefined>[];
  page: number;
  isLoading: boolean;
};

type TableKeys = keyof typeof SOCECO_TABLE_IDS;

export type SocEcoTableIdsType = (typeof SOCECO_TABLE_IDS)[TableKeys];

export type SocEcoTableType = {
  selectedTable: SocEcoTableIdsType;
  tables: Record<SocEcoTableIdsType, TableState>;
};
