import { apiClient } from '@api/client';
import {
  IMutation,
  IMutationCreateScenarioSocEcoArgs,
} from '@api/gql/ag-types';
import { gql } from '@apollo/client';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

export const mutationCreateSocEcoScenario = async (
  newScenarioSocEco: IMutationCreateScenarioSocEcoArgs,
) => {
  const result = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation CreateScenarioSocEcoMutation(
        $annualEducationPlacesGrowthRate: Float!
        $annualGardenPartnershipGrowthRate: Float!
        $annualJobsGrowthRate: Float!
        $annualPopulationGrowthRate: Float!
        $annualRetailSpaceGrowthRate: Float!
        $annualSalariesGrowthRate: Float!
        $annualServiceJobsGrowthRate: Float!
        $name: String!
        $parent: String!
        $projectId: String!
      ) {
        createScenarioSocEco(
          annualEducationPlacesGrowthRate: $annualEducationPlacesGrowthRate
          annualGardenPartnershipGrowthRate: $annualGardenPartnershipGrowthRate
          annualJobsGrowthRate: $annualJobsGrowthRate
          annualPopulationGrowthRate: $annualPopulationGrowthRate
          annualRetailSpaceGrowthRate: $annualRetailSpaceGrowthRate
          annualSalariesGrowthRate: $annualSalariesGrowthRate
          annualServiceJobsGrowthRate: $annualServiceJobsGrowthRate
          name: $name
          parent: $parent
          projectId: $projectId
        ) {
          computingInstance {
            annualEducationPlacesGrowthRate
            annualGardenPartnershipGrowthRate
            annualJobsGrowthRate
            annualPopulationGrowthRate
            annualRetailSpaceGrowthRate
            annualSalariesGrowthRate
            annualServiceJobsGrowthRate
            created
            errors
            finished
            id
            modified
            name
            scenarioId
            type
          }
        }
      }
    `,
    variables: newScenarioSocEco,
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.createScenarioSocEco;
};
