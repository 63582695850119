import { ReactNode, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';

type ContentTrafficFrequencyProps = {
  defaultExpanded?: boolean;
  title: string;
  children?: ReactNode;
};

export const ContentTrafficFrequency = (
  props: ContentTrafficFrequencyProps,
) => {
  const { defaultExpanded = false, title, children } = props;

  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <Accordion
      expanded={expanded}
      onChange={(_event, value) => {
        setExpanded(value);
      }}
      disableGutters
      sx={{
        background: 'transparent',
        border: 'none',
        boxShadow: 'none',
        '&:before': { display: 'none' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          flexDirection: 'row-reverse',
          px: 0,
          '& > .MuiAccordionSummary-content': {
            pl: 1,
          },
        }}
      >
        <Typography
          fontSize={theme => theme.typography.pxToRem(14)}
          color={theme => theme.palette.customs.avia}
        >
          {title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ pt: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
};
