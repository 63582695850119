import { sample } from 'effector';

import { EMapFeatureLayout, EMapFeatureMetaKeys } from '@constants/map';

import { railwaySource } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import {
  $LayersVisible,
  LayersVisibleApi,
} from '@features/pt-forecast-new/stores/map/layersVisible';
import { $SelectedFeatures } from '@features/pt-forecast-new/stores/map/selectedFeatures';
import {
  initSources,
  pipeRailwayTileLayer,
} from '@features/pt-forecast-new/stores/map/store';

import {
  EPipeTileLayer,
  PipeTileLayer,
} from '@utils/map/hooks/useInitTileLayer';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload && Object.keys(payload).includes(EMapFeatureLayout.railway),
  fn: payload => {
    return {
      action: EPipeTileLayer.setVisible,
      payload: payload![EMapFeatureLayout.railway],
    } as PipeTileLayer;
  },
  target: pipeRailwayTileLayer,
});

// Установка источника тайлов
sample({
  clock: [initSources],
  fn: () => {
    return {
      action: EPipeTileLayer.changeSource,
      payload: railwaySource(),
    } as PipeTileLayer;
  },
  target: pipeRailwayTileLayer,
});

// Применение стиля к выбранным фичам
sample({
  clock: [$SelectedFeatures, $LayersVisible],
  source: {
    LayersVisible: $LayersVisible,
    SelectedFeatures: $SelectedFeatures,
  },
  filter: ({ LayersVisible }) => LayersVisible[EMapFeatureLayout.railway],
  fn: ({ SelectedFeatures }) => {
    return {
      action: EPipeTileLayer.setStyle,
      payload: {
        selectedFeatureIds:
          SelectedFeatures.filter(
            item => item.layout === EMapFeatureLayout.railway,
          ).map(item => item.id) || [],
        featureIdMetaKey: EMapFeatureMetaKeys.olId,
      },
    } as PipeTileLayer;
  },
  target: pipeRailwayTileLayer,
});
