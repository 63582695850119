import { apiClient } from '@api/client';
import { IQuery } from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import {
  FetchAgglomerationsFx,
  ListAgglomerationsApi,
} from '@features/ag-projects-registry/store/lists/agglomerations/store';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

sample({
  clock: FetchAgglomerationsFx.done,
  fn: request => {
    return request.result.map(item => ({
      id: item?.id || '',
      name: item?.name || '',
    }));
  },
  target: ListAgglomerationsApi.setAgglomerations,
});

FetchAgglomerationsFx.use(async () => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query GetAgglomerations {
        getAgglomerations {
          id
          name
        }
      }
    `,
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data?.getAgglomerations || [];
});
