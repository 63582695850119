import { ITransportRegionType } from '@api/gql/types';
import {
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { AccordionFilter } from './AccordionFilter';

import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  IconButton,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { EFilterMapPoint } from '@components/MapFilters/interface';
import { LocationIcon } from '@components/icons';

import { RegionPoint } from '@features/passenger-traffic/containers/MapFiltersContainer/MapFiltersContainer';
import { SetRegionArg } from '@features/passenger-traffic/stores';

const ButtonLocation = styled(IconButton)(() => ({
  width: '56px',
  marginRight: '-11px',
  color: 'black',
  marginTop: '-6px',
  marginLeft: '8px',
}));

export type BetweenPointsProps = {
  regionA: ITransportRegionType | null;
  regionB: ITransportRegionType | null;
  regionsA: ITransportRegionType[];
  regionsB: ITransportRegionType[];
  disabled?: boolean;
  setPoint(point: EFilterMapPoint): void;
  fetchRegion(region: RegionPoint): void;
  setRegion(region: SetRegionArg): void;
  onReset?(): void;
};

export const BetweenPoints = ({
  regionsA,
  regionsB,
  setPoint,
  fetchRegion,
  setRegion,
  regionA,
  regionB,
  disabled,
  onReset,
}: BetweenPointsProps) => {
  const [searchRegionA, setSearchRegionA] = useState('');
  const [searchRegionB, setSearchRegionB] = useState('');
  const [, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);

  const setSearchRegionValue = useCallback(
    (value: string, point: EFilterMapPoint) => {
      switch (point) {
        case EFilterMapPoint.A:
          setSearchRegionA(value);
          return;
        case EFilterMapPoint.B:
          setSearchRegionB(value);
          return;
      }
    },
    [],
  );

  const handlerChangeAutocomplete = useCallback(
    (point: EFilterMapPoint) =>
      (
        _e: SyntheticEvent<Element, Event>,
        option: ITransportRegionType | string | null,
        reason: AutocompleteChangeReason,
      ) => {
        if (reason === 'clear' || typeof option === 'string') {
          return;
        }

        setRegion({ option, point });
      },
    [setRegion],
  );

  const handlerChangeTextField = useCallback(
    (point: EFilterMapPoint) => (e: SyntheticEvent<Element, Event>) => {
      const value = (e?.target as HTMLInputElement)?.value;

      setSearchRegionValue(value || '', point);
    },
    [setSearchRegionValue],
  );

  const onInputChange = useCallback(
    (point: EFilterMapPoint) =>
      (
        _e: SyntheticEvent<Element, Event>,
        value: string | null,
        reason: AutocompleteInputChangeReason,
      ) => {
        const clearIconClick = reason === 'clear';
        const region = point === EFilterMapPoint.A ? regionA : regionB;
        const clearInputValue = reason === 'input' && !value && region;

        if (clearIconClick || clearInputValue) {
          setRegion({ option: null, point });
          !!onReset && onReset();
        }
      },
    [onReset, regionA, regionB, setRegion],
  );

  const onLocationClick = useCallback(
    (point: EFilterMapPoint) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setPoint(point);
    },
    [setPoint],
  );

  const renderField = useCallback(
    (point: EFilterMapPoint) => (
      <Grid
        xs={12}
        wrap={'nowrap'}
        display={'flex'}
      >
        <Autocomplete
          fullWidth
          disabled={disabled}
          id={`regions${point}`}
          options={point === EFilterMapPoint.A ? regionsA : regionsB}
          freeSolo
          onChange={handlerChangeAutocomplete(point)}
          onInputChange={onInputChange(point)}
          getOptionLabel={option =>
            typeof option === 'object' ? option.name : option
          }
          renderOption={(props, option) => (
            <Typography {...props}>{option.name || ''}</Typography>
          )}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          forcePopupIcon={false}
          inputValue={
            point === EFilterMapPoint.A ? searchRegionA : searchRegionB
          }
          renderInput={params => (
            <TextField
              {...params}
              placeholder={point === EFilterMapPoint.A ? 'A:' : 'Б:'}
              onChange={handlerChangeTextField(point)}
            />
          )}
        />

        <ButtonLocation
          onClick={onLocationClick(point)}
          disabled={disabled}
        >
          <LocationIcon text={point === EFilterMapPoint.A ? 'A' : 'Б'} />
        </ButtonLocation>
      </Grid>
    ),
    [
      onInputChange,
      handlerChangeAutocomplete,
      handlerChangeTextField,
      onLocationClick,
      regionsA,
      regionsB,
      searchRegionA,
      searchRegionB,
      disabled,
    ],
  );

  useEffect(() => {
    if (regionA?.name) {
      setSearchRegionA(regionA.name);
    } else {
      setSearchRegionA('');
    }
  }, [regionA?.name]);

  useEffect(() => {
    if (regionB?.name) {
      setSearchRegionB(regionB.name);
    } else {
      setSearchRegionB('');
    }
  }, [regionB?.name]);

  useEffect(() => {
    if (!searchRegionA) {
      return;
    }

    const newTimeout = setTimeout(() => {
      fetchRegion({ name: searchRegionA, point: EFilterMapPoint.A });
      setSearchTimeout(null);
    }, 700);

    setSearchTimeout(newTimeout);

    return () => clearTimeout(newTimeout);
  }, [searchRegionA, fetchRegion]);

  useEffect(() => {
    if (!searchRegionB) {
      return;
    }

    const newTimeout = setTimeout(() => {
      fetchRegion({ name: searchRegionB, point: EFilterMapPoint.B });
      setSearchTimeout(null);
    }, 700);

    setSearchTimeout(newTimeout);

    return () => clearTimeout(newTimeout);
  }, [searchRegionB, fetchRegion]);

  // TODO настроить формат для value в Autocomplete
  return (
    <AccordionFilter title={'Между транспортными районами'}>
      <Grid
        container
        spacing={2}
        flexDirection={'column'}
      >
        {renderField(EFilterMapPoint.A)}
        {renderField(EFilterMapPoint.B)}
      </Grid>
    </AccordionFilter>
  );
};
