import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const LadderIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        d="M9.83 4.47c.712 0 1.21-.563 1.21-1.267s-.57-1.267-1.281-1.197c-.712 0-1.21.563-1.21 1.267s.57 1.268 1.281 1.197ZM6.057 10.742l.285.07 1.28-3.38c.143-.281.286-.493.57-.633l-.498 7.252-1.139 2.887s-.213.281 0 .704c.143.282.712.634.712.634l1.993-3.873.285-3.098h.071l1.495 2.253-.071 2.253s.07.352.284.564c.214.21.57.21.57.21l.427-.07.142-3.52-1.637-2.817c0-.774.143-2.605.143-3.38 0-1.055-.356-1.619-1.638-1.69-.284 0-1.922-.28-2.776 1.761L5.487 9.756c-.142.352 0 .774.57.986Z"
        fill="#3679EA"
      />
      <path
        d="M17.162 14.184v1.619H13.39v1.69H9.545v1.62H5.773v1.69H2v1.196h5.054v-1.69h3.844v-1.62h3.773V17h3.844v-1.62h3.488v-1.196h-4.84Z"
        fill="#3679EA"
      />
    </SvgIcon>
  );
};
