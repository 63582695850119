// Скаллирует цвет от minInput до maxInput от зеленого к красному
export const scaleColor = (
  inputValue: number,
  minInput: number,
  maxInput: number,
) => {
  inputValue = Math.min(Math.max(inputValue, minInput), maxInput);
  const hue = 110 - ((inputValue - minInput) / (maxInput - minInput)) * 110;
  return `hsl(${hue}, 95%, 35%)`;
};
