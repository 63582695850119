import { apiClient } from '@api/client';
import { IQuery, IQueryGetScenarioByIdArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import {
  MainTpuOption,
  MainTpuOptionsApi,
  fetchMainTpuOptions,
  fetchMainTpuOptionsFx,
} from '@features/ag-forecast/stores/lists/mainTpuOptions/store';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';

// Инициировать запрос основных тпу по сценарию
sample({
  clock: fetchMainTpuOptions,
  source: { FeatureSettings: $FeatureSettings },
  fn: ({ FeatureSettings }) => {
    return {
      scenarioId: FeatureSettings.scenarioUUID,
    } as IQueryGetScenarioByIdArgs;
  },
  target: fetchMainTpuOptionsFx,
});

// Запрос основных тпу по сценарию
fetchMainTpuOptionsFx.use(async params => {
  const response = await apiClient.query<
    Pick<IQuery, 'getScenarioById'>,
    IQueryGetScenarioByIdArgs
  >({
    query: gql`
      query QueryGetScenarioById($scenarioId: String!) {
        getScenarioById(scenarioId: $scenarioId) {
          mainTpu {
            id
            name
          }
        }
      }
    `,
    variables: {
      scenarioId: params.scenarioId,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  return response.data.getScenarioById || null;
});

// Записать в стор опции основных тпу
sample({
  clock: fetchMainTpuOptionsFx.done,
  fn: request => {
    return request.result?.mainTpu?.map(item => ({
      id: item?.id || '',
      name: item?.name || '',
    })) as MainTpuOption[];
  },
  target: MainTpuOptionsApi.setOptions,
});
