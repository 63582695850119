import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import {
  Autocomplete,
  IconButton,
  TextField,
  Typography,
  UseAutocompleteProps,
  autocompleteClasses,
  inputBaseClasses,
  outlinedInputClasses,
} from '@mui/material';

import { CloseIcon } from '@components/icons';

import {
  $CurrentProject,
  CurrentProjectApi,
} from '@features/ag-projects-registry/store/currentProject';
import { $Errors } from '@features/ag-projects-registry/store/errors';
import { $ListAgglomerations } from '@features/ag-projects-registry/store/lists/agglomerations';
import { $Settings } from '@features/ag-projects-registry/store/settings/store';

type selectOption = {
  value: string;
  label: string;
};

export const AgglomerationSelect = () => {
  const { agglomerations } = useUnit($ListAgglomerations);
  const { readonly } = useUnit($Settings);
  const { setAgglomerationOption } = useUnit(CurrentProjectApi);
  const currentAgglomeration = useUnit($CurrentProject);
  const { isCheckErrors } = useUnit($Errors);

  const handleAgSelect = useCallback<
    NonNullable<
      UseAutocompleteProps<selectOption, false, false, false>['onChange']
    >
  >(
    (_e, option) => {
      setAgglomerationOption(
        !option
          ? null
          : {
              name: option.label,
              id: option.value,
            },
      );
    },
    [setAgglomerationOption],
  );

  const agglomerationsOptions = useMemo<selectOption[]>(() => {
    return agglomerations.map(item => ({
      value: item.id,
      label: item.name,
    }));
  }, [agglomerations]);

  const selectedOption = useMemo<selectOption | null>(() => {
    if (!currentAgglomeration?.selectedAgglomeration) return null;
    return {
      value: currentAgglomeration.selectedAgglomeration.id,
      label: currentAgglomeration.selectedAgglomeration.name,
    };
  }, [currentAgglomeration?.selectedAgglomeration]);

  const handleClearScenario = useCallback(
    () => setAgglomerationOption(null),
    [setAgglomerationOption],
  );

  return (
    <>
      <Autocomplete
        fullWidth
        disablePortal
        disabled={readonly}
        options={agglomerationsOptions}
        value={selectedOption}
        onChange={handleAgSelect}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        sx={{
          [`&.${autocompleteClasses.hasPopupIcon}.${autocompleteClasses.hasClearIcon} .${outlinedInputClasses.root}`]:
            {
              pr: 4,
            },
          [`& .${inputBaseClasses.root}.${outlinedInputClasses.root}.${outlinedInputClasses.disabled}`]:
            {
              pointerEvents: 'none',
            },
          [`&.${autocompleteClasses.root} .${autocompleteClasses.clearIndicator}`]:
            {
              display: 'none',
            },
        }}
        renderInput={params => (
          <TextField
            {...params}
            size="medium"
            placeholder="Агломерация"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {selectedOption && !readonly && (
                    <IconButton
                      size="small"
                      onClick={handleClearScenario}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.value}
          >
            <Typography>{option.label}</Typography>
          </li>
        )}
      />

      {isCheckErrors && !selectedOption && (
        <Typography
          variant="caption"
          sx={{
            color: theme => theme.palette.secondary.main,
            mt: 1,
          }}
        >
          Необходимо выбрать агломерацию
        </Typography>
      )}
    </>
  );
};
