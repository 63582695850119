import { ModalPlanErrors } from '@features/tpu-calculation-scenarios/components/ModalPlanErrors';
import { ModalDeleteScenario } from '@features/tpu-calculation-scenarios/components/modal-delete-scenario';

export const Modals = () => {
  return (
    <>
      <ModalPlanErrors />
      <ModalDeleteScenario />
    </>
  );
};
