import { Room } from './Room';
import { Wall } from './Wall';

import { Ladder } from '@features/tpu-simulation-model/components/Editor/Constructor/Ladder';
import { SinglePointObject } from '@features/tpu-simulation-model/components/Editor/Constructor/SinglePointObject';
import { UseSimulationModelHandlersType } from '@features/tpu-simulation-model/hooks';
import {
  EditableObjectType,
  IPoint,
} from '@features/tpu-simulation-model/types';

interface ConstructorProps {
  createdObjectType: EditableObjectType;
  mousePosition: IPoint;
  points: IPoint[] | null;
  onComplete: UseSimulationModelHandlersType['onComplete'];
}

export const Constructor = ({
  createdObjectType,
  points,
  mousePosition,
  onComplete,
}: ConstructorProps) => {
  switch (createdObjectType) {
    case EditableObjectType.Room:
      return (
        <Room
          onComplete={onComplete}
          points={points}
          mousePosition={mousePosition}
        />
      );
    case EditableObjectType.Wall:
      return (
        <Wall
          onComplete={onComplete}
          points={points}
          mousePosition={mousePosition}
        />
      );
    case EditableObjectType.Ladder:
      return <Ladder point={mousePosition} />;
    case EditableObjectType.Platform:
    case EditableObjectType.Door:
    case EditableObjectType.Elevator:
    case EditableObjectType.Path:
    case EditableObjectType.PassengerGenerator:
    case EditableObjectType.Exit:
    case EditableObjectType.TicketOffice:
    case EditableObjectType.TicketPrintingMachine:
    case EditableObjectType.Toilet:
    case EditableObjectType.Turnstile:
    case EditableObjectType.SecurityCheckpoint:
    case EditableObjectType.Waiting:
    case EditableObjectType.InformationTable:
    case EditableObjectType.Restaurant:
    case EditableObjectType.Buffet:
    case EditableObjectType.Bankomat:
    case EditableObjectType.Kiosk:
    case EditableObjectType.Bank:
    case EditableObjectType.Other:
    case EditableObjectType.VendingMachine:
    case EditableObjectType.Store:
      return (
        <SinglePointObject
          point={mousePosition}
          type={createdObjectType}
        />
      );
    default:
      null;
  }
};
