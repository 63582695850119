import { IDataFileInfoType, IDataFileTypeEnum } from '@api/gql/tpu-types';
import { v4 as uuidv4 } from 'uuid';

import { IFloorFile } from '@features/tpu-project-registry/types/form';

export const DEFAULT_FLOOR_FILES_STATE: IFloorFile[] = [
  {
    description: '*Поэтажный план',
    id: '',
    uuid: uuidv4(),
  },
];

export const DEFAULT_DATA_FILES_STATE: Record<
  IDataFileTypeEnum,
  IDataFileInfoType | null
> = {
  [IDataFileTypeEnum.LongDistanceTrainSchedules]: null,
  [IDataFileTypeEnum.CommuterTrainSchedules]: null,
  [IDataFileTypeEnum.LongDistanceTrainFlow]: null,
  [IDataFileTypeEnum.CommuterTrainFlow]: null,
  [IDataFileTypeEnum.Visitors]: null,
  [IDataFileTypeEnum.ShareOfEntrances]: null,
  [IDataFileTypeEnum.ShareOfExits]: null,
  [IDataFileTypeEnum.Distribution]: null,
  [IDataFileTypeEnum.EpureVisitors]: null,
  [IDataFileTypeEnum.TicketOffices]: null,
  [IDataFileTypeEnum.Turnstiles]: null,
  [IDataFileTypeEnum.CommercialObjects]: null,
};

export const DATA_FILES_LABEL: Record<IDataFileTypeEnum, string> = {
  [IDataFileTypeEnum.CommuterTrainFlow]: 'Поток пригородные',
  [IDataFileTypeEnum.Visitors]: 'Посетители',
  [IDataFileTypeEnum.EpureVisitors]: 'Эпюра посетителей',
  [IDataFileTypeEnum.ShareOfExits]: 'Доли выходов',
  [IDataFileTypeEnum.ShareOfEntrances]: 'Доли входов',
  [IDataFileTypeEnum.TicketOffices]: 'Кассы',
  [IDataFileTypeEnum.Distribution]: 'Техническое распределение',
  [IDataFileTypeEnum.LongDistanceTrainFlow]: 'Поток дальние',
  [IDataFileTypeEnum.LongDistanceTrainSchedules]: 'Расписание дальние',
  [IDataFileTypeEnum.CommercialObjects]: 'Перечень коммерческих объектов',
  [IDataFileTypeEnum.Turnstiles]: 'АСОКУПЭ',
  [IDataFileTypeEnum.CommuterTrainSchedules]: 'Расписание пригородные',
};

export const MAX_FLOOR_FILES = 7;
