import { IPassengerTrafficType, Maybe } from '@api/gql/types';

import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { FilterMapData } from '@features/passenger-traffic/stores/filters/store';
import {
  PassengerTrafficInspectorType,
  intermediateCorrespondenceTableData,
} from '@features/passenger-traffic/stores/inspector/PassengerTraffic/store';
import { InspectorType } from '@features/passenger-traffic/stores/inspector/store';
import { VehicleTypeCode } from '@features/passenger-traffic/stores/inspector/types';

/**
 * Сумма пассажиропотока по одному виду транспорта
 * @param vehicles - массив всех видов пассажиропотока
 * @param typeVehicle - тип транспорта для суммирования
 * @returns сумма пассажиропотока по одному виду транспорта
 */
export const getVehicleTotalInPassengerTraffic = (
  vehicles: Maybe<Maybe<IPassengerTrafficType>[]>,
  typeVehicle: VehicleTypeCode | string,
) => {
  if (!vehicles?.length) return 0;

  const filteredVehicles = vehicles?.filter(
    item => item?.vehicle.code === typeVehicle,
  );

  return filteredVehicles?.reduce(
    (acc, curr) => acc + (curr?.trafficTotal || 0),
    0,
  );
};

/**
 * Получение ключа фильтра по типу транспорта
 * @param typeVehicle - тип транспорта
 * @returns получение ключа фильтра по типу транспорта
 */
export const getFilterKey = (typeVehicle: string) => {
  switch (typeVehicle) {
    case VehicleTypeCode.nightTrain:
      return 'nightTrain';
    case VehicleTypeCode.dayTrain:
      return 'dayTrain';
    case VehicleTypeCode.suburbanTrain:
      return 'suburbanTrain';
    case VehicleTypeCode.bus:
      return 'bus';
    case VehicleTypeCode.auto:
    case 'Личный автомобиль':
      return 'auto';
    case VehicleTypeCode.avia:
      return 'avia';
  }

  return 'railwayRoot';
};

/**
 * Возвращение булева значения сравнения типа выбранного объекта и типа транспорта
 * @param layout - общий тип выбранного объекта на карте
 * @param vehicle - вид транспорта
 * @returns возвращение булевского значения сравнения видов транспорта
 */
export const checkingLayout = (
  layout: EMapFeatureLayout | null,
  vehicle: string,
) => {
  const key =
    vehicle as keyof PassengerTrafficInspectorType['passengerTraffic'];

  switch (layout) {
    case null:
      return true;

    case 'railway':
      return (
        key === 'nightTrain' ||
        key === 'dayTrain' ||
        key === 'suburbanTrain' ||
        key === 'expressTrain'
      );

    case 'highway':
      return key === 'auto' || key === 'bus';

    case 'airline':
      return key === 'avia';
    case 'multimodal':
      return key === 'multimodal';
  }
};

/**
 * Фильтрация промежуточных корреспонденций для таблицы в инспекторе
 * @param data - данные в исходном формате
 * @param filter - стор фильтров для карты
 * @param inspector - стор инспектора
 * @returns массив данных для таблицы суммы пассажиропотока
 */
export const filterIngintermediateCorrespondences = (
  data: Maybe<Maybe<IPassengerTrafficType>[]>,
  filter: FilterMapData,
  inspector: InspectorType,
) => {
  return (
    data?.reduce((acc: intermediateCorrespondenceTableData[], curr) => {
      if (!curr || !curr.trafficTotal) return acc;

      const indexCorrespondence = acc.findIndex(
        item =>
          curr.direction.regionFrom?.name === item.regionFrom &&
          curr.direction.regionTo?.name === item.regionTo,
      );

      if (indexCorrespondence > -1) {
        filter[getFilterKey(curr.vehicle.code)] &&
          checkingLayout(inspector.layout, getFilterKey(curr.vehicle.code)) &&
          (acc[indexCorrespondence].trafficTotal += curr.trafficTotal || 0);
      } else {
        acc.push({
          regionFrom: curr.direction.regionFrom?.name || '',
          regionTo: curr.direction.regionTo?.name || '',
          trafficTotal:
            (filter[getFilterKey(curr.vehicle.code)] &&
              checkingLayout(
                inspector.layout,
                getFilterKey(curr.vehicle.code),
              ) &&
              curr.trafficTotal) ||
            0,
        });
      }
      return acc;
    }, []) || []
  );
};
