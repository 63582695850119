import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  Grid,
  InputAdornment,
  Slider,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from '@mui/material';

import {
  InspectorCircleIcon,
  InspectorCircleIconProps,
} from '@components/InspectorCircleIcon/InspectorCircleIcon';

type TimeBlockType = {
  icon: InspectorCircleIconProps;
  name: string;
  value: number | string;
  baseValue: number;
  disabled: boolean;
  getValue: (value: number) => void;
};

const PostfixMuiStyle: SxProps<Theme> = {
  '& .MuiTypography-root': {
    fontSize: theme => theme.typography.pxToRem(12),
    color: theme => theme.palette.border.stroke,
  },
};

export const TravelTimeBlock = (props: TimeBlockType) => {
  const { icon, name, value = 0, baseValue, disabled, getValue } = props;
  const prevProps = useRef(props);

  /**
   * Добавление нуля в начало числовой строки
   */
  const addingZero = useCallback((value: number | string) => {
    return `0${value}`;
  }, []);

  /**
   * Конвертирование десятичного числа в минуты
   */
  const convertedNumberToMinutes = useCallback(
    (value: string | number) => {
      let minutes: string | number = value.toString().split('.')[1];

      if (minutes === undefined) {
        minutes = 0;
      } else if (minutes.toString().length === 1) {
        minutes = +minutes * 10;
      }

      minutes = Math.round(+minutes * 0.6);

      if (minutes.toString().length === 1) {
        minutes = addingZero(minutes);
      }

      return typeof minutes === 'string'
        ? minutes.substring(0, 2)
        : minutes.toString().substring(0, 2);
    },
    [addingZero],
  );

  /**
   * Функция пересчета часа с обновлением приходящего значения времени в компонент
   * @param hour string | number - час(число) в правильном формате или десятичном
   * @param isHours boolean - переменная hour в правильном формате или десятичном
   * @return [number, number] - 1-ый элемент час в правильном формате, 2-ой элемент время в десятичном формате
   */
  const updateHour = useCallback(
    (hour: number | string, isHours: boolean) => {
      hour = +hour;

      let minutes = value.toString().split('.')[1];

      if (minutes === undefined) {
        minutes = '00';
      }

      if (isHours) {
        if (isNaN(+hour))
          return [
            +value.toString().split('.')[0],
            +`${Math.trunc(hour)}.${minutes}`,
          ];
      }

      return [Math.trunc(hour), +`${Math.trunc(hour)}.${minutes}`];
    },
    [value],
  );

  /**
   * Функция пересчета минут с обновлением приходящего значения времени в компонент
   * @param minutes string | number - минуты(число) в правильном формате или десятичном
   * @param isHours boolean - переменная minutes в правильном формате или десятичном
   * @return [number, number] - 1-ый элемент минуты в правильном формате, 2-ой элемент время в десятичном формате
   */
  const updateMinute = useCallback(
    (minutes: number | string, isMinutes: boolean) => {
      if (isMinutes) {
        if (isNaN(+minutes))
          return [+value.toString().split('.')[1] ?? 0, +value];

        minutes = +minutes;

        if (minutes >= 60) {
          minutes = 59;
        }

        let convertedMinutesDecimal: number | string = Math.trunc(
          (minutes * 5) / 3,
        );

        if (convertedMinutesDecimal.toString().length === 1) {
          convertedMinutesDecimal = addingZero(convertedMinutesDecimal);
        }

        return [
          minutes,
          +`${value.toString().split('.')[0]}.${convertedMinutesDecimal}`,
        ];
      }

      const convertedMinutes = convertedNumberToMinutes(minutes);
      return [
        +convertedMinutes,
        +`${value.toString().split('.')[0]}.${minutes}`,
      ];
    },
    [addingZero, convertedNumberToMinutes, value],
  );

  const [hour, setHour] = useState<number | string>(
    updateHour(value, false)[0],
  );
  const [minute, setMinute] = useState<number | string>(
    updateMinute(value, false)[0],
  );

  /**
   * Добавление цифр в поле минут, при смене фокуса
   */
  const onCheckingLengthMinutes = useCallback(() => {
    if (minute.toString().length === 1) {
      let minutes: number | string = updateMinute(minute, true)[0];
      if (minutes.toString().length === 1) {
        minutes = addingZero(minutes);
      }
      setMinute(minutes);
    }
  }, [addingZero, minute, updateMinute]);

  /**
   * Обновление значений в полях часа и минут
   */
  useEffect(() => {
    if (+prevProps.current.value !== +value) {
      prevProps.current = props;
    }

    if (+value.toString().split('.')[0] !== +hour) {
      setHour(updateHour(value, false)[0]);
    }

    if (+convertedNumberToMinutes(value) !== +minute) {
      setMinute(updateMinute(value, false)[0]);
    }
  }, [
    convertedNumberToMinutes,
    hour,
    minute,
    props,
    updateHour,
    updateMinute,
    value,
  ]);

  /**
   * Верстка тултипа для слайдера
   */
  const valueLabelFormat = () => {
    const percent =
      baseValue === 0 || isNaN(baseValue)
        ? 0
        : ((+value - baseValue) / baseValue) * 100;
    const percentString = Math.round(percent);

    const hours = +baseValue.toString().split('.')[0];
    const minutes: number | string = convertedNumberToMinutes(+baseValue);

    return (
      <>
        <Typography>
          Базовое значение {`${Math.trunc(hours)}:${minutes}`}
        </Typography>
        <Typography
          color={'customs.trainSpeedLocal'}
          textAlign={'center'}
        >
          {percent > 0 ? '+' : ''}
          {percentString}%
        </Typography>
      </>
    );
  };

  /**
   * Функция изменения значения слайдера с обновлением приходящего значения времени в компонент
   */
  const handlerChangeTimeSlider = useCallback(
    (_event: Event, newValue: number | number[]) => {
      getValue(newValue as number);
    },
    [getValue],
  );

  /**
   * Нижняя граница значения для слайдера
   */
  const minValue = useMemo(() => {
    if (isNaN(baseValue) || baseValue === 0) return 0;

    return +(baseValue / 2).toFixed(2);
  }, [baseValue]);

  /**
   * Верхняя граница значения для слайдера
   */
  const maxValue = useMemo(() => {
    if (isNaN(baseValue) || baseValue === 0) return 1;

    return +(baseValue * 1.5).toFixed(2);
  }, [baseValue]);

  /**
   * Функция обработчик изменения значения часов в поле
   */
  const onChangeHours = useCallback<NonNullable<TextFieldProps['onChange']>>(
    e => {
      const hour: number | string = (e.target as HTMLInputElement).value;
      if (/^\d{0,3}?$/.test(hour) || hour === '') {
        const times = updateHour(+hour, true);

        setHour(times[0]);
        getValue(times[1]);
      }
    },
    [getValue, updateHour],
  );

  /**
   * Функция обработчик изменения значения минут в поле
   */
  const onChangeMinutes = useCallback<NonNullable<TextFieldProps['onChange']>>(
    e => {
      const minute: number | string = (e.target as HTMLInputElement).value;
      if (/^\d{0,3}?$/.test(minute) || minute === '') {
        const times = updateMinute(+minute, true);

        setMinute(times[0]);
        getValue(times[1]);
      }
    },
    [getValue, updateMinute],
  );

  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      rowGap={1}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          columnGap: 1,
        }}
      >
        <InspectorCircleIcon
          borderColor={icon.borderColor}
          backgroundColor={icon.backgroundColor}
        />

        <Typography>{name}</Typography>
      </Box>

      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          columnGap: 1.5,
          mt: 1,
        }}
      >
        <TextField
          value={hour}
          disabled={disabled}
          sx={{
            width: theme => theme.typography.pxToRem(220),
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                sx={PostfixMuiStyle}
                position="end"
              >
                ч.
              </InputAdornment>
            ),
          }}
          onChange={onChangeHours}
        />

        <TextField
          value={minute}
          disabled={disabled}
          sx={{
            width: theme => theme.typography.pxToRem(220),
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                sx={PostfixMuiStyle}
                position="end"
              >
                мин.
              </InputAdornment>
            ),
          }}
          onChange={onChangeMinutes}
          onBlur={onCheckingLengthMinutes}
        />

        <Slider
          color="secondary"
          marks={[{ value: baseValue }]}
          min={minValue}
          max={maxValue}
          step={0.06}
          value={+value}
          disabled={disabled}
          onChange={handlerChangeTimeSlider}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
        />
      </Box>
    </Grid>
  );
};
