import { createEvent, createStore } from 'effector';

interface PlanSettingsDataType {
  scale: number;
  shouldCenterStagePosition: boolean;
  positionX: number;
  positionY: number;
  width: number;
  height: number;
  opacity: number;
  isHeatmapOn: boolean;
}

export const $PlanSettings = createStore<PlanSettingsDataType>({
  scale: 1,
  shouldCenterStagePosition: false,
  positionX: 0,
  positionY: 0,
  width: 0,
  height: 0,
  opacity: 1,
  isHeatmapOn: false,
});

export const setScaleFn = createEvent<number>();

export const incrementScaleFn = createEvent<void>();

export const decrementScaleFn = createEvent<void>();

export const setShouldCenterStagePositionAndResetScaleFn =
  createEvent<boolean>();

export const setPositionFn = createEvent<{ x: number; y: number }>();

export const setOpacityFn = createEvent<number>();

export const setModelBlockSizeFn = createEvent<{
  width: number;
  height: number;
}>();

export const resetPlanSettingFn = createEvent<void>();

export const toggleHeatmapFn = createEvent<void>();
