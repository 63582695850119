import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

export const Table = ({ data, header, fullHeightContainer }: TableProps) => {
  return (
    <TableContainer
      sx={{
        height: fullHeightContainer ? '100%' : 'auto',
      }}
    >
      <MuiTable>
        {header?.length ? (
          <TableHead>
            <TableRow>
              {header.map((head, i) => (
                <TableCell
                  sx={{
                    lineHeight: 1.25,
                    fontSize: theme => theme.typography.pxToRem(12),
                    boxShadow: theme =>
                      'inset 0 0 1px 0px ' + theme.palette.border.stroke,
                    backgroundColor: theme => theme.palette.background.default,
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                  }}
                  key={i}
                >
                  {head}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        ) : null}

        <TableBody
          sx={{
            border: theme => `1px solid ${theme.palette.border.divider}`,
            borderRadius: '8px',
          }}
        >
          {data.map((row, i) => (
            <TableRow
              hover
              key={i}
              sx={{
                '&:last-child td, &:last-child th': {
                  borderBottom: 0,
                },
              }}
            >
              {row.map((cell, j) => (
                <TableCell
                  key={j}
                  component={j === 0 ? 'th' : undefined}
                  scope={j === 0 ? 'row' : undefined}
                  align={j === 0 ? 'left' : 'right'}
                  sx={{
                    minWidth: '18ch',
                    fontSize: '12px',
                    lineHeight: '12px',
                    fontWeight: '400',
                    paddingY: '10px',
                    paddingLeft: '14px',
                    borderLeft: theme =>
                      ` ${
                        j === 0
                          ? 'none'
                          : '1px solid ' + theme.palette.border.divider
                      }`,
                  }}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export interface TableProps {
  data: RowData[];
  header?: RowData;
  fullHeightContainer?: boolean;
}

type RowData = (string | JSX.Element)[];
