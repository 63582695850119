import { Fill, Stroke, Style, Text } from 'ol/style';

import { TileStyleProps } from '@utils/map/styles/types';

export const areaBordersPassengerFlowTileStyle = (props: TileStyleProps) => {
  const { isActive, feature } = props;

  const name = feature?.get('name');
  const isSource = feature?.get('is_source') as boolean;

  if (isActive)
    return new Style({
      stroke: new Stroke({
        color: 'rgb(114,114,114)',
        width: 2,
      }),
      fill: new Fill({
        color: isSource ? 'rgba(218,182,105,0.9)' : 'rgba(208,190,119,0.70)',
      }),
    });

  return new Style({
    stroke: new Stroke({
      color: 'rgba(114,114,114,0.82)',
      width: isSource ? 1 : 0.3,
    }),
    fill: new Fill({
      color: isSource ? 'rgba(218,182,105,0.7)' : 'rgba(208,190,119,0.55)',
    }),
    // Если имя существует, создайте стиль текста
    text: name
      ? new Text({
          font: '12px Calibri,sans-serif',
          text: name,
          fill: new Fill({
            color: '#000',
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 3,
          }),
          // Здесь можно настроить дополнительные параметры текста
        })
      : undefined,
  });
};
