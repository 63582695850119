import { sample } from 'effector';

import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';
import {
  LayersVisibleApi,
  LayersVisibleStore,
} from '@widgets/widget-soceco-indicators/stores/map/layersVisible/store';
import { SocEcoTableApi } from '@widgets/widget-soceco-indicators/stores/tables/store';

/*
  walking_area - 1.1 Территории, где предполагается размещение общественно-деловой инфраструктуры в зоне пешеходной доступности
  traffic_congestion – 2.5 Дороги с загруженностью более 75%
  plan_protection_vibrations –  2.5 План мероприятий по обеспечению защиты от шума и вибраций
  animal_passage – 3.1. Зверопроходы
  animal_protection_vibration – 3.2 План мероприятий по обеспечению защиты от шума и вибраций
  culverts - 3.4. Водопропускные сооружения
  safe_crossing – 4.2 Мероприятия по созданию безопасных пересечений
  changing_routes – 5.2 Мероприятия по созданию новых и оптимизации существующих маршрутов общественного транспорта
  station_accessibility – 5.3 Мероприятия по обеспечению высокой транспортной и пешеходной доступности
 */

sample({
  clock: SocEcoTableApi.setTable,
  fn: payload => {
    const allLayers: LayersVisibleStore = {
      [SOCECO_TABLE_IDS.TABLE_1_1]: false,
      [SOCECO_TABLE_IDS.TABLE_2_2]: false,
      [SOCECO_TABLE_IDS.TABLE_2_5]: false,
      [SOCECO_TABLE_IDS.TABLE_3_1]: false,
      [SOCECO_TABLE_IDS.TABLE_3_2]: false,
      [SOCECO_TABLE_IDS.TABLE_3_4]: false,
      [SOCECO_TABLE_IDS.TABLE_4_2]: false,
      [SOCECO_TABLE_IDS.TABLE_5_2]: false,
      [SOCECO_TABLE_IDS.TABLE_5_3]: false,
    };
    allLayers[payload] = true;

    return allLayers;
  },
  target: LayersVisibleApi.setVisible,
});
