import { IRoom, IWall } from '@features/tpu-simulation-model/types';
import { getPolygonLines } from '@features/tpu-simulation-model/utils/prepare-plan-before-upload/get-polygon-lines';
import { getWallLines } from '@features/tpu-simulation-model/utils/prepare-plan-before-upload/get-wall-lines';

import { isPointInsideOfPolygon } from '@utils/simulationModel/isPointInsideOfPolygon';
import { lineIntersection } from '@utils/simulationModel/line-intersection';

export const checkIntersectionWallRoom = (
  wall: IWall,
  room: IRoom,
): boolean => {
  const roomLines = getPolygonLines(room.points.map(({ x, y }) => [x, y]));
  const wallLines = getWallLines(wall);

  const isIntersect = roomLines.some(roomLine => {
    const isRoomLineIntersectWall = wallLines.some(wallLine =>
      lineIntersection(wallLine, roomLine),
    );
    return isRoomLineIntersectWall;
  });

  const isWallInsideRoom = wall.points.some(point =>
    isPointInsideOfPolygon(
      [point.x, point.y],
      room.points.map(({ x, y }) => [x, y]),
    ),
  );

  return isIntersect || isWallInsideRoom;
};
