import { forward } from 'effector';

import {
  PassengerTrafficApi,
  setFiltersPassTraffic,
} from '@features/pt-forecast/stores';

forward({
  from: PassengerTrafficApi.setPassengerTraffic,
  to: setFiltersPassTraffic,
});
