import { SvgIcon, SvgIconProps } from '@mui/material';

export const VendingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 10.429c-1.764 0-3.293 1.717-3.293 3.929 0 2.211 1.528 3.928 3.293 3.928 1.765 0 3.294-1.717 3.294-3.928 0-2.212-1.529-3.929-3.294-3.929Zm0 1.571c1.005 0 1.723 1.098 1.723 2.358s-.718 2.357-1.723 2.357c-1.005 0-1.722-1.098-1.722-2.357 0-1.26.717-2.358 1.722-2.358Z"
        fill="#7E67DD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.757 16.89a.785.785 0 0 0 .9 1.283c.276-.167.547-.517.675-1.057.181-.757.12-2.018-.345-3.273-.436-1.178-.038-2.028-.038-2.028a.786.786 0 0 0-1.399-.717s-.744 1.379-.037 3.29c.294.795.392 1.589.327 2.152a2.14 2.14 0 0 1-.083.35ZM18.592 1.786A.786.786 0 0 0 17.807 1H6.693a.786.786 0 0 0-.785.786v2.357c0 .434.352.786.785.786h11.114a.786.786 0 0 0 .785-.786V1.786Zm-1.571.785v.786H7.479v-.786h9.542Z"
        fill="#7E67DD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 4.143a.786.786 0 0 0-.786-.786H4.786A.786.786 0 0 0 4 4.143V6.5c0 .434.352.786.786.786h14.928A.786.786 0 0 0 20.5 6.5V4.143Zm-1.571.786v.785H5.57V4.93H18.93Z"
        fill="#7E67DD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.713 6.54a.788.788 0 0 0-.784-.826H5.57a.785.785 0 0 0-.784.825l.785 15.714a.786.786 0 0 0 .785.747h11.786a.786.786 0 0 0 .785-.747l.785-15.714Zm-1.61.746-.707 14.142H7.104L6.397 7.286h11.706Z"
        fill="#7E67DD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.649 8.071H5.797a.786.786 0 0 0 0 1.572h12.852a.786.786 0 0 0 0-1.572ZM6.357 20.642h11.786a.786.786 0 0 0 0-1.57H6.357a.786.786 0 0 0 0 1.57Z"
        fill="#7E67DD"
      />
    </SvgIcon>
  );
};
