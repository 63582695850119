import { SvgIcon, SvgIconProps } from '@mui/material';

export const CartIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="white"
      />
      <path
        d="M21.3263 5.23373C21.3263 5.23369 5.87564 5.23369 5.87564 5.23369L5.44706 3.30796C5.27451 2.5399 4.60662 2 3.81629 2H1.55658C1.25046 2 1 2.25046 1 2.55658C1 2.86266 1.25046 3.11316 1.55658 3.11316H3.81629C4.0779 3.11316 4.3005 3.29124 4.35619 3.54727L5.78104 9.94793H19.4005C19.7066 9.94793 19.9571 10.1929 19.9571 10.5045C19.9571 10.8106 19.7066 11.0611 19.4005 11.0611H6.02593L6.73277 14.2336C6.90528 15.0017 7.57321 15.5416 8.36353 15.5416H8.84575L7.71151 17.0167C4.58638 17.4463 4.81317 21.9882 7.99625 22.0702C9.20464 22.0702 10.2188 21.2229 10.4734 20.0919H17.6691C17.9238 21.223 18.9379 22.0702 20.1463 22.0702C21.5433 22.0702 22.6788 20.9348 22.6788 19.5378C22.6318 16.5128 18.3599 16.0798 17.6703 18.9788C17.6703 18.9787 10.4723 18.9787 10.4723 18.9787C10.288 18.1727 9.72041 17.5109 8.97493 17.1999L10.25 15.5416H19.8123C20.5916 15.5416 21.2762 14.9905 21.4431 14.228L22.9571 7.2541C23.2025 6.24795 22.3609 5.20368 21.3263 5.23373Z"
        fill="#7E67DD"
      />
    </SvgIcon>
  );
};
