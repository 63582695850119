import { Grid, Typography } from '@mui/material';

import { CircleIcon, CircleIconProps } from '@components/Map/CircleIcon';

type LineItemProps = Pick<CircleIconProps, 'color'> & {
  label: string;
};

export const CircleItem = (props: LineItemProps) => {
  const { label, color } = props;

  return (
    <Grid
      display={'grid'}
      container
      gridTemplateColumns={'2fr 10fr'}
      gap={2}
      alignItems={'center'}
    >
      <CircleIcon color={color} />
      <Typography
        variant="body2"
        color="text.secondary"
      >
        {label}
      </Typography>
    </Grid>
  );
};
