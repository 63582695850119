import { IQuery } from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

import {
  ActionBeforeOpenErrorsWindow,
  SaveSimulationModelPlanArgs,
} from '@features/tpu-simulation-model/types';

import { PlanError } from '@utils/simulationModel/errors-analyzer/types';

interface ErrorsModalDataType {
  isOpen: boolean;
  actionBeforeModalOpen: ActionBeforeOpenErrorsWindow;
  numberOfErrors: null | number;
  isSaveResolved: boolean;
  isSaveLoading: boolean;
  isSaveRejected: boolean;
}

export const errorsModalDefaultState = {
  isOpen: false,
  actionBeforeModalOpen: ActionBeforeOpenErrorsWindow.Save,
  numberOfErrors: null,
  isSaveRejected: false,
  isSaveLoading: false,
  isSaveResolved: false,
};

export const $ErrorsModal = createStore<ErrorsModalDataType>(
  errorsModalDefaultState,
);

export const $Errors = createStore<PlanError[] | null>(null);

export const triggerFindErrorsFn = createEvent<{
  saveAfterAnalysis: boolean;
} | void>();

export const setPlanErrorsFn = createEvent<{
  errors: PlanError[];
  saveAfterAnalysis: boolean;
}>();

export const openPlanErrorsWindowFn =
  createEvent<ActionBeforeOpenErrorsWindow>();

export const closePlanErrorsWindowFn = createEvent<void>();

export const savePlanAfterCheckingErrorsFx = createEffect<
  SaveSimulationModelPlanArgs & { closeWindowAfterSave?: boolean },
  boolean
>();

export const triggerSavePlanAfterCheckingErrorsFn = createEvent<void>();

export const resetErrorsModalFn = createEvent<void>();

export const setNumberOfErrorsFn = createEvent<{
  numberOfErrors: number | null | undefined;
}>();

export const resetErrorsFn = createEvent<void>();

export const getAgentsShareInfoFx = createEffect<
  { id: string; saveAfterAnalysis: boolean },
  IQuery['allAgentShareInfo']
>();
