import { Button, DialogActions } from '@mui/material';

interface ActionsProps {
  isCloseButtonHidden: boolean;
  isActionButtonHidden: boolean;
  isActionsHidden: boolean;
  isPlanSaved: boolean;
  actionButtonConfig: {
    onClick: () => void;
    label: string;
  };
  onClose: () => void;
}

export const Actions = (props: ActionsProps) => {
  const {
    isCloseButtonHidden,
    isActionButtonHidden,
    isActionsHidden,
    isPlanSaved,
    actionButtonConfig,
    onClose,
  } = props;
  if (isActionsHidden || (isCloseButtonHidden && isActionButtonHidden))
    return null;

  return (
    <DialogActions>
      {!isCloseButtonHidden && (
        <Button
          onClick={onClose}
          fullWidth
          sx={{
            maxWidth: isActionButtonHidden ? '272px' : 'unset',
            margin: '0 auto',
          }}
        >
          Свернуть и связать объекты
        </Button>
      )}
      {!isActionButtonHidden && (
        <Button
          variant={isPlanSaved ? 'contained' : 'outlined'}
          onClick={actionButtonConfig.onClick}
          fullWidth
          sx={{
            maxWidth: isPlanSaved ? '272px' : 'unset',
            margin: '0 auto',
          }}
        >
          {actionButtonConfig.label}
        </Button>
      )}
    </DialogActions>
  );
};
