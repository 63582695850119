import { SvgIcon, SvgIconProps } from '@mui/material';

export const ErrorMiniIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8.66667 8C8.66667 8.36819 8.36819 8.66667 8 8.66667C7.63181 8.66667 7.33333 8.36819 7.33333 8V5.33333C7.33333 4.96514 7.63181 4.66667 8 4.66667C8.36819 4.66667 8.66667 4.96514 8.66667 5.33333V8ZM7.33333 10.6667C7.33333 10.2985 7.63181 10 8 10C8.36819 10 8.66667 10.2985 8.66667 10.6667C8.66667 11.0349 8.36819 11.3333 8 11.3333C7.63181 11.3333 7.33333 11.0349 7.33333 10.6667ZM10.4867 2H5.51333L2 5.51333V10.4867L5.51333 14H10.4867L14 10.4867V5.51333L10.4867 2Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
