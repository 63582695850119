import { Chip, Grid, Typography } from '@mui/material';

export const Running = () => (
  <Grid
    display={'flex'}
    gap={1.5}
    alignItems="center"
  >
    <Chip
      label="Расчет..."
      color="info"
    />

    <Typography variant="body2">Расчет запущен</Typography>
  </Grid>
);
