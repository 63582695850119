import { apiClient } from '@api/client';
import { IQuery as IQueryAg } from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';
import { EMapFeatureLayout } from '@constants/map';

import { EMapViewMode } from '@features/ag-forecast/constants/EMapViewMode';
import { agAvailableLayouts } from '@features/ag-forecast/constants/availableLayouts';
import {
  GetObjectType,
  InfrastructureInspectorApi,
  InfrastructureInspectorStore,
  getDataForInspectorFn,
  getGraphsForInspectorFx,
  getRailwayStationForInspectorFx,
} from '@features/ag-forecast/stores/inspector/infrastructure/store';
import {
  $SelectedFeatures,
  AgLayersStore,
} from '@features/ag-forecast/stores/map';
import { $UI } from '@features/ag-forecast/stores/ui';

// Запросить данные по выделенной фиче на слое инфраструктуры
sample({
  clock: $SelectedFeatures,
  source: { UI: $UI, SelectedFeatures: $SelectedFeatures },
  filter: ({ UI, SelectedFeatures }) =>
    !!SelectedFeatures.length && UI.viewMode === EMapViewMode.infrastructure,
  fn: ({ SelectedFeatures }) => {
    return {
      layout: SelectedFeatures[0]!.layout as keyof AgLayersStore,
      id: SelectedFeatures[0]!.id,
    } as GetObjectType;
  },
  target: getDataForInspectorFn,
});

// Запросить данные для графа
sample({
  clock: getDataForInspectorFn,
  filter: payload =>
    Boolean(payload.layout && agAvailableLayouts.includes(payload.layout)),
  target: getGraphsForInspectorFx,
});

// Запросить данные для жд станции
sample({
  clock: getDataForInspectorFn,
  filter: payload =>
    Boolean(payload.layout === EMapFeatureLayout.agRailwayStations),
  target: getRailwayStationForInspectorFx,
});

// триггерим событие на отображение данных жд станции для инспектора на слое инфраструктуры
sample({
  clock: getRailwayStationForInspectorFx.done,
  fn: request =>
    ({
      id: request.result?.id || null,
      name: request.result?.name || '',
      year: request.result?.year || null,
      isTpu: request.result?.isTpu || null,
      capacity: request.result?.capacity || null,
    }) as InfrastructureInspectorStore['railwayStation'],
  target: InfrastructureInspectorApi.setRailwayStationForInspector,
});

sample({
  clock: getGraphsForInspectorFx.done,
  fn: request => {
    return {
      transport: request.result?.transport || [],
      laneNum: request.result?.laneNum || 1,
      freeSpeed: request.result?.freeSpeed || 0,
      category: request.result?.category || '',
      capacity: request.result?.capacity || 0,
      oneway: request.result?.oneway || false,
    } as InfrastructureInspectorStore['graphs'];
  },
  target: InfrastructureInspectorApi.setGraphsForInspector,
});

getGraphsForInspectorFx.use(async params => {
  const response = await apiClient.query<
    IQueryAg,
    Omit<GetObjectType, 'layout'>
  >({
    query: gql`
      query QueryGetGraphsById($id: String!) {
        getGraphInstanceById(id: $id) {
          category
          transport
          laneNum
          oneway
          freeSpeed
          capacity
        }
      }
    `,
    variables: {
      id: params.id,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data.getGraphInstanceById;
});

getRailwayStationForInspectorFx.use(async params => {
  const response = await apiClient.query<
    IQueryAg,
    Omit<GetObjectType, 'layout'>
  >({
    query: gql`
      query QueryGetRailwayStationById($id: String!) {
        getRailwayStationInstanceById(id: $id) {
          id
          name
          isTpu
          year
          capacity
        }
      }
    `,
    variables: {
      id: params.id,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data.getRailwayStationInstanceById;
});
