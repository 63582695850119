import {
  Calculation,
  ErrorInfo,
  Pending,
  Stop,
  Success,
} from '@features/pt-constructor/components/ModalCalculation/states';
import { Queue } from '@features/pt-constructor/components/ModalCalculation/states/Queue';
import { useUnit } from 'effector-react';
import { useEffect, useMemo } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { DialogCloseButton } from '@components/DialogCloseButton';

import {
  $ModalCalculation,
  EStateModalCalculation,
  resetModalCalculation,
} from './store';

interface ModalCalculationProps {
  onClose: () => void;
}

export const ModalCalculation = (props: ModalCalculationProps) => {
  const { onClose } = props;
  const $modalCalculation = useUnit($ModalCalculation);
  const handlers = useUnit({
    resetModalCalculation,
  });

  const dialogTitle =
    $modalCalculation.status !== EStateModalCalculation.stop
      ? 'Прогресс расчета'
      : 'Рассчитать';

  const DescriptionComponent = useMemo(() => {
    switch ($modalCalculation.status) {
      case EStateModalCalculation.pending:
        return Pending;
      case EStateModalCalculation.calculation:
        return Calculation;
      case EStateModalCalculation.stop:
        return Stop;
      case EStateModalCalculation.success:
        return Success;
      case EStateModalCalculation.error:
        return ErrorInfo;
      case EStateModalCalculation.queue:
        return Queue;
    }
  }, [$modalCalculation.status]);

  // const onClickInitCancellation = useCallback(() => {
  //   handlers.setStatusProcessCalculation({
  //     status: EStateModalCalculation.stop,
  //   });
  // }, [handlers]);
  //
  // const onClickAbortCancellation = useCallback(() => {
  //   handlers.setStatusProcessCalculation({
  //     status: EStateModalCalculation.calculation,
  //   });
  // }, [handlers]);
  //
  // const onClickAbortCalculation = useCallback(() => {
  //   handlers.setStatusProcessCalculation({
  //     status: EStateModalCalculation.error,
  //     error: 'Пользователь отменил расчет',
  //   });
  // }, [handlers]);

  useEffect(() => {
    return handlers.resetModalCalculation;
  }, [handlers.resetModalCalculation]);

  const ActionsButtons = useMemo(() => {
    switch ($modalCalculation.status) {
      // case EStateModalCalculation.calculation:
      // return <Button onClick={onClickInitCancellation}>Отменить</Button>;
      case EStateModalCalculation.error:
      case EStateModalCalculation.success:
        return <Button onClick={onClose}>Закрыть</Button>;
      // case EStateModalCalculation.stop:
      //   return (
      //     <>
      //       <Button onClick={onClickAbortCalculation}>
      //         Не отменять расчет
      //       </Button>
      //       <Button
      //         variant={'outlined'}
      //         onClick={onClickAbortCancellation}
      //       >
      //         Отменить расчет
      //       </Button>
      //     </>
      //   );
      default:
        return null;
    }
  }, [$modalCalculation.status, onClose]);

  return (
    <Dialog open={true}>
      <DialogTitle
        variant="subtitle2"
        sx={{
          position: 'relative',
          fontSize: theme => theme.typography.pxToRem(16),
          pr: 10,
        }}
      >
        {dialogTitle}
      </DialogTitle>

      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <DescriptionComponent
          errorMessage={$modalCalculation.calculationError}
        />
      </DialogContent>

      <DialogActions>{ActionsButtons}</DialogActions>
    </Dialog>
  );
};
