import { createApi, createEffect, createStore } from 'effector';

import { ECalculationScenarioStatusType } from '@features/pt-constructor/constants/calculationScenarioStatusType';
import { PollingStatusesResponse } from '@features/pt-constructor/store/api/queryGetUserScenarioStatuses';

export const $CalculationStatuses = createStore<CalculationStatusesState>({
  statuses: [],
  timer: null,
});

export const CalculationStatusesApi = createApi($CalculationStatuses, {
  setCalculationScenarioStatuses: (
    store,
    payload: UserCalculationsStatuses[],
  ) => ({
    ...store,
    statuses: payload,
  }),
  updateCalculationScenarioStatus: (
    store,
    payload: UserCalculationsStatuses,
  ) => ({
    ...store,
    statuses: [...store.statuses.filter(e => e.id !== payload.id), payload],
  }),
  setTimer: (store, payload: CalculationStatusesState['timer']) => {
    if (store.timer) clearInterval(store.timer);

    return {
      ...store,
      timer: payload,
    };
  },
});

export const fetchLongStatusesFx = createEffect<
  void,
  PollingStatusesResponse
>();

export type UserCalculationsStatuses = {
  id: string;
  status: ECalculationScenarioStatusType;
};

export type CalculationStatusesState = {
  statuses: UserCalculationsStatuses[];
  timer: NodeJS.Timeout | null;
};
