import { createEvent, sample } from 'effector';

import { FilterMapApi } from '@features/ag-project/stores/filters';
import { SearchOptionsApi } from '@features/ag-project/stores/lists/searchOptions';
import { VehiclesApi } from '@features/ag-project/stores/lists/vehicles';

export const storesReset = createEvent();

sample({
  clock: storesReset,
  target: [FilterMapApi.reset, SearchOptionsApi.reset, VehiclesApi.reset],
});
