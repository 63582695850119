import { gql } from '@apollo/client';

export const GET_ALL_HUBS_QUERY = gql`
  query QueryAllHubs($id: UUID, $search: String) {
    allHubs(agglomerationId: $id, search: $search) {
      id
      name
      code
      creationType
      agglomeration {
        id
        name
      }
    }
  }
`;

export const GET_ALL_AGGLOMERATIONS_QUERY = gql`
  query QueryAllAgglomerations {
    allAgglomerations {
      id
      name
    }
  }
`;

export const UPLOAD_FLOOR_FILE = gql`
  mutation UploadFloorFileMutation($file: Upload!, $projectId: UUID!) {
    uploadFloorFile(file: $file, projectId: $projectId) {
      fileInfo {
        id
        order
      }
    }
  }
`;

export const UPLOAD_DATA_FILE = gql`
  mutation UploadDataFileMutation(
    $dataType: DataFileTypeEnum!
    $file: Upload!
    $projectId: UUID!
  ) {
    uploadDataFile(dataType: $dataType, file: $file, projectId: $projectId) {
      fileInfo {
        id
        state
        errors
        dataType
      }
    }
  }
`;
