import { useUnit } from 'effector-react';

import {
  $ListOfFloorsWithListOfObjects,
  $ListOfObjectsSortedByType,
} from '@features/tpu-simulation-model/store';
import {
  EditableObjectType,
  IRoom,
  isElevator,
  isLadder,
} from '@features/tpu-simulation-model/types';
import { Option } from '@features/tpu-simulation-model/utils/getAttributeOptions';

interface InfoListOfObjects {
  roomOptions: Option[] | [];
  elevatorOptions: (id: string) => (Option & { room: string })[] | [];
  ladderOptions: (id: string) => (Option & { room: string })[] | [];
}

export const useListOfRoomsElevatorsLadders = (): InfoListOfObjects => {
  const $listOfObjectsSortedByType = useUnit($ListOfObjectsSortedByType);
  const $listOfFloorsWithListOfObjects = useUnit(
    $ListOfFloorsWithListOfObjects,
  );

  const allRooms = $listOfObjectsSortedByType?.[EditableObjectType.Room] as
    | IRoom[]
    | undefined;
  const roomOptions =
    allRooms?.map(item => ({ value: item.uuid, label: item.name })) ?? [];

  const elevatorOptions = (id: string) => {
    const objectsOnSelectedFloor =
      $listOfFloorsWithListOfObjects[id] &&
      Object.values($listOfFloorsWithListOfObjects[id]);
    const elevatorsOnSelectedFloor = objectsOnSelectedFloor?.filter(isElevator);

    return (
      elevatorsOnSelectedFloor?.map(item => ({
        value: item.uuid,
        label: item.name,
        room: item.room,
      })) ?? []
    );
  };

  const ladderOptions = (id: string) => {
    const objectsOnSelectedFloor =
      $listOfFloorsWithListOfObjects[id] &&
      Object.values($listOfFloorsWithListOfObjects[id]);
    const laddersOnSelectedFloor = objectsOnSelectedFloor?.filter(isLadder);

    return (
      laddersOnSelectedFloor?.map(item => ({
        value: item.uuid,
        label: item.name,
        room: item.room,
      })) ?? []
    );
  };

  return {
    roomOptions,
    elevatorOptions,
    ladderOptions,
  };
};
