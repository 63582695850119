import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

import { ChevronRightIcon } from '@components/icons/ChevronRight';

import { CashedField } from '@features/pt-forecast-new/components/Inspector/CashedField';
import {
  $EditorMap,
  EditorMapApi,
  deleteStationFx,
} from '@features/pt-forecast-new/stores/map/editor';
import { ModalsApi } from '@features/pt-forecast-new/stores/modals';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';

export const InspectorCustomRailwayStation = () => {
  const { selectedStation, stations, graphs } = useUnit($EditorMap);
  const { updateSelectedStationParams } = useUnit(EditorMapApi);
  const { editMode } = useUnit($UI);
  const { setVisibleInspector } = useUnit(UIApi);
  const { setVisibleCannotDeleteCustomRailwayStation } = useUnit(ModalsApi);

  const selectedStationItem = useMemo(() => {
    return stations.find(station => station.id === selectedStation);
  }, [selectedStation, stations]);

  const changeNameHandler = useCallback(
    (value: string) => {
      updateSelectedStationParams({ name: value });
    },
    [updateSelectedStationParams],
  );

  const isLastStation = useMemo(() => {
    return !!graphs.find(
      graph =>
        graph.endStationId === selectedStation ||
        graph.startStationId === selectedStation,
    );
  }, [graphs, selectedStation]);

  const deleteHandler = useCallback(() => {
    if (isLastStation) {
      setVisibleCannotDeleteCustomRailwayStation(true);
    } else {
      deleteStationFx({ id: selectedStation });
    }
  }, [
    isLastStation,
    selectedStation,
    setVisibleCannotDeleteCustomRailwayStation,
  ]);

  const onClose = useCallback(() => {
    setVisibleInspector(false);
  }, [setVisibleInspector]);

  if (!selectedStation || !selectedStationItem) return null;
  return (
    <Stack
      height="100%"
      direction="column"
      justifyContent="space-between"
    >
      <Stack>
        <Stack
          alignItems={'center'}
          flexDirection={'row'}
          mb={3}
          columnGap={2}
        >
          <IconButton
            sx={{
              width: '32px',
              height: '32px',
              backgroundColor: 'primary.main',
              borderRadius: 1,
              color: 'white',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            }}
            onClick={onClose}
          >
            <ChevronRightIcon />
          </IconButton>

          <Typography variant="subtitle2">Железнодорожная станция</Typography>
        </Stack>

        <Stack rowGap={3}>
          <CashedField
            id="tpuName"
            title="Наименование"
            placeholder="Введите название станции"
            value={selectedStationItem.name}
            onBlur={value => changeNameHandler(value as string)}
            disabled={!editMode}
          />

          <CashedField
            id="transportArea"
            title="Транспортный район"
            placeholder="Название ТР заполнится автоматически"
            value={selectedStationItem.transportArea}
            disabled
          />
        </Stack>
      </Stack>

      <Box
        pb={2}
        pt={2.25}
        px={5.5}
        mt={'auto'}
        style={{
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Button
          variant={'text'}
          fullWidth
          sx={{
            color: theme => theme.palette.secondary.main,
          }}
          onClick={deleteHandler}
        >
          Удалить ЖД станцию
        </Button>
      </Box>
    </Stack>
  );
};
