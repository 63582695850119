import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const PathOuterDottedLineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <svg>
        <g clipPath="url(#a)">
          <rect
            width="24"
            height="24"
            rx="2"
            fill="#fff"
          />
          <path
            stroke="#3679EA"
            strokeDasharray="4 4"
            d="M.5.5h23v23H.5z"
          />
          <path
            d="M14.404 5.965H8.79V8.21h5.614V5.965ZM5 6.246V7.93c0 .155.126.28.28.28h1.264V5.965H5.28a.28.28 0 0 0-.281.28ZM14.404 10.879H8.79v2.245h5.614V10.88ZM5 11.16v1.684c0 .155.126.28.28.28h1.264V10.88H5.28a.28.28 0 0 0-.281.28ZM16.649 8.21h1.263a.28.28 0 0 0 .28-.28V6.246a.28.28 0 0 0-.28-.281H16.65V8.21ZM14.404 15.79H8.79v2.245h5.614v-2.246ZM5 16.07v1.684c0 .155.126.28.28.28h1.264V15.79H5.28a.28.28 0 0 0-.281.28ZM18.193 17.754V16.07a.28.28 0 0 0-.28-.28h-1.264v2.245h1.263a.28.28 0 0 0 .28-.281ZM16.088 4h-1.123v16h1.123V4ZM18.193 12.844V11.16a.28.28 0 0 0-.28-.281h-1.264v2.245h1.263a.28.28 0 0 0 .28-.28ZM8.228 13.403V4H7.105v16h1.123V13.403Z"
            fill="#273B48"
          />
        </g>
        <defs>
          <clipPath id="a">
            <rect
              width="24"
              height="24"
              rx="2"
              fill="#fff"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
