import { useUnit } from 'effector-react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  SxProps,
} from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

import { CloseIcon } from '@components/icons';

import { ROUTES } from '@constants/routes';

import { Content } from '@features/tpu-calculation-scenarios/components/ModalPlanErrors/Content';
import { useContextCSHandlers } from '@features/tpu-calculation-scenarios/hooks';
import { $Form } from '@features/tpu-calculation-scenarios/stores/form.store';
import { $ModalPlanErrors } from '@features/tpu-calculation-scenarios/stores/modalPlanErrors.store';
import { $Errors } from '@features/tpu-calculation-scenarios/stores/modalPlanErrors.store';

const dialogSx: SxProps<Theme> = {
  '& .MuiPaper-root': {
    width: '640px',
    minWidth: '640px',
    maxHeight: 'calc(100vh - 64px)',
  },
  '& .MuiDialogTitle-root': {
    padding: '24px',
  },
  '& .MuiDialogActions-root': {
    gap: '24px',
    padding: '24px',
    '&:not(:first-of-type)': {
      marginLeft: 'unset',
      display: 'flex',
    },
  },
  '& .MuiDialogContent-root': {
    padding: '0 24px 24px',
    justifyContent: 'center',
    display: 'grid',
    gridTemplateRows: '52px 24px 1fr',
    gridTemplateColumns: '1fr',
  },
};

export const ModalPlanErrors = () => {
  const navigate = useNavigate();

  const $errors = useUnit($Errors);
  const $form = useUnit($Form);
  const $modalPlanErrors = useUnit($ModalPlanErrors);
  const { setIsOpenModalPlanErrors } = useContextCSHandlers();

  const withErrors = !!$errors?.length;
  const textButtonGoTo = withErrors
    ? 'Перейти в редактор'
    : 'Перейти к исполнению модели';
  const routeButtonGoTo = withErrors
    ? `${ROUTES.TPU_EDITOR}/${$form.id}`
    : `${ROUTES.TPU_SCENARIOS}/${$form.id}${ROUTES.TPU_SCENARIOS_VIEWER}`;

  const onClose = () => {
    setIsOpenModalPlanErrors(false);
  };

  const goTo = () => {
    navigate(routeButtonGoTo);
  };

  return (
    <Dialog
      open={$modalPlanErrors.isOpen}
      onClose={onClose}
      sx={dialogSx}
    >
      <DialogTitle>
        Результат проверки возможности запустить сценарий
      </DialogTitle>
      <IconButton
        onClick={onClose}
        color={'primary'}
        sx={{ position: 'absolute', top: 24, right: 24, padding: 0 }}
      >
        <CloseIcon />
      </IconButton>
      <Content
        errors={$errors}
        isLoading={$modalPlanErrors.isLoading}
      />
      <DialogActions>
        <Button
          fullWidth
          onClick={goTo}
        >
          {textButtonGoTo}
        </Button>
        <Button
          variant={'outlined'}
          onClick={onClose}
          fullWidth
        >
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};
