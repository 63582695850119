import { createApi, createStore } from 'effector';

const initState: FeatureSettingsStore = {
  projectUUID: null,
};

export const $FeatureSettings = createStore<FeatureSettingsStore>(initState);

export const FeatureSettingsApi = createApi($FeatureSettings, {
  setProjectUUID: (store, projectUUID: string) => ({ ...store, projectUUID }),
  reset: () => initState,
});

type FeatureSettingsStore = {
  projectUUID: string | null;
};
