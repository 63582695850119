import { createEvent, createStore } from 'effector';

import { checkingNameCalculation } from './utils';

import {
  SocEcoScenarioListItem,
  TransportScenarioListItem,
} from '../lists/scenarios';
import { SocEcoSelectedScenario, TransportSelectedScenario } from '../selected';

type ErrorsCreateCalculationType = {
  isErrorName: boolean;
  isErrorSocioEconomicScenario: boolean;
  isErrorTransportationScenario: boolean;
  isCheckErrors: boolean;
};

const initState: ErrorsCreateCalculationType = {
  isErrorName: false,
  isErrorSocioEconomicScenario: false,
  isErrorTransportationScenario: false,
  isCheckErrors: false,
};

export const $ErrorsCreateCalculation =
  createStore<ErrorsCreateCalculationType>(initState);

// проверка валидности имени нового расчета
export const checkErrorName = createEvent<string>();

// проверка выбран ли сценарий соц эко
export const checkErrorSocioEconomicScenario =
  createEvent<SocEcoScenarioListItem | null>();

// проверка выбран ли транспортный сценарий
export const checkErrorTransportationScenario =
  createEvent<TransportScenarioListItem | null>();

// установка динамической проверки ошибок значений при сохранении расчета
export const setErrorCheckingSaveCalculation = createEvent<{
  name: string;
  socioEconomicScenario: SocEcoSelectedScenario | null;
  transportationScenario: TransportSelectedScenario | null;
}>();

// установка динамической проверки ошибок значений при пересчете расчета
export const setErrorCheckingReCalculation = createEvent<{
  name: string;
  socioEconomicScenario: SocEcoSelectedScenario | null;
  transportationScenario: TransportSelectedScenario | null;
}>();

export const resetErrorsCreateCalculation = createEvent();

$ErrorsCreateCalculation
  .on(checkErrorName, (state, payload) => ({
    ...state,
    isErrorName: checkingNameCalculation(payload),
  }))
  .on(checkErrorSocioEconomicScenario, (state, payload) => ({
    ...state,
    isErrorSocioEconomicScenario: !payload,
  }))
  .on(checkErrorTransportationScenario, (state, payload) => ({
    ...state,
    isErrorTransportationScenario: !payload,
  }))
  .on(setErrorCheckingSaveCalculation, (state, payload) => ({
    ...state,
    isErrorName: checkingNameCalculation(payload.name.trim()),
    isErrorSocioEconomicScenario: !payload.socioEconomicScenario,
    isErrorTransportationScenario: !payload.transportationScenario,
    isCheckErrors: true,
  }))
  .on(setErrorCheckingReCalculation, (state, payload) => ({
    ...state,
    isErrorName: checkingNameCalculation(payload.name.trim()),
    isErrorSocioEconomicScenario: !payload.socioEconomicScenario,
    isErrorTransportationScenario: !payload.transportationScenario,
    isCheckErrors: true,
  }))
  .on(resetErrorsCreateCalculation, () => initState);
