import { createEvent, createStore } from 'effector';

import { IPoint } from '@features/tpu-simulation-model/types';

interface EditorDataType {
  scale: number;
  positionX: number;
  positionY: number;
  width: number;
  height: number;
  shouldCenterStagePosition: boolean;
  opacity: number;
}

export const $Editor = createStore<EditorDataType>({
  scale: 1,
  positionX: 0,
  positionY: 0,
  width: 0,
  height: 0,
  shouldCenterStagePosition: false,
  opacity: 1,
});

export const resetEditorStateFn = createEvent();

export const setScaleFn = createEvent<number>();

export const incrementScaleFn = createEvent<void>();

export const decrementScaleFn = createEvent<void>();

export const setPositionFn = createEvent<IPoint>();

export const setOpacityFn = createEvent<number>();

export const setModelBlockSizeFn = createEvent<{
  width: number;
  height: number;
}>();

export const setShouldCenterStagePositionAndResetScaleFn =
  createEvent<boolean>();
