import { Stroke, Style } from 'ol/style';

export const changingRoutesVectorStyle = () => {
  return new Style({
    stroke: new Stroke({
      color: '#FF0000',
      width: 2,
    }),
  });
};
