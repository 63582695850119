import { useUnit } from 'effector-react';
import { ChangeEvent, useCallback } from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import {
  $FilterMap,
  EFilterMapLayout,
  FilterMapApi,
} from '@features/pt-forecast-new/stores/filters';
import { $UI } from '@features/pt-forecast-new/stores/ui';

export const LayoutChanger = () => {
  const { layout } = useUnit($FilterMap);
  const { setLayout } = useUnit(FilterMapApi);
  const { editMode } = useUnit($UI);

  const handleChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, value: string) => {
      setLayout(value as EFilterMapLayout);
    },
    [setLayout],
  );

  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      name="radio-buttons-group"
      value={layout}
      onChange={handleChange}
      sx={{
        pt: '16px',
        pl: '16px',
        '& .MuiFormControlLabel-root:has(.Mui-checked) ': {
          bgcolor: 'background.default',
        },
        '& .MuiFormControlLabel-root': {
          mr: 1,
          borderRadius: theme => theme.shape.borderRadius * 0.25,
        },
        paddingBottom: theme => theme.spacing(3.125),
      }}
    >
      <FormControlLabel
        value={EFilterMapLayout.Infrastructure}
        control={<Radio />}
        label="Инфраструктура"
        disabled={editMode}
      />
      <FormControlLabel
        value={EFilterMapLayout.PassengerFlows}
        control={<Radio />}
        label="Пассажиропотоки"
        disabled={editMode}
      />
    </RadioGroup>
  );
};
