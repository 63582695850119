import { IAgentShareInfoCategoryEnum } from '@api/gql/tpu-types';

import {
  IPassengerShareForm,
  IShareForm,
  IVisitorsShareForm,
} from '@features/tpu-passenger-types/types/forms';

export const isPassengerFormData = (
  data: IShareForm,
): data is IPassengerShareForm =>
  data.category !== IAgentShareInfoCategoryEnum.Visitors;

export const isVisitorsFormData = (
  data: IShareForm,
): data is IVisitorsShareForm =>
  data.category === IAgentShareInfoCategoryEnum.Visitors;
