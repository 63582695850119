import {
  IHubScenarioSimulationStateChoices,
  IScenarioType,
  ISimulationType,
} from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

export interface IPlayer {
  play: boolean;
  speedDivider: number;
  playSpeed: number;
  uploadSpeed: number;
  withAnimation: boolean;
  endOfSimulation: number;
  uploadedTimeOfSimulation: number;
  playDisabled: boolean;
  currentTimeOfSimulation: number;
  lastCalculatedSegment: number;
  isLoading: boolean;
}

export interface ISimulationData {
  simulationId?: string | null;
  state?: IHubScenarioSimulationStateChoices | null;
  calculatedTicks: number;
  ticks?: number | null;
}

const playerInit = {
  play: false,
  speedDivider: 1,
  playSpeed: 1000,
  uploadSpeed: 1500,
  withAnimation: true,
  endOfSimulation: 79200,
  uploadedTimeOfSimulation: 0,
  playDisabled: true,
  currentTimeOfSimulation: 0,
  lastCalculatedSegment: 0,
  isLoading: false,
};

export const $Player = createStore<IPlayer>(playerInit);

export const $SimulationData = createStore<ISimulationData>({
  calculatedTicks: 0,
});

export const setPlayFn = createEvent<boolean>();
export const setSpeedDividerFn = createEvent<number>();
export const setWithAnimationFn = createEvent<boolean>();
export const setCurrentTimeOfSimulationFn = createEvent<number>();
export const setSimulationIdFn = createEvent<string>();
export const setPlaySpeedFn = createEvent<number>();
export const setPlayDisabledFn = createEvent<boolean>();
export const setLastCalculatedSegmentFn = createEvent<number>();
export const setUploadedTimeOfSimulationFn = createEvent<number>();
export const resetScenarioPlayerFn = createEvent<void>();
export const playTickFn = createEvent<void>();
export const uploadTickFn = createEvent<void>();

export const getSimulationIdByScenarioIdFx = createEffect<
  string,
  IScenarioType | null
>();

export const getSimulationDataBySimulationIdFx = createEffect<
  { id: string; offset: number; limit: number },
  ISimulationType | null
>();

export const startSimulationByScenarioIdFx = createEffect<
  string,
  IScenarioType | null | undefined
>();

export const createSimulationReportFx = createEffect<string, boolean>();
