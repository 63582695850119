import { IQueryGetComputingRegistryInstanceByIdArgs } from '@api/gql/types';
import { sample } from 'effector';

import { setWatchModalCalculation } from '@features/pt-constructor/components/ModalCalculation/store';
import { ECalculationScenarioStatusType } from '@features/pt-constructor/constants/calculationScenarioStatusType';
import { queryGetTransportProjectsForScenario } from '@features/pt-constructor/store/api/queryGetTransportProjectsForScenario';
import {
  CalculationsApi,
  TransportProject,
} from '@features/pt-constructor/store/lists/scenarios/store';
import { CalculationStatusesApi } from '@features/pt-constructor/store/lists/statuses';

import {
  $SelectedCalculationScenario,
  SelectedCalculationScenarioApi,
  copyUserScenarioAndRunFx,
  copyUserScenarioFx,
  createCopyCalculationScenario,
  createOrUpdateCalculationScenario,
  createUserScenarioAndRunFx,
  createUserScenarioFx,
  deleteCalculationFx,
  deleteUserScenario,
  editUserScenarioFx,
  editUserScenarioFxAndRunFx,
  getTransportProjectsForScenarioFx,
  onlyRunCalculationScenario,
  runCalculationScenarioFx,
} from '.';
import { queryCreateUserScenario } from '../api/queryCreateUserScenario';
import { queryDeleteUserScenario } from '../api/queryDeleteUserScenario';
import { queryEditUserScenario } from '../api/queryEditUserScenario';
import { queryRunCalculateUserScenario } from '../api/queryRunCalculateUserScenario';
import { createScenarioReformatter } from '../api/reformatters/createScenarioReformatter';
import { editScenarioReformatter } from '../api/reformatters/editScenarioReformatter';
import { getScenarioReformatter } from '../api/reformatters/getScenarioReformatter';

// Прервать расчет
// cancelCalculation.watch(() => {
// });

// Только обновить сценарий без запуска расчета
sample({
  clock: createOrUpdateCalculationScenario,
  source: $SelectedCalculationScenario,
  filter: (store, payload) => !!store?.id && !payload.run,
  fn: store => editScenarioReformatter(store),
  target: editUserScenarioFx,
});

// Обновить и запустить сценарий
sample({
  clock: createOrUpdateCalculationScenario,
  source: $SelectedCalculationScenario,
  filter: (store, payload) => !!store?.id && payload.run,
  fn: store => editScenarioReformatter(store),
  target: editUserScenarioFxAndRunFx,
});

// Только создать сценарий без запуска расчета
sample({
  clock: createOrUpdateCalculationScenario,
  source: $SelectedCalculationScenario,
  filter: (store, payload) => !store?.id && !payload.run,
  fn: store => createScenarioReformatter(store),
  target: createUserScenarioFx,
});

// Создать сценарий и запустить расчет
sample({
  clock: createOrUpdateCalculationScenario,
  source: $SelectedCalculationScenario,
  filter: (store, payload) => !store?.id && payload.run,
  fn: store => createScenarioReformatter(store),
  target: createUserScenarioAndRunFx,
});

// Создать копию текущего сценария без запуска расчета
sample({
  clock: createCopyCalculationScenario,
  source: $SelectedCalculationScenario,
  filter: (store, payload) => !!store?.id && !payload.run,
  fn: store => createScenarioReformatter(store),
  target: copyUserScenarioFx,
});

// Создать копию текущего сценария и запустить расчет
sample({
  clock: createCopyCalculationScenario,
  source: $SelectedCalculationScenario,
  filter: (store, payload) => !!(store?.id && payload.run),
  fn: store => createScenarioReformatter(store),
  target: copyUserScenarioAndRunFx,
});

// После создания/изменения сценария, актуализировать запись в реестре
sample({
  clock: [
    createUserScenarioFx.done,
    createUserScenarioAndRunFx.done,
    editUserScenarioFx.done,
    editUserScenarioFxAndRunFx.done,
    copyUserScenarioFx.done,
    copyUserScenarioAndRunFx.done,
  ],
  filter: request => !!request.result,
  fn: request => getScenarioReformatter(request.result!),
  target: CalculationsApi.pushCalculationScenarioInRegistry,
});

// После создания/изменения сценария, установить статус черновик
sample({
  clock: [
    createUserScenarioFx.done,
    createUserScenarioAndRunFx.done,
    editUserScenarioFx.done,
    editUserScenarioFxAndRunFx.done,
    copyUserScenarioFx.done,
    copyUserScenarioAndRunFx.done,
  ],
  filter: request => !!request.result,
  fn: request => {
    return {
      id: request.result?.id,
      status: ECalculationScenarioStatusType.Draft,
    };
  },
  target: CalculationStatusesApi.updateCalculationScenarioStatus,
});

// после успешного запуска расчета, установить статус расчета - в процессе
sample({
  clock: [
    createUserScenarioAndRunFx.done,
    editUserScenarioFxAndRunFx.done,
    copyUserScenarioAndRunFx.done,
    runCalculationScenarioFx.done,
  ],
  filter: request => !!request.result,
  fn: request => {
    return {
      id: request.result?.id,
      status: ECalculationScenarioStatusType.Pending,
    };
  },
  target: CalculationStatusesApi.updateCalculationScenarioStatus,
});

// после успешного запуска расчета, передать модалке id за которым следить
sample({
  clock: [
    createUserScenarioAndRunFx.done,
    editUserScenarioFxAndRunFx.done,
    copyUserScenarioAndRunFx.done,
    runCalculationScenarioFx.done,
  ],
  filter: request => !!request.result,
  fn: request => request.result?.id,
  target: setWatchModalCalculation,
});

// После обновления/создания сценария расчета выключить режим редактирования
sample({
  clock: [
    createUserScenarioFx,
    createUserScenarioAndRunFx,
    editUserScenarioFx,
    editUserScenarioFxAndRunFx,
    copyUserScenarioFx,
    copyUserScenarioAndRunFx,
  ],
  fn: () => true,
  target: CalculationsApi.setReadonly,
});

// Отправить запрос на удаление сценария
sample({
  clock: deleteUserScenario,
  fn: id => ({ id }),
  target: deleteCalculationFx,
});

// Удалить сценарий расчета локально после завершения запроса
sample({
  clock: deleteCalculationFx.done,
  fn: data => {
    return data.params.id;
  },
  target: CalculationsApi.removeCalculationScenarioInRegistry,
});

sample({
  clock: onlyRunCalculationScenario,
  source: $SelectedCalculationScenario,
  filter: store => !!store?.id,
  fn: store => ({
    id: store?.id || '',
  }),
  target: runCalculationScenarioFx,
});

createUserScenarioAndRunFx.use(async args => {
  const response = await queryCreateUserScenario(args);
  await queryRunCalculateUserScenario({ id: response?.id });
  return response;
});

// Создание расчета
createUserScenarioFx.use(queryCreateUserScenario);

editUserScenarioFxAndRunFx.use(async args => {
  const response = await queryEditUserScenario(args);
  await queryRunCalculateUserScenario({ id: args?.id });
  return response;
});

// Редактирование расчета
editUserScenarioFx.use(queryEditUserScenario);

copyUserScenarioAndRunFx.use(async args => {
  const response = await queryCreateUserScenario({
    ...args,
    name: `${args.name} (копия)`,
  });
  await queryRunCalculateUserScenario({ id: response?.id });
  return response;
});

copyUserScenarioFx.use(
  async args =>
    await queryCreateUserScenario({ ...args, name: `${args.name} (копия)` }),
);

// Запрос постановки сценария в очередь на расчет
runCalculationScenarioFx.use(async args => {
  await queryRunCalculateUserScenario({ id: args?.id });
  return args;
});

// Удалить расчет
deleteCalculationFx.use(queryDeleteUserScenario);

// При выборе сценария, запросить его транспортные проекты
sample({
  clock: SelectedCalculationScenarioApi.selectCalculationScenario,
  fn: payload => {
    return {
      id: payload.id,
    } as IQueryGetComputingRegistryInstanceByIdArgs;
  },
  target: getTransportProjectsForScenarioFx,
});

getTransportProjectsForScenarioFx.use(queryGetTransportProjectsForScenario);

// Заполнить список транспортных проектов для сценария
sample({
  clock: getTransportProjectsForScenarioFx.done,
  fn: response => {
    return response.result?.transportScenario?.transportProjects?.map(
      transportProject => ({
        id: transportProject?.id || '',
        name: transportProject?.transportProject?.name || '',
        year: transportProject?.year || '',
      }),
    ) as TransportProject[];
  },
  target: SelectedCalculationScenarioApi.setTransportProjectsForScenario,
});
