/**
 * Конвертирование значения и умножение на 100
 * @param value значение
 * @returns новое конвертированное значение
 */
export const shorteningValueAndMultiplyingBy100 = (
  value: number | string | undefined,
) => {
  if (value === undefined || typeof +value !== 'number') return 0;

  const newValue = +(+value * 100).toFixed(2);

  return Object.is(newValue, -0) ? 0 : newValue;
};

/**
 * Деление значения на 100 и преобразование дробной части до 4 цифр
 * @param value значение
 * @returns новое конвертированное значение
 */
export const divisionBy100AndFractionalPartUpTo4Digits = (
  value: number | string | undefined,
) => {
  if (value === undefined || typeof +value !== 'number') return 0;

  let newValue = +value / 100;

  if (
    newValue.toString().split('.')[1] &&
    newValue.toString().split('.')[1].length > 4
  ) {
    newValue = +newValue.toFixed(4);
  }

  return Object.is(newValue, -0) ? 0 : newValue;
};
