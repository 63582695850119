import { sample } from 'effector';

import { queryGetYearsTimeline } from '@features/pt-forecast/stores/api/queryGetYearsTimeline';
import { resetAllFilters } from '@features/pt-forecast/stores/filters/store';
import {
  TimelineApi,
  TimelineType,
  getYearsTimelineFx,
} from '@features/pt-forecast/stores/timeline/store';

getYearsTimelineFx.use(queryGetYearsTimeline);

// Триггер сеттера годов сценария
sample({
  clock: getYearsTimelineFx.done,
  fn: request => request.result as Omit<TimelineType, 'selectedYear'>,
  target: TimelineApi.setYearsTimeline,
});

// Сброс параметров таймлайна
sample({
  clock: resetAllFilters,
  fn: () => 0,
  target: TimelineApi.selectYear,
});
