// import {
//   TableProjectRow,
// } from '@features/ag-projects-registry/components/TableProjects/TableProjectRow';
// import { ProjectType } from '@features/ag-projects-registry/store/lists/projects';
import { useUnit } from 'effector-react';
import { useMemo, useState } from 'react';

import { TableComparisonTPURow } from './TableComparisonTPURow';
import { TPUObjectType } from './configs/bodyContent';

import { TableBody } from '@mui/material';

import { $SecondPageDashboard } from '@features/ag-forecast/stores/dashboard/secondPage/store';
import { $YearLineStore } from '@features/ag-forecast/stores/yearLine';

export const TableComparisonTPUBody = () => {
  const { scenarioTpusFlow } = useUnit($SecondPageDashboard);
  const { selectedYear } = useUnit($YearLineStore);

  const data: TPUObjectType[] | undefined = useMemo(() => {
    const currentData = scenarioTpusFlow?.find(
      item => item.year === selectedYear,
    )?.scenarioTpus;

    if (!currentData) return;

    return currentData.map(item => {
      const alt =
        item?.alternative?.map(item => ({
          variant: item?.alternateTpu?.name || '-',
          ttcPopulationWeightedAverage: item?.stz?.stzAvgPopulation || 0,
          ttcWeightedAveragePassTraffic: item?.stz?.stzAvgPassTraffic || 0,
          passTraffic: item?.flow || 0,
        })) || [];

      return {
        name: item?.tpu?.name || '-',
        children: [
          {
            variant: item?.tpu?.name || '-',
            ttcPopulationWeightedAverage: item?.mainStz?.stzAvgPopulation || 0,
            ttcWeightedAveragePassTraffic:
              item?.mainStz?.stzAvgPassTraffic || 0,
            passTraffic: item?.mainFlow || 0,
          },
          ...alt,
        ],
      };
    });
  }, [scenarioTpusFlow, selectedYear]);

  const [maxTTC] = useState(() => {
    let maxTTC = 0;

    data?.forEach(item => {
      item.children.forEach(row => {
        const value =
          (row.ttcPopulationWeightedAverage || 0) >
          (row.ttcWeightedAveragePassTraffic || 0)
            ? row.ttcPopulationWeightedAverage
            : row.ttcWeightedAveragePassTraffic;

        if (value && value > maxTTC) {
          maxTTC = value;
        }
      });
    });

    return maxTTC;
  });

  const [maxPassTraffic] = useState(() => {
    let maxPassTraffic = 0;

    data?.forEach(item => {
      item.children.forEach(row => {
        if (row.passTraffic && row.passTraffic > maxPassTraffic) {
          maxPassTraffic = row.passTraffic;
        }
      });
    });

    return maxPassTraffic;
  });

  return (
    data && (
      <TableBody
        sx={{
          overflowY: 'auto',
        }}
      >
        {data.length
          ? data?.map((item: TPUObjectType, i) => (
              <TableComparisonTPURow
                item={item}
                maxTTC={maxTTC}
                maxPassTraffic={maxPassTraffic}
                key={i}
              />
            ))
          : null}
      </TableBody>
    )
  );
};
