import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const ShareOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={20}
      height={20}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M7.15813 4.84342H10.0756C10.4244 4.84342 10.7072 5.12622 10.7072 5.47505C10.7072 5.79482 10.4696 6.05908 10.1612 6.10091L10.0756 6.10668H7.15813C6.15522 6.10668 5.3343 6.88581 5.26762 7.87182L5.26325 8.00155V16.0021C5.26325 17.005 6.04239 17.826 7.02839 17.8927L7.15813 17.897H15.1593C16.1623 17.897 16.9831 17.1179 17.0498 16.1319L17.0542 16.0021V15.583C17.0542 15.234 17.337 14.9513 17.6858 14.9513C18.0056 14.9513 18.2699 15.1889 18.3117 15.4972L18.3175 15.583V16.0021C18.3175 17.6901 16.9932 19.0687 15.3271 19.1559L15.1593 19.1602H7.15813C5.4702 19.1602 4.09157 17.8361 4.00438 16.1699L4 16.0021V8.00155C4 6.31363 5.32419 4.93499 6.9904 4.8478L7.15813 4.84342ZM13.6883 6.96557V4.63288C13.6883 4.1074 14.2835 3.82436 14.6879 4.11899L14.7571 4.17705L19.8056 9.01951C20.0412 9.24551 20.0627 9.6085 19.8699 9.85943L19.8056 9.93109L14.7572 14.7752C14.378 15.139 13.7616 14.9054 13.6943 14.4095L13.6883 14.3194V12.0242L13.399 12.0496C11.3781 12.2604 9.44105 13.1707 7.5758 14.7956C7.13862 15.1764 6.46225 14.8163 6.53416 14.241C7.09401 9.76109 9.43682 7.29186 13.4362 6.98211L13.6883 6.96557ZM14.9516 6.11395V7.58047C14.9516 7.9293 14.6688 8.21209 14.3199 8.21209C11.0579 8.21209 9.03613 9.62367 8.1626 12.5553L8.09602 12.7899L8.39262 12.5905C10.2761 11.3594 12.2547 10.7386 14.3199 10.7386C14.6397 10.7386 14.904 10.9762 14.9458 11.2845L14.9516 11.3702V12.838L18.456 9.47542L14.9516 6.11395Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
