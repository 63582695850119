import './stores';

import {
  ThirdPageDashboardContainer,
  ThirdPageDashboardContainerType,
} from '@widgets/widget-soceco-indicators/containers/ThirdPageContainer';

export const WidgetSocecoIndicators = (
  props: ThirdPageDashboardContainerType,
) => {
  return <ThirdPageDashboardContainer {...props} />;
};
