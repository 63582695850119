import { Feature } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { Geometry } from 'ol/geom';
import VectorTileLayer from 'ol/layer/VectorTile';
import RenderFeature from 'ol/render/Feature';
import { Style } from 'ol/style';

import {
  CustomMap,
  getFeatureStyle,
  getMapLayerFeatures,
  layerIdKey,
  layerLayoutKey,
  mapSelectedFeature,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import {
  EMapFeatureLayout,
  EMapSelectFeaturesModes,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { FEATURES_SELECT_MODE } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/featuresSelectMode';

export type SetFeatureSelectedStyleArgs = {
  map: CustomMap;
  feature: FeatureLike;
  isReselect?: boolean;
  isActive?: boolean;
};

/** Визуальное выделение переданной фичи на карте */
export const setFeatureSelectedStyle = (args: SetFeatureSelectedStyleArgs) => {
  const { map, feature, isReselect = true, isActive } = args;
  if (!feature) return;

  const prevFeature = map.get(mapSelectedFeature);

  if (isReselect && prevFeature && isActive !== false)
    setFeatureSelectedStyle({
      isReselect: false,
      isActive: false,
      map,
      feature: prevFeature,
    });

  const layout = feature.get(layerLayoutKey) as EMapFeatureLayout;
  const width = feature.get('width') as number;
  const active = typeof isActive !== 'undefined' ? isActive : true;

  if (FEATURES_SELECT_MODE[layout] === EMapSelectFeaturesModes.segment)
    if (feature instanceof Feature)
      setGeometryFeaturesStyle({
        features: [feature],
        map,
        layout,
        width,
        active,
      });
    else
      setRenderFeaturesStyle({
        features: [feature],
        map,
        layout,
        width,
        active,
      });
  else {
    const features = getMapLayerFeatures({ map, layout });

    if (feature instanceof Feature)
      setGeometryFeaturesStyle({ features, map, layout, width, active });
    else throw Error('Selecting all features of a tile layer is not supported');
  }

  map.set(mapSelectedFeature, isActive !== false ? feature : undefined);
};

// Установка стилей для обычных фич
export const setGeometryFeaturesStyle = (
  args: setGeometryFeaturesStyleArgs,
) => {
  const { map, features, layout, width, active } = args;

  features.forEach(feature =>
    feature.setStyle(
      (feature, resolution) =>
        getFeatureStyle({
          filterMapLayout: map.filterLayout,
          width,
          layout,
          active,
          feature,
          map,
          resolution,
        }) as Style,
    ),
  );
};

// Установка стилей для рендер-фич
const setRenderFeaturesStyle = (args: setRenderFeaturesStyleArgs) => {
  const { map, features, layout, width, active } = args;

  const activeFeaturesIds = features.map(feature => feature.get(layerIdKey));

  map.getLayers().forEach(layer => {
    if (layer.getClassName() !== layout) return;
    if (layer instanceof VectorTileLayer)
      layer.setStyle(
        (feature_, resolution) =>
          getFeatureStyle({
            active: active
              ? activeFeaturesIds.includes(feature_.get(layerIdKey))
              : false,
            filterMapLayout: map.filterLayout,
            feature: feature_,
            width,
            layout,
            map,
            resolution,
          }) as Style,
      );
    else throw Error('unknown feature type');
  });
};

type setRenderFeaturesStyleArgs = Pick<SetFeatureSelectedStyleArgs, 'map'> & {
  layout: EMapFeatureLayout;
  width: number;
  features: RenderFeature[];
  active: boolean;
};

type setGeometryFeaturesStyleArgs = Pick<SetFeatureSelectedStyleArgs, 'map'> & {
  layout: EMapFeatureLayout;
  width: number;
  features: Feature<Geometry>[];
  active: boolean;
};
