import { createEvent, createStore } from 'effector';

import {
  EFilterMapLayout,
  MapFilterField,
  StartPlannedYear,
} from '@components/MapFilters';

import { EVehicle } from '@features/pt-forecast/constants/vehiclesEnum';

export const $FilterMap = createStore<FilterMapData>({
  layout: EFilterMapLayout.Infrastructure,
  prevLayout: null,
  railwayRoot: true,
  bus: true,
  dayTrain: true,
  nightTrain: true,
  auto: true,
  suburbanTrain: true,
  autoRoot: true,
  avia: true,
  actual: true,
  planned: false,
  plannedYear: StartPlannedYear,
  areaBorder: true,
  expressTrain: true,
  intermediateCorrespondents: false,
  passengerTraffic: {
    auto: 0,
    expressTrain: 0,
    bus: 0,
    nightTrain: 0,
    suburbanTrain: 0,
    dayTrain: 0,
    avia: 0,
    multimodal: 0,
  },
  multimodal: true,
});

// сеттер слоя (инфра паспоток..)
export const setLayoutFn = createEvent<EFilterMapLayout>();
// Сеттер фильтров
export const setFilters = createEvent<Partial<MapFilterField>>();
// Сохранить значения пасстрафика для логики отображения фильтров
export const setFiltersPassTraffic =
  createEvent<FilterMapData['passengerTraffic']>(); // passengerTraffic
// Сброс фильтров в дефолтное состояние
export const resetAllFilters = createEvent();

$FilterMap
  .on(setLayoutFn, (state, payload) => {
    return {
      ...state,
      prevLayout: state.layout,
      layout: payload,
    };
  })
  .on(setFilters, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  })
  .on(setFiltersPassTraffic, (state, payload) => {
    return {
      ...state,
      passengerTraffic: payload,
    };
  })
  .reset(resetAllFilters);

export type FilterMapData = MapFilterField & {
  layout: EFilterMapLayout;
  prevLayout: EFilterMapLayout | null;
  passengerTraffic: Record<EVehicle, number>;
};
