import { IHighwayType, Maybe } from '@api/gql/types';
import { useMemo } from 'react';

import { Box, Divider, IconButton, Typography } from '@mui/material';

import { ChevronRightIcon } from '@components/icons';

import { BoxData } from '@features/passenger-traffic/components/Inspector/BoxData';

import { getHighwayTypeValue } from '@utils/dataForInspector/road';

interface AutoInspectorProps {
  onClose?(): void;
  fields: Maybe<IHighwayType>;
}

export const AutoInspector = (props: AutoInspectorProps) => {
  const { onClose, fields } = props;

  const category = useMemo(
    () => getHighwayTypeValue(fields!.type) || 'н/д',
    [fields],
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 3,
        }}
      >
        <IconButton
          sx={{
            width: '32px',
            height: '32px',
            backgroundColor: 'primary.main',
            borderRadius: 1,
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={onClose}
        >
          <ChevronRightIcon />
        </IconButton>

        <Typography variant="subtitle2">Автодорога</Typography>
      </Box>

      <BoxData
        name={'Название'}
        value={fields?.highwayName || 'н/д'}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Категория'}
        value={category}
      />

      <Divider sx={{ my: 2 }} />

      <BoxData
        name={'Скоростной режим'}
        value={fields?.speed ? 'До ' + fields?.speed + ' км/ч' : 'н/д'}
      />
    </>
  );
};
