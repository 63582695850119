import { createEvent, createStore } from 'effector';

interface ModalResetRulerDataType {
  isOpen: boolean;
}

export const $ModalResetRuler = createStore<ModalResetRulerDataType>({
  isOpen: false,
});

export const setIsOpenModalResetRulerFn = createEvent<boolean>();

export const resetModalResetRulerFn = createEvent<void>();
