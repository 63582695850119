import { useMemo } from 'react';

import { Divider, Typography } from '@mui/material';

import { Accordion } from '@components/Accordion';
import { RoadIcon } from '@components/icons';

import { TrafficFrequencyBlock } from '@features/pt-forecast-new/components/Recalculation/TrafficFrequencyBlock';
import { EVehicle } from '@features/pt-forecast-new/constants/eVehicle';
import { VehicleParameterRecalculate } from '@features/pt-forecast-new/stores/recalculate';

type TrafficFrequencyContainerProps = {
  frequency: Record<
    Exclude<keyof typeof EVehicle, 'auto' | 'multimodal'>,
    number
  >;
  base: Record<Exclude<keyof typeof EVehicle, 'auto' | 'multimodal'>, number>;
  disabled: boolean;
  onChange: (param: VehicleParameterRecalculate) => void;
};

export const TrafficFrequencyContainer = (
  props: TrafficFrequencyContainerProps,
) => {
  const { frequency, base, disabled, onChange } = props;

  const nightTrain = useMemo(
    () => Math.round(frequency.nightTrain),
    [frequency.nightTrain],
  );

  const dayTrain = useMemo(
    () => Math.round(frequency.dayTrain),
    [frequency.dayTrain],
  );

  const suburbanTrain = useMemo(
    () => Math.round(frequency.suburbanTrain),
    [frequency.suburbanTrain],
  );

  const expressTrain = useMemo(
    () => Math.round(frequency.expressTrain),
    [frequency.expressTrain],
  );

  const bus = useMemo(() => Math.round(frequency.bus), [frequency.bus]);

  const avia = useMemo(() => Math.round(frequency.avia), [frequency.avia]);

  return (
    <Accordion
      defaultExpanded={false}
      sx={{
        pl: theme => theme.typography.pxToRem(44),
        pr: theme => theme.typography.pxToRem(24),
      }}
      summaryComponent={
        <>
          <RoadIcon />

          <Typography
            variant={'subtitle2'}
            sx={{ ml: 1 }}
          >
            Частота сообщения
          </Typography>
        </>
      }
      detailsSx={{ padding: '0' }}
    >
      {!!base.nightTrain && (
        <>
          <TrafficFrequencyBlock
            icon={{
              borderColor: 'black',
              backgroundColor: 'trainNight',
            }}
            name={'Ночной поезд дальнего следования'}
            value={nightTrain}
            baseValue={base.nightTrain}
            disabled={disabled}
            getValue={value => onChange({ key: 'nightTrain', value })}
          />

          <Divider sx={{ my: '16px' }} />
        </>
      )}

      {!!base.dayTrain && (
        <>
          <TrafficFrequencyBlock
            icon={{
              borderColor: 'black',
              backgroundColor: 'trainDay',
            }}
            name={'Дневной поезд дальнего следования'}
            value={dayTrain}
            baseValue={base.dayTrain}
            disabled={disabled}
            getValue={value => onChange({ key: 'dayTrain', value })}
          />

          <Divider sx={{ my: '16px' }} />
        </>
      )}

      {!!base.suburbanTrain && (
        <>
          <TrafficFrequencyBlock
            icon={{
              borderColor: 'black',
              backgroundColor: 'trainLocal',
            }}
            name={'Пригородный поезд'}
            value={suburbanTrain}
            baseValue={base.suburbanTrain}
            disabled={disabled}
            getValue={value => onChange({ key: 'suburbanTrain', value })}
          />

          <Divider sx={{ my: '16px' }} />
        </>
      )}

      {!!base.expressTrain && (
        <>
          <TrafficFrequencyBlock
            icon={{
              borderColor: 'black',
              backgroundColor: 'trainHighSpeed',
            }}
            name={'Высокоскоростной поезд'}
            value={expressTrain}
            baseValue={base.expressTrain}
            disabled={disabled}
            getValue={value => onChange({ key: 'expressTrain', value })}
          />

          <Divider sx={{ my: '16px' }} />
        </>
      )}

      {!!base.bus && (
        <>
          <TrafficFrequencyBlock
            icon={{
              borderColor: 'auto',
              backgroundColor: 'auto',
            }}
            name={'Автобус'}
            value={bus}
            baseValue={base.bus}
            disabled={disabled}
            getValue={value => onChange({ key: 'bus', value })}
          />

          <Divider sx={{ my: '16px' }} />
        </>
      )}

      {!!base.avia && (
        <TrafficFrequencyBlock
          icon={{
            borderColor: 'avia',
            backgroundColor: 'avia',
          }}
          name={'Самолет'}
          value={avia}
          baseValue={base.avia}
          disabled={disabled}
          getValue={value => onChange({ key: 'avia', value })}
        />
      )}
    </Accordion>
  );
};
