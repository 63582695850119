import { apiClient } from '@api/client';
import { IMutation, IQuery } from '@api/gql/ag-types';
import { Maybe } from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import { EAgProjectStatusType } from '@features/ag-projects-registry/constants/agProjectStatuses';
import {
  $ListProject,
  DeleteProjectFx,
  FetchProjects,
  FetchProjectsFx,
  FetchProjectsRequestParams,
  ListProjectsApi,
  ProjectType,
} from '@features/ag-projects-registry/store/lists/projects/store';

import { dateReformatter } from '@utils/dateReformatter';

const convertStatus = (status?: Maybe<number>) => {
  switch (status) {
    case 1:
      return EAgProjectStatusType.Queue;
    case 2:
      return EAgProjectStatusType.Calculation;
    case 3:
      return EAgProjectStatusType.Success;
    case 4:
      return EAgProjectStatusType.Failure;
    default:
      return EAgProjectStatusType.Failure;
  }
};

sample({
  clock: [FetchProjects, ListProjectsApi.setContains, DeleteProjectFx.done],
  source: { ListProject: $ListProject },
  fn: ({ ListProject }) => {
    return {
      offset: ListProject.page * ListProject.rowsPerPage,
      limit: (ListProject.page + 1) * ListProject.rowsPerPage,
      contains: ListProject.contains || '',
      orderBy: '-created',
    } as FetchProjectsRequestParams;
  },
  target: FetchProjectsFx,
});

sample({
  clock: FetchProjectsFx.done,
  fn: data => {
    return {
      projects: data.result?.projects?.map(item => ({
        id: item?.id || '',
        name: item?.name || '',
        number: item?.number || 0,
        agglomeration: {
          id: item?.agglomeration?.id || '',
          name: item?.agglomeration?.name || '',
        },
        date: item?.modified ? dateReformatter(item.modified) : '',
        status: convertStatus(item?.status),
        user: item?.owner || '',
      })) as ProjectType[],
      totalCount: data.result?.count || 0,
    };
  },
  target: ListProjectsApi.setProjects,
});

FetchProjectsFx.use(async params => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query FilterProjects(
        $limit: Int!
        $offset: Int!
        $orderBy: String
        $contains: String
      ) {
        filterProjects(
          limit: $limit
          offset: $offset
          orderBy: $orderBy
          contains: $contains
        ) {
          projects {
            id
            name
            number
            agglomeration {
              id
              name
            }
            modified
            status
            owner
          }
          count
        }
      }
    `,
    variables: {
      limit: params.limit,
      offset: params.offset,
      orderBy: params.orderBy,
      contains: params.contains,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  return response.data?.filterProjects || null;
});

// Проект удален успешно
// sample({
//   clock: DeleteProjectFx.done,
//   target:
// });

// Ошибка при удалении проекта
// sample({
//   clock: DeleteProjectFx.fail,
//   target:
// });

// Эффект удаления проекта
DeleteProjectFx.use(async params => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation DeleteProjectMutation($id: String!) {
        deleteProject(id: $id) {
          ok
        }
      }
    `,
    variables: {
      id: params.id,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });
  return response.data?.deleteProject || null;
});
