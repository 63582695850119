import { IAgentShareInfoCategoryEnum } from '@api/gql/tpu-types';
import { useCallback } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from '@mui/material';

import { CloseIcon } from '@components/icons';

import {
  BUYING_TICKETS,
  CATEGORY_TYPES,
  COLOR_TYPES,
  PROBABILITIES,
} from '@features/tpu-passenger-types/constants';
import { IForm } from '@features/tpu-passenger-types/types';

interface PassengerTypesFormProps {
  formData: IForm;
  onClose(): void;
  onSave(payload: IForm): void;
  onSubmit(payload: IForm): void;
}

export const PassengerTypeForm = ({
  formData,
  onClose,
  onSave,
  onSubmit,
}: PassengerTypesFormProps) => {
  const {
    register,
    watch,
    control,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<IForm>({
    values: formData,
    mode: 'onBlur',
  });

  const formValues = watch();

  const ticketOfficeProbability = useWatch({
    control,
    name: 'ticketOfficeProbability',
  });

  const tpmProbability = useWatch({
    control,
    name: 'tpmProbability',
  });

  const category = useWatch({
    control,
    name: 'category',
  });

  const setDefaultProbability = useCallback(
    (category: IAgentShareInfoCategoryEnum) => {
      if (!formValues.id) {
        // На высадку, пригородное сообщение
        if (category === IAgentShareInfoCategoryEnum.DisembarkingCommuter) {
          setValue('sharePassengerWithTickets', 98);
          setValue('ticketOfficeProbability', 80);
          setValue('tpmProbability', 20);
        }

        // На посадку, пригородное сообщение
        if (category === IAgentShareInfoCategoryEnum.EmbarkingCommuter) {
          setValue('averageTimeBeforeTrain', 7);
          setValue('sharePassengerWithTickets', 50);
          setValue('ticketOfficeProbability', 80);
          setValue('tpmProbability', 20);
          setValue('informationTableProbability', 20);
        }

        // На посадку, дальнее сообщение
        if (category === IAgentShareInfoCategoryEnum.EmbarkingLongDistance) {
          setValue('averageTimeBeforeTrain', 20);
          setValue('informationTableProbability', 70);
        }

        // Посетители
        if (category === IAgentShareInfoCategoryEnum.Visitors) {
          setValue('informationTableProbability', 20);
        }
      }
    },
    [formValues.id, setValue],
  );

  const checkSum = useCallback(
    (name: string, value: number) => {
      if (
        name === 'ticketOfficeProbability' &&
        tpmProbability !== 100 - value
      ) {
        setValue('tpmProbability', 100 - value);
      }
      if (
        name === 'tpmProbability' &&
        ticketOfficeProbability !== 100 - value
      ) {
        setValue('ticketOfficeProbability', 100 - value);
      }
    },
    [setValue, ticketOfficeProbability, tpmProbability],
  );

  const colorStyles = (color: string) => ({
    backgroundColor: color,
    width: 32,
    height: 32,
    ml: 0,
    mb: '10px',
    borderRadius: '2px',
    overflow: 'hidden',
    '.MuiSvgIcon-root': {
      display: 'none',
    },
    '.MuiTouchRipple-root': {
      display: 'none',
    },
    '.Mui-checked': {
      display: 'block',
      border: '1px solid black',
      borderRadius: '2px',
      width: '100%',
      height: '100%',
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 9.26672L8.46667 10.7334L11.8 7.40005M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z' stroke='white' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '60%',
        backgroundPosition: 'center',
      },
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '24px',
        width: '100%',
      }}
    >
      <Button
        sx={{
          width: '214px',
        }}
        onClick={handleSubmit(form =>
          !!form.agentShareInfoId && isValid ? onSubmit(form) : onSave(form),
        )}
      >
        Сохранить
      </Button>
      <Box
        sx={{
          boxShadow: '0px 0px 8px 0px #00000026',
          borderRadius: '8px',
          backgroundColor: theme => theme.palette.background.paper,
          padding: '24px',
          overflow: 'auto',
          width: '100%',
        }}
      >
        <Box
          sx={{
            gap: '12px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant={'subtitle1'}
              sx={{
                whiteSpace: 'no-wrap',
                fontSize: theme => theme.typography.pxToRem(14),
                lineHeight: theme => theme.typography.pxToRem(16),
              }}
            >
              Настройки параметров типа пассажиров
            </Typography>

            <Button
              variant="text"
              onClick={onClose}
              sx={{
                padding: '10px',
                minWidth: 'unset',
                width: '44px',
                height: '44px',
              }}
            >
              <CloseIcon />
            </Button>
          </Box>

          <Typography
            variant="body2"
            sx={{
              fontSize: theme => theme.typography.pxToRem(14),
              lineHeight: theme => theme.typography.pxToRem(14),
            }}
          >
            Категория
          </Typography>

          <Controller
            control={control}
            name="category"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disableClearable
                options={CATEGORY_TYPES}
                onChange={(_, values) => {
                  onChange(values.value);
                  setDefaultProbability(values.value);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                  />
                )}
                disabled={!!formValues?.agentShareInfoId?.length}
                value={CATEGORY_TYPES.find(item => item.value === value)}
                sx={{
                  '.MuiInputBase-root': {
                    backgroundColor: theme =>
                      `${theme.palette.background.default} !important`,
                  },
                }}
              />
            )}
          />

          <Typography
            variant="body2"
            sx={{
              fontSize: theme => theme.typography.pxToRem(14),
              lineHeight: theme => theme.typography.pxToRem(14),
            }}
          >
            Название
          </Typography>

          <Box>
            <TextField
              {...register('name', {
                required: {
                  value: true,
                  message: 'Обязательное поле для заполнения',
                },
              })}
              autoFocus
              fullWidth
              sx={{
                '.MuiInputBase-root': {
                  backgroundColor: theme =>
                    `${theme.palette.background.default} !important`,
                  border: theme =>
                    errors?.name
                      ? `1px solid ${theme.palette.secondary.main} !important`
                      : 'none',
                },
              }}
            />
            <Typography
              sx={{
                fontSize: theme => theme.typography.pxToRem(12),
                color: theme => theme.palette.secondary.main,
              }}
            >
              {errors?.name?.message}
            </Typography>
          </Box>

          {[
            IAgentShareInfoCategoryEnum.EmbarkingCommuter,
            IAgentShareInfoCategoryEnum.EmbarkingLongDistance,
          ].includes(category) && (
            <>
              <Typography
                variant="body2"
                sx={{
                  fontSize: theme => theme.typography.pxToRem(14),
                  lineHeight: theme => theme.typography.pxToRem(14),
                }}
              >
                Среднее время прибытия до отправления поезда, мин.
              </Typography>

              <OutlinedInput
                {...register('averageTimeBeforeTrain', {
                  setValueAs: v => parseInt(v),
                  pattern: /^([0-9]+)([.,]?)([0-9]*)$/,
                })}
                type="number"
                sx={{
                  backgroundColor: theme =>
                    `${theme.palette.background.default} !important`,
                }}
              />
            </>
          )}

          <Typography
            variant="body2"
            sx={{
              fontSize: theme => theme.typography.pxToRem(14),
              lineHeight: theme => theme.typography.pxToRem(14),
            }}
          >
            Максимальная скорость перемещения пассажира, м/с.
          </Typography>

          <OutlinedInput
            {...register('maximumTravelSpeed', {
              setValueAs: v => parseInt(v),
              pattern: /^([0-9]+)([.,]?)([0-9]*)$/,
            })}
            type="number"
            sx={{
              backgroundColor: theme =>
                `${theme.palette.background.default} !important`,
            }}
          />

          <Typography
            variant="body2"
            sx={{
              fontSize: theme => theme.typography.pxToRem(14),
              lineHeight: theme => theme.typography.pxToRem(14),
            }}
          >
            Цвет отображения типа на карте
          </Typography>

          <Controller
            control={control}
            name="color"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                onChange={(_, values) => {
                  onChange(values);
                }}
                row
                value={value}
              >
                {COLOR_TYPES.map(item => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio />}
                    label=""
                    sx={{ ...colorStyles(item.color) }}
                  />
                ))}
              </RadioGroup>
            )}
          />

          {category === IAgentShareInfoCategoryEnum.Visitors && (
            <>
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: theme => theme.typography.pxToRem(14),
                  lineHeight: theme => theme.typography.pxToRem(16),
                  width: '300px',
                  flexShrink: 0,
                }}
              >
                Покупка билетов
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: theme => theme.typography.pxToRem(16),
                    lineHeight: theme => theme.typography.pxToRem(20),
                    fontWeight: '400',
                    width: '300px',
                    flexShrink: 0,
                  }}
                >
                  Доля посетителей, приобретающих билеты
                </Typography>

                <Controller
                  control={control}
                  name="shareVisitorBuyingTickets"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <OutlinedInput
                        onChange={v => {
                          const val = parseInt(v.target.value) || 0;
                          if (val >= 0 && val <= 100) onChange(val);
                        }}
                        value={value}
                        sx={{
                          mr: '20px',
                          width: '100%',
                          maxWidth: '110px',
                          backgroundColor: theme =>
                            `${theme.palette.background.default} !important`,
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            {category !==
                              IAgentShareInfoCategoryEnum.Visitors && '%'}
                          </InputAdornment>
                        }
                      />
                      <Slider
                        onChange={onChange}
                        value={value}
                        min={0}
                        max={100}
                        marks={[{ value: 50, label: '' }]}
                        color="secondary"
                        sx={{
                          '.MuiSlider-rail': {
                            background: theme =>
                              `linear-gradient(to right, ${theme.palette.border.stroke} 50%, ${theme.palette.background.default} 50%)`,
                          },
                        }}
                      />
                    </>
                  )}
                />
              </Box>
            </>
          )}

          {[
            IAgentShareInfoCategoryEnum.DisembarkingCommuter,
            IAgentShareInfoCategoryEnum.EmbarkingCommuter,
          ].includes(category) && (
            <>
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: theme => theme.typography.pxToRem(14),
                  lineHeight: theme => theme.typography.pxToRem(16),
                  width: '300px',
                  flexShrink: 0,
                }}
              >
                Доля пассажиров с билетами
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: theme => theme.typography.pxToRem(16),
                    lineHeight: theme => theme.typography.pxToRem(20),
                    fontWeight: '400',
                    width: '300px',
                    flexShrink: 0,
                  }}
                >
                  Доля пассажиров с билетами, приобретенными заранее
                </Typography>

                <Controller
                  control={control}
                  name="sharePassengerWithTickets"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <OutlinedInput
                        onChange={v => {
                          const val = parseInt(v.target.value) || 0;
                          if (val >= 0 && val <= 100) onChange(val);
                        }}
                        value={value}
                        sx={{
                          mr: '20px',
                          width: '100%',
                          maxWidth: '110px',
                          backgroundColor: theme =>
                            `${theme.palette.background.default} !important`,
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            {category !==
                              IAgentShareInfoCategoryEnum.Visitors && '%'}
                          </InputAdornment>
                        }
                      />
                      <Slider
                        onChange={onChange}
                        value={value}
                        min={0}
                        max={100}
                        marks={[{ value: 50, label: '' }]}
                        color="secondary"
                        sx={{
                          '.MuiSlider-rail': {
                            background: theme =>
                              `linear-gradient(to right, ${theme.palette.border.stroke} 50%, ${theme.palette.background.default} 50%)`,
                          },
                        }}
                      />
                    </>
                  )}
                />
              </Box>

              {[
                IAgentShareInfoCategoryEnum.DisembarkingCommuter,
                IAgentShareInfoCategoryEnum.EmbarkingCommuter,
              ].includes(category) && (
                <>
                  <Typography
                    variant={'subtitle2'}
                    sx={{
                      fontSize: theme => theme.typography.pxToRem(14),
                      lineHeight: theme => theme.typography.pxToRem(16),
                      width: '300px',
                    }}
                  >
                    Покупка билетов
                  </Typography>

                  {BUYING_TICKETS.map(({ label, value }) => (
                    <Box
                      key={label}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontSize: theme => theme.typography.pxToRem(16),
                          lineHeight: theme => theme.typography.pxToRem(16),
                          fontWeight: '400',
                          width: '300px',
                          flexShrink: 0,
                        }}
                      >
                        {label}
                      </Typography>
                      <Controller
                        key={label}
                        control={control}
                        name={value}
                        render={({ field: { onChange, value, name } }) => (
                          <>
                            <OutlinedInput
                              onChange={v => {
                                const val = parseInt(v.target.value) || 0;
                                if (val >= 0 && val <= 100) {
                                  checkSum(name, val);
                                  onChange(val);
                                }
                              }}
                              value={value}
                              sx={{
                                mr: '20px',
                                width: '100%',
                                maxWidth: '110px',
                                backgroundColor: theme =>
                                  `${theme.palette.background.default} !important`,
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  {category !==
                                    IAgentShareInfoCategoryEnum.Visitors && '%'}
                                </InputAdornment>
                              }
                            />
                            <Slider
                              onChange={v => {
                                const el = v.target as HTMLInputElement;
                                checkSum(name, parseInt(el.value));
                                onChange(v);
                              }}
                              value={value}
                              min={0}
                              max={100}
                              color="secondary"
                              marks={[{ value: 50, label: '' }]}
                              sx={{
                                '.MuiSlider-rail': {
                                  background: theme =>
                                    `linear-gradient(to right, ${theme.palette.border.stroke} 50%, ${theme.palette.background.default} 50%)`,
                                },
                              }}
                            />
                          </>
                        )}
                      />
                    </Box>
                  ))}
                </>
              )}
            </>
          )}

          <Typography
            variant="subtitle2"
            sx={{
              fontSize: theme => theme.typography.pxToRem(14),
              lineHeight: theme => theme.typography.pxToRem(16),
            }}
          >
            Вероятности
          </Typography>

          {PROBABILITIES.map(({ label, value }) => {
            if (
              value === 'informationTableProbability' &&
              [
                IAgentShareInfoCategoryEnum.DisembarkingCommuter,
                IAgentShareInfoCategoryEnum.DisembarkingLongDistance,
              ].includes(category)
            )
              return null;
            return (
              <Box
                key={value}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: theme => theme.typography.pxToRem(16),
                    lineHeight: theme => theme.typography.pxToRem(16),
                    fontWeight: '400',
                    width: '300px',
                    flexShrink: 0,
                  }}
                >
                  {label}
                </Typography>
                <Controller
                  key={value}
                  control={control}
                  name={value}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <OutlinedInput
                        onChange={v => {
                          const val = parseInt(v.target.value) || 0;
                          if (val >= 0 && val <= 100) onChange(val);
                        }}
                        value={value}
                        sx={{
                          mr: '20px',
                          width: '100%',
                          maxWidth: '110px',
                          backgroundColor: theme =>
                            `${theme.palette.background.default} !important`,
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            {category !==
                              IAgentShareInfoCategoryEnum.Visitors && '%'}
                          </InputAdornment>
                        }
                      />
                      <Slider
                        onChange={onChange}
                        value={value}
                        min={0}
                        max={100}
                        color="secondary"
                        marks={[{ value: 50, label: '' }]}
                        sx={{
                          '.MuiSlider-rail': {
                            background: theme =>
                              `linear-gradient(to right, ${theme.palette.border.stroke} 50%, ${theme.palette.background.default} 50%)`,
                          },
                        }}
                      />
                    </>
                  )}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
