import { addWallsToRoomsPlatformsAtIntersections } from './add-walls-to-rooms-platforms-at-intersections';

import {
  ListOfFloorsWithListOfObjectsDataType,
  ListOfRulersDataType,
} from '@features/tpu-simulation-model/types';

export const preparePlanBeforeUpload = (data: {
  ruler: ListOfRulersDataType | null;
  plan: ListOfFloorsWithListOfObjectsDataType;
}) => {
  const { plan, ruler } = data;
  const updatedPlan = Object.fromEntries(
    Object.entries(plan).map(addWallsToRoomsPlatformsAtIntersections),
  );

  return JSON.stringify({ plan: updatedPlan, ruler: ruler });
};
