import { Circle, Group, Image, Rect } from 'react-konva';
import useImage from 'use-image';

import ArrowRightSvg from '@components/icons/ArrowRight.svg';
import LadderSvg from '@components/icons/Ladder.svg';

import { IPoint } from '@features/tpu-simulation-model/types';

export interface LadderProps {
  point: IPoint;
}

interface ArrowProps {
  parentWidth: number;
  parentHeight: number;
  enter?: boolean;
}

const Arrow = ({ parentWidth, parentHeight, enter = false }: ArrowProps) => {
  const [arrowRight] = useImage(ArrowRightSvg);

  const point = {
    x: enter ? 0 : parentWidth,
    y: parentHeight / 2,
  };

  return (
    <Group
      x={point.x}
      y={point.y}
    >
      <Circle
        fill={enter ? '#3679EA' : '#F3A015'}
        radius={8}
      />
      <Image
        image={arrowRight}
        x={-4}
        y={-4}
      />
    </Group>
  );
};

export const Ladder = ({ point }: LadderProps) => {
  const [ladder] = useImage(LadderSvg);

  return (
    <Group
      x={point.x}
      y={point.y}
    >
      <Rect
        width={44}
        height={44}
        fill={'#fff'}
        strokeWidth={1}
        stroke={'#3679EA'}
        strokeScaleEnabled={false}
      ></Rect>
      <Image
        image={ladder}
        width={24}
        height={24}
        x={(44 - 24) / 2}
        y={(44 - 24) / 2}
      />
      <Arrow
        parentWidth={44}
        parentHeight={44}
        enter
      />
      <Arrow
        parentWidth={44}
        parentHeight={44}
      />
    </Group>
  );
};
