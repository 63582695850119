import { IQuery, IQueryGetComputedGraphByIdArgs } from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

import { GraphPassFlowInspectorProps } from '@features/ag-forecast/components/Inspector/GraphPassFlowInspector/GraphPassFlowInspector';

const initState: PassFlowInspectorStore = {
  graphs: {
    capacity: 0,
    category: '',
    freeSpeed: 0,
    laneNum: 0,
    oneway: false,
    transport: [],
    flow: 0,
  },
};

export const $PassFlowInspector =
  createStore<PassFlowInspectorStore>(initState);

export const PassFlowInspectorApi = createApi($PassFlowInspector, {
  setGraphsForInspector: (
    store,
    payload: PassFlowInspectorStore['graphs'],
  ) => ({
    ...store,
    graphs: payload,
  }),
  reset: () => initState,
});

export const getPassFlowGraphsForInspectorFx = createEffect<
  IQueryGetComputedGraphByIdArgs,
  IQuery['getComputedGraphById']
>();

export type PassFlowInspectorStore = {
  graphs: GraphPassFlowInspectorProps['fields'];
};
