import { SvgIcon, SvgIconProps } from '@mui/material';

export const CopyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={20}
      height={20}
      viewBox="-4 -4 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M14.0205 0H6.02058C5.4957 0 4.99221 0.208548 4.62102 0.579739C4.24983 0.95093 4.04128 1.45447 4.04128 1.97949V3.90751H1.98467C1.46053 3.90608 0.957357 4.11265 0.585294 4.48188C0.213208 4.85092 0.00284613 5.35266 0 5.87662V13.8871C0.00143454 14.4116 0.210339 14.9144 0.581346 15.2853C0.952174 15.6563 1.45497 15.8652 1.97949 15.8666H9.97942C10.5043 15.8666 11.0078 15.658 11.379 15.2869C11.7502 14.9157 11.9587 14.4121 11.9587 13.8871V11.9591H14.0153C14.5395 11.9605 15.0426 11.7539 15.4147 11.3847C15.7868 11.0157 15.9972 10.5139 16 9.98998V1.97949C15.9986 1.45497 15.7897 0.952169 15.4187 0.581346C15.0478 0.210339 14.545 0.00142307 14.0205 0V0ZM10.3754 13.8818L10.3752 13.882C10.3752 14.1006 10.198 14.2777 9.97942 14.2777H1.97949C1.76197 14.275 1.58642 14.0993 1.58355 13.882V5.87653C1.58355 5.65794 1.7609 5.48059 1.97949 5.48059H9.97942C10.0849 5.47934 10.1863 5.52058 10.2608 5.59518C10.3354 5.66959 10.3766 5.77109 10.3752 5.87653V13.887L10.3754 13.8818ZM14.4164 9.98988C14.4164 10.2085 14.2391 10.3858 14.0205 10.3858H11.9639V5.87667C11.9639 5.35179 11.7554 4.8483 11.3842 4.47711C11.013 4.10574 10.5094 3.89718 9.98442 3.89718H5.62465V1.97958C5.62465 1.76099 5.80182 1.58364 6.0204 1.58364H14.0203C14.2378 1.58651 14.4134 1.76206 14.4163 1.97958L14.4164 9.98988Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
