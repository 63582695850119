export interface Option {
  value: string;
  label: string;
}

const passengersTypeOptions = [
  { value: 'longDistance', label: 'Дальние' },
  { value: 'commuter', label: 'Пригородные' },
];
const statusOptions = [
  { value: 'open', label: 'Открыт' },
  { value: 'close', label: 'Закрыт' },
];

const fillsRoomOptions = [
  { value: 'fills', label: 'Да' },
  { value: 'notFills', label: 'Нет' },
];

const waitingZoneOptions = [
  { value: 'exist', label: 'Есть' },
  { value: 'notExist', label: 'Нет' },
];

const directionOptions = [
  { value: 'in', label: 'На вход' },
  { value: 'out', label: 'На выход' },
  { value: 'inAndOut', label: 'В обе стороны' },
];

const allOptions: Record<string, Option[]> = {
  passengersType: passengersTypeOptions,
  status: statusOptions,
  fillsRoom: fillsRoomOptions,
  waitingZone: waitingZoneOptions,
  direction: directionOptions,
};

export const getAttributeOptions = (name: string): Option[] => allOptions[name];
