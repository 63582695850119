import { v4 as uuid } from 'uuid';

import {
  EditableObjectType,
  IExit,
  IPassengerGenerator,
  IPlatform,
  IRoom,
  Portal,
  isElevator,
  isExit,
  isLadder,
  isPassengerGenerator,
} from '@features/tpu-simulation-model/types';

import {
  RU_OBJECT_TYPE,
  WORD_ENDING_ISOLATED,
} from '@utils/simulationModel/errors-analyzer/constants';
import {
  IPortalsErrorAnalyzer,
  PlanError,
} from '@utils/simulationModel/errors-analyzer/types';

export class PortalsErrorAnalyzer implements IPortalsErrorAnalyzer {
  _objectLinkedToPortals = new Map<
    string,
    IPlatform | IRoom | IExit | IPassengerGenerator
  >();
  _passageFieldsInfo = new Map<
    string,
    {
      entrance: boolean;
      exit: boolean;
      type:
        | EditableObjectType.Room
        | EditableObjectType.PassengerGenerator
        | EditableObjectType.Exit
        | EditableObjectType.Platform
        | EditableObjectType.Door
        | EditableObjectType.Ladder
        | EditableObjectType.Elevator
        | EditableObjectType.Turnstile
        | EditableObjectType.SecurityCheckpoint;
    }
  >();
  _portals = new Set<Portal>();
  _errors = new Set<PlanError>();

  reset() {
    this._objectLinkedToPortals.clear();
    this._passageFieldsInfo.clear();
    this._portals.clear();
    this._errors.clear();
  }

  _updatePassageFieldInfo(portal: Portal) {
    const isLadderOrElevator = isLadder(portal) || isElevator(portal);

    if (isLadderOrElevator) {
      const passageFieldInfo = this._passageFieldsInfo.get(portal.room);
      if (!passageFieldInfo) return;

      this._passageFieldsInfo.set(portal.room, {
        entrance: true,
        exit: true,
        type: passageFieldInfo.type,
      });

      return;
    }

    const isDirectionIn = portal.direction === 'in';
    const isDirectionInAndOut = portal.direction === 'inAndOut';
    const firstRoomInfo = this._passageFieldsInfo.get(portal.firstRoom);
    const secondRoomInfo = this._passageFieldsInfo.get(portal.secondRoom);

    if (firstRoomInfo) {
      const entrance =
        isDirectionInAndOut || !isDirectionIn || firstRoomInfo.entrance;
      const exit = isDirectionInAndOut || isDirectionIn || firstRoomInfo.exit;

      this._passageFieldsInfo.set(portal.firstRoom, {
        entrance,
        exit,
        type: firstRoomInfo.type,
      });
    }

    if (secondRoomInfo) {
      const entrance =
        isDirectionInAndOut || isDirectionIn || secondRoomInfo.entrance;
      const exit = isDirectionInAndOut || isDirectionIn || secondRoomInfo.exit;

      this._passageFieldsInfo.set(portal.secondRoom, {
        entrance,
        exit,
        type: secondRoomInfo.type,
      });
    }
  }

  addObject(data: IRoom | IPassengerGenerator | IExit | IPlatform | Portal) {
    switch (data.type) {
      case EditableObjectType.Room:
      case EditableObjectType.Exit:
      case EditableObjectType.PassengerGenerator:
      case EditableObjectType.Platform:
        this._objectLinkedToPortals.set(data.uuid, data);
        this._passageFieldsInfo.set(data.uuid, {
          entrance: false,
          exit: false,
          type: data.type,
        });
        return;
      case EditableObjectType.Ladder:
      case EditableObjectType.Door:
      case EditableObjectType.Elevator:
      case EditableObjectType.Turnstile:
      case EditableObjectType.SecurityCheckpoint:
        this._portals.add(data);
        return;
      default:
        return;
    }
  }

  getErrors() {
    this._portals.forEach(portal => {
      this._updatePassageFieldInfo(portal);

      const isLadderOrElevator = isLadder(portal) || isElevator(portal);
      const ruType = RU_OBJECT_TYPE[portal.type];

      if (isLadderOrElevator) {
        if (!portal.room) {
          this._errors.add({
            id: uuid(),
            objectType: portal.type,
            objects: [portal],
            description: `У объекта "${ruType}" не заполнен параметр "Помещение"`,
          });
        }

        if (!portal.connectedObjects.length) {
          this._errors.add({
            id: uuid(),
            objectType: portal.type,
            objects: [portal],
            description: `У объекта "${ruType}" не заполнен параметр "Направление прохода"`,
          });
        }

        return;
      }

      if (!portal.firstRoom || !portal.secondRoom) {
        this._errors.add({
          id: uuid(),
          objectType: portal.type,
          objects: [portal],
          description: `У объекта "${ruType}" не заполнен параметр "Направление прохода"`,
        });
      }
    });

    this._passageFieldsInfo.forEach((value, key) => {
      const data = this._objectLinkedToPortals.get(key);
      if (!data) return;
      const name = 'name' in data ? `"${data.name}"` : '';
      const ruType = RU_OBJECT_TYPE[data.type];
      const endingIsolated = WORD_ENDING_ISOLATED[data.type];

      if (!value.entrance && !isPassengerGenerator(data)) {
        this._errors.add({
          id: uuid(),
          objectType: data.type,
          objects: [data],
          description: `${ruType} ${name} изолирован${endingIsolated}: отсутствует вход`,
        });
      }
      if (!value.exit && !isExit(data)) {
        this._errors.add({
          id: uuid(),
          objectType: data.type,
          objects: [data],
          description: `${ruType} ${name} изолирован${endingIsolated}: отсутствует выход`,
        });
      }
    });

    return Array.from(this._errors);
  }
}
