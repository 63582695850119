export const SOC_ECO_TH_3_3 = [
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'transport',
    label:
      'Транспорт (автомобильный транспорт, троллейбус, трамвай, метрополитен, водный транспорт, железнодорожный транспорт, прочие виды транспорта)',
  },
  {
    id: 'emissionsVolume',
    label: 'Общий объём выбросов по видам транспорта в год, тонн',
  },
];
