import {
  DashboardToolBar,
  Props as DashboardToolBarProps,
} from '@features/ag-forecast/components/DashboardToolBar';

// import { $FilterRegions } from '@features/ag-forecast/stores/';
// import { useUnit } from 'effector-react';

export const DashboardToolBarContainer = ({
  onBackButtonClick,
  onChangeView,
}: Props) => {
  // const $filterRegions = useUnit($FilterRegions);

  return (
    <DashboardToolBar
      onBackButtonClick={onBackButtonClick}
      onChangeView={onChangeView}
      pointARegion={
        '' // $filterRegions.selectedRegionA?.name
      }
      pointBRegion={
        '' // $filterRegions.selectedRegionB?.name
      }
    />
  );
};

interface Props {
  onBackButtonClick: DashboardToolBarProps['onBackButtonClick'];
  onChangeView: DashboardToolBarProps['onChangeView'];
}
