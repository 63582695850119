import { SvgIcon, SvgIconProps } from '@mui/material';

export const BasketIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={20}
      height={20}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M13.8889 10.2222V16.4444M10.3333 10.2222V16.4444M5 6.66667H19.2222M6.77778 6.66667H17.4444V19.1111C17.4444 19.602 17.0465 20 16.5556 20H7.66667C7.17575 20 6.77778 19.602 6.77778 19.1111V6.66667ZM9.44444 4H14.7778C15.2687 4 15.6667 4.39797 15.6667 4.88889V6.66667H8.55556V4.88889C8.55556 4.39797 8.95352 4 9.44444 4Z"
        stroke="currentColor"
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
