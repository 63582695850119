import { Stroke, Style } from 'ol/style';

import { TileStyleProps } from '@utils/map/styles/types';
import { createActiveFeatureBorder } from '@utils/map/tools/createActiveFeatureBorder';
import { getLineWidth } from '@utils/map/tools/getLineWidth';

export const highwayTileStyle = (props: TileStyleProps) => {
  const { map, resolution, width, isActive, feature } = props;

  const zoom = map.getView().getZoomForResolution(resolution) || 10;
  const type = feature?.getProperties()['type'];
  const lineWidth = width
    ? width
    : type === 3
    ? getLineWidth(zoom, [0, 0.4, 4, 1, 9, 2.5, 22, 4])
    : getLineWidth(zoom, [0, 0.2, 4, 0.5, 9, 2, 22, 2]);

  const styles = isActive ? [createActiveFeatureBorder(lineWidth)] : [];

  styles.push(
    new Style({
      stroke: new Stroke({
        color: '#eb8042',
        width: lineWidth,
      }),
    }),
  );

  return styles;
};
