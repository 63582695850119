import { useUnit } from 'effector-react';
import { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { ChartTooltip } from '@features/pt-forecast-new/components/Dashboards/YearlyPassChart/ChartTooltip';
import { getTrafficColor } from '@features/pt-forecast-new/components/Dashboards/YearlyPassChart/getTrafficColor';
import { tickFormatter } from '@features/pt-forecast-new/components/Dashboards/YearlyPassChart/tickFormatter';
import { vehicleTypeCodeAvailableCodes } from '@features/pt-forecast-new/constants/VehicleTypeCode';
import { $Computed } from '@features/pt-forecast-new/stores/lists/computed';

import { unitsOfMeasure } from '@utils/dashboardTools';

type YearlyPassChartProps = {
  unit: string;
};

export const YearlyPassChart = (props: YearlyPassChartProps) => {
  const { unit } = props;
  const { sampleResults } = useUnit($Computed);

  const data = useMemo(() => {
    return sampleResults.map(yearData => ({
      [vehicleTypeCodeAvailableCodes[0]]:
        yearData.passengerTraffic.dayTrain || 0,
      [vehicleTypeCodeAvailableCodes[1]]:
        yearData.passengerTraffic.nightTrain || 0,
      [vehicleTypeCodeAvailableCodes[2]]:
        yearData.passengerTraffic.suburbanTrain || 0,
      [vehicleTypeCodeAvailableCodes[3]]:
        yearData.passengerTraffic.expressTrain || 0,
      [vehicleTypeCodeAvailableCodes[4]]: yearData.passengerTraffic.avia || 0,
      [vehicleTypeCodeAvailableCodes[5]]: yearData.passengerTraffic.auto || 0,
      [vehicleTypeCodeAvailableCodes[6]]: yearData.passengerTraffic.bus || 0,
      [vehicleTypeCodeAvailableCodes[7]]:
        yearData.passengerTraffic.multimodal || 0,
      name: yearData.year,
    }));
  }, [sampleResults]);

  return (
    <ResponsiveContainer
      width="100%"
      minHeight="180px"
    >
      <BarChart
        data={data}
        height={180}
        margin={{
          top: 10,
          bottom: 30,
        }}
      >
        <CartesianGrid vertical={false} />

        <XAxis
          dataKey="name"
          fontSize={12}
        />

        <YAxis
          min={0}
          width={120}
          fontSize={12}
          tickFormatter={value => tickFormatter(value, unit)}
        >
          <Label
            value="Пассажиропоток суммарно"
            position="insideLeft"
            dx={40}
            dy={20}
            angle={-90}
            style={{ textAnchor: 'middle', fontSize: 12 }}
          />
          <Label
            value={`${unitsOfMeasure.find(item => item.value === unit)
              ?.label}. пасс.`}
            position="top"
            dx={-20}
            dy={20}
            style={{
              textAnchor: 'middle',
              fontSize: 12,
            }}
          />
        </YAxis>

        <Legend
          iconType="circle"
          wrapperStyle={{
            fontSize: '0.75rem',
            lineHeight: 1.75,
            left: 20,
            width: 'calc(100% - 50px)',
          }}
          layout="horizontal"
        />

        {vehicleTypeCodeAvailableCodes.map(code => (
          <Bar
            key={code}
            barSize={20}
            dataKey={code}
            stackId="passTraffic"
            fill={getTrafficColor(code)}
          />
        ))}

        <Tooltip<number, string>
          cursor={{ fill: 'transparent' }}
          content={args => (
            <ChartTooltip
              {...args}
              unit={unit}
            />
          )}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
