import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import {
  CloseOutlined,
  InfoOutlined,
  RestartAltOutlined,
} from '@mui/icons-material';
import { Button, IconButton, Paper, Stack, Typography } from '@mui/material';

import { ClearIcon } from '@components/icons';
import { AddRailwayGraph } from '@components/icons/AddRailwayGraph';
import { AddRailwayGraphAccept } from '@components/icons/AddRailwayGraphAccept';
import { AddRailwayStation } from '@components/icons/AddRailwayStation';
// import { SaveIcon } from '@components/icons/Save';
import { Settings2 } from '@components/icons/Settings2';

import { ECalculateStatus } from '@features/pt-forecast-new/constants/ECalculateStatus';
import {
  $EditorMap,
  EditorMapApi,
  runCalculateScenarioFx,
} from '@features/pt-forecast-new/stores/map/editor';
import {
  $FeatureSettings,
  getScenarioComputedStatusFx,
} from '@features/pt-forecast-new/stores/settings';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';

export const MapEditToolBar = () => {
  const {
    panels: { inspector, filter, newInfrastructure },
    placeRailwayStation,
    isDrawGraph,
  } = useUnit($UI);
  const {
    setPlaceRailwayStations,
    setDrawGraph,
    setVisibleWidgetRoutesParams,
    setEditMode,
    setDisabledRecalculation,
  } = useUnit(UIApi);
  const { drawGraph, lastStep } = useUnit($EditorMap);
  const { addDrawGraphEndStation, clearMainUpdated } = useUnit(EditorMapApi);
  const { scenarioId, scenarioCalculateStatus } = useUnit($FeatureSettings);

  const onClickTogglePlaceRailwayStation = useCallback(() => {
    setPlaceRailwayStations(!placeRailwayStation);
  }, [placeRailwayStation, setPlaceRailwayStations]);

  const onClickDrawGraph = useCallback(() => {
    if (drawGraph) {
      !!drawGraph &&
        drawGraph.nodes.length > 1 &&
        lastStep!.isStation &&
        addDrawGraphEndStation(lastStep!.stationId);
      setDrawGraph(false);
    } else {
      setDrawGraph(true);
    }
  }, [drawGraph, lastStep, addDrawGraphEndStation, drawGraph, setDrawGraph]);

  const handleOpenRoutesParams = useCallback(
    () => setVisibleWidgetRoutesParams(true),
    [setVisibleWidgetRoutesParams],
  );

  const handleCloseEdit = useCallback(() => {
    setEditMode(false);
  }, [setEditMode]);

  const handleClickRecalculate = useCallback(() => {
    clearMainUpdated();
    setDisabledRecalculation(true);
    runCalculateScenarioFx({
      id: scenarioId,
    }).then(() => {
      getScenarioComputedStatusFx({
        id: scenarioId,
      });
    });
  }, [clearMainUpdated, scenarioId, setDisabledRecalculation]);

  return (
    <Paper
      sx={{
        backgroundColor: theme => theme.palette.common.white,
        boxShadow: '0px 0px 8px 0px #00000026',
        borderRadius: '6px',
        width: `calc(100vw - ${inspector ? 500 : 1}px - ${
          filter || newInfrastructure ? 500 : 1
        }px - 50px)`,
        p: 2.5,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{}}
      >
        <Stack
          direction="row"
          gap={2}
        >
          <Button
            sx={{
              width: '125px',
            }}
            color={!placeRailwayStation ? 'primary' : 'secondary'}
            onClick={onClickTogglePlaceRailwayStation}
          >
            {!placeRailwayStation ? (
              <>
                <AddRailwayStation
                  style={{
                    marginRight: '8px',
                  }}
                />
                Станция
              </>
            ) : (
              <>
                <ClearIcon
                  style={{
                    marginRight: '8px',
                  }}
                />
                Отменить
              </>
            )}
          </Button>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
          >
            <Button
              sx={() => ({
                width: '138px',
              })}
              color={!isDrawGraph ? 'primary' : 'secondary'}
              onClick={onClickDrawGraph}
            >
              {!isDrawGraph ? (
                <>
                  <AddRailwayGraph
                    style={{
                      marginRight: '8px',
                    }}
                  />
                  ЖД линия
                </>
              ) : (
                <>
                  <AddRailwayGraphAccept
                    style={{
                      marginRight: '8px',
                    }}
                  />
                  Завершить
                </>
              )}
            </Button>
            <IconButton style={{ width: '24px', height: '24px' }}>
              <InfoOutlined style={{ opacity: 0.5 }} />
            </IconButton>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap={2}
        >
          <Button
            color="secondary"
            sx={{
              width: '145px',
            }}
            onClick={handleOpenRoutesParams}
          >
            <Settings2
              style={{ width: '16px', height: '16px', marginRight: '8px' }}
            />
            <Typography>Параметры</Typography>
          </Button>
          <Button
            color="primary"
            sx={{
              width: '142px',
            }}
            onClick={handleClickRecalculate}
            disabled={
              scenarioCalculateStatus === ECalculateStatus.PENDING ||
              scenarioCalculateStatus === ECalculateStatus.QUEUED
            }
          >
            <RestartAltOutlined
              style={{ width: '16px', height: '16px', marginRight: '8px' }}
            />
            <Typography>Рассчитать</Typography>
          </Button>
          <Button
            variant="outlined"
            style={{ minWidth: '44px', padding: '4px' }}
            onClick={handleCloseEdit}
          >
            <CloseOutlined />
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};
