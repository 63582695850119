import { IFloorFileInfoType } from '@api/gql/tpu-types';

import { Floors } from './Floors';
import { Legend } from './Legend';
import { Opacity } from './Opacity';
import { Ruler } from './Ruler';

import { Box } from '@mui/material';

import { PlanErrors } from '@features/tpu-simulation-model/components/Controllers/PlanErrors';
import { Scale } from '@features/tpu-simulation-model/components/Controllers/Scale';

interface ControllersProps {
  floors: IFloorFileInfoType[];
  selectedFloor: string;
  opacity: number;
  numberOfErrors: number | null;
  onPlanErrorsClick: () => void;
  onSelectFloor: (arg: string) => void;
  onCenter: () => void;
  incrementScale: () => void;
  decrementScale: () => void;
  onOpacityChange: (arg: number) => void;
}

export const Controllers = ({
  floors,
  selectedFloor,
  onSelectFloor,
  onCenter,
  incrementScale,
  decrementScale,
  opacity,
  onOpacityChange,
  onPlanErrorsClick,
  numberOfErrors,
}: ControllersProps) => {
  return (
    <Box
      sx={{
        padding: '24px',
        display: 'grid',
        gridTemplate:
          '"floors transparency ruler legend" 48px \n "errors . . scale"',
        height: '100%',
        gridRowGap: '16px',
        gridColumnGap: '24px',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        /** pointerEvents: 'none' не допускает никакого взаимодействия с элементами ниже.
         * Если есть такая необходимость, то нужно добавить соответствуещее свойство конкртеонму элементу  */
        pointerEvents: 'none',
      }}
    >
      <Floors
        options={floors}
        value={selectedFloor}
        onChange={onSelectFloor}
      />
      <Opacity
        value={opacity}
        onChange={onOpacityChange}
      />
      <Ruler />
      <Legend />
      <PlanErrors
        onClick={onPlanErrorsClick}
        numberOfErrors={numberOfErrors}
      />
      <Scale
        onCenter={onCenter}
        increment={incrementScale}
        decrement={decrementScale}
      />
    </Box>
  );
};
