import { IAgentShareInfoType } from '@api/gql/tpu-types';
import { Control, Controller, FieldPath } from 'react-hook-form';

import { Box, OutlinedInput, Typography } from '@mui/material';

import { COLORS } from '@features/tpu-passenger-types/constants';
import { IVisitorsShareForm } from '@features/tpu-passenger-types/types';

interface InputVisitorShareProps {
  control: Control<IVisitorsShareForm>;
  fieldName: FieldPath<IVisitorsShareForm>;
  agentShareInfo: IAgentShareInfoType;
}

export const InputVisitorShare = ({
  control,
  fieldName,
  agentShareInfo,
}: InputVisitorShareProps) => {
  const color = agentShareInfo.color
    ? COLORS[agentShareInfo.color]
    : 'transparent';

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: '8px',
        }}
      >
        <Box
          sx={{
            height: '16px',
            width: '16px',
            borderRadius: '50%',
            mr: '8px',
            backgroundColor: color,
          }}
        />
        <Typography
          sx={{
            fontSize: theme => theme.typography.pxToRem(14),
            lineHeight: theme => theme.typography.pxToRem(16),
          }}
        >
          {agentShareInfo.name},
          <Typography
            component={'span'}
            sx={{
              fontSize: 'inherit',
              lineHeight: 'inherit',
              color: theme => theme.palette.text.secondary,
              ml: '4px',
            }}
          >
            чел
          </Typography>
        </Typography>
      </Box>
      <Controller
        render={({ field }) => (
          <OutlinedInput
            fullWidth
            type={'number'}
            sx={{
              mr: '10px',
            }}
            {...field}
            onChange={e => {
              const value = parseInt(e.target.value) || 0;
              const updatedValue = Math.max(value, 0);
              field.onChange(updatedValue);
            }}
          />
        )}
        name={fieldName}
        control={control}
      />
    </Box>
  );
};
