import { useCallback } from 'react';
import { TooltipProps } from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';

import { Box } from '@mui/material';

import { gapBetweenDigits } from '@utils/dataForInspector';

interface TooltipData extends Payload<number, string> {
  dataKey: string;
  color: string;
  name: string;
}

export const ChartTooltip = (props: TooltipProps<number, string>) => {
  const { active, payload } = props;
  const Dot = useCallback(
    (color: string) => (
      <Box
        sx={{
          borderRadius: '50%',
          minWidth: '0.65rem',
          width: '0.65rem',
          height: '0.65rem',
          backgroundColor: color,
        }}
      />
    ),
    [],
  );

  if (!active || !payload?.length) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        backgroundColor: 'white',
        boxShadow: '0px 2px 4px 0px #0000001A',
        borderRadius: '8px',
        padding: '12px',
      }}
    >
      {payload.map((e, i) => {
        const tooltipData = e as TooltipData;
        const value = gapBetweenDigits(
          +tooltipData.payload[tooltipData.dataKey],
          0,
        );

        return (
          <Box
            key={i}
            sx={{
              display: 'flex',
              gap: '6px',
              alignItems: 'center',
              fontSize: '0.75rem',
              fontWeight: '400',
              lineHeight: '0.65rem',
              color: theme => theme.palette.text.secondary,
            }}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '4px',
                  mb: '4px',
                }}
              >
                {Dot(tooltipData.color)}

                <span>{tooltipData.name} (чел.)</span>
              </Box>

              <Box>{value}</Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
