import { useUnit } from 'effector-react';
import { useCallback, useEffect, useMemo } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import {
  EFilterMapLayout,
  EFilterMapPoint,
  MapFilterField,
  MapFilters,
} from '@components/MapFilters';

import {
  $FilterMap,
  $FilterRegions,
  SetRegionArg,
  findRegionByPrefixFn,
  resetAllFilters,
  setFilters,
  setLayoutFn,
  setRegionFn,
  setStartPointFn,
} from '@features/passenger-traffic/stores';
import { getVehicleTypesForInspectorFn } from '@features/passenger-traffic/stores/inspector/store';
import { clearPointsFn } from '@features/passenger-traffic/stores/map/store';
import {
  $UI,
  setMapBackdropFn,
  setVisibleInspector,
} from '@features/passenger-traffic/stores/ui/store';

const FiltersContainerMuiStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: '2',
  boxShadow: 'rgba(0, 0, 0, 0.1) 5px 4px 2px 0px',
  background: theme => theme.palette.background.default,
};

export const MapFiltersContainer = () => {
  const { regionsA, regionsB, selectedRegionA, selectedRegionB } =
    useUnit($FilterRegions);
  const $filterMap = useUnit($FilterMap);
  const { panels } = useUnit($UI);

  const handlers = useUnit({
    getVehicleTypesForInspectorFn,
    setLayoutFn,
    setStartPointFn,
    setMapBackdropFn,
    findRegionByPrefixFn,
    setRegionFn,
    setFilters: setFilters,
    resetAllFilters,
    clearPointsFn,
  });

  /** Получение доступных видов транспорта */
  useEffect(() => {
    handlers.getVehicleTypesForInspectorFn();
  }, [handlers]);

  const handlerChangeLayout = handlers.setLayoutFn;
  const handlerResetFilters = handlers.resetAllFilters;

  const handlerStartPoint = useCallback(
    (point: EFilterMapPoint) => {
      handlers.setStartPointFn(point);
      handlers.setMapBackdropFn(true);
    },
    [handlers],
  );

  const handlerOnInputRegion = useCallback((region: RegionPoint) => {
    const { name, point } = region;

    handlers.findRegionByPrefixFn({ name, maxCount: 3, point });
  }, []); // TODO: найти причину постоянного обновления handlers, после фикса - вернуть зависимость

  const handlerSetRegion = useCallback(
    (region: SetRegionArg) => {
      handlers.setRegionFn(region);

      if (!region.option) {
        handlers.clearPointsFn();
      }
    },
    [handlers],
  );

  const handlerChangeFields = useCallback(
    (fields: Partial<MapFilterField>) => {
      handlers.setFilters(fields);
    },
    [handlers],
  );

  const hideFields = useMemo<Array<keyof MapFilterField>>(() => {
    if (!$filterMap.passengerTraffic.expressTrain) return ['expressTrain'];
    return [];
  }, [$filterMap.passengerTraffic]);

  const handlerResetSelectedRegions = useCallback(() => {
    if (
      $filterMap.layout === EFilterMapLayout.PassengerFlows ||
      $filterMap.layout === EFilterMapLayout.RouteNetwork
    ) {
      setVisibleInspector(false);
    }
  }, [$filterMap.layout]);

  return (
    panels.filter && (
      <Box sx={FiltersContainerMuiStyle}>
        <MapFilters
          hideFields={hideFields}
          fields={$filterMap}
          layout={$filterMap.layout}
          onChangeLayout={handlerChangeLayout}
          onReset={handlerResetFilters}
          setPoint={handlerStartPoint}
          fetchRegion={handlerOnInputRegion}
          setRegion={handlerSetRegion}
          regionsA={regionsA}
          regionsB={regionsB}
          regionA={selectedRegionA}
          regionB={selectedRegionB}
          onChangeFields={handlerChangeFields}
          onResetSelectedRegions={handlerResetSelectedRegions}
        />
      </Box>
    )
  );
};

export type RegionPoint = { name: string; point: EFilterMapPoint };
