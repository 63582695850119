import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import {
  Box,
  Button,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { ChevronLeftIcon } from '@components/icons';

import { AssociatedStations } from '@features/pt-forecast-new/components/NewInfrastructure/AssociatedStations';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';

export const NewInfrastructureContainer = () => {
  const {
    panels: { filter },
  } = useUnit($UI);
  const { setVisibleNewInfrastructurePanel } = useUnit(UIApi);

  const onClose = useCallback(() => {
    setVisibleNewInfrastructurePanel(false);
  }, [setVisibleNewInfrastructurePanel]);

  const handlerCalculate = useCallback(() => {}, []);

  return (
    <Box
      sx={NewInfrastructureContainerMuiStyle}
      left={filter ? '500px' : 0}
    >
      <Stack>
        <AutoFullHeight
          mb={77}
          style={{
            width: '500px',
            maxWidth: '500px',
            overflowY: 'auto',
          }}
        >
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={3}
            columnGap={2}
          >
            <Typography variant="subtitle2">Новые объекты</Typography>

            <IconButton
              sx={{
                width: '32px',
                height: '32px',
                backgroundColor: 'primary.main',
                borderRadius: 1,
                color: 'white',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
              }}
              onClick={onClose}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Stack>

          <AssociatedStations />
        </AutoFullHeight>

        <Box
          sx={{
            mt: 'auto',
            p: 2,
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Button
            color="secondary"
            fullWidth
            // disabled={disabled.recalculation || !id}
            onClick={handlerCalculate}
          >
            Рассчитать
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

const NewInfrastructureContainerMuiStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '0',
  zIndex: '3',
  background: theme => theme.palette.background.default,
  '& > *': {
    borderTop: '1px solid #EBECEF',
    borderLeft: '1px solid #EBECEF',
    boxShadow: 'rgba(0, 0, 0, 0.1) 5px 4px 2px 0px',
    overflowY: 'auto',
  },
};
