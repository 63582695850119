import { useUnit } from 'effector-react';
import { useEffect, useRef } from 'react';

import { Box } from '@mui/material';

import { LocationIcon } from '@components/icons';
import { AddTerminalRailwayStation } from '@components/icons/AddTerminalRailwayStation';

import {
  $FilterMap,
  directionPoints,
} from '@features/pt-forecast-new/stores/filters';
import { $UI } from '@features/pt-forecast-new/stores/ui';

export const MapCursorPoint = () => {
  const pointContainerRef = useRef<HTMLDivElement>(null);
  const eventCbRef = useRef<(event: MouseEvent) => void | null>(null);
  const { isSetPoint, placeRailwayStation } = useUnit($UI);
  const { directionPointSelected } = useUnit($FilterMap);

  useEffect(() => {
    if (!pointContainerRef.current) return;

    const clearEvent = () => {
      window.document.removeEventListener('mousemove', eventCbRef.current!);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      eventCbRef.current = null;
      setTimeout(() => {
        pointContainerRef.current!.style.transform = `translate(${0}px, ${0}px)`;
        pointContainerRef.current!.style.display = 'none';
      }, 100);
    };

    if (eventCbRef.current !== null) clearEvent();

    if (!placeRailwayStation && !isSetPoint) return;

    pointContainerRef.current!.style.display = 'block';

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    eventCbRef.current = (() => {
      const rect = pointContainerRef.current!.getBoundingClientRect();
      const clientHeight = pointContainerRef.current!.scrollHeight;
      const clientWidth = pointContainerRef.current!.clientWidth;
      const yOffset = rect.top + clientHeight / 2 + (isSetPoint ? 15 : -8);
      const xOffset = rect.left + clientWidth / 2;

      return (event: MouseEvent) => {
        requestAnimationFrame(() => {
          pointContainerRef.current!.style.transform = `translate(${
            event.x - xOffset
          }px, ${event.y - yOffset}px)`;
        });
      };
    })();

    window.document.addEventListener('mousemove', eventCbRef.current!);
  }, [isSetPoint, placeRailwayStation]);

  useEffect(
    () => () => {
      eventCbRef.current &&
        window.document.removeEventListener('mousemove', eventCbRef.current);
    },
    [],
  );

  return (
    <Box
      ref={pointContainerRef}
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 1,
        height: '40px',
        pointerEvents: 'none',
        display: 'none',
      }}
    >
      {isSetPoint && (
        <LocationIcon
          text={
            directionPointSelected === directionPoints.from
              ? 'А'
              : directionPointSelected === directionPoints.to
              ? 'Б'
              : ''
          }
        />
      )}
      {placeRailwayStation && (
        <AddTerminalRailwayStation
          width="32px"
          height="32px"
        />
      )}
    </Box>
  );
};
