import { useUnit } from 'effector-react';
import { SyntheticEvent, useCallback } from 'react';

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';

import { AccordionFilter } from '@components/MapFilters/AccordionFilter';
import { typographyWhitCircleStyle } from '@components/MapFilters/utils';

import { $FilterMap, FilterMapApi } from '@features/ag-forecast/stores/filters';
import { $Vehicles } from '@features/ag-forecast/stores/lists/vehicles';
import { $MapStore } from '@features/ag-forecast/stores/map';
import { vehicleIdToLayout } from '@features/ag-forecast/utils/vehicleIdToLayout';
import { vehicleToPalette } from '@features/ag-forecast/utils/vehicleToPalette';

type VehicleFiltersInfraProps = {
  isShowCircles?: boolean;
};

export const VehicleFiltersInfra = ({
  isShowCircles,
}: VehicleFiltersInfraProps) => {
  const $filterMap = useUnit($FilterMap);
  const $vehicles = useUnit($Vehicles);
  const { isBusy } = useUnit($MapStore);

  const { setFilters } = useUnit(FilterMapApi);

  const setTransportDistrictsRootHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFilters({ transportDistricts: checked });
    },
    [setFilters],
  );

  const setIsActualHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFilters({ isActual: checked });
    },
    [setFilters],
  );

  const setIsPlannedHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFilters({ isPlanned: checked });
    },
    [setFilters],
  );

  const setVehicleHandler = useCallback(
    (vehicleId: number, checked: boolean) => {
      setFilters({
        [vehicleIdToLayout(vehicleId)]: checked,
      });
    },
    [setFilters],
  );

  if (!$vehicles) return null;

  return (
    <Box
      style={{
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'clip',
        marginRight: '12px',
        marginTop: '8px',
        paddingRight: '16px',
        padding: '16px 32px 16px 44px',
      }}
    >
      <Typography
        fontWeight={600}
        mb={2}
      >
        Фильтры
      </Typography>
      <AccordionFilter title="По видам транспорта">
        <Stack direction="column">
          {$vehicles.map(vehicle => (
            <FormControlLabel
              key={vehicle.id}
              disabled={isBusy}
              checked={$filterMap.actual[vehicleIdToLayout(vehicle.vehicleId)]}
              onChange={(_, checked) =>
                setVehicleHandler(vehicle.vehicleId, checked)
              }
              control={<Checkbox disabled={isBusy} />}
              label={
                <Typography
                  sx={({ palette }) => {
                    if (!isShowCircles) return {};
                    const [a, b] = vehicleToPalette(vehicle.vehicleId, palette);
                    return {
                      ...typographyWhitCircleStyle(a, b),
                      opacity: !isBusy ? 1 : 0.5,
                    };
                  }}
                  variant={'body2'}
                >
                  {vehicle.name}
                </Typography>
              }
            />
          ))}
        </Stack>
      </AccordionFilter>
      <Divider />
      <Stack
        pl={2}
        mt={2}
        mb={2}
      >
        <FormControlLabel
          checked={$filterMap.actual.isActual}
          onChange={setIsActualHandler}
          disabled={isBusy}
          control={<Checkbox />}
          label="Актуальная"
        />

        <FormControlLabel
          checked={$filterMap.actual.isPlanned}
          disabled={isBusy}
          onChange={setIsPlannedHandler}
          control={<Checkbox />}
          label="Планируемая"
        />
      </Stack>
      <Divider />
      <Box
        pl={2}
        mt={2}
      >
        <FormControlLabel
          checked={$filterMap.actual.transportDistricts}
          onChange={setTransportDistrictsRootHandler}
          disabled={isBusy}
          control={<Checkbox />}
          label="Границы транспортного района"
        />
      </Box>
    </Box>
  );
};
