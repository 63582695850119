import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckOkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fontSize="large"
      fill="none"
      {...props}
    >
      <path
        d="M18.333 22.489L21.0219 25.1779L27.133 19.0668M36.6663 22C36.6663 30.1002 30.0999 36.6667 21.9997 36.6667C13.8995 36.6667 7.33301 30.1002 7.33301 22C7.33301 13.8999 13.8995 7.33337 21.9997 7.33337C30.0999 7.33337 36.6663 13.8999 36.6663 22Z"
        stroke="#7CBC68"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
