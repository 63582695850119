import { IScenarioStateEnum } from '@api/gql/tpu-types';
import {
  $Form,
  $IsOpenForm,
} from '@features/tpu-calculation-scenarios/stores/form.store';
import { useUnit } from 'effector-react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button } from '@mui/material';

import { PlusIcon } from '@components/icons';

interface ToolbarProps {
  onCreateScenario: () => void;
  onBack: () => void;
  onLaunchClick: () => void;
  goToSimulationViewer: () => void;
  goToSimulationModelEditor: () => void;
}

export const Toolbar = ({
  onCreateScenario,
  onBack,
  onLaunchClick,
  goToSimulationViewer,
  goToSimulationModelEditor,
}: ToolbarProps) => {
  const $isOpenForm = useUnit($IsOpenForm);
  const $form = useUnit($Form);

  const isScenarioCalculated = $form.state === IScenarioStateEnum.Calculated;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 0 24px 0',
      }}
    >
      <Box>
        <Button
          variant={'text'}
          onClick={onBack}
          sx={{
            [`& .MuiButton-startIcon > *:nth-of-type(1)`]: {
              fontSize: '12px',
            },
            mr: '24px',
          }}
          startIcon={<ArrowBackIcon />}
          color={'info'}
        >
          Назад
        </Button>

        <Button
          variant={'contained'}
          color={'secondary'}
          sx={{
            width: '292px',
          }}
          disabled={$isOpenForm}
          onClick={onCreateScenario}
        >
          <PlusIcon sx={{ mr: '8px' }} />
          Создать сценарий
        </Button>
      </Box>

      <Box>
        <Button
          sx={{
            mr: '24px',
            width: '214px',
          }}
          disabled={!($isOpenForm && $form.isOpenForViewing)}
          onClick={isScenarioCalculated ? goToSimulationViewer : onLaunchClick}
        >
          {isScenarioCalculated ? 'Открыть модель' : 'Запустить'}
        </Button>

        <Button
          color={'secondary'}
          sx={{
            width: '214px',
          }}
          disabled={!($isOpenForm && $form.id)}
          onClick={goToSimulationModelEditor}
        >
          Перейти в редактор
        </Button>
      </Box>
    </Box>
  );
};
