import { createApi, createStore } from 'effector';

import { EVehicle } from '@features/pt-forecast/constants/vehiclesEnum';

const initStore: PassengerTrafficType = {
  passengerTraffic: {
    multimodal: 0,
    nightTrain: 0,
    dayTrain: 0,
    suburbanTrain: 0,
    bus: 0,
    auto: 0,
    avia: 0,
    expressTrain: 0,
  },
  passengerTrafficPercent: {
    multimodal: 0,
    nightTrain: 0,
    dayTrain: 0,
    suburbanTrain: 0,
    bus: 0,
    auto: 0,
    avia: 0,
    expressTrain: 0,
  },
  total: 0,
};

export const $PassengerTraffic = createStore<PassengerTrafficType>(initStore);

export const PassengerTrafficApi = createApi($PassengerTraffic, {
  setPassengerTraffic: (
    store,
    payload: PassengerTrafficType['passengerTraffic'],
  ) => ({
    ...store,
    passengerTraffic: payload,
  }),
  setPassengerTrafficPercent: (
    store,
    payload: PassengerTrafficType['passengerTrafficPercent'],
  ) => ({
    ...store,
    passengerTrafficPercent: payload,
  }),
  setTotalPassengerTraffic: (
    store,
    payload: PassengerTrafficType['total'],
  ) => ({
    ...store,
    total: payload,
  }),
  reset: () => initStore,
});

export type PassengerTrafficType = {
  passengerTraffic: Record<keyof typeof EVehicle, number>;
  passengerTrafficPercent: Record<keyof typeof EVehicle, number>;
  total: number;
};
