import {
  IQuery,
  IQueryGetProjectInfraPointByNameArgs,
} from '@api/gql/ag-types';
import { createApi, createEffect, createEvent, createStore } from 'effector';

const initState: SearchOptionsStore = {
  options: [],
};

export const $SearchOptions = createStore<SearchOptionsStore>(initState);

export const SearchOptionsApi = createApi($SearchOptions, {
  setOptions: (store, payload: SearchOption[]) => ({
    ...store,
    options: payload,
  }),
  reset: () => initState,
});

export const getSearchOptions = createEvent<string>();

export const querySearchOptionsFx = createEffect<
  IQueryGetProjectInfraPointByNameArgs,
  IQuery['getProjectInfraPointByName']
>();

export type SearchOptionsStore = {
  options: SearchOption[];
};

export type SearchOption = {
  name: string;
  value: string;
  lonLat: number[];
  type: 'zone' | 'distict' | 'station';
};
