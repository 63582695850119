import { SvgIcon, SvgIconProps } from '@mui/material';

export const TicketsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        d="M20.826 12.194H23v8.615h-2.33c-.232.698-.776.931-1.475.931H12.13l-3.494 1.164c-.932.31-1.941-.155-2.33-1.086L1.105 6.373c-.31-1.009.078-1.94 1.087-2.328l8.852-2.95c.932-.31 1.94.156 2.252 1.087l2.795 8.227h3.028c.854 0 1.63.776 1.708 1.785ZM8.17 21.818l8.774-2.95c.388-.155.466-.465.31-.853l-1.63-4.89h-3.028c-1.63 0-2.252-1.474-1.709-2.716h3.883l-.854-2.561-1.01.31-.233-.698 1.01-.31-1.553-4.502c-.078-.31-.466-.466-.777-.388L2.502 5.21c-.31.077-.466.465-.388.775l1.475 4.269 1.01-.31.232.698-1.01.31L7.317 21.43c.155.388.543.465.854.388ZM5.84 10.254l-.232-.621 1.242-.388.233.698-1.242.31Zm2.33-.699-.233-.62 1.242-.389.233.699-1.242.31Zm3.416-1.707.233.62-1.242.389-.233-.699 1.242-.31Z"
        fill="#FF792E"
      />
    </SvgIcon>
  );
};
