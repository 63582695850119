export const SOC_ECO_TH_3_4 = [
  {
    id: 'nameArea',
    label: 'Наименование участка',
  },
  {
    id: 'lineLength',
    label: 'Длина линии, м.',
  },
  {
    id: 'numberCulverts',
    label: 'Число водопропускных труб',
  },
];
