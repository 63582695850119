import { useForm } from 'react-hook-form';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { Direction } from '@features/tpu-simulation-model/components/FormAttributes/Direction';
import { FormActions } from '@features/tpu-simulation-model/components/FormAttributes/FormActions';
import { Input } from '@features/tpu-simulation-model/components/FormAttributes/Input';
import { ObjectPosition } from '@features/tpu-simulation-model/components/FormAttributes/ObjectPosition';
import { ObjectSize } from '@features/tpu-simulation-model/components/FormAttributes/ObjectSize';
import { RadioList as Radio } from '@features/tpu-simulation-model/components/FormAttributes/Radio';
import { SelectOption as Select } from '@features/tpu-simulation-model/components/FormAttributes/Select';
import { TimeRange } from '@features/tpu-simulation-model/components/FormAttributes/TimeRange';
import { Title } from '@features/tpu-simulation-model/components/FormAttributes/Title';
import { useRoomsEntrancesExits } from '@features/tpu-simulation-model/hooks';
import { useFloors } from '@features/tpu-simulation-model/hooks/useFloors';
import {
  IDoor,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';
import { getAttributeOptions } from '@features/tpu-simulation-model/utils/getAttributeOptions';

interface FormProps extends IDoor {
  onUpdateAndClose: (arg: ObjectOfTheSimulationModel) => void;
  onDeleteAndClose: (arg: string) => void;
}

export const Door = ({
  onUpdateAndClose,
  onDeleteAndClose,
  ...props
}: FormProps) => {
  const { handleSubmit, formState, control } = useForm<IDoor>({
    values: props,
  });
  const roomEntrancesExitsPlatformsOptions = useRoomsEntrancesExits();
  const { selectedFloorIndex } = useFloors();

  return (
    <Stack
      padding={3}
      gap={3}
      sx={{
        overflow: 'auto',
      }}
    >
      <Title title={'Дверь'} />
      <Input<IDoor>
        control={control}
        name={'name'}
        title={'Название объекта'}
        type={'string'}
      />
      <Radio<IDoor>
        control={control}
        name={'status'}
        title={'Статус'}
        options={getAttributeOptions('status')}
      />
      <Box>
        <Typography>Направление прохода</Typography>
        <Select<IDoor>
          control={control}
          name={'firstRoom'}
          title={''}
          placeholder={'Помещение 1'}
          options={roomEntrancesExitsPlatformsOptions}
        />
        <Select<IDoor>
          control={control}
          name={'secondRoom'}
          title={''}
          placeholder={'Помещение 2'}
          options={roomEntrancesExitsPlatformsOptions}
        />
        <Direction<IDoor>
          control={control}
          name={'direction'}
          options={getAttributeOptions('direction')}
        />
      </Box>
      <TimeRange<IDoor>
        control={control}
        nameTime1={'openFrom'}
        nameTime2={'openUntil'}
      />
      <Divider />
      <ObjectPosition
        point={props.point}
        floor={selectedFloorIndex + 1}
      />
      <ObjectSize
        width={props.width}
        height={props.height}
      />
      <Divider />
      <FormActions
        onSave={handleSubmit(onUpdateAndClose)}
        onDelete={() => onDeleteAndClose(props?.uuid)}
        disabled={!formState.isValid}
      />
    </Stack>
  );
};
