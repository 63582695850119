import { useMemo } from 'react';

import {
  SxProps,
  TablePagination,
  tablePaginationClasses,
} from '@mui/material';
import { Pagination as PaginationMui } from '@mui/material';
import type { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { Theme } from '@mui/material/styles/createTheme';

interface PaginationProps {
  rowsPerPage: number;
  page: number;
  count: number;
  setPage: (arg: number) => void;
  setRowsPerPage: (arg: number) => void;
}

const ComponentActions = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onPageChange } = props;
  const numberOfPages = useMemo(
    () => Math.ceil(count / rowsPerPage),
    [count, rowsPerPage],
  );
  const currentPage = useMemo(() => page + 1, [page]);

  const onChange = (_: unknown, page: number) => {
    onPageChange(null, page);
  };

  return (
    <PaginationMui
      shape="rounded"
      count={numberOfPages}
      page={currentPage}
      onChange={onChange}
      color="primary"
      sx={{
        margin: 'auto',
      }}
    />
  );
};

const tablePaginationSx: SxProps<Theme> = {
  fontSize: theme => theme.typography.pxToRem(12),
  lineHeight: theme => theme.typography.pxToRem(16),
  minHeight: '64px',
  padding: '10px 24px !important',
  border: theme => `1px solid ${theme.palette.border.divider}`,
  borderRadius: '8px',

  [`& .${tablePaginationClasses.spacer}`]: {
    all: 'unset',
  },
  [`& .${tablePaginationClasses.displayedRows}`]: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    order: 4,
  },
  [`& .${tablePaginationClasses.toolbar}`]: {
    padding: 'unset',
    minHeight: '44px',
  },
  [`& .${tablePaginationClasses.selectLabel}`]: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
};

export const Pagination = ({
  page,
  count,
  rowsPerPage,
  setPage,
  setRowsPerPage,
}: PaginationProps) => {
  return (
    <TablePagination
      page={page}
      count={count}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[10, 20, 50]}
      component="div"
      ActionsComponent={ComponentActions}
      onPageChange={(_, pageNumber) => {
        setPage(pageNumber - 1);
      }}
      onRowsPerPageChange={e => {
        setRowsPerPage(Number(e.target.value));
      }}
      labelDisplayedRows={({ from, to, count }) =>
        `${from} - ${to} из ${count}`
      }
      labelRowsPerPage="Показывать по"
      SelectProps={{
        variant: 'outlined',
        sx: {
          backgroundColor: theme =>
            `${theme.palette.background.default} !important`,
        },
      }}
      sx={tablePaginationSx}
    />
  );
};
