import { useUnit } from 'effector-react';
import { createElement } from 'react';

import { SvgIconProps } from '@mui/material';

import {
  BankIcon,
  BankomatIcon,
  BuffetIcon,
  DoorIcon,
  ElevatorIcon,
  ExitIcon,
  InformationTableIcon,
  KioskIcon,
  LadderIcon,
  PeopleIcon,
  PlatformOuterDottedLineIcon,
  RestaurantIcon,
  RoomOuterDottedLineIcon,
  SecurityCheckpointIcon,
  ShoppingCartIcon,
  StoreIcon,
  TicketPrintingMachineIcon,
  TicketsIcon,
  ToiletIcon,
  TurnstileIcon,
  VendingIcon,
  WaitingRoomIcon,
  WallOuterDottedLineIcon,
} from '@components/icons';
import { PathOuterDottedLineIcon } from '@components/icons/PathOuterDottedLine';

import { Sidebar } from '@features/tpu-simulation-model/components/Sidebar';
import {
  ListItemAccordion,
  ListItemBase,
} from '@features/tpu-simulation-model/components/Sidebar/types';
import { useContextSimulationModelHandlers } from '@features/tpu-simulation-model/hooks';
import {
  $EditedObject,
  $ListOfObjectsSortedByType,
} from '@features/tpu-simulation-model/store';
import { $CreatedObject } from '@features/tpu-simulation-model/store/createdObject/createdObject.store';
import {
  EditableObjectType,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

export const EditableObjectsContainer = () => {
  const $listOfObjectsSortedByType = useUnit($ListOfObjectsSortedByType);
  const $createdObject = useUnit($CreatedObject);
  const $editedObject = useUnit($EditedObject);
  const {
    setCreatedObject,
    deleteObject,
    openObjectForEdit,
    deleteAllObjectsOnCurrentFloor,
  } = useContextSimulationModelHandlers();

  const onDelete = (object: ObjectOfTheSimulationModel) => () => {
    deleteObject(object.uuid);
  };

  const onOpenEditWindow = (object: ObjectOfTheSimulationModel) => () => {
    openObjectForEdit(object);
  };

  const getConstructorListItem = ({
    Icon,
    type,
    label,
  }: {
    Icon: (props: SvgIconProps) => JSX.Element;
    type: EditableObjectType;
    label: string;
  }) => {
    return {
      label,
      Icon,
      onClick: () => setCreatedObject(type),
      selected: $createdObject.type === type,
    };
  };

  const getBuiltObjectListItem = ({
    type,
    name,
    label,
    Icon,
  }: {
    label: string;
    Icon: (props: SvgIconProps) => JSX.Element;
    type: EditableObjectType;
    name: string;
  }): ListItemBase | ListItemAccordion => {
    return {
      label,
      Icon,
      children: $listOfObjectsSortedByType?.[type]?.map(
        (objectOfTheSimulationModel, index) => ({
          label: `${name} ${index + 1}`,
          key: objectOfTheSimulationModel.uuid,
          selected:
            objectOfTheSimulationModel.uuid === $editedObject.data?.uuid,
          onClick: onOpenEditWindow(objectOfTheSimulationModel),
          onDelete: onDelete(objectOfTheSimulationModel),
        }),
      ),
    };
  };

  const builtObjectsConfig: ListItemAccordion[] = [
    {
      label: 'Пространственно планировочные',
      withSeparator: true,
      children: [
        {
          Icon: RoomOuterDottedLineIcon,
          label: 'Помещения',
          type: EditableObjectType.Room,
          name: 'Помещение',
        },
        {
          Icon: WallOuterDottedLineIcon,
          label: 'Стены',
          type: EditableObjectType.Wall,
          name: 'Стена',
        },
        {
          label: 'Лестницы',
          Icon: LadderIcon,
          type: EditableObjectType.Ladder,
          name: 'Лестница',
        },
        {
          Icon: PlatformOuterDottedLineIcon,
          label: 'Платформы',
          type: EditableObjectType.Platform,
          name: 'Платформа',
        },
        {
          label: 'Двери',
          Icon: (props: SvgIconProps) =>
            createElement(DoorIcon, {
              ...props,
              sx: { ...props.sx, color: '#3679EA' },
            }),
          type: EditableObjectType.Door,
          name: 'Дверь',
        },
        {
          label: 'Лифты',
          Icon: ElevatorIcon,
          type: EditableObjectType.Elevator,
          name: 'Лифт',
        },
      ].map(getBuiltObjectListItem),
    },
    {
      label: 'Объекты обслуживания пассажиров',
      withSeparator: true,
      children: [
        {
          label: 'Билетные кассы',
          Icon: TicketsIcon,
          type: EditableObjectType.TicketOffice,
          name: 'Билетная касса',
        },
        {
          label: 'Билетопечатающие аппараты (БПА)',
          Icon: TicketPrintingMachineIcon,
          type: EditableObjectType.TicketPrintingMachine,
          name: 'Билетопечатающий аппарат (БПА)',
        },
        {
          label: 'Турникеты',
          Icon: TurnstileIcon,
          type: EditableObjectType.Turnstile,
          name: 'Турникет',
        },
        {
          label: 'Пункты досмотра',
          Icon: SecurityCheckpointIcon,
          type: EditableObjectType.SecurityCheckpoint,
          name: 'Пункт досмотра',
        },
        {
          label: 'Информационные табло',
          Icon: InformationTableIcon,
          type: EditableObjectType.InformationTable,
          name: 'Информационное табло',
        },
        {
          label: 'Туалеты',
          Icon: ToiletIcon,
          type: EditableObjectType.Toilet,
          name: 'Туалет',
        },
        {
          label: 'Залы ожидания',
          Icon: WaitingRoomIcon,
          type: EditableObjectType.Waiting,
          name: 'Зал ожидания',
        },
      ].map(getBuiltObjectListItem),
    },
    {
      label: 'Коммерческие объекты',
      withSeparator: true,
      children: [
        {
          Icon: RestaurantIcon,
          type: EditableObjectType.Restaurant,
          label: 'Рестораны',
          name: 'Ресторан',
        },
        {
          Icon: BuffetIcon,
          type: EditableObjectType.Buffet,
          label: 'Буфеты',
          name: 'Буфет',
        },
        {
          Icon: VendingIcon,
          type: EditableObjectType.VendingMachine,
          label: 'Вендинговые аппараты',
          name: 'Вендинговый аппарат',
        },
        {
          Icon: StoreIcon,
          type: EditableObjectType.Store,
          label: 'Магазины',
          name: 'Магазин',
        },
        {
          Icon: KioskIcon,
          type: EditableObjectType.Kiosk,
          label: 'Киоски',
          name: 'Киоск',
        },
        {
          Icon: BankIcon,
          type: EditableObjectType.Bank,
          label: 'Банки',
          name: 'Банк',
        },
        {
          Icon: BankomatIcon,
          type: EditableObjectType.Bankomat,
          label: 'Банкоматы',
          name: 'Банкомат',
        },
        {
          Icon: ShoppingCartIcon,
          type: EditableObjectType.Other,
          label: 'Прочее',
          name: 'Прочее',
        },
      ].map(getBuiltObjectListItem),
    },
    {
      label: 'Управляющие объекты',
      withSeparator: true,
      children: [
        {
          label: 'Пути',
          Icon: PathOuterDottedLineIcon,
          type: EditableObjectType.Path,
          name: 'Путь',
        },
        {
          label: 'Генераторы пассажиров (входы)',
          Icon: PeopleIcon,
          type: EditableObjectType.PassengerGenerator,
          name: 'Вход',
        },
        {
          label: 'Выход',
          Icon: ExitIcon,
          type: EditableObjectType.Exit,
          name: 'Выход',
        },
      ].map(getBuiltObjectListItem),
    },
  ];

  const allObjectsConfig: ListItemAccordion[] = [
    {
      label: 'Пространственно планировочные',
      withSeparator: true,
      children: [
        {
          Icon: RoomOuterDottedLineIcon,
          type: EditableObjectType.Room,
          label: 'Построить помещение',
        },
        {
          Icon: WallOuterDottedLineIcon,
          type: EditableObjectType.Wall,
          label: 'Построить стену',
        },
        {
          Icon: LadderIcon,
          type: EditableObjectType.Ladder,
          label: 'Построить лестницу',
        },
        {
          Icon: PlatformOuterDottedLineIcon,
          type: EditableObjectType.Platform,
          label: 'Построить платформу',
        },
        {
          Icon: (props: SvgIconProps) =>
            createElement(DoorIcon, {
              ...props,
              sx: { ...props.sx, color: '#3679EA' },
            }),
          type: EditableObjectType.Door,
          label: 'Двери',
        },
        {
          Icon: ElevatorIcon,
          type: EditableObjectType.Elevator,
          label: 'Лифт',
        },
      ].map(getConstructorListItem),
    },
    {
      label: 'Объекты обслуживания пассажиров',
      withSeparator: true,
      children: [
        {
          label: 'Билетная касса',
          type: EditableObjectType.TicketOffice,
          Icon: TicketsIcon,
        },
        {
          label: 'Билетопечатающий аппарат (БПА)',
          type: EditableObjectType.TicketPrintingMachine,
          Icon: TicketPrintingMachineIcon,
        },
        {
          label: 'Турникет',
          type: EditableObjectType.Turnstile,
          Icon: TurnstileIcon,
        },
        {
          label: 'Пункт досмотра',
          type: EditableObjectType.SecurityCheckpoint,
          Icon: SecurityCheckpointIcon,
        },
        {
          label: 'Информационное табло',
          type: EditableObjectType.InformationTable,
          Icon: InformationTableIcon,
        },
        {
          label: 'Туалет',
          type: EditableObjectType.Toilet,
          Icon: ToiletIcon,
        },
        {
          label: 'Зал ожидания',
          type: EditableObjectType.Waiting,
          Icon: WaitingRoomIcon,
        },
      ].map(getConstructorListItem),
    },
    {
      label: 'Коммерческие объекты',
      withSeparator: true,
      children: [
        {
          Icon: RestaurantIcon,
          type: EditableObjectType.Restaurant,
          label: 'Ресторан',
        },
        {
          Icon: BuffetIcon,
          type: EditableObjectType.Buffet,
          label: 'Буфет',
        },
        {
          Icon: VendingIcon,
          type: EditableObjectType.VendingMachine,
          label: 'Вендинговый аппарат',
        },
        {
          Icon: StoreIcon,
          type: EditableObjectType.Store,
          label: 'Магазин',
        },
        {
          Icon: KioskIcon,
          type: EditableObjectType.Kiosk,
          label: 'Киоск',
        },
        {
          Icon: BankIcon,
          type: EditableObjectType.Bank,
          label: 'Банк',
        },
        {
          Icon: BankomatIcon,
          type: EditableObjectType.Bankomat,
          label: 'Банкомат',
        },
        {
          Icon: ShoppingCartIcon,
          type: EditableObjectType.Other,
          label: 'Прочее',
        },
      ].map(getConstructorListItem),
    },
    {
      label: 'Управляющие объекты',
      children: [
        {
          Icon: PathOuterDottedLineIcon,
          type: EditableObjectType.Path,
          label: 'Построить путь',
        },
        {
          Icon: PeopleIcon,
          type: EditableObjectType.PassengerGenerator,
          label: 'Генератор пассажиров (вход)',
        },
        {
          Icon: ExitIcon,
          type: EditableObjectType.Exit,
          label: 'Выход',
        },
      ].map(getConstructorListItem),
    },
  ];

  return (
    <Sidebar
      builtObjectsConfig={builtObjectsConfig}
      allObjectsConfig={allObjectsConfig}
      onDeleteAll={deleteAllObjectsOnCurrentFloor}
    />
  );
};
