import { Button, ButtonProps, Tooltip } from '@mui/material';

import { DragDropIcon } from '@components/icons';

interface DragButtonProps {
  isHidden: boolean;
  buttonProps: ButtonProps;
}

export const DragButton = ({ isHidden, buttonProps }: DragButtonProps) => {
  const sxProp = { height: '24px', minWidth: 'unset', ...buttonProps.sx };
  if (isHidden) return null;

  return (
    <Tooltip title={'Переместить'}>
      <Button
        {...buttonProps}
        variant={'text'}
        sx={sxProp}
      >
        <DragDropIcon />
      </Button>
    </Tooltip>
  );
};
