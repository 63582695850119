import {
  FilterRegionsApi,
  resetAllFilters,
  setFiltersPassTraffic,
  setPassengerTraffic,
} from '@features/passenger-traffic/stores';
import { forward } from 'effector';

forward({
  from: resetAllFilters,
  to: FilterRegionsApi.clearSelectedRegions,
});

forward({
  from: setPassengerTraffic,
  to: setFiltersPassTraffic,
});
