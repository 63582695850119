import { isNonRenderableObject } from './assistantFunctions';

import { EditableObjectType } from '@features/tpu-simulation-model/types/simultaionModel';

import {
  DataForCommercialObjects,
  ITableRow,
} from '../stores/chartsCommercialObjects/chartsCommercialObjects.store';

export const nonRenderableObjectTypes = [
  EditableObjectType.TicketOffice,
  EditableObjectType.TicketPrintingMachine,
  EditableObjectType.InformationTable,
  EditableObjectType.Toilet,
];

export const generateCommercialSquareList = (
  items: DataForCommercialObjects[],
): ITableRow[] => {
  if (items?.length) {
    const biggestQuantity = Math.max(
      ...items.map(item => item.cumulativeAgentsQuantity),
    );
    const biggestRevenue = Math.max(
      ...items.map(item => item.cumulativeRevenue),
    );

    return items?.map(item => ({
      icon: item.type,
      name: item.name,
      square: item.square,
      revenuePerSqMeter: isNonRenderableObject(item)
        ? null
        : Math.round(item.cumulativeRevenue / item.square),
      revenuePerSqMeterPercent: isNonRenderableObject(item)
        ? null
        : Math.round((item.cumulativeRevenue / biggestRevenue) * 100),
      agentsQuantity: item.cumulativeAgentsQuantity,
      agentsQuantityPercent: Math.round(
        (item.cumulativeAgentsQuantity / biggestQuantity) * 100,
      ),
      revenue: isNonRenderableObject(item) ? null : item.cumulativeRevenue,
      revenuePercent: isNonRenderableObject(item)
        ? null
        : Math.round((item.cumulativeRevenue / biggestRevenue) * 100),
    }));
  }

  return [];
};
