import { PropsWithChildren } from 'react';

import { IconButton } from '@mui/material';

type ButtonPaginationType = {
  isSelected: boolean;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
  ) => void;
} & PropsWithChildren;

export const ButtonPagination = (props: ButtonPaginationType) => {
  const { isSelected, onClick, children } = props;

  return (
    <IconButton
      sx={{
        width: '32px',
        height: '32px',
        backgroundColor: isSelected ? 'primary.main' : 'white',
        borderRadius: 1,
        fontSize: theme => theme.typography.pxToRem(12),
        color: isSelected ? 'white' : 'primary.main',
        '&:hover': {
          backgroundColor: 'primary.dark',
          color: 'white',
        },
      }}
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
};
