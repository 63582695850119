import { useState } from 'react';

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

const listItems = [
  {
    label: 'Москва',
    id: 1,
  },
  {
    label: 'Питер',
    id: 2,
  },
  {
    label: 'Казань',
    id: 3,
  },
  {
    label: 'Тверь',
    id: 4,
  },
];

export const UIKit = () => {
  const [checkbox, setCheckbox] = useState(false);
  const [radio, setRadio] = useState('');
  const [selectedCity, setSelectedCity] = useState([2]);
  const [visibleDialog, setVisibleDialog] = useState(false);

  const onChangeSelectedCity = (value: number) => {
    const currentIndex = selectedCity.indexOf(value);
    const newChecked = [...selectedCity];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedCity(newChecked);
  };

  return (
    <>
      <Box
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid
          display={'grid'}
          gridTemplateColumns={'repeat(4, min-content)'}
          gap={4}
          p={2}
        >
          <Button
            color={'primary'}
            variant={'contained'}
          >
            Кнопка
          </Button>

          <Button variant={'outlined'}>Кнопка</Button>

          <Button
            color={'primary'}
            variant={'contained'}
            disabled
          >
            Кнопка
          </Button>

          <Button
            variant={'outlined'}
            disabled
          >
            Кнопка
          </Button>

          <Button
            color={'primary'}
            variant={'contained'}
            size="small"
          >
            Кнопка
          </Button>

          <Button
            variant={'outlined'}
            size="small"
          >
            Кнопка
          </Button>

          <Button
            color={'primary'}
            variant={'contained'}
            disabled
            size="small"
          >
            Кнопка
          </Button>

          <Button
            variant={'outlined'}
            disabled
            size="small"
          >
            Кнопка
          </Button>

          <Button
            variant={'contained'}
            color={'secondary'}
          >
            Кнопка
          </Button>

          <Button
            variant={'contained'}
            color={'secondary'}
            size="small"
          >
            Кнопка
          </Button>

          <Button
            variant={'contained'}
            color={'secondary'}
            disabled
          >
            Кнопка
          </Button>

          <Button
            variant={'contained'}
            color={'secondary'}
            disabled
            size="small"
          >
            Кнопка
          </Button>

          <Button
            variant={'text'}
            color={'primary'}
          >
            Кнопка
          </Button>

          <Button
            variant={'text'}
            color={'primary'}
            size="small"
          >
            Кнопка
          </Button>

          <Button
            variant={'text'}
            color={'primary'}
            disabled
          >
            Кнопка
          </Button>

          <Button
            variant={'text'}
            color={'primary'}
            disabled
            size="small"
          >
            Кнопка
          </Button>
        </Grid>

        <Grid
          display={'grid'}
          gridTemplateColumns={'repeat(3, 1fr)'}
          gap={4}
          p={2}
        >
          <OutlinedInput placeholder="Please enter text" />

          <OutlinedInput
            placeholder="Please enter text"
            error
          />

          <OutlinedInput
            placeholder="Please enter text"
            color="warning"
          />
        </Grid>

        <Grid
          display={'grid'}
          gridTemplateColumns={'repeat(3, 1fr)'}
          p={2}
        >
          <Autocomplete
            freeSolo
            disableClearable
            options={[
              { label: 'Вариант поискового ответа 1', id: 1 },
              { label: 'Вариант поискового ответа 2', id: 2 },
              { label: 'Вариант поискового ответа 3', id: 3 },
            ]}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Найти"
                size="medium"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
            renderOption={(props, option) => (
              <ListItem {...props}>{option.label}</ListItem>
            )}
          />
        </Grid>

        <Grid
          display={'grid'}
          gridTemplateColumns={'repeat(4, 1fr)'}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={checkbox}
                color="error"
                onChange={() => setCheckbox(prev => !prev)}
              />
            }
            label={'checkbox'}
          />

          <FormControlLabel
            control={<Checkbox checked={true} />}
            label={'checkbox'}
            disabled
          />
        </Grid>

        <Grid
          display={'grid'}
          gridTemplateColumns={'repeat(4, 1fr)'}
        >
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={radio}
            onChange={e => setRadio(e.target.value)}
          >
            <FormControlLabel
              value="female"
              control={<Radio color="error" />}
              label="Female"
            />
            <FormControlLabel
              value="male"
              control={<Radio color="error" />}
              label="Male"
            />
          </RadioGroup>
        </Grid>

        <Stack spacing={2}>
          <Alert
            variant="filled"
            severity="error"
            icon={false}
          >
            Ошибка сохранения
          </Alert>
          <Alert
            variant="filled"
            severity="success"
            icon={false}
          >
            Изменения сохранены
          </Alert>
        </Stack>

        <Grid
          display={'grid'}
          gridTemplateColumns={'repeat(4, 1fr)'}
          m={2}
        >
          <Slider
            defaultValue={70}
            color="secondary"
          />
        </Grid>

        <Grid
          display={'grid'}
          gridTemplateColumns={'repeat(4, 1fr)'}
        >
          <List>
            {listItems.map(item => (
              <ListItem key={item.id}>
                <ListItemButton
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}
                  onClick={() => onChangeSelectedCity(item.id)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedCity.indexOf(item.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      color="secondary"
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={item.id.toString()}
                    primary={item.label}
                  />
                  <ListItemText>{2000 + item.id}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid p={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setVisibleDialog(prev => !prev)}
          >
            Dialog
          </Button>
          <Dialog open={visibleDialog}>
            <DialogTitle>Удалить расчет</DialogTitle>

            <DialogContent>
              <Typography gutterBottom>Расчет удален.</Typography>
            </DialogContent>

            <Divider />

            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setVisibleDialog(prev => !prev)}
              >
                Закрыть
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>

        <Grid p={2}>
          <Link href="#">Link</Link>
        </Grid>

        <Grid
          display={'flex'}
          p={2}
          gap={4}
        >
          <Chip
            label="Chip"
            color="success"
          />
          <Chip
            label="Chip"
            color="warning"
          />
          <Chip
            label="Chip"
            color="error"
          />
        </Grid>
      </Box>
    </>
  );
};
