import { useUnit } from 'effector-react';
import { SyntheticEvent, useCallback, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { CircleIcon } from '@components/Map/CircleIcon';
import { TrainIcon } from '@components/icons';

import {
  $SearchOptions,
  SearchOption,
  SearchOptionsApi,
  getSearchOptions,
} from '@features/ag-forecast/stores/lists/searchOptions';
import {
  $SearchInfra,
  SearchInfraApi,
} from '@features/ag-forecast/stores/map/searchInfra';

export const SearchInput = () => {
  const [inputValue, setInputValue] = useState('');
  const { options } = useUnit($SearchOptions);
  const { setOptions } = useUnit(SearchOptionsApi);
  const { selectedOption } = useUnit($SearchInfra);
  const { setSelectedOption } = useUnit(SearchInfraApi);

  const handleFocus = () => {
    setInputValue('');
    setOptions([]);
  };

  const handleInputChange = useCallback(
    async (_event: SyntheticEvent, value: string) => {
      setInputValue(value);

      if (!value) return setOptions([]);

      getSearchOptions(value);
    },
    [],
  );

  const onSelectHandler = useCallback(
    (_event: SyntheticEvent, option: NonNullable<string | SearchOption>) => {
      if (typeof option !== 'object') return;

      setSelectedOption(option);
    },
    [],
  );

  return (
    <Box sx={{ width: '460px', height: '44px' }}>
      <Autocomplete
        freeSolo
        fullWidth
        id="free-solo-2-demo"
        disableClearable
        options={options}
        value={selectedOption || ''}
        onInputChange={handleInputChange}
        onChange={onSelectHandler}
        inputValue={inputValue} // Управление состоянием ввода
        getOptionLabel={option =>
          typeof option === 'object' ? option.name : option
        }
        renderOption={(props, option) => (
          <Stack
            direction="row"
            alignItems="center"
            gap={0}
            ml={1}
            key={`${option.type}-${option.value}`}
          >
            {option!.type === 'station' && (
              <TrainIcon
                sx={{
                  color: theme => theme.palette.customs.train,
                  width: theme => theme.typography.pxToRem(20),
                  justifySelf: 'center',
                }}
              />
            )}

            {option.type === 'distict' && (
              <CircleIcon
                color={'transportAreaInspectorBoundaries'}
                sx={{ marginLeft: '5px' }}
              />
            )}

            <Typography {...props}>{option.name}</Typography>
          </Stack>
        )}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Найти"
            onFocus={handleFocus} // Обработчик фокуса на поле ввода
            size="medium"
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: (
                <InputAdornment
                  position="end"
                  onChange={handleFocus}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};
