import { IQuery, IQueryFindRegionArgs } from '@api/gql/types';
import { createApi, createEffect, createStore } from 'effector';

// import { initLayersVisible } from '@features/pt-forecast-new/stores/map/layersVisible';

export enum EFilterMapLayout {
  Infrastructure = 'Infrastructure',
  PassengerFlows = 'PassengerFlows',
}

export enum directionPoints {
  from,
  to,
}

const initState: FilterMapStore = {
  layout: EFilterMapLayout.PassengerFlows,
  auto: true,
  bus: true,
  avia: true,
  expressTrain: true,
  suburbanTrain: true,
  dayTrain: true,
  nightTrain: true,
  multimodal: true,
  intermediateCorrespondents: false,
  transportRegions: true,
  // railwayRoot: true,
  // autoRoot: true,
  actual: true,
  planned: false,
  fromSelectedRegion: null,
  toSelectedRegion: null,
  directionPointSelected: null,
};

export const $FilterMap = createStore<FilterMapStore>(initState);

export const FilterMapApi = createApi($FilterMap, {
  setLayout: (store, payload: EFilterMapLayout) => ({
    ...store,
    layout: payload,
  }),
  setFilters: (store, payload: Partial<FilterMapStore>) => ({
    ...store,
    ...payload,
  }),
  setRegionFrom: (store, payload: RegionOptionType | null) => ({
    ...store,
    fromSelectedRegion: payload,
  }),
  setRegionTo: (store, payload: RegionOptionType | null) => ({
    ...store,
    toSelectedRegion: payload,
  }),
  clearRegions: store => ({
    ...store,
    fromSelectedRegion: null,
    toSelectedRegion: null,
  }),
  setDirectionPoint: (store, payload: directionPoints | null) => ({
    ...store,
    directionPointSelected: payload,
  }),
  reset: store => ({
    ...initState,
    layout: store.layout,
  }),
});

export const fetchTransportDistrictByPointFx = createEffect<
  IQueryFindRegionArgs,
  IQuery['findRegion']
>();

export type VehiclesFilters = {
  /** Авиа */
  avia: boolean;
  /** Личный транспорт */
  auto: boolean;
  /** Автобусы */
  bus: boolean;
  /** Дневные поезда */
  dayTrain: boolean;
  /** Ночные поезда */
  nightTrain: boolean;
  /** Пригородные поезда */
  suburbanTrain: boolean;
  /** ВСМ поезда */
  expressTrain: boolean;
};

export type FilterMapStore = VehiclesFilters & {
  /** тип отображения */
  layout: EFilterMapLayout;
  /** Отображение мультимодальных поездок */
  multimodal: boolean;
  /** Отображение актуальной инфраструктуры на выбранный год */
  actual: boolean;
  /** Отображение планируемой инфраструктуры выше выбранного года */
  planned: boolean;
  /** Отображение транспортных регионов */
  transportRegions: boolean;
  /** Отображение промежуточных корреспонденций */
  intermediateCorrespondents: boolean;
  /** Выбранный регион отправления */
  fromSelectedRegion: RegionOptionType | null;
  /** Выбранный регион прибытия */
  toSelectedRegion: RegionOptionType | null;
  /** Флаг режима постановки поинта курсором */
  directionPointSelected: directionPoints | null;
};

export type RegionOptionType = {
  id: string;
  name: string;
};
