import { Coordinate } from 'ol/coordinate';
import { fromLonLat } from 'ol/proj';

type MultiCoordinate3 = Array<Array<Coordinate>>;
export interface DeepArray<T> extends Array<T | DeepArray<T>> {}

const isCoordinate = (coordinates: Array<unknown>): coordinates is Coordinate =>
  Array.isArray(coordinates) &&
  coordinates.every(val => typeof val === 'number');

export const recursivelyFromLonLat = (
  arr: DeepArray<Coordinate> | number[][],
): MultiCoordinate3 => {
  const result = [];
  for (const item of arr) {
    if (Array.isArray(item) && Array.isArray(item[0])) {
      result.push(recursivelyFromLonLat(item as Array<Coordinate>));
    } else if (isCoordinate(item)) {
      result.push(fromLonLat(item));
    }
  }
  return result as unknown as MultiCoordinate3;
};
