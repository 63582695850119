import { useUnit } from 'effector-react';
import { useCallback, useState } from 'react';

import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';

import { CashedField } from '@widgets/widget-routes-params/components/CashedField/CashedField';
import { bodyRows } from '@widgets/widget-routes-params/configs/bodyRows';
import { titleVehicleTypes } from '@widgets/widget-routes-params/configs/titleVehicleTypes';
import {
  $DirectionsParamsTable,
  DirectionsParamsTableApi,
} from '@widgets/widget-routes-params/stores/list/directionsParamsTable/store';

export const TableRoutesParams = () => {
  const [selectedCell, setSelectedCell] = useState<string | null>(null);
  const { directions } = useUnit($DirectionsParamsTable);
  const { updateAllParamsDirections } = useUnit(DirectionsParamsTableApi);

  const createCells = (row: BodyRowsType) => {
    if (!row) return;
    const cells: CellObjectType[] = [];

    for (const bodyRow of bodyRows) {
      for (const [key, value] of Object.entries(row)) {
        if (bodyRow === key) {
          cells.push({
            id: row.id as string,
            key,
            label: value,
          });
          break;
        }
      }
    }

    return cells.map((cell, i) => {
      const cellId = `${cell.id}-${cell.key}`;
      const value =
        cell.label === null ||
        cell.label === undefined ||
        cell.label === '' ||
        cell.label === 0
          ? '—'
          : typeof cell.label === 'number'
          ? cell.label.toString().replace('.', ',')
          : cell.label;

      return (
        <TableCell
          key={i}
          id={cellId}
          align={i < 4 ? 'left' : 'center'}
          className={selectedCell === cellId ? 'selected' : ''}
          sx={{
            ...rowStyleSX,
            bgcolor: theme =>
              i < 4
                ? theme.palette.border.divider
                : row[
                    'isError' +
                      cell.key.charAt(0).toUpperCase() +
                      cell.key.slice(1)
                  ]
                ? theme.palette.secondary.light
                : 'initial',
            position: 'relative',
            ['&:last-of-type']: {
              borderRight: '1px solid rgba(224, 224, 224, 1)',
            },
            '&.selected': {
              bgcolor: 'customs.bgHover',
            },
          }}
          onClick={i > 3 ? handleSelectCell : undefined}
        >
          {selectedCell === cellId ? (
            <CashedField
              id={cell.id}
              itemKey={cell.key}
              value={
                cell.label === undefined ||
                cell.label === null ||
                cell.label === 0
                  ? ''
                  : cell.label
              }
              getValue={handleUpdateValue}
              onBlur={onBlurField}
            />
          ) : (
            value
          )}
        </TableCell>
      );
    });
  };

  const handleSelectCell = useCallback(
    (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
      setSelectedCell(e.currentTarget.id);
    },
    [],
  );

  const onBlurField = useCallback(() => setSelectedCell(null), []);

  const handleUpdateValue = useCallback(
    (
      value: CellObjectType['label'],
      key: CellObjectType['key'],
      id: CellObjectType['id'],
    ) => {
      const graph = directions.find(item => item.id === id);
      const newValue =
        value === null || value === undefined || value === '' ? 0 : value;

      graph![key] = newValue;
      graph!['isError' + key.charAt(0).toUpperCase() + key.slice(1)] = false;
      updateAllParamsDirections(directions);
    },
    [directions, updateAllParamsDirections],
  );

  const repeatElements = (arr: string[], times: number) => {
    return Array(times).fill(arr).flat();
  };

  return (
    <TableContainer
      sx={{
        maxHeight: '100%',
      }}
    >
      <Table
        size="medium"
        aria-labelledby="tableTitle"
        sx={{
          width: '100%',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              rowSpan={2}
              sx={{
                ...THCellStyleSX,
                borderTopLeftRadius: theme => theme.shape.borderRadius * 2,
              }}
            >
              Линия
            </TableCell>

            <TableCell
              rowSpan={2}
              sx={THCellStyleSX}
            >
              Год
            </TableCell>

            <TableCell
              rowSpan={2}
              colSpan={2}
              sx={THCellStyleSX}
            >
              Корреспонденция
            </TableCell>

            <TableCell
              colSpan={4}
              align="center"
              sx={THCellStyleSX}
            >
              Время в пути, ч.
            </TableCell>

            <TableCell
              colSpan={4}
              align="center"
              sx={THCellStyleSX}
            >
              Стоимость проезда, руб.
            </TableCell>

            <TableCell
              colSpan={4}
              align="center"
              sx={{
                ...THCellStyleSX,
                borderTopRightRadius: theme => theme.shape.borderRadius * 2,
              }}
            >
              Частота сообщения, пар поездов в сутки
            </TableCell>
          </TableRow>

          <TableRow>
            {repeatElements(titleVehicleTypes, 3).map((item, index) => (
              <TableCell
                key={index}
                align="center"
                sx={{
                  ...THCellStyleSX,
                  borderLeft: '1px solid rgba(224, 224, 224, 1)',
                }}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {directions.map((row, i) => (
            <TableRow key={i}>{createCells(row as BodyRowsType)}</TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type BodyRowsType = Record<string, string | number | null | undefined>;

export type CellObjectType = {
  id: string;
  key: string;
  label: string | number | null | undefined;
};

const rowStyleSX: SxProps<Theme> = {
  fontSize: theme => theme.typography.pxToRem(12),
  ['&:not(:first-of-type)']: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
};

const THCellStyleSX: SxProps<Theme> = {
  fontSize: theme => theme.typography.pxToRem(12),
  bgcolor: theme => theme.palette.border.divider,
  ['&:not(:first-of-type)']: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  lineHeight: 1.25,
  position: 'sticky',
  top: 0,
  zIndex: 1,
};
