import { apiClient } from '@api/client';
import {
  Exact,
  IAgglomerationType,
  ICopyProjectMutation,
  IDataFileInfoType,
  IDataFileTypeEnum,
  IHubCreationTypeEnum,
  IHubProjectStateChoices,
  IHubType,
  IMutation,
  IProjectType,
  IQuery,
  IUpdateProjectInput,
  Maybe,
} from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';
import { v4 as uuid } from 'uuid';

import { TPU_CLIENT_NAME } from '@constants/api';

import {
  DEFAULT_DATA_FILES_STATE,
  DEFAULT_FLOOR_FILES_STATE,
  MAX_FLOOR_FILES,
} from '@features/tpu-project-registry/constants';
import {
  $FileTemplates,
  $Form,
  $IsButtonSaveDisabled,
  $IsOpenForm,
  addFloorFileFieldFn,
  changeFloorFilesOrderFn,
  checkIsNameUniqueFx,
  createDraftProjectFx,
  deleteDataFileFn,
  deleteFloorFileFn,
  editProjectFn,
  getFileTemplatesFx,
  getProjectByIdFx,
  openProjectForEditFn,
  resetFormStateFn,
  resetProjectDataFn,
  saveProjectFn,
  setAgglomerationFn,
  setDataFileFn,
  setFloorFileDescriptionFn,
  setFloorFileFn,
  setHubFn,
  setHubSearchFn,
  setNameFn,
  updateProjectFx,
} from '@features/tpu-project-registry/stores/form/form.store';
import { removeProjectFx } from '@features/tpu-project-registry/stores/modal-delete-project/modal-delete-project.store';
import {
  copyProjectFx,
  getListOfProjectsFn,
} from '@features/tpu-project-registry/stores/project-registry/project-regisrty.store';
import { IFloorFile } from '@features/tpu-project-registry/types/form';

$Form
  .reset(resetFormStateFn)
  .on(addFloorFileFieldFn, state => {
    const floorFiles = [...state.floorFiles];

    if (floorFiles.length < MAX_FLOOR_FILES) {
      floorFiles.push({
        description: `${floorFiles.length + 1} этаж`,
        id: '',
        uuid: uuid(),
      });
    }

    return { ...state, floorFiles };
  })
  .on(setFloorFileDescriptionFn, (state, payload) => {
    const { description, index } = payload;
    const floorFiles = [...state.floorFiles];

    floorFiles[index] = { ...floorFiles[index], description };

    return { ...state, floorFiles };
  })
  .on(setFloorFileFn, (state, payload) => {
    const { data, index } = payload;
    const floorFiles = [...state.floorFiles];
    floorFiles[index] = { ...floorFiles[index], ...data };

    return { ...state, floorFiles };
  })
  .on(setNameFn, (state, payload) => {
    return { ...state, name: payload };
  })
  .on(setHubFn, (state, payload) => {
    return {
      ...state,
      hub: payload,
      agglomeration: payload?.agglomeration ?? state.agglomeration,
    };
  })
  .on(setAgglomerationFn, (state, payload) => {
    return { ...state, agglomeration: payload };
  })
  .on(createDraftProjectFx.done, (_, payload) => {
    const { result } = payload;
    return {
      projectId: result ?? '',
      hub: null,
      floorFiles: DEFAULT_FLOOR_FILES_STATE,
      dataFiles: DEFAULT_DATA_FILES_STATE,
      name: '',
      hubSearch: '',
      state: IHubProjectStateChoices.Draft,
      agglomeration: null,
    };
  })
  .on(setDataFileFn, (state, payload) => {
    if (!payload || !payload.dataType) return;

    const { dataType } = payload;
    const dataFiles = { ...state.dataFiles };
    dataFiles[dataType] = payload;

    return { ...state, dataFiles };
  })
  .on(openProjectForEditFn, (_, payload) => {
    const dataFiles: Record<IDataFileTypeEnum, IDataFileInfoType | null> = {
      ...DEFAULT_DATA_FILES_STATE,
    };
    payload.dataFiles.forEach(fileInfo => {
      if (fileInfo.dataType) {
        dataFiles[fileInfo.dataType] = fileInfo;
      }
    });

    const returnData: {
      hub: IHubType | null;
      hubSearch: string;
      agglomeration: IAgglomerationType | null;
      floorFiles: Array<IFloorFile>;
      name: string;
      state: IHubProjectStateChoices;
      projectId: string;
      dataFiles: Record<IDataFileTypeEnum, IDataFileInfoType | null>;
    } = {
      projectId: payload.id,
      name: payload.name ?? '',
      state: payload.state,
      floorFiles: payload.floorFiles.map(item => ({ ...item, uuid: uuid() })),
      hubSearch: payload.hub?.name ?? '',
      hub: payload.hub,
      agglomeration: payload.hub?.agglomeration ?? null,
      dataFiles,
    };
    return returnData;
  })
  .on(deleteFloorFileFn, (state, index) => {
    const floorFiles = [...state.floorFiles];
    floorFiles[index] = {
      id: '',
      uuid: uuid(),
      description: floorFiles[index].description,
    };

    return { ...state, floorFiles };
  })
  .on(deleteDataFileFn, (state, type) => {
    state.dataFiles[type] = null;

    return {
      ...state,
    };
  })
  .on(resetProjectDataFn, form => {
    return {
      projectId: form.projectId,
      hub: null,
      floorFiles: DEFAULT_FLOOR_FILES_STATE,
      dataFiles: DEFAULT_DATA_FILES_STATE,
      name: '',
      hubSearch: '',
      state: form.state,
      agglomeration: null,
    };
  })
  .on(setHubSearchFn, (state, payload) => {
    return { ...state, hubSearch: payload };
  })
  .on(changeFloorFilesOrderFn, (state, payload) => {
    const floorFiles = [...state.floorFiles];
    let transferFile: null | IFloorFile = null;
    let transferIndex: null | number = null;
    let catchFile: null | IFloorFile = null;
    let catchIndex: null | number = null;

    floorFiles.forEach((file, index) => {
      if (file.uuid === payload.transferId) {
        transferFile = file;
        transferIndex = index;
      }
      if (file.uuid === payload.catchId) {
        catchFile = file;
        catchIndex = index;
      }
    });

    if (
      transferFile &&
      catchFile &&
      transferIndex !== null &&
      catchIndex !== null
    ) {
      floorFiles[transferIndex] = catchFile;
      floorFiles[catchIndex] = transferFile;
    }

    return { ...state, floorFiles };
  });

$FileTemplates.on(getFileTemplatesFx.done, (_, { result }) => result);

sample({
  source: $Form,
  fn: sourceData => !!sourceData.projectId.length,
  target: $IsOpenForm,
});

sample({
  clock: updateProjectFx.done,
  source: $Form,
  filter: (_, { result }) => result,
  fn: sourceData => sourceData.projectId,
  target: [getListOfProjectsFn, getProjectByIdFx],
});

sample({
  clock: saveProjectFn,
  source: $Form,
  fn: sourceData => ({
    excludeProjectId: sourceData.projectId,
    name: sourceData.name,
  }),
  target: checkIsNameUniqueFx,
});

sample({
  clock: checkIsNameUniqueFx.done,
  source: $Form,
  filter: (_, { result }) => result,
  fn: sourceData => sourceData,
  target: updateProjectFx,
});

sample({
  clock: getProjectByIdFx.doneData,
  filter: (clockData): clockData is IProjectType => !!clockData,
  target: openProjectForEditFn,
});

sample({
  clock: copyProjectFx.doneData,
  filter: (
    clockData: Maybe<ICopyProjectMutation> | undefined,
  ): clockData is Omit<Exact<ICopyProjectMutation>, 'newProject'> & {
    newProject: IProjectType;
  } => !!(clockData && clockData.newProject),
  fn: clockData => clockData.newProject,
  target: openProjectForEditFn,
});

sample({
  source: $Form,
  fn: sourceData => {
    const isFormHasAtLeastOneFloorFile = sourceData.floorFiles.some(
      ({ id }) => !!id,
    );
    return !(
      sourceData.name &&
      (sourceData.hub?.name || sourceData.hubSearch) &&
      isFormHasAtLeastOneFloorFile
    );
  },
  target: $IsButtonSaveDisabled,
});

sample({
  clock: removeProjectFx.done,
  source: $Form,
  filter: (form, { result, params: id }) => result && id === form.projectId,
  target: resetFormStateFn,
});

sample({
  clock: editProjectFn,
  target: getProjectByIdFx,
});

getFileTemplatesFx.use(async () => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query FileTemplates {
        fileTemplates
      }
    `,
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data.fileTemplates ?? '';
});

createDraftProjectFx.use(async () => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation CreateDraftProject {
        createDraftProject {
          ok
          errors {
            key
            message
          }
          projectId
        }
      }
    `,
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data?.createDraftProject?.projectId;
});

checkIsNameUniqueFx.use(async params => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query ProjectNameIsUnique($excludeProjectId: UUID, $name: String!) {
        projectNameIsUnique(excludeProjectId: $excludeProjectId, name: $name)
      }
    `,
    variables: params,
    context: { clientName: TPU_CLIENT_NAME },
  });

  return !!response.data.projectNameIsUnique;
});

getProjectByIdFx.use(async id => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query QueryProjectById($id: UUID!) {
        projectById(id: $id) {
          id
          name
          state
          hub {
            name
            code
            id
            creationType
            agglomeration {
              id
              name
            }
          }
          dataFiles {
            id
            state
            errors
            dataType
          }
          floorFiles {
            id
            description
          }
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data.projectById;
});

updateProjectFx.use(async params => {
  if (
    params.hub?.creationType === IHubCreationTypeEnum.Real &&
    !params.agglomeration?.id
  )
    return false;

  const variables: IUpdateProjectInput = {
    projectId: params.projectId,
    name: params.name,
    hub: {
      name: params.hub?.name ?? params.hubSearch,
      id: params.hub?.id ?? null,
    },
    floorFiles: params.floorFiles
      .filter(({ id }) => !!id)
      .map(({ description, id }) => ({
        description,
        fileInfoId: id,
      })),
    agglomerationId: params.agglomeration?.id,
    dataFileIds: Object.values(params.dataFiles)
      .filter(Boolean)
      .map(fileInfo => fileInfo!.id),
  };

  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation UpdateProjectMutation(
        $projectId: UUID!
        $name: String!
        $hub: HubInput!
        $floorFiles: [ProjectFloorFileInfoInput]!
        $dataFileIds: [UUID]!
      ) {
        updateProject(
          inputData: {
            projectId: $projectId
            name: $name
            hub: $hub
            floorFiles: $floorFiles
            dataFileIds: $dataFileIds
          }
        ) {
          ok
          errors {
            key
            message
          }
        }
      }
    `,
    variables,
    context: { clientName: TPU_CLIENT_NAME },
  });

  return !!response.data?.updateProject?.ok;
});
