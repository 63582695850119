export const getMoreColors = (
  elementsNumber: number,
  colorsArrayLength: number,
): string[] => {
  const newColors: string[] = [];

  for (let i = colorsArrayLength; i < elementsNumber; i++) {
    newColors.push('#' + Math.random().toString(16).slice(-6));
  }

  return newColors;
};
