import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Typography } from '@mui/material';

type TimerType = {
  setDisabled: Dispatch<SetStateAction<boolean>>;
};

export const Timer = (props: TimerType) => {
  const { setDisabled } = props;
  const [seconds, setSeconds] = useState(3);

  useEffect(() => {
    if (seconds > -1) {
      const interval = setInterval(() => {
        setSeconds(seconds => --seconds);
      }, 1000);

      return () => clearInterval(interval);
    }
    setDisabled(false);
  }, [seconds, setDisabled]);

  return (
    <Typography
      sx={{
        visibility: seconds > -1 ? 'visible' : 'hidden',
        alignSelf: 'center',
        fontSize: theme => theme.typography.pxToRem(18),
      }}
    >
      {`0${seconds}`}
    </Typography>
  );
};
