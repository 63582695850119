import './stores/index';

import { PassengerTrafficComponent } from '@features/pt-forecast-new/component';

export const PassengerTrafficForecastContainer = () => {
  return (
    <>
      <PassengerTrafficComponent />
    </>
  );
};
