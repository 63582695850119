import { AgSocEcoScenarioParams } from '@features/ag-constructor/constants/agEcoParams';
import { $ComputedScenarioList } from '@features/ag-constructor/stores/lists/scenarios';
import { ScenarioType } from '@features/pt-constructor/constants/socialScenarioType';
import { createApi, createStore, sample } from 'effector';

export enum EStepModalSocioEconomic {
  one,
  two,
}

type ModalSocioEconomic = {
  step: EStepModalSocioEconomic;
  name: string;
  type: ScenarioType;
  params: AgSocEcoScenarioParams;
  selectedId: string;
};

type DefaultAgEcoParams = Readonly<
  Record<keyof AgSocEcoScenarioParams, number>
>;

export const basicDefaultAgEcoParams: DefaultAgEcoParams = {
  annualJobsGrowthRate: 1,
  annualSalariesGrowthRate: 1,
  annualEducationPlacesGrowthRate: 1,
  annualServiceJobsGrowthRate: 1,
  annualRetailSpaceGrowthRate: 1,
  annualGardenPartnershipGrowthRate: 1,
  annualPopulationGrowthRate: 1,
} as const;

const defaultState = {
  step: EStepModalSocioEconomic.one,
  name: '',
  type: ScenarioType.Basic,
  params: basicDefaultAgEcoParams,
  selectedId: '',
};

export const $ModalSocioEconomic =
  createStore<ModalSocioEconomic>(defaultState);

export const ModalSocioEconomicApi = createApi($ModalSocioEconomic, {
  setStep: (state, payload: EStepModalSocioEconomic) => ({
    ...state,
    step: payload,
  }),
  setName: (state, payload: string) => ({
    ...state,
    name: payload,
  }),
  setType: (state, payload: ScenarioType) => ({
    ...state,
    type: payload,
  }),
  setParams: (state, payload: Partial<AgSocEcoScenarioParams>) => ({
    ...state,
    params: {
      ...state.params,
      ...payload,
    },
  }),
  setId: (state, payload: string) => ({
    ...state,
    selectedId: payload,
  }),
  reset: () => defaultState,
});

sample({
  clock: ModalSocioEconomicApi.setId,
  source: $ComputedScenarioList,
  fn(store, id) {
    const selectedScenario = store.socEcoScenarios.find(
      scenario => scenario.id === id,
    );

    return {
      annualJobsGrowthRate: selectedScenario?.annualJobsGrowthRate
        ? selectedScenario?.annualJobsGrowthRate.toFixed(2)
        : 0,
      annualSalariesGrowthRate: selectedScenario?.annualSalariesGrowthRate
        ? selectedScenario?.annualSalariesGrowthRate.toFixed(2)
        : 0,
      annualEducationPlacesGrowthRate:
        selectedScenario?.annualEducationPlacesGrowthRate
          ? selectedScenario?.annualEducationPlacesGrowthRate.toFixed(2)
          : 0,
      annualServiceJobsGrowthRate: selectedScenario?.annualServiceJobsGrowthRate
        ? selectedScenario?.annualServiceJobsGrowthRate.toFixed(2)
        : 0,
      annualRetailSpaceGrowthRate: selectedScenario?.annualRetailSpaceGrowthRate
        ? selectedScenario?.annualRetailSpaceGrowthRate.toFixed(2)
        : 0,
      annualGardenPartnershipGrowthRate:
        selectedScenario?.annualGardenPartnershipGrowthRate
          ? selectedScenario?.annualGardenPartnershipGrowthRate.toFixed(2)
          : 0,
      annualPopulationGrowthRate: selectedScenario?.annualPopulationGrowthRate
        ? selectedScenario?.annualPopulationGrowthRate.toFixed(2)
        : 0,
    } as DefaultAgEcoParams;
  },
  target: ModalSocioEconomicApi.setParams,
});
