import { EMapFeatureLayout } from '@constants/map';

export const initLayersVisible = {
  [EMapFeatureLayout.tpu]: true,
  [EMapFeatureLayout.tpuGraphs]: true,
  [EMapFeatureLayout.graphs]: true,
  [EMapFeatureLayout.transportDistricts]: true,
  [EMapFeatureLayout.agRailwayStations]: true,
  [EMapFeatureLayout.graphAuto]: true,
  [EMapFeatureLayout.graphBus]: false,
  [EMapFeatureLayout.graphTrolleybus]: false,
  [EMapFeatureLayout.graphTram]: false,
  [EMapFeatureLayout.graphMetro]: false,
  [EMapFeatureLayout.graphSuburbanRailway]: false,
  [EMapFeatureLayout.graphRopeWay]: false,
  [EMapFeatureLayout.graphWaterWay]: false,
  [EMapFeatureLayout.graphFunicular]: false,
  [EMapFeatureLayout.graphMonoRailWay]: false,
  [EMapFeatureLayout.graphPedestrain]: false,
  [EMapFeatureLayout.graphAvia]: false,
  [EMapFeatureLayout.agSearchInfra]: false,
  isActual: true,
  isPlanned: false,
};
