import { useUnit } from 'effector-react';
import { useMemo } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { LineLabel } from '@features/pt-forecast-new/components/Dashboards/IncomeByGroupsChart/LineLabel';
import { TooltipContent } from '@features/pt-forecast-new/components/Dashboards/IncomeByGroupsChart/TooltipContent';
import { XAxisTick } from '@features/pt-forecast-new/components/Dashboards/IncomeByGroupsChart/XAxisTick';
import { $ComputedData } from '@features/pt-forecast-new/stores/computedData';

export const IncomeByGroupsChart = () => {
  const { currentData } = useUnit($ComputedData);

  const data = useMemo(() => {
    return [
      {
        name: `1 группа (с наименьшими доходами)`,
        income: currentData?.passengerTrafficByIncomeGroups?.[0].value || 0,
      },
      {
        name: '2 группа',
        income: currentData?.passengerTrafficByIncomeGroups?.[1].value || 0,
      },
      {
        name: '3 группа',
        income: currentData?.passengerTrafficByIncomeGroups?.[2].value || 0,
      },
      {
        name: '4 группа',
        income: currentData?.passengerTrafficByIncomeGroups?.[3].value || 0,
      },
      {
        name: '5 группа (с наибольшими доходами)',
        income: currentData?.passengerTrafficByIncomeGroups?.[4].value || 0,
      },
    ];
  }, [currentData?.passengerTrafficByIncomeGroups]);

  return (
    <ResponsiveContainer
      width="100%"
      minHeight="200px"
    >
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 50,
        }}
      >
        <CartesianGrid
          strokeDasharray="7 7"
          stroke="#ddd"
          vertical={false}
        />

        <XAxis
          dataKey="name"
          height={20}
          padding={{ left: 30, right: 70 }}
          interval={0}
          tick={props => <XAxisTick {...props} />}
        />

        <YAxis fontSize={12} />

        <Tooltip content={TooltipContent} />

        <Line
          type="monotone"
          dataKey="income"
          stroke="#000"
          strokeDasharray="3 3"
          dot={{ strokeDasharray: '0', fill: '#000' }}
          activeDot={{ fill: '#E21A1A', stroke: '#000' }}
          label={LineLabel}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
