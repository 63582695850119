import { useUnit } from 'effector-react';

import { Notification } from '@features/tpu-project-registry/components';
import { $Notifications } from '@features/tpu-project-registry/stores';

export const Notifications = () => {
  const $notifications = useUnit($Notifications);

  return (
    <>
      {$notifications.map((data, index) => (
        <Notification
          data={data}
          index={index}
          key={data.id}
        />
      ))}
    </>
  );
};
