import { apiClient } from '@api/client';
import { IQuery, IQueryGetProjectSocEcoScenariosArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

export const queryGetProjectSocEcoScenarios = async (
  project: IQueryGetProjectSocEcoScenariosArgs,
) => {
  const result = await apiClient.query<
    Pick<IQuery, 'getProjectSocEcoScenarios'>,
    IQueryGetProjectSocEcoScenariosArgs
  >({
    query: gql`
      query QueryGetProjectSocEcoScenarios($projectId: String!) {
        getProjectSocEcoScenarios(projectId: $projectId) {
          annualEducationPlacesGrowthRate
          annualGardenPartnershipGrowthRate
          annualJobsGrowthRate
          annualPopulationGrowthRate
          annualRetailSpaceGrowthRate
          annualSalariesGrowthRate
          annualServiceJobsGrowthRate
          created
          errors
          finished
          id
          modified
          name
          scenarioId
          type
        }
      }
    `,
    variables: project,
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.getProjectSocEcoScenarios;
};
