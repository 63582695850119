import { useUnit } from 'effector-react/effector-react.umd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';

import { Accesses, Actions, Table } from '@components/TableTPU';

import { ROUTES } from '@constants/routes';

import { Pagination } from '@features/tpu-project-registry/components/Pagination';
import { SearchInput } from '@features/tpu-project-registry/components/SearchInput';
import { Status } from '@features/tpu-project-registry/components/Status';
import { FIELD } from '@features/tpu-project-registry/constants';
import { useContextPRHandlers } from '@features/tpu-project-registry/hooks';
import {
  $Form,
  $IsOpenForm,
} from '@features/tpu-project-registry/stores/form/form.store';
import { $ProjectRegistry } from '@features/tpu-project-registry/stores/project-registry/project-regisrty.store';
import { TableRow } from '@features/tpu-project-registry/types';

import { formatDate } from '@utils/formatDate';

export const ProjectTableContainer = () => {
  const navigate = useNavigate();
  const $projectRegistry = useUnit($ProjectRegistry);
  const $form = useUnit($Form);
  const $isOpenForm = useUnit($IsOpenForm);
  const {
    getListOfProjects,
    setSearch,
    setPage,
    setRowsPerPage,
    setSort,
    openDeleteProjectModal,
    copyProject,
    editProject,
    createDraftProject,
  } = useContextPRHandlers();

  const columns: GridColDef<TableRow>[] = [
    {
      field: FIELD.INDEX,
      headerName: '№',
      width: 60,
      sortable: false,
      renderCell: ({ row }) =>
        ($projectRegistry.page - 1) * $projectRegistry.pageSize + row.lineIndex,
    },
    {
      field: FIELD.NAME,
      headerName: 'Проект',
      minWidth: 357,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <Accesses />
          <Box sx={{ width: '90%', wordWrap: 'break-word' }}>{row.name}</Box>
        </>
      ),
    },
    {
      field: FIELD.HUB_NAME,
      valueGetter: ({ row }) => row.hub?.name,
      headerName: 'Название ТПУ',
      width: $isOpenForm ? 190 : 296,
    },
    {
      field: FIELD.DATE,
      headerName: 'Дата изменения',
      width: 164,
      renderCell: ({ row }) => formatDate(row.modified),
    },
    {
      field: FIELD.HUB_CODE,
      headerName: 'Код ТПУ',
      valueGetter: ({ row }) => row.hub?.code,
      width: 112,
    },
    {
      field: FIELD.AGGLOMERATION,
      headerName: 'Агломерация',
      width: $isOpenForm ? 140 : 280,
      sortable: false,
      valueGetter: ({ row }) => row.hub?.agglomeration?.name,
    },
    {
      field: FIELD.STATE,
      headerName: 'Статус',
      width: 140,
      renderCell: ({ row }) => <Status value={row.state} />,
    },
    {
      field: FIELD.USER,
      headerName: 'Пользователь',
      width: 296,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          Иванов К. М.
          <Actions
            onDelete={() =>
              openDeleteProjectModal({ name: row.name ?? '', id: row.id })
            }
            onCopy={copyProject}
            onEdit={editProject}
            id={row.id}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    getListOfProjects();
  }, [getListOfProjects]);

  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.background.paper,
        boxShadow: '0px 0px 8px 0px #00000026',
        padding: '24px',
        borderRadius: '4px',
        overflow: 'auto',
        flex: '2',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <Typography>Реестр ТПУ</Typography>

      <Box sx={{ display: 'flex', gap: '24px' }}>
        <SearchInput
          onSearchChange={setSearch}
          value={$projectRegistry.search}
        />
        <Button
          variant="contained"
          sx={{
            width: '268px',
            minWidth: '268px',
          }}
          disabled={$isOpenForm}
          onClick={createDraftProject}
        >
          Создать новый проект
        </Button>
      </Box>
      <Table
        page={$projectRegistry.page - 1}
        rowsPerPage={$projectRegistry.pageSize}
        rows={$projectRegistry.projects.map(
          (item, index): TableRow => ({
            lineIndex: index + 1,
            ...item,
          }),
        )}
        columns={columns}
        dataGridProps={{
          getRowId: row => row.id,
          onCellClick: cell => {
            if (
              cell.field === 'name'
              // && cell.row.state === IHubProjectStateChoices.Success
            )
              navigate(`${ROUTES.TPU_SCENARIOS}/${cell.row.id}`);
          },
          onSortModelChange: setSort,
          rowCount: Math.ceil(
            $projectRegistry.count / $projectRegistry.pageSize,
          ),
          paginationMode: 'server',
          sortingMode: 'server',
          rowSelectionModel: $form.projectId,
          loading: $projectRegistry.loading,
        }}
        wrapperSx={{
          height: 'calc(100% - 68px - 66px - 72px)',
        }}
      />
      <Pagination
        page={$projectRegistry.page - 1}
        rowsPerPage={$projectRegistry.pageSize}
        count={$projectRegistry.count}
        setPage={index => setPage(index + 1)}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  );
};
