import { sample } from 'effector';
import { v4 as uuid } from 'uuid';

import {
  $Notifications,
  addNotificationFn,
  hideNotificationFn,
  removeNotificationFn,
  resetNotificationsFn,
} from './notifications.store';

import {
  NotificationType,
  checkIsNameUniqueFx,
  copyProjectFx,
  updateProjectFx,
} from '@features/tpu-project-registry/stores';

$Notifications
  .reset(resetNotificationsFn)
  .on(addNotificationFn, (state, payload) => {
    const newNotification = { ...payload, isOpen: true, id: uuid() };
    return [...state, newNotification];
  })
  .on(hideNotificationFn, (state, id) => {
    return state.map(item =>
      item.id === id ? { ...item, isOpen: false } : item,
    );
  })
  .on(removeNotificationFn, (state, id) => {
    return state.filter(item => item.id !== id);
  });

sample({
  clock: updateProjectFx.doneData,
  fn: clockData => {
    return { type: NotificationType.UpdateProject, isSuccess: clockData };
  },
  target: addNotificationFn,
});

sample({
  clock: copyProjectFx.doneData,
  fn: clockData => {
    return { type: NotificationType.CopyProject, isSuccess: !!clockData?.ok };
  },
  target: addNotificationFn,
});

sample({
  clock: checkIsNameUniqueFx.doneData,
  filter: clockData => !clockData,
  fn: () => {
    return { type: NotificationType.NotUniqueProjectName, isSuccess: false };
  },
  target: addNotificationFn,
});
