import { Stroke, Style } from 'ol/style';

import { EMapFeatureMetaKeys } from '@constants/map';

import { EFeatureStyle } from '@utils/map/hooks/useInitVectorLayer';
import { VectorStyleProps } from '@utils/map/styles/types';
import { createActiveFeatureBorder } from '@utils/map/tools/createActiveFeatureBorder';

export const ptDirectionRailwayVectorStyleFilledGray = (
  props: VectorStyleProps,
) => {
  const { featureStyles, feature, width } = props;

  const currentWidth = width || 3;
  let active = false;

  if (
    featureStyles &&
    Object.keys(featureStyles).includes(
      String(feature?.get(EMapFeatureMetaKeys.olId)),
    )
  ) {
    const style = featureStyles[feature?.get(EMapFeatureMetaKeys.olId)];
    switch (style) {
      case EFeatureStyle.selected:
        active = true;
    }
  }

  const styles = active ? [createActiveFeatureBorder(currentWidth + 1)] : [];

  styles.push(
    new Style({
      stroke: new Stroke({
        lineCap: 'square',
        color: '#5A5A5A',
        width: currentWidth,
      }),
    }),
  );

  return styles;
};
