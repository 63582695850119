import { createApi, createStore } from 'effector';

const initState: ModalsState = {
  confirmCancelEditTpu: false,
  passFlow: false,
  action: null,
};

export const $Modals = createStore<ModalsState>(initState);

export const ModalsApi = createApi($Modals, {
  setModalView: (store, payload: Partial<ModalsState>) => ({
    ...store,
    ...payload,
    action: payload.action || null,
  }),
  reset: () => initState,
});

export type ModalsState = {
  passFlow: boolean;
  confirmCancelEditTpu: boolean;
  action: ((args?: unknown) => void) | null;
};
