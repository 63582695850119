import { Chip, Grid, Typography } from '@mui/material';

export const ErrorInfo = ({ errorMessage }: ErrorProps) => (
  <Grid
    display={'flex'}
    gap={1.5}
    alignItems="center"
  >
    <Chip
      label="Ошибка"
      color="error"
    />

    <Typography variant="body2">{errorMessage}</Typography>
  </Grid>
);

interface ErrorProps {
  errorMessage: string | null;
}
