import { chipClasses } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

import { palette } from '@system/theme/palette';

export const MuiChip: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: theme.typography.pxToRem(24),
      gap: theme.typography.pxToRem(4),
      borderRadius: theme.shape.borderRadius,
    }),
    sizeMedium: ({ theme }) => ({
      [`& .${chipClasses.label}`]: {
        fontSize: theme.typography.pxToRem(14),
        padding: theme.spacing(0.375, 0.75),
      },
    }),
    colorSuccess: {
      backgroundColor: palette.success.main,
      color: palette.common.white,
    },
    colorWarning: {
      backgroundColor: palette.customs.bus,
    },
    colorError: {
      backgroundColor: palette.error.main,
    },
  },
};
