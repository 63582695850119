import { createEvent, createStore } from 'effector';

export enum EStateModalDeleteProjects {
  question,
  success,
}

interface ModalDeleteProjects {
  state: EStateModalDeleteProjects;
}

const defaultState = {
  state: EStateModalDeleteProjects.question,
};

export const $ModalDeleteProjects =
  createStore<ModalDeleteProjects>(defaultState);

export const setState = createEvent<EStateModalDeleteProjects>();
export const resetModalDeleteProjects = createEvent();

$ModalDeleteProjects
  .on(setState, (state, payload) => {
    return { ...state, state: payload };
  })
  .reset(resetModalDeleteProjects);
