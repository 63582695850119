export const SOC_ECO_TH_3_6 = [
  {
    id: 'transportArea',
    label: 'Транспортный район',
  },
  // {
  //   id: 'areaTerritoriesSubjectBuild',
  //   label: 'Площадь территорий, отведённой под строительство, кв. м.',
  // },
  // {
  //   id: 'areaTerritoriesSubjectTransportLine',
  //   label: 'Площадь земель, отведённых под транспортную линию, кв. м.',
  // },
  // {
  //   id: 'areaTPU',
  //   label: 'Площадь ТПУ, кв. м.',
  // },
  {
    id: 'areaTerritoriesSubjectReclamation',
    label: 'Площадь территорий, подлежащей рекультивации, кв. м.',
  },
];
