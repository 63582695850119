import { createApi, createStore } from 'effector';

export const $ModalsAgConstructor = createStore<ModalsAgConstructor>({
  action: null,
  calculation: false,
  deleteCalculation: false,
  editDeny: false,
  recalculation: false,
  rewriteCalculated: false,
  shareCalculation: false,
  socioEconomic: false,
});

export const ModalsAgConstructorApi = createApi($ModalsAgConstructor, {
  setModalView: (state, payload: Partial<ModalsAgConstructor>) => ({
    ...state,
    ...payload,
  }),
});

export type ModalsAgConstructor<T = unknown> = {
  socioEconomic: boolean;
  calculation: boolean;
  deleteCalculation: boolean;
  shareCalculation: boolean;
  editDeny: boolean;
  recalculation: boolean;
  rewriteCalculated: boolean;
  action: T | null;
};
