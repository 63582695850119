import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { Dialog } from '@mui/material';

import { $FeatureSettings } from '@features/ag-forecast/stores/settings';

import { WidgetPassengerFlowContainer } from '@widgets/widget-passenger-flow';

export type ModalPassFlowProps = {
  onClose(): void;
};

export const ModalPassFlow = ({ onClose }: ModalPassFlowProps) => {
  const { regionUUID } = useUnit($FeatureSettings);

  const closeFrameHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog
      onClose={closeFrameHandler}
      open={true}
      fullWidth={true}
      maxWidth="xl"
      PaperProps={{ style: { width: '90vw', height: '90vh' } }}
    >
      {regionUUID && <WidgetPassengerFlowContainer regionUUID={regionUUID} />}
    </Dialog>
  );
};
