import {
  SyntheticEvent,
  useCallback, // useState
} from 'react';

import { BetweenPoints, BetweenPointsProps } from './BetweenPoints';
import { FilterSlot } from './FilterSlot';
import { SelectLayout, SelectLayoutProps } from './SelectLayout';
import {
  EFilterMapLayout, // EndPlannedYear,
  MapFilterField, // PlannedYearsRange,
  // StartPlannedYear,
} from './interface';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  Divider, // FormControl,
  FormControlLabel, // Grid,
  // MenuItem,
  // Select,
  // SelectChangeEvent,
  // Slider,
  Stack,
  Typography, // debounce,
} from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { SetRegionArg } from '@features/passenger-traffic/stores';

type MapFiltersProps = {
  autoHeight?: boolean;
  hideFields?: Array<keyof MapFilterField>;
  fields: MapFilterField;
  disabledPoints?: boolean;
  isVisibleLayoutMap?: boolean;
  isVisibleШntermediateCorrespondents?: boolean;
  onChangeFields(args: Partial<MapFilterField>): void;
  onReset(): void;
  onResetSelectedRegions?(): void;
  setRegion: (region: SetRegionArg) => void;
} & SelectLayoutProps &
  Omit<BetweenPointsProps, 'setRegion'>;

export const MapFilters = (props: MapFiltersProps) => {
  const {
    autoHeight = false,
    layout,
    isVisibleLayoutMap = true,
    isVisibleШntermediateCorrespondents = true,
    regionsA,
    regionsB,
    disabledPoints,
    onChangeLayout,
    setPoint,
    fetchRegion,
    setRegion,
    onReset,
    onResetSelectedRegions,
    onChangeFields,
    regionA,
    regionB,
    fields,
    hideFields,
  } = props;

  // TODO: временно закомментированы селект и слайдер планируемой инфраструктуры
  // const [plannedYear, setPlannedYear] = useState<number>(
  //   fields?.plannedYear || new Date().getFullYear() - 1,
  // );

  const handlerChangeActual = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      onChangeFields({ actual: checked });
    },
    [onChangeFields],
  );

  const handlerChangePlanned = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      onChangeFields({ planned: checked });
    },
    [onChangeFields],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debouncedSetPlannedYear = useCallback(
  //   debounce((value: number) => {
  //     onChangeFields({ plannedYear: value });
  //   }, 500),
  //   [],
  // );

  // const handlerChangePlannedYearSlider = useCallback(
  //   (_event: Event, newValue: number | number[]) => {
  //     setPlannedYear(newValue as number);
  //     debouncedSetPlannedYear(newValue as number);
  //   },
  //   [debouncedSetPlannedYear],
  // );

  // const handlerChangePlannedYearSelect = useCallback(
  //   (event: SelectChangeEvent) => {
  //     setPlannedYear(+event.target.value);
  //     onChangeFields({ plannedYear: +event.target.value });
  //   },
  //   [onChangeFields],
  // );

  const handlerChangeAreaBorder = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      onChangeFields({ areaBorder: checked });
    },
    [onChangeFields],
  );
  const handlerChangeIntermediateCorrespondents = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      onChangeFields({ intermediateCorrespondents: checked });
    },
    [onChangeFields],
  );

  const onResetButtonClick = useCallback(() => {
    onReset();
  }, [onReset]);

  const handlerResetSelectedRegions = useCallback(() => {
    onResetSelectedRegions && onResetSelectedRegions();
  }, [onResetSelectedRegions]);

  const onSetRegion = useCallback(
    (region: SetRegionArg) => {
      setRegion(region);
    },
    [setRegion],
  );

  return (
    <AutoFullHeight
      style={{
        borderTop: '1px solid #EBECEF',
        width: '500px',
        maxWidth: '500px',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
      autoHeight={autoHeight}
    >
      {isVisibleLayoutMap && (
        <SelectLayout
          layout={layout}
          onChangeLayout={onChangeLayout}
        />
      )}
      <Box
        style={{
          overflowY: 'auto',
          overflowX: 'clip',
          marginRight: '12px',
          paddingRight: '16px',
          padding: '16px 32px 16px 44px',
        }}
      >
        {!isVisibleLayoutMap && (
          <Typography
            variant="subtitle2"
            mt={1}
          >
            Фильтры
          </Typography>
        )}
        <BetweenPoints
          setPoint={setPoint}
          fetchRegion={fetchRegion}
          setRegion={onSetRegion}
          regionsA={regionsA}
          regionsB={regionsB}
          regionA={regionA}
          regionB={regionB}
          disabled={disabledPoints}
          onReset={handlerResetSelectedRegions}
        />

        <Divider />

        <FilterSlot
          setFields={onChangeFields}
          hideFields={hideFields}
          fields={fields}
          layout={layout}
        />

        <Divider sx={{ mb: 1 }} />

        <Stack pl={2}>
          {layout === EFilterMapLayout.Infrastructure && (
            <>
              <FormControlLabel
                checked={fields.actual}
                onChange={handlerChangeActual}
                control={<Checkbox />}
                label="Существующая"
              />

              <FormControlLabel
                checked={fields.planned}
                onChange={handlerChangePlanned}
                control={<Checkbox />}
                label="Планируемая"
              />

              {/* {fields.planned && (
                <Grid
                  display={'grid'}
                  gridTemplateColumns={'1fr 3fr'}
                  alignItems={'center'}
                  columnGap={2}
                  py={2}
                  pr={1}
                >
                  <FormControl>
                    <Select
                      value={plannedYear.toString()}
                      onChange={handlerChangePlannedYearSelect}
                      MenuProps={{
                        MenuListProps: {
                          style: {
                            maxHeight: '200px',
                          },
                        },
                      }}
                    >
                      {PlannedYearsRange.map(value => (
                        <MenuItem
                          value={value}
                          key={value}
                        >
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Slider
                    color="secondary"
                    min={StartPlannedYear}
                    max={EndPlannedYear}
                    step={1}
                    value={plannedYear}
                    onChange={handlerChangePlannedYearSlider}
                  />
                </Grid>
              )} */}

              <Divider sx={{ my: 1, ml: -2 }} />
            </>
          )}

          <FormControlLabel
            checked={fields.areaBorder}
            onChange={handlerChangeAreaBorder}
            control={<Checkbox />}
            sx={{ whiteSpace: 'nowrap' }}
            label="Показать границы транспортного района"
          />
          {isVisibleШntermediateCorrespondents && (
            <FormControlLabel
              checked={fields.intermediateCorrespondents}
              onChange={handlerChangeIntermediateCorrespondents}
              control={<Checkbox />}
              sx={{ whiteSpace: 'nowrap' }}
              label="Учитывать промежуточные корреспонденции"
            />
          )}
        </Stack>
      </Box>

      <Box
        pb="26px"
        pt={3}
        px={5.5}
        mt={'auto'}
        style={{
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Button
          variant={'text'}
          startIcon={<CloseIcon />}
          fullWidth
          onClick={onResetButtonClick}
        >
          Сбросить все
        </Button>
      </Box>
    </AutoFullHeight>
  );
};
