/**
 * Получение толщины линии объекта для карты, для отображения процента от общего пассажиропотока.
 * @param percent - процент пассажиропотока от общего
 * @return толщина в пикселях отображаемой линии на карте
 */
export const getWidthLineByPercent = (percent: number): number => {
  if (percent <= 9) {
    return 3;
  } else if (percent <= 19) {
    return 4;
  } else if (percent <= 29) {
    return 5;
  } else if (percent <= 39) {
    return 6;
  } else if (percent <= 49) {
    return 7;
  } else if (percent <= 59) {
    return 8;
  } else if (percent <= 69) {
    return 9;
  } else if (percent <= 79) {
    return 10;
  } else if (percent <= 89) {
    return 11;
  } else if (percent <= 100) {
    return 12;
  }
  return 3;
};
