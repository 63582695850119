import { Box, CircularProgress, Tooltip } from '@mui/material';

import { CloseMiniIcon } from '@components/icons';

interface ILoading {
  onClick: () => void;
}

export const Loading = (props: ILoading) => {
  const { onClick } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        columnGap: 1.5,
      }}
    >
      <CircularProgress
        color="inherit"
        size={12}
      />

      <Tooltip
        title={'Отменить загрузку'}
        placement="top"
      >
        <Box
          sx={{
            height: '21px',
          }}
        >
          <CloseMiniIcon
            sx={{
              cursor: 'pointer',
              fontSize: theme => theme.typography.pxToRem(21),
              ['&:hover']: {
                color: theme => theme.palette.text.secondary,
              },
            }}
            onClick={onClick}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};
