import {
  FlexibleObjects,
  ILadder,
  IPath,
  IPlatform,
  IRoom,
} from '@features/tpu-simulation-model/types';

import { getCorners } from '@utils/simulationModel/getCorners';
import { isPointInsideOfPolygon } from '@utils/simulationModel/isPointInsideOfPolygon';

export const isObjectInsideRoom = (
  object: IPath | IPlatform | FlexibleObjects | ILadder,
  room: IRoom,
) => {
  const corners = getCorners(object);
  const roomCoordinates = room.points.map<[number, number]>(item => [
    item.x,
    item.y,
  ]);
  const isInside = corners
    .map(corner => {
      return isPointInsideOfPolygon(corner, roomCoordinates);
    })
    .every(Boolean);

  return isInside;
};
