import { SvgIcon, SvgIconProps } from '@mui/material';

export const RailsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={48}
      height={4}
      viewBox="0 0 48 4"
      fill="none"
      {...props}
    >
      <path
        d="M2 2H46"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 2H46"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="12 5"
      />
    </SvgIcon>
  );
};
