import { sample } from 'effector';

import { EMapFeatureLayout } from '@constants/map';

import {
  $FilterMap,
  EFilterMapLayout,
  FilterMapApi,
} from '@features/pt-forecast-new/stores/filters';
import { initSources } from '@features/pt-forecast-new/stores/map';
import {
  LayersVisibleApi,
  LayersVisibleStore,
} from '@features/pt-forecast-new/stores/map/layersVisible';
import { $UI } from '@features/pt-forecast-new/stores/ui';

// Управление видимостью дирекшен слоев
sample({
  clock: [
    FilterMapApi.setFilters,
    FilterMapApi.setLayout,
    FilterMapApi.setRegionTo,
    FilterMapApi.setRegionFrom,
    FilterMapApi.clearRegions,
    FilterMapApi.reset,
    initSources,
  ],
  source: {
    FilterMap: $FilterMap,
    UI: $UI,
  },
  fn: ({ FilterMap, UI }) => {
    const isCorrespondence =
      !!FilterMap.fromSelectedRegion && !!FilterMap.toSelectedRegion;
    const isAviaFilters = FilterMap.avia;
    const isHighwayFilters =
      (FilterMap.bus && !UI.hideFilterFields.includes('bus')) ||
      (FilterMap.auto && !UI.hideFilterFields.includes('auto'));

    const isRailwayFilters =
      (FilterMap.dayTrain && !UI.hideFilterFields.includes('dayTrain')) ||
      (FilterMap.nightTrain && !UI.hideFilterFields.includes('nightTrain')) ||
      (FilterMap.suburbanTrain &&
        !UI.hideFilterFields.includes('suburbanTrain')) ||
      (FilterMap.expressTrain && !UI.hideFilterFields.includes('expressTrain'));

    const isLayoutPassengerFlows =
      FilterMap.layout === EFilterMapLayout.PassengerFlows;

    return {
      [EMapFeatureLayout.directionAirline]: isCorrespondence && isAviaFilters,
      [EMapFeatureLayout.directionHighway]:
        isCorrespondence && isHighwayFilters,
      [EMapFeatureLayout.directionRailway]:
        isCorrespondence && isRailwayFilters,
      [EMapFeatureLayout.airport]: FilterMap.avia,
      [EMapFeatureLayout.busStation]: isHighwayFilters,
      [EMapFeatureLayout.railwayStation]: isRailwayFilters,
      [EMapFeatureLayout.pointsAB]: true,
      [EMapFeatureLayout.areaBorder]: FilterMap.transportRegions,
      [EMapFeatureLayout.highway]:
        !isCorrespondence && !isLayoutPassengerFlows && isHighwayFilters,
      [EMapFeatureLayout.railway]:
        !isCorrespondence && !isLayoutPassengerFlows && isRailwayFilters,
      [EMapFeatureLayout.customRailwayGraph]:
        !isCorrespondence && !isLayoutPassengerFlows && isRailwayFilters,
      [EMapFeatureLayout.customRailwayStation]:
        !isCorrespondence && !isLayoutPassengerFlows && isRailwayFilters,
    } as Partial<LayersVisibleStore>;
  },
  target: LayersVisibleApi.setVisible,
});
