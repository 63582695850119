import { Circle, Group, Image, Rect } from 'react-konva';
import useImage from 'use-image';

import ArrowRightSvg from '@components/icons/ArrowRight.svg';
import LadderSvg from '@components/icons/Ladder.svg';

import { ILadder } from '@features/tpu-simulation-model/types';

interface ArrowProps {
  ratio: number;
  point: { x: number; y: number };
  enter?: boolean;
}

const DEFAULT_SIZE = 44;

const Arrow = ({ ratio, point, enter }: ArrowProps) => {
  const [arrowRight] = useImage(ArrowRightSvg);

  return (
    <Group
      x={point.x}
      y={point.y}
    >
      <Circle
        fill={enter ? '#3679EA' : '#F3A015'}
        radius={8 * ratio}
      />
      <Image
        image={arrowRight}
        x={-4 * ratio}
        y={-4 * ratio}
        width={8 * ratio}
        height={8 * ratio}
      />
    </Group>
  );
};

export const Ladder = (props: ILadder) => {
  const [ladder] = useImage(LadderSvg);

  const { point, width, height, rotation } = props;
  const ratioW = width / DEFAULT_SIZE;
  const ratioH = height / DEFAULT_SIZE;

  return (
    <Group
      x={point.x}
      y={point.y}
      width={width}
      height={height}
      rotation={rotation}
    >
      <Rect
        width={width}
        height={height}
        fill={'#fff'}
        strokeWidth={1}
        stroke={'#3679EA'}
        strokeScaleEnabled={false}
      ></Rect>
      <Image
        image={ladder}
        width={24 * ratioW}
        height={24 * ratioH}
        x={10 * ratioW}
        y={10 * ratioH}
      />
      <Arrow
        enter
        ratio={Math.min(ratioW, ratioH)}
        point={{ x: 0, y: height / 2 }}
      />
      <Arrow
        point={{ x: width, y: height / 2 }}
        ratio={Math.min(ratioW, ratioH)}
      />
    </Group>
  );
};
