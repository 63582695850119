import { EMapFeatureLayout } from '@constants/map';

import { AgLayersStore } from '@features/ag-project/stores/map';

export const LayersZIndex: Record<
  keyof AgLayersStore | EMapFeatureLayout.agSearchInfra,
  number
> = {
  [EMapFeatureLayout.transportDistricts]: 1,
  [EMapFeatureLayout.tpu]: 2,
  [EMapFeatureLayout.tpuGraphs]: 3,
  [EMapFeatureLayout.graphs]: 4,
  [EMapFeatureLayout.graphAuto]: 5,
  [EMapFeatureLayout.graphBus]: 6,
  [EMapFeatureLayout.graphTrolleybus]: 7,
  [EMapFeatureLayout.graphMetro]: 8,
  [EMapFeatureLayout.graphRopeWay]: 9,
  [EMapFeatureLayout.graphWaterWay]: 10,
  [EMapFeatureLayout.graphFunicular]: 11,
  [EMapFeatureLayout.graphMonoRailWay]: 12,
  [EMapFeatureLayout.graphPedestrain]: 13,
  [EMapFeatureLayout.graphAvia]: 14,
  [EMapFeatureLayout.graphTram]: 15,
  [EMapFeatureLayout.graphSuburbanRailway]: 16,
  [EMapFeatureLayout.agRailwayStations]: 17,
  [EMapFeatureLayout.agSearchInfra]: 18,
};
