import { useUnit } from 'effector-react';

import { Box, Typography } from '@mui/material';

import { $Floors } from '@features/tpu-simulation-model/store';
import { $ListOfRulers } from '@features/tpu-simulation-model/store/ruler/listOfRulers.store';
import { IPoint } from '@features/tpu-simulation-model/types';

interface AttributeProps {
  points: IPoint[];
  floor: number;
}
export function ObjectPositionForPoints({ points, floor }: AttributeProps) {
  const $listOfRulers = useUnit($ListOfRulers) ?? {};
  const $floors = useUnit($Floors);
  const ruler = $listOfRulers[$floors.selectedFloor];
  const convertedX1 = ruler
    ? Math.round((points[0].x / ruler.px) * ruler.meters)
    : points[0].x;
  const convertedY1 = ruler
    ? Math.round((points[0].y / ruler.px) * ruler.meters)
    : points[0].y;
  const convertedX2 = ruler
    ? Math.round((points[1].x / ruler.px) * ruler.meters)
    : points[1].x;
  const convertedY2 = ruler
    ? Math.round((points[1].y / ruler.px) * ruler.meters)
    : points[1].y;

  return (
    <Box>
      <Typography>Расположение</Typography>
      <Typography
        sx={{
          color: props => props.palette.text.secondary,
          fontSize: theme => theme.typography.pxToRem(12),
          mt: '4px',
        }}
      >
        {floor} этаж, X: {convertedX1} / Y: {convertedY1}; X: {convertedX2} / Y:
        {convertedY2}
      </Typography>
    </Box>
  );
}
