import { IQuery } from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

const initState: TimeSlotsStore = {
  options: [],
};

export const $TimeSlots = createStore<TimeSlotsStore>(initState);

export const TimeSlotsApi = createApi($TimeSlots, {
  setTimeSlots: (store, payload: TimeSlotOption[] | []) => ({
    ...store,
    options: payload,
  }),
  reset: () => initState,
});

export const fetchTimeSlotsFx = createEffect<void, IQuery['timeSlots']>();

export type TimeSlotOption = {
  id: string;
  name: string;
};

export type TimeSlotsStore = {
  options: TimeSlotOption[];
};
