import styled from '@emotion/styled';
import { useCallback, useEffect, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button } from '@mui/material';

import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
  DownloadIcon,
} from '@components/icons';

import { DisplayedPageType } from '@features/ag-forecast/stores/dashboard/types';

// import { useUnit } from 'effector-react';
// import { $FeatureSettings } from '@features/ag-forecast/stores/settings';
// import { keycloak } from '@system/keycloak/keycloak';

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, '44px', 0, '44px'),
  gap: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '76px',
}));

export const DashboardToolBar = ({
  onBackButtonClick,
  onChangeView,
}: Props) => {
  const [view, setView] = useState<DisplayedPageType>(1);
  // const { scenarioUUID, scenarioName } = useUnit($FeatureSettings)

  const onViewButtonClick = useCallback(
    (view: DisplayedPageType) => () => setView(view),
    [],
  );

  const renderViewButton = useCallback(
    (activeView: DisplayedPageType) => {
      return (
        <Box
          sx={{
            width: '18px',
            height: '18px',
            cursor: 'pointer',
            borderRadius: '50%',
            border: theme => `1px solid ${theme.palette.secondary.main}`,
            backgroundColor:
              view === activeView
                ? theme => theme.palette.secondary.main
                : 'transparent',
          }}
          onClick={onViewButtonClick(activeView)}
        />
      );
    },
    [onViewButtonClick, view],
  );

  const handlerPrevPage = () => setView(state => --state as DisplayedPageType);

  const handlerNextPage = () => setView(state => ++state as DisplayedPageType);

  // const handleDownloadFile = () => {
  //   const url =
  //   // 'https://' +
  //   // 'dev.rzd.expert'
  //   // location.host
  //   location.origin
  //   + '/agglomeration/agglomeration_files/file/' + scenarioUUID +'/';
  //   console.log(url);

  //   if (!url) throw new Error('Url not found');

  //   // // eslint-disable-next-line effector/no-getState
  //   // const FilterMap = $FilterMap?.getState();
  //   // const fromTo = `${FilterMap?.fromSelectedRegion?.name || ''} - ${
  //   //   FilterMap?.toSelectedRegion?.name || ''
  //   // }`;
  //   const date = new Date().toLocaleDateString().replace(/\./g, '-');

  //   const fileName = `${scenarioName} ${date}.csv`;
  //   const headers = {
  //     'X-Auth': `Bearer ${keycloak.token || ''}`,
  //     // Здесь добавьте необходимые заголовки
  //   };

  //   fetch(url!, {
  //     headers,
  //     redirect: 'follow',
  //   })
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       return response.blob();
  //     })
  //     .then(blob => {
  //       // Создаем ссылку для скачивания
  //       const downloadUrl = window.URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = downloadUrl;
  //       link.download = fileName; // Название файла
  //       document.body.appendChild(link);
  //       link.click();

  //       // Очистить ссылку после скачивания
  //       document.body.removeChild(link);
  //       window.URL.revokeObjectURL(downloadUrl);
  //     })
  //     .catch(error => {
  //       console.error(
  //         'There has been a problem with your fetch operation:',
  //         error,
  //       );
  //     });

  //   return true;
  // }

  // TODO последний эксперимент
  // const handleDownloadFile = useCallback(
  //   async () => {
  //     // if (disabled) {
  //     //   e.preventDefault();
  //     //   return;
  //     // }
  //     // const fileUrl = computingRegistry.link?.split('/')
  //     // const fileUUID = fileUrl?.[fileUrl?.length - 1] || ''
  //     // const fileUUID = '261accb9-f90f-4a98-bdac-d42445eca281';
  //     console.log(keycloak.token);

  //     try {
  //       const response = await fetch(
  //         `${'https://dev.rzd.expert/'}agglomeration/agglomeration_files/file/${scenarioUUID}`,
  //         {
  //           method: 'GET',
  //           redirect: 'follow', // follow, error или manual
  //           headers: {
  //             'X-Auth': `Bearer ${keycloak.token || ''}`,
  //           }
  //         },
  //       );

  //       console.log('!', response);

  //       // Получаем Blob из ответа
  //       const blob = await response.blob();

  //       // Создаем URL для Blob
  //       const url = URL.createObjectURL(blob);

  //       // Создаем виртуальный элемент a и настраиваем его для скачивания
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = 'your_filename.csv'; // Укажите желаемое имя файла с расширением
  //       document.body.appendChild(a); // Добавляем элемент в DOM
  //       a.click(); // Имитируем клик для скачивания файла

  //       // Удаляем элемент после скачивания
  //       document.body.removeChild(a);

  //       // Освобождаем URL после того, как не нужен
  //       URL.revokeObjectURL(url);

  //       // if (response.type === 'opaqueredirect') {
  //       //   const redirectUrl = response.headers.get('Location');
  //       //   if (redirectUrl) {
  //       //     window.open(redirectUrl, '_blank');
  //       //   } else {
  //       //     console.error('Location header missing in redirect response');
  //       //   }
  //       // } else {
  //       //   // Обработка других типов ответов
  //       // }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   },
  //   [],
  // );

  useEffect(() => {
    onChangeView(view);
  }, [onChangeView, view]);

  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          variant={'text'}
          onClick={onBackButtonClick}
          sx={{
            color: theme => theme.palette.customs.link,
            [`& .MuiButton-startIcon > *:nth-of-type(1)`]: {
              fontSize: '12px',
            },
            mr: '33px',
          }}
          startIcon={<ArrowBackIcon />}
        >
          Назад
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <a
          href={location.origin + '/src/assets/Общие показатели.xlsx'}
          style={{
            marginRight: '32px',
          }}
          // onClick={handleDownloadFile}
        >
          <Button
            variant="text"
            sx={{
              color: theme => theme.palette.customs.link,
              columnGap: 1,
            }}
          >
            Скачать
            <DownloadIcon />
          </Button>
        </a>

        {view !== 1 && (
          <Button
            variant="outlined"
            sx={{
              mr: 2,
              columnGap: 1,
            }}
            onClick={handlerPrevPage}
          >
            <ArrowCircleLeftIcon
              sx={{
                color: theme => theme.palette.secondary.main,
                fill: theme => theme.palette.common.white,
              }}
            />
            Предыдущий
          </Button>
        )}

        {renderViewButton(1)}
        {renderViewButton(2)}
        {renderViewButton(3)}

        {view !== 3 && (
          <Button
            variant="outlined"
            sx={{
              ml: 2,
              columnGap: 1,
            }}
            onClick={handlerNextPage}
          >
            <ArrowCircleRightIcon
              sx={{
                color: theme => theme.palette.secondary.main,
                fill: theme => theme.palette.common.white,
              }}
            />
            Следующий
          </Button>
        )}
      </Box>
    </Wrapper>
  );
};

export interface Props {
  pointARegion: string | undefined;
  pointBRegion: string | undefined;
  onChangeView: (view: DisplayedPageType) => void;
  onBackButtonClick: () => void;
}
