import styled from '@emotion/styled';
import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Tooltip, Typography } from '@mui/material';

import { DownloadIcon } from '@components/icons';
import { EmptyPoint } from '@components/icons/EmptyPoint';

import { getDownloadLinkFx } from '@features/pt-forecast-new/stores/computedData';
import { $FilterMap } from '@features/pt-forecast-new/stores/filters';
import { $FeatureSettings } from '@features/pt-forecast-new/stores/settings';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';

export const DashboardToolBarContainer = () => {
  const { dashboardPage } = useUnit($UI);
  const { setDashboardPage, setShowDashboard } = useUnit(UIApi);
  const { scenarioId } = useUnit($FeatureSettings);
  const { fromSelectedRegion, toSelectedRegion } = useUnit($FilterMap);

  const disabled = useMemo(() => !scenarioId, [scenarioId]);

  const handleDownloadFile = useCallback(() => {
    getDownloadLinkFx({
      id: scenarioId,
    });
  }, [scenarioId]);

  const onViewButtonClick = useCallback(
    (view: 1 | 2) => () => setDashboardPage(view),
    [setDashboardPage],
  );

  const onBackButtonClick = useCallback(() => {
    setShowDashboard(false);
  }, [setShowDashboard]);

  const renderViewButton = useCallback(
    (activeView: 1 | 2) => {
      return (
        <Box
          sx={{
            width: '18px',
            height: '18px',
            cursor: 'pointer',
            borderRadius: '50%',
            border: theme => `1px solid ${theme.palette.secondary.main}`,
            backgroundColor:
              dashboardPage === activeView
                ? theme => theme.palette.secondary.main
                : 'transparent',
          }}
          onClick={onViewButtonClick(activeView)}
        />
      );
    },
    [dashboardPage, onViewButtonClick],
  );

  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button
          variant={'text'}
          onClick={onBackButtonClick}
          sx={{
            [`& .MuiButton-startIcon > *:nth-of-type(1)`]: {
              fontSize: '12px',
            },
            mr: '33px',
          }}
          startIcon={<ArrowBackIcon />}
          color={'info'}
        >
          Назад
        </Button>

        <Typography
          variant={'subtitle1'}
          sx={{
            pl: '36px',
            mr: 3,
            borderLeft: theme => `1px solid ${theme.palette.divider}`,
          }}
        >
          Структура пассажиропотока
        </Typography>

        {fromSelectedRegion && toSelectedRegion ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <EmptyPoint />

            <Typography variant={'subtitle1'}>
              {fromSelectedRegion.name} - {toSelectedRegion.name}
            </Typography>
          </Box>
        ) : null}
      </Box>

      <Box display={'flex'}>
        <Tooltip
          title={disabled ? 'Скачивание не доступно' : 'Скачивание доступно'}
          placement="top"
          enterDelay={700}
        >
          <a
            // href={computingRegistry.link || ''}
            // download={$downloadFile.name || 'Безымянный'}
            onClick={handleDownloadFile}
          >
            <Button
              variant="text"
              sx={{
                color: theme =>
                  disabled
                    ? theme.palette.secondary.main
                    : theme.palette.customs.link,
                columnGap: 1,
                mr: 4,
              }}
            >
              Скачать
              <DownloadIcon />
            </Button>
          </a>
        </Tooltip>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          {renderViewButton(1)}
          {renderViewButton(2)}
        </Box>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, '44px', 0, '44px'),
  gap: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '76px',
}));
