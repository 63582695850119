import { createApi, createStore } from 'effector';

const defaultStore: UIAGProjectsRegistry = {
  deleteProject: false,
  shareProject: false,
  projectCreator: false,
  fileRequirements: false,
  downloadFile: false,
  action: null,
};

export const $UIAg = createStore<UIAGProjectsRegistry>(defaultStore);

export const UIAgApi = createApi($UIAg, {
  setModalView: (store, payload: Partial<UIAGProjectsRegistry>) => ({
    ...store,
    ...payload,
  }),
  setVisibleProjectCreator: (store, payload: boolean) => ({
    ...store,
    projectCreator: payload,
  }),
  reset: () => defaultStore,
});

export type UIAGProjectsRegistry<T = unknown> = {
  deleteProject: boolean;
  shareProject: boolean;
  projectCreator: boolean;
  fileRequirements: boolean;
  downloadFile: boolean;
  action: T | null;
};
