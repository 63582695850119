import { createEffect, createEvent, createStore } from 'effector';

import {
  IPoint,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

export interface EditedObjectDataType {
  data: ObjectOfTheSimulationModel | null;
  interactionWindowPoint: IPoint | null;
}

export const $EditedObject = createStore<EditedObjectDataType>({
  data: null,
  interactionWindowPoint: null,
});

export const openObjectForEditFn = createEvent<
  | ObjectOfTheSimulationModel
  | { objectForEdit: ObjectOfTheSimulationModel }
  | void
>();

export const resetEditableObjectDataFn = createEvent();

export const updateEditWindowDataFn = createEvent<ObjectOfTheSimulationModel>();

export const openObjectForEditByIdFromAnyFloorFn = createEvent<string>();

export const outputObjectIdInConsoleFx = createEffect<string, void>();
