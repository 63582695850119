import { createEvent, createStore } from 'effector';

export interface InputFindObjectValue {
  label: string;
  id: string;
}

interface InputFindObjectDataType {
  value: InputFindObjectValue | null;
}

export const $InputFindObject = createStore<InputFindObjectDataType>({
  value: null,
});

export const chooseValueForInputFindObjectFn =
  createEvent<InputFindObjectValue | null>();

export const resetInputFindObjectFn = createEvent<void>();
