import { createBorderStyle } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/createBorderStyle';
import { SetFeatureStyleProps } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/getFeatureStyle';
import { Stroke, Style } from 'ol/style';

export type GetAutoStyleArgs = Pick<SetFeatureStyleProps, 'active' | 'width'>;

export const getAutoStyle = ({ active, width }: GetAutoStyleArgs) => [
  createBorderStyle({ active, featureWidth: width || 3 }),
  new Style({
    stroke: new Stroke({
      lineCap: 'square',
      color: '#FF792E',
      width: width || 3,
    }),
  }),
];
