import { sample } from 'effector';

import {
  IncomeByGroupsApi,
  IncomeByGroupsStore,
} from '@features/pt-forecast/stores/charts/incomeByGroups/store';
import { getComputedPassTrafficFx } from '@features/pt-forecast/stores/computed/store';
import { FilterRegionApi } from '@features/pt-forecast/stores/filterRegion/store';
import { resetAllFilters } from '@features/pt-forecast/stores/filters/store';

// Запись значений дохода населения по группам в стор
sample({
  clock: getComputedPassTrafficFx.done,
  fn: payload =>
    payload.result?.map(item => ({
      ...item?.passengerTrafficByIncomeGroups,
      year: item?.year,
    })) as IncomeByGroupsStore,
  target: IncomeByGroupsApi.setIncomeByGroups,
});

// Сброс данных при сбросе фильтров
sample({
  clock: [resetAllFilters, FilterRegionApi.reset],
  target: IncomeByGroupsApi.reset,
});
