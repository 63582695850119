import { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { EDITOR_CONTAINER_ID } from '@features/tpu-simulation-model/constants';
import { IPoint } from '@features/tpu-simulation-model/types';

interface ClueProps {
  isScaleBeingMeasured: boolean;
  startPoint: IPoint | null;
  endPoint: IPoint | null;
}

export const Clue = ({
  startPoint,
  endPoint,
  isScaleBeingMeasured,
}: ClueProps) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const text = startPoint
    ? 'Кликните чтобы установить конечную точку'
    : 'Поставьте первую точку';

  useEffect(() => {
    const editorContainer = document.getElementById(EDITOR_CONTAINER_ID);
    if (!editorContainer) return;

    const handleMousePosition = (e: MouseEvent) => {
      const { left, top } = editorContainer.getBoundingClientRect();
      setMousePosition({
        x: e.clientX - left,
        y: e.clientY - top,
      });
    };

    editorContainer?.addEventListener('mousemove', handleMousePosition);
    return () =>
      editorContainer?.removeEventListener('mousemove', handleMousePosition);
  }, []);

  if (!isScaleBeingMeasured || endPoint) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        left: mousePosition.x,
        top: mousePosition.y + 50,
        backgroundColor: theme => theme.palette.background.paper,
        boxShadow: '0px 2px 4px 0px #0000001A',
        borderRadius: '8px',
        padding: '12px',
      }}
    >
      {text}
    </Box>
  );
};
