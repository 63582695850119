import { IQuery } from '@api/gql/types';
import { useMemo } from 'react';

import { Divider } from '@mui/material';

import { InspectorCircleIcon } from '@components/InspectorCircleIcon/InspectorCircleIcon';
import { MapFilterField } from '@components/MapFilters';

import { AccordionInspector } from '@features/passenger-traffic/components/Inspector/AccordionInspector';
import { ContainerTransport } from '@features/passenger-traffic/components/Inspector/ContainerTransport';
import { PathCreator } from '@features/passenger-traffic/components/Inspector/PathCreator';
import { TransportRoute } from '@features/passenger-traffic/components/Inspector/TransportRoute';
import { ContentTrafficFrequency } from '@features/passenger-traffic/components/Inspector/contentTrafficFrequency';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { DataVehicleType } from '@features/passenger-traffic/stores/inspector/RouteNetwork';

import { endingCountingCouple } from '@utils/dataForInspector';

type RailwayInspectorProps = {
  layout: EMapFeatureLayout | null;
  filter: MapFilterField;
  nightTrainsData: Omit<DataVehicleType, 'frequency'>;
  dayTrainsData: Omit<DataVehicleType, 'frequency'>;
  suburbanTrainsData: Omit<DataVehicleType, 'frequency'>;
  nightTrains: IQuery['getRailwayBetweenTrs'];
  dayTrains: IQuery['getRailwayBetweenTrs'];
  suburbanTrains: IQuery['getRailwayBetweenTrs'];
};

export const RailwayInspector = (props: RailwayInspectorProps) => {
  const {
    layout,
    filter,
    nightTrainsData,
    dayTrainsData,
    suburbanTrainsData,
    nightTrains,
    dayTrains,
    suburbanTrains,
  } = props;

  const layoutContent = useMemo(() => {
    return (
      <>
        {filter.nightTrain && (
          <ContainerTransport
            title="Ночной поезд дальнего следования"
            icon={{
              borderColor: 'black',
              backgroundColor: 'trainNight',
            }}
            price={nightTrainsData?.price}
            time={nightTrainsData?.duration}
            isExists={
              !!(
                nightTrainsData?.price ||
                nightTrainsData?.duration ||
                nightTrains?.length
              )
            }
          >
            {nightTrains?.length ? (
              <ContentTrafficFrequency
                title={`${nightTrains?.length} пар${endingCountingCouple(
                  nightTrains?.length,
                )} поездов в сутки`}
              >
                {nightTrains?.map((train, i) => (
                  <TransportRoute
                    title={`${train?.trainName} ${train?.pointFrom} — ${train?.pointTo}`}
                    key={i}
                  >
                    <PathCreator
                      stations={train?.stations}
                      layout={'nightTrain'}
                    />
                  </TransportRoute>
                ))}
              </ContentTrafficFrequency>
            ) : (
              'н/д'
            )}
          </ContainerTransport>
        )}

        {filter.nightTrain && filter.dayTrain && <Divider sx={{ my: 2 }} />}

        {filter.dayTrain && (
          <ContainerTransport
            title="Дневной поезд дальнего следования"
            icon={{
              borderColor: 'black',
              backgroundColor: 'trainDay',
            }}
            price={dayTrainsData?.price}
            time={dayTrainsData?.duration}
            isExists={
              !!(
                dayTrainsData?.price ||
                dayTrainsData?.duration ||
                dayTrains?.length
              )
            }
          >
            {dayTrains?.length ? (
              <ContentTrafficFrequency
                title={`${dayTrains?.length} пар${endingCountingCouple(
                  dayTrains?.length,
                )} поездов в сутки`}
              >
                {dayTrains?.map((train, i) => (
                  <TransportRoute
                    title={`${train?.trainName} ${train?.pointFrom} — ${train?.pointTo}`}
                    key={i}
                  >
                    <PathCreator
                      stations={train?.stations}
                      layout={'dayTrain'}
                    />
                  </TransportRoute>
                ))}
              </ContentTrafficFrequency>
            ) : (
              'н/д'
            )}
          </ContainerTransport>
        )}

        {(filter.nightTrain || filter.dayTrain) && filter.suburbanTrain && (
          <Divider sx={{ my: 2 }} />
        )}

        {filter.suburbanTrain && (
          <ContainerTransport
            title="Пригородный поезд"
            icon={{
              borderColor: 'black',
              backgroundColor: 'trainLocal',
            }}
            price={suburbanTrainsData?.price}
            time={suburbanTrainsData?.duration}
            isExists={
              !!(
                suburbanTrainsData?.price ||
                suburbanTrainsData?.duration ||
                suburbanTrains?.length
              )
            }
          >
            {suburbanTrains?.length ? (
              <ContentTrafficFrequency
                title={`${suburbanTrains?.length} пар${endingCountingCouple(
                  suburbanTrains?.length,
                )} поездов в сутки`}
              >
                {suburbanTrains?.map((train, i) => (
                  <TransportRoute
                    title={`${train?.trainName} ${train?.pointFrom} — ${train?.pointTo}`}
                    key={i}
                  >
                    <PathCreator
                      stations={train?.stations}
                      layout={'suburbanTrain'}
                    />
                  </TransportRoute>
                ))}
              </ContentTrafficFrequency>
            ) : (
              'н/д'
            )}
          </ContainerTransport>
        )}
      </>
    );
  }, [
    dayTrains,
    dayTrainsData?.duration,
    dayTrainsData?.price,
    filter.dayTrain,
    filter.nightTrain,
    filter.suburbanTrain,
    nightTrains,
    nightTrainsData?.duration,
    nightTrainsData?.price,
    suburbanTrains,
    suburbanTrainsData?.duration,
    suburbanTrainsData?.price,
  ]);

  const renderRailwayInspector = useMemo(() => {
    if (layout === null && filter.railwayRoot) {
      return (
        <AccordionInspector
          icon={
            <InspectorCircleIcon
              borderColor={'train'}
              backgroundColor={'white'}
            />
          }
          title={'ЖД транспорт'}
        >
          {layoutContent}
        </AccordionInspector>
      );
    } else if (layout === EMapFeatureLayout.railway && filter.railwayRoot) {
      return layoutContent;
    }
  }, [filter.railwayRoot, layout, layoutContent]);

  return renderRailwayInspector;
};
