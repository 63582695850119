import { sample } from 'effector';

import { UIApi } from './store';

import { FilterRegionApi } from '@features/pt-forecast/stores';
import { setComputingresultsSet } from '@features/pt-forecast/stores/computed/store';

import { resetAllFilters } from '../filters';

// отображение панели прогноза при получении данных
sample({
  clock: setComputingresultsSet,
  filter: payload => Boolean(payload),
  fn: () => true,
  target: UIApi.setVisibleForecastPanel,
});

// скрытие панели параметров прогноза при очистке точек АБ
sample({
  clock: FilterRegionApi.clearSelectedRegions,
  fn: () => false,
  target: UIApi.setVisibleParamsForecastPanel,
});

// скрытие панели прогноза при очистке точек АБ
sample({
  clock: FilterRegionApi.clearSelectedRegions,
  fn: () => false,
  target: UIApi.setVisibleForecastPanel,
});

// Сброс панелей
sample({
  clock: resetAllFilters,
  target: UIApi.resetUI,
});
