import { useUnit } from 'effector-react';
import { ChangeEvent, useCallback, useEffect } from 'react';

import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { AccordionFilter } from '@components/MapFilters/AccordionFilter';

import { SOCECO_BLOCK_IDS } from '@widgets/widget-soceco-indicators/constants/tables/blockIds';
import { SOCECO_TABLES_BLOCKS } from '@widgets/widget-soceco-indicators/constants/tables/blocks';
import {
  SOCECO_BLOCK_TITLES,
  SOCECO_TABLES_TITLES,
} from '@widgets/widget-soceco-indicators/constants/tables/titles';
import { $Settings } from '@widgets/widget-soceco-indicators/stores/settings/store';
import {
  $SocEcoTable,
  SocEcoTableApi,
} from '@widgets/widget-soceco-indicators/stores/tables/store';
import { getDataForSocEcoTable } from '@widgets/widget-soceco-indicators/utils/getDataForSocEcoTable';

export const FilterContainer = () => {
  const { selectedTable } = useUnit($SocEcoTable);
  const { setTable } = useUnit(SocEcoTableApi);
  const { scenarioId, centerAGCoords } = useUnit($Settings);

  const HandlerChangeTable = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTable(event.target.value);
    },
    [setTable],
  );

  useEffect(() => {
    if (selectedTable && scenarioId)
      getDataForSocEcoTable(
        selectedTable,
        scenarioId,
        centerAGCoords as number[],
      );
  }, [centerAGCoords, scenarioId, selectedTable]);

  return (
    <AutoFullHeight
      style={{
        paddingTop: '24px',
        minWidth: '500px',
        maxWidth: '500px',
        overflowY: 'auto',
        backgroundColor: 'white',
        height: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.1) 5px 4px 2px 0px',
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          pt: 1,
          pb: 3,
          pl: 3,
          pr: 1,
        }}
      >
        {Object.values(SOCECO_BLOCK_IDS).map(block => (
          <AccordionFilter
            sx={accordionStyleSX}
            title={SOCECO_BLOCK_TITLES[block]}
            key={block}
          >
            <RadioGroup
              value={selectedTable}
              onChange={HandlerChangeTable}
            >
              {SOCECO_TABLES_BLOCKS[block].map(table => (
                <Tooltip
                  title={SOCECO_TABLES_TITLES[table]}
                  placement="top"
                  key={table}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={SOCECO_TABLES_TITLES[table]}
                    value={table}
                    sx={textEllipsisStyleSX}
                  />
                </Tooltip>
              ))}
            </RadioGroup>
          </AccordionFilter>
        ))}
      </Box>
    </AutoFullHeight>
  );
};

const textEllipsisStyleSX = {
  ['& .MuiTypography-root']: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '400px',
  },
};

const accordionStyleSX = {
  width: '100%',
};
