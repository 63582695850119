import { Icon, Style } from 'ol/style';

import AirportSelectedSVG from '@components/icons/AirportSelected.svg';
import BusStationSelectedSVG from '@components/icons/BusStationSelected.svg';
import RailwayStationSelectedSVG from '@components/icons/RailwayStationSelected.svg';

import { EMapFeatureLayout, EMapFeatureMetaKeys } from '@constants/map';

import { VectorStyleProps } from '@utils/map/styles/types';

export const ptSearchInfraVectorStyle = (props: VectorStyleProps) => {
  const { feature } = props;
  const styles: Style[] = [];
  const layout = feature!.get(EMapFeatureMetaKeys.layout) as EMapFeatureLayout;

  if (layout === EMapFeatureLayout.airport) {
    styles.push(
      new Style({
        image: new Icon({
          src: AirportSelectedSVG,
        }),
      }),
    );
  } else if (layout === EMapFeatureLayout.busStation) {
    styles.push(
      new Style({
        image: new Icon({
          src: BusStationSelectedSVG,
        }),
      }),
    );
  } else if (layout === EMapFeatureLayout.railwayStation) {
    styles.push(
      new Style({
        image: new Icon({
          src: RailwayStationSelectedSVG,
        }),
      }),
    );
  } else
    throw new Error(`Unknown layout for ptSearchInfraVectorStyle - ${layout}`);

  return styles;
};
