import {
  IMutation,
  IMutationUpdateScenarioParamsArgs,
  IQuery,
  IQueryGetGraphDirectionsArgs,
  IQueryGetScenarioParamsArgs,
} from '@api/gql/types';
import { createApi, createEffect, createEvent, createStore } from 'effector';

const initState: TableGraphParamsType = {
  directions: [],
};

export const $DirectionsParamsTable =
  createStore<TableGraphParamsType>(initState);

export const DirectionsParamsTableApi = createApi($DirectionsParamsTable, {
  updateAllParamsDirections: (
    store,
    directions: TableGraphParamsType['directions'],
  ) => ({
    ...store,
    directions,
  }),
  resetDirectionsParams: () => initState,
});

// Триггер для обновления параметров
export const requestUpdateParamsDirection = createEvent();

export const getGraphDirectionsFx = createEffect<
  IQueryGetGraphDirectionsArgs,
  IQuery['getGraphDirections'] | undefined
>();

export const getScenarioParamsFx = createEffect<
  IQueryGetScenarioParamsArgs,
  IQuery['getScenarioParams'] | undefined
>();

export const updateScenarioParamsFx = createEffect<
  IMutationUpdateScenarioParamsArgs,
  IMutation['updateScenarioParams'] | undefined
>();

export type GraphParamsType = Record<
  string,
  string | number | boolean | null | undefined
>;

type TableGraphParamsType = {
  /** Направления для таблицы параметров */
  directions: GraphParamsType[];
};
