import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddRailwayStation = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <circle
        cx="12.5"
        cy="12"
        r="11.5"
        fill="#E21A1A"
        stroke="white"
      />
      <path
        d="M16.6426 17.8638L14.8383 15.1404C15.434 14.9702 16.0298 14.3574 16.0298 13.5234V7.66809C16.0298 6.74894 15.2638 6 14.3277 6H12.3362H10.3277C9.39149 6 8.62553 6.74894 8.62553 7.66809V13.5234C8.62553 14.3404 9.22128 14.9702 9.81702 15.1404L8.01277 17.8638C7.99574 17.8979 7.99574 17.9149 8.01277 17.9489C8.02979 17.983 8.06383 18 8.08085 18H9.15319C9.18723 18 9.20425 17.983 9.22128 17.966L10.5319 16.0255H14.1064L15.417 17.9489C15.434 17.966 15.4511 17.983 15.4851 17.983H16.5745C16.6085 17.983 16.6255 17.966 16.6426 17.9319C16.6596 17.9149 16.6596 17.8808 16.6426 17.8638ZM9.64681 9.60851V8.55319C9.64681 8.17872 9.86808 7.82128 10.3787 7.82128H14.2766C14.7702 7.82128 15.0085 8.19574 15.0085 8.55319V9.60851C15.0085 9.81277 14.9404 10 14.8043 10.1362C14.6681 10.2723 14.4809 10.3404 14.2766 10.3404H10.3787C10.1745 10.3404 9.98723 10.2723 9.85106 10.1362C9.71489 10 9.64681 9.81277 9.64681 9.60851ZM10.2936 12.8596C10.634 12.8596 10.9234 13.1489 10.9234 13.4894C10.9234 13.8468 10.634 14.1191 10.2936 14.1191C9.95319 14.1191 9.66383 13.8298 9.66383 13.4894C9.66383 13.1489 9.93617 12.8596 10.2936 12.8596ZM14.3277 12.8596C14.6681 12.8596 14.9574 13.1489 14.9574 13.4894C14.9574 13.8468 14.6681 14.1191 14.3277 14.1191C13.9872 14.1191 13.6979 13.8298 13.6979 13.4894C13.7149 13.1489 13.9872 12.8596 14.3277 12.8596Z"
        fill="white"
      />
    </SvgIcon>
  );
};
