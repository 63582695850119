import {
  IAirportType,
  IHighwayType,
  IRailwayType,
  ITransportRegionType,
  Scalars,
} from '@api/gql/types';
import { createEffect, createEvent, createStore } from 'effector';
import { Feature } from 'ol';
import { Geometry, MultiPolygon, Point } from 'ol/geom';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';

import {
  CustomMap,
  addTileLayer,
  removeLayerFeaturesFromMap,
  setLayerFeaturesToMap,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { setStyleTileLayer } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/layerTools';
import {
  setLayoutFn,
  setStartPointFn,
} from '@features/passenger-traffic/stores';
import { EVehicle } from '@features/pt-forecast/constants/vehiclesEnum';

export const default$MapStore: Store = {
  allRailways: [],
  allHighways: [],
  allAirports: [],
  allTransportRegions: [],
  passengerTrafficPercent: {
    nightTrain: 0,
    dayTrain: 0,
    suburbanTrain: 0,
    bus: 0,
    avia: 0,
    auto: 0,
    expressTrain: 0,
  },
  passengerTraffic: {
    nightTrain: 0,
    dayTrain: 0,
    suburbanTrain: 0,
    bus: 0,
    avia: 0,
    auto: 0,
    expressTrain: 0,
  },
  map: null,
};

export const $Map = createStore<Store>(default$MapStore);
// Эвент ререндера слоев карты
export const setMapFeatures = createEvent<SetMapFeaturesTypes>();
// Добавить тайловый слой
export const addMapTileLayer = createEvent<AddMapTileLayer>();
// Применить стилизацию к тайловому слою
export const setMapLayerStyle = createEvent<SetMapLayerStyleTypes>();
// Установка пасстрфика для стора карты
export const setMapPassTraffic = createEvent<Store['passengerTraffic']>();
// Установка процентного соотношения для пасстрафика стора карты
export const setMapPassTrafficPercent =
  createEvent<Store['passengerTrafficPercent']>();
// Эвент инициализации карты
export const setMapFn = createEvent<Store['map']>();
//
export const clearPointsFn = createEvent();
// Единственный тригер который должен сообщать карте что что-то изменилось
export const changeMap = createEvent();

export const getAirportsFx = createEffect<
  void,
  Scalars['PointScalar']['output']
>();
export const getTransportRegionsFx = createEffect<
  void,
  Scalars['MultiPolygonScalar']['output']
>();

export const setAllAirports = createEvent<IAirportType[]>();
export const setAllTransportRegions = createEvent<ITransportRegionType[]>();
export const refreshMapLayer = createEvent<EMapFeatureLayout>();

$Map
  .on(setMapFeatures, (state, payload) => {
    if (!state.map) {
      return state;
    }
    removeLayerFeaturesFromMap({
      layout: payload.params.layout,
      map: state.map,
    });

    if (payload.features.length || payload.params.visible === true) {
      setLayerFeaturesToMap({
        features: payload.features,
        layout: payload.params.layout,
        map: state.map,
        width: payload.params.width,
      });
    }

    return state;
  })
  .on(refreshMapLayer, (state, payload) => {
    if (!state.map) {
      return state;
    }
    state.map.getLayers().forEach(layer => {
      if (layer.getClassName() !== payload) return;
      if (!(layer instanceof VectorTileLayer)) return;
      const source = layer.getSource();
      if (!source) return;
      source.refresh();
    });

    return state;
  })
  // Применение стандартный стилей для слоя
  .on(setMapLayerStyle, (state, payload) => {
    setStyleTileLayer({
      layout: payload.layout,
      width: payload.width,
      map: state.map,
    });
    return state;
  })
  .on(setAllAirports, (state, payload) => {
    return {
      ...state,
      allAirports: payload,
    };
  })
  .on(setAllTransportRegions, (state, payload) => {
    return {
      ...state,
      allTransportRegions: payload,
    };
  })
  .on(setMapFn, (store, payload) => {
    store.map = payload;
    return store;
  })
  .on(addMapTileLayer, (state, payload) => {
    if (!state.map) return console.error('try add tile layer before init map');
    addTileLayer({
      ...payload,
      map: state.map,
    });
    return state;
  })
  .on(setStartPointFn, (state, payload) => {
    if (!state.map) {
      return state;
    }

    state.map.isSetPoint = payload;
    return state;
  })
  .on(setLayoutFn, (state, payload) => {
    if (!state.map) {
      return state;
    }
    state.map.filterLayout = payload;
    return state;
  })
  .on(setMapPassTrafficPercent, (state, payload) => ({
    ...state,
    passengerTrafficPercent: payload,
  }))
  .on(setMapPassTraffic, (state, payload) => ({
    ...state,
    passengerTraffic: payload,
  }));

export type Store = {
  allRailways: IRailwayType[];
  allHighways: IHighwayType[];
  allAirports: IAirportType[];
  allTransportRegions: ITransportRegionType[];
  passengerTrafficPercent: Record<
    Exclude<keyof typeof EVehicle, 'multimodal'>,
    number
  >;
  passengerTraffic: Record<
    Exclude<keyof typeof EVehicle, 'multimodal'>,
    number
  >;
  map: CustomMap | null | undefined;
};

export type SetMapFeaturesTypes = {
  params: {
    isAllInfrastructure?: boolean;
    layout: EMapFeatureLayout;
    width?: number;
    visible?: boolean;
  };
  features: Feature<Geometry | MultiPolygon | Point>[];
};

export type AddMapTileLayer = {
  source: VectorTileSource;
  layout: EMapFeatureLayout;
  zIndex: number;
};

export type SetMapLayerStyleTypes = {
  layout: EMapFeatureLayout;
  width?: number;
};
