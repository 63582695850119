import { IDataFileStateEnum, IDownloadFileTypeEnum } from '@api/gql/tpu-types';

import { FileUploader } from './FileUploader';

import { Link, Stack } from '@mui/material';

import { UseProjectRegistryHandlers } from '@features/tpu-project-registry/hooks';
import { IFloorFile } from '@features/tpu-project-registry/types/form';

export interface FloorFilesUploaderProps {
  floorFiles: IFloorFile[];
  onFileChange: (
    file: File | undefined,
    index: number,
    signal: AbortSignal,
  ) => Promise<void>;
  onAddField: () => void;
  onLabelChange: (description: string, index: number) => void;
  onDeleteFile: (index: number) => void;
  downloadFile: UseProjectRegistryHandlers['downloadFile'];
  changeFloorFilesOrder: UseProjectRegistryHandlers['changeFloorFilesOrder'];
}

export const FloorFilesUploader = ({
  floorFiles,
  downloadFile,
  onFileChange,
  onAddField,
  onLabelChange,
  onDeleteFile,
  changeFloorFilesOrder,
}: FloorFilesUploaderProps) => {
  const handleFileChange =
    (index: number) => async (file: File | undefined, signal: AbortSignal) => {
      await onFileChange(file, index, signal);
    };

  const handleLabelChange = (index: number) => (description: string) => {
    onLabelChange(description, index);
  };
  const handleDeleteFile = (index: number) => () => {
    onDeleteFile(index);
  };

  return (
    <Stack
      sx={{
        fontSize: theme => theme.typography.pxToRem(12),
        lineHeight: theme => theme.typography.pxToRem(16),
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      {floorFiles.map((data, index) => {
        return (
          <FileUploader
            key={data.uuid}
            fileStatus={data.id ? IDataFileStateEnum.Success : null}
            uuid={data.uuid}
            draggable
            accept={'.jpeg, .jpg, .png'}
            onDownloadFile={() => {
              if (data.id)
                downloadFile({
                  id: data.id,
                  type: IDownloadFileTypeEnum.Floor,
                });
            }}
            onFileChange={handleFileChange(index)}
            onLabelChange={handleLabelChange(index)}
            onDelete={handleDeleteFile(index)}
            changeFloorFilesOrder={changeFloorFilesOrder}
            label={data.description}
            sx={{
              padding: '10px 10px 10px 16px',
            }}
          />
        );
      })}

      <Link
        onClick={onAddField}
        sx={{
          m: '4px auto 8px 4px',
          fontSize: 'inherit',
          lineHeight: 'inherit',
          cursor: 'pointer',
        }}
      >
        Добавить этаж
      </Link>
    </Stack>
  );
};
