import { sample } from 'effector';
import { Feature } from 'ol';
import { Geometry, LineString, Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EMapFeatureLayout } from '@constants/map';

import {
  $LayersVisible,
  LayersVisibleApi,
  pipeTransportFlowVectorLayer,
} from '@features/ag-forecast/stores/map';
import { $DistrictPassFlow } from '@features/ag-forecast/stores/map/districtPassFlow';
import {
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

import {
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';
import { pointDirection } from '@utils/map/styles/agTransportFlowDistrictsVectorStyle';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload &&
    Object.keys(payload).includes(EMapFeatureLayout.transportFlowDistricts),
  fn: payload => {
    return {
      action: EPipeVectorLayer.setVisible,
      payload: payload![EMapFeatureLayout.transportFlowDistricts],
    } as PipeVectorLayer;
  },
  target: pipeTransportFlowVectorLayer,
});

// Рендер геометрии
sample({
  clock: $DistrictPassFlow,
  source: { DistrictPassFlow: $DistrictPassFlow, LayerVisible: $LayersVisible },
  // filter: ({LayerVisible}) => !!LayerVisible[EMapFeatureLayout.transportFlowDistricts],
  fn: ({ DistrictPassFlow }) => {
    const features: Feature<Geometry>[] = [];

    DistrictPassFlow.directions.forEach(direction => {
      if (direction.flow === 0) return;
      features.push(
        new Feature({
          geometry: new Point(fromLonLat(direction.toPoint)),
          [layerLayoutKey]: EMapFeatureLayout.transportFlowDistricts,
          direction: pointDirection.to,
        }),
        new Feature({
          geometry: new Point(fromLonLat(direction.fromPoint)),
          [layerLayoutKey]: EMapFeatureLayout.transportFlowDistricts,
          direction: pointDirection.from,
        }),
        new Feature({
          geometry: new LineString([
            fromLonLat(direction.fromPoint),
            fromLonLat(direction.toPoint),
          ]),
          [layerLayoutKey]: EMapFeatureLayout.transportFlowDistricts,
          [layerIdKey]: direction.id,
          flow: direction.flow,
          maxFlow: DistrictPassFlow.maxFlow,
          name: direction.name,
        }),
      );
    });

    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: {
        features,
      },
    } as PipeVectorLayer;
  },
  target: pipeTransportFlowVectorLayer,
});
