export enum EVehicle {
  multimodal = 'multimodal',
  auto = 'auto',
  bus = 'bus',
  dayTrain = 'dayTrain',
  nightTrain = 'nightTrain',
  suburbanTrain = 'suburbanTrain',
  expressTrain = 'expressTrain',
  avia = 'avia',
}
