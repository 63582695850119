import { SetFeatureStyleProps } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/getFeatureStyle';
import { Icon, Style } from 'ol/style';

import AirportSVG from '@components/icons/Airport.svg';
import AirportSelectedSVG from '@components/icons/AirportSelected.svg';

export type GetAirportStyleArgs = Pick<SetFeatureStyleProps, 'active'>;

export const getAirportStyle = ({ active }: GetAirportStyleArgs) =>
  new Style({
    image: new Icon({
      src: active ? AirportSelectedSVG : AirportSVG,
    }),
  });
