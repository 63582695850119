import { useUnit } from 'effector-react';

import { Box } from '@mui/material';

import { SocioEconomicForm } from '@features/tpu-calculation-scenarios/forms';
import { useContextCSHandlers } from '@features/tpu-calculation-scenarios/hooks';
import {
  $Form,
  $IsOpenForm,
} from '@features/tpu-calculation-scenarios/stores/form.store';

export const FormCalculationScenarioContainer = () => {
  const $isOpenForm = useUnit($IsOpenForm);
  const $form = useUnit($Form);
  const { saveScenario, closeFormState } = useContextCSHandlers();

  if (!$isOpenForm) return <Box sx={{ flex: '383', padding: '24px' }} />;

  return (
    <Box
      sx={{
        flex: '383',
        boxShadow: '0px 0px 8px 0px #00000026',
        borderRadius: '8px',
        backgroundColor: theme => theme.palette.background.paper,
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        overflow: 'auto',
        position: 'relative',
      }}
    >
      <SocioEconomicForm
        formData={$form}
        onClose={closeFormState}
        onSubmit={saveScenario}
      />
    </Box>
  );
};
