import { useUnit } from 'effector-react';
import { useCallback, useRef } from 'react';

import {
  $ListOfFloorsWithListOfObjects,
  $ScenarioId,
  savePlanAfterCheckingErrorsFx,
} from '@features/tpu-simulation-model/store';
import { $ListOfRulers } from '@features/tpu-simulation-model/store/ruler/listOfRulers.store';

export const useSavePlanCallback = () => {
  const stores = useUnit({
    plan: $ListOfFloorsWithListOfObjects,
    id: $ScenarioId,
    ruler: $ListOfRulers,
  });
  const event = useUnit(savePlanAfterCheckingErrorsFx);
  const storesRef = useRef(stores);
  storesRef.current = stores;

  const savePlanCallback = useCallback(
    async (closeWindowAfterSave: boolean = false) => {
      const { plan, id, ruler } = storesRef.current;
      if (!id) return false;

      return await event({
        plan,
        id,
        ruler,
        closeWindowAfterSave,
      });
    },
    [event],
  );

  return savePlanCallback;
};
