import { Stroke, Style } from 'ol/style';
import { Options } from 'ol/style/Style';

import { alpha } from '@mui/material';

import { palette } from '@system/theme/palette';

import { TileStyleProps } from '@utils/map/styles/types';
import { createActiveFeatureBorder } from '@utils/map/tools/createActiveFeatureBorder';

export const graphWaterWayTileStyle = (props: TileStyleProps) => {
  const { isActive, feature, isPlanned, isActual, actualYear } = props;
  const styles = isActive ? [createActiveFeatureBorder(3)] : [];
  const featureYear = feature!.get('year') as number;

  let options: Options | null = null;

  if (isActual && featureYear <= (actualYear || featureYear)) {
    options = {
      stroke: new Stroke({
        color: palette.customs.waterTransport,
        width: 3,
      }),
    };
  } else if (isPlanned && featureYear > actualYear!) {
    options = {
      stroke: new Stroke({
        color: alpha(palette.customs.waterTransport, 0.5),
        width: 3,
      }),
    };
  }

  styles.push(new Style(options || {}));
  return styles;
};
