import { ITurnstileType } from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

export const $ListOfTurnstiles = createStore<ITurnstileType[]>([]);

export const getTurnstilesFn = createEvent();

export const getTurnstilesByScenarioIdFx = createEffect<
  string,
  ITurnstileType[] | null
>();

export const resetTurnstilesFn = createEvent();
