import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

import { EMapFeatureMetaKeys } from '@constants/map';

import { EFeatureStyle } from '@utils/map/hooks/useInitVectorLayer';

import { VectorStyleProps } from '../../types';

export const ptCustomRailwayGraphVectorStyle = (props: VectorStyleProps) => {
  const { featureStyles, feature } = props;
  const currentWidth = 2;
  let active = false;

  if (
    featureStyles &&
    Object.keys(featureStyles).includes(
      String(feature?.get(EMapFeatureMetaKeys.olId)),
    )
  ) {
    const style = featureStyles[feature?.get(EMapFeatureMetaKeys.olId)];
    switch (style) {
      case EFeatureStyle.selected:
        active = true;
        break;
    }
  }

  const styles = [];

  styles.push(
    new Style({
      stroke: new Stroke({
        lineCap: 'square',
        color: active ? '#3679EA' : '#7A5A5A',
        width: currentWidth,
      }),
      image: new CircleStyle({
        radius: 2,
        fill: new Fill({
          color: active ? '#3679EA' : '#7A5A5A',
        }),
      }),
    }),
  );

  return styles;
};
