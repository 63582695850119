import { useState } from 'react';
import { Link as LinkInner } from 'react-router-dom';

import { LockPersonOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { ArrowRightIcon } from '@components/icons';

import { CardModuleStyled } from '@features/modules/components/card/CardModule.styled';

type CardModuleProps = {
  image: string;
  title: string;
  description: string;
  link: string;
  isPlanned?: boolean;
  locked?: boolean;
};

export const CardModule = (props: CardModuleProps) => {
  const { image, title, description, link, isPlanned, locked } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <CardModuleStyled>
      {locked && (
        <Tooltip
          placement="top"
          title={'У вас нет доступа к этому модулю'}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              bgcolor: 'rgba(255,255,255,0.7)', // полупрозрачный белый фон
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1, // убедитесь, что заглушка будет поверх контента
              borderRadius: '8px', // тот же радиус, что и у CardModuleStyled
              '&:hover': {
                svg: {
                  opacity: 0.45,
                },
              },
            }}
          >
            <LockPersonOutlined
              sx={{
                fontSize: 120,
                opacity: 0.4,
                color: '#000',
                transition: 'opacity 0.3s ease-in-out',
              }}
            />
          </Box>
        </Tooltip>
      )}
      <Stack
        m="24px"
        height="calc(404px - (24px * 2))"
        justifyContent="space-between"
      >
        <Stack gap="24px">
          <Box
            sx={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              width: '307px',
              height: '166px',
              borderRadius: '4px',
              filter: isPlanned ? 'grayscale(1)' : 'none',
              opacity: isPlanned ? 0.5 : 1,
            }}
          />
          <Stack gap="8px">
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: ({ typography }) => typography.pxToRem(14),
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: ({ typography }) => typography.pxToRem(14),
              }}
            >
              {description}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          gap="12px"
        >
          <Button
            fullWidth
            disabled={isPlanned || locked}
            component={LinkInner}
            color="secondary"
            sx={{
              height: '32px',
              opacity: isPlanned ? 0.5 : 1,
            }}
            to={link}
          >
            Перейти
          </Button>
          <Button
            disabled={isPlanned || locked}
            onClick={handleClick}
            sx={{
              height: '32px',
              width: '32px',
              minWidth: '32px',
              opacity: isPlanned ? 0.5 : 1,
            }}
          >
            <ArrowRightIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            slotProps={{
              paper: {
                sx: {
                  borderRadius: '8px',
                  boxShadow: '0px 0px 8px 0px #00000026',
                },
              },
            }}
          >
            <MenuItem onClick={handleClose}>
              <Link fontSize="14px">Руководство пользователя</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link fontSize="14px">Видео уроки</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link fontSize="14px">FAQ по работе модуля</Link>
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
    </CardModuleStyled>
  );
};
