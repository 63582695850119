import { apiClient } from '@api/client';
import {
  IHighwayHighwayTypeChoices,
  IQuery,
  IQueryGetAirportByIdArgs,
  IQueryGetBusStationByIdArgs,
  IQueryGetHighwayByIdArgs,
  IQueryGetRailwayByIdArgs,
  IQueryGetRailwayStationByIdArgs,
  IQueryGetRegionByIdArgs,
  IRailwayRailwayTractionTypeChoices,
} from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { EMapFeatureLayout } from '@constants/map';

import {
  AirportInspectorState,
  HighwayInspectorState,
  InspectorInfrastructureApi,
  RailwayInspectorState,
  RailwayStationInspectorState,
  TransportRegionInspectorState,
  getAirportForInspectorFx,
  getBusStationForInspectorFx,
  getHighwayForInspectorFx,
  getRailwayForInspectorFx,
  getRailwayStationForInspectorFx,
  getRegionForInspectorFx,
} from '@features/pt-forecast-new/stores/inspector/infrastructure/store';

// Запросить данные для жд пути
sample({
  clock: InspectorInfrastructureApi.getForFeature,
  filter: payload => payload.layout === EMapFeatureLayout.railway,
  fn: payload =>
    ({
      id: payload.id,
    }) as IQueryGetRailwayByIdArgs,
  target: getRailwayForInspectorFx,
});

getRailwayForInspectorFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getRailwayById'>,
    IQueryGetRailwayByIdArgs
  >({
    query: gql`
      query QueryGetRailwayById($id: String) {
        getRailwayById(id: $id) {
          railwayName
          tractionType
          tracks
          capacity
          speed
        }
      }
    `,
    variables: {
      id: params.id,
    },
  });
  return result.data.getRailwayById || null;
});

sample({
  clock: getRailwayForInspectorFx.done,
  fn: request => {
    return {
      railwayName: request.result?.railwayName || '',
      tracks: request.result?.tracks || 0,
      capacity: request.result?.capacity || 0,
      speed: request.result?.speed || 0,
      tractionType: getTractionTypeValue(request.result?.tractionType || ''),
    } as RailwayInspectorState;
  },
  target: InspectorInfrastructureApi.setRailway,
});

// Запросить данные для жд станции
sample({
  clock: InspectorInfrastructureApi.getForFeature,
  filter: payload => payload.layout === EMapFeatureLayout.railwayStation,
  fn: payload =>
    ({
      id: payload.id,
    }) as IQueryGetRailwayStationByIdArgs,
  target: getRailwayStationForInspectorFx,
});

getRailwayStationForInspectorFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getRailwayStationById'>,
    IQueryGetRailwayStationByIdArgs
  >({
    query: gql`
      query QueryGetRailwayStationById($id: String) {
        getRailwayStationById(id: $id) {
          name
          details {
            passTraffic
          }
        }
      }
    `,
    variables: {
      id: params.id,
    },
  });
  return result.data.getRailwayStationById || null;
});

sample({
  clock: getRailwayStationForInspectorFx.done,
  fn: request => {
    return {
      name: request.result?.name || '',
      passTraffic: request.result?.details?.[0]?.passTraffic || 0,
    } as RailwayStationInspectorState;
  },
  target: InspectorInfrastructureApi.setRailwayStation,
});

// Запросить данные для авто дороги
sample({
  clock: InspectorInfrastructureApi.getForFeature,
  filter: payload => payload.layout === EMapFeatureLayout.highway,
  fn: payload =>
    ({
      id: payload.id,
    }) as IQueryGetHighwayByIdArgs,
  target: getHighwayForInspectorFx,
});

getHighwayForInspectorFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getHighwayById'>,
    IQueryGetHighwayByIdArgs
  >({
    query: gql`
      query QueryGetHighwayById($id: String) {
        getHighwayById(id: $id) {
          highwayName
          type
          speed
        }
      }
    `,
    variables: {
      id: params.id,
    },
  });
  return result.data.getHighwayById || null;
});

sample({
  clock: getHighwayForInspectorFx.done,
  fn: request => {
    return {
      name: request.result?.highwayName || '',
      category: getHighwayTypeValue(request.result?.type || ''),
      speed: request.result?.speed || 0,
    } as HighwayInspectorState;
  },
  target: InspectorInfrastructureApi.setHighway,
});

// Запросить данные для автобусной остановки
sample({
  clock: InspectorInfrastructureApi.getForFeature,
  filter: payload => payload.layout === EMapFeatureLayout.busStation,
  fn: payload =>
    ({
      id: payload.id,
    }) as IQueryGetBusStationByIdArgs,
  target: getBusStationForInspectorFx,
});

getBusStationForInspectorFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getBusStationById'>,
    IQueryGetBusStationByIdArgs
  >({
    query: gql`
      query QueryGetBusStationById($id: String) {
        getBusStationById(id: $id) {
          name
          details {
            passTraffic
          }
        }
      }
    `,
    variables: {
      id: params.id,
    },
  });
  return result.data.getBusStationById || null;
});

sample({
  clock: getBusStationForInspectorFx.done,
  fn: request => {
    return {
      name: request.result?.name || '',
      passTraffic: request.result?.details?.[0]?.passTraffic || 0,
    } as RailwayStationInspectorState;
  },
  target: InspectorInfrastructureApi.setBusStation,
});

// Запросить данные для аэропорта
sample({
  clock: InspectorInfrastructureApi.getForFeature,
  filter: payload => payload.layout === EMapFeatureLayout.airport,
  fn: payload =>
    ({
      id: payload.id,
    }) as IQueryGetAirportByIdArgs,
  target: getAirportForInspectorFx,
});

getAirportForInspectorFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getAirportById'>,
    IQueryGetAirportByIdArgs
  >({
    query: gql`
      query QueryGetAirportById($id: String) {
        getAirportById(id: $id) {
          name
          details {
            passTraffic
            capacityCommon
            range
          }
        }
      }
    `,
    variables: {
      id: params.id,
    },
  });
  return result.data.getAirportById || null;
});

sample({
  clock: getAirportForInspectorFx.done,
  fn: request => {
    return {
      name: request.result?.name || '',
      passTraffic: request.result?.details?.[0]?.passTraffic || 0,
      capacity: request.result?.details?.[0]?.capacityCommon || 0,
      range: request.result?.details?.[0]?.range || 0,
    } as AirportInspectorState;
  },
  target: InspectorInfrastructureApi.setAirport,
});

// Запросить данные для транспортного региона
sample({
  clock: InspectorInfrastructureApi.getForFeature,
  filter: payload => payload.layout === EMapFeatureLayout.areaBorder,
  fn: payload =>
    ({
      id: payload.id,
    }) as IQueryGetRegionByIdArgs,
  target: getRegionForInspectorFx,
});

getRegionForInspectorFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getRegionById'>,
    IQueryGetRegionByIdArgs
  >({
    query: gql`
      query QueryGetRegionById($id: String) {
        getRegionById(id: $id) {
          name
          details {
            averageMonthlySalary
            averageNumberOfEmployeesOfOrganizationsWithoutSmp
            levelOfMotorization
            numberOfPlaces
            population
            revenueTourismIndustry
            revenueWithTaxesOnProducts
            year
          }
        }
      }
    `,
    variables: {
      id: params.id,
    },
  });
  return result.data.getRegionById || null;
});

sample({
  clock: getRegionForInspectorFx.done,
  fn: request => {
    return {
      name: request.result?.name || '',
      averageMonthlySalary:
        request.result?.details?.[0]?.averageMonthlySalary || 0,
      averageNumberOfEmployeesOfOrganizationsWithoutSmp:
        request.result?.details?.[0]
          ?.averageNumberOfEmployeesOfOrganizationsWithoutSmp || 0,
      levelOfMotorization:
        request.result?.details?.[0]?.levelOfMotorization || 0,
      numberOfPlaces: request.result?.details?.[0]?.numberOfPlaces || 0,
      population: request.result?.details?.[0]?.population || 0,
      revenueTourismIndustry:
        request.result?.details?.[0]?.revenueTourismIndustry || 0,
      revenueWithTaxesOnProducts:
        request.result?.details?.[0]?.revenueWithTaxesOnProducts || 0,
    } as TransportRegionInspectorState;
  },
  target: InspectorInfrastructureApi.setTransportRegion,
});

export const getHighwayTypeValue = (value: string) => {
  switch (value) {
    case IHighwayHighwayTypeChoices.A_1:
      return 'региональные/местные';

    case IHighwayHighwayTypeChoices.A_2:
      return 'зимник';

    case IHighwayHighwayTypeChoices.A_3:
      return 'федеральные';

    case IHighwayHighwayTypeChoices.A_4:
      return 'зарубежные';

    case IHighwayHighwayTypeChoices.A_5:
      return 'переправа';

    default:
      return 'неизвестно';
  }
};

export const getTractionTypeValue = (value: string) => {
  switch (value) {
    case IRailwayRailwayTractionTypeChoices.A_1:
      return 'электрифицированные';

    case IRailwayRailwayTractionTypeChoices.A_2:
      return 'не электрифицированные';

    default:
      return 'неизвестно';
  }
};
