import { IHubProjectStateChoices } from '@api/gql/tpu-types';
import { STATE_NAMES } from '@features/tpu-project-registry/constants';

import { Tooltip, TooltipProps, Typography, styled } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

interface StatusProps {
  value: IHubProjectStateChoices;
}

const color: Record<IHubProjectStateChoices, (theme: Theme) => string> = {
  [IHubProjectStateChoices.Success]: theme => theme.palette.success.main,
  [IHubProjectStateChoices.Queue]: theme => theme.palette.text.secondary,
  [IHubProjectStateChoices.Error]: theme => theme.palette.secondary.main,
  [IHubProjectStateChoices.Processing]: theme => theme.palette.text.primary,
  [IHubProjectStateChoices.Draft]: theme => theme.palette.text.primary,
};

const title: Record<IHubProjectStateChoices, string> = {
  [IHubProjectStateChoices.Success]: 'Проверка проекта ТПУ завершена успешно',
  [IHubProjectStateChoices.Queue]:
    'Проект ТПУ находится в очереди на валидацию',
  [IHubProjectStateChoices.Error]:
    'Валидация завершена с ошибками. Для просмотра отчета и внесения исправлений, перейдите в режим редактирования проекта',
  [IHubProjectStateChoices.Processing]:
    'Проект ТПУ находится в процессе валидации',
  [IHubProjectStateChoices.Draft]: 'Черновик',
};

const StyledTooltip = styled(({ children, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: props.className }}
  >
    {children}
  </Tooltip>
))(({ theme }) => ({
  marginRight: '8px',

  [`& .MuiTooltip-tooltip`]: {
    boxShadow: '0px 2px 4px 0px #0000001A',
    borderRadius: '8px',
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const Status = ({ value }: StatusProps) => {
  return (
    <StyledTooltip title={title[value]}>
      <Typography
        sx={{
          fontSize: theme => theme.typography.pxToRem(12),
          lineHeight: theme => theme.typography.pxToRem(16),
          color: theme => color[value](theme),
        }}
      >
        {STATE_NAMES[value]}
      </Typography>
    </StyledTooltip>
  );
};
