import { sample } from 'effector';

import { queryTimelineYears } from '@features/ag-forecast/stores/api/queryTimelineYears';
import {
  YearLineApi,
  getTimelineYearsFx,
} from '@features/ag-forecast/stores/yearLine/store';

getTimelineYearsFx.use(queryTimelineYears);

sample({
  clock: getTimelineYearsFx.done,
  fn: payload => payload.result?.timelineStart,
  target: YearLineApi.setMinYear,
});

sample({
  clock: getTimelineYearsFx.done,
  fn: payload => payload.result?.timelineEnd,
  target: YearLineApi.setMaxYear,
});
