import { Component } from '@features/tpu-calculation-scenarios/component';
import {
  CSHandlersContext,
  useCSHandlers,
} from '@features/tpu-calculation-scenarios/hooks';
import { Modals } from '@features/tpu-calculation-scenarios/modals';
import { useEffect } from 'react';

import './stores/init';

export const CalculationScenariosContainer = () => {
  const value = useCSHandlers();

  useEffect(() => {
    value.getProjectId();

    return () => value.resetScenarios();
  }, [value]);

  return (
    <CSHandlersContext.Provider value={value}>
      <Component />
      <Modals />
    </CSHandlersContext.Provider>
  );
};
