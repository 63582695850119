import { apiClient } from '@api/client';
import {
  Exact,
  IAgentShareInfoType,
  IQuery,
  ISimulationType,
} from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { TPU_CLIENT_NAME } from '@constants/api';

import {
  $MovementsLog,
  $ScenarioData,
  AgentsDataType,
  MovementLogPerTick,
  getScenarioIdFn,
  getSimulationDataBySimulationIdFx,
  resetAgentsFn,
  resetMovementsLogFn,
  setMovementsLogFn,
} from '@features/tpu-calculation-scenarios-viewing/stores';
import {
  $Agents,
  getAgentsInfoFx,
} from '@features/tpu-calculation-scenarios-viewing/stores/agents/agents.store';

$Agents.reset(resetAgentsFn).on(getAgentsInfoFx.done, (_, payload) => {
  return payload.result?.reduce((acc, { category, color }) => {
    return category ? { ...acc, [category]: color } : acc;
  }, {} as AgentsDataType);
});

$MovementsLog
  .reset(resetMovementsLogFn)
  .on(setMovementsLogFn, (state, payload) => {
    return { ...state, ...payload };
  });

sample({
  clock: getScenarioIdFn,
  source: $ScenarioData,
  filter: ({ scenarioId }) => !!scenarioId,
  fn: scenarioData => scenarioData.scenarioId as string,
  target: getAgentsInfoFx,
});

sample({
  clock: getSimulationDataBySimulationIdFx.doneData,
  filter: (clockData): clockData is Exact<ISimulationType> =>
    !!clockData?.movements,
  fn: (clockData: Exact<ISimulationType>) => {
    const movements = clockData.movements;
    const movementsLog = new Map<string, MovementLogPerTick>();

    if (movements) {
      movements.forEach(log => {
        if (log) {
          movementsLog.set(log.index.toString(), log.data);
        }
      });
    }

    return Object.fromEntries(movementsLog);
  },
  target: setMovementsLogFn,
});

getAgentsInfoFx.use(async scenarioId => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query QueryAllAgentShareInfo($scenarioId: UUID!) {
        allAgentShareInfo(scenarioId: $scenarioId) {
          color
          category
        }
      }
    `,
    variables: {
      scenarioId,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return (
    (response.data.allAgentShareInfo?.filter(Boolean) as
      | IAgentShareInfoType[]
      | undefined) ?? null
  );
});
