import { sample } from 'effector';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EFilterMapLayout } from '@components/MapFilters';

import {
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import {
  $FilterMap,
  $FilterRegions,
  SetMapFeaturesTypes,
  changeMap,
  setMapFeatures,
} from '@features/passenger-traffic/stores';

// Контроль рендера фич автобусных остановок
sample({
  clock: changeMap,
  source: { FilterMap: $FilterMap, FilterRegions: $FilterRegions },
  fn: ({ FilterMap, FilterRegions }) => {
    const layer: SetMapFeaturesTypes = {
      params: {
        layout: EMapFeatureLayout.busStation,
      },
      features: [],
    };

    // В фильтре отключено отображение всех дорог
    if (!FilterMap.autoRoot) return layer;

    // Не выбрано отображение существующих структур
    if (
      !FilterMap.actual &&
      FilterMap.layout === EFilterMapLayout.Infrastructure
    )
      return layer;

    // Находимся не на слое инфраструктуры
    if (FilterMap.layout !== EFilterMapLayout.Infrastructure) return layer;

    // Не установлены оба поина АБ
    if (!FilterRegions.selectedRegionA || !FilterRegions.selectedRegionB)
      return layer;

    //Если поинты АБ установлены, отобразить остановки только в их ТР
    if (FilterRegions.selectedRegionA && FilterRegions.selectedRegionB) {
      const stations = [
        ...FilterRegions.selectedRegionA.busstationSet,
        ...FilterRegions.selectedRegionB.busstationSet,
      ];
      layer.features = stations.map(
        station =>
          new Feature({
            geometry: new Point(fromLonLat(station.geometry.coordinates)),
            [layerIdKey]: station.id,
            [layerLayoutKey]: EMapFeatureLayout.busStation,
          }),
      );
      return layer;
    }

    // Пока глобально отображать нечего
    return layer;
  },
  target: setMapFeatures,
});
