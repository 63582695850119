import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const InfoOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.75a7.25 7.25 0 1 0 0 14.5 7.25 7.25 0 0 0 0-14.5ZM3.25 12a8.75 8.75 0 1 1 17.5 0 8.75 8.75 0 0 1-17.5 0Z"
        fill="currentColor"
      />
      <path
        d="M11.932 17.59a.743.743 0 0 1-.743-.743v-6.15a.743.743 0 0 1 1.486 0v6.15c0 .41-.333.743-.743.743Zm.008-8.815a.945.945 0 0 1-.667-.258A.84.84 0 0 1 11 7.89a.83.83 0 0 1 .273-.626A.935.935 0 0 1 11.94 7c.258 0 .478.088.66.264a.824.824 0 0 1 .28.626c0 .242-.094.451-.28.627a.928.928 0 0 1-.66.258Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
