import { sample } from 'effector';

import {
  InputFindObjectValue,
  openObjectForEditByIdFromAnyFloorFn,
} from '@features/tpu-simulation-model/store';
import {
  $InputFindObject,
  chooseValueForInputFindObjectFn,
  resetInputFindObjectFn,
} from '@features/tpu-simulation-model/store/inputFindObject/inputFindObject';

$InputFindObject
  .reset(resetInputFindObjectFn)
  .on(chooseValueForInputFindObjectFn, (_, payload) => {
    return { value: payload };
  });

sample({
  clock: chooseValueForInputFindObjectFn,
  filter: (clockData): clockData is InputFindObjectValue => !!clockData,
  fn: (clockData: InputFindObjectValue) => clockData.id,
  target: openObjectForEditByIdFromAnyFloorFn,
});
