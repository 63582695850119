import { sample } from 'effector';

import { queryGetTimeline } from '@features/passenger-traffic/stores/api/queryGetTimeline';
import {
  TimeLineApi,
  getTimelineYearsFx,
} from '@features/passenger-traffic/stores/timeline/store';

getTimelineYearsFx.use(queryGetTimeline);

// Запись значений таймлайна в стор
sample({
  clock: getTimelineYearsFx.done,
  fn: payload => payload.result,
  target: TimeLineApi.setTimeLine,
});
