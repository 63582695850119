import { useEffect } from 'react';

import { PassengerMap } from './components/PassengerMap/PassengerMap';
import {
  InspectorContainer,
  MapBackdropContainer,
  MapControlsContainer,
  MapFiltersContainer,
  YearLineContainer,
} from './containers';
import { PTHandlersContext, usePTHandlers } from './hooks';
import './stores';
import { getTimelineYearsFx, resetAllFilters } from './stores';

import { Box, Stack, SxProps, Theme } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { ToolBar } from '@features/passenger-traffic/components/ToolBar';

const ContainerMuiStyle: SxProps<Theme> = {
  position: 'relative',
};

export const ContainerPassengerTraffic = () => {
  const handlers = usePTHandlers();

  useEffect(() => {
    getTimelineYearsFx();

    return () => {
      resetAllFilters();
    };
  }, []);

  return (
    <PTHandlersContext.Provider value={handlers}>
      <ToolBar onToggleFilterPanel={handlers.toggleFilterPanel} />

      <Box sx={ContainerMuiStyle}>
        <MapFiltersContainer />

        <Stack flexDirection={'row'}>
          <Stack flex={1}>
            <MapBackdropContainer />

            <AutoFullHeight style={{ backgroundColor: 'white' }}>
              <PassengerMap />
            </AutoFullHeight>
          </Stack>
        </Stack>

        <MapControlsContainer />

        <YearLineContainer />

        <InspectorContainer />
      </Box>
    </PTHandlersContext.Provider>
  );
};
