import {
  IAgentShareInfoCategoryEnum,
  IAgentShareInfoColorEnum,
  IAgentShareInfoType,
} from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

import { SimulationModelAgentCategory } from '@features/tpu-calculation-scenarios-viewing/types';

export type AgentsDataType = Record<
  IAgentShareInfoCategoryEnum,
  IAgentShareInfoColorEnum
>;

export interface MovementLog {
  /** ca - category */
  category: SimulationModelAgentCategory;
  /** co - coordinates */
  coordinates: [number, number][];
}

/**
 * @description
 * key - extraId из данных по этажам.
 * value - лог движение за единичный тик по конкретному этажу
 */
export type MovementLogPerTick = Record<string, MovementLog[]>;

/**
 * @description
 * key - текущий временной тик из плеера.
 * value - лог движение за единичный тик по всем этажам
 */
export type MovementLogDataType = Record<string, MovementLogPerTick>;

export const $Agents = createStore<AgentsDataType | null>(null);

export const $MovementsLog = createStore<MovementLogDataType | null>(null);

export const getAgentsInfoFx = createEffect<
  string,
  IAgentShareInfoType[] | null
>();

export const resetAgentsFn = createEvent<void>();

export const resetMovementsLogFn = createEvent<void>();

export const setMovementsLogFn = createEvent<MovementLogDataType>();
