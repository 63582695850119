import { apiClient } from '@api/client';
import { IQuery, IQueryGetInfraPointByNameArgs } from '@api/gql/types';
import { gql } from '@apollo/client';

let querySearchInfraCancel: AbortController | null = null;

export const queryGetInfraPointByName = async (
  params: IQueryGetInfraPointByNameArgs,
) => {
  if (querySearchInfraCancel) {
    querySearchInfraCancel.abort();
    querySearchInfraCancel = new AbortController();
  } else querySearchInfraCancel = new AbortController();

  const result = await apiClient.query<
    Pick<IQuery, 'getInfraPointByName'>,
    IQueryGetInfraPointByNameArgs
  >({
    query: gql`
      query QueryGetInfraPointByName($contains: String) {
        getInfraPointByName(contains: $contains) {
          id
          name
          type
          point {
            lat
            lon
          }
        }
      }
    `,
    variables: {
      contains: params.contains,
    },
    context: {
      fetchOptions: {
        signal: querySearchInfraCancel.signal,
      },
    },
  });

  querySearchInfraCancel = null;

  return result.data.getInfraPointByName || [];
};
