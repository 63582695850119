import { useUnit } from 'effector-react';

import { AirportInspector } from '@features/pt-forecast-new/components/Inspector/Infrastructure/AirportInspector';
import { BusStationInspector } from '@features/pt-forecast-new/components/Inspector/Infrastructure/BusStationInspector';
import { HighwayInspector } from '@features/pt-forecast-new/components/Inspector/Infrastructure/HighwayInspector';
import { RailwayInspector } from '@features/pt-forecast-new/components/Inspector/Infrastructure/RailwayInspector';
import { RailwayStationInspector } from '@features/pt-forecast-new/components/Inspector/Infrastructure/RailwayStationInspector';
import { TransportRegionsInspector } from '@features/pt-forecast-new/components/Inspector/Infrastructure/TransportRegionsInspector';
import { $InspectorInfrastructure } from '@features/pt-forecast-new/stores/inspector/infrastructure';

export const InspectorInfrastructure = () => {
  const { layout } = useUnit($InspectorInfrastructure);

  switch (layout) {
    case 'railway':
      return <RailwayInspector />;
    case 'highway':
      return <HighwayInspector />;
    case 'railwayStation':
      return <RailwayStationInspector />;
    case 'busStation':
      return <BusStationInspector />;
    case 'airport':
      return <AirportInspector />;
    case 'areaBorder':
      return <TransportRegionsInspector />;
    default:
      return null;
  }
};
