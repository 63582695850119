import { GetObjectType } from '@features/ag-project/stores/inspector/infrastructure';
import { AgLayersStore } from '@features/ag-project/stores/map';
import { createApi, createEvent, createStore } from 'effector';

export const $Inspector = createStore<InspectorState>({
  layout: null,
});

export const InspectorApi = createApi($Inspector, {
  setLayout: (store, layout: keyof AgLayersStore | null) => ({
    ...store,
    layout,
  }),
  resetLayout: store => ({ ...store, layout: null }),
});

// Сеттер вида инспектора инфраструктуры
export const getDataForInspectorFn = createEvent<GetObjectType>();

type InspectorState = {
  layout: keyof AgLayersStore | null;
};
