import { createApi, createStore } from 'effector';

import { TimeSlotOption } from '@features/ag-forecast/stores/lists/timeSlots';

import { VehicleType } from '@widgets/widget-soceco-indicators/containers/ThirdPageContainer';

const initStore: SettingsType = {
  scenarioId: null,
  projectId: null,
  baseYear: null,
  centerAGCoords: null,
  timeSlots: [],
  vehicles: [],
};

export const $Settings = createStore<SettingsType>(initStore);

export const SettingsApi = createApi($Settings, {
  setScenarioId: (store, payload: SettingsType['scenarioId']) => ({
    ...store,
    scenarioId: payload,
  }),
  setProjectId: (store, payload: SettingsType['projectId']) => ({
    ...store,
    projectId: payload,
  }),
  setBaseYear: (store, payload: SettingsType['baseYear']) => ({
    ...store,
    baseYear: payload,
  }),
  setCenterCoords: (store, payload: SettingsType['centerAGCoords']) => ({
    ...store,
    centerAGCoords: payload,
  }),
  setTimeSlots: (store, payload: TimeSlotOption[]) => ({
    ...store,
    timeSlots: payload,
  }),
  setVehicles: (store, payload: VehicleType[]) => ({
    ...store,
    vehicles: payload,
  }),
  reset: () => initStore,
});

export type SettingsType = {
  scenarioId: string | null;
  projectId: string | null;
  baseYear: number | null;
  centerAGCoords: number[] | null;
  timeSlots: TimeSlotOption[];
  vehicles: VehicleType[];
};
