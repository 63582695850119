import { sample } from 'effector';
import { Feature } from 'ol';
import { LineString } from 'ol/geom';

import { EMapFeatureLayout, EMapFeatureMetaKeys } from '@constants/map';

import { EVehicle } from '@features/pt-forecast-new/constants/eVehicle';
import {
  $ComputedData,
  ComputedDataApi,
} from '@features/pt-forecast-new/stores/computedData';
import {
  $FilterMap,
  EFilterMapLayout,
  FilterMapApi,
} from '@features/pt-forecast-new/stores/filters';
import {
  $LayersVisible,
  LayersVisibleApi,
} from '@features/pt-forecast-new/stores/map/layersVisible';
import { $SelectedFeatures } from '@features/pt-forecast-new/stores/map/selectedFeatures';
import {
  initSources,
  pipeDirectionAirlineVectorLayer,
} from '@features/pt-forecast-new/stores/map/store';
import {
  $VectorGeometry,
  VectorGeometryApi,
} from '@features/pt-forecast-new/stores/map/vectorFeatures';
import { getWidthLineByPercent } from '@features/pt-forecast-new/utils/getWidthLineByPercent';

import { getAirLineCurve } from '@utils/geometry';
import {
  EFeatureStyle,
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload &&
    Object.keys(payload).includes(EMapFeatureLayout.directionAirline),
  fn: payload => {
    return {
      action: EPipeVectorLayer.setVisible,
      payload: payload![EMapFeatureLayout.directionAirline],
    } as PipeVectorLayer;
  },
  target: pipeDirectionAirlineVectorLayer,
});

// Рендер геометрии
sample({
  clock: [
    VectorGeometryApi.setDirectionPassFlowAirportsFrom,
    VectorGeometryApi.setDirectionPassFlowAirportsTo,
    initSources,
  ],
  source: { VectorGeometry: $VectorGeometry, ComputedData: $ComputedData },
  fn: ({ VectorGeometry, ComputedData }) => {
    const features: Feature<LineString>[] = [];

    if (
      VectorGeometry.directionPassFlow.airports.from.length &&
      VectorGeometry.directionPassFlow.airports.to.length &&
      ComputedData.currentData?.passengerTraffic.avia
    )
      features.push(
        new Feature({
          geometry: new LineString(
            getAirLineCurve(
              VectorGeometry.directionPassFlow.airports.from[0].coordinates,
              VectorGeometry.directionPassFlow.airports.to[0].coordinates,
            ),
          ),
          [EMapFeatureMetaKeys.olId]: 'airline',
          [EMapFeatureMetaKeys.layout]: EMapFeatureLayout.directionAirline,
        }),
      );

    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: { features },
    } as PipeVectorLayer;
  },
  target: pipeDirectionAirlineVectorLayer,
});

// применение стилей к слою
sample({
  clock: [
    ComputedDataApi.setPassengerTrafficPercent,
    FilterMapApi.setLayout,
    initSources,
  ],
  source: { ComputedData: $ComputedData, FilterMap: $FilterMap },
  fn: ({ ComputedData, FilterMap }) => {
    const percent = ComputedData.passengerTrafficPercent[EVehicle.avia] || 0;
    return {
      action: EPipeVectorLayer.changeThemeProps,
      payload: {
        width:
          FilterMap.layout === EFilterMapLayout.PassengerFlows
            ? getWidthLineByPercent(percent)
            : 3,
      },
    } as PipeVectorLayer;
  },
  target: pipeDirectionAirlineVectorLayer,
});

// применение стилей к фичам
sample({
  clock: [$SelectedFeatures, $LayersVisible],
  source: {
    SelectedFeatures: $SelectedFeatures,
    LayersVisible: $LayersVisible,
  },
  filter: ({ LayersVisible }) =>
    LayersVisible[EMapFeatureLayout.directionAirline],
  fn: ({ SelectedFeatures }) => {
    return {
      action: EPipeVectorLayer.setFeatureStyle,
      payload: {
        featureStyles: !SelectedFeatures.length
          ? {}
          : Object.fromEntries(
              SelectedFeatures.filter(
                item => item.layout === EMapFeatureLayout.directionAirline,
              ).map(item => [item.id, EFeatureStyle.selected]),
            ),
      },
    } as PipeVectorLayer;
  },
  target: pipeDirectionAirlineVectorLayer,
});
