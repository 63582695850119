import { useCallback } from 'react';

import { Box } from '@mui/material';

import { gapBetweenDigits } from '@utils/dataForInspector';

import { Data } from './Chart';

export const DataInfo = ({ value, label, color, reverse }: DataInfoProps) => {
  const Dot = useCallback(
    () => (
      <Box
        sx={{
          borderRadius: '50%',
          minWidth: '12px',
          width: '12px',
          height: '12px',
          backgroundColor: color,
        }}
      />
    ),
    [color],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '6px',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '12px',
        color: theme => theme.palette.text.secondary,
      }}
    >
      {reverse ? (
        <Box>
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
              mb: '4px',
            }}
          >
            <Dot />

            <span>{label}</span>
          </Box>

          <Box
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            {gapBetweenDigits(value)}
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              flex: 'none',
              width: '48px',
              display: 'flex',
              gap: '8px',
              justifyContent: 'end',
            }}
          >
            <Box
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              {gapBetweenDigits(value)}
            </Box>

            <Dot />
          </Box>
          <span>{label}</span>
        </>
      )}
    </Box>
  );
};

interface DataInfoProps extends Data {
  reverse?: boolean;
}
