import { IScenarioType } from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

export interface RegistryDataType {
  own: IScenarioType[];
  shared: IScenarioType[];
}

export const $Registry = createStore<RegistryDataType>({
  own: [],
  shared: [],
});

export const getAllScenariosFx = createEffect<string, IScenarioType[]>();

export const copyScenarioFx = createEffect<string, string | undefined>();

export const resetAllScenariosFn = createEvent();
