import { Icon, Stroke, Style } from 'ol/style';

import PointASVG from '@components/icons/PointA.svg';
import PointBSVG from '@components/icons/PointB.svg';

import { palette } from '@system/theme/palette';

import { VectorStyleProps } from '@utils/map/styles/types';
import { scaleValue } from '@utils/map/tools/scaleValue';

export enum pointDirection {
  from,
  to,
}

export const agTransportFlowDistrictsVectorStyle = ({
  feature,
}: VectorStyleProps) => {
  const styles = [];

  const type = feature?.getGeometry()?.getType();

  if (type === 'LineString') {
    const flow = Number(feature?.get('flow')) || 0;
    const maxFlow = Number(feature?.get('maxFlow')) || 0;

    styles.push(
      new Style({
        stroke: new Stroke({
          color: palette.customs.trolleybus,
          width: scaleValue(flow, 0, maxFlow, 1, 14),
        }),
      }),
    );
  } else if (type === 'Point') {
    const direction = feature?.get('direction') as pointDirection;
    styles.push(
      new Style({
        image: new Icon({
          src: direction === pointDirection.from ? PointASVG : PointBSVG,
          anchor: [0.5, 1],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
        }),
      }),
    );
  }

  return styles;
};
