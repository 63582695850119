import {
  EditableObjectType,
  IBank,
  IBankomat,
  IBuffet,
  IDoor,
  IElevator,
  IExit,
  IInformationTable,
  IKiosk,
  ILadder,
  IOther,
  IPassengerGenerator,
  IPath,
  IPlatform,
  IRestaurant,
  IRoom,
  ISecurityCheckpoint,
  IStore,
  ITicketOffice,
  ITicketPrintingMachine,
  IToilet,
  ITurnstile,
  IVendingMachine,
  IWaiting,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types/simultaionModel';

export const isWallType = (
  type: EditableObjectType,
): type is EditableObjectType.Wall => type === EditableObjectType.Wall;

export const isRoomType = (
  type: EditableObjectType,
): type is EditableObjectType.Room => type === EditableObjectType.Room;

export const isLadderType = (
  type: EditableObjectType,
): type is EditableObjectType.Ladder => type === EditableObjectType.Ladder;

export const isPlatformType = (
  type: EditableObjectType,
): type is EditableObjectType.Platform => type === EditableObjectType.Platform;

export const isDoorType = (
  type: EditableObjectType,
): type is EditableObjectType.Door => type === EditableObjectType.Door;

export const isElevatorType = (
  type: EditableObjectType,
): type is EditableObjectType.Elevator => type === EditableObjectType.Elevator;

export const isPathType = (
  type: EditableObjectType,
): type is EditableObjectType.Path => type === EditableObjectType.Path;

export const isPassengerGeneratorType = (
  type: EditableObjectType,
): type is EditableObjectType.PassengerGenerator =>
  type === EditableObjectType.PassengerGenerator;

export const isExitType = (
  type: EditableObjectType,
): type is EditableObjectType.Exit => type === EditableObjectType.Exit;

export const isTicketOfficeType = (
  type: EditableObjectType,
): type is EditableObjectType.TicketOffice =>
  type === EditableObjectType.TicketOffice;

export const isTicketPrintingMachineType = (
  type: EditableObjectType,
): type is EditableObjectType.TicketPrintingMachine =>
  type === EditableObjectType.TicketPrintingMachine;

export const isToiletType = (
  type: EditableObjectType,
): type is EditableObjectType.Toilet => type === EditableObjectType.Toilet;

export const isSecurityCheckpointType = (
  type: EditableObjectType,
): type is EditableObjectType.SecurityCheckpoint =>
  type === EditableObjectType.SecurityCheckpoint;

export const isWaitingType = (
  type: EditableObjectType,
): type is EditableObjectType.Waiting => type === EditableObjectType.Waiting;

export const isTurnstileType = (
  type: EditableObjectType,
): type is EditableObjectType.Turnstile =>
  type === EditableObjectType.Turnstile;

export const isInformationTableType = (
  type: EditableObjectType,
): type is EditableObjectType.InformationTable =>
  type === EditableObjectType.InformationTable;

export const isRestaurantType = (
  type: EditableObjectType,
): type is EditableObjectType.Restaurant =>
  type === EditableObjectType.Restaurant;

export const isBuffetType = (
  type: EditableObjectType,
): type is EditableObjectType.Buffet => type === EditableObjectType.Buffet;

export const isBankomatType = (
  type: EditableObjectType,
): type is EditableObjectType.Bankomat => type === EditableObjectType.Bankomat;

export const isKioskType = (
  type: EditableObjectType,
): type is EditableObjectType.Kiosk => type === EditableObjectType.Kiosk;

export const isBankType = (
  type: EditableObjectType,
): type is EditableObjectType.Bank => type === EditableObjectType.Bank;

export const isOtherType = (
  type: EditableObjectType,
): type is EditableObjectType.Other => type === EditableObjectType.Other;

export const isVendingMachineType = (
  type: EditableObjectType,
): type is EditableObjectType.VendingMachine =>
  type === EditableObjectType.VendingMachine;

export const isStoreType = (
  type: EditableObjectType,
): type is EditableObjectType.Store => type === EditableObjectType.Store;

export const isRoom = (item: ObjectOfTheSimulationModel): item is IRoom =>
  isRoomType(item.type);

export const isPassengerGenerator = (
  item: ObjectOfTheSimulationModel,
): item is IPassengerGenerator => isPassengerGeneratorType(item.type);

export const isExit = (item: ObjectOfTheSimulationModel): item is IExit =>
  isExitType(item.type);

export const isOther = (item: ObjectOfTheSimulationModel): item is IOther =>
  isOtherType(item.type);

export const isBankomat = (
  item: ObjectOfTheSimulationModel,
): item is IBankomat => isBankomatType(item.type);

export const isBank = (item: ObjectOfTheSimulationModel): item is IBank =>
  isBankType(item.type);

export const isKiosk = (item: ObjectOfTheSimulationModel): item is IKiosk =>
  isKioskType(item.type);

export const isStore = (item: ObjectOfTheSimulationModel): item is IStore =>
  isStoreType(item.type);

export const isVendingMachine = (
  item: ObjectOfTheSimulationModel,
): item is IVendingMachine => isVendingMachineType(item.type);

export const isBuffet = (item: ObjectOfTheSimulationModel): item is IBuffet =>
  isBuffetType(item.type);

export const isRestaurant = (
  item: ObjectOfTheSimulationModel,
): item is IRestaurant => isRestaurantType(item.type);

export const isWaiting = (item: ObjectOfTheSimulationModel): item is IWaiting =>
  isWaitingType(item.type);

export const isToilet = (item: ObjectOfTheSimulationModel): item is IToilet =>
  isToiletType(item.type);

export const isInformationTable = (
  item: ObjectOfTheSimulationModel,
): item is IInformationTable => isInformationTableType(item.type);

export const isTicketOffice = (
  item: ObjectOfTheSimulationModel,
): item is ITicketOffice => isTicketOfficeType(item.type);

export const isTicketPrintingMachine = (
  item: ObjectOfTheSimulationModel,
): item is ITicketPrintingMachine => isTicketPrintingMachineType(item.type);

export const isLadder = (item: ObjectOfTheSimulationModel): item is ILadder =>
  isLadderType(item.type);

export const isElevator = (
  item: ObjectOfTheSimulationModel,
): item is IElevator => isElevatorType(item.type);

export const isLadderOrElevator = (
  item: ObjectOfTheSimulationModel,
): item is IElevator | ILadder =>
  isElevatorType(item.type) || isElevatorType(item.type);

export const isDoor = (item: ObjectOfTheSimulationModel): item is IDoor =>
  isDoorType(item.type);

export const isTurnstile = (
  item: ObjectOfTheSimulationModel,
): item is ITurnstile => isTurnstileType(item.type);

export const isSecurityCheckpoint = (
  item: ObjectOfTheSimulationModel,
): item is ISecurityCheckpoint => isSecurityCheckpointType(item.type);

export const isPath = (item: ObjectOfTheSimulationModel): item is IPath =>
  isPathType(item.type);

export const isPlatform = (
  item: ObjectOfTheSimulationModel,
): item is IPlatform => isPlatformType(item.type);
