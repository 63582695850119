import { apiClient } from '@api/client';
import {
  IDayTimeTypeEnum,
  IMutation,
  IQuery,
  IScenarioDayTypeEnum,
  IScenarioStateEnum,
  IScenarioType,
  ITurnstileModeEnum,
} from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';
import { matchPath } from 'react-router-dom';

import { TPU_CLIENT_NAME } from '@constants/api';
import { ROUTES } from '@constants/routes';

import {
  $Form,
  $IsOpenForm,
  FormData,
  closeFormStateFn,
  createScenarioFx,
  getScenarioByIdFx,
  openFormForCreateNewScenarioFn,
  openScenarioForEditFn,
  openScenarioForViewingFn,
  resetFormStateFn,
  saveScenarioFn,
  setIsOpenFormFn,
  updateScenarioFx,
} from '@features/tpu-calculation-scenarios/stores/form.store';
import {
  $ModalDeleteScenario,
  deleteScenarioFn,
  openDeleteScenarioModalFn,
  removeScenarioFx,
  resetModalDeleteScenarioFn,
} from '@features/tpu-calculation-scenarios/stores/modal-delete-scenario.store';
import {
  $Errors,
  $ModalPlanErrors,
  IModelData,
  getPlanDataFx,
  resetErrorsFn,
  resetModalPlanErrorsFn,
  setErrorsFn,
  setIsOpenModalPlanErrorsFn,
  validateScenarioFx,
} from '@features/tpu-calculation-scenarios/stores/modalPlanErrors.store';
import {
  $ProjectId,
  getScenarioIdFn,
} from '@features/tpu-calculation-scenarios/stores/project.store';
import {
  $Registry,
  copyScenarioFx,
  getAllScenariosFx,
  resetAllScenariosFn,
} from '@features/tpu-calculation-scenarios/stores/registry.store';

import { getPlanErrors } from '@utils/simulationModel';

$IsOpenForm.reset(resetAllScenariosFn).on(setIsOpenFormFn, (_, value) => value);

$Registry
  .reset(resetAllScenariosFn)
  .on(getAllScenariosFx.done, (state, { result }) => {
    return { ...state, own: result };
  });

$ProjectId.reset(resetAllScenariosFn).on(getScenarioIdFn, () => {
  const matchedInfo = matchPath(
    { path: `${ROUTES.TPU_SCENARIOS}/:id` },
    window.location.pathname,
  );
  return matchedInfo?.params.id;
});

$Form
  .reset(resetFormStateFn)
  .on(openScenarioForViewingFn, state => {
    return {
      ...state,
      isOpenForViewing: true,
    };
  })
  .on(openScenarioForEditFn, state => {
    return {
      ...state,
      isOpenForViewing: false,
    };
  })
  .on(openFormForCreateNewScenarioFn, state => {
    return {
      ...state,
      isOpenForViewing: false,
    };
  })
  .on(getScenarioByIdFx.done, (state, { result }) => {
    if (result) {
      return {
        isOpenForViewing: state.isOpenForViewing,
        id: result.id,
        dayType: result.dayType ?? IScenarioDayTypeEnum.Workday,
        dayTimeType: result.dayTimeType ?? IDayTimeTypeEnum.AllDay,
        turnstileMode: result.turnstileMode ?? ITurnstileModeEnum.DoubleSided,
        state: result.state ?? IScenarioStateEnum.NotCalculated,
        name: result.name,
        changePassengerTrafficIntensity: `${result.changePassengerTrafficIntensity}`,
      };
    }
  });

$ModalPlanErrors
  .reset(resetModalPlanErrorsFn)
  .on(setIsOpenModalPlanErrorsFn, (_, payload) => {
    return { isOpen: payload, isLoading: payload };
  })
  .on(setErrorsFn, state => {
    return { ...state, isLoading: false };
  });

$Errors
  .reset(resetErrorsFn)
  .on(setErrorsFn, (_, payload) => {
    return payload;
  })
  .on(validateScenarioFx.doneData, (state, payload) => {
    if (!payload) {
      return [...(state ?? []), 'Не загружено расписание поездов'];
    }
  });

$ModalDeleteScenario
  .reset(resetModalDeleteScenarioFn)
  .on(openDeleteScenarioModalFn, (state, payload) => {
    return { ...state, id: payload.id, name: payload.name, isOpen: true };
  })
  .on(removeScenarioFx.doneData, (state, payload) => {
    return { ...state, isResolve: payload, isRejected: !payload };
  })
  .on(removeScenarioFx.pending, (state, payload) => {
    return { ...state, isLoading: payload };
  });

sample({
  clock: deleteScenarioFn,
  source: $ModalDeleteScenario,
  filter: sourceData => !!sourceData.id,
  fn: sourceData => {
    return sourceData.id as string;
  },
  target: removeScenarioFx,
});

sample({
  clock: setIsOpenModalPlanErrorsFn,
  source: $Form,
  filter: (sourceData, clockData) => clockData && !!sourceData.id,
  fn: (sourceData: FormData) => sourceData.id,
  target: [getPlanDataFx, validateScenarioFx],
});

sample({
  clock: getPlanDataFx.doneData,
  fn: clockData => {
    const document = clockData.plan?.document ?? null;
    const plan = document && JSON.parse(document);

    return getPlanErrors({
      plan,
      agents: clockData.agents,
    }).map(({ description }) => description);
  },
  target: setErrorsFn,
});

sample({
  clock: saveScenarioFn,
  source: { projectId: $ProjectId, form: $Form },
  filter: ({ form }) => !form.id.length,
  fn: ({ projectId }, clockData) => ({
    ...clockData,
    projectId,
    changePassengerTrafficIntensity: Number(
      clockData.changePassengerTrafficIntensity,
    ),
  }),
  target: createScenarioFx,
});

sample({
  clock: saveScenarioFn,
  source: { form: $Form },
  filter: ({ form }) => !!form.id.length,
  fn: ({ form }, clockData) => ({
    ...clockData,
    scenarioId: form.id,
    changePassengerTrafficIntensity: Number(
      clockData.changePassengerTrafficIntensity,
    ),
  }),
  target: updateScenarioFx,
});

sample({
  clock: [
    createScenarioFx.done,
    updateScenarioFx.done,
    removeScenarioFx.done,
    copyScenarioFx.done,
  ],
  source: $ProjectId,
  fn: projectId => projectId,
  target: getAllScenariosFx,
});

sample({
  clock: getScenarioByIdFx.done,
  fn: sourceData => !!sourceData.result?.id,
  target: setIsOpenFormFn,
});

sample({
  clock: openFormForCreateNewScenarioFn,
  fn: () => true,
  target: setIsOpenFormFn,
});

sample({
  clock: [openScenarioForEditFn, openScenarioForViewingFn],
  target: getScenarioByIdFx,
});

sample({
  clock: [copyScenarioFx.done, createScenarioFx.done],
  source: $Form,
  filter: (_, { result }) => !!result,
  fn: (_, { result }) => result as string,
  target: [getScenarioByIdFx],
});

sample({
  clock: closeFormStateFn,
  fn: () => false,
  target: [setIsOpenFormFn, resetFormStateFn],
});

sample({
  clock: removeScenarioFx.done,
  source: $Form,
  filter: (form, { result, params: id }) => result && form.id === id,
  target: closeFormStateFn,
});

getAllScenariosFx.use(async projectId => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query AllScenarios($projectId: UUID!) {
        allScenarios(projectId: $projectId) {
          id
          created
          modified
          name
          state
          dayType
        }
      }
    `,
    variables: {
      projectId,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return (response.data.allScenarios?.filter(Boolean) ?? []) as IScenarioType[];
});

createScenarioFx.use(async inputData => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation CreateScenarioMutation(
        $projectId: UUID!
        $name: String!
        $dayType: ScenarioDayTypeEnum!
        $dayTimeType: DayTimeTypeEnum!
        $turnstileMode: TurnstileModeEnum!
        $changePassengerTrafficIntensity: Float!
      ) {
        createScenario(
          inputData: {
            projectId: $projectId
            name: $name
            dayType: $dayType
            dayTimeType: $dayTimeType
            turnstileMode: $turnstileMode
            changePassengerTrafficIntensity: $changePassengerTrafficIntensity
          }
        ) {
          ok
          errors {
            key
            message
          }
          scenario {
            id
          }
        }
      }
    `,
    variables: inputData,
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data?.createScenario?.scenario?.id;
});

updateScenarioFx.use(async inputData => {
  await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation UpdateScenario(
        $scenarioId: UUID!
        $name: String!
        $dayType: ScenarioDayTypeEnum!
        $dayTimeType: DayTimeTypeEnum!
        $turnstileMode: TurnstileModeEnum!
        $changePassengerTrafficIntensity: Float!
      ) {
        updateScenario(
          inputData: {
            scenarioId: $scenarioId
            name: $name
            dayType: $dayType
            dayTimeType: $dayTimeType
            turnstileMode: $turnstileMode
            changePassengerTrafficIntensity: $changePassengerTrafficIntensity
          }
        ) {
          ok
          errors {
            key
            message
          }
        }
      }
    `,
    variables: inputData,
    context: { clientName: TPU_CLIENT_NAME },
  });
});

getScenarioByIdFx.use(async id => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query ScenarioById($id: UUID!) {
        scenarioById(id: $id) {
          id
          name
          state
          dayType
          dayTimeType
          turnstileMode
          changePassengerTrafficIntensity
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data.scenarioById;
});

removeScenarioFx.use(async id => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation RemoveScenarioMutation($id: UUID!) {
        removeScenario(scenarioId: $id) {
          ok
          errors {
            key
            message
          }
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return !!response.data?.removeScenario?.ok;
});

copyScenarioFx.use(async id => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation CopyScenarioMutation($id: UUID!) {
        copyScenario(scenarioId: $id) {
          ok
          errors {
            key
            message
          }
          newScenario {
            id
          }
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data?.copyScenario?.newScenario?.id;
});

getPlanDataFx.use(async id => {
  const response = await apiClient.query<IModelData>({
    query: gql`
      query PlanData($id: UUID!) {
        plan: planByScenarioId(scenarioId: $id) {
          document
        }
        agents: allAgentShareInfo(scenarioId: $id) {
          storeProbability
          bankProbability
          foodProbability
          otherProbability
          toiletProbability
          informationTableProbability
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data;
});

validateScenarioFx.use(async id => {
  const response = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation ValidateBeforeSimulation($id: UUID!) {
        validateBeforeSimulation(scenarioId: $id) {
          ok
          errors {
            message
          }
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return !!response.data?.validateBeforeSimulation?.ok;
});
