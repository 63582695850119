import { UseCalculationScenariosViewingHandlers } from '@features/tpu-calculation-scenarios-viewing/hooks/useCalculationScenariosViewingHandlers/useCalculationScenariosViewingHandlers';
import { createContext } from 'react';

type CalculationScenariosViewingHandlersContextValue =
  UseCalculationScenariosViewingHandlers;

export const CalculationScenariosViewingHandlersContext =
  createContext<CalculationScenariosViewingHandlersContextValue>(
    {} as CalculationScenariosViewingHandlersContextValue,
  );
