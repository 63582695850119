import {sample} from "effector";
import {$UI, UIApi} from "@features/pt-forecast-new/stores/ui";
import {
  $EditorMap,
  EditorMapApi,
  EditorMapStore,
  getGraphItemsFx, runCalculateScenarioFx,
  updateGraphItemFx,
  updateStationFx
} from "@features/pt-forecast-new/stores/map/editor";
import './customRailwayStation'
import './customRailwayGraph'
import {
  IMutationUpdateGraphItemArgs,
  IMutationUpdateStationArgs
} from "@api/gql/types";
import {
  mutationUpdateStation
} from "@features/pt-forecast-new/stores/api/mutationUpdateStation";
import {
  mutationUpdateGraph
} from "@features/pt-forecast-new/stores/api/mutationUpdateGraph";
import {
  queryGetScenarioGraph
} from "@features/pt-forecast-new/stores/api/queryGetScenarioGraph";
import { parsingInfrastructureTypesForRequest } from "@features/pt-forecast-new/constants/paramsByInfrastructureTypes";
import {
  queryRunCalculateUserScenario
} from "@features/pt-forecast-new/stores/api/mutationRunCalculateUserScenario";

runCalculateScenarioFx.use(queryRunCalculateUserScenario)

// Закрытие инспектора кастомной инфраструктуры
const closeInspectorCustomInfrastructure = sample({
  clock: [EditorMapApi.updateSelectedGraphParams, EditorMapApi.updateSelectedStationParams],
  source: {UI: $UI, EditorMap: $EditorMap},
  filter: ({UI}) => UI.editMode,
  fn: ({EditorMap}) => {
    targetGraph = EditorMap.graphs.find(({id}) => id === EditorMap.selectedGraph)
  }
})

let targetStation = null
// Если поля были изменены
sample({
  clock: closeInspectorCustomInfrastructure,
  source: {EditorMap: $EditorMap},
  filter: ({EditorMap}) => {
    if (!EditorMap.selectedStation) return false
    targetStation = EditorMap.stations.find(({id}) => id === EditorMap.selectedStation)
    return !!targetStation?.isUpdated;
  },
  fn: () => {
    return {
      name: targetStation!.name,
      stationId: targetStation!.id,
    } as IMutationUpdateStationArgs
  },
  target: updateStationFx
})

updateStationFx.use(mutationUpdateStation);

// параметры станции успешно обновились
sample({
  clock: updateStationFx.done,
  fn: () => false,
  target: [EditorMapApi.setIsUpdatedStation]
})

let targetGraph = null
// Если поля были изменены
sample({
  clock: closeInspectorCustomInfrastructure,
  source: {EditorMap: $EditorMap},
  filter: ({EditorMap}) => {
    if (!EditorMap.selectedGraph) return false
    targetGraph = EditorMap.graphs.find(({id}) => id === EditorMap.selectedGraph)
    return !!targetGraph?.isUpdated;
  },
  fn: () => {
    return {
      name: targetGraph!.name,
      year: Number(targetGraph!.yearOfCommissioning),
      speed: Number(targetGraph!.maxSpeed),
      graphItemId: targetGraph!.id,
      type: parsingInfrastructureTypesForRequest[targetGraph!.infrastructureType]
    } as IMutationUpdateGraphItemArgs
  },
  target: updateGraphItemFx
})

updateGraphItemFx.use(mutationUpdateGraph)

// параметры графа успешно обновились
sample({
  clock: updateGraphItemFx.done,
  fn: () => false,
  target: [EditorMapApi.setIsUpdatedGraph]
})

getGraphItemsFx.use(queryGetScenarioGraph)

// парсинг пользовательской геометрии
sample({
  clock: getGraphItemsFx.done,
  fn: (request) => {
    const involvedStations: string[] = []

    return {
      graphs: request.result?.instances
        .map(graph => ({
          id: graph.id || '',
          name: graph.name || '',
          infrastructureType: graph.type || 'A_3',
          maxSpeed: graph.speed || 0,
          yearOfCommissioning: graph.year || new Date().getFullYear(),
          startStationId: graph.startStation?.id || '',
          endStationId: graph.endStation?.id || '',
          // intermediateStations: intermediateStations(graph.relatedStations || []) || [] as string[],
          intermediateStations: graph.relatedStations
            .map(station => station.id)
            .filter(stationId =>
              stationId !== graph.endStation?.id &&
              stationId !== graph.startStation?.id
            ) || [],
          geometry: graph.geometry.coordinates[0].map((coords: number[]) => ({
            lon: coords[1],
            lat: coords[0],
          })),
          isSaved: true,
          isUpdated: false,
          delete: false
        })),
      stations: request.result?.scenarioStations.map(station => {
        if (station.railwayStation?.id) {
          involvedStations.push(station.railwayStation.id)
        }
        return {
          id: station.id || '',
          name: station.name || '',
          transportArea: station.transportRegion?.name || '',
          geometry: {
            lon: station.vertex.theGeom.coordinates[0],
            lat: station.vertex.theGeom.coordinates[1],
          },
          vertexId: station.vertex.id || '',
          isGhost: !!station.railwayStation?.id,
          isSaved: true,
          isUpdated: false,
          delete: false
        }
      }),
      involvedStations
    } as Pick<EditorMapStore, 'graphs' | 'stations' | 'involvedStations'>
  },
  target: EditorMapApi.setInfrastructure
})


sample({
  clock: UIApi.setEditMode,
  filter: payload => !payload,
  fn: () => false,
  target: [EditorMapApi.clearDrawGraph, UIApi.setDrawGraph, UIApi.setPlaceRailwayStations]
})
