import { SvgIcon, SvgIconProps } from '@mui/material';

export const TpuIcon = (props: SvgIconProps) => (
  <SvgIcon
    width={20}
    height={20}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <circle
      cx="12"
      cy="12"
      r="11.5"
      fill="currentColor"
      stroke="#344B5A"
    />
    <path
      d="M7.33301 12.0007H16.6663M11.9997 7.33398L11.9997 16.6673"
      stroke="white"
      strokeLinecap="round"
    />
  </SvgIcon>
);
