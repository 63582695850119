import { IRailwayRailwayTractionTypeChoices } from '@api/gql/types';

type getTractionTypeValueArgs = IRailwayRailwayTractionTypeChoices | undefined;

type getTractionTypeValueReturn = string | undefined;

export const getTractionTypeValue = (
  value: getTractionTypeValueArgs,
): getTractionTypeValueReturn => {
  switch (value) {
    case IRailwayRailwayTractionTypeChoices.A_1:
      return 'электрифицированные';

    case IRailwayRailwayTractionTypeChoices.A_2:
      return 'неэлектрифицированные';
  }
};
