import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const PathIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        d="M14.404 5.965H8.79v2.246h5.614V5.965ZM5 6.246V7.93c0 .155.126.28.28.28h1.264V5.966H5.28a.28.28 0 0 0-.281.28ZM14.404 10.877H8.79v2.246h5.614v-2.246ZM5 11.158v1.684c0 .155.126.28.28.28h1.264v-2.245H5.28a.28.28 0 0 0-.281.281ZM16.649 8.21h1.263a.28.28 0 0 0 .28-.28V6.246a.28.28 0 0 0-.28-.281H16.65v2.246ZM14.404 15.79H8.79v2.245h5.614V15.79ZM5 16.07v1.684c0 .156.126.281.28.281h1.264V15.79H5.28a.28.28 0 0 0-.281.28ZM18.193 17.755V16.07a.28.28 0 0 0-.28-.28h-1.264v2.245h1.263a.28.28 0 0 0 .28-.28ZM16.088 4h-1.123v16h1.123V4ZM18.193 12.842v-1.684a.28.28 0 0 0-.28-.28h-1.264v2.245h1.263a.28.28 0 0 0 .28-.28ZM8.228 13.404V4H7.105v16h1.123v-6.596Z"
        fill="#273B48"
      />
    </SvgIcon>
  );
};
