import {
  IDayTimeTypeEnum,
  IScenarioDayTypeEnum,
  ITurnstileModeEnum,
} from '@api/gql/tpu-types';
import { useForm } from 'react-hook-form';

import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { CloseIcon } from '@components/icons';

import { RadioButton } from '@features/tpu-calculation-scenarios/components/RadioButton';
import { DAY_TYPE_TRANSLATE } from '@features/tpu-calculation-scenarios/constants';
import { $Form } from '@features/tpu-calculation-scenarios/stores/form.store';
import { FormData } from '@features/tpu-calculation-scenarios/stores/form.store';

interface SocioEconomicFormProps {
  formData: FormData;
  onClose(): void;
  onSubmit(payload: FormData): void;
}

export const SocioEconomicForm = ({
  onSubmit,
  formData,
  onClose,
}: SocioEconomicFormProps) => {
  const { control, handleSubmit, register, formState, reset } =
    useForm<FormData>({
      values: formData,
      defaultValues: $Form.defaultState,
    });

  const { isValid } = formState;

  const handleReset = () => {
    reset({
      ...$Form.defaultState,
      id: formData.id,
      isOpenForViewing: formData.isOpenForViewing,
    });
  };

  const getFormTitle = () => {
    if (formData.isOpenForViewing) return 'Просмотр сценария';

    return formData?.id
      ? 'Редактировать сценарий'
      : 'Создать новый сценарий прогноза';
  };

  return (
    <>
      <Box
        sx={{
          display: formData.isOpenForViewing ? 'block' : 'none',
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          zIndex: 2,
        }}
      />
      <Button
        variant="text"
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 24,
          right: 24,
          padding: '10px',
          minWidth: 'unset',
          width: '44px',
          height: '44px',
          zIndex: 3,
        }}
      >
        <CloseIcon />
      </Button>
      <Typography
        variant={'subtitle1'}
        sx={{
          whiteSpace: 'no-wrap',
          fontSize: theme => theme.typography.pxToRem(14),
          lineHeight: theme => theme.typography.pxToRem(16),
        }}
      >
        {getFormTitle()}
      </Typography>

      <Typography
        variant="body2"
        sx={{
          fontSize: theme => theme.typography.pxToRem(14),
          lineHeight: theme => theme.typography.pxToRem(14),
        }}
      >
        Название сценария
      </Typography>
      <TextField
        {...register('name', { required: true })}
        fullWidth
        placeholder={'Название сценария'}
        sx={{
          mb: '12px',

          '.MuiInputBase-root': {
            backgroundColor: theme =>
              `${theme.palette.background.default} !important`,
          },
        }}
      />

      <Box>
        <Typography
          variant={'subtitle1'}
          sx={{
            mb: '16px',
            fontSize: theme => theme.typography.pxToRem(14),
            lineHeight: theme => theme.typography.pxToRem(16),
          }}
        >
          Параметры прогнозирования
        </Typography>
      </Box>

      <Box>
        <Typography
          variant="body2"
          sx={{
            fontSize: theme => theme.typography.pxToRem(14),
            mb: '8px',
          }}
        >
          Изменение интенсивности пассажиропотока относительно базового уровня,
          %
        </Typography>

        <OutlinedInput
          fullWidth
          {...register('changePassengerTrafficIntensity', {
            pattern: /^([0-9]+)([.,]?)([0-9]*)$/,
          })}
        />
      </Box>

      <Box>
        <Typography
          variant="body2"
          sx={{
            fontSize: theme => theme.typography.pxToRem(14),
            mb: '8px',
          }}
        >
          Сценарий
        </Typography>
        <Autocomplete
          id="scenario-select"
          disablePortal
          value={{ label: 'Базовый', value: '1' }}
          options={[{ label: 'Базовый', value: '1' }]}
          isOptionEqualToValue={(opt, val) => opt.value === val.value}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Сценарий"
            />
          )}
          renderOption={(props, option) => (
            <li
              {...props}
              key={`${option.label}-${option.value}`}
            >
              <Typography>{option.label}</Typography>
            </li>
          )}
          sx={{
            mb: 0.75,
          }}
        />
      </Box>

      <Box>
        <Typography
          variant="body2"
          sx={{
            fontSize: theme => theme.typography.pxToRem(14),
            mb: '8px',
          }}
        >
          Шаг модельного времени
        </Typography>
        <Autocomplete
          id="time-step-select"
          disablePortal
          value={{ label: '1 минута', value: '1' }}
          options={[{ label: '1 минута', value: '1' }]}
          isOptionEqualToValue={(opt, val) => opt.value === val.value}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Шаг модельного времени"
            />
          )}
          renderOption={(props, option) => (
            <li
              {...props}
              key={`${option.label}-${option.value}`}
            >
              <Typography>{option.label}</Typography>
            </li>
          )}
          sx={{
            mb: 0.75,
          }}
        />
      </Box>

      <RadioButton
        control={control}
        name={'dayType'}
        label={'День недели'}
        options={[
          {
            value: IScenarioDayTypeEnum.Workday,
            label: DAY_TYPE_TRANSLATE[IScenarioDayTypeEnum.Workday],
          },
          {
            value: IScenarioDayTypeEnum.Weekend,
            label: DAY_TYPE_TRANSLATE[IScenarioDayTypeEnum.Weekend],
          },
        ]}
      />
      <RadioButton
        control={control}
        name={'dayTimeType'}
        label={'Время дня'}
        options={[
          {
            value: IDayTimeTypeEnum.AllDay,
            label: 'Весь день',
          },
          {
            value: IDayTimeTypeEnum.EveningRushHour,
            label: 'Утренний час пик',
          },
          {
            value: IDayTimeTypeEnum.MorningRushHour,
            label: 'Вечерний час пик',
          },
        ]}
      />
      <RadioButton
        control={control}
        name={'turnstileMode'}
        label={'Режим работы турникетов'}
        options={[
          {
            value: ITurnstileModeEnum.DoubleSided,
            label: 'На вход и выход (двусторонние)',
          },
          {
            value: ITurnstileModeEnum.OneSided,
            label: 'На вход или выход (односторонние)',
          },
        ]}
      />
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        sx={{ mt: 'auto' }}
      >
        {!formData.isOpenForViewing && (
          <>
            <Button
              onClick={handleSubmit(onSubmit)}
              sx={{ width: '268px' }}
              disabled={!isValid}
            >
              Сохранить
            </Button>
            <Button
              onClick={handleReset}
              variant="text"
              sx={{ width: '268px' }}
            >
              <Close
                fontSize="small"
                sx={{ mr: '8px' }}
              />
              Сбросить все
            </Button>
          </>
        )}
      </Stack>
    </>
  );
};
