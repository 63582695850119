import { sample } from 'effector';
import { Feature } from 'ol';
import { MultiPolygon } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EMapFeatureLayout, EMapFeatureMetaKeys } from '@constants/map';

import {
  $FilterMap,
  EFilterMapLayout,
  FilterMapApi,
} from '@features/pt-forecast-new/stores/filters';
import {
  $LayersVisible,
  LayersVisibleApi,
} from '@features/pt-forecast-new/stores/map/layersVisible';
import { $SelectedFeatures } from '@features/pt-forecast-new/stores/map/selectedFeatures';
import {
  initSources,
  pipeAreaBorderVectorLayer,
} from '@features/pt-forecast-new/stores/map/store';
import {
  $VectorGeometry,
  VectorGeometryApi,
} from '@features/pt-forecast-new/stores/map/vectorFeatures';

import {
  EFeatureStyle,
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload && Object.keys(payload).includes(EMapFeatureLayout.areaBorder),
  fn: payload => {
    return {
      action: EPipeVectorLayer.setVisible,
      payload: payload![EMapFeatureLayout.areaBorder],
    } as PipeVectorLayer;
  },
  target: pipeAreaBorderVectorLayer,
});

// Рендер геометрии
sample({
  clock: [
    VectorGeometryApi.setTransportRegions,
    VectorGeometryApi.setDirectionPassFlowRegionsFrom,
    VectorGeometryApi.setDirectionPassFlowRegionsTo,
    VectorGeometryApi.setDirectionPassFlowRegionsIntersection,
    FilterMapApi.setLayout,
    FilterMapApi.setRegionTo,
    FilterMapApi.setRegionFrom,
    FilterMapApi.clearRegions,
    FilterMapApi.reset,
    initSources,
  ],
  source: { VectorGeometry: $VectorGeometry, FilterMap: $FilterMap },
  fn: ({ VectorGeometry, FilterMap }) => {
    const features: Feature<MultiPolygon>[] = [];

    const geometry = [];

    if (
      (FilterMap.layout === EFilterMapLayout.PassengerFlows &&
        (FilterMap.toSelectedRegion || FilterMap.fromSelectedRegion)) ||
      (FilterMap.layout === EFilterMapLayout.Infrastructure &&
        FilterMap.toSelectedRegion &&
        FilterMap.fromSelectedRegion)
    ) {
      geometry.push(...VectorGeometry.directionPassFlow.regions.intersection);
      if (VectorGeometry.directionPassFlow.regions.from)
        geometry.push(VectorGeometry.directionPassFlow.regions.from);
      if (VectorGeometry.directionPassFlow.regions.to)
        geometry.push(VectorGeometry.directionPassFlow.regions.to);
    } else if (FilterMap.layout === EFilterMapLayout.Infrastructure) {
      geometry.push(...VectorGeometry.transportRegions);
    }

    geometry.forEach(transportRegionGeometry => {
      const multipolygon = transportRegionGeometry.coordinates.map(polygon =>
        polygon.map(item => item.map(coordinates => fromLonLat(coordinates))),
      );
      features.push(
        new Feature({
          geometry: new MultiPolygon(multipolygon),
          [EMapFeatureMetaKeys.olId]: transportRegionGeometry.id,
          [EMapFeatureMetaKeys.layout]: EMapFeatureLayout.areaBorder,
        }),
      );
    });

    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: { features },
    } as PipeVectorLayer;
  },
  target: pipeAreaBorderVectorLayer,
});

// применение стилей к фичам
sample({
  clock: [$SelectedFeatures, $LayersVisible],
  source: {
    SelectedFeatures: $SelectedFeatures,
    LayersVisible: $LayersVisible,
  },
  filter: ({ LayersVisible }) => LayersVisible[EMapFeatureLayout.areaBorder],
  fn: ({ SelectedFeatures }) => {
    return {
      action: EPipeVectorLayer.setFeatureStyle,
      payload: {
        featureStyles: !SelectedFeatures.length
          ? {}
          : Object.fromEntries(
              SelectedFeatures.filter(
                item => item.layout === EMapFeatureLayout.areaBorder,
              ).map(item => [item.id, EFeatureStyle.selected]),
            ),
      },
    } as PipeVectorLayer;
  },
  target: pipeAreaBorderVectorLayer,
});
