import { VehicleType } from '@features/ag-forecast/stores/lists/vehicles';
import { vehicleIdToLayout } from '@features/ag-forecast/utils/vehicleIdToLayout';
import { FeatureLike } from 'ol/Feature';

import { EMapFeatureLayout, EMapLayerMetaKeys } from '@constants/map';
import { TILE_SERVICE_LAYERS } from '@constants/tileServiceLayers';

// Функция поиска лэйаута для переданной фичи по её параметрам
export const graphLayoutExtractor = (
  feature: FeatureLike,
  vehicles: VehicleType[],
) => {
  if (feature.get(EMapLayerMetaKeys.layout))
    return feature.get(EMapLayerMetaKeys.layout);
  if (feature.get('layer') === TILE_SERVICE_LAYERS.COMPUTED_GRAPHS.LAYER) {
    const vehicleUUID = feature.get('transport_id');
    const vehicle = vehicles.find(vehicle => vehicleUUID === vehicle.id);
    return vehicleIdToLayout(vehicle!.vehicleId);
  }
  if (feature.get('layer') === TILE_SERVICE_LAYERS.TRANSPORT_DISTRICTS.LAYER) {
    return EMapFeatureLayout.transportDistricts;
  }
  return '';
};
