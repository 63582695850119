import { SvgIcon, SvgIconProps } from '@mui/material';

export const TurnstileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        d="M17.163 22.326c0-.371.302-.673.674-.673h4.04a.673.673 0 0 1 0 1.347h-4.04a.673.673 0 0 1-.674-.674ZM1 22.326c0-.371.302-.673.673-.673h4.041a.673.673 0 1 1 0 1.347h-4.04A.673.673 0 0 1 1 22.326Z"
        fill="#3679EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.755 1.898H18.96v2.694h1.796V1.898ZM18.96 1a.898.898 0 0 0-.898.898v2.694c0 .496.402.898.898.898h1.796a.898.898 0 0 0 .898-.898V1.898A.898.898 0 0 0 20.755 1H18.96ZM4.592 1.898H2.796v2.694h1.796V1.898ZM2.796 1a.898.898 0 0 0-.898.898v2.694c0 .496.402.898.898.898h1.796a.898.898 0 0 0 .898-.898V1.898A.898.898 0 0 0 4.592 1H2.796ZM20.755 14.47H18.96v7.183h1.796V14.47Zm-1.796-.899a.898.898 0 0 0-.898.898v7.184c0 .496.402.898.898.898h1.796a.898.898 0 0 0 .898-.898V14.47a.898.898 0 0 0-.898-.898H18.96ZM4.592 14.47H2.796v7.183h1.796V14.47Zm-1.796-.899a.898.898 0 0 0-.898.898v7.184c0 .496.402.898.898.898h1.796a.898.898 0 0 0 .898-.898V14.47a.898.898 0 0 0-.898-.898H2.796Z"
        fill="#3679EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.653 5.49h-3.592v8.081h3.592V5.49Zm-3.592-.898a.898.898 0 0 0-.898.898v8.081c0 .496.402.898.898.898h3.592a.898.898 0 0 0 .898-.898V5.49a.898.898 0 0 0-.898-.898h-3.592ZM5.49 5.49H1.898v8.081H5.49V5.49Zm-3.592-.898A.898.898 0 0 0 1 5.49v8.081c0 .496.402.898.898.898H5.49a.898.898 0 0 0 .898-.898V5.49a.898.898 0 0 0-.898-.898H1.898Z"
        fill="#3679EA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.163 10.878V8.184H6.388v2.694h10.775Zm.898-2.694a.898.898 0 0 0-.898-.898H6.388a.898.898 0 0 0-.898.898v2.694c0 .495.402.897.898.897h10.775a.898.898 0 0 0 .898-.897V8.184Z"
        fill="#3679EA"
      />
    </SvgIcon>
  );
};
