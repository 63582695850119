import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { MapButton } from '@components/Map/MapButton';
import { MinusIcon, PlusIcon } from '@components/icons';

import { pipeMapControls } from '@features/ag-forecast/stores/map';

import { EPipeMapControls } from '@utils/map/hooks/useInitMapControls';

export const MapControlsContainer = () => {
  const mapControls = useUnit(pipeMapControls);

  const onClickZoomIn = useCallback(() => {
    mapControls({ action: EPipeMapControls.touchZoom, payload: 'in' });
  }, [mapControls]);

  const onClickZoomOut = useCallback(() => {
    mapControls({ action: EPipeMapControls.touchZoom, payload: 'out' });
  }, [mapControls]);

  return (
    <>
      <MapButton
        sx={{
          position: 'absolute',
          top: theme => theme.typography.pxToRem(24 + 44 + 16),
          right: theme => theme.typography.pxToRem(24 + 24),
          boxShadow: '0px 0px 8px 0px #00000026',
          zIndex: 1,
        }}
        onClick={onClickZoomIn}
      >
        <PlusIcon />
      </MapButton>

      <MapButton
        sx={{
          position: 'absolute',
          top: theme => theme.typography.pxToRem(24 + 88 + 24),
          right: theme => theme.typography.pxToRem(24 + 24),
          boxShadow: '0px 0px 8px 0px #00000026',
          zIndex: 1,
        }}
        onClick={onClickZoomOut}
      >
        <MinusIcon />
      </MapButton>
    </>
  );
};
