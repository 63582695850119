import {
  IQuery,
  IQueryFindRegionArgs,
  IQueryFindRegionByPrefixArgs,
  IQueryGetComputedDirectionArgs,
} from '@api/gql/types';
import { gql } from '@apollo/client';
import { sample } from 'effector';
import { boundingExtent } from 'ol/extent';
import { fromLonLat } from 'ol/proj';
import { apiClient } from 'src/api/provider';

import { FilterRegionsData } from '@features/passenger-traffic/stores';
import {
  $Map,
  $Settings,
  FilterRegionApi,
  SetListRegionsPayload,
  getComputedDirectionFx,
  getComputedDirectionHighwayFx,
  getComputedDirectionRailwayFx,
  getComputedPassTrafficFx,
  resetAllFilters,
} from '@features/pt-forecast/stores';
import {
  $Timeline,
  TimelineApi,
} from '@features/pt-forecast/stores/timeline/store';

import {
  $FilterRegions,
  findRegionByPrefixFn,
  findRegionByPrefixFx,
  findRegionFn,
  findRegionFx,
  setRegionFn,
} from '.';

// триггерим событие на установку подходящих регионов
sample({
  clock: findRegionByPrefixFx.done,
  fn: request => {
    return {
      point: request.params.point,
      options: request.result,
    } as SetListRegionsPayload;
  },
  target: FilterRegionApi.setListRegions,
});

// Очистить выбранные точки и сообщить карте
sample({
  clock: setRegionFn,
  filter: payload => !payload.option,
  target: FilterRegionApi.clearSelectedRegions,
});

// Получить расширенную информацию по искому региону и записать в стор
sample({
  clock: findRegionFx.done,
  fn: request => ({
    point: request.params.point,
    option: request.result.transportRegion,
  }),
  target: FilterRegionApi.setSelectedRegion,
});

// триггерим событие на поиск региона
sample({
  clock: findRegionFn,
  target: findRegionFx,
});

// триггерим событие на поиск подходящих регионов через ввод
sample({
  clock: findRegionByPrefixFn,
  target: findRegionByPrefixFx,
});

// триггерим событие на поиск региона через поле ввода
sample({
  clock: setRegionFn,
  filter: payload => Boolean(payload.option),
  fn: payload => {
    return {
      lat: payload.option?.center?.geometry.coordinates[0],
      lon: payload.option?.center?.geometry.coordinates[1],
      point: payload.point,
    };
  },
  target: findRegionFx,
});

// ЕСли обе точки установленны, запросить данные по корреспонденции
sample({
  clock: FilterRegionApi.setSelectedRegion,
  source: { FilterRegions: $FilterRegions, Settings: $Settings },
  filter: ({ FilterRegions }) =>
    Boolean(FilterRegions.selectedRegionA && FilterRegions.selectedRegionB),
  fn: ({ FilterRegions, Settings }) => ({
    regionFrom: FilterRegions.selectedRegionA?.id,
    regionTo: FilterRegions.selectedRegionB?.id,
    computingRegistryId: Settings.scenarioId,
  }),
  target: getComputedPassTrafficFx,
});

sample({
  clock: [FilterRegionApi.setSelectedRegion, TimelineApi.selectYear],
  source: {
    FilterRegions: $FilterRegions,
    Timeline: $Timeline,
    Settings: $Settings,
  },
  filter: ({ FilterRegions }) =>
    Boolean(FilterRegions.selectedRegionA && FilterRegions.selectedRegionB),
  fn: ({ FilterRegions, Timeline, Settings }) =>
    ({
      computingRegistryId: Settings.scenarioId,
      regionFrom: FilterRegions.selectedRegionA?.id,
      regionTo: FilterRegions.selectedRegionB?.id,
      includePayment: true,
      plannedYear: (Timeline.baseYear || 0) + (Timeline.selectedYear || 0),
    }) as IQueryGetComputedDirectionArgs,
  target: [
    getComputedDirectionFx,
    getComputedDirectionHighwayFx,
    getComputedDirectionRailwayFx,
    FilterRegionApi.clearDirections,
  ],
});

// Записать полученную корреспонденцию в стор
sample({
  clock: getComputedDirectionFx.done,
  source: { Map: $Map, FilterRegions: $FilterRegions },
  fn: ({ Map, FilterRegions }, request) => {
    // Центрирование между выбранными регионами
    const a = FilterRegions.selectedRegionA?.center?.geometry?.coordinates;
    const b = FilterRegions.selectedRegionB?.center?.geometry?.coordinates;
    if (a?.length && b?.length) {
      const extent = boundingExtent([fromLonLat(a), fromLonLat(b)]);
      Map.map
        ?.getView()
        .fit(extent, { padding: [150, 600, 150, 600], duration: 200 });
    }

    return {
      regions: request.result?.getTransportRegionsIntersection || [],
      stations: request.result?.getRailwayStationsIntersection || [],
    } as FilterRegionsData['directions'];
  },
  target: FilterRegionApi.setDirections,
});

sample({
  clock: getComputedDirectionHighwayFx.done,
  fn: request => request.result?.highways || [],
  target: FilterRegionApi.setHighwayDirections,
});

sample({
  clock: getComputedDirectionRailwayFx.done,
  fn: request => request.result?.railways || [],
  target: FilterRegionApi.setRailwayDirections,
});

// Сброс регионов при сбросе всех остальных фильтров
sample({
  clock: resetAllFilters,
  target: FilterRegionApi.reset,
});

// // Сброс прогресс бара после завершения запроса
// sample({
//   clock: [getComputedDirectionFx.done, getComputedDirectionFx.fail],
//   fn: () => false,
//   target: setVisibleProgressGeometry,
// });

getComputedDirectionRailwayFx.use(async params => {
  // setVisibleProgressGeometry(true);

  const response = await apiClient.query<
    IQuery,
    IQueryGetComputedDirectionArgs
  >({
    query: gql`
      query QueryGetComputedDirection(
        $regionFrom: String
        $regionTo: String
        $plannedYear: Int
        $includePayment: Boolean
        $computingRegistryId: String
      ) {
        getComputedDirection(
          includePayment: $includePayment
          plannedYear: $plannedYear
          regionFrom: $regionFrom
          regionTo: $regionTo
          computingRegistryId: $computingRegistryId
        ) {
          railways {
            id
            geometry
            details {
              status
              year
            }
          }
        }
      }
    `,
    variables: params,
  });

  return response.data.getComputedDirection;
});

getComputedDirectionHighwayFx.use(async params => {
  // setVisibleProgressGeometry(true);

  const response = await apiClient.query<
    IQuery,
    IQueryGetComputedDirectionArgs
  >({
    query: gql`
      query QueryGetComputedDirection(
        $regionFrom: String
        $regionTo: String
        $plannedYear: Int
        $includePayment: Boolean
        $computingRegistryId: String
      ) {
        getComputedDirection(
          includePayment: $includePayment
          plannedYear: $plannedYear
          regionFrom: $regionFrom
          regionTo: $regionTo
          computingRegistryId: $computingRegistryId
        ) {
          highways {
            id
            geometry
            details {
              status
              year
            }
          }
        }
      }
    `,
    variables: params,
  });

  return response.data.getComputedDirection;
});

// запрос на получение всех путей по точкам
getComputedDirectionFx.use(async params => {
  // setVisibleProgressGeometry(true);

  const response = await apiClient.query<
    IQuery,
    IQueryGetComputedDirectionArgs
  >({
    query: gql`
      query QueryGetComputedDirection(
        $regionFrom: String
        $regionTo: String
        $plannedYear: Int
        $includePayment: Boolean
        $computingRegistryId: String
      ) {
        getComputedDirection(
          includePayment: $includePayment
          plannedYear: $plannedYear
          regionFrom: $regionFrom
          regionTo: $regionTo
          computingRegistryId: $computingRegistryId
        ) {
          id
          getTransportRegionsIntersection {
            id
            border {
              geometry
            }
          }
          getRailwayStationsIntersection {
            id
            name
            geometry
          }
        }
      }
    `,
    variables: params,
  });

  return response.data.getComputedDirection;
});

// запрос на получение тр по точке на карте
findRegionFx.use(async params => {
  const response = await apiClient.query<IQuery, IQueryFindRegionArgs>({
    query: gql`
      query QueryFindRegion($lat: Float!, $lon: Float!) {
        findRegion(lat: $lat, lon: $lon) {
          transportRegion {
            id
            name
            center {
              name
              id
              geometry
            }
            railwaystationSet {
              geometry
              id
              isTerminal
              details {
                year
              }
            }
            busstationSet {
              geometry
              id
              details {
                year
              }
            }
            airportSet {
              geometry
              id
              details {
                year
              }
            }
          }
          geometry
        }
      }
    `,
    variables: {
      lon: params.lon,
      lat: params.lat,
    },
  });

  return response.data.findRegion!;
});

findRegionByPrefixFx.use(async params => {
  if (params.name!.length < (params.maxCount || 3)) return [];

  const response = await apiClient.query<IQuery, IQueryFindRegionByPrefixArgs>({
    query: gql`
      query QueryFindRegionByPrefix($name: String, $maxCount: Int) {
        findRegionByPrefix(name: $name, maxCount: $maxCount) {
          id
          name
          trId
          center {
            geometry
            id
            name
            transportRegion {
              center {
                geometry
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: {
      name: params.name,
      maxCount: params.maxCount,
    },
  });

  return response.data.findRegionByPrefix;
});
