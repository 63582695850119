import { useMemo } from 'react';

import { Divider, Typography } from '@mui/material';

import { Accordion } from '@components/Accordion';
import { ClockIcon } from '@components/icons';

import { TravelTimeBlock } from '@features/pt-forecast-new/components/Recalculation/TravelTimeBlock';
import { EVehicle } from '@features/pt-forecast-new/constants/eVehicle';
import { VehicleParameterRecalculate } from '@features/pt-forecast-new/stores/recalculate';

type TravelTimeContainerProps = {
  duration: Record<Exclude<EVehicle, 'multimodal'>, number>;
  base: Record<Exclude<EVehicle, 'multimodal'>, number>;
  disabled: boolean;
  onChange: (param: VehicleParameterRecalculate) => void;
};

export const TravelTimeContainer = (props: TravelTimeContainerProps) => {
  const { duration, base, disabled, onChange } = props;

  const nightTrain = useMemo(
    () => +duration.nightTrain.toFixed(2),
    [duration.nightTrain],
  );

  const dayTrain = useMemo(
    () => +duration.dayTrain.toFixed(2),
    [duration.dayTrain],
  );

  const suburbanTrain = useMemo(
    () => +duration.suburbanTrain.toFixed(2),
    [duration.suburbanTrain],
  );

  const expressTrain = useMemo(
    () => +duration.expressTrain.toFixed(2),
    [duration.expressTrain],
  );

  const bus = useMemo(() => +duration.bus.toFixed(2), [duration.bus]);

  const auto = useMemo(() => +duration.auto.toFixed(2), [duration.auto]);

  const avia = useMemo(() => +duration.avia.toFixed(2), [duration.avia]);

  return (
    <Accordion
      defaultExpanded={false}
      sx={{
        pl: theme => theme.typography.pxToRem(44),
        pr: theme => theme.typography.pxToRem(24),
      }}
      summaryComponent={
        <>
          <ClockIcon />

          <Typography
            variant={'subtitle2'}
            sx={{ ml: 1 }}
          >
            Время в пути
          </Typography>
        </>
      }
      detailsSx={{ padding: '0' }}
    >
      {!!base.nightTrain && (
        <>
          <TravelTimeBlock
            icon={{
              borderColor: 'black',
              backgroundColor: 'trainNight',
            }}
            name={'Ночной поезд дальнего следования'}
            value={nightTrain}
            baseValue={base.nightTrain}
            disabled={disabled}
            getValue={value => onChange({ key: 'nightTrain', value })}
          />

          <Divider sx={{ my: '16px' }} />
        </>
      )}

      {!!base.dayTrain && (
        <>
          <TravelTimeBlock
            icon={{
              borderColor: 'black',
              backgroundColor: 'trainDay',
            }}
            name={'Дневной поезд дальнего следования'}
            value={dayTrain}
            baseValue={base.dayTrain}
            disabled={disabled}
            getValue={value => onChange({ key: 'dayTrain', value })}
          />

          <Divider sx={{ my: '16px' }} />
        </>
      )}

      {!!base.suburbanTrain && (
        <>
          <TravelTimeBlock
            icon={{
              borderColor: 'black',
              backgroundColor: 'trainLocal',
            }}
            name={'Пригородный поезд'}
            value={suburbanTrain}
            baseValue={base.suburbanTrain}
            disabled={disabled}
            getValue={value => onChange({ key: 'suburbanTrain', value })}
          />

          <Divider sx={{ my: '16px' }} />
        </>
      )}

      {!!base.expressTrain && (
        <>
          <TravelTimeBlock
            icon={{
              borderColor: 'black',
              backgroundColor: 'trainHighSpeed',
            }}
            name={'Высокоскоростной поезд'}
            value={expressTrain}
            baseValue={base.expressTrain}
            disabled={disabled}
            getValue={value => onChange({ key: 'expressTrain', value })}
          />

          <Divider sx={{ my: '16px' }} />
        </>
      )}

      {!!base.bus && (
        <>
          <TravelTimeBlock
            icon={{
              borderColor: 'auto',
              backgroundColor: 'auto',
            }}
            name={'Автобус'}
            value={bus}
            baseValue={base.bus}
            disabled={disabled}
            getValue={value => onChange({ key: 'bus', value })}
          />

          <Divider sx={{ my: '16px' }} />
        </>
      )}

      {!!base.auto && (
        <>
          <TravelTimeBlock
            icon={{
              borderColor: 'expressways',
              backgroundColor: 'expressways',
            }}
            name={'Личный транспорт'}
            value={auto}
            baseValue={base.auto}
            disabled={disabled}
            getValue={value => onChange({ key: 'auto', value })}
          />

          <Divider sx={{ my: '16px' }} />
        </>
      )}

      {!!base.avia && (
        <TravelTimeBlock
          icon={{
            borderColor: 'avia',
            backgroundColor: 'avia',
          }}
          name={'Самолет'}
          value={avia}
          baseValue={base.avia}
          disabled={disabled}
          getValue={value => onChange({ key: 'avia', value })}
        />
      )}
    </Accordion>
  );
};
