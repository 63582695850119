import { createElement, memo } from 'react';

import { FlexibleObject } from '@features/tpu-simulation-model/components/Editor/SpatialObjects/FlexibleObject';
import { Ladder } from '@features/tpu-simulation-model/components/Editor/SpatialObjects/Ladder';
import { Path } from '@features/tpu-simulation-model/components/Editor/SpatialObjects/Path';
import { Platform } from '@features/tpu-simulation-model/components/Editor/SpatialObjects/Platform';
import { Room } from '@features/tpu-simulation-model/components/Editor/SpatialObjects/Room';
import { Wall } from '@features/tpu-simulation-model/components/Editor/SpatialObjects/Wall';
import {
  EditableObjectType,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

type SpatialObjectProps = {
  isSelected: boolean;
  opacity: number;
  onSelect: (arg: ObjectOfTheSimulationModel) => void;
  updateObjectData: (arg: ObjectOfTheSimulationModel) => void;
} & ObjectOfTheSimulationModel;

const SpatialObjectComponent = ({
  onSelect,
  isSelected,
  updateObjectData,
  opacity,
  ...props
}: SpatialObjectProps) => {
  switch (props.type) {
    case EditableObjectType.Room:
      return createElement(Room, { ...props, onSelect, opacity });
    case EditableObjectType.Wall:
      return createElement(Wall, { ...props, onSelect, opacity });
    case EditableObjectType.Platform:
      return createElement(Platform, {
        ...props,
        onSelect,
        updateObjectData,
        isSelected,
      });
    case EditableObjectType.Path:
      return createElement(Path, {
        ...props,
        onSelect,
        isSelected,
        updateObjectData,
      });
    case EditableObjectType.Ladder:
      return createElement(Ladder, {
        ...props,
        onSelect,
        isSelected,
        updateObjectData,
      });
    case EditableObjectType.Elevator:
    case EditableObjectType.Door:
    case EditableObjectType.PassengerGenerator:
    case EditableObjectType.Exit:
    case EditableObjectType.TicketOffice:
    case EditableObjectType.TicketPrintingMachine:
    case EditableObjectType.SecurityCheckpoint:
    case EditableObjectType.Toilet:
    case EditableObjectType.Turnstile:
    case EditableObjectType.Waiting:
    case EditableObjectType.InformationTable:
    case EditableObjectType.Restaurant:
    case EditableObjectType.Buffet:
    case EditableObjectType.VendingMachine:
    case EditableObjectType.Store:
    case EditableObjectType.Kiosk:
    case EditableObjectType.Bank:
    case EditableObjectType.Bankomat:
    case EditableObjectType.Other:
      return createElement(FlexibleObject, {
        data: props,
        updateObjectData,
        onSelect,
        isSelected,
      });
    default:
      null;
  }
};

export const SpatialObject = memo(SpatialObjectComponent);
