export const SOC_ECO_TH_3_5 = [
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'graphCategory',
    label: 'Категория графа',
  },
  {
    id: 'volumesTechnogenicImpact',
    label:
      'Объёмы техногенного воздействия с учётом мероприятий, тыс. тонн выбросов',
  },
];
