import {
  IAgentShareInfoCategoryEnum,
  IUpdateAgentShareInfoInput,
} from '@api/gql/tpu-types';
import { useUnit } from 'effector-react';

import {
  closeModalDeletePassengerTypeFn,
  openModalDeletePassengerTypeFn,
  resetDeletePassengerTypeStoreFn,
  triggerRemovePassengerTypeFn,
} from '@features/tpu-passenger-types/stores';
import {
  createPassengerTypeFn,
  createTypeFx,
  openCategoryFn,
  openSharesFn,
  openTypeForCreateFn,
  openTypeForEditFn,
  resetFormStateFn,
  savePassengerTypeFn,
  setFormStateFn,
  setIsOpenTypeFormFn,
  setIsTriggerSaveFormFn,
  updateTypeFx,
} from '@features/tpu-passenger-types/stores/form.store';
import {
  openShareForEditFn,
  savePassengerShareFn,
  setIsOpenShareFormFn,
  setIsTriggerSaveShareFormFn,
  setSelectedCategoryFn,
  setShareFormStateFn,
  updateShareFx,
} from '@features/tpu-passenger-types/stores/formShare.store';
import {
  getAllAgentShareInfoCategoryFx,
  setScenarioIdFn,
} from '@features/tpu-passenger-types/stores/registry.store';
import { IForm, IShareForm } from '@features/tpu-passenger-types/types';

export interface UsePassengerTypesHandlers {
  setIsOpenTypeForm(payload: boolean): void;
  openTypeForEdit(payload: string): void;
  getAllAgentShareInfoCategory(scenarioId: string | undefined): void;
  createType(payload: boolean): void;
  updateType(payload: IUpdateAgentShareInfoInput): void;
  openCategory(payload: boolean): void;
  openShares(payload: boolean): void;
  saveType(payload: IForm): void;
  setIsTriggerSaveForm(payload: boolean): void;
  setFormState(payload: IForm): void;
  removePassengerType(): void;
  savePassengerType(payload: IForm): void;
  setIsOpenShareForm(payload: boolean): void;
  setIsTriggerSaveShareForm(payload: boolean): void;
  openShareForEdit(payload: string): void;
  setShareFormState(payload: IShareForm): void;
  savePassengerShare(payload: IShareForm): void;
  setSelectedCategory(payload: IAgentShareInfoCategoryEnum): void;
  setScenarioId(): void;
  resetFormState(): void;
  openTypeForCreate(): void;
  resetDeletePassengerTypeStore(): void;
  closeModalDeletePassengerType(): void;
  openModalDeletePassengerType(payload: { name: string; id: string }): void;
}

export const usePassengerTypesHandlers = (): UsePassengerTypesHandlers => {
  const events = useUnit({
    setIsOpenTypeFormFn,
    openTypeForEditFn,
    getAllAgentShareInfoCategoryFx,
    createPassengerTypeFn,
    openCategoryFn,
    openSharesFn,
    createTypeFx,
    setIsTriggerSaveFormFn,
    setFormStateFn,
    triggerRemovePassengerTypeFn,
    updateTypeFx,
    savePassengerTypeFn,
    setIsOpenShareFormFn,
    setIsTriggerSaveShareFormFn,
    openShareForEditFn,
    setShareFormStateFn,
    updateShareFx,
    savePassengerShareFn,
    setSelectedCategoryFn,
    setScenarioIdFn,
    resetFormStateFn,
    openTypeForCreateFn,
    resetDeletePassengerTypeStoreFn,
    closeModalDeletePassengerTypeFn,
    openModalDeletePassengerTypeFn,
  });

  return {
    setIsOpenTypeForm: events.setIsOpenTypeFormFn,
    openTypeForEdit: events.openTypeForEditFn,
    getAllAgentShareInfoCategory: events.getAllAgentShareInfoCategoryFx,
    createType: events.openCategoryFn,
    openCategory: events.openCategoryFn,
    openShares: events.openSharesFn,
    saveType: events.createPassengerTypeFn,
    setIsTriggerSaveForm: events.setIsTriggerSaveFormFn,
    setFormState: events.setFormStateFn,
    removePassengerType: events.triggerRemovePassengerTypeFn,
    updateType: events.updateTypeFx,
    savePassengerType: events.savePassengerTypeFn,
    setIsOpenShareForm: events.setIsOpenShareFormFn,
    setIsTriggerSaveShareForm: events.setIsTriggerSaveShareFormFn,
    openShareForEdit: events.openShareForEditFn,
    setShareFormState: events.setShareFormStateFn,
    savePassengerShare: events.savePassengerShareFn,
    setSelectedCategory: events.setSelectedCategoryFn,
    setScenarioId: events.setScenarioIdFn,
    resetFormState: events.resetFormStateFn,
    openTypeForCreate: events.openTypeForCreateFn,
    resetDeletePassengerTypeStore: events.resetDeletePassengerTypeStoreFn,
    closeModalDeletePassengerType: events.closeModalDeletePassengerTypeFn,
    openModalDeletePassengerType: events.openModalDeletePassengerTypeFn,
  };
};
