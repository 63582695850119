import { useUnit } from 'effector-react';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Box,
  Chip,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
  iconButtonClasses,
} from '@mui/material';

import {
  BasketIcon,
  CopyIcon,
  EditIcon,
  ShareOutlinedIcon,
} from '@components/icons';

import { ECalculationScenarioStatusType } from '@features/pt-constructor/constants/calculationScenarioStatusType';
import { useConfirms } from '@features/pt-constructor/hooks/useConfirms';
import { CalculationsApi } from '@features/pt-constructor/store/lists/scenarios/store';

import {
  $CalculationStatuses,
  $Calculations,
  $SelectedCalculationScenario,
  CalculationScenario,
  SelectedCalculationScenarioApi,
  copyUserCalculation,
  createCopyCalculationScenario,
  createOrUpdateCalculationScenario,
  deleteUserScenario,
  setModalView,
} from '../store';
import {
  $ErrorsCreateCalculation,
  checkErrorName,
} from '../store/errorsCreateCalculation';

export const RegisterItem = ({ item, disabled }: RegisterItemProps) => {
  const { id } = useUnit($SelectedCalculationScenario);
  const [scenarioName, setScenarioName] = useState(item?.name || '');
  const { selectCalculationScenario, setNameCalculationScenario } = useUnit(
    SelectedCalculationScenarioApi,
  );
  const $calculationStatuses = useUnit($CalculationStatuses);
  const { readonly } = useUnit($Calculations);
  const { setReadonly, setVisibleFormCreateCalculationScenario } =
    useUnit(CalculationsApi);
  const $errorsCreateCalculation = useUnit($ErrorsCreateCalculation);
  const handlers = useUnit({
    copyUserCalculation,
    createOrUpdateCalculationScenario,
    deleteUserScenario,
    createCopyCalculationScenario,
  });

  const { beforeDeleteHandler, beforeDropScenario } = useConfirms();

  // Статус текущего расчета
  const calculationStatus = item
    ? $calculationStatuses.statuses.find(e => e.id === item.id)?.status
    : null;

  const disabledCopy = useMemo(
    () =>
      (calculationStatus !== ECalculationScenarioStatusType.Success &&
        calculationStatus !== ECalculationScenarioStatusType.Draft) ||
      !!disabled,
    [calculationStatus, disabled],
  );

  const disabledEdit = useMemo(
    () =>
      calculationStatus === ECalculationScenarioStatusType.Queue ||
      calculationStatus === ECalculationScenarioStatusType.Pending ||
      !!disabled,
    [calculationStatus, disabled],
  );

  const disabledShare = useMemo(
    () =>
      (calculationStatus !== ECalculationScenarioStatusType.Success &&
        calculationStatus !== ECalculationScenarioStatusType.Draft) ||
      !!disabled,
    [calculationStatus, disabled],
  );

  const disabledRemove = useMemo(
    () =>
      calculationStatus === ECalculationScenarioStatusType.Queue ||
      calculationStatus === ECalculationScenarioStatusType.Pending ||
      !!disabled,
    [calculationStatus, disabled],
  );

  // Копировать расчет
  const onCopyCalculation = useCallback(
    (_e: SyntheticEvent<Element, Event>, disabled: boolean) => {
      if (!item || disabled) return;

      beforeDropScenario(() => {
        handlers.createCopyCalculationScenario({ run: false });
      });
    },
    [beforeDropScenario, handlers, item],
  );

  // Редактировать расчет
  const handleEditUserCalculation = useCallback(
    (e: SyntheticEvent<Element, Event>, disabled: boolean) => {
      e.stopPropagation();

      if (!item || disabled) return;

      beforeDropScenario(() => {
        setReadonly(false);
        selectCalculationScenario(item);
      });
    },
    [beforeDropScenario, setReadonly, item, selectCalculationScenario],
  );

  // Отобразить модалку - поделиться расчетом
  const handleShareCalculation = useCallback(
    (e: SyntheticEvent<Element, Event>, disabled: boolean) => {
      e.stopPropagation();

      if (!item || disabled) return;

      beforeDropScenario(() => {
        setReadonly(true);
        setModalView({ shareCalculation: true });
      });
    },
    [beforeDropScenario, setReadonly, item],
  );

  // Отобразить модалку удаления расчета
  const handleDeleteCalculation = useCallback(
    (e: SyntheticEvent<Element, Event>, disabled: boolean) => {
      e.stopPropagation();

      if (!item || disabled) return;

      beforeDropScenario(() => {
        setReadonly(true);
        selectCalculationScenario(item);
        beforeDeleteHandler(isDelete => {
          if (isDelete && item?.id) handlers.deleteUserScenario(item.id);
        });
      });
    },
    [
      beforeDeleteHandler,
      beforeDropScenario,
      handlers,
      item,
      selectCalculationScenario,
      setReadonly,
    ],
  );

  // просмотреть параметры расчета
  const handleSelectedScenarioCalculation = useCallback(() => {
    if (!item) return;

    beforeDropScenario(() => {
      setReadonly(true);
      setVisibleFormCreateCalculationScenario(false);
      selectCalculationScenario(item);
    });
  }, [
    item,
    beforeDropScenario,
    setReadonly,
    setVisibleFormCreateCalculationScenario,
    selectCalculationScenario,
  ]);

  // запись имени сценария в стор
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeScenarioName = useCallback(
    (e: SyntheticEvent<Element, Event>) => {
      const value = (e?.target as HTMLInputElement)?.value;
      setScenarioName(value);
      $errorsCreateCalculation.isCheckErrors && checkErrorName(value);
    },
    [$errorsCreateCalculation.isCheckErrors],
  );

  // запись в стор имени выбранного или нового сценария
  const onBlurChangeScenarioName = useCallback(() => {
    setNameCalculationScenario(scenarioName);
  }, [scenarioName, setNameCalculationScenario]);

  useEffect(() => {
    if (!(item?.id !== id || (readonly && item?.id === id))) {
      setScenarioName(item?.name || '');
    }
  }, [id, item?.id, item?.name, readonly]);

  return (
    <ListItem
      key={'userScenarios' + item?.id}
      className={id === item?.id ? 'selected' : ''}
      sx={{
        pl: 4,
        py: 0.5,
        '&:hover, &.selected': {
          bgcolor: 'customs.bgHover',
          borderRadius: '4px',
        },
      }}
      onClick={
        item?.id !== id || (readonly && item?.id === id)
          ? handleSelectedScenarioCalculation
          : undefined
      }
    >
      {item?.id !== id || (readonly && item?.id === id) ? (
        <>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body2',
              sx: {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            }}
            primary={
              <Tooltip
                title={item?.name}
                placement="top-start"
              >
                <span>{item?.name}</span>
              </Tooltip>
            }
          />

          <Stack
            direction="row"
            gap={2}
            ml={1}
            alignItems={'center'}
          >
            {calculationStatus === ECalculationScenarioStatusType.Success && (
              <Chip
                color="success"
                label={'Завершено'}
                style={{ width: '96px' }}
              />
            )}
            {calculationStatus === ECalculationScenarioStatusType.Pending && (
              <Chip
                color="info"
                label={'Расчет...'}
                style={{ width: '96px' }}
              />
            )}
            {calculationStatus === ECalculationScenarioStatusType.Draft && (
              <Chip
                color="default"
                label={'Черновик'}
                style={{ width: '96px' }}
              />
            )}
            {calculationStatus === ECalculationScenarioStatusType.Failure && (
              <Chip
                color="error"
                label={'Ошибка'}
                style={{ width: '96px' }}
              />
            )}
            {calculationStatus === ECalculationScenarioStatusType.Queue && (
              <Chip
                color="default"
                label={'В очереди'}
                style={{ width: '96px' }}
              />
            )}

            <Stack direction="row">
              <Tooltip
                title={'Копировать'}
                placement="top"
              >
                <IconButton
                  size={'small'}
                  className={disabledCopy ? iconButtonClasses.disabled : ''}
                  onClick={e => onCopyCalculation(e, disabledCopy)}
                >
                  <CopyIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={'Редактировать'}
                placement="top"
              >
                <IconButton
                  size={'small'}
                  className={disabledEdit ? iconButtonClasses.disabled : ''}
                  onClick={e => handleEditUserCalculation(e, disabledEdit)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={'Поделиться'}
                placement="top"
              >
                <IconButton
                  size={'small'}
                  className={disabledShare ? iconButtonClasses.disabled : ''}
                  onClick={e => handleShareCalculation(e, disabledShare)}
                >
                  <ShareOutlinedIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={'Удалить'}
                placement="top"
              >
                <IconButton
                  size={'small'}
                  className={disabledRemove ? iconButtonClasses.disabled : ''}
                  onClick={e => handleDeleteCalculation(e, disabledRemove)}
                >
                  <BasketIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
        >
          <TextField
            fullWidth
            autoFocus={!id}
            value={scenarioName}
            onChange={onChangeScenarioName}
            onBlur={onBlurChangeScenarioName}
            placeholder="Введите название сценария расчета..."
          />

          {$errorsCreateCalculation.isErrorName && (
            <Typography
              variant="caption"
              sx={{
                color: theme => theme.palette.secondary.main,
                mt: 1,
              }}
            >
              Имя должно быть не меньше двух символов
            </Typography>
          )}
        </Box>
      )}
    </ListItem>
  );
};

interface RegisterItemProps {
  item?: CalculationScenario;
  disabled?: boolean;
}
