import {
  $PlanSettings,
  decrementScaleFn,
  incrementScaleFn,
  resetPlanSettingFn,
  setModelBlockSizeFn,
  setOpacityFn,
  setPositionFn,
  setScaleFn,
  setShouldCenterStagePositionAndResetScaleFn,
  toggleHeatmapFn,
} from '@features/tpu-calculation-scenarios-viewing/stores/plan-settings/plan-settings.store';

$PlanSettings
  .reset(resetPlanSettingFn)
  .on(setScaleFn, (state, payload) => {
    return { ...state, scale: payload };
  })
  .on(incrementScaleFn, state => {
    const newScale = state.scale + 0.05;
    return {
      ...state,
      scale: newScale > 3 ? 3 : newScale,
    };
  })
  .on(decrementScaleFn, state => {
    const newScale = state.scale - 0.05;
    return {
      ...state,
      scale: newScale < 0.25 ? 0.25 : newScale,
    };
  })
  .on(setShouldCenterStagePositionAndResetScaleFn, (state, payload) => {
    return {
      ...state,
      shouldCenterStagePosition: payload,
      scale: 1,
      positionX: 0,
      positionY: 0,
    };
  })
  .on(setModelBlockSizeFn, (state, { width, height }) => {
    return { ...state, width, height };
  })
  .on(setPositionFn, (state, { x, y }) => {
    return { ...state, positionX: x, positionY: y };
  })
  .on(setOpacityFn, (state, opacity) => {
    return { ...state, opacity };
  })
  .on(toggleHeatmapFn, state => {
    return { ...state, isHeatmapOn: !state.isHeatmapOn };
  });
