import { createApi, createStore } from 'effector';

const initState: TableGraphParamsType = {
  isAlertInputError: false,
  isGetDirections: false,
  isUpdateParamsDirection: false,
  isRedirection: false,
};

export const $UI = createStore<TableGraphParamsType>(initState);

export const UIApi = createApi($UI, {
  setVisible: (store, payload: Record<string, boolean>) => ({
    ...store,
    ...payload,
  }),
  resetUI: () => initState,
});

type TableGraphParamsType = {
  isAlertInputError: boolean;
  isGetDirections: boolean;
  isUpdateParamsDirection: boolean;
  isRedirection: boolean;
};
