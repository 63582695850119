import { useFloors } from '@features/tpu-simulation-model/hooks/useFloors';
import { useSimulationModelHandlers } from '@features/tpu-simulation-model/hooks/useSimulationModelHandlers';
import {
  ActionEnum,
  IElevator,
  ILadder,
  isLadderOrElevator,
} from '@features/tpu-simulation-model/types';

type ObjectType = ILadder | IElevator;

interface ConnectedObjects {
  changeConnectedObjects: (
    formData: ObjectType,
    action: ActionEnum,
  ) => ObjectType;
}

export const useConnectedObjects = (): ConnectedObjects => {
  const { getObjectById } = useFloors();
  const { updateObjectDataByFloorId } = useSimulationModelHandlers();

  const changeConnectedObjects = (formData: ObjectType, action: ActionEnum) => {
    const connectedObjects: string[] =
      formData.connectedObjects.filter(Boolean);
    const connectedFloors: string[] = formData.connectedFloors.filter(Boolean);

    if (connectedObjects.length) {
      connectedObjects.forEach((id, i) => {
        const object = getObjectById(id, connectedFloors[i]);

        if (!(object && isLadderOrElevator(object))) return;

        let newConnectedObjects: string[] = [];
        let newConnectedFloors: string[] = [];

        if (action === ActionEnum.ADD) {
          newConnectedObjects = [
            formData.uuid,
            ...connectedObjects.filter(item => item !== object.uuid),
          ];
          newConnectedFloors = [
            formData.floorId,
            ...connectedFloors.filter(item => item !== object.floorId),
          ];
        }
        if (action === ActionEnum.REMOVE) {
          newConnectedObjects = object.connectedObjects.filter(
            item => item !== formData.uuid,
          );
          newConnectedFloors = object.connectedFloors.filter(
            item => item !== formData.floorId,
          );
        }

        const newDataObject = {
          ...object,
          connectedFloors: newConnectedFloors,
          direction: formData?.direction,
          connectedObjects: newConnectedObjects,
        };
        updateObjectDataByFloorId(newDataObject);
      });
    }

    return {
      ...formData,
      connectedFloors,
      connectedObjects,
    };
  };

  return {
    changeConnectedObjects,
  };
};
