import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import { useCalculationScenariosViewingHandlers } from './useCalculationScenariosViewingHandlers/useCalculationScenariosViewingHandlers';

import { $Player } from '../stores/scenarioPlayer/scenarioPlayer.store';

export const useScenarioPlayerTicks = () => {
  const { uploadTick, playTick } = useCalculationScenariosViewingHandlers();

  const { playSpeed, uploadSpeed } = useUnit($Player);

  useEffect(() => {
    const interval = setInterval(() => {
      uploadTick();
    }, uploadSpeed);

    return () => {
      clearInterval(interval);
    };
  }, [uploadSpeed, uploadTick]);

  useEffect(() => {
    const interval = setInterval(() => {
      playTick();
    }, playSpeed);

    return () => {
      clearInterval(interval);
    };
  }, [playSpeed, playTick]);
};
