import {
  IComputingRegistryType,
  IQuery,
  IQueryGetComputingRegistryInstanceByIdArgs,
} from '@api/gql/types';
import { createApi, createEffect, createStore } from 'effector';

import { ECalculateStatus } from '@features/pt-forecast-new/constants/ECalculateStatus';

const initState: FeatureSettingsStore = {
  scenarioId: null,
  scenarioCalculateStatus: null,
  scenarioDetails: {
    socEcoScenario: null,
    transportScenario: null,
    name: '',
  },
};

export const $FeatureSettings = createStore<FeatureSettingsStore>(initState);

export const FeatureSettingsApi = createApi($FeatureSettings, {
  setScenarioId: (store, scenarioId: FeatureSettingsStore['scenarioId']) => ({
    ...store,
    scenarioId,
  }),
  setScenarioCalculateStatus: (store, payload: ECalculateStatus) => ({
    ...store,
    scenarioCalculateStatus: payload,
  }),
  setScenarioDetails: (
    store,
    payload: FeatureSettingsStore['scenarioDetails'],
  ) => ({
    ...store,
    scenarioDetails: payload,
  }),
  reset: () => initState,
});

export const getScenarioDetailsFx = createEffect<
  IQueryGetComputingRegistryInstanceByIdArgs,
  IQuery['getComputingRegistryInstanceById']
>();

export const getScenarioComputedStatusFx = createEffect<
  IQueryGetComputingRegistryInstanceByIdArgs & { isPending?: boolean },
  IQuery['getComputingRegistryInstanceById']
>();

export type FeatureSettingsStore = {
  scenarioId: string | null;
  scenarioCalculateStatus: ECalculateStatus | null;
  scenarioDetails: Pick<
    IComputingRegistryType,
    'transportScenario' | 'socEcoScenario'
  > & {
    name: string;
  };
};
