import { sample } from 'effector';

import {
  $CurrentChartsData,
  $PieChartColors,
  $UploadedChartsData,
  addUploadedDataForChartsFn,
  getMoreColorsFn,
  resetScenarioChartsDataFn,
} from './charts.store';

import { $Plan } from '@features/tpu-calculation-scenarios-viewing/stores';
import { getMoreColors } from '@features/tpu-calculation-scenarios-viewing/utils/getMoreColors';
import {
  getCurrentDataForCharts,
  parseDataForCharts,
} from '@features/tpu-calculation-scenarios-viewing/utils/сhartsDataParsers';

import {
  $Player,
  getSimulationDataBySimulationIdFx,
} from '../scenarioPlayer/scenarioPlayer.store';

$PieChartColors.on(getMoreColorsFn, (state, result) => {
  if (result > state.length) {
    return [...state, ...getMoreColors(result, state.length)];
  }

  return state;
});

$CurrentChartsData.reset(resetScenarioChartsDataFn);

$UploadedChartsData
  .reset(resetScenarioChartsDataFn)
  .on(addUploadedDataForChartsFn, (state, result) => {
    if (result) {
      return {
        agentsQuantity: [...state.agentsQuantity, ...result.agentsQuantity],
        cumulativeAgentsQuantity: [
          ...state.cumulativeAgentsQuantity,
          ...result.cumulativeAgentsQuantity,
        ],
        entranceDistribution: [
          ...state.entranceDistribution,
          ...result.entranceDistribution,
        ],
        exitsDistribution: [
          ...state.exitsDistribution,
          ...result.exitsDistribution,
        ],
      };
    }
  });

sample({
  clock: $Player.map(({ lastCalculatedSegment }) => lastCalculatedSegment),
  source: $UploadedChartsData,
  fn: (sourceData, clockData) => getCurrentDataForCharts(sourceData, clockData),
  target: $CurrentChartsData,
});

sample({
  clock: getSimulationDataBySimulationIdFx.done,
  source: { uploadedData: $UploadedChartsData, planData: $Plan },
  filter: (_, clockData) => !!clockData.result?.objectResults?.length,
  fn: (sourceData, clockData) =>
    parseDataForCharts(clockData.result, sourceData.planData),
  target: addUploadedDataForChartsFn,
});

sample({
  clock: $CurrentChartsData,
  source: $PieChartColors,
  filter: (pieColors, chartsData) =>
    chartsData.entranceDistribution?.length > pieColors.length ||
    chartsData.exitsDistribution?.length > pieColors.length,
  fn: (_, chartsData) => {
    if (
      chartsData.entranceDistribution.length >=
      chartsData.exitsDistribution.length
    ) {
      return chartsData.entranceDistribution.length;
    }

    return chartsData.exitsDistribution.length;
  },
  target: getMoreColorsFn,
});
