import {
  IMutation,
  IMutationDeleteScenarioArgs,
  IPredictionScenarioSocEcoType,
  IQuery,
  IQueryGetProjectScenariosArgs,
  IQueryGetProjectSocEcoScenariosArgs,
} from '@api/gql/ag-types';
import { createApi, createEffect, createStore } from 'effector';

import { AgSocEcoScenarioParams } from '@features/ag-constructor/constants/agEcoParams';

const initState: ComputedScenarioListState = {
  userScenarios: [],
  availableScenarios: [],
  socEcoScenarios: [],
};

export const $ComputedScenarioList =
  createStore<ComputedScenarioListState>(initState);

export const ComputedScenarioListApi = createApi($ComputedScenarioList, {
  setUserComputedScenarioList: (store, payload: ComputedScenario[]) => ({
    ...store,
    userScenarios: payload,
  }),
  setAvailableComputedScenarioList: (store, payload: ComputedScenario[]) => ({
    ...store,
    availableScenarios: payload,
  }),
  setSocEcoComputedScenarioList: (store, payload) => ({
    ...store,
    socEcoScenarios: payload,
  }),
  pushUserComputedScenarioList: (store, payload) => {
    const userScenarios = [
      ...store.userScenarios.filter(scenario => scenario.id !== payload.id),
      payload,
    ];
    return {
      ...store,
      userScenarios,
    };
  },
  reset: () => initState,
});

export const fetchComputedScenarioListFx = createEffect<
  IQueryGetProjectScenariosArgs,
  IQuery['getProjectScenarios']
>();

export const fetchSocEcoScenarioListFx = createEffect<
  IQueryGetProjectSocEcoScenariosArgs,
  IQuery['getProjectSocEcoScenarios']
>();

export const fetchDeleteScenarioFx = createEffect<
  IMutationDeleteScenarioArgs,
  IMutation['deleteScenario'] | undefined
>();

export type SocEcoScenarioListItem = AgSocEcoScenarioParams &
  IPredictionScenarioSocEcoType;

export type ComputedScenario = {
  id: string;
  name: string;
  user?: string;
};

export type ComputedScenarioListState = {
  userScenarios: ComputedScenario[];
  availableScenarios: ComputedScenario[];
  socEcoScenarios: SocEcoScenarioListItem[];
};
