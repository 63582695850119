import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { RegisterItem } from './RegisterItem';

import SearchIcon from '@mui/icons-material/Search';
import { List, Stack, TextField, Typography, debounce } from '@mui/material';

import { Accordion } from '@components/Accordion';
import { AutoFullHeight } from '@components/AutoFullHeight';

import { CalculationScenario } from '@features/pt-constructor/store/lists/scenarios/store';

import { PaperCard } from '../components/PaperCard';

// const __mockAvailable__ = [
//   {
//     name: 'Ускорение сообщение до 160 (10 агломераций)',
//     user: 'Кристина Миронова',
//     id: 1,
//   },
//   {
//     name: 'Ускорение сообщение до 180 (20 агломераций)',
//     user: 'Вадим Волков',
//     id: 2,
//   },
//   {
//     name: 'Ускорение сообщение до 200 (30 агломераций)',
//     user: 'Антон Быков',
//     id: 3,
//   },
//   {
//     name: 'Ускорение сообщение до 250 (40 агломераций) длинный текст  длинный текст  длинный текст ',
//     user: 'Алексей Тутов',
//     id: 4,
//   },
// ];

export const SettlementRegister = ({
  isNewRegister,
  userCalculations,
}: SettlementRegisterProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [scenarios, setScenarios] = useState(userCalculations);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchValue = useCallback(
    debounce((val: string) => {
      setScenarios(
        val.length === 0
          ? userCalculations
          : userCalculations.filter(
              item => item.name.toLowerCase().indexOf(val.toLowerCase()) >= 0,
            ),
      );
    }, 400),
    [userCalculations],
  );

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchValue(event.target.value);
      debouncedSetSearchValue(event.target.value);
    },
    [debouncedSetSearchValue],
  );

  useEffect(() => {
    setScenarios(
      searchValue.length === 0
        ? userCalculations
        : userCalculations.filter(
            item =>
              item.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0,
          ),
    );
  }, [searchValue, userCalculations]);

  return (
    <PaperCard title={'Реестр расчетов'}>
      <AutoFullHeight
        style={{
          overflowY: 'auto',
          paddingRight: '8px',
          width: '100%',
        }}
        mb={24 + 32}
      >
        <Accordion
          summaryComponent={
            <Stack
              direction="row"
              spacing={2}
              alignItems={'center'}
              width={'100%'}
            >
              <Typography variant={'subtitle1'}>Пользовательские</Typography>

              <TextField
                fullWidth
                size="medium"
                placeholder="Найти проект"
                value={searchValue}
                onChange={handleSearch}
                onClick={event => event.stopPropagation()}
                InputProps={{
                  type: 'search',
                  endAdornment: <SearchIcon />,
                }}
              />
            </Stack>
          }
        >
          <List sx={{ ml: -1, mt: -1 }}>
            {isNewRegister && <RegisterItem />}

            {scenarios.map(item => {
              return (
                <RegisterItem
                  key={item.id}
                  item={item}
                  disabled={isNewRegister}
                />
              );
            })}
          </List>
        </Accordion>

        {/* <Divider sx={{ pt: 2, mb: 2 }} />

        <Accordion
          summaryComponent={
            <Typography variant={'subtitle1'}>Доступные</Typography>
          }
        >
          <List sx={{ ml: -1, mt: -1 }}>
            {__mockAvailable__.map(item => {
              return (
                <ListItem
                  key={'accessibleScenarios' + item.id}
                  sx={{
                    pl: 4,
                    py: 0,
                  }}
                  secondaryAction={
                    <IconButton size={'small'}>
                      <CopyIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'body2',
                      sx: {
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        maxWidth: 'calc(100% - 24px)',
                      },
                    }}
                    secondaryTypographyProps={{
                      variant: 'caption',
                      sx: {
                        color: 'customs.link',
                      },
                    }}
                    secondary={item.user}
                    primary={item.name}
                  />
                </ListItem>
              );
            })}
          </List>
        </Accordion> */}
      </AutoFullHeight>
    </PaperCard>
  );
};

interface SettlementRegisterProps {
  isNewRegister: boolean;
  userCalculations: CalculationScenario[];
}
