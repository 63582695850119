import { Fill, Stroke, Style } from 'ol/style';

import { TileStyleProps } from '@utils/map/styles/types';

export const transportDistrictTileStyle = (props: TileStyleProps) => {
  const { isActive } = props;

  if (isActive)
    return new Style({
      stroke: new Stroke({
        color: 'rgba(119,177,208,0.70)',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(169,218,239,0.40)',
      }),
    });

  return new Style({
    stroke: new Stroke({
      color: 'rgba(119,177,208,0.55)',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(169,218,239,0.13)',
    }),
  });
};
