import { useForm } from 'react-hook-form';

import { Box, Button, Typography } from '@mui/material';

import { CloseIcon } from '@components/icons';

import { InputVisitorShare } from '@features/tpu-passenger-types/components';
import { CATEGORY_TYPE_TRANSLATE } from '@features/tpu-passenger-types/constants';
import { IVisitorsShareForm } from '@features/tpu-passenger-types/types';

interface PassengerShareFormProps {
  formData: IVisitorsShareForm;
  onClose(): void;
  onSave(payload: IVisitorsShareForm): void;
}

export const VisitorsShareForm = ({
  formData,
  onSave,
  onClose,
}: PassengerShareFormProps) => {
  const { watch, control, handleSubmit } = useForm<IVisitorsShareForm>({
    values: formData,
  });

  const share = watch('shareInfo');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '24px',
        width: '100%',
      }}
    >
      <Button
        sx={{
          width: '214px',
        }}
        onClick={handleSubmit(onSave)}
      >
        Сохранить
      </Button>
      <Box
        sx={{
          boxShadow: '0px 0px 8px 0px #00000026',
          borderRadius: '8px',
          backgroundColor: theme => theme.palette.background.paper,
          padding: '24px',
          overflow: 'auto',
          width: '100%',
        }}
      >
        <Box
          sx={{
            gap: '12px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '10px',
              minHeight: '48px',
            }}
          >
            <Box>
              <Typography
                component="span"
                sx={{
                  fontSize: theme => theme.typography.pxToRem(16),
                  fontWeight: '600',
                  mr: '6px',
                }}
              >
                Доли типов пассажиров на ТПУ
              </Typography>

              <Typography
                component="span"
                sx={{
                  fontSize: theme => theme.typography.pxToRem(16),
                  color: theme => theme.palette.text.secondary,
                  fontWeight: '600',
                }}
              >
                ({CATEGORY_TYPE_TRANSLATE.VISITORS})
              </Typography>
            </Box>

            <Button
              variant="text"
              onClick={onClose}
              sx={{
                padding: '10px',
                minWidth: 'unset',
                width: '44px',
                height: '44px',
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          {share.map((item, index) => (
            <InputVisitorShare
              key={index}
              control={control}
              fieldName={`shareInfo.${index}.absoluteQuantity`}
              agentShareInfo={item}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
