import {
  IQueryTrainsByScenarioIdArgs,
  ITrainListType,
  ITrainSortEnum,
  ITrainType,
  ITrainTypeEnum,
  ITurnOnOffTrainInput,
  ITurnOnOffTrainMutation,
} from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';

interface ScheduleDataType {
  list: ITrainType[];
  trainType: ITrainTypeEnum;
  sort: [ITrainSortEnum] | null;
  page: number;
  pageSize: number;
  count: number;
  search: string;
}

export const defaultState = {
  list: [],
  trainType: ITrainTypeEnum.LongDistance,
  page: 1,
  pageSize: 10,
  count: 0,
  search: '',
  sort: null,
};

export const $Schedule = createStore<ScheduleDataType>(defaultState);

export const setSearchFn = createEvent<string>();

export const setPageFn = createEvent<number>();

export const setPageSizeFn = createEvent<number>();

export const setSortFn = createEvent<GridSortModel>();

export const setTrainTypeFn = createEvent<ITrainTypeEnum>();

export const getListOfTrainsFx = createEffect<
  IQueryTrainsByScenarioIdArgs,
  ITrainListType | null
>();

export const turnOnOffTrainFx = createEffect<
  ITurnOnOffTrainInput,
  ITurnOnOffTrainMutation | null
>();
