import { sample } from 'effector';

import {
  checkingLayout,
  filterIngintermediateCorrespondences,
  getFilterKey,
  getVehicleTotalInPassengerTraffic,
} from './utils';

import {
  $FilterRegions,
  FilterRegionsApi,
} from '@features/passenger-traffic/stores/filterRegions';
import {
  $FilterMap,
  setFilters,
} from '@features/passenger-traffic/stores/filters';
import { setVisibleLoading } from '@features/passenger-traffic/stores/ui/store';

import { getPercent } from '@utils/getPercent';

import {
  $PassengerTrafficInspector,
  PassengerTrafficInspectorType,
  getPassengerTrafficForInspectorFx,
  intermediateCorrespondenceTableData,
  setDataCorrespondences,
  setPassengerTraffic,
  setPassengerTrafficPercent,
  setPassengerTrafficResponse,
  setTotalPassengerTraffic,
} from '.';
import {
  $Inspector,
  VehicleTypeCode,
  getDataForInspectorFn,
  resetSelectedLayout,
} from '..';

// триггер на получение пассажиропотока, стоимости и времени в пути всех видов транспорта
sample({
  clock: FilterRegionsApi.setSelectedRegion,
  source: { FilterRegions: $FilterRegions, FilterMap: $FilterMap },
  filter: ({ FilterRegions }) =>
    Boolean(FilterRegions.selectedRegionA && FilterRegions.selectedRegionB),
  fn: ({ FilterRegions, FilterMap }) => ({
    regionFrom: FilterRegions.selectedRegionA?.id,
    regionTo: FilterRegions.selectedRegionB?.id,
    intermediateCorrespondence: FilterMap.intermediateCorrespondents,
  }),
  target: getPassengerTrafficForInspectorFx,
});

// триггер на получение пассажиропотока, стоимости и времени в пути всех видов транспорта при изменении фильтра
sample({
  clock: setFilters,
  source: { FilterRegions: $FilterRegions, FilterMap: $FilterMap },
  filter: ({ FilterRegions }, payload) =>
    Boolean(
      FilterRegions.selectedRegionA &&
        FilterRegions.selectedRegionB &&
        Object.keys(payload).includes('intermediateCorrespondents'),
    ),
  fn: ({ FilterRegions, FilterMap }) => ({
    regionFrom: FilterRegions.selectedRegionA?.id,
    regionTo: FilterRegions.selectedRegionB?.id,
    intermediateCorrespondence: FilterMap.intermediateCorrespondents,
  }),
  target: getPassengerTrafficForInspectorFx,
});

// сеттер данных пасс потока в первоначальном виде
sample({
  clock: getPassengerTrafficForInspectorFx.done,
  fn: payload => payload.result,
  target: setPassengerTrafficResponse,
});

// сеттер пассажиропотока по видам транспорта
sample({
  clock: getPassengerTrafficForInspectorFx.done,
  fn: payload => {
    const result = payload.result;

    return {
      nightTrain: getVehicleTotalInPassengerTraffic(
        result,
        VehicleTypeCode.nightTrain,
      ),
      dayTrain: getVehicleTotalInPassengerTraffic(
        result,
        VehicleTypeCode.dayTrain,
      ),
      suburbanTrain: getVehicleTotalInPassengerTraffic(
        result,
        VehicleTypeCode.suburbanTrain,
      ),
      bus: getVehicleTotalInPassengerTraffic(result, VehicleTypeCode.bus),
      auto: getVehicleTotalInPassengerTraffic(result, 'Личный автомобиль'),
      avia: getVehicleTotalInPassengerTraffic(result, VehicleTypeCode.avia),
      expressTrain: getVehicleTotalInPassengerTraffic(
        result,
        VehicleTypeCode.expressTrain,
      ),
      multimodal: getVehicleTotalInPassengerTraffic(
        result,
        'Мультимодальный транспорт',
      ),
    } as PassengerTrafficInspectorType['passengerTraffic'];
  },
  target: setPassengerTraffic,
});

// сеттер суммы всего пассажиропотока в зависимости от фильтра
sample({
  clock: [setPassengerTraffic, getDataForInspectorFn, resetSelectedLayout],
  source: {
    PassengerTrafficInspector: $PassengerTrafficInspector,
    FilterMap: $FilterMap,
    Inspector: $Inspector,
  },
  fn: ({ PassengerTrafficInspector, FilterMap, Inspector }) => {
    const keys = Object.keys(PassengerTrafficInspector.passengerTraffic);

    return keys.reduce((acc, curr) => {
      const key =
        curr as keyof PassengerTrafficInspectorType['passengerTraffic'];
      const traffic = PassengerTrafficInspector.passengerTraffic[key] || 0;

      if (FilterMap[key] && checkingLayout(Inspector.layout, key)) {
        return (acc += traffic ?? 0);
      } else {
        return acc;
      }
    }, 0);
  },
  target: setTotalPassengerTraffic,
});

// Обновление суммы всего пассажиропотока при изменении фильтра
sample({
  clock: setFilters,
  source: {
    FilterRegions: $FilterRegions,
    FilterMap: $FilterMap,
    PassengerTrafficInspector: $PassengerTrafficInspector,
  },
  filter: ({ FilterRegions }, payload) =>
    Boolean(
      FilterRegions.selectedRegionA &&
        FilterRegions.selectedRegionB &&
        !Object.keys(payload).includes('areaBorder'),
    ),
  fn: ({ PassengerTrafficInspector }) =>
    PassengerTrafficInspector.passengerTraffic,
  target: setPassengerTraffic,
});

// сеттер процентов пассажиропотока от общего по видам транспорта
sample({
  clock: setTotalPassengerTraffic,
  source: {
    PassengerTrafficInspector: $PassengerTrafficInspector,
    FilterMap: $FilterMap,
    Inspector: $Inspector,
  },
  fn: ({ PassengerTrafficInspector, FilterMap, Inspector }, total) => {
    if (!total) {
      return {
        nightTrain: 0,
        dayTrain: 0,
        suburbanTrain: 0,
        bus: 0,
        auto: 0,
        avia: 0,
        expressTrain: 0,
        multimodal: 0,
      } as PassengerTrafficInspectorType['passengerTrafficPercent'];
    }

    return {
      nightTrain:
        FilterMap.nightTrain && checkingLayout(Inspector.layout, 'nightTrain')
          ? getPercent(
              total,
              PassengerTrafficInspector.passengerTraffic.nightTrain,
            )
          : 0,
      dayTrain:
        FilterMap.dayTrain && checkingLayout(Inspector.layout, 'dayTrain')
          ? getPercent(
              total,
              PassengerTrafficInspector.passengerTraffic.dayTrain,
            )
          : 0,
      suburbanTrain:
        FilterMap.suburbanTrain &&
        checkingLayout(Inspector.layout, 'suburbanTrain')
          ? getPercent(
              total,
              PassengerTrafficInspector.passengerTraffic.suburbanTrain,
            )
          : 0,
      bus:
        FilterMap.bus && checkingLayout(Inspector.layout, 'bus')
          ? getPercent(total, PassengerTrafficInspector.passengerTraffic.bus)
          : 0,
      auto:
        FilterMap.auto && checkingLayout(Inspector.layout, 'auto')
          ? getPercent(total, PassengerTrafficInspector.passengerTraffic.auto)
          : 0,
      avia:
        FilterMap.avia && checkingLayout(Inspector.layout, 'avia')
          ? getPercent(total, PassengerTrafficInspector.passengerTraffic.avia)
          : 0,
      expressTrain:
        FilterMap.expressTrain &&
        checkingLayout(Inspector.layout, 'expressTrain')
          ? getPercent(
              total,
              PassengerTrafficInspector.passengerTraffic.expressTrain,
            )
          : 0,
      multimodal:
        FilterMap.multimodal && checkingLayout(Inspector.layout, 'multimodal')
          ? getPercent(
              total,
              PassengerTrafficInspector.passengerTraffic.multimodal,
            )
          : 0,
    } as PassengerTrafficInspectorType['passengerTrafficPercent'];
  },
  target: setPassengerTrafficPercent,
});

// сеттер данных таблиц пассажиропотока
sample({
  clock: getPassengerTrafficForInspectorFx.done,
  source: {
    FilterMap: $FilterMap,
  },
  fn: ({ FilterMap }, payload) => {
    const intermediateCorrespondences =
      payload.result?.reduce(
        (acc: intermediateCorrespondenceTableData[], curr) => {
          if (!curr || !curr.trafficTotal) return acc;

          const indexCorrespondence = acc.findIndex(
            item =>
              curr.direction.regionFrom?.name === item.regionFrom &&
              curr.direction.regionTo?.name === item.regionTo,
          );

          if (indexCorrespondence > -1) {
            FilterMap[getFilterKey(curr.vehicle.code)] &&
              (acc[indexCorrespondence].trafficTotal += curr.trafficTotal || 0);
          } else {
            acc.push({
              regionFrom: curr.direction.regionFrom?.name || '',
              regionTo: curr.direction.regionTo?.name || '',
              trafficTotal:
                (FilterMap[getFilterKey(curr.vehicle.code)] &&
                  curr.trafficTotal) ||
                0,
            });
          }
          return acc;
        },
        [],
      ) || [];

    return intermediateCorrespondences;
  },
  target: setDataCorrespondences,
});

// Обновление данных таблицы инспектора пасс потока при смене фильтров
sample({
  clock: [setFilters],
  source: {
    FilterMap: $FilterMap,
    FilterRegions: $FilterRegions,
    PassengerTrafficInspector: $PassengerTrafficInspector,
    Inspector: $Inspector,
  },
  filter: ({ FilterRegions }, payload) => {
    return Boolean(
      FilterRegions.selectedRegionA &&
        FilterRegions.selectedRegionB &&
        !Object.keys(payload).includes('areaBorder') &&
        !Object.keys(payload).includes('intermediateCorrespondents'),
    );
  },
  fn: ({ FilterMap, PassengerTrafficInspector, Inspector }) =>
    filterIngintermediateCorrespondences(
      PassengerTrafficInspector.passengerTrafficResponse,
      FilterMap,
      Inspector,
    ),
  target: setDataCorrespondences,
});

// Обновление данных таблицы инспектора пасс потока, при обновлении отображении фильтров для данных инспектора по клику по объекту карты и сбросе выбора объекта
sample({
  clock: [getDataForInspectorFn, resetSelectedLayout],
  source: {
    FilterMap: $FilterMap,
    FilterRegions: $FilterRegions,
    PassengerTrafficInspector: $PassengerTrafficInspector,
    Inspector: $Inspector,
  },
  filter: ({ FilterRegions }) => {
    return Boolean(
      FilterRegions.selectedRegionA && FilterRegions.selectedRegionB,
    );
  },
  fn: ({ FilterMap, PassengerTrafficInspector, Inspector }) =>
    filterIngintermediateCorrespondences(
      PassengerTrafficInspector.passengerTrafficResponse,
      FilterMap,
      Inspector,
    ),
  target: setDataCorrespondences,
});

// Отображение загрузки для инспектора слоя пасс потока
sample({
  clock: getPassengerTrafficForInspectorFx,
  fn: () => ({ inspectorPassTraffic: true }),
  target: setVisibleLoading,
});

// Скрытие загрузки для инспектора слоя пасс потока
sample({
  clock: getPassengerTrafficForInspectorFx.finally,
  fn: () => ({ inspectorPassTraffic: false }),
  target: setVisibleLoading,
});
