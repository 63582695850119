import {
  ProjectEditorContainer,
  ProjectTableContainer,
} from '@features/tpu-project-registry/containers';

import { AutoFullHeight } from '@components/AutoFullHeight';

export const Component = () => {
  return (
    <AutoFullHeight
      mb={24}
      style={{
        width: '100%',
        display: 'flex',
        padding: '24px',
        gap: '24px',
        boxSizing: 'border-box',
      }}
    >
      <ProjectTableContainer />
      <ProjectEditorContainer />
    </AutoFullHeight>
  );
};
