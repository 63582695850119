import { Chip, Grid, Typography } from '@mui/material';

export const Calculation = () => (
  <Grid
    display={'flex'}
    gap={1.5}
  >
    <Chip
      label="В процессе"
      color="warning"
    />

    <Typography variant="body2">
      Рассчитывается, это может занять несколько минут.
    </Typography>
  </Grid>
);
