export const EmptyPoint = () => {
  return (
    <svg
      width="34"
      height="40"
      viewBox="0 0 34 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_903_44778)">
        <path
          d="M16.9973 8.75C15.2179 8.75094 13.4787 9.27919 11.9994 10.268C10.5201 11.2569 9.36698 12.6619 8.68579 14.3058C8.00459 15.9496 7.82583 17.7584 8.1721 19.5038C8.51836 21.2491 9.37411 22.8527 10.6313 24.112C12.2613 25.742 16.7703 28.773 16.9973 31.25C17.2243 28.773 21.7303 25.746 23.3593 24.117C24.6179 22.8582 25.4751 21.2546 25.8224 19.5087C26.1698 17.7629 25.9918 15.9533 25.3109 14.3086C24.63 12.6639 23.4768 11.258 21.997 10.2686C20.5172 9.27921 18.7773 8.75074 16.9973 8.75Z"
          fill="#E21A1A"
        />
        <path
          d="M12.2772 10.6837C13.6743 9.74984 15.3168 9.25093 16.9973 9.25C18.6784 9.25075 20.3216 9.74986 21.7191 10.6843C23.1167 11.6187 24.2058 12.9465 24.8489 14.4998C25.492 16.0532 25.6601 17.7623 25.332 19.4112C25.004 21.06 24.1944 22.5746 23.0057 23.7634C22.6171 24.1521 22.0493 24.631 21.3878 25.188L21.3738 25.1998C20.7239 25.747 19.9964 26.3595 19.3111 27.0002C18.622 27.6444 17.9607 28.3306 17.4541 29.0248C17.2862 29.2549 17.1314 29.4911 16.9972 29.7317C16.8629 29.4909 16.708 29.2545 16.5399 29.0242C16.033 28.3295 15.3714 27.6429 14.6819 26.9982C13.9958 26.3567 13.2676 25.7433 12.6171 25.1955L12.6038 25.1842C11.942 24.6268 11.3739 24.1475 10.985 23.7586C9.79773 22.5693 8.98956 21.0548 8.66254 19.4065C8.33551 17.7581 8.50433 16.0497 9.1477 14.4972C9.79106 12.9447 10.8801 11.6176 12.2772 10.6837Z"
          stroke="white"
        />
      </g>
      <path
        d="M16.9961 22C16.4051 22 15.82 21.8836 15.274 21.6575C14.7281 21.4313 14.232 21.0998 13.8141 20.682C13.3962 20.2641 13.0648 19.768 12.8386 19.2221C12.6125 18.6761 12.4961 18.0909 12.4961 17.5C12.4961 16.9091 12.6125 16.3239 12.8386 15.7779C13.0648 15.232 13.3962 14.7359 13.8141 14.318C14.232 13.9002 14.7281 13.5687 15.274 13.3425C15.82 13.1164 16.4051 13 16.9961 13C18.1896 13 19.3342 13.4741 20.1781 14.318C21.022 15.1619 21.4961 16.3065 21.4961 17.5C21.4961 18.6935 21.022 19.8381 20.1781 20.682C19.3342 21.5259 18.1896 22 16.9961 22Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_903_44778"
          x="0"
          y="0.75"
          width="33.9961"
          height="38.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_903_44778"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_903_44778"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
