import {
  TableProjectRow,
  TableProjectRowProps,
} from '@features/ag-projects-registry/components/TableProjects/TableProjectRow';
import { ProjectType } from '@features/ag-projects-registry/store/lists/projects';

import {
  TableBody, // TableCell,
  // TableRow
} from '@mui/material';

type TableProjectBodyProps = {
  rows: ProjectType[];
  selected: readonly string[];
  emptyRows: number;
} & Pick<TableProjectRowProps, 'onClickRow' | 'onClickProjectButton'>;

export const TableProjectBody = (props: TableProjectBodyProps) => {
  const {
    rows,
    selected,
    // emptyRows,
    onClickRow,
    onClickProjectButton,
  } = props;

  return (
    <TableBody
      sx={{
        overflowY: 'auto',
        height: 'calc(100% - 250px)',
      }}
    >
      {rows.map((row, index) => (
        <TableProjectRow
          key={row.id}
          row={row}
          isSelected={selected.indexOf(row.id) !== -1}
          labelId={`enhanced-table-checkbox-${index}`}
          onClickRow={onClickRow}
          onClickProjectButton={onClickProjectButton}
        />
      ))}
      {/* {emptyRows > 0 && (
        <TableRow
          style={{
            height: 50 * emptyRows,
          }}
        >
          <TableCell
            colSpan={6}
            sx={{ py: 1.75 }}
          />
        </TableRow>
      )} */}
    </TableBody>
  );
};
