import { apiClient } from '@api/client';
import { IQuery, IQueryGetProjectByIdArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

export const queryTimelineYears = async (project: IQueryGetProjectByIdArgs) => {
  const result = await apiClient.query<
    Pick<IQuery, 'getProjectById'>,
    IQueryGetProjectByIdArgs
  >({
    query: gql`
      query QueryTimelineYears($id: String!) {
        getProjectById(id: $id) {
          timelineEnd
          timelineStart
        }
      }
    `,
    variables: project,
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.getProjectById;
};
