import { useCallback } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { DialogCloseButton } from '@components/DialogCloseButton';

import { ModalsProps } from '@features/pt-forecast-new/containers/ModalsContainer/ModalsContainer';

export const InfoCannotDeleteCustomRailwayStation = (props: ModalsProps) => {
  const { onClose } = props;

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  return (
    <Dialog
      open={true}
      onClose={handleClose}
    >
      <DialogTitle
        variant="subtitle2"
        sx={{
          position: 'relative',
          fontSize: theme => theme.typography.pxToRem(16),
          pr: 10,
        }}
      >
        Невозможно удалить ЖД станцию
      </DialogTitle>

      <DialogCloseButton onClick={handleClose} />

      <DialogContent>
        <Typography>
          ЖД станция не может быть удалена, так как она является начальной или
          конечной для существующей ЖД линии. Пожалуйста, удалите линию, чтобы
          удалить станцию.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Box />
        <Button onClick={handleClose}>Закрыть</Button>
      </DialogActions>
    </Dialog>
  );
};
