import { apiClient } from '@api/client';
import { IMutation, IMutationUpdateScenarioParamsArgs } from '@api/gql/types';
import { gql } from '@apollo/client';

export const mutationUpdateScenarioParams = async (
  params: IMutationUpdateScenarioParamsArgs,
) => {
  const result = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation UpdateGraphItemMutation(
        $data: [FlatParamsInput]
        $scenarioId: UUID!
      ) {
        updateScenarioParams(data: $data, scenarioId: $scenarioId) {
          data {
            durationDayTrain
            durationExpressSuburbanTrain
            durationNightTrain
            durationSuburbanTrain
            frequencyDayTrain
            frequencyExpressSuburbanTrain
            frequencyNightTrain
            frequencySuburbanTrain
            priceDayTrain
            priceExpressSuburbanTrain
            priceNightTrain
            priceSuburbanTrain
            regionFromId
            regionToId
            year
          }
        }
      }
    `,
    variables: params,
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.updateScenarioParams;
};
