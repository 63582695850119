import { MapBrowserEvent, Map as MapOl } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { RefObject, useEffect, useRef, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { EMapFeatureLayout } from '@constants/map';

export type MapFeatureTooltipProps = {
  mapRef: RefObject<MapOl | null>;
};

export const MapFeatureTooltip = (props: MapFeatureTooltipProps) => {
  const { mapRef } = props;
  const containerRef = useRef<null | HTMLDivElement>(null);
  const [featureProps, setFeatureProps] = useState<FeatureProps | null>(null);
  const eventRef = useRef<null | MapBrowserEvent<UIEvent>>(null);

  const frameHandler = () => {
    const pixel = mapRef.current!.getEventPixel(
      eventRef.current!.originalEvent,
    );
    containerRef.current!.style.transform = `translateX(${
      pixel[0] + 5
    }px) translateY(${pixel[1] + 5}px)`;

    const features: FeatureLike[] = [];

    mapRef.current?.forEachFeatureAtPixel(
      pixel,
      feature => {
        const layer = feature.get('layout');
        if (!layer || layer !== EMapFeatureLayout.transportFlowDistricts)
          return;
        return features.push(feature);
      },
      {
        hitTolerance: 8,
      },
    );

    if (features.length > 0) {
      containerRef.current!.style.display = 'block';
      setFeatureProps({
        flow: features[0].get('flow') ? Math.round(features[0].get('flow')) : 0,
        name: features[0].get('name') || '',
      });
    } else {
      containerRef.current!.style.display = 'none';
      setFeatureProps({
        flow: 0,
        name: '',
      });
    }
  };

  useEffect(() => {
    if (!mapRef.current) throw new Error('mapRef is null');
    if (!containerRef.current) throw new Error('containerRef is null');

    const mapEeventCb = (event: MapBrowserEvent<UIEvent>) => {
      eventRef.current = event;
      requestAnimationFrame(frameHandler);
    };

    const containerEventCb = () => {
      containerRef.current!.style.display = 'none';
    };

    mapRef.current!.on('pointermove', mapEeventCb);
    containerRef.current?.addEventListener('mouseenter', containerEventCb);

    return () => {
      mapRef.current?.un('pointermove', mapEeventCb);
      containerRef.current?.removeEventListener('mouseenter', containerEventCb);
    };
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'absolute',
        top: `0px`,
        left: `0px`,
        margin: 'auto',
        // transform: 'translateX(-50%) translateY(-110%)',
        display: 'none',
        backgroundColor: 'white',
        height: 'fit-content',
        width: 'fit-content',
        padding: 2,
        borderRadius: 2,
        zIndex: 10,
        // '&::after': {
        //   content: '""',
        //   position: 'absolute',
        //   bottom: '-10px',
        //   left: '50%',
        //   transform: 'translateX(-50%) translateY(-10%) rotate(180deg)',
        //   width: '20px',
        //   height: '10px',
        //   backgroundColor: 'white',
        //   clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
        // },
      }}
    >
      {featureProps && (
        <>
          <Typography variant="subtitle2">
            Корреспонденция:
            <span style={{ fontWeight: 'normal', marginLeft: 4 }}>
              {featureProps.name || 'н/д'}
            </span>
          </Typography>
          <Typography variant="subtitle2">
            Пассажиропоток:
            <span style={{ fontWeight: 'normal', marginLeft: 4 }}>
              {featureProps.flow || 'н/д'} пасс.
            </span>
          </Typography>
        </>
      )}
    </Box>
  );
};

type FeatureProps = {
  flow: number;
  name: string;
};
