import { useEffect, useState } from 'react';

import { Slider, Typography } from '@mui/material';

interface SliderSocioEconomicProps {
  value: number;
  base: number;
  getValue: (value: number) => void;
}

export const SliderSocioEconomic = ({
  value,
  base,
  getValue,
}: SliderSocioEconomicProps) => {
  // const minValue = base - 5;
  // const maxValue = base + 5;
  const [minValue, setMinValue] = useState(base - 5);
  const [maxValue, setMaxValue] = useState(base + 5);

  const valueLabelFormat = () => {
    const percent = Number(value - base);
    const percentString = percent.toFixed(1);

    return (
      <>
        <Typography>Базовое значение {base}</Typography>
        <Typography
          color={'customs.trainSpeedLocal'}
          textAlign={'center'}
        >
          {percent > 0 ? '+' : ''}
          {percentString}%
        </Typography>
      </>
    );
  };

  const handleChange = (_event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      getValue(newValue);
    }
  };

  useEffect(() => {
    setMinValue(+base - 5);
    setMaxValue(+base + 5);
  }, [base]);

  return (
    <Slider
      color="secondary"
      marks={[{ value: base }]}
      value={value}
      step={0.1}
      min={minValue}
      max={maxValue}
      valueLabelDisplay="auto"
      valueLabelFormat={valueLabelFormat}
      onChange={handleChange}
    />
  );
};
