import { useUnit } from 'effector-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Divider, Stack, Tooltip } from '@mui/material';

import { ROUTES } from '@constants/routes';

import { SearchInput } from '@features/ag-forecast/containers/ToolBarContainer/SearchInput';
import { ModalsApi } from '@features/ag-forecast/stores/modals';
import { $FeatureSettings } from '@features/ag-forecast/stores/settings';
import { $UI, UIApi } from '@features/ag-forecast/stores/ui';

export const ToolBarContainer = (props: ToolBarContainerType) => {
  const { showDashboardView } = props;
  const { editTPUMode } = useUnit($UI);
  const { toggleFilter } = useUnit(UIApi);
  const { projectUUID, regionUUID } = useUnit($FeatureSettings);
  const { setModalView } = useUnit(ModalsApi);

  const navigate = useNavigate();

  const handleClickBuilder = useCallback(() => {
    navigate(`${ROUTES.AGGLOMERATION_CONSTRUCTOR}?projectId=${projectUUID}`);
  }, [navigate, projectUUID]);

  const handleClickTRLinksShow = useCallback(() => {
    if (!regionUUID) return;
    setModalView({ passFlow: true });
  }, [regionUUID, setModalView]);

  return (
    <Stack
      alignItems={'center'}
      py={2}
      bgcolor={'common.white'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      px={5.5}
    >
      <Stack flexDirection={'row'}>
        <Stack
          width={'calc(500px - 44px)'}
          pr={5.5}
        >
          <Button
            fullWidth
            color={'primary'}
            variant={'contained'}
            onClick={toggleFilter}
          >
            Транспортные слои
          </Button>
        </Stack>
        <Divider
          sx={{ borderColor: '#EBECEF', mr: 5.5 }}
          orientation={'vertical'}
          flexItem
        />
        <SearchInput />
        {regionUUID && (
          <Button
            sx={{
              ml: 4,
            }}
            onClick={handleClickTRLinksShow}
          >
            Связь с другими территориями
          </Button>
        )}
      </Stack>

      <Box
        display={'flex'}
        columnGap={4}
      >
        <Tooltip
          title={
            editTPUMode
              ? 'Недоступно в режиме редактирования инфраструктуры'
              : ''
          }
        >
          <span>
            <Button
              color={'secondary'}
              variant={'contained'}
              disabled={editTPUMode}
              onClick={handleClickBuilder}
            >
              Конструктор прогнозов
            </Button>
          </span>
        </Tooltip>

        <Tooltip
          title={
            editTPUMode
              ? 'Недоступно в режиме редактирования инфраструктуры'
              : ''
          }
        >
          <span>
            <Button
              variant={'contained'}
              disabled={editTPUMode}
              onClick={showDashboardView}
            >
              Дашборд
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Stack>
  );
};

type ToolBarContainerType = {
  showDashboardView: () => void;
};
