import { useUnit } from 'effector-react';
import { SyntheticEvent, useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { AccordionFilter } from '@components/MapFilters/AccordionFilter';
import { typographyWhitCircleStyle } from '@components/MapFilters/utils';

import { LayoutChanger } from '@features/ag-project/components/LayoutChanger/LayoutChanger';
import { $FilterMap, FilterMapApi } from '@features/ag-project/stores/filters';
import { $Vehicles } from '@features/ag-project/stores/lists/vehicles';
import { $MapStore } from '@features/ag-project/stores/map';
import { $UI } from '@features/ag-project/stores/ui/store';
import { vehicleIdToLayout } from '@features/ag-project/utils/vehicleIdToLayout';
import { vehicleToPalette } from '@features/ag-project/utils/vehicleToPalette';

const FiltersContainerMuiStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '0',
  left: '0',
  zIndex: '1',
  background: theme => theme.palette.background.default,
};

export const MapFiltersContainer = () => {
  const $filterMap = useUnit($FilterMap);
  const $vehicles = useUnit($Vehicles);
  const { filter } = useUnit($UI);
  const { isBusy } = useUnit($MapStore);

  const { reset, setFilters } = useUnit(FilterMapApi);

  const setTransportDistrictsRootHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFilters({ transportDistricts: checked });
    },
    [setFilters],
  );

  const setIsActualHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFilters({ isActual: checked });
    },
    [setFilters],
  );

  const setIsPlannedHandler = useCallback(
    (_event: SyntheticEvent, checked: boolean) => {
      setFilters({ isPlanned: checked });
    },
    [setFilters],
  );

  const setVehicleHandler = useCallback(
    (vehicleId: number, checked: boolean) => {
      setFilters({
        [vehicleIdToLayout(vehicleId)]: checked,
      });
    },
    [setFilters],
  );

  const onResetButtonClick = useCallback(() => {
    reset();
  }, [reset]);

  if (!filter || !$vehicles) return null;

  return (
    <Box sx={FiltersContainerMuiStyle}>
      <Stack direction="column">
        <AutoFullHeight
          style={{
            borderTop: '1px solid #EBECEF',
            width: '500px',
            maxWidth: '500px',
            overflowY: 'auto',
          }}
          mb={77}
        >
          <Box
            style={{
              marginTop: 24,
              marginBottom: 24,
              paddingRight: 44,
              paddingLeft: 44,
            }}
          >
            <LayoutChanger />
          </Box>

          <Divider />

          <Box
            style={{
              position: 'relative',
              overflowY: 'auto',
              overflowX: 'clip',
              marginRight: '12px',
              marginTop: '8px',
              paddingRight: '16px',
              padding: '16px 32px 16px 44px',
            }}
          >
            <Typography
              fontWeight={600}
              mb={2}
            >
              Фильтры
            </Typography>
            <AccordionFilter title="По видам транспорта">
              <Stack direction="column">
                {$vehicles.map(vehicle => (
                  <FormControlLabel
                    key={vehicle.id}
                    checked={
                      $filterMap.actual[vehicleIdToLayout(vehicle.vehicleId)]
                    }
                    onChange={(_, checked) =>
                      setVehicleHandler(vehicle.vehicleId, checked)
                    }
                    control={<Checkbox />}
                    disabled={isBusy}
                    label={
                      <Typography
                        sx={({ palette }) => {
                          const [a, b] = vehicleToPalette(
                            vehicle.vehicleId,
                            palette,
                          );
                          return {
                            ...typographyWhitCircleStyle(a, b),
                            opacity: isBusy ? 0.5 : 1,
                          };
                        }}
                        variant={'body2'}
                      >
                        {vehicle.name}
                      </Typography>
                    }
                  />
                ))}
              </Stack>
            </AccordionFilter>
            <Divider />
            <Stack
              pl={2}
              mt={2}
              mb={2}
            >
              <FormControlLabel
                checked={$filterMap.actual.isActual}
                onChange={setIsActualHandler}
                control={<Checkbox />}
                disabled={isBusy}
                label="Актуальная"
              />

              <FormControlLabel
                checked={$filterMap.actual.isPlanned}
                onChange={setIsPlannedHandler}
                control={<Checkbox />}
                disabled={isBusy}
                label="Планируемая"
              />
            </Stack>
            <Divider />
            <Box
              pl={2}
              mt={2}
            >
              <FormControlLabel
                checked={$filterMap.actual.transportDistricts}
                onChange={setTransportDistrictsRootHandler}
                control={<Checkbox />}
                disabled={isBusy}
                label="Границы транспортного района"
              />
            </Box>
          </Box>
        </AutoFullHeight>
        <Box
          pb="26px"
          pt={3}
          px={5.5}
          mt={'auto'}
          style={{
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Button
            variant={'text'}
            startIcon={<CloseIcon />}
            fullWidth
            disabled={isBusy}
            onClick={onResetButtonClick}
          >
            Сбросить все
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
