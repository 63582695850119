import { downloadFile } from '@api/queries';

import { downloadFileFx } from '@features/tpu-calculation-scenarios-viewing/stores/download-file/download-file';

downloadFileFx.use(async args => {
  if (!args) return null;

  const response = await downloadFile(args);

  return response.data?.downloadFile ?? null;
});
