import { Map as MapOl } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { RefObject, useEffect, useRef, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { TILE_SERVICE_LAYERS } from '@constants/tileServiceLayers';

const initState: FeatureTooltipProps = {
  name: '',
  population: 0,
  totalFlow: 0,
};

type MapFeatureTooltipProps = {
  mapRef: RefObject<MapOl | null>;
};

export const MapFeatureTooltip = (props: MapFeatureTooltipProps) => {
  const { mapRef } = props;
  const containerRef = useRef<null | HTMLDivElement>(null);
  const currentFeatureRef = useRef<null | FeatureLike>(null);
  const [featureProps, setFeatureProps] =
    useState<FeatureTooltipProps>(initState);

  useEffect(() => {
    if (!mapRef.current) throw new Error('mapRef is null');
    if (!containerRef.current) throw new Error('containerRef is null');
    mapRef.current!.on('pointermove', event => {
      if (event.dragging) {
        containerRef.current!.style.display = 'none';
        currentFeatureRef.current = null;
        return;
      }
      const pixel = mapRef.current!.getEventPixel(event.originalEvent);
      containerRef.current!.style.top = `calc(${pixel[1]}px - 5px)`;
      containerRef.current!.style.left = `${pixel[0]}px`;

      const features: FeatureLike[] = [];

      mapRef.current?.forEachFeatureAtPixel(event.pixel, feature => {
        const layer = feature.get('layer');
        if (
          !layer ||
          layer !== TILE_SERVICE_LAYERS.AREA_BORDER_PASSENGER_FLOW.LAYER
        )
          return;
        return features.push(feature);
      });

      if (features.length > 0) {
        currentFeatureRef.current = features[0];
        containerRef.current!.style.display = 'block';
        setFeatureProps({
          name: features[0].get('name') || '',
          population: Number(features[0].get('population')) || 0,
          totalFlow: Number(features[0].get('total_flow')) || 0,
        });
      } else {
        containerRef.current!.style.display = 'none';
        currentFeatureRef.current = null;
        setFeatureProps(initState);
      }
      containerRef.current?.addEventListener('mouseenter', () => {
        containerRef.current!.style.display = 'none';
      });
    });

    mapRef
      .current!.getTargetElement()
      .addEventListener('pointerleave', function () {
        containerRef.current!.style.display = 'none';
        currentFeatureRef.current = null;
        setFeatureProps(initState);
      });
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'absolute',
        top: `0px`,
        left: `0px`,
        margin: 'auto',
        transform: 'translateX(-50%) translateY(-110%)',
        backgroundColor: 'white',
        height: 'fit-content',
        width: 'fit-content',
        padding: 2,
        borderRadius: 2,
        zIndex: 10,
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: '-10px',
          left: '50%',
          transform: 'translateX(-50%) translateY(-10%) rotate(180deg)',
          width: '20px',
          height: '10px',
          backgroundColor: 'white',
          clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
        },
      }}
    >
      <Stack
        direction="column"
        gap={0.5}
      >
        <Typography variant="subtitle2">
          Регион:
          <span style={{ fontWeight: 'normal', marginLeft: 4 }}>
            {featureProps.name || 'н/д'}
          </span>
        </Typography>
        {!!featureProps.population && (
          <Typography variant="subtitle2">
            Население:
            <span style={{ fontWeight: 'normal', marginLeft: 4 }}>
              {featureProps.population || 'н/д'}
            </span>
          </Typography>
        )}
        {!!featureProps.totalFlow && (
          <Typography variant="subtitle2">
            Пассажиропоток:
            <span style={{ fontWeight: 'normal', marginLeft: 4 }}>
              {featureProps.totalFlow.toFixed(1) || 'н/д'}
            </span>
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

type FeatureTooltipProps = {
  name: string;
  population: number;
  totalFlow: number;
};
