export const SOC_ECO_TH_5_7 = [
  {
    id: 'transportObject',
    label: 'Транспортный объект',
  },
  {
    id: 'transportArea',
    label: 'Транспортный район',
  },
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'volumesCargoFlows',
    label: 'Объёмы грузопотоков, тыс. тонн',
  },
  {
    id: 'needStorageFacilities',
    label: 'Необходимость в складских помещениях, кв. м.',
  },
];
