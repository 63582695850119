import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const ShoppingCartIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        d="M21.326 5.234H5.876l-.429-1.926A1.663 1.663 0 0 0 3.817 2h-2.26A.558.558 0 0 0 1 2.557c0 .306.25.556.557.556h2.26c.26 0 .484.178.54.434L5.78 9.948h13.62c.306 0 .556.245.556.557 0 .306-.25.556-.556.556H6.026l.707 3.173c.172.768.84 1.308 1.63 1.308h.483l-1.134 1.475c-3.126.43-2.899 4.971.284 5.053a2.542 2.542 0 0 0 2.477-1.978h7.196a2.535 2.535 0 0 0 5.01-.554c-.047-3.025-4.32-3.458-5.009-.56h-7.198A2.55 2.55 0 0 0 8.975 17.2l1.275-1.658h9.562c.78 0 1.464-.551 1.631-1.314l1.514-6.974c.245-1.006-.596-2.05-1.63-2.02Z"
        fill="#7E67DD"
      />
    </SvgIcon>
  );
};
