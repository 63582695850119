import {
  $Editor,
  decrementScaleFn,
  incrementScaleFn,
  resetEditorStateFn,
  setModelBlockSizeFn,
  setOpacityFn,
  setPositionFn,
  setScaleFn,
  setShouldCenterStagePositionAndResetScaleFn,
} from '@features/tpu-simulation-model/store/editor/editor.store';

$Editor
  .reset(resetEditorStateFn)
  .on(setScaleFn, (state, payload) => {
    return { ...state, scale: payload };
  })
  .on(setPositionFn, (state, payload) => {
    const { x, y } = payload;
    return { ...state, positionX: x, positionY: y };
  })
  .on(incrementScaleFn, state => {
    const newScale = state.scale + 0.05;
    return {
      ...state,
      scale: newScale > 3 ? 3 : newScale,
    };
  })
  .on(decrementScaleFn, state => {
    const newScale = state.scale - 0.05;
    return {
      ...state,
      scale: newScale < 0.25 ? 0.25 : newScale,
    };
  })
  .on(setShouldCenterStagePositionAndResetScaleFn, (state, payload) => {
    return {
      ...state,
      shouldCenterStagePosition: payload,
      scale: 1,
      positionX: 0,
      positionY: 0,
    };
  })
  .on(setOpacityFn, (state, payload) => {
    return { ...state, opacity: payload };
  })
  .on(setModelBlockSizeFn, (state, payload) => {
    return { ...state, width: payload.width, height: payload.height };
  });
