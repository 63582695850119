import { apiClient } from '@api/client';
import { IQuery } from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { TPU_CLIENT_NAME } from '@constants/api';

import {
  $ScenarioId,
  getScenarioIdFn,
  resetProjectInfoFn,
} from '@features/tpu-simulation-model/store';
import {
  $Project,
  getProjectInfoFromScenarioIdFx,
} from '@features/tpu-simulation-model/store/project/project.store';

$Project
  .reset(resetProjectInfoFn)
  .on(getProjectInfoFromScenarioIdFx.done, (state, { result }) => {
    if (!result) return;

    return {
      ...state,
      name: result.name,
      id: result.id,
      hub: result.hub,
    };
  });

sample({
  clock: getScenarioIdFn,
  source: $ScenarioId,
  filter: id => !!id,
  fn: id => id as string,
  target: getProjectInfoFromScenarioIdFx,
});

getProjectInfoFromScenarioIdFx.use(async id => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query GetProjectInfoFromScenarioId($id: UUID!) {
        scenarioById(id: $id) {
          project {
            id
            name
            hub {
              name
              code
            }
          }
        }
      }
    `,
    variables: {
      id,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return response.data.scenarioById?.project ?? null;
});
