import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Box,
  Grid,
  InputAdornment,
  Slider,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from '@mui/material';

import {
  InspectorCircleIcon,
  InspectorCircleIconProps,
} from '@components/InspectorCircleIcon/InspectorCircleIcon';

type TimeBlockType = {
  icon: InspectorCircleIconProps;
  name: string;
  value: number | string;
  baseValue: number;
  disabled: boolean;
  getValue: (value: number | string) => void;
};

const PostfixMuiStyle: SxProps<Theme> = {
  '& .MuiTypography-root': {
    fontSize: theme => theme.typography.pxToRem(12),
    color: theme => theme.palette.border.stroke,
  },
};

export const TravelCostsBlock = (props: TimeBlockType) => {
  const { icon, name, value, baseValue, disabled, getValue } = props;
  const prevProps = useRef(props);

  const [costDisplay, setCostDisplay] = useState<number | string>(0);

  /**
   * Обновление значеня в поле стоимости проезда
   */
  useEffect(() => {
    let cost: string | number = +value;
    const decimal = value.toString().split('.')[1];

    if (decimal) {
      if (decimal.length > 1) {
        cost = (+value).toFixed(2).toString().replace('.', ',');
      } else {
        cost = (+value).toFixed(1).toString().replace('.', ',');
      }
    } else {
      cost = value.toString().replace('.', ',');
    }

    if (value === undefined || cost === costDisplay) return;

    setCostDisplay((+value).toFixed(2).toString().replace('.', ','));

    if (+prevProps.current.value !== +value) {
      prevProps.current = props;
    }
  }, [costDisplay, props, value]);

  /**
   * Нормализация переменной в число, при смене фокуса
   */
  const onCheckingnormalizedCost = useCallback(() => {
    getValue(+value);
  }, [getValue, value]);

  /**
   * Верстка тултипа для слайдера
   */
  const valueLabelFormat = () => {
    const percent =
      baseValue === 0 || isNaN(baseValue)
        ? 0
        : ((+value - baseValue) / baseValue) * 100;
    const percentString = Math.round(percent);

    return (
      <>
        <Typography>Базовое значение {Math.round(baseValue)}</Typography>
        <Typography
          color={'customs.trainSpeedLocal'}
          textAlign={'center'}
        >
          {percent > 0 ? '+' : ''}
          {percentString}%
        </Typography>
      </>
    );
  };

  /**
   * Функция изменения значения слайдера с обновлением приходящего значения стоимости проезда в компонент
   */
  const handlerChangeCostSlider = useCallback(
    (_event: Event, newValue: number | number[]) => {
      getValue(newValue as number);
    },
    [getValue],
  );

  /**
   * Нижняя граница значения для слайдера
   */
  const minValue = useMemo(() => {
    if (isNaN(baseValue) || baseValue === 0) return 0;

    return Math.round(+(baseValue / 2));
  }, [baseValue]);

  /**
   * Верхняя граница значения для слайдера
   */
  const maxValue = useMemo(() => {
    if (isNaN(baseValue) || baseValue === 0) return 1;

    return Math.round(+(baseValue * 1.5));
  }, [baseValue]);

  /**
   * Функция обработчик изменения значения стоимости проезда в поле
   */
  const onChangeCost = useCallback<NonNullable<TextFieldProps['onChange']>>(
    e => {
      const cost: number | string = (e.target as HTMLInputElement).value;
      const normalizedValue = cost.replace(',', '.');

      if (
        /^\d{0,}(\.\d{0,2})?$/.test(normalizedValue) ||
        normalizedValue === ''
      ) {
        setCostDisplay(cost);
        getValue(normalizedValue);
      }
    },
    [getValue],
  );

  return (
    <Grid
      display={'flex'}
      flexDirection={'column'}
      rowGap={1}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          columnGap: 1,
        }}
      >
        <InspectorCircleIcon
          borderColor={icon.borderColor}
          backgroundColor={icon.backgroundColor}
        />

        <Typography>{name}</Typography>
      </Box>

      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          columnGap: 1.5,
          mt: 1,
        }}
      >
        <TextField
          value={costDisplay}
          disabled={disabled}
          sx={{
            width: theme => theme.typography.pxToRem(220),
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                sx={PostfixMuiStyle}
                position="end"
              >
                ₽
              </InputAdornment>
            ),
          }}
          onChange={onChangeCost}
          onBlur={onCheckingnormalizedCost}
        />

        <Slider
          color="secondary"
          marks={[{ value: baseValue }]}
          min={minValue}
          max={maxValue}
          step={1}
          value={+value}
          disabled={disabled}
          onChange={handlerChangeCostSlider}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
        />
      </Box>
    </Grid>
  );
};
