import { apiClient } from '@api/client';
import {
  IQuery,
  IQueryGetComputingRegistryInstanceByIdArgs,
} from '@api/gql/types';
import { gql } from '@apollo/client';
import { guard, sample } from 'effector';

import { EVehicle } from '@features/pt-forecast-new/constants/eVehicle';
import {
  $ComputedData,
  ComputedDataApi,
  getDownloadLinkFx,
} from '@features/pt-forecast-new/stores/computedData';
import {
  $FilterMap,
  FilterMapApi,
} from '@features/pt-forecast-new/stores/filters';
import {
  $Computed,
  ComputedApi,
} from '@features/pt-forecast-new/stores/lists/computed';
import {
  $YearLine,
  YearLineApi,
} from '@features/pt-forecast-new/stores/yearLine';
import { DailyUnevennessTimeList } from '@features/pt-forecast/stores';

import { keycloak } from '@system/keycloak/keycloak';

import { getPercent } from '@utils/getPercent';

// при смене выбранного года, забрать из набора данных сет за выбранный год
sample({
  clock: [ComputedApi.setComputedResults, YearLineApi.setSelectedYear],
  source: { Computed: $Computed, YearLine: $YearLine },
  fn: ({ Computed, YearLine }) => {
    const dataForSelectedYear = Computed.sampleResults.find(
      item => item.year === YearLine.selectedYear,
    );
    if (!dataForSelectedYear) throw new Error('No data for selected year');

    return dataForSelectedYear;
  },
  target: ComputedDataApi.setComputedData,
});

// Событие изменения фильтров по видам транспорта
export const filterVehicleChange = guard(FilterMapApi.setFilters, {
  filter: payload =>
    Object.keys(payload).some(e =>
      [
        'multimodal',
        'auto',
        'avia',
        'bus',
        'suburbanTrain',
        'dayTrain',
        'nightTrain',
        'expressTrain',
      ].includes(e),
    ),
});

// посчитать общую сумму по выбранным видам транспорта
sample({
  clock: [ComputedDataApi.setComputedData, filterVehicleChange],
  source: { ComputedData: $ComputedData, FilterMap: $FilterMap },
  filter: ({ ComputedData }) => !!ComputedData.currentData,
  fn: ({ ComputedData, FilterMap }) => {
    const keys = Object.keys(ComputedData.currentData!.passengerTraffic);

    return keys.reduce((acc, curr) => {
      const key = curr as EVehicle;
      const traffic = ComputedData.currentData!.passengerTraffic[key] || 0;

      if (FilterMap[key]) {
        return (acc += traffic ?? 0);
      } else {
        return acc;
      }
    }, 0);
  },
  target: ComputedDataApi.setTotal,
});

// сеттер процентов пассажиропотока от общего по видам транспорта
sample({
  clock: [ComputedDataApi.setTotal],
  source: {
    ComputedData: $ComputedData,
    FilterMap: $FilterMap,
  },
  filter: ({ ComputedData }) => !!ComputedData.currentData,
  fn: ({ ComputedData, FilterMap }) => {
    if (!ComputedData.recalculatedTotal) {
      return {
        multimodal: 0,
        nightTrain: 0,
        dayTrain: 0,
        suburbanTrain: 0,
        bus: 0,
        auto: 0,
        avia: 0,
        expressTrain: 0,
      } as Record<EVehicle, number>;
    }

    return {
      multimodal: FilterMap.multimodal
        ? getPercent(
            ComputedData.recalculatedTotal,
            ComputedData.currentData!.passengerTraffic.multimodal,
          )
        : 0,
      nightTrain: FilterMap.nightTrain
        ? getPercent(
            ComputedData.recalculatedTotal,
            ComputedData.currentData!.passengerTraffic.nightTrain,
          )
        : 0,
      dayTrain: FilterMap.dayTrain
        ? getPercent(
            ComputedData.recalculatedTotal,
            ComputedData.currentData!.passengerTraffic.dayTrain,
          )
        : 0,
      suburbanTrain: FilterMap.suburbanTrain
        ? getPercent(
            ComputedData.recalculatedTotal,
            ComputedData.currentData!.passengerTraffic.suburbanTrain,
          )
        : 0,
      bus: FilterMap.bus
        ? getPercent(
            ComputedData.recalculatedTotal,
            ComputedData.currentData!.passengerTraffic.bus,
          )
        : 0,
      auto: FilterMap.auto
        ? getPercent(
            ComputedData.recalculatedTotal,
            ComputedData.currentData!.passengerTraffic.auto,
          )
        : 0,
      avia: FilterMap.avia
        ? getPercent(
            ComputedData.recalculatedTotal,
            ComputedData.currentData!.passengerTraffic.avia,
          )
        : 0,
      expressTrain: FilterMap.expressTrain
        ? getPercent(
            ComputedData.recalculatedTotal,
            ComputedData.currentData!.passengerTraffic.expressTrain,
          )
        : 0,
    } as Record<EVehicle, number>;
  },
  target: ComputedDataApi.setPassengerTrafficPercent,
});

// Посчитать или пересчитать суточную неравномерность
sample({
  clock: [ComputedDataApi.setComputedData, filterVehicleChange],
  source: { ComputedData: $ComputedData, FilterMap: $FilterMap },
  filter: ({ ComputedData }) => !!ComputedData.currentData,
  fn: ({ ComputedData, FilterMap }) => {
    if (!ComputedData.currentData!.dailyUnevenness) return [];

    const result: number[] = Array.from({ length: 8 }).map(
      (_, index: number) => {
        return Object.keys(ComputedData.currentData!.dailyUnevenness!).reduce(
          (acc, curr) => {
            const key = curr as EVehicle;
            const time = DailyUnevennessTimeList[index];
            const traffic =
              ComputedData.currentData!.dailyUnevenness![key][time] || 0;

            if (FilterMap[key]) return acc + traffic;
            else return acc;
          },
          0,
        );
      },
    );

    return result;
  },
  target: ComputedDataApi.setDailyUnevennessSummary,
});

// Посчитать или пересчитать сезонную неравномерность
sample({
  clock: [ComputedDataApi.setComputedData, filterVehicleChange],
  source: { ComputedData: $ComputedData, FilterMap: $FilterMap },
  filter: ({ ComputedData }) => !!ComputedData.currentData,
  fn: ({ ComputedData, FilterMap }) => {
    if (!ComputedData.currentData!.unevenness) return [];

    const result: number[] = Array.from({ length: 12 }).map(
      (_, index: number) => {
        return Object.keys(ComputedData.currentData!.unevenness!).reduce(
          (acc, curr) => {
            const key = curr as EVehicle;
            const traffic =
              ComputedData.currentData!.unevenness![key][index] || 0;

            if (FilterMap[key]) return acc + traffic;
            else return acc;
          },
          0,
        );
      },
    );

    return result;
  },
  target: ComputedDataApi.setUnevennessSummary,
});

getDownloadLinkFx.use(async params => {
  const result = await apiClient.query<
    Pick<IQuery, 'getComputingRegistryInstanceById'>,
    IQueryGetComputingRegistryInstanceByIdArgs
  >({
    query: gql`
      query QueryGetComputingRegistryInstanceById($id: String) {
        getComputingRegistryInstanceById(id: $id) {
          link
          name
        }
      }
    `,
    variables: {
      id: params.id,
    },
  });

  const url = result.data.getComputingRegistryInstanceById?.link;
  if (!url) throw new Error('Url not found');

  // // eslint-disable-next-line effector/no-getState
  // const FilterMap = $FilterMap?.getState();
  const scenarioName = result.data.getComputingRegistryInstanceById?.name;
  // const fromTo = `${FilterMap?.fromSelectedRegion?.name || ''} - ${
  //   FilterMap?.toSelectedRegion?.name || ''
  // }`;
  const date = new Date().toLocaleDateString().replace(/\./g, '-');

  const fileName = `${scenarioName} ${date}.csv`;
  const headers = {
    'X-Auth': `Bearer ${keycloak.token || ''}`,
    // Здесь добавьте необходимые заголовки
  };

  fetch(url!, {
    headers,
    redirect: 'follow',
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      // Создаем ссылку для скачивания
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName; // Название файла
      document.body.appendChild(link);
      link.click();

      // Очистить ссылку после скачивания
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    })
    .catch(error => {
      console.error(
        'There has been a problem with your fetch operation:',
        error,
      );
    });

  return true;
});

sample({
  clock: ComputedApi.reset,
  target: ComputedDataApi.reset,
});
