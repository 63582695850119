import { useUnit } from 'effector-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Divider,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { IncomeIcon, TransportsIcon } from '@components/icons';

import { ROUTES } from '@constants/routes';

import { TrafficFrequencyContainer } from '@features/pt-forecast-new/containers/RecalculationContainer/TrafficFrequencyContainer/TrafficFrequencyContainer';
import { TravelCostsContainer } from '@features/pt-forecast-new/containers/RecalculationContainer/TravelCostsContainer/TravelCostsContainer';
import { TravelTimeContainer } from '@features/pt-forecast-new/containers/RecalculationContainer/TravelTimeContainer/TravelTimeContainer';
import {
  $Recalculate,
  VehicleParameterRecalculate,
  updateComputedResultParamsFx,
} from '@features/pt-forecast-new/stores/recalculate';
import { $FeatureSettings } from '@features/pt-forecast-new/stores/settings';
import { $UI, UIApi } from '@features/pt-forecast-new/stores/ui';
import { updateComputedResultsParamsReformatter } from '@features/pt-forecast-new/utils/updateComputedResultsParamsReformatter';

export const RecalculationContainer = () => {
  const navigate = useNavigate();
  const {
    panels: { paramsForecast, filter },
    disabled,
  } = useUnit($UI);
  const { setDisabledRecalculation } = useUnit(UIApi);
  const { id, price, frequency, duration } = useUnit($Recalculate);
  const { scenarioDetails } = useUnit($FeatureSettings);

  const [durationParams, setDurationParams] = useState(duration);
  const [priceParams, setPriceParams] = useState(price);
  const [frequencyParams, setFrequencyParams] = useState(frequency);

  const onMoveToConstructorClick = useCallback(() => {
    navigate(ROUTES.PASSENGER_TRAFFIC_CONSTRUCTOR);
  }, [navigate]);

  const handlerUpdateDuration = useCallback(
    (param: VehicleParameterRecalculate) => {
      setDurationParams(state => ({
        ...state,
        [param.key]: param.value,
      }));
    },
    [],
  );

  const handlerUpdatePrice = useCallback(
    (param: VehicleParameterRecalculate) => {
      setPriceParams(state => ({
        ...state,
        [param.key]: param.value,
      }));
    },
    [],
  );

  const handlerUpdateFrequency = useCallback(
    (param: VehicleParameterRecalculate) => {
      setFrequencyParams(state => ({
        ...state,
        [param.key]: param.value,
      }));
    },
    [],
  );

  const handlerCalculate = useCallback(() => {
    setDisabledRecalculation(true);

    updateComputedResultParamsFx(
      updateComputedResultsParamsReformatter({
        duration: durationParams,
        price: priceParams,
        frequency: frequencyParams,
        id: id,
      }),
    );
  }, [
    id,
    durationParams,
    frequencyParams,
    priceParams,
    setDisabledRecalculation,
  ]);

  useEffect(() => {
    setDurationParams(duration);
    setPriceParams(price);
    setFrequencyParams(frequency);
  }, [duration, frequency, price]);

  return (
    paramsForecast && (
      <Box
        sx={CalculationContainerMuiStyle}
        left={filter ? '500px' : 0}
      >
        <Stack direction="column">
          <AutoFullHeight
            mb={77}
            style={{
              paddingTop: '16px',
              width: '500px',
              maxWidth: '500px',
              overflowY: 'auto',
            }}
          >
            <TravelTimeContainer
              duration={durationParams}
              base={duration}
              disabled={disabled.recalculation}
              onChange={handlerUpdateDuration}
            />

            <Divider sx={{ my: '16px' }} />

            <TravelCostsContainer
              price={priceParams}
              base={price}
              disabled={disabled.recalculation}
              onChange={handlerUpdatePrice}
            />

            <Divider sx={{ my: '16px' }} />

            <TrafficFrequencyContainer
              frequency={frequencyParams}
              base={frequency}
              disabled={disabled.recalculation}
              onChange={handlerUpdateFrequency}
            />

            <Divider sx={{ my: '16px' }} />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                py: 1,
                px: 5.5,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: 1,
                  mb: 2,
                }}
              >
                <IncomeIcon />

                <Typography variant={'subtitle2'}>
                  Сценарий социально-экономического прогноза
                </Typography>
              </Box>

              <TextField
                sx={{
                  width: '100%',
                }}
                value={scenarioDetails.socEcoScenario?.name || ''}
                disabled
              />

              <Button
                variant={'text'}
                onClick={onMoveToConstructorClick}
                sx={{
                  fontSize: '12px',
                  height: '14px',
                  p: 0,
                  [`& .MuiTouchRipple-root`]: {
                    height: '14px',
                    p: 0,
                  },
                  mt: 1.5,
                }}
                color={'info'}
              >
                Перейти в конструктор
              </Button>
            </Box>

            <Divider sx={{ my: '16px' }} />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                py: 1,
                px: 5.5,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: 1,
                  mb: 2,
                }}
              >
                <TransportsIcon />

                <Typography variant={'subtitle2'}>
                  Сценарий транспортного прогноза
                </Typography>
              </Box>

              <TextField
                sx={{
                  width: '100%',
                }}
                value={scenarioDetails?.transportScenario?.name || ''}
                disabled
              />

              <Button
                variant={'text'}
                onClick={onMoveToConstructorClick}
                sx={{
                  fontSize: '12px',
                  height: '14px',
                  p: 0,
                  [`& .MuiTouchRipple-root`]: {
                    height: '14px',
                    p: 0,
                  },
                  mt: 1.5,
                }}
                color={'info'}
              >
                Перейти в конструктор
              </Button>
            </Box>
          </AutoFullHeight>

          <Box
            sx={{
              mt: 'auto',
              p: 2,
              borderTop: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            <Button
              color="secondary"
              fullWidth
              disabled={disabled.recalculation || !id}
              onClick={handlerCalculate}
            >
              Рассчитать
            </Button>
          </Box>
        </Stack>
      </Box>
    )
  );
};

const CalculationContainerMuiStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '0',
  zIndex: '3',
  background: theme => theme.palette.background.default,
  '& > *': {
    borderTop: '1px solid #EBECEF',
    borderLeft: '1px solid #EBECEF',
    boxShadow: 'rgba(0, 0, 0, 0.1) 5px 4px 2px 0px',
    overflowY: 'auto',
  },
};
