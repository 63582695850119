import { IAgentShareInfoType } from '@api/gql/tpu-types';
import { v4 as uuid } from 'uuid';

import {
  EditableObjectType,
  IBank,
  IBankomat,
  IBuffet,
  IInformationTable,
  IKiosk,
  IOther,
  IRestaurant,
  IStore,
  IToilet,
  IVendingMachine,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

import {
  IAgentsShareAnalyzer,
  PlanError,
} from '@utils/simulationModel/errors-analyzer/types';

export class AgentsShareAnalyzer implements IAgentsShareAnalyzer {
  _data: IAgentShareInfoType[] | null = null;
  _stores = new Set<IStore | IVendingMachine | IKiosk>();
  _banks = new Set<IBank | IBankomat>();
  _food = new Set<IRestaurant | IBuffet>();
  _other = new Set<IOther>();
  _toilets = new Set<IToilet>();
  _informationTables = new Set<IInformationTable>();
  _errors = new Set<PlanError>();

  reset() {
    this._data = null;
    this._stores.clear();
    this._banks.clear();
    this._food.clear();
    this._other.clear();
    this._toilets.clear();
    this._informationTables.clear();
    this._errors.clear();
  }

  updateData(data: IAgentShareInfoType[] | null) {
    this._data = data;
  }

  addObject(data: ObjectOfTheSimulationModel) {
    switch (data.type) {
      case EditableObjectType.Store:
      case EditableObjectType.VendingMachine:
      case EditableObjectType.Kiosk:
        this._stores.add(data);
        return;
      case EditableObjectType.Bank:
      case EditableObjectType.Bankomat:
        this._banks.add(data);
        return;
      case EditableObjectType.Restaurant:
      case EditableObjectType.Buffet:
        this._food.add(data);
        return;
      case EditableObjectType.Other:
        this._other.add(data);
        return;
      case EditableObjectType.Toilet:
        this._toilets.add(data);
        return;
      case EditableObjectType.InformationTable:
        this._informationTables.add(data);
        return;
      default:
        return;
    }
  }

  getErrors() {
    if (!this._data) return [];
    const conditions = {
      isStoreProbabilityNotNull: false,
      isBankProbabilityNotNull: false,
      isFoodProbabilityNotNull: false,
      isOtherProbabilityNotNull: false,
      isToiletProbabilityNotNull: false,
      isInformationTableProbabilityNotNull: false,
    };

    this._data.forEach(agentShareInfo => {
      if (agentShareInfo.storeProbability > 0)
        conditions.isStoreProbabilityNotNull = true;
      if (agentShareInfo.bankProbability > 0)
        conditions.isBankProbabilityNotNull = true;
      if (agentShareInfo.foodProbability > 0)
        conditions.isFoodProbabilityNotNull = true;
      if (agentShareInfo.otherProbability > 0)
        conditions.isOtherProbabilityNotNull = true;
      if (agentShareInfo.toiletProbability > 0)
        conditions.isToiletProbabilityNotNull = true;
      if (agentShareInfo.informationTableProbability > 0)
        conditions.isInformationTableProbabilityNotNull = true;
    });

    if (conditions.isStoreProbabilityNotNull && !this._stores.size) {
      this._errors.add({
        objectType: [
          EditableObjectType.Store,
          EditableObjectType.VendingMachine,
          EditableObjectType.Kiosk,
        ],
        id: uuid(),
        description:
          'На плане отсутствуют объекты "Магазин", "Вендинговый аппарат", "Киоск" и есть не нулевая вероятность их посещения',
      });
    }

    if (conditions.isBankProbabilityNotNull && !this._banks.size) {
      this._errors.add({
        objectType: EditableObjectType.Bank,
        id: uuid(),
        description:
          'На плане отсутствуют объекты "Банк", "Банкомат" и есть не нулевая вероятность их посещения',
      });
    }

    if (conditions.isFoodProbabilityNotNull && !this._food.size) {
      this._errors.add({
        objectType: [EditableObjectType.Restaurant, EditableObjectType.Buffet],
        id: uuid(),
        description:
          'На плане отсутствуют объекты "Ресторан", "Буффет" и есть не нулевая вероятность их посещения',
      });
    }

    if (conditions.isOtherProbabilityNotNull && !this._other.size) {
      this._errors.add({
        objectType: EditableObjectType.Other,
        id: uuid(),
        description:
          'На плане отсутствует объект "Прочее" и есть не нулевая вероятность его посещения',
      });
    }

    if (conditions.isToiletProbabilityNotNull && !this._toilets.size) {
      this._errors.add({
        objectType: EditableObjectType.Toilet,
        id: uuid(),
        description:
          'На плане отсутствует объект "Туалет" и есть не нулевая вероятность его посещения',
      });
    }

    if (
      conditions.isInformationTableProbabilityNotNull &&
      !this._informationTables.size
    ) {
      this._errors.add({
        objectType: EditableObjectType.InformationTable,
        id: uuid(),
        description:
          'На плане отсутствует объект "Информационное табло" и есть не нулевая вероятность его посещения',
      });
    }

    return Array.from(this._errors);
  }
}
