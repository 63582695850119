import { VehicleTypeCode } from '@features/passenger-traffic/stores';

// TODO: Брать цвета из палет темы
export const getTrafficColor = (vehicle: VehicleTypeCode) => {
  switch (vehicle) {
    case VehicleTypeCode.multimodal:
      return '#3362BC';
    case VehicleTypeCode.railwayRoot:
      return '#7E67DD';
    case VehicleTypeCode.avia:
      return '#00A1DC';
    case VehicleTypeCode.bus:
      return '#F3A015';
    case VehicleTypeCode.auto:
      return '#FF792E';
    case VehicleTypeCode.nightTrain:
      return '#8AB0D2';
    case VehicleTypeCode.dayTrain:
      return '#A3A86B';
    case VehicleTypeCode.suburbanTrain:
      return '#DDAB71';
    case VehicleTypeCode.expressTrain:
      return '#7E67DD';
    default:
      return 'black';
  }
};
