import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';

export const MuiFormControlLabel: Components<Theme>['MuiFormControlLabel'] = {
  styleOverrides: {
    label: ({ theme }) => ({
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      userSelect: 'none',
    }),
  },
};
