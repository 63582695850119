interface TabContentProps {
  choosenTab: number;
  value: number;
  children: JSX.Element | JSX.Element[];
}

export const TabContent = ({
  choosenTab,
  children,
  value,
}: TabContentProps) => {
  if (value !== choosenTab) return null;

  return children;
};
