import { Button } from '@mui/material';

import { BasketIcon } from '@components/icons';

interface ActionsProps {
  onSave: () => void;
  onDelete: () => void;
  disabled: boolean;
}

export function FormActions({ onSave, onDelete, disabled }: ActionsProps) {
  return (
    <>
      <Button
        fullWidth
        onClick={onSave}
        disabled={disabled}
      >
        Сохранить и закрыть
      </Button>
      <Button
        variant="text"
        fullWidth
        onClick={onDelete}
      >
        <BasketIcon sx={{ mr: '8px' }} />
        Удалить объект
      </Button>
    </>
  );
}
