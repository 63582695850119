import { Event, Subscription } from 'effector';
import { Feature, Map as MapOl } from 'ol';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

import { EMapFeatureLayout, EMapLayerMetaKeys } from '@constants/map';

import { VectorStyleProps } from '@utils/map/styles/types';
import {
  EVectorLayerStyles,
  getVectorLayerStyle,
  getVectorLayerStyleProps,
} from '@utils/map/tools/getVectorLayerStyle';

export enum EFeatureStyle {
  default = 'default',
  selected = 'selected',
  started = 'started',
  ended = 'ended',
  alternate = 'alternate',
  alternateSelected = 'alternateSelected',
}

export enum EPipeVectorLayer {
  replaceFeatures,
  setVisible,
  setFeatureStyle,
  changeTheme,
  changeThemeProps,
}

type themeProps = Omit<VectorStyleProps, 'map' | 'feature' | 'resolution'>;

export type PipeVectorLayer =
  | {
      action: EPipeVectorLayer.replaceFeatures;
      payload: {
        width?: number;
        features: Feature<Geometry>[];
      };
    }
  | {
      action: EPipeVectorLayer.setVisible;
      payload: boolean;
    }
  | {
      action: EPipeVectorLayer.setFeatureStyle;
      payload: {
        featureStyles: Record<string, EFeatureStyle>;
      };
    }
  | {
      action: EPipeVectorLayer.changeTheme;
      payload: {
        theme: EVectorLayerStyles;
        props?: themeProps;
        isNoReplace?: boolean;
      };
    }
  | {
      action: EPipeVectorLayer.changeThemeProps;
      payload: themeProps;
    };

export type useInitVectorLayerProps = {
  mapRef: MutableRefObject<MapOl | null>;
  events: Event<PipeVectorLayer>[];
  config: {
    readonly zIndex: number;
    readonly layout: EMapFeatureLayout;
    readonly visible?: boolean;
    readonly themeProps?: themeProps;
  } & getVectorLayerStyleProps;
};

export const useInitVectorLayer = (props: useInitVectorLayerProps) => {
  const { mapRef, events, config } = props;

  const layerRef = useRef<VectorLayer<VectorSource<Feature<Geometry>>>>();
  const themeFnRef = useRef(getVectorLayerStyle(config));
  const lastThemeProps = useRef<themeProps>({});
  const subscribersRef = useRef<Subscription[]>([]);

  const listener = useCallback(({ action, payload }: PipeVectorLayer) => {
    switch (action) {
      // переопределение фич на слое
      case EPipeVectorLayer.replaceFeatures:
        layerRef.current?.setSource(
          new VectorSource({ features: payload.features }),
        );
        mapRef.current?.render();
        return;
      // Установка видимости слоя
      case EPipeVectorLayer.setVisible:
        return layerRef.current?.setVisible(payload);
      // Ручное управление стилями отдельных фич
      case EPipeVectorLayer.setFeatureStyle:
        layerRef.current?.setStyle((feature, resolution) =>
          themeFnRef.current({
            resolution,
            feature,
            map: mapRef.current!,
            featureStyles: payload.featureStyles,
            ...lastThemeProps.current,
          }),
        );
        break;
      case EPipeVectorLayer.changeTheme:
        themeFnRef.current = getVectorLayerStyle({
          layout: config.layout,
          theme: payload.theme,
        });

        if (payload.isNoReplace)
          lastThemeProps.current = {
            ...lastThemeProps.current,
            ...payload.props,
          };
        else lastThemeProps.current = payload.props || {};

        layerRef.current?.setStyle((feature, resolution) =>
          themeFnRef.current({
            resolution,
            feature,
            map: mapRef.current!,
            ...lastThemeProps.current,
          }),
        );
        break;
      // Переопределение пропсов темы
      case EPipeVectorLayer.changeThemeProps:
        lastThemeProps.current = payload || {};

        layerRef.current?.setStyle((feature, resolution) =>
          themeFnRef.current({
            resolution,
            feature,
            map: mapRef.current!,
            ...lastThemeProps.current,
          }),
        );
        // mapRef.current?.render();
        break;
    }
  }, []);

  useEffect(() => {
    layerRef.current = new VectorLayer({
      declutter: false,
      zIndex: config.zIndex,
      visible: config.visible,
      style: (feature, resolution) =>
        themeFnRef.current({
          resolution,
          feature,
          map: mapRef.current!,
          ...config.themeProps,
        }),
    });

    layerRef.current.set(EMapLayerMetaKeys.layout, config.layout);

    subscribersRef.current = events.map(event => event.watch(listener));

    mapRef.current?.addLayer(layerRef.current);

    return () => {
      subscribersRef.current.forEach(subscriber => subscriber.unsubscribe());
    };
  }, []);

  return layerRef;
};
