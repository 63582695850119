import { apiClient } from '@api/client';
import { IMutation, IMutationUpdateScenarioArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

export const mutationUpdateScenario = async (
  scenario: IMutationUpdateScenarioArgs,
) => {
  const result = await apiClient.mutate<IMutation>({
    mutation: gql`
      mutation UpdateScenarioMutation(
        $name: String!
        $scenarioId: UUID!
        $socEcoScenario: UUID!
        $takeIntoInfra: Boolean!
      ) {
        updateScenario(
          name: $name
          scenarioId: $scenarioId
          socEcoScenario: $socEcoScenario
          takeIntoInfra: $takeIntoInfra
        ) {
          ok
          error
          instance {
            id
          }
        }
      }
    `,
    variables: scenario,
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  if (result.errors?.length) throw new Error(result.errors[0].message);

  return result.data?.updateScenario;
};
