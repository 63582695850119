import { SvgIcon, SvgIconProps } from '@mui/material';

export const RulerIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M7.821 20a.929.929 0 0 1-.66-.273l-2.888-2.889a.933.933 0 0 1 0-1.319L15.519 4.273a.933.933 0 0 1 1.32 0l2.888 2.889a.933.933 0 0 1 0 1.319L8.481 19.727a.929.929 0 0 1-.66.273ZM16.18 4.8a.133.133 0 0 0-.095.038L4.838 16.085a.133.133 0 0 0 0 .188l2.889 2.889a.133.133 0 0 0 .188 0L19.162 7.916a.133.133 0 0 0 0-.189l-2.889-2.889a.132.132 0 0 0-.094-.039Z"
        fill="currentColor"
      />
      <path
        d="M16.027 7.427a.398.398 0 0 1-.282-.117l-1.35-1.348a.4.4 0 1 1 .566-.566l1.349 1.35a.4.4 0 0 1-.283.681ZM14.163 8.563a.398.398 0 0 1-.283-.117l-.984-.984a.4.4 0 1 1 .565-.566l.984.985a.4.4 0 0 1-.282.682ZM13.027 10.428a.398.398 0 0 1-.283-.118l-1.348-1.348a.4.4 0 1 1 .565-.566l1.348 1.35a.4.4 0 0 1-.282.681ZM11.163 11.563a.398.398 0 0 1-.283-.117l-.984-.984a.4.4 0 1 1 .565-.566l.984.985a.4.4 0 0 1-.282.682ZM10.027 13.428a.398.398 0 0 1-.282-.118l-1.35-1.348a.4.4 0 1 1 .566-.566l1.349 1.35a.4.4 0 0 1-.283.681ZM8.162 14.563a.398.398 0 0 1-.282-.117l-.985-.984a.4.4 0 1 1 .566-.566l.984.985a.4.4 0 0 1-.283.682ZM7.027 16.427a.398.398 0 0 1-.283-.117l-1.348-1.348a.4.4 0 1 1 .565-.566l1.348 1.35a.4.4 0 0 1-.282.681Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
