import { CommercialAreasTable } from '@features/tpu-calculation-scenarios-viewing/components/CommercialAreasTable';
import { NoDataAvailable } from '@features/tpu-calculation-scenarios-viewing/components/NoDataAvailable/NoDataAvailable';
import {
  $ScenarioData,
  $TableCommercialSquare,
  EViewerQueryParams,
} from '@features/tpu-calculation-scenarios-viewing/stores';
import { useUnit } from 'effector-react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { ROUTES } from '@constants/routes';

export const CommercialAreasContainer = () => {
  const navigate = useNavigate();

  const $scenarioData = useUnit($ScenarioData);
  const $tableCommercialSquare = useUnit($TableCommercialSquare);

  const goToCommercialAreas = () => {
    navigate(
      `${ROUTES.TPU_SCENARIOS}/${$scenarioData.scenarioId}${ROUTES.TPU_SCENARIOS_VIEWER}` +
        `?page=${EViewerQueryParams.commercialAreas}`,
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        backgroundColor: theme => theme.palette.background.paper,
        padding: '24px',
      }}
    >
      <Typography
        variant={'subtitle1'}
        sx={{ mb: '24px' }}
      >
        Коммерческие площади
      </Typography>

      {$tableCommercialSquare?.length ? (
        <CommercialAreasTable
          rows={$tableCommercialSquare}
          onClick={goToCommercialAreas}
        />
      ) : (
        <NoDataAvailable />
      )}
    </Box>
  );
};
