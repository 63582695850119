import { createBorderStyle } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/createBorderStyle';
import { SetFeatureStyleProps } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/getFeatureStyle';
import { Stroke, Style } from 'ol/style';

export type GetPlannedHighwayStyleArgs = Pick<
  SetFeatureStyleProps,
  'active' | 'width'
>;

export const getPlannedHighwayStyle = ({
  active,
  width,
}: GetPlannedHighwayStyleArgs) => [
  createBorderStyle({ active, featureWidth: width || 2 }),
  new Style({
    stroke: new Stroke({
      color: '#FF792E99',
      width,
    }),
  }),
];
