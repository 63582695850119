import {
  $ComponentState,
  TComponentState,
  getComponentStateFn,
  resetComponentStateFn,
} from './componentState.store';

$ComponentState.reset(resetComponentStateFn).on(getComponentStateFn, () => {
  const currentPage = new URLSearchParams(window.location.search).get(
    'page',
  ) as TComponentState;

  return currentPage;
});
