import {
  IQueryGetProjectScenariosArgs, // IComputingRegistryScenarioResultStatusChoices,
  IQueryGetProjectSocEcoScenariosArgs,
} from '@api/gql/ag-types';
import { sample } from 'effector';

import { mutationCreateScenario } from '@features/ag-constructor/stores/api/mutationCreateScenario';
import { mutationCreateSocEcoScenario } from '@features/ag-constructor/stores/api/mutationCreateSocEcoScenario';
import { mutationRunComputeScenario } from '@features/ag-constructor/stores/api/mutationRunComputeScenario';
import { queryGetScenarioById } from '@features/ag-constructor/stores/api/queryGetScenarioById';
import {
  CurrentScenarioApi,
  SelectedSocEcoScenario,
  SetScenarioPayload,
  createScenarioFx,
  fetchCreateScenarioSocEcoFx,
  getScenarioByIdFx,
  runComputeScenarioFx,
  updateScenarioFx,
} from '@features/ag-constructor/stores/currentScenario/store';
import {
  ComputedScenarioListApi,
  fetchComputedScenarioListFx,
  fetchSocEcoScenarioListFx,
} from '@features/ag-constructor/stores/lists/scenarios';
import { $FeatureSettings } from '@features/ag-constructor/stores/settings';

import { mutationUpdateScenario } from '../api/mutationUpdateScenario';
import { fetchScenariosStatusesFx } from '../lists/statuses/store';

getScenarioByIdFx.use(queryGetScenarioById);

// Поместить полученный сценарий в стор выбранного сценария
sample({
  clock: getScenarioByIdFx.done,
  fn: request => {
    return {
      name: request.result?.name || '',
      id: request.result?.id || '',
      takeIntoInfra: request.result?.takeIntoInfra,
      socEcoScenario: request.result?.socEcoScenario,
    } as SetScenarioPayload;
  },
  target: CurrentScenarioApi.setScenario,
});

//--------------

createScenarioFx.use(mutationCreateScenario);

// Сброс текущего сценария и добавление нового в список сценариев расчета
sample({
  clock: createScenarioFx.done,
  fn: request => {
    const scenario = request.result?.scenarioInstance;
    return {
      name: scenario?.name || '',
      id: scenario?.id || '',
    };
  },
  target: [
    ComputedScenarioListApi.pushUserComputedScenarioList,
    CurrentScenarioApi.reset,
  ],
});

// Выбор нового сценария расчета
sample({
  clock: createScenarioFx.done,
  fn: request => ({
    scenarioId: request.result?.scenarioInstance?.id || '',
  }),
  target: getScenarioByIdFx,
});

// Обновление статуса после создания сценария расчета
sample({
  clock: createScenarioFx.done,
  source: {
    featureSettings: $FeatureSettings,
  },
  fn: ({ featureSettings }) =>
    ({
      projectId: featureSettings.projectUUID,
    }) as IQueryGetProjectScenariosArgs,
  target: fetchScenariosStatusesFx,
});

//--------------

updateScenarioFx.use(mutationUpdateScenario);

// Обновление списка сценариев расчета
sample({
  clock: updateScenarioFx.done,
  source: {
    featureSettings: $FeatureSettings,
  },
  fn: ({ featureSettings }) =>
    ({
      projectId: featureSettings.projectUUID,
    }) as IQueryGetProjectScenariosArgs,
  target: fetchComputedScenarioListFx,
});

// Обновление статуса после изменения сценария расчета
sample({
  clock: updateScenarioFx.done,
  source: {
    featureSettings: $FeatureSettings,
  },
  fn: ({ featureSettings }) =>
    ({
      projectId: featureSettings.projectUUID,
    }) as IQueryGetProjectScenariosArgs,
  target: fetchScenariosStatusesFx,
});

// Переход в режим чтения
sample({
  clock: updateScenarioFx.done,
  fn: () => true,
  target: CurrentScenarioApi.setReadonly,
});

//--------------

fetchCreateScenarioSocEcoFx.use(mutationCreateSocEcoScenario);

// Получение списка сценариев соц эко
sample({
  clock: fetchCreateScenarioSocEcoFx.done,
  source: $FeatureSettings,
  fn: store =>
    ({
      projectId: store.projectUUID || '',
    }) as IQueryGetProjectSocEcoScenariosArgs,
  target: fetchSocEcoScenarioListFx,
});

// Выбор нового сценария соц эко
sample({
  clock: fetchCreateScenarioSocEcoFx.done,
  fn: ({ result }) => {
    const scenario = result?.computingInstance;
    return {
      annualPopulationGrowthRate: scenario?.annualPopulationGrowthRate,
      annualEducationPlacesGrowthRate:
        scenario?.annualEducationPlacesGrowthRate,
      annualRetailSpaceGrowthRate: scenario?.annualRetailSpaceGrowthRate,
      annualJobsGrowthRate: scenario?.annualJobsGrowthRate,
      annualServiceJobsGrowthRate: scenario?.annualServiceJobsGrowthRate,
      annualSalariesGrowthRate: scenario?.annualSalariesGrowthRate,
      annualGardenPartnershipGrowthRate:
        scenario?.annualGardenPartnershipGrowthRate,
      name: scenario?.name,
      id: scenario?.id,
    } as SelectedSocEcoScenario;
  },
  target: CurrentScenarioApi.createSocEcoScenario,
});

//--------------

runComputeScenarioFx.use(mutationRunComputeScenario);
