import { downloadFile } from '@api/queries';

import { downloadFileFx } from '@features/tpu-project-registry/stores/download-file/download-file.store';

import { base64ToBlob } from '@utils/fileConversion';

downloadFileFx.use(async ({ id, type }) => {
  const response = await downloadFile({ objectId: id, fileType: type });

  const fileData = response.data?.downloadFile?.file || '';

  const blob = base64ToBlob(fileData, 'application/octet-stream');
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('target', '_blank');
  a.setAttribute('download', response.data?.downloadFile?.name ?? '');
  a.click();
  URL.revokeObjectURL(url);
});
