import { createEvent, createStore } from 'effector';

export enum EPTPanels {
  filter = 'filter',
}

export type PTUI = {
  panels: PTPanels;
  backdrop: boolean;
  loading: PTLoading;
};

export type PTPanels = {
  filter: boolean;
  inspector: boolean;
};

export type PTLoading = {
  inspectorPassTraffic: boolean;
};

export const $UI = createStore<PTUI>({
  panels: {
    filter: false,
    inspector: false,
  },
  backdrop: false,
  loading: {
    inspectorPassTraffic: false,
  },
});

export const toggleFilterPanelFn = createEvent();

export const setMapBackdropFn = createEvent<boolean>();

export const toggleInspectorPanelFn = createEvent();

export const setVisibleInspector = createEvent<boolean>();

export const onCloseInspectorFn = createEvent();

export const setVisibleLoading = createEvent<Partial<PTLoading>>();

$UI
  .on(toggleFilterPanelFn, state => {
    state.panels.filter = !state.panels.filter;

    return { ...state };
  })
  .on(setMapBackdropFn, (state, payload) => {
    state.backdrop = payload;

    return { ...state };
  })
  .on(toggleInspectorPanelFn, state => {
    return {
      ...state,
      panels: {
        ...state.panels,
        inspector: !state.panels.inspector,
      },
    };
  })
  .on(setVisibleInspector, (state, payload) => {
    return {
      ...state,
      panels: {
        ...state.panels,
        inspector: payload,
      },
    };
  })
  .on(onCloseInspectorFn, state => {
    return {
      ...state,
      panels: {
        ...state.panels,
        inspector: false,
      },
    };
  })
  .on(setVisibleLoading, (state, payload) => ({
    ...state,
    loading: {
      ...state.loading,
      ...payload,
    },
  }));
