import { sample } from 'effector';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EMapFeatureLayout } from '@constants/map';

import { $FilterMap, FilterMapApi } from '@features/ag-forecast/stores/filters';
import {
  $AgGeometry,
  AgGeometryApi,
  LayersVisibleApi,
  pipeRailwayStationsLayer,
} from '@features/ag-forecast/stores/map';
import {
  $YearLineStore,
  YearLineApi,
} from '@features/ag-forecast/stores/yearLine';
import {
  layerIdKey,
  layerLayoutKey,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox';

import {
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload &&
    Object.keys(payload).includes(EMapFeatureLayout.graphSuburbanRailway),
  fn: payload => {
    return {
      action: EPipeVectorLayer.setVisible,
      payload: payload![EMapFeatureLayout.graphSuburbanRailway],
    } as PipeVectorLayer;
  },
  target: pipeRailwayStationsLayer,
});

// Рендер геометрии
sample({
  clock: [
    AgGeometryApi.setAgGeometry,
    FilterMapApi.setFilters,
    YearLineApi.setSelectedYear,
  ],
  source: {
    AgGeometry: $AgGeometry,
    YearLineStore: $YearLineStore,
    FilterMap: $FilterMap,
  },
  filter: ({ AgGeometry }) => !!AgGeometry.railwayStations?.length,
  fn: ({ AgGeometry, FilterMap, YearLineStore }) => {
    if (!AgGeometry.railwayStations)
      throw Error('AgGeometry.railwayStations is undefined');

    const features: Feature<Point>[] = [];

    AgGeometry.railwayStations.forEach(railwayStation => {
      if (
        FilterMap.actual.isActual &&
        railwayStation.year <= YearLineStore.selectedYear
      ) {
        features.push(
          new Feature({
            geometry: new Point(fromLonLat(railwayStation.coordinates)),
            [layerIdKey]: railwayStation.id,
            [layerLayoutKey]: EMapFeatureLayout.agRailwayStations,
            isPlanned: false,
            isTpu: railwayStation.isTpu,
          }),
        );
      }
      if (
        FilterMap.actual.isPlanned &&
        railwayStation.year > YearLineStore.selectedYear
      ) {
        features.push(
          new Feature({
            geometry: new Point(fromLonLat(railwayStation.coordinates)),
            [layerIdKey]: railwayStation.id,
            [layerLayoutKey]: EMapFeatureLayout.agRailwayStations,
            isPlanned: true,
            isTpu: railwayStation.isTpu,
          }),
        );
      }
    });

    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: {
        features,
      },
    } as PipeVectorLayer;
  },
  target: pipeRailwayStationsLayer,
});
