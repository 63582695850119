import { apiClient } from '@api/client';
import { IQuery } from '@api/gql/types';
import { gql } from '@apollo/client';

export type QueryGetAllScenariosResponse = Pick<
  IQuery,
  | 'transportScenarioInstances'
  | 'socEcoScenarioInstances'
  | 'transportProjectList'
  | 'computingRegistry'
>;

export const queryGetAllScenarios = async () => {
  const response = await apiClient.query<QueryGetAllScenariosResponse>({
    query: gql`
      query QueryGetAllScenarios {
        transportScenarioInstances {
          name
          id
          scenarioType
          transportProjects {
            transportProject {
              name
            }
            id
            year
          }
        }
        socEcoScenarioInstances {
          id
          name
          jobGrowthRate
          growthRateOfMotorization
          growthRateInTheNumberOfPlacements
          revenueGrowthRate
          populationGrowthRate
          salaryGrowthRate
          scenarioType
          computed
        }
        transportProjectList {
          name
          id
        }
        computingRegistry {
          id
          name
          socEcoScenario {
            growthRateInTheNumberOfPlacements
            scenarioType
            revenueGrowthRate
            growthRateOfMotorization
            populationGrowthRate
            jobGrowthRate
            salaryGrowthRate
            name
            id
          }
          transportScenario {
            id
            scenarioType
            name
            #            transportProjects {
            #              id
            #              year
            #              transportProject {
            #                name
            #              }
            #            }
          }
        }
      }
    `,
  });

  return response.data;
};
