import { createEvent, createStore } from 'effector';

export const enum EViewerQueryParams {
  passengerFlowCharts = 'passenger-flow-charts',
  commercialAreas = 'commercial-areas',
}

export type TComponentState = EViewerQueryParams | null;

export const $ComponentState = createStore<TComponentState>(null);

export const getComponentStateFn = createEvent<void>();

export const resetComponentStateFn = createEvent();
