import { createApi, createStore } from 'effector';

export const initState: ErrorsType = {
  isCheckErrors: false,
  errorsName: [],
};

export const $Errors = createStore<ErrorsType>(initState);

export const ErrorsApi = createApi($Errors, {
  setCheckErrors: (store, payload: boolean) => ({
    ...store,
    isCheckErrors: payload,
  }),
  setErrorsName: (store, errorsName: ErrorsType['errorsName']) => ({
    ...store,
    errorsName,
  }),
  reset: () => initState,
});

type ErrorsType = {
  isCheckErrors: boolean;
  errorsName: string[];
};
