import { useUnit } from 'effector-react';
import { SyntheticEvent, useCallback, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { AirportIcon, BusStationIcon, TrainIcon } from '@components/icons';

import { mapFocusKit } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/featureTools/mapFocusKit';
import {
  $Map,
  changeMap,
  getDataForInspectorFn,
  setMapFeatures,
} from '@features/passenger-traffic/stores';
import { querySearchInfra } from '@features/passenger-traffic/stores/api/querySearchInfra';

export const SearchInput = () => {
  const { map } = useUnit($Map);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<OptionItem[]>([]);

  const handleFocus = () => {
    setInputValue('');
    setOptions([]);
  };

  const handleInputChange = useCallback(
    async (_event: SyntheticEvent, value: string) => {
      setInputValue(value);

      if (!value) return setOptions([]);

      const result = await querySearchInfra({ contains: value });
      setOptions(
        result.data.getInfraPointByName?.map(item => ({
          value: item?.id || '',
          name: item?.name || '',
          type: item?.type || '',
          lonLat: [item!.point!.lat!, item!.point!.lon!],
        })) || [],
      );
    },
    [],
  );

  const onSelectHandler = useCallback(
    (_event: SyntheticEvent, option: NonNullable<string | OptionItem>) => {
      if (typeof option !== 'object' || !map) return;

      switch (option.type) {
        case 'airport':
          return mapFocusKit.focusAirport(
            option,
            map,
            setMapFeatures,
            changeMap,
            getDataForInspectorFn,
          );
        case 'railway_station':
          return mapFocusKit.focusRailwayStation(
            option,
            map,
            setMapFeatures,
            changeMap,
            getDataForInspectorFn,
          );
        case 'bus_station':
          return mapFocusKit.focusBusStation(
            option,
            map,
            setMapFeatures,
            changeMap,
            getDataForInspectorFn,
          );
        case 'transport_region':
        case 'transport_point':
          return;
        default:
          throw new Error(`Unknown option type - ${option.type}`);
      }
    },
    [map],
  );

  return (
    <Box sx={{ width: '460px', height: '44px' }}>
      <Autocomplete
        freeSolo
        fullWidth
        id="free-solo-2-demo"
        disableClearable
        options={options}
        onInputChange={handleInputChange}
        onChange={onSelectHandler}
        inputValue={inputValue} // Управление состоянием ввода
        getOptionLabel={option =>
          typeof option === 'object' ? option.name : option
        }
        renderOption={(props, option) => (
          <Stack
            direction="row"
            alignItems="center"
            gap={0}
            ml={1}
          >
            {option.type === 'railway_station' && (
              <TrainIcon
                sx={{
                  color: theme => theme.palette.customs.train,
                  width: theme => theme.typography.pxToRem(20),
                  justifySelf: 'center',
                }}
              />
            )}

            {option.type === 'airport' && (
              <AirportIcon
                sx={{
                  color: theme => theme.palette.customs.avia,
                  width: theme => theme.typography.pxToRem(20),
                  justifySelf: 'center',
                }}
              />
            )}
            {option.type === 'bus_station' && (
              <BusStationIcon
                sx={{
                  color: theme => theme.palette.customs.auto,
                  width: theme => theme.typography.pxToRem(20),
                  justifySelf: 'center',
                }}
              />
            )}
            <Typography
              {...props}
              key={`${option.type}-${option.value}`}
            >
              {option.name}
            </Typography>
          </Stack>
        )}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Найти"
            onFocus={handleFocus} // Обработчик фокуса на поле ввода
            size="medium"
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: (
                <InputAdornment
                  position="end"
                  onChange={handleFocus}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

type OptionItem = {
  name: string;
  value: string;
  lonLat: number[];
  type: string;
};
