import { sample } from 'effector';

import { queryDataDashboardP2 } from '@features/ag-forecast/stores/api/queryDataDashboardP2';
import {
  SecondPageApi,
  getTPUFx,
} from '@features/ag-forecast/stores/dashboard/secondPage/store';

getTPUFx.use(queryDataDashboardP2);

// Запись данных тпу в стор
sample({
  clock: getTPUFx.done,
  target: SecondPageApi.setTPU,
});
