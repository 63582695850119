import { Grid, Typography } from '@mui/material';

import { CheckOkIcon } from '@components/icons';

export const Success = () => (
  <Grid
    display={'flex'}
    gap={1.5}
    alignItems={'center'}
  >
    <CheckOkIcon
      sx={{
        fontSize: '44px',
        color: 'transparent',
      }}
    />

    <Typography variant="body2">Доступ предоставлен</Typography>
  </Grid>
);
