import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';

import { Toolbar } from '@features/tpu-passenger-types/components/Toolbar';
import {
  FormPassengerShareContainer,
  FormPassengerTypeContainer,
  RegistyPassengerTypesContainer,
} from '@features/tpu-passenger-types/containers';
import { useContextPassengerTypesHandlers } from '@features/tpu-passenger-types/hooks';
import { $IsOpenTypeForm } from '@features/tpu-passenger-types/stores/form.store';
import { $IsOpenShareForm } from '@features/tpu-passenger-types/stores/formShare.store';

export const Component = () => {
  const navigate = useNavigate();
  const {
    setScenarioId,
    setIsTriggerSaveShareForm,
    setIsTriggerSaveForm,
    openTypeForCreate,
  } = useContextPassengerTypesHandlers();

  const handleClickBack = () => {
    navigate(-1);
  };

  const handleSaveShare = () => {
    setIsTriggerSaveShareForm(true);
  };
  const handleSave = () => {
    setIsTriggerSaveForm(true);
  };

  useEffect(() => {
    setScenarioId();
  }, [setScenarioId]);

  return (
    <Box
      sx={{
        padding: '24px',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          width: '58vw',
          mr: '24px',
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <Toolbar
          onCreateType={openTypeForCreate}
          onBack={handleClickBack}
          onSaveShare={handleSaveShare}
          onSave={handleSave}
        />
        <AutoFullHeight
          mb={24}
          style={{ display: 'flex', gap: '24px' }}
        >
          <RegistyPassengerTypesContainer />
        </AutoFullHeight>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <AutoFullHeight
          mb={24}
          style={{ display: 'flex' }}
        >
          {$IsOpenTypeForm && <FormPassengerTypeContainer />}
          {$IsOpenShareForm && <FormPassengerShareContainer />}
        </AutoFullHeight>
      </Box>
    </Box>
  );
};
