import { createEvent, createStore } from 'effector';

import {
  IRuler,
  ListOfRulersDataType,
  SimulationModelPlanData,
} from '@features/tpu-simulation-model/types';

export const $ListOfRulers = createStore<ListOfRulersDataType>({});

export const $Ruler = createStore<IRuler | null>(null);

export const setListOfRulers = createEvent<SimulationModelPlanData>();

export const resetListOfRulersFn = createEvent<void>();

export const resetRulerFn = createEvent<void>();
