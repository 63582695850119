import {
  ScenarioOption,
  TransportProject,
} from '@features/pt-constructor/store';
import { createEvent, createStore, sample } from 'effector';

import { checkingNameScenarioTransport } from './utils';

export enum EStepModalTransport {
  one,
  two,
}

export type ModalTransportFields = {
  name: string;
};

type ModalTransport = {
  step: EStepModalTransport;
  name: string;
  option: ScenarioOption | null;
  transportObjects: TransportProject[];
  isErrorName: boolean;
  isErrorScenario: boolean;
  isCheckErrors: boolean;
};

const defaultState = {
  step: EStepModalTransport.one,
  name: '',
  option: null,
  transportObjects: [],
  isErrorName: false,
  isErrorScenario: false,
  isCheckErrors: false,
};

export const $ModalTransport = createStore<ModalTransport>(defaultState);

export const setStep = createEvent<EStepModalTransport>();

export const setTransportName = createEvent<ModalTransport['name']>();

export const setTransportOption = createEvent<ScenarioOption>();

export const setTransportObjects =
  createEvent<ModalTransport['transportObjects']>();

// проверка валидности имени нового транспортного сценария
export const checkErrorName = createEvent();

// проверка выбран ли базовый транспортного сценарий
export const checkErrorScenario = createEvent();

// установка динамической проверки ошибок значений на первом шаге
export const setErrorChecking = createEvent();

export const resetModalTransport = createEvent();

$ModalTransport
  .on(setStep, (state, payload) => ({
    ...state,
    step: payload,
  }))
  .on(setTransportName, (state, name) => ({
    ...state,
    name,
  }))
  .on(setTransportOption, (state, option) => ({
    ...state,
    option,
  }))
  .on(setTransportObjects, (state, transportObjects) => ({
    ...state,
    transportObjects,
  }))
  .on(checkErrorName, state => ({
    ...state,
    isErrorName: checkingNameScenarioTransport(state.name),
  }))
  .on(checkErrorScenario, state => ({
    ...state,
    isErrorScenario: !state.option,
  }))
  .on(setErrorChecking, state => ({
    ...state,
    name: state.name.trim(),
    isErrorName: checkingNameScenarioTransport(state.name.trim()),
    isErrorScenario: !state.option,
    isCheckErrors: true,
  }))
  .reset(resetModalTransport);

// триггер проверки значений и переход на следующий шаг в случае отсутствия ошибок
sample({
  clock: setErrorChecking,
  source: $ModalTransport,
  filter: state => !state.isErrorName && !state.isErrorScenario,
  fn: () => EStepModalTransport.two,
  target: setStep,
});
