import { Stroke, Style } from 'ol/style';

interface CreateBorderStyleProps {
  active: boolean;
  featureWidth: number;
}

/**
 * Получить стиль обводки для инфраструктуры
 * @param data
 * @returns
 */
export const createBorderStyle = ({
  active,
  featureWidth,
}: CreateBorderStyleProps) =>
  new Style({
    stroke: new Stroke({
      color: active ? 'black' : 'transparent',
      width: featureWidth + 2,
      lineCap: 'butt',
    }),
  });
