import { ITransportRegionType } from '@api/gql/types';
import { sample } from 'effector';

import { EFilterMapLayout } from '@components/MapFilters';

import { createMultiAreaBordersFeature } from '@features/passenger-traffic/components/PassengerMap/mapToolBox';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import {
  $FilterMap,
  $FilterRegions,
  $Map,
  SetMapFeaturesTypes,
  changeMap,
  setMapFeatures,
} from '@features/passenger-traffic/stores';

// Контроль рендера фич границ ТР
sample({
  clock: changeMap,
  source: { Map: $Map, FilterMap: $FilterMap, FilterRegions: $FilterRegions },
  fn: ({ Map, FilterMap, FilterRegions }) => {
    const layer: SetMapFeaturesTypes = {
      params: {
        layout: EMapFeatureLayout.areaBorder,
      },
      features: [],
    };

    // В фильтре отключено отображение границ ТР
    if (!FilterMap.areaBorder) return layer;

    // Установлен поинт А и Б
    if (FilterRegions.selectedRegionA && FilterRegions.selectedRegionB) {
      // Установлен флаг - отображать промежуточные корреспонденции
      if (FilterMap.intermediateCorrespondents) {
        layer.features = createMultiAreaBordersFeature(
          FilterRegions.directions.regions,
        );
        return layer;
      }
      // Без промежуточных корреспонденций
      else {
        const areas: ITransportRegionType[] = [];
        const regionA = Map.allTransportRegions.find(
          region => region.id === FilterRegions.selectedRegionA?.id,
        );
        const regionB = Map.allTransportRegions.find(
          region => region.id === FilterRegions.selectedRegionB?.id,
        );
        if (regionA) areas.push(regionA);
        if (regionB) areas.push(regionB);
        layer.features = createMultiAreaBordersFeature(areas);
        return layer;
      }
    }

    // Если слой инфраструктуры то разместить все границы в слое
    if (FilterMap.layout === EFilterMapLayout.Infrastructure) {
      layer.features = createMultiAreaBordersFeature(Map.allTransportRegions);
    }

    return layer;
  },
  target: setMapFeatures,
});
