// Скаллирует значение от минимального к максимальному по указанному диапазону
export const scaleValue = (
  inputValue: number,
  minInput: number,
  maxInput: number,
  minOutput: number,
  maxOutput: number,
) => {
  inputValue = Math.min(Math.max(inputValue, minInput), maxInput);
  return (
    ((inputValue - minInput) / (maxInput - minInput)) *
      (maxOutput - minOutput) +
    minOutput
  );
};
