import { useUnit } from 'effector-react';
import { useNavigate } from 'react-router-dom';

import { Dialog, DialogTitle, IconButton, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

import { CloseIcon } from '@components/icons';

import { ROUTES } from '@constants/routes';

import { Actions } from '@features/tpu-simulation-model/components/ModalPlanErrors/Actions';
import { Content } from '@features/tpu-simulation-model/components/ModalPlanErrors/Content';
import {
  useContextSimulationModelHandlers,
  useSavePlanCallback,
} from '@features/tpu-simulation-model/hooks';
import {
  $Errors,
  $ErrorsModal,
  $Project,
  $ScenarioId,
} from '@features/tpu-simulation-model/store';
import { ActionBeforeOpenErrorsWindow } from '@features/tpu-simulation-model/types';

export const ModalPlanErrors = () => {
  const navigate = useNavigate();
  const { isSaveResolved, isOpen, isSaveLoading, actionBeforeModalOpen } =
    useUnit($ErrorsModal);
  const $errors = useUnit($Errors);
  const $scenarioId = useUnit($ScenarioId);
  const $project = useUnit($Project);
  const { closePlanErrorsWindow, openObjectForEditByIdFromAnyFloor } =
    useContextSimulationModelHandlers();
  const savePlan = useSavePlanCallback();

  const isPlanWithoutErrors = !$errors?.length;
  const title = isPlanWithoutErrors
    ? 'Ошибок не найдено'
    : 'Найдены ошибки в связях объектов';
  const windowWidth = isPlanWithoutErrors ? 640 : 960;

  const dialogSx: SxProps<Theme> = {
    '& .MuiDialogTitle-root': {
      padding: '24px',
    },
    '& .MuiDialogActions-root': {
      gap: '24px',
      gridTemplateColumns: isSaveResolved ? '1fr' : 'repeat(2, 1fr)',
      padding: '24px',
      '&:not(:first-of-type)': {
        marginLeft: 'unset',
        display: 'flex',
      },
    },
    '& .MuiDialogContent-root': {
      padding: '0 24px 24px',
      justifyContent: 'center',
    },
    '.MuiDialog-paper': {
      maxWidth: `${windowWidth}px`,
      width: `${windowWidth}px`,
    },
  };

  const savePlanAndGoToRoute = (route: string) => async () => {
    if (isSaveResolved) {
      return navigate(route);
    }

    const isSave = await savePlan(true);
    if (isSave) navigate(route);
  };

  const buttonConfig: Record<
    ActionBeforeOpenErrorsWindow,
    {
      onClick: (arg?: boolean) => Promise<void | boolean> | void;
      label: string;
    }
  > = {
    [ActionBeforeOpenErrorsWindow.Save]: {
      onClick: () => savePlan(),
      label: 'Все равно сохранить',
    },
    [ActionBeforeOpenErrorsWindow.GoToPassengerTypesEditor]: {
      onClick: savePlanAndGoToRoute(
        `${ROUTES.TPU_PASSENGER_TYPES}/${$scenarioId}`,
      ),
      label: isSaveResolved
        ? 'Перейти в редактор типов пассажиров'
        : 'Все равно перейти в редактор типов пассажиров',
    },
    [ActionBeforeOpenErrorsWindow.GoToScenarioRegistry]: {
      onClick: savePlanAndGoToRoute(`${ROUTES.TPU_SCENARIOS}/${$project.id}`),
      label: isSaveResolved
        ? 'Перейти в реестр сценариев'
        : 'Все равно перейти в реестр сценариев',
    },
    [ActionBeforeOpenErrorsWindow.GoToTrainSchedule]: {
      onClick: savePlanAndGoToRoute(
        `${ROUTES.TPU_TRAIN_SCHEDULE}/${$scenarioId}`,
      ),
      label: isSaveResolved
        ? 'Перейти в расписание поездов'
        : 'Все равно перейти в расписание поездов',
    },
    [ActionBeforeOpenErrorsWindow.CheckErrors]: {
      onClick: () => {},
      label: '',
    },
  };

  const isActionButtonHidden =
    actionBeforeModalOpen === ActionBeforeOpenErrorsWindow.CheckErrors;
  const isActionButtonSaveAnywayHidden =
    actionBeforeModalOpen === ActionBeforeOpenErrorsWindow.Save &&
    isSaveResolved;

  return (
    <Dialog
      open={isOpen}
      sx={dialogSx}
      transitionDuration={0}
      onClose={closePlanErrorsWindow}
    >
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        onClick={closePlanErrorsWindow}
        color={'primary'}
        sx={{ position: 'absolute', top: 24, right: 24, padding: 0 }}
      >
        <CloseIcon />
      </IconButton>
      <Content
        isPlanSaved={isSaveResolved}
        isLoading={isSaveLoading}
        errors={$errors ?? []}
        onObjectClick={openObjectForEditByIdFromAnyFloor}
      />
      <Actions
        isActionsHidden={isSaveLoading}
        isActionButtonHidden={
          isActionButtonHidden || isActionButtonSaveAnywayHidden
        }
        isCloseButtonHidden={isPlanWithoutErrors || isSaveResolved}
        isPlanSaved={isSaveResolved}
        actionButtonConfig={buttonConfig[actionBeforeModalOpen]}
        onClose={closePlanErrorsWindow}
      />
    </Dialog>
  );
};
