import { SvgIcon, SvgIconProps } from '@mui/material';

export const TicketIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M19.826 11.194H22V19.8087H19.6707C19.4377 20.5072 18.8942 20.7401 18.1954 20.7401H11.1298L7.63586 21.9042C6.70414 22.2147 5.69476 21.749 5.30654 20.8177L0.104393 5.37328C-0.206183 4.36435 0.182039 3.43303 1.19141 3.04498L10.0428 0.0957784C10.9746 -0.214662 11.9839 0.250994 12.2945 1.18232L15.0897 9.40899H18.1178C18.9719 9.40899 19.7483 10.1851 19.826 11.194ZM7.17 20.8177L15.9438 17.8685C16.332 17.7133 16.4096 17.4028 16.2543 17.0148L14.6238 12.1253H11.5957C9.96518 12.1253 9.34403 10.6508 9.88754 9.40899H13.7697L12.9157 6.84787L11.9063 7.1583L11.6733 6.45982L12.6827 6.14938L11.1298 1.64798C11.0522 1.33754 10.664 1.18232 10.3534 1.25993L1.50199 4.20913C1.19141 4.28674 1.03612 4.67479 1.11377 4.98523L2.589 9.25379L3.59837 8.94333L3.83131 9.64184L2.82193 9.95227L6.31591 20.4296C6.4712 20.8177 6.85942 20.8953 7.17 20.8177ZM4.84068 9.25379L4.60775 8.63289L5.85005 8.24484L6.08298 8.94333L4.84068 9.25379ZM7.17 8.55527L6.93707 7.9344L8.17937 7.54635L8.4123 8.24484L7.17 8.55527ZM10.5863 6.84787L10.8193 7.46874L9.57696 7.85679L9.34403 7.1583L10.5863 6.84787Z"
        fill="#FF792E"
      />
    </SvgIcon>
  );
};
