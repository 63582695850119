export const SOC_ECO_TH_2_3 = [
  {
    id: 'nameTPU',
    label: 'Название ТПУ',
  },
  {
    id: 'transportArea',
    label: 'Транспортный район',
  },
  {
    id: 'TPUStatus',
    label: 'Статус ТПУ, существующий / перспективный',
  },
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'passengerTraffic',
    label: 'Пассажиропотоки, тыс. чел.',
  },
];
