import { sample } from 'effector';
import { v4 as uuid } from 'uuid';

import {
  $CreatedObject,
  CreatedObjectDataType,
  IOnCompleteArgs,
  addPointFn,
  cancelCreationFn,
  createBankFx,
  createBankomatFx,
  createBuffetFx,
  createDoorFx,
  createElevatorFx,
  createExitFx,
  createInformationTableFx,
  createKioskFx,
  createLadderFx,
  createOtherFx,
  createPassengerGeneratorFx,
  createPathFx,
  createPlatformFx,
  createRestaurantFx,
  createRoomFx,
  createSecurityCheckpointFx,
  createStoreFx,
  createTicketOfficeFx,
  createTicketPrintingMachineFx,
  createToiletFx,
  createTurnstileFx,
  createVendingMachineFx,
  createWaitingFx,
  createWallFx,
  onCompleteFn,
  removeLastPointFn,
  resetCreatedObjectDataFn,
  setCreatedObjectFn,
} from './createdObject.store';

import { addObjectInListFn } from '@features/tpu-simulation-model/store';
import { resetRulerMeasurementFn } from '@features/tpu-simulation-model/store/ruler';
import {
  EditableObjectType,
  IBank,
  IBankomat,
  IBuffet,
  IDoor,
  IElevator,
  IExit,
  IInformationTable,
  IKiosk,
  ILadder,
  IOther,
  IPassengerGenerator,
  IPath,
  IPlatform,
  IRestaurant,
  IRoom,
  ISecurityCheckpoint,
  IStore,
  ITicketOffice,
  ITicketPrintingMachine,
  IToilet,
  ITurnstile,
  IVendingMachine,
  IWaiting,
  IWall,
  isBankType,
  isBankomatType,
  isBuffetType,
  isDoorType,
  isElevatorType,
  isExitType,
  isInformationTableType,
  isKioskType,
  isLadderType,
  isOtherType,
  isPassengerGeneratorType,
  isPathType,
  isPlatformType,
  isRestaurantType,
  isRoomType,
  isSecurityCheckpointType,
  isStoreType,
  isTicketOfficeType,
  isTicketPrintingMachineType,
  isToiletType,
  isTurnstileType,
  isVendingMachineType,
  isWaitingType,
  isWallType,
} from '@features/tpu-simulation-model/types';
import { getBoundPoint } from '@features/tpu-simulation-model/utils/getBoundPoint';

$CreatedObject
  .reset(resetCreatedObjectDataFn)
  .on(addPointFn, (state, point) => {
    if (!state.type) {
      return console.error('Не выбран редактируемый объект');
    }

    const { points } = state;
    const oldPoints = points ?? [];
    const boundPoint = getBoundPoint(point, oldPoints);

    return {
      ...state,
      points: [...oldPoints, boundPoint],
    };
  })
  .on(setCreatedObjectFn, (_, payload) => {
    return { points: null, type: payload };
  })
  .on(removeLastPointFn, state => {
    return { ...state, points: state.points?.slice(0, -1) ?? null };
  });

sample({
  clock: setCreatedObjectFn,
  target: resetRulerMeasurementFn,
});

sample({
  clock: [cancelCreationFn, addObjectInListFn],
  target: resetCreatedObjectDataFn,
});

sample({
  clock: addPointFn,
  source: $CreatedObject,
  filter: sourceData =>
    sourceData.type !== EditableObjectType.Room &&
    sourceData.type !== EditableObjectType.Wall,
  fn: () => {},
  target: onCompleteFn,
});

// Триггер создания "Стены"
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: createData => !!createData.type && isWallType(createData.type),
  fn: (createData, onComplete) =>
    ({
      ...createData,
      isClosed: onComplete?.isClosed,
    }) as CreatedObjectDataType<EditableObjectType.Wall> & IOnCompleteArgs,
  target: createWallFx,
});

// Триггер создания "Помещения"
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isRoomType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.Room>,
  target: createRoomFx,
});

// Триггер создания "Лестницы"
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isLadderType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.Ladder>,
  target: createLadderFx,
});

// Триггер создания 'Платформы'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isPlatformType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.Platform>,
  target: createPlatformFx,
});

// Триггер создания 'Двери'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isDoorType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.Door>,
  target: createDoorFx,
});

// Триггер создания 'Лифта'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isElevatorType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.Elevator>,
  target: createElevatorFx,
});

// Триггер создания 'Пути'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isPathType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.Path>,
  target: createPathFx,
});

// Триггер создания 'Генератора пассажиров'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isPassengerGeneratorType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.PassengerGenerator>,
  target: createPassengerGeneratorFx,
});

// Триггер создания 'Выхода'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isExitType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.Exit>,
  target: createExitFx,
});

// Триггер создания 'Билетной кассы'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isTicketOfficeType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.TicketOffice>,
  target: createTicketOfficeFx,
});

// Триггер создания 'Билетопечатающий аппарат (БПА)'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isTicketPrintingMachineType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.TicketPrintingMachine>,
  target: createTicketPrintingMachineFx,
});

// Триггер создания 'Турникет'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isTurnstileType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.Turnstile>,
  target: createTurnstileFx,
});

// Триггер создания 'Пункт досмотра'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isSecurityCheckpointType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.SecurityCheckpoint>,
  target: createSecurityCheckpointFx,
});

// Триггер создания 'Информационное табло'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isInformationTableType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.InformationTable>,
  target: createInformationTableFx,
});

// Триггер создания 'Туалет'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isToiletType(type),
  fn: (createData, onComplete) =>
    ({
      ...createData,
      fillsRoom: onComplete?.fillsRoom,
    }) as CreatedObjectDataType<EditableObjectType.Toilet> & IOnCompleteArgs,
  target: createToiletFx,
});

// Триггер создания 'Зал ожидания'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isWaitingType(type),
  fn: (createData, onComplete) =>
    ({
      ...createData,
      fillsRoom: onComplete?.fillsRoom,
    }) as CreatedObjectDataType<EditableObjectType.Waiting> & IOnCompleteArgs,
  target: createWaitingFx,
});

// Триггер создания 'Ресторан'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isRestaurantType(type),
  fn: (createData, onComplete) =>
    ({
      ...createData,
      fillsRoom: onComplete?.fillsRoom,
    }) as CreatedObjectDataType<EditableObjectType.Restaurant> &
      IOnCompleteArgs,
  target: createRestaurantFx,
});

// Триггер создания 'Буфет'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isBuffetType(type),
  fn: (createData, onComplete) =>
    ({
      ...createData,
      fillsRoom: onComplete?.fillsRoom,
    }) as CreatedObjectDataType<EditableObjectType.Buffet> & IOnCompleteArgs,
  target: createBuffetFx,
});

// Триггер создания 'Вендинговый аппарат'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isVendingMachineType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.VendingMachine>,
  target: createVendingMachineFx,
});

// Триггер создания 'Магазин'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isStoreType(type),
  fn: (createData, onComplete) =>
    ({
      ...createData,
      fillsRoom: onComplete?.fillsRoom,
    }) as CreatedObjectDataType<EditableObjectType.Store> & IOnCompleteArgs,
  target: createStoreFx,
});

// Триггер создания 'Киоск'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isKioskType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.Kiosk>,
  target: createKioskFx,
});

// Триггер создания 'Банк'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isBankType(type),
  fn: (createData, onComplete) =>
    ({
      ...createData,
      fillsRoom: onComplete?.fillsRoom,
    }) as CreatedObjectDataType<EditableObjectType.Bank> & IOnCompleteArgs,
  target: createBankFx,
});

// Триггер создания 'Банкомат'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isBankomatType(type),
  fn: sourceData =>
    sourceData as CreatedObjectDataType<EditableObjectType.Bankomat>,
  target: createBankomatFx,
});

// Триггер создания 'Прочее'
sample({
  clock: onCompleteFn,
  source: $CreatedObject,
  filter: ({ type }) => !!type && isOtherType(type),
  fn: (createData, onComplete) =>
    ({
      ...createData,
      fillsRoom: onComplete?.fillsRoom,
    }) as CreatedObjectDataType<EditableObjectType.Other> & IOnCompleteArgs,
  target: createOtherFx,
});

createRoomFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;

  const data: IRoom = {
    points,
    name: '',
    walls: [],
    uuid: uuid(),
    type,
  };

  return data;
});

createWallFx.use(state => {
  const { points, type, isClosed } = state;
  if (!points || !type) return;

  const data: IWall = {
    isClosed: !!isClosed,
    points,
    name: '',
    uuid: uuid(),
    type,
  };

  return data;
});

createLadderFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;

  const data: ILadder = {
    point: points[0],
    uuid: uuid(),
    type,
    name: '',
    room: '',
    connectedFloors: [],
    connectedObjects: [],
    pedestrianSpeed: 0.8,
    status: 'open',
    direction: 'inAndOut',
    openFrom: '03:00:00',
    openUntil: '01:00:00',
    width: 44,
    height: 44,
    length: 0,
    rotation: 0,
    floorId: '',
  };

  return data;
});

createPlatformFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;

  const data: IPlatform = {
    point: points[0],
    uuid: uuid(),
    type,
    walls: [],
    platform: 0,
    width: 44,
    height: 44,
    rotation: 0,
  };

  return data;
});

createDoorFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;

  const data: IDoor = {
    name: '',
    status: 'open',
    firstRoom: '',
    secondRoom: '',
    direction: 'inAndOut',
    openFrom: '03:00:00',
    openUntil: '01:00:00',
    point: points[0],
    uuid: uuid(),
    type,
    width: 24,
    height: 24,
    rotation: 0,
  };

  return data;
});

createElevatorFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;

  const data: IElevator = {
    point: points[0],
    uuid: uuid(),
    type,
    name: '',
    room: '',
    floorId: '',
    connectedFloors: [],
    connectedObjects: [],
    direction: 'inAndOut',
    openFrom: '03:00:00',
    openUntil: '01:00:00',
    status: 'open',
    waitingZone: 'exist',
    width: 24,
    height: 24,
    rotation: 0,
  };

  return data;
});

createPathFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;

  const data: IPath = {
    point: points[0],
    uuid: uuid(),
    type,
    way: 0,
    platforms: [],
    rotation: 0,
    width: 24,
    height: 24,
  };

  return data;
});

createPassengerGeneratorFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;

  const data: IPassengerGenerator = {
    point: points[0],
    uuid: uuid(),
    name: '',
    type,
    entranceId: '',
    share: 0,
    status: 'open',
    openFrom: '03:00:00',
    openUntil: '01:00:00',
    rotation: 0,
    width: 24,
    height: 24,
  };

  return data;
});

createExitFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;

  const data: IExit = {
    point: points[0],
    uuid: uuid(),
    name: '',
    type,
    exitId: '',
    share: 0,
    status: 'open',
    openFrom: '03:00:00',
    openUntil: '01:00:00',
    rotation: 0,
    width: 24,
    height: 24,
  };

  return data;
});

createTicketOfficeFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;

  const data: ITicketOffice = {
    point: points[0],
    uuid: uuid(),
    type,
    name: '',
    room: '',
    numberOfWindowsQueues: 0,
    passengersType: 'longDistance',
    averageServiceTime: 0,
    failuresProbability: 0,
    status: 'open',
    waitingZone: 'notExist',
    openFrom: '03:00:00',
    openUntil: '01:00:00',
    rotation: 0,
    width: 24,
    height: 24,
  };

  return data;
});
createTicketPrintingMachineFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;

  const data: ITicketPrintingMachine = {
    point: points[0],
    uuid: uuid(),
    type,
    name: '',
    room: '',
    averageServiceTime: 0,
    failuresProbability: 0,
    status: 'open',
    waitingZone: 'notExist',
    openFrom: '03:00:00',
    openUntil: '01:00:00',
    rotation: 0,
    width: 24,
    height: 24,
  };

  return data;
});

createTurnstileFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;
  const data: ITurnstile = {
    point: points[0],
    uuid: uuid(),
    turnstileId: '',
    name: '',
    firstRoom: '',
    secondRoom: '',
    status: 'open',
    waitingZone: 'notExist',
    failuresProbability: 0,
    openFrom: '03:00:00',
    openUntil: '01:00:00',
    direction: 'in',
    throughput: 0,
    type,
    rotation: 0,
    width: 24,
    height: 24,
  };

  return data;
});

createSecurityCheckpointFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;
  const data: ISecurityCheckpoint = {
    point: points[0],
    uuid: uuid(),
    name: '',
    firstRoom: '',
    secondRoom: '',
    direction: 'in',
    throughput: 0,
    failuresProbability: 0,
    status: 'open',
    waitingZone: 'exist',
    openFrom: '03:00:00',
    openUntil: '01:00:00',
    type,
    rotation: 0,
    width: 24,
    height: 24,
  };

  return data;
});

createInformationTableFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;
  const data: IInformationTable = {
    point: points[0],
    uuid: uuid(),
    name: '',
    room: '',
    averageServiceTime: 0,
    status: 'open',
    waitingZone: 'exist',
    type,
    rotation: 0,
    width: 24,
    height: 24,
  };

  return data;
});

createToiletFx.use(state => {
  const { points, type, fillsRoom } = state;
  if (!points || !type) return;
  const data: IToilet = {
    point: points[0],
    uuid: uuid(),
    name: '',
    room: '',
    servedAtSameTime: 0,
    status: 'open',
    waitingZone: 'exist',
    openFrom: '03:00:00',
    openUntil: '01:00:00',
    type,
    rotation: 0,
    width: 24,
    height: 24,
    fillsRoom: fillsRoom ? fillsRoom : 'notFills',
  };

  return data;
});

createWaitingFx.use(state => {
  const { points, type, fillsRoom } = state;
  if (!points || !type) return;
  const data: IWaiting = {
    point: points[0],
    uuid: uuid(),
    name: '',
    room: '',
    servedAtSameTime: 0,
    averageRevenue: 0,
    status: 'open',
    waitingZone: 'exist',
    openFrom: '03:00:00',
    openUntil: '01:00:00',
    type,
    rotation: 0,
    width: 24,
    height: 24,
    fillsRoom: fillsRoom ? fillsRoom : 'notFills',
  };

  return data;
});

createRestaurantFx.use(state => {
  const { points, type, fillsRoom } = state;
  if (!points || !type) return;

  const data: IRestaurant = {
    point: points[0],
    uuid: uuid(),
    type,
    name: '',
    room: '',
    servedAtSameTime: 0,
    averageServiceTime: 0,
    averageRevenue: 0,
    averageCostPerDay: 0,
    bounceRate: 0,
    status: 'open',
    openFrom: '06:00:00',
    openUntil: '22:00:00',
    rotation: 0,
    width: 24,
    height: 24,
    fillsRoom: fillsRoom ? fillsRoom : 'notFills',
  };

  return data;
});

createBuffetFx.use(state => {
  const { points, type, fillsRoom } = state;
  if (!points || !type) return;

  const data: IBuffet = {
    point: points[0],
    uuid: uuid(),
    type,
    name: '',
    room: '',
    servedAtSameTime: 0,
    averageServiceTime: 0,
    averageRevenue: 0,
    averageCostPerDay: 0,
    bounceRate: 0,
    status: 'open',
    openFrom: '06:00:00',
    openUntil: '22:00:00',
    waitingZone: 'exist',
    rotation: 0,
    width: 24,
    height: 24,
    fillsRoom: fillsRoom ? fillsRoom : 'notFills',
  };

  return data;
});

createVendingMachineFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;

  const data: IVendingMachine = {
    point: points[0],
    uuid: uuid(),
    type,
    name: '',
    room: '',
    averageServiceTime: 0,
    averageRevenue: 0,
    averageCostPerDay: 0,
    bounceRate: 0,
    status: 'open',
    openFrom: '03:00:00',
    openUntil: '01:00:00',
    waitingZone: 'exist',
    rotation: 0,
    width: 24,
    height: 24,
  };

  return data;
});

createStoreFx.use(state => {
  const { points, type, fillsRoom } = state;
  if (!points || !type) return;

  const data: IStore = {
    point: points[0],
    uuid: uuid(),
    type,
    name: '',
    room: '',
    servedAtSameTime: 0,
    averageServiceTime: 0,
    averageRevenue: 0,
    averageCostPerDay: 0,
    bounceRate: 0,
    status: 'open',
    openFrom: '06:00:00',
    openUntil: '22:00:00',
    rotation: 0,
    width: 24,
    height: 24,
    fillsRoom: fillsRoom ? fillsRoom : 'notFills',
  };

  return data;
});

createKioskFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;

  const data: IKiosk = {
    point: points[0],
    uuid: uuid(),
    type,
    name: '',
    room: '',
    servedAtSameTime: 0,
    averageServiceTime: 0,
    averageRevenue: 0,
    averageCostPerDay: 0,
    bounceRate: 0,
    status: 'open',
    openFrom: '06:00:00',
    openUntil: '22:00:00',
    waitingZone: 'exist',
    rotation: 0,
    width: 24,
    height: 24,
  };

  return data;
});

createBankFx.use(state => {
  const { points, type, fillsRoom } = state;
  if (!points || !type) return;

  const data: IBank = {
    point: points[0],
    uuid: uuid(),
    type,
    name: '',
    room: '',
    servedAtSameTime: 0,
    averageServiceTime: 0,
    averageRevenue: 0,
    averageCostPerDay: 0,
    bounceRate: 0,
    status: 'open',
    openFrom: '06:00:00',
    openUntil: '22:00:00',
    rotation: 0,
    width: 24,
    height: 24,
    fillsRoom: fillsRoom ? fillsRoom : 'notFills',
  };

  return data;
});

createBankomatFx.use(state => {
  const { points, type } = state;
  if (!points || !type) return;

  const data: IBankomat = {
    point: points[0],
    uuid: uuid(),
    type,
    name: '',
    room: '',
    averageServiceTime: 0,
    averageRevenue: 0,
    averageCostPerDay: 0,
    bounceRate: 0,
    status: 'open',
    openFrom: '06:00:00',
    openUntil: '22:00:00',
    waitingZone: 'exist',
    rotation: 0,
    width: 24,
    height: 24,
  };

  return data;
});

createOtherFx.use(state => {
  const { points, type, fillsRoom } = state;
  if (!points || !type) return;

  const data: IOther = {
    point: points[0],
    uuid: uuid(),
    type,
    name: '',
    room: '',
    servedAtSameTime: 0,
    averageServiceTime: 0,
    averageRevenue: 0,
    averageCostPerDay: 0,
    bounceRate: 0,
    status: 'open',
    openFrom: '06:00:00',
    openUntil: '22:00:00',
    rotation: 0,
    width: 24,
    height: 24,
    fillsRoom: fillsRoom ? fillsRoom : 'notFills',
  };

  return data;
});
