import { Icon, Style } from 'ol/style';

import PointASVG from '@components/icons/PointA.svg';
import PointBSVG from '@components/icons/PointB.svg';

import { VectorStyleProps } from '@utils/map/styles/types';

export const ptPointsABVectorStyle = (props: VectorStyleProps) => {
  const { feature } = props;
  const direction = feature!.get('direction') as 'from' | 'to';
  return new Style({
    image: new Icon({
      src: direction === 'from' ? PointASVG : PointBSVG,
    }),
  });
};
