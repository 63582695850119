import { createEvent, createStore } from 'effector';

import {
  IRuler,
  ListOfRulersDataType,
  SimulationModelPlanData,
} from '@features/tpu-simulation-model/types';

export const $ListOfRulers = createStore<ListOfRulersDataType | null>(null);

export const updateRulerDataByFloorIdFn = createEvent<{
  id: string;
  ruler: IRuler | null;
}>();

export const setListOfRulersFn = createEvent<SimulationModelPlanData | null>();

export const createListOfRulersFn = createEvent<ListOfRulersDataType | null>();

export const resetListOfRulersFn = createEvent();
