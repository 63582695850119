import {
  TableHead as MuiTableHead,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import { agComparisonTPUTableHeadCells } from './configs/headCells';

export const TableComparisonTPUHead = () => {
  return (
    <MuiTableHead
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}
    >
      <TableRow>
        {agComparisonTPUTableHeadCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{
              fontSize: theme => theme.typography.pxToRem(12),
              bgcolor: theme => theme.palette.border.divider,
              outline: '1px solid rgba(224, 224, 224, 1)',
              outlineOffset: '-1px',
              border: 0,
              '&:first-of-type': {
                borderTopLeftRadius: '6px',
              },
              '&:last-of-type': {
                borderTopRightRadius: '6px',
              },
            }}
          >
            {Array.isArray(headCell.label)
              ? headCell.label.map((label, i) => (
                  <Typography
                    key={i}
                    fontSize={theme => theme.typography.pxToRem(12)}
                  >
                    {label}
                  </Typography>
                ))
              : headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};
