export enum EMapFeatureLayout {
  // Паспоток
  highway = 'highway',
  railway = 'railway',
  areaBorder = 'areaBorder',
  directionHighway = 'directionHighway',
  directionRailway = 'directionRailway',
  directionAirline = 'directionAirline',
  busStation = 'busStation',
  railwayStation = 'railwayStation',
  airport = 'airport',
  pointsAB = 'pointsAB',
  customRailwayStation = 'customRailwayStation',
  customRailwayGraph = 'customRailwayGraph',
  ptSearchInfra = 'ptSearchInfra',
  ////////////////////////////////////////
  // Мусор
  pointA = 'pointA', // deprecated
  pointB = 'pointB', // deprecated
  plannedTileHighway = 'plannedTileHighway',
  plannedHighway = 'plannedHighway',

  plannedTileRailway = 'plannedTileRailway',
  plannedRailway = 'plannedRailway',

  avia = 'avia',

  airline = 'airline',
  auto = 'auto',
  intermediateCorrespondents = 'intermediateCorrespondents',
  multimodal = 'multimodal',
  // Агло
  ////////////////////////////////////////
  tpu = 'tpu',
  tpuGraphs = 'tpuGraphs',
  tpuDash = 'tpuDash',
  graphs = 'graphs',
  transportDistricts = 'transportDistricts',
  transportZones = 'transportZones',
  transportFlowDistricts = 'transportFlowDistricts',
  agRailwayStations = 'agRailwayStations',
  graphAuto = 'graphAuto',
  graphBus = 'graphBus',
  graphTrolleybus = 'graphTrolleybus',
  graphTram = 'graphTram',
  graphMetro = 'graphMetro',
  graphSuburbanRailway = 'graphSuburbanRailway',
  graphRopeWay = 'graphRopeWay',
  graphWaterWay = 'graphWaterWay',
  graphFunicular = 'graphFunicular',
  graphMonoRailWay = 'graphMonoRailWay',
  graphPedestrain = 'graphPedestrain',
  graphAvia = 'graphAvia',
  areaBorderPassengerFlow = 'areaBorderPassengerFlow',
  pathPassengerFlow = 'pathPassengerFlow',
  agSearchInfra = 'agSearchInfra',
  // 3я Страница дашбордов агломерации
  animalPassage = 'animalPassage',
  animalProtectionVibration = 'animalProtectionVibration',
  changingRoutes = 'changingRoutes',
  culverts = 'culverts',
  protectionVibration = 'protectionVibration',
  safeCrossing = 'safeCrossing',
  stationAccessibility = 'stationAccessibility',
  allGraphsAgglomeration = 'allGraphsAgglomeration',
  walkingArea = 'walkingArea',
  trafficCongestion = 'trafficCongestion',
}

export enum EMapLayoutTypes {
  tile = 'tile',
  vector = 'vector',
}

export enum EMapSelectFeaturesModes {
  segment = 'segment',
  path = 'path',
}

export enum EMapMetaKeys {
  point = 'point',
  selectedFeatures = 'selectedFeatures',
}

export enum EMapLayerMetaKeys {
  layout = 'layout',
}

export enum EMapFeatureMetaKeys {
  olId = 'ol_uid',
  layout = 'layout',
  isStart = 'isStart',
}

export enum EFilterMapPoint {
  A = 'A',
  B = 'B',
}
