import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import Grid from '@mui/material/Unstable_Grid2';

import { SettlementRegister } from '@features/pt-constructor/list';
import {
  $Calculations,
  fetchLongStatusesFx,
} from '@features/pt-constructor/store';

export const SettlementRegisterContainer = () => {
  const { userScenarios, isNewCalculationScenario } = useUnit($Calculations);

  useEffect(() => {
    const timer = setInterval(() => {
      fetchLongStatusesFx();
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  });

  return (
    <Grid xs={4}>
      <SettlementRegister
        userCalculations={userScenarios}
        isNewRegister={isNewCalculationScenario}
      />
    </Grid>
  );
};
