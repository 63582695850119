import { InfrastructureSlot } from './Infrastructure.slot';
import { PassengerFlowsSlot } from './PassengerFlows.slot';
import { RouteNetworkSlot } from './RouteNetwork.slot';
import { BaseFilterSlotProps, EFilterMapLayout } from './interface';

type FilterSlotProps = {
  layout: EFilterMapLayout;
} & BaseFilterSlotProps;

export const FilterSlot = (props: FilterSlotProps) => {
  const { layout, setFields, fields, hideFields } = props;

  const Component = (() => {
    switch (layout) {
      case EFilterMapLayout.Infrastructure:
        return InfrastructureSlot;
      case EFilterMapLayout.RouteNetwork:
        return RouteNetworkSlot;
      case EFilterMapLayout.PassengerFlows:
        return PassengerFlowsSlot;
    }
  })();

  return (
    <Component
      fields={fields}
      hideFields={hideFields}
      setFields={setFields}
    />
  );
};
