import { useUnit } from 'effector-react';

import { BlockFileUpload } from '@features/ag-projects-registry/components/BlockFileUpload';
import { $Files, EFileTypes } from '@features/ag-projects-registry/store/files';

export const UploadFilesBlock = () => {
  const $files = useUnit($Files);

  return (
    <>
      <BlockFileUpload
        title="*Граф"
        stateFile={$files.graph}
        keyFile={EFileTypes.graph}
        format=".geojson"
      />

      <BlockFileUpload
        title="*ЖД станции"
        stateFile={$files.graphStops}
        keyFile={EFileTypes.graphStops}
        format=".geojson"
      />

      <BlockFileUpload
        title="*Транспортные районы"
        stateFile={$files.transportAreas}
        keyFile={EFileTypes.transportAreas}
        format=".geojson"
      />

      <BlockFileUpload
        title="*Центры транспортных районов"
        stateFile={$files.centersOfTransportAreas}
        keyFile={EFileTypes.centersOfTransportAreas}
        format=".geojson"
      />

      <BlockFileUpload
        title="*Транспортные зоны"
        stateFile={$files.transportZones}
        keyFile={EFileTypes.transportZones}
        format=".geojson"
      />

      <BlockFileUpload
        title="*Центры транспортных зон"
        stateFile={$files.centersOfTransportZones}
        keyFile={EFileTypes.centersOfTransportZones}
        format=".geojson"
      />

      <BlockFileUpload
        title="*Показатели транспортных районов и параметры перемещения"
        stateFile={$files.indicatorsOfTransportAreasAndTravelParameters}
        keyFile={EFileTypes.indicatorsOfTransportAreasAndTravelParameters}
        format=".xlsx"
      />

      <BlockFileUpload
        title="*Данные по пригородным ЖД маршрутам"
        stateFile={$files.dataOnSuburbanRailwayRoutes}
        keyFile={EFileTypes.dataOnSuburbanRailwayRoutes}
        format=".xlsx"
      />
    </>
  );
};
