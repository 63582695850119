import { useUnit } from 'effector-react';

import { Box, IconButton } from '@mui/material';

import { BasketIcon } from '@components/icons';

import {
  $Editor,
  EditedObjectDataType,
} from '@features/tpu-simulation-model/store';
import { EditableObjectType } from '@features/tpu-simulation-model/types';

interface InteractionWindowProps extends EditedObjectDataType {
  onDelete: () => void;
}

const objectNames: Record<EditableObjectType, string> = {
  [EditableObjectType.Room]: 'Помещение',
  [EditableObjectType.Path]: 'Путь',
  [EditableObjectType.PassengerGenerator]: 'Генератор пассажиров (вход)',
  [EditableObjectType.Door]: 'Дверь',
  [EditableObjectType.Platform]: 'Платформа',
  [EditableObjectType.Elevator]: 'Лифт',
  [EditableObjectType.Wall]: 'Стена',
  [EditableObjectType.Ladder]: 'Лестница',
  [EditableObjectType.Exit]: 'Выход',
  [EditableObjectType.Toilet]: 'Туалет',
  [EditableObjectType.Waiting]: 'Зал ожидания',
  [EditableObjectType.Turnstile]: 'Турникет',
  [EditableObjectType.SecurityCheckpoint]: 'Пункт досмотра',
  [EditableObjectType.InformationTable]: 'Информационное табло',
  [EditableObjectType.TicketPrintingMachine]: 'Билетопечатающий аппарат (БПА)',
  [EditableObjectType.TicketOffice]: 'Билетная касса',
  [EditableObjectType.Restaurant]: 'Ресторан',
  [EditableObjectType.Buffet]: 'Буфет',
  [EditableObjectType.VendingMachine]: 'Вендинговый аппарат',
  [EditableObjectType.Store]: 'Магазин',
  [EditableObjectType.Kiosk]: 'Киоск',
  [EditableObjectType.Bank]: 'Банк',
  [EditableObjectType.Bankomat]: 'Банкомат',
  [EditableObjectType.Other]: 'Прочее',
};

export const InteractionWindow = ({
  interactionWindowPoint,
  data,
  onDelete,
}: InteractionWindowProps) => {
  const { positionX, scale, positionY } = useUnit($Editor);

  if (!data || !interactionWindowPoint) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        backgroundColor: theme => theme.palette.background.paper,
        zIndex: 1,
        boxShadow: '0px 2px 4px 0px #0000001A',
        padding: '12px',
        borderRadius: '8px',
        pointerEvents: 'all',
        transform: `translate(calc(-50% + ${
          positionX + interactionWindowPoint.x * scale
        }px), ${positionY + interactionWindowPoint.y * scale + 12}px)`,
      }}
    >
      {objectNames[data.type]}
      <IconButton onClick={onDelete}>
        <BasketIcon />
      </IconButton>
    </Box>
  );
};
