import { createApi, createStore } from 'effector';

import { SearchOption } from '@features/pt-forecast-new/stores/lists/searchOptions';

const initState: SearchInfraStore = {
  selectedOption: null,
  features: {
    airport: null,
    busStation: null,
    railwayStation: null,
  },
};

export const $SearchInfra = createStore<SearchInfraStore>(initState);

export const SearchInfraApi = createApi($SearchInfra, {
  setSelectedOption: (store, payload: SearchOption) => ({
    ...store,
    selectedOption: payload,
  }),
  setGeometry: (store, payload: SearchInfraStore['features']) => ({
    ...store,
    features: payload,
  }),
  reset: () => initState,
});

export type SearchInfraStore = {
  selectedOption: SearchOption | null;
  features: {
    airport: {
      geometry: number[];
      id: string;
    } | null;
    busStation: {
      geometry: number[];
      id: string;
    } | null;
    railwayStation: {
      geometry: number[];
      id: string;
    } | null;
  };
};
