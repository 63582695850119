import {
  IMutation,
  IMutationCreateGraphArgs,
  IMutationCreateTpuArgs,
  IMutationSaveCustomInfrastructureArgs,
  IMutationScenarioComputeArgs,
  IMutationUpdateCustomInfrastructureArgs,
  IMutationUpdateGraphArgs,
  IMutationUpdateTpuArgs,
  IQuery,
  IQueryGetClosestInstancePointByPointArgs,
  IQueryGetGraphInstanceByIdArgs,
  IQueryGetRailwayStationInstanceByIdArgs,
  IQueryGetScenarioTpuArgs,
} from '@api/gql/ag-types';
import { createApi, createEffect, createEvent, createStore } from 'effector';

import { EVehicleAg } from '@features/ag-forecast/constants/EVehicleAg';

const initState: EditorMapStore = {
  tpus: [],
  graphs: [],
  drawGraph: null,
  selectedTpu: null,
  selectedTpuGraph: null,
};

export const $EditorMap = createStore<EditorMapStore>(initState);

export const EditorMapApi = createApi($EditorMap, {
  addTpu: (store, payload: TpuItem) => ({
    ...store,
    tpus: [...store.tpus, payload],
  }),
  addGraph: (store, payload: TpuGraphItem) => ({
    ...store,
    graphs: [...(store.graphs || []), payload],
    drawGraph: null,
  }),
  setStartPoint: (store, payload: SetStartPointPayload | null) =>
    !payload
      ? store
      : {
          ...store,
          drawGraph: {
            startPointId: payload.startPointId,
            nodes: [payload.node],
          },
        },
  addGraphNode: (store, payload: number[] | null) =>
    !store.drawGraph || !payload
      ? store
      : {
          ...store,
          drawGraph: {
            ...store.drawGraph,
            nodes: [...(store.drawGraph?.nodes || []), payload],
          },
        },
  clearDrawGraph: store => ({
    ...store,
    drawGraph: null,
  }),
  selectTpu: (store, payload: string | null) => ({
    ...store,
    selectedTpu: payload,
  }),
  selectTpuGraph: (store, payload: string | null) => ({
    ...store,
    selectedTpuGraph: payload,
  }),
  updateSelectedTpuGraphParams: (store, payload: Partial<TpuGraphItem>) =>
    !store.selectedTpuGraph
      ? store
      : {
          ...store,
          graphs: store.graphs.map(graph =>
            graph.id === store.selectedTpuGraph
              ? {
                  ...graph,
                  ...payload,
                  isUpdated: true,
                }
              : graph,
          ),
        },
  updateSelectedTpuParams: (store, payload: Partial<TpuItem>) =>
    !store.selectedTpu
      ? store
      : {
          ...store,
          tpus: store.tpus.map(tpu =>
            tpu.id === store.selectedTpu
              ? {
                  ...tpu,
                  ...payload,
                  isUpdated: true,
                }
              : tpu,
          ),
        },
  setInfrastructure: (
    store,
    payload: Pick<EditorMapStore, 'graphs' | 'tpus'>,
  ) => ({
    ...store,
    ...payload,
  }),
  removeGraph: (store, payload: string) => ({
    ...store,
    graphs: store.graphs.map(graph =>
      payload !== graph.id
        ? graph
        : {
            ...graph,
            isUpdated: true,
            delete: true,
          },
    ),
  }),
  removeTpu: (store, payload: string) => ({
    ...store,
    tpus: store.tpus.map(tpu =>
      payload !== tpu.id
        ? tpu
        : {
            ...tpu,
            isUpdated: true,
            delete: true,
          },
    ),
    graphs: store.graphs.map(graph =>
      payload !== graph.tpuId
        ? graph
        : {
            ...graph,
            isUpdated: true,
            delete: true,
          },
    ),
  }),
  reset: () => initState,
});

export const updateInfrastructure = createEvent();

export const saveTpuInfrastructure = createEvent();
export const fetchTpuInfrastructure = createEvent();
export const runComputeScenario = createEvent();

// Эффект создания тпу
export const createTpuFx = createEffect<
  IMutationCreateTpuArgs,
  IMutation['createTpu']
>();

// Эффект создания графа ТПУ
export const createGraphFx = createEffect<
  IMutationCreateGraphArgs,
  IMutation['createGraph']
>();

// Эффект обновление параметров ТПУ
export const updateTpuFx = createEffect<
  Partial<IMutationUpdateTpuArgs>,
  IMutation['updateTpu']
>();

// Эффект обновления параметров графа тпу
export const updateGraphFx = createEffect<
  IMutationUpdateGraphArgs,
  IMutation['updateGraph']
>();

export const updateInfrastructureFx = createEffect<
  IMutationUpdateCustomInfrastructureArgs,
  IMutation['updateCustomInfrastructure']
>();

export const getTpuFx = createEffect<
  IQueryGetRailwayStationInstanceByIdArgs,
  IQuery['getRailwayStationInstanceById']
>();

export const getTpuGraphFx = createEffect<
  IQueryGetGraphInstanceByIdArgs,
  IQuery['getGraphInstanceById']
>();

export const getScenarioTpuInfrastructureFx = createEffect<
  IQueryGetScenarioTpuArgs,
  IQuery['getScenarioTpu']
>();

export const saveInfrastructureFx = createEffect<
  IMutationSaveCustomInfrastructureArgs,
  IMutation['saveCustomInfrastructure']
>();

export const runComputeScenarioFx = createEffect<
  IMutationScenarioComputeArgs,
  IMutation['scenarioCompute']
>();

export const getClosestInstancePointByPointFx = createEffect<
  IQueryGetClosestInstancePointByPointArgs,
  IQuery['getClosestInstancePointByPoint']
>();

export type SetStartPointPayload = {
  startPointId: string;
  node: number[];
};

export type TpuItem = {
  id: string;
  name: string;
  capacity: number;
  floorsNumber: number;
  parentTpuId: string | null;
  geometry: { lon: number; lat: number };
  isSaved: boolean;
  isUpdated: boolean;
  delete: boolean;
};

export type TpuGraphItem = {
  id: string;
  name: string;
  tpuId: string;
  geometry: Array<{ lon: number; lat: number }>;
  cost: number;
  laneNum: number;
  parkingCo: number;
  separated: string;
  year: number;
  zone: number;
  oneway: boolean;
  freeSpeed: number;
  frequencyWeekdays: number;
  frequencyWeekend: number;
  capacity: number;
  capacityHour: number;
  category: string;
  vehicles: EVehicleAg[];
  isSaved: boolean;
  isUpdated: boolean;
  delete: boolean;
};

type DrawGraphState = {
  startPointId: string;
  nodes: number[][];
};

export type EditorMapStore = {
  tpus: TpuItem[];
  graphs: TpuGraphItem[];
  drawGraph: DrawGraphState | null;
  selectedTpu: string | null;
  selectedTpuGraph: string | null;
};
