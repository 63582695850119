export const debounced = (cb: () => void) => {
  let timeout: NodeJS.Timeout | null = null;

  const func = () => {
    timeout && clearTimeout(timeout);
    timeout = setTimeout(cb, 500);
  };

  return func;
};
