import { ReactNode } from 'react';

import { Box, SxProps, Theme, Typography } from '@mui/material';

type BoxDataProps = {
  sx?: SxProps<Theme>;
  name: string;
  value?: string | number | ReactNode;
  icon?: ReactNode;
};

export const BoxData = (props: BoxDataProps) => {
  const { sx, name, value, icon } = props;
  return (
    <Box
      sx={{
        gap: 1,
        ...sx,
      }}
    >
      {icon}

      <Typography variant="subtitle2">{name}</Typography>

      <Box sx={{ fontSize: theme => theme.typography.pxToRem(14) }}>
        {value}
      </Box>
    </Box>
  );
};
