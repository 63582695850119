import { alpha, styled } from '@mui/material';

export interface CircleIconProps {
  color: 'trainPlanned' | 'auto' | 'transportAreaInspectorBoundaries';
}

export const CircleIcon = styled('div')<CircleIconProps>(
  ({ theme, color }) => ({
    border: '1px solid',
    borderColor: theme.palette.customs[color],
    borderRadius: '50%',
    width: theme.typography.pxToRem(12),
    height: theme.typography.pxToRem(12),
    backgroundColor: alpha(theme.palette.customs[color], 0.3),
    justifySelf: 'center',
  }),
);
