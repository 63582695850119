import { apiClient } from '@api/client';
import { IQuery, IQueryGetProjectVehiclesArgs } from '@api/gql/ag-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { AGGLOMERATE_CLIENT_NAME } from '@constants/api';

import {
  VehicleType,
  VehiclesApi,
  getVehiclesFx,
} from '@features/ag-project/stores/lists/vehicles/store';

// Записать в стор полученные виды транспорта
sample({
  clock: getVehiclesFx.done,
  fn: payload => {
    return payload.result?.map(item => ({
      id: item?.id,
      name: item?.name,
      maxSpeed: item?.maxSpeed,
      vehicleId: item?.vehicleId,
    })) as VehicleType[];
  },
  target: VehiclesApi.setVehicles,
});

getVehiclesFx.use(async params => {
  const response = await apiClient.query<IQuery, IQueryGetProjectVehiclesArgs>({
    query: gql`
      query QueryGetVehicleList($projectId: String!) {
        getProjectVehicles(projectId: $projectId) {
          id
          name
          vehicleId
          maxSpeed
        }
      }
    `,
    variables: {
      projectId: params.projectId,
    },
    context: {
      clientName: AGGLOMERATE_CLIENT_NAME,
    },
  });

  return response.data.getProjectVehicles;
});
