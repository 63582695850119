import { sample } from 'effector';

import {
  resetCreatedObjectDataFn,
  rulerLengthSetterFn,
  setIsOpenNotificationFn,
  showNotificationFn,
} from '@features/tpu-simulation-model/store';
import {
  $Floors,
  getFloorsInfoFx,
} from '@features/tpu-simulation-model/store/floors';
import {
  $ListOfRulers,
  createListOfRulersFn,
  resetListOfRulersFn,
  setListOfRulersFn,
  updateRulerDataByFloorIdFn,
} from '@features/tpu-simulation-model/store/ruler/listOfRulers.store';
import {
  $RulerTool,
  DEFAULT_STATE,
  addRulerPointFn,
  getCurrentFloorRulerDataFn,
  resetRulerFn,
  resetRulerMeasurementFn,
  startRulerMeasurementFn,
} from '@features/tpu-simulation-model/store/ruler/rulerTool.store';
import { ListOfRulersDataType } from '@features/tpu-simulation-model/types';

const setRulerLengthFn = sample({
  clock: rulerLengthSetterFn,
  source: $Floors,
  filter: (sourceData, payload) => !!(sourceData.floorsInfo.length && payload),
  fn: (_, payload) => payload,
});

$RulerTool
  .reset(resetRulerFn)
  .on(resetRulerMeasurementFn, state => {
    return {
      ...DEFAULT_STATE,
      px: state.px,
      meters: state.meters,
    };
  })
  .on(startRulerMeasurementFn, state => {
    return { ...state, isScaleBeingMeasured: true };
  })
  .on(addRulerPointFn, (state, payload) => {
    const { startPoint } = state;

    return {
      ...state,
      startPoint: startPoint ? startPoint : payload,
      endPoint: startPoint && !state.endPoint ? payload : state.endPoint,
      isOpenRulerInputWindow: !!startPoint,
    };
  })
  .on(setRulerLengthFn, (state, payload) => {
    const { startPoint, endPoint } = state;
    if (!startPoint || !endPoint) return;

    const xSquare = (endPoint.x - startPoint.x) ** 2;
    const ySquare = (endPoint.y - startPoint.y) ** 2;

    const px = Math.round(Math.sqrt(xSquare + ySquare));
    return {
      ...state,
      px,
      meters: payload,
      isScaleBeingMeasured: false,
      isOpenRulerInputWindow: false,
      startPoint: null,
      endPoint: null,
    };
  })
  .on(getCurrentFloorRulerDataFn, (state, payload) => {
    const px = payload?.px ?? null;
    const meters = payload?.meters ?? null;

    return {
      ...state,
      isOpenRulerInputWindow: false,
      isScaleBeingMeasured: false,
      endPoint: null,
      startPoint: null,
      meters,
      px,
    };
  })
  .on(setIsOpenNotificationFn, (state, payload) => {
    return { ...state, isOpenNotification: payload };
  })
  .on(showNotificationFn, (state, isSuccess) => {
    return { ...state, isSuccess, isOpenNotification: true };
  });

$ListOfRulers
  .reset(resetListOfRulersFn)
  .on(updateRulerDataByFloorIdFn, (state, payload) => {
    if (!state) return;

    const { id, ruler } = payload;
    state[id] = ruler;

    return { ...state };
  })
  .on(setListOfRulersFn, (state, payload) => {
    if (!payload || !payload.ruler) return;

    const newState = { ...state };
    const listOfFloors = Object.keys(newState);
    const { ruler } = payload;

    listOfFloors.forEach(floorId => {
      if (floorId in ruler) {
        newState[floorId] = ruler[floorId];
      }
    });

    return { ...newState };
  })
  .on(createListOfRulersFn, (state, payload) => {
    if (state && Object.entries(state).length) return;
    return payload;
  });

sample({
  clock: getFloorsInfoFx.done,
  fn: ({ result }) => {
    return (
      result?.reduce((acc, currentFloor) => {
        return { ...acc, [currentFloor.extraId]: null };
      }, {}) ?? null
    );
  },
  target: createListOfRulersFn,
});

sample({
  clock: [resetRulerFn, setRulerLengthFn],
  source: { floors: $Floors, rulerTool: $RulerTool },
  fn: ({ floors, rulerTool }) => {
    const { px, meters } = rulerTool;
    const ruler = px && meters ? { px, meters } : null;

    return { id: floors.selectedFloor, ruler };
  },
  target: updateRulerDataByFloorIdFn,
});

sample({
  source: { listOfRulers: $ListOfRulers, floors: $Floors },
  filter: ({ listOfRulers }) => !!listOfRulers,
  fn: ({ listOfRulers, floors }) =>
    (listOfRulers as ListOfRulersDataType)[floors.selectedFloor],
  target: getCurrentFloorRulerDataFn,
});

sample({
  clock: startRulerMeasurementFn,
  target: resetCreatedObjectDataFn,
});

sample({
  clock: setRulerLengthFn,
  source: $Floors,
  fn: (sourceData, payload) => !!(sourceData.floorsInfo.length && payload),
  target: showNotificationFn,
});
