import { useUnit } from 'effector-react';
import { useCallback } from 'react';

import { MapBackdrop } from '@components/Map/MapBackdrop';

import { useContextPTHandlers } from '@features/passenger-traffic/hooks';
import { $UI } from '@features/passenger-traffic/stores/ui/store';

export const MapBackdropContainer = () => {
  const handlers = useContextPTHandlers();
  const $ui = useUnit($UI);

  const handlerCloseBackdrop = useCallback(() => {
    handlers.setMapBackdrop(false);
  }, [handlers]);

  return $ui.backdrop ? (
    <MapBackdrop onCloseBackdrop={handlerCloseBackdrop} />
  ) : null;
};
