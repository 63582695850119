import { IQuery, IQueryGetInfraPointByNameArgs } from '@api/gql/types';
import { createApi, createEffect, createEvent, createStore } from 'effector';

import { SearchInfraStore } from '@features/pt-forecast-new/stores/map/searchInfra';

const initState: SearchOptionsStore = {
  options: [],
};

export const $SearchOptions = createStore<SearchOptionsStore>(initState);

export const SearchOptionsApi = createApi($SearchOptions, {
  setOptions: (store, payload: SearchOption[]) => ({
    ...store,
    options: payload,
  }),
  reset: () => initState,
});

export const getSearchOptions = createEvent<string>();

export const querySearchOptionsFx = createEffect<
  IQueryGetInfraPointByNameArgs,
  IQuery['getInfraPointByName'] | undefined
>();

export type SearchOptionsStore = {
  options: SearchOption[];
};

export type SearchOption = {
  name: string;
  value: string;
  lonLat: number[];
  type: keyof SearchInfraStore['features'];
};
