import { useUnit } from 'effector-react';
import { useMemo } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { EFilterMapLayout } from '@components/MapFilters';
import { YearLine } from '@components/YearLine';

import { $FilterMap, $TimeLine } from '@features/passenger-traffic/stores';
import { $UI } from '@features/passenger-traffic/stores/ui/store';

export const YearLineContainer = () => {
  const { panels } = useUnit($UI);
  const { filter, inspector } = panels;
  const { layout } = useUnit($FilterMap);
  const { selectedYear, baseYear, finalYear } = useUnit($TimeLine);

  const YearLineContainerMuiStyle = useMemo<SxProps<Theme>>(
    () => ({
      position: 'absolute',
      bottom: '0',
      left: '500px',
      right: inspector ? '500px' : '0',
      zIndex: '1',
      background: theme => theme.palette.background.default,
    }),
    [inspector],
  );

  return (
    filter &&
    layout == EFilterMapLayout.PassengerFlows && (
      <Box sx={YearLineContainerMuiStyle}>
        <YearLine
          selectedYear={selectedYear}
          baseYear={baseYear}
          finalYear={finalYear}
        />
      </Box>
    )
  );
};
