import { NoDataAvailable } from '@features/tpu-calculation-scenarios-viewing/components/NoDataAvailable/NoDataAvailable';
import { ScenarioBarChart } from '@features/tpu-calculation-scenarios-viewing/components/ScenarioBarChart';
import { ScenarioPieChart } from '@features/tpu-calculation-scenarios-viewing/components/ScenarioPieChart';
import {
  $CurrentChartsData,
  $PieChartColors,
} from '@features/tpu-calculation-scenarios-viewing/stores/charts/charts.store';
import { useUnit } from 'effector-react';

import { Box, Typography } from '@mui/material';

import { commaDelimiterNumber } from '@utils/commaDelimiterNumber';
import { dividingNumber } from '@utils/dividingNumber';

export const ChartsPageContainer = () => {
  const $currentChartsData = useUnit($CurrentChartsData);
  const $pieChartColors = useUnit($PieChartColors);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        gap: '24px',
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme => theme.palette.background.paper,
          boxShadow: '0px 0px 8px 0px #00000026',
          padding: '24px',
          borderRadius: '4px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '49%',
          height: '400px',
        }}
      >
        <Typography fontWeight={'bold'}>Кол-во чел на ТПУ</Typography>

        <ScenarioBarChart
          color={'#83CA6B'}
          chartData={$currentChartsData.agentsQuantity}
          customCursor={{ color: '#F6F6F6', opacity: 0.7 }}
          xLabel={'Время ч.'}
          xLabelSettings={{ dY: 18 }}
          yLabel={'Пассажиропоток, пасс.'}
          yLabelSettings={{ dX: -35, unit: 'пасс.' }}
        />
      </Box>

      <Box
        sx={{
          backgroundColor: theme => theme.palette.background.paper,
          boxShadow: '0px 0px 8px 0px #00000026',
          padding: '24px',
          borderRadius: '4px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '49%',
          height: '400px',
        }}
      >
        <Typography fontWeight={'bold'}>
          Нарастающий итог пассажиропотока
        </Typography>

        <ScenarioBarChart
          color={'#FEC583'}
          chartData={$currentChartsData.cumulativeAgentsQuantity}
          customCursor={{ color: '#F6F6F6', opacity: 0.7 }}
          xLabel={'Время ч.'}
          xLabelSettings={{ dY: 18 }}
          yLabel={'Пассажиропоток, тыс. пасс.'}
          yLabelSettings={{ dX: -35, unit: 'тыс. пасс.' }}
          valueFormatterForTooltip={commaDelimiterNumber}
        />
      </Box>

      <Box
        sx={{
          backgroundColor: theme => theme.palette.background.paper,
          boxShadow: '0px 0px 8px 0px #00000026',
          padding: '24px',
          borderRadius: '4px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '49%',
          height: 'fit-content',
        }}
      >
        <Typography fontWeight={'bold'}>Распределение по входам</Typography>

        {$currentChartsData?.entranceDistribution?.length ? (
          <ScenarioPieChart
            diameter={300}
            valueFormatter={dividingNumber}
            chartData={$currentChartsData.entranceDistribution}
            pieColors={$pieChartColors}
            unit={'пасс.'}
          />
        ) : (
          <NoDataAvailable height={350} />
        )}
      </Box>

      <Box
        sx={{
          backgroundColor: theme => theme.palette.background.paper,
          boxShadow: '0px 0px 8px 0px #00000026',
          padding: '24px',
          borderRadius: '4px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '49%',
          height: 'fit-content',
        }}
      >
        <Typography fontWeight={'bold'}>Распределение по выходам</Typography>

        {$currentChartsData?.exitsDistribution?.length ? (
          <ScenarioPieChart
            diameter={300}
            valueFormatter={dividingNumber}
            chartData={$currentChartsData.exitsDistribution}
            pieColors={$pieChartColors}
            unit={'пасс.'}
          />
        ) : (
          <NoDataAvailable height={350} />
        )}
      </Box>
    </Box>
  );
};
