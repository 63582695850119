import { useUnit } from 'effector-react';

import { Alert, Box } from '@mui/material';

import { ECalculateStatus } from '@features/pt-forecast-new/constants/ECalculateStatus';
import { $FeatureSettings } from '@features/pt-forecast-new/stores/settings';
import { $UI } from '@features/pt-forecast-new/stores/ui';

export const CalculationNotification = () => {
  const { scenarioCalculateStatus } = useUnit($FeatureSettings);
  const {
    editMode,
    panels: { filter },
  } = useUnit($UI);

  return (
    (scenarioCalculateStatus === ECalculateStatus.PENDING ||
      scenarioCalculateStatus === ECalculateStatus.QUEUED) && (
      <Box
        sx={{
          position: 'absolute',
          top: theme => (editMode ? theme.spacing(15) : theme.spacing(5)),
          left: `calc(50% - 200px + ${filter ? 250 : 1}px)`,
          zIndex: 10,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {scenarioCalculateStatus === ECalculateStatus.PENDING && (
          <Alert
            variant="filled"
            severity="info"
            icon={false}
            sx={{
              height: 'min-content',
              width: '400px',
            }}
          >
            Идёт расчёт...
          </Alert>
        )}

        {scenarioCalculateStatus === ECalculateStatus.QUEUED && (
          <Alert
            variant="filled"
            icon={false}
            sx={{
              height: 'min-content',
              width: '400px',
              bgcolor: theme => theme.palette.background.paper,
              border: '1px solid rgba(0, 0, 0, 0.38)',
              color: theme => theme.palette.text.primary,
            }}
          >
            В очереди
          </Alert>
        )}
      </Box>
    )
  );
};
