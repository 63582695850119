import { ReactNode } from 'react';

import { List, SvgIconProps, Typography } from '@mui/material';

import { Accordion as AccordionBase } from '@components/Accordion';

interface AccordionProps {
  label: string;
  children?: ReactNode;
  Icon?: (props: SvgIconProps) => JSX.Element;
}

export const Accordion = ({ label, children, Icon }: AccordionProps) => {
  return (
    <AccordionBase
      summaryComponent={
        <>
          {Icon && <Icon sx={{ mr: '8px' }} />}
          <Typography variant={'body2'}>{label}</Typography>
        </>
      }
    >
      <List sx={{ ml: -1, mt: -1 }}>{children}</List>
    </AccordionBase>
  );
};
