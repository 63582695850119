import { useUnit } from 'effector-react';

import { $ListOfObjectsSortedByType } from '@features/tpu-simulation-model/store';
import { EditableObjectType } from '@features/tpu-simulation-model/types';
import { Option } from '@features/tpu-simulation-model/utils/getAttributeOptions';

export const useRoomsEntrancesExits = (): Option[] => {
  const $listOfObjectsSortedByType = useUnit($ListOfObjectsSortedByType);

  const allRooms = $listOfObjectsSortedByType?.[EditableObjectType.Room];
  const roomOptions =
    allRooms?.map(item => ({
      value: item.uuid,
      label: `Помещение "${item.name}"`,
    })) ?? [];

  const allPlatforms =
    $listOfObjectsSortedByType?.[EditableObjectType.Platform];
  const platformsOptions =
    allPlatforms?.map(item => ({
      value: item.uuid,
      label: `Платформа: "${item.platform}"`,
    })) ?? [];

  const allPassengerGenerators =
    $listOfObjectsSortedByType?.[EditableObjectType.PassengerGenerator];
  const passengerGeneratorOptions =
    allPassengerGenerators?.map(item => ({
      value: item.uuid,
      label: `Вход: "${item.name}"`,
    })) ?? [];

  const allExits = $listOfObjectsSortedByType?.[EditableObjectType.Exit];
  const exitOptions =
    allExits?.map(item => ({
      value: item.uuid,
      label: `Выход: "${item.name}"`,
    })) ?? [];

  return new Array<Option>().concat(
    roomOptions,
    passengerGeneratorOptions,
    exitOptions,
    platformsOptions,
  );
};
