import { useUnit } from 'effector-react';

import {
  IOnCompleteArgs,
  InputFindObjectValue,
  addPointFn,
  addRulerPointFn,
  cancelCreationFn,
  chooseValueForInputFindObjectFn,
  closePlanErrorsWindowFn,
  decrementScaleFn,
  deleteAllObjectsOnCurrentFloorFn,
  getScenarioIdFn,
  getTurnstilesFn,
  getWaysPlatformsFn,
  incrementScaleFn,
  onCompleteFn,
  openObjectForEditByIdFromAnyFloorFn,
  openObjectForEditFn,
  openPlanErrorsWindowFn,
  removeLastPointFn,
  resetCreatedObjectDataFn,
  resetEditableObjectDataFn,
  resetEditorStateFn,
  resetEntrancesExitsFn,
  resetErrorsFn,
  resetErrorsModalFn,
  resetFloorsInfoFn,
  resetInputFindObjectFn,
  resetListOfFloorsWithListOfObjectsFn,
  resetProjectInfoFn,
  resetRulerFn,
  resetRulerMeasurementFn,
  resetScenarioIdFn,
  resetTurnstilesFn,
  resetUIFn,
  resetWaysPlatformsFn,
  rulerLengthSetterFn,
  savePlanFn,
  selectFloorFn,
  setCreatedObjectFn,
  setIsOpenDefaultHUBInfoFn,
  setIsOpenNotificationFn,
  setModelBlockSizeFn,
  setOpacityFn,
  setPositionFn,
  setScaleFn,
  setShouldCenterStagePositionAndResetScaleFn,
  startRulerMeasurementFn,
  triggerFindErrorsFn,
  triggerForDeleteObjectFn,
  triggerForUpdateObjectDataByFloorIdFn,
  triggerForUpdateObjectDataFn,
} from '@features/tpu-simulation-model/store/';
import {
  resetModalResetRulerFn,
  setIsOpenModalResetRulerFn,
} from '@features/tpu-simulation-model/store/modal-reset-ruler';
import { resetListOfRulersFn } from '@features/tpu-simulation-model/store/ruler/listOfRulers.store';
import {
  ActionBeforeOpenErrorsWindow,
  EditableObjectType,
  IElevator,
  ILadder,
  IPoint,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

export interface UseSimulationModelHandlersType {
  addPoint(payload: IPoint): void;
  onComplete(payload?: IOnCompleteArgs): void;
  cancelCreation(): void;
  getScenarioId(): void;
  startRulerMeasurement(): void;
  setCreatedObject(payload: EditableObjectType | null): void;
  deleteObject(payload: string): void;
  openObjectForEdit(payload: ObjectOfTheSimulationModel): void;
  updateObjectData(payload: ObjectOfTheSimulationModel): void;
  updateObjectDataByFloorId(payload: ILadder | IElevator): void;
  selectFloor(payload: string): void;
  openObjectForEditByIdFromAnyFloor(payload: string): void;
  addRulerPoint(payload: IPoint): void;
  rulerLengthSetter(payload: number): void;
  setOpacity(payload: number): void;
  resetRuler(): void;
  savePlan(): void;
  resetEditableObjectData(): void;
  deleteAllObjectsOnCurrentFloor(): void;
  resetEditorState(): void;
  setScale(payload: number): void;
  setPosition(payload: IPoint): void;
  openDefaultHubInfo(): void;
  closeDefaultHubInfo(): void;
  resetRulerMeasurement(): void;
  getTurnstiles(): void;
  getWaysPlatforms(): void;
  triggerCenter(): void;
  onTriggeredCenter(): void;
  incrementScale(): void;
  decrementScale(): void;
  triggerFindErrors(payload: { saveAfterAnalysis: boolean } | void): void;
  closePlanErrorsWindow(): void;
  openPlanErrorsWindow(payload: ActionBeforeOpenErrorsWindow): void;
  resetSimulationModel(): void;
  resetInputFindObject(): void;
  chooseValueForInputFindObject(payload: InputFindObjectValue | null): void;
  setModelBlockSize(payload: { width: number; height: number }): void;
  setIsOpenNotification(payload: boolean): void;
  setIsOpenModalResetRuler(payload: boolean): void;
  removeLastPoint(): void;
}

export const useSimulationModelHandlers =
  (): UseSimulationModelHandlersType => {
    const events = useUnit({
      addPointFn,
      onCompleteFn,
      setCreatedObjectFn,
      cancelCreationFn,
      triggerForDeleteObjectFn,
      openObjectForEditFn,
      triggerForUpdateObjectDataFn,
      triggerForUpdateObjectDataByFloorIdFn,
      getScenarioIdFn,
      selectFloorFn,
      startRulerMeasurementFn,
      addRulerPointFn,
      rulerLengthSetterFn,
      resetRulerFn,
      savePlanFn,
      resetEditableObjectDataFn,
      deleteAllObjectsOnCurrentFloorFn,
      resetEditorStateFn,
      setScaleFn,
      setPositionFn,
      resetRulerMeasurementFn,
      setIsOpenDefaultHUBInfoFn,
      getTurnstilesFn,
      getWaysPlatformsFn,
      setOpacityFn,
      incrementScaleFn,
      decrementScaleFn,
      setShouldCenterStagePositionAndResetScaleFn,
      openPlanErrorsWindowFn,
      closePlanErrorsWindowFn,
      triggerFindErrorsFn,
      openObjectForEditByIdFromAnyFloorFn,
      chooseValueForInputFindObjectFn,
      resetInputFindObjectFn,
      resetCreatedObjectDataFn,
      setModelBlockSizeFn,
      setIsOpenNotificationFn,
      resetModalResetRulerFn,
      setIsOpenModalResetRulerFn,
      removeLastPointFn,
    });

    const openDefaultHubInfo = () => setIsOpenDefaultHUBInfoFn(true);
    const closeDefaultHubInfo = () => setIsOpenDefaultHUBInfoFn(false);

    const triggerCenter = () =>
      setShouldCenterStagePositionAndResetScaleFn(true);

    const onTriggeredCenter = () =>
      setShouldCenterStagePositionAndResetScaleFn(false);

    const resetSimulationModel = () => {
      resetCreatedObjectDataFn();
      resetEditableObjectDataFn();
      resetEditorStateFn();
      resetErrorsModalFn();
      resetErrorsFn();
      resetFloorsInfoFn();
      resetListOfFloorsWithListOfObjectsFn();
      resetEntrancesExitsFn();
      resetTurnstilesFn();
      resetWaysPlatformsFn();
      resetProjectInfoFn();
      resetRulerFn();
      resetRulerMeasurementFn();
      resetListOfRulersFn();
      resetUIFn();
      resetInputFindObjectFn();
      resetScenarioIdFn();
      events.resetModalResetRulerFn();
    };

    return {
      addPoint: events.addPointFn,
      onComplete: events.onCompleteFn,
      setCreatedObject: events.setCreatedObjectFn,
      cancelCreation: events.cancelCreationFn,
      deleteObject: events.triggerForDeleteObjectFn,
      openObjectForEdit: events.openObjectForEditFn,
      updateObjectData: events.triggerForUpdateObjectDataFn,
      updateObjectDataByFloorId: events.triggerForUpdateObjectDataByFloorIdFn,
      getScenarioId: events.getScenarioIdFn,
      selectFloor: events.selectFloorFn,
      startRulerMeasurement: events.startRulerMeasurementFn,
      addRulerPoint: events.addRulerPointFn,
      rulerLengthSetter: events.rulerLengthSetterFn,
      resetRuler: events.resetRulerFn,
      savePlan: events.savePlanFn,
      resetEditableObjectData: events.resetEditableObjectDataFn,
      deleteAllObjectsOnCurrentFloor: events.deleteAllObjectsOnCurrentFloorFn,
      resetEditorState: events.resetEditorStateFn,
      setScale: events.setScaleFn,
      setPosition: events.setPositionFn,
      resetRulerMeasurement: events.resetRulerMeasurementFn,
      openDefaultHubInfo,
      closeDefaultHubInfo,
      getTurnstiles: events.getTurnstilesFn,
      getWaysPlatforms: events.getWaysPlatformsFn,
      incrementScale: events.incrementScaleFn,
      decrementScale: events.decrementScaleFn,
      setOpacity: events.setOpacityFn,
      openPlanErrorsWindow: events.openPlanErrorsWindowFn,
      closePlanErrorsWindow: events.closePlanErrorsWindowFn,
      triggerFindErrors: events.triggerFindErrorsFn,
      triggerCenter,
      onTriggeredCenter,
      openObjectForEditByIdFromAnyFloor:
        events.openObjectForEditByIdFromAnyFloorFn,
      resetSimulationModel,
      chooseValueForInputFindObject: events.chooseValueForInputFindObjectFn,
      resetInputFindObject: events.resetInputFindObjectFn,
      setModelBlockSize: events.setModelBlockSizeFn,
      setIsOpenNotification: events.setIsOpenNotificationFn,
      setIsOpenModalResetRuler: events.setIsOpenModalResetRulerFn,
      removeLastPoint: events.removeLastPointFn,
    };
  };
