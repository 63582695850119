import { createEvent, createStore } from 'effector';

import { IPoint, IRuler } from '@features/tpu-simulation-model/types';

interface RulerToolDataType {
  isScaleBeingMeasured: boolean;
  isOpenRulerInputWindow: boolean;
  isSuccess: boolean;
  isOpenNotification: boolean;
  startPoint: IPoint | null;
  endPoint: IPoint | null;
  px: number | null;
  meters: number | null;
}

export const DEFAULT_STATE = {
  isScaleBeingMeasured: false,
  isOpenRulerInputWindow: false,
  isSuccess: false,
  isOpenNotification: false,
  startPoint: null,
  endPoint: null,
  px: null,
  meters: null,
};

export const $RulerTool = createStore<RulerToolDataType>(DEFAULT_STATE);

export const startRulerMeasurementFn = createEvent();

export const addRulerPointFn = createEvent<IPoint>();

export const rulerLengthSetterFn = createEvent<number>();

export const resetRulerFn = createEvent();

export const resetRulerMeasurementFn = createEvent();

export const getCurrentFloorRulerDataFn = createEvent<IRuler | null>();

export const setIsOpenNotificationFn = createEvent<boolean>();

export const showNotificationFn = createEvent<boolean>();
