import { apiClient } from '@api/client';
import {
  IQuery,
  IQueryGetComputingRegistryInstanceByIdArgs,
} from '@api/gql/types';
import { gql } from '@apollo/client';

export const queryGetTransportProjectsForScenario = async ({
  id,
}: IQueryGetComputingRegistryInstanceByIdArgs) => {
  const result = await apiClient.query<
    Pick<IQuery, 'getComputingRegistryInstanceById'>,
    IQueryGetComputingRegistryInstanceByIdArgs
  >({
    query: gql`
      query QueryGetComputingRegistryInstanceById($id: String!) {
        getComputingRegistryInstanceById(id: $id) {
          transportScenario {
            transportProjects {
              id
              year
              transportProject {
                name
              }
            }
          }
        }
      }
    `,
    variables: { id },
  });

  return result.data?.getComputingRegistryInstanceById || null;
};
