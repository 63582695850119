import { SOCECO_TABLE_IDS } from '../tables/tableIds';

export const SocEcoInfrastructureType = {
  [SOCECO_TABLE_IDS.TABLE_1_1]: SOCECO_TABLE_IDS.TABLE_1_1,
  [SOCECO_TABLE_IDS.TABLE_2_5]: SOCECO_TABLE_IDS.TABLE_2_5,
  [SOCECO_TABLE_IDS.TABLE_3_1]: SOCECO_TABLE_IDS.TABLE_3_1,
  [SOCECO_TABLE_IDS.TABLE_3_2]: SOCECO_TABLE_IDS.TABLE_3_2,
  [SOCECO_TABLE_IDS.TABLE_3_4]: SOCECO_TABLE_IDS.TABLE_3_4,
  [SOCECO_TABLE_IDS.TABLE_4_2]: SOCECO_TABLE_IDS.TABLE_4_2,
  [SOCECO_TABLE_IDS.TABLE_5_2]: SOCECO_TABLE_IDS.TABLE_5_2,
  [SOCECO_TABLE_IDS.TABLE_5_3]: SOCECO_TABLE_IDS.TABLE_5_3,
} as const;
