import { GetObjectType } from '@features/ag-project/stores/inspector/infrastructure';
import {
  InspectorApi,
  getDataForInspectorFn,
} from '@features/ag-project/stores/inspector/store';
import {
  $SelectedFeatures,
  AgLayersStore,
  SelectedFeaturesApi,
} from '@features/ag-project/stores/map';
import { UIApi } from '@features/ag-project/stores/ui';
import { sample } from 'effector';

// Запросить данные по выделенной фиче
sample({
  clock: SelectedFeaturesApi.setSelectedFeatures,
  filter: payload => !!payload,
  fn: payload => {
    return {
      layout: payload!.layout as keyof AgLayersStore,
      id: payload!.id,
    } as GetObjectType;
  },
  target: getDataForInspectorFn,
});

// Отобразить инспектор, если есть выделенные фичи
sample({
  clock: $SelectedFeatures,
  fn: store => !!store.length,
  target: UIApi.setInspector,
});

// Установить вид инспектора
sample({
  clock: getDataForInspectorFn,
  fn: payload => payload.layout,
  target: InspectorApi.setLayout,
});
