import { useUnit } from 'effector-react';
import { useEffect, useMemo } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { AutoFullHeight } from '@components/AutoFullHeight';
import { Loading } from '@components/Loading/Loading';

import { TableParams } from '@widgets/widget-soceco-indicators/components/ThirdPage/TableParams';
import { excludedTables } from '@widgets/widget-soceco-indicators/components/ThirdPage/configs/excludedTables';
// import { SOCECO_TABLE_IDS } from '@widgets/widget-soceco-indicators/constants/tables/tableIds';
import { MapCentredWrapper } from '@widgets/widget-soceco-indicators/containers/MapContainer/MapCentredWrapper';
import { MapContainer } from '@widgets/widget-soceco-indicators/containers/MapContainer/MapContainer';
import { MapControlsContainer } from '@widgets/widget-soceco-indicators/containers/MapControlsContainer/MapControlsContainer';
import { FilterContainer } from '@widgets/widget-soceco-indicators/containers/ThirdPageContainer/FilterContainer';
import { initTileSources } from '@widgets/widget-soceco-indicators/stores/map';
// import { SocEcoInfrastructureFetchEvents } from '@widgets/widget-soceco-indicators/stores/map/infrastructure';
import {
  $Settings,
  SettingsApi,
} from '@widgets/widget-soceco-indicators/stores/settings/store';
import {
  $SocEcoTable,
  SocEcoTableApi,
} from '@widgets/widget-soceco-indicators/stores/tables/store';

export const ThirdPageDashboardContainer = ({
  scenarioUUID,
  centerCoords,
  projectUUID,
  minYear,
  timeSlots,
  vehicles,
}: ThirdPageDashboardContainerType) => {
  const { baseYear, scenarioId, centerAGCoords } = useUnit($Settings);
  const {
    setProjectId,
    setScenarioId,
    setBaseYear,
    setCenterCoords,
    setTimeSlots,
    setVehicles,
  } = useUnit(SettingsApi);
  const { selectedTable, tables } = useUnit($SocEcoTable);
  const { setTable } = useUnit(SocEcoTableApi);

  const isDisplayTable = useMemo(
    () => !excludedTables.includes(selectedTable),
    [selectedTable],
  );

  useEffect(() => {
    if (scenarioUUID !== scenarioId) setScenarioId(scenarioUUID);
    if (minYear !== baseYear) setBaseYear(minYear);
    if (centerCoords !== centerAGCoords) setCenterCoords(centerCoords);
    if (timeSlots.length) setTimeSlots(timeSlots);
    if (vehicles?.length) setVehicles(vehicles);
    if (projectUUID) {
      setProjectId(projectUUID);
      initTileSources(projectUUID);
    }
  }, [
    baseYear,
    centerAGCoords,
    centerCoords,
    minYear,
    projectUUID,
    scenarioId,
    scenarioUUID,
    setBaseYear,
    setCenterCoords,
    setProjectId,
    setScenarioId,
    setTimeSlots,
    setVehicles,
    timeSlots,
    vehicles,
  ]);

  useEffect(() => {
    setTable(selectedTable);
  }, [selectedTable, setTable]);

  return (
    <Box sx={ContainerMuiStyle}>
      <FilterContainer />

      <AutoFullHeight
        style={{
          padding: '24px',
          width: '100%',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '24px',
            height: '100%',
          }}
        >
          <Box
            sx={{
              height: 'calc(50%)',
              backgroundColor: theme => theme.palette.common.white,
              borderRadius: 2,
              boxShadow: '0px 0px 8px 0px #00000026',
            }}
          >
            <MapCentredWrapper centerCoords={centerCoords}>
              <MapContainer />
            </MapCentredWrapper>

            <MapControlsContainer />
          </Box>

          {isDisplayTable && (
            <Box
              sx={{
                height: 'calc(50%)',
                backgroundColor: theme => theme.palette.common.white,
                borderRadius: 2,
                boxShadow: '0px 0px 8px 0px #00000026',
              }}
            >
              <TableParams />
            </Box>
          )}
        </Box>
      </AutoFullHeight>

      <Loading
        isLoading={tables[selectedTable].isLoading}
        sx={{ height: 'calc(100% - 155px)' }}
      />
    </Box>
  );
};

const ContainerMuiStyle: SxProps<Theme> = {
  borderTop: theme => `1px solid ${theme.palette.border.divider}`,
  display: 'flex',
};

export type ThirdPageDashboardContainerType = {
  scenarioUUID: string | null;
  projectUUID: string | null;
  centerCoords: number[] | null;
  timeSlots: TimeSlotOption[];
  minYear: number;
  vehicles: VehicleType[] | null;
};

export type TimeSlotOption = {
  id: string;
  name: string;
};

export type VehicleType = {
  id: string;
  name: string;
  vehicleId: EVehicleAg;
  maxSpeed: number;
  isPublic: boolean;
};

export enum EVehicleAg {
  auto = 1,
  bus = 2,
  trolleybus = 3,
  tram = 4,
  metro = 5,
  suburbanRailway = 6,
  ropeWay = 7,
  waterWay = 8,
  funicular = 9,
  monoRailWay = 10,
  pedestrain = 11,
  avia = 12,
}
