export const SOC_ECO_TH_7_2 = [
  {
    id: 'nameTransportLineSection',
    label: 'Наименование участка транспортной линии',
  },
  {
    id: 'year',
    label: 'Год',
  },
  {
    id: 'volumeTransportWork',
    label: 'Объём транспортной работы, тыс. поездо-км.',
  },
  {
    id: 'amountElectricityConsumed',
    label: 'Объём потреблённой электроэнергии, КВт*ч.',
  },
];
