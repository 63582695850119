import { SvgIcon, SvgIconProps } from '@mui/material';

export const FilterIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M13.4545 15.697C13.8562 15.697 14.1818 16.0226 14.1818 16.4242C14.1818 16.8259 13.8562 17.1515 13.4545 17.1515H10.5455C10.1438 17.1515 9.81818 16.8259 9.81818 16.4242C9.81818 16.0226 10.1438 15.697 10.5455 15.697H13.4545ZM16.3636 10.8485C16.7653 10.8485 17.0909 11.1741 17.0909 11.5758C17.0909 11.9774 16.7653 12.303 16.3636 12.303H7.63636C7.23471 12.303 6.90909 11.9774 6.90909 11.5758C6.90909 11.1741 7.23471 10.8485 7.63636 10.8485H16.3636ZM19.2727 6C19.6744 6 20 6.32561 20 6.72727C20 7.12893 19.6744 7.45455 19.2727 7.45455H4.72727C4.32561 7.45455 4 7.12893 4 6.72727C4 6.32561 4.32561 6 4.72727 6H19.2727Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
