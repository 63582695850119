import { SvgIcon, SvgIconProps } from '@mui/material';

export const TicketPrintingMachineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.324 5.04v2.257h2.045c1.451 0 2.631 1.13 2.631 2.52V20.48c0 1.39-1.18 2.52-2.631 2.52H3.631C2.18 23 1 21.87 1 20.48V9.816c0-1.39 1.18-2.52 2.631-2.52h2.044V2.703C5.675 1.764 6.552 1 7.628 1h10.038c.62 0 1.164.219 1.57.631.937.952.862 2.628.851 2.816l-.037.592h-1.726Zm0-2.496V3.78h.427c-.046-.418-.163-.926-.427-1.235ZM17.008 4.22v5.45H6.991V2.701c0-.24.291-.442.637-.442h9.38V4.22Zm1.316 5.45V8.555h2.045c.726 0 1.316.566 1.316 1.26V20.48c0 .695-.59 1.26-1.316 1.26H3.631c-.725 0-1.315-.565-1.315-1.26V9.816c0-.694.59-1.26 1.315-1.26h2.044v1.112h-.614v1.26h13.891v-1.26h-.628Zm-7.342-4.351h4.097v-1.26h-4.097v1.26Zm0 2.622h4.097V6.68h-4.097v1.26Zm-2.35-2.622h1.281v-1.26h-1.28v1.26Zm0 2.622h1.281V6.68h-1.28v1.26Zm9.209 11.579c-.655 0-1.185-.508-1.185-1.135 0-.628.53-1.136 1.185-1.136.654 0 1.185.508 1.185 1.136 0 .627-.53 1.135-1.185 1.135Zm-12.78-.505h6.94v-1.26H5.06v1.26Z"
        fill="#FF792E"
      />
    </SvgIcon>
  );
};
