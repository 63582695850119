import { NoDataAvailable } from '@features/tpu-calculation-scenarios-viewing/components/NoDataAvailable/NoDataAvailable';
import { ScenarioBarChart } from '@features/tpu-calculation-scenarios-viewing/components/ScenarioBarChart';
import { ScenarioPieChart } from '@features/tpu-calculation-scenarios-viewing/components/ScenarioPieChart';
import {
  $ScenarioData,
  EViewerQueryParams,
} from '@features/tpu-calculation-scenarios-viewing/stores';
import {
  $CurrentChartsData,
  $PieChartColors,
} from '@features/tpu-calculation-scenarios-viewing/stores/charts/charts.store';
import { useUnit } from 'effector-react';
import { useNavigate } from 'react-router-dom';

import { Box, Divider, Typography, styled } from '@mui/material';

import { Accordion } from '@components/Accordion';
import { Diagram } from '@components/icons/Diagram';
import { DiagramCircle } from '@components/icons/DiagramCircle';

import { ROUTES } from '@constants/routes';

import { commaDelimiterNumber } from '@utils/commaDelimiterNumber';
import { dividingNumber } from '@utils/dividingNumber';

export const ScenariosChartsContainer = () => {
  const navigate = useNavigate();

  const $scenarioData = useUnit($ScenarioData);
  const $currentChartsData = useUnit($CurrentChartsData);
  const $pieChartColors = useUnit($PieChartColors);

  const goToCharts = () => {
    navigate(
      `${ROUTES.TPU_SCENARIOS}/${$scenarioData.scenarioId}${ROUTES.TPU_SCENARIOS_VIEWER}` +
        `?page=${EViewerQueryParams.passengerFlowCharts}`,
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        backgroundColor: theme => theme.palette.background.paper,
        padding: '24px',
        '&::-webkit-scrollbar': { width: '5px', height: '5px' },
        '&::-webkit-scrollbar-track': {
          background: '#F6F6F6',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#BBC0CB',
          border: 'none',
          borderRadius: '50px',
        },
      }}
    >
      <Accordion
        summaryComponent={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'auto',
              backgroundColor: theme => theme.palette.background.paper,
            }}
          >
            <Diagram sx={{ mr: '8px' }} />
            <Typography variant={'subtitle1'}>Кол-во чел на ТПУ</Typography>
          </Box>
        }
      >
        <ChartWrapper>
          <ScenarioBarChart
            color={'#83CA6B'}
            width={'700px'}
            chartData={$currentChartsData.agentsQuantity}
            customCursor={{ color: '#F6F6F6', opacity: 0.7 }}
            xLabel={'Время ч.'}
            xLabelSettings={{ dX: -160, dY: 18 }}
            yLabel={'Пассажиропоток, пасс.'}
            yLabelSettings={{ dX: -35, unit: 'пасс.' }}
            onClick={goToCharts}
          />
        </ChartWrapper>
      </Accordion>

      <Divider sx={{ pt: 2, mb: 2 }} />

      <Accordion
        summaryComponent={
          <Box
            sx={{
              display: 'flex',
              overflow: 'auto',
              alignItems: 'center',
              backgroundColor: theme => theme.palette.background.paper,
            }}
          >
            <Diagram sx={{ mr: '8px' }} />
            <Typography
              variant={'subtitle1'}
              noWrap
            >
              Нарастающий итог пассажиропотока
            </Typography>
          </Box>
        }
      >
        <ChartWrapper>
          <ScenarioBarChart
            color={'#FEC583'}
            width={'700px'}
            chartData={$currentChartsData.cumulativeAgentsQuantity}
            customCursor={{ color: '#F6F6F6', opacity: 0.7 }}
            xLabel={'Время ч.'}
            xLabelSettings={{ dX: -160, dY: 18 }}
            yLabel={'Пассажиропоток, тыс. пасс.'}
            yLabelSettings={{ dX: -35, unit: 'тыс. пасс.' }}
            valueFormatterForTooltip={commaDelimiterNumber}
            onClick={goToCharts}
          />
        </ChartWrapper>
      </Accordion>

      <Divider sx={{ pt: 2, mb: 2 }} />

      <Accordion
        summaryComponent={
          <Box
            sx={{
              display: 'flex',
              overflow: 'auto',
              alignItems: 'center',
              backgroundColor: theme => theme.palette.background.paper,
            }}
          >
            <DiagramCircle sx={{ mr: '8px' }} />
            <Typography variant={'subtitle1'}>
              Распределение по входам
            </Typography>
          </Box>
        }
      >
        {$currentChartsData?.entranceDistribution?.length ? (
          <ScenarioPieChart
            diameter={144}
            valueFormatter={dividingNumber}
            chartData={$currentChartsData.entranceDistribution}
            pieColors={$pieChartColors}
            unit={'пасс.'}
            onClick={goToCharts}
          />
        ) : (
          <NoDataAvailable height={144} />
        )}
      </Accordion>
    </Box>
  );
};

const ChartWrapper = styled('div')(() => ({
  padding: 0,
  overflow: 'auto',
  '&::-webkit-scrollbar': { width: '5px', height: '5px' },
  '&::-webkit-scrollbar-track': {
    background: '#F6F6F6',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#BBC0CB',
    border: 'none',
    borderRadius: '50px',
  },
}));
