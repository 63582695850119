import { PropsWithChildren } from 'react';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Fade, styled } from '@mui/material';

export type MapBackdropProps = PropsWithChildren<{
  onCloseBackdrop(): void;
}>;

const BackdropRoot = styled('div', {
  name: 'MapBackdrop',
})(() => ({
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  WebkitTapHighlightColor: 'transparent',
}));

export const MapBackdrop = (props: MapBackdropProps) => {
  const { onCloseBackdrop, children } = props;
  const handleClickAway = () => onCloseBackdrop();

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Fade in={true}>
        <BackdropRoot
          sx={{
            position: 'fixed',
            right: 0,
            bottom: 0,
            top: 0,
            left: 0,
            WebkitTapHighlightColor: 'transparent',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          {children}
        </BackdropRoot>
      </Fade>
    </ClickAwayListener>
  );
};
