import { Stroke, Style } from 'ol/style';

import { TileStyleProps } from '@utils/map/styles/types';
import { createActiveFeatureBorder } from '@utils/map/tools/createActiveFeatureBorder';
import { getLineWidth } from '@utils/map/tools/getLineWidth';
import { scaleColor } from '@utils/map/tools/scaleColor';
import { scaleValue } from '@utils/map/tools/scaleValue';

export const graphWaterWayPassFlowTileStyle = (props: TileStyleProps) => {
  const { isActive, feature, map, resolution } = props;
  const zoom = map.getView().getZoomForResolution(resolution) || 10;
  const width = scaleValue(
    feature!.get('capacity'),
    0,
    feature!.get('max_capacity'),
    1,
    20,
  );
  const color = scaleColor(feature!.get('flow'), 0, feature!.get('capacity'));

  const styles = isActive ? [createActiveFeatureBorder(width + 1)] : [];

  styles.push(
    new Style({
      stroke: new Stroke({
        color,
        width: getLineWidth(zoom, [
          10,
          width * 0.1,
          11,
          width * 0.2,
          12,
          width * 0.3,
          13,
          width * 0.4,
          14,
          width * 0.5,
          15,
          width * 0.7,
          16,
          width,
        ]),
        lineCap: 'square',
      }),
    }),
  );
  return styles;
};
