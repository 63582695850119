import { SvgIcon, SvgIconProps } from '@mui/material';

export const Pause = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={44}
      height={44}
      viewBox="0 0 44 44"
      fill="currentColor"
      {...props}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 11 11"
        fill="currentColor"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Dribbble-Light-Preview"
            transform="translate(-65.600000, -3763.500000)"
            fill="currentColor"
          >
            <g
              id="icons"
              transform="translate(56.000000, 160.000000)"
            >
              <path
                d="M11,3613 L13,3613 L13,3605 L11,3605 L11,3613 Z M15,3613 L17,3613 L17,3605 L15,3605 L15,3613 Z"
                id="pause-[#1010]"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
