import { SvgIcon, SvgIconProps } from '@mui/material';

export const RoomIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        x=".5"
        y=".5"
        width="23"
        height="23"
        rx="1.5"
        fill="#E9EEF3"
        stroke="#F14848"
      />
    </SvgIcon>
  );
};
