import { useCallback } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import { DialogCloseButton } from '@components/DialogCloseButton';

interface ModalFileRequirementsProps {
  onClose(): void;
}

export const ModalFileRequirements = (props: ModalFileRequirementsProps) => {
  const { onClose } = props;

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog
      onClose={handleClose}
      open={true}
    >
      <DialogTitle
        variant="subtitle2"
        sx={{
          position: 'relative',
          fontSize: theme => theme.typography.pxToRem(16),
          pr: 10,
        }}
      >
        Требования к форматам файлов
      </DialogTitle>

      <DialogCloseButton onClick={handleClose} />

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          textIndent: 24,
          rowGap: 2,
        }}
      >
        <Typography>
          Загружаемые файлы проходят двухуровневый форматно-логический контроль
          на соответствие критериям качества данных.
        </Typography>

        <Typography>
          На первом уровне файл проверяется на соблюдение структуры данных и
          отсутствие пропущенных значений (для формата XLSX).
        </Typography>

        <Typography>
          На втором уровне записи файла проверяются по заданным форматам,
          сопоставляются ключи записей с ключами справочников ПКМПП, проверяется
          наличие дублей записей.
        </Typography>

        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <ListItem>1. Граф - формат geojson</ListItem>

          <ListItem>2. Транспортные районы - формат geojson</ListItem>

          <ListItem>3. Центры транспортных районов - формат geojson</ListItem>

          <ListItem>4. Транспортные зоны - формат geojson</ListItem>

          <ListItem>5. Центры транспортных зон - формат geojson</ListItem>

          <ListItem>
            6. Показатели транспортных районов и параметры перемещения - формат
            xlsx
          </ListItem>

          <ListItem>
            7. Данные по пригородным ЖД маршрутам - формат xlsx
          </ListItem>
        </List>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Закрыть</Button>
      </DialogActions>
    </Dialog>
  );
};
