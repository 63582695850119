import { sample } from 'effector';

import {
  $SelectedFeatures,
  SelectedFeaturesApi,
} from '@features/ag-project/stores/map';
import {
  $SearchInfra,
  SearchInfraApi,
} from '@features/ag-project/stores/map/searchInfra';
import { $UI, UIApi } from '@features/ag-project/stores/ui/store';

// Сброс выделения фичи при закрытии инспектора
sample({
  clock: [UIApi.toggleInspector, UIApi.setInspector],
  source: { UI: $UI, SelectedFeatures: $SelectedFeatures },
  filter: ({ UI, SelectedFeatures }) =>
    !UI.inspector && !!SelectedFeatures.length,
  fn: () => null,
  target: SelectedFeaturesApi.setSelectedFeatures,
});

// Сброс выделения фичи при закрытии инспектора
sample({
  clock: [UIApi.toggleInspector, UIApi.setInspector],
  source: { UI: $UI, SearchInfra: $SearchInfra },
  filter: ({ UI, SearchInfra }) => !UI.inspector && !!SearchInfra,
  target: SearchInfraApi.reset,
});
