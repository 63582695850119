import { SvgIcon, SvgIconProps } from '@mui/material';

export const StoreIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        width="24"
        height="24"
        rx="2"
        fill="#fff"
      />
      <path
        d="M16.88 1H6.965c-.542 0-1.085.232-1.395.697L1.31 8.05c-.233.31-.31.62-.31 1.085v12.084C1 22.225 1.775 23 2.782 23h18.436A1.753 1.753 0 0 0 23 21.218V9.134c0-.387-.078-.697-.31-1.085l-4.26-6.352C17.965 1.232 17.422 1 16.88 1Zm.078 2.789c0 .465-.388.852-.93.852H7.662c-.465 0-.93-.387-.93-.852v-.155c0-.465.388-.852.93-.852h8.366c.465 0 .93.387.93.852v.155Zm-2.944 10.845H12.31l-.078-.078a.592.592 0 0 1-.154-.387c0-.155.077-.31.154-.387a.592.592 0 0 1 .388-.155c.155 0 .31.077.387.155l1.007.852ZM6.19 9.909c.775.232 1.395 1.007 1.395 1.859v8.443H3.634v-8.443c0-.852.62-1.627 1.394-1.86V8.283c-.077 0-.155-.155-.155-.233v-.542c0-.077.078-.232.155-.232H6.19c.078 0 .155.155.155.232v.542c0 .078-.077.233-.155.233v1.627Zm10.613 6.661-1.395-1.394h4.493v4.958H14.79V15.33l1.627 1.627.387-.387Zm-4.648 0 .387.388 1.627-1.627v4.803H9.056v-4.958h4.493l-1.394 1.394Zm-.62-2.4c0 .154 0 .309.078.464H9.056V9.676h5.113v4.338l-.62-.62c-.232-.232-.542-.31-.852-.31-.31 0-.62.155-.852.31-.232.155-.31.465-.31.775Zm4.726-1.163c-.31 0-.62.155-.852.31l-.62.62V9.599H19.9v4.957h-2.556c.078-.154.078-.31.078-.464 0-.31-.155-.62-.388-.853-.155-.154-.465-.232-.774-.232Zm.464.775a.592.592 0 0 1 .155.387c0 .155-.077.31-.155.387l-.077.078H15.02l.852-.852a.591.591 0 0 1 .388-.155c.155 0 .31 0 .464.155Z"
        fill="#7E67DD"
      />
    </SvgIcon>
  );
};
