import {
  PropsWithChildren,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionDetails,
  Accordion as AccordionMui,
  AccordionSummary,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

type AccordionFilterProps = PropsWithChildren & {
  defaultExpanded?: boolean;
  title?: string;
  summaryComponent?: ReactNode;
  detailsSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
};

const AccordionMuiStyle = {
  background: 'transparent',
  border: 'none',
  boxShadow: 'none',
  '&:before': { display: 'none' },
};

const AccordionSummaryStyle = {
  flexDirection: 'row-reverse',
  paddingLeft: 0,
  '& > .MuiAccordionSummary-content': { pl: 2 },
};
export const Accordion = (props: AccordionFilterProps) => {
  const {
    defaultExpanded = true,
    title,
    children,
    summaryComponent,
    detailsSx,
    sx,
  } = props;

  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <AccordionMui
      expanded={expanded}
      onChange={useCallback((_event: SyntheticEvent, value: boolean) => {
        setExpanded(value);
      }, [])}
      disableGutters
      sx={{ ...AccordionMuiStyle, ...sx }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={AccordionSummaryStyle}
      >
        {summaryComponent ?? <Typography>{title}</Typography>}
      </AccordionSummary>
      <AccordionDetails sx={detailsSx}>{children}</AccordionDetails>
    </AccordionMui>
  );
};
