import { sample } from 'effector';
import { Feature } from 'ol';
import { Geometry, Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

import { EMapFeatureLayout, EMapFeatureMetaKeys } from '@constants/map';

import {
  initSources,
  pipeCustomRailwayStationVectorLayer,
} from '@features/pt-forecast-new/stores/map';
import {
  $EditorMap,
  CustomRailwayStationItem,
  EditorMapApi,
} from '@features/pt-forecast-new/stores/map/editor';
import { LayersVisibleApi } from '@features/pt-forecast-new/stores/map/layersVisible';

import {
  EFeatureStyle,
  EPipeVectorLayer,
  PipeVectorLayer,
} from '@utils/map/hooks/useInitVectorLayer';

import { $SelectedFeatures } from '../selectedFeatures';

// Управление видимостью слоя
sample({
  clock: LayersVisibleApi.setVisible,
  filter: payload =>
    !!payload &&
    Object.keys(payload).includes(EMapFeatureLayout.customRailwayStation),
  fn: payload => {
    return {
      action: EPipeVectorLayer.setVisible,
      payload: payload![EMapFeatureLayout.customRailwayStation],
    } as PipeVectorLayer;
  },
  target: pipeCustomRailwayStationVectorLayer,
});

// рендер геометрии
sample({
  clock: [EditorMapApi.addStation, EditorMapApi.setInfrastructure, initSources],
  source: { EditorMap: $EditorMap },
  fn: ({ EditorMap }) => {
    const features: Feature<Geometry>[] = [];

    if (EditorMap.stations) {
      EditorMap.stations.forEach(staion => {
        features.push(
          new Feature({
            geometry: new Point(
              fromLonLat([staion.geometry.lon, staion.geometry.lat]),
            ),
            [EMapFeatureMetaKeys.olId]: staion.id,
            [EMapFeatureMetaKeys.layout]:
              EMapFeatureLayout.customRailwayStation,
            isTransparent: staion.isGhost,
          }),
        );
      });
    }

    return {
      action: EPipeVectorLayer.replaceFeatures,
      payload: { features },
    } as PipeVectorLayer;
  },
  target: pipeCustomRailwayStationVectorLayer,
});

// применение стилей к станции
sample({
  clock: [$EditorMap, $SelectedFeatures],
  source: { EditorMap: $EditorMap, SelectedFeatures: $SelectedFeatures },
  fn: ({ EditorMap, SelectedFeatures }) => {
    const selectedRailwayStations = SelectedFeatures.filter(
      item => item.layout === EMapFeatureLayout.customRailwayStation,
    ).map(item => item.id);

    return {
      action: EPipeVectorLayer.setFeatureStyle,
      payload: {
        featureStyles: Object.fromEntries(
          EditorMap.stations.map(item => [
            item.id,
            getStationStyle(
              item,
              selectedRailwayStations,
              EditorMap.drawGraph?.startStationId,
              EditorMap.drawGraph?.endStationId,
            ),
          ]),
        ),
      },
    } as PipeVectorLayer;
  },
  target: pipeCustomRailwayStationVectorLayer,
});

// Определения стиля для переданной станции
const getStationStyle = (
  station: CustomRailwayStationItem,
  selectedIds: string[],
  startedId?: string | null,
  endedId?: string | null,
) => {
  const isSelected = selectedIds.includes(station.id);

  if (isSelected) return EFeatureStyle.selected;
  if (startedId === station.id) return EFeatureStyle.started;
  if (endedId === station.id) return EFeatureStyle.ended;
  return EFeatureStyle.default;
};
