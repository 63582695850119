import {
  IconButton,
  ListItemButton as ListItemButtonMui,
  SvgIconProps,
} from '@mui/material';

import { BasketIcon } from '@components/icons';

interface ListItemProps {
  label: string;
  draggable?: boolean;
  selected?: boolean;
  Icon?: (props: SvgIconProps) => JSX.Element;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onClick?: () => void;
  onDelete?: () => void;
}

export const ListItem = ({
  label,
  draggable,
  selected,
  Icon,
  onDragEnd,
  onDragStart,
  onClick,
  onDelete,
}: ListItemProps) => {
  return (
    <ListItemButtonMui
      draggable={draggable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onClick={onClick}
      sx={{
        fontSize: theme => theme.typography.pxToRem(14),
        lineHeight: theme => theme.typography.pxToRem(16),
        height: '44px',

        '&:hover': {
          backgroundColor: '#E9EEF3',

          '& .IconButton-visible': {
            visibility: 'visible',
          },
        },
      }}
      selected={selected}
    >
      {Icon && <Icon sx={{ mr: '8px' }} />}
      {label}
      {onDelete && (
        <IconButton
          className={'IconButton-visible'}
          sx={{ ml: 'auto', visibility: 'hidden' }}
          onClick={e => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <BasketIcon />
        </IconButton>
      )}
    </ListItemButtonMui>
  );
};
