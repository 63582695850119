import { useUnit } from 'effector-react';

import {
  $Floors,
  $ListOfFloorsWithListOfObjects,
} from '@features/tpu-simulation-model/store';
import { ObjectOfTheSimulationModel } from '@features/tpu-simulation-model/types';
import { Option } from '@features/tpu-simulation-model/utils/getAttributeOptions';

interface ListOfObjects {
  floorOptions: Option[] | [];
  selectedFloorId: string;
  selectedFloorIndex: number;
  getFloorIndex: (id: string) => number | null;
  getObjectById: (
    id: string,
    floorId: string,
  ) => ObjectOfTheSimulationModel | null;
}

export const useFloors = (): ListOfObjects => {
  const $listOfFloorsWithListOfObjects = useUnit(
    $ListOfFloorsWithListOfObjects,
  );
  const allFloors = useUnit($Floors).floorsInfo;
  const floorOptions =
    allFloors?.map((item, i) => ({
      value: item.extraId as string,
      label: `${i + 1}`,
    })) ?? [];

  const selectedFloorId = useUnit($Floors).selectedFloor;
  const selectedFloor = allFloors.find(
    item => item.extraId === selectedFloorId,
  );
  const selectedFloorIndex =
    (selectedFloor && allFloors.indexOf(selectedFloor)) || 0;

  const getObjectById = (id: string, floorId: string) =>
    $listOfFloorsWithListOfObjects[floorId][id];

  const getFloorIndex = (id: string) => {
    const selectedFloor = allFloors?.find(item => item.extraId === id);
    return (selectedFloor && allFloors.indexOf(selectedFloor)) || null;
  };

  return {
    floorOptions,
    selectedFloorId,
    selectedFloorIndex,
    getFloorIndex,
    getObjectById,
  };
};
