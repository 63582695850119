export const DISTRICT_TYPES = {
  A_1: 'Жилой',
  A_2: 'Производственный',
  A_3: 'Общественно-деловой',
  A_4: 'Дачный',
  A_5: 'Жилой и рабочий',
  A_6: 'Промышленный',
  A_7: 'Рекреационный',
  A_8: 'Прочий',
};
