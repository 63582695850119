import { Icon, Stroke, Style } from 'ol/style';

import CulvertsBridgeSVG from '@components/icons/CulvertsBridge.svg';

export const culvertsVectorStyle = () => {
  return new Style({
    stroke: new Stroke({
      color: '#FF0000',
      width: 3,
    }),
    image: new Icon({
      src: CulvertsBridgeSVG,
    }),
  });
};
