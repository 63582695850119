import {
  IAgentShareInfoCategoryEnum,
  IUpdateAgentShareInfoByCategoryInput,
} from '@api/gql/tpu-types';
import { createEffect, createEvent, createStore } from 'effector';

import { IShareForm } from '@features/tpu-passenger-types/types';

export const $ShareForm = createStore<IShareForm>({
  scenarioId: '',
  category: IAgentShareInfoCategoryEnum.Visitors,
  shareInfo: [],
});
export const $IsOpenShareForm = createStore<boolean>(false);
export const $TriggerSaveShareForm = createStore<boolean>(false);
export const setIsOpenShareFormFn = createEvent<boolean>();
export const setIsTriggerSaveShareFormFn = createEvent<boolean>();
export const openShareForEditFn = createEvent<IAgentShareInfoCategoryEnum>();
export const savePassengerShareFn = createEvent<IShareForm>();
export const setShareFormStateFn = createEvent<IShareForm>();
export const setSelectedCategoryFn = createEvent<IAgentShareInfoCategoryEnum>();
export const updateShareFx = createEffect<
  IUpdateAgentShareInfoByCategoryInput,
  void
>();
