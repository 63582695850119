import { useMemo } from 'react';

import { Box, Divider, IconButton, Typography } from '@mui/material';

import { Accordion } from '@components/Accordion';
import { MapFilterField } from '@components/MapFilters';
import { PieChart, PieChartProps } from '@components/PieChart';
import { Table, TableProps } from '@components/Table';
import { ChevronRightIcon } from '@components/icons';

import { getTrafficColor } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/helpers';
import { FilterRegionsData } from '@features/passenger-traffic/stores/filterRegions';
import { FilterMapData } from '@features/passenger-traffic/stores/filters';
import { PassengerTrafficInspectorType } from '@features/passenger-traffic/stores/inspector/PassengerTraffic';
import { VehicleTypeCode } from '@features/passenger-traffic/stores/inspector/types';

import { convertUnitOfMeasure } from '@utils/dataForInspector';

interface PassengerTrafficInspectorProps {
  filter: FilterMapData;
  filterRegions: FilterRegionsData;
  passengerTrafficInspector: PassengerTrafficInspectorType;
  onClose?(): void;
}

export const PassengerTrafficInspector = (
  props: PassengerTrafficInspectorProps,
) => {
  const { filter, filterRegions, passengerTrafficInspector, onClose } = props;

  const regionFrom = filterRegions.selectedRegionA;
  const regionTo = filterRegions.selectedRegionB;
  const { total, passengerTrafficPercent, intermediateCorrespondences } =
    passengerTrafficInspector;

  /** Сумма пассажиропотока для графика */
  const totalChartInfo = useMemo<PieChartProps['totalInfo']>(() => {
    const data = convertUnitOfMeasure({
      value: total,
      unit: 'пасс.',
      isReturnArray: true,
    });

    return {
      totalValue: data[0],
      totalLabel: data[1] + ' ' + data[2],
    };
  }, [total]);

  /** Легенда графика */
  const pieChartData = useMemo<PieChartProps['data'] | null>(() => {
    const data = [];

    for (const vehicleId in passengerTrafficPercent) {
      const key = vehicleId as keyof MapFilterField;
      const index =
        vehicleId as keyof PassengerTrafficInspectorType['passengerTrafficPercent'];
      const label: VehicleTypeCode | undefined =
        VehicleTypeCode[key as keyof typeof VehicleTypeCode];

      if (vehicleId) {
        data.push({
          value: passengerTrafficPercent[index],
          label,
          color: getTrafficColor(label as VehicleTypeCode),
        });
      }
    }

    return data;
  }, [passengerTrafficPercent]);

  /** Данные для основной таблицы */
  const totalTableInfo = useMemo<TableProps['data']>(
    () => [
      [
        `${regionFrom?.center?.name} — ${regionTo?.center?.name}`,
        `${totalChartInfo.totalValue} ${totalChartInfo.totalLabel}`,
      ],
    ],
    [
      regionFrom?.center?.name,
      regionTo?.center?.name,
      totalChartInfo.totalLabel,
      totalChartInfo.totalValue,
    ],
  );

  /** Данные для таблицы промежуточных корреспонденций */
  const detailedTotalTableInfo = useMemo<TableProps['data'] | null>(() => {
    if (intermediateCorrespondences.length === 0) {
      return null;
    }

    const descTraffic = intermediateCorrespondences.sort(
      (a, b) => (b?.trafficTotal ?? 0) - (a?.trafficTotal ?? 0),
    );

    return descTraffic.map(correspondence => {
      return [
        `${correspondence.regionFrom} — ${correspondence.regionTo}`,
        `${convertUnitOfMeasure({
          value: correspondence.trafficTotal,
          singleDigitAccuracy: 0,
        })}`,
      ];
    });
  }, [intermediateCorrespondences]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 3,
        }}
      >
        <IconButton
          sx={{
            width: '32px',
            height: '32px',
            backgroundColor: 'primary.main',
            borderRadius: 1,
            color: 'white',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
          onClick={onClose}
        >
          <ChevronRightIcon />
        </IconButton>

        {regionFrom && regionTo && (
          <Typography variant="subtitle2">
            {regionFrom.center?.name} — {regionTo.center?.name}
          </Typography>
        )}
      </Box>

      <>
        <Typography
          variant="subtitle2"
          mb="16px"
        >
          Пассажиропоток
        </Typography>

        {pieChartData && (
          <PieChart
            data={pieChartData}
            totalInfo={totalChartInfo}
            sizeParams={{
              height: 140,
              width: 140,
              innerRadius: 45,
              outerRadius: 70,
              paddingAngle: 1,
            }}
          />
        )}

        <Divider sx={{ my: '24px' }} />

        <Table data={totalTableInfo} />

        {detailedTotalTableInfo && filter.intermediateCorrespondents && (
          <Box>
            <Accordion
              summaryComponent={
                <Typography variant={'subtitle2'}>
                  Суммарные пассажиропотоки
                </Typography>
              }
              detailsSx={{ padding: '0' }}
            >
              <Table data={detailedTotalTableInfo} />
            </Accordion>
          </Box>
        )}
      </>
    </>
  );
};
