import './stores/init';

import { Component } from '@features/tpu-passenger-types/component';
import {
  PassengerTypesHandlersContext,
  usePassengerTypesHandlers,
} from '@features/tpu-passenger-types/hooks/usePassengerTypesHandlers';
import { Modals } from '@features/tpu-passenger-types/modals';

export const PassengerTypesContainer = () => {
  const value = usePassengerTypesHandlers();

  return (
    <PassengerTypesHandlersContext.Provider value={value}>
      <Component />
      <Modals />
    </PassengerTypesHandlersContext.Provider>
  );
};
