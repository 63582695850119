import { useMapTools } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/hooks/useMapTools';
import { useContextPTHandlers } from '@features/passenger-traffic/hooks';
import { setMapFn } from '@features/passenger-traffic/stores/map/store';

import { Map } from '@components/Map';

export const PassengerMap = () => {
  const { setStopPoint, findRegion } = useContextPTHandlers();
  const { handlerOnClickMap } = useMapTools({ setStopPoint, findRegion });

  return (
    <Map
      onClick={handlerOnClickMap}
      setMap={setMapFn}
    />
  );
};
