import { apiClient } from '@api/client';
import {
  IMutation,
  ISocEcoParamsUpdateInput,
  ITransportParamsInput,
} from '@api/gql/types';
import { gql } from '@apollo/client';

export type QueryEditUserScenarioArgs = {
  id: string;
  name: string;
  socEcoScenarioId?: string;
  socEcoScenarioType?: number;
  socEcoScenarioParams?: ISocEcoParamsUpdateInput;
  transportScenarioId?: string;
  transportScenarioType?: number;
  transportScenarioParams?: ITransportParamsInput;
};

export const queryEditUserScenario = async (
  editUserScenario: QueryEditUserScenarioArgs,
) => {
  const result = await apiClient.mutate<IMutation, QueryEditUserScenarioArgs>({
    mutation: gql`
      mutation QueryUpdateComputingRegistry(
        $id: UUID!
        $name: String!
        $socEcoScenarioId: UUID
        $socEcoScenarioType: Int
        $socEcoScenarioParams: SocEcoParamsUpdateInput
        $transportScenarioParams: TransportParamsInput
        $transportScenarioId: UUID
        $transportScenarioType: Int
      ) {
        updateComputingRegistry(
          id: $id
          name: $name
          socEcoScenarioParams: $socEcoScenarioParams
          socEcoScenarioId: $socEcoScenarioId
          socEcoScenarioType: $socEcoScenarioType
          transportScenarioParams: $transportScenarioParams
          transportScenarioId: $transportScenarioId
          transportScenarioType: $transportScenarioType
        ) {
          computingRegistryObj {
            id
            name
            socEcoScenario {
              growthRateInTheNumberOfPlacements
              scenarioType
              revenueGrowthRate
              growthRateOfMotorization
              populationGrowthRate
              jobGrowthRate
              salaryGrowthRate
              name
              id
            }
            transportScenario {
              id
              name
              scenarioType
              transportProjects {
                id
                year
                transportProject {
                  name
                }
              }
            }
          }
        }
      }
    `,
    variables: editUserScenario,
  });
  if (result.errors?.length) throw new Error(result.errors[0].message);
  return result.data?.updateComputingRegistry?.computingRegistryObj;
};
