import { apiClient } from '@api/client';
import { IComputingRegistryType, IQuery } from '@api/gql/types';
import { gql } from '@apollo/client';

export const queryGetUserScenarioStatuses = async () => {
  const result = await apiClient.query<Pick<IQuery, 'computingRegistry'>>({
    query: gql`
      query QueryComputingRegistry {
        computingRegistry {
          resultStatus
          id
        }
      }
    `,
  });
  return result?.data?.computingRegistry as PollingStatusesResponse;
};

export type PollingStatusesResponse = Pick<
  IComputingRegistryType,
  'id' | 'resultStatus'
>[];
