import { createApi, createStore } from 'effector';

const initialState: InspectorState = {
  transportRegion: null,
};

export const $Inspector = createStore<InspectorState>(initialState);

export const InspectorApi = createApi($Inspector, {
  setTransportRegion: (store, payload: InspectorState['transportRegion']) => ({
    ...store,
    transportRegion: payload,
  }),
  reset: () => initialState,
});

export type InspectorState = {
  transportRegion: {
    population: number;
    average_number_of_employees_of_organizations_without_smp: number;
    average_monthly_salary: number;
    revenue_with_taxes_on_products: number;
    number_of_places: number;
    revenue_tourism_industry: number;
    level_of_motorization: number;
    total_flow: number;
    name: string;
  } | null;
};
