import { apiClient } from '@api/client';
import { IQuery, ITurnstileType } from '@api/gql/tpu-types';
import { gql } from '@apollo/client';
import { sample } from 'effector';

import { TPU_CLIENT_NAME } from '@constants/api';

import {
  $ScenarioId,
  resetTurnstilesFn,
} from '@features/tpu-simulation-model/store';
import {
  $ListOfTurnstiles,
  getTurnstilesByScenarioIdFx,
  getTurnstilesFn,
} from '@features/tpu-simulation-model/store/listOfTurnstiles/listOfTurnstiles.store';

$ListOfTurnstiles
  .reset(resetTurnstilesFn)
  .on(getTurnstilesByScenarioIdFx.done, (_, { result }) => {
    return result ?? [];
  });

sample({
  clock: getTurnstilesFn,
  source: $ScenarioId,
  filter: id => !!id,
  fn: id => id as string,
  target: getTurnstilesByScenarioIdFx,
});

getTurnstilesByScenarioIdFx.use(async scenarioId => {
  const response = await apiClient.query<IQuery>({
    query: gql`
      query SampleTurnstilesByScenarioId($scenarioId: UUID!) {
        sampleTurnstilesByScenarioId(scenarioId: $scenarioId) {
          bandwidthPerHour
          eid
          openFrom
          openUntil
        }
      }
    `,
    variables: {
      scenarioId,
    },
    context: { clientName: TPU_CLIENT_NAME },
  });

  return (response.data.sampleTurnstilesByScenarioId?.filter(Boolean) ??
    []) as ITurnstileType[];
});
