import { Line } from 'react-konva';

import { IWall } from '@features/tpu-simulation-model/types';

interface WallProps extends IWall {
  opacity: number;
}

export const Wall = (props: WallProps) => {
  const { points, isClosed, opacity } = props;

  return (
    <Line
      opacity={opacity}
      strokeWidth={4}
      stroke="#E21A1A"
      closed={isClosed}
      points={points.flatMap(({ x, y }) => [x, y])}
    />
  );
};
