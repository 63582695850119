import { useUnit } from 'effector-react';
import { useForm } from 'react-hook-form';

import WarningIcon from '@mui/icons-material/Warning';
import { Button, Divider, Stack, Typography } from '@mui/material';

import { Checkboxes } from '@features/tpu-simulation-model/components/FormAttributes/Checkboxes';
import { FormActions } from '@features/tpu-simulation-model/components/FormAttributes/FormActions';
import { ObjectPosition } from '@features/tpu-simulation-model/components/FormAttributes/ObjectPosition';
import { ObjectSize } from '@features/tpu-simulation-model/components/FormAttributes/ObjectSize';
import { SelectOption as Select } from '@features/tpu-simulation-model/components/FormAttributes/Select';
import { Title } from '@features/tpu-simulation-model/components/FormAttributes/Title';
import { useContextSimulationModelHandlers } from '@features/tpu-simulation-model/hooks';
import { useFloors } from '@features/tpu-simulation-model/hooks/useFloors';
import { useWaysPlatforms } from '@features/tpu-simulation-model/hooks/useListOfWaysPlatforms';
import { $ListOfWaysPlatforms } from '@features/tpu-simulation-model/store';
import {
  ActionBeforeOpenErrorsWindow,
  IPath,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

interface FormProps extends IPath {
  onUpdateAndClose: (arg: ObjectOfTheSimulationModel) => void;
  onDeleteAndClose: (arg: string) => void;
}

export const Path = ({
  onUpdateAndClose,
  onDeleteAndClose,
  ...props
}: FormProps) => {
  const { handleSubmit, formState, control } = useForm<IPath>({
    values: props,
  });
  const { selectedFloorIndex } = useFloors();
  const { wayOptions } = useWaysPlatforms();
  const listOfPlatforms = useUnit($ListOfWaysPlatforms).platforms as string[];
  const { triggerFindErrors, openPlanErrorsWindow } =
    useContextSimulationModelHandlers();

  const openErrorsModal = (action: ActionBeforeOpenErrorsWindow) => () => {
    triggerFindErrors({ saveAfterAnalysis: true });
    openPlanErrorsWindow(action);
  };

  return (
    <Stack
      padding={3}
      gap={3}
      sx={{
        overflow: 'auto',
      }}
    >
      <Title title={'Путь'} />
      <Select<IPath>
        control={control}
        name={'way'}
        title={'Номер пути'}
        placeholder={'Выберите номер пути'}
        options={wayOptions}
      />
      {listOfPlatforms?.length ? (
        <Checkboxes<IPath>
          control={control}
          name={'platforms'}
          title={'Выбрать платформы'}
          options={listOfPlatforms}
        />
      ) : (
        <Typography>
          <WarningIcon
            color={'warning'}
            sx={{
              mr: '4px',
              position: 'relative',
              top: '5px',
            }}
          />
          Добавьте объект «Платформа», для связки с путем
        </Typography>
      )}
      <Button
        variant={'outlined'}
        onClick={openErrorsModal(
          ActionBeforeOpenErrorsWindow.GoToTrainSchedule,
        )}
      >
        Расписание поездов
      </Button>
      <Divider />
      <ObjectSize
        width={props.width}
        height={props.height}
      />
      <ObjectPosition
        point={props.point}
        floor={selectedFloorIndex + 1}
      />
      <Divider />
      <FormActions
        onSave={handleSubmit(onUpdateAndClose)}
        onDelete={() => onDeleteAndClose(props?.uuid)}
        disabled={!formState.isValid}
      />
    </Stack>
  );
};
