import { SvgIcon, SvgIconProps } from '@mui/material';

export const RoomOuterDottedLineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <svg
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          clipPath="url(#a)"
          stroke="#F14848"
        >
          <rect
            x="4.25"
            y="4.25"
            width="15.5"
            height="15.5"
            rx="1.75"
            fill="#E9EEF3"
            strokeWidth=".5"
          />
          <path
            strokeDasharray="4 4"
            d="M.5.5h23v23H.5z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <rect
              width="24"
              height="24"
              rx="2"
              fill="#fff"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
