import { FeatureLike } from 'ol/Feature';
import { StyleLike } from 'ol/style/Style';

import { EFilterMapLayout } from '@components/MapFilters';

import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { CustomMap } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/helpers';
import {
  getAirlineStyle,
  getAirportStyle,
  getAreaBorderStyle,
  getAutoStyle,
  getBusStationStyle,
  getHighwayStyle,
  getIntermediateCorrespondentsStyle,
  getPlannedHighwayStyle,
  getPlannedRailwayStyle,
  getPointAStyle,
  getPointBStyle,
  getRailwayStation,
  getRailwayStyle,
} from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/layouts';
import { getPlannedTileHighwayStyle } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/layouts/getPlannedTileHighway';
import { getPlannedTileRailwayStyle } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/styleTools/layouts/getPlannedTileRailway';

export interface SetFeatureStyleProps {
  layout: EMapFeatureLayout;
  active: boolean;
  filterMapLayout: EFilterMapLayout | undefined;
  width?: number;
  feature?: FeatureLike;
  resolution: number;
  map: CustomMap;
}

/** Возвращает стилизацию для слоя по layout */
export const getFeatureStyle = (args: SetFeatureStyleProps): StyleLike => {
  const { layout, feature, map, resolution, active, filterMapLayout, width } =
    args;

  if (layout === EMapFeatureLayout.multimodal) return [];

  switch (layout) {
    case EMapFeatureLayout.plannedTileRailway:
      return getPlannedTileRailwayStyle({
        active,
        width,
        map,
        resolution,
        filterMapLayout,
      });
    case EMapFeatureLayout.directionRailway:
    case EMapFeatureLayout.railway:
      return getRailwayStyle({
        active,
        width,
        map,
        resolution,
        filterMapLayout,
      });

    case EMapFeatureLayout.plannedRailway:
      return getPlannedRailwayStyle({ active, width });

    case EMapFeatureLayout.airline:
      return getAirlineStyle({ active, width });

    case EMapFeatureLayout.auto:
      return getAutoStyle({ active, width });

    case EMapFeatureLayout.plannedTileHighway:
      return getPlannedTileHighwayStyle({
        active,
        width,
        map,
        resolution,
        feature,
      });

    case EMapFeatureLayout.directionHighway:
    case EMapFeatureLayout.highway:
      return getHighwayStyle({ active, width, map, resolution, feature });

    case EMapFeatureLayout.plannedHighway:
      return getPlannedHighwayStyle({ active, width });

    case EMapFeatureLayout.areaBorder:
      return getAreaBorderStyle({ active, width });

    case EMapFeatureLayout.airport:
      return getAirportStyle({ active });

    case EMapFeatureLayout.busStation:
      return getBusStationStyle({ active });

    case EMapFeatureLayout.railwayStation:
      return getRailwayStation({ active, filterMapLayout });

    case EMapFeatureLayout.intermediateCorrespondents:
      return getIntermediateCorrespondentsStyle({ active, width });

    case EMapFeatureLayout.pointA:
      return getPointAStyle();

    case EMapFeatureLayout.pointB:
      return getPointBStyle();

    // TODO: удалить все что связанно с EMapFeatureLayout.avia?
    case EMapFeatureLayout.avia: {
      return [];
    }
  }
};
