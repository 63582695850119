import {
  StepConnector,
  StepIconProps,
  StepLabel,
  stepConnectorClasses,
  stepLabelClasses,
  styled,
} from '@mui/material';

import { RadioButtonCheckedIcon } from '@components/icons';

export const StepPointConnectorV2 = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.root}`]: {
    top: '20px',
    marginLeft: '-8px',
    marginRight: '-8px',
    marginTop: '26px',
    zIndex: '0',
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 8,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
  [`&.${stepConnectorClasses.completed} .${stepConnectorClasses.line}, &.${stepConnectorClasses.active} .${stepConnectorClasses.line}`]:
    {
      backgroundColor: theme.palette.border.stroke,
    },
}));

export type StepPointIconPropsV2 = StepIconProps & {
  isComing?: boolean;
};

export const StepPointIconV2 = ({
  active,
  isComing,
  ...props
}: StepPointIconPropsV2) => {
  return (
    <RadioButtonCheckedIcon
      color={active ? 'secondary' : undefined}
      hexcolor={isComing && !active && !props.completed ? '#757474' : undefined}
    />
  );
};

type StepPointPropsV2 = {
  disabled?: boolean;
};
export const StepPointV2 = styled(StepLabel)<StepPointPropsV2>(
  ({ theme, disabled }) => ({
    [`&.${stepLabelClasses.root}`]: {
      flexDirection: 'column-reverse',
    },
    [`& .${stepLabelClasses.labelContainer}`]: {
      marginBottom: theme.spacing(1),
    },
    [`& .${stepLabelClasses.iconContainer}`]: {
      paddingRight: 0,
      color: theme.palette.border.stroke,
      cursor: disabled ? 'default' : 'pointer',
    },
    [`& .${stepLabelClasses.iconContainer}.${stepLabelClasses.completed}`]: {
      color: theme.palette.primary.main,
    },
    [`& .${stepLabelClasses.iconContainer}.${stepLabelClasses.completed} rect`]:
      {
        fill: theme.palette.primary.main,
      },
  }),
);
