import {
  IDirectionType,
  IHighwayType,
  IQueryFindRegionArgs,
  IQueryFindRegionByPrefixArgs,
  IQueryGetDirectionArgs,
  IRailwayStationType,
  IRailwayType,
  ITransportRegionDetailType,
  ITransportRegionType,
  Maybe,
} from '@api/gql/types';
import { EMapFeatureLayout } from '@features/passenger-traffic/components/PassengerMap/mapToolBox/constants/enums';
import { createApi, createEffect, createEvent, createStore } from 'effector';

import { EFilterMapPoint } from '@components/MapFilters';

const initialState = {
  regionsA: [],
  regionsB: [],
  selectedRegionA: null,
  selectedRegionB: null,
  directions: {
    railway: [],
    highway: [],
    regions: [],
    stations: [],
  },
};

export const $FilterRegions = createStore<FilterRegionsData>(initialState);

// Флаг постановки поинта на карту кликом //TODO в рефакторинг
export const setStartPointFn = createEvent<EFilterMapPoint | undefined>();
// Получает базовую информацию о регионе (без станций и тд)
export const setRegionFn = createEvent<SetRegionArg>();
//
export const findRegionByPrefixFn = createEvent<FindRegionByPrefixArgs>();
//
export const findRegionFn = createEvent<FindRegionArgs>();
// Получает расширенный объект ТР со станциями и тд по координатам
export const findRegionFx = createEffect<
  FindRegionArgs,
  ITransportRegionDetailType
>();
//
export const findRegionByPrefixFx = createEffect<
  FindRegionByPrefixArgs,
  Maybe<Maybe<ITransportRegionType>[]>
>();

// Получить корреспонденции
export const getDirectionFx = createEffect<
  IQueryGetDirectionWithLayoutArgs,
  Maybe<IDirectionType>
>();

export const getDirectionRailwayFx = createEffect<
  IQueryGetDirectionWithLayoutArgs,
  Maybe<IDirectionType>
>();

export const getDirectionHighwayFx = createEffect<
  IQueryGetDirectionWithLayoutArgs,
  Maybe<IDirectionType>
>();

export const $isIdleFindRegionFx = findRegionFx.pending.map(
  pending => !pending,
);

export const FilterRegionsApi = createApi($FilterRegions, {
  setListRegions: (state, payload: SetListRegionsPayload) => ({
    ...state,
    regionsA: payload.point === EFilterMapPoint.A ? payload.options : [],
    regionsB: payload.point === EFilterMapPoint.B ? payload.options : [],
  }),
  setSelectedRegion: (state, payload: SetRegionPayload) => ({
    ...state,
    selectedRegionA:
      payload.point === EFilterMapPoint.A
        ? payload.option
        : state.selectedRegionA,
    selectedRegionB:
      payload.point === EFilterMapPoint.B
        ? payload.option
        : state.selectedRegionB,
    regionsA: [],
    regionsB: [],
  }),
  clearSelectedRegions: state => ({
    ...state,
    selectedRegionA: null,
    selectedRegionB: null,
    regionsA: [],
    regionsB: [],
  }),
  setDirections: (
    state,
    payload: Omit<FilterRegionsData['directions'], 'highway' | 'railway'>,
  ) => ({
    ...state,
    directions: {
      ...state.directions,
      ...payload,
    },
  }),
  setRailwayDirections: (state, payload: IRailwayType[]) => ({
    ...state,
    directions: {
      ...state.directions,
      railway: payload,
    },
  }),
  setHighwayDirections: (state, payload: IHighwayType[]) => ({
    ...state,
    directions: {
      ...state.directions,
      highway: payload,
    },
  }),
  clearDirections: state => ({
    ...state,
    directions: initialState.directions,
  }),
  reset: () => initialState,
});

export type FilterRegionsData = {
  regionsA: ITransportRegionType[];
  regionsB: ITransportRegionType[];
  selectedRegionA: ITransportRegionType | null;
  selectedRegionB: ITransportRegionType | null;
  directions: {
    railway: IRailwayType[];
    highway: IHighwayType[];
    regions: ITransportRegionType[];
    stations: IRailwayStationType[];
  };
};

export type FindRegionByPrefixArgs = IQueryFindRegionByPrefixArgs & {
  point: EFilterMapPoint;
};

export type SetRegionArg = {
  option: ITransportRegionType | null;
  point: EFilterMapPoint;
};

export type FindRegionArgs = IQueryFindRegionArgs & { point: EFilterMapPoint };

export type IQueryGetDirectionWithLayoutArgs = IQueryGetDirectionArgs & {
  layout: EMapFeatureLayout;
  width?: number;
};

export type SetListRegionsPayload = {
  point: EFilterMapPoint;
  options: ITransportRegionType[];
};

export type SetRegionPayload = {
  option: ITransportRegionType | null;
  point: EFilterMapPoint;
};
