import { createEvent, sample } from 'effector';

import { ComputedDataApi } from '@features/pt-forecast-new/stores/computedData';
import { FilterMapApi } from '@features/pt-forecast-new/stores/filters';
import { InspectorApi } from '@features/pt-forecast-new/stores/inspector';
import { InspectorInfrastructureApi } from '@features/pt-forecast-new/stores/inspector/infrastructure';
import { ComputedApi } from '@features/pt-forecast-new/stores/lists/computed';
import { EditorMapApi } from '@features/pt-forecast-new/stores/map/editor';
import { LayersVisibleApi } from '@features/pt-forecast-new/stores/map/layersVisible';
import { SelectedFeaturesApi } from '@features/pt-forecast-new/stores/map/selectedFeatures';
import { VectorGeometryApi } from '@features/pt-forecast-new/stores/map/vectorFeatures';
import { RecalculateApi } from '@features/pt-forecast-new/stores/recalculate';
import { FeatureSettingsApi } from '@features/pt-forecast-new/stores/settings';
import { UIApi } from '@features/pt-forecast-new/stores/ui';
import { YearLineApi } from '@features/pt-forecast-new/stores/yearLine';

export const storesReset = createEvent();

sample({
  clock: storesReset,
  target: [
    ComputedDataApi.reset,
    FilterMapApi.reset,
    InspectorApi.reset,
    InspectorInfrastructureApi.reset,
    ComputedApi.reset,
    SelectedFeaturesApi.reset,
    LayersVisibleApi.reset,
    VectorGeometryApi.reset,
    RecalculateApi.reset,
    FeatureSettingsApi.reset,
    UIApi.reset,
    YearLineApi.reset,
    EditorMapApi.reset,
  ],
});
