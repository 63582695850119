import { useMemo } from 'react';

import { Box } from '@mui/material';

import { Chart, ChartProps } from './Chart';
import { DataInfo } from './DataInfo';

export const PieChart = ({ data, totalInfo, sizeParams }: PieChartProps) => {
  const dataWithValue = useMemo(
    () => data.filter(({ value }) => (value ?? 0) > 0),
    [data],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '30px',
      }}
    >
      <Chart
        data={dataWithValue}
        totalInfo={totalInfo}
        sizeParams={sizeParams}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        {dataWithValue.map(data => (
          <DataInfo
            value={data?.value}
            label={data.label}
            color={data.color}
            reverse={false}
            key={data.color}
          />
        ))}
      </Box>
    </Box>
  );
};

export type PieChartProps = ChartProps;
