import { SvgIcon, SvgIconProps } from '@mui/material';

export const PlatformOuterDottedLineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <svg
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#a)">
          <rect
            width="24"
            height="24"
            rx="2"
            fill="#fff"
          />
          <path
            fill="#A3D7EB"
            d="M4 11h16v6H4zM10 7h10v4H10z"
          />
          <path
            stroke="#3679EA"
            strokeDasharray="4 4"
            d="M.5.5h23v23H.5z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <rect
              width="24"
              height="24"
              rx="2"
              fill="#fff"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
