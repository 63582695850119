import { useUnit } from 'effector-react';
import { createElement } from 'react';
import { Layer } from 'react-konva';

import { SpatialObject } from '@features/tpu-simulation-model/components/Editor/SpatialObjects/SpatialObject';
import { useSimulationModelHandlers } from '@features/tpu-simulation-model/hooks';
import {
  $EditedObject,
  $Editor,
  $ListOfObjectsSortedByType,
} from '@features/tpu-simulation-model/store';
import {
  EditableObjectType,
  ObjectOfTheSimulationModel,
} from '@features/tpu-simulation-model/types';

interface SpatialObjectsProps {
  listening: boolean;
}

const isNotRoomWallPlatform = (data: [string, unknown]) => {
  const [type] = data;

  return (
    type !== EditableObjectType.Room &&
    type !== EditableObjectType.Wall &&
    type !== EditableObjectType.Platform
  );
};

export const SpatialObjects = ({ listening }: SpatialObjectsProps) => {
  const $editor = useUnit($Editor);
  const $editedObject = useUnit($EditedObject);
  const $listOfObjects = useUnit($ListOfObjectsSortedByType);

  const { openObjectForEdit, updateObjectData } = useSimulationModelHandlers();

  if (!$listOfObjects) return null;

  const rooms = $listOfObjects.ROOM ?? [];
  const walls = $listOfObjects.WALL ?? [];
  const platforms = $listOfObjects.PLATFORM ?? [];
  const filteredList = Object.entries($listOfObjects)
    .filter(isNotRoomWallPlatform)
    .flatMap<ObjectOfTheSimulationModel>(([, item]) => item);

  const list = new Array<ObjectOfTheSimulationModel>().concat(
    rooms,
    platforms,
    walls,
    filteredList,
  );

  return (
    <Layer listening={listening}>
      {list.map(simulationModelObject =>
        createElement(SpatialObject, {
          ...simulationModelObject,
          opacity: $editor.opacity,
          onSelect: openObjectForEdit,
          isSelected: $editedObject.data?.uuid === simulationModelObject.uuid,
          updateObjectData: updateObjectData,
          key: simulationModelObject.uuid,
        }),
      )}
    </Layer>
  );
};
